import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'
import { Font } from '@politechdev/blocks-design-system'
import styles from './DetailItem.module.scss'

const cx = classNames.bind(styles)

const DetailItem = ({ hide, label, className, children }) => {
  if (hide) {
    return null
  }
  return (
    <div className={cx('item', className)}>
      <div className={styles.label}>
        <Font.Label>{label}</Font.Label>
      </div>
      <div className={styles.value}>
        <Font.Copy>{children}</Font.Copy>
      </div>
    </div>
  )
}

DetailItem.displayName = 'DetailItem'

DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  hide: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
}

DetailItem.defaultProps = {
  children: null,
  hide: false,
}

export default DetailItem
