import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Section,
  Sheet,
  ProgressBar,
  Tab,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useContext } from 'react'
import TemplateContent from '../TemplateContent/TemplateContent'
import styles from './Template.module.scss'
import {
  TemplateContext,
  TemplateProvider,
} from '../TemplateContext/TemplateContext'
import TemplateEditModal from '../TemplateEditModal/TemplateEditModal'
import TemplateControls from '../TemplateControls/TemplateControls'
import TemplateNameField from '../TemplateNameField/TemplateNameField'
import TemplateStyle from '../TemplateStyle/TemplateStyle'
import TemplatePreview from '../TemplatePreview/TemplatePreview'
import TemplatePreviewLabel from '../TemplatePreviewLabel/TemplatePreviewLabel'

const Template = () => {
  const { t } = useTranslation()
  const { isLoading, errorMsg, content, colors, fontFamily } =
    useContext(TemplateContext)
  return (
    <div>
      <CardError
        hide={!errorMsg}
        message={t('There was a problem retrieving this draft.')}
      />
      <TemplateEditModal />
      <PageHeader title={t('Edit draft')}>
        <TemplateControls />
      </PageHeader>
      <ProgressBar show={isLoading} />
      <Sheet>
        <div className={styles.panels}>
          <section>
            <TemplateNameField />
            <Tab.Container>
              <Tab label={t('Content')}>
                <TemplateContent />
              </Tab>
              <Tab label={t('Style')}>
                <TemplateStyle />
              </Tab>
            </Tab.Container>
          </section>
          <Section>
            <TemplatePreviewLabel />
            <TemplatePreview template={{ content, colors, fontFamily }} />
          </Section>
        </div>
      </Sheet>
    </div>
  )
}
export default () => (
  <TemplateProvider>
    <Template />
  </TemplateProvider>
)
