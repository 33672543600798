import { Switch, Route } from 'react-router-dom'
import { View } from 'components'
import ScriptsSelect from './ScriptsSelect'
import ScriptEdit from './ScriptEdit'
import CallScriptsContextProvider from './CallScriptsContext'

const Scripts = ({ match }) => (
  <CallScriptsContextProvider>
    <Switch>
      <Route exact path={`${match.url}/:scriptId`}>
        <View>
          <ScriptEdit />
        </View>
      </Route>
      <Route exact path={`${match.url}`}>
        <View>
          <ScriptsSelect />
        </View>
      </Route>
    </Switch>
  </CallScriptsContextProvider>
)

export default Scripts
