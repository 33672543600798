import { ButtonBlock, Uploader } from 'components'
import { Button, Section, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const AttachmentsBlock = ({ existingFiles, onUpload }) => {
  const { t } = useTranslation()
  return (
    <div>
      <Section label={t('Attachments')}>
        {existingFiles.map(file => (
          <ButtonBlock>
            <Button onClick={() => window.open(file.file_url, '_blank')}>
              <Icon.Paperclip />
              <span>{file.name}</span>
            </Button>
          </ButtonBlock>
        ))}
        <ButtonBlock>
          <Uploader
            label={t('New attachment')}
            dialog
            autoUpload
            stringifyData={false}
            endpoint="/system/files/upload?metadata_generator=documents"
            onUpload={onUpload}
          />
        </ButtonBlock>
      </Section>
    </div>
  )
}

export default AttachmentsBlock
