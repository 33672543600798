import { useTranslation } from 'react-i18next'
import { CardError, Map, LoadBar, Sheet } from 'components'
import { Font } from '@politechdev/blocks-design-system'
import { MapProvider, QueryParamProvider, PaginationProvider } from 'contexts'
import { capitalize } from 'lodash'
import {
  LocationMapProvider,
  useLocationMap,
} from '../LocationMapProvider/LocationMapProvider'
import LocationMapPopup from '../LocationMapPopup/LocationMapPopup'
import LocationMapSidebar from '../LocationMapSidebar/LocationMapSidebar'
import LocationMapViewToggle from '../LocationMapViewToggle/LocationMapViewToggle'
import LocationMapSearchButton from '../LocationMapSearchButton/LocationMapSearchButton'
import styles from './LocationMap.module.scss'

const LocationMap = () => {
  const { t } = useTranslation()
  const {
    isVR,
    viewport,
    setViewport,
    activeLocation,
    setActiveLocation,
    clearActiveLocation,
    locations,
    isLoading,
    hasPermission,
    useClusters,
  } = useLocationMap()

  if (!hasPermission) {
    return (
      <CardError
        hide={hasPermission}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  const resource = isVR ? 'form' : 'signature'

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LocationMapSidebar />
        <div className={styles.map}>
          <LocationMapViewToggle />
          <Map
            viewport={viewport}
            onViewportChange={setViewport}
            className={styles.canvas}
            maxZoom={18}
            minZoom={1}
            showZoomControls={false}
          >
            <div className={styles.loadbar}>
              <LoadBar show={isLoading} />
            </div>
            <LocationMapSearchButton />
            <LocationMapPopup />
            <Sheet className={styles.legend}>
              <Font.Copy className={styles.legend__header}>
                {t(`${capitalize(resource)}s per field hour`)}*
              </Font.Copy>
              <div className={styles.legend__content}>
                <Font.Copy
                  className={styles['legend__content__four-plus-items']}
                >
                  <div className={styles.legend__content__box} />
                  {t(`4+ ${resource}s`)}
                </Font.Copy>
                <Font.Copy
                  className={styles['legend__content__two-to-four-items']}
                >
                  <div className={styles.legend__content__box} />
                  {t(`2-4 ${resource}s`)}
                </Font.Copy>
                <Font.Copy
                  className={styles['legend__content__one-to-two-items']}
                >
                  <div className={styles.legend__content__box} />
                  {t(`1-2 ${resource}s`)}
                </Font.Copy>
                <Font.Copy
                  className={styles['legend__content__less-than-one-item']}
                >
                  <div className={styles.legend__content__box} />
                  {t(`Less than 1 ${resource}`)}
                </Font.Copy>
                <Font.Copy className={styles['legend__content__no-shifts']}>
                  <div className={styles.legend__content__box} />
                  {t('No shifts')}
                </Font.Copy>
              </div>
              <Font.Copy variant="hint" className={styles.legend__disclaimer}>
                *{t('Within selected date range')}
              </Font.Copy>
            </Sheet>
            <Map.Markers
              markers={locations
                .filter(location => location.id !== activeLocation?.id)
                .map(location => ({
                  ...location,
                  itemsPerFieldHour: isVR
                    ? location.stats.in_range.forms_per_hour
                    : location.stats.in_range.signatures_per_hour,
                  numShifts: isVR
                    ? location.stats.in_range.vr_shifts_count
                    : location.stats.in_range.petition_shifts_count,
                }))}
              onMarkerClick={setActiveLocation}
              onClusterClick={clearActiveLocation}
              useClusters={useClusters}
            />
          </Map>
        </div>
      </div>
    </div>
  )
}

export default () => (
  <QueryParamProvider>
    <PaginationProvider
      enablePageSize={false}
      defaultPageSize={50}
      enableQueryParams
    >
      <MapProvider>
        <LocationMapProvider>
          <LocationMap />
        </LocationMapProvider>
      </MapProvider>
    </PaginationProvider>
  </QueryParamProvider>
)
