import React from 'react'

export default props => (
  <svg
    {...props}
    width="30px"
    height="30px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.9s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(36 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(72 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.7s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(108 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(144 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(180 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(216 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.3s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(252 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.2s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(288 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.1s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(324 50 50)">
      <rect
        x="46.5"
        y="14"
        rx="19.065"
        ry="5.74"
        width="7"
        height="14"
        fill="#fff"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
  </svg>
)
