import { Font, Icon } from '@politechdev/blocks-design-system'
import {
  didPacketFlagCompletePhoneVerification,
  didPacketFlagCompleteVisualReview,
  isPacketFlag,
} from '../utils'
import styles from './ReviewListIconSet.module.scss'

const Result = ({ didPass }) => (didPass ? <Icon.Check /> : <Icon.X />)

const VisualReview = ({ flag }) => (
  <Font.Label variant="detail">
    <span>
      <Icon.Eye />
    </span>
    <span>
      <Result didPass={didPacketFlagCompleteVisualReview(flag)} />
    </span>
  </Font.Label>
)

const PhoneVerification = ({ flag }) => (
  <Font.Label variant="detail">
    <span>
      <Icon.Phone />
    </span>
    <span>
      <Result didPass={didPacketFlagCompletePhoneVerification(flag)} />
    </span>
  </Font.Label>
)

const Comments = ({ flag }) => (
  <Font.Label variant="detail">
    <span>
      <Icon.Comment />
    </span>
    <span>{flag.comments.length}</span>
  </Font.Label>
)

const ReviewListIconSet = ({ flag }) =>
  isPacketFlag(flag) ? (
    <div className={styles.container}>
      <VisualReview flag={flag} />
      <PhoneVerification flag={flag} />
      <Comments flag={flag} />
    </div>
  ) : (
    <div>
      <Comments flag={flag} />
    </div>
  )

export default ReviewListIconSet
