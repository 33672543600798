import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Sheet,
  ButtonBlock,
  Button,
  Icon,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import PhoneBankForm from '../PhoneBankForm/PhoneBankForm'
import { PhoneBankContext } from '../PhoneBankContext/PhoneBankContext'

const PhoneBankEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { phoneBankId } = match.params
  const {
    phoneBank: currentPhoneBank,
    getPhoneBank,
    phoneBankReqLoading,
    phoneBankReqHasErrors,
    phoneBankReqLoaded,
  } = useContext(PhoneBankContext)

  useEffect(() => {
    getPhoneBank(phoneBankId)
  }, [phoneBankId])

  const phoneBankLoaded =
    phoneBankReqLoaded &&
    !phoneBankReqHasErrors &&
    currentPhoneBank?.id === +phoneBankId

  return (
    <View>
      <ViewContainer loading={phoneBankReqLoading}>
        <Sheet>
          <PageHeader title={t('Edit phone bank')}>
            <ButtonBlock justify="right">
              <Link to={`/organize/phone_banks/${phoneBankId}`}>
                <Button.Secondary>
                  <Icon.Times alt="close" />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </PageHeader>
          <CardError
            hide={!phoneBankReqHasErrors}
            message={t("We're unable to retrieve this phone bank")}
          />
          {phoneBankLoaded && <PhoneBankForm phoneBank={currentPhoneBank} />}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default PhoneBankEdit
