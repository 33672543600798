import { Switch, Route, useRouteMatch } from 'react-router-dom'
import DeliverySelect from '../DeliverySelect/DeliverySelect'
import DeliverySingle from '../DeliverySingle/DeliverySingle'
import DeliveryCreate from '../DeliveryCreate/DeliveryCreate'
import DeliveryEdit from '../DeliveryEdit/DeliveryEdit'

const DeliveryRoutes = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={routeProps => (
          <DeliverySelect
            match={routeProps.match}
            history={routeProps.history}
          />
        )}
      />
      <Route exact path={`${match.path}/new`} component={DeliveryCreate} />
      <Route exact path={`${match.path}/:id`} component={DeliverySingle} />
      <Route exact path={`${match.path}/:id/edit`} component={DeliveryEdit} />
    </Switch>
  )
}

export default DeliveryRoutes
