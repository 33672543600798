import { useTranslation } from 'react-i18next'
import moment from 'moment'
import styles from './GalleryHeader.module.scss'

const GalleryHeader = ({ shift }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.header}>
      <div className={styles.details}>
        <ul>
          <li>
            <span className={styles.label}>{t('Canvasser')}</span>
            <span>{shift.canvasser.full_name}</span>
          </li>
          <li>
            <span className={styles.label}>{t('Shift Date')}</span>
            <span>{moment(shift.shift_start).format('MMM D, YYYY')}</span>
          </li>
        </ul>
      </div>
      <div className={styles.message}>
        <span className={styles.title}>{t('Things to look out for')}</span>
        <span className={styles.text}>
          <ol>
            <li>
              {t(
                'Technical issues that will require packet deletion (unredacted forms, redactions that are covering too much, blurry scans, etc.)'
              )}
            </li>
            <li>
              {t(
                'Trends across forms like handwriting that needs attention, missing phone numbers, etc'
              )}
            </li>
          </ol>
        </span>
      </div>
    </div>
  )
}

export default GalleryHeader
