import { createContext, useContext, useMemo, useState, useEffect } from 'react'
import { useReactRouter } from 'hooks'
import { getToken, setToken, removeToken, decodeToken } from 'utils/auth'

const getSession = (token = getToken()) => {
  let session = null

  if (token) {
    session = decodeToken(token)
  }
  return session
}

const AuthContext = createContext()

export const AuthProvider = props => {
  const { history, location } = useReactRouter()

  const [session, setSession] = useState(() => getSession())
  const [entryPath, setEntryPath] = useState(
    () =>
      location.pathname &&
      location.pathname !== '/' &&
      `${location.pathname}${location.search}`
  )

  const login = token => {
    setToken(token)
    setSession(getSession(token))
  }

  const logout = () => {
    removeToken()
    setSession(null)
  }

  const tokenValues = useMemo(() => {
    const locked = Boolean(session?.locked)
    const twoFactorRequired = Boolean(session?.needs_two_factor)
    const twoFactorEnabled = Boolean(session?.two_factor_enabled)
    const twoFactorPhone = session?.two_factor_phone

    const validToken = session?.token
    const isLoggedIn = validToken && !twoFactorRequired
    const isImpersonating = Boolean(session?.true_user)
    const trueUser = session?.true_user

    return {
      locked,
      twoFactorRequired,
      twoFactorEnabled,
      twoFactorPhone,
      validToken,
      isLoggedIn,
      isImpersonating,
      trueUser,
    }
  }, [session?.token])

  useEffect(() => {
    if (session?.exp && session.exp * 1000 < Date.now()) {
      logout()
    }
  }, [window.location.href, session?.exp])

  useEffect(() => {
    if (tokenValues.isLoggedIn && entryPath) {
      history.push(entryPath)
      setEntryPath(null)
    }
  }, [tokenValues.isLoggedIn, entryPath])

  return (
    <AuthContext.Provider
      value={{ login, logout, ...tokenValues }}
      {...props}
    />
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}
