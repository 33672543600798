import { createContext, useContext, useMemo, useState } from 'react'
import { setWith, cloneDeep, get } from 'lodash'

const FormContext = createContext()

export const useForm = () => {
  const context = useContext(FormContext)
  if (context) return context

  const [formData, setFormData] = useState({})
  const [fieldErrors, setFieldErrors] = useState({})

  const setField = (val, key) => {
    setFormData(prev => setWith(cloneDeep(prev), key, val))
  }

  const getField = key => get(formData, key)

  const areFieldsValid = useMemo(
    () => Object.values(fieldErrors).every(error => error === null),
    [fieldErrors]
  )

  const setFieldError = (field, message) =>
    setFieldErrors(prevFieldErrors => ({
      ...prevFieldErrors,
      [field]: message,
    }))

  return {
    formData,
    setFormData,
    setField,
    getField,
    setFieldError,
    fieldErrors,
    areFieldsValid,
  }
}

export const FormProvider = ({ children }) => {
  const formStateParams = useForm()

  return (
    <FormContext.Provider value={formStateParams}>
      {children}
    </FormContext.Provider>
  )
}
