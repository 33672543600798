import { Button } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import PhoneVerificationScriptItem from 'qualityControl/callScripts/PhoneVerificationScriptItem'
import { defaultLanguages } from 'constants/qcCallScriptsConfig/defaultScripts'
import {
  ENGLISH,
  PHONE,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import { useContext } from 'react'
import { CallScriptsContext } from 'qualityControl/callScripts/CallScriptsContext'
import { getCurrentSessionScript } from 'qualityControl/contexts/contextSelectors'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import { useScriptLanguage } from 'qualityControl/contexts/PhoneVerificationQuestionsContext'
import styles from './CallScript.module.scss'

const getActiveButton = isActive =>
  isActive ? Button.Accent : Button.Secondary

const CallScript = ({
  setHasEditedChanges,
  setContactedStatus,
  setVerificationResponses,
  verificationResponses,
  questions,
  phoneNumber,
}) => {
  const { t } = useTranslation()
  const {
    pendingScript: { language: currentLanguage },
    setPendingScript,
  } = useContext(CallScriptsContext)
  const [currentPacket] = useCurrentPacket()
  const [, setLanguage] = useScriptLanguage()
  const currentScript = getCurrentSessionScript(currentPacket, currentLanguage)

  const setScriptLanguage = lang => {
    setLanguage(lang)
    setPendingScript(oldScript => ({
      ...oldScript,
      language: lang,
    }))
  }

  const isActiveLanguage = lang =>
    currentLanguage === lang || (!currentLanguage && lang === ENGLISH)

  return (
    <div className={styles.callScript}>
      <div className={styles.header}>
        <h2>{t('Script')}</h2>
        <div className={styles.languageToggle}>
          {Object.values(defaultLanguages).map(language => {
            const ActiveButton = getActiveButton(isActiveLanguage(language))
            return (
              <ActiveButton
                key={language}
                onClick={() => setScriptLanguage(language)}
              >
                {t(language)}
              </ActiveButton>
            )
          })}
        </div>
      </div>
      <section className={styles.scriptItemWrapper}>
        {currentScript.map((item, i) =>
          item.questionKey !== PHONE ? (
            <PhoneVerificationScriptItem
              {...item}
              setHasEditedChanges={setHasEditedChanges}
              setContactedStatus={setContactedStatus}
              setVerificationResponses={setVerificationResponses}
              verificationResponses={verificationResponses}
              questions={questions}
              phoneNumber={phoneNumber}
              key={i}
            />
          ) : null
        )}
      </section>
    </div>
  )
}

export default CallScript
