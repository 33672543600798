import { useTranslation } from 'react-i18next'
import isEqual from 'react-fast-compare'
import { Sheet, Sticky, LoadBar } from 'components'
import {
  ButtonBlock,
  Button,
  Font,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useForm } from 'contexts'
import { useRequest } from 'hooks'
import { putList } from 'requests/lists'
import styles from './ListSaveBanner.module.scss'

const ListSaveBanner = ({ currentList, makeListRequest }) => {
  const { t } = useTranslation()
  const {
    formData: {
      requiredFilters,
      conditionalFilters,
      excludedFilters,
      hasRefreshRequest,
      requiredFiltersAreValid,
      conditionalFiltersAreValid,
      excludedFiltersAreValid,
    },
    setFormData,
    areFieldsValid,
    setField,
  } = useForm()

  const allFiltersAreValid =
    requiredFiltersAreValid !== false &&
    conditionalFiltersAreValid !== false &&
    excludedFiltersAreValid !== false

  const { makeRequest, isLoading, hasErrors } = useRequest(putList, {
    onSuccess: async () => {
      await makeListRequest()
      setField(false, 'hasRefreshRequest')
    },
  })

  const makeUpdateRequest = () => {
    if (!requiredFilters || !conditionalFilters || !excludedFilters) {
      return
    }
    makeRequest(
      currentList.id,
      {
        search_params: {
          requiredFilters,
          conditionalFilters,
          excludedFilters,
        },
      },
      currentList,
      true
    )
  }

  const revertFilters = () => {
    setFormData(currentList.search_params)
  }

  const hasChanged =
    !!requiredFilters &&
    !!conditionalFilters &&
    !!excludedFilters &&
    !isEqual(
      {
        requiredFilters,
        conditionalFilters,
        excludedFilters,
      },
      {
        requiredFilters: currentList.search_params.requiredFilters || {},
        conditionalFilters: currentList.search_params.conditionalFilters || {},
        excludedFilters: currentList.search_params.excludedFilters || {},
      }
    )

  if (!hasChanged && !hasRefreshRequest) return null

  return (
    <Sticky
      className={styles.shadow}
      stuckClassName={styles['shadow--stuck']}
      offset={60}
    >
      <div className={styles.container}>
        <Sheet className={styles.banner}>
          <LoadBar className={styles.loadbar} show={isLoading} />
          <ContentBlock className={styles.content}>
            {hasErrors ? (
              <Font.Copy Element="p">
                {t('An error occurred while saving your list.')}
              </Font.Copy>
            ) : (
              <Font.Copy variant="highlight" Element="p">
                {t('Your list has changed. Save updates?')}
              </Font.Copy>
            )}
            <ButtonBlock justify="right">
              <Button.Secondary
                disabled={isLoading || !areFieldsValid || !allFiltersAreValid}
                onClick={makeUpdateRequest}
              >
                {t('Save')}
              </Button.Secondary>
              <Button.Accent
                swapTheming
                disabled={isLoading}
                onClick={revertFilters}
              >
                {t('Revert')}
              </Button.Accent>
            </ButtonBlock>
          </ContentBlock>
        </Sheet>
      </div>
    </Sticky>
  )
}

export default ListSaveBanner
