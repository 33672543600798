import { useContext } from 'react'
import { AttachmentsBlock } from 'components'
import { postEventAttachment } from 'requests/events'
import { useRequest } from 'hooks/useRequest'
import { EventContext } from '../EventContext/EventContext'

const EventAttachments = () => {
  const { currentEvent, setCurrentEvent } = useContext(EventContext)
  const setDocuments = documents =>
    setCurrentEvent({ ...currentEvent, documents })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: addEventAttachment } = useRequest(postEventAttachment, {
    onSuccess: ({ document }) => {
      setDocuments(currentEvent.documents.concat([document]))
    },
  })

  return (
    <AttachmentsBlock
      existingFiles={currentEvent.documents}
      onUpload={fileData => addEventAttachment(currentEvent.id, fileData)}
    />
  )
}

export default EventAttachments
