import { isNonPrimaryPhone } from 'constants/people'
import { useAgentState } from 'powerDialer/AgentContext/AgentContext'
import { COMPLETE, READY } from 'constants/phoneBankSessions'
import {
  getNextCall,
  getSession,
  putCallStatus,
} from 'requests/phoneBankSessions'
import useEvent from 'hooks/useEvent'

const { createContext, useContext, useState } = require('react')

const CallSessionContext = createContext()

const CallSessionStandardProvider = ({ children }) => {
  const [currentSession, setCurrentSession] = useState({
    phone_bank: {},
  })
  const [currentCall, setCurrentCall] = useState({
    responses: [],
    invalid_numbers: [],
  })
  const [currentParticipant, setCurrentParticipant] = useState({
    contact_methods: [],
  })
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState()
  const [inReview, setInReview] = useState(false)
  const [callLoading, setCallLoading] = useState(false)
  const [callError, setCallError] = useState(false)
  const [callStep, setCallStep] = useState(READY)
  const [phoneBankComplete, setPhoneBankComplete] = useState(false)

  const updateRadioResponse = (questionId, response) => {
    const questionFound = currentCall.responses.find(
      r => r.question_id === questionId
    )

    if (questionFound) {
      setCurrentCall(oldCurrentCall => ({
        ...oldCurrentCall,
        responses: oldCurrentCall.responses.map(r =>
          r.question_id === questionId
            ? { question_id: questionId, answer_option_id: response }
            : r
        ),
      }))
    } else {
      setCurrentCall(oldCurrentCall => ({
        ...oldCurrentCall,
        responses: oldCurrentCall.responses.concat([
          {
            question_id: questionId,
            answer_option_id: response,
          },
        ]),
      }))
    }
  }

  const updateCheckboxResponse = (questionId, response) => {
    const oldResp = currentCall.responses.filter(
      r => r.question_id !== questionId
    )

    const newResp = response
      .filter(checkbox => checkbox.checked)
      .map(checkbox => ({
        question_id: questionId,
        answer_option_id: checkbox.value,
      }))

    setCurrentCall(oldCurrentCall => ({
      ...oldCurrentCall,
      responses: oldResp.concat(newResp),
    }))
  }

  const updateOpenEnded = (questionId, response) => {
    setCurrentCall(oldCurrentCall => ({
      ...oldCurrentCall,
      responses: [
        ...oldCurrentCall.responses.filter(
          resp => resp.question_id !== questionId
        ),
        {
          question_id: questionId,
          open_ended_answer_text: response,
        },
      ],
    }))
  }

  const clearCall = () => {
    setCurrentCall({
      participated: undefined,
      non_participation_reason: undefined,
      responses: [],
      invalid_numbers: [],
    })
    setCurrentParticipant({ contact_methods: [] })
  }

  const getConferenceName = useEvent(
    subdomain => `${subdomain}-${currentSession.id}-${currentCall.id}`
  )

  const updateCallStatus = useEvent(
    async (endpoint, params, { voipConferencesEnabled, subdomain }) => {
      const eventParams =
        endpoint === 'connect'
          ? {
              phone_number: selectedPhoneNumber,
              conference: voipConferencesEnabled
                ? getConferenceName(subdomain)
                : undefined,
            }
          : params

      await putCallStatus(
        endpoint,
        currentSession.phone_bank.id,
        currentCall.id,
        eventParams
      )
    }
  )

  const submitCall = async (submitAttrs, tenantOptions) => {
    setCallLoading(true)
    setCallError(false)
    try {
      if (submitAttrs.non_participation_reason) {
        submitAttrs.responses = []
      }
      await updateCallStatus('disconnect', submitAttrs, tenantOptions)
      setCallLoading(false)
      setCallError(false)
      setCallStep(COMPLETE)
    } catch (err) {
      setCallLoading(false)
      setCallError(err)
    }
  }

  const setParticipation = participated => {
    setCurrentCall(oldCurrentCall => ({
      ...oldCurrentCall,
      participated,
      non_participation_reason:
        participated === true
          ? undefined
          : oldCurrentCall.non_participation_reason,
    }))
  }

  const setNonParticipationReason = reason => {
    setCurrentCall(oldCurrentCall => ({
      ...oldCurrentCall,
      non_participation_reason: reason,
    }))
  }

  const receiveInvalidNumbers = invalid_numbers => {
    setCurrentCall(oldCurrentCall => ({
      ...oldCurrentCall,
      invalid_numbers,
    }))
  }

  const clearInvalidNumbers = () => {
    setCurrentCall(oldCurrentCall => ({
      ...oldCurrentCall,
      invalid_numbers: [],
    }))
  }

  const fetchNextCall = async (phoneBankId, mode, hasVoip) => {
    try {
      const json = await getNextCall(phoneBankId, mode, hasVoip)

      setCurrentCall({
        ...json['phone_banking/call'],
        responses: [],
        participated: null,
        non_participation_reason: null,
        invalid_numbers: [],
      })

      const participant = json['phone_banking/call'].person

      setCurrentParticipant(participant)
      const phoneNumbers = participant.contact_methods.filter(
        method => isNonPrimaryPhone(method) && !method.invalid
      )
      const altNumber = phoneNumbers.pop()
      setSelectedPhoneNumber(
        participant.primary_phone_number || altNumber.content
      )
    } catch (err) {
      const { error } = err.json
      if (
        error.person.includes('no more people are available to call currently')
      ) {
        setPhoneBankComplete(true)
      } else {
        throw error
      }
    }
  }

  return (
    <CallSessionContext.Provider
      value={{
        callId: currentCall.id,
        currentSession,
        setCurrentSession,
        currentCall,
        setCurrentCall,
        currentParticipant,
        setCurrentParticipant,
        selectedPhoneNumber,
        setSelectedPhoneNumber,
        inReview,
        setInReview,
        callLoading,
        setCallLoading,
        callError,
        setCallError,
        callStep,
        setCallStep,
        phoneBankComplete,
        setPhoneBankComplete,
        updateRadioResponse,
        updateCheckboxResponse,
        updateOpenEnded,
        clearCall,
        getConferenceName,
        updateCallStatus,
        submitCall,
        setParticipation,
        setNonParticipationReason,
        receiveInvalidNumbers,
        clearInvalidNumbers,
        fetchNextCall,
      }}
    >
      {children}
    </CallSessionContext.Provider>
  )
}

const CallSessionPowerDialerProvider = ({ children }) => {
  const { callId } = useAgentState()
  const [currentSession, setCurrentSession] = useState({
    phone_bank: {},
  })
  const fetchSession = (phoneBankId, sessionId) =>
    new Promise((resolve, reject) => {
      getSession(phoneBankId, sessionId)
        .then(({ 'phone_banking/session': session }) => {
          setCurrentSession(session)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })

  return (
    <CallSessionContext.Provider
      value={{
        callId,
        currentSession,
        fetchSession,
      }}
    >
      {children}
    </CallSessionContext.Provider>
  )
}

const CallSessionProvider = {
  Standard: CallSessionStandardProvider,
  PowerDialer: CallSessionPowerDialerProvider,
}

export const useCallSessionContext = () => useContext(CallSessionContext)

export default CallSessionProvider
