import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Radio,
  Checkbox,
  Tag,
  TextField,
} from '@politechdev/blocks-design-system'
import QuestionEditForm from '../../Questions/QuestionEditForm'
import InterpolatedContent from '../../InterpolatedContent/InterpolatedContent'
import styles from '../ScriptItem.module.scss'
import { useQuestionChoices } from '../useQuestionChoices'
import { useCallSessionContext } from '../../CallSessionContext/CallSessionContext'

const ActiveQuestion = ({
  type,
  content,
  choices,
  tag,
  itemId,
  setResponse,
  disabled,
}) => {
  const { t } = useTranslation()

  const { callId } = useCallSessionContext()

  const [selectedChoice, setSelectedChoice] = useState(null)
  const [openEndedText, setOpenEndedText] = useState('')
  const [choicesInState, setChoicesInState] = useQuestionChoices(choices)

  useEffect(() => {
    setSelectedChoice(null)
    setOpenEndedText('')
    setChoicesInState(prevChoicesInState =>
      type === 'multiple_choice'
        ? prevChoicesInState.map(choice => ({ ...choice, checked: false }))
        : prevChoicesInState
    )
  }, [callId])

  switch (type) {
    case 'single_choice':
      return (
        <div>
          <div className={styles['script-item__name-and-tag']}>
            <InterpolatedContent text={content} />
            <div>{tag && <Tag>{tag}</Tag>}</div>
          </div>
          {!!choicesInState.length && (
            <div className={styles['script-item__choices']}>
              <Radio.Group
                id={`single-choice-${itemId}`}
                name={`single-choice-${itemId}`}
                aria-label="Choices"
                value={selectedChoice}
                options={choicesInState}
                onChange={val => {
                  setResponse(itemId, +val, 'single_choice')
                  setSelectedChoice(+val)
                }}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      )

    case 'multiple_choice':
      return (
        <div className={styles['script-item__content']}>
          <div className={styles['script-item__name-and-tag']}>
            <InterpolatedContent text={content} />
            {tag && <Tag>{tag}</Tag>}
          </div>
          <div className={styles['script-item__choices']}>
            {choicesInState.map((choice, i) => (
              <Checkbox
                key={choice.label}
                id={`choice-${itemId}-${i}`}
                name={`choice-${itemId}-${i}`}
                label={choice.label}
                checked={choice.checked}
                value={choice.value}
                onChange={() => {
                  setChoicesInState(oldChoicesInState => {
                    const updatedChoices = oldChoicesInState.map(c =>
                      c.value === +choice.value
                        ? { ...c, checked: !c.checked }
                        : c
                    )
                    setResponse(itemId, updatedChoices, 'multiple_choice')
                    return updatedChoices
                  })
                }}
                disabled={disabled}
              />
            ))}
          </div>
        </div>
      )

    case 'open_ended':
      return (
        <div className={styles['script-item__content']}>
          <div className={styles['script-item__name-and-tag']}>
            <InterpolatedContent text={content} />
            {tag && <Tag>{tag}</Tag>}
          </div>
          <div className={styles['script-item__choices']}>
            <TextField
              id="response"
              label={t('Response')}
              value={openEndedText}
              onChange={val => {
                setOpenEndedText(val)
                setResponse(itemId, val, 'open_ended')
              }}
              disabled={disabled}
            />
          </div>
        </div>
      )

    default: {
      return null
    }
  }
}

const EditQuestion = ({
  type,
  tag,
  choices,
  content,
  setQuestionText,
  setQuestionType,
  setQuestionChoices,
  setQuestionTag,
}) => {
  const [choicesInState] = useQuestionChoices(choices)

  switch (type) {
    case 'single_choice':
      return (
        <QuestionEditForm
          type={type}
          choices={choicesInState}
          content={content}
          tag={tag}
          updateQuestionType={setQuestionType}
          updateQuestionText={setQuestionText}
          updateQuestionChoices={setQuestionChoices}
          updateQuestionTag={setQuestionTag}
        />
      )

    case 'multiple_choice':
      return (
        <QuestionEditForm
          type={type}
          choices={choicesInState}
          content={content}
          tag={tag}
          updateQuestionType={setQuestionType}
          updateQuestionText={setQuestionText}
          updateQuestionChoices={setQuestionChoices}
          updateQuestionTag={setQuestionTag}
        />
      )

    case 'open_ended':
      return (
        <QuestionEditForm
          type={type}
          choices={choicesInState}
          content={content}
          tag={tag}
          updateQuestionType={setQuestionType}
          updateQuestionText={setQuestionText}
          updateQuestionChoices={setQuestionChoices}
          updateQuestionTag={setQuestionTag}
        />
      )

    default: {
      return null
    }
  }
}

const Content = {
  Active: ActiveQuestion,
  Edit: EditQuestion,
}
export default Content
