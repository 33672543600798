import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, TeamSelectField } from 'components'
import {
  Button,
  Modal,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useAutoTable } from 'contexts'
import { useRequest, useRoutePathParams } from 'hooks'
import { putOrganization } from 'requests/organizations'
import { uniqBy } from 'lodash'
import { formatErrorMessage } from 'utils/formatting'
import styles from './AddOrganizationTeamModal.module.scss'

const AddOrganizationTeamModal = ({ existingTeams }) => {
  const { t } = useTranslation()
  const { refreshPage } = useAutoTable()
  const [{ id: currentOrganizationId }] = useRoutePathParams()

  const [modalOpen, setModalOpen] = useState(false)
  const [teams, setTeams] = useState([])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setTeams([])
  }

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(
    data => putOrganization(currentOrganizationId, data),
    {
      onSuccess: () => {
        closeModal()
        refreshPage()
      },
    }
  )
  const errorMsg = formatErrorMessage(errors)

  const addTeams = () => {
    makeRequest({
      team_ids: uniqBy([...existingTeams, ...teams], 'id').map(team => team.id),
    })
  }

  return (
    <>
      <Button.Accent onClick={openModal}>{t('Add teams')}</Button.Accent>
      <Modal
        id="add-teams"
        title={t(`Add teams`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError hide={!hasErrors} message={errorMsg} hideSupportLink />
            <div className={styles.field}>
              <TeamSelectField
                teams={teams}
                isMulti
                onSelect={setTeams}
                filters={[
                  {
                    column: 'organizations',
                    operator: 'is',
                    invert: 'true',
                    param: currentOrganizationId,
                  },
                ]}
                clearable
                usePortal
              />
            </div>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent disabled={!teams.length} onClick={addTeams}>
              {t('Save')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AddOrganizationTeamModal
