import React, { useState, useCallback } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [modalParams, setModalParams] = useState(null)

  const openModal = useCallback((id, params = {}) => {
    setModalParams({ [id]: params })
  }, [])

  const closeModal = useCallback(() => {
    setModalParams(null)
  }, [])

  return (
    <ModalContext.Provider value={{ modalParams, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModalTrigger = () =>
  useContextSelector(ModalContext, value => value.openModal)

export const useModal = id => {
  const params = useContextSelector(
    ModalContext,
    value => value.modalParams?.[id]
  )
  const closeModal = useContextSelector(ModalContext, value => value.closeModal)

  return [{ isOpen: !!params, closeModal }, params || {}]
}
