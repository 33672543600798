import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-md'
import { Icon } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'

import styles from './SelectPanel.module.scss'

const cx = classNames.bind(styles)

class SelectPanel extends Component {
  state = {
    expanded: !this.props.defaultClosed,
  }

  handleToggle() {
    this.props.onToggle && this.props.onToggle(!this.state.expanded)
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  renderHeader() {
    const { IconProp, title, Actions } = this.props
    const { expanded } = this.state

    return (
      <div className={styles.header}>
        <div
          className={cx('main', {
            'main--expanded': expanded,
          })}
          onClick={() => {
            this.handleToggle()
          }}
          tabIndex={0}
          role="button"
        >
          <IconProp className={styles.icon} />
          <h3 className={styles.title}>{title}</h3>
          {this.state.expanded ? <Icon.ChevronDown /> : <Icon.ChevronUp />}
        </div>
        <div className={styles.actions}>
          <Actions />
        </div>
      </div>
    )
  }

  render() {
    const { children } = this.props
    const { expanded } = this.state

    return (
      <Card>
        {this.renderHeader()}
        <div
          className={cx('body', {
            'body--closed': !expanded,
          })}
        >
          {children}
        </div>
      </Card>
    )
  }
}

SelectPanel.propTypes = {
  IconProp: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func,
  Actions: PropTypes.func,
}

SelectPanel.defaultProps = {
  onToggle: () => {},
  Actions: () => null,
  IconProp: () => null,
}

export default SelectPanel
