import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import styles from './ShiftCalendarItem.module.scss'

const cx = classNames.bind(styles)

const ShiftCalendarItem = ({
  start,
  startInRange,
  end,
  endInRange,
  type,
  id,
  location,
  canvasser,
}) => {
  let dateFormat = 'h:mma'
  if (!startInRange || !endInRange) {
    dateFormat = 'M/D'
  }

  const dateRange = [start.format(dateFormat), end.format(dateFormat)]
    .join(' – ')
    .replace(/:00/gi, '')

  return (
    <Link
      to={`/collect/${type}/shifts/${id}/edit`}
      className={cx(`${type}-item`)}
    >
      {dateRange} {location?.name} ({canvasser?.full_name})
    </Link>
  )
}

export default ShiftCalendarItem
