import React from 'react'
import PropTypes from 'prop-types'
import { User } from 'components/uiKit/icons'
import classNames from 'classnames/bind'
import styles from './Avatar.module.scss'

const cx = classNames.bind(styles)

const Avatar = ({
  className,
  onClick,
  name,
  subtitle,
  imageUrl,
  imageLarge,
}) => (
  <a // eslint-disable-line
    role="button"
    tabIndex="0"
    className={cx('avatar', className)}
    onClick={() => {
      onClick && onClick()
    }}
    onKeyPress={e => e.charCode === 13 && onClick()}
  >
    <div
      className={cx('image', {
        'image--large': imageLarge,
        'image--default': !imageUrl,
      })}
    >
      {imageUrl ? (
        <img src={imageUrl} alt={`${name} avatar`} />
      ) : (
        <User style={{ fontSize: '40px' }} />
      )}
    </div>
    <div>
      <div className={styles.name}>{name}</div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  </a>
)

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
}

export default Avatar
