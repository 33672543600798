import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Switch } from '@politechdev/blocks-design-system'
import { RoleAbilityContext } from '../RoleAbilityContextProvider/RoleAbilityContextProvider'
import styles from './RoleAbilitySwitch.module.scss'

const RoleAbilitySwitch = ({ ability, disabled = false }) => {
  const { t } = useTranslation()
  const { toggleAbility, isChecked } = useContext(RoleAbilityContext)

  const handleChange = () => {
    toggleAbility(ability)
  }

  return (
    <div className={styles.switch}>
      <Switch
        key={ability.id}
        id={ability.id}
        label={t(ability.label)}
        name={ability.label}
        disabled={disabled}
        onChange={handleChange}
        value={isChecked(ability.id)}
      />
    </div>
  )
}

export default RoleAbilitySwitch
