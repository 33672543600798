import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { MultiSearchSelect } from 'components'
import { useCurrent } from 'contexts/index'
import { buildLanguage, buildOption } from './utils'

const LanguageSelectFieldDEPRECATED = ({
  label,
  languages,
  onSelect,
  ...props
}) => {
  const { t } = useTranslation()

  const { currentTurfActivismLanguages: turfLanguages } = useCurrent()

  const [value, setValue] = useState(languages.map(buildOption))

  useEffect(() => {
    setValue(languages.map(buildOption))
  }, [languages])

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(buildLanguage))
  }

  return (
    <MultiSearchSelect
      label={label || t('Languages')}
      options={turfLanguages.map(language => ({
        label: language,
        value: language,
      }))}
      onChange={handleMultiChange}
      value={value || []}
      {...props}
    />
  )
}

LanguageSelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
}

LanguageSelectFieldDEPRECATED.defaultProps = {
  id: 'languages',
  languages: [],
}

export default LanguageSelectFieldDEPRECATED
