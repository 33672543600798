import { fetchJSON, stringifyParams } from 'utils/req'

const phoneBankQuestionTagParams = {
  fields: ['name'],
}

export const fetchPhoneBankQuestionTags = params => {
  const queryString = {
    ...phoneBankQuestionTagParams,
    ...params,
  }
  return fetchJSON(
    `/api/v1/phone_banking_questions/tags?${stringifyParams(queryString)}`,
    'GET',
    null,
    { useJwt: true }
  )
}
