import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from 'components'
import { useTranslation } from 'react-i18next'
import { maskZipcode } from 'utils/inputMasks'

const MIN_ZIP_LENGTH = 5
const MAX_ZIP_LENGTH = 10

const ZipCodeFieldDEPRECATED = ({
  value,
  error,
  errorText,
  onChange,
  ...props
}) => {
  const { t } = useTranslation()

  const fieldError =
    (value?.length &&
      ![MIN_ZIP_LENGTH, MAX_ZIP_LENGTH].includes(value.length)) ||
    error
  const fieldErrorText =
    value?.length && fieldError ? t('Invalid zip code') : errorText

  return (
    <TextField
      label={t('Zip code')}
      pattern="\d{5}(-\d{4})?"
      value={value}
      error={fieldError}
      errorText={fieldErrorText}
      onChange={val => {
        onChange(maskZipcode(val).substring(0, MAX_ZIP_LENGTH))
      }}
      {...props}
    />
  )
}

ZipCodeFieldDEPRECATED.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

ZipCodeFieldDEPRECATED.defaultProps = {
  error: false,
  errorText: '',
}

export default ZipCodeFieldDEPRECATED
