import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { Button, ButtonBlock, Icon } from '@politechdev/blocks-design-system'
import { patchEventAttended } from 'requests/events'
import { useDebounce } from 'use-debounce/lib'

import AddGuestModal from '../AddGuestModal/AddGuestModal'
import AddToCalendarModal from '../AddToCalendarModal/AddToCalendarModal'
import tableConfig from './tableConfig'
import styles from './GuestsTable.module.scss'
import { EventContext } from '../EventContext/EventContext'
import { useAttendees } from './guests'

const GuestsTable = () => {
  const { t } = useTranslation()
  const { renderTable, refreshPage, searchValue } = useAutoTable()
  const {
    currentEvent: { id: currentEventId, shifts, public_settings },
  } = useContext(EventContext)

  const hasEmailOrganizer = shifts.some(shift =>
    shift.volunteers.some(
      volunteer => volunteer.role?.name === 'Email Organizer'
    )
  )

  const [debouncedSearchValue] = useDebounce(searchValue, 300)

  const { attendees, fetchAttendees } = useAttendees(
    currentEventId,
    debouncedSearchValue
  )

  const markAllAttended = async () => {
    await patchEventAttended(currentEventId)
    refreshPage()
  }

  return (
    <>
      <div className={styles.header}>
        <ButtonBlock justify="left">
          <AddGuestModal
            onComplete={refreshPage}
            currentAttendees={attendees}
          />
          <Button.Secondary onClick={markAllAttended}>
            {t('Mark all guests as attended')}
          </Button.Secondary>
        </ButtonBlock>
      </div>
      {renderTable({
        data: attendees,
        totalRecords: attendees.length,
        fetchAction: fetchAttendees,
        columns: tableConfig.columns.toSpliced(
          -1,
          0,
          ...tableConfig.buildExtraFieldColumns(public_settings)
        ),
        headerActions: (
          <>
            <AddToCalendarModal />
            {hasEmailOrganizer && (
              <Button.Secondary
                aria-label={t('Email guest list')}
                href={`/engage/email_templates/new?event_id=${currentEventId}`}
              >
                <Icon.Envelope />
              </Button.Secondary>
            )}
          </>
        ),
      })}
    </>
  )
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enablePagination
    enableSort
    enableFilters
    showFilterEditor
    showQuickSearch
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    showDownloadButton
    defaultFilters={tableConfig.defaultFilters}
  >
    <GuestsTable />
  </AutoTableProvider>
)
