import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './TextBlock.module.scss'

const cx = classNames.bind(styles)

const TextBlock = props => (
  <div
    className={cx(
      'block',
      {
        'block--bg': props.hasBackground,
        'block--warn': props.warn,
        'block--muted': props.muted,
      },
      props.className
    )}
  >
    {props.children}
  </div>
)

TextBlock.propTypes = {
  children: PropTypes.node.isRequired,
  hasBackground: PropTypes.bool,
  warn: PropTypes.bool,
  muted: PropTypes.bool,
}

TextBlock.defaultProps = {
  hasBackground: false,
  warn: false,
  muted: false,
}

export default TextBlock
