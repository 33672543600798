/* eslint-disable react/jsx-no-target-blank */
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, Icon, Font } from '@politechdev/blocks-design-system'
import styles from './LinkToClipboard.module.scss'

const rPROTOCOL = /^((http|https):\/\/)/

const LinkToClipboard = ({ label, url, openInNewTab }) => {
  const { t } = useTranslation()
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)
  const showMessage = () => {
    setShowCopiedMessage(true)
    setTimeout(() => {
      setShowCopiedMessage(false)
    }, 3500)
  }

  const urlText = url.split('https://').join('').split('http://').join('')

  return (
    <div className={styles.container}>
      <div className={styles.link}>
        <Link
          to={{
            pathname: rPROTOCOL.test(url) ? url : `http://${url}`,
          }}
          target={openInNewTab === false ? '_self' : '_blank'}
          rel={openInNewTab === false ? undefined : 'noreferrer'}
        >
          {label || urlText}
        </Link>
      </div>
      <CopyToClipboard text={url} onCopy={showMessage}>
        <div className={styles.button}>
          <Button.Secondary tooltipLabel={t('Copy Link')}>
            <Icon.Link />
          </Button.Secondary>
          {showCopiedMessage ? (
            <Font.Copy variant="secondary" className={styles.message}>
              <Icon.CircleCheck /> <span>{t('Copied')}</span>
            </Font.Copy>
          ) : null}
        </div>
      </CopyToClipboard>
    </div>
  )
}

export default LinkToClipboard
