import {
  calculatePercent,
  combineName,
  formatActiveColumn,
  formatDecimalCell,
  formatQcStatus,
  summaryFunctions,
  combineJsonArrays,
} from 'utils/reporting'
import { YES_NO_OPTIONS } from 'registrationReports/constants'

export default {
  petitions_by_packet: {
    id: 'petitions_by_packet',
    name: 'Packet',
    dataDictionaryUrl:
      'https://docs.google.com/spreadsheets/d/1Br0BHxyRR2ZZ3Uzwqr-wi3fCKC6169NtDJR3Dbjo_D4/edit#gid=663985049',
    columns: [
      {
        dataKey: 'packet_name',
        title: 'Packet Name',
        type: 'string',
        resizable: true,
      },
      {
        dataKey: 'turf_name',
        title: 'Turf Name',
        type: 'string',
        resizable: true,
      },
      {
        dataKey: 'canvasser_name',
        title: 'Canvasser Name',
        type: 'string',
        resizable: true,
        deriveFunction: ({ rowData }) =>
          combineName(
            rowData.canvasser_first_name,
            rowData.canvasser_last_name
          ),
      },
      {
        dataKey: 'active_canvasser',
        title: 'Active Canvasser',
        type: 'enum',
        options: YES_NO_OPTIONS,
        resizable: true,
        deriveFunction: ({ rowData }) =>
          formatActiveColumn(rowData.canvasser_archived),
      },
      {
        dataKey: 'packet_county',
        title: 'Packet County',
        type: 'string',
        resizable: true,
      },
      {
        dataKey: 'assignee_name',
        title: 'Current Assignee Name',
        type: 'string',
        resizable: true,
        deriveFunction: ({ rowData }) =>
          combineName(rowData.assignee_first_name, rowData.assignee_last_name),
      },
      {
        dataKey: 'upload_date',
        title: 'Upload Date',
        type: 'date',
        resizable: true,
      },
      {
        dataKey: 'collection_date',
        title: 'Collection Date',
        type: 'date',
        resizable: true,
      },
      {
        dataKey: 'qc_status',
        title: 'QC Status',
        type: 'string',
        deriveFunction: ({ rowData }) => formatQcStatus(rowData.qc_status),
        resizable: true,
      },
      {
        dataKey: 'qc_completed_date',
        title: 'QC Completed Date',
        type: 'date',
        resizable: true,
      },
      {
        dataKey: 'reviewer_names',
        title: 'Reviewer Names',
        type: 'string',
        resizable: true,
        deriveFunction: ({ rowData }) =>
          combineJsonArrays(
            rowData.signature_reviewer_names,
            rowData.page_reviewer_names,
            'first_name',
            'last_name',
            ' '
          ),
      },
      {
        dataKey: 'total_signatures',
        title: 'Total Signatures',
        type: 'number',
        resizable: true,
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'valid_signatures',
        title: 'Valid Signatures',
        type: 'number',
        resizable: true,
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'valid_signature_percent',
        title: 'Valid Signature %',
        type: 'number',
        resizable: true,
        bodyCell: formatDecimalCell,
        deriveFunction: ({ rowData }) =>
          calculatePercent(rowData.valid_signatures, rowData.total_signatures),
        summaryFunction: ({ data, acc }) =>
          summaryFunctions.customPercent(data, acc, [
            'valid_signatures',
            'total_signatures',
          ]),
      },
    ],
  },
}
