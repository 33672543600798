import ReactIntercom from 'react-intercom'
import { useCurrent } from './contexts'

const Intercom = () => {
  const {
    currentUser: { id, email, name: userName, turf },
    currentTenant: { subdomain, name: tenantName },
  } = useCurrent()
  const user = {
    user_id: `${subdomain}-${id}`,
    email,
    name: userName,
    turf: turf?.name,
    company: {
      id: subdomain,
      name: tenantName,
    },
    hide_default_launcher: true,
    revision: process.env.VERSION,
  }

  if (process.env.NODE_ENV === 'development') return null

  return <ReactIntercom appID={process.env.INTERCOM_APP_ID} {...user} />
}

export default Intercom
