import { FormattedData } from 'components'
import { TextBlock, DetailTable, Font } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { formatDisplayName } from 'utils/formatting'
import { ACTIVITY_TYPE_OPTIONS } from './constants'

const ActivityLogTable = ({ log = [] }) => {
  const { t } = useTranslation()

  if (log.length === 0) {
    return (
      <TextBlock>
        <Font.Copy variant="secondary">{t('No activity logged')}</Font.Copy>
      </TextBlock>
    )
  }

  return (
    <DetailTable columnHeaders={[t('Contacted by'), t('Type'), t('Date')]}>
      {log.map((contact, i) => (
        <DetailTable.Row key={i}>
          <DetailTable.Cell>
            {contact.user
              ? formatDisplayName(contact.user)
              : t('[User deleted]')}
          </DetailTable.Cell>
          <DetailTable.Cell>
            {contact.contact_type &&
              ACTIVITY_TYPE_OPTIONS.find(
                type => type.value === contact.contact_type
              )?.label}
          </DetailTable.Cell>
          <DetailTable.Cell>
            <FormattedData type="date" value={contact.contacted_at} />
          </DetailTable.Cell>
        </DetailTable.Row>
      ))}
    </DetailTable>
  )
}

export default ActivityLogTable
