import { TEMPLATE_ITEM_OPTIONS } from 'email/template/TemplateContext/constants'

export const buildMarkupStyles = template_content => ({
  fontFamily: template_content.fontFamily,
  backgroundColor: template_content.colors.background,
  titleColor: template_content.colors[TEMPLATE_ITEM_OPTIONS.TITLE],
  headingColor: template_content.colors[TEMPLATE_ITEM_OPTIONS.HEADING],
  bodyCopyColor: template_content.colors[TEMPLATE_ITEM_OPTIONS.BODY_COPY],
  buttonBackgroundColor:
    template_content.colors[TEMPLATE_ITEM_OPTIONS.BUTTON_LINK],
  buttonColor: '#ffffff',
})
