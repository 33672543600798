import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactRouter } from 'hooks'
import { List, ListItem } from 'components'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { formatDisplayName } from 'utils/formatting'
import styles from './RoleDetailUsers.module.scss'
import { RoleContext } from '../RoleContext/RoleContext'

const RoleDetailUsers = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [showUsers, setShowUsers] = useState(false)

  const { currentRoleId, currentRoleUsers } = useContext(RoleContext)

  useEffect(() => {
    setShowUsers(false)
  }, [currentRoleId])

  return (
    <div>
      <div className={styles.toggle}>
        <p>
          {currentRoleUsers.length}{' '}
          {t(
            `${
              currentRoleUsers.length === 1 ? 'user shares' : 'users share'
            } this role`
          )}
        </p>
        {currentRoleUsers.length ? (
          <div className={styles.button}>
            <Button.Secondary
              iconBefore={false}
              onClick={() => setShowUsers(!showUsers)}
            >
              {showUsers ? (
                <>
                  <span>{t('Hide')}</span> <Icon.AngleUp />
                </>
              ) : (
                <>
                  <span>{t('Show')}</span> <Icon.AngleDown />
                </>
              )}
            </Button.Secondary>
          </div>
        ) : null}
      </div>
      {showUsers ? (
        <div className={styles.list}>
          <List>
            {currentRoleUsers.map(user => (
              <ListItem
                key={user.id}
                primaryText={formatDisplayName(user)}
                onClick={() => {
                  history.push(`/admin/users/${user.id}/edit`)
                }}
              />
            ))}
          </List>
        </div>
      ) : null}
    </div>
  )
}

export default RoleDetailUsers
