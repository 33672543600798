import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Section, LoadBar, CardError } from 'components'
import { ContentBlock } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { fetchLists, postCopy } from 'requests/lists'
import { formatListFolders, sortListFolders } from 'utils/lists'
import { defaultTo } from 'lodash'
import ListFolder from '../ListFolder/ListFolder'
import { ListCheckboxProvider } from '../ListCheckboxProvider/ListCheckboxProvider'

const ListFolders = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrent()
  const { makeRequest, isLoading, errors, hasErrors, response } = useRequest(
    () =>
      fetchLists({
        fields: [
          'id',
          'name',
          'meta',
          {
            folder: ['id', 'name', 'user_id', { sharees: ['id', 'name'] }],
          },
        ],
        per: Number.MAX_SAFE_INTEGER,
        include_shared: true,
      })
  )

  const listFolders = defaultTo(
    response?.lists.reduce(formatListFolders, []).sort(sortListFolders),
    []
  )

  const ownedListFolders = listFolders.filter(
    folder => folder.user_id === currentUser.id
  )
  const sharedListFolders = listFolders.filter(
    folder => folder.user_id !== currentUser.id
  )

  const copyLists = async lists => {
    const requests = lists.map(list => postCopy(list.id))

    await Promise.all(requests)
    await makeRequest()
  }

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    makeRequest()
  }, [])

  const showLists = !isLoading && !errorMsg

  return (
    <>
      <CardError hide={!hasErrors} message={errorMsg} />
      <Section
        label={t('My folders')}
        empty={!ownedListFolders.length && !isLoading}
        emptyMessage={t('No lists')}
      >
        <ContentBlock>
          <LoadBar show={isLoading} />
          {showLists &&
            ownedListFolders.map(folder => (
              <ListCheckboxProvider key={folder.id} lists={folder.lists}>
                <ListFolder
                  folder={folder}
                  numFolders={listFolders.length}
                  copyLists={copyLists}
                  getFolders={makeRequest}
                />
              </ListCheckboxProvider>
            ))}
        </ContentBlock>
      </Section>
      <Section
        label={t('Folders shared with me')}
        empty={!sharedListFolders.length && !isLoading}
        emptyMessage={t('No shared folders')}
      >
        <ContentBlock>
          <LoadBar show={isLoading} />
          {showLists &&
            sharedListFolders.map(folder => (
              <ListCheckboxProvider key={folder.id} lists={folder.lists}>
                <ListFolder
                  folder={folder}
                  numFolders={listFolders.length}
                  copyLists={copyLists}
                  getFolders={makeRequest}
                />
              </ListCheckboxProvider>
            ))}
        </ContentBlock>
      </Section>
    </>
  )
}

export default ListFolders
