import { curry } from 'lodash/function'

export const immutablySwapItems = curry((increment, index, array) => {
  const nextArray = [...array]
  nextArray[index] = array[index + increment]
  nextArray[index + increment] = array[index]
  return nextArray
})

export const immutablyRemoveItemByIndex = curry((index, array) => {
  const nextArray = [...array]
  nextArray.splice(index, 1)
  return nextArray
})

export const toArray = item => (Array.isArray(item) ? item : [item])

export const removeDuplicateOptions = options =>
  options.reduce(
    (options, option) =>
      options.find(({ value }) => value === option.value)
        ? options
        : [...options, option],
    []
  )

export const alphaNumericCompare = new Intl.Collator(undefined, {
  numeric: true,
}).compare
