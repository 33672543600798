import { Button, Icon, Font } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import styles from './FolderShareControls.module.scss'

const VISIBLE_ICONS = 3

const FolderShareControls = ({ folderSharees, openModal }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.actions}>
      {folderSharees.length > 0 && (
        <Font.Copy variant="hint">{t('Shared with')}</Font.Copy>
      )}
      <div className={styles.avatars}>
        {folderSharees.map((sharee, index) => {
          if (
            index < VISIBLE_ICONS - 1 ||
            (index === VISIBLE_ICONS - 1 &&
              folderSharees.length === VISIBLE_ICONS)
          ) {
            return (
              <Button.Secondary
                key={sharee.id}
                onClick={openModal}
                tooltipLabel={sharee.name}
                tooltipPosition="bottom"
              >
                <Icon.User />
              </Button.Secondary>
            )
          }
          if (index === VISIBLE_ICONS - 1) {
            return (
              <Button.Secondary
                icon
                primary
                key="others"
                onClick={openModal}
                tooltipLabel={`${folderSharees.length - index} others`}
                tooltipPosition="bottom"
              >
                <Icon.Users />
              </Button.Secondary>
            )
          }
          return null
        })}
      </div>
      <Button.Secondary onClick={openModal}>Share</Button.Secondary>
    </div>
  )
}

export default FolderShareControls
