import styles from '../ScriptItem.module.scss'
import ActionsSection from './ActionsSection'
import ContentSection from './ContentSection'
import ReorderActions from '../ReorderActions'

const ScriptSection = ({
  loading,
  isOnTop,
  isOnBottom,
  moveUp,
  moveDown,
  sectionNumber,
  removeSelf,
  isEditable = true,
}) => {
  const wrapperClass = `${styles['script-item__wrapper']} ${styles['script-item__section']}`

  return (
    <div className={wrapperClass}>
      <ContentSection sectionNumber={sectionNumber} />
      {isEditable && (
        <div className={styles['script-item__actions']}>
          <ActionsSection loading={loading} removeSelf={removeSelf} />
        </div>
      )}
      <div className={styles['script-item__reorder-arrows']}>
        <ReorderActions
          loading={loading}
          isOnTop={isOnTop}
          isOnBottom={isOnBottom}
          moveUp={moveUp}
          moveDown={moveDown}
        />
      </div>
    </div>
  )
}

export default ScriptSection
