import { useState } from 'react'

const useDeliverySingle = shouldUseVdrs => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [issueCounties, setIssueCounties] = useState([])

  const checkVdrs = (canvasser, locations, successFunc) => {
    if (!canvasser || !locations) {
      return true
    }

    const approvedCounties = canvasser.vdrs.map(({ county }) => county)
    const countiesNotApproved = locations.filter(
      location => !approvedCounties.includes(location)
    )

    setIssueCounties(countiesNotApproved)

    if (countiesNotApproved.length === 0) {
      successFunc()
    } else {
      setModalIsOpen(true)
    }
  }

  const handleAssemblyNext = (
    updateDeliveryStatus,
    match,
    selectedFormIds,
    canvasser,
    locations
  ) => {
    if (shouldUseVdrs) {
      checkVdrs(canvasser, locations, () =>
        updateDeliveryStatus(match.params.id, 'assemble', selectedFormIds)
      )
    } else {
      updateDeliveryStatus(match.params.id, 'assemble', selectedFormIds)
    }
  }

  return {
    modalIsOpen,
    setModalIsOpen,
    issueCounties,
    handleAssemblyNext,
  }
}

export default useDeliverySingle
