import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from 'components'
import { useTranslation } from 'react-i18next'
import { isValidEmail } from 'utils/inputValidations'

const EmailField = ({ id, value, error, errorText, ...props }) => {
  const { t } = useTranslation()

  const fieldError = (value?.length && !isValidEmail(value)) || error
  const fieldErrorText =
    value?.length && fieldError ? t('Must be a valid email') : errorText

  return (
    <TextField
      id={id || 'email'}
      type="email"
      label={t('Email')}
      value={value}
      error={fieldError}
      errorText={fieldErrorText}
      {...props}
    />
  )
}

EmailField.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default EmailField
