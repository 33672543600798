import React from 'react'
import FolderCreateModal from '../FolderCreateModal/FolderCreateModal'
import FolderRenameModal from '../FolderRenameModal/FolderRenameModal'
import FolderDeleteModal from '../FolderDeleteModal/FolderDeleteModal'
import DocumentRenameModal from '../DocumentRenameModal/DocumentRenameModal'
import DocumentDeleteModal from '../DocumentDeleteModal/DocumentDeleteModal'
import DocumentDetailModal from '../DocumentDetailModal/DocumentDetailModal'

const Modals = () => (
  <>
    <FolderCreateModal />
    <FolderRenameModal />
    <FolderDeleteModal />
    <DocumentRenameModal />
    <DocumentDeleteModal />
    <DocumentDetailModal />
  </>
)

export default Modals
