import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardError, LoadBar } from 'components'
import {
  Button,
  ButtonBlock,
  useToast,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { postEmailTemplate, putEmailTemplate } from 'requests/email'
import { formatErrorMessage } from 'utils/formatting'
import { TemplateContext } from '../TemplateContext/TemplateContext'

const TemplateControls = () => {
  const { t } = useTranslation()
  const { setToast } = useToast()
  const { templateName, content, colors, fontFamily, themeName } =
    useContext(TemplateContext)
  const { match, history } = useReactRouter()

  const handleSaveSuccess = () => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      history.push('/email/manage')
    }, 2000)
  }

  const postEmailTemplateRequest = useRequest(postEmailTemplate, {
    onSuccess: () => {
      setToast({
        message: t('Draft created'),
        variant: 'success',
      })
      handleSaveSuccess()
    },
  })

  const putEmailTemplateRequest = useRequest(putEmailTemplate, {
    onSuccess: () => {
      setToast({
        message: t('Draft updated'),
        variant: 'success',
      })
      handleSaveSuccess()
    },
  })

  const template = { content, colors, fontFamily, themeName }
  return (
    <ButtonBlock justify="right">
      <CardError
        hide={
          !putEmailTemplateRequest.hasErrors &&
          !postEmailTemplateRequest.hasErrors
        }
        message={formatErrorMessage(
          putEmailTemplateRequest.errors || postEmailTemplateRequest.errors
        )}
      />
      <LoadBar
        show={
          putEmailTemplateRequest.isLoading ||
          postEmailTemplateRequest.isLoading
        }
      />
      <Link to="/email/manage">
        <Button.Secondary>{t('Cancel')}</Button.Secondary>
      </Link>
      <Button.Accent
        disabled={!templateName}
        onClick={() => {
          match.params?.id
            ? putEmailTemplateRequest.makeRequest(
                match.params.id,
                templateName,
                template
              )
            : postEmailTemplateRequest.makeRequest(templateName, template)
        }}
      >
        {t('Save')}
      </Button.Accent>
    </ButtonBlock>
  )
}

export default TemplateControls
