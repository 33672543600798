import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { CardError, LoadBar } from 'components'
import { useRequest } from 'hooks/useRequest'
import { useTranslation } from 'react-i18next'
import { useAutoTable } from 'contexts/index'
import { defaultTo } from 'lodash'
import { formatErrorMessage } from 'utils/formatting'
import { usePacketAssignment } from '../../PacketAssignmentContext'
import { useAssignedCheckboxes } from '../CheckboxContextProvider'

const ReassignmentButtons = ({ user, refreshAssignedPackets }) => {
  const { t } = useTranslation()

  const { refreshPage } = useAutoTable()

  const {
    setIsReassignmentModalOpen,
    setPacketsBeingAssigned,
    unassignPackets,
  } = usePacketAssignment()

  const { getCheckedCheckboxValues, checkAll, areAllChecked, uncheckAll } =
    useAssignedCheckboxes()

  const { assigned_packets } = defaultTo(user, {
    assigned_packets: [],
  })

  const { makeRequest, isLoading, errors } = useRequest(
    packetsToUnassign => unassignPackets(packetsToUnassign),
    {
      onSuccess: async () => {
        uncheckAll()
        refreshPage()
        refreshAssignedPackets()
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  const areNoPacketsSelected = getCheckedCheckboxValues().length === 0
  const handleSelection = areAllChecked() ? uncheckAll : checkAll
  const selectButtonText = areAllChecked() ? t('Clear all') : t('Select all')
  const userHasNoPackets = assigned_packets.length === 0

  return (
    <div>
      <CardError hide={!errorMsg} message={errorMsg} />
      <LoadBar show={isLoading} />
      <ButtonBlock>
        <Button onClick={handleSelection} disabled={userHasNoPackets}>
          {selectButtonText}
        </Button>
        <Button.Secondary
          disabled={areNoPacketsSelected || userHasNoPackets}
          onClick={() => {
            setPacketsBeingAssigned(getCheckedCheckboxValues())
            setIsReassignmentModalOpen(true)
          }}
        >
          {t('Reassign')}
        </Button.Secondary>
        <Button.Secondary
          disabled={areNoPacketsSelected}
          onClick={() => {
            makeRequest(getCheckedCheckboxValues())
          }}
        >
          {t('Unassign')}
        </Button.Secondary>
      </ButtonBlock>
    </div>
  )
}

export default ReassignmentButtons
