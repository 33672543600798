import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchTeams = params =>
  fetchJSON(`/api/v1/teams?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchTeam = (id, params = {}) =>
  fetchJSON(`/api/v1/teams/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postTeam = (team, params) =>
  fetchJSON(
    `/api/v1/teams?${stringifyParams(params)}`,
    'POST',
    { team },
    { useJwt: true }
  )

export const putTeam = (id, team, params) =>
  fetchJSON(
    `/api/v1/teams/${id}?${stringifyParams(params)}`,
    'PUT',
    { team },
    {
      useJwt: true,
    }
  )

export const destroyTeam = team =>
  fetchJSON(`/api/v1/teams/${team.id}`, 'DELETE', null, {
    useJwt: true,
  })

export const fetchTeamMemberships = (id, params) =>
  fetchJSON(
    `/api/v1/teams/${id}/team_memberships?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const postTeamMembership = (id, params) =>
  fetchJSON(
    `/api/v1/teams/${id}/team_memberships`,
    'POST',
    { team_membership: params },
    { useJwt: true }
  )

export const destroyTeamMembership = (teamId, id) =>
  fetchJSON(`/api/v1/teams/${teamId}/team_memberships/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const textTeam = (teamId, textContent) =>
  fetchJSON(
    `/api/v1/teams/${teamId}/sms`,
    'POST',
    { message: textContent },
    { useJwt: true }
  )

export const inviteTeam = (teamId, eventId) => () =>
  fetchJSON(`/api/v1/teams/${teamId}/invite/${eventId}`, 'POST', null, {
    useJwt: true,
  })
