import { useContext, useEffect } from 'react'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useBackgroundRequest } from 'hooks'
import { Sheet } from '@politechdev/blocks-design-system'
import columnConfig from './columnConfigs/TurfStatsColumnConfig'
import { WeeklyReportContext } from '../WeeklyReportContext'

const CHART_TYPE = 'turf_stats'

const TurfStats = () => {
  const { renderTable } = useAutoTable()

  const { chartMap, filters, requestChartId, requestChart } =
    useContext(WeeklyReportContext)

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const { makeRequest, isLoading, errorMsg } = useBackgroundRequest({
    key: CHART_TYPE,
    requestId: type =>
      requestChartId(type, turfId, currentTurfPerformsExternalQC),
    requestData: requestChart,
  })

  useEffect(() => {
    if (filters.turf_id) {
      makeRequest()
    }
  }, [filters])

  const chartData = chartMap[CHART_TYPE]?.data

  return (
    <Sheet>
      <div className="panel__header">Stats and Flags by Turf</div>
      <div className="panel__body">
        {renderTable({
          data: chartData ? chartData.rows : undefined,
          sqlQuery: chartData ? chartData.sql_query : '',
          columns: columnConfig,
          fetchAction: makeRequest,
          loading: isLoading,
          error: errorMsg,
          rowKey: 'index',
          emptyHeight: 200,
          emptyMessage: 'No Turfs Found',
        })}
      </div>
    </Sheet>
  )
}

export default () => (
  <AutoTableProvider
    initialTableId="turf_stats"
    showDownloadButton
    isFrontend
    showTotals
  >
    <TurfStats />
  </AutoTableProvider>
)
