import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import {
  SelectField,
  TextField,
  FieldBlock,
  ButtonBlock,
  Button,
  Section,
  Icon,
} from '@politechdev/blocks-design-system'
import { CardError } from 'components'
import { useRequest } from 'hooks'
import { uniqBy } from 'lodash'
import { PARTICIPANT_KEYS } from '../InterpolatedContent/utils'
import { fetchPhoneBankQuestionTags } from './utils'

const TagInterpolateBlock = ({
  currentText,
  currentTag,
  updateText,
  updateTag,
  selectionStartId,
  tagAllowed,
}) => {
  const [internalTag, setInternalTag] = useState(currentTag)
  const [tagSearch, setTagSearch] = useState()
  const [debouncedTag] = useDebounce(tagSearch, 300)
  const [isAddingTag, setIsAddingTag] = useState(false)
  const [existingTag, setExistingTag] = useState(false)
  const [existingTagOptions, setExistingTagOptions] = useState([])
  const [isAddingInterpolation, setIsAddingInterpolation] = useState(false)
  const { t } = useTranslation()

  const { makeRequest, isLoading, hasErrors } = useRequest(
    fetchPhoneBankQuestionTags,
    {
      onSuccess: res => {
        const { tags } = res
        const tagOptions = uniqBy(
          tags
            .filter(tag => tag.name.length > 0)
            .map(tag => ({
              label: tag.name,
              value: tag.name,
            })),
          item => item.label
        )
        setExistingTagOptions([
          ...tagOptions,
          ...(internalTag ? [{ label: internalTag, value: internalTag }] : []),
        ])
      },
    }
  )

  useEffect(() => {
    makeRequest({
      filters: {
        rules: [
          debouncedTag
            ? {
                rules: [
                  {
                    column: 'name',
                    operator: 'containing',
                    param: debouncedTag,
                  },
                ],
              }
            : undefined,
        ],
      },
    })
  }, [debouncedTag])

  const interpolationOptions = Object.keys(PARTICIPANT_KEYS).map(key => {
    const value = PARTICIPANT_KEYS[key]
    return {
      label: value.slice(1, value.length - 1),
      value,
    }
  })

  const insertInterpolation = value => {
    const input = document.querySelector(`#${selectionStartId}`)
    const nextText = currentText.split('')
    nextText.splice(input?.selectionStart, 0, `${value}`)
    updateText(nextText.join(''))
    setIsAddingInterpolation(false)
    input.focus()
  }

  if (isAddingTag) {
    const invalidTag = !internalTag
    return (
      <Section>
        <CardError
          hide={!hasErrors}
          message={t('An error occurred while loading existing tags')}
        />
        <ButtonBlock>
          <Button.Secondary
            onClick={() => {
              setExistingTag(!existingTag)
              setInternalTag('')
            }}
          >
            {existingTag ? t('Add new tag') : t('Add existing tag')}
          </Button.Secondary>
        </ButtonBlock>
        <FieldBlock>
          {existingTag ? (
            <SelectField
              id="tag-name"
              label={t('Tag name')}
              loading={isLoading}
              error={hasErrors}
              placeholder={t('Search tag name')}
              options={existingTagOptions}
              value={internalTag}
              onSelect={setInternalTag}
              onInputChange={val => {
                setTagSearch(val)
                val && setInternalTag(null)
              }}
            />
          ) : (
            <TextField
              id="tag-name"
              label={t('Tag name')}
              placeholder={t('Enter tag name')}
              error={invalidTag}
              errorMessage={t('Invalid tag')}
              value={internalTag}
              onChange={val => setInternalTag(val.toLowerCase())}
            />
          )}
        </FieldBlock>
        <ButtonBlock>
          <Button.Primary
            disabled={invalidTag}
            onClick={() => {
              updateTag(internalTag)
              setIsAddingTag(false)
            }}
          >
            {t('Save tag')}
          </Button.Primary>
          <Button.Secondary
            onClick={() => {
              updateTag(undefined)
              setIsAddingTag(false)
            }}
          >
            {t('Remove tag')}
          </Button.Secondary>
          <Button.Secondary onClick={() => setIsAddingTag(false)}>
            {t('Cancel')}
          </Button.Secondary>
        </ButtonBlock>
      </Section>
    )
  }
  if (isAddingInterpolation) {
    return (
      <FieldBlock>
        <SelectField
          label={t('Interpolation options')}
          onSelect={insertInterpolation}
          options={interpolationOptions}
        />
      </FieldBlock>
    )
  }

  return (
    <Section>
      <ButtonBlock>
        <Button onClick={() => setIsAddingTag(true)} disabled={!tagAllowed}>
          <Icon.Tag />
          <span>{currentTag ? t('Edit tag') : t('Add tag')}</span>
        </Button>
        <Button onClick={() => setIsAddingInterpolation(true)}>
          <Icon.Brackets />
          <span>{t('Add interpolated value')}</span>
        </Button>
      </ButtonBlock>
    </Section>
  )
}

export default TagInterpolateBlock
