import React, { useState, createContext, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'contexts'
import AuthFrame from '../AuthFrame/AuthFrame'
import SetupTwoFactorIntro from '../SetupTwoFactorIntro/SetupTwoFactorIntro'
import SetupTwoFactorPhone from '../SetupTwoFactorPhone/SetupTwoFactorPhone'
import SetupTwoFactorVerify from '../SetupTwoFactorVerify/SetupTwoFactorVerify'
import SetupTwoFactorSuccess from '../SetupTwoFactorSuccess/SetupTwoFactorSuccess'

const SetupTwoFactorContext = createContext()

const SetupTwoFactor = () => {
  const { t } = useTranslation()

  const { validToken } = useAuth()

  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')

  const [token, setToken] = useState(null)
  const [activePage, setActivePage] = useState(1)

  const nextPage = () => setActivePage(page => page + 1)
  const prevPage = () => setActivePage(page => page - 1)

  if (!validToken) return <Redirect to="/auth/login" />

  return (
    <AuthFrame>
      <AuthFrame.Title>{t('Set up two-factor authentication')}</AuthFrame.Title>
      <SetupTwoFactorContext.Provider
        value={{
          phone,
          setPhone,
          code,
          setCode,
          token,
          setToken,
          activePage,
          nextPage,
          prevPage,
        }}
      >
        {activePage === 1 && <SetupTwoFactorIntro />}
        {activePage === 2 && <SetupTwoFactorPhone />}
        {activePage === 3 && <SetupTwoFactorVerify />}
        {activePage === 4 && <SetupTwoFactorSuccess />}
      </SetupTwoFactorContext.Provider>
    </AuthFrame>
  )
}

export default SetupTwoFactor

export const useTwoFactorSetup = () => useContext(SetupTwoFactorContext)
