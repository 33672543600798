import { formatPhoneNumber } from 'utils/formatting'

export const organizationAddressCsvCell = data =>
  [
    data.address?.line_one,
    data.address?.city,
    data.address?.state,
    data.address?.zipcode,
  ].join(' ')

export const organizationPhoneNumberCsvCell = data =>
  formatPhoneNumber(data.phone_number)

export const organizationLeaderNameCsvCell = data => data.leader?.name
