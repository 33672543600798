export const maskPhone = val => {
  if (typeof val !== 'string') return undefined

  const numbers = val.replace(/\D/g, '').split('')
  const chars = []
  if (numbers.length > 0) {
    chars.push('(', ...numbers.slice(0, 3))
    if (numbers.length > 3) {
      chars.push(') ', ...numbers.slice(3, 6))
      if (numbers.length > 6) {
        chars.push('-', ...numbers.slice(6, 10))
        if (numbers.length > 10) {
          chars.push(...numbers.slice(10))
        }
      }
    }
  }

  return chars.join('')
}

export const unmaskPhone = val => {
  if (!val) return ''
  return val.replace(/\D/g, '')
}

export const maskZipcode = val => {
  const numbers = val.replace(/\D/g, '').split('')
  const chars = []
  if (numbers.length > 0) {
    chars.push(...numbers.slice(0, 5))
    if (numbers.length > 5) {
      chars.push('-', ...numbers.slice(5, 9))
    }
  }
  return chars.join('')
}
