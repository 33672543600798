import styles from './AudioProgress.module.scss'

const AudioProgress = ({ value, max, onChange }) => {
  const progress =
    !Number.isNaN(max) && !Number.isNaN(value)
      ? Math.round((value / max) * 100)
      : 0

  const setTrackPosition = event => {
    const { clientX: clickX, target } = event
    const { width, x: sliderX } = target.getBoundingClientRect()
    const clickPosition = ((clickX - sliderX) / width) * max

    onChange(clickPosition)
  }

  return (
    <div
      role="slider"
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={value}
      aria-label="recording-duration"
      tabIndex="0"
      className={styles.track}
      onClick={setTrackPosition}
    >
      <div className={styles.progress} style={{ width: `${progress}%` }} />
    </div>
  )
}

export default AudioProgress
