import qs from 'qs'

const authId = process.env.SMARTY_STREETS_CLIENT_KEY

export const acceptablePrecisionValues = ['Rooftop', 'Parcel', 'Zip9']

export const fetchAddressOptions = ({ label, fields, match = 'strict' }) =>
  new Promise((resolve, reject) => {
    fetch(
      `https://us-street.api.smartystreets.com/street-address?&auth-id=${authId}&match=${match}&${qs.stringify(
        fields
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Host: 'us-street.api.smartystreets.com',
          'Content-Type': 'application/json',
        },
      }
    )
      .then(
        res =>
          res.ok
            ? res.json()
            : reject({ message: 'Fetch address error', label }) // eslint-disable-line prefer-promise-reject-errors
      )
      .then(json => resolve({ label, candidates: json }))
      .catch(err => ({ err, message: 'Fetch address error', label }))
  })

export const fetchAddressSuggestions = ({ label, fields }) =>
  new Promise((resolve, reject) => {
    fetch(
      `https://us-autocomplete-pro.api.smartystreets.com/lookup?&key=${authId}&${qs.stringify(
        fields
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then(
        res =>
          res.ok
            ? res.json()
            : reject({ message: 'Fetch address error', label }) // eslint-disable-line prefer-promise-reject-errors
      )
      .then(json => resolve(json?.suggestions || []))
      .catch(err => ({ err, message: 'Fetch address error', label }))
  })

const validateAddresses = addresses =>
  Promise.all(addresses.map(address => fetchAddressOptions(address)))

export default validateAddresses
