export const peopleNameCsvCell = data => data.name

export const peoplePhoneNumberCsvCell = data =>
  data.contact_methods
    .filter(method => method.contact_type.includes('phone_number'))
    .map(number => number.content)
    .concat([data.primary_phone_number])
    .join(', ')

export const peopleAddressCsvCell = data =>
  [data.residential_address?.line_one, data.residential_address?.line_two].join(
    ' '
  )

export const peopleCityCsvCell = data => data.residential_address?.city

export const peopleStateCsvCell = data => data.residential_address?.state

export const peopleZipCodeCsvCell = data => data.residential_address?.zipcode

export const peopleOrganizationCsvCell = data =>
  data.organizations.map(organization => organization.name).join(', ')

export const peopleEmailCsvCell = data =>
  data.contact_methods
    .filter(method => method.contact_type.includes('email_address'))
    .map(address => address.content)
    .concat([data.primary_email_address])
    .join(', ')
