import { Section, FormattedData, LoadBar } from 'components'
import { READY, CONNECTING } from 'constants/phoneBankSessions'
import PropTypes from 'prop-types'
import { useReactRouter } from 'hooks'
import { useTranslation } from 'react-i18next'
import styles from './CallPanelInfo.module.scss'

const CallPanelInfo = ({ callStep, phoneNumber }) => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  if (callStep === READY) {
    return (
      <div className={styles.content}>
        <ol className={styles.instructions}>
          <li>
            {t(
              'Read through the script on your own and familiarize yourself with it.'
            )}
          </li>
          <li>
            {t(
              'Review the participant information and start the call when you’re ready.'
            )}
          </li>
          <li>
            {match.params.mode === 'external'
              ? t(
                  'Click the “start call” button when you’re ready to call the number using your own phone.'
                )
              : t(
                  'Click the “start call” button to call the registrant from your browser. Make sure to have a microphone plugged into your browsing device'
                )}
          </li>
        </ol>
      </div>
    )
  }
  if (callStep === CONNECTING) {
    return (
      <div className={styles.content}>
        <LoadBar className={styles.loadbar} show />
      </div>
    )
  }
  return (
    <div className={styles.content}>
      <Section>
        <span className={styles.label}>Phone number</span>
        <p>
          <FormattedData value={phoneNumber} type="phone" />
        </p>
      </Section>
    </div>
  )
}

export default CallPanelInfo

CallPanelInfo.propTypes = {
  callStep: PropTypes.string,
  phoneNumber: PropTypes.string,
}
