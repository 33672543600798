import {
  buildDefaultFilters,
  combineName,
  formatActiveColumn,
  summaryFunctions,
} from 'utils/reporting'
import { YES_NO_OPTIONS } from '../../constants'
import {
  basePhoneQcColumns,
  baseVisualQcColumns,
} from '../columns/baseProgramColumns'

export default {
  id: 'qc_by_canvasser',
  name: 'Canvasser',
  description:
    'This report provides information on individual QC flags from the Visual and Phone Verification QC process for canvassers. The information is available as raw totals or as percentages.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=393845982',
  columns: [
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    {
      dataKey: 'active_canvasser',
      title: 'Active Canvasser',
      type: 'enum',
      options: YES_NO_OPTIONS,
      resizable: true,
      deriveFunction: ({ rowData }) =>
        formatActiveColumn(rowData.canvasser_archived),
    },
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'total_scans',
      title: 'Total Scans',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_pledge_cards',
      title: 'Total Pledge Cards',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_scans_qc',
      title: 'Total Forms Through QC',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    ...baseVisualQcColumns,
    {
      dataKey: 'scans_with_phones_qc',
      title: 'Forms with Phones (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.total_scans_qc - rowData.missing_phone_qc,
      summaryFunction: summaryFunctions.sum,
    },
    ...basePhoneQcColumns,
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Default Filter',
        visibleColumns: [
          'canvasser_name',
          'active_canvasser',
          'turf_name',
          'total_scans_qc',
          'complete_scans_qc',
          'missing_phone_qc',
          'handwriting_qc',
          'canvasser_assistance_qc',
        ],
      },
      {
        title: 'Visual QC Raw',
        visibleColumns: [
          'canvasser_name',
          'active_canvasser',
          'turf_name',
          'total_scans',
          'total_scans_qc',
          'approved_qc',
          'missing_phone_qc',
          'reupload_qc',
          'handwriting_qc',
          'canvasser_assistance_qc',
        ],
      },
      {
        title: 'Visual QC Percents',
        visibleColumns: [
          'canvasser_name',
          'active_canvasser',
          'turf_name',
          'total_scans',
          'total_scans_qc',
          'percent_approved_qc',
          'percent_missing_phone_qc',
          'percent_reupload_qc',
          'percent_handwriting_qc',
          'percent_canvasser_assistance_qc',
        ],
      },
      {
        title: 'Phone Ver. Raw',
        visibleColumns: [
          'canvasser_name',
          'active_canvasser',
          'turf_name',
          'scans_with_phones_qc',
          'total_scans_called_qc',
          'contacted_qc',
          'confirmed_registered_qc',
          'did_not_register_qc',
          'incorrect_address_qc',
          'incorrect_dob_qc',
          'received_feedback_qc',
          'duplicate_scans_qc',
          'voicemail_matches_applicant_qc',
        ],
      },
      {
        title: 'Phone Ver. Percents',
        visibleColumns: [
          'canvasser_name',
          'active_canvasser',
          'turf_name',
          'scans_with_phones_qc',
          'percent_scans_called_qc',
          'percent_contacted_qc',
          'percent_confirmed_registered_qc',
          'percent_did_not_register_qc',
          'percent_correct_address_qc',
          'percent_correct_dob_qc',
          'percent_received_feedback_qc',
          'percent_voicemail_matches_applicant_qc',
        ],
      },
    ])
  },
}
