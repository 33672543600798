import { fetchJSON } from 'utils/req'

const buildChartParams = (chartType, params) => ({
  report: { ...params, type: chartType },
})

export const createChartId = (chartType, params = {}) =>
  fetchJSON(
    `/api/v1/reports/voter_registration`,
    'POST',
    buildChartParams(chartType, params),
    {
      useJwt: true,
    }
  )

export const fetchChartData = id =>
  fetchJSON(
    `/api/v1/reports/voter_registration/${id}`,
    'GET',
    null,
    {
      useJwt: true,
    },
    {
      showStatusCode: true,
    }
  )
