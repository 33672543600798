import { isEmpty } from 'lodash'
import moment from 'moment'
import { dateFormat, dateTimeFormat, timeFormat } from './constants'

export function formatPhoneNumber(s) {
  const s2 = `${s}`.replace(/\D/g, '')
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : `(${m[1]}) ${m[2]}-${m[3]}`
}

export function formatNumberAsString(
  number,
  decimalPlaces = 0,
  locale = 'en-US'
) {
  if (typeof number === 'undefined') return number

  return number.toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
  })
}

export const formatLegacyErrorMsg = async errorOrPromise => {
  const { error } = await Promise.resolve(errorOrPromise)

  let errorMessage = null

  if (error && Object.entries(error).length > 0) {
    const { 0: firstError } = Object.entries(error)
    const [errorField, errorMessages] = firstError
    const errorText = errorMessages.join(', ')

    errorMessage = `${errorField} ${errorText || 'encountered an error'}`
  } else if (typeof error === 'string') {
    errorMessage = error
  }

  return (
    errorMessage ||
    'An error occurred while trying to process your request. Please try again or contact Blocks support.'
  )
}

export const formatApiError = async errorResponse => {
  try {
    let errors = {}
    const response = await errorResponse.json

    if (response?.error && errorResponse.status < 500) {
      errors =
        typeof response.error === 'string'
          ? { error: [response.error] }
          : response.error
    } else if (errorResponse.status === 504) {
      errors = { error: ['Request timeout'] }
    } else {
      errors = { error: ['An unknown error occurred'] }
    }

    const result = Object.keys(errors).reduce((acc, field) => {
      acc[field] = Array.isArray(errors[field])
        ? errors[field].join(', ')
        : errors[field]
      return acc
    }, {})

    return result
  } catch (e) {
    return {}
  }
}

export const formatErrorMessage = errors => {
  if (isEmpty(errors)) return ''

  if (typeof errors === 'string') return errors

  return Object.entries(errors).reduce((formattedMessage, error) => {
    const [field, message] = error
    return `${formattedMessage}${
      formattedMessage ? '; ' : ''
    }${field} ${message}`
  }, '')
}

export const getGeneralError = errors => errors.error || errors.request

export const formatName = parts => parts.filter(p => !isEmpty(p)).join(' ')

export const titleize = str => {
  if (!str) return ''

  return str
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .join('_')
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, m => m.toUpperCase())
    .replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
}

export const formatPersonAddress = person => {
  const address = person?.residential_address

  if (!address) return ''

  if (!address.line_one) return address.zipcode || ''

  const display = [
    `${titleize(address.line_one)} ${address.line_two || ''}`,
    titleize(address.city),
    `${address.state || ''} ${address.zipcode || ''}`,
    titleize(address.county),
  ]

  return display
    .map(part => part.trim())
    .filter(part => part)
    .join(', ')
}

export const formatLocationName = location => titleize(location.name)

export const formatLocal = (input, formatType = 'dateTime') => {
  const formatting = () => {
    if (formatType === 'date') return dateFormat
    if (formatType === 'dateTime') return dateTimeFormat
    if (formatType === 'time') return timeFormat
  }
  return moment.parseZone(input).local().format(formatting())
}

export const formatDisplayName = user => {
  if (!user) {
    return 'Blocks'
  }
  const displayableNameField = ['full_name', 'name', 'email'].find(field =>
    user[field]?.trim()
  )
  if (!displayableNameField) {
    throw new Error('Provided user does not have a name or email.')
  }
  return user[displayableNameField].trim()
}

export const formatDateStringAsISOString = dateString => {
  if (typeof dateString === 'undefined' || dateString === null) {
    return undefined
  }
  const dateObj = new Date(dateString)
  return dateObj.toString() === 'Invalid Date'
    ? undefined
    : dateObj.toISOString()
}
export const formatDateStringToISO = (dateString, format) =>
  moment(dateString, format).toISOString()

export const formatAsISODate = dateString =>
  moment(dateString).format('YYYY-MM-DD')

export const formatAutoTableParams = params => ({
  current_page: params?.page,
  per: params?.pageSize,
  sort_attr: params?.sortColumn,
  sort_dir: params?.sortOrder,
  ...((params?.filterRules || params?.filterConjunction) && {
    filters: {
      rules: params?.filterRules,
      conjunction: params?.filterConjunction,
    },
  }),
})
