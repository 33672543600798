import { MapMarker } from 'components/uiKit/icons'
import styles from './MarkerIcon.module.scss'

const getMarkerName = (itemsPerFieldHour, hasShifts) => {
  if (itemsPerFieldHour > 4) {
    return 'four-plus'
  }
  if (itemsPerFieldHour >= 2) {
    return 'two-to-four'
  }
  if (itemsPerFieldHour >= 1) {
    return 'one-to-two'
  }
  if (hasShifts) {
    return 'less-than-one'
  }
  return 'no-shifts'
}

const MarkerIcon = ({ itemsPerFieldHour, hasShifts }) => (
  <div className={styles.container}>
    <MapMarker
      className={
        styles[`marker__${getMarkerName(itemsPerFieldHour || 0, hasShifts)}`]
      }
    />
  </div>
)

export default MarkerIcon
