const defaultForm = {
  vdrs: [],
}

export const buildForm = (canvasser = defaultForm) => ({
  ...canvasser,
})

export const areVdrsUnmodified = (formVdrs, originalVdrs) =>
  originalVdrs.every(vdr =>
    formVdrs.find(
      formVdr => formVdr.county === vdr.county && formVdr.code === vdr.code
    )
  )
