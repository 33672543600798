import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  PageHeader,
  ContentBlock,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import { useCurrent } from 'contexts/index'
import LocationTable from '../LocationTable/LocationTable'

const LocationSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewLocations = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'view',
  })
  const canModifyLocations = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'modify',
  })

  const tableType = useMemo(
    () => location.pathname.split('/')[2],
    [location.pathname]
  )

  if (!canViewLocations) {
    return (
      <CardError
        hide={canViewLocations}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('Locations')}>
        <ButtonBlock justify="right">
          {tableType === 'event' ? (
            <Link
              onClick={e => !canModifyLocations && e.preventDefault()}
              to={`${location.pathname}/new`}
            >
              <Button.Accent disabled={!canModifyLocations}>
                {t('Add location')}
              </Button.Accent>
            </Link>
          ) : (
            <Link
              onClick={e => !canModifyLocations && e.preventDefault()}
              to={`${location.pathname}/new`}
            >
              <Button.Accent disabled={!canModifyLocations}>
                {t('Add location')}
              </Button.Accent>
            </Link>
          )}
        </ButtonBlock>
      </PageHeader>
      <ContentBlock>
        <Sheet>
          <LocationTable tableType={tableType} />
        </Sheet>
      </ContentBlock>
    </View>
  )
}

export default LocationSelect
