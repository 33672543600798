import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { MultiSearchSelect } from 'components'
import { fetchTurfs } from 'requests/turfs'
import { useRequest } from 'hooks/useRequest'
import { buildTurf, buildOption } from './utils'

const TurfMultiSelectFieldDEPRECATED = ({
  label,
  onSelect,
  turfs,
  ...props
}) => {
  const { t } = useTranslation()

  const [value, setValue] = useState([])
  const [options, setOptions] = useState([])

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest } = useRequest(fetchTurfs, {
    onSuccess: ({ turfs }) => setOptions(turfs.map(buildOption)),
  })

  useEffect(() => {
    makeRequest()
  }, [])

  useEffect(() => {
    setValue(turfs.map(buildOption))
  }, [turfs])

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(buildTurf))
  }

  return (
    <MultiSearchSelect
      label={label || t('Turfs')}
      options={options}
      onChange={handleMultiChange}
      value={value || []}
      {...props}
    />
  )
}

TurfMultiSelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  turfs: PropTypes.arrayOf(PropTypes.string),
}

TurfMultiSelectFieldDEPRECATED.defaultProps = {
  id: 'turf-multi-select',
  turfs: [],
}

export default TurfMultiSelectFieldDEPRECATED
