import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Chip, Autocomplete } from 'react-md'
import { withTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  TextBlock,
  FieldBlock,
  ContentBlock,
  SelectField,
  Section,
  SectionLabel,
} from '@politechdev/blocks-design-system'
import styles from './ChipBlock.module.scss'

export class ChipBlock extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    choices: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]),
    selectedChips: PropTypes.array,
    removeChip: PropTypes.func.isRequired,
    addChip: PropTypes.func.isRequired,
    allSelected: PropTypes.bool,
    addAll: PropTypes.func,
    removeAll: PropTypes.func,
    isAutocomplete: PropTypes.bool,
    onChange: PropTypes.func,
    dataLabel: PropTypes.string,
    dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    emptyMessage: PropTypes.string,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    selectedChips: [],
    allSelected: false,
    addAll: null,
    removeAll: null,
    onChange: null,
    dataLabel: 'name',
    dataValue: 'id',
    isAutocomplete: false,
    choices: [],
    emptyMessage: '',
  }

  state = {
    readOnly: true,
  }

  componentDidUpdate(prevProps) {
    const { allSelected, addAll } = this.props
    if (!prevProps.allSelected && allSelected && addAll) {
      addAll()
    }
  }

  convertStringChoices = choices => {
    const areAllValuesStrings = choices.every(
      choice => typeof choice === 'string'
    )
    return areAllValuesStrings
      ? choices.map(choice => ({ name: choice, id: choice }))
      : choices
  }

  renderChips() {
    const { t, emptyMessage } = this.props

    if (this.props.selectedChips && this.props.selectedChips.length) {
      return (
        <ContentBlock>
          {this.props.selectedChips.map((chip, i) => (
            <Chip
              className={styles.item}
              label={chip}
              removable
              key={i}
              onClick={() => {
                this.props.removeChip(chip, i)
              }}
            />
          ))}
        </ContentBlock>
      )
    }
    return (
      <TextBlock>
        {emptyMessage || (
          <p>
            {t('No ', { text: 'no-options' })}{' '}
            <span className={styles.emptyMessageSpan}>{this.props.label}</span>{' '}
            {t('selected')}
          </p>
        )}
      </TextBlock>
    )
  }

  renderChipContent() {
    const {
      t,
      label,
      addChip,
      addAll,
      removeAll,
      choices,
      selectedChips,
      onChange,
      dataLabel,
      dataValue,
      isAutocomplete,
      allSelected,
      loading,
    } = this.props

    if (this.state.readOnly) {
      return (
        <>
          {this.renderChips()}
          <ButtonBlock>
            <Button
              onClick={() => {
                this.setState({ readOnly: false })
              }}
            >
              {selectedChips.length ? t('Add more') : t('Add')}
            </Button>
          </ButtonBlock>
        </>
      )
    }

    return (
      <>
        {this.renderChips()}
        <FieldBlock>
          {isAutocomplete ? (
            <Autocomplete
              id={label}
              label={t('Search ') + label.toLowerCase()}
              data={choices}
              dataLabel={dataLabel}
              dataValue={dataValue}
              clearOnAutocomplete
              onAutocomplete={(chipText, index, matches) =>
                addChip(chipText, index, matches)
              }
              onChange={chipText => (onChange ? onChange(chipText) : null)}
            />
          ) : (
            <SelectField
              id={label}
              loading={loading}
              label={t('Search ') + label.toLowerCase()}
              options={this.convertStringChoices(choices).map(choice => ({
                label: choice[dataLabel],
                value: choice[dataValue],
              }))}
              onSelect={(chipText, index, matches) =>
                addChip(chipText, index, matches)
              }
            />
          )}
        </FieldBlock>
        <ButtonBlock>
          <Button
            onClick={() => {
              this.setState({ readOnly: true })
            }}
          >
            {t('Done')}
          </Button>
        </ButtonBlock>
        {addAll && (
          <div className={styles.multipleSelectionContainer}>
            <Button onClick={addAll} disabled={allSelected}>
              {t('Select all')} {label}
            </Button>
          </div>
        )}
        {removeAll && (
          <div className={styles.multipleSelectionContainer}>
            <Button
              onClick={() => {
                this.setState({ readOnly: false }, removeAll)
              }}
              disabled={!selectedChips || !selectedChips.length}
            >
              {t('Remove all')} {label}
            </Button>
          </div>
        )}
      </>
    )
  }

  renderSectionLabel() {
    const { label } = this.props

    if (!label) return null

    return (
      <ContentBlock>
        <SectionLabel>{label}</SectionLabel>
      </ContentBlock>
    )
  }

  render() {
    return (
      <Section>
        {this.renderSectionLabel()}
        {this.renderChipContent()}
      </Section>
    )
  }
}

export default withTranslation()(ChipBlock)
