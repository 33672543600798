import { useState, useEffect } from 'react'
import { useReactRouter } from 'hooks/router'
import { deleteProjection } from 'requests/fieldManagement'

const useDeleteProjection = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [projectionIdToDelete, setProjectionIdToDelete] = useState(null)
  const { history } = useReactRouter()

  useEffect(() => {
    if (!projectionIdToDelete) return
    setIsLoading(true)
    setErrorMessage(null)
    deleteProjection(projectionIdToDelete)
      .then(() => {
        setIsLoading(false)
        history.push('/field-management/projections')
      })
      .catch(() => {
        setErrorMessage(
          'An internal error occurred while trying to delete your goal'
        )
      })
  }, [projectionIdToDelete])

  return {
    isLoading,
    errorMessage,
    setProjectionIdToDelete,
  }
}

export default useDeleteProjection
