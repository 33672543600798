import { formatActiveColumn } from 'utils/reporting'
import { YES_NO_OPTIONS } from 'registrationReports/constants'
import { baseGeneralColumns } from './baseGeneralColumns'

export default {
  petitions_by_turf: {
    id: 'petitions_by_turf',
    name: 'Turf',
    dataDictionaryUrl:
      'https://docs.google.com/spreadsheets/d/1Br0BHxyRR2ZZ3Uzwqr-wi3fCKC6169NtDJR3Dbjo_D4/edit#gid=0',
    columns: [
      {
        dataKey: 'turf_name',
        title: 'Turf Name',
        type: 'string',
        resizable: true,
      },
      {
        dataKey: 'active_turf',
        title: 'Active Turf',
        type: 'enum',
        options: YES_NO_OPTIONS,
        resizable: true,
        deriveFunction: ({ rowData }) =>
          formatActiveColumn(rowData.turf_archived),
      },
      ...baseGeneralColumns,
    ],
  },
}
