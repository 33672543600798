import { useTranslation } from 'react-i18next'
import { View, Sheet, SubheaderBlock } from 'components'
import CampaignForm from '../CampaignForm/CampaignForm'

const CampaignCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <SubheaderBlock title={t('New Campaign')} />
      <Sheet>
        <CampaignForm />
      </Sheet>
    </View>
  )
}

export default CampaignCreate
