import React from 'react'
import { Switch, Route } from 'react-router'
import { useTranslation } from 'react-i18next'
import { adminSettingsPaths } from 'layout/routesConfig'
import { PageHeader } from '@politechdev/blocks-design-system'

const SectionHeader = () => {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route path={adminSettingsPaths}>
        <PageHeader collapsed title={t('Admin settings')} />
      </Route>
    </Switch>
  )
}

export default SectionHeader
