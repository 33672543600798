import { createPortal } from 'react-dom'
import { useMounted } from 'hooks'

const Portal = ({ children }) => {
  const isMounted = useMounted()

  return isMounted
    ? createPortal(children, document.querySelector('#context-portal'))
    : null
}

export default Portal
