import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { SelectField } from '@politechdev/blocks-design-system'
import { CardError } from 'components'
import { fetchPeople } from 'requests/people'
import { useCachedRequest } from 'hooks/useRequest'
import { buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts/index'
import { buildOptions, buildParams } from './utils'

const PersonSelectField = ({
  label,
  hideLabel,
  filters,
  onSelect,
  person,
  keepEmpty,
  fields,
  excludedPersonIds = [],
  disabledPersonIds = [],
  disabledPersonReason = '',
  canCreatePerson,
  onSelectCreate,
  ...props
}) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()

  const hasPermission = doesCurrentUserHavePermission({
    resource: 'person',
    ability: 'view',
  })

  const CREATE_PERSON = 'createPerson'
  const [value, setValue] = useState(person?.id)
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState([])
  const [debounced] = useDebounce(query, 300)

  const { makeRequest, isLoading, errors, hasErrors, response } =
    useCachedRequest(params => fetchPeople(params), {
      onSuccess: ({ people: incomingPeople }) =>
        setOptions(
          (canCreatePerson
            ? [
                {
                  value: CREATE_PERSON,
                  label: t('CREATE PERSON'),
                },
              ]
            : []
          ).concat(
            incomingPeople
              .filter(person => !excludedPersonIds.includes(person.id))
              .map(person =>
                buildOptions(person, disabledPersonIds, disabledPersonReason)
              )
          )
        ),
    })

  const errorMsg = Object.entries(errors)
    .map(([field, error]) => `${field}: ${error}`)
    .join('; ')

  useEffect(() => {
    if (value === CREATE_PERSON) {
      onSelectCreate()
      keepEmpty && setValue(undefined)
      setValue(person?.id)
    }
    if (value !== person?.id && value !== CREATE_PERSON) {
      const selection = response?.people.find(({ id }) => id === value)
      onSelect && onSelect(selection)
      keepEmpty && setValue(undefined)
    }
  }, [value])

  useEffect(() => {
    if (value !== person?.id) {
      setValue(person?.id)
    }
  }, [person])

  const getPeople = () => {
    const params = buildParams({
      query: debounced,
      filters,
      fields,
    })
    hasPermission &&
      makeRequest(
        `getPersonOptions ${debounced} ${JSON.stringify(filters)}`,
        params
      )
  }

  useEffect(() => {
    getPeople()
  }, [debounced, JSON.stringify(filters)])

  const singleOptions = buildSingleOptions(options, person, person =>
    buildOptions(person, disabledPersonIds, disabledPersonReason)
  )

  return (
    <>
      <CardError hide={!hasErrors} message={errorMsg} />
      <SelectField
        label={hideLabel ? undefined : label || t('Person')}
        options={singleOptions}
        onSelect={setValue}
        onInputChange={setQuery}
        value={value}
        loading={isLoading}
        disabled={!hasPermission}
        hint={
          !hasPermission && t('You do not have permission to edit this field')
        }
        {...props}
      />
    </>
  )
}

PersonSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  person: PropTypes.object,
  keepEmpty: PropTypes.bool,
}

PersonSelectField.defaultProps = {
  id: 'person',
  filters: [],
  person: null,
  keepEmpty: false,
}

export default PersonSelectField
