const commonParams = ({ startDate, endDate }) => ({
  filters: {
    rules: [
      {
        column: 'start_time',
        operator: 'after',
        param: startDate.format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        column: 'end_time',
        operator: 'before',
        param: endDate.format('YYYY-MM-DD HH:mm:ss'),
      },
    ],
    conjunction: 'inclusive',
  },
  per: Number.MAX_SAFE_INTEGER,
  indexed: true,
})

export const buildEventParams = ({ startDate, endDate }) => ({
  ...commonParams({ startDate, endDate }),
  associations: ['location'],
  fields: ['id', 'name', 'start_time', 'end_time', { location: ['name'] }],
})

export const buildMeetingParams = ({ startDate, endDate }) => ({
  ...commonParams({ startDate, endDate }),
  associations: ['location', 'person'],
  fields: [
    'id',
    'start_time',
    'end_time',
    { location: ['name'] },
    { person: ['name'] },
  ],
})

export const buildEvents = event => ({
  ...event,
  key: `${event.id}-event`,
  type: 'event',
  start: event.start_time,
  end: event.end_time,
})

export const buildMeetings = meeting => ({
  ...meeting,
  key: `${meeting.id}-meeting`,
  type: 'meeting',
  start: meeting.start_time,
  end: meeting.end_time,
})
