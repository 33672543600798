import { useTranslation } from 'react-i18next'
import {
  Button,
  Section,
  ContentBlock,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import styles from './StaffListNotice.module.scss'

const StaffListEmpty = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const goToSchedule = () => {
    history.push('/quality_control/manage/schedule')
  }

  return (
    <Section className={styles.notice}>
      <ContentBlock>
        <p>{t('No scheduled users')}</p>
      </ContentBlock>
      <ButtonBlock justify="center">
        <Button onClick={goToSchedule}>{t('Manage Schedule')}</Button>
      </ButtonBlock>
    </Section>
  )
}

export default StaffListEmpty
