import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Font,
  TextField,
  SelectField,
  ButtonBlock,
  FieldBlock,
  ContentBlock,
  Tag,
  Button,
  Icon,
} from '@politechdev/blocks-design-system'
import TagInterpolateBlock from '../TagInterpolateBlock/TagInterpolateBlock'
import scriptItemStyles from '../ScriptItem/ScriptItem.module.scss'

const QuestionEditForm = ({
  updateQuestionText,
  updateQuestionType,
  updateQuestionChoices,
  updateQuestionTag,
  tag,
  content,
  type,
  choices,
}) => {
  const { t } = useTranslation()

  const [editForm, setEditForm] = useState({
    content,
    tag,
    type,
    choices,
  })

  const QUESTION_TYPE_MAP = {
    single_choice: {
      label: t('Single answer'),
      icon: Icon.Circle({}),
      value: 'single_choice',
    },
    multiple_choice: {
      label: t('Checkboxes'),
      value: 'multiple_choice',
      icon: Icon.Square({}),
    },
    open_ended: {
      label: t('Open-ended'),
      value: 'open_ended',
      icon: null,
    },
  }

  const onTextChange = val => {
    setEditForm(prevState => ({ ...prevState, content: val }))
    updateQuestionText(val)
  }

  const onTagChange = val => {
    setEditForm(prevState => ({
      ...prevState,
      tag: val,
    }))
    updateQuestionTag(val)
  }

  const onTypeChange = val => {
    if (val === 'open_ended') {
      onTagChange(undefined)
    }
    setEditForm(prevState => ({ ...prevState, type: val }))
    updateQuestionType(val)
  }

  const onChoiceAdd = () => {
    setEditForm(prevState => ({
      ...prevState,
      choices: [...prevState.choices, { value: undefined, label: '' }],
    }))
  }

  const onChoiceUpdate = (val, i, destroy) => {
    const nextChoices = editForm.choices
      .map((item, ii) => (i === ii ? { ...item, label: val } : item))
      .filter((_, ii) => !(i === ii && destroy))
    setEditForm(prevState => ({
      ...prevState,
      choices: nextChoices,
    }))
    updateQuestionChoices(nextChoices)
  }

  return (
    <div>
      <TagInterpolateBlock
        currentTag={editForm.tag}
        currentText={editForm.content}
        updateTag={onTagChange}
        updateText={onTextChange}
        selectionStartId="question-text"
        tagAllowed={editForm.type !== 'open_ended'}
      />
      <div className={scriptItemStyles['script-item__name-and-tag']}>
        <TextField
          className={scriptItemStyles['script-item__name-and-tag__field']}
          id="question-text"
          label={t('Question text')}
          value={editForm.content}
          onChange={onTextChange}
        />
        <div>{editForm.tag && <Tag>{editForm.tag}</Tag>}</div>
      </div>
      <FieldBlock>
        <SelectField
          id="question-type"
          label={t('Question type')}
          options={Object.values(QUESTION_TYPE_MAP)}
          onSelect={onTypeChange}
          value={editForm.type}
        />
      </FieldBlock>
      {editForm.type !== 'open_ended' && (
        <>
          <ContentBlock>
            <Font.Copy variant="hint">{t('Answer choices')}</Font.Copy>
            {(editForm.choices || []).map(
              (choice, i) =>
                !choice.destroy && (
                  <div
                    key={`script-edit-choice-${i}`}
                    className={scriptItemStyles['script-item__choices--edit']}
                  >
                    {QUESTION_TYPE_MAP[editForm.type].icon}
                    <TextField
                      className={
                        scriptItemStyles['script-item__choices--edit__field']
                      }
                      id={`script-edit-choice-${i}`}
                      name={`script-edit-choice-${i}`}
                      value={choice.label}
                      onChange={val => onChoiceUpdate(val, i)}
                    />
                    <Button.Secondary
                      onClick={() => onChoiceUpdate(choice.label, i, true)}
                    >
                      <Icon.Times alt={t('Remove')} />
                    </Button.Secondary>
                  </div>
                )
            )}
          </ContentBlock>
          <ButtonBlock>
            <Button.Secondary
              onClick={onChoiceAdd}
              tooltipLabel={t('Add choice')}
            >
              <Icon.Plus alt={t('Add choice')} />
            </Button.Secondary>
          </ButtonBlock>
        </>
      )}
    </div>
  )
}

export default QuestionEditForm
