import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { View } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
} from '@politechdev/blocks-design-system'
import ReviewControls from './ReviewControls/ReviewControls'

const CheckinReview = () => {
  const { t } = useTranslation()

  return (
    <View>
      <PageHeader title={t('Review Responses')}>
        <ButtonBlock justify="right">
          <Link to="/check-ins">
            <Button.Secondary>
              <Icon.Times />
            </Button.Secondary>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ReviewControls />
    </View>
  )
}

export default CheckinReview
