import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  SelectField,
  Button,
  Icon,
  ContentBlock,
  Font,
} from '@politechdev/blocks-design-system'
import styles from '../GoalBreakdown.module.scss'

const BreakdownPaginator = ({
  currentPage,
  setCurrentPage,
  pageMap,
  pageSize,
  weekCount,
}) => {
  const { t } = useTranslation()

  return (
    <ContentBlock className={styles.breakdown__header}>
      <div className={styles.breakdown__paginator}>
        <Button.Secondary
          onClick={() => {
            setCurrentPage(() => currentPage - 1)
          }}
          className={styles.breakdown__paginator__button}
          disabled={currentPage < 2}
        >
          <Icon.ChevronLeft aria-label={t('Previous page')} />
        </Button.Secondary>
        <SelectField
          className={styles.breakdown__paginator__select}
          value={currentPage}
          onSelect={val => {
            setCurrentPage(val)
          }}
          options={Object.values(pageMap).map(page => ({
            value: page.pageNumber,
            label: page.label,
          }))}
        />
        <Button.Secondary
          onClick={() => {
            setCurrentPage(() => currentPage + 1)
          }}
          className={styles.breakdown__paginator__button}
          disabled={currentPage >= Object.keys(pageMap).length}
        >
          <Icon.ChevronRight aria-label={t('Next page')} />
        </Button.Secondary>
      </div>
      <div className={styles.breakdown__paginator__meta}>
        <Font.Copy variant="hint">{`Week ${currentPage * pageSize - 7} - ${
          currentPage * pageSize < weekCount
            ? currentPage * pageSize
            : weekCount
        } of ${weekCount}`}</Font.Copy>
      </div>
    </ContentBlock>
  )
}

export default BreakdownPaginator
