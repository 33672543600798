import { useRequest } from 'hooks/useRequest'
import { createContext, useEffect, useState } from 'react'
import { fetchFolder, fetchFolders, putFolder } from 'requests/folders'

const DocumentContext = createContext()

export const DocumentContextProvider = ({ children }) => {
  const [documents, setDocuments] = useState([])
  const [totalDocuments, setTotalDocuments] = useState(0)

  const {
    makeRequest: makeCurrentFolderRequest,
    response: currentFolderResponse,
    isLoading: currentFolderLoading,
    hasErrors: currentFolderHasErrors,
  } = useRequest(fetchFolder)

  const getCurrentFolder = id => {
    makeCurrentFolderRequest(id, { fields: ['id', 'name', 'lft', 'rgt'] })
  }

  const currentFolder = currentFolderResponse
    ? currentFolderResponse.folder
    : {}

  const {
    makeRequest: makeFoldersRequest,
    response: foldersResponse,
    isLoading: foldersLoading,
    hasErrors: foldersErrors,
  } = useRequest(fetchFolders)

  const getFolders = () => {
    makeFoldersRequest({ fields: ['id', 'name', 'parent_id', 'lft', 'rgt'] })
  }

  const folders = foldersResponse ? foldersResponse.folders : []

  const moveFolder = async (folderId, parentId) => {
    await putFolder(folderId, { parent_id: parentId })
    getFolders()
    if (currentFolder.id === folderId) {
      getCurrentFolder(folderId)
    }
  }

  useEffect(() => {
    getFolders()
  }, [])

  return (
    <DocumentContext.Provider
      value={{
        documents,
        setDocuments,
        totalDocuments,
        setTotalDocuments,
        getFolders,
        foldersLoading,
        foldersErrors,
        folders,
        getCurrentFolder,
        currentFolder,
        currentFolderLoading,
        currentFolderHasErrors,
        moveFolder,
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}

export default DocumentContext
