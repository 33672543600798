import { calculatePercent, summaryFunctions } from 'utils/reporting'

export default {
  phone_bank_caller: {
    columns: [
      {
        dataKey: 'phone_bank_name',
        title: 'Phone Bank name',
        type: 'string',
      },
      {
        dataKey: 'caller_name',
        title: 'Caller name',
        type: 'string',
        deriveFunction: ({ rowData }) =>
          `${rowData.user_first_name} ${rowData.user_last_name}`,
      },
      {
        dataKey: 'total_sessions',
        title: 'Total sessions',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'total_calls',
        title: 'Total calls',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'total_contacted',
        title: 'People contacted',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'percent_contacted',
        title: 'Percent contacted',
        type: 'number',
        deriveFunction: ({ rowData }) =>
          calculatePercent(rowData.total_contacted, rowData.total_people),
        summaryFunction: ({ data, acc }) =>
          summaryFunctions.customPercent(data, acc, [
            'total_contacted',
            'total_people',
          ]),
      },
    ],
  },
}
