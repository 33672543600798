import {
  ADDRESS_GROUP_DEFAULT,
  CHECKBOX_DEFAULT,
  FIELD_TYPES,
  GROUP,
  NAME_GROUP_DEFAULT,
  RADIO_DEFAULT,
} from './constants'

const REGISTRATION_TYPE_OPTIONS = [
  {
    value: 'new_registration',
    label: 'New Registration',
  },
  {
    value: 'other_change',
    label: 'Change of Address, Name, or Other Information',
  },
  {
    value: 'replacement_card',
    label: 'Replace voter information card',
  },
]

const GENDER_OPTIONS = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
]

const YES_NO_OPTIONS = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
]

const ETHNICITY_OPTIONS = [
  {
    value: 'American Indian/Alaskan Native',
    label: 'American Indian/Alaskan Native',
  },
  {
    value: 'Asian/Pacific Islander',
    label: 'Asian/Pacific Islander',
  },
  {
    value: 'Black, not of Hispanic Origin',
    label: 'Black, not of Hispanic Origin',
  },
  { value: 'Hispanic', label: 'Hispanic' },
  {
    value: 'White, not of Hispanic Origin',
    label: 'White, not of Hispanic Origin',
  },
  { value: 'Multi-racial', label: 'Multi-racial' },
  { value: 'Other', label: 'Other' },
]

export const convertToRedactedField = fieldConfig => {
  const splitAttribute = fieldConfig.attribute.split('.')
  const hasAttribute = `has_${splitAttribute.pop()}`
  const attribute = [...splitAttribute, hasAttribute].join('.')
  return {
    ...fieldConfig,
    label: `Has ${fieldConfig.label.toLowerCase()}`,
    component: FIELD_TYPES.CHECKBOX,
    defaultValue: CHECKBOX_DEFAULT,
    attribute,
  }
}

export const fieldConfigs = {
  hard_copy_collected: {
    defaultValue: true,
  },
  registration_type: {
    label: 'Reason for registration',
    component: FIELD_TYPES.RADIO,
    defaultValue: RADIO_DEFAULT,
    options: REGISTRATION_TYPE_OPTIONS,
    group: null,
  },
  us_citizen: {
    label: 'US citizen',
    component: FIELD_TYPES.RADIO,
    defaultValue: RADIO_DEFAULT,
    options: YES_NO_OPTIONS,
    group: null,
  },
  date_of_birth: {
    label: 'Date of birth',
    component: FIELD_TYPES.DATE,
    group: null,
  },
  state_id: {
    label: 'Has state ID',
    component: FIELD_TYPES.CHECKBOX,
    defaultValue: CHECKBOX_DEFAULT,
    group: null,
  },
  social_security: {
    label: 'Has SSN',
    component: FIELD_TYPES.CHECKBOX,
    defaultValue: CHECKBOX_DEFAULT,
    group: null,
  },
  last_name: {
    groupAttributeKey: 'last_name',
    component: FIELD_TYPES.NAME_BLOCK,
    group: GROUP.NAME_FIELDS,
    label: 'Registrant name',
    defaultValue: NAME_GROUP_DEFAULT,
  },
  first_name: {
    groupAttributeKey: 'first_name',
    component: FIELD_TYPES.NAME_BLOCK,
    group: GROUP.NAME_FIELDS,
    label: 'Registrant name',
    defaultValue: NAME_GROUP_DEFAULT,
  },
  middle_name: {
    groupAttributeKey: 'middle_name',
    component: FIELD_TYPES.NAME_BLOCK,
    group: GROUP.NAME_FIELDS,
    label: 'Registrant name',
    defaultValue: NAME_GROUP_DEFAULT,
  },
  name_suffix: {
    groupAttributeKey: 'name_suffix',
    component: FIELD_TYPES.NAME_BLOCK,
    group: GROUP.NAME_FIELDS,
    label: 'Registrant name',
    defaultValue: NAME_GROUP_DEFAULT,
  },
  voting_street_address_one: {
    groupAttributeKey: 'street_address_one',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.VOTING_ADDRESS_FIELDS,
    label: 'Residential address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  voting_street_address_two: {
    groupAttributeKey: 'street_address_two',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.VOTING_ADDRESS_FIELDS,
    label: 'Residential address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  voting_city: {
    groupAttributeKey: 'city',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.VOTING_ADDRESS_FIELDS,
    label: 'Residential address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  voting_state: {
    groupAttributeKey: 'state',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.VOTING_ADDRESS_FIELDS,
    label: 'Residential address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  voting_zipcode: {
    groupAttributeKey: 'zipcode',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.VOTING_ADDRESS_FIELDS,
    label: 'Residential address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  county: {
    groupAttributeKey: 'county',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.VOTING_ADDRESS_FIELDS,
    label: 'Residential address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  mailing_street_address_one: {
    groupAttributeKey: 'street_address_one',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.MAILING_ADDRESS_FIELDS,
    label: 'Mailing address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  mailing_street_address_two: {
    groupAttributeKey: 'street_address_two',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.MAILING_ADDRESS_FIELDS,
    label: 'Mailing address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  mailing_city: {
    groupAttributeKey: 'city',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.MAILING_ADDRESS_FIELDS,
    label: 'Mailing address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  mailing_state: {
    groupAttributeKey: 'state',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.MAILING_ADDRESS_FIELDS,
    label: 'Mailing address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  mailing_zipcode: {
    groupAttributeKey: 'zipcode',
    component: FIELD_TYPES.ADDRESS_BLOCK,
    group: GROUP.MAILING_ADDRESS_FIELDS,
    label: 'Mailing address',
    defaultValue: ADDRESS_GROUP_DEFAULT,
  },
  gender: {
    component: FIELD_TYPES.RADIO,
    defaultValue: RADIO_DEFAULT,
    options: GENDER_OPTIONS,
    group: null,
    label: 'Gender',
  },
  phone_number: {
    component: FIELD_TYPES.TEXT,
    type: 'tel',
    group: null,
    label: 'Phone number',
  },
  ethnicity: {
    component: FIELD_TYPES.RADIO,
    defaultValue: RADIO_DEFAULT,
    options: ETHNICITY_OPTIONS,
    group: null,
    label: 'Ethnicity',
  },
  registration_date: {
    component: FIELD_TYPES.DATE,
    group: null,
    label: 'Registration date',
  },
  signature: {
    component: FIELD_TYPES.CHECKBOX,
    defaultValue: CHECKBOX_DEFAULT,
    group: null,
    label: 'Has applicant signature',
  },
  email_address: {
    label: 'Email',
    component: FIELD_TYPES.TEXT,
    type: 'email',
  },
  eligible_voting_age: {
    label: 'Voting age',
    component: FIELD_TYPES.RADIO,
    options: YES_NO_OPTIONS,
  },
}
