import qs from 'qs'
import { setToken, getToken, removeToken, decodeToken } from 'utils/auth'
import { authFailureText } from './constants'
import RequestException from './RequestException'

export const defaultJSONHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
}

const handleErrorStatus = async res => {
  // eslint-disable-next-line eqeqeq
  if (res.status == 403) {
    if (
      res.headers.has('jwt') &&
      Boolean(decodeToken(res.headers.get('jwt'))?.locked)
    ) {
      setToken(res.headers.get('jwt'))
    }
    res
      .clone()
      .text()
      .then(text => {
        if (text.includes(authFailureText) && getToken()) {
          removeToken()
          window.location.reload()
        }
      })
  }
  if (!res.ok) {
    const error = new RequestException('error status code', res)
    await error.readJSON()

    throw error
  }

  return res
}

const buildFormData = (object, form = null, namespace = null) => {
  const formData = form || new FormData()
  Object.keys(object).forEach(key => {
    const hasProperty = Object.prototype.hasOwnProperty.call(object, key)
    if (!hasProperty || !object[key]) {
      return
    }
    const formKey = namespace ? `${namespace}[${key}]` : key
    if (typeof object[key] === 'object' && !(object[key] instanceof File)) {
      buildFormData(object[key], formData, formKey)
    } else {
      formData.append(formKey, object[key])
    }
  })

  return formData
}

const isUrlExternal = url => {
  if (url.indexOf('//') === 0) return true
  if (url.indexOf('://') === -1) return false
  if (url.indexOf('.') === -1) return false
  if (url.indexOf('/') === -1) return false
  if (url.indexOf(':') > url.indexOf('/')) return false
  if (url.indexOf('://') < url.indexOf('.')) return true
  return false
}

const getSubdomain = () => window.location.hostname.split('.')[0]

export const getApiUrl = (url, tenant = getSubdomain()) =>
  isUrlExternal(url) ? url : `//${process.env.API_HOSTNAME}/${tenant}${url}`

export const fetchJSON = (
  url,
  method = 'GET',
  data = undefined,
  headers = {},
  options = {}
) => {
  const { useJwt, ...passThroughHeaders } = headers
  const newHeaders = {
    ...(!options.isUpload && defaultJSONHeaders),
    ...passThroughHeaders,
  }

  if (useJwt) {
    newHeaders.Authorization = `Bearer ${getToken()}`
  }

  const fetchOpts = {
    credentials: 'same-origin',
    method: method.toUpperCase(),
    headers: newHeaders,
    body: null,
  }
  if (options.signal) {
    fetchOpts.signal = options.signal
  }

  if (data && options.isUpload) {
    fetchOpts.body = buildFormData(data)
  } else if (data) {
    fetchOpts.body = JSON.stringify(data)
  }

  const buildResponse = (status, data) =>
    options.showStatusCode
      ? {
          statusCode: status,
          response: data,
        }
      : data

  const handleResponse = res => {
    if (res.headers.has('jwt')) {
      setToken(res.headers.get('jwt'))
    }

    return res.status === 204
      ? buildResponse(res.status, {})
      : res
          .json()
          .then(json => buildResponse(res.status, json))
          .catch(() => ({}))
  }

  return fetch(getApiUrl(url), fetchOpts)
    .then(handleErrorStatus)
    .then(handleResponse)
}

export const stringifyParams = (params, options = {}) =>
  qs.stringify(
    {
      indexed: true,
      ...params,
    },
    {
      arrayFormat: 'indices',
      ...options,
    }
  )
