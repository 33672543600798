import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PersonSelectField } from 'components'
import { SelectField, Button, Icon } from '@politechdev/blocks-design-system'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { useAutoTable, useCurrent } from 'contexts'
import { formatPhoneNumber } from 'utils/formatting'
import { deleteEventAttendee, putEventAttendee } from 'requests/events'
import { EventContext } from '../EventContext/EventContext'
import styles from './GuestsTable.module.scss'

export const GuestsNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/people/${rowData.person.id}`}>
      {rowData.person.name}
    </Link>
  </TableGridCell>
)

export const PersonFirstNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.person.first_name}</TableGridCell>
)

export const PersonLastNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.person.last_name}</TableGridCell>
)

export const GuestsEmailCell = ({ rowData }) => (
  <TableGridCell>
    <a
      href={`mailto:${rowData.person.email}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {rowData.person.email}
    </a>
  </TableGridCell>
)

export const GuestsPhoneCell = ({ rowData }) => (
  <TableGridCell>
    <a href={`tel:${rowData.person.primary_phone_number}`}>
      {formatPhoneNumber(rowData.person.primary_phone_number)}
    </a>
  </TableGridCell>
)

const GuestsAddressCell = ({ rowData }) => {
  const { t } = useTranslation()

  const fullAddress = [
    rowData.person.residential_address?.line_one,
    rowData.person.residential_address?.city,
    rowData.person.residential_address?.state,
    rowData.person.residential_address?.zipcode,
    rowData.person.residential_address?.county,
  ]
    .filter(x => !!x)
    .join(', ')

  return <TableGridCell>{fullAddress || t('No address')}</TableGridCell>
}

export const guestsAddressCellRender = props => (
  <GuestsAddressCell rowData={props.rowData} />
)

const GuestsStatusCell = ({ rowData }) => {
  const { t } = useTranslation()

  const { currentEvent } = useContext(EventContext)

  const { refreshPage } = useAutoTable()

  const uninviteAttendee = async () => {
    await deleteEventAttendee(currentEvent.id, rowData.id)
    refreshPage()
  }

  const updateAttendeeStatus = async status => {
    await putEventAttendee(currentEvent.id, rowData.id, { status })
    refreshPage()
  }

  return (
    <div className={styles['guest-cell']}>
      <SelectField
        hideLabel
        id={`guest_${rowData.person.id}_status`}
        value={rowData.status}
        onSelect={updateAttendeeStatus}
        options={[
          { label: t('Yes'), value: 'attended' },
          { label: t('No'), value: 'invited' },
        ]}
      />
      <Button.Secondary
        tooltipLabel={t('Uninvite')}
        tooltipPosition="left"
        onClick={uninviteAttendee}
      >
        <Icon.Minus />
      </Button.Secondary>
    </div>
  )
}

export const guestsStatusCellRender = props => (
  <GuestsStatusCell rowData={props.rowData} />
)

const InvitedByCell = ({ rowData }) => {
  const { refreshPage } = useAutoTable()

  const { currentEvent } = useContext(EventContext)

  const updateInvitedBy = async inviter => {
    await putEventAttendee(currentEvent.id, rowData.id, {
      inviter_id: inviter?.id || null,
    })
    refreshPage()
  }

  return (
    <PersonSelectField
      id={`guest_${rowData.person.id}_inviter`}
      person={rowData.inviter}
      onSelect={updateInvitedBy}
      onClear={updateInvitedBy}
      clearable
      hideLabel
      excludedPersonIds={[rowData.person.id]}
    />
  )
}

export const invitedByCellRender = props => (
  <InvitedByCell rowData={props.rowData} />
)

const GuestRoleCell = ({ rowData }) => {
  const { refreshPage } = useAutoTable()

  const { currentEvent } = useContext(EventContext)
  const { eventRoleOptions } = useCurrent()

  const updateRole = async role => {
    await putEventAttendee(currentEvent.id, rowData.id, {
      role: role || null,
    })
    refreshPage()
  }

  return (
    <SelectField
      onSelect={updateRole}
      options={eventRoleOptions}
      value={rowData.role || undefined}
      clearable
      onClear={updateRole}
    />
  )
}

export const guestRoleCellRender = props => (
  <GuestRoleCell rowData={props.rowData} />
)

export const cellForGuestNeed =
  field =>
  ({ rowData }) => <TableGridCell>{rowData.needs[field]}</TableGridCell>
