import { useTranslation } from 'react-i18next'
import { Icon, Font } from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import { useContext } from 'react'
import { defaultTo } from 'lodash'
import styles from './RolesSidebarItem.module.scss'
import { RoleContext } from '../RoleContext/RoleContext'

const cx = classNames.bind(styles)

const RolesSidebarItem = ({ roleId, isChild = false }) => {
  const { t } = useTranslation()
  const { roles, currentRoleId } = useContext(RoleContext)
  const role = defaultTo(
    roles.find(role => role.id === roleId),
    {
      users: [],
      children: [],
    }
  )
  const { users } = role
  const activeUsers = users.filter(user => !user.disabled_at)
  const childIds = role.children.map(child => child.id)
  return (
    !!role && (
      <>
        <Link
          to={`/admin/roles/${role.id}`}
          className={cx('item', {
            'item--active': role.id === currentRoleId,
            'item--child': isChild,
          })}
          style={
            role.depth > 0 ? { paddingLeft: `${role.depth + 0.5}rem` } : {}
          }
        >
          <div className={styles['role-name']}>
            <Font.Copy
              variant="highlight"
              className={styles['role-name__icon']}
            >
              {role.depth > 0 && (
                <Icon.SubdirectoryArrowRight alt={t('Child role')} />
              )}
            </Font.Copy>
            <Font.Action>
              <p>{role.name}</p>
            </Font.Action>
          </div>
          <div className={styles.indicator__container}>
            <div className={styles.indicator}>
              <span>{activeUsers.length}</span>
              <Icon.User
                className={styles.indicator__icon}
                alt={t('Active users')}
              />
            </div>
            <div className={styles.indicator}>
              <span>{childIds.length}</span>
              <Icon.SubdirectoryArrowRight
                alt={t('Role tree depth')}
                className={styles.indicator__icon}
              />
            </div>
          </div>
        </Link>
        {childIds && (
          <div>
            {childIds.map(id => (
              <RolesSidebarItem key={id} roleId={id} isChild />
            ))}
          </div>
        )}
      </>
    )
  )
}

export default RolesSidebarItem
