import { buildDefaultFilters } from 'utils/reporting'
import {
  baseDistrictColumns,
  baseDistrictFilters,
  districtDataDictionaryUrl,
} from '../columns/baseDistrictColumns'

export default {
  id: 'district_by_location',
  name: 'Location',
  description:
    'This report provides information on the legislative districts of registrants. Users can use the filter dropdown to toggle between district types. Districts are determined from the addresses collected on registration forms. This report summarizes the data by canvassing location.',
  dataDictionaryUrl: districtDataDictionaryUrl,
  columns: [
    {
      dataKey: 'location_name',
      title: 'Location Name',
      type: 'string',
      resizable: true,
    },
    ...baseDistrictColumns,
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, baseDistrictFilters)
  },
}
