/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useMemo,
  createRef,
  useState,
  useEffect,
  isValidElement,
} from 'react'
import { isEqual, isEmpty } from 'lodash/lang'
import { xorWith } from 'lodash/array'
import { useDebouncedCallback } from 'use-debounce'
import {
  TableGrid,
  Column,
  TableFooter,
  TableFilters,
  ColumnQuickSearch,
  SavedFilterControls,
  HideColumnControls,
  FilterRuleControls,
  ActionColumn,
} from 'components'
import { useAutoTable, usePagination, useFilters, useSort } from 'contexts'
import { usePrevious } from 'hooks'
import DownloadPanel from './DownloadPanel/DownloadPanel'
import TableActions from './TableActions/TableActions'
import {
  buildFilteredData,
  buildSortedData,
  buildDerivedData,
  formatTotalData,
  getColumnConfig,
} from './utils'
import styles from './AutoTable.module.scss'

const AutoTable = ({
  tableId,
  persistSelectedFilter,
  isFrontend,
  enableQueryParams,
  enablePagination,
  enableFilters,
  enableSort,
  showDownloadButton,
  showFilterEditor,
  showQuickSearch,
  showFullTextQuickSearch,
  showTableLink,
  showTotals,
  freezeLeftColumns,
  freezeRightColumns,
  columns,
  fetchAction,
  data,
  sqlQuery,
  totalRecords,
  instanceLoading = false,
  instanceError = false,
  downloadName,
  headerActions,
  ...props
}) => {
  const {
    setTableId,
    tableRequest,
    isLoading,
    errorMsg,
    setTableRequest,
    isDownloading,
    setTableColumns,
    searchValue,
    setSearchValue,
  } = useAutoTable()

  const {
    filterConjunction = 'exclusive',
    filterRules,
    hiddenColumns,
    initialFilterReady,
    clearActiveFilter,
  } = useFilters(enableFilters)

  const { page, setPage, pageSize, setPageSize } =
    usePagination(enablePagination)

  const { sortColumn, sortOrder, onColumnSort } = useSort(enableSort)

  const showSecondaryTotals = columns.some(
    column => column.secondarySummaryFunction
  )

  useEffect(() => {
    setTableRequest(() => fetchAction)
    setTableColumns(columns)
  }, [])

  const dataColumns = columns.filter(
    col => !isValidElement(col) && !col.actions
  )
  const quickSearchColumn = dataColumns?.[0] || {}

  useEffect(() => {
    tableId && setTableId(tableId)
  }, [tableId])

  const gridRef = createRef()

  const [primitiveParams, setPrimitiveParams] = useState({
    pageSize,
    page,
    sortColumn,
    sortOrder,
    filterConjunction,
  })

  const preparedData = useMemo(() => {
    let pendingData = data

    pendingData = buildDerivedData(pendingData, dataColumns)

    if (!isFrontend) return pendingData

    if (enableFilters) {
      pendingData = buildFilteredData(
        pendingData,
        [
          ...(filterRules || []),
          showQuickSearch && searchValue
            ? {
                column: quickSearchColumn.dataKey,
                operator: 'containing',
                param: searchValue,
              }
            : null,
          showFullTextQuickSearch && searchValue
            ? {
                column: 'search',
                param: [searchValue],
              }
            : null,
        ],
        filterConjunction,
        dataColumns
      )
    }

    if (enableSort) {
      const alphaNumericSort = getColumnConfig(
        columns,
        sortColumn,
        'alphaNumericSort'
      )

      pendingData = buildSortedData(
        pendingData,
        sortColumn,
        sortOrder,
        alphaNumericSort || false
      )
    }

    return pendingData
  }, [
    isFrontend,
    data,
    filterRules,
    filterConjunction,
    dataColumns,
    sortColumn,
    sortOrder,
    searchValue,
  ])

  const totalsRow = useMemo(() => {
    if (!showTotals) return null

    return columns.reduce(
      (row, column, index) =>
        formatTotalData(
          data,
          preparedData,
          row,
          column,
          index,
          column.summaryFunction
        ),
      {
        [columns[0].dataKey]: 'Total',
        id: 'totals-row',
      }
    )
  }, [showTotals, columns, preparedData])

  const secondaryTotalsRow = useMemo(() => {
    if (!showSecondaryTotals) return null

    return columns.reduce(
      (row, column, index) => {
        if (index === 0) return row

        return formatTotalData(
          data,
          preparedData,
          row,
          column,
          index,
          column.secondarySummaryFunction
        )
      },
      {
        [columns[0].dataKey]: '365 Day Total',
        id: 'totals-row',
      }
    )
  }, [showSecondaryTotals, columns, preparedData])

  const headerWithTotals = ({ cells, headerIndex }) =>
    headerIndex === 0
      ? cells
      : cells.map(c => {
          const child = showSecondaryTotals ? (
            <div className={styles.totals}>
              <div>{totalsRow[c.props['data-key']]}</div>
              <div className={styles.row}>
                {secondaryTotalsRow[c.props['data-key']]}
              </div>
            </div>
          ) : (
            totalsRow[c.props['data-key']]
          )
          return React.cloneElement(c, null, [child])
        })

  const [debouncedQuickSearch] = useDebouncedCallback(tableRequest, 500)

  const handleQuickSearch = input => {
    setSearchValue(input)
    !isFrontend && debouncedQuickSearch(input)
  }

  const handleFilterReset = () => {
    if (filterRules && !isEmpty(filterRules)) {
      setSearchValue('')
      clearActiveFilter()
    } else {
      handleQuickSearch('')
      clearActiveFilter()
    }
  }

  const prevRules = usePrevious(filterRules)
  const prevPrimitives = usePrevious(primitiveParams)
  const prevReady = usePrevious(initialFilterReady)
  const prevConjunction = usePrevious(filterConjunction)

  useEffect(() => {
    setPrimitiveParams({
      pageSize,
      page,
      sortColumn,
      sortOrder,
    })
  }, [pageSize, page, sortColumn, sortOrder])

  useEffect(() => {
    if (
      initialFilterReady &&
      (!prevReady ||
        xorWith(prevRules, filterRules, isEqual).length ||
        !isEqual(prevPrimitives, primitiveParams) ||
        prevConjunction !== filterConjunction)
    ) {
      if (!isFrontend) {
        tableRequest(searchValue)
      }

      if (!prevPrimitives || prevPrimitives.page !== primitiveParams.page) {
        gridRef.current.scrollToTop()
      }
    }
  }, [
    primitiveParams,
    filterRules,
    filterConjunction,
    initialFilterReady,
    isFrontend,
  ])

  if (isDownloading) {
    return (
      <DownloadPanel
        data={preparedData}
        columns={dataColumns}
        filename={downloadName}
        totalRecords={totalRecords}
      />
    )
  }

  return (
    <div>
      {showFilterEditor && (
        <TableFilters
          handleResetClick={handleFilterReset}
          headerLeft={
            (showQuickSearch || showFullTextQuickSearch) && (
              <ColumnQuickSearch
                value={searchValue}
                columnName={
                  showQuickSearch ? quickSearchColumn?.title : 'Search'
                }
                onSearch={handleQuickSearch}
              />
            )
          }
          headerRight={
            <TableActions
              data={preparedData}
              sqlQuery={sqlQuery}
              columns={dataColumns}
              headerActions={headerActions}
              enableFilters={enableFilters}
              showTableLink={showTableLink}
              showDownloadButton={showDownloadButton}
              isFrontend={isFrontend}
              totalRecords={totalRecords}
              downloadName={downloadName}
            />
          }
        >
          <SavedFilterControls />
          <HideColumnControls columns={dataColumns} />
          <FilterRuleControls columns={dataColumns} />
        </TableFilters>
      )}
      {!showFilterEditor &&
        (showQuickSearch ||
          showFullTextQuickSearch ||
          headerActions ||
          showTableLink ||
          showDownloadButton) && (
          <div className="table-filters__wrapper">
            <div className="table-filters__header__actions">
              {(showQuickSearch || showFullTextQuickSearch) && (
                <div className="table-filters__header__actions--left">
                  <ColumnQuickSearch
                    value={searchValue}
                    columnName={
                      showQuickSearch ? quickSearchColumn?.title : 'Search'
                    }
                    onSearch={handleQuickSearch}
                  />
                </div>
              )}
              {(headerActions || showTableLink || showDownloadButton) && (
                <div className="table-filters__header__actions--right">
                  <TableActions
                    data={preparedData}
                    sqlQuery={sqlQuery}
                    columns={dataColumns}
                    headerActions={headerActions}
                    enableFilters={enableFilters}
                    showTableLink={showTableLink}
                    showDownloadButton={showDownloadButton}
                    isFrontend={isFrontend}
                    totalRecords={totalRecords}
                    downloadName={downloadName}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      <TableGrid
        ref={gridRef}
        data={preparedData}
        sortColumn={sortColumn}
        sortOrder={sortOrder}
        onColumnSort={onColumnSort}
        pageSize={pageSize}
        hiddenColumns={hiddenColumns}
        error={instanceError || errorMsg}
        loading={instanceLoading || isLoading}
        totals={showTotals ? totalsRow : undefined}
        headerHeight={
          showTotals ? [55, showSecondaryTotals ? 60 : 30] : undefined
        }
        headerRenderer={showTotals ? headerWithTotals : undefined}
        {...props}
      >
        {columns.map((col, index) => {
          if (isValidElement(col)) return col

          if (col.actions) {
            return (
              <ActionColumn
                key={index}
                actions={col.actions}
                listInline
                {...col}
              />
            )
          }

          let frozen = false
          if (index <= freezeLeftColumns - 1) {
            frozen = 'left'
          } else if (index >= columns.length - freezeRightColumns) {
            frozen = 'right'
          }

          return (
            <Column
              key={col.dataKey}
              sortable={enableSort}
              frozen={frozen}
              {...col}
            />
          )
        })}
      </TableGrid>
      <TableFooter
        enablePagination={enablePagination}
        page={page}
        pageSize={pageSize}
        totalRecords={enablePagination ? totalRecords : preparedData.length}
        setPageSize={setPageSize}
        setPage={setPage}
      />
    </div>
  )
}

export default AutoTable
