import { SelectField } from '@politechdev/blocks-design-system'
import { useEffect, useState } from 'react'
import { useCachedRequest } from 'hooks/useRequest'
import { fetchScheduleUserOptions } from 'requests/qualityControl'
import { useDebounce } from 'use-debounce/lib'
import { formatDisplayName } from 'utils/formatting'
import { useCurrent } from 'contexts/index'

const ScheduleUserSelect = ({
  id,
  user,
  disabled,
  onSelect,
  label,
  scheduledUserIds,
}) => {
  const [users, setUsers] = useState([])

  const [query, setQuery] = useState('')
  const currentContext = useCurrent()
  const [debounced] = useDebounce(query, 300)

  const { makeRequest } = useCachedRequest(fetchScheduleUserOptions, {
    onSuccess: ({ users: incomingUsers }) => {
      setUsers(incomingUsers)
    },
  })

  const visableUserOptions = [
    ...users,
    ...(user?.id && !query ? [user] : []),
  ].reduce((options, userOption) => {
    if (!scheduledUserIds.has(userOption.id) || userOption.id === user.id) {
      options.push({
        value: userOption.id,
        user: userOption,
        label: formatDisplayName(userOption),
      })
    }
    return options
  }, [])

  const getUsers = () => {
    makeRequest(query, query, currentContext)
  }

  useEffect(() => {
    getUsers()
  }, [debounced])

  return (
    <SelectField
      label={label}
      id={id}
      value={user.id}
      options={visableUserOptions}
      disabled={disabled}
      onSelect={id => {
        onSelect(visableUserOptions.find(({ value }) => value === id).user)
      }}
      onInputChange={setQuery}
    />
  )
}

export default ScheduleUserSelect
