import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, LoadBar, ButtonBlock } from 'components'
import { useRequest } from 'hooks'
import CardError from '../error-handling/CardError'
import styles from './ContentEditor.module.scss'

const TextBlock = ({ content, setContent, contentLabel }) => (
  <TextField
    id={`${contentLabel}-text-block`}
    rows={2}
    label={contentLabel}
    value={content}
    onChange={setContent}
  />
)
const Controls = ({ canSave, onSave, resetContent, endEditing }) => {
  const { isLoading, hasErrors, makeRequest } = useRequest(onSave, {
    onSuccess: endEditing,
  })
  return (
    <div>
      <LoadBar show={isLoading} />
      <CardError hide={!hasErrors} />
      <ButtonBlock>
        <Button primary raised onClick={makeRequest} disabled={!canSave}>
          Save
        </Button>
        <Button
          primary
          flat
          onClick={() => {
            resetContent()
            endEditing()
          }}
        >
          Cancel
        </Button>
      </ButtonBlock>
    </div>
  )
}

const ContentEditor = ({ onSave, endEditing, label, content }) => {
  const [text, setText] = useState('')

  useEffect(() => {
    setText(content)
  }, [])

  return (
    <div className={styles.input}>
      <TextBlock content={text} setContent={setText} contentLabel={label} />
      <Controls
        onSave={() => onSave(text)}
        canSave={text !== content}
        resetContent={() => setText(content)}
        endEditing={endEditing}
      />
    </div>
  )
}

ContentEditor.propTypes = {
  onSave: PropTypes.func.isRequired,
  endEditing: PropTypes.func.isRequired,
  label: PropTypes.string,
  content: PropTypes.string,
}

ContentEditor.defaultProps = {
  label: '',
  content: '',
}

export default ContentEditor
