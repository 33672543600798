import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Marker as MarkerGL } from '@urbica/react-map-gl'
import Cluster from '@urbica/react-map-gl-cluster'
import { useViewportDispatch } from '../ViewportProvider/ViewportProvider'
import ClusterMarker from '../ClusterMarker/ClusterMarker'
import MarkerIcon from '../MarkerIcon/MarkerIcon'

const ClusterContainer = ({ onClusterClick, children }) => {
  const clusterRef = useRef()
  const setViewport = useViewportDispatch()

  const handleClusterClick = ({ clusterId, latitude, longitude }) => {
    const supercluster = clusterRef.current.getCluster()
    const zoom = supercluster.getClusterExpansionZoom(clusterId)
    onClusterClick && onClusterClick(supercluster)

    setViewport(viewport => ({
      ...viewport,
      latitude,
      longitude,
      zoom,
    }))
  }

  return (
    <Cluster
      radius={50}
      extent={512}
      nodeSize={64}
      ref={clusterRef}
      component={cluster => (
        <ClusterMarker onClick={handleClusterClick} cluster={cluster} />
      )}
    >
      {children}
    </Cluster>
  )
}

const Markers = ({
  markers,
  onMarkerClick,
  onClusterClick,
  centerOnClick,
  useClusters,
}) => {
  const setViewport = useViewportDispatch()

  const handleMarkerClick = marker => {
    onMarkerClick && onMarkerClick(marker)
    centerOnClick &&
      setViewport(viewport => ({
        latitude: marker.latitude,
        longitude: marker.longitude,
        zoom: Math.max(10, viewport.zoom),
      }))
  }

  const Container = useClusters ? ClusterContainer : React.Fragment

  return (
    <Container onClusterClick={useClusters && onClusterClick}>
      {markers.map((marker, index) => (
        <MarkerGL
          key={marker.id || index}
          latitude={marker.latitude}
          longitude={marker.longitude}
          anchor="bottom"
          onClick={() => handleMarkerClick(marker)}
        >
          <MarkerIcon
            itemsPerFieldHour={marker.itemsPerFieldHour}
            hasShifts={marker.numShifts > 0}
          />
        </MarkerGL>
      ))}
    </Container>
  )
}

Markers.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      data: PropTypes.object,
    })
  ).isRequired,
  onMarkerClick: PropTypes.func,
  onClusterClick: PropTypes.func,
  centerOnClick: PropTypes.bool,
  useClusters: PropTypes.bool,
}

Markers.defaultProps = {
  onMarkerClick: null,
  onClusterClick: null,
  centerOnClick: true,
  useClusters: false,
}

export default Markers
