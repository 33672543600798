import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CheckinView from '../CheckinView/CheckinView'
import CheckinResponseReview from '../CheckinReview/CheckinReview'
import CheckinManagement from '../CheckinManagement/CheckinManagement'
import ManagementContextProvider from '../CheckinContext/ManagementContext'

const CheckinRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={CheckinView} />
    <ManagementContextProvider>
      <Route path={`${match.path}/manage`} component={CheckinManagement} />
      <Route path={`${match.path}/review`} component={CheckinResponseReview} />
    </ManagementContextProvider>
  </Switch>
)

export default CheckinRoutes
