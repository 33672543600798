import { formatPhoneNumber } from 'utils/formatting'

export const teamCityCsvCell = data =>
  data.organizations[0] ? data.organizations[0].city : null

export const teamLeaderNameCsvCell = data => data.leader?.name

export const teamPhoneNumberCsvCell = data =>
  formatPhoneNumber(data.leader?.primary_phone_number)

export const teamOrganizerNameCsvCell = data => data.organizer.name

export const teamOrganizationCsvCell = data =>
  data.organizations.map(o => o.name)
