export const rPageNumber = /_page_(\d\d\d)_of/
export const rTotalPages = /_of_(\d\d\d)\.pdf/

export const getPageFromFilename = filename => {
  // eslint-disable-next-line no-unused-vars
  const [_, rawPage] = rPageNumber.exec(filename)
  return parseInt(rawPage)
}
export const getTotalPagesFromFilename = filename => {
  // eslint-disable-next-line no-unused-vars
  const [_, rawPageCount] = rTotalPages.exec(filename)
  return parseInt(rawPageCount)
}

export const correctInQC = status => {
  if (['in_qc', 'ready_for_qc'].includes(status)) {
    return status.replace('qc', 'visual_qc')
  }
  return status
}
