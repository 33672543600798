import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CardError,
  ButtonBlock,
  ContentBlock,
  Button,
  FieldBlock,
} from 'components'
import {
  Modal,
  TextField,
  SelectField,
  DateField,
} from '@politechdev/blocks-design-system'
import { useRequest, useEvent } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'
import { STATES } from 'us'
import { useCurrentScan } from 'qualityControl/contexts/ScanContext'
import { updateRegistrantInformation } from 'requests/scans'
import styles from './EditRegistrantProfileModal.module.scss'

const EditRegistrantProfileModal = ({
  setIsOpen,
  isOpen,
  currentRegistrationForm,
}) => {
  const { t } = useTranslation()
  const [, , { reload: reloadScan }] = useCurrentScan()
  const [registrantInformation, setRegistrantInformation] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    voting_street_address_one: '',
    voting_state: '',
    voting_zipcode: '',
    voting_city: '',
    date_of_birth: undefined,
  })

  useEffect(() => {
    setRegistrantInformation(currentRegistrationForm)
  }, [currentRegistrationForm, isOpen])

  const { isLoading, makeRequest, errors, hasErrors } = useRequest(
    updateRegistrantInformation,
    {
      onSuccess: () => {
        setIsOpen(false)
        reloadScan()
      },
    }
  )
  const errorMsg = formatErrorMessage(errors)

  const handleRegistrantInformationUpdate = (field, inputValue) => {
    const nextRegistrantInformation = { ...registrantInformation }
    nextRegistrantInformation[field] = inputValue
    setRegistrantInformation(nextRegistrantInformation)
  }

  const save = useEvent(() => {
    makeRequest(registrantInformation)
  })

  return (
    <Modal
      id="edit-registrant-profile-modal"
      title={t('Registrant Information')}
      className={styles.modal}
      onHide={() => setIsOpen(false)}
      isOpen={isOpen}
      showLoading={isLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError message={errorMsg} hide={!hasErrors} />
        </ContentBlock>
        <FieldBlock>
          <TextField
            id="first_name"
            label={t('First Name')}
            onChange={val =>
              handleRegistrantInformationUpdate('first_name', val)
            }
            value={registrantInformation.first_name}
          />
          <TextField
            id="last_name"
            label={t('Last Name')}
            onChange={val =>
              handleRegistrantInformationUpdate('last_name', val)
            }
            value={registrantInformation.last_name}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="phone_number"
            type="tel"
            label={t('Phone Number')}
            onChange={val =>
              handleRegistrantInformationUpdate('phone_number', val)
            }
            value={registrantInformation.phone_number}
          />
          <DateField
            label={t('Date of birth')}
            value={registrantInformation.date_of_birth}
            onChange={val =>
              handleRegistrantInformationUpdate('date_of_birth', val)
            }
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="voting_street_address_one"
            label={t('Street Address')}
            onChange={val =>
              handleRegistrantInformationUpdate(
                'voting_street_address_one',
                val
              )
            }
            value={registrantInformation.voting_street_address_one}
          />
          <SelectField
            id="voting_state"
            label={t('State')}
            options={STATES.map(state => ({
              value: state.abbr,
              label: state.name,
            }))}
            onSelect={val => {
              handleRegistrantInformationUpdate('voting_state', val)
            }}
            value={registrantInformation.voting_state}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="voting_city"
            label={t('City')}
            onChange={val =>
              handleRegistrantInformationUpdate('voting_city', val)
            }
            value={registrantInformation.voting_city}
          />
          <TextField
            id="voting_zipcode"
            label={t('Zip Code')}
            onChange={val =>
              handleRegistrantInformationUpdate('voting_zipcode', val)
            }
            value={registrantInformation.voting_zipcode}
          />
        </FieldBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button primary flat onClick={() => setIsOpen(false)}>
            {t('Cancel')}
          </Button>
          <Button primary raised onClick={save}>
            {t('Save')}
          </Button>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default EditRegistrantProfileModal
