import {
  Button,
  ButtonBlock,
  TextBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import styles from './RegistrantProfileEditButton.module.scss'

const RegistrantProfileEditButton = ({ setIsEditModalOpen, isDisabled }) => {
  const { t } = useTranslation()

  return (
    <>
      <ButtonBlock>
        <Button
          className={styles.button}
          disabled={isDisabled}
          onClick={() => setIsEditModalOpen(true)}
        >
          <Icon.Pencil /> <span>{t('Update info')}</span>
        </Button>
      </ButtonBlock>
      {isDisabled && (
        <TextBlock>
          <p className={styles['disabled-message']}>
            {t(
              'Registrant information is currently being processed and is unavailable for updates.'
            )}
          </p>
        </TextBlock>
      )}
    </>
  )
}

export default RegistrantProfileEditButton
