import {
  Button,
  ContentBlock,
  TextField,
  Icon,
} from '@politechdev/blocks-design-system'
import useEvent from 'hooks/useEvent'
import { useTranslation } from 'react-i18next'
import styles from './TurfForm.module.scss'

const TurfSpecificSettings = ({
  settings = [],
  onChange,
  label,
  keyPrefix,
}) => {
  const { t } = useTranslation()

  const handleSettingChange = useEvent((index, value) => {
    const updated = [...settings]
    updated.splice(index, 1, value)
    onChange(updated)
  })

  const addSetting = useEvent(() => {
    onChange([...settings, ''])
  })

  const removeSetting = useEvent(index => {
    const updated = [...settings]
    updated.splice(index, 1)
    onChange(updated)
  })

  return (
    <ContentBlock>
      {settings.map((setting, index) => (
        <div className={styles['turf-setting__row']}>
          <TextField
            key={`${keyPrefix}-${index}`}
            label={label}
            name={`${keyPrefix}-${index}`}
            value={setting}
            onChange={value => handleSettingChange(index, value)}
          />
          <Button.Secondary onClick={() => removeSetting(index)}>
            <Icon.Times aria-label={t('remove')} />
          </Button.Secondary>
        </div>
      ))}
      <Button onClick={addSetting}>
        <Icon.Plus />
      </Button>
    </ContentBlock>
  )
}

export default TurfSpecificSettings
