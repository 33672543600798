export default {
  id: 'qc_revision',
  name: 'History of Visual QC Revisions',
  description:
    'This report shows all of the voter registration scans where QC was redone.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1595309166',
  columns: [
    {
      dataKey: 'packet_name',
      title: 'Packet name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'event',
      title: 'Event',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'scan_number',
      title: 'Scan number',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'created_at',
      title: 'Event time',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'user',
      title: 'User',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        `${rowData.first_name} ${rowData.last_name}`,
    },
    {
      dataKey: 'response',
      title: 'Response',
      type: 'string',
      resizable: true,
    },
  ],
}
