import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './DetailTable.module.scss'
import Cell from './Cell/Cell'
import Row from './Row/Row'

const cx = classNames.bind(styles)

const DetailTable = ({ columnHeaders, children, align }) => (
  <table className={styles.table}>
    <thead>
      <tr>
        {columnHeaders.map((header, i) => (
          <th
            className={cx('table__header', {
              'table__header--center': align === 'center',
              'table__header--right': align === 'right',
            })}
            key={i}
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
)

DetailTable.propTypes = {
  columnHeaders: PropTypes.array,
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
}

DetailTable.Cell = Cell
DetailTable.Row = Row

export default DetailTable
