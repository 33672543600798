import { createValidatorFromSchema, validate } from 'utils/schemaValidation'
import { ANY_DATE, dateTypes } from '../RelativeDateRangeField/utils'
import { COLUMN_WITHOUT_DATE, COLUMN_WITH_DATE } from './constants'

const { isIncludedIn, isNumberLike, isOneOf } = validate

const rDateFormat = /^\d\d\d\d-\d\d-\d\d$/
const isValidDateFormat = value => rDateFormat.test(value)
const isValidResponsibility = value =>
  !isNumberLike(value) && typeof value === 'string'
const isValidResponsibilityArray = values =>
  Array.isArray(values) && values.every(isValidResponsibility)
const isValidScopeParams = param => {
  if (!Array.isArray(param)) return false
  const [responsibilities, startDate, endDate] = param
  const isStartDateValid = isValidDateFormat(startDate)
  const isEndDateValid = isValidDateFormat(endDate)

  return (
    isValidResponsibilityArray(responsibilities) &&
    isStartDateValid &&
    isEndDateValid
  )
}

const isValidAnyDateFilter = createValidatorFromSchema({
  column: [isIncludedIn([COLUMN_WITHOUT_DATE])],
  param: [isValidResponsibilityArray],
})

const isValidWithDateFilterRule = createValidatorFromSchema({
  column: [isIncludedIn([COLUMN_WITH_DATE])],
  param: [isValidScopeParams],
})

const isValidFilterRule = isOneOf([
  isValidAnyDateFilter,
  isValidWithDateFilterRule,
])

export const fromApiEventResponsibilitiesValidator = createValidatorFromSchema({
  meta: {
    dateType: [isIncludedIn([...dateTypes, ANY_DATE])],
    relativeValue: [isNumberLike],
  },
  params: {
    rules: [rules => rules.some(isValidFilterRule)],
  },
})

export const fromUiEventResponsibilitiesValidator = createValidatorFromSchema({
  meta: {
    dateType: [isIncludedIn([...dateTypes, ANY_DATE])],
    relativeValue: [isNumberLike],
  },
  value: {
    rules: [rules => rules.some(isValidFilterRule)],
  },
})

export const eventResponsibilitiesFormatForAPI = filter =>
  filter.value || { rules: [] }
export const eventResponsibilitiesFormatForUI = filter =>
  filter.params || { rules: [] }
