import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { fetchMeetings, deleteMeeting } from 'requests/events'
import { useState } from 'react'
import { formatErrorMessage } from 'utils/formatting'
import tableConfig from './tableConfig'

const MeetingTable = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const { renderTable, refreshPage } = useAutoTable()

  const { doesCurrentUserHavePermission } = useCurrent()

  const [meetings, setMeetings] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const canModifyMeeting = doesCurrentUserHavePermission({
    resource: 'meeting',
    ability: 'modify',
  })

  const canDeleteMeeting = doesCurrentUserHavePermission({
    resource: 'meeting',
    ability: 'remove',
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: requestMeetings } = useRequest(
    params =>
      fetchMeetings({
        current_page: params?.page,
        per: params?.pageSize,
        sort_attr: params?.sortColumn,
        sort_dir: params?.sortOrder,
        filters: {
          rules: params?.filterRules,
          conjunction: params?.filterConjunction,
        },
      }),
    {
      onSuccess: ({ meetings, meta }) => {
        setMeetings(meetings)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const {
    makeRequest: deleteRequest,
    isLoading,
    errors,
  } = useRequest(deleteMeeting, {
    onSuccess: refreshPage,
  })

  const errorMsg = formatErrorMessage(errors)

  const handleEdit = rowData => {
    history.push(`${match.path}/${rowData.id}/edit`)
  }

  const handleDelete = rowData => {
    deleteRequest(rowData.id)
  }

  const columns = [
    {
      actions: [
        createRowAction(t('Edit'), handleEdit, canModifyMeeting),
        createRowAction(t('Delete'), handleDelete, canDeleteMeeting),
      ].filter(Boolean),
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return renderTable({
    data: meetings,
    loading: isLoading,
    error: errorMsg,
    totalRecords,
    columns,
    fetchAction: requestMeetings,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
    defaultFilters={tableConfig.defaultFilters}
  >
    <MeetingTable />
  </AutoTableProvider>
)
