import { useContext, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Sticky, LoadBar, CardError } from 'components'
import { Icon } from '@politechdev/blocks-design-system'
import { QueryParamProvider, ScrollProvider, useScroll } from 'contexts'
import { SearchProvider, useSearch } from '../SearchProvider/SearchProvider'
import { useClipboardStatus } from '../ClipboardProvider/ClipboardProvider'
import { usePermissions } from '../PermissionsProvider/PermissionsProvider'
import SearchBar from '../SearchBar/SearchBar'
import SearchGlobalToggle from '../SearchGlobalToggle/SearchGlobalToggle'
import SearchResults from '../SearchResults/SearchResults'
import PanelMessage from '../PanelMessage/PanelMessage'
import styles from './Search.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const Search = ({ scrollRef }) => {
  const { t } = useTranslation()
  const { canModify } = usePermissions()
  const { startScroll, endScroll } = useScroll()

  const [, dropRef] = useDrop({
    accept: [],
    hover: (item, monitor) => {
      const { y: yPos } = monitor.getClientOffset()

      if (yPos < 180) {
        startScroll('up')
      } else if (window.innerHeight - yPos < 180) {
        startScroll('down')
      } else {
        endScroll()
      }
    },
    canDrop: () => canModify,
  })

  const {
    isPristine,
    isGlobal,
    isLoading: searchLoading,
    hasErrors: searchError,
    hasResults,
    documents,
    folders,
  } = useSearch()

  const { currentFolder } = useContext(DocumentContext)

  const { isLoading: clipboardLoading, errorMsg: clipboardError } =
    useClipboardStatus()

  const isLoading = searchLoading || clipboardLoading

  return (
    <div ref={scrollRef} className={styles.panel}>
      <div ref={dropRef} className={styles.container}>
        <LoadBar show={isLoading} className={styles.loadbar} />
        <CardError
          hide={!searchError}
          message={t("We're unable to complete your search")}
        />
        <CardError
          hide={!clipboardError}
          message={t("We're unable to complete this action")}
        />
        <Sticky
          className={styles.header}
          stuckClassName={styles['header--stuck']}
        >
          <div className={styles.back}>
            <Link to={`/share/documents/${currentFolder.id}`}>
              <Icon.ChevronLeft alt="" />
              <span>{currentFolder.name}</span>
            </Link>
          </div>
          <SearchBar />
          <SearchGlobalToggle />
        </Sticky>
        {hasResults && (
          <SearchResults folders={folders} documents={documents} />
        )}
        {isLoading && !hasResults && <PanelMessage title={t('Searching')} />}
        {isPristine && !isLoading && !hasResults && (
          <PanelMessage
            title={t('Search your files')}
            message={
              isGlobal
                ? t(
                    'Find matching folders or documents anywhere in your documents'
                  )
                : t('Find matching folders or documents in this folder')
            }
          />
        )}
        {!isPristine && !isLoading && !hasResults && (
          <PanelMessage
            title={t('No results')}
            message={
              isGlobal
                ? t("We couldn't find anything matching your query")
                : t(
                    "We couldn't find anything matching your query in this folder"
                  )
            }
          />
        )}
      </div>
    </div>
  )
}

export default () => {
  const scrollRef = useRef()

  return (
    <ScrollProvider speed={2} targetRef={scrollRef}>
      <QueryParamProvider>
        <SearchProvider>
          <Search scrollRef={scrollRef} />
        </SearchProvider>
      </QueryParamProvider>
    </ScrollProvider>
  )
}
