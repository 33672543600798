import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchRoles = params =>
  fetchJSON(`/api/v1/roles?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchRole = (id, params) =>
  fetchJSON(`/api/v1/roles/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postRole = (role, params) =>
  fetchJSON(
    `/api/v1/roles?${stringifyParams(params)}`,
    'POST',
    { role },
    { useJwt: true }
  )

export const putRole = (id, role, params) =>
  fetchJSON(
    `/api/v1/roles/${id}?${stringifyParams(params)}`,
    'PUT',
    { role },
    { useJwt: true }
  )

export const deleteRole = id =>
  fetchJSON(`/api/v1/roles/${id}`, 'DELETE', null, { useJwt: true })
