import { Filter } from 'qualityControl/inbox/Filter'
import menuItems from 'qualityControl/inbox/menuItems'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { QCPacketFilterContext } from './QCPacketFilterContext/QCPacketFilterContext'
import { useInbox } from './InboxContext'

export const DaysLeftFilter = () => {
  const { t } = useTranslation()
  const { isPendingPacketsEmpty: isDisabled } = useInbox()
  const { daysLeft, setDaysLeft } = useContext(QCPacketFilterContext)

  return (
    <Filter
      id="days_left_filter"
      label={t('Days left')}
      value={daysLeft}
      onChange={setDaysLeft}
      menuItems={menuItems.daysLeft}
      isDisabled={isDisabled}
    />
  )
}

export default DaysLeftFilter
