import { fetchJSON, stringifyParams } from 'utils/req'

export const createPhoneVerificationCall = (call, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/phone_verification_calls?${stringifyParams(
      params
    )}`,
    'POST',
    {
      call,
    },
    {
      useJwt: true,
    }
  )

export const updatePhoneVerificationCall = (callRecordId, call, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/phone_verification_calls/${callRecordId}?${stringifyParams(
      params
    )}`,
    'PUT',
    {
      call,
    },
    {
      useJwt: true,
    }
  )

export const endPhoneVerificationCall = (callRecordId, call) =>
  fetchJSON(
    `/api/v1/quality_control/phone_verification_calls/${callRecordId}/end`,
    'PUT',
    {
      call,
    },
    {
      useJwt: true,
    }
  )
