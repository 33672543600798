import { EditableContent, InputArea } from 'components'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { useToast, Button } from '@politechdev/blocks-design-system'
import { defaultFlagParams } from 'requests/flags'
import styles from './FlagSingleActionPlan.module.scss'
import { useCurrentFlagContext } from './contexts/CurrentFlagContext'

const FlagSingleActionPlan = () => {
  const { t } = useTranslation()
  const { setToast } = useToast()

  const {
    currentUser: {
      role: { admin: isUserAdmin },
    },
    doesCurrentUserHavePermission,
  } = useCurrent()

  const hasEditPermission =
    isUserAdmin ||
    doesCurrentUserHavePermission({
      resource: 'quality_control/flag',
      ability: 'modify',
    })

  const { currentFlag, updateFlagAction } = useCurrentFlagContext()

  const updateFlagRequest = useRequest(updateFlagAction, {
    onError: () => {
      setToast({
        message: t('Failed to update flag action plan'),
        variant: 'error',
      })
    },
  })

  const actionPlan = currentFlag.action_plan

  const saveActionPlanEdits = actionPlan => {
    updateFlagRequest.makeRequest(
      currentFlag.id,
      {
        flag: { action_plan: actionPlan },
      },
      defaultFlagParams
    )
  }

  return (
    <EditableContent
      label={t('Action plan')}
      onSave={actionPlan => saveActionPlanEdits(actionPlan)}
      content={actionPlan}
      ReadModeDisplay={({ startEditing }) => (
        <InputArea
          PrimaryControl={
            hasEditPermission && (
              <Button.Secondary onClick={startEditing}>
                {t('edit')}
              </Button.Secondary>
            )
          }
        >
          <pre className={styles.actionplan}>{actionPlan}</pre>
        </InputArea>
      )}
    />
  )
}

export default FlagSingleActionPlan

export const DeliveriesFlagSingleActionPlan = FlagSingleActionPlan
