import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export default props => (
  <FontAwesomeIcon
    icon={faExclamationCircle}
    {...props}
    style={{ fontSize: '16px' }}
  />
)
