export const LOAD_SIGNATURES = 'LOAD_SIGNATURES'
export const UPDATE_SIGNATURE = 'UPDATE_SIGNATURE'

export const initialState = {
  signatures: {},
  totalSignatures: 0,
  reviewedSignatures: 0,
  reviewsAdded: 0,
  maxPage: 0,
}

export function signaturesReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SIGNATURES: {
      const signaturesByPageNumber = action.payload.reduce((result, page) => {
        result[page.number] = page.signatures
        return result
      }, {})
      const signatures = Object.values(signaturesByPageNumber).flat()
      return {
        ...initialState,
        pages: action.payload.sort(
          (pageA, pageB) => pageA.number - pageB.number
        ),
        signatures: signaturesByPageNumber,
        totalSignatures: signatures.length,
        reviewedSignatures: signatures.filter(
          signature =>
            signature.visual_review_responses.length ||
            action.payload.find(page =>
              page.signatures.find(pageSig => pageSig.id === signature.id)
            ).visual_review_responses.length
        ).length,
        maxPage: Math.max(...Object.keys(signaturesByPageNumber)),
      }
    }
    case UPDATE_SIGNATURE: {
      const updatedSignature = action.payload
      const { signatures, reviewedSignatures, reviewsAdded } = state

      const pageNumber = updatedSignature.page.number

      let addedReview = false
      const page = signatures[pageNumber].map(signature => {
        const isRelevantSignature = signature.id === updatedSignature.id
        if (isRelevantSignature) {
          if (
            !signature.visual_review_responses.length &&
            updatedSignature.visual_review_responses.length
          ) {
            addedReview = true
          }
        }
        return isRelevantSignature ? updatedSignature : signature
      })

      return {
        ...state,
        signatures: { ...signatures, [pageNumber]: page },
        reviewedSignatures: addedReview
          ? reviewedSignatures + 1
          : reviewedSignatures,
        reviewsAdded: addedReview ? reviewsAdded + 1 : reviewsAdded,
      }
    }
    default:
      return state
  }
}

export const loadSignatures = signatures => ({
  type: LOAD_SIGNATURES,
  payload: signatures,
})

export const updateSignature = signature => ({
  type: UPDATE_SIGNATURE,
  payload: signature,
})
