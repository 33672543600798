import { LoadBar, CardError } from 'components'
import {
  ButtonBlock,
  Button,
  ContentBlock,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRoutePathParams, useReactRouter, useRequest } from 'hooks'
import { useState, useContext } from 'react'
import CopyToTurfOptions from './CopyToTurfOptions'
import { CallScriptsContext } from '../CallScriptsContext'

const ScriptEditControls = ({ scriptLoading, scriptLoadError }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [applyToOtherTurfs, setApplyToOtherTurfs] = useState(false)
  const goBackToSelect = () => history.push(`/quality_control/call_scripts`)
  const {
    setPendingScriptStructureToDefault,
    pendingScript: { language },
    clearPendingScript,
    createScript,
    updateScript,
  } = useContext(CallScriptsContext)

  const onCancel = () => {
    clearPendingScript()
    goBackToSelect()
  }

  const [{ scriptId }] = useRoutePathParams()

  const saveScriptCallback = scriptId === 'new' ? createScript : updateScript

  const {
    makeRequest: saveScript,
    isLoading: scriptSaving,
    hasErrors: scriptSaveHasErrors,
  } = useRequest(saveScriptCallback, { onSuccess: goBackToSelect })

  const isLoading = scriptLoading || scriptSaving
  const hasErrors = scriptLoadError || scriptSaveHasErrors

  return (
    <>
      <CardError
        hide={!hasErrors}
        hideSupportLink
        message={
          scriptLoadError
            ? 'There was a problem loading this script'
            : 'There was a problem saving this script'
        }
      />
      <LoadBar show={isLoading} />
      <div className="pv-scripts__controls">
        <div className="pv-scripts__controls__primary-controls">
          <ButtonBlock>
            <Button.Secondary
              onClick={() => setPendingScriptStructureToDefault(language)}
            >
              {t('Reset to default')}
            </Button.Secondary>
          </ButtonBlock>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={onCancel}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent onClick={() => saveScript(applyToOtherTurfs)}>
              {t('Save')}
            </Button.Accent>
          </ButtonBlock>
        </div>
        <ContentBlock>
          <Checkbox
            checked={applyToOtherTurfs}
            name="apply-to-turf-checkbox"
            onChange={() => setApplyToOtherTurfs(!applyToOtherTurfs)}
            id="apply-to-turf-checkbox"
            label={t('Apply changes to other turfs')}
          />
        </ContentBlock>
        {applyToOtherTurfs && <CopyToTurfOptions />}
      </div>
    </>
  )
}

export default ScriptEditControls
