import { Switch, Route } from 'react-router-dom'
import MeetingNew from '../MeetingNew/MeetingNew'
import MeetingEditForm from '../MeetingEditForm/MeetingEditForm'
import MeetingSelect from '../MeetingSelect/MeetingSelect'
import MeetingSingle from '../MeetingSingle/MeetingSingle'

const MeetingRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={MeetingSelect} />
    <Route exact path={`${match.url}/new`} component={MeetingNew} />
    <Route exact path={`${match.url}/:id`} component={MeetingSingle} />
    <Route exact path={`${match.url}/:id/edit`} component={MeetingEditForm} />
  </Switch>
)

export default MeetingRoutes
