import { Section } from 'components/'
import PledgeCardTile from 'qualityControl/pledgeCardGallery/PledgeCardTile/PledgeCardTile'
import { usePledgeCardGalleryState } from 'qualityControl/pledgeCardGallery/PledgeCardGalleryContext/PledgeCardGalleryContext'
import styles from 'qualityControl/pledgeCardGallery/PledgeCardGallery.module.scss'

const PledgeCardSelect = () => {
  const { packetScans } = usePledgeCardGalleryState()

  return (
    <Section>
      <div className={styles.grid}>
        {packetScans.map(scan => (
          <PledgeCardTile scan={scan} key={scan.id} />
        ))}
      </div>
    </Section>
  )
}

export default PledgeCardSelect
