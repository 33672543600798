import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Font,
  Modal,
} from '@politechdev/blocks-design-system'

const CancelDataEntryModal = ({ isOpen, setIsOpen, onConfirm }) => {
  const { t } = useTranslation()

  const onApply = () => {
    onConfirm()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      id="warn-cancel-data-entry"
      title={t('Are you sure you want to leave?')}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <Font.Copy Element="p">
              {t('You will lose any changes made on the current form.')}
            </Font.Copy>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>
            {t('Continue data entry')}
          </Button.Secondary>
          <Button.Danger onClick={onApply}>
            {t('Leave data entry')}
          </Button.Danger>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default CancelDataEntryModal
