import { createContext, useContext, useEffect, useRef } from 'react'

const ScrollContext = createContext()

export const useScroll = ({ speed = 1, targetRef } = {}) => {
  const context = useContext(ScrollContext)
  if (context) return context

  const intervalRef = useRef()
  const directionRef = useRef()

  const endScroll = () => {
    intervalRef.current && clearInterval(intervalRef.current)
    intervalRef.current = null
    directionRef.current = null
  }

  const startScroll = direction => {
    const directionChange = directionRef.current !== direction

    if (intervalRef.current && directionChange) {
      clearInterval(intervalRef.current)
    }

    if (directionChange) {
      directionRef.current = direction
      intervalRef.current = setInterval(() => {
        const scrollArg = { top: (direction === 'up' ? -1 : 1) * speed }

        if (targetRef?.current?.scrollBy) {
          targetRef.current.scrollBy(scrollArg)
        } else {
          window.scrollBy(scrollArg)
        }
      }, 1)
    }
  }

  useEffect(() => () => clearInterval(intervalRef.current), [])

  return {
    startScroll,
    endScroll,
    targetRef,
  }
}

export const ScrollProvider = ({ children, ...options }) => (
  <ScrollContext.Provider value={useScroll(options)}>
    {children}
  </ScrollContext.Provider>
)
