import GalleryScan from '../GalleryScan/GalleryScan'
import GalleryCarousel from '../GalleryCarousel/GalleryCarousel'
import styles from './GalleryCompare.module.scss'

const GalleryCompare = ({ cover, scans, selectedScanNums }) => (
  <div className={styles.compare}>
    <div className={styles.container}>
      <div className={styles.scans}>
        {selectedScanNums.map(scanNumber =>
          scanNumber === 'cover' ? (
            <GalleryScan
              cover={cover}
              removedAt={cover?.file_locator?.metadata?.redacted_at}
              width={500}
              labelPosition="top"
              url="?scans[]=cover"
              isTitle
            />
          ) : (
            <GalleryScan
              scan={scans.find(s => `${s.scan_number}` === scanNumber)}
              removedAt={
                scans.find(s => `${s.scan_number}` === scanNumber)?.file_locator
                  ?.redacted_at
              }
              width={500}
              labelPosition="top"
              url={`?scans[]=${scanNumber}`}
              isTitle
            />
          )
        )}
      </div>
    </div>
    <GalleryCarousel
      cover={cover}
      scans={scans}
      selectedScanNums={selectedScanNums}
    />
  </div>
)

export default GalleryCompare
