import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  FieldBlock,
  Section,
  Sheet,
  TextField,
  Tab,
  PageHeader,
} from '@politechdev/blocks-design-system'
import {
  QueryParamProvider,
  useQueryParams,
  PaginationProvider,
  usePagination,
} from 'contexts'
import { fetchPhoneBanks } from 'requests/phoneBanks'
import { useRequest } from 'hooks'
import { useHistory } from 'react-router-dom'
import PhoneBankList from '../components/PhoneBankList'
import styles from './PhoneBankSelect.module.scss'

const PhoneBankSelect = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    makeRequest: phoneBanksReq,
    isLoading: isPhoneBanksReqLoading,
    hasErrors: hasPhoneBanksError,
    response: phoneBanksResponse,
  } = useRequest(fetchPhoneBanks)

  const phoneBanks = phoneBanksResponse?.phone_banks
  const meta = phoneBanksResponse?.meta

  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [currentTabId, setCurrentTabId] = useState('active')
  const [rules, setRules] = useState({
    current_page: 1,
    per: 50,
    sort_attr: 'start_date',
    sort_dir: 'desc',
    filters: {
      rules: [
        {
          column: 'archived',
          operator: 'is',
          param: false,
        },
      ],
    },
  })

  const {
    queryParams: { searchValue },
    setQueryParams,
  } = useQueryParams()

  const setSearchValue = newSearchValue => {
    setQueryParams({ searchValue: newSearchValue })
  }

  const { page, setPage } = usePagination(true)

  const quickSearchFilterRule = input => {
    if (!input) return null

    return {
      column: 'name',
      operator: 'containing',
      param: input,
    }
  }

  useEffect(() => {
    if (meta && meta.current_page !== 1) {
      setData(data.concat(phoneBanks))
    } else {
      setData(phoneBanks || [])
    }
  }, [phoneBanks])

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setSearchValue(search)
      setPage(1)
    }, 1500)

    return () => clearTimeout(delayDebounce)
  }, [search])

  useEffect(() => {
    phoneBanksReq(rules)
  }, [rules])

  useEffect(() => {
    setRules({
      ...rules,
      filters: {
        rules: [
          {
            column: 'archived',
            operator: 'is',
            param: currentTabId === 'archived',
          },
          ...[quickSearchFilterRule(searchValue)].filter(Boolean),
        ],
        conjunction: 'exclusive',
      },
      current_page: page,
    })
  }, [searchValue, page, currentTabId])

  const routeToNew = () => {
    history.push('/organize/phone_banks/new')
  }

  const copyPhoneBank = () => {
    history.push('/organize/phone_banks/copy')
  }

  const routeToSingle = id => {
    history.push(`/organize/phone_banks/${id}`)
  }

  return (
    <View>
      <PageHeader title={t('Phone banks')}>
        <ButtonBlock justify="right">
          <Button.Accent onClick={routeToNew}>{t('New')}</Button.Accent>
          <Button onClick={copyPhoneBank}>{t('Copy')}</Button>
        </ButtonBlock>
      </PageHeader>
      <CardError
        hide={!hasPhoneBanksError}
        message={t('There was an error loading your phone banks')}
      />
      <FieldBlock>
        <TextField
          name="search_text"
          id="search_text"
          type="search"
          label={t('Phone bank or list name')}
          value={search}
          onChange={val => {
            setSearch(val)
          }}
          loading={isPhoneBanksReqLoading}
        />
      </FieldBlock>
      <ViewContainer className={styles.container}>
        <Sheet key="select-list">
          <Tab.Container
            selectedTabId={currentTabId}
            onChange={({ tabId }) => {
              setCurrentTabId(tabId)
            }}
          >
            <Tab label={t('Active')} id="active">
              {data?.length || isPhoneBanksReqLoading ? (
                <PhoneBankList
                  phoneBanks={
                    data ? data.filter(phoneBank => !phoneBank.archived) : []
                  }
                  onClickPhoneBank={routeToSingle}
                  isLoading={isPhoneBanksReqLoading}
                />
              ) : (
                <Section label={t('Ready to set up a phone bank?')}>
                  <TextBlock>
                    {t(
                      'Phone banks help you with voter outreach by creating a script and having volunteers call a list of voters to collect pertinant information about them and motivate them on key issues.'
                    )}
                  </TextBlock>
                  <ButtonBlock>
                    <Button.Accent onClick={routeToNew}>
                      {t('Create a phone bank')}
                    </Button.Accent>
                  </ButtonBlock>
                </Section>
              )}
            </Tab>
            <Tab label={t('Archived')} id="archived">
              <PhoneBankList
                phoneBanks={
                  data ? data.filter(phoneBank => phoneBank.archived) : []
                }
                onClickPhoneBank={routeToSingle}
                isLoading={isPhoneBanksReqLoading}
              />
            </Tab>
          </Tab.Container>
        </Sheet>
      </ViewContainer>
      {meta && meta.current_page < meta.total_count / meta.per && (
        <ButtonBlock justify="center">
          <Button
            className="triggered-scans-list__show-more-button"
            onClick={() => setPage(page + 1)}
          >
            {t('View more')}
          </Button>
        </ButtonBlock>
      )}
    </View>
  )
}

export default () => (
  <QueryParamProvider>
    <PaginationProvider>
      <PhoneBankSelect />
    </PaginationProvider>
  </QueryParamProvider>
)
