import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useKeyboardNav } from '../KeyboardNavProvider/KeyboardNavProvider'
import styles from './Item.module.scss'

const cx = classNames.bind(styles)

const Item = ({
  label,
  icon,
  onClick,
  onKeyDown,
  onMouseEnter,
  isDisabled,
  isHidden,
  className,
  ...props
}) => {
  const itemRef = useRef(null)
  const { addItem, setPosition } = useKeyboardNav()

  const handleRef = item => {
    if (!isDisabled) {
      addItem(item)
    }
    itemRef.current = item
  }

  const handleClick = event => {
    isDisabled ? event.stopPropagation() : onClick(event)
  }

  const handleMouseEnter = event => {
    onMouseEnter && onMouseEnter(event)
    if (!isDisabled) {
      setPosition(itemRef.current)
    }
  }

  const handleKeyDown = event => {
    onKeyDown && onKeyDown(event)
    if (event.key === 'Enter') {
      onClick(event)
    }
  }

  if (isHidden) return null

  return (
    <div
      role="menuitem"
      ref={handleRef}
      className={cx(
        'item',
        {
          'item--disabled': isDisabled,
        },
        className
      )}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseEnter={handleMouseEnter}
      aria-disabled={isDisabled}
      tabIndex={-1}
      {...props}
    >
      <div className={styles.content}>
        {icon && <div className={styles.icon}>{icon}</div>}
        {label && <span className={styles.label}>{label}</span>}
      </div>
    </div>
  )
}

Item.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  className: PropTypes.string,
}

Item.defaultProps = {
  onClick: () => {},
  isDisabled: false,
  isHidden: false,
}

export default Item
