import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, SelectField } from '@politechdev/blocks-design-system'
import ArchivedItem from './ArchivedItem'

const SubTurf = ({
  activeTurfs,
  setActiveTurfs,
  turfs,
  activeTurf,
  index,
  label,
  disabled,
  isLoading,
  disableArchived,
  required,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    setActiveTurfs(currentActiveTurfs => {
      const lastTurf = activeTurfs[activeTurfs.length - 1]
      if (lastTurf.turfId !== activeTurf.turfId) {
        return currentActiveTurfs
      }
      const nextActiveTurfs = [...currentActiveTurfs]
      const childTurfs = turfs.filter(
        turf => turf.parent_id === activeTurf.turfId
      )
      if (activeTurf.turfId !== null && childTurfs.length) {
        nextActiveTurfs.push({
          turfId: null,
        })
      }
      return nextActiveTurfs
    })
  }, [activeTurf])

  const triggerActiveTurfUpdate = selectedTurfId =>
    setActiveTurfs(prevActiveTurfs => {
      const nextActiveTurfs = [...prevActiveTurfs]
      nextActiveTurfs[index] = { ...activeTurf, turfId: selectedTurfId }
      return nextActiveTurfs
    })

  const formatTurfOptions = turf => ({
    ...turf,
    key: turf.value,
    styledLabel: turf.archived && (
      <ArchivedItem label={turf.name} disabled={disabled} />
    ),
    disabled: turf.archived && disableArchived,
    onClick: () => triggerActiveTurfUpdate(turf.id),
  })

  const parentTurf = activeTurfs[index - 1]
  const turfOptions =
    parentTurf !== undefined
      ? turfs
          .filter(turf => turf.parent_id === parentTurf.turfId)
          .map(formatTurfOptions)
      : turfs
          .filter(turf => !turfs.find(t => turf.parent_id === t.id))
          .map(formatTurfOptions)

  return (
    <>
      <SelectField
        id="turf"
        label={index === 0 ? label || t('Turf') : t('Sub turf')}
        options={turfOptions}
        value={activeTurf.turfId}
        disabled={index !== activeTurfs.length - 1 || disabled}
        onSelect={selectedTurfId => {
          setActiveTurfs(prevActiveTurfs => {
            const nextActiveTurfs = [...prevActiveTurfs]
            nextActiveTurfs[index] = { ...activeTurf, turfId: selectedTurfId }
            return nextActiveTurfs
          })
        }}
        isLoading={isLoading}
        required={required}
      />
      {activeTurfs.length > 1 && index === activeTurfs.length - 1 && (
        <Button.Secondary
          onClick={() => {
            setActiveTurfs(prevActiveTurfs =>
              prevActiveTurfs.filter(
                prevActiveTurf => prevActiveTurf.turfId !== activeTurf.turfId
              )
            )
          }}
          aria-label={t('clear')}
        >
          <Icon.Times />
        </Button.Secondary>
      )}
    </>
  )
}

export default SubTurf
