import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import { CSSTransitionGroup } from 'react-transition-group'
import { useFilters } from 'contexts'

const TableFilters = ({
  headerLeft,
  headerRight,
  simple,
  children,
  handleResetClick,
}) => {
  const { t } = useTranslation()
  const { isFiltered } = useFilters()
  const [expanded, setExpanded] = useState(isFiltered)

  useEffect(() => {
    if (isFiltered) {
      setExpanded(true)
    }
  }, [isFiltered])

  return (
    <div className="table-filters__wrapper">
      <div className="table-filters__header__actions">
        <FieldBlock>{headerLeft}</FieldBlock>
        <div className="table-filters__header__actions--right">
          {headerRight}
        </div>
      </div>
      {!expanded ? (
        !simple && (
          <ButtonBlock>
            <Button.Secondary onClick={() => setExpanded(true)}>
              <span>{t('Filter')}</span>
              <Icon.Filter />
            </Button.Secondary>
            {isFiltered && (
              <>
                <Icon.ExclamationCircle />
                <span>{t('Filters applied')}</span>
              </>
            )}
          </ButtonBlock>
        )
      ) : (
        <ButtonBlock>
          <Button.Secondary onClick={() => setExpanded(false)}>
            <span>{t('Hide filters')}</span>
            <Icon.ArrowUp />
          </Button.Secondary>
        </ButtonBlock>
      )}

      <CSSTransitionGroup
        transitionName="slide"
        transitionEnterTimeout={150}
        transitionLeaveTimeout={150}
      >
        {expanded && !simple && (
          <div className="table-filters__body__wrapper">
            <div className="table-filters__body" key="table-filters__body">
              {isFiltered && (
                <ButtonBlock>
                  <Button.Secondary onClick={handleResetClick}>
                    <span>{t('Clear all')}</span>
                    <Icon.Undo />
                  </Button.Secondary>
                </ButtonBlock>
              )}
              {children}
            </div>
          </div>
        )}
      </CSSTransitionGroup>
    </div>
  )
}

export default TableFilters

TableFilters.propTypes = {
  headerRight: PropTypes.node,
  headerLeft: PropTypes.node,
  simple: PropTypes.bool,
}

TableFilters.defaultProps = {
  headerRight: undefined,
  headerLeft: undefined,
  simple: false,
}
