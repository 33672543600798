import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import {
  Button,
  ButtonBlock,
  TextBlock,
  ProgressBar,
  ContentBlock,
} from 'components'
import { useAutoTable, useFilters } from 'contexts'
import { useRequest } from 'hooks'
import { uniqBy } from 'lodash'
import { buildCsvData, buildCsvColumns } from '../utils'
import styles from './DownloadPanel.module.scss'

const DOWNLOAD_PAGE_SIZE = 100

const DownloadPanel = ({ data, columns, filename, totalRecords }) => {
  const { t } = useTranslation()
  const [pagedData, setPagedData] = useState([])
  const [page, setPage] = useState(1)
  const [isComplete, setComplete] = useState(false)
  const [failed, setFailed] = useState(false)

  const pageCount = Math.ceil(totalRecords / DOWNLOAD_PAGE_SIZE)

  const progressPercent =
    totalRecords > 0 ? (pagedData.length / totalRecords) * 100 : 100

  const { tableId, requestPage, refreshPage, errorMsg, finishDownloading } =
    useAutoTable()
  const { hiddenColumns } = useFilters()

  const completeProcess = () => {
    if (isComplete) return
    setComplete(true)
    refreshPage()
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest } = useRequest(requestPage, {
    onSuccess: () => {
      setPage(page => (page < pageCount ? page + 1 : page))
    },
  })

  useEffect(() => {
    if (progressPercent === 100) {
      completeProcess()
    }
  }, [progressPercent])

  useEffect(() => {
    if (data.length === 0) {
      completeProcess()
    } else {
      setPagedData(existing => uniqBy([...existing, ...data], item => item.id))
    }
  }, [data])

  useEffect(() => {
    if (errorMsg) {
      setFailed(true)
      completeProcess()
    }
  }, [errorMsg])

  useEffect(() => {
    makeRequest({ page, pageSize: DOWNLOAD_PAGE_SIZE })
  }, [page])

  if (!isComplete && !failed) {
    return (
      <div className={styles.panel}>
        <TextBlock>
          <span className={styles.title}>{t('Building your download')}</span>
          <span className={styles.subtitle}>
            {Math.round(progressPercent)}% {t('complete')}
          </span>
        </TextBlock>
        <ContentBlock className={styles.progress}>
          <ProgressBar value={progressPercent} />
        </ContentBlock>
        <div>
          <ButtonBlock>
            <Button onClick={finishDownloading}>{t('Cancel')}</Button>
          </ButtonBlock>
        </div>
      </div>
    )
  }

  if (isComplete && !failed) {
    return (
      <div className={styles.panel}>
        <TextBlock>
          <span className={styles.title}>{t('Your download is ready')}</span>
          <span className={styles.subtitle}>
            {pagedData.length === 1
              ? `${pagedData.length} ${t('row included')}`
              : `${pagedData.length} ${t('rows included')}`}
          </span>
        </TextBlock>
        <div>
          <ButtonBlock justify="center">
            <CSVLink
              uFEFF={false}
              data={buildCsvData(pagedData, columns)}
              headers={buildCsvColumns(columns, hiddenColumns)}
              filename={filename || `${tableId}.csv`}
            >
              <Button accent raised>
                {t('Download')}
              </Button>
            </CSVLink>
          </ButtonBlock>
          <ButtonBlock justify="center">
            <Button onClick={finishDownloading}>{t('Close')}</Button>
          </ButtonBlock>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.panel}>
      <TextBlock>
        <span className={styles.title}>
          {t('There was an error building your download')}
        </span>
        <span className={styles.subtitle}>{t('Please try again later')}</span>
      </TextBlock>
      <div>
        <ButtonBlock>
          <Button onClick={finishDownloading}>{t('Close')}</Button>
        </ButtonBlock>
      </div>
    </div>
  )
}

export default DownloadPanel
