import moment from 'moment'
import { ImportsDateCell } from './ImportsTableCells'

export default {
  label: 'imports',
  defaultSortOrder: 'desc',
  defaultSortColumn: 'created_at',
  columns: [
    {
      dataKey: 'filename',
      title: 'Import name',
      type: 'string',
    },
    {
      dataKey: 'created_at',
      title: 'Date imported',
      type: 'date',
      filterConstructor: rule => ({
        ...rule,
        param: moment(rule.param).format('YYYY-MM-DDT00:00:00'),
      }),
      bodyCell: ImportsDateCell,
    },
    {
      dataKey: 'status',
      title: 'Status',
      type: 'text',
    },
    {
      dataKey: 'error_rows_count',
      title: 'Issues',
      type: 'number',
    },
  ],
}
