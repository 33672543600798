import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from 'components'
import {
  Font,
  TextBlock,
  ButtonBlock,
  Button,
  Sheet,
  TextField,
  Section,
  FieldBlock,
  ProgressBar,
  SectionLabel,
} from '@politechdev/blocks-design-system'
import useCheckins from 'checkins/hooks/useCheckins'
import { formatLocal } from 'utils/formatting'
import styles from './QuestionContainer.module.scss'

const QuestionContainer = () => {
  const { t } = useTranslation()

  const {
    currentUserCheckin,
    currentUserCheckinAnswers,
    setAnswerKey,
    isLoading,
  } = useCheckins()

  const [responses, setResponses] = useState([])

  useEffect(() => {
    setResponses(currentUserCheckinAnswers)
  }, [currentUserCheckinAnswers])

  const currentAnswerTimestamps = currentUserCheckinAnswers
    .filter(a => !a.question.archived)
    .flatMap(x => [moment(x.created_at), moment(x.updated_at)])

  const latestTimestamp = moment.max(currentAnswerTimestamps)

  const [isResubmitting, setIsResubmitting] = useState(false)

  const checkinActiveToday = () => {
    const weekMap = [
      {
        id: 0,
        label: 'Sunday',
      },
      {
        id: 1,
        label: 'Monday',
      },
      {
        id: 2,
        label: 'Tuesday',
      },
      {
        id: 3,
        label: 'Wednesday',
      },
      {
        id: 4,
        label: 'Thursday',
      },
      {
        id: 5,
        label: 'Friday',
      },
      {
        id: 6,
        label: 'Saturday',
      },
    ]
    const activeDaysByName = weekMap
      .filter(weekObj =>
        currentUserCheckin?.days_of_the_week.includes(weekObj.id)
      )
      .map(weekObj => weekObj.label)
    const today = moment().format('dddd')

    return activeDaysByName.includes(today)
  }

  const checkinStillActive = () => {
    const today = moment().format()
    const endDate = currentUserCheckin?.end_date
    return !endDate || moment(today).isSameOrBefore(endDate, 'day')
  }

  const handleOnchange = (val, questionId) => {
    const rIndex = responses.findIndex(r => r.question.id === questionId)
    if (rIndex !== -1) {
      const newResponses = responses
      const splicedEl = newResponses.splice(rIndex, 1)
      newResponses.push({
        ...splicedEl[0],
        text: val,
      })
      setResponses([...newResponses])
    } else {
      const newAnswer = {
        text: val,
        question: { id: questionId },
      }
      setResponses(prev => [...prev, newAnswer])
    }
  }

  const hasActiveCheckIn =
    currentUserCheckin?.questions.filter(x => !x.archived).length &&
    !currentUserCheckin?.archived &&
    checkinActiveToday() &&
    checkinStillActive()

  return (
    <ViewContainer>
      <Sheet>
        <ProgressBar show={isLoading} />
        <Section
          empty={!hasActiveCheckIn && !isLoading}
          emptyMessage={t('There is no check-in posted today for this turf')}
        >
          {(isResubmitting || !currentAnswerTimestamps.length) && !isLoading ? (
            <div className={styles['question-container']}>
              {currentUserCheckin?.questions
                .filter(q => !q.archived)
                .sort((a, b) => a.position - b.position)
                .map(question => (
                  <Section key={question.id}>
                    <TextBlock>
                      <Font.Copy variant="highlight">{question.text}</Font.Copy>
                    </TextBlock>
                    <FieldBlock variant="large">
                      <TextField
                        id={`question-${question.id}`}
                        label={t('Answer')}
                        value={
                          responses[
                            responses.findIndex(
                              x => x.question.id === question.id
                            )
                          ]?.text
                        }
                        onChange={val => {
                          handleOnchange(val, question.id)
                        }}
                      />
                    </FieldBlock>
                  </Section>
                ))}
              <ButtonBlock>
                <Button.Accent
                  disabled={isLoading}
                  onClick={() => {
                    setAnswerKey(responses)
                    setIsResubmitting(false)
                  }}
                >
                  {t('Submit')}
                </Button.Accent>
              </ButtonBlock>
            </div>
          ) : (
            !isLoading && (
              <div>
                <TextBlock>
                  <SectionLabel>
                    {t('Check-in posted sucessfully')}
                  </SectionLabel>
                </TextBlock>
                <TextBlock>{t('Your check-in has been submitted')}</TextBlock>
                <ButtonBlock>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      setIsResubmitting(true)
                    }}
                  >
                    {t('Re-submit')}
                  </Button>
                </ButtonBlock>
                <TextBlock>
                  <Font.Copy variant="hint">
                    {t('Submitted at')} {formatLocal(latestTimestamp, 'time')}
                  </Font.Copy>
                </TextBlock>
              </div>
            )
          )}
        </Section>
      </Sheet>
    </ViewContainer>
  )
}

export default QuestionContainer
