import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Section, DetailItem } from 'components'
import { TextBlock } from '@politechdev/blocks-design-system'
import { ORGANIZATION_TYPES } from 'constants/organizations'
import { formatPhoneNumber } from 'utils/formatting'
import { getAddressPieces } from './utils'
import styles from './OrganizationDetails.module.scss'

const OrganizationDetails = ({ currentOrganization }) => {
  const { t } = useTranslation()

  const address = currentOrganization.address || {}

  const addressPieces = getAddressPieces({
    address: address.line_one,
    city: address.city,
    state: address.state,
    zipcode: address.zipcode,
  })

  const mailingAddressPieces = getAddressPieces({
    address: currentOrganization.mailing_street_address,
    city: currentOrganization.mailing_city,
    state: currentOrganization.mailing_state,
    zipcode: currentOrganization.mailing_zipcode,
  })

  return (
    <Grid>
      <Section>
        <DetailItem label={t('Leader')}>
          {currentOrganization.leader ? (
            <Link to={`/organize/people/${currentOrganization.leader?.id}`}>
              {currentOrganization.leader?.name}
            </Link>
          ) : (
            'N/A'
          )}
        </DetailItem>
        <DetailItem label={t('Address')}>
          <div className={styles.address}>
            {addressPieces.map((piece, index) => (
              <span key={index} className={styles.addressLine}>
                {piece}
              </span>
            ))}
          </div>
        </DetailItem>
        {mailingAddressPieces.length > 0 && (
          <DetailItem label={t('Mailing address')}>
            <div className={styles.address}>
              {mailingAddressPieces.map((piece, index) => (
                <span key={index}>{piece}</span>
              ))}
            </div>
          </DetailItem>
        )}
        <DetailItem
          label={t('Congressional district')}
          hide={!address.district_config?.congressional}
        >
          {address.district_config?.congressional}
        </DetailItem>
        <DetailItem
          label={t('State legislative upper district')}
          hide={!address.district_config?.state_legislative_upper}
        >
          {address.district_config?.state_legislative_upper}
        </DetailItem>
        <DetailItem
          label={t('State legislative lower district')}
          hide={!address.district_config?.state_legislative_lower}
        >
          {address.district_config?.state_legislative_lower}
        </DetailItem>
        {currentOrganization.phone_number && (
          <DetailItem label={t('Phone number')}>
            {formatPhoneNumber(currentOrganization.phone_number)}
          </DetailItem>
        )}
        {currentOrganization.website && (
          <DetailItem label={t('Website')}>
            {currentOrganization.website}
          </DetailItem>
        )}
      </Section>
      <Section>
        <DetailItem label={t('Organization type')}>
          {ORGANIZATION_TYPES[currentOrganization.organization_type]?.label}
        </DetailItem>
        {currentOrganization.soft_member_count !== null && (
          <DetailItem label={t('Members reported')}>
            {currentOrganization.soft_member_count}
          </DetailItem>
        )}
        {currentOrganization.members_count !== null && (
          <DetailItem label={t('Members on Blocks')}>
            {currentOrganization.members_count}
          </DetailItem>
        )}
        {currentOrganization.influence_level !== null && (
          <DetailItem label={t('Influence level')}>
            {currentOrganization.influence_level}
          </DetailItem>
        )}
        {currentOrganization.issues.length > 0 && (
          <DetailItem label={t('Focus')}>
            {currentOrganization.issues.join(', ')}
          </DetailItem>
        )}
        <DetailItem label={t('Status')}>
          {currentOrganization.active ? t('Active') : t('Inactive')}
        </DetailItem>
      </Section>
      <Section
        label="Notes"
        empty={!currentOrganization.notes}
        emptyMessage="No notes"
      >
        <TextBlock>{currentOrganization.notes}</TextBlock>
      </Section>
    </Grid>
  )
}

export default OrganizationDetails
