import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SelectField,
  FieldBlock,
  Checkbox,
  Font,
  Icon,
} from '@politechdev/blocks-design-system'
import { RolesLocalContext, RoleModalFormContext } from '../RoleModal'
import RoleModalUpdateContent from '../RoleModalUpdate'
import { useRoleModalState } from '../../RoleModalStateProvider/RoleModalStateProvider'
import styles from './RoleModalCreate.module.scss'

const RoleModalCreateContent = () => {
  const { t } = useTranslation()
  const { formData, setField } = useContext(RoleModalFormContext)
  const { roles } = useContext(RolesLocalContext)
  const { actionLabel } = useRoleModalState()

  const roleOptions = roles.map(role => ({
    label: role.name,
    styledLabel: (
      <div
        className={styles['role-name']}
        style={{ paddingLeft: `${10 * (role.depth - 0)}px` }}
      >
        {role.depth > 0 && <Icon.SubdirectoryArrowRight />}
        <Font.Copy>{role.name}</Font.Copy>
      </div>
    ),
    value: role.id,
  }))

  return (
    <>
      <RoleModalUpdateContent />
      <FieldBlock>
        <SelectField
          id={`roleParent${actionLabel}`}
          label={t('Role parent')}
          required
          options={roleOptions}
          value={formData.parent_id}
          onSelect={val => setField(val, 'parent_id')}
        />
      </FieldBlock>
      <FieldBlock>
        <Checkbox
          id="training"
          name="training"
          label={t('Available for QC training')}
          checked={formData.needs_training}
          onChange={val => setField(val, 'needs_training')}
        />
      </FieldBlock>
    </>
  )
}

export default RoleModalCreateContent
