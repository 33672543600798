import { useState } from 'react'

const useFormListItem = (form, handleExclude, handleInclude) => {
  const {
    county,
    notes,
    phone_verification_responses,
    scan_number,
    shift,
    id,
    delivery_id,
    flags,
  } = form
  const personName = form.form ? form.form.display_name : null
  const [notesTabIsOpen, setNotesTabIsOpen] = useState(false)
  const allNotes = notes ? [{ source: 'Visual QC', note: notes }] : []
  if (phone_verification_responses.length) {
    phone_verification_responses.forEach(
      res =>
        res.notes &&
        allNotes.push({ source: 'Phone Verification', note: res.notes })
    )
  }
  const noteCount = allNotes.length
  const notesButtonLabel = notesTabIsOpen ? 'Hide notes' : noteCount
  const ineligibleLabel = delivery_id
    ? `Attached to delivery #${delivery_id}`
    : "Doesn't match criteria"
  const hasIssues = !!flags.filter(flag => flag.status !== 'completed').length

  const exclude = () => handleExclude(form.id)

  const include = () => handleInclude(form.id)

  return {
    county,
    scan_number,
    shift,
    id,
    personName,
    notesTabIsOpen,
    setNotesTabIsOpen,
    allNotes,
    notesButtonLabel,
    ineligibleLabel,
    hasIssues,
    exclude,
    include,
  }
}

export default useFormListItem
