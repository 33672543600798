import { defaultTo } from 'lodash'
import { createContext, useMemo, useState } from 'react'
import { fetchTurfs, changeTurfParent } from 'requests/turfs'

export const TurfContext = createContext()

const TurfContextProvider = ({ children }) => {
  const [activeParentId, setActiveParentId] = useState(null)
  const isActiveParent = parentId => activeParentId === parentId

  const [unsortedTurfs, setTurfs] = useState([])
  const turfs = useMemo(
    () => unsortedTurfs.sort((a, b) => a.lft - b.lft),
    [unsortedTurfs]
  )
  const rootTurfs = useMemo(
    () =>
      turfs.filter(
        turf => !turfs.find(t => t.lft < turf.lft && t.rgt > turf.rgt)
      ),
    [turfs]
  )
  const getTurf = turfId =>
    defaultTo(
      turfs.find(turf => turf.id === turfId),
      {}
    )
  const getDirectChildTurfs = turfId =>
    turfs.filter(turf => turf.parent_id === turfId)
  const getChildTurfs = parentId => {
    const parentTurf = getTurf(parentId)
    return turfs.filter(
      turf => turf.lft > parentTurf.lft && turf.rgt < parentTurf.rgt
    )
  }
  const hasChildren = turfId => {
    const turf = getTurf(turfId)
    return turf.rgt - turf.lft > 1
  }

  const requestTurfs = async params => {
    const { turfs: incomingTurfs } = await fetchTurfs(params)
    setTurfs(incomingTurfs)
  }
  const moveTurf = async ({ turfId, parentId, prevId, nextId }) => {
    const activeTurf = getTurf(turfId)
    const childTurfs = getChildTurfs(turfId)

    if (
      !parentId ||
      [parentId, nextId, prevId].includes(turfId) ||
      childTurfs.find(turf => [turfId, parentId].includes(turf.id))
    ) {
      return
    }

    let newPosition
    if (prevId) {
      newPosition = turfs.find(turf => turf.id === prevId).rgt + 1
    }
    if (!newPosition && nextId) {
      newPosition = turfs.find(turf => turf.id === nextId).lft
    }
    if (!newPosition && parentId) {
      newPosition = turfs.find(turf => turf.id === parentId).lft + 1
    }
    if (!newPosition) return

    const width = activeTurf.rgt - activeTurf.lft + 1
    let distance = newPosition - activeTurf.lft
    let oldPosition = activeTurf.lft

    if (distance < 0) {
      distance -= width
      oldPosition += width
    }

    if (newPosition !== oldPosition) {
      const updatedTurfs = turfs
        .map(turf => ({
          ...turf,
          lft: turf.lft >= newPosition ? turf.lft + width : turf.lft,
          rgt: turf.rgt >= newPosition ? turf.rgt + width : turf.rgt,
        }))
        .map(turf => {
          if (turf.lft >= oldPosition && turf.rgt < oldPosition + width) {
            return {
              ...turf,
              lft: turf.lft + distance,
              rgt: turf.rgt + distance,
            }
          }
          return turf
        })
        .map(turf => ({
          ...turf,
          lft: turf.lft > oldPosition + width - 1 ? turf.lft - width : turf.lft,
          rgt: turf.rgt > oldPosition + width - 1 ? turf.rgt - width : turf.rgt,
          parent_id: turf.id === activeTurf.id ? parentId : turf.parent_id,
        }))
      setTurfs(updatedTurfs)
    }
    setActiveParentId(parentId)
  }

  const placeTurf = async (turfId, newParentId) => {
    setActiveParentId(null)
    await changeTurfParent({ turfId, newParentId })
  }

  const contextValues = useMemo(
    () => ({
      activeParentId,
      setActiveParentId,
      isActiveParent,
      turfs,
      setTurfs,
      rootTurfs,
      getTurf,
      getDirectChildTurfs,
      getChildTurfs,
      hasChildren,
      requestTurfs,
      moveTurf,
      placeTurf,
    }),
    [activeParentId, rootTurfs]
  )

  return (
    <TurfContext.Provider value={contextValues}>
      {children}
    </TurfContext.Provider>
  )
}

export default TurfContextProvider
