import { useState } from 'react'
import PropTypes from 'prop-types'
import { Font, Icon, Button } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import styles from './ScanReviewSection.module.scss'

const StatusBadge = ({ label, Icon }) => (
  <div data-testid="status">
    <Font.Label variant="detail">
      <Icon />
      <span>{label}</span>
    </Font.Label>
  </div>
)

const ScanReviewSection = ({ children, approved, initExpanded }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(initExpanded || false)
  const reviewStatusMap = {
    approved: {
      label: t('Approved'),
      icon: Icon.Check,
    },
    disapproved: {
      label: t('Issues found'),
      icon: Icon.CircleXmark,
    },
  }
  const status = approved ? 'approved' : 'disapproved'

  return (
    <div className={styles.container} data-testid="scan-review-item">
      <div className={styles.section}>
        <StatusBadge
          label={reviewStatusMap[status].label}
          Icon={reviewStatusMap[status].icon}
        />

        <Button.Secondary
          onClick={() => {
            setExpanded(!expanded)
          }}
        >
          {expanded ? t('Collapse') : t('Inspect')}
        </Button.Secondary>
      </div>
      {expanded && <div className={styles.content}>{children}</div>}
    </div>
  )
}

ScanReviewSection.propTypes = {
  initExpanded: PropTypes.bool,
}

ScanReviewSection.defaultProps = {
  initExpanded: false,
}

export default ScanReviewSection
