import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ChipBlock } from 'components'
import {
  SectionLabel,
  DateField,
  TextBlock,
  FieldBlock,
  ContentBlock,
  Checkbox,
  useToast,
} from '@politechdev/blocks-design-system'
import {
  READY_FOR_QC,
  IN_QC,
  READY_FOR_PHONE_VERIFICATION,
  IN_PHONE_VERIFICATION,
  COMPLETED,
} from 'constants/qualityControl'
import { useTurfs } from 'contexts'
import { useDeliveryActions, useDeliveryState } from './DeliveryContext'
import {
  getFilterQcStatuses,
  getEligibleFormIds,
  buildCountyOptions,
} from './utils'

const DeliveryFilters = () => {
  const { t } = useTranslation()

  const { setToast } = useToast()

  const { currentTurfs, refreshCurrentTurfs } = useTurfs()

  const {
    currentDelivery,
    pendingUpdates,
    packets,
    isDeliveryLoading,
    packetsLoading,
  } = useDeliveryState()

  const eligibleFormIds = getEligibleFormIds({
    delivery: currentDelivery,
    pendingUpdates,
    packets,
    searchTerm: '',
    currentTurfs,
    options: {
      county: false,
    },
  })

  const countyOptions = buildCountyOptions({ eligibleFormIds, packets })

  const pendingChanges = pendingUpdates.reduce(
    (combinedChanges, currentChange) => ({
      ...combinedChanges,
      ...currentChange.change,
    }),
    {}
  )

  const qcStatuses = getFilterQcStatuses(currentDelivery, pendingChanges)

  const counties =
    pendingChanges.form_filter_counties ?? currentDelivery.form_filter_counties

  const noCounty =
    pendingChanges.form_filters_no_county ??
    !!currentDelivery.form_filters_no_county

  const startDate =
    pendingChanges.form_filter_registration_date_start ??
    currentDelivery.form_filter_registration_date_start

  const endDate =
    pendingChanges.form_filter_registration_date_end ??
    currentDelivery.form_filter_registration_date_end

  const { updateDelivery, applyOptimisticDeliveryUpdate } = useDeliveryActions()

  const doUpdateDelivery = async (id, attrs) => {
    const rollback = applyOptimisticDeliveryUpdate(attrs)
    const res = await updateDelivery(id, attrs)
    if (!res.delivery) {
      setToast({
        message: t('A filter update has failed, please verify filters'),
        variant: 'error',
      })
    }
    rollback()
  }

  const deliveryRequiresQc = currentDelivery
    ? currentDelivery.office.qc_config.required_for_delivery
    : true

  const changeDateRange = (newStartDateTime, newEndDateTime) => {
    const newStartDate = newStartDateTime
      ? newStartDateTime.slice(0, newStartDateTime.indexOf('T'))
      : null

    const newEndDate = newEndDateTime
      ? newEndDateTime.slice(0, newEndDateTime.indexOf('T'))
      : null

    doUpdateDelivery(currentDelivery.id, {
      form_filter_registration_date_start: newStartDate,
      form_filter_registration_date_end: newEndDate,
    })
  }

  const setQcStatuses = statuses => {
    doUpdateDelivery(currentDelivery.id, {
      form_filter_qc_statuses: statuses,
    })
  }

  const addCounty = selectedCounty => {
    const newCounties = [...counties, selectedCounty]

    doUpdateDelivery(currentDelivery.id, {
      form_filter_counties: newCounties,
    })
  }

  const addAllCounties = () => {
    const allCounties = countyOptions
      .filter(county => !!county)
      .map(county => county.id)

    doUpdateDelivery(currentDelivery.id, {
      form_filter_counties: allCounties,
    })
  }

  const clearCounties = () => {
    doUpdateDelivery(currentDelivery.id, {
      form_filter_counties: [],
      form_filters_no_county: false,
    })
  }

  const removeCounty = selectedCounty => {
    const newCounties = counties.filter(county => county !== selectedCounty)
    doUpdateDelivery(currentDelivery.id, {
      form_filter_counties: newCounties,
      form_filters_no_county: newCounties.length ? noCounty : false,
    })
  }

  const setNoCounties = value => {
    doUpdateDelivery(currentDelivery.id, {
      form_filters_no_county: value,
    })
  }

  const changeQcStatus = (status, selected) => {
    if (selected) {
      setQcStatuses(qcStatuses.concat([status]))
      return
    }

    const filterExistingStatus = existingStatus => existingStatus !== status

    setQcStatuses(qcStatuses.filter(filterExistingStatus))
  }

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  return (
    <div className="delivery__single__filters">
      <ChipBlock
        label={t('Counties')}
        loading={isDeliveryLoading || packetsLoading}
        choices={countyOptions.filter(
          county => !counties.includes(county.id) && !!county
        )}
        selectedChips={counties}
        addChip={addCounty}
        removeChip={removeCounty}
        addAll={addAllCounties}
        removeAll={clearCounties}
        autoBlur={false}
        closeOnSelect={false}
      />
      <TextBlock className="no-county-checkbox">
        <Checkbox
          id="form_filters_no_county"
          name="Include forms without a county"
          label={t('Include forms without a county')}
          checked={noCounty}
          onChange={setNoCounties}
        />
      </TextBlock>
      <ContentBlock>
        <SectionLabel>{t('Filters')}</SectionLabel>
      </ContentBlock>
      {!currentDelivery?.office.voter_registration_config
        .uses_scan_lookup_codes && (
        <FieldBlock variant="medium">
          <DateField
            label={t('Shift start')}
            type="range"
            startDate={startDate}
            endDate={endDate}
            onChange={changeDateRange}
            onClear={changeDateRange}
            clearable
          />
        </FieldBlock>
      )}
      <FieldBlock>
        <Checkbox
          id="form_filter_ready_for_visual_qc"
          name="form_filter_ready_for_visual_qc"
          label={t('Ready for Visual QC')}
          checked={qcStatuses.includes(READY_FOR_QC)}
          disabled={deliveryRequiresQc}
          onChange={selected => changeQcStatus(READY_FOR_QC, selected)}
        />
        <Checkbox
          id="form_filter_in_visual_qc"
          name="form_filter_in_visual_qc"
          label={t('In Visual QC')}
          checked={qcStatuses.includes(IN_QC)}
          disabled={deliveryRequiresQc}
          onChange={selected => changeQcStatus(IN_QC, selected)}
        />
        <Checkbox
          id="form_filter_ready_for_phone_verification"
          name="form_filter_ready_for_phone_verification"
          label={t('Ready for Phone Verification')}
          checked={qcStatuses.includes(READY_FOR_PHONE_VERIFICATION)}
          disabled={deliveryRequiresQc}
          onChange={selected =>
            changeQcStatus(READY_FOR_PHONE_VERIFICATION, selected)
          }
        />
        <Checkbox
          id="form_filter_in_phone_verification"
          name="form_filter_in_phone_verification"
          label={t('In Phone Verification')}
          checked={qcStatuses.includes(IN_PHONE_VERIFICATION)}
          disabled={deliveryRequiresQc}
          onChange={selected => changeQcStatus(IN_PHONE_VERIFICATION, selected)}
        />
        <Checkbox
          id="form_filter_qc_completed"
          name="form_filter_qc_completed"
          label={t('QC Completed')}
          checked={deliveryRequiresQc ? true : qcStatuses.includes(COMPLETED)}
          disabled={deliveryRequiresQc}
          onChange={selected => changeQcStatus(COMPLETED, selected)}
        />
      </FieldBlock>
    </div>
  )
}

export default DeliveryFilters
