import { Button, ButtonBlock, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import useEvent from 'hooks/useEvent'
import nanoid from 'nanoid'
import { defaultTo } from 'lodash'

const NewScheduleItem = ({
  onUpdate,
  userType,
  tooltipPosition = 'right',
  lastSchedule = {},
}) => {
  const { t } = useTranslation()

  const addToSchedule = useEvent(params => {
    onUpdate({
      localId: nanoid(),
      user_type: userType,
      ...params,
    })
  })

  return (
    <ButtonBlock>
      <Button
        data-testid={`new-schedule-button-${userType
          .toLowerCase()
          .replace(/\s/, '_')}`}
        tooltipLabel={t('New item')}
        tooltipPosition={tooltipPosition}
        onClick={() =>
          addToSchedule({
            user: {},
            minutes: defaultTo(lastSchedule.minutes, 0),
          })
        }
      >
        <Icon.Plus />
      </Button>
    </ButtonBlock>
  )
}

export default NewScheduleItem
