import {
  TemplateNameCell,
  TemplateCreatorCell,
  TemplateCreateAtCell,
} from './ManageTableCells'

export default {
  label: 'Email templates',
  defaultSortOrder: 'desc',
  defaultSortColumn: 'created_at',
  columns: [
    {
      dataKey: 'name',
      title: 'Name',
      type: 'string',
      bodyCell: TemplateNameCell,
    },
    {
      dataKey: 'creator',
      title: 'Creator',
      type: 'string',
      bodyCell: TemplateCreatorCell,
      filterable: false,
      sortable: false,
    },
    {
      dataKey: 'created_at',
      title: 'Created at',
      type: 'date',
      bodyCell: TemplateCreateAtCell,
      filterable: false,
      sortable: false,
    },
  ],
}
