import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, LoadBar } from 'components'
import { useRoutePathParams, useRequest } from 'hooks'
import { fetchDocumentsThroughFolder } from 'requests/documents'
import FolderContentDocument from '../FolderContentDocument/FolderContentDocument'
import FolderContentFolder from '../FolderContentFolder/FolderContentFolder'
import PanelMessage from '../PanelMessage/PanelMessage'
import styles from './FolderContent.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const FolderContent = () => {
  const { t } = useTranslation()
  const [{ id: folderId }] = useRoutePathParams()
  const { setDocuments, documents, folders, currentFolder } =
    useContext(DocumentContext)
  const childFolders = folders.filter(
    folder => folder.parent_id === currentFolder.id
  )
  const { makeRequest, isLoading, hasErrors } = useRequest(
    fetchDocumentsThroughFolder,
    {
      onSuccess: ({ documents }) => {
        setDocuments(documents)
      },
    }
  )

  useEffect(() => {
    makeRequest(folderId)
  }, [folderId])

  const hasResults = childFolders.length > 0 || documents.length > 0

  return (
    <>
      <LoadBar show={isLoading} className={styles.loadbar} />
      <CardError
        hide={!hasErrors}
        message={t("We're unable to retrieve your documents")}
      />
      {hasResults && (
        <div className={styles.items}>
          {childFolders.map(
            folder =>
              folder.parent_id === currentFolder.id && (
                <FolderContentFolder key={folder.id} folder={folder} />
              )
          )}
          {documents.map(
            document =>
              document.folder_id === currentFolder.id && (
                <FolderContentDocument key={document.id} document={document} />
              )
          )}
        </div>
      )}
      {isLoading && !hasResults && <PanelMessage title={t('Loading')} />}
      {!isLoading && !hasResults && (
        <PanelMessage
          title={t('Empty folder')}
          message={t('There are no folders or documents in this folder')}
        />
      )}
    </>
  )
}

export default FolderContent
