import { NativeTypes } from 'react-dnd-html5-backend'

const WORD = 'DOCUMENT_TYPES/word'
const POWERPOINT = 'DOCUMENT_TYPES/powerpoint'
const EXCEL = 'DOCUMENT_TYPES/excel'
const CODE = 'DOCUMENT_TYPES/code'
const ARCHIVE = 'DOCUMENT_TYPES/archive'
const PDF = 'DOCUMENT_TYPES/pdf'
const TEXT = 'DOCUMENT_TYPES/text'
const CSV = 'DOCUMENT_TYPES/csv'
const VIDEO = 'DOCUMENT_TYPES/video'
const IMAGE = 'DOCUMENT_TYPES/image'
const AUDIO = 'DOCUMENT_TYPES/audio'
const OTHER = 'DOCUMENT_TYPES/other'

export const DOCUMENT_TYPES = {
  WORD,
  POWERPOINT,
  EXCEL,
  CODE,
  ARCHIVE,
  PDF,
  TEXT,
  CSV,
  VIDEO,
  IMAGE,
  AUDIO,
  OTHER,
}

const FOLDER = 'RESOURCE_TYPES/folder'
const DOCUMENT = 'RESOURCE_TYPES/document'

export const RESOURCE_TYPES = {
  FOLDER,
  DOCUMENT,
}

const COPY = 'CLIPBOARD_ACTIONS/copy'
const CUT = 'CLIPBOARD_ACTIONS/cut'
const PASTE = 'CLIPBOARD_ACTIONS/paste'

export const CLIPBOARD_ACTIONS = {
  COPY,
  CUT,
  PASTE,
}

const SIDEBAR_FOLDER = 'INTERACTABLE_TYPES/sidebar_folder'
const PANEL_FOLDER = 'INTERACTABLE_TYPES/panel_folder'
const PANEL_DOCUMENT = 'INTERACTABLE_TYPES/panel_document'
const PANEL_BREADCRUMB = 'INTERACTABLE_TYPES/panel_breadcrumb'
const PANEL = 'INTERACTABLE_TYPES/panel'
const SEARCH_FOLDER = 'INTERACTABLE_TYPES/search_folder'
const SEARCH_DOCUMENT = 'INTERACTABLE_TYPES/search_document'
const UPLOAD = NativeTypes.FILE

export const INTERACTABLE_TYPES = {
  SIDEBAR_FOLDER,
  PANEL_FOLDER,
  PANEL_DOCUMENT,
  PANEL_BREADCRUMB,
  PANEL,
  SEARCH_FOLDER,
  SEARCH_DOCUMENT,
  UPLOAD,
}

const CREATE_FOLDER = 'MODAL_TYPES/create_folder'
const RENAME_FOLDER = 'MODAL_TYPES/rename_folder'
const DELETE_FOLDER = 'MODAL_TYPES/delete_folder'
const RENAME_DOCUMENT = 'MODAL_TYPES/rename_document'
const DELETE_DOCUMENT = 'MODAL_TYPES/delete_document'
const DETAIL_DOCUMENT = 'MODAL_TYPES/detail_document'

export const MODAL_TYPES = {
  CREATE_FOLDER,
  RENAME_FOLDER,
  DELETE_FOLDER,
  RENAME_DOCUMENT,
  DELETE_DOCUMENT,
  DETAIL_DOCUMENT,
}
