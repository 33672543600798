import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchImportErrors = (importId, params) =>
  fetchJSON(
    `/api/v1/imports/${importId}/error_rows?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchImportError = (importId, recordId, params) =>
  fetchJSON(
    `/api/v1/imports/${importId}/error_rows/${recordId}?${stringifyParams(
      params
    )}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const deleteImportError = (importId, rowId, data) =>
  fetchJSON(`/api/v1/imports/${importId}/error_rows/${rowId}`, 'DELETE', data, {
    useJwt: true,
  })

export const mergeImportError = (importId, rowId, { target_id, row_data }) =>
  fetchJSON(
    `/api/v1/imports/${importId}/error_rows/${rowId}/merge`,
    'POST',
    {
      target_id,
      row_data,
    },
    {
      useJwt: true,
    }
  )

export const postImportError = (importId, rowId, data) =>
  fetchJSON(
    `/api/v1/imports/${importId}/error_rows/${rowId}/save`,
    'POST',
    data,
    {
      useJwt: true,
    }
  )

export const putDiscardAllImportErrors = importId =>
  fetchJSON(`/api/v1/imports/${importId}/discard_all`, 'PUT', null, {
    useJwt: true,
  })

export const putSaveAllImportErrors = importId =>
  fetchJSON(`/api/v1/imports/${importId}/save_all`, 'PUT', null, {
    useJwt: true,
  })
