import { combineName, summaryFunctions } from 'utils/reporting'

export default {
  counts_by_organizer: {
    columns: [
      {
        dataKey: 'user_name',
        title: 'User Name',
        type: 'string',
        deriveFunction: ({ rowData }) =>
          combineName(rowData.user_first_name, rowData.user_last_name),
      },
      {
        dataKey: 'email',
        title: 'Email',
        type: 'string',
      },
      {
        dataKey: 'turf_name',
        title: 'Turf Name',
        type: 'string',
      },
      {
        dataKey: 'events_count',
        title: 'Events count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'meetings_count',
        title: 'Meetings count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'teams_count',
        title: 'Teams count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'organizations_count',
        title: 'Organizations count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
    ],
  },
}
