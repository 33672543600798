import { TEMPLATE_ITEM_OPTIONS } from 'email/template/TemplateContext/constants'
import {
  buildStart,
  buildEnd,
  buildTitle,
  buildHeading,
  buildButton,
  buildBodyCopy,
  buildImage,
} from './markupSegments'
import { buildMarkupStyles } from './utils'

const buildFunctions = {
  start: buildStart,
  end: buildEnd,
  [TEMPLATE_ITEM_OPTIONS.TITLE]: buildTitle,
  [TEMPLATE_ITEM_OPTIONS.HEADING]: buildHeading,
  [TEMPLATE_ITEM_OPTIONS.IMAGE]: buildImage,
  [TEMPLATE_ITEM_OPTIONS.BODY_COPY]: buildBodyCopy,
  [TEMPLATE_ITEM_OPTIONS.BUTTON_LINK]: buildButton,
}

const buildMarkup = template => {
  const { content } = template
  const styles = buildMarkupStyles(template)
  const markupItems = []

  markupItems.push(buildFunctions.start(styles))

  content.forEach(item => {
    markupItems.push(buildFunctions[item.type](item.value, styles))
  })

  markupItems.push(buildFunctions.end())

  return {
    __html: markupItems.join(' '),
  }
}

export default buildMarkup
