import { Button } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { usePacketAssignment } from '../PacketAssignmentContext'

const ReprioritizeTurfPacketButton = () => {
  const { t } = useTranslation()

  const { setIsTurfReprioritizationModalOpen } = usePacketAssignment()

  return (
    <Button.Secondary onClick={() => setIsTurfReprioritizationModalOpen(true)}>
      {t('Reprioritize Packets')}
    </Button.Secondary>
  )
}

export default ReprioritizeTurfPacketButton
