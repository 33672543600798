import { List, TextBlock, Section } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { QCPacketFilterContext } from './QCPacketFilterContext/QCPacketFilterContext'
import PacketItem from './PacketItem'
import { useInbox } from './InboxContext'

export const PacketList = ({ packets, isEmpty, emptyMsg, isSidebarOpen }) => {
  const { t } = useTranslation()

  if (isEmpty) {
    return (
      <Section secondary collapsed>
        <TextBlock>{t(emptyMsg)}</TextBlock>
      </Section>
    )
  }

  return (
    <List
      itemData={packets}
      render={packet => (
        <PacketItem
          key={packet.id}
          packet={packet}
          isSidebarOpen={isSidebarOpen}
        />
      )}
    />
  )
}

export const CompletedPacketList = ({ isSidebarOpen }) => {
  const { completedPackets: packets, completedPacketsEmptyMsg: emptyMsg } =
    useInbox()
  const isEmpty = packets.length === 0
  return (
    <PacketList
      packets={packets}
      isEmpty={isEmpty}
      emptyMsg={emptyMsg}
      isSidebarOpen={isSidebarOpen}
    />
  )
}

export const VisualReviewPacketList = ({ isSidebarOpen }) => {
  const {
    visualReviewPacketsEmptyMsg: emptyMsg,
    visualReviewPackets: packets,
  } = useInbox()
  const { filterPackets } = useContext(QCPacketFilterContext)
  const filteredPackets = filterPackets(packets)
  const isEmpty = filteredPackets.length === 0
  return (
    <PacketList
      packets={filteredPackets}
      isEmpty={isEmpty}
      emptyMsg={emptyMsg}
      isSidebarOpen={isSidebarOpen}
    />
  )
}

export const PhoneVerificationPacketList = ({ isSidebarOpen }) => {
  const {
    phoneVerificationPackets: packets,
    phoneVerificationPacketsEmptyMsg: emptyMsg,
  } = useInbox()
  const { filterPackets } = useContext(QCPacketFilterContext)
  const filteredPackets = filterPackets(packets)
  const isEmpty = filteredPackets.length === 0
  return (
    <PacketList
      packets={filteredPackets}
      isEmpty={isEmpty}
      emptyMsg={emptyMsg}
      isSidebarOpen={isSidebarOpen}
    />
  )
}
