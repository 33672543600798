import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBackgroundRequest } from 'hooks'
import moment from 'moment'
import { useCurrent } from 'contexts/index'
import WidgetPanel from 'dashboard/WidgetPanel/WidgetPanel'
import { DashboardFilterContext } from 'dashboard/DashboardFilterContext/DashboardFilterContext'
import { requestChartId, requestChart } from '../../../requests/charts'
import GenericBarChart from '../GenericBarChart'
import styles from './VoterRegistrationsByTypeOverTime.module.scss'

const VoterRegistrationsByTypeOverTime = () => {
  const { t } = useTranslation()

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const {
    startDate,
    endDate,
    turfId: turfIdFilter,
  } = useContext(DashboardFilterContext)

  const filters = useMemo(
    () => ({ startDate, endDate, turfId: turfIdFilter }),
    [startDate, endDate, turfIdFilter]
  )

  const [data, setData] = useState([])

  const { makeRequest, isLoading, errorMsg, response } = useBackgroundRequest({
    key: 'voter_registrations_by_type_over_time',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters,
      }),
    requestData: requestChart,
  })

  useEffect(() => {
    makeRequest()
  }, [filters])

  useEffect(() => {
    if (response) {
      setData(response.rows)
    }
  }, [response])

  const bars = [
    { dataKey: 'completed_count', name: t('Completed') },
    { dataKey: 'incomplete_count', name: t('Incomplete') },
  ]

  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      data[i].date = moment(data[i].date).format('MM/DD/YYYY')
    }
  }

  return (
    <WidgetPanel
      title={t('Registration types over time')}
      isLoading={isLoading}
      errorMsg={errorMsg}
      isEmpty={!data?.length}
    >
      <div className={styles.content}>
        <GenericBarChart data={data} bars={bars} />
      </div>
    </WidgetPanel>
  )
}

export default VoterRegistrationsByTypeOverTime
