import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError, FormattedData } from 'components'
import {
  Sheet,
  ProgressBar,
  Section,
  DetailItem,
  Grid,
  Icon,
  Button,
  ButtonBlock,
  TextBlock,
  Tab,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useRoutePathParams, useReactRouter, useRequest } from 'hooks'
import { useDebouncedCallback } from 'use-debounce'
import { useCurrent } from 'contexts/index'
import { putPhoneBank } from 'requests/phoneBanks'
import { isPhoneBankOpen } from 'phone_banks/utils'
import { timezoneName } from 'utils/dateTime'
import PhoneBankDeleteModal from '../PhoneBankDeleteModal/PhoneBankDeleteModal'
import PhoneBankArchiveButton from '../PhoneBankArchiveButton/PhoneBankArchiveButton'
import { options } from '../PhoneBankForm/PhoneBankTypeSelect'
import PhoneBankProgress from '../components/PhoneBankProgress'
import Script from '../components/Script/Script'
import SessionLinks from './SessionLinks'
import { PhoneBankContext } from '../PhoneBankContext/PhoneBankContext'

const PhoneBankSingle = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyPhoneBank = doesCurrentUserHavePermission({
    resource: 'phone_banking/phone_bank',
    ability: 'modify',
  })

  const {
    phoneBank: currentPhoneBank,
    setPhoneBank,
    getPhoneBank,
    phoneBankReqLoading,
    phoneBankReqHasErrors,
    phoneBankReqLoaded,
  } = useContext(PhoneBankContext)

  const [{ phoneBankId }] = useRoutePathParams()
  const [scriptItems, setScriptItems] = useState(
    currentPhoneBank?.script?.script_objects || []
  )
  const [
    doesCurrentPhoneBankHaveQuestions,
    setDoesCurrentPhoneBankHaveQuestions,
  ] = useState(
    scriptItems.length !== 0 &&
      scriptItems.find(scriptItem => scriptItem.question !== null)
  )

  useEffect(() => {
    setScriptItems(currentPhoneBank?.script?.script_objects || [])
  }, [currentPhoneBank?.script?.script_objects])

  useEffect(() => {
    setDoesCurrentPhoneBankHaveQuestions(
      scriptItems.length !== 0 &&
        scriptItems.find(scriptItem => scriptItem.question !== null)
    )
  }, [scriptItems])

  const [callDelay, setCallDelay] = useState()

  const {
    makeRequest: updateRequest,
    isLoading: updateLoading,
    hasErrors: updateError,
  } = useRequest(putPhoneBank, {
    onSuccess: ({ phone_bank }) => {
      setPhoneBank(phone_bank)
    },
  })

  const [debouncedUpdateCallDelay] = useDebouncedCallback(value => {
    updateRequest(phoneBankId, {
      min_call_delay_in_hours: value || 0,
    })
  }, 750)

  const phoneBankLoaded =
    phoneBankReqLoaded &&
    !phoneBankReqHasErrors &&
    currentPhoneBank?.id === +phoneBankId

  const handleCallDelayChange = callDelay => {
    if (phoneBankLoaded) {
      setCallDelay(callDelay)
      debouncedUpdateCallDelay(callDelay)
    }
  }

  useEffect(() => {
    getPhoneBank(phoneBankId)
  }, [phoneBankId])

  useEffect(() => {
    setCallDelay(currentPhoneBank.min_call_delay_in_hours)
  }, [currentPhoneBank])

  if (phoneBankReqLoading) return <ProgressBar show />

  if (phoneBankReqHasErrors || !currentPhoneBank) {
    return (
      <View>
        <ViewContainer>
          <Sheet>
            <CardError
              message={t('There was an error loading this phone bank.')}
            />
          </Sheet>
        </ViewContainer>
      </View>
    )
  }

  return (
    <View>
      <ViewContainer>
        {phoneBankLoaded && (
          <>
            <Sheet>
              <PageHeader title={currentPhoneBank.name}>
                <ButtonBlock justify="right">
                  <Link
                    onClick={e => !canModifyPhoneBank && e.preventDefault()}
                    to={`${match.url}/edit`}
                  >
                    <Button
                      disabled={!canModifyPhoneBank}
                      aria-label={t('Edit')}
                    >
                      <Icon.Pencil alt={t('Edit')} />
                    </Button>
                  </Link>
                  <PhoneBankArchiveButton />
                  <PhoneBankDeleteModal />
                </ButtonBlock>
              </PageHeader>
              <Grid>
                <Section>
                  <DetailItem label={t('Start date')}>
                    <FormattedData
                      value={currentPhoneBank.start_date}
                      type="date"
                      fallback={t('No start date specified')}
                    />
                  </DetailItem>
                  <DetailItem label={t('End date')}>
                    <FormattedData
                      value={currentPhoneBank.end_date}
                      type="date"
                      fallback={t('No end date specified')}
                    />
                  </DetailItem>
                  {currentPhoneBank.turf && (
                    <DetailItem label={t('Turf')}>
                      {currentPhoneBank.turf.name}
                    </DetailItem>
                  )}
                </Section>
                <Section>
                  <DetailItem label={t('Daily open time')}>
                    <FormattedData
                      value={currentPhoneBank.daily_start_time}
                      type="time"
                      fallback={t('No start time specified')}
                      format="HH:mm:ss"
                    />
                  </DetailItem>
                  <DetailItem label={t('Daily close time')}>
                    <FormattedData
                      value={currentPhoneBank.daily_end_time}
                      type="time"
                      fallback={t('No end time specified')}
                      format="HH:mm:ss"
                    />
                  </DetailItem>
                  <DetailItem label={t('Timezone')}>
                    {timezoneName(currentPhoneBank.time_zone)}
                  </DetailItem>
                </Section>
              </Grid>
              <Grid>
                <Section
                  label={t('Description')}
                  empty={!currentPhoneBank.description}
                  emptyMessage={t('No description')}
                >
                  <TextBlock>
                    <p>{currentPhoneBank.description}</p>
                  </TextBlock>
                </Section>
                <Section label={t('Call list')}>
                  <TextBlock>
                    <p>{currentPhoneBank.list.name}</p>
                  </TextBlock>
                </Section>
                {currentPhoneBank.call_type && (
                  <Section label={t('Call session type')}>
                    <TextBlock>
                      <p>
                        {
                          options.find(
                            el => el.id === currentPhoneBank.call_type
                          ).label
                        }
                      </p>
                    </TextBlock>
                  </Section>
                )}
              </Grid>
              <Grid>
                <SessionLinks
                  sessionType={currentPhoneBank.call_type}
                  hasQuestions={doesCurrentPhoneBankHaveQuestions}
                  isOpen={isPhoneBankOpen(currentPhoneBank)}
                />
              </Grid>
            </Sheet>
            <Sheet className="sheet--stacked">
              <ProgressBar show={updateLoading} />
              <CardError
                hide={!updateError}
                message={t('We were unable to update your phone bank data')}
              />
              <Tab.Container>
                <Tab label={t('Progress')}>
                  <PhoneBankProgress
                    callsMade={currentPhoneBank.calls_count}
                    callsCompleted={currentPhoneBank.participated_calls_count}
                    currentRound={currentPhoneBank.current_round}
                    callsPerRound={currentPhoneBank.calls_per_round}
                    totalPhones={currentPhoneBank.list.meta.phones}
                    callDelay={callDelay}
                    setCallDelay={handleCallDelayChange}
                    canModifyPhoneBank={canModifyPhoneBank}
                    phoneBankId={phoneBankId}
                    fetchRequest={getPhoneBank}
                  />
                </Tab>
                <Tab label={t('Script')}>
                  <Script
                    scriptItems={scriptItems}
                    setScriptItems={setScriptItems}
                  />
                </Tab>
              </Tab.Container>
            </Sheet>
          </>
        )}
      </ViewContainer>
    </View>
  )
}

export default PhoneBankSingle
