import { fetchJSON, stringifyParams } from 'utils/req'

export const postEmailTemplate = (name, template) =>
  fetchJSON(
    `/api/v1/email_templates`,
    'POST',
    { email_template: { name, template_content: template } },
    { useJwt: true }
  )

export const putEmailTemplate = (id, name, template) =>
  fetchJSON(
    `/api/v1/email_templates/${id}`,
    'PUT',
    { email_template: { name, template_content: template } },
    { useJwt: true }
  )

export const fetchEmailTemplate = (id, params) =>
  fetchJSON(
    `/api/v1/email_templates/${id}?${stringifyParams(params)}`,
    'GET',
    null,
    { useJwt: true }
  )

export const fetchEmailTemplates = params =>
  fetchJSON(`/api/v1/email_templates?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const destroyEmailTemplate = id =>
  fetchJSON(`/api/v1/email_templates/${id}`, 'DELETE', null, { useJwt: true })

export const postEmail = (templateId, email) =>
  fetchJSON(
    `/api/v1/email_templates/${templateId}/send`,
    'POST',
    {
      email,
    },
    { useJwt: true }
  )

export const fetchSentEmails = params =>
  fetchJSON(`/api/v1/sent_emails?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchSentEmail = (id, params) =>
  fetchJSON(
    `/api/v1/sent_emails/${id}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
