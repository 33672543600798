import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@politechdev/blocks-design-system'
import styles from './ScriptItem.module.scss'

const ReorderActions = ({ loading, isOnTop, isOnBottom, moveUp, moveDown }) => {
  const { t } = useTranslation()

  return (
    <>
      <Button.Secondary
        className={styles.button}
        disabled={isOnTop || loading}
        onClick={moveUp}
        aria-label={t('Move up')}
      >
        <Icon.ChevronUp alt={t('Move up')} />
      </Button.Secondary>
      <Button.Secondary
        className={styles.button}
        disabled={isOnBottom || loading}
        onClick={moveDown}
        aria-label={t('Move down')}
      >
        <Icon.ChevronDown alt={t('Move down')} />
      </Button.Secondary>
    </>
  )
}

export default ReorderActions
