import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { MultiSearchSelect, SearchSelect } from 'components'
import { useCurrent } from 'contexts/index'
import { buildOption, buildResponsibility } from './utils'

const ResponsibilitySelectFieldDEPRECATED = ({
  hasPermission,
  label,
  filters,
  onSelect,
  responsibility,
  responsibilities = [],
  isMulti = false,
  ...props
}) => {
  const { t } = useTranslation()
  const { responsibilityOptions } = useCurrent()
  const [value, setValue] = useState(responsibility)
  const [multiValue, setMultiValue] = useState(
    responsibilityOptions.filter(({ value }) =>
      responsibilities.some(r => r === value)
    )
  )

  useEffect(() => {
    setValue(responsibility)
  }, [responsibility])

  useEffect(() => {
    setMultiValue(responsibilities.map(buildOption))
  }, [JSON.stringify(responsibilities), responsibilityOptions])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(buildResponsibility))
  }

  return isMulti ? (
    <MultiSearchSelect
      label={label || t('Responsibility')}
      options={responsibilityOptions}
      onChange={handleMultiChange}
      value={multiValue}
      {...props}
    />
  ) : (
    <SearchSelect
      label={label || t('Responsibility')}
      options={responsibilityOptions}
      onChange={handleChange}
      value={value || ''}
      {...props}
    />
  )
}

ResponsibilitySelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  responsibility: PropTypes.string,
}

ResponsibilitySelectFieldDEPRECATED.defaultProps = {
  id: 'responsibility',
  responsibility: null,
}

export default ResponsibilitySelectFieldDEPRECATED
