import { createContext, useContext, useEffect, useState } from 'react'
import { useQueryParams } from 'contexts'

const isSafeSortOrder = sortOrder =>
  ['asc', 'desc'].includes(sortOrder?.toLowerCase())

const SortContext = createContext()

export const SortProvider = ({
  children,
  enableQueryParams = false,
  defaultSortColumn = undefined,
  defaultSortOrder = 'asc',
}) => {
  const { queryParams, setQueryParams } = useQueryParams(enableQueryParams)

  const [{ sortColumn, sortOrder }, setSort] = useState({
    sortColumn: queryParams.sortColumn || defaultSortColumn,
    sortOrder: isSafeSortOrder(queryParams.sortOrder)
      ? queryParams.sortOrder
      : defaultSortOrder,
  })

  useEffect(() => {
    const defaultParams = {}
    if (!isSafeSortOrder(queryParams.sortOrder)) {
      defaultParams.sortOrder = sortOrder
    }
    setQueryParams(defaultParams, 'replace')
  }, [])

  const onColumnSort = ({ key, order }) => {
    const sortData = {
      sortColumn: key !== '' ? key : undefined,
      sortOrder: order,
    }

    setQueryParams(sortData)
    setSort(sortData)
  }

  return (
    <SortContext.Provider
      value={{
        setSort,
        sortColumn,
        sortOrder,
        onColumnSort,
      }}
    >
      {children}
    </SortContext.Provider>
  )
}

export const useSort = (enabled = true) => {
  if (!enabled) {
    return {
      setSort: () => {},
      sortOrder: 'asc',
      onColumnSort: () => {},
    }
  }

  const context = useContext(SortContext)
  if (context === undefined) {
    throw new Error('useSort must be used within a SortProvider')
  }
  return context
}
