import { TextBlock } from 'components'
import { Button, ButtonBlock, Modal } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const DuplicatePacketsModal = ({ isOpen, setIsOpen, packets }) => {
  const { t } = useTranslation()

  return (
    <Modal
      id="duplicate-packet-report-table-cell-modal"
      title={t('Packets')}
      onHide={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <Modal.Body>
        {packets.map((packet, i) => (
          <div key={`comment-${i}`}>
            <TextBlock>
              <Link
                to={`/collect/voter_registration/shifts/${packet.shiftId}/packet`}
              >
                {packet.filename}
              </Link>
            </TextBlock>
          </div>
        ))}
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button
            onClick={() => {
              setIsOpen(false)
            }}
          >
            {t('Close')}
          </Button>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default DuplicatePacketsModal
