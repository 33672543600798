import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNumber } from 'lodash'
import {
  CardError,
  RoleSelect,
  CheckboxGroup,
  StateSelectField,
  TurfSelectField,
} from 'components'
import {
  ProgressBar,
  Section,
  Button,
  ButtonBlock,
  FieldBlock,
  Checkbox,
  Switch,
  Font,
  TextField,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useForm } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { isValidEmail } from 'utils/inputValidations'
import { languageOptions } from 'constants/users'
import { TimeZoneSelectField } from 'components/TimeZoneSelectField/TimeZoneSelectField'
import { postUser, putUser } from 'requests/users'
import UserRoleDetails from '../UserRoleDetails/UserRoleDetails'
import { isTrainable, buildForm, buildRequest } from './utils'
import { QC_OFFICE_OPTIONS } from '../constants'
import MissingNameFieldWarningModal from './MissingNameFieldWarningModal'

const UserForm = ({ user }) => {
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)
  const isEditForm = !!user
  const formAction = isEditForm
    ? putUser
    : data => postUser(data.user, data.welcomeEmail)
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const { formData, setField, setFormData } = useForm()

  const { makeRequest, isLoading, hasErrors, isRequestComplete, response } =
    useRequest(formAction)

  useEffect(() => {
    user && setFormData(buildForm(user))
  }, [])

  const isFormValid = () => {
    if (
      isEmpty(formData.email) ||
      !isValidEmail(formData.email) ||
      !isNumber(formData.turf_id) ||
      !formData.role
    ) {
      return false
    }

    if (!isEditForm || formData.reset_password) {
      if (isEditForm && formData.current_password?.length < 6) {
        return false
      }

      return (
        formData.password?.length > 5 &&
        formData.password === formData.password_confirmation
      )
    }
    return true
  }

  useEffect(() => {
    if (hasErrors || !isRequestComplete) return

    history.push('/admin/users')
  }, [isRequestComplete, hasErrors, response])

  const submitForm = () => makeRequest(buildRequest(formData))

  return (
    <>
      <ProgressBar show={isLoading} />
      <CardError
        hide={!hasErrors}
        message={`${t('An error occurred while')} ${
          isEditForm ? t('editing') : t('creating')
        } ${t('this user')}`}
      />
      <form>
        <FieldBlock>
          <TextField
            id="first-name"
            label={t('First name')}
            value={formData.first_name || ''}
            onChange={val => setField(val, 'first_name')}
          />
          <TextField
            id="last-name"
            label={t('Last name')}
            value={formData.last_name || ''}
            onChange={val => setField(val, 'last_name')}
          />
        </FieldBlock>
        <Section label={t('Account details')}>
          <FieldBlock>
            <TextField
              required
              id="email"
              label={t('Email')}
              type="email"
              value={formData.email || ''}
              onChange={val => setField(val, 'email')}
              error={!!formData.email && !isValidEmail(formData.email)}
              errorMessage={t('Must be a valid email')}
            />
          </FieldBlock>
          {isEditForm && (
            <FieldBlock>
              <Checkbox
                id="reset-password"
                name="reset-password"
                label={t('Reset password?')}
                checked={formData.reset_password}
                onChange={val => {
                  setField(undefined, 'current_password')
                  setField(undefined, 'password')
                  setField(undefined, 'password_confirmation')
                  setField(val, 'reset_password')
                }}
              />
            </FieldBlock>
          )}
          {isEditForm && formData.reset_password && (
            <FieldBlock>
              <TextField
                required
                id="current-password"
                label={t('Current password')}
                type="password"
                value={formData.current_password || ''}
                ignoreWhitespace
                onChange={val => setField(val, 'current_password')}
                error={formData.current_password?.length < 6}
                errorMessage={t('Must be at least 6 characters long')}
              />
            </FieldBlock>
          )}
          {(!isEditForm || formData.reset_password) && (
            <FieldBlock>
              <TextField
                required
                id="password"
                label={isEditForm ? t('New password') : t('Password')}
                type="password"
                value={formData.password || ''}
                ignoreWhitespace
                onChange={val => setField(val, 'password')}
                error={formData.password?.length < 6}
                errorMessage={t('Must be at least 6 characters long')}
              />
              <TextField
                required
                id="confirm-password"
                label={
                  isEditForm
                    ? t('New password confirmation')
                    : t('Confirm password')
                }
                type="password"
                value={formData.password_confirmation || ''}
                ignoreWhitespace
                onChange={val => setField(val, 'password_confirmation')}
                error={formData.password_confirmation !== formData.password}
                errorMessage={t('Password does not match')}
              />
            </FieldBlock>
          )}
          {!isEditForm && (
            <FieldBlock>
              <Checkbox
                id="welcome-email"
                name="Send welcome email"
                label={t('Send welcome email')}
                checked={formData.welcome_email}
                onChange={val => setField(val, 'welcome_email')}
              />
            </FieldBlock>
          )}
        </Section>
        <Section label={t('Preferences')}>
          <FieldBlock>
            <SelectField
              id="language"
              label="Language"
              options={languageOptions}
              value={formData.locale}
              onSelect={val => {
                setField(val, 'locale')
              }}
            />
            <TimeZoneSelectField
              value={formData.time_zone}
              onSelect={val => {
                setField(val, 'time_zone')
              }}
            />
          </FieldBlock>
          <FieldBlock>
            <SelectField
              id="qc-office"
              label={t('QC Office')}
              options={QC_OFFICE_OPTIONS}
              value={formData.qc_office || ''}
              onSelect={val => setField(val, 'qc_office')}
            />
          </FieldBlock>
        </Section>
        <Section label={t('Permissions')}>
          <FieldBlock>
            <RoleSelect
              id="role-select"
              onSelect={val => setField(val, 'role')}
              role={formData.role}
              fields={['id', 'name', 'needs_training', 'permissions']}
              required
            />
            <TurfSelectField
              id="turf-select"
              label={t('Turf')}
              value={formData.turf_id}
              onSelect={val => setField(val, 'turf_id')}
              required
              showArchived={isEditForm}
              disableArchived
            />
          </FieldBlock>
          <UserRoleDetails role={formData.role} />
        </Section>
        <Section
          label={t('QC training')}
          empty={!isTrainable(formData.role)}
          emptyMessage={t(
            'The role you selected is not available for QC  training. Please select a trainable role to set QC training.'
          )}
        >
          <FieldBlock>
            <div>
              <Switch
                id="active-training"
                name="active-training"
                label={t('In active training')}
                value={formData.training_status}
                onChange={val => setField(val, 'training_status')}
              />
              <Font.Copy variant="hint">
                {t(
                  'Active training staff display a training badge until the staff member has worked 3 shifts'
                )}
              </Font.Copy>
            </div>
          </FieldBlock>
          <FieldBlock>
            <StateSelectField
              id="states-trained-in"
              isMulti
              label={t('States trained in')}
              states={formData.trained_in_states}
              onSelect={val => setField(val, 'trained_in_states')}
            />
          </FieldBlock>
          <Section label={t('Languages trained in')}>
            <FieldBlock>
              <CheckboxGroup
                id="language"
                options={languageOptions}
                values={formData.languages}
                onChange={val => setField(val, 'languages')}
              />
            </FieldBlock>
          </Section>
        </Section>
        <ButtonBlock justify="left">
          <Button.Accent
            onClick={() => {
              if (!formData.first_name || !formData.last_name) {
                setWarningModalIsOpen(true)
              } else {
                submitForm()
              }
            }}
            disabled={!isFormValid() || isLoading}
          >
            {t(isEditForm ? 'Save user' : 'Create user')}
          </Button.Accent>
          <Link to="/admin/users">
            <Button.Secondary>{t('Cancel')}</Button.Secondary>
          </Link>
        </ButtonBlock>
      </form>
      <MissingNameFieldWarningModal
        isOpen={warningModalIsOpen}
        close={() => setWarningModalIsOpen(false)}
        firstMissing={!formData.first_name}
        lastMissing={!formData.last_name}
        saveUserRequest={submitForm}
      />
    </>
  )
}

export default UserForm
