import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Divider.module.scss'

const cx = classNames.bind(styles)

const Divider = ({ className }) => <div className={cx('divider', className)} />

Divider.propTypes = {
  className: PropTypes.string,
}

export default Divider
