import { orderBy } from 'lodash'
import moment from 'moment'
import { useRequest } from 'hooks'
import {
  createFlagComment,
  deleteFlagComment,
  updateFlagComment,
} from 'requests/flags'
import { useToast } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import ExistingComment from './ExistingComment'
import NewComment from './NewComment'
import { useCurrentFlagContext } from '../contexts/CurrentFlagContext'

const FlagSingleComments = () => {
  const { t } = useTranslation()
  const { setToast } = useToast()
  const { currentFlag, setCurrentFlag } = useCurrentFlagContext()

  const createCommentRequest = useRequest(createFlagComment, {
    onSuccess: response => {
      setCurrentFlag(current => ({
        ...current,
        comments: [...current.comments, response['quality_control/comment']],
      }))
    },
    onError: () => {
      setToast({
        message: t('Failed to save comment'),
        variant: 'error',
      })
    },
  })

  const updateCommentRequest = useRequest(updateFlagComment, {
    onSuccess: response => {
      const updatedComment = response['quality_control/comment']
      setCurrentFlag(current => ({
        ...current,
        comments: current.comments.map(comment =>
          comment.id === updatedComment.id ? updatedComment : comment
        ),
      }))
    },
    onError: () => {
      setToast({
        message: t('Failed to save comment'),
        variant: 'error',
      })
    },
  })

  const deleteCommentRequest = useRequest(deleteFlagComment, {
    onSuccess: commentId => {
      setCurrentFlag(current => ({
        ...current,
        comments: current.comments.filter(comment => comment.id !== commentId),
      }))
    },
    onError: () => {
      setToast({
        message: t('Failed to delete comment'),
        variant: 'error',
      })
    },
  })

  const handleCreateComment = commentBody =>
    createCommentRequest.makeRequest(currentFlag.id, commentBody, {
      fields: ['id', 'body', 'created_at', { author: ['id', 'name', 'email'] }],
    })

  const handleSaveComment = (commentId, commentBody) =>
    updateCommentRequest.makeRequest(currentFlag.id, commentId, commentBody, {
      fields: ['id', 'body', 'created_at', { author: ['id', 'name', 'email'] }],
    })

  const handleDeleteComment = commentId =>
    deleteCommentRequest.makeRequest(currentFlag.id, commentId, {
      fields: ['id', 'body', 'created_at', { author: ['id', 'name', 'email'] }],
    })

  const comments = currentFlag?.comments ?? []

  return (
    <div>
      {orderBy(comments, comment => moment(comment.created_at), ['asc']).map(
        comment => (
          <ExistingComment
            key={`flag-single-comment-${comment.id}`}
            comment={comment}
            saveCommentEdits={handleSaveComment}
            deleteComment={handleDeleteComment}
          />
        )
      )}
      <NewComment createComment={handleCreateComment} />
    </div>
  )
}

export default FlagSingleComments
