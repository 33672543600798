import React from 'react'
import { Button } from 'components'
import { Plus as PlusIcon, Minus as MinusIcon } from 'components/uiKit/icons'
import { useViewport } from '../ViewportProvider/ViewportProvider'
import styles from './Interface.module.scss'

const Interface = ({ maxZoom, minZoom }) => {
  const [viewport, setViewport] = useViewport()

  return (
    <div className={styles.interface}>
      <div className={styles.buttons}>
        <Button
          floating
          onClick={() =>
            setViewport(viewport => ({
              ...viewport,
              zoom: Math.floor(viewport.zoom + 1),
            }))
          }
          className={styles.button}
          disabled={viewport.zoom >= maxZoom}
        >
          <PlusIcon />
        </Button>
        <div className={styles.separator} />
        <Button
          floating
          onClick={() =>
            setViewport(viewport => ({
              ...viewport,
              zoom: Math.ceil(viewport.zoom - 1),
            }))
          }
          className={styles.button}
          disabled={viewport.zoom <= minZoom}
        >
          <MinusIcon />
        </Button>
      </div>
    </div>
  )
}

export default Interface
