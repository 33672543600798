import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  PageHeader,
  ContentBlock,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import ImportsTable from './ImportsTable'

const ImportSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()
  const [canViewImports, canModifyImports] = [true, true]

  if (!canViewImports) {
    return (
      <CardError
        hide={canViewImports}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('Imports')}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyImports && e.preventDefault()}
            to={`${location.pathname}/new`}
          >
            <Button.Accent disabled={!canModifyImports}>
              {t('New import')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ContentBlock>
        <Sheet>
          <ImportsTable />
        </Sheet>
      </ContentBlock>
    </View>
  )
}

export default ImportSelect
