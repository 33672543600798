import { useEffect } from 'react'
import { useAutoTable, useFilters } from 'contexts'
import { areAllFiltersValid } from 'components/DataTable/TableFilters/utils'
import useEvent from 'hooks/useEvent'
import moment from 'moment-timezone'
import { usePetitionReport } from '../PetitionReports/PetitionReportsProvider'
import { reportTypes } from '../configs'

const PetitionReportsTable = ({ reportType, dateRange, turfId }) => {
  const {
    isLoading: filterLoading,
    errorMsg: filterErrorMsg,
    filterRules,
  } = useFilters()
  const { renderTable } = useAutoTable()

  const { reportData, requestPetitionReport, isLoading, errorMsg } =
    usePetitionReport()

  const { columns } = reportTypes[reportType]

  const requestReportData = useEvent(() =>
    requestPetitionReport(
      reportType,
      moment.tz(dateRange.start, 'UTC').format('YYYYMMDD'),
      moment.tz(dateRange.end, 'UTC').format('YYYYMMDD'),
      turfId
    )
  )

  useEffect(() => {
    requestReportData()
  }, [dateRange, turfId, reportType])

  if (!areAllFiltersValid(filterRules, columns)) {
    return null
  }

  return renderTable({
    data: reportData,
    loading: isLoading || filterLoading,
    error: errorMsg || filterErrorMsg,
    columns,
    fetchAction: requestPetitionReport,
  })
}

export default PetitionReportsTable
