import { useEffect, createContext, useContext } from 'react'
import { useStoredState } from 'hooks'
import i18n from 'translate/i18n'
import { useCurrent } from 'contexts'
import namespaces from 'translate/namespaces'

const LocaleContext = createContext()

export const LocaleProvider = ({ children }) => {
  const [storedLocale, setStoredLocale] = useStoredState('blocks-locale')

  const {
    currentUser: { locale: userLocale },
    currentTenant: { subdomain },
  } = useCurrent()

  const locale = storedLocale || userLocale

  useEffect(() => {
    const namespace = namespaces[subdomain]
    if (namespace) {
      i18n.loadNamespaces(namespace)
      i18n.setDefaultNamespace(namespace)
    }
  }, [subdomain])

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale])

  return (
    <LocaleContext.Provider value={[locale, setStoredLocale]}>
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocale = () => {
  const context = useContext(LocaleContext)
  if (context === undefined) {
    throw new Error(`useLocale must be used within a LocaleProvider`)
  }
  return context
}
