import React from 'react'
import { useTranslation } from 'react-i18next'
import { CountySelectField } from 'components'
import {
  Switch,
  DateField,
  SelectField,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import moment from 'moment'
import { useLocationMap } from '../LocationMapProvider/LocationMapProvider'
import { VIEW_TYPES } from '../constants'
import styles from './LocationMapViewToggle.module.scss'

const LocationMapViewToggle = () => {
  const { t } = useTranslation()
  const {
    viewType,
    setViewType,
    stateOptions,
    state,
    setState,
    county,
    setCounty,
    clearCounty,
    updateBounds,
    hasMoved,
    useClusters,
    setUseClusters,
    dates,
    setDates,
  } = useLocationMap()

  const setBoundsViewType = () => {
    setCounty(undefined)
    setViewType(VIEW_TYPES.BOUNDS)
    if (hasMoved || viewType !== VIEW_TYPES.BOUNDS) {
      updateBounds()
    }
  }

  return (
    <FieldBlock>
      <DateField
        className={styles.date}
        label={t('Date range')}
        type="range"
        startDate={dates.start}
        endDate={moment(dates.end).subtract(1, 'day').toISOString()}
        onChange={(start, end) => {
          setDates({
            start,
            end: moment(end).add(1, 'day').toISOString(),
          })
        }}
      />
      {stateOptions.length > 0 && (
        <>
          {stateOptions.length > 1 && (
            <SelectField
              className={styles.state}
              id="state"
              label={t('State')}
              options={stateOptions}
              value={state}
              onSelect={val => {
                if (val !== state) {
                  clearCounty()
                }
                setState(val)
              }}
            />
          )}
          <CountySelectField
            className={styles.county}
            state={state}
            county={county}
            onSelect={val => {
              setCounty(val)
              setViewType(VIEW_TYPES.COUNTY)
            }}
            onClear={setBoundsViewType}
            clearable
            disabled={!state}
          />
        </>
      )}
      <Switch
        className={styles.cluster}
        label={t('Cluster locations')}
        value={useClusters}
        onChange={() => setUseClusters(val => !val)}
      />
    </FieldBlock>
  )
}

export default LocationMapViewToggle
