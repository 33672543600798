import { useTranslation } from 'react-i18next'
import styles from './TemplatePreviewLabel.module.scss'

const TemplatePreviewLabel = () => {
  const { t } = useTranslation()

  return <div className={styles.label}>{t('preview')}</div>
}

export default TemplatePreviewLabel
