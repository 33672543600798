import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { MultiSelectField } from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'

const DenominationSelectField = ({
  label,
  filters,
  onSelect,
  denominations = [],
  ...props
}) => {
  const { t } = useTranslation()
  const { denominationOptions } = useCurrent()

  const handleMultiChange = selections => {
    onSelect && onSelect(selections)
  }

  return (
    <MultiSelectField
      label={label || t('Denominations')}
      options={denominationOptions}
      onSelect={handleMultiChange}
      values={denominations || []}
      {...props}
    />
  )
}

DenominationSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  denominations: PropTypes.arrayOf(PropTypes.object),
}

DenominationSelectField.defaultProps = {
  id: 'denominations',
  filters: [],
  denominations: [],
}

export default DenominationSelectField
