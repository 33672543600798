import { useTranslation } from 'react-i18next'
import { View, Sheet, SubheaderBlock } from 'components'
import UserForm from '../UserForm/UserForm'

const UserCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <Sheet>
        <SubheaderBlock title={t('New user')} />
        <UserForm />
      </Sheet>
    </View>
  )
}

export default UserCreate
