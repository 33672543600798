import sortAlphaDown from 'assets/icons/far-sort-alpha-down.svg'
import sortAlphaUp from 'assets/icons/far-sort-alpha-up.svg'
import sortDateDown from 'assets/icons/blocks-sort-date-down.svg'
import sortDateUp from 'assets/icons/blocks-sort-date-up.svg'

const usePacketListHeader = (
  sortType,
  sortOrder,
  setPacketSortType,
  setPacketSortOrder
) => {
  const setSort = ({ sortType, sortOrder }) => {
    setPacketSortType(sortType)
    setPacketSortOrder(sortOrder)
  }

  const noAlphaSort = sortAlphaDown
  const noDateSort = sortDateDown
  const icons = {
    alpha: noAlphaSort,
    date: noDateSort,
    ASC: {
      alpha: sortAlphaDown,
      date: sortDateDown,
    },
    DSC: {
      alpha: sortAlphaUp,
      date: sortDateUp,
    },
  }

  const reverseOrder = sortOrder === 'ASC' ? 'DSC' : 'ASC'
  const alphaIcon = sortType === 'alpha' ? icons[sortOrder].alpha : icons.alpha
  const dateIcon = sortType === 'date' ? icons[sortOrder].date : icons.date
  const toggleSort = type =>
    setSort({
      sortType: type,
      sortOrder: sortType === type ? reverseOrder : 'ASC',
    })

  return {
    alphaIcon,
    dateIcon,
    toggleSort,
  }
}

export default usePacketListHeader
