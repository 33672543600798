import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { Paginator } from 'components'
import {
  SectionLabel,
  List,
  ContentBlock,
  ButtonBlock,
  Button,
  Sheet,
} from '@politechdev/blocks-design-system'
import SignatureListItem from './SignatureListItem'
import styles from './PetitionPacketSingle.module.scss'

const cx = classNames.bind(styles)

const SignatureList = ({
  signatures,
  hasPageLevelIssue,
  currentPageNumber,
  maxPage,
  handlePageChange,
  isEditable,
  canCompleteQC,
  handleCompleteQC,
  isQcCompleted,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ContentBlock>
        <SectionLabel tight>{t('Signatures')}</SectionLabel>
        <Sheet>
          <List
            itemData={signatures.sort(
              (signatureA, signatureB) =>
                signatureA.line_number - signatureB.line_number
            )}
            render={item => (
              <SignatureListItem
                item={item}
                isEditable={isEditable}
                hasPageLevelIssue={hasPageLevelIssue}
              />
            )}
            loading={false}
            emptyMessage={t('No signatures')}
          />
          <div className={cx('signature-list__actions')}>
            {maxPage > -Infinity ? (
              <Paginator
                currentPage={currentPageNumber}
                totalPages={maxPage}
                onNext={handlePageChange(1)}
                onPrevious={handlePageChange(-1)}
                justify="left"
              />
            ) : null}
          </div>
        </Sheet>
      </ContentBlock>
      {isEditable ? (
        <ButtonBlock>
          <Button.Accent
            disabled={!canCompleteQC || isQcCompleted}
            onClick={handleCompleteQC}
          >
            {isQcCompleted ? t('QC Completed') : t('Complete QC')}
          </Button.Accent>
        </ButtonBlock>
      ) : null}
    </>
  )
}

export default SignatureList
