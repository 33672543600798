/* eslint-disable arrow-body-style */
import {
  TextBlock,
  ContentBlock,
  Icon,
  Section,
} from '@politechdev/blocks-design-system'
import { SectionLabel } from 'components'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { phoneBankTypes } from 'phone_banks/constants'
import { useCurrent } from 'contexts/index'
import styles from './PhoneBankTypeSelect.module.scss'

const cx = classNames.bind(styles)

export const options = [
  {
    label: 'Manual dialer',
    id: phoneBankTypes.MANUAL,
    Icon: Icon.PhoneOffice,
    description:
      'Phone bank session surveyors dial numbers from the call list manually using their own phones. This session type does not use VOIP calling over Twilio nor does it require purchasing or spending Twilio minutes on your account.',
  },
  {
    label: 'Web based dialer',
    id: phoneBankTypes.WEB,
    Icon: Icon.PhoneLaptop,
    description:
      'This phone bank session type allows agents to select whether they want to make calls directly in the browser or dial externally using their own phone. Calling in the browser is an additional cost.',
  },
  {
    label: 'Power dialer',
    id: phoneBankTypes.POWER,
    Icon: Icon.HeadSideHeadphones,
    description:
      'Allows an operator to initiate calls in the browser for the agents available in the phone bank call session. Once a participant answers the call, they get automatically patched over to an available agent.',
  },
]

const PhoneBankTypeSelect = ({ value, onChange }) => {
  const { t } = useTranslation()
  const {
    tenantOptions: { power_dialer_enabled: powerDialerEnabled },
  } = useCurrent()

  const description = options.find(option => option.id === value)?.description
  return (
    <>
      <TextBlock>
        <SectionLabel id="phonebank-type-select-label">
          {t('Call session type')}
        </SectionLabel>
      </TextBlock>
      <div
        aria-labelledby="phone-bank-type-select-label"
        role="radiogroup"
        className={cx('container')}
      >
        {options.map(option => {
          const disablePowerDialer =
            !powerDialerEnabled && option.id === phoneBankTypes.POWER

          if (disablePowerDialer) return null
          return (
            <div
              key={option.id}
              className={cx('option', {
                'option--selected': value === option.id,
              })}
              tabIndex={0}
              role="radio"
              aria-checked={value === option.id}
              onClick={() => onChange(option.id)}
            >
              {option.Icon({ className: cx('option__icon') })}
              <span>{t(option.label)}</span>
            </div>
          )
        })}
      </div>
      {!!description && (
        <ContentBlock>
          <Section secondary className={cx('description-container')}>
            <TextBlock>{description}</TextBlock>
          </Section>
        </ContentBlock>
      )}
    </>
  )
}

export default PhoneBankTypeSelect
