import { useTranslation } from 'react-i18next'
import {
  ResponsiveContainer,
  ScatterChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Scatter,
  ReferenceLine,
  Label,
} from 'recharts'

import styles from './CanvasserScatterPlot.module.scss'

const CanvasserScatterPlot = ({
  xAxisName,
  xAxisKey,
  yAxisKey,
  canvasserPoint,
  otherData,
  canvasserName,
  xAvg,
  yAvg,
}) => {
  const { t } = useTranslation()

  return (
    <ResponsiveContainer height="90%">
      <ScatterChart margin={{ top: 5, right: 5, bottom: 40, left: 15 }}>
        <XAxis
          type="number"
          name={xAxisName}
          dataKey={xAxisKey}
          label={{ value: xAxisName, position: 'bottom' }}
        />
        <YAxis type="number" dataKey={yAxisKey} domain={(0, 'dataMax')} />
        <Tooltip />
        <Legend verticalAlign="top" align="right" />
        <Scatter name={canvasserName} data={canvasserPoint} fill="#348DDF" />
        <Scatter name={t('Other canvassers')} data={otherData} fill="#DAE0E2" />
        <ReferenceLine y={yAvg} stroke="red" strokeDasharray="10">
          <Label
            className={styles['reference-line-label']}
            value="Turf performance"
            position="insideTopRight"
            offset={20}
          />
        </ReferenceLine>
        <ReferenceLine x={xAvg} stroke="red" strokeDasharray="10" />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default CanvasserScatterPlot
