import { useTranslation } from 'react-i18next'
import qs from 'qs'
import moment from 'moment'

export const useGoogleCalendarUrl = event => {
  const { t } = useTranslation()

  const start = moment(event.start_time).utc().format('YYYYMMDDTHHmmss')
  const end = moment(event.end_time).utc().format('YYYYMMDDTHHmmss')

  const details = [
    event.description,
    `${t('This event was created by Blocks, view it here:')} ${
      window.location.href
    }`,
  ]
    .filter(Boolean)
    .join('\r\n\r\n')

  let location
  if (event.location) {
    location = [
      event.location.name,
      event.location.street_address,
      event.location.city,
      [event.location.state, event.location.zipcode].filter(Boolean).join(' '),
    ]
      .filter(Boolean)
      .join(', ')
  }

  const queryString = qs.stringify({
    action: 'TEMPLATE',
    text: event.name,
    dates: `${start}Z/${end}Z`,
    details,
    location,
  })

  return `https://calendar.google.com/calendar/render?${queryString}`
}
