import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError, MultiSearchSelect, SearchSelect } from 'components'
import { useRequest } from 'hooks'
import { fetchTeams } from 'requests/teams'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts'
import { buildParams, buildTeam, buildOption } from './utils'

const TeamSelectFieldDEPRECATED = ({
  id,
  label,
  filters,
  onSelect,
  team = {},
  teams = [],
  isMulti,
  canSelectAll,
  excludedOptions = [],
  noOptionsMessage,
}) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'team',
    ability: 'view',
  })

  const [options, setOptions] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState(team?.id)
  const [multiValue, setMultiValue] = useState(
    options.filter(option => teams.some(val => val.id === option.value))
  )
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  const { makeRequest, isLoading } = useRequest(fetchTeams, {
    onSuccess: ({ teams: incomingTeams }) => {
      setOptions(
        incomingTeams.map(buildOption).concat([
          ...(canSelectAll
            ? [
                {
                  label: t(`All ${label?.toLowerCase() || 'teams'}`),
                  value: 'ALL',
                },
              ]
            : []
          ).filter(teamOption =>
            excludedOptions.every(
              optionValue => teamOption.value !== optionValue
            )
          ),
        ])
      )
    },
    onError: () => {
      setErrorMsg('failed to fetch teams')
    },
  })

  const getTeams = () => {
    const params = buildParams({
      query: debounced,
      filters,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getTeams()
  }, [debounced, JSON.stringify(filters)])

  useEffect(() => {
    setValue(team?.id)
  }, [team])

  useEffect(() => {
    setMultiValue(teams.map(buildOption))
  }, [JSON.stringify(teams)])

  const singleOptions = buildSingleOptions(options, team, buildOption)

  const multiOptions = buildMultiOptions(options, teams, buildOption)

  const handleChange = selection => {
    const selectedOption = singleOptions.find(
      ({ value }) => value === selection
    )
    onSelect && onSelect(selectedOption ? buildTeam(selectedOption) : undefined)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(buildTeam))
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSearchSelect
          id={id}
          label={label || t('Team')}
          options={multiOptions}
          onChange={handleMultiChange}
          onInputChange={setQuery}
          value={multiValue}
          loading={isLoading}
          disabled={!hasPermission}
          helpText={
            !hasPermission && t('You do not have permission to edit this field')
          }
          noOptionsMessage={noOptionsMessage}
        />
      ) : (
        <SearchSelect
          id={id}
          label={label || t('Team')}
          options={singleOptions}
          onChange={handleChange}
          onInputChange={setQuery}
          value={value || ''}
          loading={isLoading}
          disabled={!hasPermission}
          helpText={
            !hasPermission && t('You do not have permission to edit this field')
          }
          noOptionsMessage={noOptionsMessage}
        />
      )}
    </>
  )
}

TeamSelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  team: PropTypes.object,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
}

TeamSelectFieldDEPRECATED.defaultProps = {
  id: 'teams',
  filters: [],
  team: null,
}

export default TeamSelectFieldDEPRECATED
