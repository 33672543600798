import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchOrganizingReport = params => {
  const { reportType } = params
  const queryParams = {
    startdate: params.startDate,
    enddate: params.endDate,
    turf_id: params.turfId,
  }
  const url = queryParams
    ? `/api/v1/events/reports/${reportType}?${stringifyParams(queryParams)}`
    : `/api/v1/events/reports/${reportType}`
  return fetchJSON(url, 'GET', null, {
    useJwt: true,
  })
}
