import { useContext, useEffect, useState } from 'react'
import { ToggleBoxGroup, CardError } from 'components'
import { ContentBlock, Button, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { putScript } from 'requests/phoneBanks'
import { PhoneBankContext } from 'phone_banks/PhoneBankContext/PhoneBankContext'
import ScriptItem from '../ScriptItem/ScriptItem'
import QuestionNewForm from '../Questions/QuestionNewForm'
import SectionDividerNew from '../SectionDividerNew'
import ScriptItemNewForm from '../ScriptItemNewForm'
import scriptItemStyles from '../ScriptItem/ScriptItem.module.scss'
import styles from './Script.module.scss'
import { formatNewItem } from './utils'
import ScriptIntro from '../ScriptItem/Intro/ScriptIntro'

const scriptableIdKeyMap = {
  section: 'section_id',
  instruction: 'script_text_id',
  dialogue: 'script_text_id',
  single_choice: 'question_id',
  multiple_choice: 'question_id',
  open_ended: 'question_id',
}

const Script = ({ scriptItems, setScriptItems }) => {
  const {
    phoneBank: { script, newItem },
  } = useContext(PhoneBankContext)
  const [newItemType, setNewItemType] = useState()
  const [causeStatement, setCauseStatement] = useState(script.cause_statement)
  const { t } = useTranslation()
  const scriptItemMap = {
    instruction: {
      label: t('Add instructions'),
      icon: <Icon.Map className={styles.primary} alt="" />,
      value: 'instruction',
      newComponent: <ScriptItemNewForm type="instruction" />,
    },
    dialogue: {
      label: t('Add dialog'),
      icon: <Icon.CommentAltLines className={styles.accent} alt="" />,
      value: 'dialogue',
      newComponent: <ScriptItemNewForm type="dialogue" />,
    },
    question: {
      label: t('Add question'),
      icon: <Icon.QuestionCircle className={styles.accent} alt="" />,
      value: 'question',
      newComponent: <QuestionNewForm />,
    },
    section: {
      label: t('Add section'),
      icon: <Icon.ListAlt className={styles.secondary} alt="" />,
      value: 'section',
      newComponent: <SectionDividerNew />,
    },
  }
  let sectionCounter = 0

  const { makeRequest, isLoading, hasErrors } = useRequest(putScript, {
    onSuccess: res => {
      setScriptItems(res['phone_banking/script'].script_objects)
    },
    onError: () => {
      setScriptItems(prevScriptItems => prevScriptItems.filter(item => item.id))
    },
  })

  const updatePositions = (newItem, updatedScriptItems) => {
    const newOrder = updatedScriptItems
      ? updatedScriptItems.map((item, i) =>
          item.id
            ? {
                id: item.id,
                position_in_script: i,
              }
            : {
                ...newItem,
              }
        )
      : scriptItems.map((item, i) =>
          item.id
            ? {
                id: item.id,
                position_in_script: i,
              }
            : {
                ...newItem,
              }
        )
    makeRequest(script.id, { script_objects: newOrder })
  }

  const reorderItem = (i, delta) => {
    setScriptItems(prevScriptItems => {
      const items = [...prevScriptItems]
      const movingItem = items[i]
      items[i] = items[i + delta]
      items[i + delta] = movingItem
      const newOrder = items.map((item, i) => ({
        id: item.id,
        position_in_script: i,
      }))
      makeRequest(script.id, { script_objects: newOrder })
      return items
    })
  }

  const removeItem = targetIndex => {
    setScriptItems(prevScriptItems => {
      const newScriptItems = prevScriptItems.filter(
        (_, index) => index !== targetIndex
      )

      makeRequest(script.id, { script_objects: newScriptItems })

      return newScriptItems
    })
  }

  const updateContent = (content, targetIndex) => {
    if (Object.hasOwn(content, 'cause_statement')) {
      return setCauseStatement(content.cause_statement)
    }
    return setScriptItems(prevScriptItems =>
      prevScriptItems.map((item, i) => {
        if (i === targetIndex && item.question) {
          return { ...item, question: { ...item.question, ...content } }
        }
        if (i === targetIndex) {
          return { ...item, script_text: { ...item.script_text, ...content } }
        }
        return item
      })
    )
  }

  useEffect(() => {
    setScriptItems(script.script_objects)
  }, [script.script_objects])

  useEffect(() => {
    if (newItem) {
      const formattedNewItem = formatNewItem(newItem, scriptItems)
      setScriptItems(oldScriptItems => {
        const newScriptItems = oldScriptItems.concat([formattedNewItem])
        setNewItemType(null)
        const idKey = scriptableIdKeyMap[newItem.type]

        if (idKey === 'section_id') {
          updatePositions(
            {
              ...formattedNewItem,
            },
            newScriptItems
          )
          return newScriptItems
        }

        if (idKey === 'script_text_id') {
          updatePositions(
            {
              script_text_id: formattedNewItem.script_text.id,
              position_in_script: formattedNewItem.position_in_script,
            },
            newScriptItems
          )
          return newScriptItems
        }

        updatePositions(
          {
            question_id: formattedNewItem.question.id,
            position_in_script: formattedNewItem.position_in_script,
          },
          newScriptItems
        )
        return newScriptItems
      })
    }
  }, [newItem])

  return (
    <div className="phone-bank-script">
      <div
        className={`${scriptItemStyles['script-item__container']} ${scriptItemStyles['script-item__container--edit']}`}
      >
        <CardError
          hide={!hasErrors}
          message={t('There was an error editing this script')}
        />
        <div className={scriptItemStyles['script-item__section']}>
          <h3 className={scriptItemStyles['script-item__section__header']}>
            {scriptItems.length ? t('Manage script') : t('Create a script')}
          </h3>
        </div>
        {causeStatement !== null ? (
          <ScriptIntro
            content={causeStatement}
            updateContent={updateContent}
            scriptId={script.id}
          />
        ) : (
          <div className="margin--bottom">
            <Button.Primary
              onClick={() => {
                makeRequest(script.id, {
                  cause_statement: '',
                })
                updateContent({ cause_statement: '' })
              }}
            >
              {t('Include an introduction')}
            </Button.Primary>
          </div>
        )}
        {scriptItems.map((item, i) => {
          if (item.is_section_divider) {
            sectionCounter += 1
          }
          const content =
            item.question?.question_to_ask || item.script_text?.text
          return (
            <ScriptItem
              key={`item-${i}`}
              item={item}
              content={content}
              loading={isLoading}
              choices={item.question?.answer_options}
              sectionNumber={sectionCounter}
              isOnTop={i === 0}
              isOnBottom={i === scriptItems.length - 1}
              moveUp={() => reorderItem(i, -1)}
              moveDown={() => reorderItem(i, 1)}
              removeSelf={() => removeItem(i)}
              updateContent={content => updateContent(content, i)}
              disabled
            />
          )
        })}
      </div>
      <ContentBlock>
        <ToggleBoxGroup
          options={Object.values(scriptItemMap)}
          dataLabel="icon"
          dataDetail="label"
          isCollapsed={!newItemType}
          onChange={selectedBox => {
            setNewItemType(selectedBox ? selectedBox.value : null)
          }}
        />
        {newItemType ? scriptItemMap[newItemType].newComponent : null}
      </ContentBlock>
    </div>
  )
}

export default Script
