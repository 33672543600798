import { useTranslation } from 'react-i18next'
import { useToast } from '@politechdev/blocks-design-system'
import { QCStatus } from 'constants/qualityControl'
import { useRequest, UseRequestState } from 'hooks/useRequest'
import React, { createContext, useEffect, useState } from 'react'
import { useContextOrThrow } from 'utils/contexts'
import { QueryParamScanProvider } from './ScanContext'
import { PhoneVerificationQuestionsProvider } from './PhoneVerificationQuestionsContext'
import { Packet } from './types'
import { advancePacket, fetchPacket } from './requests'

const defaultPacket = {
  filename: '',
  shift_date: '',
  canvasser: { first_name: '', last_name: '' },
  scans: [],
  shift: { id: '' },
  location: {},
  turf: { voter_registration_config: {} },
  creator: { time_zone: 'America/New_York' },
} as unknown as Packet

type PacketContextState = {
  currentPacket: Packet
  setCurrentPacketId: (
    packetId: number | undefined,
    forceReload?: boolean
  ) => void
  packetRequest: Omit<UseRequestState<Packet>, 'makeRequest'> & {
    reload: () => Promise<void>
  }
  advanceCurrentPacket: (newStatus: QCStatus) => Promise<void>
}
const PacketContext = createContext<PacketContextState | undefined>(undefined)

type PacketProviderProps = {
  packetId?: number
  setPacketId: (packetId?: number) => void
}

export const PacketProvider: React.FC<PacketProviderProps> = ({
  packetId,
  setPacketId,
  children,
}) => {
  const [currentPacket, setCurrentPacket] = useState(defaultPacket)

  const { setToast } = useToast()
  const { t } = useTranslation()
  const { makeRequest, ...packetRequest } = useRequest(fetchPacket, {
    onSuccess: setCurrentPacket,
    onError: () =>
      setToast({
        message: t('There was an unknown error loading a part of this page.'),
        variant: 'error',
        onClose: () => null,
      }),
  })

  const reload = async () => {
    await makeRequest(packetId)
  }

  useEffect(() => {
    setCurrentPacket(defaultPacket)
    packetRequest.clearRequest()
    if (packetId != null) {
      void makeRequest(packetId)
    } else {
      packetRequest.clearRequest()
    }
  }, [packetId])

  // eslint-disable-next-line blocks/missing-response-error
  const advancePacketRequest = useRequest(advancePacket, {
    onSuccess: () => {
      void reload()
    },
  })

  const advanceCurrentPacket = (newStatus: QCStatus) =>
    advancePacketRequest.makeRequest(currentPacket, newStatus)

  const setCurrentPacketId = (id?: number, forceReload: boolean = false) => {
    setPacketId(id)
    if (id === packetId && forceReload) {
      void reload()
    }
  }

  return (
    <PacketContext.Provider
      value={{
        currentPacket,
        setCurrentPacketId,
        advanceCurrentPacket,
        packetRequest: { ...packetRequest, reload },
      }}
    >
      <QueryParamScanProvider>
        <PhoneVerificationQuestionsProvider turfId={currentPacket?.turf.id}>
          {children}
        </PhoneVerificationQuestionsProvider>
      </QueryParamScanProvider>
    </PacketContext.Provider>
  )
}

export const PacketConsumer = PacketContext.Consumer

export function useCurrentPacket() {
  const { currentPacket, setCurrentPacketId, packetRequest } =
    useContextOrThrow(PacketContext)
  return [currentPacket, setCurrentPacketId, packetRequest] as const
}

export function usePacketActions() {
  const { advanceCurrentPacket } = useContextOrThrow(PacketContext)
  return {
    advanceCurrentPacket,
  }
}
