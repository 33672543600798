import React from 'react'
import classNames from 'classnames/bind'
import styles from './SectionContainer.module.scss'

const cx = classNames.bind(styles)

const SectionContainer = ({ children, className, ...props }) => (
  <section className={cx('container', className)} {...props}>
    {children}
  </section>
)

export default SectionContainer
