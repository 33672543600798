import {
  Button,
  ButtonBlock,
  MenuButton,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useReactRouter } from 'hooks'
import { createSession } from 'requests/phoneBankSessions'
import { phoneBankTypes, callSessionModes } from 'phone_banks/constants'
import { useCurrent } from 'contexts/index'

const CallMenu = ({ callType, isOperator, disabled }) => {
  const { t } = useTranslation()
  const {
    history: { push },
    match: { params },
  } = useReactRouter()
  const {
    tenantOptions: { voip_enabled: hasVoip },
  } = useCurrent()

  const goToSession = async mode => {
    const { phoneBankId } = params
    if (mode === callSessionModes.OPERATOR) {
      push(`/organize/phone_banks/${phoneBankId}/call_sessions/${mode}`)
    } else {
      createSession(phoneBankId).then(sessionId => {
        push(
          `/organize/phone_banks/${phoneBankId}/call_sessions/${sessionId}/${mode}`
        )
      })
    }
  }

  const isPowerDialerActive = () => true

  const menuItems = [
    {
      label: t('Call using your own phone'),
      value: callSessionModes.EXTERNAL,
    },
    {
      label: t('Call in the browser'),
      value: callSessionModes.VOIP,
    },
  ]

  const buttonText = t('Join Call Session')

  return (
    <ButtonBlock>
      {hasVoip && callType === phoneBankTypes.WEB ? (
        <MenuButton.Accent
          menuItems={menuItems}
          disabled={disabled}
          label={buttonText}
        >
          <MenuButton.Select
            onSelect={value => {
              goToSession(value)
            }}
            options={menuItems}
          />
        </MenuButton.Accent>
      ) : null}

      {callType === phoneBankTypes.MANUAL ||
      (!hasVoip && callType === phoneBankTypes.WEB) ? (
        <Button.Accent
          onClick={() => goToSession(callSessionModes.EXTERNAL)}
          disabled={disabled}
        >
          {buttonText}
        </Button.Accent>
      ) : null}

      {!isOperator && callType === phoneBankTypes.POWER ? (
        <Button.Accent
          onClick={() => goToSession(callSessionModes.AGENT)}
          disabled={!isPowerDialerActive() || disabled}
        >
          {buttonText}
        </Button.Accent>
      ) : null}
      {isOperator && callType === phoneBankTypes.POWER ? (
        <Button.Accent
          onClick={() => goToSession(callSessionModes.OPERATOR)}
          disabled={disabled}
        >
          {t('Start session as operator')}
        </Button.Accent>
      ) : null}
    </ButtonBlock>
  )
}

export default CallMenu
