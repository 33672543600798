import { FieldBlock, TextField } from '@politechdev/blocks-design-system'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './TemplateNameField.module.scss'
import { TemplateContext } from '../TemplateContext/TemplateContext'

const TemplateNameField = () => {
  const { templateName, setTemplateName } = useContext(TemplateContext)
  const { t } = useTranslation()
  return (
    <FieldBlock className={styles['field-block']}>
      <TextField
        label={t('Draft name')}
        onChange={setTemplateName}
        value={templateName}
        required
      />
    </FieldBlock>
  )
}

export default TemplateNameField
