import moment from 'moment'
import { dateTimeFormat } from 'utils/constants'

export const eventCreatorCsvCell = data => data.creator?.name

export const eventTypeCsvCell = data => data.type

export const eventLocationCsvCell = data => data.location?.name

export const eventDateCsvCell = data =>
  moment(data.start_time).format(dateTimeFormat)

export const eventAttendedCountCsvCell = data =>
  data.attended_count + data.walk_in_count

export const eventDidNotAttendCountCsvCell = data =>
  data.no_show_count + data.invited_count

export const eventOrganizationCsvCell = data => data.organization.name

export const eventTeamsCsvCell = data =>
  data.teams.map(team => team.name).join(', ')
