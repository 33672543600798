import { createRowAction } from 'components/DataTable/utils'
import { useAutoTable } from 'contexts'
import { useRequest } from 'hooks/useRequest'
import tableConfig from 'qualityControl/SortablePacketColumnConfig'
import { useTranslation } from 'react-i18next'
import { getGeneralError } from 'utils/formatting'
import AssignmentTableFilters from '../filters/AssignmentTableFilters'
import AssignmentButtons from './AssignmentButtons'
import CheckboxColumn from './CheckboxColumn'
import { usePacketAssignment } from '../PacketAssignmentContext'

const PacketTable = ({
  assignPacket,
  viewInbox,
  prioritizePacket,
  openReassignModal,
}) => {
  const { t } = useTranslation()
  const { renderTable, refreshPage } = useAutoTable()

  const {
    totalPacketCount: totalRecords,
    tableReadyPackets,
    getQcPackets,
    getScheduledUsers,
    unassignPacket,
  } = usePacketAssignment()

  const { defaultSortColumn, defaultSortOrder } = tableConfig

  const {
    makeRequest: unassignReq,
    isLoading: unassignLoading,
    errors: unassignErrors,
  } = useRequest(packetToUnassign => unassignPacket(packetToUnassign), {
    onSuccess: () => Promise.all([refreshPage(), getScheduledUsers()]),
  })

  const {
    makeRequest: getPackets,
    isLoading: packetReqLoading,
    errors: packetReqErrors,
  } = useRequest(params =>
    getQcPackets({
      ...params,
      fields: [
        'id',
        'original_filename',
        'qc_deadline',
        'scans_count',
        'created_at',
        { creator: 'time_zone' },
        { shift: ['status', 'shift_start'] },
        { assignee: ['id', 'name', 'extras', 'email', 'qc_office'] },
        { turf: ['name', 'phone_verification_language'] },
        { location: ['state', 'timezone'] },
      ],
    })
  )

  const unassignErrorMsg = getGeneralError(unassignErrors)
  const packetReqErrorMsg = getGeneralError(packetReqErrors)

  const isLoading = unassignLoading || packetReqLoading
  const errorMsg = unassignErrorMsg || packetReqErrorMsg

  const onReassignClick = () => {
    openReassignModal()
  }

  const columns = [
    <CheckboxColumn key="select-column" />,
    {
      actions: [
        createRowAction(t('Reassign'), assignPacket),
        {
          ...createRowAction(t('Unassign'), packet => unassignReq(packet)),
          show: rowData => rowData.assigned_user,
        },
        {
          ...createRowAction(t('View Assignee Inbox'), viewInbox),
          show: rowData => rowData.assigned_user,
        },
        createRowAction(t('Reprioritize'), prioritizePacket),
      ],
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return (
    <>
      <AssignmentTableFilters />
      <AssignmentButtons onReassignClick={onReassignClick} />
      {renderTable({
        loading: isLoading,
        error: errorMsg,
        data: tableReadyPackets ?? [],
        totalRecords,
        columns,
        fetchAction: getPackets,
        defaultSortColumn,
        defaultSortOrder,
        showDownloadButton: true,
      })}
    </>
  )
}

export default PacketTable
