import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { Timestamp } from 'components/Time/Timestamp'
import { useTranslation } from 'react-i18next'

export const SentOnCell = ({ rowData }) => (
  <TableGridCell>
    <Timestamp dateTime={rowData.created_at} />
  </TableGridCell>
)

export const DraftNameCell = ({ rowData }) => (
  <TableGridCell>
    <span>{rowData.template.name}</span>
  </TableGridCell>
)

export const SentByCell = ({ rowData }) => (
  <TableGridCell>
    <span>{rowData.sender.full_name}</span>
  </TableGridCell>
)

const ContactListContentCell = ({ rowData }) => {
  const { t } = useTranslation()
  if (rowData.list?.name) {
    return <span>{`${t('Lists')}: ${rowData.list.name}`}</span>
  }
  if (rowData.team?.name) {
    return <span>{`${t('Teams')}: ${rowData.team.name}`}</span>
  }
  if (rowData.event?.name) {
    return <span>{`${t('Event guestlist')}: ${rowData.event.name}`}</span>
  }
  return <span>{`${t('No contact list')}`}</span>
}

export const ContactListCell = ({ rowData }) => (
  <TableGridCell>
    <ContactListContentCell rowData={rowData} />
  </TableGridCell>
)
