import { useContext, useEffect } from 'react'
import { ContentBlock, Checkbox } from '@politechdev/blocks-design-system'
import { useTurfs } from 'contexts/index'
import { CallScriptsContext } from '../CallScriptsContext'

const CopyToTurfOptions = () => {
  const {
    currentTurfs: userTurfTree,
    refreshCurrentTurfs,
    turfRefreshIsLoading,
  } = useTurfs()

  const { pendingScript, copyToTurfOptions, toggleCopyToTurfOption } =
    useContext(CallScriptsContext)

  const sortByName = (a, b) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()

    if (nameA > nameB) {
      return 1
    }
    if (nameA < nameB) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  if (turfRefreshIsLoading) return null

  return (
    <ContentBlock>
      {userTurfTree.sort(sortByName).map(({ name, id }) => {
        if (id === pendingScript.turfId) {
          return null
        }
        return (
          <Checkbox
            label={name}
            key={`copy-to-turf-option-${id}`}
            id={`copy-to-turf-option-${id}`}
            name={`copy-to-turf-option-${id}`}
            onChange={() => {
              toggleCopyToTurfOption(id)
            }}
            checked={copyToTurfOptions.includes(id)}
          />
        )
      })}
    </ContentBlock>
  )
}

export default CopyToTurfOptions
