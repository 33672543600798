import {
  Button,
  Icon,
  TextBlock,
  ButtonBlock,
  Font,
} from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import styles from './TemplateContentCard.module.scss'

const cx = classNames.bind(styles)

const TemplateContentCard = ({
  type,
  value,
  onEdit,
  onDelete,
  animated,
  clearAnimation,
}) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.card, styles['animation-container'])}>
      <div
        className={cx('animation-block', {
          'animation-block--animated': animated,
        })}
        onAnimationEnd={clearAnimation}
      />
      <div className={styles.card__header}>
        <Font.Label>{type}</Font.Label>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onEdit}>
            <Icon.Pencil aria-label={t('Edit')} />
          </Button.Secondary>
          <Button.Secondary onClick={onDelete}>
            <Icon.Times aria-label={t('Remove')} />
          </Button.Secondary>
        </ButtonBlock>
      </div>
      <TextBlock className={styles.card__preview}>
        <Font.Copy className={styles.card__preview__text}>{value}</Font.Copy>
      </TextBlock>
    </div>
  )
}

export default TemplateContentCard
