import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react'
import moment from 'moment'
import { DEFAULT_VIEWPORT } from 'components/Map/constants'
import { useCurrent, useMap, usePagination, useQueryParams } from 'contexts'
import { useEvent, useOnScreen, useReactRouter, useRequest } from 'hooks'
import { fetchLocationMap } from 'requests/locations'
import countiesByState from 'utils/counties.json'
import { formatErrorMessage } from 'utils/formatting'
import { SHIFT_TYPE } from 'shifts/constants'
import { VIEW_TYPES } from '../constants'

const LocationMapContext = createContext()

export const LocationMapProvider = ({ children }) => {
  const { fitBounds, getBounds } = useMap()
  const { queryParams, setQueryParams } = useQueryParams()
  const { page, pageSize, setPage } = usePagination()

  const { currentTenantStateOptions, doesCurrentUserHavePermission } =
    useCurrent()

  const hasPermission = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'view',
  })

  const [hasMoved, setHasMoved] = useState(false)
  const [useClusters, setUseClusters] = useState(false)
  const [locations, setLocations] = useState([])
  const [totalLocations, setTotalLocations] = useState(0)
  const [dates, setDates] = useState({
    start: moment()
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString(),
    end: moment()
      .add(1, 'day')
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString(),
  })

  const { location } = useReactRouter()

  const shiftType = useMemo(
    () => location.pathname.split('/')[2],
    [location.pathname]
  )

  const isVR = useMemo(() => shiftType === SHIFT_TYPE.REGISTRATION, [shiftType])

  const activeLocationRef = useRef()
  const activeLocationVisible = useOnScreen(activeLocationRef)

  const [viewport, updateViewport] = useState({
    latitude: +queryParams.lat || DEFAULT_VIEWPORT.latitude,
    longitude: +queryParams.lng || DEFAULT_VIEWPORT.longitude,
    zoom: +queryParams.zoom || DEFAULT_VIEWPORT.zoom,
  })
  const setViewport = input => {
    updateViewport(viewport => {
      const value = typeof input === 'function' ? input(viewport) : input

      setQueryParams({
        lat: value.latitude?.toFixed(7),
        lng: value.longitude?.toFixed(7),
        zoom: Math.round(value.zoom * 2) / 2,
      })
      return value
    })
    setHasMoved(true)
  }

  const [bounds, setBounds] = useState(() => {
    const safeAsNumber = param => (Number.isNaN(param) ? undefined : +param)

    const bounds = {
      ne: {
        lat: safeAsNumber(queryParams.bounds?.ne?.lat),
        lng: safeAsNumber(queryParams.bounds?.ne?.lng),
      },
      sw: {
        lat: safeAsNumber(queryParams.bounds?.sw?.lat),
        lng: safeAsNumber(queryParams.bounds?.sw?.lng),
      },
    }
    if (bounds.ne.lat && bounds.ne.lng && bounds.sw.lat && bounds.sw.lng) {
      return bounds
    }

    return null
  })
  const updateBounds = (bounds = getBounds()) => {
    setBounds(bounds)
    setQueryParams({ bounds, county: undefined, state: undefined })
    setHasMoved(false)
  }

  const getDefaultState = () => {
    if (currentTenantStateOptions.length === 1) {
      return currentTenantStateOptions[0].value
    }
    return undefined
  }
  const isValidState = state => {
    if (!state) return false

    return (currentTenantStateOptions || []).find(
      ({ value }) => value.toLowerCase() === state.toLowerCase()
    )
  }
  const [state, setState] = useState(() => {
    if (isValidState(queryParams.state)) {
      return queryParams.state
    }
    return getDefaultState()
  })
  const clearState = () => setState(getDefaultState())

  const isValidCounty = (county, state) => {
    if (!isValidState(state)) return false
    if (!county) return false

    return countiesByState[state.toUpperCase()].find(
      c => c.toLowerCase() === county.toLowerCase()
    )
  }
  const [county, updateCounty] = useState(() => {
    if (isValidCounty(queryParams.county, queryParams.state)) {
      return queryParams.county
    }
    return undefined
  })
  const setCounty = county => {
    updateCounty(county)
    setBounds(undefined)
    setQueryParams({ county, state, bounds: undefined })
  }
  const clearCounty = () => updateCounty(undefined)

  const [viewType, updateViewType] = useState(() => {
    if ([VIEW_TYPES.BOUNDS, VIEW_TYPES.COUNTY].includes(queryParams.view)) {
      return queryParams.view
    }
    return VIEW_TYPES.BOUNDS
  })
  const setViewType = viewType => {
    updateViewType(viewType)
    setQueryParams({ view: viewType })
  }

  useEffect(() => {
    if (viewType !== VIEW_TYPES.COUNTY) {
      state && clearState()
      county && clearCounty()
    }
  }, [viewType])

  const [activeLocation, setActiveLocation] = useState(null)
  const clearActiveLocation = () => {
    setActiveLocation(null)
    activeLocationRef.current = null
  }

  const { makeRequest, isLoading, errors } = useRequest(fetchLocationMap, {
    onSuccess: ({ locations: incomingLocations, meta: { total_count } }) => {
      setLocations(incomingLocations)
      setTotalLocations(total_count)
      if ((!bounds && !queryParams.lat) || viewType === VIEW_TYPES.COUNTY) {
        fitBounds(incomingLocations)
      }
    },
  })

  const errorMsg = formatErrorMessage(errors)

  const getLocations = useEvent(() => {
    if (!hasPermission) return
    clearActiveLocation()

    makeRequest({
      per: pageSize,
      current_page: page,
      county,
      start_date: dates.start,
      end_date: dates.end,
      boundary: bounds
        ? {
            ne_lat: bounds.ne.lat,
            ne_lng: bounds.ne.lng,
            sw_lat: bounds.sw.lat,
            sw_lng: bounds.sw.lng,
          }
        : undefined,
    })
  })

  const hasPageOffset = () => {
    if (page > 1) {
      setPage(1)
      return true
    }

    return false
  }

  useEffect(() => {
    getLocations()
  }, [page, dates, isVR])

  useEffect(() => {
    if (viewType === VIEW_TYPES.BOUNDS && !hasPageOffset()) {
      getLocations()
    }
  }, [bounds, viewType])

  useEffect(() => {
    if (viewType === VIEW_TYPES.COUNTY && !hasPageOffset()) {
      getLocations()
    }
  }, [county, viewType])

  return (
    <LocationMapContext.Provider
      value={{
        isVR,
        viewport,
        setViewport,
        activeLocation,
        setActiveLocation,
        clearActiveLocation,
        activeLocationRef,
        activeLocationVisible,
        viewType,
        setViewType,
        hasMoved,
        setHasMoved,
        useClusters,
        setUseClusters,
        dates,
        setDates,
        state,
        setState,
        clearState,
        stateOptions: currentTenantStateOptions,
        county,
        setCounty,
        clearCounty,
        updateBounds,
        locations,
        totalLocations,
        isLoading,
        errorMsg,
        hasPermission,
      }}
    >
      {children}
    </LocationMapContext.Provider>
  )
}

export const useLocationMap = () => {
  const context = useContext(LocationMapContext)
  if (context === undefined) {
    throw new Error('useLocationMap must be used within a LocationMapProvider')
  }
  return context
}
