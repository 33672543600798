import React from 'react'
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  ReferenceLine,
  Area,
  Tooltip,
  Label,
  Legend,
} from 'recharts'

import styles from './FilledLineChart.module.scss'

/**
 * series object
 * @typedef {Object} Series
 * @property {string} dataKey - key used to access data
 * @property {string} color - color used to display the line
 */

/**
 * props object
 * @typedef {Object} Props
 * @property {Array<Object>} data - array of data objects
 * @property {string|number} reference - value of reference line on y axis
 * @property {Array<Series>} series - array of object used to determine what lines are drawn on chart
 * @property {string} xAxisKey - key used to access data
 * @property {Function} xTickFormatter - function used to format tick, and tootip labels,
 */

/**
 *
 * @param {Props} props
 * @returns React.Component
 */
const FilledLineChart = ({
  data,
  reference,
  referenceLabel,
  series,
  xAxisKey,
  xTickFormatter,
  yLabel,
}) => (
  <div className={styles['chart-container']}>
    <ResponsiveContainer width="90%" height={300}>
      <AreaChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey={xAxisKey}
          tickFormatter={xTickFormatter}
          padding={{ left: 20, right: 20 }}
        />
        <YAxis type="number" domain={[0, +reference + 50]}>
          <Label
            angle={-90}
            value={yLabel}
            position="insideLeft"
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip labelFormatter={xTickFormatter} />
        <Legend verticalAlign="top" align="right" height={36} />
        <ReferenceLine y={reference} stroke="red" strokeDasharray="10">
          <Label
            className={styles['reference-line-label']}
            value={referenceLabel}
            offset={20}
            position="insideLeft"
          />
        </ReferenceLine>
        {series.map(({ dataKey, color }) => (
          <Area
            key={dataKey}
            id={dataKey}
            type="linear"
            dataKey={dataKey}
            stroke={color}
            fill={color}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  </div>
)

export default FilledLineChart
