import { Button, Icon } from '@politechdev/blocks-design-system'
import { useRoutePathParams, useReactRouter } from 'hooks'

const CloseSidebarButton = () => {
  const {
    history: { push },
  } = useReactRouter()

  const [, replaceParams] = useRoutePathParams()

  const goBackToListView = () => push(replaceParams({ packetId: undefined }))

  return (
    <div className="inbox-viewer__collapse-bar">
      <Button.Secondary onClick={goBackToListView}>
        <Icon.ChevronRight />
      </Button.Secondary>
    </div>
  )
}

export default CloseSidebarButton
