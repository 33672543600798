import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { MultiSearchSelect, CardError } from 'components'
import { useRequest } from 'hooks'
import { fetchPeopleDistricts } from 'requests/people'
import { buildMultiOptions } from 'utils/select'
import { buildOption, formatDistrictOptions } from './utils'

const DistrictSelectFieldDEPRECATED = ({
  label,
  onSelect,
  districts = [],
  districtType,
  ...props
}) => {
  const { t } = useTranslation()

  const [options, setOptions] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [multiValue, setMultiValue] = useState(
    options.filter(option => districts.some(val => val === option.value))
  )
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  const { makeRequest, isLoading } = useRequest(fetchPeopleDistricts, {
    onSuccess: ({ districts }) => {
      setOptions(formatDistrictOptions(districts, districtType))
    },
    onError: () => {
      setErrorMsg('Could not get districts')
    },
  })

  const multiOptions = buildMultiOptions(options, districts, buildOption)

  useEffect(() => {
    makeRequest()
  }, [debounced])

  useEffect(() => {
    setMultiValue(districts.map(buildOption))
  }, [JSON.stringify(districts)])

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(({ value }) => value))
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      <MultiSearchSelect
        label={label || t('District')}
        options={multiOptions}
        onChange={handleMultiChange}
        onInputChange={setQuery}
        value={multiValue}
        isLoading={isLoading}
        {...props}
      />
    </>
  )
}

DistrictSelectFieldDEPRECATED.propTypes = {
  label: PropTypes.string,
  district: PropTypes.object,
  districtType: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
}

export default DistrictSelectFieldDEPRECATED
