const { uniqBy } = require('lodash')

export const buildMultiOptions = (options, multiValues, buildOption) =>
  uniqBy([...options, ...multiValues.map(buildOption)], option => option.value)

export const buildSingleOptions = (options, value, buildOption) =>
  uniqBy(
    options.concat(value ? [buildOption(value)] : []),
    option => option.value
  )

export const sortOptionsByLabel = arr =>
  arr.toSorted((a, b) => a.label.localeCompare(b.label))
