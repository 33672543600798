import React from 'react'
import moment from 'moment'
import classNames from 'classnames/bind'
import Items from '../Items/Items'
import styles from './WeekRow.module.scss'

const cx = classNames.bind(styles)

const WeekRow = ({
  week,
  showMarker = false,
  rowLimit = null,
  className = null,
}) => (
  <div className={cx('week', { 'week--withMarker': showMarker }, className)}>
    <div className={styles.days}>
      {week.map(({ date, overflow }) => {
        const isToday = date.isSame(moment(), 'day')

        return (
          <div
            key={date.format('X')}
            className={cx('day', {
              'day--overflow': overflow,
              'day--today': isToday,
            })}
          >
            {showMarker && (
              <div className={styles.marker}>
                <span>{date.format('D')}</span>
              </div>
            )}
          </div>
        )
      })}
    </div>
    <Items
      startDate={week[0].date}
      endDate={week[week.length - 1].date}
      rowLimit={rowLimit}
    />
  </div>
)

export default WeekRow
