import { useContext, useState } from 'react'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { fetchImportErrors } from 'requests/duplicatePrevention'
import tableConfig from './tableConfig'
import { RecordsContext } from '../RecordsContext/RecordsContext'

const RecordsTable = () => {
  const { match } = useReactRouter()
  const { renderTable } = useAutoTable()
  const { count, setCount } = useContext(RecordsContext)
  const [data, setData] = useState([])

  const {
    makeRequest: importReq,
    isLoading: isImportReqLoading,
    hasErrors: importError,
  } = useRequest(
    params =>
      fetchImportErrors(match.params.importId, {
        current_page: params?.page,
        per: params?.pageSize,
        sort_attr: params?.sortColumn,
        sort_dir: params?.sortOrder,
        fields: ['id', 'row_data', 'duplicate_found'],
        filters: {
          rules: params?.filterRules,
          conjunction: params?.filterConjunction,
        },
        indexed: true,
      }),
    {
      onSuccess: ({ meta, 'imports/error_rows': errorRows }) => {
        setData(errorRows)
        setCount(meta.total_count)
      },
    }
  )

  return renderTable({
    data,
    loading: isImportReqLoading,
    error: importError,
    totalRecords: count,
    columns: tableConfig.columns,
    fetchAction: importReq,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
  >
    <RecordsTable />
  </AutoTableProvider>
)
