import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Icon,
  FieldBlock,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useFilters } from 'contexts'
import ButtonBlock from 'components/ButtonBlock/ButtonBlock'
import CreateFilterModal from './CreateFilterModal'
import EditFilterModal from './EditFilterModal'

const SavedFilterControls = () => {
  const { t } = useTranslation()

  const {
    availableFilters,
    activeFilterId,
    saveFilter,
    setActiveFilter,
    filterRules,
    hiddenColumns,
    activeFilter,
  } = useFilters()

  const filterOptions = availableFilters.map(f => ({
    label: f.name,
    value: f.id,
  }))

  const [openDialogName, setDialogOpen] = useState(false)
  const openDialog = dialogName => () => setDialogOpen(dialogName)

  const actionButtonProps = activeFilterId
    ? { iconChildren: 'edit', onClick: openDialog('edit') }
    : { iconChildren: 'save', onClick: openDialog('create') }

  const closeDialog = () => setDialogOpen(false)

  const handleSaveClick = filterName => {
    saveFilter(filterName)
    closeDialog()
  }

  return (
    <>
      {!!filterOptions.length && (
        <FieldBlock>
          <SelectField
            id="saved-filters"
            className="table-filters__select"
            label={t('Saved filters')}
            value={activeFilterId}
            options={filterOptions}
            onSelect={setActiveFilter}
          />
        </FieldBlock>
      )}
      {!activeFilter?.default &&
      (filterRules.length || hiddenColumns.length) ? (
        <ButtonBlock>
          <Button.Secondary {...actionButtonProps}>
            <span>{activeFilterId ? t('Edit') : t('Save')}</span>
            {activeFilterId ? <Icon.Pencil /> : <Icon.Save />}
          </Button.Secondary>
        </ButtonBlock>
      ) : null}
      <CreateFilterModal
        close={closeDialog}
        save={handleSaveClick}
        isOpen={openDialogName === 'create'}
      />
      <EditFilterModal close={closeDialog} isOpen={openDialogName === 'edit'} />
    </>
  )
}

export default SavedFilterControls
