import {
  ENGLISH,
  phoneVerificationQuestions as phoneVerificationQuestionsByLanguage,
  questionKeys,
  type QuestionsRecord,
  type Language,
  type QuestionKey,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useRequest, type UseRequestData } from 'hooks'
import { useContextOrThrow } from 'utils/contexts'
import { fetchPhoneVerificationQuestions } from './requests'

type PhoneVerificationQuestionsState = {
  phoneVerificationQuestions: QuestionsRecord
  phoneVerificationQuestionsRequest: Omit<
    UseRequestData<QuestionsRecord>,
    'makeRequest' | 'response' | 'clearRequest'
  >
  scriptLanguage: Language
  setScriptLanguage: (language: Language) => void
}

export const PhoneVerificationQuestionsContext = createContext<
  PhoneVerificationQuestionsState | undefined
>(undefined)

export const PhoneVerificationQuestionsProvider: React.FC<{
  turfId: number | undefined
}> = ({ turfId, children }) => {
  const [scriptLanguage, setCurrentScriptLanguage] = useState<Language>(ENGLISH)
  const [phoneVerificationQuestions, setPhoneVerificationQuestions] =
    useState<QuestionsRecord>(phoneVerificationQuestionsByLanguage[ENGLISH])

  // eslint-disable-next-line blocks/missing-response-error
  const phoneVerificationQuestionsRequest = useRequest(
    fetchPhoneVerificationQuestions,
    {
      onSuccess: turfQuestions => {
        setPhoneVerificationQuestions(questions => {
          const turfQuestionIds = new Map(
            turfQuestions.map(({ id, question }) => [question, id])
          )

          return Object.fromEntries(
            questionKeys.map(key => [
              key,
              {
                ...questions[key],
                id: turfQuestionIds.get(key),
              },
            ])
          ) as QuestionsRecord
        })
      },
    }
  )

  useEffect(() => {
    if (!turfId) return

    const controller = new AbortController()
    void phoneVerificationQuestionsRequest.makeRequest(turfId, {
      signal: controller.signal,
    })

    return () => controller.abort()
  }, [turfId])

  const setScriptLanguage = useCallback((language: Language) => {
    setCurrentScriptLanguage(language)
    setPhoneVerificationQuestions(
      oldQuestions =>
        Object.fromEntries(
          questionKeys.map((key: QuestionKey) => [
            key,
            {
              ...oldQuestions[key],
              choices:
                phoneVerificationQuestionsByLanguage[language][key].choices,
              content:
                phoneVerificationQuestionsByLanguage[language][key].content,
            },
          ])
        ) as QuestionsRecord
    )
  }, [])

  return (
    <PhoneVerificationQuestionsContext.Provider
      value={{
        phoneVerificationQuestions,
        phoneVerificationQuestionsRequest,
        scriptLanguage,
        setScriptLanguage,
      }}
    >
      {children}
    </PhoneVerificationQuestionsContext.Provider>
  )
}

export const usePhoneVerificationQuestions = () => {
  const { phoneVerificationQuestions, phoneVerificationQuestionsRequest } =
    useContextOrThrow(PhoneVerificationQuestionsContext)
  return [
    phoneVerificationQuestions,
    phoneVerificationQuestionsRequest,
  ] as const
}

export const useScriptLanguage = () => {
  const { scriptLanguage, setScriptLanguage } = useContextOrThrow(
    PhoneVerificationQuestionsContext
  )
  return [scriptLanguage, setScriptLanguage] as const
}
