import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TextBlock } from '@politechdev/blocks-design-system'
import { RolesLocalContext } from './RoleModal'

const RoleModalDeleteContent = () => {
  const { t } = useTranslation()
  const { currentRole } = useContext(RolesLocalContext)
  return (
    <TextBlock>
      {t('Are you sure you want to delete')} <strong>{currentRole.name}</strong>
    </TextBlock>
  )
}

export default RoleModalDeleteContent
