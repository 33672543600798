import { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { View } from 'components'
import { useReactRouter } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useTurfs } from 'contexts/index'
import ScheduledExportsSelect from './ScheduledExportsSelect'
import ScheduledExportsSingle from './ScheduledExportsSingle/ScheduledExportsSingle'
import ScheduledExportsForm from './ScheduledExportsForm/ScheduledExportsForm'

const ScheduledExports = () => {
  const { match, history } = useReactRouter()
  const { refreshCurrentTurfs, turfRefreshIsLoading, turfRefreshComplete } =
    useTurfs()

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  const { t } = useTranslation()

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/new`}
        render={routeProps => (
          <View loading={turfRefreshIsLoading}>
            {turfRefreshComplete && (
              <ScheduledExportsForm {...routeProps} t={t} history={history} />
            )}
          </View>
        )}
      />
      <Route
        exact
        path={`${match.url}/:id`}
        render={routeProps => (
          <View loading={turfRefreshIsLoading}>
            {turfRefreshComplete && (
              <ScheduledExportsSingle {...routeProps} t={t} history={history} />
            )}
          </View>
        )}
      />
      <Route
        exact
        path={`${match.url}/:id/edit`}
        render={routeProps => (
          <View loading={turfRefreshIsLoading}>
            {turfRefreshComplete && (
              <ScheduledExportsForm
                isEdit
                {...routeProps}
                t={t}
                history={history}
              />
            )}
          </View>
        )}
      />
      <Route
        exact
        path={`${match.url}`}
        render={routeProps => (
          <View loading={turfRefreshIsLoading}>
            {turfRefreshComplete && (
              <ScheduledExportsSelect {...routeProps} t={t} history={history} />
            )}
          </View>
        )}
      />
    </Switch>
  )
}

export default ScheduledExports
