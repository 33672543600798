import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete as ReactMDAutocomplete } from 'react-md'
import styles from './Autocomplete.module.scss'

const Autocomplete = ({ data, onChange, loading, ...props }) => {
  const [searchText, setSearchText] = useState()
  useEffect(() => {
    onChange && onChange(searchText)
  }, [searchText])

  const getData = () => {
    if (loading || !Array.isArray(data)) {
      return [
        <li key="loading-results" className={styles.empty}>
          <span>Loading...</span>
        </li>,
      ]
    }
    if (!data || !data.length) {
      return [
        <li key="no-results" className={styles.empty}>
          <span>No results found</span>
        </li>,
      ]
    }
    return searchText ? data : []
  }

  return (
    <ReactMDAutocomplete {...props} data={getData()} onChange={setSearchText} />
  )
}

Autocomplete.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
}

Autocomplete.defaultProps = {
  data: [],
  onChange: () => {},
  loading: false,
}

export default Autocomplete
