import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styles from './Stepper.module.scss'

export default class Stepper extends Component {
  static propTypes = {
    activeStep: PropTypes.number,
    children: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  }

  static defaultProps = {
    activeStep: undefined,
  }

  state = {
    activeStep: this.props.activeStep || 1,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activeStep) {
      this.setState({
        activeStep: nextProps.activeStep,
      })
    }
  }

  nextStep() {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }))
  }

  previousStep() {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }))
  }

  renderSteps() {
    return React.Children.map(this.props.children, (child, i) => {
      const step = i + 1
      return React.cloneElement(child, {
        isControlled: Number.isInteger(this.props.activeStep),
        horizontal: this.props.horizontal,
        step,
        active: step === this.state.activeStep,
        complete: step < this.state.activeStep,
        last: step === React.Children.count(this.props.children),
        nextStep: this.nextStep.bind(this),
        previousStep: this.previousStep.bind(this),
      })
    })
  }

  renderHorizontalStepLabels() {
    const stepLabels = React.Children.map(this.props.children, (child, i) => {
      const step = i + 1
      return React.cloneElement(child, {
        horizontalStepLabel: true,
        step,
        active: step === this.state.activeStep,
        complete: step < this.state.activeStep,
        last: step === React.Children.count(this.props.children),
        nextStep: () => {
          this.nextStep()
        },
        previousStep: () => {
          this.previousStep()
        },
      })
    })

    return (
      <ol className={styles['stepper--horizontal__header']}>{stepLabels}</ol>
    )
  }

  render() {
    const { horizontal } = this.props

    if (horizontal) {
      return (
        <div className={`${styles.stepper} ${styles['stepper--horizontal']}`}>
          {this.renderHorizontalStepLabels()}
          {this.renderSteps()}
        </div>
      )
    }
    return <div className={styles.stepper}>{this.renderSteps()}</div>
  }
}
