import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components'
import classNames from 'classnames/bind'
import styles from './ToggleBoxGroup.module.scss'

const cx = classNames.bind(styles)

const ToggleBoxGroup = ({
  startingIndex,
  options,
  isCollapsed,
  onChange,
  dataLabel,
  dataDetail,
}) => {
  const [activeIndex, setActiveIndex] = useState(startingIndex)

  const selectToggleBox = (toggleBox, index) => {
    setActiveIndex(index)
    onChange(toggleBox)
  }

  const deselectToggleBox = () => {
    setActiveIndex(undefined)
    onChange(null)
  }

  useEffect(() => {
    if (isCollapsed) deselectToggleBox()
  }, [isCollapsed])

  return (
    <div className={styles['toggle-box__group']}>
      {options.map((toggleBox, i) => {
        if (activeIndex === i || activeIndex === undefined) {
          return (
            <div
              key={i}
              className={cx('toggle-box', {
                'toggle-box--selected': activeIndex === i,
              })}
            >
              <div
                role="button"
                className={styles['toggle-box__content']}
                tabIndex="0"
                onClick={
                  toggleBox.isButton
                    ? toggleBox.onClick
                    : () => selectToggleBox(toggleBox, i)
                }
                onKeyPress={e => {
                  if (e.key !== 'Enter') return
                  toggleBox.isButton
                    ? toggleBox.onClick()
                    : selectToggleBox(toggleBox, i)
                }}
              >
                <div className={styles['toggle-box__label']}>
                  {toggleBox[dataLabel]}
                </div>
                <div className={styles['toggle-box__details']}>
                  {toggleBox[dataDetail]}
                </div>
              </div>
              {activeIndex === i ? (
                <div className={styles['toggle-box__button']}>
                  <Button icon onClick={deselectToggleBox}>
                    remove_circle
                  </Button>
                </div>
              ) : null}
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

ToggleBoxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      isButton: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ).isRequired,
  isCollapsed: PropTypes.bool,
  onChange: PropTypes.func,
  dataLabel: PropTypes.node.isRequired,
  dataDetail: PropTypes.string.isRequired,
}

ToggleBoxGroup.defaultProps = {
  isCollapsed: false,
  onChange: () => null,
}

export default ToggleBoxGroup
