import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Section, LoadBar, CardError } from 'components'
import { Font, TextBlock } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks/useRequest'
import { fetchGoals } from 'requests/fieldManagement'
import { buildGoalsParams, getTotalTarget } from 'utils/fieldManagement'
import { ProjectionContext } from '../ProjectionContext/ProjectionContext'

const ProjectionAssociatedGoal = ({ selectedTurf, startDate, endDate }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { goal, setGoal } = useContext(ProjectionContext)

  const { makeRequest: fetchGoalsRequest } = useRequest(fetchGoals, {
    onSuccess: resp => {
      setLoading(false)
      setGoal(
        getTotalTarget(resp['field_management/goals'], startDate, endDate)
      )
    },
    onError: () => {
      setError(true)
    },
  })

  useEffect(() => {
    setLoading(true)
    setError(false)
    fetchGoalsRequest(
      buildGoalsParams(startDate, endDate, undefined, selectedTurf.id)
    )
  }, [selectedTurf, startDate, endDate])

  return (
    <Section secondary>
      <LoadBar show={loading} />
      <CardError
        hide={!error}
        message={t('An internal error ocurred while trying to fetch goal data')}
      />
      <TextBlock>
        <Font.Copy variant="highlight">
          {t('Goal for')} {selectedTurf.name}
        </Font.Copy>
        <Font.Display variant="large">{goal}</Font.Display>
      </TextBlock>
    </Section>
  )
}

export default ProjectionAssociatedGoal
