import React from 'react'
import { Icon, Button } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import DialogueItem from './DialogueItem'
import PhoneVerificationQuestionItem from './PhoneVerificationQuestionItem'
import questionItemstyles from './QuestionItem.module.scss'

const scriptItemMap = {
  dialogue: {
    icon: <Icon.CommentAltLines />,
    ItemContent: DialogueItem,
  },
  single_choice: {
    icon: <Icon.QuestionCircle />,
    ItemContent: PhoneVerificationQuestionItem,
  },
}

const PhoneVerificationScriptItem = ({
  type = 'single_choice',
  editable,
  isOnTop,
  isOnBottom,
  moveUp,
  moveDown,
  remove,
  ...itemProps
}) => {
  const { t } = useTranslation()
  const { icon, ItemContent } = scriptItemMap[type]

  return (
    <div className={questionItemstyles['script-item__wrapper']}>
      <div className={questionItemstyles['script-item']}>
        <div className={questionItemstyles['script-item__icon']}>{icon}</div>
        <div className={questionItemstyles['script-item__content']}>
          <ItemContent editable={editable} {...itemProps} />
        </div>
        <div className={questionItemstyles['script-item__actions']}>
          <Button.Secondary onClick={remove} aria-label={t('Remove')}>
            <Icon.Times />
          </Button.Secondary>
        </div>
      </div>
      <div className={questionItemstyles['script-item__reorder-arrows']}>
        {editable && (
          <>
            <Button.Secondary
              disabled={isOnTop}
              onClick={moveUp}
              key="move-up"
              aria-label={t('Move up')}
            >
              <Icon.ChevronUp alt={t('Move up')} />
            </Button.Secondary>
            <Button.Secondary
              disabled={isOnBottom}
              onClick={moveDown}
              key="move-down"
              aria-label={t('Move down')}
            >
              <Icon.ChevronDown alt={t('Move down')} />
            </Button.Secondary>
          </>
        )}
      </div>
    </div>
  )
}

export default PhoneVerificationScriptItem
