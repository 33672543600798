import { combineName } from 'utils/reporting'
import {
  basePartyLeanColumns,
  partyLeanDataDictionaryUrl,
} from '../columns/basePartyLeanColumns'

export default {
  id: 'party_lean_by_canvasser',
  name: 'Canvasser',
  description:
    'This report provides information on the party lean values of registrants. It summarizes this information by the canvasser who collected the forms.',
  dataDictionaryUrl: partyLeanDataDictionaryUrl,
  columns: [
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    ...basePartyLeanColumns,
  ],
}
