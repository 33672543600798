import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { RoleAbilityContext } from '../RoleAbilityContextProvider/RoleAbilityContextProvider'
import styles from './RoleAbilityDependantModal.module.scss'

const RoleAbilityDependantModal = () => {
  const { t } = useTranslation()
  const {
    pendingAction: { ability, dependants },
    clearPendingAction,
    toggleAbilities,
  } = useContext(RoleAbilityContext)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(!!dependants.length)
  }, [dependants])

  return (
    <Modal
      id="roles-dependant-modal"
      title={t('Required by Dependants')}
      isOpen={isOpen}
      onHide={clearPendingAction}
    >
      <Modal.Body>
        {ability && (
          <ContentBlock>
            <p>
              The following abilities require{' '}
              <span className={styles.strong}>
                {ability.permission.label} - {ability.label}
              </span>{' '}
              and must also be disabled:
            </p>
            <div className={styles.list}>
              <ul>
                {dependants.map(dependant => (
                  <li key={dependant.id}>
                    <span className={styles.strong}>
                      {dependant.permission.label}
                    </span>{' '}
                    - {dependant.label}
                  </li>
                ))}
              </ul>
            </div>
          </ContentBlock>
        )}
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={clearPendingAction}>
            {t('Cancel')}
          </Button.Secondary>
          <Button onClick={toggleAbilities}>{t('Disable All')}</Button>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default RoleAbilityDependantModal
