import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEvent, useReactRouter, useRequest } from 'hooks'
import { CardError } from 'components'
import { Button, ButtonBlock, Modal } from '@politechdev/blocks-design-system'
import {
  useRoleModalActions,
  useRoleModalState,
} from '../RoleModalStateProvider/RoleModalStateProvider'
import RoleModalUpdateContent from './RoleModalUpdate'
import RoleModalCreateContent from './RoleModalCreate/RoleModalCreate'
import RoleModalDeleteContent from './RoleModalDelete'
import { RoleContext } from '../RoleContext/RoleContext'

export const RolesLocalContext = createContext()
export const RoleModalFormContext = createContext()

const RoleModal = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { modalTitle, actionLabel, modalType } = useRoleModalState()
  const { closeModal } = useRoleModalActions()
  const {
    sortedRoles: roles,
    currentRole,
    updateRole,
    createRole,
    deleteRole,
  } = useContext(RoleContext)

  let action = () => {}
  let Content = () => null
  if (modalType === RoleModal.TYPE.UPDATE) {
    action = updateRole
    Content = RoleModalUpdateContent
  } else if (modalType === RoleModal.TYPE.CREATE) {
    action = createRole
    Content = RoleModalCreateContent
  } else if (modalType === RoleModal.TYPE.DELETE) {
    action = deleteRole
    Content = RoleModalDeleteContent
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(action, {
    onSuccess: role => {
      closeModal()
      history.push(role.id ? `/admin/roles/${role.id}` : '/admin/roles')
    },
  })

  const [formData, setFormData] = useState(currentRole)
  const setField = (val, key) => {
    setFormData(prev => ({
      ...prev,
      [key]: val,
    }))
  }

  const formSubmit = useEvent(async () => {
    await makeRequest(formData)
  })

  useEffect(() => {
    modalType === RoleModal.TYPE.CREATE
      ? setFormData({})
      : setFormData({ ...currentRole })
  }, [modalType])

  const errorMessage =
    modalType === RoleModal.TYPE.DELETE
      ? `We could not delete the role. Ensure the role you are trying to delete is not in use.`
      : 'An internal error occurred while trying to process your request'

  return (
    <RolesLocalContext.Provider value={{ roles, currentRole }}>
      <RoleModalFormContext.Provider value={{ formSubmit, setField, formData }}>
        <Modal
          id={`role-${actionLabel.toLowerCase()}-modal`}
          title={t(modalTitle)}
          isOpen={!!modalType}
          onHide={closeModal}
          showLoading={isLoading}
          allowOverflow
        >
          <Modal.Body>
            <CardError
              message={t(errorMessage)}
              hide={!hasErrors}
              hideSupportLink
            />
            <form>{Content && <Content />}</form>
          </Modal.Body>
          <Modal.Actions>
            <ButtonBlock justify="right">
              <Button.Secondary onClick={closeModal}>
                {t('Cancel')}
              </Button.Secondary>
              <Button.Accent
                disabled={
                  !formData.name || !formData.description || !formData.parent_id
                }
                onClick={formSubmit}
              >
                {t(actionLabel)}
              </Button.Accent>
            </ButtonBlock>
          </Modal.Actions>
        </Modal>
      </RoleModalFormContext.Provider>
    </RolesLocalContext.Provider>
  )
}

RoleModal.TYPE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

export default RoleModal
