export const buildOption = districtValue => ({
  value: districtValue,
  label: districtValue,
})

export const formatDistrictOptions = (districts, districtType) =>
  districts
    .map(district => district[districtType])
    .filter(Boolean)
    .map(buildOption)
