import React from 'react'
import { Checkbox } from '@politechdev/blocks-design-system'

const MultipleChoice = ({ options = [], selectedIds = [], onSelect }) => (
  <div>
    {options.map(choice => (
      <Checkbox
        key={choice.id}
        label={choice.response}
        checked={selectedIds.find(id => id === choice.id)}
        onChange={() => onSelect(choice.id)}
      />
    ))}
  </div>
)

export default MultipleChoice
