import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MultiSearchSelect, SearchSelect } from 'components'
import { US_STATES } from 'utils/constants'
import { buildOption } from './utils'

const StateSelectFieldDEPRECATED = ({
  id,
  label = 'State',
  onSelect,
  state = {},
  states = [],
  isMulti,
  required,
  clearable,
  className,
}) => {
  const options = Object.keys(US_STATES).map(buildOption)
  const [value, setValue] = useState(state)
  const [multiValue, setMultiValue] = useState(
    options.filter(option => states.some(val => val === option.value))
  )

  useEffect(() => {
    setValue(state)
  }, [state])

  useEffect(() => {
    setMultiValue(states.map(buildOption))
  }, [JSON.stringify(states)])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(({ value }) => value))
  }

  return isMulti ? (
    <MultiSearchSelect
      id={id}
      label={label}
      options={options}
      value={multiValue}
      onChange={handleMultiChange}
      required={required}
      clearable={clearable}
      className={className}
    />
  ) : (
    <SearchSelect
      id={id}
      label={label}
      options={options}
      value={value || ''}
      onChange={handleChange}
      required={required}
      clearable={clearable}
      className={className}
    />
  )
}

StateSelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  state: PropTypes.string,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
}

StateSelectFieldDEPRECATED.defaultProps = {
  id: 'state',
  state: '',
  required: false,
  clearable: false,
}

export default StateSelectFieldDEPRECATED
