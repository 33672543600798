import React from 'react'
import PropTypes from 'prop-types'
import { SectionLabel } from 'components'

const ChartContainer = ({ title, children }) => (
  <>
    {title && (
      <SectionLabel primary tight>
        {title}
      </SectionLabel>
    )}
    <div className="chart__container">{children}</div>
  </>
)

ChartContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
}

export default ChartContainer
