import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Grid, Section, DetailItem } from 'components'
import { Icon } from '@politechdev/blocks-design-system'
import { dateFormat } from 'utils/constants'

const ListDetails = ({ currentList }) => {
  const { t } = useTranslation()

  return (
    <Grid>
      <Section>
        <DetailItem label={t('Folder')}>
          <Icon.FolderOpen />
          {currentList.folder.name}
        </DetailItem>
        <DetailItem label={t('Created on')}>
          {moment(currentList.created_at).format(dateFormat)}
        </DetailItem>
        <DetailItem label={t('Last refresh')}>
          {moment(currentList.refreshed_at).format(dateFormat)}
        </DetailItem>
      </Section>
      <Section>
        <DetailItem label={t('Contacts')}>{currentList.meta.total}</DetailItem>
        <DetailItem label={t('Unique households')}>
          {currentList.meta.households}
        </DetailItem>
        <DetailItem label={t('Callable phone numbers')}>
          {currentList.meta.phones}
        </DetailItem>
      </Section>
    </Grid>
  )
}

export default ListDetails
