import { createContext, useContext, useRef } from 'react'
import mapboxgl from 'mapbox-gl'

const MapContext = createContext()

export const useMapRef = () => {
  const context = useContext(MapContext)
  if (context) return context

  const mapRef = useRef(null)

  return mapRef
}

export const useMap = () => {
  const mapRef = useContext(MapContext)
  if (mapRef === undefined) {
    throw new Error('useMap must be used within a MapProvider')
  }

  const getMap = () => mapRef.current?.getMap()

  const fitBounds = (coordinates = [], options = {}) => {
    const map = getMap()
    if (!map) return
    if (!coordinates.length) return

    const bounds = coordinates.reduce((bounds, coord) => {
      if (!coord.latitude || !coord.longitude) {
        return bounds
      }

      return bounds.extend([coord.longitude, coord.latitude])
    }, new mapboxgl.LngLatBounds())

    map.fitBounds(bounds, { padding: 24, ...options }, { originalEvent: true })
  }

  const getBounds = () => {
    const map = getMap()
    if (!map) return null

    const bounds = map.getBounds()

    return {
      ne: {
        lat: bounds._ne.lat.toFixed(7),
        lng: bounds._ne.lng.toFixed(7),
      },
      sw: {
        lat: bounds._sw.lat.toFixed(7),
        lng: bounds._sw.lng.toFixed(7),
      },
    }
  }

  return {
    getMap,
    fitBounds,
    getBounds,
  }
}

export const MapProvider = ({ children }) => {
  const mapRef = useMapRef()

  return <MapContext.Provider value={mapRef}>{children}</MapContext.Provider>
}
