import React, { useContext } from 'react'
import {
  Icon,
  Button,
  Font,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { ManagementContext } from 'checkins/CheckinContext/ManagementContext'
import styles from './QuestionList.module.scss'
import ArchiveButton from '../ArchiveButton/ArchiveButton'

const QuestionList = () => {
  const { t } = useTranslation()

  const { newCheckinPreview, setQuestionsToOrderSwitch } =
    useContext(ManagementContext)

  const highestPosition = () => {
    const activeQuestionsArray = newCheckinPreview.questions
      .map(q => q.position)
      .filter(q => q)
    if (!activeQuestionsArray.length) return 0
    return Math.max(
      ...newCheckinPreview.questions.map(q => q.position).filter(q => q)
    )
  }

  const moveQuestionUp = question => {
    if (question.position === 1) return
    const questionToSwitch = newCheckinPreview.questions.find(
      q => q.position === question.position - 1
    )
    return setQuestionsToOrderSwitch({
      questionToMoveUp: question,
      questionToMoveDown: questionToSwitch,
    })
  }

  const moveQuestionDown = question => {
    if (question.position === highestPosition()) return
    const questionToSwitch = newCheckinPreview.questions.find(
      q => q.position === question.position + 1
    )
    return setQuestionsToOrderSwitch({
      questionToMoveUp: questionToSwitch,
      questionToMoveDown: question,
    })
  }

  return newCheckinPreview.questions
    .filter(q => !q.archived)
    .sort((a, b) => a.position - b.position)
    .map((question, id) => (
      <TextBlock>
        <div className={styles.wrapper}>
          <div className={styles['text-container']}>
            <Font.Copy variant="hint" element="p">
              {t('Question')} {id + 1}
            </Font.Copy>
            <Font.Copy variant="highlight">{t(question.text)}</Font.Copy>
          </div>
          <div className={styles['button-block']}>
            <div className={styles['button-block__positions']}>
              <Button.Secondary
                onClick={() => moveQuestionUp(question)}
                tooltipLabel={t('Move up')}
              >
                <Icon.ChevronUp alt={t('Move up')} />
              </Button.Secondary>
              <Button.Secondary
                onClick={() => moveQuestionDown(question)}
                tooltipLabel={t('Move down')}
              >
                <Icon.ChevronDown alt={t('Move down')} />
              </Button.Secondary>
            </div>
            <div className={styles['button-block__archive']}>
              <ArchiveButton id={question.id} position={question.position} />
            </div>
          </div>
        </div>
      </TextBlock>
    ))
}

export default QuestionList
