import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectField } from 'react-md'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button } from 'components'
import styles from './TimePickerDEPRECATED.module.scss'

const TimePickerDEPRECATED = ({
  id,
  label,
  initialTime,
  onChange,
  clearable,
  errors,
}) => {
  const { t } = useTranslation()

  const roundedInitialTime = useMemo(() => {
    if (!initialTime) return null

    return initialTime
      .minute(Math.ceil(initialTime.minute() / 15) * 15)
      .second(0)
  }, [initialTime])

  const [hour, setHour] = useState(roundedInitialTime?.format('h'))
  const [minute, setMinute] = useState(roundedInitialTime?.format('mm'))
  const [meridiem, setMeridiem] = useState(roundedInitialTime?.format('A'))

  useEffect(() => {
    if (hour && minute && meridiem) {
      const getHour = hour => {
        if (hour === 12 && meridiem === 'AM') {
          return 0
        }
        if (hour === 12 && meridiem === 'PM') {
          return 12
        }
        if (hour !== 12 && meridiem === 'PM') {
          return hour + 12
        }

        return hour
      }
      onChange(
        hour && minute && meridiem
          ? {
              hour: getHour(+hour),
              minute: +minute,
            }
          : null
      )
    }
  }, [hour, minute, meridiem])

  const clearTime = () => {
    setHour(null)
    setMinute(null)
    setMeridiem(null)
  }

  return (
    <div className={styles['container--deprecated']}>
      <div className={styles.input}>
        <SelectField
          id={`${id}-hour`}
          label={t(label)}
          onChange={setHour}
          menuItems={[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
          ]}
          value={hour || ''}
        />
        <SelectField
          id={`${id}-minute`}
          onChange={setMinute}
          menuItems={['00', '15', '30', '45']}
          value={minute || ''}
        />
        <SelectField
          id={`${id}-meridiem`}
          onChange={setMeridiem}
          menuItems={['AM', 'PM']}
          value={meridiem || ''}
        />
        {clearable && (hour || minute || meridiem) && (
          <Button
            icon
            secondary
            onClick={clearTime}
            className={styles.clearButton}
          >
            close
          </Button>
        )}
      </div>
      {errors && <div className={styles.error}>{errors}</div>}
    </div>
  )
}

TimePickerDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  initialTime: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
}

TimePickerDEPRECATED.defaultProps = {
  id: 'time',
  initialTime: moment(),
  clearable: false,
}

export default TimePickerDEPRECATED
