import { sortOptionsByLabel } from 'utils/select'

export const QC_OFFICE_OPTIONS = sortOptionsByLabel([
  {
    label: 'Brooklyn',
    value: 'Brooklyn',
  },
  {
    label: 'Long Beach',
    value: 'Long Beach',
  },
  {
    label: 'Chicago',
    value: 'Chicago',
  },
  {
    label: 'Houston',
    value: 'Houston',
  },
  {
    label: 'New Orleans',
    value: 'New Orleans',
  },
  {
    label: 'Charleston',
    value: 'Charleston',
  },
  {
    label: 'Full Time Staff',
    value: 'Full Time Staff',
  },
  {
    label: 'Remote',
    value: 'Remote',
  },
])
