import { Switch, Route } from 'react-router-dom'
import PeopleTable from '../PeopleTable/PeopleTable'
import PersonSingle from '../PersonSingle/PersonSingle'
import NewPersonForm from '../NewPersonForm/NewPersonForm'
import PersonEdit from '../PersonEdit/PersonEdit'

const PersonRoutes = props => {
  const { match } = props

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={PeopleTable} />
      <Route exact path={`${match.url}/new`} component={NewPersonForm} />
      <Route
        path={`${match.url}/:id`}
        exact
        render={routeProps => (
          <PersonSingle
            personId={routeProps.match.params.id}
            {...props}
            {...routeProps}
          />
        )}
      />
      <Route
        path={`${match.url}/:id/edit`}
        exact
        render={routeProps => (
          <PersonEdit
            personId={routeProps.match.params.id}
            {...props}
            {...routeProps}
          />
        )}
      />
    </Switch>
  )
}

export default PersonRoutes
