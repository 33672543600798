import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import {
  MultiSelectField,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchTeams } from 'requests/teams'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts'
import { buildParams, buildTeam, buildOption } from './utils'

const TeamSelectField = ({
  id,
  label,
  filters,
  onSelect,
  team,
  teams = [],
  isMulti,
  canSelectAll,
  excludedOptions = [],
  loading,
  clearable,
}) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'team',
    ability: 'view',
  })

  const [options, setOptions] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState(team?.id)
  const [multiValue, setMultiValue] = useState(teams.map(team => team.id))
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  const { makeRequest, isLoading } = useRequest(fetchTeams, {
    onSuccess: ({ teams: incomingTeams }) => {
      setOptions(
        incomingTeams.map(buildOption).concat([
          ...(canSelectAll
            ? [
                {
                  label: t(`All ${label?.toLowerCase() || 'teams'}`),
                  value: 'ALL',
                },
              ]
            : []
          ).filter(teamOption =>
            excludedOptions.every(
              optionValue => optionValue !== teamOption.value
            )
          ),
        ])
      )
    },
    onError: () => {
      setErrorMsg('failed to fetch teams')
    },
  })

  const getTeams = () => {
    const params = buildParams({
      query: debounced,
      filters,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getTeams()
  }, [debounced, JSON.stringify(filters)])

  useEffect(() => {
    setValue(team?.id)
  }, [team])

  useEffect(() => {
    setMultiValue(teams.map(team => team.id))
  }, [JSON.stringify(teams)])

  const singleOptions = buildSingleOptions(options, team, buildOption)

  const multiOptions = buildMultiOptions(options, teams, buildOption)

  const handleChange = selection => {
    const selectedOption = singleOptions.find(
      ({ value }) => value === selection
    )
    onSelect && onSelect(selectedOption ? buildTeam(selectedOption) : undefined)
  }

  const handleMultiChange = selections => {
    onSelect &&
      onSelect(
        multiOptions
          .filter(({ value }) => selections.includes(value))
          .map(buildTeam)
      )
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSelectField
          id={id}
          label={label || t('Team')}
          options={multiOptions}
          onSelect={handleMultiChange}
          onInputChange={setQuery}
          values={multiValue}
          loading={isLoading || loading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          clearable={clearable}
        />
      ) : (
        <SelectField
          id={id}
          label={label || t('Team')}
          options={singleOptions}
          onSelect={handleChange}
          onInputChange={setQuery}
          value={value}
          loading={isLoading || loading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          clearable={clearable}
        />
      )}
    </>
  )
}

TeamSelectField.propTypes = {
  id: PropTypes.string,
  team: PropTypes.object,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
}

TeamSelectField.defaultProps = {
  id: 'teams',
  filters: [],
  team: null,
}

export default TeamSelectField
