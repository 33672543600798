import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { GoalListContext } from './GoalListContext'
import styles from './GoalList.module.scss'

const GoalListButton = ({ turfId, goal }) => {
  const { t } = useTranslation()
  const { turfMap, setSelectedTurf } = useContext(GoalListContext)

  if (goal && !goal.isAggregate) {
    return (
      <Link
        to={`/field-management/goals/${goal.id}`}
        className={styles.list__item__button}
      >
        <Button>{t('View goal')}</Button>
      </Link>
    )
  }

  return (
    <Button.Secondary
      className={styles.list__item__button}
      onClick={() => setSelectedTurf(turfMap[turfId])}
    >
      <span>{t('Expand')}</span> <Icon.ChevronDown />
    </Button.Secondary>
  )
}

export default GoalListButton
