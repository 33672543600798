import { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonBlock, Button } from '@politechdev/blocks-design-system'
import { Section, DetailItem } from 'components'
import { fetchGoals } from 'requests/fieldManagement'
import { useRequest } from 'hooks/useRequest'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { formatNumberAsString } from 'utils/formatting'
import { OtherGoalsListContext } from './OtherGoalsListContext'

const getTargetCount = targets =>
  formatNumberAsString(targets.reduce((a, c) => a + c.forms_to_collect || 0, 0))

const OtherGoalsList = ({ turfId, goalId }) => {
  const { t } = useTranslation()
  const {
    otherGoals,
    setOtherGoals,
    activePage,
    setActivePage,
    totalAmount,
    setTotalAmount,
  } = useContext(OtherGoalsListContext)

  const pageSize = 10

  const fetchGoalsHandlers = {
    onSuccess: response => {
      if (totalAmount === 0) {
        setOtherGoals(
          response['field_management/goals'].filter(goal => goal.id !== goalId)
        )
        setTotalAmount(response.meta.total_count)
        return
      }
      setOtherGoals(oldGoals =>
        oldGoals.concat(
          response['field_management/goals'].filter(goal => goal.id !== goalId)
        )
      )
    },
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makeFetchGoalsRequest } = useRequest(
    fetchGoals,
    fetchGoalsHandlers
  )

  useEffect(() => {
    setActivePage(1)
  }, [turfId])

  useEffect(() => {
    if (turfId) {
      makeFetchGoalsRequest({
        fields: ['id', 'start_date', 'end_date', 'turf_id', 'targets'],
        per: pageSize,
        current_page: activePage,
        turf_id: turfId,
      })
    }
  }, [turfId, activePage])

  if (!turfId || !otherGoals.length) return null

  return (
    <Section label={t('Other goals in this turf')} secondary>
      {otherGoals.map(goal => (
        <DetailItem
          key={goal.id}
          label={`${moment(goal.start_date).format(dateFormat)} - ${moment(
            goal.end_date
          ).format(dateFormat)}`}
        >
          {getTargetCount(goal.targets)} registrations
        </DetailItem>
      ))}
      {activePage * pageSize < totalAmount && (
        <ButtonBlock>
          <Button onClick={() => setActivePage(oldPage => oldPage + 1)}>
            Load more
          </Button>
        </ButtonBlock>
      )}
    </Section>
  )
}

export default OtherGoalsList
