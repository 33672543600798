import { buildDefaultFilters } from 'utils/reporting'
import {
  baseDistrictColumns,
  baseDistrictFilters,
  districtDataDictionaryUrl,
} from '../columns/baseDistrictColumns'

export default {
  id: 'district_by_turf',
  name: 'Turf',
  description:
    'This report provides information on the legislative districts of registrants. Users can use the filter dropdown to toggle between district types. Districts are determined from the addresses collected on registration forms. This report groups data to the children of the turf selected.',
  dataDictionaryUrl: districtDataDictionaryUrl,
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    ...baseDistrictColumns,
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, baseDistrictFilters)
  },
}
