import React from 'react'
import classNames from 'classnames/bind'
import { Font } from '@politechdev/blocks-design-system'
import styles from './SubheaderBlock.module.scss'

const cx = classNames.bind(styles)

const SubHeaderBlock = ({ title, className, children }) => (
  <div className={cx('header', className)}>
    <Font.Display>{title}</Font.Display>
    <div className={styles['side-content']}>{children}</div>
  </div>
)

export default SubHeaderBlock
