import {
  basePartisanScoreColumns,
  partisanScoreDataDictionaryUrl,
} from '../columns/basePartisanScoreColumns'

export default {
  id: 'partisan_score_by_turf',
  name: 'Turf',
  description:
    'This report provides information on the partisan score values of registrants. It groups data to the children of the turf selected. Partisan scores come from third party voter-file vendors. They are not created by Blocks.',
  dataDictionaryUrl: partisanScoreDataDictionaryUrl,
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    ...basePartisanScoreColumns,
  ],
}
