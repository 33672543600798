import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import { TextBlock, Button, Icon } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks/useRequest'
import { AutoTableProvider, useAutoTable, usePagination } from 'contexts/index'
import { createRowAction } from 'components/DataTable/utils'
import { useReactRouter } from 'hooks/router'
import { getToken } from 'utils/auth'
import { getApiUrl } from 'utils/req'
import { fetchDeliveryForms } from './utils'
import { useDeliveryState } from './DeliveryContext'
import reviewTableConfig from './reviewTableConfig'

const ReviewTable = () => {
  const { t } = useTranslation()
  const { currentDelivery } = useDeliveryState()
  const { history } = useReactRouter()
  const { renderTable } = useAutoTable()
  const { pageSize, page } = usePagination()

  const [forms, setForms] = useState([])
  const [totalForms, setTotalForms] = useState()

  const {
    makeRequest: fetchAttachedForms,
    isLoading: loading,
    hasErrors: error,
  } = useRequest(fetchDeliveryForms, {
    onSuccess: ({ voter_registration_scans: forms, meta: { total_count } }) => {
      setForms(forms)
      setTotalForms(total_count)
    },
  })

  useEffect(() => {
    fetchAttachedForms(currentDelivery.id)
  }, [currentDelivery.id])

  if (error) {
    return (
      <TextBlock>
        <CardError
          hide={false}
          message={t(
            'An internal error occurred while fetching registration forms for this delivery'
          )}
        />
      </TextBlock>
    )
  }

  const handleViewScan = rowData => {
    history.push(
      `/collect/voter_registration/shifts/${rowData.shift.id}/packet/scans/${rowData.id}?source=deliveries&sourceId=${currentDelivery.id}`
    )
  }

  const columns = [
    {
      actions: [createRowAction(t('View scan'), handleViewScan)].filter(
        Boolean
      ),
      listInline: true,
    },
    ...reviewTableConfig.columns,
  ]

  const paginate = forms => {
    const startIndex = (page - 1) * pageSize

    return forms.slice(startIndex, startIndex + pageSize)
  }

  return renderTable({
    data: paginate(forms),
    loading,
    totalRecords: totalForms,
    columns,
    headerActions: (
      <>
        <Button.Secondary
          aria-label={t('Export list of forms')}
          onClick={() => {
            window.open(
              getApiUrl(
                `/api/v1/deliveries/${
                  currentDelivery.id
                }/registration_forms.csv?jwt=${getToken()}`
              ),
              '_blank'
            )
          }}
        >
          <Icon.Download />
        </Button.Secondary>
        <Button.Secondary
          aria-label={t('Print list of forms')}
          onClick={() => {
            window.open(
              getApiUrl(
                `/api/v1/deliveries/${
                  currentDelivery.id
                }/registration_forms.pdf?jwt=${getToken()}`
              ),
              '_blank'
            )
          }}
        >
          <Icon.Print />
        </Button.Secondary>
      </>
    ),
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={reviewTableConfig.label}
    defaultSortColumn={reviewTableConfig.defaultSortColumn}
    defaultSortOrder={reviewTableConfig.defaultSortOrder}
    enableSort
    isFrontend
    enableQueryParams
    enablePagination
  >
    <ReviewTable />
  </AutoTableProvider>
)
