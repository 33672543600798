import { DEFAULT_LIST_FOLDER_NAME } from 'constants/lists'

export const formatListFolders = (folders, list) => {
  const listFolder = folders.find(folder => folder.id === list.folder.id)
  if (listFolder) {
    listFolder.lists.push({
      id: list.id,
      name: list.name,
      meta: list.meta,
    })
  } else {
    folders.push({
      id: list.folder.id,
      name: list.folder.name,
      user_id: list.folder.user_id,
      sharees: list.folder.sharees,
      lists: [
        {
          id: list.id,
          name: list.name,
          meta: list.meta,
        },
      ],
    })
  }
  return folders
}

export const sortListFolders = (a, b) => {
  if (a.name === DEFAULT_LIST_FOLDER_NAME) return -1
  if (b.name === DEFAULT_LIST_FOLDER_NAME) return 1
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}
