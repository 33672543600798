import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { DetailItem } from 'components'
import { Button, Modal, ButtonBlock } from '@politechdev/blocks-design-system'
import { dateTimeFormat } from 'utils/constants'
import { useModal } from '../ModalProvider/ModalProvider'
import { MODAL_TYPES } from '../constants'
import { formatBytes } from '../utils'

const DocumentDetailModal = () => {
  const { t } = useTranslation()
  const [{ isOpen, closeModal }, { document }] = useModal(
    MODAL_TYPES.DETAIL_DOCUMENT
  )

  if (!document) return null

  return (
    <Modal
      id={MODAL_TYPES.DETAIL_DOCUMENT}
      title={t(`Document info`)}
      isOpen={isOpen}
    >
      <Modal.Body>
        <div>
          <DetailItem label={t('File name')}>{document.name}</DetailItem>
          <DetailItem label={t('File size')}>
            {formatBytes(document.file?.metadata?.size)}
          </DetailItem>
          <DetailItem label={t('Created at')}>
            {moment(document.created_at).format(dateTimeFormat)}
          </DetailItem>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Accent onClick={closeModal}>{t('Close')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default DocumentDetailModal
