import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Modal,
  ButtonBlock,
  ContentBlock,
  Button,
} from '@politechdev/blocks-design-system'
import { useAutoTable } from 'contexts'
import { useRequest } from 'hooks/useRequest'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { formatErrorMessage } from 'utils/formatting'
import { putPacket } from 'requests/tableQcManager'
import { getDateTimeXDaysFromNow } from 'utils/dateTime'
import PacketList from './PacketList'

const ReprioritizePacketsModal = ({ refreshAssignedPackets = () => {} }) => {
  const { t } = useTranslation()
  const { refreshPage } = useAutoTable()

  const [packetSearch, setPacketSearch] = useState('')

  const {
    isReprioritizationModalOpen: isOpen,
    setIsReprioritizationModalOpen,
    setPacketsBeingReprioritized,
    packetsBeingReprioritized,
  } = usePacketAssignment()

  useEffect(() => {
    setPacketSearch('')
  }, [isOpen])

  const { makeRequest, isLoading, errors } = useRequest(
    possiblyUpdatedPackets => {
      const requests = possiblyUpdatedPackets
        .map(packet => {
          if (!packet.reprioritization) return false

          const days = +packet.reprioritization

          return putPacket(packet.id, {
            qc_deadline: getDateTimeXDaysFromNow(days),
          })
        })
        .filter(Boolean)

      return Promise.all(requests)
    },
    {
      onSuccess: async () => {
        setPacketsBeingReprioritized([])
        setIsReprioritizationModalOpen(false)
        refreshPage()
        refreshAssignedPackets()
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  const title =
    packetsBeingReprioritized.length > 1
      ? 'Reprioritize Packets'
      : 'Reprioritize Packet'

  const filteredPackets = packetsBeingReprioritized.filter(packet =>
    packet.original_filename.toLowerCase().includes(packetSearch)
  )

  return (
    <Modal
      id="reprioritize-packets-modal"
      className="quality-control__packet-prioritization__modal"
      title={title}
      onHide={() => {
        setIsReprioritizationModalOpen(false)
      }}
      isOpen={isOpen}
      showLoading={isLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError hide={!errorMsg} message={errorMsg} />
          <PacketList
            packets={filteredPackets}
            packetSearch={packetSearch}
            setPacketSearch={setPacketSearch}
          />
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button
            className="table-filters__modal__button"
            onClick={() => {
              setIsReprioritizationModalOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
          <Button.Accent
            className="table-filters__modal__button"
            onClick={() => makeRequest(packetsBeingReprioritized)}
          >
            {t('Save')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ReprioritizePacketsModal
