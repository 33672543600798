import { useEffect, useState } from 'react'
import moment from 'moment'
import { useAutoTable, useFilters } from 'contexts'
import { useRequest } from 'hooks/useRequest'
import { areAllFiltersValid } from 'components/DataTable/TableFilters/utils'
import { fetchPhoneBankReport } from 'requests/phoneBankReports'
import { useTranslation } from 'react-i18next'
import { tableConfig } from '../configs'

const PhoneBankReportsTable = ({ dateRange, turfId, reportType }) => {
  const {
    isLoading: filterLoading,
    errorMsg: filterErrorMsg,
    filterRules,
  } = useFilters()
  const { renderTable } = useAutoTable()
  const { t } = useTranslation()
  const [reportData, setReportData] = useState([])

  const { columns } = tableConfig[reportType]

  const { isLoading, hasErrors, makeRequest } = useRequest(
    fetchPhoneBankReport,
    {
      onSuccess: res =>
        setReportData(
          res.map((item, index) => ({
            ...item,
            id: index,
          }))
        ),
    }
  )

  const requestReportData = () =>
    makeRequest(reportType, {
      startdate: moment(dateRange.start).format('YYYYMMDD'),
      enddate: moment(dateRange.end).format('YYYYMMDD'),
      turf_id: turfId,
    })

  useEffect(() => {
    requestReportData()
  }, [dateRange, turfId, reportType])

  if (!areAllFiltersValid(filterRules, columns)) {
    return null
  }

  return renderTable({
    data: reportData,
    loading: isLoading || filterLoading,
    error: hasErrors ? t('Something went wrong') : false || filterErrorMsg,
    columns,
    fetchAction: requestReportData,
  })
}

export default PhoneBankReportsTable
