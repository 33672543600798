import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer } from 'components'
import { PageHeader, Sheet } from '@politechdev/blocks-design-system'
import UserAvatar from '../UserAvatar/UserAvatar'
import UserAccountForm from '../UserAccountForm/UserAccountForm'
import UserSecurityDetails from '../UserSecurityDetails/UserSecurityDetails'

const UserSettings = () => {
  const { t } = useTranslation()

  return (
    <View>
      <PageHeader title={t('User settings')} />
      <ViewContainer>
        <Sheet>
          <UserAvatar />
          <UserAccountForm />
          <UserSecurityDetails />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default UserSettings
