import moment from 'moment'

export const buildForm = ({
  phoneBank,
  defaultTurfId,
  defaultTimeZone,
  list_id,
  callType,
}) => {
  if (!phoneBank) {
    return {
      openHour: 9,
      openMin: 0,
      closeHour: 17,
      closeMin: 0,
      turf_id: defaultTurfId,
      time_zone: defaultTimeZone,
      list_id: +list_id,
      call_type: callType,
    }
  }
  const format = 'HH:mm:ss'
  const startTime = moment(phoneBank.daily_start_time, format)
  const endTime = moment(phoneBank.daily_end_time, format)

  const isStartTimeValid = startTime.isValid()
  const isEndTimeValid = endTime.isValid()

  return {
    ...phoneBank,
    openHour: isStartTimeValid ? startTime.hour() : 9,
    openMin: isStartTimeValid ? startTime.minute() : 0,
    closeHour: isEndTimeValid ? endTime.hour() : 17,
    closeMin: isEndTimeValid ? endTime.minute() : 0,
  }
}

export const buildRequest = formData => ({
  ...formData,
  daily_start_time: moment()
    .hour(formData.openHour)
    .minute(formData.openMinute)
    .format('HH:mm:ss'),
  daily_end_time: moment()
    .hour(formData.closeHour)
    .minute(formData.closeMinute)
    .format('HH:mm:ss'),
})
