import { useState } from 'react'
import { useTurfs } from 'contexts'
import FlatTurfSelectField from './FlatTurfSelectField'
import ExpandedTurfSelectField from './ExpandedTurfSelectField'

const TurfSelectField = ({
  value: controlledValue = null,
  onSelect,
  turfs: customTurfOptions,
  label,
  disabled,
  showArchived,
  disableArchived,
  required,
  isExpanded,
  clearable,
  onClear,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const { preparedTurfs, turfRefreshIsLoading } = useTurfs()

  const sortByInstanceOfSearchTerm = (a, b) =>
    a.label.toLowerCase().indexOf(searchTerm?.toLowerCase()) -
    b.label.toLowerCase().indexOf(searchTerm?.toLowerCase())

  return isExpanded ? (
    <ExpandedTurfSelectField
      label={label}
      value={controlledValue}
      onSelect={onSelect}
      turfs={
        customTurfOptions ||
        preparedTurfs.filter(turf => showArchived || !turf.archived)
      }
      disabled={disabled}
      disableArchived={disableArchived}
      isLoading={turfRefreshIsLoading}
      required={required}
    />
  ) : (
    <FlatTurfSelectField
      label={label}
      value={controlledValue}
      onSelect={onSelect}
      onInputChange={setSearchTerm}
      turfs={(
        customTurfOptions ||
        preparedTurfs.filter(turf => showArchived || !turf.archived)
      ).toSorted(sortByInstanceOfSearchTerm)}
      disabled={disabled}
      disableArchived={disableArchived}
      isLoading={turfRefreshIsLoading}
      required={required}
      clearable={clearable}
      onClear={onClear}
    />
  )
}

export default TurfSelectField
