import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@politechdev/blocks-design-system'
import styles from '../ScriptItem.module.scss'

const ActionsSection = ({ loading, removeSelf }) => {
  const { t } = useTranslation()
  return (
    <Button.Secondary
      className={styles.button}
      onClick={removeSelf}
      disabled={loading}
      aria-label={t('Delete')}
    >
      <Icon.Times alt={t('Delete')} />
    </Button.Secondary>
  )
}

export default ActionsSection
