import {
  PersonNameCell,
  HostNameCell,
  MeetingLocationCell,
  MeetingDateCell,
  MeetingStatusCell,
  MeetingNotesCell,
  MeetingTypeCell,
  PersonFirstNameCell,
  PersonLastNameCell,
} from './MeetingTableCells'

import {
  PersonNameCsvCell,
  hostNameCsvCell,
  meetingLocationCsvCell,
  meetingDateCsvCell,
  meetingStatusCsvCell,
  PersonFirstNameCsvCell,
  PersonLastNameCsvCell,
} from './meetingCsvCells'

const constructDateFilter = rule => {
  let { param } = rule

  if (rule.operator !== 'more_than' && rule.operator !== 'less_than') {
    param += ' 00:00:00'
  }

  return { ...rule, param }
}

export default {
  label: 'meetings',
  defaultSortOrder: 'desc',
  defaultSortColumn: 'start_time',
  columns: [
    {
      dataKey: 'person_full_name',
      title: 'Person name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: PersonNameCell,
      csvCell: PersonNameCsvCell,
      filterConstructor: rule => ({
        ...rule,
        column: 'person_full_name',
      }),
    },
    {
      dataKey: 'person_first_name',
      title: 'First name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PersonFirstNameCell,
      csvCell: PersonFirstNameCsvCell,
    },
    {
      dataKey: 'person_last_name',
      title: 'Last name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PersonLastNameCell,
      csvCell: PersonLastNameCsvCell,
    },
    {
      dataKey: 'user_full_name',
      title: 'Host',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: HostNameCell,
      csvCell: hostNameCsvCell,
    },
    {
      dataKey: 'type',
      title: 'Type',
      type: 'string',
      resizable: true,
      bodyCell: MeetingTypeCell,
    },
    {
      dataKey: 'location_name',
      title: 'Location',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: MeetingLocationCell,
      csvCell: meetingLocationCsvCell,
    },
    {
      dataKey: 'start_time',
      title: 'Date',
      type: 'date',
      autoResize: true,
      resizable: true,
      bodyCell: MeetingDateCell,
      csvCell: meetingDateCsvCell,
      filterConstructor: constructDateFilter,
    },
    {
      dataKey: 'status',
      title: 'Status',
      type: 'enum',
      options: [
        { label: 'Open', value: 'Open' },
        { label: 'Completed', value: 'Completed' },
        { label: 'Cancelled', value: 'Cancelled' },
      ],
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: MeetingStatusCell,
      csvCell: meetingStatusCsvCell,
      filterConstructor: rule => ({
        column: 'by_status',
        param: rule.param,
      }),
    },
    {
      dataKey: 'notes',
      title: 'Notes',
      type: 'string',
      resizable: true,
      bodyCell: MeetingNotesCell,
    },
  ],
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: { hiddenColumns: ['person_first_name', 'person_last_name'] },
      },
    ]
  },
}
