import React from 'react'
import { useTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Font, ContentBlock } from '@politechdev/blocks-design-system'
import { ListFilterProvider } from '../ListFilterProvider/ListFilterProvider'
import FilterDropZone from '../FilterDropZone/FilterDropZone'
import FilterSelectionZone from '../FilterSelectionZone/FilterSelectionZone'
import styles from './ListFilterGroup.module.scss'

const ListFilterGroup = ({ group }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.group}>
      <ContentBlock className={styles.header}>
        <Font.Display variant="small">{t(group.title)}</Font.Display>
        <Font.Copy variant="secondary">{t(group.subtitle)}</Font.Copy>
      </ContentBlock>
      <div className={styles.content}>
        <FilterDropZone />
        <FilterSelectionZone />
      </div>
    </div>
  )
}

export default ({ group, initialFilters }) => (
  <DndProvider backend={HTML5Backend}>
    <ListFilterProvider group={group} initialFilters={initialFilters}>
      <ListFilterGroup group={group} />
    </ListFilterProvider>
  </DndProvider>
)
