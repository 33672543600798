import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { useContext } from 'react'
import RolesSidebarItem from '../RolesSidebarItem/RolesSidebarItem'
import styles from './RolesSidebar.module.scss'
import { useRoleModalActions } from '../RoleModalStateProvider/RoleModalStateProvider'
import { RoleContext } from '../RoleContext/RoleContext'

const RolesSidebar = () => {
  const { t } = useTranslation()
  const { openCreateModal } = useRoleModalActions()
  const { topLevelRoleIds } = useContext(RoleContext)

  return (
    <div>
      {topLevelRoleIds.map(id => (
        <div key={id} className={styles.container}>
          <RolesSidebarItem roleId={id} />
        </div>
      ))}
      <div className={styles.button}>
        <Button aria-label="Add" onClick={openCreateModal}>
          <Icon.Plus />
        </Button>
        <div className={styles.label}>{t('New role')}</div>
      </div>
    </div>
  )
}

export default RolesSidebar
