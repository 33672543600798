import moment, { duration } from 'moment'
import { dateFormat } from 'utils/constants'

export const createProjectionWeeks = ({
  startDate,
  endDate,
  dailyCanvassers = 6,
  daysCanvassingPerWeek = 6,
  averageFieldHours = 6.5,
  formsPerHour = 2,
}) => {
  const newWeekCount = Math.ceil(
    duration(moment.utc(endDate).diff(moment.utc(startDate)) + 1).asWeeks()
  )
  const currentWeeklyProjectedData = []
  let currentStartDate = moment.utc(startDate)
  let currentEndDate = moment.utc(startDate).add(6, 'd')
  for (let i = 0; i < newWeekCount; i += 1) {
    // the end is whatever comes first, the overall end date or the current end date
    currentEndDate =
      moment.utc(endDate).diff(currentEndDate) > 0
        ? currentEndDate
        : moment.utc(endDate)
    const currentWeek = {
      startDate: currentStartDate.toDate(),
      endDate: currentEndDate.toDate(),
      dailyCanvassers,
      daysCanvassingPerWeek,
      averageFieldHours,
      formsPerHour,
    }
    currentWeeklyProjectedData.push(currentWeek)
    currentStartDate = currentStartDate.add(1, 'w')
    currentEndDate = currentEndDate.add(1, 'w')
  }
  return currentWeeklyProjectedData
}

export const bulkUpdateWeeks = ({
  dailyCanvassers,
  applyDailyCanvassers,
  daysCanvassingPerWeek,
  applyDaysCanvassingPerWeek,
  averageFieldHours,
  applyAverageFieldHours,
  formsPerHour,
  applyFormsPerHour,
  startWeekIndex,
  endWeekIndex,
  weeklyProjectedData,
  setWeeklyProjectedData,
}) => {
  const updatedWeeklyData = weeklyProjectedData.map((weekProjection, i) => {
    if (i >= startWeekIndex && i <= endWeekIndex) {
      return {
        ...weekProjection,
        dailyCanvassers: applyDailyCanvassers
          ? dailyCanvassers
          : weekProjection.dailyCanvassers,
        daysCanvassingPerWeek: applyDaysCanvassingPerWeek
          ? daysCanvassingPerWeek
          : weekProjection.daysCanvassingPerWeek,
        averageFieldHours: applyAverageFieldHours
          ? averageFieldHours
          : weekProjection.averageFieldHours,
        formsPerHour: applyFormsPerHour
          ? formsPerHour
          : weekProjection.formsPerHour,
      }
    }

    return weekProjection
  })
  setWeeklyProjectedData(updatedWeeklyData)
}

export const calcProjectedTotal = element => {
  const weeks =
    (moment.utc(element.endDate).diff(moment.utc(element.startDate), 'days') +
      1) /
    7
  return (
    weeks *
    element.dailyCanvassers *
    element.daysCanvassingPerWeek *
    element.averageFieldHours *
    element.formsPerHour
  )
}

const calculateElapsedRate = (startDate, endDate) =>
  moment.utc(endDate).diff(moment.min(moment.utc(), endDate)) /
  moment.utc(endDate).diff(moment.utc(startDate))

export const deriveStatus = (
  startDate,
  endDate,
  totalProjected,
  totalCollected
) => {
  const elapsedRate = calculateElapsedRate(startDate, endDate)
  const collectedRate = totalCollected / totalProjected

  if (elapsedRate > collectedRate) {
    return 'Running behind'
  }
  if (collectedRate > 1.2 * elapsedRate) {
    return 'Ahead'
  }
  return 'On track'
}

export const deriveTargetFormsTotal = (
  numDays,
  numCanvassers,
  numHours,
  formsPerHour
) => Math.round(numDays * numCanvassers * numHours * formsPerHour)

const partialWeeks = (targets, filterStartDate, filterEndDate) =>
  targets
    .filter(({ start_date, end_date }) => {
      const didStartAfterFilterStartDate = filterStartDate
        ? moment
            .utc(start_date)
            .isSameOrAfter(moment.utc(filterStartDate), 'day')
        : true

      const didStartBeforeFilterStartDate = filterStartDate
        ? moment.utc(start_date).isBefore(moment.utc(filterStartDate), 'days')
        : true

      const didStartAfterFilterEndDate = filterEndDate
        ? moment.utc(start_date).isSameOrAfter(moment.utc(filterEndDate), 'day')
        : true

      const didEndBeforeFilterEndDate = filterEndDate
        ? moment.utc(end_date).isSameOrBefore(moment.utc(filterEndDate), 'day')
        : true

      const didEndAfterFilterEndDate = filterEndDate
        ? moment.utc(end_date).isAfter(moment.utc(filterEndDate), 'day')
        : true

      const didEndBeforeFilterStartDate = filterStartDate
        ? moment
            .utc(end_date)
            .isSameOrBefore(moment.utc(filterStartDate), 'day')
        : true

      const startsWithinFilter =
        didStartAfterFilterStartDate &&
        !didEndBeforeFilterEndDate &&
        !didStartAfterFilterEndDate

      const endsWithinFilter =
        didEndBeforeFilterEndDate &&
        !didStartAfterFilterStartDate &&
        !didEndBeforeFilterStartDate

      const filterWithinGoal =
        didStartBeforeFilterStartDate && didEndAfterFilterEndDate

      const result = startsWithinFilter || endsWithinFilter || filterWithinGoal

      return result
    })
    .map(target => {
      const { start_date, end_date, forms_to_collect } = target
      if (
        moment(start_date).isSameOrBefore(moment(filterStartDate)) &&
        moment(end_date).isSameOrAfter(moment(filterEndDate))
      ) {
        const filterDays =
          moment(filterEndDate).diff(moment(filterStartDate), 'days') + 1
        const goalDays = moment(end_date).diff(moment(start_date), 'days') + 1
        const prorateRatio = filterDays / goalDays
        return {
          ...target,
          forms_to_collect: Math.ceil(forms_to_collect * prorateRatio),
        }
      }
      if (moment(start_date).isBefore(moment(filterStartDate))) {
        const filterDays =
          moment(end_date).diff(moment(filterStartDate), 'days') + 1
        const goalDays = moment(end_date).diff(moment(start_date), 'days') + 1
        const prorateRatio = filterDays / goalDays
        return {
          ...target,
          forms_to_collect: Math.ceil(forms_to_collect * prorateRatio),
        }
      }
      if (moment(end_date).isAfter(moment(filterEndDate))) {
        const filterDays =
          moment(filterEndDate).diff(moment(start_date), 'days') + 1
        const goalDays = moment(end_date).diff(moment(start_date), 'days') + 1
        const prorateRatio = filterDays / goalDays
        return {
          ...target,
          forms_to_collect: Math.ceil(forms_to_collect * prorateRatio),
        }
      }
      return target
    })

export const filterTargetsByDateRange = (
  targets,
  filterStartDate,
  filterEndDate
) =>
  targets
    .filter(({ start_date, end_date }) => {
      const didStartAfterFilterStartDate = filterStartDate
        ? moment
            .utc(start_date)
            .isSameOrAfter(moment.utc(filterStartDate), 'day')
        : true

      const didEndBeforeFilterEndDate = filterEndDate
        ? moment.utc(end_date).isSameOrBefore(moment.utc(filterEndDate), 'day')
        : true

      const result = didStartAfterFilterStartDate && didEndBeforeFilterEndDate

      return result
    })
    .concat(partialWeeks(targets, filterStartDate, filterEndDate))
    .sort((a, b) => {
      if (moment(a.start_date).unix() < moment(b.start_date).unix()) return -1
      if (moment(a.start_date).unix() > moment(b.start_date).unix()) return 1
      return 0
    })

export const formatRowsForChart = rows =>
  rows
    .sort((a, b) => {
      if (moment(a.date).unix() < moment(b.date).unix()) return -1
      if (moment(a.date).unix() > moment(b.date).unix()) return 1
      return 0
    })
    .reduce(
      (rows, { date, total_collected }, index) =>
        rows.concat([
          {
            date: moment(date).unix(),
            collected:
              index === 0
                ? total_collected
                : total_collected + rows[index - 1].collected,
          },
        ]),
      []
    )

export const getTotalTarget = (goals, filterStartDate, filterEndDate) => {
  const targets = goals.flatMap(({ targets }) => targets)
  const filteredTargets = filterTargetsByDateRange(
    targets,
    filterStartDate,
    filterEndDate
  )

  let total = 0
  filteredTargets.forEach(({ forms_to_collect }) => {
    total += forms_to_collect
  })

  return total
}

export const getTotalCollected = (goals, filterStartDate, filterEndDate) => {
  const targets = goals.flatMap(({ targets }) => targets)
  const filteredTargets = filterTargetsByDateRange(
    targets,
    filterStartDate,
    filterEndDate
  )

  let total = 0
  filteredTargets.forEach(({ forms_collected }) => {
    total += forms_collected
  })

  return total
}

export const tickFormatter = value => moment.unix(value).format(dateFormat)

export const buildGoalsParams = (
  startDate,
  endDate,
  tag,
  turfId = undefined
) => {
  const rules = [
    startDate
      ? { column: 'end_date', operator: 'after', param: startDate }
      : null,
    endDate
      ? { column: 'start_date', operator: 'before', param: endDate }
      : null,
    tag ? { column: 'labels', operator: 'any_in', param: [tag] } : null,
  ].filter(f => f)

  return {
    fields: ['id', 'start_date', 'end_date', 'labels', 'turf_id', 'targets'],
    per: Number.MAX_SAFE_INTEGER,
    filters: {
      rules,
    },
    turf_id: turfId,
  }
}

export const buildProjectionsChart = ({
  weeklyProjectedData,
  weeklyActualData,
  setFormattedChartData,
  totalStartDate,
}) => {
  if (weeklyProjectedData.length > 0) {
    if (weeklyActualData.length === 0) {
      const formattedProjected = []

      weeklyProjectedData.forEach((element, index) =>
        formattedProjected.push({
          date: moment(element.endDate).format('X'),
          projected:
            Math.floor(calcProjectedTotal(element)) +
            weeklyProjectedData
              .slice(0, index)
              .reduce(
                (acc, datapoint) =>
                  acc + Math.floor(calcProjectedTotal(datapoint)),
                0
              ),
        })
      )
      setFormattedChartData(
        [
          {
            date: moment(totalStartDate).format('X'),
            projected: 0,
          },
        ].concat(formattedProjected)
      )
    } else {
      const formattedActual = []

      weeklyActualData.forEach((element, index) =>
        formattedActual.push({
          date: moment(element.endDate).format('X'),
          collected:
            element.formsCollected +
            weeklyActualData
              .slice(0, index)
              .reduce((acc, datapoint) => acc + datapoint.formsCollected, 0),
        })
      )

      const formattedProjected = []

      weeklyProjectedData.forEach((element, index) => {
        if (index <= weeklyActualData.length) {
          formattedProjected.push({
            date: moment(element.endDate).format('X'),
            projected:
              Math.floor(calcProjectedTotal(element)) +
              (formattedActual[index - 1]
                ? formattedActual[index - 1].collected
                : 0),
          })
        } else {
          formattedProjected.push({
            date: moment(element.endDate).format('X'),
            projected:
              Math.floor(calcProjectedTotal(element)) +
              (formattedProjected[index - 1]
                ? formattedProjected[index - 1].projected
                : 0),
          })
        }
      })

      const actualChartData = [
        {
          date: moment(totalStartDate).format('X'),
          collected: 0,
        },
      ]
        .concat(formattedActual.slice(0, weeklyActualData.length - 1))
        .concat([
          {
            date: formattedActual[weeklyActualData.length - 1].date,
            collected: formattedActual[weeklyActualData.length - 1].collected,
          },
        ])

      const actualAndProjectedChartData = [
        {
          date: moment(totalStartDate).format('X'),
          collected: 0,
        },
      ]
        .concat(formattedActual.slice(0, weeklyActualData.length - 1))
        .concat([
          {
            date: formattedActual[weeklyActualData.length - 1].date,
            collected: formattedActual[weeklyActualData.length - 1].collected,
            projected: formattedActual[weeklyActualData.length - 1].collected,
          },
        ])
        .concat(formattedProjected.slice(weeklyActualData.length))

      if (formattedProjected.length === formattedActual.length) {
        setFormattedChartData(actualChartData)
      } else {
        setFormattedChartData(actualAndProjectedChartData)
      }
    }
  }
}
