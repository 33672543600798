import translatedCellRenderer from 'tableCells/TranslatedCell'
import { summaryFunctions } from 'utils/reporting'

export default {
  counts_by_turf: {
    columns: [
      {
        dataKey: 'turf_name',
        title: 'Turf Name',
        type: 'string',
      },
      {
        dataKey: 'meetings_count',
        title: 'Meeting Count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'events_count',
        title: 'Events Count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'people_count',
        title: 'People Count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'teams_count',
        title: 'Teams Count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'organizations_count',
        title: 'Organizations Count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
        headerCell: translatedCellRenderer,
      },
    ],
  },
}
