import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ContentBlock,
  Icon,
  TextField,
  FieldBlock,
} from '@politechdev/blocks-design-system'

const NewQuestion = ({
  addingQuestion,
  setAddingQuestion,
  newQuestion,
  setNewQuestion,
  addQuestion,
}) => {
  const { t } = useTranslation()

  return addingQuestion ? (
    <FieldBlock variant="large">
      <TextField
        id="new-question"
        label={t('New question')}
        value={newQuestion}
        onChange={val => setNewQuestion(val)}
      />
      <Button
        disabled={!newQuestion}
        onClick={addQuestion}
        tooltipLabel={t('Save new question')}
      >
        <Icon.Save alt={t('Save new question')} />
      </Button>
      <Button.Secondary
        onClick={() => {
          setNewQuestion('')
          setAddingQuestion(false)
        }}
        tooltipLabel={t('Cancel')}
      >
        <Icon.Times alt={t('Cancel')} />
      </Button.Secondary>
    </FieldBlock>
  ) : (
    <ContentBlock>
      <Button.Secondary onClick={() => setAddingQuestion(true)}>
        <Icon.Plus alt={t('Add Question')} />
      </Button.Secondary>
    </ContentBlock>
  )
}

export default NewQuestion
