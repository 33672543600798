import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import React, { Component } from 'react'
import moment from 'moment'
import { FontIcon, IconSeparator, Button } from 'react-md'

require('flatpickr/dist/themes/light.css') // eslint-disable-line

class DatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: props.value,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ selectedDate: nextProps.value })
    }
  }

  clearDate = () => {
    this.setState({ selectedDate: '' })
    this.props.onChange([''])
  }

  render() {
    const {
      timeEnabled,
      className,
      label,
      required,
      disabled,
      options,
      ...props
    } = this.props
    const { selectedDate } = this.state
    const fieldClass = `date-picker ${className || ''} ${
      selectedDate ? 'date-picker--has-value' : ''
    }`

    const requiredMark = required ? ' *' : ''

    return (
      <div className={fieldClass}>
        <Flatpickr
          {...props}
          options={{
            wrap: true,
            ...options,
          }}
          required={!!required}
          data-enable-time={timeEnabled}
          data-date-format={timeEnabled ? 'M d, Y h:i K' : 'M d, Y'}
          value={selectedDate ? moment(selectedDate).toDate() : null}
          onChange={dates => {
            const selectedDate = dates[0]
            this.setState({ selectedDate })
            this.props.onChange([
              moment(selectedDate).format(
                timeEnabled ? moment.defaultFormatUtc : 'YYYY-MM-DD'
              ),
            ])
          }}
        >
          <label htmlFor="date-picker" className="date-picker__label">
            <IconSeparator label={label + requiredMark} iconBefore>
              <FontIcon data-toggle>event</FontIcon>
            </IconSeparator>
          </label>
          <input id="date-picker" disabled={disabled} type="text" data-input />
          {!disabled && (
            <Button className="clear-dates" icon onClick={this.clearDate}>
              clear
            </Button>
          )}
        </Flatpickr>
        <hr className="md-divider md-divider--text-field md-divider--expand-from-left" />
      </div>
    )
  }
}

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default DatePicker
