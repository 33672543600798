import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  SelectField,
  MultiSelectField,
} from '@politechdev/blocks-design-system'
import { US_STATES } from 'utils/constants'
import { buildOption } from './utils'

const StateSelectField = ({
  id,
  label = 'State',
  onSelect,
  state,
  states = [],
  isMulti,
  ...props
}) => {
  const options = Object.keys(US_STATES).map(buildOption)
  const [value, setValue] = useState(state)
  const [multiValue, setMultiValue] = useState(states)

  useEffect(() => {
    setValue(state)
  }, [state])

  useEffect(() => {
    setMultiValue(states)
  }, [JSON.stringify(states)])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections)
  }

  return isMulti ? (
    <MultiSelectField
      id={id}
      label={label}
      options={options}
      values={multiValue}
      onSelect={handleMultiChange}
      {...props}
    />
  ) : (
    <SelectField
      id={id}
      label={label}
      options={options}
      value={value || ''}
      onSelect={handleChange}
      {...props}
    />
  )
}

StateSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  state: PropTypes.string,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
}

StateSelectField.defaultProps = {
  id: 'state',
  state: '',
  required: false,
  clearable: false,
}

export default StateSelectField
