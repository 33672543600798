import { languageOptions } from 'constants/users'

export const isTrainable = role => role?.needs_training

export const buildForm = ({ password, ...user }) => ({
  ...user,
  training_status: user.training_status === 'in_progress',
  languages: user.spoken_language_locales
    .map(lang =>
      languageOptions.find(languageOption => languageOption.value === lang)
    )
    .filter(Boolean),
})

export const buildRequest = formData => ({
  user: {
    ...formData,
    role_id: formData.role?.id,
    extras: {
      ...(formData.extras || {}),
    },
    spoken_language_locales: (formData.languages || []).map(lang => lang.value),
    training_status: formData.training_status ? 'in_progress' : 'none',
  },
  welcomeEmail: !!formData.welcome_email,
})
