import { useTranslation } from 'react-i18next'

const TranslatedCell = ({ column }) => {
  const { t } = useTranslation()
  return <span>{t(column.title)}</span>
}

const translatedCellRenderer = props => <TranslatedCell {...props} />

export default translatedCellRenderer
