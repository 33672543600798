import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { fetchMeeting, putMeeting } from 'requests/events'
import {
  View,
  ViewContainer,
  PersonSelectField,
  LocationSelectField,
  TimePicker,
  CampaignSelectField,
} from 'components'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  SelectField,
  DateField,
  TextField,
  PageHeader,
  Sheet,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { LOCATION_TYPES } from 'constants/locations'
import CreatePartialPersonModal from 'components/CreatePartialPerson/CreatePartialPersonModal'
import { useReactRouter } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import { useCurrent } from 'contexts/index'
import { defaultTo } from 'lodash'
import styles from './MeetingEditForm.module.scss'

const MeetingEditForm = () => {
  const { match, history } = useReactRouter()
  const [form, setForm] = useState({
    turf: {
      activism_options_config: {
        languages: [],
      },
    },
  })
  const {
    currentUser: { time_zone: timezone },
    meetingTypeOptions,
  } = useCurrent()
  const [isCreateGuestOpen, setCreateGuestOpen] = useState(false)
  const [isCreateAdditionalGuestOpen, setCreateAdditionalGuestOpen] =
    useState(false)
  const [timeError, setTimeError] = useState(false)
  const [eventDay, setEventDay] = useState()
  const { t } = useTranslation()

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest, isLoading: loading } = useRequest(putMeeting, {
    onSuccess: () => {
      history.push(`/build/meetings/${form.id}`)
    },
  })

  useEffect(() => {
    const { start_time, end_time } = form
    setTimeError(false)
    if (moment(start_time).isAfter(moment(end_time))) {
      setTimeError(true)
    }
  }, [form])

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makeFetchRequest, isLoading: fetchLoading } = useRequest(
    fetchMeeting,
    {
      onSuccess: ({ meeting }) => {
        setForm(meeting)
        setEventDay(moment(meeting.start_time).format('YYYY-MM-DD'))
      },
    }
  )

  const setField = (val, field) => {
    setForm(prevForm => ({
      ...prevForm,
      [field]: val,
    }))
  }

  const handleSave = () => {
    const {
      start_time,
      end_time,
      person,
      additional_person,
      location,
      campaign,
      ...remainingForm
    } = form

    const formattedStartTime = moment
      .tz(`${eventDay} ${moment(start_time).format('HH:mm:ss')}`, timezone)
      .format()
    const formattedEndTime = moment
      .tz(`${eventDay} ${moment(end_time).format('HH:mm:ss')}`, timezone)
      .format()
    makeRequest(form.id, {
      ...remainingForm,
      person_id: person.id,
      additional_person_id: additional_person?.id || null,
      location_id: location?.id || null,
      campaign_id: campaign?.id || null,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
    })
  }

  const isFormValid =
    eventDay && form.start_time && form.end_time && form.person

  useEffect(() => {
    makeFetchRequest(match.params.id)
  }, [])

  const { languages } = defaultTo(form.turf.activism_options_config, {
    languages: [],
  })

  return (
    <View>
      <ViewContainer loading={loading || fetchLoading}>
        <Sheet>
          <PageHeader title={t('Edit meeting')} />
          <form className={styles['meeting-form']}>
            {meetingTypeOptions.length > 0 && (
              <FieldBlock>
                <SelectField
                  id="meeting-type"
                  label={t('Type')}
                  options={meetingTypeOptions}
                  value={form.type}
                  onSelect={val => setField(val, 'type')}
                />
              </FieldBlock>
            )}
            <FieldBlock>
              <DateField
                label={t('Meeting day')}
                value={eventDay && moment(eventDay).toISOString()}
                clearable
                onClear={() => setEventDay(null)}
                onChange={start => {
                  setEventDay(moment(start).format('YYYY-MM-DD'))
                }}
                required
              />
            </FieldBlock>
            {eventDay && (
              <FieldBlock>
                <TimePicker
                  label="Start Time"
                  id="event-start-time"
                  onChange={val => setField(moment(val), 'start_time')}
                  initialTime={
                    form.start_time ? moment(form.start_time) : moment()
                  }
                  errors={timeError && 'Start time must be before end time.'}
                />
                <TimePicker
                  label="End Time"
                  id="event-end-time"
                  onChange={val => setField(moment(val), 'end_time')}
                  initialTime={form.end_time ? moment(form.end_time) : moment()}
                />
              </FieldBlock>
            )}
            <FieldBlock className={styles.guest}>
              <div className={styles.select}>
                <PersonSelectField
                  id="guestSearch"
                  label={t('Guest')}
                  onSelect={person => setField(person, 'person')}
                  person={form.person}
                  excludedPersonIds={
                    form.additional_person ? [form.additional_person.id] : []
                  }
                  required
                  canCreatePerson
                  onSelectCreate={() => setCreateGuestOpen(true)}
                />
              </div>
              {isCreateGuestOpen ? (
                <CreatePartialPersonModal
                  isOpen={isCreateGuestOpen}
                  setIsOpen={setCreateGuestOpen}
                  onCreated={person => setField(person, 'person')}
                  languages={languages}
                />
              ) : null}
            </FieldBlock>
            <FieldBlock className={styles.guest}>
              <div className={styles.select}>
                <PersonSelectField
                  id="additionalGuestSearch"
                  label={t('Additional guest')}
                  onSelect={person => setField(person, 'additional_person')}
                  person={form.additional_person}
                  clearable
                  onClear={() => setField(null, 'additional_person')}
                  excludedPersonIds={form.person ? [form.person.id] : []}
                  canCreatePerson
                  onSelectCreate={() => setCreateAdditionalGuestOpen(true)}
                />
              </div>
              {isCreateAdditionalGuestOpen ? (
                <CreatePartialPersonModal
                  isOpen={isCreateAdditionalGuestOpen}
                  setIsOpen={setCreateAdditionalGuestOpen}
                  onCreated={person => setField(person, 'additional_person')}
                  languages={languages}
                />
              ) : null}
            </FieldBlock>
            <FieldBlock>
              <LocationSelectField
                id="location"
                label={t('Location')}
                onSelect={location => setField(location, 'location')}
                location={form.location}
                locationTypes={[LOCATION_TYPES.venue]}
                clearable
              />
            </FieldBlock>
            <FieldBlock>
              <CampaignSelectField
                id="campaign"
                label={t('Campaign')}
                onSelect={campaign => setField(campaign, 'campaign')}
                campaign={form.campaign || {}}
                clearable
              />
            </FieldBlock>
            <FieldBlock>
              <TextField
                label={t('Notes')}
                id="meetingNotes"
                value={form.notes}
                onChange={notes => setField(notes, 'notes')}
              />
            </FieldBlock>
            <ButtonBlock>
              <Button.Accent onClick={handleSave} disabled={!isFormValid}>
                {t('Save')}
              </Button.Accent>
              <Link to={`/build/meetings/${form.id}`}>
                <Button.Secondary>{t('Cancel')}</Button.Secondary>
              </Link>
            </ButtonBlock>
          </form>
          <ProgressBar show={loading} />
        </Sheet>
      </ViewContainer>
    </View>
  )
}
export default MeetingEditForm
