import { createContext, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ButtonBlock,
  ContentBlock,
  Button,
} from '@politechdev/blocks-design-system'
import FlagCommentsModal from 'qualityControlReports/modals/FlagCommentsModal/FlagCommentsModal'
import DuplicatePacketsModal from 'registrationReports/modals/DuplicatePacketsModal/DuplicatePacketsModal'

const MODAL_TYPES = {
  DEFAULT: 'REPORTS/MODAL/DEFAULT',
  FLAG_COMMENTS: 'REGISTRATION_REPORTS/MODAL/FLAG_COMMENTS',
  DUPLICATE_PACKETS: 'REGISTRATION_REPORTS/MODAL/DUPLICATE_FACKETS',
}

const MODALS = {
  [MODAL_TYPES.DEFAULT]: ({ isOpen, setIsOpen, title = '', body = '' }) => {
    const { t } = useTranslation()
    return (
      <Modal
        id="reports-table-cell-modal"
        title={t(title)}
        onHide={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <Modal.Body>
          <ContentBlock>{body}</ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button onClick={() => setIsOpen(false)}>{t('Close')}</Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    )
  },
  [MODAL_TYPES.FLAG_COMMENTS]: FlagCommentsModal,
  [MODAL_TYPES.DUPLICATE_PACKETS]: DuplicatePacketsModal,
}

const ReportModalContext = createContext()
export const ReportModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState({})
  const [modalType, setModalType] = useState(MODAL_TYPES.DEFAULT)

  const SelectedModal = MODALS[modalType]
  return (
    <ReportModalContext.Provider
      value={{ isOpen, setIsOpen, setContent, setModalType, MODAL_TYPES }}
    >
      <SelectedModal isOpen={isOpen} setIsOpen={setIsOpen} {...content} />
      {children}
    </ReportModalContext.Provider>
  )
}

export const useReportModalContext = () => {
  const context = useContext(ReportModalContext)
  if (context === undefined) {
    throw new Error(
      'useReportModalContext must be used within a ReportModalProvider'
    )
  }

  return context
}
