import { LoadBar } from 'components'
import { Font, TextBlock } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import {
  AGENT_STATUS,
  useAgentState,
} from 'powerDialer/AgentContext/AgentContext'
import styles from './CallPanelInfo.module.scss'
import { formatWaitTime } from '../../util'

const ReconnectionMessage = () => {
  const { t } = useTranslation()
  const { isOperatorDisconnected } = useAgentState()

  if (isOperatorDisconnected) {
    return (
      <TextBlock>
        <Font.Copy className={styles.reconnectionMessage}>
          {t('Attempting to reconnect...')}
        </Font.Copy>
      </TextBlock>
    )
  }
  return null
}

const AverageWaitTime = () => {
  const { t } = useTranslation()
  const { isOperatorDisconnected, averageWaitTime } = useAgentState()

  if (isOperatorDisconnected) {
    return null
  }
  return (
    <TextBlock>
      <div className={styles.waitTime}>
        <Font.Label>{t('Average wait time')}</Font.Label>
        <Font.Copy>{formatWaitTime(averageWaitTime)}</Font.Copy>
      </div>
    </TextBlock>
  )
}

const CallPanelInfo = () => {
  const { t } = useTranslation()
  const { status } = useAgentState()

  if (status === AGENT_STATUS.AWAY) {
    return (
      <ol className={styles.instructions}>
        <li>
          {t(
            'Read through the script on your own and familiarize yourself with it'
          )}
        </li>
        <li>{t('Mark yourself as ready to begin receiving calls')}</li>
      </ol>
    )
  }
  if (status === AGENT_STATUS.READY) {
    return (
      <>
        <ReconnectionMessage />
        <LoadBar show />
        <AverageWaitTime />
      </>
    )
  }

  return null
}

export default CallPanelInfo
