export const conjunctions = {
  inclusive: 'MATCH ANY',
  exclusive: 'MATCH ALL',
}

export const operators = {
  string: [
    {
      prefix: 'is exactly',
      value: 'is',
      inputProps: {
        type: 'text',
        placeholder: 'Text to match',
      },
    },
    {
      prefix: 'does not contain',
      value: 'containing',
      invert: true,
      inputProps: {
        type: 'text',
        placeholder: 'Text to match',
      },
    },
    {
      prefix: 'contains',
      value: 'containing',
      inputProps: {
        type: 'text',
        placeholder: 'Text to match',
      },
    },
  ],
  number: [
    {
      prefix: 'is exactly',
      value: 'is',
      inputProps: { type: 'number', min: 0 },
    },
    {
      prefix: 'is more than',
      value: 'more_than',
      inputProps: { type: 'number', min: 0 },
    },
    {
      prefix: 'is less than',
      value: 'less_than',
      inputProps: { type: 'number', min: 0 },
    },
  ],
  enum: [
    {
      prefix: 'is',
      value: 'is',
    },
  ],
  multiple_choice: [
    {
      prefix: 'is one of',
      value: 'in',
    },
  ],
  date: [
    {
      prefix: 'is exactly',
      value: 'is',
      inputProps: { type: 'date' },
    },
    {
      prefix: 'is before',
      value: 'before',
      inputProps: { type: 'date' },
    },
    {
      prefix: 'is after',
      value: 'after',
      inputProps: { type: 'date' },
    },
  ],
}
