import { Step, Stepper, Uploader, View } from 'components'
import {
  Checkbox,
  Font,
  TooltipContainer,
  Icon,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useTranslation, withTranslation } from 'react-i18next'
import { postImport } from 'requests/dataSources'
import { useState } from 'react'
import { useCurrent } from 'contexts/index'
import { IMPORTABLE_ATTRS } from './constants'
import SpreadsheetPreview from './SpreadsheetPreview'
import styles from './ImportsUpload.module.scss'

const ImportsUpload = () => {
  const { t } = useTranslation()
  const [importInfo, setImportInfo] = useState({
    stored_spreadsheet_data: {},
    mappings: [],
  })

  const {
    tenantOptions: {
      auto_merge_import_duplicates: autoMergeImportDuplicatesEnabled,
    },
  } = useCurrent()

  const [forPhoneBank, setForPhoneBank] = useState(false)

  const { stored_spreadsheet_data, mappings } = importInfo

  const canPostImport = () => {
    const mappingKeys = mappings.map(({ attribute }) => attribute)
    if (forPhoneBank) {
      return (
        mappingKeys.includes('first_name') &&
        mappingKeys.includes('last_name') &&
        mappingKeys.includes('primary_phone_number')
      )
    }
    return (
      mappingKeys.includes('first_name') &&
      mappingKeys.includes('last_name') &&
      (mappingKeys.includes('primary_phone_number') ||
        mappingKeys.includes('primary_email_address'))
    )
  }

  return (
    <View>
      <PageHeader title={t('Import people records')} />
      <div className={styles.container}>
        <Stepper>
          <Step
            label={t('Upload file')}
            disableNext={!stored_spreadsheet_data?.metadata?.preview?.length}
            disablePrevious
            previousButtonLabel={t('Back')}
          >
            <Uploader
              label={t('Import file')}
              endpoint="/system/files/upload?metadata_generator=import"
              onUpload={uploadData => {
                setImportInfo(prevImportInfo => ({
                  ...prevImportInfo,
                  stored_spreadsheet_data: uploadData,
                }))
                return Promise.resolve()
              }}
              onReset={() =>
                setImportInfo(prevImportInfo => ({
                  ...prevImportInfo,
                  stored_spreadsheet_data: null,
                }))
              }
              autoUpload
              stringifyData={false}
              hideCancelButton
              hideUploadButton
              fileTypes={['.csv']}
              dataValidator={data =>
                data?.metadata?.preview?.length
                  ? ''
                  : t('Could not detect any data in the uploaded file')
              }
              validateUTF8
            />
          </Step>
          <Step
            label={t('Prepare import')}
            nextButtonLabel={t('Submit')}
            disableNext={canPostImport() === false}
            onNext={() =>
              postImport({
                stored_spreadsheet_data,
                mappings: mappings.map(m => ({
                  column: m.attribute,
                  index: m.columnIndex,
                })),
                for_phone_bank: forPhoneBank,
              })
            }
          >
            <SpreadsheetPreview
              preview_data={stored_spreadsheet_data?.metadata?.preview}
              setMappings={mappings =>
                setImportInfo(prevImportInfo => ({
                  ...prevImportInfo,
                  mappings,
                }))
              }
              importable_attrs={IMPORTABLE_ATTRS}
            />
            {!autoMergeImportDuplicatesEnabled && (
              <div className={styles['for-phone-bank']}>
                <Checkbox
                  id="for-phone-bank-checkbox"
                  onChange={() => setForPhoneBank(prev => !prev)}
                  checked={forPhoneBank}
                  label={t('Import as phone bank list')}
                />
                <span>
                  <TooltipContainer
                    label={t(
                      'Selecting this option will build a list with the imported records. The import must include a primary phone number.'
                    )}
                  >
                    <Icon.InfoCircle />
                  </TooltipContainer>
                </span>
              </div>
            )}
            {!canPostImport() && (
              <Font.Copy variant="hint">
                {forPhoneBank
                  ? t('First name, last name, and primary phone are required.')
                  : t(
                      'First name, last name, and either primary phone or primary email are required.'
                    )}
              </Font.Copy>
            )}
          </Step>
          <Step label={t('Review')} hideNextButton hidePreviousButton>
            <p>
              {t(
                'Your import is processing in the background and will be available in a few minutes. You may leave this page.'
              )}
            </p>
          </Step>
        </Stepper>
      </div>
    </View>
  )
}

export default withTranslation()(ImportsUpload)
