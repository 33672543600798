import React from 'react'
import { ProgressBarGroup } from 'components'
import { ChartColors } from 'components/uiKit/Colors'

const ProgressBars = ({ data }) => {
  if (!data) return null
  return (
    <div data-testid="progress-bars" className="chart__progress-bars">
      {data.map((data, index) => {
        if (!data.total && !data.qced) return null
        const groupColor = ChartColors[index]
        return <ProgressBarGroup data={data} color={groupColor} key={index} />
      })}
    </div>
  )
}

export default ProgressBars
