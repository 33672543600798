export default [
  '#1BBC9B',
  '#3598DB',
  '#9B58B5',
  '#34495E',
  '#F1C40F',
  '#E77E23',
  '#E84C3D',
  '#ECF0F1',
  '#95A5A5',
  '#2DCC70',
]
