import { createContext, useContext, useRef } from 'react'

const KeyboardNavContext = createContext()

export const KeyboardNavProvider = ({ children }) => {
  const refs = useRef(new Map()).current
  const position = useRef(-1)

  const addItem = item => {
    item && refs.set(item, refs.size)
  }

  const clearItems = () => {
    position.current = -1
    refs.clear()
  }

  const focusItem = i => {
    const items = [...refs.keys()]
    if (!items[i]) return

    position.current = i
    items[i].focus()
  }

  const moveDown = () => {
    if (position.current + 1 < refs.size) {
      focusItem(position.current + 1)
    } else if (position.current + 1 === refs.size) {
      focusItem(0)
    }
  }

  const moveUp = () => {
    if ([-1, 0].includes(position.current)) {
      focusItem(refs.size - 1)
    } else if (position.current - 1 < refs.size) {
      focusItem(position.current - 1)
    }
  }

  const setPosition = item => {
    if (refs.has(item)) {
      focusItem(refs.get(item))
    }
  }

  return (
    <KeyboardNavContext.Provider
      value={{ addItem, clearItems, moveDown, moveUp, setPosition }}
    >
      {children}
    </KeyboardNavContext.Provider>
  )
}

export const useKeyboardNav = () => {
  const context = useContext(KeyboardNavContext)
  return context
}
