import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonBlock,
  Button,
  FieldBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import { SearchSelect, Section, TextField } from 'components'
import useVdrs from './useVdrs'

const CanvasserVdrs = ({ vdrData, onChange, stateOptions }) => {
  const { t } = useTranslation()
  const { countyOptions, updateCounty, updateCode, addVdr, removeVdr } =
    useVdrs(vdrData, onChange, stateOptions)
  const vdrNotInCountyOptions = vdr =>
    vdr.county && !countyOptions.some(county => county.value === vdr.county)

  return (
    <Section label={t('Volunteer Deputy Registrations')}>
      {vdrData.map((vdr, index) => (
        <FieldBlock key={index}>
          <SearchSelect
            id={`vdrCounty${index}`}
            label={t('County')}
            value={vdr.county}
            options={countyOptions.concat(
              vdrNotInCountyOptions(vdr)
                ? [{ value: vdr.county, label: vdr.county }]
                : []
            )}
            onChange={val => updateCounty(val, index)}
            required
          />
          <TextField
            id={`vdrCode${index}`}
            label={t('VDR code')}
            value={vdr.code}
            onChange={val => updateCode(val, index)}
            required
          />
          <Button aria-label="Remove VDR" onClick={() => removeVdr(index)}>
            <Icon.Times />
          </Button>
        </FieldBlock>
      ))}
      <ButtonBlock>
        <Button aria-label="Add VDR" onClick={addVdr}>
          <Icon.Plus />
        </Button>
      </ButtonBlock>
    </Section>
  )
}

export default CanvasserVdrs
