import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { useReactRouter } from 'hooks/router'
import { DEFAULT_INSPECTED_USER } from 'constants/tableQCmanager'
import InboxContent from './InboxContent'
import QCPacketFilterProvider from './QCPacketFilterContext/QCPacketFilterContext'
import { useQualityControl } from '../QualityControlContext'
import { useInbox } from './InboxContext'

const InspectedInbox = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { staffId } = match.params
  const { getInspectedUser, setInspectedUser } = useQualityControl()

  const { advanceReviewedPackets } = useInbox()

  const {
    isLoading: packetsLoading,
    hasErrors: packetsError,
    makeRequest: requestPackets,
  } = useRequest(getInspectedUser, {
    onSuccess: () => advanceReviewedPackets(staffId),
  })

  useEffect(() => {
    requestPackets(staffId)
    return () => {
      setInspectedUser(DEFAULT_INSPECTED_USER)
    }
  }, [])

  const errorMsg =
    packetsError &&
    t('There was an issue trying to retrieve this user’s worklist')

  return (
    <QCPacketFilterProvider>
      <InboxContent
        errorMsg={errorMsg}
        isLoading={packetsLoading}
        pageTitle={t('Inspected QC Worklist')}
      />
    </QCPacketFilterProvider>
  )
}

export default InspectedInbox
