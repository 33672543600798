export default {
  svfl: 'evc',
  svga: 'evc',
  svnv: 'evc',
  svco: 'evc',
  svdemo: 'evc',
  svmi: 'evc',
  svmn: 'evc',
  svnc: 'evc',
  svoh: 'evc',
  svpa: 'evc',
  svwi: 'evc',
  onearizona: 'evc',
  vrp: 'evc',
  pico: 'faithinaction',
  ampi: 'ampi',
  faithinaction: 'faithinaction',
  fldems: 'fldems',
  dreamdefenders: 'dreamdefenders',
  sisnerosstrategies: 'fldems',
  equalground: 'fldems',
  'ra-tx': 'registeramerica',
  'ra-az': 'registeramerica',
  'ra-pa': 'registeramerica',
  'ra-mi': 'registeramerica',
  'ra-fl': 'registeramerica',
  default: 'translations',
}
