import { Filter } from 'qualityControl/inbox/Filter'
import menuItems from 'qualityControl/inbox/menuItems'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { QCPacketFilterContext } from './QCPacketFilterContext/QCPacketFilterContext'
import { useInbox } from './InboxContext'

export const TimeZoneFilter = () => {
  const { t } = useTranslation()
  const { isPendingPacketsEmpty: isDisabled } = useInbox()
  const { timeZone, setTimeZone } = useContext(QCPacketFilterContext)

  return (
    <Filter
      id="timezone_filter"
      label={t('Timezone')}
      value={timeZone}
      onChange={setTimeZone}
      menuItems={menuItems.timeZone}
      isDisabled={isDisabled}
    />
  )
}

export default TimeZoneFilter
