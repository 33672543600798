import { useEffect } from 'react'
import { useToast } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const NetworkCheck = () => {
  const { t } = useTranslation()
  const { setToast } = useToast()

  useEffect(() => {
    window.addEventListener('online', () => {
      setToast({
        message: t('Your internet connection has been reestablished'),
        variant: 'success',
      })
    })

    window.addEventListener('offline', () => {
      setToast({
        message: t('Your internet connection has been lost'),
        variant: 'error',
      })
    })
  }, [])

  return null
}

export default NetworkCheck
