import { sumBy, meanBy } from 'lodash'

const qcTimelineCalculations = timeline => ({
  total_shifts: {
    derive: ({ rowData }) => rowData[`total_shifts_${timeline}`] ?? 0,
    get summary() {
      return ({ data }) => sumBy(data, rowData => this.derive({ rowData }))
    },
  },
  total_hours: {
    derive: ({ rowData }) =>
      rowData[`total_minutes_${timeline}`] === undefined
        ? 0
        : rowData[`total_minutes_${timeline}`] / 60,
    get summary() {
      return ({ data }) => sumBy(data, rowData => this.derive({ rowData }))
    },
  },
  avg_shift_length: {
    derive: ({ rowData }) =>
      rowData[`total_minutes_${timeline}`] === undefined
        ? 0
        : rowData[`total_minutes_${timeline}`] /
          60 /
          rowData[`total_shifts_${timeline}`],
    get summary() {
      return ({ data }) =>
        meanBy(
          data.filter(row => row[`total_minutes_${timeline}`] !== undefined),
          rowData => this.derive({ rowData })
        )
    },
  },
  total_scans_viz: {
    derive: ({ rowData }) => rowData[`total_scans_viz_${timeline}`] ?? 0,
    get summary() {
      return ({ data }) => sumBy(data, rowData => this.derive({ rowData }))
    },
  },
  total_scans_phone: {
    derive: ({ rowData }) => rowData[`total_scans_phone_${timeline}`] ?? 0,
    get summary() {
      return ({ data }) => sumBy(data, rowData => this.derive({ rowData }))
    },
  },
  viz_qc_rate: {
    derive: ({ rowData }) =>
      rowData[`total_minutes_${timeline}`] === undefined
        ? 0
        : rowData[`total_scans_viz_${timeline}`] /
          (rowData[`total_minutes_${timeline}`] / 60),
    get summary() {
      return ({ data }) =>
        meanBy(
          data.filter(row => row[`total_minutes_${timeline}`] !== undefined),
          rowData => this.derive({ rowData })
        )
    },
  },
  phone_qc_rate: {
    derive: ({ rowData }) =>
      rowData[`total_minutes_${timeline}`] === undefined
        ? 0
        : (rowData[`total_scans_phone_${timeline}`] * 3) /
          (rowData[`total_minutes_${timeline}`] / 60),
    get summary() {
      return ({ data }) =>
        meanBy(
          data.filter(row => row[`total_minutes_${timeline}`] !== undefined),
          rowData => this.derive({ rowData })
        )
    },
  },
  total_qc_rate: {
    derive: ({ rowData }) =>
      rowData[`total_minutes_${timeline}`] === undefined
        ? 0
        : (rowData[`total_scans_phone_${timeline}`] * 3 +
            rowData[`total_scans_viz_${timeline}`]) /
          4 /
          (rowData[`total_minutes_${timeline}`] / 60),
    get summary() {
      return ({ data }) =>
        meanBy(
          data.filter(row => row[`total_minutes_${timeline}`] !== undefined),
          rowData => this.derive({ rowData })
        )
    },
  },
})

export default qcTimelineCalculations
