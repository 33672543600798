import { compact } from 'lodash/array'
import { orderBy } from 'lodash/collection'
import { get } from 'lodash'
import operations from './TableFilters/utils'

export const createRowAction = (
  actionLabel,
  actionHandler,
  isEnabled = true
) => ({
  primaryText: actionLabel,
  onClick: actionHandler,
  isDisabled:
    typeof isEnabled === 'function'
      ? rowData => !isEnabled(rowData)
      : !isEnabled,
})

export const buildFilteredData = (
  data,
  filterRules = [],
  filterConjunction = 'exclusive',
  columns
) => {
  const compactFilterRules = compact(filterRules)
  if (!compactFilterRules.length) return data

  const columnsByKey = columns.reduce(
    (acc, col) => ({ ...acc, [col.dataKey]: col }),
    {}
  )

  const testRow = ({ column, operator, param, invert }, row) => {
    const { type } = columnsByKey[column]
    const operation = operations[type][operator]
    const value = get(row, column)
    return operation(value, param, !!invert)
  }

  return data.filter(row =>
    filterConjunction === 'inclusive'
      ? compactFilterRules.some(rule => testRow(rule, row))
      : compactFilterRules.every(rule => testRow(rule, row))
  )
}

const alphaNumericCollator = new Intl.Collator(undefined, { numeric: true })

export const getColumnConfig = (columns, key, config) => {
  const columnIndex = columns.findIndex(col => col.dataKey === key)
  return columnIndex !== -1 ? columns[columnIndex][config] : undefined
}

export const buildSortedData = (
  data,
  sortColumn,
  sortOrder,
  alphaNumeircSort
) => {
  if (alphaNumeircSort) {
    return sortOrder === 'desc'
      ? data.sort((a, b) =>
          alphaNumericCollator.compare(b[sortColumn], a[sortColumn])
        )
      : data.sort((a, b) =>
          alphaNumericCollator.compare(a[sortColumn], b[sortColumn])
        )
  }

  return orderBy(data, [sortColumn], [sortOrder])
}

export const buildDerivedData = (data = [], columns) => {
  const derivedData = data.map(data => ({ ...data }))
  columns.forEach(column => {
    if (column.derivedFrom) {
      derivedData.forEach(rowData => {
        rowData[column.dataKey] = rowData[column.derivedFrom]
      })
    }
    if (column.deriveFunction) {
      derivedData.forEach((rowData, i) => {
        rowData[column.dataKey] = column.deriveFunction({
          rowData: column.useRawData ? data[i] : rowData,
          cellData: rowData[column.dataKey],
        })
      })
    }
  })
  return derivedData
}

export const buildCsvData = (data = [], columns) => {
  const csvColumns = columns.filter(column => column.csvCell)

  return data
    .map(row => {
      const cells = csvColumns.reduce((acc, column) => {
        acc[column.dataKey] = column.csvCell(row)
        return acc
      }, {})

      return {
        ...row,
        ...cells,
      }
    })
    .map(row =>
      Object.keys(row).reduce((acc, key) => {
        let cellValue = row[key]

        if (typeof cellValue === 'string') {
          cellValue = cellValue.replace(/"/g, '""')
        }

        return {
          ...acc,
          [key]: cellValue,
        }
      }, {})
    )
}

export const buildCsvColumns = (columns, hiddenColumns = []) =>
  columns
    .map(c => ({
      key: c.dataKey,
      label: c.title,
    }))
    .filter(c => !hiddenColumns.includes(c.key))

export const formatTotalData = (
  data,
  preparedData,
  row,
  column,
  index,
  summaryFunction
) => {
  if (index === 0) return row

  const total = summaryFunction
    ? summaryFunction({
        data: column.useRawData ? data : preparedData,
        key: column.dataKey,
        acc: row,
      })
    : undefined

  if (total === 'NaN') {
    row[column.dataKey] = '-'
  } else if (typeof total === 'string') {
    row[column.dataKey] = total
  } else if (!Number.isFinite(total)) {
    row[column.dataKey] = '-'
  } else if (!Number.isInteger(total)) {
    row[column.dataKey] = total.toFixed(2)
  } else {
    row[column.dataKey] = total
  }

  return row
}
