import translatedCellRenderer from 'tableCells/TranslatedCell'
import { CampaignNameCell, CampaignDateCell } from './CampaignTableCells'

export default {
  label: 'campaigns',
  columns: [
    {
      dataKey: 'name',
      title: 'Name',
      type: 'string',
      bodyCell: CampaignNameCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'start_date',
      title: 'Start date',
      type: 'date',
      bodyCell: CampaignDateCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'end_date',
      title: 'End date',
      type: 'date',
      bodyCell: CampaignDateCell,
      headerCell: translatedCellRenderer,
    },
  ],
}
