import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { stringify } from 'qs'

const QCResponseNameCell = ({ rowData }) => {
  if (
    rowData.response_description &&
    rowData.implies_incomplete_form === 'true'
  ) {
    return (
      <TableGridCell>
        <Link
          to={`/collect/voter_registration/shifts?${stringify({
            filterRules: [
              {
                column: 'visual_review_responses_description',
                operator: 'is',
                param: rowData.response_description,
              },
            ],
          })}`}
        >
          {rowData.response_description}
        </Link>
      </TableGridCell>
    )
  }
  return <TableGridCell>{rowData.response_description}</TableGridCell>
}

export default QCResponseNameCell
