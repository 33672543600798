export const isTrainable = role => role.needs_training

export const isVisualQcTrainable = role =>
  !!(
    role.permissions.find(
      p => p.resource === 'shift' && p.abilities.includes('visual_qc')
    ) || role.admin
  )

export const isPhoneVerTrainable = role =>
  !!(
    role.permissions.find(
      p => p.resource === 'shift' && p.abilities.includes('phone_verification')
    ) || role.admin
  )
