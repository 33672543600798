import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import {
  READY,
  CONNECTING,
  ACTIVE,
  REVIEW,
  COMPLETE,
} from 'constants/phoneBankSessions'
import PropTypes from 'prop-types'

const CallPanelControls = ({
  callStep,
  startCall,
  endCall,
  disabled,
  endSession,
  resetCall,
}) => {
  const { t } = useTranslation()

  const dialButtonProps = {
    [READY]: {
      text: 'START CALL',
      action: startCall,
    },
    [CONNECTING]: {
      text: 'END CALL',
      action: endCall,
    },
    [ACTIVE]: {
      text: 'END CALL',
      action: endCall,
    },
  }

  return (
    <ButtonBlock>
      {callStep === REVIEW && (
        <Button.Secondary onClick={resetCall}>
          {t('Try again')}
        </Button.Secondary>
      )}
      {callStep !== REVIEW && callStep !== COMPLETE && (
        <Button.Accent
          onClick={dialButtonProps[callStep].action}
          disabled={disabled}
        >
          {dialButtonProps[callStep].text}
        </Button.Accent>
      )}
      <Button onClick={endSession} disabled={callStep !== READY}>
        {t('End session')}
      </Button>
    </ButtonBlock>
  )
}

export default CallPanelControls

CallPanelControls.propTypes = {
  callStep: PropTypes.string,
  startCall: PropTypes.func,
  endCall: PropTypes.func,
  disabled: PropTypes.bool,
  endSession: PropTypes.func,
  resetCall: PropTypes.func,
}
