import { useState, useEffect, useMemo } from 'react'

const useOnScreen = ref => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    [ref]
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect()
  }, [ref.current])

  return isIntersecting
}

export default useOnScreen
