import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer } from 'components'
import {
  Sheet,
  ButtonBlock,
  Button,
  Icon,
  PageHeader,
} from '@politechdev/blocks-design-system'
import PhoneBankForm from '../PhoneBankForm/PhoneBankForm'

const PhoneBankNew = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <PageHeader title={t('New phone bank')}>
            <ButtonBlock justify="right">
              <Link to="/organize/phone_banks">
                <Button.Secondary>
                  <Icon.Times alt="close" />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </PageHeader>
          <PhoneBankForm />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default PhoneBankNew
