/* eslint-disable jsx-a11y/control-has-associated-label */
import { Sparklines, SparklinesLine } from 'react-sparklines'

const VoterRegSummaryRow = ({ data }) => (
  <tr>
    <td>{data.turf_name}</td>
    <td className="text-center">{data.total_registrations}</td>
    <td className="text-right">
      <Sparklines data={data.trend}>
        <SparklinesLine color="blue" />
      </Sparklines>
    </td>
  </tr>
)

export default VoterRegSummaryRow
