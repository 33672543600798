import { Button } from 'react-md'
import { useTranslation } from 'react-i18next'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'

const ReassignmentScheduleButton = () => {
  const { t } = useTranslation()
  const { setIsReassignmentScheduleOpen } = usePacketAssignment()

  return (
    <Button primary raised onClick={() => setIsReassignmentScheduleOpen(true)}>
      {t(`Manage Schedule`)}
    </Button>
  )
}

export default ReassignmentScheduleButton
