import { useEffect, useState } from 'react'
import { Grid, LoadBar } from 'components'
import { useReactRouter } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import { fetchPerson } from 'requests/people'
import useEvent from 'hooks/useEvent'
import PersonAdditionalInformation from 'people/PersonAdditionalInformation/PersonAdditionalInformation'
import PersonRelationships from 'people/PersonRelationships/PersonRelationships'
import PersonDemographics from 'people/PersonDemographics/PersonDemographics'
import PersonLanguages from 'people/PersonLanguages/PersonLanguages'
import { personFields } from './utils'

const PersonProfile = () => {
  const [person, setPerson] = useState(null)
  const { match } = useReactRouter()

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: personReq,
    isLoading: isPersonLoading,
    isRequestComplete: isPersonRequestComplete,
  } = useRequest(fetchPerson, {
    onSuccess: ({ person }) => setPerson(person),
  })

  const refreshPerson = useEvent(async () => {
    const res = await personReq(match.params.id, personFields)
    setPerson(res.person)
  })

  useEffect(() => {
    personReq(match.params.id, personFields)
  }, [match.params.id])

  if (!isPersonRequestComplete || !person || isPersonLoading) {
    return <LoadBar show />
  }
  return (
    <Grid>
      <PersonDemographics person={person} refreshPerson={refreshPerson} />
      <PersonRelationships person={person} refreshPerson={refreshPerson} />
      <PersonLanguages person={person} refreshPerson={refreshPerson} />
      <PersonAdditionalInformation
        person={person}
        refreshPerson={refreshPerson}
      />
    </Grid>
  )
}

export default PersonProfile
