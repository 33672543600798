import { Button, Icon } from '@politechdev/blocks-design-system'
import { useReportModalContext } from 'contexts'

const EllipsisModalCell = ({ column, cellData }) => {
  const { setIsOpen, setContent, setModalType, MODAL_TYPES } =
    useReportModalContext()

  return (
    <div className="ellipsis-modal-cell">
      <div className="ellipsis-modal-cell__cell-data">{cellData || ''}</div>
      <Button.Secondary
        onClick={() => {
          setModalType(MODAL_TYPES.DEFAULT)
          setContent({
            title: column.title,
            body: cellData || '',
          })
          setIsOpen(true)
        }}
      >
        <Icon.Expand />
      </Button.Secondary>
    </div>
  )
}

export default EllipsisModalCell

export const ellipsisModalCellRender = props => <EllipsisModalCell {...props} />
