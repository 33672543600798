import { useTranslation } from 'react-i18next'
import { CountySelectField, ScrollToTop } from 'components'
import {
  TextField,
  SelectField,
  Section,
  Button,
  FieldBlock,
  ButtonBlock,
  TextBlock,
  Icon,
  Font,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { isValidEmail, sanitizeStr, isValidUrl } from 'utils/inputValidations'
import {
  EMAIL_TYPES,
  SOCIAL_LINK_TYPES,
  nonPrimaryPhoneOptions,
} from 'constants/people'
import US from 'us'
import styles from './NewPersonFormContact.module.scss'

const NewPersonFormContact = ({
  newPerson,
  updatePersonState,
  newPhones,
  newEmails,
}) => {
  const { t } = useTranslation()

  const addNewContactMethodFields = type => {
    if (type === 'phone') {
      updatePersonState({
        newPhones: [
          ...newPhones,
          { contact_type: '', content: '', extension: '' },
        ],
      })
    } else if (type === 'email') {
      updatePersonState({
        newEmails: [...newEmails, { contact_type: '', content: '' }],
      })
    }
  }

  const updateContactMethod = (index, key, value, type) => {
    if (type === 'phone') {
      updatePersonState({
        newPhones: newPhones.map((phone, phoneIndex) =>
          phoneIndex === index ? { ...phone, [key]: value } : phone
        ),
      })
    } else if (type === 'email') {
      updatePersonState({
        newEmails: newEmails.map((email, emailIndex) =>
          emailIndex === index ? { ...email, [key]: value } : email
        ),
      })
    }
  }

  const getSocialLinkUrl = typeKey => {
    const linkOption = newPerson.social_links.find(
      link => link.type === typeKey
    )
    return linkOption ? linkOption.url : ''
  }

  const nonPrimaryemailOptions = Object.keys(EMAIL_TYPES)
    .filter(key => key !== 'primary_email_address')
    .map(key => ({ value: key, label: EMAIL_TYPES[key] }))

  return (
    <>
      <ScrollToTop />
      <form>
        <Section label={t('Address information')}>
          <FieldBlock>
            <TextField
              id="street-address"
              label={t('Street address')}
              value={newPerson.residential_address.line_one || ''}
              onChange={line_one => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    residential_address: {
                      ...newPerson.residential_address,
                      line_one,
                    },
                  },
                })
              }}
              autoComplete="off"
            />
            <TextField
              id="address-two"
              label={t('Address 2')}
              value={newPerson.residential_address.line_two || ''}
              onChange={line_two => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    residential_address: {
                      ...newPerson.residential_address,
                      line_two,
                    },
                  },
                })
              }}
              autoComplete="off"
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="city"
              label={t('City')}
              value={newPerson.residential_address.city || ''}
              onChange={city => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    residential_address: {
                      ...newPerson.residential_address,
                      city,
                    },
                  },
                })
              }}
              autoComplete="off"
            />
            <SelectField
              id="state"
              label={t('State')}
              value={newPerson.residential_address.state || ''}
              options={US.STATES.map(state => ({
                value: state.abbr,
                label: state.name,
              }))}
              onSelect={state => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    residential_address: {
                      ...newPerson.residential_address,
                      state,
                    },
                  },
                })
              }}
              autoComplete="off"
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="zipcode"
              label={t('Zipcode')}
              value={newPerson.residential_address.zipcode || ''}
              onChange={zipcode => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    residential_address: {
                      ...newPerson.residential_address,
                      zipcode,
                    },
                  },
                })
              }}
              autoComplete="off"
            />
            <CountySelectField
              id="county"
              label={t('County')}
              state={newPerson.residential_address.state}
              county={newPerson.residential_address.county}
              onSelect={county => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    residential_address: {
                      ...newPerson.residential_address,
                      county,
                    },
                  },
                })
              }}
              autoComplete="off"
            />
          </FieldBlock>
        </Section>
        <Section label={t('Phone numbers')}>
          <FieldBlock>
            <TextField
              type="tel"
              id="primary_phone_number"
              label={t('Primary phone number')}
              value={newPerson?.primary_phone_number || ''}
              onChange={primary_phone_number => {
                updatePersonState({
                  newPerson: { ...newPerson, primary_phone_number },
                })
              }}
              autoComplete="off"
            />
          </FieldBlock>
          <TextBlock>
            <Font.Label>{t('Other phones')}</Font.Label>
          </TextBlock>
          {newPhones.map((phone, index) => (
            <FieldBlock key={index}>
              <SelectField
                id="phone"
                label={t('Phone type')}
                value={newPhones[index].contact_type}
                options={nonPrimaryPhoneOptions}
                onSelect={phone =>
                  updateContactMethod(index, 'contact_type', phone, 'phone')
                }
                autoComplete="off"
              />
              <TextField
                type="tel"
                id={`new-phone-${index}-content`}
                label={t('Phone number')}
                value={newPhones[index].content || ''}
                disabled={!newPhones[index].contact_type}
                onChange={phoneNumber =>
                  updateContactMethod(index, 'content', phoneNumber, 'phone')
                }
                autoComplete="off"
              />
              <div className={styles.extension}>
                <TextField
                  id={`new-phone-${index}-extension`}
                  label={t('Extension')}
                  value={newPhones[index].extension || ''}
                  disabled={!newPhones[index].contact_type}
                  onChange={phoneNumber =>
                    updateContactMethod(
                      index,
                      'extension',
                      phoneNumber,
                      'phone'
                    )
                  }
                  autoComplete="off"
                />
              </div>
              <Button.Secondary
                aria-label={t('Remove')}
                onClick={() => {
                  updatePersonState({
                    newPhones: newPhones.filter(
                      phone => phone !== newPhones[index]
                    ),
                  })
                }}
              >
                <Icon.Times />
              </Button.Secondary>
            </FieldBlock>
          ))}
          <ButtonBlock>
            <Button
              aria-label={t('Add')}
              onClick={() => {
                addNewContactMethodFields('phone')
              }}
            >
              <Icon.Plus />
            </Button>
          </ButtonBlock>
        </Section>
        <Section label={t('Emails')}>
          <FieldBlock>
            <TextField
              type="text"
              id="primary_email_address"
              label={t('Primary email address')}
              value={newPerson.primary_email_address}
              onChange={primary_email_address => {
                updatePersonState({
                  newPerson: { ...newPerson, primary_email_address },
                })
              }}
              error={
                !!newPerson.primary_email_address &&
                !isValidEmail(newPerson.primary_email_address)
              }
              errorText={t('Must be a valid email')}
              autoComplete="off"
            />
          </FieldBlock>
          <TextBlock>
            <Font.Label>{t('Other emails')}</Font.Label>
          </TextBlock>
          {newEmails.map((newEmail, emailIndex) => (
            <FieldBlock key={emailIndex}>
              <SelectField
                id="email"
                label={t('Email type')}
                value={newEmails[emailIndex].contact_type}
                options={nonPrimaryemailOptions}
                onSelect={val =>
                  updateContactMethod(emailIndex, 'contact_type', val, 'email')
                }
                autoComplete="off"
              />
              <TextField
                type="text"
                id={`new-email-${emailIndex}-content`}
                label={t('Email')}
                value={newEmails[emailIndex].content}
                disabled={!newEmails[emailIndex].contact_type}
                onChange={email =>
                  updateContactMethod(
                    emailIndex,
                    'content',
                    sanitizeStr(email),
                    'email'
                  )
                }
                error={
                  !!newEmails[emailIndex].content &&
                  !isValidEmail(newEmails[emailIndex].content)
                }
                errorText={t('Must be a valid email')}
                autoComplete="off"
              />
              <Button.Secondary
                aria-label={t('Remove')}
                onClick={() => {
                  updatePersonState({
                    newEmails: newEmails.filter(
                      e => e !== newEmails[emailIndex]
                    ),
                  })
                }}
              >
                <Icon.Times />
              </Button.Secondary>
            </FieldBlock>
          ))}
          <ButtonBlock>
            <Button
              aria-label={t('Add')}
              onClick={() => {
                addNewContactMethodFields('email')
              }}
            >
              <Icon.Plus />
            </Button>
          </ButtonBlock>
        </Section>
        <Section label={t('Social media')}>
          <FieldBlock>
            {Object.keys(SOCIAL_LINK_TYPES).map(key => (
              <TextField
                key={key}
                type="text"
                id={key}
                label={`${SOCIAL_LINK_TYPES[key]} profile URL`}
                value={getSocialLinkUrl(key)}
                onChange={url => {
                  updatePersonState({
                    newPerson: {
                      ...newPerson,
                      social_links: [
                        ...newPerson.social_links.filter(
                          link => link.type !== key
                        ),
                        {
                          type: key,
                          url,
                        },
                      ],
                    },
                  })
                }}
                placeholder={`Example: https://${key}.com/JaneDoe'`}
                error={
                  !!getSocialLinkUrl(key) && !isValidUrl(getSocialLinkUrl(key))
                }
                errorText={`Must be a valid full url. Example: https://${key}.com/JaneDoe'`}
                autoComplete="off"
              />
            ))}
          </FieldBlock>
        </Section>
        <Section label={t('Preferred contact method')}>
          <FieldBlock>
            <SelectField
              id="best_contact_method"
              label={t('Best contact method')}
              value={newPerson.best_contact_method || ''}
              options={['Email', 'Call', 'Text'].map(contactMethod => ({
                value: contactMethod,
                label: contactMethod,
              }))}
              onSelect={bestMethod => {
                updatePersonState({
                  newPerson: { ...newPerson, best_contact_method: bestMethod },
                })
              }}
              autoComplete="off"
            />
            <Checkbox
              id="person-sms"
              name="add-sms"
              className={styles.checkbox}
              label={t('Receive SMS notifications')}
              type="checkbox"
              checked={newPerson.receives_sms}
              onChange={receives_sms => {
                updatePersonState({
                  newPerson: { ...newPerson, receives_sms },
                })
              }}
              autoComplete="off"
            />
          </FieldBlock>
        </Section>
      </form>
    </>
  )
}

export default NewPersonFormContact
