import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { LoadBar, SectionLabel } from 'components'
import styles from './WidgetPanel.module.scss'

const cx = classNames.bind(styles)

const WidgetPanel = ({
  title,
  isFull,
  isLoading,
  isEmpty,
  errorMsg,
  children,
}) => {
  const { t } = useTranslation()

  const getContent = () => {
    if (isLoading) {
      return (
        <div className={styles.loadbar}>
          <LoadBar show />
        </div>
      )
    }

    if (errorMsg) {
      return (
        <div className={styles.notice}>
          {t('An error occurred loading this widget.')}
        </div>
      )
    }

    if (isEmpty) {
      return (
        <div className={styles.notice}>
          {t('No data available for the selected time period.')}
        </div>
      )
    }

    return children
  }

  return (
    <div
      className={cx('panel', {
        'panel--full': isFull,
      })}
    >
      <div className={styles.header}>
        <SectionLabel>{title}</SectionLabel>
      </div>
      <div
        className={cx('content', {
          'content--loading': isLoading,
        })}
      >
        {getContent()}
      </div>
    </div>
  )
}

WidgetPanel.propTypes = {
  title: PropTypes.string.isRequired,
  isFull: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

WidgetPanel.defaultProps = {
  isFull: false,
  isLoading: false,
  isEmpty: false,
}

export default WidgetPanel
