import styles from './HighlightCell.module.scss'

const HighlightCell =
  (key, predicate) =>
  ({ rowData }) => {
    if (rowData?.[key] === undefined || Number.isNaN(rowData?.[key])) {
      return '-'
    }
    return (
      <>
        <div className={predicate(rowData) && styles.highlight} />
        <div className={predicate(rowData) && styles.cell}>{rowData[key]}</div>
      </>
    )
  }

export default HighlightCell
