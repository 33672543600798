import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, EventSelectField, Button as IconButton } from 'components'
import {
  Modal,
  Button,
  TextBlock,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { postInvites } from 'requests/lists'
import styles from './ListEventInviteModal.module.scss'

const ListEventInviteModal = ({ currentList }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [event, setEvent] = useState(null)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setEvent(null)
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(postInvites, {
    onSuccess: closeModal,
  })

  const sendInvites = () => {
    makeRequest(currentList.id, event.id)
  }

  return (
    <>
      <IconButton
        icon
        tooltipLabel={t('Add list to event')}
        onClick={openModal}
      >
        event
      </IconButton>
      <Modal
        id="list-event-invite"
        title={t(`Add list to event`)}
        isOpen={modalOpen}
        showLoading={isLoading}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error adding this list to your event')}
              hideSupportLink
            />
            <TextBlock className={styles.text}>
              {t('Add the people in this list to the following event')}
            </TextBlock>
            <div className={styles.field}>
              <EventSelectField
                onSelect={setEvent}
                event={event}
                required
                clearable
              />
            </div>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent onClick={sendInvites} disabled={!event}>
              {t('Invite')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ListEventInviteModal
