import React, { Component } from 'react'
import PropTypes from 'prop-types'
import hideElement from 'utils/react'
import { withTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import styles from './Stepper.module.scss'

class Step extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    step: PropTypes.number,
    active: PropTypes.bool,
    complete: PropTypes.bool,
    errorMsg: PropTypes.string,
    summaryStep: PropTypes.bool,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    nextButtonLabel: PropTypes.string,
    previousButtonLabel: PropTypes.string,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    hideNextButton: PropTypes.bool,
    hidePreviousButton: PropTypes.bool,
    disableNext: PropTypes.bool,
    disablePrevious: PropTypes.bool,
    customNextButtonFunction: PropTypes.func,
    isControlled: PropTypes.bool,
  }

  static defaultProps = {
    step: 0,
    active: false,
    complete: false,
    summaryStep: false,
    nextStep: null,
    previousStep: null,
    nextButtonLabel: 'Continue',
    previousButtonLabel: '',
    onNext: null,
    onPrevious: null,
    hideNextButton: false,
    hidePreviousButton: false,
    disableNext: false,
    disablePrevious: false,
    customNextButtonFunction: null,
    isControlled: false,
  }

  state = {
    loading: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.active && this.props.active && prevState.loading) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ loading: false })
    }
  }

  handleNextClick = () =>
    this.handleClick(
      this.props.onNext,
      !this.props.errorMsg
        ? this.props.nextStep
        : setTimeout(() => {
            this.setState({ loading: false })
          }, 500)
    )

  handlePreviousClick = () =>
    this.handleClick(this.props.onPrevious, this.props.previousStep)

  handleClick = (customCallback, defaultCallback) =>
    this.setState({ loading: true }, () => {
      const callback = this.props.isControlled
        ? Promise.resolve(customCallback())
        : Promise.resolve(customCallback && customCallback()).then(
            defaultCallback
          )
      return callback.catch(() => {
        this.setState({ loading: false })
      })
    })

  render() {
    const {
      children,
      errorMsg,
      step,
      summaryStep,
      horizontal,
      horizontalStepLabel,
      active,
      label,
      complete,
      disablePrevious,
      disableNext,
      nextButtonLabel,
      previousButtonLabel,
      hideNextButton,
      hidePreviousButton,
      customNextButtonFunction,
      t,
    } = this.props
    const { loading } = this.state

    const circleContent = complete ? <Icon.Check /> : step
    const stepContent = active ? (
      children
    ) : (
      <div style={{ display: 'none' }}>Inactive step</div>
    )
    const stepIndicatorClass = `${styles.step__indicator} ${
      active || complete ? styles['step__indicator--highlight'] : ''
    }`

    const stepBody = (
      <div className={styles.step__body} style={hideElement(!active)}>
        <div className={styles.step__content}>{stepContent}</div>
        {errorMsg && (
          <TextBlock warn className={styles.error}>
            {errorMsg}
          </TextBlock>
        )}
        <ButtonBlock>
          <Button.Accent
            disabled={loading || disableNext}
            onClick={customNextButtonFunction || this.handleNextClick}
            style={hideElement(hideNextButton || summaryStep)}
          >
            {t(nextButtonLabel)}
          </Button.Accent>
          <Button.Secondary
            disabled={loading || disablePrevious}
            onClick={this.handlePreviousClick}
            style={hideElement(hidePreviousButton)}
          >
            {previousButtonLabel || (step === 1 ? t('Cancel') : t('Back'))}
          </Button.Secondary>
        </ButtonBlock>
      </div>
    )

    if (horizontalStepLabel && summaryStep) return null
    if (horizontalStepLabel) {
      return (
        <div className={stepIndicatorClass}>
          <div className={styles.step__indicator__circle}>{circleContent}</div>
          <div className={styles.step__indicator__label}>{label}</div>
        </div>
      )
    }
    if (horizontal) return <div>{stepBody}</div>
    return (
      <section>
        <div className={stepIndicatorClass}>
          <div className={styles.step__indicator__circle}>{circleContent}</div>
          <div className={styles.step__indicator__label}>{label}</div>
        </div>
        {stepBody}
      </section>
    )
  }
}

export default withTranslation()(Step)
