export const getDateRange = (date, view) => {
  const startDate = date.clone()
  const endDate = date.clone().endOf('day')
  let datesInView = 1

  if (view === 'month') {
    startDate.startOf('month').startOf('week')
    endDate.endOf('month').endOf('week')
    datesInView = endDate.diff(startDate, 'days', true)
  }
  if (view === 'week') {
    startDate.startOf('week')
    endDate.endOf('week')
    datesInView = endDate.diff(startDate, 'days', true)
  }

  const dateRange = new Array(Math.ceil(datesInView)).fill().map((_, i) => {
    const calendarDate = startDate.clone().add(i, 'days')
    return {
      date: calendarDate,
      overflow: view === 'month' && !date.isSame(calendarDate, 'month'),
    }
  })

  return {
    startDate,
    endDate,
    dateRange,
  }
}
