import { useContext, useEffect } from 'react'
import { useCurrent, useAutoTable, useFilters } from 'contexts'
import { useBackgroundRequest } from 'hooks'
import { areAllFiltersValid } from 'components/DataTable/TableFilters/utils'
import { QualityControlReportsContext } from './QualityControlReportsContext'

const StandardReportTable = ({ reportType, dateRange, turfId }) => {
  const {
    filterRules,
    isLoading: filterLoading,
    errorMsg: filterErrorMsg,
  } = useFilters()
  const { renderTable } = useAutoTable()
  const { currentTurfPerformsExternalQC } = useCurrent()
  const { setCurrentReportType, reportData, requestReportId, requestReport } =
    useContext(QualityControlReportsContext)

  const {
    makeRequest: reportReq,
    isLoading: isReportReqLoading,
    errorMsg: reportReqErrorMsg,
  } = useBackgroundRequest({
    key: reportType.id,
    requestId: type =>
      requestReportId({
        startDate: dateRange.start,
        endDate: dateRange.end,
        reportType: type,
        turfId,
        currentTurfPerformsExternalQC,
      }),
    requestData: requestReport,
  })

  useEffect(() => {
    setCurrentReportType(reportType.id)
  }, [reportType.id])

  useEffect(() => {
    reportReq()
  }, [dateRange, turfId, reportType])

  if (!areAllFiltersValid(filterRules, reportType.columns)) {
    return null
  }

  return renderTable({
    data: reportData.data ? reportData.data : reportData,
    sqlQuery: reportData.sqlQuery ? reportData.sqlQuery : '',
    loading: isReportReqLoading || filterLoading,
    error: reportReqErrorMsg || filterErrorMsg,
    columns: reportType.columns,
    rowKey: reportType.rowKey || 'id',
    showTotals: reportType.showTotals ?? true,
    fetchAction: reportReq,
    freezeLeftColumns: reportType.freezeLeftColumns || 1,
  })
}

export default StandardReportTable
