import React from 'react'
import { Switch, Route } from 'react-router-dom'
import UserSelect from '../UserSelect/UserSelect'
import UserCreate from '../UserCreate/UserCreate'
import UserEdit from '../UserEdit/UserEdit'

const UserRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={UserSelect} />
    <Route exact path={`${match.path}/new`} component={UserCreate} />
    <Route exact path={`${match.path}/:id/edit`} component={UserEdit} />
  </Switch>
)

export default UserRoutes
