import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Sheet } from 'components'
import { PageHeader } from '@politechdev/blocks-design-system'
import DeliveryForm from '../DeliveryForm/DeliveryForm'

const DeliveryCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <PageHeader title={t('New delivery')} />
          <DeliveryForm />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default DeliveryCreate
