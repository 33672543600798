import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchPhoneBankReport = (reportType, params) => {
  const queryParams = {
    ...params,
    type: reportType,
  }
  return fetchJSON(
    `/api/v1/reports/phone_banking?${stringifyParams(queryParams)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
}
