import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchLists = params =>
  fetchJSON(`/api/v1/lists?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchList = (id, params) =>
  fetchJSON(`/api/v1/lists/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postList = (list, params) =>
  fetchJSON(
    `/api/v1/lists?${stringifyParams(params)}`,
    'POST',
    { list },
    { useJwt: true }
  )

export const putList = (id, listParams, existingList, refresh) =>
  fetchJSON(
    `/api/v1/lists/${id}`,
    'PUT',
    { list: { ...existingList, ...listParams }, refresh },
    { useJwt: true }
  )

export const moveLists = async (lists, folderId) => {
  const requests = lists.map(list =>
    putList(
      list.id,
      {
        list_folder_id: folderId,
      },
      list
    )
  )

  await Promise.all(requests)
}

export const destroyList = id =>
  fetchJSON(`/api/v1/lists/${id}`, 'DELETE', null, { useJwt: true })

export const fetchMembers = (id, params = {}, signal) =>
  fetchJSON(
    `/api/v1/lists/${id}/people?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    },
    { signal }
  )

export const fetchPreview = (params, signal) =>
  fetchJSON(
    `/api/v1/lists/preview`,
    'POST',
    params,
    { useJwt: true },
    { signal }
  )

export const postInvites = (id, eventId) =>
  fetchJSON(
    `/api/v1/lists/${id}/invite`,
    'POST',
    {
      event_id: eventId,
    },
    { useJwt: true }
  )

export const postCopy = id =>
  fetchJSON(`/api/v1/lists/${id}/copy`, 'POST', null, { useJwt: true })

export const postEmail = (id, email) =>
  fetchJSON(`/api/v1/lists/${id}/email`, 'POST', { email }, { useJwt: true })

export const postSms = (id, message) =>
  fetchJSON(`/api/v1/lists/${id}/sms`, 'POST', { message }, { useJwt: true })

export const fetchListFolders = params => {
  const queryString = stringifyParams(params)

  return fetchJSON(`/api/v1/lists/folders?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchListFolder = (id, params) => {
  const queryString = stringifyParams(params)

  return fetchJSON(`/api/v1/lists/folders/${id}?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const postListFolder = list_folder =>
  fetchJSON(
    `/api/v1/lists/folders?${stringifyParams({
      fields: ['id', 'name', { lists: 'name' }, { sharees: 'id' }],
    })}`,
    'POST',
    { list_folder },
    { useJwt: true }
  )

export const putListFolder = (id, folder) =>
  fetchJSON(
    `/api/v1/lists/folders/${id}`,
    'PUT',
    {
      list_folder: folder,
    },
    { useJwt: true }
  )

export const destroyListFolder = id =>
  fetchJSON(`/api/v1/lists/folders/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const postListSalsaExport = id =>
  fetchJSON(`/api/v1/lists/${id}/export/salsa`, 'POST', null, { useJwt: true })
