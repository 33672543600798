import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'components'
import {
  Section,
  TextBlock,
  ButtonBlock,
  Font,
  PageHeader,
} from '@politechdev/blocks-design-system'
import CallMenu from 'phone_banks/CallMenu/CallMenu'
import { useCurrent } from 'contexts/index'
import TestMicrophone from './TestMicrophone/TestMicrophone'
import TestAudio from './TestAudio/TestAudio'

const PowerDialerAgentOnboarding = () => {
  const { t } = useTranslation()
  const [hasTestedMic, setHasTestedMic] = useState(false)
  const [hasTestedAudio, setHasTestedAudio] = useState(false)
  const {
    currentTenant: { name },
  } = useCurrent()

  return (
    <View>
      <PageHeader title={t('Before you begin as a phone bank agent')} />
      <Section label={t('Thank you for volunteering your time')}>
        <TextBlock Element="p">
          {t(
            'Please read the following guidelines before starting your session.'
          )}
        </TextBlock>
      </Section>
      <Section label={t('Running a phone bank session')}>
        <TextBlock Element="p">
          {t(
            'Thank you for helping us reach out to voters! Phone banking is an efficient and effective way to communicate with voters about the upcoming election and what’s at stake. When you are ready to take a call you will be patched to a voter through a voice call to your browser. Be sure to have working audio and mic connected. A script will be displayed for you to read out to the person on the other line. Mark down the answers and submit a response to continue with the next voter. You can pause your session at any moment in between calls.'
          )}
        </TextBlock>
      </Section>
      <Section label={t('General etiquette')}>
        <TextBlock Element="p">
          {t(
            'When talking to a voter make sure you are kind, courteous, and understanding. The conversations that you have with voters will go with them into the polls and can be the deciding factor on whether or not they vote for our candidate. With that in mind, make sure to keep all conversations positive, and to never get into fights with someone on the other line.'
          )}
        </TextBlock>
      </Section>
      <Section label={t('Privacy policy')}>
        <TextBlock Element="p">
          {`${t(
            'Do not record or share any voter’s contact information. Any data captured during the call is property of'
          )}`}{' '}
          <strong>{name.toUpperCase()}</strong>{' '}
          {`${t(
            'and results of calls may not be shared outside of the calling platform.'
          )}
          `}
        </TextBlock>
      </Section>
      <Section label={t('Test your audio before starting')}>
        <ButtonBlock>
          <TestMicrophone
            onClick={() => {
              setHasTestedMic(true)
            }}
          />
          <TestAudio onClick={() => setHasTestedAudio(true)} />
        </ButtonBlock>
      </Section>
      <Section>
        <CallMenu
          callType="power_dialer"
          disabled={!hasTestedMic || !hasTestedAudio}
        />
        {(!hasTestedMic || !hasTestedAudio) && (
          <TextBlock collapsed>
            <Font.Copy variant="hint">
              {t('You must test your microphone and audio before continuing')}
            </Font.Copy>
          </TextBlock>
        )}
      </Section>
    </View>
  )
}

export default PowerDialerAgentOnboarding
