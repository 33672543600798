/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
  isValidElement,
} from 'react'
import PropTypes from 'prop-types'
import { AutoTable } from 'components'
import {
  QueryParamProvider,
  PaginationProvider,
  FilterProvider,
  SortProvider,
  useSort,
  useFilters,
  usePagination,
  useQueryParams,
} from 'contexts'
import { useRequest, useEvent } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'

const AutoTableContext = createContext()

const TableProvider = ({
  tableId,
  setTableId,
  children,
  showFullTextQuickSearch,
  ...props
}) => {
  const [tableColumns, setTableColumns] = useState([])

  const dataColumns = useMemo(
    () => tableColumns.filter(col => !isValidElement(col) && !col.actions),
    [tableColumns]
  )

  const {
    queryParams: { searchValue },
    setQueryParams,
  } = useQueryParams()

  const setSearchValue = newSearchValue => {
    setQueryParams({ searchValue: newSearchValue })
  }

  const quickSearchColumn = dataColumns?.[0] || {}
  const quickSearchFilterRule = input => {
    if (!input || input === '') return null

    if (showFullTextQuickSearch) {
      return {
        column: 'search',
        param: [input],
      }
    }

    return quickSearchColumn.filterConstructor
      ? quickSearchColumn.filterConstructor({
          column: quickSearchColumn.dataKey,
          operator: 'containing',
          param: input,
        })
      : {
          column: quickSearchColumn.dataKey,
          operator: 'containing',
          param: input,
        }
  }

  const [isDownloading, setDownloading] = useState(false)
  const startDownloading = () => setDownloading(true)
  const finishDownloading = () => setDownloading(false)

  const { pageSize, page } = usePagination(props.enablePagination)
  const { filterConjunction, filterRules } = useFilters(props.enableFilters)
  const { sortColumn, sortOrder } = useSort(props.enableSort)

  const params = useRef({
    pageSize,
    page,
    filterConjunction,
    filterRules,
    sortColumn,
    sortOrder,
  })

  const preparedFilterRules = filterRules
    ? filterRules.map(filterRule => {
        const column = dataColumns.find(
          column => column.dataKey === filterRule.column
        )

        return column?.filterConstructor
          ? column.filterConstructor(filterRule)
          : filterRule
      })
    : []

  useEffect(() => {
    params.current = {
      pageSize,
      page,
      filterConjunction,
      filterRules,
      sortColumn,
      sortOrder,
    }
  }, [pageSize, page, filterConjunction, filterRules, sortColumn, sortOrder])

  const [tableRequest, setTableRequest] = useState(() => () => {})

  const { makeRequest, isLoading, errors, hasErrors } = useRequest(tableRequest)
  const errorMsg = hasErrors ? formatErrorMessage(errors) : ''

  const makeTableRequest = useEvent((input, options = {}) => {
    const rules = [
      ...preparedFilterRules,
      quickSearchFilterRule(input ?? searchValue),
    ].filter(Boolean)

    const conjunction =
      rules.length <= 1 || filterConjunction ? filterConjunction : 'exclusive'

    return makeRequest({
      ...params.current,
      filterRules: rules,
      filterConjunction: conjunction,
      ...options,
    })
  })

  const refreshPage = () => makeTableRequest()

  const requestPage = (options = {}) => makeTableRequest(null, options)

  const renderTable = ({ loading, error, ...instanceProps }) => (
    <AutoTable
      {...props}
      showFullTextQuickSearch={showFullTextQuickSearch}
      {...instanceProps}
      instanceLoading={loading}
      instanceError={error}
    />
  )

  return (
    <AutoTableContext.Provider
      value={{
        tableId,
        setTableId,
        renderTable,
        requestPage,
        refreshPage,
        tableRequest: makeTableRequest,
        isLoading,
        errorMsg,
        setTableRequest,
        isAutoTable: true,
        isDownloading,
        startDownloading,
        finishDownloading,
        setTableColumns,
        searchValue,
        setSearchValue,
      }}
    >
      {children}
    </AutoTableContext.Provider>
  )
}

export const AutoTableProvider = ({ initialTableId, children, ...props }) => {
  const [tableId, setTableId] = useState(initialTableId)

  const providers = []
  if (props.enablePagination) providers.push(PaginationProvider)
  if (props.enableFilters) providers.push(FilterProvider)
  if (props.enableSort) providers.push(SortProvider)

  return (
    <QueryParamProvider {...props}>
      {providers.reverse().reduce(
        (acc, Provider) => (
          <Provider tableId={tableId} {...props}>
            {acc}
          </Provider>
        ),
        <TableProvider tableId={tableId} setTableId={setTableId} {...props}>
          {children}
        </TableProvider>
      )}
    </QueryParamProvider>
  )
}

AutoTableProvider.propTypes = {
  initialTableId: PropTypes.string,
  persistSelectedFilter: PropTypes.bool,
  isFrontend: PropTypes.bool,
  enableQueryParams: PropTypes.bool,
  enablePagination: PropTypes.bool,
  enableFilters: PropTypes.bool,
  enableSort: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  showFilterEditor: PropTypes.bool,
  showQuickSearch: PropTypes.bool,
  showFullTextQuickSearch: PropTypes.bool,
  showTableLink: PropTypes.bool,
  showTotals: PropTypes.bool,
  freezeLeftColumns: PropTypes.number,
  freezeRightColumns: PropTypes.number,
}

AutoTableProvider.defaultProps = {
  initialTableId: '',
  persistSelectedFilter: false,
  isFrontend: false,
  enableQueryParams: false,
  enablePagination: false,
  enableFilters: false,
  enableSort: false,
  showDownloadButton: false,
  showFilterEditor: false,
  showQuickSearch: false,
  showTableLink: false,
  showTotals: false,
  freezeLeftColumns: 0,
  freezeRightColumns: 0,
}

export const useAutoTable = () => {
  const context = useContext(AutoTableContext)
  if (context === undefined) {
    return { isAutoTable: false }
  }
  return context
}
