import { useEffect, useState } from 'react'
import { useRouteQueryParams } from 'hooks'

export const withReloadableSession = Component => props => {
  const [{ sessionId }] = useRouteQueryParams()
  const [key, setKey] = useState()

  useEffect(() => {
    if (sessionId !== key) {
      setKey(sessionId)
    }
  }, [sessionId])

  return <Component key={key} {...props} />
}
