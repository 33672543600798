import { buildDefaultFilters } from 'utils/reporting'
import baseQcSummaryColumns from '../columns/baseQcSummaryColumns'

export default {
  id: 'qc_summary_by_user',
  name: 'Individual QC Staff Work',
  description:
    'This report provides information on quality control shifts, totals and rates for individual QC staff members.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1486695993',
  isTimeline: true,
  nonTimelineColumns: [
    'first_name',
    'last_name',
    'role_name',
    'qc_office',
    'email',
    'user_id',
  ],
  totalOnlyKeys: [
    'total_no_phone_scans_viz_day',
    'total_no_phone_scans_viz_week',
    'total_no_phone_scans_viz_all_time',
  ],
  aggregateKeys: ['email'],
  columns: [
    {
      dataKey: 'user_id',
      title: 'Blocks user ID',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'staff_name',
      title: 'Staff name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        [rowData.first_name, rowData.last_name].join(' '),
    },
    ...baseQcSummaryColumns,
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Day',
        visibleColumns: [
          'staff_name',
          'role_name',
          'qc_office',
          'total_shifts_day',
          'total_hours_day',
          'avg_shift_length_day',
          'total_scans_viz_day',
          'total_scans_phone_day',
          'viz_qc_rate_day',
          'phone_qc_rate_day',
          'total_qc_rate_day',
          'user_id',
        ],
      },
      {
        title: 'Week',
        visibleColumns: [
          'staff_name',
          'role_name',
          'qc_office',
          'total_shifts_week',
          'total_hours_week',
          'avg_shift_length_week',
          'total_scans_viz_week',
          'total_scans_phone_week',
          'viz_qc_rate_week',
          'phone_qc_rate_week',
          'total_qc_rate_week',
          'user_id',
        ],
      },
      {
        title: 'All time',
        visibleColumns: [
          'staff_name',
          'role_name',
          'qc_office',
          'total_shifts_all_time',
          'total_hours_all_time',
          'avg_shift_length_all_time',
          'total_scans_viz_all_time',
          'total_scans_phone_all_time',
          'viz_qc_rate_all_time',
          'phone_qc_rate_all_time',
          'total_qc_rate_all_time',
          'user_id',
        ],
      },
    ])
  },
}
