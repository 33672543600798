import { useContext, useEffect, useState } from 'react'
import { Sheet, TextBlock, Font } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import styles from './TemplateColorCard.module.scss'
import { TemplateContext } from '../TemplateContext/TemplateContext'
import { THEME_OPTIONS } from '../TemplateStyle/constants'

const TemplateColorCard = ({ type }) => {
  const { t } = useTranslation()
  const { updateColor, colors, updateTheme } = useContext(TemplateContext)
  const [localColor, setLocalColor] = useState(colors[type])
  const [debouncedColor] = useDebounce(localColor, 0)

  useEffect(() => {
    updateColor(type, debouncedColor)
    updateTheme(THEME_OPTIONS.CUSTOM, {})
  }, [debouncedColor])

  return (
    <div className={styles.card}>
      <Sheet>
        <div className={styles.card__title}>
          <Font.Label>{`${t(type)} color`}</Font.Label>
        </div>
        <TextBlock className={styles.card__content}>
          <div className={styles.card__content__container}>
            <div className={styles['card__content__color-label']}>
              {colors[type]}
            </div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              style={{ backgroundColor: `${colors[type]}` }}
              className={styles['card__content__color-input']}
            >
              <input
                onChange={e => {
                  setLocalColor(e.target.value)
                }}
                type="color"
              />
            </label>
          </div>
        </TextBlock>
      </Sheet>
    </div>
  )
}

export default TemplateColorCard
