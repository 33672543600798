import React from 'react'
import { PdfViewer } from 'components'
import { useTranslation } from 'react-i18next'
import { Font, Button, Icon } from '@politechdev/blocks-design-system'
import styles from '../ShiftDataEntry.module.scss'

function DocumentHeader({ file, page, documentName }) {
  const { t } = useTranslation()
  return (
    <div className={styles['pdf-header']}>
      <Font.Copy variant="highlight">
        {page === 0 ? t('Cover sheet') : t(`${documentName}`)}
      </Font.Copy>
      <a href={file} target="_blank" rel="noopener noreferrer">
        <Button.Secondary>
          <Icon.ExternalLink aria-label="open-pdf" />
        </Button.Secondary>
      </a>
    </div>
  )
}

function DocumentViewer({ fileUrl, page, totalPages, documentName }) {
  const { t } = useTranslation()
  if (!fileUrl) {
    return (
      <div className={styles['pdf-header']}>
        <Font.Copy variant="highlight">
          {page === 0 ? t('Cover sheet') : t(`${documentName}`)}
        </Font.Copy>
      </div>
    )
  }
  return (
    <PdfViewer
      file={fileUrl}
      CustomHeader={DocumentHeader}
      headerProps={{ file: fileUrl, page, totalPages, documentName }}
    />
  )
}

export default DocumentViewer
