import { orderBy } from 'lodash'

export const personFields = {
  fields: [
    'id',
    { user: ['name', 'id', 'email'] },
    'position',
    'interest_level',
    'issues',
    'skills',
    { meetings: ['id', 'start_time', { user: ['name'] }] },
    {
      organization_memberships: [
        'id',
        'organization_id',
        { organization: ['id', 'name'] },
      ],
    },
    { campaigns: ['id', 'name'] },
    {
      team_memberships: ['id', 'responsibility', { team: ['id', 'name'] }],
    },
    'versions',
  ],
}

export const getPositionHistory = person => {
  let prevPosition = null
  const versions = orderBy(person.versions, 'created_at', 'asc')
  const versionsWithChanges = versions.filter(version => {
    const position = version.object ?? {}

    if (prevPosition !== position) {
      prevPosition = position
      return true
    }

    return false
  })
  versionsWithChanges.push({
    object: { position: person.position },
  })

  const mutatedForDateAdded = []
  let previousDate = null
  versionsWithChanges.forEach(version => {
    const { position } = version.object ?? {}
    const created_at = previousDate

    if (position) {
      mutatedForDateAdded.push({ position, created_at })
    }

    previousDate = version.created_at
  })

  return mutatedForDateAdded
}
