import React, { useContext } from 'react'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { SummaryPacketsCheckboxGroupContext } from './CheckboxContextProvider'

const AssignmentButtons = ({ onReassignClick }) => {
  const { t } = useTranslation()
  const { getCheckedCheckboxValues, checkAll, areAllChecked, uncheckAll } =
    useContext(SummaryPacketsCheckboxGroupContext)

  const areNoPacketsSelected = getCheckedCheckboxValues().length === 0
  const handleSelection = areAllChecked() ? uncheckAll : checkAll
  const selectButtonText = areAllChecked() ? t('Clear all') : t('Select all')

  return (
    <div>
      <ButtonBlock>
        <Button className="no-margin-left" onClick={handleSelection}>
          {selectButtonText}
        </Button>
        <Button
          disabled={areNoPacketsSelected}
          onClick={() => {
            // reassigning
            onReassignClick(getCheckedCheckboxValues())
          }}
        >
          {t('Reassign selected')}
        </Button>
      </ButtonBlock>
    </div>
  )
}

export default AssignmentButtons
