import { useTranslation } from 'react-i18next'
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md'
import { Checkbox, CardError } from 'components'
import {
  Button,
  TextBlock,
  ButtonBlock,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'

const AddGuestModal = ({
  guests,
  removeGuest,
  isWalkIn,
  setWalkIn,
  addAttendeesError,
}) => {
  const { t } = useTranslation()

  if (!guests.length) {
    return (
      <TextBlock>
        <Font.Copy variant="hint">{t('None selected')}</Font.Copy>
      </TextBlock>
    )
  }

  return (
    <div>
      <DataTable plain baseId="selected-guests">
        <TableHeader>
          <TableRow>
            <TableColumn role="button">{t('Name')}</TableColumn>
            <TableColumn role="button">{t('Email')}</TableColumn>
            <TableColumn role="button">{t('Phone')}</TableColumn>
            <TableColumn />
          </TableRow>
        </TableHeader>
        <TableBody>
          {guests.map((person, i) => (
            <TableRow key={`person-${i}`}>
              <TableColumn>{person.name}</TableColumn>
              <TableColumn>{person.primary_email_address}</TableColumn>
              <TableColumn>{person.primary_phone_number}</TableColumn>
              <TableColumn>
                <ButtonBlock>
                  <Button.Secondary
                    aria-label="clear"
                    onClick={() => {
                      removeGuest(i)
                    }}
                  >
                    <Icon.Times />
                  </Button.Secondary>
                </ButtonBlock>
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </DataTable>
      <Checkbox
        id="walkIn"
        name="walkIn"
        value="walkIn"
        label={t('Mark as walk-in guest')}
        checked={isWalkIn}
        onChange={setWalkIn}
      />
      <CardError
        hide={!addAttendeesError}
        message={t(
          'An internal error occurred while adding guests to this event'
        )}
      />
    </div>
  )
}

export default AddGuestModal
