import { fetchJSON, stringifyParams } from 'utils/req'
import {
  defaultPhoneBankOptions,
  detailedPhoneBankOptions,
  detailedScriptOptions,
  scriptQuestionParams,
} from 'constants/phoneBanks'

export const copyPhoneBank = (sourcePhoneBankId, params) =>
  fetchJSON(
    `/api/v1/phone_banks/${sourcePhoneBankId}/copy?${stringifyParams(params)}`,
    'POST',
    null,
    {
      useJwt: true,
    }
  )

export const fetchPhoneBanks = params => {
  const queryString = stringifyParams({ ...defaultPhoneBankOptions, ...params })

  return fetchJSON(`/api/v1/phone_banks?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchPhoneBank = id => {
  const queryString = stringifyParams(detailedPhoneBankOptions)

  return fetchJSON(`/api/v1/phone_banks/${id}/?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchPhoneBankCalls = params =>
  fetchJSON(
    `/api/v1/phone_bank_calls?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchPhoneBankSessions = params =>
  fetchJSON(
    `/api/v1/phone_bank_sessions?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const postPhoneBank = phoneBank =>
  fetchJSON(
    `/api/v1/phone_banks?${stringifyParams(detailedPhoneBankOptions)}`,
    'POST',
    { phone_bank: phoneBank },
    { useJwt: true }
  )

export const postScript = newScript =>
  fetchJSON(
    `/api/v1/scripts?${stringifyParams(detailedScriptOptions)}`,
    'POST',
    { script: newScript },
    { useJwt: true }
  )

export const postScriptText = attrs =>
  fetchJSON(
    `/api/v1/script_texts?${stringifyParams({ fields: ['id', 'type'] })}`,
    'POST',
    { script_text: attrs },
    { useJwt: true }
  )

export const postScriptQuestion = attrs =>
  fetchJSON(
    `/api/v1/phone_banking_questions?${stringifyParams(scriptQuestionParams)}`,
    'POST',
    { question: attrs },
    { useJwt: true }
  )

export const createPhoneBank = async data => {
  const { 'phone_banking/script': script } = await postScript({
    cause_statement: '',
  })
  const response = await postPhoneBank({ ...data, script_id: script.id })
  return response
}

export const putPhoneBank = (id, phoneBank) =>
  fetchJSON(
    `/api/v1/phone_banks/${id}?${stringifyParams(detailedPhoneBankOptions)}`,
    'PUT',
    { phone_bank: phoneBank },
    { useJwt: true }
  )

export const putScript = (id, attrs) => {
  const queryString = stringifyParams(detailedScriptOptions)
  return fetchJSON(
    `/api/v1/scripts/${id}?${queryString}`,
    'PUT',
    { script: attrs },
    { useJwt: true }
  )
}

export const putScriptText = (id, attrs) =>
  fetchJSON(
    `/api/v1/script_texts/${id}`,
    'PUT',
    { script_text: attrs },
    { useJwt: true }
  )

export const putScriptQuestion = (id, attrs) =>
  fetchJSON(
    `/api/v1/phone_banking_questions/${id}?${stringifyParams(
      scriptQuestionParams
    )}`,
    'PUT',
    { question: attrs },
    { useJwt: true }
  )

export const deletePhoneBank = id =>
  fetchJSON(`/api/v1/phone_banks/${id}`, 'DELETE', null, { useJwt: true })

export const advancePhoneBankRound = id =>
  fetchJSON(`/api/v1/phone_banks/${id}/advance_round`, 'PATCH', null, {
    useJwt: true,
  })
