import { useTranslation } from 'react-i18next'
import { Font } from '@politechdev/blocks-design-system'
import { PdfViewer } from 'components'
import styles from './PledgeCardPdfViewer.module.scss'

const PledgeCardPdfViewer = ({ scanUrl, scanNum, ...props }) => {
  const { t } = useTranslation()
  return scanUrl ? (
    <PdfViewer
      className={styles.viewer}
      file={scanUrl}
      documentLoadingMessage={t('Scan loading...')}
      {...props}
    />
  ) : (
    <div className={styles['missing-pledge__container']}>
      <div className={styles['missing-pledge__content']}>
        <Font.Copy variant="highlight">{t('No pledge card')}</Font.Copy>
      </div>
    </div>
  )
}

export default PledgeCardPdfViewer
