import { calcProjectedTotal } from 'utils/fieldManagement'

export const formatProjectionSummaryData = ({
  weeklyActualData,
  weeklyProjectedData,
}) => {
  const currentProgress = weeklyActualData.concat(
    weeklyProjectedData.slice(weeklyActualData.length)
  )
  if (currentProgress.length !== 0) {
    let projectedWithActualFormsCollected = 0
    let projectedFormsCollected = 0
    let avgCanvassersPerDay = 0
    let avgDaysSpentCanvassing = 0
    let avgHoursSpentCanvassingDaily = 0
    let avgFormsPerHour = 0
    weeklyProjectedData.forEach(element => {
      projectedFormsCollected += calcProjectedTotal(element)
    })
    currentProgress.forEach(element => {
      projectedWithActualFormsCollected += calcProjectedTotal(element)
      avgCanvassersPerDay += element.dailyCanvassers
      avgDaysSpentCanvassing += element.daysCanvassingPerWeek
      avgHoursSpentCanvassingDaily += element.averageFieldHours
      avgFormsPerHour += element.formsPerHour
    })
    projectedWithActualFormsCollected = Math.floor(
      projectedWithActualFormsCollected
    )
    projectedFormsCollected = Math.floor(projectedFormsCollected)
    const delta = projectedWithActualFormsCollected - projectedFormsCollected
    avgCanvassersPerDay = Math.round(
      avgCanvassersPerDay / currentProgress.length
    )
    avgDaysSpentCanvassing = Math.round(
      avgDaysSpentCanvassing / currentProgress.length
    )
    avgHoursSpentCanvassingDaily = parseFloat(
      (avgHoursSpentCanvassingDaily / currentProgress.length).toFixed(1)
    )
    avgFormsPerHour = Math.round(avgFormsPerHour / currentProgress.length)
    return {
      projectedWithActualFormsCollected,
      projectedFormsCollected,
      delta,
      avgCanvassersPerDay,
      avgDaysSpentCanvassing,
      avgHoursSpentCanvassingDaily,
      avgFormsPerHour,
    }
  }
  return {
    projectedWithActualFormsCollected: 0,
    projectedFormsCollected: 0,
    delta: 0,
    deltaNeg: false,
    avgCanvassersPerDay: 0,
    avgDaysSpentCanvassing: 0,
    avgHoursSpentCanvassingDaily: 0,
    avgFormsPerHour: 0,
  }
}
