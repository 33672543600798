import { fetchJSON } from 'utils/req'

export const createReportId = (reportType, params = {}) =>
  fetchJSON(
    `/api/v1/reports/voter_registration`,
    'POST',
    {
      report: {
        type: reportType,
        ...params,
      },
    },
    {
      useJwt: true,
    }
  )

export const fetchReportData = id =>
  fetchJSON(
    `/api/v1/reports/voter_registration/${id}`,
    'GET',
    null,
    {
      useJwt: true,
    },
    {
      showStatusCode: true,
    }
  )
