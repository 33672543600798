import { FieldBlock } from '@politechdev/blocks-design-system'
import {
  CanvasserFlagSwitchControl,
  CanvasserSelectControl,
  FlagTypeSelectControl,
  SortByFlagControl,
  TechnicalFlagSwitchControl,
  FlagIdInputControl,
  TurfSelectControl,
  NewCommentFilterControl,
  StartDatePickerControl,
  EndDatePickerControl,
} from './FilterControls'

export const FlagsReviewFilters = () => (
  <>
    <FieldBlock>
      <TechnicalFlagSwitchControl />
      <CanvasserFlagSwitchControl />
    </FieldBlock>
    <FieldBlock>
      <TurfSelectControl />
    </FieldBlock>
    <FieldBlock>
      <StartDatePickerControl />
      <EndDatePickerControl />
    </FieldBlock>
    <FieldBlock>
      <SortByFlagControl />
      <FlagTypeSelectControl />
      <FlagIdInputControl />
      <CanvasserSelectControl />
    </FieldBlock>
    <FieldBlock>
      <NewCommentFilterControl />
    </FieldBlock>
  </>
)
