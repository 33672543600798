import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, PersonSelectField } from 'components'
import {
  ButtonBlock,
  Modal,
  ContentBlock,
  Button,
} from '@politechdev/blocks-design-system'
import { useAutoTable } from 'contexts'
import { useRequest, useRoutePathParams } from 'hooks'
import { postOrganizationMembership } from 'requests/people'
import { formatErrorMessage } from 'utils/formatting'
import styles from './AddOrganizationMemberModal.module.scss'

const AddOrganizationMemberModal = ({ members }) => {
  const { t } = useTranslation()
  const { refreshPage } = useAutoTable()
  const [{ id: currentOrganizationId }] = useRoutePathParams()

  const [modalOpen, setModalOpen] = useState(false)
  const [person, setPerson] = useState(null)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setPerson(null)
  }

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(
    postOrganizationMembership,
    {
      onSuccess: () => {
        closeModal()
        refreshPage()
      },
    }
  )
  const errorMsg = formatErrorMessage(errors)

  const addMember = () => {
    makeRequest(person.id, {
      organization_id: currentOrganizationId,
    })
  }

  return (
    <>
      <Button.Accent onClick={openModal}>{t('Add member')}</Button.Accent>
      <Modal
        id="add-member"
        title={t(`Add member`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError hide={!hasErrors} message={errorMsg} hideSupportLink />
            <div className={styles.field}>
              <PersonSelectField
                person={person}
                onSelect={setPerson}
                filters={members.map(member => ({
                  column: 'id',
                  operator: 'is',
                  invert: true,
                  param: member.id,
                }))}
                clearable
              />
            </div>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent disabled={!person} onClick={addMember}>
              {t('Save')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AddOrganizationMemberModal
