import { useTranslation } from 'react-i18next'
import {
  Sheet,
  SelectField,
  FieldBlock,
} from '@politechdev/blocks-design-system'

const OrganizingReportsPicker = ({
  categoryId,
  changeReportCategory,
  reportCategories,
}) => {
  const { t } = useTranslation()
  return (
    <Sheet>
      <FieldBlock>
        <SelectField
          id="report-category"
          label={t('Report')}
          options={Object.values(reportCategories).map(category => ({
            value: category.id,
            label: t(category.name),
          }))}
          value={categoryId}
          onSelect={changeReportCategory}
        />
      </FieldBlock>
    </Sheet>
  )
}

export default OrganizingReportsPicker
