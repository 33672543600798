import { useTranslation } from 'react-i18next'
import { useReactRouter, useRoutePathParams } from 'hooks'
import { MenuButton, ButtonBlock } from '@politechdev/blocks-design-system'
import { READY_FOR_QC, IN_QC } from 'constants/qualityControl'
import { useCurrent } from 'contexts/index'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import styles from './CallMenu.module.scss'

const CallMenu = () => {
  const { t } = useTranslation()
  const [currentPacket] = useCurrentPacket()
  const {
    history: { push },
    location: { search },
  } = useReactRouter()
  const [{ type, flagId, originalType, packetId }] = useRoutePathParams()
  const {
    doesCurrentUserHavePermission,
    tenantOptions: { voip_enabled: hasVoip },
  } = useCurrent()
  const { inspectedUser } = useQualityControl()
  const canCreateNewCalls = doesCurrentUserHavePermission({
    resource: 'quality_control/phone_verification_call',
    ability: 'modify',
  })
  const goToSession = mode => {
    const baseRoute = `/quality_control/inbox/${currentPacket.id}/phone_verification_session/${mode}`
    let queryParameters = ''
    if (search) {
      queryParameters = `${search}`
    }
    if (type && flagId && originalType && packetId) {
      const sourceId = JSON.stringify([type, flagId, originalType, packetId])
      queryParameters += queryParameters
        ? `&source=flags&sourceId=${sourceId}`
        : `?source=flags&sourceId=${sourceId}`
    }
    const fullRoute = `${baseRoute}${queryParameters}`
    if (inspectedUser.id) {
      push(`${fullRoute}&inspectedUserId=${inspectedUser.id}`)
    } else {
      push(fullRoute)
    }
  }

  const callSessionDisabled =
    currentPacket.shift.status === READY_FOR_QC ||
    currentPacket.shift.status === IN_QC

  const callMenuOptions = [
    hasVoip
      ? {
          label: t('Call in the browser'),
          value: 'voip',
        }
      : undefined,
    {
      label: t('Call using your own phone'),
      value: 'external',
    },
  ].filter(i => i)

  return (
    <ButtonBlock justify="center">
      <div className={styles.hintContainer}>
        <MenuButton
          label={t('Begin Call Session')}
          disabled={callSessionDisabled || !canCreateNewCalls}
        >
          <MenuButton.Select
            onSelect={value => goToSession(value)}
            options={callMenuOptions}
          />
        </MenuButton>
        {callSessionDisabled && (
          <div className={styles.hint}>
            {t(
              'Visual QC must be completed on this packet before beginning phone verification'
            )}
          </div>
        )}
      </div>
    </ButtonBlock>
  )
}

export default CallMenu
