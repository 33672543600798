import { useContext } from 'react'
import { generateCheckboxGroupProvider } from 'providers/NaiveCheckboxGroupProvider'

export const [
  UserAssignedPacketsCheckboxGroupContext,
  UserAssignedPacketsCheckboxProvider,
] = generateCheckboxGroupProvider()

export const useAssignedCheckboxes = () =>
  useContext(UserAssignedPacketsCheckboxGroupContext)

export const [
  UserAssignablePacketsCheckboxGroupContext,
  UserAssignablePacketsCheckboxProvider,
] = generateCheckboxGroupProvider()

export const useAssignableCheckboxes = () =>
  useContext(UserAssignablePacketsCheckboxGroupContext)
