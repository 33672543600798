import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, TextField } from 'components'
import {
  Icon,
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { useEvent, useRequest } from 'hooks'
import { putListFolder } from 'requests/lists'
import styles from './FolderEditModal.module.scss'

const FolderEditModal = ({ folder, getListFolders }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [name, setName] = useState(folder.name)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(putListFolder, {
    onSuccess: async () => {
      await getListFolders()
      closeModal()
    },
  })

  const save = useEvent(() => {
    makeRequest(folder.id, { name })
  })

  return (
    <>
      <Button.Secondary aria-label={t('Rename')} onClick={openModal}>
        <Icon.Pencil alt={t('Rename')} />
      </Button.Secondary>
      <Modal
        id="folder-rename"
        title={t(`Rename folder`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error renaming your folder')}
              hideSupportLink
            />
            <div className={styles.field}>
              <TextField
                id="name"
                label={t('Name')}
                value={name}
                onChange={setName}
                required
              />
            </div>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent onClick={save} disabled={name === '' || isLoading}>
              {t('Save')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default FolderEditModal
