import { Button, Icon, Font } from '@politechdev/blocks-design-system'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RecordContext } from '../RecordContext/RecordContext'
import styles from './RecordPager.module.scss'

const RecordPager = () => {
  const { t } = useTranslation()
  const { importRes, row, setRow } = useContext(RecordContext)
  const count = importRes?.meta?.total_count

  useEffect(() => {
    if (row > count && count > 0) {
      setRow(row - 1)
    }
  }, [row, count])

  return (
    <div className={styles.pager}>
      <Button.Secondary
        disabled={row === 0 || row === 1}
        onClick={() => {
          setRow(prevRow => prevRow - 1)
        }}
      >
        <Icon.ChevronLeft />
      </Button.Secondary>
      <Font.Copy variant="hint">
        {count ? `${row} ${t('of')} ${count}` : t('Loading...')}
      </Font.Copy>
      <Button.Secondary
        disabled={row + 1 > count}
        onClick={() => {
          setRow(prevRow => prevRow + 1)
        }}
      >
        <Icon.ChevronRight />
      </Button.Secondary>
    </div>
  )
}

export default RecordPager
