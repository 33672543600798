/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadBar, CardError } from 'components'
import { TextBlock } from '@politechdev/blocks-design-system'
import { useParams } from 'react-router'
import SessionSidebar from 'phone_banks/call_session/SessionSidebar'
import { NaiveParticipantProfile } from 'phone_banks/call_session/active/NaiveParticipantProfile'
import CallSessionProvider, {
  useCallSessionContext,
} from 'phone_banks/components/CallSessionContext/CallSessionContext'
import {
  AgentContextProvider,
  AGENT_STATUS,
  useAgentActions,
  useAgentState,
} from '../AgentContext/AgentContext'
import CallPanel from './CallPanel'
import SessionResponses from './SessionResponses'
import ActiveMainPanel from './ActiveMainPanel'

const SessionActive = ({
  phoneBankComplete,
  handleResponseChange,
  participant,
  status,
  loading,
  error,
}) => {
  const { t } = useTranslation()

  if (loading) return <LoadBar show />

  if (error) {
    return (
      <CardError
        hide={false}
        message={t(
          'An error occurred fetching a number to call. Please refresh your browser and try again'
        )}
        hideSupportLink
      />
    )
  }

  if (phoneBankComplete) {
    return (
      <TextBlock>
        <h2>{t('Phone bank closed')}</h2>
        <p>
          {t(
            'All potential voters have been contacted, so the phone bank has ended.'
          )}
        </p>
        <p>
          {t(
            'Thank you for participating in this phone bank, your time and effort is greatly appreciated.'
          )}
        </p>
      </TextBlock>
    )
  }

  return (
    <div className="pb-session">
      <div className="pb-session__container--no-header">
        <SessionSidebar>
          <CallPanel />
          {status === AGENT_STATUS.ONCALL || status === AGENT_STATUS.REVIEW ? (
            <NaiveParticipantProfile
              participant={participant}
              disablePhoneSelect
            />
          ) : null}
          <SessionResponses />
        </SessionSidebar>
        <ActiveMainPanel handleResponseChange={handleResponseChange} />
      </div>
    </div>
  )
}

const SessionWithConsumer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const { phoneBankId, id: sessionId } = useParams()

  const { participant, status } = useAgentState()
  const {
    updateRadioResponse,
    updateOpenEndedResponse,
    updateCheckboxResponse,
  } = useAgentActions()

  const { fetchSession } = useCallSessionContext()

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        await fetchSession(phoneBankId, sessionId)
      } catch (e) {
        setError(e)
      }
      setIsLoading(false)
    }
    asyncEffect()
  }, [])

  const handleResponseChange = (questionId, response, type) => {
    if (type === 'single_choice') {
      updateRadioResponse({ questionId, response })
    } else if (type === 'multiple_choice') {
      updateCheckboxResponse({ questionId, response })
    } else if (type === 'open_ended') {
      updateOpenEndedResponse({ questionId, response })
    }
  }

  return (
    <SessionActive
      handleResponseChange={handleResponseChange}
      loading={isLoading}
      error={error}
      participant={participant}
      status={status}
    />
  )
}

export default () => (
  <AgentContextProvider>
    <CallSessionProvider.PowerDialer>
      <SessionWithConsumer />
    </CallSessionProvider.PowerDialer>
  </AgentContextProvider>
)
