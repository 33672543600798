import { calculateScanCount } from 'qualityControl/packetAssignment/staff/utils'
import moment from 'moment'

export const getScansAssignedOutOfShiftHours = (scanCount, shiftHours) => {
  const scans = scanCount === 1 ? 'scan' : 'scans'
  const hours = shiftHours === 1 ? 'shift hour' : 'shift hours'
  return `${scanCount} ${scans} assigned / ${shiftHours} ${hours}`
}

const emptyUser = {
  id: 0,
  full_name: '',
  avatar: '',
  role: { name: '', permissions: [] },
  spoken_language_locales: ['en'],
  turf: { states: [] },
  quality_control_schedules: [{ hours: 0 }],
  assigned_packets: [],
}

export const createStaffCardData = (user = emptyUser) => {
  const {
    id,
    email,
    name,
    role,
    time_zone,
    trained_in_states,
    training_status,
    spoken_language_locales,
    quality_control_schedules,
    assignable_packet_ids = [],
    assigned_packets,
  } = user

  const today = moment().format('YYYY-MM-DD')
  const shiftHours =
    (quality_control_schedules || []).reduce((minutes, schedule) => {
      if (schedule.date === today) return schedule.minutes
      return minutes
    }, 0) / 60

  const permissions = {
    visual:
      user.role.admin ||
      (
        user.role.permissions.find(
          permission => permission.resource === 'shift'
        )?.abilities || []
      ).includes('visual_qc'),
    phone:
      user.role.admin ||
      (
        user.role.permissions.find(
          permission => permission.resource === 'shift'
        )?.abilities || []
      ).includes('phone_verification'),
  }

  return {
    name,
    position: [user.qc_office, role.name].filter(Boolean).join(' – '),
    trainingStatus: training_status,
    trainedStates: trained_in_states,
    timezone: time_zone,
    shiftHours,
    staffId: id,
    staffEmail: email,
    languages: spoken_language_locales?.length
      ? spoken_language_locales
      : ['en'],
    permissions,
    assignablePacketIds: assignable_packet_ids,
    assignedScansCount: calculateScanCount(assigned_packets),
  }
}
