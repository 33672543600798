import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonBlock,
  Font,
  Checkbox,
  MenuButton,
  DialogBlock,
} from '@politechdev/blocks-design-system'
import { useFilters } from 'contexts'
import styles from './HideColumnControls.module.scss'

const HideColumnControls = ({ columns }) => {
  const { t } = useTranslation()
  const { onToggleColumn, hiddenColumns } = useFilters()
  const [isOpen, setOpen] = useState(false)
  const openMenu = () => setOpen(true)
  const closeMenu = () => setOpen(false)

  const columnKeys = new Set()
  columns.forEach(({ dataKey }) => columnKeys.add(dataKey))

  const totalCount = columns.length
  const showCount =
    totalCount - hiddenColumns.filter(column => columnKeys.has(column)).length

  return (
    <>
      <div className={styles.field__label}>
        <Font.Copy variant="hint">{t('Columns')}</Font.Copy>
      </div>
      <ButtonBlock>
        <MenuButton.Secondary
          isOpen={isOpen}
          onToggle={isOpen ? closeMenu : openMenu}
          label={`${t('Showing')} ${showCount} ${t('of')} ${totalCount}`}
        >
          <MenuButton.Dialog>
            <div className="table-filters__column-list__header">
              <p className="table-filters__helper-text">
                {t('Select columns')}
              </p>
            </div>
            <DialogBlock>
              {columns.map(({ dataKey, title }) => {
                const checked = !hiddenColumns.includes(dataKey)
                return (
                  <Checkbox
                    checked={checked}
                    id={dataKey}
                    name={dataKey}
                    label={t(title)}
                    key={dataKey}
                    onChange={() => onToggleColumn(dataKey, !checked)}
                  />
                )
              })}
            </DialogBlock>
            <DialogBlock row justify="end">
              <Button onClick={closeMenu}>{t('Done')}</Button>
            </DialogBlock>
          </MenuButton.Dialog>
        </MenuButton.Secondary>
      </ButtonBlock>
    </>
  )
}

HideColumnControls.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({ dataKey: PropTypes.string, title: PropTypes.string })
  ),
}

HideColumnControls.defaultProps = {
  columns: [],
}

export default HideColumnControls
