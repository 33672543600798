import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import countiesByState from 'utils/counties.json'
import {
  View,
  ViewContainer,
  Sheet,
  Section,
  DetailItem,
  FormattedData,
  ScrollToTop,
  LoadBar,
} from 'components'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { useRoutePathParams } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import { useCurrent } from 'contexts/index'
import RegistrationFormTable from './RegistrationFormTable'
import { fetchShift } from '../requests/shifts'
import ShiftSinglePacketViewer from './ShiftSinglePacketViewer'

const RegistrationPacketSingle = () => {
  const [{ shiftType, id: shiftId }] = useRoutePathParams()
  const [countyOptions, setCountyOptions] = useState([])
  const {
    currentTenant: { states },
  } = useCurrent()

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest,
    isRequestComplete: shiftLoaded,
    response,
  } = useRequest(fetchShift)

  const currentShift = response?.shift
  const { t } = useTranslation()

  useEffect(() => {
    makeRequest(shiftId, {
      fields: [
        {
          packet: [
            'id',
            'original_filename',
            {
              scans: [
                { form: ['display_name'] },
                'delivery_id',
                'id',
                'county',
                'scan_number',
              ],
            },
          ],
        },
        { turf: ['states'] },
        { canvasser: ['full_name'] },
        'shift_start',
      ],
    })
  }, [shiftId])

  useEffect(() => {
    if (states) {
      setCountyOptions(
        states.flatMap(state =>
          countiesByState[state].map(countyName => ({
            label: countyName,
            value: countyName,
          }))
        )
      )
    }
  }, [currentShift])

  return (
    <View>
      <ScrollToTop />
      <ViewContainer>
        {!shiftLoaded ? (
          <LoadBar show />
        ) : (
          <>
            <Sheet
              title={`Packet${
                currentShift.packet
                  ? ` ${currentShift.packet.original_filename}`
                  : ''
              }`}
              key="detail-sheet"
            >
              <div className="packet__details">
                <Section>
                  <DetailItem label={t('Canvasser')}>
                    {currentShift.canvasser.full_name}
                  </DetailItem>
                  <DetailItem label={t('Shift date')}>
                    <FormattedData
                      type="date"
                      value={currentShift.shift_start}
                    />
                  </DetailItem>
                </Section>
                <Section>
                  <DetailItem label={t('Forms assigned to delivery')}>
                    {
                      currentShift.packet.scans.filter(s => !!s.delivery_id)
                        .length
                    }
                  </DetailItem>
                  <DetailItem label={t('Total forms')}>
                    {currentShift.packet.scans.length}
                  </DetailItem>
                </Section>
              </div>
              <ButtonBlock justify="right">
                <Link to={`/collect/${shiftType}/shifts/${shiftId}/edit`}>
                  <Button>{t('View shift')}</Button>
                </Link>
              </ButtonBlock>
            </Sheet>
            <RegistrationFormTable
              scans={currentShift.packet.scans}
              countyOptions={countyOptions}
              loading={!shiftLoaded}
              key="reg-forms"
            />
            <Sheet>
              <ShiftSinglePacketViewer packetId={currentShift.packet.id} />
            </Sheet>
          </>
        )}
      </ViewContainer>
    </View>
  )
}

export default RegistrationPacketSingle
