import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Font } from '@politechdev/blocks-design-system'
import { ManagementContext } from 'checkins/CheckinContext/ManagementContext'
import classNames from 'classnames/bind'
import styles from './SchedulePicker.module.scss'

const cx = classNames.bind(styles)

const SchedulePicker = () => {
  const { t } = useTranslation()
  const { newCheckinPreview, previewNewCheckIn } = useContext(ManagementContext)
  const dayStrings = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const handleScheduleChange = id => {
    newCheckinPreview.days_of_the_week.includes(id)
      ? previewNewCheckIn({
          days_of_the_week: newCheckinPreview.days_of_the_week.filter(
            filterDay => filterDay !== id
          ),
        })
      : previewNewCheckIn({
          days_of_the_week: [...newCheckinPreview.days_of_the_week, id],
        })
  }

  return (
    <div className={styles['schedule-picker']}>
      <Font.Copy variant="hint">{t('Days collecting answers')}</Font.Copy>
      <div className={styles['schedule-picker__wrapper']}>
        {dayStrings.map((day, id) => (
          <div
            className={cx('schedule-picker__icon', {
              'schedule-picker__icon--selected':
                newCheckinPreview.days_of_the_week.includes(id),
            })}
            onClick={() => handleScheduleChange(id)}
          >
            <Font.Action>{t(`weekDayInitial.${day}`)}</Font.Action>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SchedulePicker
