import { fetchJSON, stringifyParams } from 'utils/req'

export const postLogin = ({ email, password }) =>
  fetchJSON('/api/v1/auth_token', 'POST', { email, password })

export const setupPhone = phone =>
  fetchJSON(
    '/api/v1/current/two_factor/setup_phone',
    'POST',
    { phone },
    { useJwt: true }
  )

export const verifyCode = code =>
  fetchJSON(
    '/api/v1/current/two_factor/verify_code',
    'POST',
    { code },
    { useJwt: true }
  )

export const sendCode = () =>
  fetchJSON(
    '/api/v1/current/two_factor/send_code',
    'POST',
    {},
    { useJwt: true }
  )

export const sendResetEmail = email =>
  fetchJSON(`/api/v1/current/password`, 'POST', { email }, { useJwt: false })

export const verifyResetToken = token =>
  fetchJSON(
    `/api/v1/current/password?${stringifyParams({ token })}`,
    'GET',
    null,
    { useJwt: false }
  )

export const resetPassword = ({ token, password, passwordConfirm }) =>
  fetchJSON(
    `/api/v1/current/password?${stringifyParams({ token })}`,
    'PUT',
    {
      user: {
        password,
        password_confirmation: passwordConfirm,
      },
    },
    { useJwt: false }
  )
