import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import BaseTable, { AutoResizer } from 'react-base-table'
import PropTypes from 'prop-types'
import { sum } from 'lodash/math'
import {
  DefaultSortIcon,
  DefaultEmptyState,
  DefaultLoadingState,
} from './DefaultComponents'
import { getMinColumnWidth } from './utils/getColumnWidth'

// eslint-disable-next-line prefer-arrow-callback
const GridWithRef = forwardRef(function TableGrid(
  {
    children,
    columnNames,
    data,
    emptyMessage,
    error,
    headerHeight,
    height,
    hiddenColumns,
    loading,
    maxHeight,
    emptyHeight,
    onRowClick,
    pageSize,
    rowHeight,
    sortColumn,
    sortOrder,
    width,
    ...passThroughProps
  },
  ref
) {
  const tableRef = useRef()
  const { t } = useTranslation()

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (loading) {
      setInitialized(true)
    }
  }, [loading])

  useImperativeHandle(ref, () => ({
    scrollToTop: () => tableRef.current && tableRef.current.scrollToTop(0),
  }))

  const makeDefaultColumn = (key, index) => ({
    key,
    title: key,
    dataKey: key,
    columnIndex: index,
    width: 200,
    sortable: false,
    resizable: false,
    hidden: hiddenColumns.includes(key),
  })

  const makeColumns = shouldStretchCols => {
    if (!children && !columnNames.length && !data.length) {
      return []
    }

    if (children) {
      return React.Children.map(children, (child, i) => {
        if (!child) return null
        const { props, key } = child

        return {
          ...makeDefaultColumn(key, i),
          flexGrow: shouldStretchCols && !props.isActionColumn ? 1 : undefined,
          width: props.autoResize
            ? getMinColumnWidth(data, props.dataKey, props.title)
            : 200,
          ...props,
          title: t(props.title),
          // eslint-disable-next-line no-nested-ternary
          cellRenderer: props.isActionColumn
            ? ({ rowData }) => (
                <props.bodyCell
                  rowData={rowData}
                  actions={props.actions}
                  listInline={props.listInline}
                  disabled={props.disabled}
                />
              )
            : props.isSelectColumn
              ? ({ rowData }) => <props.bodyCell rowData={rowData} />
              : props.bodyCell,
          headerRenderer: props.headerCell,
        }
      })
    }
    const colNames = columnNames.length ? columnNames : Object.keys(data[0])
    return colNames.map((key, i) => makeDefaultColumn(key, i))
  }

  const getGridHeight = () => {
    if (height) return height

    const totalHeaderHeight = Array.isArray(headerHeight)
      ? sum(headerHeight)
      : headerHeight

    const rowCount = pageSize
      ? Math.min(pageSize, data.length) || pageSize
      : data.length

    const totalRowHeight = rowCount * rowHeight
    const totalHeight = totalRowHeight + totalHeaderHeight

    return totalRowHeight > 0
      ? Math.min(totalHeight, maxHeight)
      : emptyHeight || maxHeight
  }

  return (
    <AutoResizer height={getGridHeight()} width={width}>
      {autosize => {
        const columnsWidth = tableRef.current
          ? tableRef.current.getTotalColumnsWidth()
          : autosize.width
        const shouldStretchCols = columnsWidth <= autosize.width
        return (
          <BaseTable
            ref={tableRef}
            width={autosize.width}
            headerHeight={headerHeight}
            height={autosize.height}
            data={data}
            sortBy={
              sortColumn && sortOrder
                ? { key: sortColumn, order: sortOrder.toLowerCase() }
                : undefined
            }
            rowEventHandlers={{ onClick: onRowClick }}
            rowClassName={({ rowIndex }) =>
              rowIndex % 2 === 0 ? 'row-even' : 'row-odd'
            }
            columns={makeColumns(shouldStretchCols)}
            components={{
              SortIndicator: DefaultSortIcon,
            }}
            emptyRenderer={
              initialized &&
              !loading && <DefaultEmptyState message={t(emptyMessage)} />
            }
            overlayRenderer={
              <DefaultLoadingState
                error={error}
                loading={loading}
                headerHeight={headerHeight}
              />
            }
            fixed={!shouldStretchCols}
            {...passThroughProps}
          />
        )
      }}
    </AutoResizer>
  )
})

GridWithRef.propTypes = {
  className: PropTypes.string,
  columnNames: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.array,
  emptyMessage: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  frozenData: PropTypes.arrayOf(PropTypes.object),
  headerHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.number,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  maxHeight: PropTypes.number,
  emptyHeight: PropTypes.number,
  onColumnSort: PropTypes.func,
  onRowClick: PropTypes.func,
  pageSize: PropTypes.number,
  rowHeight: PropTypes.number,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'ASC', 'desc', 'DESC']),
  width: PropTypes.number,
}

GridWithRef.defaultProps = {
  className: undefined,
  columnNames: [],
  data: [],
  emptyMessage: 'No data found',
  error: false,
  frozenData: [],
  headerHeight: 50,
  height: undefined,
  hiddenColumns: [],
  loading: false,
  maxHeight: 575,
  emptyHeight: null,
  onColumnSort: undefined,
  onRowClick: () => {},
  pageSize: undefined,
  rowHeight: 50,
  sortColumn: undefined,
  sortOrder: undefined,
  width: undefined,
}

export default GridWithRef
