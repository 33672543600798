const rDateLike = /^\d\d\d\d-\d\d-\d\d$/
const rDateTimeLike = /^\d\d\d\d-\d\d-\d\dT/
const looksLikeDate = (maybeDate: string): boolean =>
  !!maybeDate.match(rDateLike) || !!maybeDate.match(rDateTimeLike)

const convertToDateRange = (
  startDate: string | undefined,
  endDate: string | undefined
): {
  start: string | null
  end: string | null
} => ({
  start: startDate && looksLikeDate(startDate) ? startDate.split('T')[0] : null,
  end: endDate && looksLikeDate(endDate) ? endDate.split('T')[0] : null,
})

export { convertToDateRange }
