import {
  Button,
  TextBlock,
  ProgressBar,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks/useRequest'
import { CardError, InputArea } from 'components'
import { useTranslation } from 'react-i18next'
import ScanReviewSection from 'qualityControl/scanReview/ScanReviewSection/ScanReviewSection'
import { formatDisplayName } from 'utils/formatting'
import { useCurrent } from 'contexts/index'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { qcPacketFields } from 'constants/qualityControl'
import { useScanActions } from 'qualityControl/contexts/ScanContext'
import RedoQcButton from '../RedoQcButton'
import styles from './IssuePanel.module.scss'

const IssuePanel = ({ currentScan, currentReviews, goToNext }) => {
  const { t } = useTranslation()
  const { disapproveScan, redoScanResponses } = useScanActions()
  const { isLoading, hasErrors, makeRequest } = useRequest(redoScanResponses)
  const currentScanNotes = currentScan.notes
  const { doesCurrentUserHavePermission } = useCurrent()
  const { getPackets, inspectedUser, getInspectedUser } = useQualityControl()
  const canPerformVisualQC = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'visual_qc',
  })

  const isAutoReviewed =
    currentReviews.length && !currentReviews.filter(r => r.user).length

  const reviewerName = new Set(
    currentReviews.map(r => formatDisplayName(r.user))
  )

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makeDisapproveRequest } = useRequest(disapproveScan, {
    onSuccess: () => {
      inspectedUser.id
        ? getInspectedUser(inspectedUser.id)
        : getPackets({
            fields: qcPacketFields,
          })

      goToNext()
    },
  })

  const issuesRaised = currentReviews.map(r => r.response.id)

  return (
    <>
      <ProgressBar show={isLoading} />
      <ScanReviewSection initExpanded={isAutoReviewed}>
        {reviewerName.size > 0 && (
          <TextBlock>
            <p className={styles.label}>{t('Found by')}</p>
            <p>{reviewerName}</p>
          </TextBlock>
        )}
        <InputArea label={t('Reasons')}>
          <ul>
            {currentReviews.length &&
              currentReviews.map(r => (
                <li key={`${r.response.response}-issue`}>
                  {t(r.response.description)}
                </li>
              ))}
          </ul>
        </InputArea>
        <InputArea label={t('Notes')}>{currentScanNotes}</InputArea>
        <ButtonBlock>
          <RedoQcButton
            redoResponses={() => makeRequest(currentScan)}
            isAutoReviewed={isAutoReviewed}
            issuesRaised={issuesRaised}
          />
          {isAutoReviewed && (
            <Button.Accent
              disabled={!canPerformVisualQC}
              onClick={() => {
                makeDisapproveRequest(currentScan.id, '', issuesRaised)
              }}
            >
              {t('Submit responses')}
            </Button.Accent>
          )}
        </ButtonBlock>
      </ScanReviewSection>
      <CardError hide={!hasErrors} />
    </>
  )
}

export default IssuePanel
