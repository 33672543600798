import { formatPhoneNumber } from 'utils/formatting'

export const guestNameCsvCell = data => data.person.name

export const PersonFirstNameCsvCell = data => data.person.first_name
export const PersonLastNameCsvCell = data => data.person.last_name

export const guestAddressCsvCell = data => {
  const fullAddress = [
    data.person.residential_address?.line_one,
    data.person.residential_address?.city,
    data.person.residential_address?.state,
    data.person.residential_address?.zipcode,
    data.person.residential_address?.county,
  ]
    .filter(x => !!x)
    .join(', ')

  return fullAddress
}

export const guestPhoneCsvCell = data =>
  formatPhoneNumber(data.person.primary_phone_number)

export const guestEmailCsvCell = data => data.person.email

export const guestStatusCsvCell = data => data.status

export const invitedByCsvCell = data => (data.inviter ? data.inviter.name : '')

export const guestRoleCsvCell = data => data.role

export const csvCellForGuestNeed = field => data => data.needs[field]
