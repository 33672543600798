import React, { useContext, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { merge } from 'lodash'
import { CardError, LoadBar, ViewMoreButton } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  FieldBlock,
  Sheet,
  Section,
  TextField,
  TextBlock,
  Font,
  useToast,
} from '@politechdev/blocks-design-system'
import moment from 'moment'
import { maskPhone } from 'utils/inputMasks'
import { useReactRouter, useRequest } from 'hooks'
import { mergeImportError } from 'requests/duplicatePrevention'
import { getExtraFields } from 'duplicatePrevention/record/utils'
import RecordValueDiff from '../RecordValueDiff/RecordValueDiff'
import { getDifferences } from './utils'
import { RecordContext } from '../RecordContext/RecordContext'
import RecordMergeDialog from '../RecordMergeDialog/RecordMergeDialog'
import DiffIndicator from '../FieldIndicator/DiffIndicator'

const RecordDuplicate = ({ personRecord, readOnly }) => {
  const { setToast } = useToast()
  const {
    errorRecord,
    resolvedFields,
    setResolvedFields,
    hideConfirmMerge,
    importReq,
    row,
    setRow,
    setDifferences,
  } = useContext(RecordContext)
  const { match } = useReactRouter()
  const [isOpen, setIsOpen] = useState(false)

  const personPollAttributes = {
    polladdress1: personRecord?.polling_info?.address,
    polldescription1: personRecord?.polling_info?.description?.[0],
    polldescription2: personRecord?.polling_info?.description?.[1],
  }

  const differences = getDifferences(
    merge({}, errorRecord, resolvedFields),
    merge({}, personRecord, personPollAttributes)
  )

  useEffect(() => {
    setDifferences(differences)
  }, [errorRecord])

  const { t } = useTranslation()

  const onSuccess = () => {
    setToast({
      message: t('Success'),
      variant: 'success',
    })

    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      importReq()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
      if (row === 0) {
        setRow(1)
      }
    }, 1500)
  }

  const mergeImportRequest = useRequest(mergeImportError, { onSuccess })

  const extraFields = getExtraFields(errorRecord, {
    ...personRecord,
    ...personPollAttributes,
  })

  const birthDateRecordVal = useMemo(() => {
    if (resolvedFields.birth_date !== undefined) {
      return resolvedFields.birth_date
    }
    return errorRecord.birth_date
      ? moment(errorRecord.birth_date).format('MM/DD/YYYY')
      : undefined
  }, [errorRecord, resolvedFields])

  const fullNameError = [
    errorRecord.first_name,
    errorRecord.middle_name,
    errorRecord.last_name,
  ]
    .filter(x => !!x)
    .join(' ')

  const doesNameDiffer =
    differences.first_name || differences.middle_name || differences.last_name

  const fullAddressError = [
    [
      personRecord.residential_address?.line_one,
      personRecord.residential_address?.line_two,
    ]
      .filter(x => !!x)
      .join(', '),
    [personRecord.residential_address?.city],
    [personRecord.residential_address?.county],
    [
      personRecord.residential_address?.state,
      personRecord.residential_address?.zipcode,
    ]
      .filter(x => !!x)
      .join(', '),
  ]
    .filter(x => !!x)
    .join(', ')

  const doesAddressDiffer =
    differences.residential_address?.line_one ||
    differences.residential_address?.line_two ||
    differences.residential_address?.county ||
    differences.residential_address?.city ||
    differences.residential_address?.state ||
    differences.residential_address?.zipcode

  return (
    <div>
      {isOpen && !hideConfirmMerge && (
        <RecordMergeDialog
          personRecord={personRecord}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
      {!readOnly && (
        <ButtonBlock collapsed>
          <Button
            onClick={() => {
              hideConfirmMerge
                ? mergeImportRequest.makeRequest(
                    match.params.importId,
                    errorRecord.id,
                    {
                      target_id: personRecord.id,
                      row_data: merge(
                        {
                          residential_address: personRecord.residential_address,
                        },
                        errorRecord,
                        resolvedFields,
                        {
                          birth_date: moment(
                            resolvedFields.birth_date || errorRecord.birth_date
                          ).format('YYYY-MM-DD'),
                        }
                      ),
                    }
                  )
                : setIsOpen(true)
            }}
          >
            {t('Merge here')}
          </Button>
        </ButtonBlock>
      )}
      <ContentBlock>
        <Sheet>
          <LoadBar show={mergeImportRequest.isLoading} />
          <CardError
            hide={!mergeImportRequest.hasErrors}
            message={t('Something went wrong merging this record.')}
          />
          <Section label={t('Name')}>
            <DiffIndicator
              section="fullName"
              value={fullNameError}
              hasDifference={doesNameDiffer}
            />
            <FieldBlock>
              <TextField
                label={t('First name')}
                value={
                  resolvedFields.first_name !== undefined
                    ? resolvedFields.first_name
                    : errorRecord.first_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    first_name: val,
                  }))
                }
                required
              />
              <TextField
                label={t('Middle name')}
                value={
                  resolvedFields.middle_name !== undefined
                    ? resolvedFields.middle_name
                    : errorRecord.middle_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    middle_name: val,
                  }))
                }
              />
              <TextField
                label={t('Last name')}
                value={
                  resolvedFields.last_name !== undefined
                    ? resolvedFields.last_name
                    : errorRecord.last_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    last_name: val,
                  }))
                }
                required
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary phone number')}>
            <DiffIndicator
              section="primary_phone_number"
              value={maskPhone(personRecord.primary_phone_number)}
              hasDifference={differences.primary_phone_number}
            />
            <RecordValueDiff
              diff={differences.primary_phone_number}
              dupeVal={maskPhone(personRecord.primary_phone_number)}
              recordVal={
                resolvedFields.primary_phone_number !== undefined
                  ? resolvedFields.primary_phone_number
                  : errorRecord.primary_phone_number?.replace(/\D/g, '')
              }
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  primary_phone_number: val,
                }))
              }
              type="phone"
            />
          </Section>
          <Section label={t('Primary email')}>
            <DiffIndicator
              section="primary_email_address"
              value={maskPhone(personRecord.primary_email_address)}
              hasDifference={differences.primary_email_address}
            />
            <RecordValueDiff
              diff={differences.primary_email_address}
              dupeVal={personRecord.primary_email_address}
              recordVal={
                resolvedFields.primary_email_address !== undefined
                  ? resolvedFields.primary_email_address
                  : errorRecord.primary_email_address
              }
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  primary_email_address: val,
                }))
              }
            />
          </Section>
          <Section label="Address">
            <DiffIndicator
              section="fullResidentialAddress"
              value={fullAddressError}
              hasDifference={doesAddressDiffer}
            />
            <RecordValueDiff
              label={t('Address line 1')}
              dupeVal={personRecord.residential_address?.line_one}
              recordVal={
                resolvedFields.residential_address?.line_one !== undefined
                  ? resolvedFields.residential_address.line_one
                  : errorRecord.residential_address?.line_one
              }
              diff={differences.residential_address?.line_one}
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  residential_address: {
                    ...oldResolvedFields.residential_address,
                    line_one: val,
                  },
                }))
              }
            />
            <RecordValueDiff
              label={t('Address line 2')}
              dupeVal={personRecord.residential_address?.line_two}
              recordVal={
                resolvedFields.residential_address?.line_two !== undefined
                  ? resolvedFields.residential_address.line_two
                  : errorRecord.residential_address?.line_two
              }
              diff={differences.residential_address?.line_two}
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  residential_address: {
                    ...oldResolvedFields.residential_address,
                    line_two: val,
                  },
                }))
              }
            />
            <RecordValueDiff
              label={t('City')}
              dupeVal={personRecord.residential_address?.city}
              recordVal={
                resolvedFields.residential_address?.city !== undefined
                  ? resolvedFields.residential_address.city
                  : errorRecord.residential_address?.city
              }
              diff={differences.residential_address?.city}
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  residential_address: {
                    ...oldResolvedFields.residential_address,
                    city: val,
                  },
                }))
              }
            />
            <RecordValueDiff
              label={t('County')}
              dupeVal={personRecord.residential_address?.county}
              recordVal={
                resolvedFields.residential_address?.county !== undefined
                  ? resolvedFields.residential_address.county
                  : errorRecord.residential_address?.county
              }
              diff={differences.residential_address?.county}
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  residential_address: {
                    ...oldResolvedFields.residential_address,
                    county: val,
                  },
                }))
              }
            />
            <RecordValueDiff
              label={t('State')}
              dupeVal={personRecord.residential_address?.state}
              recordVal={
                resolvedFields.residential_address?.state !== undefined
                  ? resolvedFields.residential_address.state
                  : errorRecord.residential_address?.state
              }
              diff={differences.residential_address?.state}
              type="state"
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  residential_address: {
                    ...oldResolvedFields.residential_address,
                    state: val,
                  },
                }))
              }
            />
            <RecordValueDiff
              label={t('Zipcode')}
              dupeVal={personRecord.residential_address?.zipcode}
              recordVal={
                resolvedFields.residential_address?.zipcode !== undefined
                  ? resolvedFields.residential_address.zipcode
                  : errorRecord.residential_address?.zipcode
              }
              diff={differences.residential_address?.zipcode}
              type="zipcode"
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  residential_address: {
                    ...oldResolvedFields.residential_address,
                    zipcode: val,
                  },
                }))
              }
            />
          </Section>
          <Section label={t('Date of birth')}>
            <DiffIndicator
              section="birth_date"
              value={moment(personRecord.birth_date).format('MM/DD/YYYY')}
              hasDifference={differences.birth_date}
            />
            <RecordValueDiff
              dupeVal={
                personRecord.birth_date
                  ? moment(personRecord.birth_date).format('MM/DD/YYYY')
                  : personRecord.birth_date
              }
              type="date"
              recordVal={birthDateRecordVal}
              diff={differences.birth_date}
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  birth_date: val,
                }))
              }
            />
          </Section>
          <Section label={t('External ID')}>
            <DiffIndicator
              section="external_id"
              value={personRecord.external_id}
              hasDifference={differences.external_id}
            />
            <RecordValueDiff
              dupeVal={personRecord.external_id}
              recordVal={
                resolvedFields.external_id !== undefined
                  ? resolvedFields.external_id
                  : errorRecord.external_id
              }
              diff={differences.external_id}
              changeFunc={val =>
                setResolvedFields(oldResolvedFields => ({
                  ...oldResolvedFields,
                  external_id: val,
                }))
              }
            />
          </Section>
          {!!extraFields.length && (
            <ViewMoreButton
              count={extraFields.length}
              name="field"
              isOpenByDefault={false}
            >
              {extraFields.map(
                field =>
                  field.value && (
                    <TextBlock>
                      <Font.Label>{t(field.label)}</Font.Label>
                      <Font.Copy>{field.value}</Font.Copy>
                    </TextBlock>
                  )
              )}
            </ViewMoreButton>
          )}
        </Sheet>
      </ContentBlock>
    </div>
  )
}

export default RecordDuplicate
