import { Sheet, SectionLabel } from 'components'
import { useTranslation } from 'react-i18next'
import AssignablePacketsTable from './table/AssignablePacketsTable'

const AssignablePackets = ({ user, refreshAssignedPackets }) => {
  const { t } = useTranslation()

  return (
    <div className="quality-control__packet-management">
      <SectionLabel tight>{t('Assignable packets')}</SectionLabel>
      <Sheet>
        <AssignablePacketsTable
          user={user}
          refreshAssignedPackets={refreshAssignedPackets}
        />
      </Sheet>
    </div>
  )
}

export default AssignablePackets
