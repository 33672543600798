import { Switch, Route } from 'react-router-dom'
import PhoneBankSingle from 'phone_banks/PhoneBankSingle/PhoneBankSingle'
import PhoneBankSelect from 'phone_banks/PhoneBankSelect/PhoneBankSelect'
import PhoneBankCopy from 'phone_banks/PhoneBankCopy/PhoneBankCopy'
import SessionNew from 'phone_banks/call_session/new/SessionNew'
import SessionActive from 'phone_banks/call_session/active/SessionActive'
import SessionEnd from 'phone_banks/call_session/end/SessionEnd'
import PhoneBankNew from 'phone_banks/PhoneBankNew/PhoneBankNew'
import PhoneBankEdit from 'phone_banks/PhoneBankEdit/PhoneBankEdit'
import { callSessionModes } from 'phone_banks/constants'
import Operator from 'powerDialer/Operator/Operator'
import PowerDialerAgent from 'powerDialer/Agent/PowerDialerAgent'
import AgentOnboarding from 'powerDialer/Agent/AgentOnboarding'
import OperatorOnboarding from 'powerDialer/Operator/OperatorOnboarding'
import HasPowerDialerPermission from './HasPowerDialerPermission'
import PhoneBankContextProvider from '../PhoneBankContext/PhoneBankContext'
import CallSessionProvider from '../components/CallSessionContext/CallSessionContext'

const PhoneBankRoutes = ({ match }) => (
  <PhoneBankContextProvider>
    <CallSessionProvider.Standard>
      <Switch>
        <Route exact path={`${match.url}`} component={PhoneBankSelect} />
        <Route exact path={`${match.url}/new`} component={PhoneBankNew} />
        <Route exact path={`${match.url}/copy`} component={PhoneBankCopy} />
        <Route
          exact
          path={`${match.url}/:phoneBankId`}
          component={PhoneBankSingle}
        />
        <Route
          exact
          path={`${match.path}/:phoneBankId/edit`}
          component={PhoneBankEdit}
        />
        <Route
          exact
          path={`${match.path}/:phoneBankId/call_sessions/agent-onboarding`}
          render={() => (
            <HasPowerDialerPermission>
              <AgentOnboarding />
            </HasPowerDialerPermission>
          )}
        />
        <Route
          exact
          path={`${match.path}/:phoneBankId/call_sessions/operator-onboarding`}
          render={() => (
            <HasPowerDialerPermission>
              <OperatorOnboarding />
            </HasPowerDialerPermission>
          )}
        />
        <Route
          exact
          path={`${match.url}/:phoneBankId/call_sessions/new`}
          component={SessionNew}
        />
        <Route
          exact
          path={`${match.url}/:phoneBankId/call_sessions/${callSessionModes.OPERATOR}`}
          render={() => (
            <HasPowerDialerPermission>
              <Operator />
            </HasPowerDialerPermission>
          )}
        />
        <Route
          exact
          path={`${match.url}/:phoneBankId/call_sessions/:id/end`}
          component={SessionEnd}
        />
        <Route
          exact
          path={`${match.url}/:phoneBankId/call_sessions/:id/${callSessionModes.AGENT}`}
          render={() => (
            <HasPowerDialerPermission>
              <PowerDialerAgent />
            </HasPowerDialerPermission>
          )}
        />
        <Route
          path={`${match.url}/:phoneBankId/call_sessions/:id/:mode`}
          component={SessionActive}
        />
      </Switch>
    </CallSessionProvider.Standard>
  </PhoneBankContextProvider>
)

export default PhoneBankRoutes
