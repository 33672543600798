import { Temporal } from 'temporal-polyfill'

export const isPhoneBankOpen = phoneBank => {
  const { start_date, end_date, daily_start_time, daily_end_time, time_zone } =
    phoneBank

  const today = Temporal.Now.plainDateISO(time_zone)
  const timeNow = Temporal.Now.plainTimeISO(time_zone)

  const startDate = Temporal.PlainDate.from(start_date)
  const endDate = Temporal.PlainDate.from(end_date)

  const startTimeToday = Temporal.PlainTime.from(daily_start_time)
  const endTimeToday = Temporal.PlainTime.from(daily_end_time)

  return (
    Temporal.PlainDate.compare(startDate, today) <= 0 &&
    Temporal.PlainDate.compare(today, endDate) <= 0 &&
    Temporal.PlainTime.compare(startTimeToday, timeNow) <= 0 &&
    Temporal.PlainTime.compare(timeNow, endTimeToday) < 0
  )
}
