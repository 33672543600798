/* eslint-disable react/no-danger */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, ButtonBlock, FieldBlock } from 'components'
import { useRequest } from 'hooks'
import {
  sendCode as sendCodeRequest,
  verifyCode as verifyCodeRequest,
} from 'requests/auth'
import { formatPhoneNumber } from 'utils/formatting'
import AuthFrame from '../AuthFrame/AuthFrame'
import { useTwoFactorSetup } from '../SetupTwoFactor/SetupTwoFactor'
import styles from './SetupTwoFactorVerify.module.scss'

const SetupTwoFactorVerify = () => {
  const { t } = useTranslation()

  const { code, setCode, phone, setToken, nextPage, prevPage } =
    useTwoFactorSetup()

  const {
    makeRequest: resendCode,
    isLoading: resendLoading,
    hasErrors: resendError,
  } = useRequest(sendCodeRequest)

  const {
    makeRequest: verifyCode,
    isLoading: verifyLoading,
    hasErrors: verifyError,
  } = useRequest(verifyCodeRequest, {
    onSuccess: response => {
      setToken(response?.token)
      nextPage()
    },
    onError: error => {
      if (error?.response?.status === 401) {
        return t('Invalid or expired two-factor code')
      }
      return t('We had trouble verifying your code. Please try again later.')
    },
  })

  const handleSubmit = e => {
    e.preventDefault()
    verifyCode(code)
  }

  return (
    <div>
      <AuthFrame.Content>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'We just sent a text message with a 6-digit verification code to {{twoFactorPhone}}. Please provide it below then click "Verify code" to finish setup. It may take up to 2 minutes to receive your code.',
              {
                twoFactorPhone: `<strong>${formatPhoneNumber(phone)}</strong>`,
                interpolation: { escapeValue: false },
              }
            ),
          }}
        />
      </AuthFrame.Content>
      {verifyError && <AuthFrame.Error>{verifyError}</AuthFrame.Error>}
      {resendError && (
        <AuthFrame.Error>
          {t(
            'We had trouble resending your two-factor code. Please try again later.'
          )}
        </AuthFrame.Error>
      )}
      <form onSubmit={handleSubmit}>
        <FieldBlock>
          <TextField
            id="code"
            label={t('Two-factor code')}
            value={code}
            onChange={val => setCode(val.replace(/\D/g, ''))}
            disabled={verifyLoading || resendLoading}
          />
          <Button primary onClick={resendCode} className={styles.button}>
            {resendLoading ? t('Resending code') : t('Resend code')}
          </Button>
        </FieldBlock>

        <AuthFrame.Actions>
          <ButtonBlock justify="spread">
            <Button raised primary type="submit">
              {verifyLoading ? t('Verifying code') : t('Verify code')}
            </Button>
            <Button
              primary
              onClick={() => {
                prevPage()
                setCode('')
              }}
            >
              {t('Change phone')}
            </Button>
          </ButtonBlock>
        </AuthFrame.Actions>
      </form>
    </div>
  )
}

export default SetupTwoFactorVerify
