import { cloneDeep } from 'lodash'
import moment from 'moment-timezone'

export const doUnfilledFieldsExist = (shownMeetings, isExistingMeeting) =>
  shownMeetings.some(meeting => {
    const { user, start_time, end_time, eventDay } = meeting
    return (
      !isExistingMeeting(meeting) &&
      (!user?.id || !start_time || !end_time || !eventDay)
    )
  })

export const formatMeetingTime = (day, time, timezone) =>
  moment.tz(`${day} ${time}`, timezone).format()

export const cancelMeetingUtil = (
  indexToUpdate,
  shownMeetings,
  setShownMeetings
) => {
  const newMeetings = shownMeetings.map((meeting, index) => {
    if (indexToUpdate !== index) {
      return meeting
    }
    return { ...meeting, cancelled: true }
  })
  setShownMeetings(newMeetings)
}

export const buildSaveMeetingsRequests = ({
  shownMeetings,
  isExistingMeeting,
  existingMeetings,
  cancelMeetingRequest,
  timezone,
  personId,
  postMeeting,
}) => {
  let requests = []
  requests = requests.concat(
    shownMeetings.map(shownMeeting => {
      if (isExistingMeeting(shownMeeting)) {
        if (
          existingMeetings.find(
            meeting =>
              meeting.id === shownMeeting.id &&
              shownMeeting.cancelled === true &&
              meeting.cancelled === false
          )
        ) {
          return cancelMeetingRequest(shownMeeting.id)
        }
        return async () => {}
      }
      const { start_time, end_time, user, eventDay } = shownMeeting

      const formattedStartTime = formatMeetingTime(
        eventDay,
        start_time,
        timezone
      )
      const formattedEndTime = formatMeetingTime(eventDay, end_time, timezone)

      const baseRequestData = {
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        person_id: personId,
        user_id: user.id,
      }
      return postMeeting(baseRequestData)
    })
  )
  return Promise.all(requests)
}

export const addMeetingUtil = (setShownMeetings, shownMeetings) => {
  setShownMeetings([
    ...shownMeetings,
    {
      start_time: moment({ hour: 9 }).format('HH:mm:ss'),
      end_time: moment({ hour: 17 }).format('HH:mm:ss'),
      eventDay: moment().format('YYYY-MM-DD'),
    },
  ])
}

export const removeMeetingUtil = (setShownMeetings, shownMeetings, index) => {
  setShownMeetings(
    shownMeetings.filter((_, meetingIndex) => meetingIndex !== index)
  )
}

export const updateMeetingFieldUtil = ({
  val,
  key,
  index,
  setShownMeetings,
  shownMeetings,
}) => {
  setShownMeetings(
    shownMeetings.map((meeting, meetingIndex) => {
      if (meetingIndex !== index) {
        return meeting
      }
      const newMeeting = cloneDeep(meeting)
      newMeeting[key] = val
      return newMeeting
    })
  )
}
