import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonBlock } from 'components'
import { useAuth } from 'contexts'
import { useTimeout } from 'hooks'
import AuthFrame from '../AuthFrame/AuthFrame'
import { useTwoFactorSetup } from '../SetupTwoFactor/SetupTwoFactor'

const SetupTwoFactorSuccess = () => {
  const { t } = useTranslation()

  const { login } = useAuth()
  const { token } = useTwoFactorSetup()

  const finishSetup = () => {
    login(token)
  }

  const { startTimeout } = useTimeout(finishSetup, 30000)

  useEffect(() => {
    startTimeout()
  }, [])

  return (
    <div>
      <AuthFrame.Content>
        {t(
          'Congratulations! Two-factor authentication is now setup. Press "Finish" to continue to Blocks.'
        )}
      </AuthFrame.Content>
      <AuthFrame.Actions>
        <ButtonBlock justify="left">
          <Button raised primary onClick={finishSetup}>
            {t('Finish')}
          </Button>
        </ButtonBlock>
      </AuthFrame.Actions>
    </div>
  )
}

export default SetupTwoFactorSuccess
