import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader, Section } from '@politechdev/blocks-design-system'

const DuplicatePreventionDone = () => {
  const { t } = useTranslation()
  return (
    <Section>
      <PageHeader title={t('All errors in the import have been resolved.')} />
    </Section>
  )
}

export default DuplicatePreventionDone
