import { Avatar, Sheet, Section } from 'components'
import { useCurrent } from 'contexts/index'

const MainSectionHeader = () => {
  const {
    currentUser: {
      name,
      avatar,
      turf: { name: turfName },
    },
  } = useCurrent()
  return (
    <Section>
      <Sheet>
        <div>
          <Avatar
            name={name}
            imageUrl={avatar}
            imageLarge
            subtitle={turfName}
          />
        </div>
      </Sheet>
    </Section>
  )
}

export default MainSectionHeader
