import {
  usePledgeCardGalleryActions,
  usePledgeCardGalleryState,
  ViewerType,
} from 'qualityControl/pledgeCardGallery/PledgeCardGalleryContext/PledgeCardGalleryContext'

const useGalleryViewOptions = () => {
  const { packetScans, firstScan, secondScan } = usePledgeCardGalleryState()
  const { setSecondScan, setViewerType, setQueryParams } =
    usePledgeCardGalleryActions()
  const firstScanIndex = packetScans.findIndex(scan => scan.id === firstScan.id)
  const secondScanIndex = packetScans.findIndex(
    scan => scan.id === secondScan.id
  )
  const changeViewType = type => {
    if (
      (firstScanIndex === secondScanIndex || secondScanIndex === -1) &&
      type === ViewerType.Pledge
    ) {
      const newSecondScan =
        firstScanIndex === 0 ? packetScans[1] : packetScans[0]
      setSecondScan(newSecondScan)
      setQueryParams({ secondScanId: newSecondScan.id })
    }
    setViewerType(type)
    setQueryParams({ type })
  }

  return { changeViewType }
}

export default useGalleryViewOptions
