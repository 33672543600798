import { createContext, useState } from 'react'
import {
  startOfCurrentYearYMD,
  currentDateYMD,
  formatISODate,
} from 'utils/dateTime'

export const DashboardFilterContext = createContext()

const DashboardFilterProvider = ({ children }) => {
  const [startDate, setStartDate] = useState(
    formatISODate(startOfCurrentYearYMD)
  )
  const [endDate, setEndDate] = useState(formatISODate(currentDateYMD))
  const [turfId, setTurfId] = useState(null)
  return (
    <DashboardFilterContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        turfId,
        setTurfId,
      }}
    >
      {children}
    </DashboardFilterContext.Provider>
  )
}

export default DashboardFilterProvider
