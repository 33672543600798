import React, { useEffect, useState } from 'react'
import SubTurf from './SubTurf'

const ExpandedTurfSelectField = ({
  turfs,
  onSelect,
  value,
  label,
  disabled,
  disableArchived,
  isLoading,
  required,
}) => {
  const [activeTurfs, setActiveTurfs] = useState([
    {
      turfId: null,
    },
  ])

  useEffect(() => {
    if (
      turfs.length &&
      activeTurfs.length === 1 &&
      activeTurfs[0].turfId === null
    ) {
      const nextActiveTurfs = []
      nextActiveTurfs.push({
        turfId: value,
        parentId: turfs.find(turf => turf.id === value)?.parent_id,
      })
      while (
        turfs.find(
          turf =>
            turf.id === nextActiveTurfs[nextActiveTurfs.length - 1].parentId
        )
      ) {
        const parentTurf = turfs.find(
          turf =>
            turf.id === nextActiveTurfs[nextActiveTurfs.length - 1].parentId
        )
        nextActiveTurfs.push({
          turfId: parentTurf.id,
          parentId: parentTurf.parent_id,
        })
      }
      nextActiveTurfs.reverse()
      setActiveTurfs(nextActiveTurfs)
    }
  }, [value, turfs])

  useEffect(() => {
    const selectedTurf = [...activeTurfs]
      .reverse()
      .find(activeTurf => activeTurf.turfId !== null)
    if (selectedTurf) onSelect(selectedTurf.turfId)
  }, [activeTurfs])

  return activeTurfs.map((activeTurf, i) => (
    <SubTurf
      key={i}
      activeTurf={activeTurf}
      activeTurfs={activeTurfs}
      setActiveTurfs={setActiveTurfs}
      turfs={turfs}
      index={i}
      label={label}
      disabled={disabled}
      isLoading={isLoading}
      disableArchived={disableArchived}
      required={required}
    />
  ))
}

export default ExpandedTurfSelectField
