import moment from 'moment'

const generateDatesInGoal = (start, end) => {
  const arr = []
  for (
    let startDate = moment(start);
    startDate.isSameOrBefore(moment(end));
    startDate = startDate.add(1, 'days')
  ) {
    arr.push(moment(startDate).toDate())
  }
  return arr
}

const generateDisabledGoalDates = ({ otherGoals, totalStartDate }) => {
  const goalDates = otherGoals
    .reduce(
      (acc, curr) =>
        acc.concat(generateDatesInGoal(curr.start_date, curr.end_date)),
      []
    )
    .flat()
  if (totalStartDate !== undefined) {
    const goalsAfterStartDate = otherGoals.filter(otherGoal => {
      const otherGoalStart = moment(otherGoal.start_date)

      return otherGoalStart.isAfter(moment(totalStartDate))
    })
    if (goalsAfterStartDate.length === 0) {
      return [undefined, goalDates]
    }
    const earliestGoalStartAfterStartDate = goalsAfterStartDate.reduce(
      (prev, curr) =>
        moment(prev.start_date).isAfter(moment(curr.start_date)) ? curr : prev,
      goalsAfterStartDate[0]
    )
    return [
      moment(earliestGoalStartAfterStartDate.start_date)
        .subtract(1, 'days')
        .toDate(),
      goalDates,
    ]
  }
  return [undefined, goalDates]
}

export default generateDisabledGoalDates
