import { useState, useEffect } from 'react'

const useStoredState = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = window.localStorage.getItem(key)

    return storedValue !== null ? JSON.parse(storedValue) : initialValue
  })

  useEffect(() => {
    const storedValue = window.localStorage.getItem(key)

    if (value !== undefined || storedValue !== null) {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  }, [value])

  return [value, setValue]
}

export default useStoredState
