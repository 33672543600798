import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Font,
  ButtonBlock,
  Button,
  Checkbox,
  Sheet,
  Section,
  TextField,
  TextBlock,
  FieldBlock,
  Grid,
  Icon,
} from '@politechdev/blocks-design-system'
import { useForm } from 'contexts'
import { useRequest } from 'hooks/useRequest'
import { APPROVED_RESPONSE } from './constants'
import { updatePage } from './utils'
import styles from './PetitionPacketSingle.module.scss'

const PageIssues = ({
  pages,
  currentPageNumber,
  visualReviewResponses,
  getUpdatedShift,
  isQcCompleted,
}) => {
  const { t } = useTranslation()
  const {
    formData: pageForm,
    setField: setPageField,
    setFormData: setPageFormData,
  } = useForm()
  const [editingPageIssues, setEditingPageIssues] = useState(false)
  const issueResponseOptions = visualReviewResponses.filter(
    vrr =>
      vrr.response !== APPROVED_RESPONSE &&
      vrr.reviewable_type === 'petition_page'
  )

  const currentPage = pages?.find(p => p.number === currentPageNumber)

  const updatePages = incomingPages => {
    setPageFormData(incomingPages)
    setEditingPageIssues(false)
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updatePageReq } = useRequest(updatePage, {
    onSuccess: ({ petition_pages }) => {
      getUpdatedShift()
      updatePages(petition_pages)
    },
  })

  const togglePageResponse = responseId => {
    const reviewIndex = pageForm.visual_review_responses.findIndex(
      r => r.id === responseId
    )

    const newReviews =
      reviewIndex > -1
        ? pageForm.visual_review_responses.toSpliced(reviewIndex, 1)
        : [...pageForm.visual_review_responses, { id: responseId }]

    setPageField(newReviews, 'visual_review_responses')
  }

  useEffect(() => {
    setPageFormData(currentPage)
  }, [currentPage])

  return (
    <Section label={`${t('Petition page')} ${currentPageNumber}`}>
      <Sheet>
        {!pageForm.visual_review_responses?.length && !editingPageIssues && (
          <ButtonBlock>
            <Button.Secondary
              onClick={() => setEditingPageIssues(true)}
              disabled={isQcCompleted}
            >
              <span>{t('Raise issue')}</span>
              <Icon.CircleExclamation />
            </Button.Secondary>
          </ButtonBlock>
        )}
        {editingPageIssues && (
          <div>
            <TextBlock>
              <Font.Label>{t('Issues found')}</Font.Label>
            </TextBlock>
            <TextBlock>
              {issueResponseOptions.length ? (
                issueResponseOptions.map(option => (
                  <Checkbox
                    id={option.id}
                    key={option.id}
                    name={`page-response-${option.id}`}
                    className={styles.checkbox}
                    checked={pageForm.visual_review_responses?.some(
                      r => r.id === option.id
                    )}
                    label={option.description}
                    aria-label={option.description}
                    onChange={() => togglePageResponse(option.id)}
                  />
                ))
              ) : (
                <p>{t('No page issue options set')}</p>
              )}
            </TextBlock>
            <FieldBlock variant="large">
              <TextField
                id="notes"
                label={t('Notes')}
                value={pageForm.notes}
                onChange={val => setPageField(val, 'notes')}
              />
            </FieldBlock>
            <TextBlock>
              <Font.Copy variant="hint">
                {t(
                  'Marking an issue on this page will invalidate all signatures on the page. This action is reversable.'
                )}
              </Font.Copy>
            </TextBlock>
            <ButtonBlock justify="left">
              <Button.Accent
                onClick={() => updatePageReq(currentPage, pageForm)}
              >
                {t('Save')}
              </Button.Accent>
              <Button.Secondary onClick={() => setEditingPageIssues(false)}>
                {t('Cancel')}
              </Button.Secondary>
            </ButtonBlock>
          </div>
        )}
        {!editingPageIssues &&
          pageForm.visual_review_responses?.length > 0 &&
          issueResponseOptions?.length > 0 && (
            <>
              <Grid>
                <TextBlock>
                  <Font.Label>{t('Issues Found')}</Font.Label>
                  <Font.Copy>
                    {pageForm.visual_review_responses
                      ?.map(
                        response =>
                          issueResponseOptions.find(
                            option => option.id === response.id
                          ).description
                      )
                      .join(', ')}
                  </Font.Copy>
                </TextBlock>
                {pageForm.notes && (
                  <TextBlock>
                    <Font.Label>{t('Notes')}</Font.Label>
                    <Font.Copy>{pageForm.notes}</Font.Copy>
                  </TextBlock>
                )}
              </Grid>
              <ButtonBlock>
                <Button.Secondary
                  onClick={() => setEditingPageIssues(true)}
                  disabled={isQcCompleted}
                >
                  {t('Edit page issues')}
                </Button.Secondary>
              </ButtonBlock>
            </>
          )}
      </Sheet>
    </Section>
  )
}

export default PageIssues
