import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, Icon } from '@politechdev/blocks-design-system'
import styles from './TableLinkButton.module.scss'

const TableLinkButton = ({ url }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const copyUrl = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3500)
  }

  return (
    <CopyToClipboard text={url} onCopy={copyUrl}>
      <div className={styles.container}>
        <Button.Secondary
          key="clipboard"
          icon
          tooltipPosition="left"
          tooltipLabel={t('Copy URL link')}
        >
          <Icon.Link />
        </Button.Secondary>
        {copied && (
          <div className={styles.confirmation}>
            <Icon.CircleCheck />
            <span>{t('Copied')}</span>
          </div>
        )}
      </div>
    </CopyToClipboard>
  )
}

export default TableLinkButton
