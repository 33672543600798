import { useTranslation } from 'react-i18next'
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts'

const DedupedTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        {payload
          .filter(
            (line, index) =>
              payload.findIndex(otherLine => line.name === otherLine.name) ===
              index
          )
          .map(line => (
            <p style={{ color: line.color }}>{`${line.name}: ${line.value}`}</p>
          ))}
      </div>
    )
  }

  return null
}

const DedupedLegend = ({ payload }) => (
  <ul
    className="recharts-default-legend"
    style={{ padding: '0px', margin: '0px', textAlign: 'center' }}
  >
    {payload
      .filter(
        (line, index) =>
          payload.findIndex(otherLine => line.dataKey === otherLine.dataKey) ===
          index
      )
      .map((entry, index) => (
        <li
          className={`recharts-legend-item legend-item-${index}`}
          style={{ display: 'inline-block', marginRight: '10px' }}
          key={`item-${index}`}
        >
          <svg
            className="recharts-surface"
            width="14"
            height="14"
            viewBox="0 0 32 32"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginRight: '4px',
            }}
          >
            <title />
            <desc />
            <path
              strokeWidth="4"
              fill="none"
              stroke={entry.color}
              d="M0,16h10.666666666666666
              A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
              H32M21.333333333333332,16
              A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
              className="recharts-legend-icon"
            />
          </svg>
          <span
            className="recharts-legend-item-text"
            style={{ color: entry.color }}
          >
            {entry.value}
          </span>
        </li>
      ))}
  </ul>
)

const CanvasserLinePlot = ({ xAxisKey, yAxisKey, data, canvasserName }) => {
  const { t } = useTranslation()

  return (
    <ResponsiveContainer height="90%">
      <LineChart data={data}>
        <XAxis dataKey={xAxisKey} />
        <YAxis dataKey={yAxisKey} />
        <Tooltip content={<DedupedTooltip />} />
        <Legend content={<DedupedLegend />} />
        <Line
          type="linear"
          dataKey="canvasser"
          name={canvasserName}
          stroke="#348DDF"
          strokeWidth={2}
          strokeDasharray="5 5"
          dot={{ strokeDasharray: '0 0' }}
          connectNulls
        />
        <Line
          type="linear"
          dataKey="canvasser"
          name={canvasserName}
          stroke="#348DDF"
          strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="average"
          name={t('Turf average')}
          stroke="#485973"
          strokeWidth={2}
          strokeDasharray="5 5"
          dot={{ strokeDasharray: '0 0' }}
          connectNulls
        />
        <Line
          type="linear"
          dataKey="average"
          name={t('Turf average')}
          stroke="#485973"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default CanvasserLinePlot
