import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import { PageHeader, Section, Sheet } from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { fetchLocation } from 'requests/locations'
import LocationForm from '../LocationForm/LocationForm'

const LocationEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const { makeRequest, isLoading, hasErrors, isRequestComplete, response } =
    useRequest(fetchLocation)

  const currentLocation = response?.location

  useEffect(() => {
    makeRequest(match.params.id, {
      fields: [
        'id',
        'name',
        'street_address',
        'city',
        'state',
        'zipcode',
        'county',
        'location_type',
        'hours',
        'primary_point_of_contact',
        'phone_number',
        'notes',
        'category',
        { turf: ['id', 'name'] },
      ],
      associations: ['turf'],
    })
  }, [match.params.id])

  const locationLoaded =
    isRequestComplete && currentLocation.id === +match.params.id && !hasErrors

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <PageHeader title={t('Edit Location')} />
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this location")}
          />
          {locationLoaded && (
            <Section>
              <LocationForm location={currentLocation} />
            </Section>
          )}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default LocationEdit
