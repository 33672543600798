import React from 'react'
import {
  ButtonBlock,
  Button,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const TableFooter = ({
  enablePagination,
  page,
  pageSize,
  pageSizeOptions,
  defaultPageSize,
  totalRecords,
  setPage,
  setPageSize,
}) => {
  const { t } = useTranslation()

  const totalPages = Math.ceil(totalRecords / (pageSize || defaultPageSize))
  const pageForward = () => setPage(page + 1)
  const pageBack = () => setPage(page - 1)

  return (
    <div className="paginator__wrapper">
      <Font.Copy variant="secondary" Element="span">
        {totalRecords} {t('results')}
      </Font.Copy>
      {enablePagination && totalRecords > 0 && (
        <ButtonBlock justify="right">
          <div className="paginator__item">
            <select
              name="page-size"
              className="paginator__dropdown"
              value={pageSize}
              onChange={e => {
                setPageSize(+e.target.value)
              }}
            >
              {pageSizeOptions.map(option => (
                <option value={option}>{option}</option>
              ))}
            </select>
            <Font.Copy variant="hint" Element="span">
              {t('Per page')}
            </Font.Copy>
          </div>
          <Button.Secondary onClick={pageBack} disabled={page === 1}>
            <Icon.ArrowLeft />
          </Button.Secondary>
          <Font.Copy variant="hint" Element="span">
            {t('Page')} {page} {t('of')} {totalPages}
          </Font.Copy>
          <Button.Secondary
            onClick={pageForward}
            disabled={page === totalPages}
          >
            <Icon.ArrowRight />
          </Button.Secondary>
        </ButtonBlock>
      )}
    </div>
  )
}

TableFooter.propTypes = {
  enablePagination: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  page: PropTypes.number,
  pageSize: PropTypes.number,
  setPage: PropTypes.func,
  setPageSize: PropTypes.func,
  totalRecords: PropTypes.number,
}

TableFooter.defaultProps = {
  enablePagination: false,
  defaultPageSize: 10,
  pageSizeOptions: [5, 10, 20, 50, 100],
  page: 1,
  pageSize: undefined,
  setPage: () => {},
  setPageSize: () => {},
  totalRecords: 0,
}

export default TableFooter
