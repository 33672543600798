import { useState } from 'react'
import {
  TextField,
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import useEvent from 'hooks/useEvent'

const AddItemModal = ({ visible, close, addNewItem }) => {
  const { t } = useTranslation()
  const [content, setContent] = useState('')

  const closeSelf = () => {
    setContent('')
    close()
  }

  const add = useEvent(() => {
    addNewItem({ type: 'dialogue', content })
    closeSelf()
  })

  return (
    <Modal
      id="add-dialogue-item-modal"
      title={t('Add new dialogue item')}
      isOpen={visible}
      onHide={closeSelf}
    >
      <Modal.Body>
        <ContentBlock>
          <TextField
            label={t('Dialogue text')}
            id="dialogue-text"
            value={content}
            onChange={setContent}
          />
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeSelf}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={add}>{t('Add')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default AddItemModal
