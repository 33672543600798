import { ORGANIZATION_TYPES } from 'constants/organizations'

export const buildForm = organization => ({
  ...organization,
  organization_type: ORGANIZATION_TYPES[organization.organization_type]?.value,
  issues: organization.issues.map(issue => ({
    value: issue,
    label: issue,
  })),
})

export const buildRequest = formData => ({
  ...formData,
  leader_id: formData.leader?.id,
  location_ids: (formData.locations || []).map(location => location.id),
  team_ids: (formData.teams || []).map(team => team.id),
  issues: (formData.issues || []).map(issue => issue.value),
  soft_member_count: +formData.soft_member_count,
})
