import moment from 'moment'

export const buildTableData = data =>
  data
    ? Object.entries(data).map(([name, values], index) => ({
        ...values,
        name,
        index,
      }))
    : []

export const buildReportFilename = (
  reportType,
  { start_date, end_date },
  extension = 'csv'
) =>
  `${reportType}_${moment(start_date).format('YYYY-MM-DD')}_${moment(
    end_date
  ).format('YYYY-MM-DD')}.${extension}`
