import { Link } from 'react-router-dom'
import {
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
  Sheet,
} from '@politechdev/blocks-design-system'
import { View, ViewContainer } from 'components'
import { useTranslation } from 'react-i18next'
import PersonEditForm from '../PersonEditForm/PersonEditForm'

const PersonEdit = ({ history, personId }) => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <PageHeader title={t('Edit person')}>
            <ButtonBlock justify="right">
              <Link to={`/organize/people/${personId}`}>
                <Button.Secondary>
                  <Icon.Times />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </PageHeader>
          <PersonEditForm history={history} personId={personId} />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default PersonEdit
