import { useTranslation } from 'react-i18next'

export const TrainedStates = ({ trainedStates }) => {
  const { t } = useTranslation()

  return (
    <p className="trained-states">
      {trainedStates?.length
        ? t('Trained in')
        : t('No trained states assigned')}
      {trainedStates?.reduce((fullString, stateAbbrev, i) => {
        const comma = i > 0 ? ',' : ''
        return `${fullString}${comma} ${stateAbbrev}`
      }, '')}
    </p>
  )
}
