export const ORGANIZATION_TYPES = {
  Congregation: {
    value: 'Congregation',
    label: 'Congregation',
  },
  'Partner/Ally': {
    value: 'Partner/Ally',
    label: 'Partner/Ally',
  },
  'Labor Union': {
    value: 'Labor Union',
    label: 'Labor Union',
  },
  'Community Group': {
    value: 'Community Group',
    label: 'Community Group',
  },
  'Denominational Office': {
    value: 'Denominational Office',
    label: 'Denominational Office',
  },
  'Social Service Organization': {
    value: 'Social Service Organization',
    label: 'Social Service Organization',
  },
  'Neighborhood Association': {
    value: 'Neighborhood Association',
    label: 'Neighborhood Association',
  },
  Unknown: {
    value: 'Unknown',
    label: 'Unknown',
  },
  School: {
    value: 'School',
    label: 'School',
  },
  Funder: {
    value: 'Funder',
    label: 'Funder',
  },
  Tribe: {
    value: 'Tribe',
    label: 'Tribe',
  },
  'Government Agency': {
    value: 'Government Agency',
    label: 'Government Agency',
  },
  'Homeless Encampment': {
    value: 'Homeless Encampment',
    label: 'Homeless Encampment',
  },
  Coalition: {
    value: 'Coalition',
    label: 'Coalition',
  },
}

export const ALL_ORGANIZATION_TYPES = [
  ORGANIZATION_TYPES.Coalition,
  ORGANIZATION_TYPES['Community Group'],
  ORGANIZATION_TYPES.Congregation,
  ORGANIZATION_TYPES['Denominational Office'],
  ORGANIZATION_TYPES.Funder,
  ORGANIZATION_TYPES['Government Agency'],
  ORGANIZATION_TYPES['Homeless Encampment'],
  ORGANIZATION_TYPES['Labor Union'],
  ORGANIZATION_TYPES['Neighborhood Association'],
  ORGANIZATION_TYPES['Partner/Ally'],
  ORGANIZATION_TYPES.School,
  ORGANIZATION_TYPES['Social Service Organization'],
  ORGANIZATION_TYPES.Tribe,
  ORGANIZATION_TYPES.Unknown,
]
