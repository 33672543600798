import React from 'react'
import { FontIcon } from 'react-md'
import { TextField, Button } from 'components'
import { useDeliveryActions, useDeliveryState } from '../DeliveryContext'
import usePacketListHeader from './hooks/usePacketListHeader'

const PacketListHeader = () => {
  const {
    packetSortType: sortType,
    packetSortOrder: sortOrder,
    packetSearchTerm: searchTerm,
  } = useDeliveryState()
  const {
    setPacketSortType,
    setPacketSortOrder,
    setPacketSearchTerm: setSearch,
  } = useDeliveryActions()

  const { alphaIcon, dateIcon, toggleSort } = usePacketListHeader(
    sortType,
    sortOrder,
    setPacketSortType,
    setPacketSortOrder
  )

  return (
    <div className="form-viewer__toolbar">
      <div className="form-viewer__toolbar__container">
        <div className="form-viewer__search">
          <TextField
            id="packet-search"
            onChange={setSearch}
            value={searchTerm}
            aria-label="Search packets"
            leftIcon={<FontIcon>search</FontIcon>}
          />
        </div>
        <div className="sort-button__group">
          <Button
            icon
            className={`sort-button ${
              sortType === 'alpha' ? 'sort-button--active' : ''
            }`}
            onClick={() => toggleSort('alpha')}
          >
            <img alt="sort alphabetically" src={alphaIcon} />
          </Button>
          <Button
            icon
            className={`sort-button ${
              sortType === 'date' ? 'sort-button--active' : ''
            }`}
            onClick={() => toggleSort('date')}
          >
            <img alt="sort by date" src={dateIcon} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PacketListHeader
