import { calculatePercent } from 'utils/reporting'
import { sum, average } from 'registrationReports/reportsConfig/columnTotals'

export default [
  {
    dataKey: 'canvasser_name',
    title: 'Name',
    type: 'string',
    frozen: 'left',
    sortable: true,
    resizable: true,
    deriveFunction: ({ rowData }) =>
      `${rowData.first_name} ${rowData.last_name}`,
  },
  {
    dataKey: 'turf_name',
    title: 'Turf Name',
    type: 'string',
    sortable: true,
    resizable: true,
  },
  {
    dataKey: 'total_scans_qc',
    title: 'Total Forms Through QC',
    type: 'number',
    sortable: true,
    resizable: true,
    summaryFunction: sum,
  },
  {
    dataKey: 'complete_pct',
    title: '% Forms Complete',
    type: 'number',
    sortable: true,
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.complete_qc, rowData.total_scans_qc),
    bodyCell: ({ cellData, rowData }) => {
      if (cellData < 90 && rowData.total_scans_qc !== 0) {
        // eslint-disable-next-line react/jsx-filename-extension
        return <div className="border-cell--warn">{`${cellData}%`}</div>
      }
      return `${cellData}%`
    },
    summaryFunction: average,
  },
  {
    dataKey: 'total_disconnected_pct',
    title: 'Disconnect %',
    type: 'number',
    sortable: true,
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.disconnected_qc, rowData.total_scans_qc),
    bodyCell: ({ cellData }) => `${cellData}%`,
    summaryFunction: average,
  },
  {
    dataKey: 'total_wrong_number_pct',
    title: 'Wrong Number %',
    type: 'number',
    sortable: true,
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.wrong_number_qc, rowData.total_scans_qc),
    bodyCell: ({ cellData }) => `${cellData}%`,
    summaryFunction: average,
  },
  {
    dataKey: 'flags_per_hundred',
    title: 'Flags per 100 Forms',
    type: 'number',
    sortable: true,
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.flags, rowData.total_scans_qc),
    summaryFunction: average,
  },
]
