import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'
import {
  ZipCodeField,
  CardError,
  LoadBar,
  StateSelectField,
  LocationSelectField,
  TeamSelectField,
  PersonSelectField,
  DenominationSelectField,
} from 'components'
import {
  SelectField,
  MultiSelectField,
  Section,
  TextField,
  FieldBlock,
  ButtonBlock,
  Button,
  Switch,
  NumberField,
  useToast,
} from '@politechdev/blocks-design-system'
import { useCurrent, useForm } from 'contexts'
import { useRequest, useReactRouter } from 'hooks'
import { ALL_ORGANIZATION_TYPES } from 'constants/organizations'
import {
  fetchOrganizations,
  postOrganization,
  putOrganization,
} from 'requests/organizations'
import { LOCATION_TYPES } from 'constants/locations'
import { formatErrorMessage } from 'utils/formatting'
import { buildForm, buildRequest } from './utils'
import styles from './OrganizationForm.module.scss'

const OrganizationForm = ({ organization }) => {
  const isEditForm = !!organization
  const formAction = isEditForm
    ? updatedOrg => putOrganization(organization.id, updatedOrg)
    : postOrganization

  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { setToast } = useToast()

  const {
    membershipTypeOptions,
    showDenominations,
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfActivismIssues: issues,
  } = useCurrent()

  const issueOptions = issues.map(issue => ({ label: issue, value: issue }))

  const { formData, setField, setFormData } = useForm({})

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(formAction, {
    onSuccess: ({ 'people/organization': organization }) => {
      history.push(`/organize/organizations/${organization.id}`)
    },
  })

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    organization
      ? setFormData(buildForm(organization))
      : setFormData({ active: true })
  }, [])

  const fetchOrganizationsRequest = useRequest(fetchOrganizations, {
    onError: () => {
      setToast({
        variant: 'error',
        message: t('Failed to check for unique name'),
      })
    },
  })

  const [debouncedfetchOrgsReq] = useDebouncedCallback(
    fetchOrganizationsRequest.makeRequest,
    500
  )

  const isOrganizationNameTaken =
    fetchOrganizationsRequest.response?.meta.total_count > 0

  const isFormValid = () =>
    !isEmpty(formData.name) &&
    !isEmpty(formData.address?.line_one) &&
    !isEmpty(formData.address?.city) &&
    !isEmpty(formData.address?.state) &&
    !isEmpty(formData.address?.zipcode) &&
    !isEmpty(formData.organization_type) &&
    !isOrganizationNameTaken

  useEffect(() => {
    if (!formData.name) return

    const rules = [
      {
        column: 'name',
        operator: 'is',
        param: formData.name,
      },
      {
        column: 'turf',
        operator: 'is',
        param: organization ? organization.turf_id : turfId,
      },
    ]

    debouncedfetchOrgsReq({
      fields: ['name'],
      indexed: true,
      filters: {
        rules: organization
          ? [
              ...rules,
              {
                column: 'id',
                operator: 'is',
                param: organization.id,
                invert: true,
              },
            ]
          : rules,
      },
    })
  }, [formData.name])

  const submitForm = e => {
    e.preventDefault()
    makeRequest(buildRequest(formData))
  }

  return (
    <>
      <LoadBar show={isLoading} />
      <CardError hide={!hasErrors} message={errorMsg} />
      <form onSubmit={submitForm}>
        <Section>
          <FieldBlock>
            <TextField
              id="name"
              label={t('Organization name')}
              value={formData.name}
              onChange={val => setField(val, 'name')}
              required
              error={isOrganizationNameTaken}
              errorMessage="Name has already been taken"
              loading={fetchOrganizationsRequest.isLoading}
            />
            <SelectField
              id="organization-type"
              label={t('Organization type')}
              options={ALL_ORGANIZATION_TYPES}
              value={formData.organization_type}
              onSelect={val => setField(val, 'organization_type')}
              required
            />
            <PersonSelectField
              id="leader"
              label={t('Leader')}
              person={formData.leader}
              clearable
              onSelect={val => setField(val || { id: null }, 'leader')}
            />
          </FieldBlock>
        </Section>
        <Section label="Contact">
          <FieldBlock>
            <TextField
              id="phone_number"
              type="tel"
              label={t('Phone number')}
              value={formData.phone_number}
              onChange={val => setField(val, 'phone_number')}
            />
            <TextField
              id="website"
              label={t('Website')}
              value={formData.website}
              onChange={val => setField(val, 'website')}
            />
          </FieldBlock>
        </Section>
        <Section label="Address">
          <FieldBlock>
            <TextField
              id="street_address"
              label={t('Street address')}
              value={formData.address?.line_one}
              onChange={val =>
                setField({ ...formData.address, line_one: val }, 'address')
              }
              required
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="city"
              label={t('City')}
              value={formData.address?.city}
              onChange={val =>
                setField({ ...formData.address, city: val }, 'address')
              }
              required
            />
            <StateSelectField
              state={formData.address?.state}
              onSelect={val =>
                setField({ ...formData.address, state: val }, 'address')
              }
              required
            />
            <ZipCodeField
              id="zipcode"
              label={t('Zip code')}
              value={formData.address?.zipcode}
              onChange={val =>
                setField({ ...formData.address, zipcode: val }, 'address')
              }
              required
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="mailing_street_address"
              label={t('Mailing address')}
              value={formData.mailing_street_address}
              onChange={val => setField(val, 'mailing_street_address')}
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="mailing_city"
              label={t('Mailing city')}
              value={formData.mailing_city}
              onChange={val => setField(val, 'mailing_city')}
            />
            <StateSelectField
              id="mailing_state"
              label={t('Mailing state')}
              state={formData.mailing_state}
              onSelect={val => setField(val, 'mailing_state')}
              clearable
            />
            <ZipCodeField
              id="mailing_zipcode"
              label={t('Mailing zip code')}
              value={formData.mailing_zipcode}
              onChange={val => setField(val, 'mailing_zipcode')}
            />
          </FieldBlock>
        </Section>
        <Section label="Details">
          <FieldBlock>
            <Switch
              className={styles.switch}
              name="active"
              label={t('Active')}
              onChange={val => setField(val, 'active')}
              value={formData.active}
            />
          </FieldBlock>
          <FieldBlock>
            <NumberField
              id="soft_member_count"
              label={t('Member count')}
              value={formData.soft_member_count}
              type="number"
              min="0"
              onChange={val => setField(val, 'soft_member_count')}
            />
            <SelectField
              id="membership-type"
              label={t('Membership type')}
              options={membershipTypeOptions}
              value={formData.membership_type}
              onSelect={val => setField(val, 'membership_type')}
            />
          </FieldBlock>
          <FieldBlock>
            <SelectField
              id="influence_level"
              label={t('Influence level')}
              value={formData.influence_level}
              options={[...Array(5)].map((_, index) => ({
                value: index + 1,
                label: index + 1,
              }))}
              onSelect={val => setField(val, 'influence_level')}
            />
          </FieldBlock>
          <FieldBlock>
            <MultiSelectField
              id="issues"
              label={t('Organization focus')}
              values={formData.issues || []}
              options={issueOptions}
              onSelect={val => setField(val, 'issues')}
            />
          </FieldBlock>
          <FieldBlock>
            <LocationSelectField
              locations={formData.locations}
              locationTypes={[LOCATION_TYPES.venue]}
              isMulti
              onSelect={val => setField(val, 'locations')}
            />
            <TeamSelectField
              teams={formData.teams || []}
              isMulti
              onSelect={val => setField(val, 'teams')}
            />
            {showDenominations && (
              <DenominationSelectField
                denominations={formData.denominations || []}
                onSelect={val => setField(val, 'denominations')}
              />
            )}
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="notes"
              label={t('Notes')}
              value={formData.notes}
              onChange={val => setField(val, 'notes')}
            />
          </FieldBlock>
        </Section>
        <ButtonBlock justify="left">
          <Button.Accent type="submit" disabled={!isFormValid() || isLoading}>
            {t(isEditForm ? 'Save Organization' : 'Create Organization')}
          </Button.Accent>
        </ButtonBlock>
      </form>
    </>
  )
}

export default OrganizationForm
