import { SelectField } from '@politechdev/blocks-design-system'
import ArchivedItem from './ArchivedItem'

const FlatTurfSelectField = ({
  turfs,
  onSelect,
  onInputChange,
  value,
  label,
  disabled,
  disableArchived,
  isLoading,
  required,
  clearable,
  onClear,
}) => (
  <SelectField
    label={label}
    value={value}
    onInputChange={onInputChange}
    options={turfs.map(turf => ({
      ...turf,
      styledLabel: turf.archived && (
        <ArchivedItem label={turf.name} disabled={disabled} />
      ),
      disabled: turf.archived && disableArchived,
    }))}
    onSelect={onSelect}
    disabled={disabled}
    isLoading={isLoading}
    required={required}
    clearable={clearable}
    onClear={onClear}
  />
)

export default FlatTurfSelectField
