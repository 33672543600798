import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchForm = (id, params) =>
  fetchJSON(`/api/v1/forms/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const putForm = (id, form) =>
  fetchJSON(
    `/api/v1/forms/${id}`,
    'PUT',
    { form },
    {
      useJwt: true,
    }
  )

export const fetchForms = params =>
  fetchJSON(`/api/v1/forms?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })
