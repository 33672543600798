import React from 'react'
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { ContextMenu } from 'components'
import { Icon } from '@politechdev/blocks-design-system'
import { useScroll, useContextMenu } from 'contexts'
import { useModalTrigger } from '../ModalProvider/ModalProvider'
import { useDocumentClipboard } from '../ClipboardProvider/ClipboardProvider'
import { usePermissions } from '../PermissionsProvider/PermissionsProvider'
import DocumentLink from '../DocumentLink/DocumentLink'
import DocumentIcon from '../DocumentIcon/DocumentIcon'
import ResourceIcon from '../ResourceIcon/ResourceIcon'
import { INTERACTABLE_TYPES, MODAL_TYPES } from '../constants'
import { isItemDocument } from '../utils'

const FolderContentDocument = ({ document }) => {
  const { t } = useTranslation()
  const { canModify, canDelete } = usePermissions()
  const { endScroll } = useScroll()

  const [{ isDragging }, dragRef] = useDrag({
    item: {
      id: document.id,
      parent_id: document.folder_id,
      type: INTERACTABLE_TYPES.PANEL_DOCUMENT,
    },
    isDragging: monitor => {
      const item = monitor.getItem()
      return document.id === item.id && isItemDocument(item)
    },
    end: endScroll,
    canDrag: () => canModify,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const openModal = useModalTrigger()

  const { showMenu, isMenuActive } = useContextMenu({
    id: [INTERACTABLE_TYPES.PANEL_DOCUMENT, document.id],
  })

  const { isCutting, isCopying, cutItem, copyItem } = useDocumentClipboard({
    document,
  })

  return (
    <div>
      <DocumentLink url={document.file_url} onContextMenu={showMenu}>
        <ResourceIcon
          name={document.name}
          icon={<DocumentIcon mime_type={document.mime_type} />}
          isDisabled={isDragging}
          isFocused={isMenuActive}
          isClipboard={isCutting || isCopying}
          ref={dragRef}
        />
      </DocumentLink>
      <ContextMenu id={[INTERACTABLE_TYPES.PANEL_DOCUMENT, document.id]}>
        <ContextMenu.Item
          label={t('Copy')}
          icon={<Icon.Copy alt="" />}
          onClick={copyItem}
          isDisabled={isCopying || !canModify}
        />
        <ContextMenu.Item
          label={t('Cut')}
          icon={<Icon.Cut alt="" />}
          onClick={cutItem}
          isDisabled={isCutting || !canModify}
        />
        <ContextMenu.Divider />
        <ContextMenu.Item
          label={t('Info')}
          icon={<Icon.Info alt="" />}
          onClick={() => {
            openModal(MODAL_TYPES.DETAIL_DOCUMENT, {
              document,
            })
          }}
        />
        <ContextMenu.Item
          label={t('Rename')}
          icon={<Icon.Pen alt="" />}
          isDisabled={!canModify}
          onClick={() => {
            openModal(MODAL_TYPES.RENAME_DOCUMENT, {
              document,
            })
          }}
        />
        <ContextMenu.Item
          label={t('Delete')}
          icon={<Icon.TrashAlt alt="" />}
          isDisabled={!canDelete}
          onClick={() => {
            openModal(MODAL_TYPES.DELETE_DOCUMENT, {
              document,
            })
          }}
        />
      </ContextMenu>
    </div>
  )
}

export default FolderContentDocument
