import React from 'react'
import { Radio } from '@politechdev/blocks-design-system'

const SingleChoice = ({ options = [], value, onSelect }) => (
  <div>
    {options.map(choice => (
      <Radio
        key={choice.id}
        label={choice.response}
        checked={value === choice.id}
        onChange={() => onSelect(choice.id)}
      />
    ))}
  </div>
)

export default SingleChoice
