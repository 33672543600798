import React from 'react'
import {
  createContext,
  useContext,
  useContextSelector,
} from 'use-context-selector'
import { useControlled } from 'hooks'
import { DEFAULT_VIEWPORT } from '../constants'

const ViewportContext = createContext()

export const ViewportProvider = ({
  viewport: controlledViewport,
  onChange,
  children,
}) => {
  const [viewport, setViewport] = useControlled({
    default: DEFAULT_VIEWPORT,
    value: controlledViewport,
    onChange,
  })

  return (
    <ViewportContext.Provider value={[viewport, setViewport]}>
      {children}
    </ViewportContext.Provider>
  )
}

export const useViewport = () => useContext(ViewportContext)

export const useViewportState = () =>
  useContextSelector(ViewportContext, value => value[0])

export const useViewportDispatch = () =>
  useContextSelector(ViewportContext, value => value[1])
