import { useRef, useEffect } from 'react'
import {
  ButtonBlock,
  Font,
  TextBlock,
  ContentBlock,
  DetailItem,
  Section,
  Grid,
  Button,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { ViewContainer, View } from 'components'
import { useTranslation } from 'react-i18next'
import styles from './Operator.module.scss'
import OperatorSummary from '../OperatorSummary/OperatorSummary'
import OperatorDial from '../OperatorDial/OperatorDial'
import OperatorGauge from '../OperatorGauge/OperatorGauge'
import EndSessionModal from '../EndSessionModal/EndSessionModal'
import AbandonRateExceededModal from '../AbandonRateExceededModal/AbandonRateExceededModal'
import {
  useCallStats,
  OperatorContextProvider,
  useOperatorActions,
} from '../OperatorContext/OperatorContext'
import { formatRelativeTime, formatLocalTime } from '../util'
import OperatorGaugeLegend from '../OperatorGauge/OperatorGaugeLegend'

const MainDisplay = () => {
  const { t } = useTranslation()
  const {
    remainingContacts,
    startEndSession,
    channelError,
    socketDisconnected,
  } = useCallStats()
  const { endSession } = useOperatorActions()

  if (socketDisconnected) {
    return (
      <>
        <ContentBlock className={styles.highlight}>
          <Font.Copy
            variant="highlight"
            className={styles['socket-disconnect-warning']}
          >
            {t('Error: Connection interrupted.')}
          </Font.Copy>
        </ContentBlock>
        <ContentBlock className={styles.highlight}>
          <Font.Copy variant="highlight">
            {t(
              'The session was closed due to an error. You may try to start the session again.'
            )}
          </Font.Copy>
        </ContentBlock>
        <ContentBlock className={styles.highlight}>
          <ButtonBlock justify="center">
            <Button.Accent onClick={() => window.location.reload()}>
              {t('Start a new session')}
            </Button.Accent>
            <Button.Secondary onClick={endSession}>
              {t('Quit')}
            </Button.Secondary>
          </ButtonBlock>
        </ContentBlock>
      </>
    )
  }

  return (
    <>
      {remainingContacts === 0 && (
        <ContentBlock className={styles.highlight}>
          <Font.Copy variant="highlight">
            {t('All contacts have been attempted this round')}
          </Font.Copy>
        </ContentBlock>
      )}
      {startEndSession && (
        <ContentBlock className={styles.highlight}>
          <Font.Copy variant="highlight">
            {t(
              'Session ended. This session will shut down when the remaining agents have finished their calls and submissions.'
            )}
          </Font.Copy>
        </ContentBlock>
      )}
      {channelError && (
        <TextBlock className={styles.highlight}>
          <Font.Copy variant="error">
            {t('There was an error connecting with the dialer.')}
          </Font.Copy>
        </TextBlock>
      )}
      <div className={styles.chart}>
        <div className={styles.chart__main}>
          <OperatorDial />
          <OperatorGauge />
        </div>
        <div className={styles.chart__legend}>
          <OperatorGaugeLegend />
        </div>
      </div>
      <OperatorSummary />
    </>
  )
}

const Operator = () => {
  const { t } = useTranslation()
  const {
    startTime,
    completedCalls,
    remainingContacts,
    participantsReached,
    showAbandonRateModal,
    setShowAbandonRateModal,
  } = useCallStats()
  const { endSession } = useOperatorActions()

  const timeoutRef = useRef()
  const sessionLengthRef = useRef()
  useEffect(() => {
    if (sessionLengthRef.current) {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
      }
      timeoutRef.current = setInterval(() => {
        sessionLengthRef.current.innerText = formatRelativeTime(
          Date.now() - startTime
        )
      }, 1000)
    }
    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
      }
    }
  })

  return (
    <div className={styles.operator}>
      <PageHeader title={t('Operator')}>
        <ButtonBlock justify="right">
          <EndSessionModal endSession={endSession} />
        </ButtonBlock>
      </PageHeader>
      <View>
        <Grid>
          <Section>
            <DetailItem label="Session length">
              <span ref={sessionLengthRef}>
                {formatRelativeTime(Date.now() - startTime)}
              </span>
            </DetailItem>
            <DetailItem label="Start time">
              {formatLocalTime(startTime)}
            </DetailItem>
          </Section>
          <Section>
            <DetailItem label="Calls made">{completedCalls}</DetailItem>
            <DetailItem label="Participants reached">
              {participantsReached}
            </DetailItem>
            <DetailItem label="Remaining contacts">
              {remainingContacts}
            </DetailItem>
          </Section>
        </Grid>
        <ViewContainer>
          <MainDisplay />
        </ViewContainer>
      </View>
      <AbandonRateExceededModal
        modalOpen={showAbandonRateModal}
        setModalOpen={setShowAbandonRateModal}
      />
    </div>
  )
}

export default () => (
  <OperatorContextProvider>
    <Operator />
  </OperatorContextProvider>
)
