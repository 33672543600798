export const sanitizeStr = (val, maxLength = 50) =>
  val.replace(/[<>=?,!]/g, '').substring(0, maxLength)

export const sanitizeNum = (val, maxLength = 50) =>
  val.replace(/\D/g, '').substring(0, maxLength)

const START = String.raw`^`
const OPEN_GROUP = String.raw`(`
const CLOSE_GROUP = String.raw`)`
const OR = String.raw`|`
const END = String.raw`$`
const AT = String.raw`@`
const OPEN_BRACKET = String.raw`\[`
const CLOSE_BRACKET = String.raw`\]`
const UNQUOTED_EMAIL_USERNAME = String.raw`([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)`
const QUOTED_EMAIL_USERNAME = String.raw`(".+")`
const IPV4_ADDRESS = String.raw`([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})`
const DOMAIN_NAME = String.raw`(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})`
const PORT = String.raw`(\:\d+)?`
const PATH = String.raw`(\/[-a-zA-Z\d%_.~+]*)*`
const QUERY_STRING = String.raw`(\?[;&a-zA-Z\d%_.~+=-]*)?`
const ANCHOR = String.raw`(\#[-a-zA-Z\d_]*)?`
const PROTOCOL = String.raw`(https?:\/\/)`

export const isValidEmail = email => {
  const expression = new RegExp(
    START +
      OPEN_GROUP +
      UNQUOTED_EMAIL_USERNAME +
      OR +
      QUOTED_EMAIL_USERNAME +
      CLOSE_GROUP +
      AT +
      OPEN_GROUP +
      OPEN_BRACKET +
      IPV4_ADDRESS +
      CLOSE_BRACKET +
      OR +
      DOMAIN_NAME +
      CLOSE_GROUP +
      END
  )
  return expression.test(String(email.toLowerCase()))
}

export const isValidUrl = url => {
  const pattern = new RegExp(
    START +
      PROTOCOL +
      OPEN_GROUP +
      DOMAIN_NAME +
      OR +
      IPV4_ADDRESS +
      CLOSE_GROUP +
      PORT +
      PATH +
      QUERY_STRING +
      ANCHOR +
      END
  )
  return !!pattern.test(url)
}

export const isValidPhone = (phone = '') => phone && phone.length === 10

export const VALID_PHONE_LENGTH = 14
