import React from 'react'
import { Switch, Route } from 'react-router-dom'
import UserSettings from '../UserSettings/UserSettings'

const UserSettingRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={UserSettings} />
  </Switch>
)

export default UserSettingRoutes
