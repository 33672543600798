import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchFolders = (params = {}) => {
  const paginationParams = {
    current_page: 1,
    per: Number.MAX_SAFE_INTEGER,
    sort_attr: 'lft',
    sort_dir: 'asc',
  }

  const queryString = stringifyParams({ ...params, ...paginationParams })

  return fetchJSON(`/api/v1/folders?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const destroyFolder = id =>
  fetchJSON(`/api/v1/folders/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const fetchFolder = (id, params) =>
  fetchJSON(`/api/v1/folders/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postFolder = folder =>
  fetchJSON(`/api/v1/folders`, 'POST', { folder }, { useJwt: true })

export const putFolder = (id, folder) =>
  fetchJSON(
    `/api/v1/folders/${id}`,
    'PUT',
    { folder },
    {
      useJwt: true,
    }
  )
