import { uniqBy } from 'lodash'
import { fetchJSON, stringifyParams } from 'utils/req'
import { createValidatorFromSchema, validate } from 'utils/schemaValidation'

const { isIncludedIn } = validate

export const COLUMN_NAME = 'with_phone_bank_activity'

const phoneBankQuestionTagParams = {
  fields: ['id', 'name', { answer_options: ['response'] }],
}

export const fetchPhoneBankQuestionTags = params => {
  const queryString = {
    ...phoneBankQuestionTagParams,
    ...params,
  }
  return fetchJSON(
    `/api/v1/phone_banking_questions/tags?${stringifyParams(queryString)}`,
    'GET',
    null,
    { useJwt: true }
  )
}

const buildTagAnswerOption = (tagId, tagName, answer) => {
  const label = `${tagName}: ${answer}`
  const value = { tagId, answer }
  return {
    label,
    value,
  }
}

export const buildTagAnswerOptions = tags =>
  tags.flatMap(({ id, name, answer_options }) => {
    const responses = uniqBy(answer_options, 'response').map(
      ({ response }) => response
    )
    return responses.map(response => buildTagAnswerOption(id, name, response))
  })

export const buildSelectedTags = (rule = {}, meta = {}) => {
  const taggedResponses = rule.param?.tagged_responses || []
  const labels = meta.labels || []
  const labelMap = Object.fromEntries(labels)
  return taggedResponses.map(([tagId, answer]) => ({
    label: labelMap[tagId + answer],
    value: {
      tagId,
      answer,
    },
  }))
}

export const updateRule = ({ params, filter, setFilterValue }) => {
  const newRule = {
    column: COLUMN_NAME,
    param: {
      tagged_responses: params.tagged_responses.map(({ value }) => [
        value.tagId,
        value.answer,
      ]),
    },
  }

  const meta = {
    labels: params.tagged_responses.map(({ value, label }) => [
      value.tagId + value.answer,
      label,
    ]),
  }

  setFilterValue(
    filter,
    {
      rules: [newRule],
    },
    meta
  )
}

export const buildTagsReqParams = debounced => ({
  filters: {
    rules: debounced.length
      ? [
          {
            column: 'name',
            operator: 'containing',
            param: debounced,
          },
        ]
      : [],
  },
})

export const phonebankTagsFormatForAPI = filter => filter.value || { rules: [] }
export const phonebankTagsFormatForUI = filter => filter.params || { rules: [] }

const isValidScopeParam = param => {
  if (!param || !param.tagged_responses) return false

  return Array.isArray(param.tagged_responses)
}

const isValidPhonebankTagRule = createValidatorFromSchema({
  column: [isIncludedIn([COLUMN_NAME])],
  param: [isValidScopeParam],
})

export const fromApiPhonebankTagsValidator = createValidatorFromSchema({
  meta: {
    labels: [Array.isArray],
  },
  params: {
    rules: [rules => rules.some(isValidPhonebankTagRule)],
  },
})

export const fromUiPhonebankTagsValidator = createValidatorFromSchema({
  meta: {
    labels: [Array.isArray],
  },
  value: {
    rules: [rules => rules.some(isValidPhonebankTagRule)],
  },
})
