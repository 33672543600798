import { Section, FormattedData } from 'components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { READY } from 'constants/phoneBankSessions'
import styles from './CallPanelInfo.module.scss'

const CallPanelInfo = ({ phoneNumber, callStep, showPhone }) => {
  const { t } = useTranslation()
  if (callStep === READY) {
    return (
      <div className={styles.content}>
        <ol className={styles.instructions}>
          {!phoneNumber ? (
            <li className={styles.warning}>
              {t(
                'Please view participant details and update primary phone number before starting call.'
              )}
            </li>
          ) : null}
          <li>
            {t(
              'Read through the script on your own and familiarize yourself with it.'
            )}
          </li>
          <li>
            {t(
              'Review the participant information and start the call when you’re ready.'
            )}
          </li>
        </ol>
        {showPhone && (
          <Section>
            <span className={styles.label}>Phone number</span>
            <p>
              <FormattedData value={phoneNumber} type="phone" />
            </p>
          </Section>
        )}
      </div>
    )
  }
  return (
    <div className={styles.content}>
      <Section>
        <span className={styles.label}>Phone number</span>
        <p>
          <FormattedData value={phoneNumber} type="phone" />
        </p>
      </Section>
    </div>
  )
}

export default CallPanelInfo

CallPanelInfo.propTypes = {
  phoneNumber: PropTypes.string,
  showPhone: PropTypes.bool,
}
