import { combineName, summaryFunctions } from 'utils/reporting'

export default {
  user_activity: {
    columns: [
      {
        dataKey: 'name',
        title: 'User',
        type: 'string',
        deriveFunction: ({ rowData }) =>
          combineName(rowData.user_first_name, rowData.user_last_name),
      },
      {
        dataKey: 'last_sign_in_at',
        title: 'Last Sign In Date',
        type: 'date',
      },
      {
        dataKey: 'events_added',
        title: 'Events added',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'teams_added',
        title: 'Teams added',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'people_added',
        title: 'People added',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
    ],
  },
}
