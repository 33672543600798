import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, TextField, Button as IconButton } from 'components'
import {
  Button,
  TextBlock,
  Modal,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useEvent, useRequest } from 'hooks'
import { postEmail } from 'requests/lists'
import styles from './ListEmailModal.module.scss'

const ListEmailModal = ({ currentList }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setSubject('')
    setBody('')
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(postEmail, {
    onSuccess: closeModal,
  })

  const makeEmailRequest = useEvent(() => {
    makeRequest(currentList.id, { subject, body })
  })

  return (
    <>
      <IconButton icon tooltipLabel={t('Email list')} onClick={openModal}>
        mail_outline
      </IconButton>
      <Modal
        id="list-email"
        title={t(`Email list`)}
        isOpen={modalOpen}
        showLoading={isLoading}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error sending your email')}
              hideSupportLink
            />
            <TextBlock className={styles.text}>
              {t('Compose your email below')}
            </TextBlock>
            <div className={styles.field}>
              <TextField
                id="subject"
                label={t('Subject')}
                value={subject}
                onChange={setSubject}
                required
              />
            </div>
            <div className={styles.field}>
              <TextField
                id="body"
                label={t('Body')}
                rows={6}
                value={body}
                onChange={setBody}
                required
              />
            </div>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent
              onClick={makeEmailRequest}
              disabled={subject === '' || body === '' || isLoading}
            >
              {t('Send')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ListEmailModal
