import React from 'react'
import { PdfViewer } from 'components'
import classNames from 'classnames/bind'

import styles from './DocumentSelector.module.scss'

const cx = classNames.bind(styles)

const DocumentSelector = ({ fileUrl, scanNumber, isSelected }) => {
  if (!fileUrl) return null
  return (
    <PdfViewer
      file={fileUrl}
      showScanNumber
      scanNumber={scanNumber}
      className={cx('preview', {
        'preview--selected': isSelected,
      })}
    />
  )
}

export default DocumentSelector
