import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { CardError, TextField, Button as IconButton } from 'components'
import {
  Button,
  TextBlock,
  Modal,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useEvent, useRequest } from 'hooks'
import { postSms } from 'requests/lists'
import styles from './ListSmsModal.module.scss'

const cx = classNames.bind(styles)

const MAX_MESSAGE_LENGTH = 150

const ListSmsModal = ({ currentList }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [message, setMessage] = useState('')

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setMessage('')
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(postSms, {
    onSuccess: closeModal,
  })

  const makeSmsRequest = useEvent(() => {
    makeRequest(currentList.id, message)
  })

  return (
    <>
      <IconButton icon tooltipLabel={t('Text list')} onClick={openModal}>
        textsms
      </IconButton>
      <Modal
        id="list-sms"
        title={t(`Text list`)}
        isOpen={modalOpen}
        showLoading={isLoading}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error texting this list')}
              hideSupportLink
            />
            <TextBlock className={styles.text}>
              {t('Compose your text message below')}
            </TextBlock>
            <div className={styles.field}>
              <TextField
                id="message"
                label={t('Message')}
                rows={6}
                value={message}
                onChange={val => {
                  setMessage(val.substring(0, MAX_MESSAGE_LENGTH))
                }}
              />
            </div>
            <TextBlock
              className={cx('hint', {
                'hint--warn': message.length === MAX_MESSAGE_LENGTH,
              })}
            >
              {`${message.length} / ${MAX_MESSAGE_LENGTH} ${t(
                'characters remaining'
              )}`}
            </TextBlock>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent
              onClick={makeSmsRequest}
              disabled={message === '' || isLoading}
            >
              {t('Send')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ListSmsModal
