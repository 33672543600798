import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md'
import { Font, SelectField, TextBlock } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import styles from './SpreadsheetPreview.module.scss'

const SpreadsheetPreview = props => {
  const { t } = useTranslation()
  const { importable_attrs, setMappings, preview_data } = props
  const [fields, setFields] = useState({})
  const headers = preview_data[0]
  const rows = preview_data.slice(-(preview_data.length - 1))
  const [selectedAttributes, setSelectedAttributes] = useState([])

  const importable_attrs_options = importable_attrs

  useEffect(() => {
    if (selectedAttributes.length) {
      setMappings(selectedAttributes)
    }
  }, [selectedAttributes])

  const handleMappingSelect = (attribute, columnIndex) => {
    setSelectedAttributes(prevSelectedAttributes => {
      const nextSelectedAttributes = prevSelectedAttributes.filter(
        selectedAttribute => selectedAttribute.columnIndex !== columnIndex
      )
      attribute !== 'ignore' &&
        nextSelectedAttributes.push({ attribute, columnIndex })
      return nextSelectedAttributes
    })
  }

  return (
    <div className={styles.container}>
      <TextBlock>
        <Font.Copy variant="secondary">
          {t(
            'Select the correct field header for each column you would like to map for the import. All other fields are ignored.'
          )}
        </Font.Copy>
      </TextBlock>
      <DataTable plain>
        <TableHeader>
          <TableRow>
            {headers.map((column, i) => (
              <TableColumn key={`select-field-${i}`}>
                <SelectField
                  className={styles.select}
                  id={column}
                  label={t('Assign column')}
                  options={importable_attrs_options}
                  onSelect={value => {
                    setFields(prevFields => {
                      const toClear = Object.values(prevFields).indexOf(value)
                      const key = Object.keys(prevFields)[toClear]
                      return key
                        ? {
                            ...prevFields,
                            [key]: null,
                            [column]: value,
                          }
                        : {
                            ...prevFields,
                            [column]: value,
                          }
                    })
                    handleMappingSelect(value, i)
                  }}
                  value={fields[column]}
                />
              </TableColumn>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {[
            <TableRow key="column-headers">
              {headers.map((column, i) => (
                <TableColumn key={`column-header-${i}`}>
                  <Font.Copy variant="secondary" Element="em">
                    {column}
                  </Font.Copy>
                </TableColumn>
              ))}
            </TableRow>,
          ].concat(
            rows.map((row, i) => (
              <TableRow key={`field-row-${i}`}>
                {row.map((value, index) => (
                  <TableColumn key={index}>{value}</TableColumn>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </DataTable>
    </div>
  )
}

export default SpreadsheetPreview
