import React from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneField, Button, ButtonBlock, FieldBlock } from 'components'
import { useRequest } from 'hooks'
import { setupPhone } from 'requests/auth'
import { isValidPhone } from 'utils/inputValidations'
import AuthFrame from '../AuthFrame/AuthFrame'
import { useTwoFactorSetup } from '../SetupTwoFactor/SetupTwoFactor'

const SetupTwoFactorPhone = () => {
  const { t } = useTranslation()

  const { phone, setPhone, nextPage } = useTwoFactorSetup()
  const validPhone = isValidPhone(phone)

  const { makeRequest, isLoading, hasErrors } = useRequest(setupPhone, {
    onSuccess: nextPage,
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (!validPhone) return

    makeRequest(phone)
  }

  return (
    <div>
      <AuthFrame.Content>
        {t(
          'Please provide the phone number you wish to have your login codes sent to.'
        )}
      </AuthFrame.Content>
      {hasErrors && (
        <AuthFrame.Error>
          {t(
            'We had trouble saving your phone number. Please try again later.'
          )}
        </AuthFrame.Error>
      )}
      <form onSubmit={handleSubmit}>
        <FieldBlock>
          <PhoneField
            id="phone"
            label={t('Phone number')}
            value={phone}
            onChange={setPhone}
          />
        </FieldBlock>
        <AuthFrame.Actions>
          <ButtonBlock justify="left">
            <Button raised primary type="submit">
              {isLoading ? t('Saving phone') : t('Save phone')}
            </Button>
          </ButtonBlock>
        </AuthFrame.Actions>
      </form>
    </div>
  )
}

export default SetupTwoFactorPhone
