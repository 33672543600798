export const formatReportData = response => {
  if (response.rows) {
    return {
      data: response.rows.map((item, index) => ({
        ...item,
        meta: Object.keys(response.meta).reduce(
          (result, key) => ({
            ...result,
            [key]: Number(response.meta[key] ?? 0),
          }),
          {}
        ),
        id: index,
      })),
      sqlQuery: response.sql_query || '',
    }
  }
  return response.map((item, index) => ({
    ...item,
    id: index,
    meta: {},
    sqlQuery: response.sql_query || '',
  }))
}
