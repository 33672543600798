import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchTurfs = (params = {}) => {
  const defaultParams = {
    current_page: 1,
    per: Number.MAX_SAFE_INTEGER,
    fields: [
      'id',
      'name',
      'parent_id',
      'lft',
      'rgt',
      'extras',
      'archived',
      'voter_registration_enabled',
    ],
    sort_attr: 'lft',
    sort_dir: 'asc',
  }

  const queryString = stringifyParams({ ...defaultParams, ...params })

  return fetchJSON(`/api/v1/turfs?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const archiveTurf = id =>
  fetchJSON(`/api/v1/turfs/${id}/archive`, 'PATCH', null, {
    useJwt: true,
  })

export const unarchiveTurf = id =>
  fetchJSON(`/api/v1/turfs/${id}/unarchive`, 'PATCH', null, {
    useJwt: true,
  })

export const destroyTurf = id =>
  fetchJSON(`/api/v1/turfs/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const fetchTurf = (id, params = []) => {
  const defaultParams = {
    associations: ['turf_level'],
    fields: [
      'id',
      'name',
      'parent_id',
      'lft',
      'rgt',
      'extras',
      'qc_config',
      'phone_verification_language',
      'min_phone_verification_percent',
      'min_phone_verification_rounds',
      'voter_registration_enabled',
      'qc_turnaround_days',
      'turf_level',
      'petition_requirements',
      'archived',
    ],
    sort_attr: 'lft',
    sort_dir: 'asc',
  }

  const queryString = stringifyParams({ ...defaultParams, ...params })

  return fetchJSON(`/api/v1/turfs/${id}?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const changeTurfParent = async ({ turfId, newParentId }) =>
  fetchJSON(
    `/api/v1/turfs/${turfId}`,
    'PUT',
    { turf: { parent_id: newParentId } },
    {
      useJwt: true,
    }
  )

export const postTurf = turf =>
  fetchJSON(`/api/v1/turfs`, 'POST', { turf }, { useJwt: true })

export const putTurf = (id, turf) =>
  fetchJSON(
    `/api/v1/turfs/${id}`,
    'PUT',
    { turf },
    {
      useJwt: true,
    }
  )
