import {
  CardError,
  ResponsibilitySelectField,
  SectionContainer,
  TeamSelectField,
} from 'components'
import {
  Button,
  ContentBlock,
  FieldBlock,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'
import { useEffect, useState } from 'react'
import {
  destroyTeamMembership,
  postTeamMembership,
  putTeamMembership,
} from 'requests/people'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks'

const PersonActivismTeams = ({ person }) => {
  const { t } = useTranslation()
  const [newTeamMembership, setNewTeamMembership] = useState(null)
  const [teamMemberships, setTeamMemberships] = useState([])

  const {
    makeRequest: createReq,
    isLoading: createReqLoading,
    hasErrors: createReqHasErrors,
    errors: createReqErrors,
  } = useRequest(postTeamMembership, {
    onSuccess: ({ team_membership }) => {
      setNewTeamMembership(null)
      setTeamMemberships(prevMemberships => [
        ...prevMemberships,
        team_membership,
      ])
    },
  })

  const {
    makeRequest: updateReq,
    isLoading: updateReqLoading,
    hasErrors: updateReqHasErrors,
    errors: updateReqErrors,
  } = useRequest(putTeamMembership, {
    onSuccess: ({ team_membership }) =>
      setTeamMemberships(prevMemberships => {
        const newMemberships = [...prevMemberships]
        newMemberships[
          newMemberships.findIndex(
            membership => membership.id === team_membership.id
          )
        ] = team_membership
        return newMemberships
      }),
  })

  const {
    makeRequest: deleteReq,
    isLoading: deleteReqLoading,
    hasErrors: deleteReqHasErrors,
    errors: deleteReqErrors,
  } = useRequest(destroyTeamMembership, {
    onSuccess: () => {},
  })

  useEffect(() => {
    setTeamMemberships(person.team_memberships)
  }, [person])

  const handleTeamMembershipChange = (membershipId, attrs) => {
    membershipId
      ? updateReq(person.id, membershipId, attrs, {
          fields: ['id', 'responsibility', { team: ['id', 'name'] }],
        })
      : createReq(person.id, attrs, {
          fields: ['id', { team: ['id', 'name'] }],
        })
  }

  const handleTeamMembershipDelete = membershipId => {
    membershipId
      ? deleteReq(person.id, membershipId) &&
        setTeamMemberships(prevMemberships => {
          const newMemberships = [...prevMemberships]
          return newMemberships.filter(
            membership => membership.id !== membershipId
          )
        })
      : setNewTeamMembership(null)
  }

  const allTeamMemberships = newTeamMembership
    ? [...teamMemberships, newTeamMembership]
    : teamMemberships

  return (
    <SectionContainer>
      <CardError
        hide={!createReqHasErrors && !updateReqHasErrors && !deleteReqHasErrors}
        message={
          Object.values(createReqErrors).join(', ') ||
          Object.values(updateReqErrors).join(', ') ||
          Object.values(deleteReqErrors).join(', ')
        }
      />
      <ContentBlock>
        <Font.Label>{`${t('Teams')}`}</Font.Label>
      </ContentBlock>
      {allTeamMemberships.map((membership, i) => (
        <FieldBlock key={i}>
          <TeamSelectField
            id={`team-select-${i}`}
            onSelect={team => {
              handleTeamMembershipChange(membership.id, {
                team_id: team.id,
              })
            }}
            team={teamMemberships[i] && teamMemberships[i].team}
            loading={createReqLoading || updateReqLoading || deleteReqLoading}
            filters={[
              {
                column: 'members',
                operator: 'is',
                invert: 'true',
                param: person.id,
              },
            ]}
          />
          <ResponsibilitySelectField
            id={`responsibility-select-${i}`}
            onSelect={responsibility =>
              handleTeamMembershipChange(membership.id, { responsibility })
            }
            loading={createReqLoading || updateReqLoading || deleteReqLoading}
            disabled={!teamMemberships[i]?.team}
            label={t('Team role')}
            responsibility={
              teamMemberships[i] && teamMemberships[i].responsibility
            }
          />
          <Button.Secondary
            aria-label={t('Delete')}
            onClick={() => handleTeamMembershipDelete(membership.id, i)}
          >
            <Icon.Times />
          </Button.Secondary>
        </FieldBlock>
      ))}
      <ContentBlock>
        <Button
          aria-label={t('Add')}
          data-testid="add-team-membership"
          onClick={() => {
            setNewTeamMembership({ team: null, responsibility: null })
          }}
        >
          <Icon.Plus />
        </Button>
      </ContentBlock>
    </SectionContainer>
  )
}

export default PersonActivismTeams
