import React from 'react'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { formatPhoneNumber } from 'utils/formatting'

export const TeamNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/teams/${rowData.id}`}>{rowData.name}</Link>
  </TableGridCell>
)

export const TeamCityCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.organizations[0] ? rowData.organizations[0].city : null}
  </TableGridCell>
)

export const TeamLeaderNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.leader?.name}</TableGridCell>
)

export const TeamPhoneNumberCell = ({ rowData }) => (
  <TableGridCell>
    {formatPhoneNumber(rowData.leader?.primary_phone_number)}
  </TableGridCell>
)

export const TeamOrganizerNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.organizer.name}</TableGridCell>
)

export const TeamOrganizationCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.organizations.map((o, i) => (
      <React.Fragment key={i}>
        <Link to={`/organize/organizations/${o.id}`}>{o.name}</Link>
        {i + 1 < rowData.organizations.length && ' / '}
      </React.Fragment>
    ))}
  </TableGridCell>
)
