import { EditableContent } from 'components'
import { useTranslation } from 'react-i18next'
import ExistingCommentReadMode from './ExistingCommentReadMode'

const ExistingComment = ({ comment, saveCommentEdits, deleteComment }) => {
  const { t } = useTranslation()

  return (
    <EditableContent
      label={t('Comment')}
      content={comment.body}
      onSave={commentBody => saveCommentEdits(comment.id, commentBody)}
      ReadModeDisplay={({ startEditing }) => (
        <ExistingCommentReadMode
          comment={comment}
          startEditing={startEditing}
          deleteComment={deleteComment}
        />
      )}
    />
  )
}

export default ExistingComment
