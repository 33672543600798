import {
  PeopleNameCell,
  PeopleOrganizationCell,
  PeoplePhoneNumberCell,
  PeopleAddressCell,
  PeopleCityCell,
  PeopleStateCell,
  PeopleZipCodeCell,
  PeopleEmailCell,
} from './PeopleTableCells'
import {
  peopleNameCsvCell,
  peoplePhoneNumberCsvCell,
  peopleAddressCsvCell,
  peopleCityCsvCell,
  peopleStateCsvCell,
  peopleZipCodeCsvCell,
  peopleOrganizationCsvCell,
  peopleEmailCsvCell,
} from './peopleCsvCells'

export default {
  label: 'people',
  columns: [
    {
      dataKey: 'full_name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: PeopleNameCell,
      csvCell: peopleNameCsvCell,
      filterOperators: [
        {
          prefix: 'contains',
          value: 'containing',
          inputProps: {
            type: 'text',
            placeholder: 'Text to search',
          },
        },
      ],
      filterConstructor: rule => ({
        rules: [
          {
            column: 'full_name',
            operator: 'containing',
            param: rule.param,
          },
        ],
        conjunction: 'inclusive',
      }),
    },
    {
      dataKey: 'first_name',
      title: 'First name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
    },
    {
      dataKey: 'last_name',
      title: 'Last name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
    },
    {
      dataKey: 'phone_number',
      title: 'Phone Number',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PeoplePhoneNumberCell,
      csvCell: peoplePhoneNumberCsvCell,
      filterConstructor: rule => ({
        rules: [
          {
            column: 'primary_phone_number',
            operator: rule?.operator || 'containing',
            param: rule?.param || '',
          },
        ],
        conjunction: 'inclusive',
      }),
    },
    {
      dataKey: 'residential_address_full_street',
      title: 'Address',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PeopleAddressCell,
      csvCell: peopleAddressCsvCell,
    },
    {
      dataKey: 'residential_address_city',
      title: 'City',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PeopleCityCell,
      csvCell: peopleCityCsvCell,
    },
    {
      dataKey: 'residential_address_state',
      title: 'State',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PeopleStateCell,
      csvCell: peopleStateCsvCell,
    },
    {
      dataKey: 'residential_address_zipcode',
      title: 'Zip code',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PeopleZipCodeCell,
      csvCell: peopleZipCodeCsvCell,
    },
    {
      dataKey: 'organizations_name',
      title: 'Organizations',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PeopleOrganizationCell,
      csvCell: peopleOrganizationCsvCell,
    },
    {
      dataKey: 'email',
      title: 'Email',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PeopleEmailCell,
      csvCell: peopleEmailCsvCell,
      filterConstructor: rule => ({
        rules: [
          {
            column: 'primary_email_address',
            operator: rule?.operator || 'containing',
            param: rule?.param || '',
          },
        ],
        conjunction: 'inclusive',
      }),
    },
  ],
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: { hiddenColumns: ['first_name', 'last_name'] },
      },
    ]
  },
}
