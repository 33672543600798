import { formatPhoneNumber } from 'utils/formatting'

export const memberNameCsvCell = data => data.person.name
export const memberFirstNameCsvCell = data => data.person.first_name
export const memberLastNameCsvCell = data => data.person.last_name

export const memberResponsibilityCsvCell = data => data.responsibility

export const memberAddressCsvCell = data => {
  const address = data.person.residential_address
  if (address) {
    return `${address.line_one} ${address.line_two} ${address.city}, ${address.state} ${address.zipcode}`
  }
}

export const memberPhoneCsvCell = data =>
  formatPhoneNumber(data.person.primary_phone_number)

export const memberEmailCsvCell = data => data.person.primary_email_address
