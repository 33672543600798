import React, { useState, forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import { FontIcon } from 'react-md'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './SearchSelect.module.scss'

const cx = classNames.bind(styles)

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <FontIcon>
      {props.selectProps.menuIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
    </FontIcon>
  </components.DropdownIndicator>
)

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <FontIcon>close</FontIcon>
  </components.ClearIndicator>
)

const SearchSelect = forwardRef(
  (
    {
      id,
      label,
      value: controlledValue,
      options,
      defaultValue,
      labelKey,
      valueKey,
      onChange,
      onFocus,
      onBlur,
      onInputChange,
      disabled,
      required,
      clearable,
      isLoading,
      error,
      errorText,
      helpText,
      autoBlur,
      openOnFocus,
      remoteFiltered,
      closeOnSelect,
      className,
      noOptionsMessage,
      isOptionDisabled,
      hideLabel,
    },
    forwardedRef
  ) => {
    const { t } = useTranslation()

    const [selectedOption, setSelectedOption] = useState(null)
    const [isFocused, setFocused] = useState(false)
    const [isPristine, setPristine] = useState(true)

    const internalRef = useRef()
    const selectRef = forwardedRef || internalRef

    const focusSelect = () => {
      selectRef.current.focus()
    }

    const handleFocus = () => {
      setFocused(true)
      onFocus && onFocus()
    }

    const handleBlur = () => {
      setFocused(false)
      setPristine(false)
      onBlur && onBlur()
    }

    const handleChange = option => {
      setFocused(false)
      setSelectedOption(option)
      onChange && onChange(option?.value)
    }

    const uncontrolledValue = selectedOption
      ? selectedOption[valueKey]
      : defaultValue
    const value = controlledValue ?? uncontrolledValue

    const fieldError = (!isPristine && required && !value) || error
    const fieldErrorText =
      fieldError && required && !value ? t('Required') : errorText

    return (
      <div className={styles.container}>
        <div
          className={cx('field', className, {
            'field--focused': isFocused,
            'field--error': fieldError,
            'field--has-value': options.some(
              option => option[valueKey] === value
            ),
          })}
        >
          {!hideLabel && (
            <label
              htmlFor={id}
              className={styles.label}
              tabIndex="-1"
              onFocus={focusSelect}
            >
              {[label, required && '*'].filter(Boolean).join(' ')}
            </label>
          )}
          <Select
            id={id}
            ref={selectRef}
            isClearable={!!clearable}
            isDisabled={disabled}
            isLoading={isLoading}
            value={options
              .map(option => ({
                value: option[valueKey],
                label: option[labelKey],
              }))
              .filter(option => option.value === value)}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onInputChange={onInputChange}
            blurInputOnSelect={autoBlur}
            openMenuOnFocus={openOnFocus}
            isOptionDisabled={isOptionDisabled}
            closeMenuOnSelect={closeOnSelect}
            options={options
              .map(option => ({
                ...option,
                value: option[valueKey],
                label: option[labelKey]?.toString(),
              }))
              .sort((a, b) =>
                a.label.localeCompare(b.label, undefined, { numeric: true })
              )}
            classNamePrefix="ReactSelect"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            components={{ DropdownIndicator, ClearIndicator }}
            noOptionsMessage={() => noOptionsMessage}
            filterOption={remoteFiltered ? () => true : undefined}
          />
        </div>
        {helpText && <div className={styles.help}>{helpText}</div>}
        {fieldError && <div className={styles.error}>{fieldErrorText}</div>}
      </div>
    )
  }
)

SearchSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  autoBlur: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  remoteFiltered: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  className: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  isOptionDisabled: PropTypes.func,
}

SearchSelect.defaultProps = {
  id: '',
  label: '',
  defaultValue: null,
  labelKey: 'label',
  valueKey: 'value',
  onFocus: null,
  onBlur: null,
  disabled: false,
  required: false,
  clearable: false,
  isLoading: false,
  error: false,
  errorText: '',
  autoBlur: false,
  openOnFocus: false,
  remoteFiltered: false,
  closeOnSelect: true,
  className: '',
  noOptionsMessage: 'No options',
  isOptionDisabled: null,
}

export default SearchSelect
