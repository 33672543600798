import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { some } from 'lodash/collection'
import { useRequest, useRouteQueryParams } from 'hooks'
import { CardError, InputArea, ButtonBlock, TextField } from 'components'
import {
  FieldBlock,
  Modal,
  Radio,
  Checkbox,
  Button,
  Icon,
} from '@politechdev/blocks-design-system'
import { updatePhoneVerificationCall } from 'requests/phoneVerificationCalls'
import {
  COMPLETE,
  REVIEW,
  ACTIVE,
  READY,
  CONNECTING,
} from 'constants/phoneBankSessions'
import {
  NOT_CONTACTED_REASON,
  RESPONSES_REQUIRING_NOTES,
  NEEDS_ATTENTION_OTHER,
  NO_PHONE,
  requiredQuestionKeys,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { formatErrorMessage } from 'utils/formatting'
import { getCurrentSessionScript } from 'qualityControl/contexts/contextSelectors'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import { useScriptLanguage } from 'qualityControl/contexts/PhoneVerificationQuestionsContext'
import styles from './LogCallControls.module.scss'

const LogCallControls = ({
  questions,
  isEditing,
  editingCallId,
  setSessionScanCallMap,
  goToNextScan,
  phoneNumber,
  notesValue,
  setNotesValue,
  hasEditedChanges,
  setOverrideIsEveryScanCalled,
  getPacket,
  setContactedStatus,
  contactedStatus,
  setVerificationResponses,
  verificationResponses,
  createCallRecord,
  submitPhoneVerification,
  lastActiveCallRecordId,
}) => {
  const { callStep, setCallStep } = useCallSessionContext()

  const nonParticipationResponse =
    verificationResponses[questions[NOT_CONTACTED_REASON].id]

  const needsAttentionResponse =
    verificationResponses[questions[NEEDS_ATTENTION_OTHER].id] === 't'

  const [currentPacket] = useCurrentPacket()
  const [language] = useScriptLanguage()
  const currentScript = getCurrentSessionScript(currentPacket, language)

  const areAllPhoneVerificationQuestionsAnswered = requiredQuestionKeys
    .filter(questionKey =>
      currentScript.find(scriptItem => scriptItem.questionKey === questionKey)
    )
    .every(questionKey => verificationResponses[questions[questionKey].id])

  const { t } = useTranslation()
  const [{ scanId }] = useRouteQueryParams()

  const [notesError, setNotesError] = useState('')
  const [isNotContactedModalOpen, setIsNotContactedModalOpen] = useState(false)

  useEffect(() => {
    setContactedStatus('')
    setNotesValue('')
    setNotesError('')
    setVerificationResponses({})
  }, [scanId])

  const submitErrorMsg = formatErrorMessage(submitPhoneVerification.errors)

  // eslint-disable-next-line blocks/missing-response-error
  const updateCallRequest = useRequest(updatePhoneVerificationCall, {
    onSuccess: () => {
      setOverrideIsEveryScanCalled(false)
      goToNextScan()
      getPacket()
    },
  })

  const setNonParticipationReason = response => {
    setVerificationResponses(current => ({
      ...current,
      [questions[NOT_CONTACTED_REASON].id]: response,
    }))
  }

  const toggleContacted = val => {
    if (val === 'f') {
      setVerificationResponses({})
      setIsNotContactedModalOpen(true)
    }
    if (val === 't') {
      setNonParticipationReason('')
    }
    setContactedStatus(val)
  }

  const isContactedWithAllResponses =
    contactedStatus === 't' && areAllPhoneVerificationQuestionsAnswered

  const isUncontactedWithNonParticipationReason =
    contactedStatus === 'f' && nonParticipationResponse

  const canSubmit =
    !submitPhoneVerification.isLoading &&
    callStep !== CONNECTING &&
    callStep !== ACTIVE &&
    ((isContactedWithAllResponses && callStep === REVIEW) ||
      isUncontactedWithNonParticipationReason)

  const canEdit =
    !submitPhoneVerification.isLoading &&
    (isContactedWithAllResponses || isUncontactedWithNonParticipationReason)

  const responsesRequiringNotes = RESPONSES_REQUIRING_NOTES.filter(
    requirement => {
      if (!Array.isArray(requirement)) {
        return !!questions[requirement]?.response
      }
      if (requirement.length === 1) {
        return !!questions[requirement[0]]?.response
      }
      if (requirement.length === 2) {
        return requirement[1].includes(questions[requirement[0]]?.response)
      }
      return false
    }
  ).map(requirement =>
    Array.isArray(requirement) ? requirement[0] : requirement
  )

  const handleLogCall = async () => {
    if (responsesRequiringNotes.length && !notesValue) {
      setNotesError(
        `${t(
          'A note is required for these responses:'
        )} ${responsesRequiringNotes}`
      )
    } else {
      const hasNoPhone =
        verificationResponses[questions[NOT_CONTACTED_REASON].id] === NO_PHONE

      let callId = lastActiveCallRecordId

      if (hasNoPhone && !callId) {
        const response = await createCallRecord.makeRequest(
          {
            voter_registration_scan_id: scanId,
            number: 'no-phone-number',
          },
          {
            fields: ['id'],
          }
        )

        const call = response['quality_control/phone_verification_call']
        callId = call.id
      }

      const formattedResponses = Object.entries(verificationResponses)
        .filter(([, response]) => !!response)
        .map(([id, response], index) => {
          const isNeedsAttention = questions[NEEDS_ATTENTION_OTHER].id === id
          const isFirstResponse = index === 0
          const shouldHaveNotes =
            !!notesValue && (isNeedsAttention || isFirstResponse)

          return {
            phone_verification_question_id: id,
            response,
            notes: shouldHaveNotes ? notesValue : undefined,
          }
        })

      await submitPhoneVerification.makeRequest(callId, {
        responses: formattedResponses,
      })

      setCallStep(READY)
      setSessionScanCallMap(current => ({ ...current, [scanId]: callId }))
      goToNextScan()
      getPacket()
    }
  }

  const handleUpdateCall = () => {
    const responses = Object.entries(verificationResponses)
      .filter(([, response]) => !!response)
      .map(([id, response], index) => {
        const isNeedsAttention = questions[NEEDS_ATTENTION_OTHER].id === id
        const isFirstResponse = index === 0
        const shouldHaveNotes =
          !!notesValue && (isNeedsAttention || isFirstResponse)

        return {
          phone_verification_question_id: id,
          response,
          notes: shouldHaveNotes ? notesValue : undefined,
        }
      })

    updateCallRequest.makeRequest(editingCallId, {
      responses,
      number: phoneNumber,
    })
  }

  const notActivatedChoice = questions[NOT_CONTACTED_REASON].choices.find(
    choice => choice.value === nonParticipationResponse
  )

  if (callStep === COMPLETE) {
    return <div className={styles.completed}>Submitted</div>
  }
  if (callStep !== READY && callStep !== REVIEW && callStep !== ACTIVE) {
    return null
  }

  const contactedChoices = [
    {
      value: 't',
      label: t('Contacted'),
      disabled: !isEditing && callStep === READY,
    },
    {
      value: 'f',
      label: t('Could not contact'),
      onClick: () => {
        contactedStatus === 'f' && setIsNotContactedModalOpen(true)
      },
    },
  ]

  const noncontactOptions = questions[NOT_CONTACTED_REASON].choices.filter(
    choice => {
      if (isEditing) return true

      if (callStep === READY) {
        return choice.value === NO_PHONE
      }

      return choice.value !== NO_PHONE
    }
  )
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <InputArea label={t('Notes')} className={styles.notes}>
            {callStep === COMPLETE ? (
              notesValue || t('No notes')
            ) : (
              <TextField
                id="scan-notes"
                value={notesValue}
                onChange={val => setNotesValue(val)}
                required={some(responsesRequiringNotes)}
                error={!!notesError}
                errorText={notesError}
              />
            )}
          </InputArea>
          <div className={styles['contact-options']}>
            <Radio.Group
              id="participated-select"
              name="participated"
              type="radio"
              value={contactedStatus}
              options={contactedChoices}
              onChange={toggleContacted}
            />
            <Checkbox
              id="needs-attention"
              name="needs-attention"
              label={t('Needs attention for some other reason')}
              disabled={callStep === READY && !isEditing}
              checked={needsAttentionResponse}
              onChange={isChecked => {
                setVerificationResponses(current => ({
                  ...current,
                  [questions[NEEDS_ATTENTION_OTHER].id]: isChecked ? 't' : 'f',
                }))
              }}
            />
          </div>
          <Modal
            id="is-not-contacted-modal"
            title={t('Could not be contacted')}
            isOpen={isNotContactedModalOpen}
          >
            <Modal.Body>
              {isNotContactedModalOpen && (
                <FieldBlock>
                  <Radio.Group
                    id={NOT_CONTACTED_REASON}
                    name={NOT_CONTACTED_REASON}
                    value={nonParticipationResponse}
                    options={noncontactOptions}
                    onChange={setNonParticipationReason}
                  />
                </FieldBlock>
              )}
            </Modal.Body>
            <Modal.Actions>
              <ButtonBlock justify="right">
                <Button.Secondary
                  onClick={() => {
                    setIsNotContactedModalOpen(false)
                  }}
                >
                  {t('Cancel')}
                </Button.Secondary>
                <Button.Accent
                  onClick={() => {
                    setIsNotContactedModalOpen(false)
                  }}
                >
                  {t('Select reason')}
                </Button.Accent>
              </ButtonBlock>
            </Modal.Actions>
          </Modal>
          <div className={styles['submit-wrapper']}>
            <div>
              {callStep === COMPLETE ? (
                <p>
                  <span> {t('Submitted')}</span>
                  <Icon.Check />
                </p>
              ) : (
                <Button.Accent
                  disabled={isEditing ? !canEdit : !canSubmit}
                  onClick={isEditing ? handleUpdateCall : handleLogCall}
                >
                  {isEditing ? t('Update response') : t('Submit response')}
                </Button.Accent>
              )}
              {!isEditing && !canSubmit && (
                <div className={styles['hint-text']}>
                  {callStep !== ACTIVE &&
                    t(
                      'Enter a response for all questions or select could not contact to submit'
                    )}
                </div>
              )}
              {isEditing && hasEditedChanges && (
                <div className={styles['hint-text']}>
                  {callStep !== ACTIVE && t('Please update to save changes')}
                </div>
              )}
              {notActivatedChoice && (
                <div className={styles['hint-text']}>
                  {t('Could not contact')}: {notActivatedChoice.label}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['error-card']}>
        <CardError
          hide={!submitPhoneVerification.hasErrors}
          message={submitErrorMsg}
          hideSupportLink
        />
      </div>
    </>
  )
}

export default LogCallControls
