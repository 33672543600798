import React from 'react'

export const TableGridCell = ({ children, subtitle }) => (
  <>
    <div className="BaseTable__row-cell-text">{children}</div>
    {subtitle && subtitle !== '' && (
      <div className="BaseTable__row-cell-subtitle">{subtitle}</div>
    )}
  </>
)
