import {
  calculatePercent,
  calculateRate,
  formatDecimalCell,
  secondsToHours,
  summaryFunctions,
} from 'utils/reporting'

export const baseGeneralColumns = [
  {
    dataKey: 'total_signatures',
    title: 'Total Signatures',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'valid_signatures',
    title: 'Valid Signatures',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'valid_signature_percent',
    title: 'Valid Signature %',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.valid_signatures, rowData.total_signatures),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'valid_signatures',
        'total_signatures',
      ]),
  },
  {
    dataKey: 'total_shifts',
    title: 'Total Shifts',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'total_shift_hours',
    title: 'Total Shift Hours',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) => secondsToHours(rowData.total_shift_time),
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'total_field_hours',
    title: 'Total Field Hours',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) => secondsToHours(rowData.total_field_time),
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'signatures_per_field_hour',
    title: 'Signatures per Field Hour',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculateRate(
        rowData.total_signatures,
        secondsToHours(rowData.total_field_time)
      ),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customRatio(data, acc, [
        'total_signatures',
        'total_field_hours',
      ]),
  },
]
