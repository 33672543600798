import { fetchJSON, stringifyParams } from 'utils/req'
import { DOCUMENTS_FIELDS } from 'constants/documents'

export const fetchDocumentsThroughFolder = async folderId => {
  const response = await fetchJSON(
    `/api/v1/folders/${folderId}?${stringifyParams({
      fields: [{ documents: DOCUMENTS_FIELDS }],
    })}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
  return {
    meta: response.meta,
    warnings: response.warnings,
    documents: response.folder.documents,
  }
}

export const fetchDocuments = (params = {}) =>
  fetchJSON(`/api/v1/documents?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postDocument = data =>
  fetchJSON('/api/v1/documents', 'POST', { document: data }, { useJwt: true })

export const uploadDocument = file =>
  fetchJSON(
    `//${process.env.API_HOSTNAME}/system/files/upload?metadata_generator=documents&url=true`,
    'POST',
    { file },
    { Authorization: `Basic ${process.env.SYSTEM_AUTH_TOKEN}` },
    {
      isUpload: true,
      showStatusCode: true,
    }
  )

export const uploadDocuments = async (folderId, files) => {
  await Promise.all(
    files.map(async file => {
      const {
        statusCode,
        response: { data: document },
      } = await uploadDocument(file)

      if (statusCode === 200) {
        await postDocument({
          file_locator: document,
          folder_id: folderId,
        })
      }
    })
  )
}

export const postDuplicateDocument = (id, data) =>
  fetchJSON(
    `/api/v1/documents/${id}/duplicate`,
    'POST',
    { document: data },
    { useJwt: true }
  )

export const putDocument = (id, data) =>
  fetchJSON(
    `/api/v1/documents/${id}`,
    'PUT',
    { document: data },
    {
      useJwt: true,
    }
  )

export const destroyDocument = id =>
  fetchJSON(`/api/v1/documents/${id}`, 'DELETE', null, { useJwt: true })

export const moveDocument = async ({ documentId, folderId }) => {
  await putDocument(documentId, { folder_id: folderId })
  return fetchDocumentsThroughFolder(folderId)
}

export const duplicateDocument = async ({ documentId, folderId }) => {
  await postDuplicateDocument(documentId, { folder_id: folderId })
  return fetchDocumentsThroughFolder(folderId)
}
