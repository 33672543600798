import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@politechdev/blocks-design-system'
import { useSearch } from '../SearchProvider/SearchProvider'
import styles from './SearchGlobalToggle.module.scss'

const SearchGlobalToggle = () => {
  const { t } = useTranslation()

  const { isGlobal, setGlobal } = useSearch()

  return (
    <div className={styles.container}>
      <div className={styles.toggle}>
        <Button
          className={styles.buttonLeft}
          variant={
            isGlobal ? Button.VARIANTS.SECONDARY : Button.VARIANTS.ACCENT
          }
          onClick={() => setGlobal(false)}
        >
          {t('This folder')}
        </Button>
        <Button
          className={styles.buttonRight}
          variant={
            !isGlobal ? Button.VARIANTS.SECONDARY : Button.VARIANTS.ACCENT
          }
          onClick={() => setGlobal(true)}
        >
          {t('Everywhere')}
        </Button>
      </div>
    </div>
  )
}

export default SearchGlobalToggle
