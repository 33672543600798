import {
  calculatePercent,
  formatDecimalCell,
  summaryFunctions,
} from 'utils/reporting'

export const partisanScoreDataDictionaryUrl =
  'https://docs.google.com/spreadsheets/d/1xABMR2DqVMaDx-u18b9gH-yh0-wh7P6Av2BVBU2zodE/edit#gid=2066303461'

export const basePartisanScoreColumns = [
  {
    dataKey: 'partisan_score_segment',
    title: 'Partisan Score Segment',
    type: 'string',
    resizable: true,
  },
  {
    dataKey: 'scans_qc',
    title: 'Forms Matched with Score (QC)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'percent_of_total_scans_qc_with_value',
    title: 'Percent of Total Forms with Partisan Score (QC)',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.scans_qc, rowData.total_scans_qc_with_value),
  },
  {
    dataKey: 'percent_of_total_scans_qc',
    title: 'Percent of Total Forms (QC)',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.scans_qc, rowData.total_scans_qc),
    summaryFunction: ({ data }) =>
      summaryFunctions.percentOfGroupedTotal(
        data,
        'scans_qc',
        'total_scans_qc',
        'table_id'
      ),
  },
]
