import { calculatePercent, combineName } from 'utils/reporting'

export default {
  id: 'viz_by_day',
  name: 'Visual QC by Day',
  description:
    'This report provides information on staffer performance for each day they worked on visual QC. Staffers may have multiple days depending on the date range criteria. The report includes individual flags from visual QC by either raw totals or percentages.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1396035619',
  columns: [
    {
      dataKey: 'qc_staffer',
      title: 'QC Staffer',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.first_name, rowData.last_name),
    },
    {
      dataKey: 'visual_qc_date',
      title: 'Visual QC Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'total_scans',
      title: 'Total Scans',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_qc',
      title: 'Total Forms Through QC',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'approved_qc',
      title: 'Approved (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'missing_phone_qc',
      title: 'Missing Phone (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'reupload_qc',
      title: 'Reupload (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'handwriting_qc',
      title: 'Handwriting (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_form_qc',
      title: 'Not a Card (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'canvasser_assistance_qc',
      title: 'Canvasser Assistance (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'other_qc',
      title: 'Other (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'percent_approved_qc',
      title: 'Percent Approved (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.approved_qc, rowData.total_scans_qc),
    },
    {
      dataKey: 'percent_missing_phone_qc',
      title: 'Percent Missing Phone (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.missing_phone_qc, rowData.total_scans_qc),
    },
    {
      dataKey: 'percent_reupload_qc',
      title: 'Percent Reupload (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.reupload_qc, rowData.total_scans_qc),
    },
    {
      dataKey: 'percent_handwriting_qc',
      title: 'Percent Handwriting (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.handwriting_qc, rowData.total_scans_qc),
    },
    {
      dataKey: 'percent_canvasser_assistance_qc',
      title: 'Percent Canvasser Assistance (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.canvasser_assistance_qc,
          rowData.total_scans_qc
        ),
    },
    {
      dataKey: 'percent_other_qc',
      title: 'Percent Other (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.other_qc, rowData.total_scans_qc),
    },
  ],
}
