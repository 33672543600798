import { formatPhoneNumber } from 'utils/formatting'

export const memberNameCsvCell = data => data.name

export const memberFirstNameCsvCell = data => data.first_name

export const memberLastNameCsvCell = data => data.last_name

export const memberStreetAddressCsvCell = data => {
  const address = data.residential_address
  if (address) {
    return [address?.line_one, address?.line_two].join(' ')
  }
}

export const memberCityCsvCell = data => {
  const address = data.residential_address
  if (address) {
    return address.city
  }
}

export const memberStateCsvCell = data => {
  const address = data.residential_address
  if (address) {
    return address.state
  }
}

export const memberZipcodeCsvCell = data => {
  const address = data.residential_address
  if (address) {
    return address.zipcode
  }
}

export const memberPhoneCsvCell = data =>
  formatPhoneNumber(data.primary_phone_number)

export const memberEmailCsvCell = data => data.primary_email_address
