import { buildDefaultFilters } from 'utils/reporting'
import { baseFieldColumns } from '../columns/baseFieldColumns'

export default {
  id: 'county',
  name: 'County',
  description:
    'This report provides information from the cover sheet and data entry by collection county.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=565021327',
  partisanDataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1xABMR2DqVMaDx-u18b9gH-yh0-wh7P6Av2BVBU2zodE/edit#gid=565021327',
  columns: [
    {
      dataKey: 'collection_state',
      title: 'Collection State',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'collection_county',
      title: 'Collection County',
      type: 'string',
      resizable: true,
    },
    ...baseFieldColumns,
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Canvass Information',
        visibleColumns: [
          'collection_state',
          'collection_county',
          'total_field_hours_cover',
          'scans_per_field_hour_cover',
          'total_shift_hours',
          'total_scans_cover',
          'complete_scans_qc',
          'scans_with_phones_qc',
        ],
      },
      {
        title: 'Raw Counts',
        visibleColumns: [
          'collection_state',
          'collection_county',
          'total_field_hours_cover',
          'scans_per_field_hour_cover',
          'total_scans_cover',
          'total_scans_qc',
          'complete_scans_cover',
          'complete_scans_qc',
          'incomplete_scans_cover',
          'incomplete_scans_qc',
          'scans_with_phones_cover',
          'scans_with_phones_qc',
        ],
      },
      {
        title: 'Percentages',
        visibleColumns: [
          'collection_state',
          'collection_county',
          'total_field_hours_cover',
          'scans_per_field_hour_cover',
          'complete_scan_percent_cover',
          'complete_scan_percent_qc',
          'incomplete_scan_percent_cover',
          'incomplete_scan_percent_qc',
          'percent_with_phones_cover',
          'percent_with_phones_qc',
        ],
      },
    ])
  },
}
