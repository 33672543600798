import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest, useReactRouter } from 'hooks'
import { useAutoTable, useCurrent } from 'contexts'
import { CardError, ResponsibilitySelectField } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { postTeamMembership } from 'requests/teams'
import FindOrCreatePartialPerson from 'components/CreatePartialPerson/FindOrCreatePartialPerson'
import styles from './AddTeamMemberModal.module.scss'

const AddTeamMemberModal = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { teamId } = match.params

  const { refreshPage } = useAutoTable()
  const { currentTurfActivismLanguages: languages } = useCurrent()

  const [modalOpen, setModalOpen] = useState(false)
  const [person, setPerson] = useState()
  const [responsibility, setResponsibility] = useState()

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setPerson(null)
    setResponsibility(null)
  }

  const postTeamMembershipRequest = useRequest(postTeamMembership, {
    onSuccess: () => {
      closeModal()
      refreshPage()
    },
  })

  const formSubmit = () => {
    const params = {
      member_id: person.id,
      responsibility,
    }
    postTeamMembershipRequest.makeRequest(teamId, params)
  }

  return (
    <>
      <Button.Accent onClick={openModal}>{t('Add team member')}</Button.Accent>
      <Modal
        id="add-team-member-modal"
        title={t('Add team member')}
        isOpen={modalOpen}
        className={styles.modal}
        showLoading={postTeamMembershipRequest.isLoading}
      >
        <FindOrCreatePartialPerson
          person={person}
          onSelect={setPerson}
          languages={languages}
          inModal
        >
          <Modal.Body>
            <ContentBlock>
              <CardError
                message={t(
                  'An internal error occurred while trying to process your request'
                )}
                hide={!postTeamMembershipRequest.hasErrors}
                hideSupportLink
              />
              <ResponsibilitySelectField
                responsibility={responsibility}
                onSelect={setResponsibility}
              />
              <FindOrCreatePartialPerson.PersonSelector
                label={t('Search people to add')}
              />
            </ContentBlock>
          </Modal.Body>
          <Modal.Actions>
            <ButtonBlock justify="right">
              <Button.Secondary onClick={closeModal}>
                {t('Cancel')}
              </Button.Secondary>
              <Button.Accent disabled={!person} onClick={formSubmit}>
                {t('Save')}
              </Button.Accent>
            </ButtonBlock>
          </Modal.Actions>
        </FindOrCreatePartialPerson>
      </Modal>
    </>
  )
}

export default AddTeamMemberModal
