import { useRequest } from 'hooks/useRequest'
import {
  Section,
  DetailItem,
  FormattedData,
  LoadBar,
  CardError,
} from 'components'
import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash/collection'
import ScanReviewSection from 'qualityControl/scanReview/ScanReviewSection/ScanReviewSection'
import moment from 'moment-timezone'
import { formatDisplayName } from 'utils/formatting'
import { useCurrent } from 'contexts/index'
import { useScanActions } from 'qualityControl/contexts/ScanContext'
import RedoQcButton from './RedoQcButton'

const ApprovalConfirmationPanel = ({ currentReviews, currentScan }) => {
  const { t } = useTranslation()
  const {
    currentUser: { time_zone: userTimezone },
  } = useCurrent()
  const { user, created_at } = orderBy(
    currentReviews,
    'created_at',
    'desc'
  ).find(r => r.response.response === 'approved')

  const { redoScanResponses } = useScanActions()
  const { isLoading, hasErrors, makeRequest } = useRequest(redoScanResponses)

  return (
    <>
      <LoadBar show={isLoading} />
      <ScanReviewSection approved>
        <Section>
          <DetailItem label={t('Approved on')}>
            <FormattedData
              type="date"
              value={moment.tz(created_at, userTimezone)}
            />
          </DetailItem>
          <DetailItem label={t('Approved by')}>
            <p>{user && formatDisplayName(user)}</p>
          </DetailItem>
        </Section>
        <div className="button__group button__group--centered">
          <RedoQcButton redoResponses={() => makeRequest(currentScan)} />
        </div>
      </ScanReviewSection>
      <CardError hide={!hasErrors} />
    </>
  )
}

export default ApprovalConfirmationPanel
