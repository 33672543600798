import { useTranslation } from 'react-i18next'
import { Section, ButtonBlock, Button } from 'components'
import { Facebook, Twitter, LinkedIn } from 'components/uiKit/icons'

const SocialLinksBlock = ({ className, facebook, twitter, linkedIn }) => {
  const { t } = useTranslation()
  const socialMediaAccounts = [
    {
      icon: <Facebook />,
      url: facebook,
    },
    {
      icon: <Twitter />,
      url: twitter,
    },
    {
      icon: <LinkedIn />,
      url: linkedIn,
    },
  ].filter(acct => !!acct.url)

  return (
    <Section
      label={t('Social media links')}
      className={className}
      empty={!socialMediaAccounts.length}
      emptyMessage={t('No social media links')}
    >
      <ButtonBlock>
        {socialMediaAccounts.map(acct => (
          <Button
            floating
            svg
            primary
            key={acct.url}
            href={acct.url}
            target="_blank"
            tooltipLabel={acct.url}
          >
            {acct.icon}
          </Button>
        ))}
      </ButtonBlock>
    </Section>
  )
}

export default SocialLinksBlock
