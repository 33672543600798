import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CountySelectField } from 'components'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  TextBlock,
  Icon,
  Checkbox,
  TextField,
  SelectField,
  Section,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import US from 'us'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { maskPhone, unmaskPhone } from 'utils/inputMasks'
import {
  isValidEmail,
  sanitizeStr,
  isValidUrl,
  VALID_PHONE_LENGTH,
} from 'utils/inputValidations'
import {
  EMAIL_TYPES,
  SOCIAL_LINK_TYPES,
  isNonPrimaryPhone,
  nonPrimaryPhoneOptions,
} from 'constants/people'
import { useRequest } from 'hooks/useRequest'
import { putPerson, fetchPerson } from 'requests/people'
import { useCurrent } from 'contexts/index'
import styles from './PersonEditForm.module.scss'

const PersonEditForm = ({ history, personId, hideCancel, onChange }) => {
  const [personForm, setPersonForm] = useState({
    first_name: null,
    middle_name: null,
    last_name: null,
    prefix: null,
    suffix_name: null,
    pronouns: null,
    residential_address: null,
    contact_methods: [],
    primary_phone_number: null,
    primary_email_address: null,
    receives_sms: null,
    social_links: [],
  })

  const { doesCurrentUserHavePermission } = useCurrent()

  const canEditSpecialFields = doesCurrentUserHavePermission({
    resource: 'person',
    ability: 'edit_special_fields',
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: personReq, isRequestComplete: personReqComplete } =
    useRequest(fetchPerson, {
      onSuccess: ({ person }) => {
        setPersonForm({
          first_name: person.first_name,
          middle_name: person.middle_name,
          last_name: person.last_name,
          prefix: person.prefix,
          suffix_name: person.suffix_name,
          pronouns: person.pronouns,
          primary_phone_number: person.primary_phone_number,
          primary_email_address: person.primary_email_address,
          receives_sms: person.receives_sms,
          social_links: person.social_links,
          residential_address: {
            ...person.residential_address,
          },
          contact_methods: person.contact_methods,
          external_id: person.external_id,
        })
      },
    })

  useEffect(() => {
    personReq(personId, {
      fields: [
        'first_name',
        'middle_name',
        'last_name',
        'prefix',
        'suffix_name',
        'pronouns',
        'social_links',
        'primary_phone_number',
        'primary_email_address',
        'receives_sms',
        'external_id',
        {
          residential_address: [
            'id',
            'city',
            'county',
            'line_one',
            'line_two',
            'state',
            'zipcode',
          ],
        },
        {
          contact_methods: [
            'id',
            'content',
            'contact_type',
            'extension',
            'invalid',
          ],
        },
      ],
    })
  }, [personId])

  const { t } = useTranslation()

  const {
    makeRequest: updatePersonReq,
    isLoading,
    errors,
  } = useRequest(putPerson, {
    onSuccess: () => {
      if (history) {
        history.push(`/organize/people/${personId}`)
      }
    },
  })

  if (!personReqComplete) return null

  const addContactMethod = type => {
    const tempId = `${type}${
      personForm.contact_methods.filter(
        contactMethod => contactMethod.type === type
      ).length + 1
    }`

    setPersonForm({
      ...personForm,
      contact_methods: [...personForm.contact_methods, { type, tempId }],
    })
  }

  const isFormValid = () => {
    const validPhones = personForm.contact_methods.every(
      ({ contact_type, content }) =>
        contact_type?.includes('phone')
          ? !!content && maskPhone(content).length === VALID_PHONE_LENGTH
          : true
    )

    const validEmails = personForm.contact_methods.every(
      ({ contact_type, content }) =>
        contact_type?.includes('email')
          ? !!content && isValidEmail(content)
          : true
    )

    const validSocialLinks = personForm.social_links.every(
      link => !link.url || isValidUrl(link.url)
    )

    return (
      personForm.first_name &&
      personForm.last_name &&
      validPhones &&
      validEmails &&
      validSocialLinks
    )
  }

  const addressIsEmpty = address => {
    if (address === null) return true
    const { id, ...address_fields } = address
    return !Object.values(address_fields).some(val => val)
  }

  const handleUpdatePerson = async () => {
    const cleanForm = {
      ...personForm,
      contact_methods: personForm.contact_methods.filter(
        m => m.contact_type || m._destroy
      ),
      primary_email_address: personForm.primary_email_address || null,
      middle_name: personForm.middle_name?.length
        ? personForm.middle_name
        : null,
      residential_address: addressIsEmpty(personForm.residential_address)
        ? null
        : personForm.residential_address,
    }
    onChange?.(cleanForm)
    return updatePersonReq(personId, cleanForm)
  }

  const errorsOn = path => {
    if (!errors) return null
    return get(errors, path)
  }

  const updateAddressAttribute = (attr, val) => {
    setPersonForm({
      ...personForm,
      residential_address: {
        ...personForm.residential_address,
        [attr]: val,
      },
    })
  }

  const indexOfExistingContactMethod = id =>
    personForm.contact_methods.findIndex(
      contactMethod => contactMethod.id === id
    )

  const indexOfNewContactMethod = tempId =>
    personForm.contact_methods.findIndex(
      contactMethod => contactMethod.tempId === tempId
    )

  const updateExistingContactMethod = (id, attribute, value) => {
    const updatedContactMethods = personForm.contact_methods.slice()
    const updatedIndex = updatedContactMethods.findIndex(
      contactMethod => contactMethod.id === id
    )

    if (updatedIndex >= 0) {
      updatedContactMethods[updatedIndex][attribute] = value
    }

    setPersonForm({
      ...personForm,
      contact_methods: updatedContactMethods,
    })
  }

  const removeExistingContactMethod = (type, id, index) => {
    const updatedContactMethods = personForm.contact_methods.filter(
      m => m.id !== id
    )

    setPersonForm({
      ...personForm,
      contact_methods: updatedContactMethods,
    })

    if (index === 0) {
      const newType = ''
      const tempId = `${newType}${
        personForm.contact_methods.filter(
          contactMethod => contactMethod.type === newType
        ).length + 1
      }`
      setPersonForm({
        ...personForm,
        contact_methods: [...updatedContactMethods, { newType, tempId }],
      })
    }
  }

  const updateNewContactMethod = (tempId, attribute, value) => {
    const updatedContactMethods = personForm.contact_methods.slice()
    updatedContactMethods.find(
      contactMethod => contactMethod.tempId === tempId
    )[attribute] = value

    setPersonForm({
      ...personForm,
      contact_methods: updatedContactMethods,
    })
  }

  const newContactMethods = type =>
    personForm.contact_methods.filter(
      contactMethod => !contactMethod.id && contactMethod.type === type
    )

  const removeNewContactFields = tempId => {
    setPersonForm({
      ...personForm,
      contact_methods: personForm.contact_methods.filter(
        m => m.tempId !== tempId
      ),
    })
  }

  const getSocialLinkUrl = typeKey => {
    const linkOption = personForm.social_links.find(
      link => link.type === typeKey
    )
    return linkOption ? linkOption.url : ''
  }

  const nonPrimaryEmailOptions = Object.keys(EMAIL_TYPES)
    .filter(key => key !== 'primary_email_address')
    .map(key => ({ value: key, label: EMAIL_TYPES[key] }))

  return (
    <div>
      <ProgressBar show={isLoading} />
      <form>
        <FieldBlock>
          <TextField
            type="text"
            id="person-prefix"
            label={t('Prefix')}
            value={personForm.prefix || ''}
            onChange={prefix => setPersonForm({ ...personForm, prefix })}
          />
          <TextField
            type="text"
            id="person-suffix"
            label={t('Suffix')}
            value={personForm.suffix_name || ''}
            onChange={suffix_name =>
              setPersonForm({ ...personForm, suffix_name })
            }
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            type="text"
            id="person-first-name"
            label={t('First name')}
            required
            value={personForm.first_name}
            onChange={first_name =>
              setPersonForm({ ...personForm, first_name })
            }
          />
          <TextField
            type="text"
            id="person-middle-name"
            label={t('Middle name')}
            value={personForm.middle_name}
            onChange={middle_name =>
              setPersonForm({ ...personForm, middle_name })
            }
          />
          <TextField
            type="text"
            id="person-last-name"
            label={t('Last name')}
            required
            value={personForm.last_name}
            onChange={last_name => setPersonForm({ ...personForm, last_name })}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            type="text"
            id="person-pronouns"
            label={t('Pronouns')}
            value={personForm.pronouns || ''}
            onChange={pronouns => setPersonForm({ ...personForm, pronouns })}
          />
        </FieldBlock>
        <Section label={t('Address information')}>
          <FieldBlock>
            <TextField
              type="text"
              id="address-1"
              label={t('Address line 1')}
              value={personForm.residential_address?.line_one || ''}
              onChange={line_one =>
                updateAddressAttribute('line_one', line_one)
              }
            />
            <TextField
              type="text"
              id="address-2"
              label={t('Address line 2')}
              value={personForm.residential_address?.line_two || ''}
              onChange={line_two =>
                updateAddressAttribute('line_two', line_two)
              }
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              type="text"
              id="city"
              label={t('City')}
              value={personForm.residential_address?.city || ''}
              onChange={city => updateAddressAttribute('city', city)}
            />
            <SelectField
              id="us-state"
              label={t('State')}
              options={US.STATES.map(state => ({
                value: state.abbr,
                label: state.name,
              }))}
              value={personForm.residential_address?.state || ''}
              onSelect={state => updateAddressAttribute('state', state)}
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              type="text"
              id="zipcode"
              label={t('Zipcode')}
              value={personForm.residential_address?.zipcode || ''}
              onChange={zipcode => updateAddressAttribute('zipcode', zipcode)}
            />
            <CountySelectField
              id="county"
              label={t('County')}
              state={personForm.residential_address?.state}
              county={personForm.residential_address?.county}
              onSelect={county => updateAddressAttribute('county', county)}
            />
          </FieldBlock>
          <ButtonBlock>
            <Button
              flat
              iconBefore={false}
              iconChildren="close"
              onClick={() =>
                setPersonForm({
                  ...personForm,
                  residential_address: null,
                })
              }
            >
              {t('Clear address')}
            </Button>
          </ButtonBlock>
        </Section>
        <Section label={t('Contact information')}>
          <FieldBlock>
            <TextField
              type="tel"
              id="primary_phone_number"
              label={t('Primary phone number')}
              value={maskPhone(personForm.primary_phone_number) || ''}
              error={
                !!(
                  personForm.primary_phone_number &&
                  maskPhone(personForm.primary_phone_number).length <
                    VALID_PHONE_LENGTH
                )
              }
              errorText={t('Number must contain 10 digits')}
              onChange={number =>
                setPersonForm({
                  ...personForm,
                  primary_phone_number: maskPhone(number),
                })
              }
            />
          </FieldBlock>
          {personForm.contact_methods
            .filter(c => isNonPrimaryPhone(c) && !c.tempId)
            .map((phone, index) => (
              <FieldBlock>
                <SelectField
                  id={`phone-${phone.id}-type`}
                  label={t('Phone type')}
                  options={nonPrimaryPhoneOptions}
                  value={phone.contact_type}
                  error={
                    !!errorsOn(
                      `contact_methods[${indexOfExistingContactMethod(
                        phone.id
                      )}].contact_type`
                    )
                  }
                  errorText={errorsOn(
                    `contact_methods[${indexOfExistingContactMethod(
                      phone.id
                    )}].contact_type`
                  )}
                  onSelect={phoneType => {
                    updateExistingContactMethod(
                      phone.id,
                      'contact_type',
                      phoneType
                    )
                  }}
                />
                <TextField
                  type="tel"
                  id={`phone-${phone.id}-content`}
                  label={t('Phone number')}
                  disabled={!phone.contact_type}
                  value={maskPhone(phone.content) || ''}
                  error={
                    !!(
                      phone.content &&
                      maskPhone(phone.content).length < VALID_PHONE_LENGTH
                    )
                  }
                  errorText={t('Number must contain 10 digits')}
                  onChange={phone => {
                    updateExistingContactMethod(
                      phone.id,
                      'content',
                      maskPhone(phone)
                    )
                  }}
                />
                <div className={styles.extension}>
                  <TextField
                    type="text"
                    id={`phone-${phone.id}-extension`}
                    label={t('Extension')}
                    disabled={!phone.contact_type}
                    value={phone.extension || ''}
                    onChange={extension => {
                      updateExistingContactMethod(
                        phone.id,
                        'extension',
                        extension
                      )
                    }}
                  />
                </div>
                <Button.Secondary
                  aria-label={t('Remove')}
                  onClick={() => {
                    removeExistingContactMethod('phone', phone.id, index)
                  }}
                >
                  <Icon.Times />
                </Button.Secondary>
              </FieldBlock>
            ))}
          {newContactMethods('phone').map((newPhone, index) => (
            <FieldBlock>
              <SelectField
                id={`new-phone-${index}-type`}
                label={t('Phone type')}
                options={nonPrimaryPhoneOptions}
                value={newPhone.contact_type || ''}
                error={
                  !!errorsOn(
                    `contact_methods[${indexOfNewContactMethod(
                      newPhone.tempId
                    )}].contact_type`
                  )
                }
                errorText={errorsOn(
                  `contact_methods[${indexOfNewContactMethod(
                    newPhone.tempId
                  )}].contact_type`
                )}
                onSelect={phoneType => {
                  updateNewContactMethod(
                    newPhone.tempId,
                    'contact_type',
                    phoneType
                  )
                }}
              />
              <TextField
                type="text"
                id={`new-phone-${index}-content`}
                label={t('Phone number')}
                value={maskPhone(newPhone.content) || ''}
                disabled={!newPhone.contact_type}
                error={
                  !!(
                    newPhone.content &&
                    maskPhone(newPhone.content).length < VALID_PHONE_LENGTH
                  )
                }
                errorText={t('Number must contain 10 digits')}
                onChange={phone => {
                  updateNewContactMethod(
                    newPhone.tempId,
                    'content',
                    unmaskPhone(phone).slice(0, 10)
                  )
                }}
              />
              <TextField
                type="text"
                id={`new-phone-${index}-extension`}
                label={t('Extension')}
                value={newPhone.extension || ''}
                disabled={!newPhone.contact_type}
                onChange={extension => {
                  updateNewContactMethod(
                    newPhone.tempId,
                    'extension',
                    extension
                  )
                }}
              />
              <Button.Secondary
                aria-label={t('Remove')}
                onClick={() => {
                  removeNewContactFields(newPhone.tempId)
                }}
              >
                <Icon.Times />
              </Button.Secondary>
            </FieldBlock>
          ))}
          <ButtonBlock>
            <Button
              aria-label={t('Add phone number')}
              onClick={() => addContactMethod('phone')}
            >
              <Icon.Plus />
            </Button>
          </ButtonBlock>
          <FieldBlock>
            <Checkbox
              id="person-sms"
              name="add-sms"
              label={t('Receive SMS notifications')}
              type="checkbox"
              checked={personForm.receives_sms}
              onChange={val =>
                setPersonForm({ ...personForm, receives_sms: val })
              }
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="primary_email_address"
              type="email"
              label={t('Primary email')}
              value={personForm.primary_email_address || ''}
              error={
                !!personForm.primary_email_address &&
                !isValidEmail(personForm.primary_email_address)
              }
              errorText={t('Must be a valid email')}
              onChange={val =>
                setPersonForm({ ...personForm, primary_email_address: val })
              }
            />
          </FieldBlock>
          {personForm.contact_methods
            .filter(c => c.contact_type?.includes('email') && !c.tempId)
            .map((email, index) => (
              <FieldBlock>
                <SelectField
                  id={`email-${email.id}-type`}
                  label={t('Email type')}
                  options={nonPrimaryEmailOptions}
                  value={email.contact_type}
                  error={
                    !!errorsOn(
                      `contact_methods[${indexOfExistingContactMethod(
                        email.id
                      )}].contact_type`
                    )
                  }
                  errorText={errorsOn(
                    `contact_methods[${indexOfExistingContactMethod(
                      email.id
                    )}].contact_type`
                  )}
                  onSelect={val => {
                    updateExistingContactMethod(email.id, 'contact_type', val)
                  }}
                />
                <TextField
                  id={`email-${email.id}`}
                  type="email"
                  label={t('Email')}
                  value={email.content || ''}
                  disabled={!email.contact_type}
                  error={!!email.content && !isValidEmail(email.content)}
                  errorText={t('Must be a valid email')}
                  onChange={email => {
                    updateExistingContactMethod(
                      email.id,
                      'content',
                      sanitizeStr(email)
                    )
                  }}
                />
                <Button
                  aria-label={t('Remove')}
                  onClick={() => {
                    removeExistingContactMethod('email', email.id, index)
                  }}
                >
                  <Icon.Times />
                </Button>
              </FieldBlock>
            ))}
          {newContactMethods('email').map((newEmail, index) => (
            <FieldBlock>
              <SelectField
                id={`new-email-${index}-type`}
                label={t('Email type')}
                options={nonPrimaryEmailOptions}
                value={newEmail.contact_type || ''}
                error={
                  !!errorsOn(
                    `contact_methods[${indexOfNewContactMethod(
                      newEmail.tempId
                    )}].contact_type`
                  )
                }
                errorText={errorsOn(
                  `contact_methods[${indexOfNewContactMethod(
                    newEmail.tempId
                  )}].contact_type`
                )}
                onSelect={emailType => {
                  updateNewContactMethod(
                    newEmail.tempId,
                    'contact_type',
                    emailType
                  )
                }}
              />
              <TextField
                id={`new-email-${index}`}
                label={t('Email')}
                value={newEmail.content || ''}
                error={!!newEmail.content && !isValidEmail(newEmail.content)}
                errorText={t('Must be a valid email')}
                disabled={!newEmail.contact_type}
                onChange={email => {
                  updateNewContactMethod(
                    newEmail.tempId,
                    'content',
                    sanitizeStr(email)
                  )
                }}
              />
              <Button.Secondary
                aria-label={t('Remove')}
                onClick={() => {
                  removeNewContactFields(newEmail.tempId)
                }}
              >
                <Icon.Times />
              </Button.Secondary>
            </FieldBlock>
          ))}
          <ButtonBlock>
            <Button
              aria-label={t('Add email address')}
              onClick={() => addContactMethod('email')}
            >
              <Icon.Plus />
            </Button>
          </ButtonBlock>
        </Section>
        <Section label={t('Social media')}>
          <FieldBlock variant="large">
            {Object.keys(SOCIAL_LINK_TYPES).map(key => (
              <TextField
                key={key}
                type="text"
                id={key}
                label={`${SOCIAL_LINK_TYPES[key]} profile URL`}
                value={getSocialLinkUrl(key)}
                onChange={url =>
                  setPersonForm({
                    ...personForm,
                    social_links: [
                      ...personForm.social_links.filter(
                        link => link.type !== key
                      ),
                      {
                        type: key,
                        url,
                      },
                    ],
                  })
                }
                placeholder={`Ex: https://${key}.com/JaneDoe`}
                error={
                  !!getSocialLinkUrl(key) && !isValidUrl(getSocialLinkUrl(key))
                }
                errorText={`Must be a valid full url. Ex: https://${key}.com/JaneDoe'`}
              />
            ))}
          </FieldBlock>
        </Section>
        <Section label={t('External')}>
          <FieldBlock>
            <TextField
              type="text"
              id="person-external-id"
              label={t('External ID')}
              value={personForm.external_id || ''}
              onChange={external_id =>
                setPersonForm({ ...personForm, external_id })
              }
              error={errorsOn('external_id')}
              errorText={errorsOn('external_id')}
              disabled={!canEditSpecialFields}
            />
          </FieldBlock>
        </Section>
        <ButtonBlock>
          <Button.Accent
            onClick={handleUpdatePerson}
            disabled={!isFormValid() || isLoading}
          >
            {t('Save')}
          </Button.Accent>
          {!hideCancel && (
            <Link to={`/organize/people/${personId}`}>
              <Button.Secondary>{t('Cancel')}</Button.Secondary>
            </Link>
          )}
        </ButtonBlock>
        {!isFormValid() ? (
          <TextBlock>
            {t('Cannot submit. Please check required* fields')}
          </TextBlock>
        ) : null}
      </form>
    </div>
  )
}

export default PersonEditForm
