import { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MultiSearchSelect, SearchSelect } from 'components'
import countiesByState from 'utils/counties.json'
import { useTranslation } from 'react-i18next'
import { uniqueCounties } from 'utils/constants'
import { buildOption } from './utils'

const CountySelectFieldDEPRECATED = ({
  id,
  label,
  onSelect,
  state,
  county,
  counties = [],
  emptyMessage,
  isMulti,
  ...props
}) => {
  const { t } = useTranslation()

  const countyList = useMemo(
    () => (state ? countiesByState[state] : uniqueCounties).map(buildOption),
    [state]
  )
  const [value, setValue] = useState(county)
  const [multiValue, setMultiValue] = useState(counties.map(buildOption))

  useEffect(() => {
    setValue(county)
  }, [county])

  useEffect(() => {
    setMultiValue(counties.map(buildOption))
  }, [JSON.stringify(counties)])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(({ value }) => value))
  }

  return isMulti ? (
    <MultiSearchSelect
      id={id}
      label={label || t('Counties')}
      options={countyList}
      value={multiValue}
      onChange={handleMultiChange}
      noOptionsMessage={t('Select a state first')}
      {...props}
    />
  ) : (
    <SearchSelect
      id={id}
      label={label || t('County')}
      options={countyList}
      value={value || ''}
      onChange={handleChange}
      noOptionsMessage={t('Select a state first')}
      {...props}
    />
  )
}

CountySelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  county: PropTypes.string,
  state: PropTypes.string,
  shouldFetch: PropTypes.bool,
}

CountySelectFieldDEPRECATED.defaultProps = {
  id: 'county',
  label: 'County',
  county: '',
  state: '',
  shouldFetch: false,
}

export default CountySelectFieldDEPRECATED
