import React from 'react'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { formatPhoneNumber } from 'utils/formatting'

export const PeopleNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/people/${rowData.id}`}>{rowData.name}</Link>
  </TableGridCell>
)

export const PeoplePhoneNumberCell = ({ rowData }) => (
  <TableGridCell
    subtitle={
      rowData.contact_methods.filter(c => c.contact_type.includes('phone')) > 1
        ? `+${
            rowData.contact_methods.filter(c =>
              c.contact_type.includes('phone')
            ).length - 1
          } more`
        : null
    }
  >
    {formatPhoneNumber(rowData.primary_phone_number)}
  </TableGridCell>
)

export const PeopleOrganizationCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.organizations.map(organization => organization.name).join(', ')}
  </TableGridCell>
)

export const PeopleAddressCell = ({ rowData }) => (
  <TableGridCell>
    {[
      rowData.residential_address?.line_one,
      rowData.residential_address?.line_two,
    ].join(' ')}
  </TableGridCell>
)

export const PeopleCityCell = ({ rowData }) => (
  <TableGridCell>{rowData.residential_address?.city}</TableGridCell>
)

export const PeopleStateCell = ({ rowData }) => (
  <TableGridCell>{rowData.residential_address?.state}</TableGridCell>
)

export const PeopleZipCodeCell = ({ rowData }) => (
  <TableGridCell>{rowData.residential_address?.zipcode}</TableGridCell>
)

export const PeopleEmailCell = ({ rowData }) => (
  <TableGridCell
    subtitle={
      rowData.contact_methods.filter(c => c.contact_type.includes('email')) > 1
        ? `+${
            rowData.contact_methods.filter(c =>
              c.contact_type.includes('email')
            ).length - 1
          } more`
        : null
    }
  >
    {rowData.primary_email_address}
  </TableGridCell>
)
