import moment from 'moment-timezone'
import { combineName, secondsToHours, summaryFunctions } from 'utils/reporting'
import { ellipsisModalCellRender } from '../../bodyCells/EllipsisModalCell'
import { flagCommentsModalCellRenderer } from '../../bodyCells/FlagCommentsModalCell/FlagCommentsModalCell'

export default {
  id: 'qc_flag_status',
  name: 'Flag Status',
  description: 'This report provides information on all flags.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1457864830',
  columns: [
    {
      dataKey: 'flag_id',
      title: 'Flag ID',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    {
      dataKey: 'comments',
      title: 'Comments',
      type: 'string',
      resizeable: true,
      bodyCell: flagCommentsModalCellRenderer,
    },
    {
      dataKey: 'turf_name',
      title: 'Turf name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'category',
      title: 'Category',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.implies_canvasser_issue ? 'Canvasser' : 'Technical',
    },
    {
      dataKey: 'flag_details',
      title: 'Details',
      type: 'string',
      bodyCell: ellipsisModalCellRender,
      resizable: true,
    },
    {
      dataKey: 'flag_date',
      title: 'Flag date',
      type: 'date',
      resizable: true,
      bodyCell: ({ cellData }) => {
        if (cellData === '') return cellData
        return moment
          .tz(cellData, 'America/New_York')
          .format('YYYY-MM-DD h:mm A z')
      },
      csvCell: ({ flag_date }) => {
        if (flag_date === '') return flag_date
        return moment
          .tz(flag_date, 'America/New_York')
          .format('YYYY-MM-DD h:mm A z')
      },
    },
    {
      dataKey: 'flag_status',
      title: 'Flag status',
      type: 'string',
      resizable: true,
      deriveFunction: ({ cellData }) => {
        if (cellData === 'pending') return 'awaiting table review'
        if (cellData === 'ready') return 'ready for group qc action'
        if (cellData === 'reviewed') return 'awaiting resolution'
        if (cellData === 'completed') return 'resolved'
        return cellData
      },
    },
    {
      dataKey: 'original_filename',
      title: 'Packet name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'scans_count',
      title: 'Scans count',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'time_to_resolve',
      title: 'Flag Resolution Time (hours)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => secondsToHours(rowData.time_to_resolve),
      summaryFunction: summaryFunctions.avg,
    },
  ],
}
