import React from 'react'
import { useTranslation } from 'react-i18next'
import { PdfViewer } from 'components'

export const ScanPdfViewer = ({ scanUrl, ...props }) => {
  const { t } = useTranslation()
  return scanUrl ? (
    <PdfViewer
      file={scanUrl}
      documentLoadingMessage={t('Scan loading...')}
      {...props}
    />
  ) : null
}

export const CoverPdfViewer = ({ url, ...props }) => {
  const { t } = useTranslation()
  return (
    <PdfViewer
      file={url}
      documentLoadingMessage={t('Cover sheet loading...')}
      {...props}
    />
  )
}
