import { createContext, useContext, useEffect, useState } from 'react'
import { useEvent, useReactRouter, useRequest } from 'hooks'
import { useCheckboxGroup } from 'hooks/useCheckboxGroup'
import { getAbilityDependants, getAbilityDependencies } from 'utils/roles'
import { formatErrorMessage } from 'utils/formatting'
import { useCurrent } from 'contexts/index'
import { RoleContext } from '../RoleContext/RoleContext'

const defaultPendingState = {
  ability: null,
  dependencies: [],
  dependants: [],
}

export const RoleAbilityContext = createContext()

export const RoleAbilityProvider = ({ children }) => {
  const { match } = useReactRouter()
  const {
    currentRoleId,
    currentRole,
    updateRoleAbilities,
    setRoleModified,
    getTenantPermissions,
    currentRolePermissions,
  } = useContext(RoleContext)
  const currentRoleNeedsTraining = Boolean(currentRole.needs_training)
  const { tenantBlocks } = useCurrent()
  const tenantPermissionAbilities = getTenantPermissions(tenantBlocks).flatMap(
    permission => permission.abilities
  )
  const checkboxManagement = useCheckboxGroup(tenantPermissionAbilities)
  const [pendingAction, setPendingAction] = useState(defaultPendingState)
  const [needsTraining, setNeedsTraining] = useState(currentRoleNeedsTraining)
  const [searchString, setSearchString] = useState('')

  const toggleNeedsTraining = value => {
    setRoleModified(true)
    setNeedsTraining(value)
  }

  const clearPendingAction = () => {
    setPendingAction(defaultPendingState)
  }

  const { makeRequest, isLoading, errors, clearRequest } = useRequest(
    updateRoleAbilities,
    {
      onSuccess: () => {
        setRoleModified(false)
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  const setInitialAbilities = useEvent(() => {
    const abilities = tenantPermissionAbilities
      .filter(ability => {
        const hasAbility = currentRolePermissions.includes(ability.id)
        const isChecked = checkboxManagement.isChecked(ability.id)

        return hasAbility !== isChecked
      })
      .map(ability => ability.id)

    checkboxManagement.toggleCheckboxes(abilities)
    setNeedsTraining(currentRoleNeedsTraining)
    setRoleModified(false)
  })

  useEffect(() => {
    setInitialAbilities()
  }, [currentRoleId, currentRolePermissions])

  useEffect(() => {
    setNeedsTraining(currentRoleNeedsTraining)
  }, [currentRoleId, currentRoleNeedsTraining])

  useEffect(() => {
    clearRequest()
  }, [match.params.id])

  const checkDependencies = ability => {
    const dependencies = getAbilityDependencies(ability).filter(
      dependency => !checkboxManagement.isChecked(dependency.id)
    )

    if (dependencies.length) {
      setPendingAction({ ability, dependencies, dependants: [] })
      return false
    }

    return true
  }

  const checkDependants = ability => {
    const dependants = getAbilityDependants(ability).filter(dependant =>
      checkboxManagement.isChecked(dependant.id)
    )

    if (dependants.length) {
      setPendingAction({ ability, dependants, dependencies: [] })
      return false
    }

    return true
  }

  const toggleAbility = ability => {
    const isChecked = checkboxManagement.isChecked(ability.id)
    const hasRequirements = isChecked
      ? checkDependants(ability)
      : checkDependencies(ability)

    if (hasRequirements) {
      setRoleModified(true)
      checkboxManagement.toggleCheckbox(ability.id)
    }
  }

  const toggleAbilities = () => {
    const { ability, dependants, dependencies } = pendingAction
    const isChecked = checkboxManagement.isChecked(ability.id)
    const abilities = [ability, ...(isChecked ? dependants : dependencies)]

    setRoleModified()
    checkboxManagement.toggleCheckboxes(abilities.map(({ id }) => id))
    clearPendingAction()
  }

  const saveRoleAbilities = () => {
    const abilities = checkboxManagement.getCheckboxValues().map(ability => ({
      permission: ability.value.permission.id,
      value: ability.value.value,
      checked: ability.checked,
    }))
    makeRequest(abilities, needsTraining)
  }

  return (
    <RoleAbilityContext.Provider
      value={{
        ...checkboxManagement,
        toggleAbility,
        toggleAbilities,
        needsTraining,
        toggleNeedsTraining,
        errorMsg,
        isLoading,
        pendingAction,
        clearPendingAction,
        saveRoleAbilities,
        setInitialAbilities,
        searchString,
        setSearchString,
      }}
    >
      {children}
    </RoleAbilityContext.Provider>
  )
}
