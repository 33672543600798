import { useTranslation } from 'react-i18next'
import {
  TextBlock,
  Section,
  List,
  ListItem,
  Font,
} from '@politechdev/blocks-design-system'
import { useContext, useEffect, useState } from 'react'
import { SendContext } from '../SendContext/SendContext'

const SendRecipients = () => {
  const { t } = useTranslation()
  const { contacts, contactMeta } = useContext(SendContext)
  const [expand, setExpand] = useState(false)
  const displayContacts = !expand ? contacts.slice(0, 5) : contacts

  useEffect(() => {
    setExpand(false)
  }, [contacts])

  return (
    <Section
      empty={!contactMeta.total_count}
      emptyMessage={t('No contacts with emails found in the list')}
    >
      <TextBlock>
        <Font.Label>{t('In this list')}</Font.Label>
      </TextBlock>
      <TextBlock>
        <Font.Label variant="detail">{`${contactMeta.total_count} ${t(
          contactMeta.total_count === 1 ? 'contact' : 'contacts'
        )} ${t('with email')}`}</Font.Label>
      </TextBlock>
      <List
        itemData={displayContacts}
        render={(contact, i) => (
          <ListItem key={`${contact.name}-${i}`}>
            <TextBlock>{contact.name}</TextBlock>
          </ListItem>
        )}
      />
      {!expand && contactMeta.total_count > 5 && (
        <TextBlock>
          <span onClick={() => setExpand(true)}>
            {`${contactMeta.total_count - 5} ${t('more')}`}&hellip;
          </span>
        </TextBlock>
      )}
    </Section>
  )
}

export default SendRecipients
