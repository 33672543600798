import React, { useState } from 'react'
import { useRequest } from 'hooks'
import { Button } from 'react-md'
import { SendTextDialog } from 'components'
import { useTranslation } from 'react-i18next'
import { textTeam } from 'requests/teams'

const TextTeamDialog = ({ teamId }) => {
  const { t } = useTranslation()

  const [dialogVisible, setDialogVisble] = useState(false)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest } = useRequest(textTeam)

  const hideDialog = () => {
    setDialogVisble(false)
  }

  const showDialog = () => {
    setDialogVisble(true)
  }

  const sendText = textContent => makeRequest(teamId, textContent)

  return (
    <span>
      <Button
        icon
        aria-label={t('Text team')}
        tooltipLabel={t('Text team')}
        onClick={showDialog}
      >
        sms
      </Button>
      {dialogVisible && (
        <SendTextDialog
          onClose={hideDialog}
          isOpen={dialogVisible}
          onSend={sendText}
        />
      )}
    </span>
  )
}

export default TextTeamDialog
