import { useTranslation } from 'react-i18next'
import {
  CONNECTING,
  ACTIVE,
  REVIEW,
  COMPLETE,
  READY,
} from 'constants/phoneBankSessions'
import { TimerDisplay } from 'components'
import PropTypes from 'prop-types'
import styles from './CallPanelHeader.module.scss'

const CallPanelHeader = ({ callStep, showTimer, secondsElapsed }) => {
  const { t } = useTranslation()

  const headerText = {
    [READY]: t('Before you begin...'),
    [CONNECTING]: t('Connecting...'),
    [ACTIVE]: t('On a call...'),
    [REVIEW]: t('Call ended'),
    [COMPLETE]: t('Call ended'),
  }

  return (
    <div className={styles.header}>
      <h2>{headerText[callStep]}</h2>
      {showTimer && <TimerDisplay secondsElapsed={secondsElapsed} />}
    </div>
  )
}

export default CallPanelHeader

CallPanelHeader.propTypes = {
  callStep: PropTypes.string,
  showTimer: PropTypes.bool,
  secondsElapsed: PropTypes.number,
}
