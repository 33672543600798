import {
  GuestsNameCell,
  GuestsEmailCell,
  GuestsPhoneCell,
  guestsAddressCellRender,
  invitedByCellRender,
  guestsStatusCellRender,
  guestRoleCellRender,
  PersonFirstNameCell,
  PersonLastNameCell,
  cellForGuestNeed,
} from './GuestsTableCells'

import {
  guestNameCsvCell,
  guestAddressCsvCell,
  guestPhoneCsvCell,
  guestStatusCsvCell,
  guestEmailCsvCell,
  invitedByCsvCell,
  guestRoleCsvCell,
  PersonFirstNameCsvCell,
  PersonLastNameCsvCell,
  csvCellForGuestNeed,
} from './csvCells'

const extraFields = [
  {
    extraField: 'child_care_needs',
    needsKey: 'child_care',
    title: 'Child care needs (if any)',
  },
  {
    extraField: 'transportation_needs',
    needsKey: 'transportation',
    title: 'Transportation needs (if any)',
  },
  {
    extraField: 'dietary_restrictions',
    needsKey: 'diet',
    title: 'Dietary restrictions (if any)',
  },
  {
    extraField: 'preferred_language',
    needsKey: 'language',
    title: 'Preferred language',
  },
  {
    extraField: 'other',
    needsKey: 'other',
  },
]

const buildExtraFieldColumns = public_settings =>
  extraFields
    .filter(({ extraField }) =>
      public_settings?.extra_fields?.includes(extraField)
    )
    .map(({ extraField, needsKey, title }) => ({
      dataKey: extraField,
      title: title ?? public_settings?.other_field_label,
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: cellForGuestNeed(needsKey),
      csvCell: csvCellForGuestNeed(needsKey),
      filterOperators: [
        {
          prefix: 'contains',
          value: 'containing',
          inputProps: {
            type: 'text',
            placeholder: 'Text to search',
          },
        },
      ],
      filterConstructor: rule => ({
        rules: [
          {
            column: extraField,
            operator: 'containing',
            param: rule.param,
          },
        ],
        conjunction: 'inclusive',
      }),
    }))

export default {
  label: 'attendees',
  defaultSortColumn: 'person_full_name',
  defaultSortOrder: 'asc',
  columns: [
    {
      dataKey: 'person_full_name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: GuestsNameCell,
      csvCell: guestNameCsvCell,
      filterOperators: [
        {
          prefix: 'contains',
          value: 'containing',
          inputProps: {
            type: 'text',
            placeholder: 'Text to search',
          },
        },
      ],
      filterConstructor: rule => ({
        rules: [
          {
            column: 'person_full_name',
            operator: 'containing',
            param: rule.param,
          },
        ],
        conjunction: 'inclusive',
      }),
    },
    {
      dataKey: 'person_first_name',
      title: 'First name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PersonFirstNameCell,
      csvCell: PersonFirstNameCsvCell,
    },
    {
      dataKey: 'person_last_name',
      title: 'Last name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: PersonLastNameCell,
      csvCell: PersonLastNameCsvCell,
    },
    {
      dataKey: 'person_email',
      title: 'Email',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: GuestsEmailCell,
      csvCell: guestEmailCsvCell,
    },
    {
      dataKey: 'person_primary_phone_number',
      title: 'Phone number',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: GuestsPhoneCell,
      csvCell: guestPhoneCsvCell,
    },
    {
      dataKey: 'person_residential_address_line_one',
      title: 'Address',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: guestsAddressCellRender,
      csvCell: guestAddressCsvCell,
    },
    {
      dataKey: 'role',
      title: 'Role',
      type: 'string',
      autoResize: false,
      resizable: true,
      width: 100,
      bodyCell: guestRoleCellRender,
      csvCell: guestRoleCsvCell,
    },
    {
      dataKey: 'inviter',
      title: 'Invited By',
      type: 'string',
      autoResize: false,
      resizable: true,
      width: 100,
      bodyCell: invitedByCellRender,
      csvCell: invitedByCsvCell,
    },
    {
      dataKey: 'status',
      title: 'Attended',
      type: 'string',
      autoResize: false,
      resizable: true,
      width: 100,
      bodyCell: guestsStatusCellRender,
      csvCell: guestStatusCsvCell,
    },
  ],
  buildExtraFieldColumns,
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: {
          hiddenColumns: [
            'person_first_name',
            'person_last_name',
            ...extraFields.map(({ extraField }) => extraField),
          ],
        },
      },
    ]
  },
}
