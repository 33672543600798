import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  TextField,
  SelectField,
  Sheet,
  ProgressBar,
  Font,
  Button,
  ButtonBlock,
  FieldBlock,
  TextBlock,
  Icon,
  Tag,
} from '@politechdev/blocks-design-system'
import { pull } from 'lodash'
import { PhoneBankContext } from 'phone_banks/PhoneBankContext/PhoneBankContext'
import styles from './QuestionNewForm.module.scss'
import scriptItemStyles from '../ScriptItem/ScriptItem.module.scss'
import TagInterpolateBlock from '../TagInterpolateBlock/TagInterpolateBlock'

const QuestionNewForm = () => {
  const { t } = useTranslation()

  const {
    makeScriptQuestion,
    scriptQuestionReqIsLoading,
    scriptQuestionReqHasErrors,
  } = useContext(PhoneBankContext)

  const [state, setState] = useState({
    content: '',
    type: 'single_choice',
    choices: [
      { id: null, response: t('Yes') },
      { id: null, response: t('No') },
      { id: null, response: t('Maybe') },
    ],
    tag: undefined,
    showExisting: false,
  })

  const QUESTION_TYPE_MAP = {
    single_choice: {
      label: t('Single answer'),
      icon: Icon.Circle({}),
      value: 'single_choice',
    },
    multiple_choice: {
      label: t('Checkboxes'),
      value: 'multiple_choice',
      icon: Icon.Square({}),
    },
    open_ended: {
      label: t('Open-ended'),
      value: 'open_ended',
      icon: null,
    },
  }

  const { content, choices, type, tag } = state

  return (
    <Sheet>
      <ProgressBar show={scriptQuestionReqIsLoading} />
      <CardError
        hide={!scriptQuestionReqHasErrors}
        message={t('There was an error creating this question.')}
      />
      <TagInterpolateBlock
        currentTag={tag}
        currentText={content}
        updateTag={val => setState(prevState => ({ ...prevState, tag: val }))}
        updateText={val =>
          setState(prevState => ({ ...prevState, content: val }))
        }
        selectionStartId="question-text"
        tagAllowed={type !== 'open_ended'}
      />
      <div className={scriptItemStyles['script-item__name-and-tag']}>
        <TextField
          className={scriptItemStyles['script-item__name-and-tag__field']}
          id="question-text"
          label={t('Question text')}
          value={content}
          rows={1}
          onChange={val =>
            setState(prevState => ({ ...prevState, content: val }))
          }
          ignoreWhitespace
        />
        <div>{tag && <Tag>{tag}</Tag>}</div>
      </div>
      <FieldBlock>
        <SelectField
          id="question-type"
          label={t('Question type')}
          options={Object.values(QUESTION_TYPE_MAP)}
          onSelect={val => {
            setState(prevState => ({
              ...prevState,
              type: val,
              tag: val === 'open_ended' ? undefined : prevState.tag,
            }))
          }}
          value={type}
        />
      </FieldBlock>
      {type !== 'open_ended' && (
        <>
          <TextBlock>
            <Font.Copy variant="hint">{t('Answer choices')}</Font.Copy>
          </TextBlock>
          <TextBlock>
            {(choices || []).map((choice, i) => (
              <div
                key={`choice-${i}`}
                className={scriptItemStyles['script-item__choices--edit']}
              >
                {QUESTION_TYPE_MAP[type].icon}
                <TextField
                  id={`script-edit-choice-${i}`}
                  className={
                    scriptItemStyles['script-item__choices--edit__field']
                  }
                  name={`script-edit-choice-${i}`}
                  value={choice.response}
                  onChange={val =>
                    setState(prevState => ({
                      ...prevState,
                      choices: choices.map((item, ii) =>
                        i === ii ? { id: null, response: val } : item
                      ),
                    }))
                  }
                />
                <Button.Secondary
                  icon
                  onClick={() => {
                    setState(prevState => ({
                      ...prevState,
                      choices: pull(choices, choice),
                    }))
                  }}
                  aria-label={t('Remove choice')}
                >
                  <Icon.Times alt={t('Remove choice')} />
                </Button.Secondary>
              </div>
            ))}
            <Button.Secondary
              className={styles['choice__add-button']}
              onClick={() => {
                setState(prevState => ({
                  ...prevState,
                  choices: [...choices, { id: null, response: '' }],
                }))
              }}
              aria-label={t('Add choice')}
            >
              <Icon.Plus alt={t('Add choice')} />
            </Button.Secondary>
          </TextBlock>
        </>
      )}
      <ButtonBlock justify="left">
        <Button.Accent
          onClick={() => {
            makeScriptQuestion({
              type,
              question_to_ask: content,
              tags_list: tag ? [tag.trim().toLowerCase()] : [],
              answer_options: type !== 'open-ended' ? choices : undefined,
            })
          }}
        >
          {t('Save')}
        </Button.Accent>
      </ButtonBlock>
    </Sheet>
  )
}

export default QuestionNewForm
