import Plugin from '@uppy/core/lib/BasePlugin'
import languageEncoding from 'detect-file-encoding-and-language'

export default class CharacterValidator extends Plugin {
  constructor(uppy, opts = {}) {
    super(uppy, opts)
    this.id = opts.id || 'charactervalidator'
    this.type = 'preprocessor'
  }

  detectEncoding = async fileId => {
    const file = this.uppy.getFile(fileId)
    const { encoding } = await languageEncoding(file.data)
    if (encoding !== 'UTF-8') {
      this.uppy.removeFile(fileId)
      this.uppy.emit('error', file, new Error('File is not valid UTF-8'))
    }
  }

  processCSVs = async fileIds => {
    await Promise.all(
      fileIds.map(fileId => ({
        fileId,
        isValid: this.detectEncoding(fileId),
      }))
    )
  }

  install() {
    this.uppy.addPreProcessor(this.processCSVs)
  }

  uninstall() {
    this.uppy.removePreProcessor(this.processCSVs)
  }
}
