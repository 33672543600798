import { useEffect, useState } from 'react'
import { List, ListItem, CardError, ViewContainer } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Font,
  Icon,
  PageHeader,
  Sheet,
  Section,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { fetchExports } from 'requests/exports'
import { useReactRouter, useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useTurfs } from 'contexts/index'
import styles from './ScheduledExportsSelect.module.scss'

const INITIAL_PAGE_SIZE = 5

const ScheduledExportsSelect = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const { currentTurfs: turfOptions } = useTurfs()

  const [scheduledExports, setScheduledExports] = useState([])
  const [meta, setMeta] = useState({})
  const [page, setPage] = useState(1)
  const [per, setPer] = useState(INITIAL_PAGE_SIZE)

  const { makeRequest, isLoading, hasErrors } = useRequest(fetchExports, {
    onSuccess: ({ scheduled_exports, meta }) => {
      setScheduledExports(scheduled_exports)
      setMeta(meta)
    },
  })

  useEffect(() => {
    makeRequest({
      fields: [
        'id',
        'attachment_name',
        'frequency',
        'paused',
        { turfs: ['name'] },
      ],
      associations: ['turfs'],
      indexed: true,
      per,
      current_page: page,
    })
  }, [per, page])

  const routeToNew = () => {
    history.push('/data_sources/scheduled_exports/new')
  }

  const routeToSingle = id => {
    history.push(`/data_sources/scheduled_exports/${id}`)
  }

  const pageBack = () => setPage(page => page - 1)
  const pageForward = () => setPage(page => page + 1)
  const totalPages = Math.ceil(meta.total_count / meta.per)

  return (
    <div>
      <PageHeader title={t('Exports')}>
        <ButtonBlock justify="right">
          <Button.Accent onClick={routeToNew}>{t('Add export')}</Button.Accent>
        </ButtonBlock>
      </PageHeader>
      <ProgressBar show={isLoading} />
      <CardError
        hide={!hasErrors}
        message={t('There was an error loading your exports')}
      />
      {scheduledExports?.length ? (
        <ViewContainer>
          <Sheet>
            <List>
              {scheduledExports.map(record => (
                <ListItem
                  key={record.id}
                  primaryText={record.attachment_name}
                  secondaryText={
                    record.paused
                      ? t('Paused')
                      : `${t('Exported')} ${record.frequency}`
                  }
                  details={
                    record.turfs.length > 0 &&
                    (() => (
                      <div key="turf">
                        {record.turfs.length === turfOptions.length
                          ? t('All turfs')
                          : record.turfs[0].name}
                      </div>
                    ))
                  }
                  onClick={() => {
                    routeToSingle(record.id)
                  }}
                />
              ))}
            </List>
            <div className={styles.paginator__wrapper}>
              {meta.total_count > INITIAL_PAGE_SIZE && (
                <>
                  <TextBlock>
                    <Font.Copy variant="secondary" Element="span">
                      {meta.total_count} {t('results')}
                    </Font.Copy>
                  </TextBlock>
                  <ButtonBlock justify="right">
                    <div className={styles.paginator__item}>
                      <select
                        name="page-size"
                        className={styles.paginator__dropdown}
                        value={per}
                        onChange={e => {
                          setPer(+e.target.value)
                          setPage(1)
                        }}
                      >
                        {[5, 10, 20, 50, 100].map(option => (
                          <option value={option}>{option}</option>
                        ))}
                      </select>
                      <Font.Copy variant="hint" Element="span">
                        {t('Per page')}
                      </Font.Copy>
                    </div>
                    <Button.Secondary onClick={pageBack} disabled={page === 1}>
                      <Icon.ArrowLeft />
                    </Button.Secondary>
                    <Font.Copy variant="hint" Element="span">
                      {t('Page')} {page} {t('of')} {totalPages}
                    </Font.Copy>
                    <Button.Secondary
                      onClick={pageForward}
                      disabled={page === totalPages}
                    >
                      <Icon.ArrowRight />
                    </Button.Secondary>
                  </ButtonBlock>
                </>
              )}
            </div>
          </Sheet>
        </ViewContainer>
      ) : (
        <Section label={t('Ready to set up your first export?')}>
          <TextBlock>
            {t(
              'Exports allow you to send exports of your Blocks data via email on a predefined schedule or on-demand.'
            )}
          </TextBlock>
          <ButtonBlock>
            <Button.Accent onClick={routeToNew}>
              {t('Create an export')}
            </Button.Accent>
          </ButtonBlock>
        </Section>
      )}
    </div>
  )
}

export default ScheduledExportsSelect
