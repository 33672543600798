import { SORT_OPTIONS } from 'constants/packetFilters'
import US from 'us'

export const SHOW_ALL = ''
export const OVERDUE = 'Less than 0'
export const ZERO = '0'
export const ONE = '1'
export const TWO = '2'
export const THREE = '3'
export const FOUR = '4'
export const FIVE = '5'
export const THREE_TO_FIVE = '3 - 5'
export const MORE_THAN_FIVE = 'More than 5'
export const PT = 'PT'
export const MT = 'MT'
export const ET = 'ET'
export const CT = 'CT'
export const VISUAL_QC = 'viz_qc'
export const PHONE_VERIFICATION = 'phone_ver'
const allUSStates = US.STATES.map(state => ({
  label: state.name,
  value: state.abbr,
}))

export default {
  daysSinceCollection: [
    { label: 'Show all', value: SHOW_ALL },
    { label: '0', value: ZERO },
    { label: '1', value: ONE },
    { label: '2', value: TWO },
    { label: '3', value: THREE },
    { label: '4', value: FOUR },
    { label: '5', value: FIVE },
    { label: 'More than 5', value: MORE_THAN_FIVE },
  ],
  daysLeft: [
    { label: 'Show all', value: SHOW_ALL },
    { label: 'Overdue', value: OVERDUE },
    { label: '0', value: ZERO },
    { label: '1', value: ONE },
    { label: '2', value: TWO },
    { label: '3', value: THREE },
    { label: '4', value: FOUR },
    { label: '5', value: FIVE },
  ],
  timeZone: [
    { label: 'Show all', value: SHOW_ALL },
    { label: ET, value: 'America/New_York' },
    { label: CT, value: 'America/Chicago' },
    { label: MT, value: 'America/Phoenix' },
    { label: PT, value: 'America/Los_Angeles' },
  ],
  unitedStates: [{ label: 'Show all', value: SHOW_ALL }, ...allUSStates],
  sortByList: [
    { label: 'Days left', value: SORT_OPTIONS.DAYS_LEFT },
    { label: 'Timezone', value: SORT_OPTIONS.TIME_ZONE },
    {
      label: 'Days since collection',
      value: SORT_OPTIONS.DAYS_SINCE_COLLECTION,
    },
    { label: 'State', value: SORT_OPTIONS.UNITED_STATES },
  ],
}
