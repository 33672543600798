import { Modal } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import CreatePartialPerson from './CreatePartialPerson'
import styles from './CreatePartialPersonModal.module.scss'

const CreatePartialPersonModal = ({
  isOpen,
  setIsOpen,
  onCreated,
  languages,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      id="create-partial-person"
      title={t('Create person')}
      isOpen={isOpen}
      className={styles.modal}
    >
      <CreatePartialPerson
        onCreated={onCreated}
        onClose={() => setIsOpen(false)}
        languages={languages}
      >
        <Modal.Body>
          <CreatePartialPerson.Body />
        </Modal.Body>
        <Modal.Actions>
          <CreatePartialPerson.Actions />
        </Modal.Actions>
      </CreatePartialPerson>
    </Modal>
  )
}

export default CreatePartialPersonModal
