import React from 'react'
import { Switch, Route } from 'react-router-dom'
import TeamSelect from '../TeamSelect/TeamSelect'
import TeamSingle from '../TeamSingle/TeamSingle'
import TeamNew from '../TeamNew/TeamNew'
import TeamEdit from '../TeamEdit/TeamEdit'

const TeamRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={TeamSelect} />
    <Route exact path={`${match.path}/new`} component={TeamNew} />
    <Route exact path={`${match.path}/:teamId`} component={TeamSingle} />
    <Route path={`${match.path}/:id/edit`} component={TeamEdit} />
  </Switch>
)

export default TeamRoutes
