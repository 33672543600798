import React from 'react'
import SearchResultDocument from '../SearchResultDocument/SearchResultDocument'
import SearchResultFolder from '../SearchResultFolder/SearchResultFolder'
import styles from './SearchResults.module.scss'

const SearchResults = ({ folders, documents }) => (
  <div className={styles.results}>
    {folders.map(folder => (
      <SearchResultFolder key={folder.id} folder={folder} />
    ))}
    {documents.map(document => (
      <SearchResultDocument key={document.id} document={document} />
    ))}
  </div>
)

export default SearchResults
