import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import { formatPhoneNumber } from 'utils/formatting'

const FormattedData = ({ value, type, fallback, format }) => {
  const { i18n } = useTranslation()

  switch (type) {
    case 'date':
      return moment(value, format).isValid() ? (
        <span>
          {moment(value, format)
            .locale(i18n.language || 'en')
            .format('MMM D, YYYY')}
        </span>
      ) : (
        <span>{fallback || ''}</span>
      )

    case 'time':
      return moment(value, format).isValid() ? (
        <span>{moment(value, format).format('h:mm A')}</span>
      ) : (
        <span>{fallback || ''}</span>
      )

    case 'dateTime':
      return moment(value, format).isValid() ? (
        <span>
          {moment(value, format)
            .locale(i18n.language || 'en')
            .format('MMM D, YYYY, h:mm A')}
        </span>
      ) : (
        <span>{fallback || ''}</span>
      )

    case 'phone':
      return formatPhoneNumber(value)

    default:
      // eslint-disable-next-line no-console
      console.error(
        `No valid type was provided to FormattedData. Expected 'date', 'time', 'dateTime' or 'phone'`
      )
      return value
  }
}

FormattedData.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  fallback: PropTypes.string,
}

FormattedData.defaultProps = {
  value: '',
}

export default FormattedData
