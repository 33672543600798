import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextBlock,
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import styles from './AbandonRateExceededModal.module.scss'

const AbandonRateExceededModal = ({ modalOpen, setModalOpen }) => {
  const { t } = useTranslation()

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <Modal
      id="abandon-rate-exceeded-modal"
      title={t('Abandon rate exceeded limit')}
      isOpen={modalOpen}
      className={styles.modal}
    >
      <Modal.Body>
        <ContentBlock>
          <TextBlock className={styles.text}>
            {t(
              'Too many contacts have been abandoned. An abandon is when a contact answered the phone but there were no available agents to answer on the other end. Your abandon rate exceeded 20%, so we are automatically preventing your from adding more calls until your abandon rate decreases. You can manually override this block if you wish to proceed anyway.'
            )}{' '}
          </TextBlock>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Accent onClick={closeModal}>{t('Done')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default AbandonRateExceededModal
