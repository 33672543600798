import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  Modal,
  TextBlock,
} from '@politechdev/blocks-design-system'

const MissingNameFieldWarningModal = ({
  isOpen,
  close,
  saveUserRequest,
  firstMissing,
  lastMissing,
}) => {
  const { t } = useTranslation()

  const onApply = () => {
    saveUserRequest()
    close()
  }

  const onCancel = () => {
    close()
  }

  const missingString = [
    firstMissing ? 'first name' : undefined,
    lastMissing ? 'last name' : undefined,
  ]
    .filter(p => p)
    .join(' and ')

  return (
    <Modal
      id="warn-missing-user-name"
      title={t(`Save without a ${missingString}?`)}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <p>{t(`Would you like to continue without a ${missingString}?`)}</p>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Danger onClick={onApply}>
            {t('Save without name')}
          </Button.Danger>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default MissingNameFieldWarningModal
