import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextBlock,
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import styles from './EndSessionModal.module.scss'

const EndSessionModal = ({ endSession }) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Button.Danger onClick={openModal}>{t('End session')}</Button.Danger>
      <Modal
        id="end-session"
        title={t('End Session')}
        isOpen={modalOpen}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <TextBlock className={styles.text}>
              {t(
                'This will end the session immediately for all agents who are not currently on a call. The session will stay active for those currently on a call and end it as soon as they finish their submissions.'
              )}{' '}
            </TextBlock>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                closeModal()
                endSession()
              }}
            >
              {t('End Session')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default EndSessionModal
