export const buildOption = team => ({
  value: team.id,
  label: team.name,
})

export const buildTeam = option => ({
  id: option.value,
  name: option.label,
})

export const buildParams = ({ query, filters = [] }) => ({
  filters: {
    rules: [
      query && query !== ''
        ? {
            rules: [
              {
                column: 'name',
                operator: 'containing',
                param: query,
              },
            ],
            conjunction: 'inclusive',
          }
        : undefined,
      ...filters,
    ],
    conjunction: 'exclusive',
  },
  fields: ['id', 'name'],
  sort_attr: 'name',
  sort_dir: 'asc',
  per: 25,
  indexed: true,
})
