import React from 'react'
import TopBar from '../TopBar/TopBar'
import ImpersonationBar from '../ImpersonationBar/ImpersonationBar'
import styles from './ApplicationFrame.module.scss'
import Navigation from '../Navigation/Navigation'
import { NavigationProvider } from '../NavigationProvider/NavigationProvider'
import SideNavigationRoutes from '../SideNavigation/SideNavigation'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import SectionHeader from '../SectionHeader/SectionHeader'
import { NavigationPermissionProvider } from '../NavigationPermissionProvider/NavigationPermissionProvider'
import MaintenanceBanner from '../MaintenanceBanner/MaintenanceBanner'

const ApplicationFrame = ({ children }) => (
  <NavigationProvider>
    <NavigationPermissionProvider>
      <div className={styles.frame}>
        <TopBar />
        <MaintenanceBanner />
        <Navigation />
        <BreadCrumbs />
        <SectionHeader />
        <div className={styles.panel}>
          <SideNavigationRoutes />
          <div className={styles.content}>
            <div className={styles.banners}>
              <ImpersonationBar />
            </div>
            {children}
          </div>
        </div>
      </div>
    </NavigationPermissionProvider>
  </NavigationProvider>
)

export default ApplicationFrame
