import { Link } from 'react-router-dom'
import { Avatar, ContentBlock, Button, ButtonBlock } from 'components'
import { useTranslation } from 'react-i18next'
import { StaffBarChart } from 'qualityControl/packetAssignment/staff/StaffBarChart'
import { TrainedStates } from 'qualityControl/packetAssignment/staff/TrainedStates'
import { StaffLanguages } from 'qualityControl/packetAssignment/staff/StaffLanguages'
import { StaffTrainingStatus } from 'qualityControl/packetAssignment/staff/StaffTrainingStatus'
import { formatDisplayName } from 'utils/formatting'
import { getScansAssignedOutOfShiftHours } from 'utils/tableQcManager'
import StaffPermissions from './StaffPermissions'
import styles from './StaffListItem.module.scss'

const StaffListItem = ({ user, Buttons, isAssigning }) => {
  const { t } = useTranslation()
  const scanCount = user.assignedScansCount

  return (
    <div className={styles.item}>
      <div className={styles.item__avatar}>
        <Avatar
          name={formatDisplayName(user)}
          imageUrl={user.avatar}
          imageLarge
          subtitle={user.position}
        />
      </div>
      <div className={styles.item__detail}>
        <TrainedStates trainedStates={user.trainedStates} />
        <p className={styles.item__detail__timezone}>{user.timezone}</p>
      </div>
      <div className={styles.item__badges}>
        <StaffLanguages languages={user.languages} />
        <StaffTrainingStatus status={user.trainingStatus} />
      </div>
      <div className={styles['item__bar-chart']}>
        <StaffBarChart value={scanCount / user.shiftHours} />
        <div className={styles['item__bar-chart__label']}>
          {getScansAssignedOutOfShiftHours(scanCount, user.shiftHours)}
        </div>
      </div>
      <div className={styles.item__permissions}>
        <StaffPermissions permissions={user.permissions} />
      </div>
      <div className={styles.item__actions}>{Buttons}</div>
      {isAssigning && !user.isViewable && (
        <div className={styles.item__notice}>
          <ContentBlock>
            {t(
              'You do not have the required role to assign packets to this user'
            )}
          </ContentBlock>
        </div>
      )}
      {isAssigning && user.isViewable && !!user.unassignablePackets && (
        <div className={styles.item__notice}>
          <ContentBlock>
            {user.assignablePackets
              ? t(
                  `Cannot be assigned ({{count}}) selected packet${
                    user.unassignablePackets !== 1 ? 's' : ''
                  }`,
                  {
                    count: user.unassignablePackets,
                  }
                )
              : t('Cannot be assigned')}
          </ContentBlock>
          <ButtonBlock justify="right">
            <Button primary>
              <Link to={`/admin/users/${user.staffId}/edit`}>
                {t('EDIT USER')}
              </Link>
            </Button>
          </ButtonBlock>
        </div>
      )}
    </div>
  )
}

export default StaffListItem
