import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import { SelectField } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchEmailTemplates } from 'requests/email'
import { formatErrorMessage } from 'utils/formatting'
import { buildParams } from './utils'

const EmailTemplateSelectField = ({
  label,
  filters,
  onSelect,
  template,
  ...props
}) => {
  const { t } = useTranslation()

  const { makeRequest, isLoading, hasErrors, errors, response } =
    useRequest(fetchEmailTemplates)

  const errorMsg = formatErrorMessage(errors)

  const [value, setValue] = useState(template?.id)
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  useEffect(() => {
    if (value !== template?.id) {
      const selection =
        value === null
          ? null
          : response?.email_templates.find(({ id }) => id === value)
      onSelect && onSelect(selection)
    }
  }, [value])

  useEffect(() => {
    if (value !== template?.id) {
      setValue(template?.id)
    }
  }, [template])

  const getTemplates = () => {
    const params = buildParams({
      query: debounced,
      filters,
    })
    makeRequest(params)
  }

  useEffect(() => {
    getTemplates()
  }, [debounced, filters])

  const buildTemplateOptions = template => ({
    value: template.id,
    label: template.name,
  })

  const options = [
    ...(template && query === '' ? [template] : []),
    ...(response?.email_templates || []),
  ]
    .reduce(
      (options, template) =>
        options.find(({ id }) => id === template.id)
          ? options
          : [...options, template],
      []
    )
    .map(buildTemplateOptions)

  return (
    <>
      <CardError hide={!hasErrors} message={errorMsg} />
      <SelectField
        id="template"
        label={label || t('Email draft')}
        options={options}
        onSelect={setValue}
        onInputChange={setQuery}
        value={value}
        loading={isLoading}
        error={hasErrors}
        errorMessage={errorMsg}
        {...props}
      />
    </>
  )
}

EmailTemplateSelectField.propTypes = {
  id: PropTypes.string,
  list: PropTypes.object,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
}

EmailTemplateSelectField.defaultProps = {
  id: 'email-template',
  filters: [],
  list: null,
}

export default EmailTemplateSelectField
