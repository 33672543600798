import { useState, useEffect, useRef } from 'react'
import { Font, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { capitalize } from 'lodash'
import styles from './LocationMapSidebarItem.module.scss'
import { useLocationMap } from '../LocationMapProvider/LocationMapProvider'

const cx = classNames.bind(styles)

const LocationMapSidebarItem = ({ location }) => {
  const { t } = useTranslation()
  const {
    isVR,
    activeLocation,
    setActiveLocation,
    activeLocationRef,
    setViewport,
    setHasMoved,
  } = useLocationMap()

  const resource = isVR ? 'form' : 'signature'

  const thisLocationRef = useRef()

  const addressPieces = [
    location.street_address,
    [
      [location.city, location.state].filter(Boolean).join(', '),
      location.zipcode,
    ]
      .filter(Boolean)
      .join(' '),
  ].filter(Boolean)
  const itemsPerFieldHour = Number(
    isVR
      ? location.stats.in_range.forms_per_hour
      : location.stats.in_range.signatures_per_hour
  ).toFixed(2)
  const allTimeItemsPerFieldHour = Number(
    isVR
      ? location.stats.all_time.forms_per_hour
      : location.stats.all_time.signatures_per_hour
  ).toFixed(2)
  const numShifts =
    (isVR
      ? location.stats.in_range.vr_shifts_count
      : location.stats.in_range.petition_shifts_count) || 0
  const allTimeNumShifts =
    (isVR
      ? location.stats.all_time.vr_shifts_count
      : location.stats.all_time.petition_shifts_count) || 0
  const isActive = activeLocation?.id === location.id
  const [showDropdown, setShowDropdown] = useState(false)
  useEffect(() => {
    if (!isActive) {
      setShowDropdown(false)
    } else {
      activeLocationRef.current = thisLocationRef.current
    }
  }, [isActive])
  return (
    <>
      <div
        className={cx('location', {
          'location--active': isActive,
        })}
        ref={thisLocationRef}
        onClick={() => {
          setActiveLocation(location)
          setHasMoved(true)
          setViewport(viewport => ({
            latitude: location.latitude,
            longitude: location.longitude,
            zoom: Math.max(10, viewport.zoom),
          }))
        }}
      >
        <Font.Copy variant="highlight">{location.name}</Font.Copy>
        <Font.Copy variant="hint">
          {numShifts} {t('shifts')}
        </Font.Copy>
        <div className={styles.spacer} />
        {addressPieces.length > 0 && (
          <>
            <Font.Label variant="detail">{t('Address')}</Font.Label>
            {addressPieces.map(piece => (
              <Font.Copy>{piece}</Font.Copy>
            ))}
            <div className={styles.spacer} />
          </>
        )}
        <Font.Label variant="detail">
          {t(`${capitalize(resource)}s per hour`)}
        </Font.Label>
        <Font.Copy>{itemsPerFieldHour}</Font.Copy>
      </div>
      {isActive && (
        <div className={styles['all-time-dropdown']}>
          <div
            type="button"
            className={styles['all-time-dropdown--button-container']}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setShowDropdown(val => !val)
            }}
          >
            <Font.Label>{t('All time stats')}</Font.Label>
            <Icon.ChevronRight
              className={cx('all-time-dropdown--icon', {
                'all-time-dropdown--icon--active': showDropdown,
              })}
            />
          </div>
          {showDropdown && (
            <div className={styles['all-time-dropdown--container']}>
              <Font.Label variant="detail">
                {t(`${capitalize(resource)}s per hour`)}
              </Font.Label>
              <Font.Copy>{allTimeItemsPerFieldHour}</Font.Copy>
              <div className={styles.spacer} />
              <Font.Label variant="detail">{t('Shifts')}</Font.Label>
              <Font.Copy>
                {allTimeNumShifts} {t('shifts')}
              </Font.Copy>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default LocationMapSidebarItem
