import React from 'react'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { formatPhoneNumber } from 'utils/formatting'

export const OrganizationNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/organizations/${rowData.id}`}>{rowData.name}</Link>
  </TableGridCell>
)

export const OrganizationAddressCell = ({ rowData }) => (
  <TableGridCell>
    {[
      rowData.address?.line_one,
      rowData.address?.city,
      rowData.address?.state,
      rowData.address?.zipcode,
    ].join(' ')}
  </TableGridCell>
)

export const OrganizationPhoneNumberCell = ({ rowData }) => (
  <TableGridCell>{formatPhoneNumber(rowData.phone_number)}</TableGridCell>
)

export const OrganizationLeaderNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.leader?.name}</TableGridCell>
)
