import React from 'react'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { formatPhoneNumber } from 'utils/formatting'
import { ResponsibilitySelectFieldDEPRECATED } from 'components/index'
import { useRequest } from 'hooks/useRequest'
import { putTeamMembership } from 'requests/people'
import { useAutoTable } from 'contexts/index'

export const MemberNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/people/${rowData.person.id}`}>
      {rowData.person.name}
    </Link>
  </TableGridCell>
)

export const MemberFirstNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.person.first_name}</TableGridCell>
)

export const MemberLastNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.person.last_name}</TableGridCell>
)

export const MemberResponsibilityCell = ({ rowData }) => {
  const { refreshPage } = useAutoTable()

  const {
    makeRequest: updateReq,
    isLoading: updateReqLoading,
    hasErrors: updateReqHasErrors,
    errors: updateReqErrors,
  } = useRequest(putTeamMembership, { onSuccess: () => refreshPage() })

  return (
    <TableGridCell>
      <ResponsibilitySelectFieldDEPRECATED
        id="responsibility-select"
        onSelect={responsibility =>
          updateReq(rowData.person.id, rowData.id, {
            responsibility: responsibility || null,
          })
        }
        isLoading={updateReqLoading}
        label=" "
        responsibility={rowData.responsibility}
        clearable
        error={updateReqHasErrors}
        errorText={Object.values(updateReqErrors).join(', ')}
      />
    </TableGridCell>
  )
}

export const MemberResponsibilityCellRenderer = props => (
  <MemberResponsibilityCell {...props} />
)

export const MemberAddressCell = ({ rowData }) => {
  const address = rowData.person.residential_address
  if (address?.line_one) {
    return (
      <TableGridCell>
        {address.line_one} {address.line_two} {address.city}, {address.state}{' '}
        {address.zipcode}
      </TableGridCell>
    )
  }
}

export const MemberPhoneCell = ({ rowData }) => (
  <TableGridCell>
    {formatPhoneNumber(rowData.person.primary_phone_number)}
  </TableGridCell>
)

export const MemberEmailCell = ({ rowData }) => (
  <TableGridCell>{rowData.person.primary_email_address}</TableGridCell>
)
