import { useContext } from 'react'
import { Checkbox } from '@politechdev/blocks-design-system'
import styles from './AutoTable.module.scss'

const SelectColumnHeader = () => <div />

const SelectColumnCell =
  CheckboxGroupContext =>
  ({ rowData }) => {
    const { toggleCheckbox, isChecked } = useContext(CheckboxGroupContext)
    const { id } = rowData
    return (
      <Checkbox
        className={styles.checkbox}
        checked={isChecked(id)}
        onChange={() => toggleCheckbox(id)}
        id={id}
        name={`name-${id}`}
        aria-label="select column"
        aria-labelledby={id}
      />
    )
  }

const generateDefaultProps = (bodyCell, propOverrides) => ({
  isSelectColumn: true,
  width: 50,
  frozen: 'left',
  sortable: false,
  align: 'center',
  listInline: true,
  actions: [],
  headerCell: SelectColumnHeader,
  bodyCell,
  ...propOverrides,
})

export const generateSelectColumn = (
  CheckboxGroupContext,
  propOverrides = {}
) => {
  const SelectColumn = () => {}
  SelectColumn.defaultProps = generateDefaultProps(
    SelectColumnCell(CheckboxGroupContext),
    propOverrides
  )
  return SelectColumn
}
