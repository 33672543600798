import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchUsers = (params = {}) => {
  const queryParams = stringifyParams({
    current_page: params.current_page,
    per: params.per,
    sort_attr: params.sort_attr,
    sort_dir: params.sort_dir,
    filters: {
      rules: [
        params.filters,
        {
          column: 'disabled_at',
          operator: 'is_nil',
        },
      ],
      conjunction: 'exclusive',
    },
    fields: [
      'id',
      'name',
      'email',
      'full_name',
      'last_sign_in_at',
      { turf: ['id', 'name'] },
      { role: ['id', 'name'] },
    ],
  })

  return fetchJSON(`/api/v1/users?${queryParams}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchUser = (id, params) =>
  fetchJSON(`/api/v1/users/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postUser = (user, welcomeEmail) =>
  fetchJSON(
    '/api/v1/users',
    'POST',
    { user, send_welcome_email: welcomeEmail },
    {
      useJwt: true,
    }
  )

export const putUser = ({ user }) =>
  fetchJSON(
    `/api/v1/users/${user.id}`,
    'PUT',
    { user },
    {
      useJwt: true,
    }
  )

export const disableUserRequest = id =>
  fetchJSON(`/api/v1/users/${id}/disable`, 'PUT', null, {
    useJwt: true,
  })

export const destroyUser = id =>
  fetchJSON(`/api/v1/users/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const impersonateUser = id =>
  fetchJSON(`/api/v1/users/${id}/impersonate`, 'POST', null, {
    useJwt: true,
  })

export const stopImpersonatingUser = () =>
  fetchJSON(`/api/v1/users/stop_impersonating`, 'POST', null, {
    useJwt: true,
  })
