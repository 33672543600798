import { useState, useEffect } from 'react'
import { createProjection } from 'requests/fieldManagement'

const useCreateProjection = callback => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [projection, setProjection] = useState({})

  const buildFormData = projection => ({
    start_date: projection.startDate,
    end_date: projection.endDate,
    name: projection.name,
    turf_id: projection.turfId,
    targets: projection.weeklyProjectedData.map(d => ({
      start_date: d.startDate,
      end_date: d.endDate,
      canvassers_per_day: d.dailyCanvassers,
      days_per_week: d.daysCanvassingPerWeek,
      field_hours_per_day: d.averageFieldHours,
      forms_per_hour: d.formsPerHour,
    })),
  })

  useEffect(() => {
    if (!Object.keys(projection).length) return
    setIsLoading(true)
    setErrorMessage(null)
    createProjection(buildFormData(projection))
      .then(() => {
        setIsLoading(false)
        callback()
      })
      .catch(() => {
        setErrorMessage('Error in receiving projection data')
      })
  }, [projection])

  return {
    isLoading,
    errorMessage,
    setProjection,
  }
}

export default useCreateProjection
