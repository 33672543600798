import { Section } from 'components/index'
import { Link } from 'react-router-dom'
import {
  ButtonBlock,
  Button,
  TextBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { useTranslation, Trans } from 'react-i18next'

const ProjectionExitWarningModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()

  return (
    <Modal id="warn-exit" title={t('Unsaved changes')} isOpen={isOpen}>
      <Modal.Body>
        <Section>
          <TextBlock>
            <Trans i18nKey="projectionExitWarning">
              You are about to leave this view with changes that have not been
              saved. These changes will be ignored and the projection will
              remain how it was the last time it was saved. Are you sure you
              want to continue?
            </Trans>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={() => setIsOpen(false)}>
            {t('Go back')}
          </Button.Secondary>
          <Link to="/field-management/projections">
            <Button.Accent>{t('Continue without saving')}</Button.Accent>
          </Link>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ProjectionExitWarningModal
