import AssignablePackets from 'qualityControl/packetAssignment/assignablePackets/AssignablePackets'
import MainPanel from 'qualityControl/packetAssignment/assignablePackets/panel/MainPanel'
import { useEffect, useState } from 'react'
import { useRequest } from 'hooks/useRequest'
import { LoadBar, ViewContainer } from 'components'
import { AutoTableProvider } from 'contexts'
import { useReactRouter } from 'hooks'
import { fetchUser } from 'requests/users'
import ReassignPacketsModal from './modals/ReassignPacketsModal'
import ReprioritizePacketsModal from './modals/ReprioritizePacketsModal'
import {
  UserAssignablePacketsCheckboxProvider,
  UserAssignedPacketsCheckboxProvider,
} from './assignablePackets/CheckboxContextProvider'
import { filterAssignedPackets } from './staff/utils'
import { usePacketAssignment } from './PacketAssignmentContext'

const UserPacketAssignment = () => {
  const [user, setUser] = useState()

  const { getScheduledUsers } = usePacketAssignment()

  // eslint-disable-next-line blocks/missing-response-error
  const fetchUserRequest = useRequest(fetchUser, {
    onSuccess: ({ user }) => {
      const assigned_packets = filterAssignedPackets(user.assigned_packets)
      setUser({ ...user, assigned_packets })
    },
  })

  const { match } = useReactRouter()
  const userId = +match.params.id

  const handleFetchUser = () => {
    fetchUserRequest.makeRequest(userId, {
      fields: [
        'id',
        'name',
        'email',
        'turf_id',
        { turf: ['name', 'id', 'qc_config'] },
        { role: ['name', 'admin', 'permissions'] },
        { quality_control_schedules: ['minutes', 'date'] },
        {
          assigned_packets: [
            'id',
            'original_filename',
            'scans_count',
            'qc_deadline',
            'shift_id',
            {
              turf: [
                'name',
                'phone_verification_language',
                {
                  visual_review_responses: [
                    'id',
                    'description',
                    'response',
                    'active',
                    'grouping_metadata',
                    'reviewable_type',
                  ],
                },
              ],
            },
            {
              shift: [
                'id',
                'status',
                'completed_at',
                'qc_external',
                'shift_end',
              ],
            },
            {
              scans: [
                {
                  visual_reviews: [
                    'id',
                    'created_at',
                    {
                      response: [
                        'response',
                        'implies_skips_phone_verification',
                      ],
                    },
                    {
                      user: ['id', 'name'],
                    },
                  ],
                },
              ],
            },
            { creator: 'time_zone' },
            { location: ['state', 'timezone'] },
            { unresolved_flags: [{ trigger: ['stops_qc', 'needs_reupload'] }] },
          ],
        },
      ],
    })
  }

  useEffect(() => {
    handleFetchUser()
    getScheduledUsers()
  }, [])

  return (
    <ViewContainer>
      <LoadBar show={fetchUserRequest.isLoading} />
      {!!user && (
        <AutoTableProvider
          initialTableId="packet-assignment"
          enableQueryParams
          enablePagination
          enableFilters
          enableSort
          showFilterEditor
          showDownloadButton
        >
          <UserAssignablePacketsCheckboxProvider>
            <UserAssignedPacketsCheckboxProvider>
              <MainPanel user={user} refreshAssignedPackets={handleFetchUser} />
              <AssignablePackets
                user={user}
                refreshAssignedPackets={handleFetchUser}
              />
              <ReassignPacketsModal refreshAssignedPackets={handleFetchUser} />
              <ReprioritizePacketsModal
                refreshAssignedPackets={handleFetchUser}
              />
            </UserAssignedPacketsCheckboxProvider>
          </UserAssignablePacketsCheckboxProvider>
        </AutoTableProvider>
      )}
    </ViewContainer>
  )
}

export default UserPacketAssignment
