import { useState, useEffect } from 'react'
import { fetchProjection } from 'requests/fieldManagement'
import { deriveTargetFormsTotal } from 'utils/fieldManagement'

const useProjection = (
  projectionId,
  {
    setWeeklyProjectedData,
    setWeeklyActualData,
    setTotalStartDate,
    setTotalEndDate,
  }
) => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isBlank, setIsBlank] = useState(true)
  const [projectionName, setProjectionName] = useState(null)
  const [selectedTurfId, setSelectedTurfId] = useState(null)

  useEffect(() => {
    fetchProjection(projectionId, {
      fields: ['turf_id', 'name', 'targets', 'start_date', 'end_date'],
    })
      .then(({ 'field_management/projection': projection }) => ({
        weeklyProjectedData: projection.targets.map(t => ({
          startDate: t.start_date,
          endDate: t.end_date,
          formsCollected: deriveTargetFormsTotal(
            t.days_per_week,
            t.canvassers_per_day,
            t.field_hours_per_day,
            t.forms_per_hour
          ),
          dailyCanvassers: t.canvassers_per_day,
          daysCanvassingPerWeek: t.days_per_week,
          averageFieldHours: t.field_hours_per_day,
          formsPerHour: t.forms_per_hour,
        })),
        weeklyActualData: projection.targets.reduce(
          (newArr, t, targetIndex) => {
            const actualsExist = projection.targets
              .slice(targetIndex)
              .find(target => target.actual_total_forms)
            if (actualsExist) {
              newArr.push({
                startDate: t.start_date,
                endDate: t.end_date,
                formsCollected: t.actual_total_forms,
                dailyCanvassers:
                  t.actual_days === 0
                    ? 0
                    : parseFloat(
                        (t.actual_canvassers / t.actual_days).toFixed(3)
                      ),
                daysCanvassingPerWeek: t.actual_days,
                averageFieldHours:
                  t.actual_canvassers === 0
                    ? 0
                    : parseFloat(
                        (t.actual_field_hours / t.actual_canvassers).toFixed(3)
                      ),
                formsPerHour: parseFloat(t.actual_forms_per_hour.toFixed(3)),
              })
            }
            return newArr
          },
          []
        ),
        projectionInfo: {
          startDate: projection.start_date,
          endDate: projection.end_date,
          name: projection.name,
          turfId: projection.turf_id,
        },
        totalStartDate: projection.start_date,
        totalEndDate: projection.end_date,
      }))
      .then(data => {
        setWeeklyActualData(data.weeklyActualData)
        setWeeklyProjectedData(data.weeklyProjectedData)
        setTotalStartDate(data.projectionInfo.startDate)
        setTotalEndDate(data.projectionInfo.endDate)
        setProjectionName(data.projectionInfo.name)
        setSelectedTurfId(data.projectionInfo.turfId)
        if (
          data.totalStartDate <
            data.weeklyProjectedData[data.weeklyProjectedData.length - 1]
              .endDate &&
          data.totalEndDate > data.weeklyProjectedData[0].startDate &&
          data.weeklyProjectedData.length > 0
        ) {
          setIsBlank(false)
        }
        setIsLoading(false)
      })
      .catch(() => {
        setErrorMessage('Error in receiving projection data')
      })
  }, [])

  return {
    isLoading,
    errorMessage,
    isBlank,
    projectionName,
    setProjectionName,
    selectedTurfId,
    setSelectedTurfId,
  }
}

export default useProjection
