import { Button } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useReactRouter, useRoutePathParams } from 'hooks'

const ViewFlagButton = ({ flagId, typeOverride }) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const [, replaceParams] = useRoutePathParams()
  const nextRoute =
    typeOverride !== undefined
      ? `${replaceParams({ type: typeOverride })}/${flagId}/${
          match.params.type
        }`
      : `${match.url}/${flagId}/${match.params.type}`
  return (
    <Button raised primary onClick={() => history.push(nextRoute)}>
      {t('View')}
    </Button>
  )
}

export default ViewFlagButton
