import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  ProgressBar,
  useToast,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { useRequest, useReactRouter } from 'hooks'
import { postEmail } from 'requests/email'
import { Link } from 'react-router-dom'
import { SendContext } from '../SendContext/SendContext'
import buildMarkup from '../../template/TemplatePreview/buildMarkup'

const SendControls = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { setToast } = useToast()
  const { template, event, list, team, subject, from, contactMeta } =
    useContext(SendContext)

  const handleSendSuccess = () => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      history.push('/email/manage')
    }, 2000)
  }

  const {
    makeRequest: sendEmailReq,
    isLoading: isSendLoading,
    hasErrors: sendError,
  } = useRequest(postEmail, {
    onSuccess: () => {
      setToast({
        message: t('Success'),
        variant: 'success',
      })
      handleSendSuccess()
    },
  })
  return (
    <>
      <ProgressBar show={isSendLoading} />
      <CardError
        hide={!sendError}
        message={t('There was an error sending this email.')}
      />
      <ButtonBlock justify="right">
        <Link to="/email/manage">
          <Button.Secondary>{t('Cancel')}</Button.Secondary>
        </Link>
        <Link to="/email/template">
          <Button>{t('Create draft')}</Button>
        </Link>
        <Button.Accent
          disabled={!template || !subject || !contactMeta.total_count || !from}
          onClick={() => {
            sendEmailReq(template.id, {
              team_id: team?.id,
              event_id: event?.id,
              list_id: list?.id,
              body: buildMarkup(template.template_content).__html,
              from,
              subject,
            })
          }}
        >
          {t('Send')}
        </Button.Accent>
      </ButtonBlock>
    </>
  )
}

export default SendControls
