import { useRef, useEffect } from 'react'

const useTimeout = (fn, delay = 10000) => {
  const functionRef = useRef()
  const timeoutRef = useRef()

  useEffect(() => {
    functionRef.current = fn
  }, [fn])

  const startTimeout = () => {
    timeoutRef.current = setTimeout(functionRef.current, delay)
  }

  const stopTimeout = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
  }

  useEffect(() => () => clearTimeout(timeoutRef.current), [])

  return {
    startTimeout,
    clearTimeout: stopTimeout,
  }
}

export default useTimeout
