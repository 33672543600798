import React from 'react'
import WeekRow from '../WeekRow/WeekRow'
import styles from './WeekView.module.scss'

export const getWeekViewTitle = ({ startDate, endDate }) => {
  const sameYear = startDate.isSame(endDate, 'year')
  const sameMonth = startDate.isSame(endDate, 'month')

  if (sameYear && sameMonth) {
    return [startDate.format('MMM D'), endDate.format('D, YYYY')].join(' – ')
  }

  if (sameYear) {
    return [startDate.format('MMM D'), endDate.format('MMM D, YYYY')].join(
      ' – '
    )
  }

  return [startDate.format('MMM D, YYYY'), endDate.format('MMM D, YYYY')].join(
    ' – '
  )
}

const WeekView = ({ dateRange }) => (
  <div className={styles.calendar}>
    <div className={styles.columns}>
      {dateRange.map(({ date }, index) => (
        <div className={styles.label} key={index}>
          <span>{date.format('ddd M/D')}</span>
        </div>
      ))}
    </div>
    <WeekRow week={dateRange} className={styles.week} />
  </div>
)

export default WeekView
