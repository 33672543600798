import { combineName, summaryFunctions } from 'utils/reporting'

export default {
  id: 'qc_packets_by_day',
  name: 'Packets Due by QC Staff ',
  description:
    'This report provides information on how many packets are left to QC broken down by assigned staff member and due date.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=659802862',
  columns: [
    {
      dataKey: 'assignee_name',
      title: 'Assigned Staff Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.assignee_id
          ? combineName(rowData.assignee_first_name, rowData.assignee_last_name)
          : 'Unassigned',
    },
    {
      dataKey: 'overdue',
      title: 'Overdue Packets',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'zero_days',
      title: 'Due Today',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'one_days',
      title: 'Due Tomorrow',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'two_days',
      title: 'Due in 2 Days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'three_days',
      title: 'Due in 3 Days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'four_days',
      title: 'Due in 4 Days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'five_days',
      title: 'Due in 5 Days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'six_or_more_days',
      title: 'Due in 6 or More Days',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
  ],
}
