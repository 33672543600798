import { TEMPLATE_ITEM_OPTIONS } from 'email/template/TemplateContext/constants'

export const THEME_OPTIONS = {
  CUSTOM: 'Custom',
  DEFAULT: 'Default',
  BUSINESS: 'Business',
  ENERGETIC: 'Energetic',
  BLOCKS: 'Blocks',
}

export const THEMES = {
  [THEME_OPTIONS.CUSTOM]: null,
  [THEME_OPTIONS.DEFAULT]: {
    background: '#F2FBFE',
    [TEMPLATE_ITEM_OPTIONS.TITLE]: '#5B6163',
    [TEMPLATE_ITEM_OPTIONS.HEADING]: '#1A1A1A',
    [TEMPLATE_ITEM_OPTIONS.BODY_COPY]: '#1A1A1A',
    [TEMPLATE_ITEM_OPTIONS.BUTTON_LINK]: '#348DDF',
  },
  [THEME_OPTIONS.BUSINESS]: {
    background: '#FFFFFF',
    [TEMPLATE_ITEM_OPTIONS.TITLE]: '#1A1A1A',
    [TEMPLATE_ITEM_OPTIONS.HEADING]: '#1A1A1A',
    [TEMPLATE_ITEM_OPTIONS.BODY_COPY]: '#1A1A1A',
    [TEMPLATE_ITEM_OPTIONS.BUTTON_LINK]: '#1C1C1C',
  },
  [THEME_OPTIONS.BLOCKS]: {
    background: '#FCFCFC',
    [TEMPLATE_ITEM_OPTIONS.TITLE]: '#142743',
    [TEMPLATE_ITEM_OPTIONS.HEADING]: '#2A3051',
    [TEMPLATE_ITEM_OPTIONS.BODY_COPY]: '#1A1A1A',
    [TEMPLATE_ITEM_OPTIONS.BUTTON_LINK]: '#485973',
  },
  [THEME_OPTIONS.ENERGETIC]: {
    background: '#343B54',
    [TEMPLATE_ITEM_OPTIONS.TITLE]: '#E0E34F',
    [TEMPLATE_ITEM_OPTIONS.HEADING]: '#DBDFDF',
    [TEMPLATE_ITEM_OPTIONS.BODY_COPY]: '#FCFCFC',
    [TEMPLATE_ITEM_OPTIONS.BUTTON_LINK]: '#E76F51',
  },
}
