import { useContext, useEffect, useMemo, useState } from 'react'
import { useBackgroundRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import { DashboardFilterContext } from 'dashboard/DashboardFilterContext/DashboardFilterContext'
import { requestChartId, requestChart } from '../../../requests/charts'
import WidgetPanel from '../../WidgetPanel/WidgetPanel'
import VoterRegSummaryRow from './VoterRegSummaryRow'

const VoterRegSummaryReport = () => {
  const { t } = useTranslation()

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const {
    startDate,
    endDate,
    turfId: turfIdFilter,
  } = useContext(DashboardFilterContext)

  const filters = useMemo(
    () => ({ startDate, endDate, turfId: turfIdFilter }),
    [startDate, endDate, turfIdFilter]
  )

  const [data, setData] = useState([])

  const { makeRequest, isLoading, errorMsg, response } = useBackgroundRequest({
    key: 'voter_registrations_top_performers_by_turf',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters,
      }),
    requestData: requestChart,
  })

  useEffect(() => {
    makeRequest()
  }, [filters])

  useEffect(() => {
    if (response) {
      setData(response.rows)
    }
  }, [response])

  return (
    <WidgetPanel
      title={t('Total registrations by turf')}
      isLoading={isLoading}
      errorMsg={errorMsg}
      isEmpty={!data.length}
    >
      <table className="table table-striped top-performers">
        <thead>
          <tr>
            <th>{t('Turf Level')}</th>
            <th className="text-center">{t('Voter Registrations')}</th>
            <th className="text-right">{t('Trend')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <VoterRegSummaryRow data={row} index={i} key={`row-${i}`} />
          ))}
        </tbody>
      </table>
    </WidgetPanel>
  )
}

export default VoterRegSummaryReport
