import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewContainer, View, CardError } from 'components'
import {
  PageHeader,
  Sheet,
  ProgressBar,
  Button,
  ButtonBlock,
  Icon,
  useToast,
} from '@politechdev/blocks-design-system'
import { useCurrent, FormProvider } from 'contexts'
import { useRequest, useRoutePathParams } from 'hooks'
import { fetchList, postListSalsaExport } from 'requests/lists'
import { FILTER_GROUPS } from '../constants'
import ListFilterGroup from '../ListFilterGroup/ListFilterGroup'
import ListDetails from '../ListDetails/ListDetails'
import ListSaveBanner from '../ListSaveBanner/ListSaveBanner'
import ListMemberTable from '../ListMemberTable/ListMemberTable'
import ListEditModal from '../ListEditModal/ListEditModal'
import ListDeleteModal from '../ListDeleteModal/ListDeleteModal'
import styles from './ListSingle.module.scss'

const ListSingle = () => {
  const { t } = useTranslation()
  const { setToast } = useToast()
  const [{ listId }] = useRoutePathParams()
  const { currentUser } = useCurrent()
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const {
    makeRequest: exportReq,
    isLoading: isExportReqLoading,
    hasErrors: exportReqError,
  } = useRequest(postListSalsaExport, {
    onSuccess: counts => {
      const toastMessage = {
        failed: counts.failed ? `${t('Rejected:')} ${counts.failed}` : null,
        succeeded:
          counts.added || counts.updated
            ? `${t('Exported:')} ${counts.added + counts.updated}`
            : null,
      }
      setToast({
        message: Object.values(toastMessage).filter(Boolean).join('. '),
        variant: counts.failed ? 'error' : 'success',
      })
    },
  })

  const { makeRequest, isLoading, hasErrors, response } = useRequest(fetchList)

  const makeListRequest = () =>
    makeRequest(listId, {
      fields: [
        'id',
        'name',
        'search_params',
        'created_at',
        'refreshed_at',
        'repopulation_status',
        'user_id',
        'meta',
        { turf: ['extras'] },
        { folder: ['name'] },
      ],
    })

  useEffect(() => {
    makeListRequest()
  }, [listId])

  const currentList = response ? response.list : null
  const isListShared = currentList?.user_id !== currentUser.id

  return (
    <View>
      <ViewContainer loading={isLoading}>
        {hasErrors && (
          <Sheet>
            <CardError
              hide={!hasErrors}
              message={t("We're unable to retrieve this list")}
            />
          </Sheet>
        )}
        {currentList && (
          <>
            <Sheet>
              <ProgressBar show={isExportReqLoading} />
              <CardError
                hide={!exportReqError}
                message={t('Exporting this list to Salsa failed.')}
              />
              <PageHeader title={currentList.name}>
                {currentList.turf.options?.salsa_api_key && (
                  <ButtonBlock justify="left" className={styles.buttons}>
                    <Button.Secondary onClick={() => exportReq(listId)}>
                      {t('Export to Salsa')}
                    </Button.Secondary>
                  </ButtonBlock>
                )}
                <ButtonBlock justify="right" className={styles.buttons}>
                  <Button.Primary
                    aria-label={t('Rename')}
                    onClick={() => setEditModalOpen(true)}
                  >
                    <Icon.Pencil alt={t('Rename')} />
                  </Button.Primary>
                  <ListEditModal
                    currentList={currentList}
                    makeListRequest={makeListRequest}
                    isEditModalOpen={isEditModalOpen}
                    setEditModalOpen={setEditModalOpen}
                  />
                  <ListDeleteModal currentList={currentList} />
                </ButtonBlock>
              </PageHeader>
              <ListDetails currentList={currentList} />
            </Sheet>
            <ListSaveBanner
              currentList={currentList}
              makeListRequest={makeListRequest}
            />
            <ListMemberTable
              currentList={currentList}
              makeListRequest={makeListRequest}
            />
            {!isListShared &&
              FILTER_GROUPS.map(group => (
                <Sheet key={group.id}>
                  <ListFilterGroup
                    initialFilters={currentList.search_params[group.id]}
                    group={group}
                  />
                </Sheet>
              ))}
          </>
        )}
      </ViewContainer>
    </View>
  )
}

export default () => (
  <FormProvider>
    <ListSingle />
  </FormProvider>
)
