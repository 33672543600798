import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  TextBlock,
  ButtonBlock,
  ContentBlock,
  Modal,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { deleteEvent, deleteMeeting } from 'requests/events'
import { formatErrorMessage } from 'utils/formatting'
import styles from './EventDeleteModal.module.scss'

const EventDeleteModal = ({ event, meeting }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(
    meeting ? deleteMeeting : deleteEvent,
    {
      onSuccess: () => {
        closeModal()
        meeting
          ? history.push('/build/meetings')
          : history.push('/build/events')
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  return (
    <>
      <Button onClick={openModal}>
        <Icon.TrashAlt />
      </Button>
      <Modal
        id="event-delete"
        title={meeting ? t(`Delete meeting`) : t(`Delete event`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError hide={!hasErrors} message={errorMsg} hideSupportLink />
            {meeting ? (
              <TextBlock className={styles.text}>
                <Font.Copy>
                  {`${t('Are you sure you want to delete meeting with')} `}
                  <strong>{meeting.person?.name}</strong>?
                </Font.Copy>
              </TextBlock>
            ) : (
              <TextBlock className={styles.text}>
                <Font.Copy>
                  {`${t('Are you sure you want to delete')} `}
                  <strong>{event.name}</strong>?
                </Font.Copy>
              </TextBlock>
            )}
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                meeting ? makeRequest(meeting.id) : makeRequest(event.id)
              }}
            >
              {t('Delete')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default EventDeleteModal
