import React from 'react'

export default props => (
  <svg
    {...props}
    width="208"
    height="248"
    viewBox="0 0 208 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160.833 41.3333H155.667V15.5C155.667 6.9528 148.714 0 140.167 0H67.8334C59.2862 0 52.3334 6.9528 52.3334 15.5V41.3333H47.1668C21.5251 41.3333 0.666748 62.1917 0.666748 87.8333V170.5C0.666748 194.372 18.8138 213.869 42.0001 216.478V242.833C42.0001 245.689 44.311 248 47.1668 248H160.833C163.689 248 166 245.689 166 242.833V216.478C189.186 213.869 207.333 194.372 207.333 170.5V87.8333C207.333 62.1917 186.475 41.3333 160.833 41.3333Z"
      fill="#485973"
    />
    <path
      d="M62.6667 15.5C62.6667 12.6543 64.9877 10.3333 67.8334 10.3333H140.167C143.012 10.3333 145.333 12.6543 145.333 15.5V41.3333H62.6667V15.5Z"
      fill="#F3FBFD"
    />
    <path d="M155.667 217H52.3335V237.667H155.667V217Z" fill="#F3FBFD" />
    <path
      d="M197 170.5C197 190.44 180.773 206.667 160.833 206.667H47.1667C27.2266 206.667 11 190.44 11 170.5V87.8333C11 67.8932 27.2266 51.6667 47.1667 51.6667H160.833C180.773 51.6667 197 67.8932 197 87.8333V170.5Z"
      fill="#F3FBFD"
    />
    <path
      opacity="0.3"
      d="M81.3958 10.3333H67.8334C64.9877 10.3333 62.6667 12.6543 62.6667 15.5V41.3333H78.1667V15.5C78.1667 12.6543 79.6173 10.3333 81.3958 10.3333Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M140.167 10.3333H126.604C128.383 10.3333 129.833 12.6543 129.833 15.5V41.3333H145.333V15.5C145.333 12.6543 143.013 10.3333 140.167 10.3333Z"
      fill="#010101"
    />
    <path
      opacity="0.3"
      d="M63.8147 217H52.3335V237.667H63.8147V217Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M155.667 217H144.186V237.667H155.667V217Z"
      fill="#010101"
    />
    <path
      opacity="0.3"
      d="M31.6667 170.5V87.8333C31.6667 67.8932 44.2875 51.6667 59.7964 51.6667H47.1667C27.2266 51.6667 11 67.8932 11 87.8333V170.5C11 190.44 27.2266 206.667 47.1667 206.667H59.7964C44.2875 206.667 31.6667 190.44 31.6667 170.5Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M160.833 51.6667H148.204C163.712 51.6667 176.333 67.8932 176.333 87.8333V170.5C176.333 190.44 163.712 206.667 148.204 206.667H160.833C180.773 206.667 197 190.44 197 170.5V87.8333C197 67.8932 180.773 51.6667 160.833 51.6667Z"
      fill="#010101"
    />
    <path
      d="M134.596 151.539C126.584 160.439 115.716 165.333 104 165.333C92.2843 165.333 81.4162 160.439 73.4038 151.539C71.4865 149.42 68.227 149.228 66.1079 151.165C63.9887 153.073 63.8172 156.342 65.7345 158.461C75.5835 169.39 89.5294 175.667 104 175.667C118.471 175.667 132.417 169.39 142.266 158.461C144.183 156.342 144.012 153.073 141.892 151.165C139.773 149.238 136.514 149.43 134.596 151.539Z"
      fill="#485973"
    />
    <path
      d="M73.0001 124C78.707 124 83.3334 119.374 83.3334 113.667C83.3334 107.96 78.707 103.333 73.0001 103.333C67.2931 103.333 62.6667 107.96 62.6667 113.667C62.6667 119.374 67.2931 124 73.0001 124Z"
      fill="#485973"
    />
    <path
      d="M135 124C140.707 124 145.333 119.374 145.333 113.667C145.333 107.96 140.707 103.333 135 103.333C129.293 103.333 124.667 107.96 124.667 113.667C124.667 119.374 129.293 124 135 124Z"
      fill="#485973"
    />
    <path
      d="M155.667 237.667V217H52.3335L73.0002 237.667H155.667Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M160.833 41.3333H155.667V15.5C155.667 6.9528 148.714 0 140.167 0H67.8334C59.2862 0 52.3334 6.9528 52.3334 15.5V41.3333H47.1668C21.5251 41.3333 0.666748 62.1917 0.666748 87.8333V170.5C0.666748 194.372 18.8138 213.869 42.0001 216.478V242.833C42.0001 245.689 44.311 248 47.1668 248H160.833C163.689 248 166 245.689 166 242.833V216.478C189.186 213.869 207.333 194.372 207.333 170.5V87.8333C207.333 62.1917 186.475 41.3333 160.833 41.3333Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="78.1668"
        y1="191.167"
        x2="140.167"
        y2="253.167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#010101" stopOpacity="0.2" />
        <stop offset="1" stopColor="#010101" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="-0.318192"
        y1="79.6414"
        x2="208.964"
        y2="177.231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)
