import { appsignal } from 'appsignal'

function formatTraceAsArrayOfLines(stacktrace) {
  return stacktrace.split('\n').filter(line => line !== '')
}

export const logError = error => {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error)
  } else {
    appsignal.sendError(error)
  }
}

const sendOrLogSpan = span => {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(span.serialize())
  } else {
    appsignal.send(span)
  }
}

export const logErrorResponse = (
  useRequestError,
  useRequestArgs,
  errorMessage,
  stacktrace
) => {
  const span = appsignal
    .createSpan()
    .setParams({ response: useRequestError.json, arguments: useRequestArgs })

  const responseError = new Error(errorMessage)
  span.setError(responseError)

  if (stacktrace) {
    span._data.error.backtrace = formatTraceAsArrayOfLines(stacktrace)
  }

  sendOrLogSpan(span)
}
