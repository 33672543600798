import { ErrorBoundary as AppsignalBoundary } from '@appsignal/react'
import { appsignal } from './appsignal'
import GenericErrorPage from './auth/GenericErrorPage/GenericErrorPage'

export const ErrorBoundary = ({ children }) => {
  if (process.env.NODE_ENV === 'development') {
    return children
  }

  return (
    <AppsignalBoundary instance={appsignal} fallback={GenericErrorPage}>
      {children}
    </AppsignalBoundary>
  )
}
