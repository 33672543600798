import React from 'react'
import PropTypes from 'prop-types'

import { TextBlock } from 'components'

const ListSubheading = ({ label }) => (
  <TextBlock>
    <p className="muted"> &mdash; {label} &mdash;</p>
  </TextBlock>
)

ListSubheading.propTypes = {
  label: PropTypes.string.isRequired,
}

export default ListSubheading
