import ReactDOM from 'react-dom'

const SessionFooterCallButtonPortal = ({ children, isPortalAvailable }) => {
  if (!isPortalAvailable) {
    return null
  }
  return ReactDOM.createPortal(
    children,
    document.querySelector('#pv__call_panel_call_actions_portal_target')
  )
}

export default SessionFooterCallButtonPortal
