import { Font, Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { CardError, ContentBlock, Sheet, ViewContainer } from 'components'
import { useTranslation } from 'react-i18next'

function SubmitOrContinue({
  onSubmit,
  onReview,
  onContinue,
  isLoading,
  canContinue,
  noUploadsInConfig,
  hasErrors,
  submitErrors,
}) {
  const { t } = useTranslation()

  let mainCopy = ''
  if (noUploadsInConfig) {
    mainCopy = t('Would you like to continue data entry?')
  } else if (canContinue) {
    mainCopy = t(
      'All uploads have been selected. Would you like to continue entry without scans?'
    )
  } else {
    mainCopy = t(
      'All uploads have been selected. Upload or review your submission.'
    )
  }

  let errorCopy = t('Something went wrong uploading this packet')
  if (submitErrors?.filename?.includes('already exists')) {
    errorCopy = t('A packet with this name has already been uploaded')
  }
  if (submitErrors?.error?.includes('timeout')) {
    errorCopy = t('Packet is too large to process')
  }

  return (
    <ViewContainer loading={isLoading}>
      <Sheet>
        <CardError hide={!hasErrors} message={errorCopy} />
        <ContentBlock>
          <Font.Copy Element="p">{mainCopy}</Font.Copy>
        </ContentBlock>
        <ButtonBlock>
          {canContinue ? (
            <Button onClick={onContinue} disabled={isLoading}>
              {t('Continue')}
            </Button>
          ) : null}
          <Button.Secondary onClick={onReview}>
            {t('Review entries')}
          </Button.Secondary>
          <Button.Accent onClick={onSubmit} disabled={isLoading}>
            {t('Finish and submit')}
          </Button.Accent>
        </ButtonBlock>
      </Sheet>
    </ViewContainer>
  )
}

export default SubmitOrContinue
