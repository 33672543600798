import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ProgressBar } from 'components'
import { Button } from 'react-md'
import { useTranslation } from 'react-i18next'

const ProgressBarGroup = ({ data, color }) => {
  if (!data) return null

  const { t } = useTranslation()
  const [isExpanded, setExpanded] = useState(false)

  const renderLabel = (label, color, groups) => (
    <>
      {groups ? (
        <Button
          className="progress-bar__expand-button"
          icon
          onClick={() => {
            setExpanded(!isExpanded)
          }}
        >
          {isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        </Button>
      ) : null}
      <div className="progress-bar__chip" style={{ background: color }} />
      <span>{label}</span>
    </>
  )

  const renderProgressBar = ({ data, color, groups, index }) => {
    const message = `${data.qced} / ${data.total} ${t('scans reviewed')}`

    return (
      <ProgressBar
        key={index}
        value={(data.qced / data.total) * 100}
        label={renderLabel(data.label, color, groups)}
        message={message}
        color={color}
      />
    )
  }

  return (
    <div className="chart__progress-bars">
      {renderProgressBar({ data, color, groups: data.turfs })}
      {data.turfs && (
        <div
          className={`chart__progress-bars --sub-group ${
            isExpanded && '--expanded'
          }`}
        >
          {data.turfs.map((turfData, index) =>
            renderProgressBar({ data: turfData, color, index })
          )}
        </div>
      )}
    </div>
  )
}

ProgressBarGroup.propTypes = {
  data: PropTypes.array.isRequired,
}

export default ProgressBarGroup
