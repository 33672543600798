export const buildOption = person => {
  const personName =
    person.full_name ||
    [person.first_name, person.middle_name, person.last_name]
      .filter(Boolean)
      .join(' ')

  return {
    value: person.id,
    label: personName,
  }
}
