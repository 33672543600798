import { ellipsisModalCellRender } from 'qualityControlReports/bodyCells/EllipsisModalCell'
import { combineName, summaryFunctions } from 'utils/reporting'

export default {
  event_attendance: {
    columns: [
      {
        dataKey: 'event_name',
        title: 'Event name',
        type: 'string',
        resizable: true,
      },
      {
        dataKey: 'event_date',
        title: 'Event date',
        type: 'date',
        resizable: true,
      },
      {
        dataKey: 'type',
        title: 'Event type',
        type: 'string',
        resizable: true,
      },
      {
        dataKey: 'event_creator_name',
        title: 'Host',
        type: 'string',
        deriveFunction: ({ rowData }) =>
          combineName(
            rowData.event_creator_first_name,
            rowData.event_creator_last_name
          ),
        resizable: true,
      },
      {
        dataKey: 'location_name',
        title: 'Location name',
        type: 'string',
        resizable: true,
      },
      {
        dataKey: 'attendees_count',
        title: 'Attendees Count',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
        resizable: true,
      },
      {
        dataKey: 'congregations',
        title: 'Congregations',
        type: 'array',
        bodyCell: ellipsisModalCellRender,
        deriveFunction: ({ rowData }) =>
          rowData.congregations ? rowData.congregations.join(', ') : null,
        resizable: true,
      },
    ],
  },
}
