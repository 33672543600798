export const interpolateContent = (content, tokens) =>
  tokens.reduce((interpolatedContent, token) => {
    if (
      !token.value ||
      typeof token.value !== 'string' ||
      token.value.trim() === ''
    ) {
      return interpolatedContent
    }

    return interpolatedContent.replace(
      new RegExp(token.target.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'ig'),
      token.value.trim()
    )
  }, content)
