import moment from 'moment'
import { sumBy, meanBy, round } from 'lodash/math'
import {
  formatDuration,
  calculateRate,
  calculatePercent,
} from 'utils/reporting'

const sum = ({ data, key }) => sumBy(data, row => parseFloat(row[key]) || 0)

export const findOtherColTotals = (data, possibleSource, keys) =>
  keys.map(key => {
    const possibleValue = parseFloat(possibleSource[key])
    if (possibleValue || possibleValue === 0) {
      return possibleValue
    }
    return sum({ data, key })
  })

const average = ({ data, key }) =>
  round(meanBy(data, row => parseFloat(row[key]) || 0))

const timelineAverage =
  timelineLabel =>
  ({ data, key }) => {
    const count = data[0]?.[`${timelineLabel}_row_count`] || 0
    const sum = sumBy(data, row => parseFloat(row[key]) || 0)
    if (!count) return 0

    return sum / count
  }

const customPercent = (data, acc, keys) => {
  const [val1, val2] = findOtherColTotals(data, acc, keys)
  return calculatePercent(val1, val2)
}

export const customRatio = (data, acc, keys) => {
  const [val1, val2] = findOtherColTotals(data, acc, keys)
  return calculateRate(val1, val2)
}

const customExactRatio = (data, acc, keys) => {
  const [val1, val2] = findOtherColTotals(data, acc, keys)
  return calculateRate(val1, val2)
}

// eslint-disable-next-line import/prefer-default-export
export const columnSummaryFunctions = {
  total_scans_cover: sum,
  total_scans: sum,
  total_scans_day: sum,
  total_scans_week: sum,
  total_scans_all_time: sum,
  total_scans_qc: sum,
  scans_with_phones_qc: sum,
  scans_with_phones_qc_day: sum,
  scans_with_phones_qc_week: sum,
  scans_with_phones_qc_all_time: sum,
  total_scans_through_qc_day: sum,
  total_scans_through_qc_week: sum,
  total_scans_through_qc_all_time: sum,
  days_since_collection: average,
  approved_qc: sum,
  approved_qc_day: sum,
  approved_qc_week: sum,
  approved_qc_all_time: sum,
  missing_phone_qc: sum,
  missing_phone_qc_day: sum,
  missing_phone_qc_week: sum,
  missing_phone_qc_all_time: sum,
  missing_required_qc_day: sum,
  missing_required_qc_week: sum,
  missing_required_qc_all_time: sum,
  handwriting_qc: sum,
  handwriting_qc_day: sum,
  handwriting_qc_week: sum,
  handwriting_qc_all_time: sum,
  not_form_qc: sum,
  not_form_qc_day: sum,
  not_form_qc_week: sum,
  not_form_qc_all_time: sum,
  canvasser_assistance_qc: sum,
  canvasser_assistance_qc_day: sum,
  canvasser_assistance_qc_week: sum,
  canvasser_assistance_qc_all_time: sum,
  other_qc: sum,
  other_qc_day: sum,
  other_qc_week: sum,
  other_qc_all_time: sum,
  contacted_qc: sum,
  contacted_qc_day: sum,
  contacted_qc_week: sum,
  contacted_qc_all_time: sum,
  phone_needs_attention_qc: sum,
  phone_needs_attention_qc_day: sum,
  phone_needs_attention_qc_week: sum,
  phone_needs_attention_qc_all_time: sum,
  confirmed_registered_qc: sum,
  confirmed_registered_qc_day: sum,
  confirmed_registered_qc_week: sum,
  confirmed_registered_qc_all_time: sum,
  did_not_register_qc: sum,
  did_not_register_qc_day: sum,
  did_not_register_qc_week: sum,
  did_not_register_qc_all_time: sum,
  correct_address_qc: sum,
  correct_address_qc_day: sum,
  correct_address_qc_week: sum,
  correct_address_qc_all_time: sum,
  incorrect_address_qc: sum,
  incorrect_address_qc_day: sum,
  incorrect_address_qc_week: sum,
  incorrect_address_qc_all_time: sum,
  correct_dob_qc: sum,
  correct_dob_qc_day: sum,
  correct_dob_qc_week: sum,
  correct_dob_qc_all_time: sum,
  incorrect_dob_qc: sum,
  incorrect_dob_qc_day: sum,
  incorrect_dob_qc_week: sum,
  incorrect_dob_qc_all_time: sum,
  received_feedback_qc: sum,
  received_feedback_qc_day: sum,
  received_feedback_qc_week: sum,
  received_feedback_qc_all_time: sum,
  duplicate_scans_qc: sum,
  not_home_qc: sum,
  not_home_qc_day: sum,
  not_home_qc_week: sum,
  not_home_qc_all_time: sum,
  disconnected_qc: sum,
  disconnected_qc_day: sum,
  disconnected_qc_week: sum,
  disconnected_qc_all_time: sum,
  call_back_qc: sum,
  call_back_qc_day: sum,
  call_back_qc_week: sum,
  call_back_qc_all_time: sum,
  language_barrier_qc: sum,
  language_barrier_qc_day: sum,
  language_barrier_qc_week: sum,
  language_barrier_qc_all_time: sum,
  refused_qc: sum,
  refused_qc_day: sum,
  refused_qc_week: sum,
  refused_qc_all_time: sum,
  wrong_number_qc: sum,
  wrong_number_qc_day: sum,
  wrong_number_qc_week: sum,
  wrong_number_qc_all_time: sum,
  wrong_voicemail_qc: sum,
  wrong_voicemail_qc_day: sum,
  wrong_voicemail_qc_week: sum,
  wrong_voicemail_qc_all_time: sum,
  busy_qc: sum,
  busy_qc_day: sum,
  busy_qc_week: sum,
  busy_qc_all_time: sum,
  do_not_call_qc: sum,
  do_not_call_qc_day: sum,
  do_not_call_qc_week: sum,
  do_not_call_qc_all_time: sum,
  hours_between_qc: average,
  percent_called_qc: average,
  total_calls: sum,
  total_calls_day: sum,
  total_calls_week: sum,
  total_calls_all_time: sum,
  percent_contacted_qc: ({ data, acc }) =>
    customPercent(data, acc, ['contacted_qc', 'total_scans_called_qc']),
  percent_contacted_qc_day: ({ data, acc }) =>
    customPercent(data, acc, ['contacted_qc_day', 'total_scans_called_qc_day']),
  percent_contacted_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'contacted_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_contacted_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'contacted_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_phone_needs_attention_qc: ({ data, acc }) =>
    customPercent(data, acc, [
      'phone_needs_attention_qc',
      'total_scans_called_qc',
    ]),
  percent_phone_needs_attention_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'phone_needs_attention_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_phone_needs_attention_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'phone_needs_attention_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_phone_needs_attention_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'phone_needs_attention_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_confirmed_registered_qc: ({ data, acc }) =>
    customPercent(data, acc, [
      'confirmed_registered_qc',
      'total_scans_called_qc',
    ]),
  percent_confirmed_registered_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'confirmed_registered_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_confirmed_registered_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'confirmed_registered_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_confirmed_registered_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'confirmed_registered_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_did_not_register_qc: ({ data, acc }) =>
    customPercent(data, acc, ['did_not_register_qc', 'total_scans_called_qc']),
  percent_did_not_register_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'did_not_register_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_did_not_register_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'did_not_register_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_did_not_register_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'did_not_register_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_correct_address_qc: ({ data, acc }) =>
    customPercent(data, acc, ['correct_address_qc', 'total_scans_called_qc']),
  percent_correct_address_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'correct_address_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_correct_address_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'correct_address_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_correct_address_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'correct_address_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_incorrect_address_qc: ({ data, acc }) =>
    customPercent(data, acc, ['incorrect_address_qc', 'total_scans_called_qc']),
  percent_incorrect_address_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'incorrect_address_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_incorrect_address_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'incorrect_address_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_incorrect_address_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'incorrect_address_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_correct_dob_qc: ({ data, acc }) =>
    customPercent(data, acc, ['correct_dob_qc', 'total_scans_called_qc']),
  percent_correct_dob_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'correct_dob_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_correct_dob_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'correct_dob_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_correct_dob_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'correct_dob_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_incorrect_dob_qc: ({ data, acc }) =>
    customPercent(data, acc, ['incorrect_dob_qc', 'total_scans_called_qc']),
  percent_incorrect_dob_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'incorrect_dob_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_incorrect_dob_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'incorrect_dob_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_incorrect_dob_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'incorrect_dob_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_received_feedback_qc: ({ data, acc }) =>
    customPercent(data, acc, ['received_feedback_qc', 'total_scans_called_qc']),
  percent_received_feedback_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'received_feedback_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_received_feedback_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'received_feedback_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_received_feedback_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'received_feedback_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_not_home_qc: ({ data, acc }) =>
    customPercent(data, acc, ['not_home_qc', 'total_calls']),
  percent_not_home_qc_day: ({ data, acc }) =>
    customPercent(data, acc, ['not_home_qc_day', 'total_calls_day']),
  percent_not_home_qc_week: ({ data, acc }) =>
    customPercent(data, acc, ['not_home_qc_week', 'total_calls_week']),
  percent_not_home_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, ['not_home_qc_all_time', 'total_calls_all_time']),
  percent_disconnected_qc: ({ data, acc }) =>
    customPercent(data, acc, ['disconnected_qc', 'total_scans_called_qc']),
  percent_disconnected_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'disconnected_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_disconnected_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'disconnected_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_disconnected_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'disconnected_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_call_back_qc: ({ data, acc }) =>
    customPercent(data, acc, ['call_back_qc', 'total_calls']),
  percent_call_back_qc_day: ({ data, acc }) =>
    customPercent(data, acc, ['call_back_qc_day', 'total_calls_day']),
  percent_call_back_qc_week: ({ data, acc }) =>
    customPercent(data, acc, ['call_back_qc_week', 'total_calls_week']),
  percent_call_back_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, ['call_back_qc_all_time', 'total_calls_all_time']),
  percent_language_barrier_qc: ({ data, acc }) =>
    customPercent(data, acc, ['language_barrier_qc', 'total_scans_called_qc']),
  percent_language_barrier_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'language_barrier_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_language_barrier_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'language_barrier_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_language_barrier_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'language_barrier_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_refused_qc: ({ data, acc }) =>
    customPercent(data, acc, ['refused_qc', 'total_scans_called_qc']),
  percent_refused_qc_day: ({ data, acc }) =>
    customPercent(data, acc, ['refused_qc_day', 'total_scans_called_qc_day']),
  percent_refused_qc_week: ({ data, acc }) =>
    customPercent(data, acc, ['refused_qc_week', 'total_scans_called_qc_week']),
  percent_refused_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'refused_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_wrong_number_qc: ({ data, acc }) =>
    customPercent(data, acc, ['wrong_number_qc', 'total_scans_called_qc']),
  percent_wrong_number_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'wrong_number_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_wrong_number_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'wrong_number_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_wrong_number_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'wrong_number_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_wrong_voicemail_qc: ({ data, acc }) =>
    customPercent(data, acc, ['wrong_voicemail_qc', 'total_scans_called_qc']),
  percent_wrong_voicemail_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'wrong_voicemail_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_wrong_voicemail_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'wrong_voicemail_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_wrong_voicemail_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'wrong_voicemail_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  percent_busy_qc: ({ data, acc }) =>
    customPercent(data, acc, ['busy_qc', 'total_calls']),
  percent_busy_qc_day: ({ data, acc }) =>
    customPercent(data, acc, ['busy_qc_day', 'total_calls_day']),
  percent_busy_qc_week: ({ data, acc }) =>
    customPercent(data, acc, ['busy_qc_week', 'total_calls_week']),
  percent_busy_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, ['busy_qc_all_time', 'total_calls_all_time']),
  percent_do_not_call_qc: ({ data, acc }) =>
    customPercent(data, acc, ['do_not_call_qc', 'total_scans_called_qc']),
  percent_do_not_call_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'do_not_call_qc_day',
      'total_scans_called_qc_day',
    ]),
  percent_do_not_call_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'do_not_call_qc_week',
      'total_scans_called_qc_week',
    ]),
  percent_do_not_call_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'do_not_call_qc_all_time',
      'total_scans_called_qc_all_time',
    ]),
  average_call_time: ({ data }) => {
    if (!data.length) return '00:00:00'

    const milliseconds = data.reduce(
      (acc, value) =>
        moment.duration(value.average_call_time).as('milliseconds') + acc,
      0
    )

    return formatDuration(milliseconds / data.length)
  },
  reupload_qc: sum,
  reupload_qc_day: sum,
  reupload_qc_week: sum,
  reupload_qc_all_time: sum,
  percent_approved_qc: ({ data, acc }) =>
    customPercent(data, acc, ['approved_qc', 'total_scans_qc']),
  percent_approved_qc_day: ({ data, acc }) =>
    customPercent(data, acc, ['approved_qc_day', 'total_scans_through_qc_day']),
  percent_approved_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'approved_qc_week',
      'total_scans_through_qc_week',
    ]),
  percent_approved_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'approved_qc_all_time',
      'total_scans_through_qc_all_time',
    ]),
  percent_missing_phone_qc: ({ data, acc }) =>
    customPercent(data, acc, ['missing_phone_qc', 'total_scans_qc']),
  percent_missing_phone_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'missing_phone_qc_day',
      'total_scans_through_qc_day',
    ]),
  percent_missing_phone_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'missing_phone_qc_week',
      'total_scans_through_qc_week',
    ]),
  percent_missing_phone_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'missing_phone_qc_all_time',
      'total_scans_through_qc_all_time',
    ]),
  percent_reupload_qc: ({ data, acc }) =>
    customPercent(data, acc, ['reupload_qc', 'total_scans_qc']),
  percent_reupload_qc_day: ({ data, acc }) =>
    customPercent(data, acc, ['reupload_qc_day', 'total_scans_through_qc_day']),
  percent_reupload_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'reupload_qc_week',
      'total_scans_through_qc_week',
    ]),
  percent_reupload_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'reupload_qc_all_time',
      'total_scans_through_qc_all_time',
    ]),
  percent_missing_required_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'missing_required_qc_day',
      'total_scans_through_qc_day',
    ]),
  percent_missing_required_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'missing_required_qc_week',
      'total_scans_through_qc_week',
    ]),
  percent_missing_required_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'missing_required_qc_all_time',
      'total_scans_through_qc_all_time',
    ]),
  percent_handwriting_qc: ({ data, acc }) =>
    customPercent(data, acc, ['handwriting_qc', 'total_scans_qc']),
  percent_handwriting_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'handwriting_qc_day',
      'total_scans_through_qc_day',
    ]),
  percent_handwriting_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'handwriting_qc_week',
      'total_scans_through_qc_week',
    ]),
  percent_handwriting_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'handwriting_qc_all_time',
      'total_scans_through_qc_all_time',
    ]),
  percent_canvasser_assistance_qc: ({ data, acc }) =>
    customPercent(data, acc, ['canvasser_assistance_qc', 'total_scans_qc']),
  percent_canvasser_assistance_qc_day: ({ data, acc }) =>
    customPercent(data, acc, [
      'canvasser_assistance_qc_day',
      'total_scans_through_qc_day',
    ]),
  percent_canvasser_assistance_qc_week: ({ data, acc }) =>
    customPercent(data, acc, [
      'canvasser_assistance_qc_week',
      'total_scans_through_qc_week',
    ]),
  percent_canvasser_assistance_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'canvasser_assistance_qc_all_time',
      'total_scans_through_qc_all_time',
    ]),
  percent_other_qc: ({ data, acc }) =>
    customPercent(data, acc, ['other_qc', 'total_scans_qc']),
  percent_other_qc_day: ({ data, acc }) =>
    customPercent(data, acc, ['other_qc_day', 'total_scans_through_qc_day']),
  percent_other_qc_week: ({ data, acc }) =>
    customPercent(data, acc, ['other_qc_week', 'total_scans_through_qc_week']),
  percent_other_qc_all_time: ({ data, acc }) =>
    customPercent(data, acc, [
      'other_qc_all_time',
      'total_scans_through_qc_all_time',
    ]),
  total_scans_called_qc: sum,
  total_scans_called_qc_day: sum,
  total_scans_called_qc_week: sum,
  total_scans_called_qc_all_time: sum,
  total_visual_qc_days_day: timelineAverage('day'),
  total_visual_qc_days_week: timelineAverage('week'),
  total_visual_qc_days_all_time: timelineAverage('all_time'),
  total_phone_qc_days_day: timelineAverage('day'),
  total_phone_qc_days_week: timelineAverage('week'),
  total_phone_qc_days_all_time: timelineAverage('all_time'),
  calls_per_day_day: ({ data, acc }) =>
    customRatio(data, acc, ['total_calls_day', 'total_phone_qc_days_day']),
  calls_per_day_week: ({ data, acc }) =>
    customRatio(data, acc, ['total_calls_week', 'total_phone_qc_days_week']),
  calls_per_day_all_time: ({ data, acc }) =>
    customRatio(data, acc, [
      'total_calls_all_time',
      'total_phone_qc_days_all_time',
    ]),
  total_packets_uploaded: sum,
  total_scans_uploaded: sum,
  total_flags: sum,
  completed_flags: sum,
  total_contacts_day: sum,
  total_contacts_week: sum,
  total_contacts_all_time: sum,
  total_verified_day: sum,
  total_verified_week: sum,
  total_verified_all_time: sum,
  contact_rate_day: ({ data, acc }) =>
    customExactRatio(data, acc, ['total_contacts_day', 'total_calls_day']),
  contact_rate_week: ({ data, acc }) =>
    customExactRatio(data, acc, ['total_contacts_week', 'total_calls_week']),
  contact_rate_all_time: ({ data, acc }) =>
    customExactRatio(data, acc, [
      'total_contacts_all_time',
      'total_calls_all_time',
    ]),
  verified_rate_day: ({ data, acc }) =>
    customExactRatio(data, acc, ['total_verified_day', 'total_calls_day']),
  verified_rate_week: ({ data, acc }) =>
    customExactRatio(data, acc, ['total_verified_week', 'total_calls_week']),
  verified_rate_all_time: ({ data, acc }) =>
    customExactRatio(data, acc, [
      'total_verified_all_time',
      'total_calls_all_time',
    ]),
  avg_length_of_call_day: ({ data, acc }) => {
    const [lengths_of_calls_seconds, total_calls] = findOtherColTotals(
      data,
      acc,
      ['lengths_of_calls_seconds_day', 'total_calls_day']
    )
    if (total_calls === 0) return '00:00:00'
    return moment
      .utc((lengths_of_calls_seconds / total_calls) * 1000)
      .format('HH:mm:ss.S')
  },
  avg_length_of_call_week: ({ data, acc }) => {
    const [lengths_of_calls_seconds, total_calls] = findOtherColTotals(
      data,
      acc,
      ['lengths_of_calls_seconds_week', 'total_calls_week']
    )
    if (total_calls === 0) return '00:00:00'
    return moment
      .utc((lengths_of_calls_seconds / total_calls) * 1000)
      .format('HH:mm:ss.S')
  },
  avg_length_of_call_all_time: ({ data, acc }) => {
    const [lengths_of_calls_seconds, total_calls] = findOtherColTotals(
      data,
      acc,
      ['lengths_of_calls_seconds_all_time', 'total_calls_all_time']
    )
    if (total_calls === 0) return '00:00:00'
    return moment
      .utc((lengths_of_calls_seconds / total_calls) * 1000)
      .format('HH:mm:ss.S')
  },
  overdue: sum,
  zero_days: sum,
  one_days: sum,
  two_days: sum,
  three_days: sum,
  four_days: sum,
  five_days: sum,
  six_days: sum,
  seven_days: sum,
  eight_days: sum,
  nine_days: sum,
  ten_days: sum,
  total_packet_scans_day: sum,
  total_packet_scans_week: sum,
  total_packet_scans_all_time: sum,
}
