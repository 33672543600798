import { useCurrent } from 'contexts/index'
import styles from './DashboardWidgets.module.scss'
import EventsByType from '../widgets/EventsByType/EventsByType'
import EventsByDate from '../widgets/EventsByDate/EventsByDate'
import GroupEventsByOrganizer from '../widgets/GroupEventsByOrganizer/GroupEventsByOrganizer'
import MeetingsByOrganizer from '../widgets/MeetingsByOrganizer/MeetingsByOrganizer'
import EventsAttendance from '../widgets/EventsAttendance/EventsAttendance'
import MostMeetingsByOrganizer from '../widgets/MostMeetingsByOrganizer/MostMeetingsByOrganizer'
import VoterRegOverTimeLineChart from '../widgets/VoterRegOverTimeLineChart/VoterRegOverTimeLineChart'
import VoterRegistrationsByType from '../widgets/VoterRegistrationsByType/VoterRegistrationsByType'
import VoterRegistrationsByTypeOverTime from '../widgets/VoterRegistrationsByTypeOverTime/VoterRegistrationsByTypeOverTime'
import VoterRegSummaryReport from '../widgets/VoterRegSummaryReport/VoterRegSummaryReport'

const DashboardWidget = ({ type }) => {
  switch (type) {
    case 'events_by_type':
      return <EventsByType />
    case 'events_by_date':
      return <EventsByDate />
    case 'group_events_by_organizer':
      return <GroupEventsByOrganizer />
    case 'meetings_by_organizer':
      return <MeetingsByOrganizer />
    case 'events_attendance_by_organizer':
      return <EventsAttendance />
    case 'most_meetings_by_organizer':
      return <MostMeetingsByOrganizer />
    case 'voter_registrations_over_time':
      return <VoterRegOverTimeLineChart />
    case 'voter_registrations_by_type':
      return <VoterRegistrationsByType />
    case 'voter_registrations_by_type_over_time':
      return <VoterRegistrationsByTypeOverTime />
    case 'voter_registrations_top_performers_by_turf':
      return <VoterRegSummaryReport />
    default:
      return null
  }
}

const DashboardWidgets = () => {
  const {
    currentUser: {
      role: {
        dashboard_layout: {
          content: { widgets },
        },
      },
    },
  } = useCurrent()
  return (
    <div className={styles.grid}>
      {widgets.map((widget, i) => (
        <div key={i}>
          <DashboardWidget type={widget} />
        </div>
      ))}
    </div>
  )
}

export default DashboardWidgets
