import { Icon } from '@politechdev/blocks-design-system'
import { DetailItem } from 'components'
import { useTranslation } from 'react-i18next'
import Count from './Count/Count'
import styles from './OperatorSummary.module.scss'
import { useCallStats } from '../OperatorContext/OperatorContext'
import { formatWaitTime, formatPercentage } from '../util'

const OperatorSummary = () => {
  const { t } = useTranslation()
  const {
    averageWaitTime,
    pickupPercent,
    abandonRate,
    agentsAvailable,
    agentsIdle,
    agentsOnCall,
  } = useCallStats()
  return (
    <div className={styles.container}>
      <div className={styles.summary}>
        <div className={styles['main--inactive']}>
          <h4 className={styles.label}>Agent summary</h4>
          <div className={styles.counts}>
            <Count
              label={t('On a call')}
              value={agentsOnCall}
              Icon={Icon.PhoneVolume}
              accent
            />
            <Count
              label={t('Available')}
              value={agentsAvailable}
              Icon={Icon.HourglassHalf}
              primary
            />
            <Count
              label={t('Idle')}
              value={agentsIdle}
              Icon={Icon.TimesHexagon}
              gray
            />
          </div>
          <div>
            <DetailItem label={t('Average wait time')}>
              {formatWaitTime(averageWaitTime)}
            </DetailItem>
            <DetailItem label={t('Estimated contact ratio')}>
              {formatPercentage(pickupPercent)}
            </DetailItem>
            <DetailItem label={t('Abandon rate')}>
              {formatPercentage(abandonRate)}
            </DetailItem>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OperatorSummary
