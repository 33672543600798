import moment from 'moment'
import { unmaskPhone } from 'utils/inputMasks'
import _, { assign } from 'lodash'
import { FIELD_KEYS } from '../utils'

export const getDifferences = (errorRecord, personRecord) => {
  const differences = {
    [FIELD_KEYS.FIRST_NAME]: false,
    [FIELD_KEYS.LAST_NAME]: false,
    [FIELD_KEYS.MIDDLE_NAME]: false,
    [FIELD_KEYS.PREFIX]: false,
    [FIELD_KEYS.SUFFIX_NAME]: false,
    [FIELD_KEYS.RESIDENTIAL_ADDRESS]: false,
    [FIELD_KEYS.BIRTH_DATE]: false,
    [FIELD_KEYS.PRIMARY_PHONE_NUMBER]: false,
    [FIELD_KEYS.PRIMARY_EMAIL_ADDRESS]: false,
    [FIELD_KEYS.GOTV_PHONE_NUMBER]: false,
    [FIELD_KEYS.EXTERNAL_ID]: false,
  }

  Object.keys(differences).forEach(key => {
    switch (key) {
      case FIELD_KEYS.PREVIOUS_REGISTRATION_ADDRESS:
      case FIELD_KEYS.PREVIOUS_REGISTRATION_DATE:
      case FIELD_KEYS.PREVIOUS_REGISTRATION_ORGANIZATION:
      case FIELD_KEYS.GOTV_PHONE_NUMBER:
      case FIELD_KEYS.EXTERNAL_ID:
      case FIELD_KEYS.FIRST_NAME:
      case FIELD_KEYS.LAST_NAME:
      case FIELD_KEYS.MIDDLE_NAME:
      case FIELD_KEYS.PREFIX:
      case FIELD_KEYS.PRIMARY_EMAIL_ADDRESS:
      case FIELD_KEYS.SUFFIX_NAME: {
        if (!errorRecord[key]) {
          break
        } else if (errorRecord[key] !== personRecord[key]) {
          differences[key] = true
        }
        break
      }
      case FIELD_KEYS.BIRTH_DATE: {
        if (!errorRecord[key] && errorRecord[key] !== '') break
        if (!moment(errorRecord[key]).isSame(personRecord[key], 'day')) {
          differences[key] = true
        }
        break
      }
      case FIELD_KEYS.PRIMARY_PHONE_NUMBER: {
        if (!errorRecord[key]) {
          break
        }
        if (unmaskPhone(errorRecord[key]) !== unmaskPhone(personRecord[key])) {
          differences[key] = true
        }
        break
      }
      case FIELD_KEYS.RESIDENTIAL_ADDRESS: {
        const errorAddress = errorRecord.residential_address
        const personAddress = personRecord[key]
        if (!errorAddress) {
          break
        }
        const addressFieldsToDiff = Object.keys(errorAddress).map(
          addressField => FIELD_KEYS[addressField.toUpperCase()]
        )
        differences[key] = assign.apply(
          _,
          addressFieldsToDiff.map(addressKey => ({
            [addressKey]:
              errorAddress?.[addressKey] !== personAddress?.[addressKey],
          }))
        )
        break
      }
      default: {
        break
      }
    }
  })

  return differences
}
