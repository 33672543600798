import { useState, useCallback, useContext } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'
import { useRequest } from 'hooks'
import {
  duplicateDocument,
  fetchDocumentsThroughFolder,
  moveDocument,
} from 'requests/documents'
import { formatErrorMessage } from 'utils/formatting'
import { CLIPBOARD_ACTIONS, RESOURCE_TYPES } from '../constants'
import DocumentContext from '../DocumentContext/DocumentContext'

const ClipboardContext = createContext()

export const ClipboardProvider = ({ children }) => {
  const [item, setItem] = useState({})
  const [action, setAction] = useState(null)

  const { moveFolder } = useContext(DocumentContext)

  const {
    makeRequest: moveDocumentRequest,
    isLoading: moveDocumentLoading,
    hasErrors: moveDocumentHasErrors,
    errors: moveDocumentErrors,
  } = useRequest(moveDocument)
  const {
    makeRequest: duplicateDocumentRequest,
    isLoading: duplicateDocumentLoading,
    hasErrors: duplicateDocumentHasErrors,
    errors: duplicateDocumentErrors,
  } = useRequest(duplicateDocument)
  const {
    makeRequest: moveFolderRequest,
    isLoading: moveFolderLoading,
    hasErrors: moveFolderHasErrors,
    errors: moveFolderErrors,
  } = useRequest(moveFolder)

  const moveDocumentErrorMsg = formatErrorMessage(moveDocumentErrors)
  const duplicateDocumentErrorMsg = formatErrorMessage(duplicateDocumentErrors)
  const moveFolderErrorMsg = formatErrorMessage(moveFolderErrors)

  const isLoading =
    moveDocumentLoading || duplicateDocumentLoading || moveFolderLoading
  const hasErrors =
    moveDocumentHasErrors || duplicateDocumentHasErrors || moveFolderHasErrors
  const errorMsg =
    moveDocumentErrorMsg || duplicateDocumentErrorMsg || moveFolderErrorMsg

  const copy = useCallback(({ id = null, type = null, ...params }) => {
    setItem({ id, type, ...params })
    setAction(CLIPBOARD_ACTIONS.COPY)
  }, [])

  const cut = useCallback(({ id = null, type = null, ...params }) => {
    setItem({ id, type, ...params })
    setAction(CLIPBOARD_ACTIONS.CUT)
  }, [])

  const clear = useCallback(() => {
    setItem({})
    setAction(null)
  }, [])

  return (
    <ClipboardContext.Provider
      value={{
        item,
        action,
        copy,
        cut,
        clear,
        moveDocument: moveDocumentRequest,
        duplicateDocument: duplicateDocumentRequest,
        moveFolder: moveFolderRequest,
        isLoading,
        hasErrors,
        errorMsg,
      }}
    >
      {children}
    </ClipboardContext.Provider>
  )
}

export const useClipboardActions = () => {
  const moveDocument = useContextSelector(
    ClipboardContext,
    value => value.moveDocument
  )
  const duplicateDocument = useContextSelector(
    ClipboardContext,
    value => value.duplicateDocument
  )
  const moveFolder = useContextSelector(
    ClipboardContext,
    value => value.moveFolder
  )

  return {
    moveDocument,
    duplicateDocument,
    moveFolder,
  }
}

export const useClipboardStatus = () => {
  const isLoading = useContextSelector(
    ClipboardContext,
    value => value.isLoading
  )
  const errorMsg = useContextSelector(ClipboardContext, value => value.errorMsg)

  return { isLoading, errorMsg }
}

export const useClipboard = ({
  item,
  canPaste: canPasteFunc,
  onPaste: onPasteFunc,
}) => {
  const action = useContextSelector(ClipboardContext, value => value.action)

  const clipboardItem = useContextSelector(
    ClipboardContext,
    ({ item: clipboardItem }) => {
      if (!clipboardItem.id || !action) return false
      if (!canPasteFunc) return false
      if (!canPasteFunc(clipboardItem, action)) return false

      return clipboardItem
    }
  )
  const isCopying = useContextSelector(
    ClipboardContext,
    value =>
      value.item.id === item.id &&
      value.item.type === item.type &&
      value.action === CLIPBOARD_ACTIONS.COPY
  )
  const isCutting = useContextSelector(
    ClipboardContext,
    value =>
      value.item.id === item.id &&
      value.item.type === item.type &&
      value.action === CLIPBOARD_ACTIONS.CUT
  )
  const clear = useContextSelector(ClipboardContext, value => value.clear)
  const copy = useContextSelector(ClipboardContext, value => value.copy)
  const cut = useContextSelector(ClipboardContext, value => value.cut)

  const copyItem = () => {
    copy(item)
  }
  const cutItem = () => {
    cut(item)
  }
  const pasteItem = () => {
    if (!clipboardItem) return null
    onPasteFunc(clipboardItem, action)
    clear()
  }

  return {
    isCopying,
    isCutting,
    canPaste: !!clipboardItem,
    copyItem,
    cutItem,
    pasteItem,
  }
}

export const useFolderClipboard = ({ folder, onPaste }) => {
  const { moveDocument, duplicateDocument, moveFolder } = useClipboardActions()
  const { setDocuments } = useContext(DocumentContext)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updateCurrentDocs } = useRequest(
    fetchDocumentsThroughFolder,
    {
      onSuccess: ({ documents }) => setDocuments(documents),
    }
  )

  return useClipboard({
    item: {
      id: folder.id,
      lft: folder.lft,
      rgt: folder.rgt,
      parent_id: folder.parent_id,
      type: RESOURCE_TYPES.FOLDER,
      onPaste,
    },
    canPaste: (item, action) => {
      const isParent = folder.id === item.parent_id
      if (item.type === RESOURCE_TYPES.DOCUMENT) {
        if (isParent && action === CLIPBOARD_ACTIONS.CUT) {
          return false
        }

        return true
      }

      return !(item.lft <= folder.lft && item.rgt >= folder.rgt)
    },
    onPaste: async (item, action) => {
      if (item.type === RESOURCE_TYPES.DOCUMENT) {
        if (action === CLIPBOARD_ACTIONS.CUT) {
          await moveDocument({
            documentId: item.id,
            folderId: folder.id,
          })
        }
        if (action === CLIPBOARD_ACTIONS.COPY) {
          await duplicateDocument({
            documentId: item.id,
            folderId: folder.id,
          })
        }
      }

      if (item.type === RESOURCE_TYPES.FOLDER) {
        await moveFolder(item.id, folder.id)
      }

      updateCurrentDocs(folder.id)

      item.onPaste && item.onPaste()
      onPaste && onPaste()
    },
  })
}

export const useDocumentClipboard = ({ document, onPaste }) =>
  useClipboard({
    item: {
      id: document.id,
      parent_id: document.folder_id,
      type: RESOURCE_TYPES.DOCUMENT,
      onPaste,
    },
  })
