import { useState } from 'react'
import { Icon, Button, Font } from '@politechdev/blocks-design-system'
import useEvent from 'hooks/useEvent'
import { useTranslation } from 'react-i18next'
import styles from './OperatorDial.module.scss'
import {
  useCallStats,
  useOperatorActions,
} from '../OperatorContext/OperatorContext'

const deriveWarningsFromStats = ({
  callsRinging,
  pickupPercent,
  remainingContacts,
  agentsAvailable,
  abandonRate,
}) =>
  Object.entries({
    noAgents: agentsAvailable < 1,
    noContacts: remainingContacts < 1,
    abandonRate: abandonRate > 0.2,
    threshold: (callsRinging * pickupPercent) / (agentsAvailable || 1) > 1.1,
  })
    .filter(([, isActive]) => isActive)
    .map(([warning]) => warning)

const OperatorDial = () => {
  const stats = useCallStats()
  const { callsRinging } = stats
  const { dial } = useOperatorActions()
  const { t } = useTranslation()

  const [warningsIgnored, setWarningsIgnored] = useState(false)
  const temporarilyIgnoreWarning = () => {
    setWarningsIgnored(true)
    setTimeout(() => setWarningsIgnored(false), 20000)
  }

  const ignorableWarnings = ['threshold', 'abandonRate']
  const activeWarnings = deriveWarningsFromStats(stats).filter(
    warning => !(warningsIgnored && ignorableWarnings.includes(warning))
  )

  const canIgnoreWarnings =
    activeWarnings.length > 0 &&
    activeWarnings.every(warning => ignorableWarnings.includes(warning))

  const handleAddCall = useEvent(() => {
    dial()
    document.activeElement && document.activeElement.blur()
  })

  return (
    <div className={styles.dialer__container}>
      <div className={styles.dialer}>
        <Button.Accent
          disabled={activeWarnings.length > 0}
          onClick={handleAddCall}
          className={styles.addcall}
          tooltipLabel={t('Add call')}
          tooltipPosition="top"
        >
          <Icon.Plus fillColor="#fff" />
        </Button.Accent>
        <Font.Display variant="small">
          {callsRinging} {`call${callsRinging === 1 ? '' : 's'} ringing`}
        </Font.Display>
      </div>
      {canIgnoreWarnings && (
        <Button.Secondary onClick={temporarilyIgnoreWarning}>
          {t('Ignore warning and continue')}
        </Button.Secondary>
      )}
    </div>
  )
}

export default OperatorDial
