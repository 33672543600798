import { sortBy } from 'lodash'
import { localizeTime } from 'utils/dateTime'

export const personFields = {
  fields: [
    'id',
    {
      meetings: [
        'id',
        'start_time',
        'end_time',
        'cancelled',
        'notes',
        { user: ['name', 'email'] },
      ],
    },
    {
      event_attendances: [
        { event: ['id', 'name', 'start_time', 'end_time'] },
        'status',
        'id',
        { inviter: ['id', 'name'] },
      ],
    },
    { notes: ['id', 'content', 'author', 'created_at'] },
    {
      volunteerships: [
        'id',
        'responsibility',
        { event: ['id', 'name', 'start_time', 'end_time'] },
        { shift: ['id', 'start_time', 'end_time'] },
      ],
    },
    'created_at',
    'updated_at',
    'source',
  ],
}

export const handlePersonFetchRequest = ({
  person,
  setPerson,
  setUpcomingEventShifts,
  setPastEvents,
  setPastMeetings,
  setUpcomingEvents,
  setUpcomingMeetings,
}) => {
  setPerson(person)
  const events = person.event_attendances.map(event_attended => {
    const { status, inviter } = event_attended
    return {
      ...event_attended.event,
      status,
      inviter,
      attendanceId: event_attended.id,
    }
  })
  const { meetings } = person

  const eventShifts = person.volunteerships.map(volunteership => ({
    volunteerId: volunteership.id,
    id: volunteership.shift.id,
    event: volunteership.event,
    start_time: volunteership.shift.start_time,
    end_time: volunteership.shift.end_time,
    responsibility: volunteership.responsibility,
  }))

  setUpcomingEventShifts(
    sortBy(
      eventShifts.filter(({ end_time }) => localizeTime(end_time).isAfter()),
      ({ start_time }) => start_time
    )
  )

  setPastEvents(
    sortBy(
      events.filter(event => localizeTime(event.end_time).isBefore()),
      'start_time'
    )
  )
  setPastMeetings(
    sortBy(
      meetings.filter(event => localizeTime(event.end_time).isBefore()),
      'start_time'
    )
  )
  setUpcomingEvents(
    sortBy(
      events.filter(event => localizeTime(event.end_time).isAfter()),
      'start_time'
    )
  )
  setUpcomingMeetings(
    sortBy(
      meetings.filter(event => localizeTime(event.end_time).isAfter()),
      'start_time'
    )
  )
}

export const limitShownGathering = (gathering, gatheringLimit) =>
  gathering.slice(0, gatheringLimit)
