import translatedCellRenderer from 'tableCells/TranslatedCell'
import {
  ReviewCollectedCell,
  ReviewPacketNameCell,
  ReviewRegistrantCell,
  ReviewUploadedCell,
} from './ReviewTableCells'

export default {
  label: 'review',
  defaultSortOrder: 'asc',
  defaultSortColumn: 'scan_number',
  columns: [
    {
      dataKey: 'scan_number',
      title: 'Scan',
      type: 'number',
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'packet.original_filename',
      title: 'Packet name',
      type: 'string',
      bodyCell: ReviewPacketNameCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'form.display_name',
      title: 'Registrant',
      type: 'string',
      bodyCell: ReviewRegistrantCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'county',
      title: 'County',
      type: 'string',
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'shift.shift_start',
      title: 'Collected',
      type: 'date',
      bodyCell: ReviewCollectedCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'created_at',
      title: 'Uploaded',
      type: 'date',
      bodyCell: ReviewUploadedCell,
      headerCell: translatedCellRenderer,
    },
  ],
}
