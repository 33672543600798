import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CampaignSelect from '../CampaignSelect/CampaignSelect'
import CampaignCreate from '../CampaignCreate/CampaignCreate'
import CampaignEdit from '../CampaignEdit/CampaignEdit'

const CampaignRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={CampaignSelect} />
    <Route exact path={`${match.path}/new`} component={CampaignCreate} />
    <Route exact path={`${match.path}/:id/edit`} component={CampaignEdit} />
  </Switch>
)

export default CampaignRoutes
