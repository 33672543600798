import React from 'react'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'

export const TeamNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/teams/${rowData.id}`}>{rowData.name}</Link>
  </TableGridCell>
)

export const TeamLeaderNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.leader?.name}</TableGridCell>
)
