import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'

export const ProjectionNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/field-management/projections/${rowData.id}`}>
      {rowData.name}
    </Link>
  </TableGridCell>
)

export const ProjectionTurfCell = ({ rowData }) => (
  <TableGridCell>{rowData.turfName}</TableGridCell>
)

export const ProjectionCreatorCell = ({ rowData }) => (
  <TableGridCell>{rowData.creatorName}</TableGridCell>
)

export const ProjectionStatusCell = ({ rowData }) => (
  <TableGridCell>{rowData.status}</TableGridCell>
)

export const ProjectionStartDateCell = ({ rowData }) => (
  <TableGridCell>{moment(rowData.startDate).format(dateFormat)}</TableGridCell>
)

export const ProjectionEndDateCell = ({ rowData }) => (
  <TableGridCell>{moment(rowData.endDate).format(dateFormat)}</TableGridCell>
)

export const ProjectionTotalCell = ({ rowData }) => (
  <TableGridCell>{Math.floor(rowData.total)}</TableGridCell>
)
