import { fetchJSON, stringifyParams } from 'utils/req'

export const defaultOptions = {
  fields: [
    'id',
    'slug',
    'first_name',
    'last_name',
    'full_name',
    'phone_number',
    'notes',
    'email',
    'archived',
    { turf: ['id', 'name'] },
  ],
  indexed: true,
}

export const fetchCanvassers = params =>
  fetchJSON(`/api/v1/canvassers?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchCanvasser = (id, params) =>
  fetchJSON(
    `/api/v1/canvassers/${id}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const postCanvasser = (canvasser, params) =>
  fetchJSON(
    `/api/v1/canvassers?${stringifyParams(params)}`,
    'POST',
    { canvasser },
    { useJwt: true }
  )

export const putCanvasser = (id, canvasser, params) =>
  fetchJSON(
    `/api/v1/canvassers/${id}?${stringifyParams(params)}`,
    'PUT',
    { canvasser },
    { useJwt: true }
  )

export const destroyCanvasser = id =>
  fetchJSON(`/api/v1/canvassers/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const fetchTurf = id => {
  const queryString = stringifyParams({
    fields: ['states'],
  })

  return fetchJSON(`/api/v1/turfs/${id}?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}
