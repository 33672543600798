import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Grid, DetailItem, Section } from 'components'
import { dateFormat } from '../utils/constants'

export const PhoneBankListMetaData = ({ list }) => {
  const { t } = useTranslation()
  return (
    <Grid>
      <Section>
        <DetailItem label={t('Contacts')}>{list.meta.total}</DetailItem>
        <DetailItem label={t('Unique households')}>
          {list.meta.households}
        </DetailItem>
        <DetailItem label={t('Callable phone numbers')}>
          {list.meta.phones}
        </DetailItem>
      </Section>
      <Section>
        <DetailItem label={t('Created on')}>
          {moment(list.created_at).format(dateFormat)}
        </DetailItem>
        <DetailItem label={t('Last refresh')}>
          {moment(list.refreshed_at).format(dateFormat)}
        </DetailItem>
        <DetailItem label={t('Folder')}>{list.folder.name}</DetailItem>
      </Section>
    </Grid>
  )
}
