import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { getDelivery } from 'requests/deliveries'
import DeliveryForm from '../DeliveryForm/DeliveryForm'

const DeliveryEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [currentDelivery, setCurrentDelivery] = useState({})

  const { makeRequest, isLoading, hasErrors, isRequestComplete } = useRequest(
    getDelivery,
    {
      onSuccess: res => {
        const { delivery: incomingDelivery } = res
        setCurrentDelivery(incomingDelivery)
      },
    }
  )

  useEffect(() => {
    makeRequest(match.params.id)
  }, [match.params.id])

  const deliveryLoaded =
    isRequestComplete && !hasErrors && currentDelivery.id === +match.params.id

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <PageHeader title={t('Edit delivery')}>
            <ButtonBlock justify="right">
              <Link
                to={`/collect/voter_registration/deliveries/${
                  deliveryLoaded ? currentDelivery.id : ''
                }`}
              >
                <Button.Secondary>
                  <Icon.Times />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </PageHeader>
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this delivery")}
          />
          {deliveryLoaded && <DeliveryForm delivery={currentDelivery} />}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default DeliveryEdit
