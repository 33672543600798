import { DetailItem } from 'components'
import { useTranslation } from 'react-i18next'
import { formatDisplayName, formatPhoneNumber } from 'utils/formatting'
import { Timestamp } from 'components/Time/Timestamp'

const CallHistoryItem = ({
  callTime,
  caller,
  result,
  notes,
  call,
  failedCalls = [],
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <>
      <DetailItem label={t('Result')}>
        <strong>{result}</strong>
      </DetailItem>
      <DetailItem label={t('Call time')}>
        <Timestamp
          dateTime={call.created_at}
          formatOptions={{
            dateStyle: 'medium',
            timeStyle: 'short',
          }}
        />
      </DetailItem>
      <DetailItem label={t('Called by')}>
        {formatDisplayName(caller)}
      </DetailItem>
      <DetailItem label={t('Contacted')}>{`${
        props['contacted?'] ? t('Yes') : t('No')
      }`}</DetailItem>
      <DetailItem label={t('Notes')}>{notes}</DetailItem>
      <DetailItem label={t('Number Dialed')}>
        {formatPhoneNumber(call.number)}
      </DetailItem>
      {!!failedCalls.length && (
        <DetailItem label={t('Other Numbers Attempted')} />
      )}
      {failedCalls.map(failedCall => (
        <DetailItem
          label={
            <Timestamp
              dateTime={failedCall.created_at}
              formatOptions={{
                dateStyle: 'medium',
                timeStyle: 'short',
              }}
            />
          }
        >
          {formatPhoneNumber(failedCall.number)}
        </DetailItem>
      ))}
    </>
  )
}

export default CallHistoryItem
