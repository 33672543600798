import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'components'
import {
  Section,
  SectionLabel,
  Font,
  TextBlock,
  ContentBlock,
  Checkbox,
  PageHeader,
} from '@politechdev/blocks-design-system'
import CallMenu from 'phone_banks/CallMenu/CallMenu'
import { useCurrent } from 'contexts/index'
import styles from './Operator.module.scss'
import powerDialerChartImg from './images/power-dialer-chart.png'
import sessionSummaryImg from './images/session-summary.png'
import queueChartImg from './images/queue-chart.png'

const PowerDialerAgentOnboarding = () => {
  const { t } = useTranslation()
  const [didAgreeToTerms, setDidAgreeToTerms] = useState(false)
  const { doesCurrentUserHavePermission } = useCurrent()
  const canBeOperator = doesCurrentUserHavePermission({
    resource: 'phone_banking/phone_bank',
    ability: 'operator',
  })

  return (
    <View>
      <PageHeader title={t('Before you begin as an operator')} />
      <Section>
        <TextBlock>
          <Font.Display variant="small" Element="h2">
            {t('Some definitions')}
          </Font.Display>
        </TextBlock>
        <TextBlock>
          <Font.Copy
            Element="ul"
            variant="reading-block"
            className={styles.list}
          >
            <li>
              <strong>{t('Operator')}</strong> -{' '}
              {t(
                'The user operating the phone bank. Operators control the phone bank session, initiate calls and are able to see agent activity at a glance.'
              )}
            </li>
            <li>
              <strong>{t('Agent')}</strong> -{' '}
              {t(
                'A phone bank agent is a user who is taking calls that the operator has initiated when the calls are answered. They have access to the phone bank script and are responsible for speaking to the phone bank participants and marking down their responses.'
              )}
            </li>
            <li>
              <strong>{t('Participant')}</strong> -{' '}
              {t(
                'A participant is a person in the contact list who has been reached via the phone bank.'
              )}
            </li>
          </Font.Copy>
        </TextBlock>
      </Section>
      <Section label={t('How power dialers work')}>
        <TextBlock>
          <Font.Copy Element="p" variant="reading-block">
            {t(
              'A Phone Bank Power Dialer is a type of phone bank that allows a single operator to issue many calls in rapid succession that can be routed to available agents. Operators control how many outgoing calls are made and can see how many agents are currently on a call with a participant, ready to take their next call or waiting idly. It is the responsibility of the operator to ensure that the amount of calls initiated does not outpace the agent capacity, yet is high enough to keep a steady pace that doesn’t keep agents waiting too long in between calls. The right amount of calls to initiate depends on how many agents are available in the session, the average contact rate and other variables that the operator should continuously evaluate to achieve an optimal pace.'
            )}
          </Font.Copy>
        </TextBlock>
        <ContentBlock>
          <img
            className={styles.image}
            src={powerDialerChartImg}
            alt="Power dialer chart"
          />
        </ContentBlock>
      </Section>
      <Section>
        <TextBlock>
          <Font.Display variant="small" Element="h2">
            {t('How to operate a phone bank')}
          </Font.Display>
        </TextBlock>
        <TextBlock>
          <SectionLabel>Agent queue</SectionLabel>
        </TextBlock>
        <TextBlock>
          {t(`Let's take a look at an example agent queue:`)}
        </TextBlock>
        <ContentBlock>
          <img
            className={styles.image}
            src={sessionSummaryImg}
            alt="Session summary"
          />
        </ContentBlock>
        <TextBlock>
          <Font.Copy variant="reading-block" Element="p">
            {t(
              'It displays how many agents you have in each of three possible statuses.'
            )}
          </Font.Copy>
          <Font.Copy variant="reading-block" Element="p">
            {t(
              'The not ready state indicates that the agent has joined the phone bank session but is not yet ready to take a call, likely because they are preparing by reading the script, submitting a response from their last call or taking a break in between calls.'
            )}
          </Font.Copy>
          <Font.Copy variant="reading-block" Element="p">
            {t(
              'Agents who are waiting to be connected are available and waiting to be patched over to a participant.'
            )}
          </Font.Copy>
          <Font.Copy variant="reading-block" Element="p">
            {t(
              'Agents who are on a call are currently on the phone with a participant and therefore not available to be patched over to another participant.'
            )}
          </Font.Copy>
          <Font.Copy variant="reading-block" Element="p">
            {t(
              'Use this queue information to determine how many agents are available to take new calls at any given moment. These numbers will be continuously shifting throughout a phone bank as agents go in and out of calls, so it is important to watch for changes throughout the session.'
            )}
          </Font.Copy>
        </TextBlock>
        <TextBlock>
          <SectionLabel>Adding calls</SectionLabel>
        </TextBlock>
        <TextBlock>
          <Font.Copy variant="reading-block" Element="p">
            {t(
              `Operators can initiate calls using the blue “plus” button. When a call is added, a contact will be pulled from the call list and dialed. This contact will be added to a pool of “contacts dialed.” If the call fails to get answered, the contact is removed from the pool and will not be dialed again until the next round of calls . If the call is answered, it will also be removed from the dial pool and patched over to an available agent.`
            )}
          </Font.Copy>
        </TextBlock>
        <ContentBlock>
          <img className={styles.image} src={queueChartImg} alt="Queue chart" />
        </ContentBlock>
        <TextBlock>
          <SectionLabel>Abandons</SectionLabel>
        </TextBlock>
        <TextBlock>
          <Font.Copy Element="p" variant="reading-block">
            {t(
              `An abandon occurs when a participant answers a call, but there was no available agent to patch over. This is typical a sign that the operator has dialed too many calls for the amount of agents available, so answers are coming in at a rate that exceeds the agent capacity. Operators should be very careful to avoid abandons as much as possible. This leads to bad experiences for participants and can even cause the calls to be interpreted as scam calls. The abandon rate is displayed in the operator view. Operators should strive to keep the abandon rate at 0.`
            )}
          </Font.Copy>
        </TextBlock>
        <TextBlock>
          <SectionLabel>Killswitch</SectionLabel>
        </TextBlock>
        <TextBlock>
          <Font.Copy Element="p" variant="reading-block">
            {t(
              `If the abandon rate exceeds 20%, a system “killswitch” is engaged which will prevent the operator from adding more calls.`
            )}
          </Font.Copy>
        </TextBlock>
        <TextBlock>
          <SectionLabel>Recommended pace</SectionLabel>
        </TextBlock>
        <TextBlock>
          <Font.Copy Element="p" variant="reading-block">
            {t(
              `With all this in mind, we recommend that operators start adding calls at a conservative pace, trying to not have more calls dialed than there are agents available. As the system begins calculating the average response rate, operators can slowly increase the amount of calls keeping a close eye on the available agents and keeping the abandon rate at or close to zero.`
            )}
          </Font.Copy>
        </TextBlock>
        <TextBlock>
          <SectionLabel>Ending a session</SectionLabel>
        </TextBlock>
        <TextBlock>
          <Font.Copy Element="p" variant="reading-block">
            {t(
              `Operators can chose to end a phone bank session by clicking the “end session” button at the top right of the operator view. This will remove all agents from the phone bank session who are not currently on a call or filling out answers from a call that was just ended. Those who are still on a call or submitting answers will be allowed to stay on until they complete their last call. We recommend that operators let all dialed numbers finish clear out of the dialing pool before ending the session.`
            )}
          </Font.Copy>
        </TextBlock>
        <TextBlock>
          <SectionLabel>Terms and conditions</SectionLabel>
        </TextBlock>
        <TextBlock className={styles['scroll-container']}>
          <p>
            Pop-up sustainable edison bulb keffiyeh man braid austin bruh,
            vibecession heirloom shoreditch. Sartorial copper mug street art
            actually shoreditch, kale chips shaman banh mi cold-pressed gentrify
            subway tile big mood vibecession dreamcatcher air plant. Ramps cred
            plaid ennui yes plz quinoa brunch. Lumbersexual swag trust fund,
            sriracha green juice actually cronut bushwick helvetica cloud bread
            seitan photo booth lomo.
          </p>
          <p>
            Everyday carry salvia bespoke roof party. Heirloom fingerstache
            photo booth fit kinfolk ramps, literally health goth small batch
            try-hard gastropub craft beer prism kitsch. Pabst vegan humblebrag
            everyday carry you probably haven&apos;t heard of them. Trust fund
            subway tile gatekeep ascot hot chicken.
          </p>
          <p>
            Viral snackwave beard shaman. Tonx gluten-free same whatever cliche,
            flexitarian taxidermy unicorn celiac. Drinking vinegar VHS fanny
            pack cold-pressed, bitters palo santo JOMO. Man bun small batch
            asymmetrical health goth, fit meh distillery 90&apos;s selfies vegan
            adaptogen YOLO freegan sus.
          </p>
          <p>
            Pickled godard artisan thundercats 90&apos;s gluten-free. Everyday
            carry beard shaman jianbing squid 90&apos;s hell of kogi post-ironic
            food truck big mood. Cloud bread four dollar toast aesthetic, shaman
            portland cold-pressed artisan tbh narwhal marfa. Disrupt raw denim
            vape gentrify irony paleo. Twee pug sus adaptogen small batch, prism
            put a bird on it swag. Kombucha tumblr cloud bread fixie tattooed,
            hammock farm-to-table selfies heirloom banjo cardigan craft beer.
            Brooklyn poke offal iPhone, activated charcoal ramps blog.
          </p>
          <p>
            Bespoke quinoa organic sriracha marfa, irony chillwave air plant
            bushwick etsy. Twee sustainable la croix scenester, taiyaki artisan
            vegan forage. Farm-to-table next level actually, semiotics fanny
            pack disrupt adaptogen banh mi. Cliche franzen locavore, narwhal
            vaporware prism hell of glossier selvage blog waistcoat literally
            wayfarers. Asymmetrical yr kale chips, farm-to-table tote bag tilde
            yuccie 90&apos;s occupy pug bodega boys. Cray hell of poutine,
            ethical biodiesel heirloom drinking vinegar meggings vexillologist.
          </p>
        </TextBlock>
      </Section>
      <TextBlock>
        <Checkbox
          label={t('I agree to the terms and conditions')}
          checked={didAgreeToTerms}
          onChange={() => setDidAgreeToTerms(!didAgreeToTerms)}
        />
      </TextBlock>
      <CallMenu
        callType="power_dialer"
        isOperator
        disabled={!didAgreeToTerms || !canBeOperator}
      />
      {!canBeOperator && (
        <TextBlock>
          <Font.Copy variant="secondary">
            You do not have permissions to be a phone bank operator
          </Font.Copy>
        </TextBlock>
      )}
    </View>
  )
}

export default PowerDialerAgentOnboarding
