import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SelectField, Font } from '@politechdev/blocks-design-system'
import styles from './TimePicker.module.scss'

const TimePicker = ({ id, label, initialTime, onChange, errors }) => {
  const { t } = useTranslation()

  const roundedInitialTime = useMemo(() => {
    if (!initialTime) return null

    return initialTime
      .minute(Math.ceil(initialTime.minute() / 15) * 15)
      .second(0)
  }, [initialTime])

  const [hour, setHour] = useState(roundedInitialTime?.format('h'))
  const [minute, setMinute] = useState(roundedInitialTime?.format('mm'))
  const [meridiem, setMeridiem] = useState(roundedInitialTime?.format('A'))

  useEffect(() => {
    if (hour && minute && meridiem) {
      const getHour = hour => {
        if (hour === 12 && meridiem === 'AM') {
          return 0
        }
        if (hour === 12 && meridiem === 'PM') {
          return 12
        }
        if (hour !== 12 && meridiem === 'PM') {
          return hour + 12
        }

        return hour
      }
      onChange(
        hour && minute && meridiem
          ? {
              hour: getHour(+hour),
              minute: +minute,
            }
          : null
      )
    }
  }, [hour, minute, meridiem])

  return (
    <div className={styles.container}>
      <SelectField
        className={styles.field}
        id={`${id}-hour`}
        label={t(label)}
        onSelect={setHour}
        options={[
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' },
          { label: '6', value: '6' },
          { label: '7', value: '7' },
          { label: '8', value: '8' },
          { label: '9', value: '9' },
          { label: '10', value: '10' },
          { label: '11', value: '11' },
          { label: '12', value: '12' },
        ]}
        value={hour || ''}
      />
      <SelectField
        className={styles.field}
        id={`${id}-minute`}
        onSelect={setMinute}
        options={[
          { label: '00', value: '00' },
          { label: '15', value: '15' },
          { label: '30', value: '30' },
          { label: '45', value: '45' },
        ]}
        value={minute || ''}
      />
      <SelectField
        className={styles.field}
        id={`${id}-meridiem`}
        onSelect={setMeridiem}
        options={[
          { label: 'AM', value: 'AM' },
          { label: 'PM', value: 'PM' },
        ]}
        value={meridiem || ''}
      />
      {errors && (
        <div className={styles.error}>
          <Font.Copy variant="error">{errors}</Font.Copy>
        </div>
      )}
    </div>
  )
}

TimePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  initialTime: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

TimePicker.defaultProps = {
  id: 'time',
  initialTime: moment(),
}

export default TimePicker
