import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import {
  SelectField,
  MultiSelectField,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchEvents } from 'requests/events'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts/index'
import { buildOption, buildEvent, buildParams } from './utils'

const EventSelectField = ({
  label,
  hideLabel,
  onSelect,
  canSelectAll,
  event = {},
  events = [],
  requestParams,
  excludedValues = [],
  isMulti,
  ...props
}) => {
  const { t } = useTranslation()

  const [options, setOptions] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState(event?.id)
  const [multiValue, setMultiValue] = useState(events.map(event => event.id))
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  const { doesCurrentUserHavePermission } = useCurrent()

  const hasPermission = doesCurrentUserHavePermission({
    resource: 'event',
    ability: 'view',
  })

  const { makeRequest, isLoading } = useRequest(fetchEvents, {
    onSuccess: ({ events: incomingEvents }) => {
      setOptions(
        [
          ...incomingEvents,
          ...(canSelectAll
            ? [
                {
                  name: t(`All ${label?.toLowerCase() || 'events'}`),
                  id: 'ALL',
                  start_time: '1970-01-01T00:00:00Z',
                },
              ]
            : []),
        ]
          .filter(option => !excludedValues.includes(option.id))
          .map(buildOption)
      )
    },
    onError: () => {
      setErrorMsg('Could not fetch events')
    },
  })

  const getEvents = () => {
    const params = buildParams(debounced, {
      ...requestParams,
    })
    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getEvents()
  }, [debounced])

  useEffect(() => {
    setValue(event?.id)
  }, [event])

  useEffect(() => {
    setMultiValue(events.map(event => event.id))
  }, [JSON.stringify(events)])

  const singleOptions = buildSingleOptions(options, event, buildOption)

  const multiOptions = buildMultiOptions(options, events, buildOption)

  const handleChange = selection => {
    const selectedOption = options.find(({ value }) => value === selection)
    onSelect &&
      onSelect(selectedOption ? buildEvent(selectedOption) : undefined)
  }

  const handleMultiChange = selections => {
    onSelect &&
      onSelect(
        multiOptions
          .filter(({ value }) => selections.includes(value))
          .map(buildEvent)
      )
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSelectField
          label={label || t('Event')}
          options={
            multiValue.find(currentValue => currentValue.value === 'ALL')
              ? []
              : multiOptions
          }
          onSelect={handleMultiChange}
          onInputChange={setQuery}
          values={multiValue}
          loading={isLoading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      ) : (
        <SelectField
          label={hideLabel ? undefined : label || t('Event')}
          options={singleOptions}
          onSelect={handleChange}
          onInputChange={setQuery}
          value={value}
          loading={isLoading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      )}
    </>
  )
}

EventSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  event: PropTypes.object,
  requestParams: PropTypes.object,
}

EventSelectField.defaultProps = {
  id: 'event',
  event: null,
  requestParams: {},
}

export default EventSelectField
