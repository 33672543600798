import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentBlock, Font, Icon } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { formatNumberAsString } from 'utils/formatting'
import GoalListItemButton from './GoalListItemButton'
import styles from './GoalList.module.scss'
import { GoalListContext } from './GoalListContext'

const GoalListItem = ({ className, secondary, turf }) => {
  const { t } = useTranslation()
  const cx = classNames.bind(styles)
  const { goalsByTurfMap } = useContext(GoalListContext)

  const goalData = goalsByTurfMap[turf.id] ? goalsByTurfMap[turf.id] : []

  const getTagLabel = goal =>
    goal.tags.length < 3 ? goal.tags.join(', ') : 'Multiple tags'

  const getProgressBarWidth = progressPercentage => {
    if (progressPercentage === 0) return 0

    if (progressPercentage > 0 && progressPercentage < 2) return 2

    if (progressPercentage > 100) return 100

    return progressPercentage
  }

  return goalData.map(goal => (
    <ContentBlock
      className={cx(className, 'list__item', {
        list__secondary__item: secondary,
      })}
    >
      <div className={styles.list__item__primary}>
        <div className={styles.list__item__goal}>
          <Font.Copy>{turf.name}</Font.Copy>
        </div>
        {goal && (
          <div className={styles.list__item__chart}>
            <div className={styles.list__item__chart__container}>
              <div
                className={styles.list__item__chart__fill}
                style={{
                  width: `${getProgressBarWidth(goal.progressPercentage)}%`,
                }}
              />
            </div>
            <div className={styles.list__item__chart__total}>
              {formatNumberAsString(goal.formsToCollect)}{' '}
              {t('registration goal')}
            </div>
            <div className={styles.list__item__info}>
              <Icon.InfoCircle />
              <div className={styles.list__item__info__tooltip}>
                <Font.Copy
                  variant="hint"
                  className={styles.list__item__info__tooltip__text}
                >
                  {formatNumberAsString(goal.formsCollected)} of{' '}
                  {formatNumberAsString(goal.formsToCollect)} goal met (
                  {formatNumberAsString(Math.round(goal.progressPercentage))}%)
                </Font.Copy>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.list__item__secondary}>
        <div className={styles.list__item__goal}>
          <Font.Copy variant="secondary">
            {moment(goal.startDate).format(dateFormat)} -{' '}
            {moment(goal.endDate).format(dateFormat)}
          </Font.Copy>
        </div>
        <span className={styles.list__item__chip}>
          <Font.Copy variant="hint" className={styles.list__item__chip__text}>
            {getTagLabel(goal)}
          </Font.Copy>
        </span>
      </div>
      {secondary ? (
        <GoalListItemButton turfId={turf.id} goal={goal} />
      ) : (
        <div className={styles.list__item__chevron}>
          <Icon.ChevronDown />
        </div>
      )}
    </ContentBlock>
  ))
}

export default GoalListItem
