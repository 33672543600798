import {
  DateField,
  Section,
  TextField,
  MultiSelectField,
  SelectField,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'

const NewPersonFormBasic = ({ updatePersonState, newPerson }) => {
  const { ethnicityOptions, genderOptions } = useCurrent()
  const { t } = useTranslation()

  return (
    <form>
      <Section label={t('Basic information')}>
        <FieldBlock>
          <TextField
            id="prefix"
            label={t('Prefix')}
            value={newPerson.prefix}
            onChange={val => {
              updatePersonState({ newPerson: { ...newPerson, prefix: val } })
            }}
            autoComplete="off"
          />
          <TextField
            id="suffix_name"
            label={t('Suffix')}
            value={newPerson.suffix_name}
            onChange={val => {
              updatePersonState({
                newPerson: { ...newPerson, suffix_name: val },
              })
            }}
            autoComplete="off"
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="first-name"
            label={t('First name')}
            required
            value={newPerson.first_name}
            onChange={val => {
              updatePersonState({
                newPerson: { ...newPerson, first_name: val },
              })
            }}
            autoComplete="off"
          />
          <TextField
            id="middle-name"
            label={t('Middle name')}
            value={newPerson.middle_name}
            onChange={val => {
              updatePersonState({
                newPerson: { ...newPerson, middle_name: val },
              })
            }}
            autoComplete="off"
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="last-name"
            label={t('Last name')}
            required
            value={newPerson.last_name}
            onChange={val => {
              updatePersonState({
                newPerson: { ...newPerson, last_name: val },
              })
            }}
            autoComplete="off"
          />
          <TextField
            id="pronouns"
            label={t('Pronouns')}
            value={newPerson.pronouns}
            onChange={val => {
              updatePersonState({ newPerson: { ...newPerson, pronouns: val } })
            }}
            autoComplete="off"
          />
        </FieldBlock>
        <FieldBlock>
          <SelectField
            id="gender"
            label={t('Select gender')}
            value={newPerson.gender || ''}
            options={genderOptions}
            onSelect={val => {
              updatePersonState({ newPerson: { ...newPerson, gender: val } })
            }}
            autoComplete="off"
          />
          <MultiSelectField
            id="ethnicity-type"
            label={t('Select ethnicity')}
            values={newPerson.ethnicities.map(obj => obj.value)}
            options={ethnicityOptions}
            onSelect={values => {
              updatePersonState({
                newPerson: {
                  ...newPerson,
                  ethnicities: values.map(val => ({ value: val, label: val })),
                },
              })
            }}
            autoComplete="off"
          />
        </FieldBlock>
        <FieldBlock>
          <DateField
            label={t('Date of birth')}
            value={newPerson.birth_date}
            onChange={val => {
              updatePersonState({
                newPerson: {
                  ...newPerson,
                  birth_date: val,
                },
              })
            }}
            autoComplete="off"
          />
        </FieldBlock>
      </Section>
    </form>
  )
}

export default NewPersonFormBasic
