import { useContext, useEffect } from 'react'
import { PhoneBankContext } from '../PhoneBankContext/PhoneBankContext'

const SectionDividerNew = () => {
  const { setPhoneBank } = useContext(PhoneBankContext)

  useEffect(() => {
    setPhoneBank(oldPhoneBank => ({
      ...oldPhoneBank,
      newItem: { type: 'section' },
    }))
  }, [])

  return null
}

export default SectionDividerNew
