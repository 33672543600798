import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TextField } from '@politechdev/blocks-design-system'

const ColumnQuickSearch = ({ columnName, onSearch, value }) => {
  const { t } = useTranslation()

  return (
    <TextField
      className="table-filters__search"
      id="first-col-search"
      type="search"
      label={t(columnName)}
      onChange={onSearch}
      value={value}
    />
  )
}

ColumnQuickSearch.propTypes = {
  columnName: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
}

ColumnQuickSearch.defaultProps = {
  columnName: '',
}

export default ColumnQuickSearch
