import { Icon, TooltipContainer } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import styles from './TurfSelectField.module.scss'

const ArchivedItem = ({ label, disabled }) => {
  const cx = classNames.bind(styles)
  const { t } = useTranslation()

  return (
    <span
      className={cx('item--archived', { 'item--archived--disabled': disabled })}
    >
      <span className={styles['item--archived__icon']}>
        <TooltipContainer label={t('Archived')}>
          <Icon.BoxArchive />
        </TooltipContainer>
      </span>{' '}
      <span>{label}</span>
    </span>
  )
}

export default ArchivedItem
