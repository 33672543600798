import { useState, useEffect } from 'react'

function useTimer() {
  const [secondsElapsed, setSecondsElapsed] = useState(0)
  const [isActive, setIsActive] = useState(false)

  function startTimer() {
    setIsActive(true)
  }

  function stopTimer() {
    setIsActive(false)
  }

  function resetTimer() {
    setSecondsElapsed(0)
    setIsActive(false)
  }

  function restartTimer() {
    setSecondsElapsed(0)
    setIsActive(true)
  }

  useEffect(() => {
    let interval = null
    if (isActive) {
      interval = setInterval(() => {
        setSecondsElapsed(secondsElapsed => secondsElapsed + 1)
      }, 1000)
    } else if (!isActive && secondsElapsed !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isActive, secondsElapsed])

  return { startTimer, stopTimer, resetTimer, secondsElapsed, restartTimer }
}

export default useTimer
