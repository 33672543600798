export const READY = 'ready'
export const CONNECTING = 'connecting'
export const ACTIVE = 'active'
export const REVIEW = 'review'
export const COMPLETE = 'complete'

export const callSteps = ['intro', 'preCall', 'connecting', 'active', 'ended']

export const nonParticipationOptions = [
  { value: 'already_voted', label: 'Already Voted' },
  { value: 'busy', label: 'Busy' },
  { value: 'call_back', label: 'Call back' },
  { value: 'deceased', label: 'Deceased' },
  { value: 'disconnected', label: 'Disconnected' },
  { value: 'do_not_call', label: 'Do not call' },
  { value: 'lang_barrier', label: 'Language barrier' },
  { value: 'left_message', label: 'Left message' },
  { value: 'moved', label: 'Moved' },
  { value: 'not_home', label: 'Not home' },
  { value: 'refused', label: 'Refused', id: 'refused' },
  { value: 'wrong_number', label: 'Wrong number' },
]

// NON_PARTICIPATION_VALUES.VALUE = "value"
export const NON_PARTICIPATION_VALUES = Object.fromEntries(
  Object.values(nonParticipationOptions).map(({ value }) => [
    value.toUpperCase(),
    value,
  ])
)

export const callFields = [
  'id',
  {
    person: [
      'id',
      'first_name',
      'middle_name',
      'last_name',
      'name',
      'primary_phone_number',
      'state',
      'polling_info',
      'gender',
      'languages',
      { contact_methods: ['contact_type', 'content', 'id', 'invalid'] },
    ],
  },
  {
    twilio_call: ['phone_number'],
  },
]
