import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DocumentBrowser from '../DocumentBrowser/DocumentBrowser'

const DocumentRoutes = ({ match }) => (
  <Switch>
    <Route path={match.path} component={DocumentBrowser} />
  </Switch>
)

export default DocumentRoutes
