import { Font } from '@politechdev/blocks-design-system'
import styles from './Count.module.scss'

const Count = ({
  Icon,
  value,
  label,
  accent = false,
  primary = false,
  gray = false,
}) => (
  <div className={styles.container}>
    <div className={styles.icon}>
      <div>
        <Font.Display variant="large">
          {accent && <Icon className={styles.icon__accent} />}
          {primary && <Icon className={styles.icon__primary} />}
          {gray && <Icon className={styles.icon__gray} />}
        </Font.Display>
      </div>
    </div>
    <div className={styles.count}>
      <div className={styles.number}>{value}</div>
      <div className={styles.label}>{label}</div>
    </div>
  </div>
)

export default Count
