import { IdCell, NameCell, TypeCell, StartCell } from './RecordsTableCells'

export default {
  label: 'Import',
  defaultSortOrder: 'asc',
  defaultSortColumn: 'id',
  columns: [
    {
      dataKey: 'id',
      title: 'Row #',
      type: 'number',
      bodyCell: IdCell,
      filterable: false,
    },
    {
      dataKey: 'name',
      title: 'Name',
      type: 'string',
      bodyCell: NameCell,
      filterable: false,
      sortable: false,
    },
    {
      dataKey: 'type',
      title: 'Type',
      type: 'string',
      bodyCell: TypeCell,
      filterable: false,
      sortable: false,
    },
    {
      dataKey: 'start',
      title: '',
      type: 'string',
      bodyCell: StartCell,
      filterable: false,
      sortable: false,
    },
  ],
}
