import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { fetchSentEmails } from 'requests/email'
import tableConfig from './tableConfig'

const OutboxTable = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { renderTable } = useAutoTable()
  const [sentEmails, setSentEmails] = useState([])
  const [numSentEmails, setNumSentEmails] = useState(0)
  const { makeRequest, isLoading, hasErrors } = useRequest(
    params =>
      fetchSentEmails({
        fields: [
          'id',
          'subject',
          'created_at',
          'recipients_count',
          { sender: ['full_name'] },
          {
            list: ['name'],
          },
          {
            team: ['name'],
          },
          {
            event: ['name'],
          },
          {
            template: ['name'],
          },
        ],
        current_page: params?.page,
        per: params?.pageSize,
        sort_attr: params?.sortColumn,
        sort_dir: params?.sortOrder,
        filters: {
          rules: params?.filterRules,
          conjunction: params?.filterConjunction,
        },
        indexed: true,
      }),
    {
      onSuccess: ({ sent_emails, meta: { total_count } }) => {
        setSentEmails(sent_emails)
        setNumSentEmails(total_count)
      },
    }
  )

  const handleView = rowData => {
    history.push(`/email/manage/${rowData.id}`)
  }

  const columns = [
    {
      actions: [createRowAction(t('View'), handleView)],
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return renderTable({
    data: sentEmails,
    loading: isLoading,
    error: hasErrors,
    totalRecords: numSentEmails,
    columns,
    fetchAction: makeRequest,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
  >
    <OutboxTable />
  </AutoTableProvider>
)
