import { useEffect, useState } from 'react'
import { useAutoTable, useFilters } from 'contexts'
import moment from 'moment'
import { areAllFiltersValid } from 'components/DataTable/TableFilters/utils'
import { useRequest } from 'hooks/useRequest'
import { fetchOrganizingReport } from 'requests/organizingReports'
import { formatErrorMessage } from 'utils/formatting'
import { reportTypes } from '../configs'

const OrganizingReportsTable = ({ reportType, dateRange, turfId }) => {
  const {
    isLoading: filterLoading,
    errorMsg: filterErrorMsg,
    filterRules,
  } = useFilters()
  const { renderTable } = useAutoTable()

  const { columns } = reportTypes[reportType]

  const [reportData, setReportData] = useState([])

  const { isLoading, errors, makeRequest } = useRequest(fetchOrganizingReport, {
    onSuccess: data => {
      setReportData(
        data.map((item, index) => ({
          ...item,
          id: index,
        }))
      )
    },
  })
  const errorMsg = formatErrorMessage(errors)

  const requestReportData = () =>
    makeRequest({
      startDate: moment(dateRange.start).format('YYYYMMDD'),
      endDate: moment(dateRange.end).format('YYYYMMDD'),
      turfId,
      reportType,
    })

  useEffect(() => {
    requestReportData()
  }, [dateRange, turfId, reportType])

  if (!areAllFiltersValid(filterRules, columns)) {
    return null
  }

  return renderTable({
    data: reportData,
    loading: isLoading || filterLoading,
    error: errorMsg || filterErrorMsg,
    columns,
    fetchAction: requestReportData,
  })
}

export default OrganizingReportsTable
