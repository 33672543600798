import React, { Component } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import PropTypes from 'prop-types'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { Button } from 'react-md'
import { Section, ContentBlock } from 'components'

class DateRangeSelector extends Component {
  defaultPresets = [
    {
      text: 'Today',
      start: moment(),
      end: moment(),
    },
    {
      text: 'Yesterday',
      start: moment().subtract(1, 'day'),
      end: moment().subtract(1, 'day'),
    },
    {
      text: 'Last 7 Days',
      start: moment().subtract(6, 'day'),
      end: moment(),
    },
    {
      text: 'Last 30 Days',
      start: moment().subtract(29, 'day'),
      end: moment(),
    },
    {
      text: 'This Month',
      start: moment().startOf('month'),
      end: moment().endOf('month'),
    },
    {
      text: 'Last Month',
      start: moment().startOf('month').subtract(1, 'month'),
      end: moment().subtract(1, 'month').endOf('month'),
    },
  ]

  static propTypes = {
    label: PropTypes.string,
    initialStartDate: PropTypes.object,
    initialEndDate: PropTypes.object,
    onDatesChange: PropTypes.func.isRequired,
    customPresets: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        start: PropTypes.object.isRequired,
        end: PropTypes.object.isRequired,
      })
    ),
    defaultPresets: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    label: '',
    initialStartDate: null,
    initialEndDate: null,
    customPresets: null,
    defaultPresets: false,
    className: '',
  }

  state = {
    startDate: this.props.initialStartDate
      ? moment(this.props.initialStartDate)
      : null,
    endDate: this.props.initialEndDate
      ? moment(this.props.initialEndDate)
      : null,
    focusedInput: null,
    activeButton: null,
  }

  onDatesChange = ({ startDate, endDate }) => {
    const { onDatesChange } = this.props
    this.setState({ startDate, endDate })
    onDatesChange && onDatesChange(startDate, endDate)
  }

  onFocusChange = focusedInput => {
    this.setState({ focusedInput })
  }

  renderDatePresets = () => {
    const { customPresets, defaultPresets } = this.props
    const { activeButton } = this.state

    if (!customPresets && !defaultPresets) return null

    let presets = []

    if (defaultPresets) presets = this.defaultPresets
    if (customPresets) presets = [...presets, ...customPresets]

    const presetList = presets.map(({ text, start, end }, i) => (
      <Button
        key={text}
        className={activeButton === i ? 'md-btn--color-secondary-active' : ''}
        secondary
        flat
        onClick={() => {
          this.onDatesChange({ startDate: start, endDate: end })
          this.setState({ activeButton: i })
        }}
      >
        {text}
      </Button>
    ))

    return (
      <Section label="Quick select">
        <ContentBlock>{presetList}</ContentBlock>
      </Section>
    )
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state
    const {
      label,
      clearable,
      className,
      defaultPresets,
      customPresets,
      initialStartDate,
      initialEndDate,
      ...props
    } = this.props

    return (
      <div className={`date-range-selector daterange-dropdown ${className}`}>
        {label && <label className="date-range-selector__label">{label}</label>}
        <DateRangePicker
          {...props}
          minimumNights={0}
          isOutsideRange={() => false}
          renderCalendarInfo={this.renderDatePresets}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          small
          showClearDates={clearable || false}
          showDefaultInputIcon
          noBorder
          hideKeyboardShortcutsPanel
          startDateId="startDateId"
          endDateId="endDateId"
        />
      </div>
    )
  }
}

export default DateRangeSelector
