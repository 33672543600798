export const buildParams = ({ query, filters = [] }) => ({
  filters: {
    rules: [
      query && query !== ''
        ? {
            rules: [
              {
                column: 'full_name',
                operator: 'containing',
                param: query,
              },
            ],
            conjunction: 'exclusive',
          }
        : undefined,
      {
        column: 'archived',
        operator: 'is',
        param: 'false',
      },
      ...filters,
    ],
    conjunction: 'exclusive',
  },
  sort_attr: 'full_name',
  fields: ['id', 'full_name', { turf: ['name', 'voter_registration_enabled'] }],
  associations: ['turf'],
  sort_dir: 'asc',
  per: 10,
  indexed: true,
})
