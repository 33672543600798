import { StateSelectField } from 'components'
import {
  FieldBlock,
  TextField,
  DateField,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const RecordValueDiff = ({
  label,
  diff,
  dupeVal,
  recordVal,
  changeFunc,
  type = 'text',
}) => {
  const { t } = useTranslation()

  const fields = {
    date: (
      <DateField
        label={label}
        value={recordVal ?? dupeVal}
        onChange={changeFunc}
      />
    ),
    state: (
      <StateSelectField
        label={label}
        state={recordVal ?? dupeVal}
        onSelect={changeFunc}
      />
    ),
    zipcode: (
      <TextField
        label={label}
        value={recordVal ?? dupeVal}
        error={recordVal && !recordVal.match(/^\d{5}(-\d{4})?$/)}
        errorMessage={t('Invalid zip code')}
        onChange={changeFunc}
      />
    ),
    phone: (
      <TextField
        label={label}
        type="tel"
        value={recordVal ?? dupeVal}
        onChange={changeFunc}
      />
    ),
    text: (
      <TextField
        label={label}
        value={recordVal ?? dupeVal}
        onChange={changeFunc}
      />
    ),
  }

  if (!diff) {
    return <FieldBlock>{fields[type]}</FieldBlock>
  }

  return <FieldBlock>{fields[type]}</FieldBlock>
}

export default RecordValueDiff
