import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PersonSelectField from 'components/PersonSelectField/PersonSelectField'
import {
  ButtonBlock,
  Button,
  Font,
  Modal,
} from '@politechdev/blocks-design-system'
import CreatePartialPerson from './CreatePartialPerson'
import styles from './FindOrCreatePartialPerson.module.scss'

const FindOrCreatePartialPersonContext = createContext()

const FindOrCreatePartialPerson = ({
  person,
  onSelect,
  languages,
  inModal,
  children,
}) => {
  const [creatingNewPerson, setCreatingNewPerson] = useState(false)

  return creatingNewPerson ? (
    <CreatePartialPerson
      onClose={() => setCreatingNewPerson(false)}
      onCreated={onSelect}
      languages={languages}
    >
      {inModal ? (
        <>
          <Modal.Body>
            <CreatePartialPerson.Body />
          </Modal.Body>
          <Modal.Actions>
            <CreatePartialPerson.Actions />
          </Modal.Actions>
        </>
      ) : undefined}
    </CreatePartialPerson>
  ) : (
    <FindOrCreatePartialPersonContext.Provider
      value={{ person, onSelect, setCreatingNewPerson }}
    >
      {children}
    </FindOrCreatePartialPersonContext.Provider>
  )
}

FindOrCreatePartialPerson.PersonSelector = ({ label }) => {
  const { t } = useTranslation()
  const { person, onSelect, setCreatingNewPerson } = useContext(
    FindOrCreatePartialPersonContext
  )

  return (
    <div className={styles.toolbar}>
      <div className={styles['full-width-block']}>
        <PersonSelectField person={person} onSelect={onSelect} label={label} />
      </div>
      <Font.Copy variant="secondary">{t('or')}</Font.Copy>
      <ButtonBlock>
        <Button.Secondary onClick={() => setCreatingNewPerson(true)}>
          {t('Create person')}
        </Button.Secondary>
      </ButtonBlock>
    </div>
  )
}

export default FindOrCreatePartialPerson
