import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ScriptItem from '../../components/ScriptItem/ScriptItem'
import scriptItemStyles from '../../components/ScriptItem/ScriptItem.module.scss'
import ScriptIntro from '../../components/ScriptItem/Intro/ScriptIntro'

const SessionScript = ({ script, handleResponseChange, disabled }) => {
  const { t } = useTranslation()

  let sectionCounter = 0

  if (!script) return null

  return (
    <div className="phone-bank-script">
      <div className={scriptItemStyles['script-item__container']}>
        {!!script.cause_statement && (
          <>
            <div
              key="intro-title"
              className={scriptItemStyles['script-item__section']}
            >
              <h3 className={scriptItemStyles['script-item__section__header']}>
                {t('Introduction')}
              </h3>
            </div>
            <ScriptIntro content={script.cause_statement} />
          </>
        )}
        {script.script_objects.map((item, i) => {
          if (item.is_section_divider) {
            sectionCounter += 1
          }
          const content =
            item.question?.question_to_ask || item.script_text?.text

          return (
            <ScriptItem
              key={`item-${i}`}
              scriptId={script.id}
              item={item}
              disabled={disabled}
              content={content}
              choices={item.question?.answer_options}
              sectionNumber={sectionCounter}
              setResponse={handleResponseChange}
              disableEditing
            />
          )
        })}
      </div>
    </div>
  )
}

SessionScript.propTypes = {
  script: PropTypes.object.isRequired,
}

export default SessionScript
