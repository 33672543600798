import React from 'react'
import { useTranslation } from 'react-i18next'
import { Map } from 'components'
import { Button } from '@politechdev/blocks-design-system'
import { useLocationMap } from '../LocationMapProvider/LocationMapProvider'
import styles from './LocationMapPopup.module.scss'

const LocationMapPopup = () => {
  const { t } = useTranslation()
  const { activeLocation, clearActiveLocation } = useLocationMap()

  if (!activeLocation) return null

  return (
    <Map.Popup
      title={activeLocation.name}
      latitude={activeLocation.latitude}
      longitude={activeLocation.longitude}
      onClose={clearActiveLocation}
    >
      <a
        href={encodeURI(
          `https://maps.google.com/maps?saddr=&daddr=${[
            activeLocation.street_address,
            activeLocation.city,
            activeLocation.state,
            activeLocation.zipcode,
          ].join(' ')}`
        )}
        target="_blank"
        rel="noreferrer"
      >
        <Button.Accent className={styles.center}>
          {t('Get directions')}
        </Button.Accent>
      </a>
    </Map.Popup>
  )
}

export default LocationMapPopup
