import { calculatePercent, summaryFunctions } from 'utils/reporting'

export default {
  phone_bank: {
    columns: [
      {
        dataKey: 'phone_bank_name',
        title: 'Phone Bank',
        type: 'string',
      },
      {
        dataKey: 'start_date',
        title: 'Phone Bank start date',
        type: 'date',
      },
      {
        dataKey: 'end_date',
        title: 'Phone Bank end date',
        type: 'date',
      },
      {
        dataKey: 'current_round',
        title: 'Current round',
        type: 'number',
      },
      {
        dataKey: 'total_callers',
        title: 'Total callers',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'total_people',
        title: 'Total contacts',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'total_sessions',
        title: 'Total sessions',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'total_called',
        title: 'Total called',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'percent_attempted',
        title: '% contacts attempted',
        type: 'number',
        deriveFunction: ({ rowData }) =>
          calculatePercent(rowData.total_called, rowData.total_people),
        summaryFunction: ({ data, acc }) =>
          summaryFunctions.customPercent(data, acc, [
            'total_called',
            'total_people',
          ]),
      },
      {
        dataKey: 'total_contacted',
        title: 'Total contacted',
        type: 'number',
        summaryFunction: summaryFunctions.sum,
      },
      {
        dataKey: 'percent_contacted',
        title: '% contacted',
        type: 'number',
        deriveFunction: ({ rowData }) =>
          calculatePercent(rowData.total_contacted, rowData.total_people),
        summaryFunction: ({ data, acc }) =>
          summaryFunctions.customPercent(data, acc, [
            'total_contacted',
            'total_people',
          ]),
      },
    ],
  },
}
