import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { Button } from 'components'
import { useReactRouter } from 'hooks'
import { useTranslation } from 'react-i18next'
import { getName } from '../../utils'

export const IdCell = ({ rowData }) => (
  <TableGridCell>{rowData?.id}</TableGridCell>
)

export const NameCell = ({ rowData }) => {
  const record = rowData?.row_data
  return <TableGridCell>{`${record ? getName(record) : ''}`}</TableGridCell>
}

const StartCellContent = ({ rowData }) => {
  const { match } = useReactRouter()
  const { t } = useTranslation()
  return (
    <Link
      to={`/data_sources/imports/${match.params.importId}/duplicate_prevention/${rowData.id}`}
    >
      <Button raised primary>
        {t('Review')}
      </Button>
    </Link>
  )
}

export const StartCell = ({ rowData }) => (
  <TableGridCell>
    <StartCellContent rowData={rowData} />
  </TableGridCell>
)

const TypeCellContent = ({ isDuplicate }) => {
  const { t } = useTranslation()
  return (
    <span>{`${t(
      isDuplicate ? 'Duplicate found' : 'Other issue with row'
    )}`}</span>
  )
}

export const TypeCell = ({ rowData }) => {
  const isDuplicate = rowData.duplicate_found
  return (
    <TableGridCell>
      <TypeCellContent isDuplicate={isDuplicate} />
    </TableGridCell>
  )
}
