import { useEffect, useState } from 'react'
import {
  FieldBlock,
  Button,
  SelectField,
  TextField,
  ButtonBlock,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { ZipCodeField } from 'components'
import {
  fetchAddressSuggestions,
  fetchAddressOptions,
} from 'components/AddressValidator/blocksApiHelpers'
import useEvent from 'hooks/useEvent'
import { useRequest } from 'hooks/useRequest'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce/lib'
import countiesByState from 'utils/counties.json'
import { US_STATES } from 'utils/constants'
import { get } from 'lodash'
import { createAttributeMap, getCopyFromData } from '../utils'

const MODES = {
  COPIED: 'COPIED',
  SEARCH: 'SEARCH',
  FIELDS: 'FIELDS',
}

const stateOptions = Object.keys(US_STATES).map(key => ({
  label: US_STATES[key],
  value: key,
}))

const smartyStreetsResponseToLabel = suggestion => {
  let whiteSpace = ''
  if (suggestion.secondary) {
    if (suggestion.entries > 1) {
      suggestion.secondary += ` (${suggestion.entries} entries)`
    }
    whiteSpace = ' '
  }
  return `${suggestion.street_line + whiteSpace + suggestion.secondary} ${
    suggestion.city
  }, ${suggestion.state} ${suggestion.zipcode}`
}

const AddressLookup = ({
  section,
  group,
  field,
  formConfig,
  attributes = [],
  getField,
  fieldSetter,
  fieldErrors,
  isEditing,
}) => {
  const { t } = useTranslation()
  const [fieldMap, setFieldMap] = useState({})
  const { copyMap, copiedSectionLabel, copiedFieldLabel } = getCopyFromData(
    section,
    field,
    formConfig
  )
  const copyableId = field.attribute.replace('.', '_')
  const hasCopyableFields = !!copyMap && !!Object.entries(copyMap).length

  const setFieldIfExists = fieldKey => {
    const attribute = fieldMap[fieldKey]
    if (attribute) {
      return fieldSetter(attribute)
    }
    return () => {}
  }

  useEffect(() => {
    setFieldMap(createAttributeMap(attributes))
  }, [attributes])

  const defaultMode = isEditing ? MODES.FIELDS : MODES.SEARCH
  const fieldMode =
    getField(`meta.${field.attribute}.field_mode`) || defaultMode

  const setFieldMode = value => {
    if (value !== MODES.FIELDS) {
      setFieldIfExists('street_address_one')('')
      setFieldIfExists('street_address_two')('')
      setFieldIfExists('city')('')
      setFieldIfExists('state')('')
      setFieldIfExists('zipcode')('')
      setFieldIfExists('county')('')
    }
    fieldSetter(`meta.${field.attribute}.field_mode`)(value)
  }

  const handleCopyToggle = shouldCopy => {
    setFieldMode(shouldCopy ? MODES.COPIED : MODES.SEARCH)
    const copyPairs = Object.entries(copyMap)
    fieldSetter(`meta.copiedFields.${copyableId}`)({
      copyPairs,
      shouldCopy,
    })
  }

  const [suggestions, setSuggestions] = useState([])

  const toggleAddressMode = () => {
    if (fieldMode === MODES.SEARCH) {
      setFieldMode(MODES.FIELDS)
    } else {
      setFieldMode(MODES.SEARCH)
    }
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest, isLoading } = useRequest(fetchAddressSuggestions, {
    onSuccess: newSuggestions => {
      setSuggestions(
        newSuggestions.map(suggestion => ({
          ...suggestion,
          label: smartyStreetsResponseToLabel(suggestion),
        }))
      )
    },
  })

  const [debouncedSearch] = useDebouncedCallback(makeRequest, 500, {
    maxWait: 700,
  })

  const handleStreetAddressChange = useEvent(value => {
    if (!value) return

    debouncedSearch({
      fields: {
        search: value,
      },
    })
  })

  // eslint-disable-next-line blocks/missing-response-error
  const getCounty = useRequest(
    ({ street_line, city, state, zipcode }) =>
      fetchAddressOptions({
        fields: {
          street: street_line,
          city,
          state,
          zipcode,
        },
      }),
    {
      onSuccess: response => {
        const match = response?.candidates[0]
        if (match) {
          setFieldIfExists('county')(match.metadata.county_name)
        }
      },
    }
  )

  const handleStreetAddressSelect = useEvent(async value => {
    if (!value) {
      setSuggestions([])
      return
    }
    setFieldIfExists('street_address_one')(value.street_line)
    setFieldIfExists('street_address_two')(value.secondary)
    setFieldIfExists('city')(value.city)
    setFieldIfExists('state')(value.state)
    setFieldIfExists('zipcode')(value.zipcode)
    await getCounty.makeRequest(value)
    setFieldMode(MODES.FIELDS)
  })

  const hasFieldError = Object.values(fieldMap).some(
    attribute => !!get(fieldErrors, attribute)
  )

  return (
    <>
      {hasCopyableFields ? (
        <FieldBlock>
          <Checkbox
            variant="large"
            label={t('Same as {{section}} - {{field}}', {
              section: copiedSectionLabel,
              field: copiedFieldLabel,
            })}
            checked={fieldMode === MODES.COPIED}
            onChange={handleCopyToggle}
          />
        </FieldBlock>
      ) : null}
      {fieldMode === MODES.SEARCH && (
        <FieldBlock>
          <SelectField
            name="street-address"
            id={group ? `${group}-street-address` : 'street-address'}
            label={t('Search address')}
            options={suggestions.map(suggestion => ({
              label: suggestion.label,
              value: suggestion,
            }))}
            loading={isLoading || getCounty.isLoading}
            onInputChange={handleStreetAddressChange}
            onSelect={handleStreetAddressSelect}
            emptyMessage={t('No addresses found')}
            clearable
            icon="Search"
            error={hasFieldError}
            errorMessage={t('Invalid field')}
            autoFilterOptions={false}
          />
          <Button.Secondary onClick={toggleAddressMode}>
            {t('Enter manually')}
          </Button.Secondary>
        </FieldBlock>
      )}
      {fieldMode === MODES.FIELDS && (
        <>
          <ButtonBlock>
            <Button.Secondary onClick={toggleAddressMode}>
              {t('Use address autocomplete')}
            </Button.Secondary>
          </ButtonBlock>
          {fieldMap.street_address_one ? (
            <FieldBlock variant="large">
              <TextField
                type="text"
                name="street"
                id="street"
                label={t('Address')}
                value={getField(fieldMap.street_address_one)}
                onChange={fieldSetter(fieldMap.street_address_one)}
                error={!!get(fieldErrors, fieldMap.street_address_one)}
                errorMessage={get(fieldErrors, fieldMap.street_address_one)}
              />
            </FieldBlock>
          ) : null}
          {fieldMap.street_address_two ? (
            <FieldBlock variant="large">
              <TextField
                type="text"
                name="street_two"
                id="street_two"
                label={t('Address line 2')}
                value={getField(fieldMap.street_address_two)}
                onChange={fieldSetter(fieldMap.street_address_two)}
                error={!!get(fieldErrors, fieldMap.street_address_two)}
                errorMessage={get(fieldErrors, fieldMap.street_address_two)}
              />
            </FieldBlock>
          ) : null}
          <FieldBlock>
            {fieldMap.city ? (
              <TextField
                type="text"
                name="city"
                id="city"
                label={t('City')}
                value={getField(fieldMap.city)}
                onChange={fieldSetter(fieldMap.city)}
                error={!!get(fieldErrors, fieldMap.city)}
                errorMessage={get(fieldErrors, fieldMap.city)}
              />
            ) : null}
            {fieldMap.state ? (
              <SelectField
                label={t('State')}
                value={getField(fieldMap.state)}
                onSelect={value => {
                  fieldSetter(fieldMap.state)(value)
                  fieldSetter(fieldMap.county)(null)
                }}
                options={stateOptions}
                clearable
                onClear={() => {
                  fieldSetter(fieldMap.state)(null)
                  fieldSetter(fieldMap.county)(null)
                }}
                error={!!get(fieldErrors, fieldMap.state)}
                errorMessage={get(fieldErrors, fieldMap.state)}
              />
            ) : null}
            {fieldMap.zipcode ? (
              <ZipCodeField
                value={getField(fieldMap.zipcode)}
                onChange={fieldSetter(fieldMap.zipcode)}
                error={!!get(fieldErrors, fieldMap.zipcode)}
                errorMessage={get(fieldErrors, fieldMap.zipcode)}
              />
            ) : null}
          </FieldBlock>
          {fieldMap.county ? (
            <FieldBlock>
              <SelectField
                label={t('County')}
                disabled={!countiesByState[getField(fieldMap.state)]}
                options={(countiesByState[getField(fieldMap.state)] || []).map(
                  county => ({
                    value: county,
                    label: county,
                  })
                )}
                value={getField(fieldMap.county)}
                onSelect={fieldSetter(fieldMap.county)}
                error={!!get(fieldErrors, 'county')}
                errorMessage={get(fieldErrors, 'county')}
                clearable
                onClear={() => fieldSetter(fieldMap.county)(null)}
              />
            </FieldBlock>
          ) : null}
        </>
      )}
    </>
  )
}

export default AddressLookup
