import { useEffect, useRef, useState } from 'react'

const Sticky = ({ className, stuckClassName, children, offset }) => {
  const [isStuck, setStuck] = useState(false)
  const containerRef = useRef(null)
  const offsetRef = useRef(offset ? offset + 1 : undefined)

  useEffect(() => {
    if (containerRef.current && offsetRef.current === undefined) {
      offsetRef.current = containerRef.current.getBoundingClientRect().top
    }
  }, [])

  const handleScroll = () => {
    containerRef.current &&
      setStuck(
        containerRef.current.getBoundingClientRect().top < offsetRef.current
      )
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [])

  return (
    <div
      ref={containerRef}
      className={`${className} ${isStuck ? stuckClassName : ''}`}
    >
      {children}
    </div>
  )
}

export default Sticky
