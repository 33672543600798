import { useState, useEffect } from 'react'

export function useQuestionChoices(choices) {
  const [choicesInState, setChoicesInState] = useState(
    (choices || [])
      .map(choice => ({ label: choice.response, value: choice.id }))
      .sort((a, b) => a.value - b.value)
  )

  useEffect(() => {
    setChoicesInState(
      (choices || [])
        .map(choice => ({
          label: choice.response,
          value: choice.id,
        }))
        .sort((a, b) => a.value - b.value)
    )
  }, [choices])

  return [choicesInState, setChoicesInState]
}
