import { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class ScrollTo extends PureComponent {
  static propTypes = {
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    scrollOptions: PropTypes.exact({
      top: PropTypes.number,
      left: PropTypes.number,
      behavior: PropTypes.oneOf(['smooth', 'auto']),
    }),
    doScroll: PropTypes.bool,
  }

  static defaultProps = {
    x: window.scrollX,
    y: window.scrollY,
    scrollOptions: null,
    doScroll: false,
  }

  componentDidMount() {
    const { x, y, scrollOptions } = this.props

    this.scroll(x, y, scrollOptions)
  }

  componentDidUpdate(prevProps) {
    const { x, y, scrollOptions, doScroll } = this.props
    if (
      prevProps.x !== x ||
      prevProps.y !== y ||
      prevProps.scrollOptions !== scrollOptions ||
      (prevProps.doScroll !== doScroll && doScroll)
    ) {
      this.scroll(x, y, scrollOptions)
    }
  }

  scroll = (x, y, scrollOptions) => {
    if (!window) return null

    return scrollOptions
      ? window.scrollTo(scrollOptions)
      : window.scrollTo(x, y)
  }

  render() {
    return null
  }
}
