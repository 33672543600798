import moment from 'moment'
import {
  UserNameCell,
  UserRoleNameCell,
  UserTurfNameCell,
  UserLastSeenCell,
} from './UserTableCells'
import {
  userRoleNameCsvCell,
  userTurfNameCsvCell,
  userLastSeenCsvCell,
} from './userCsvCells'

export default {
  label: 'users',
  columns: [
    {
      dataKey: 'full_name',
      title: 'User',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: UserNameCell,
    },
    {
      dataKey: 'email',
      title: 'Email',
      type: 'string',
      autoResize: true,
      resizable: true,
    },
    {
      dataKey: 'role_name',
      title: 'Role',
      type: 'string',
      bodyCell: UserRoleNameCell,
      csvCell: userRoleNameCsvCell,
    },
    {
      dataKey: 'turf_name',
      title: 'Turf',
      type: 'string',
      bodyCell: UserTurfNameCell,
      csvCell: userTurfNameCsvCell,
    },
    {
      dataKey: 'last_sign_in_at',
      title: 'Last seen',
      type: 'date',
      filterConstructor: rule => ({
        ...rule,
        param: moment(rule.param).format('YYYY-MM-DDT00:00:00'),
      }),
      bodyCell: UserLastSeenCell,
      csvCell: userLastSeenCsvCell,
    },
  ],
}
