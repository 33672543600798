import moment from 'moment'
import { formatDecimalCell } from 'utils/reporting'
import packetConfig from './reports/packet'
import canvasserQualityByGroupConfig from './reports/canvasserQualityByGroup'
import canvasserQualityByGroupTabletConfig from './reports/canvasserQualityByGroupTablet'
import canvasserQualityByGroupPartyConfig from './reports/canvasserQualityByGroupParty'
import canvasserQualityConfig from './reports/canvasserQuality'
import canvasserQualityTabletConfig from './reports/canvasserQualityTablet'
import canvasserQualityPartyConfig from './reports/canvasserQualityParty'
import countyTabletConfig from './reports/countyTablet'
import locationTabletConfig from './reports/locationTablet'
import countyConfig from './reports/county'
import locationConfig from './reports/location'
import locationPartyConfig from './reports/locationParty'
import { columnSummaryFunctions } from './columnTotals'
import { REGISTRATION_REPORTS_ALL_TIME_START_DATE } from '../constants'
import validatedFormsByTurf from './reports/validatedFormsByTurf'
import validatedFormsByCanvasser from './reports/validatedFormsByCanvasser'
import qcByCanvasserConfig from './reports/qcByCanvasser'
import qcByGroupConfig from './reports/qcByGroup'
import qcStatusConfig from './reports/qcStatus'
import qcByVisualResponseConfig from './reports/qcByVisualResponse'
import duplicateApplicantsByCanvasserConfig from './reports/duplicateApplicantsByCanvasser'
import partyByTurfConfig from './reports/partyByTurf'
import partyByCanvasserConfig from './reports/partyByCanvasser'
import partyLocationConfig from './reports/partyByLocation'
import partyLeanByTurfConfig from './reports/partyLeanByTurf'
import partyLeanByCanvasserConfig from './reports/partyLeanByCanvasser'
import partyLeanByLocationConfig from './reports/partyLeanByLocation'
import districtByTurfConfig from './reports/districtByTurf'
import districtByCanvasserConfig from './reports/districtByCanvasser'
import districtByLocationConfig from './reports/districtByLocation'
import partisanScoreByTurfConfig from './reports/partisanScoreByTurf'
import partisanScoreByCanvasserConfig from './reports/partisanScoreByCanvasser'
import partisanScoreByLocationConfig from './reports/partisanScoreByLocation'

export const reportCategories = [
  {
    id: 'general',
    name: 'General',
    reportTypes: ['packet'],
    permissionId: 'registration_general',
  },
  {
    id: 'coverSheet',
    name: 'Field',
    reportTypes: [
      'canvasser_quality_by_group',
      'canvasser_quality',
      'county',
      'location',
    ],
    permissionId: 'cover_sheet',
  },
  {
    id: 'coverSheetTablet',
    name: 'Field (tablet)',
    reportTypes: [
      'canvasser_quality_by_group_tablet',
      'canvasser_quality_tablet',
      'county_tablet',
      'location_tablet',
    ],
    permissionId: 'cover_sheet_tablet',
  },
  {
    id: 'coverSheetParty',
    name: 'Field (partisan)',
    reportTypes: [
      'canvasser_quality_by_group_party',
      'canvasser_quality_party',
      'county',
      'location_party',
    ],
    permissionId: 'cover_sheet_party_report',
  },
  {
    id: 'qcProgram',
    name: 'Program',
    reportTypes: [
      'qc_status',
      'qc_by_canvasser',
      'qc_by_group',
      'qc_by_visual_response',
    ],
    permissionId: 'qc_program',
  },
  {
    id: 'validation',
    name: 'Verification',
    reportTypes: [
      'validated_forms_by_turf',
      'validated_forms_by_canvasser',
      'duplicate_applicants_by_canvasser',
    ],
    isSelectable: false,
    permissionId: 'form_validation',
  },
  {
    id: 'party',
    name: 'Party Preference',
    reportTypes: ['party_by_turf', 'party_by_canvasser', 'party_by_location'],
    permissionId: 'party_report',
  },
  {
    id: 'party_lean',
    name: 'Party Lean',
    reportTypes: [
      'party_lean_by_turf',
      'party_lean_by_canvasser',
      'party_lean_by_location',
    ],
    permissionId: 'party_lean_report',
  },
  {
    id: 'district',
    name: 'District',
    reportTypes: [
      'district_by_turf',
      'district_by_canvasser',
      'district_by_location',
    ],
    permissionId: 'district_report',
  },
  {
    id: 'partisan_score',
    name: 'Partisan Score',
    reportTypes: [
      'partisan_score_by_turf',
      'partisan_score_by_canvasser',
      'partisan_score_by_location',
    ],
    permissionId: 'partisan_score_report',
  },
]

export const permissionIds = reportCategories.map(
  category => category.permissionId
)

const prepareReportTypes = () => {
  const reportConfigs = [
    packetConfig,
    canvasserQualityByGroupConfig,
    canvasserQualityByGroupTabletConfig,
    canvasserQualityByGroupPartyConfig,
    canvasserQualityConfig,
    canvasserQualityTabletConfig,
    canvasserQualityPartyConfig,
    locationTabletConfig,
    countyConfig,
    locationConfig,
    locationPartyConfig,
    validatedFormsByTurf,
    validatedFormsByCanvasser,
    qcByCanvasserConfig,
    qcByGroupConfig,
    qcStatusConfig,
    qcByVisualResponseConfig,
    countyTabletConfig,
    duplicateApplicantsByCanvasserConfig,
    partyByTurfConfig,
    partyByCanvasserConfig,
    partyLocationConfig,
    partyLeanByTurfConfig,
    partyLeanByCanvasserConfig,
    partyLeanByLocationConfig,
    districtByTurfConfig,
    districtByCanvasserConfig,
    districtByLocationConfig,
    partisanScoreByTurfConfig,
    partisanScoreByCanvasserConfig,
    partisanScoreByLocationConfig,
  ]

  return reportConfigs.map(reportConfig => ({
    ...reportConfig,
    columns: reportConfig.columns.map(column => ({
      ...column,
      bodyCell:
        column.type === 'number' && !column.bodyCell
          ? formatDecimalCell
          : column.bodyCell,
      summaryFunction:
        column.summaryFunction || columnSummaryFunctions[column.dataKey],
    })),
  }))
}

const customDatePresets = [
  {
    text: 'Total',
    start: moment(REGISTRATION_REPORTS_ALL_TIME_START_DATE),
    end: moment(),
  },
  {
    text: 'Year to Date',
    start: moment().startOf('year'),
    end: moment(),
  },
]

const defaultReportType = 'packet'

export default {
  reportTypes: prepareReportTypes(),
  reportCategories,
  customDatePresets,
  defaultReportType,
}
