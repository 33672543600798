import React, { useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ResponsibilitySelectFieldDEPRECATED } from 'components'
import useEvent from 'hooks/useEvent'
import { usePrevious } from 'hooks/index'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import RelativeDateRangeField from '../RelativeDateRangeField/RelativeDateRangeField'
import { ANY_DATE } from '../RelativeDateRangeField/utils'
import styles from './EventResponsibilitiesField.module.scss'
import {
  COLUMN_WITHOUT_DATE,
  COLUMN_WITH_DATE,
  END_DATE_INDEX,
  RESPONSIBILITY_INDEX,
  START_DATE_INDEX,
} from './constants'

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const formatStartTime = moment => moment.format('YYYY-MM-DD')

const formatEndTime = moment => moment.format('YYYY-MM-DD')

const getRules = filter => filter.value?.rules || []

const getParams = filter => {
  const isAnyDate = filter?.meta?.dateType === ANY_DATE
  const params = isAnyDate
    ? getRules(filter).find(rule => rule?.column === COLUMN_WITHOUT_DATE)
        ?.param || []
    : getRules(filter).find(rule => rule?.column === COLUMN_WITH_DATE)
        ?.param || [
        [],
        formatStartTime(moment()),
        formatEndTime(moment()),
        timezone,
      ]

  const shouldAddTimeZone = !isAnyDate && params.length === 3
  return shouldAddTimeZone ? [...params, timezone] : params
}
const getRoles = filter =>
  filter?.meta?.dateType === ANY_DATE
    ? getParams(filter)
    : getParams(filter)[RESPONSIBILITY_INDEX]
const getStart = filter =>
  moment(
    filter?.meta?.dateType === ANY_DATE
      ? formatEndTime(moment())
      : getParams(filter)[START_DATE_INDEX]
  )
const getEnd = filter =>
  moment(
    filter?.meta?.dateType === ANY_DATE
      ? formatEndTime(moment())
      : getParams(filter)[END_DATE_INDEX]
  )

const EventResponsibilityField = ({ filter, filterType }) => {
  const { setFilterValue } = useListFilters()
  const { t } = useTranslation()

  const prevFilter = usePrevious(filter)
  useEffect(() => {
    const prevDateType = prevFilter?.meta?.dateType
    const dateType = filter?.meta?.dateType

    const isAnAny = prevDateType === ANY_DATE || dateType === ANY_DATE
    const changingFromAny = isAnAny && dateType !== ANY_DATE
    if (changingFromAny) {
      const roles = getRoles(prevFilter)
      const rules = getRules(filter).filter(
        rule =>
          rule.column !== COLUMN_WITH_DATE &&
          rule.column !== COLUMN_WITHOUT_DATE
      )
      const param = [...getParams(filter)]
      param[0] = roles
      rules.push({
        column: COLUMN_WITH_DATE,
        param,
      })
      setFilterValue(filter, { rules })
    }
  }, [filter?.meta?.dateType])

  const updateRoles = responsibilities => {
    const rules = getRules(filter).filter(
      rule =>
        rule.column !== COLUMN_WITH_DATE && rule.column !== COLUMN_WITHOUT_DATE
    )

    let param = []

    if (filter?.meta?.dateType === ANY_DATE) {
      param = responsibilities
      rules.push({
        column: COLUMN_WITHOUT_DATE,
        operator: 'in',
        param,
      })
    } else {
      param = [...getParams(filter)]
      param[0] = responsibilities
      rules.push({
        column: COLUMN_WITH_DATE,
        param,
      })
    }

    setFilterValue(filter, {
      rules,
    })
  }

  const updateDate = ([startTime, endTime]) => {
    const param = [...getParams(filter)]

    param[1] = startTime && formatStartTime(startTime)
    param[2] = endTime ? formatEndTime(endTime) : getEnd(filter)

    return [...param.slice(0, 3), timezone]
  }

  const buildAnyRule = useEvent(() => ({
    column: COLUMN_WITHOUT_DATE,
    operator: 'in',
    param: getRoles(filter),
  }))

  return (
    <div className={styles.field}>
      <ResponsibilitySelectFieldDEPRECATED
        label={t(filterType.name)}
        isMulti
        responsibilities={
          Array.isArray(getRoles(filter)) ? getRoles(filter) : []
        }
        onSelect={updateRoles}
        usePortal
      />
      <RelativeDateRangeField
        filter={filter}
        filterType={filterType}
        columns={[COLUMN_WITH_DATE]}
        buildColumnValue={updateDate}
        getStart={getStart}
        getEnd={getEnd}
        includeAny
        anyColumns={[COLUMN_WITHOUT_DATE]}
        buildAnyRule={buildAnyRule}
      />
    </div>
  )
}

export default EventResponsibilityField
