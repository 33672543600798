import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { useDebouncedCallback } from 'use-debounce'
import {
  View,
  ViewContainer,
  Uploader,
  LocationSelectField,
  CampaignSelectField,
  OrganizationSelectField,
  EventTypeSelectField,
  TeamSelectField,
} from 'components'
import {
  Sheet,
  DateField,
  ProgressBar,
  TextField,
  Section,
  Button,
  ButtonBlock,
  FieldBlock,
  Checkbox,
  Icon,
  Font,
  Switch,
  TimePicker,
  TextArea,
  useToast,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { LOCATION_TYPES } from 'constants/locations'
import { useLocalForm, useReactRouter, useRequest } from 'hooks'
import { fetchEvent, fetchEvents, putEvent } from 'requests/events'
import styles from './EventEditForm.module.scss'

const getISODateTime = ({ date, time }) => {
  const formattedDateTime = `${moment(date).format('YYYY-MM-DD')}${moment(
    time
  ).format('THH:mm:ssZ')}`

  return formattedDateTime
}

const EventEditForm = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const { setToast } = useToast()

  const { formData, setField, setFormData } = useLocalForm()
  const [timeError, setTimeError] = useState(false)
  const [eventDate, setEventDate] = useState(moment(formData.start_time))

  useEffect(() => {
    const { start_time, end_time } = formData
    setTimeError(false)
    if (
      moment(getISODateTime({ time: start_time })).isAfter(
        moment(getISODateTime({ time: end_time }))
      )
    ) {
      setTimeError(true)
    }
  }, [formData])

  const fetchEventRequest = useRequest(fetchEvent, {
    onSuccess: response => {
      setFormData({
        ...response.event,
        type: response.event.type,
        public_settings: response.event.public_settings?.extra_fields || [],
        publicEventSettingsHasOpenField: (
          response.event.public_settings?.extra_fields || []
        ).includes('other'),
        publicEventSettingsOpenFieldLabel:
          response.event.public_settings?.other_field_label,
        public_page_header_file_locator:
          response.event.public_page_header_file_locator?.data,
        postRegistrationMessage:
          response.event.public_settings?.post_registration_message,
        publicEventTitle: response.event.public_settings?.title,
      })
      setEventDate(moment(response.event.start_time).toISOString())
    },
    onError: () => {
      setToast({
        variant: 'error',
        message: t('Failed to get event'),
      })
    },
  })

  const putEventRequest = useRequest(putEvent, {
    onSuccess: () => {
      history.push(`/build/events/${match.params.id}`)
    },
    onError: () => {
      setToast({
        variant: 'error',
        message: t('Failed to update event'),
      })
    },
  })

  useEffect(() => {
    fetchEventRequest.makeRequest(match.params.id, {
      fields: [
        'start_time',
        'end_time',
        'name',
        'description',
        'notes',
        'public',
        'type',
        'public_settings',
        'url',
        'url_label',
        { location: ['id', 'name'] },
        { organization: ['id', 'name'] },
        { coorganizations: ['id', 'name'] },
        { teams: ['id', 'name'] },
        { campaign: ['id', 'name'] },
      ],
    })
  }, [])

  const updateSettings = (checked, field) => {
    if (checked) {
      setField([...formData.public_settings, field], 'public_settings')
    } else {
      setField(
        formData.public_settings.filter(setting => setting !== field),
        'public_settings'
      )
    }
  }

  const handleSave = async () => {
    const {
      start_time,
      end_time,
      location,
      campaign,
      organization,
      coorganizations,
      teams,
      public_settings,
      publicEventTitle,
      publicEventSettingsHasOpenField,
      publicEventSettingsOpenFieldLabel,
      postRegistrationMessage,
    } = formData

    putEventRequest.makeRequest(match.params.id, {
      ...formData,
      start_time: getISODateTime({ date: eventDate, time: start_time }),
      end_time: getISODateTime({ date: eventDate, time: end_time }),
      location_id: location === null ? null : location.id,
      campaign_id: campaign?.id,
      organization_id: organization?.id,
      coorganization_ids: coorganizations?.map(o => o.id),
      team_ids: teams?.map(t => t.id),
      public_settings: {
        extra_fields: publicEventSettingsHasOpenField
          ? [...public_settings, 'other'].filter(
              (val, index) =>
                [...public_settings, 'other'].findIndex(
                  potentialVal => potentialVal === val
                ) === index
            )
          : public_settings.filter(val => val !== 'other'),
        other_field_label: publicEventSettingsHasOpenField
          ? publicEventSettingsOpenFieldLabel
          : undefined,
        post_registration_message: postRegistrationMessage,
        title: publicEventTitle,
      },
    })
  }

  const handleUpload = fileData => {
    setField(fileData, 'public_page_header_file_locator')
    return Promise.resolve()
  }

  const fetchEventsRequest = useRequest(fetchEvents, {
    onError: () => {
      setToast({
        variant: 'error',
        message: t('Failed to search event names'),
      })
    },
  })

  const [debouncedfetchEventsReq] = useDebouncedCallback(
    fetchEventsRequest.makeRequest,
    500
  )

  useEffect(() => {
    if (!formData.name || !fetchEventRequest.response) return

    debouncedfetchEventsReq({
      indexed: true,
      filters: {
        rules: [
          {
            column: 'name',
            operator: 'is',
            param: formData.name,
          },
          {
            column: 'turf_id',
            operator: 'is',
            param: fetchEventRequest.response.event.turf_id,
          },
          {
            column: 'id',
            operator: 'is',
            param: fetchEventRequest.response.event.id,
            invert: true,
          },
        ],
      },
    })
  }, [formData.name])

  const isEventNameTaken = fetchEventsRequest.response?.meta.total_count > 0

  const isFormValid =
    formData.name &&
    formData.type &&
    formData.organization &&
    formData.start_time &&
    formData.end_time &&
    !timeError &&
    eventDate &&
    !isEventNameTaken

  return (
    <View>
      <ViewContainer
        loading={fetchEventRequest.isLoading || putEventRequest.isLoading}
      >
        <Sheet className={styles['event-form']}>
          <PageHeader title={t('Edit event')}>
            <ButtonBlock justify="right">
              <Link to={`/build/events/${match.params.id}`}>
                <Button.Secondary>
                  <Icon.Times />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </PageHeader>
          <FieldBlock>
            <TextField
              label={t('Event name')}
              id="eventName"
              value={formData.name}
              onChange={name => setField(name, 'name')}
              required
              error={isEventNameTaken}
              errorMessage={
                isEventNameTaken ? 'Name has already been taken' : 'Required'
              }
              loading={fetchEventsRequest.isLoading}
            />
            <EventTypeSelectField
              id="eventType"
              name="Event type"
              label={t('Event type')}
              eventType={formData.type}
              required
              onSelect={type => setField(type, 'type')}
            />
          </FieldBlock>
          <FieldBlock>
            <DateField
              label={t('Event day')}
              value={eventDate}
              onChange={val => {
                if (val === '') {
                  setEventDate('')
                } else {
                  setEventDate(val)
                }
              }}
              required
            />
          </FieldBlock>
          <FieldBlock>
            {eventDate && (
              <TimePicker
                label="Start Time"
                id="event-start-time"
                onSelect={val => {
                  setField(
                    moment(eventDate)
                      .hour(val.hour)
                      .minute(val.minute)
                      .second(0)
                      .format(),
                    'start_time'
                  )
                }}
                hour={formData.start_time && moment(formData.start_time).hour()}
                minute={
                  formData.start_time && moment(formData.start_time).minute()
                }
                required
                error={timeError}
                errorMessage={t('Start time must be before end time')}
              />
            )}
            {eventDate && (
              <TimePicker
                label="End Time"
                id="event-end-time"
                onSelect={val => {
                  setField(
                    moment(eventDate)
                      .hour(val.hour)
                      .minute(val.minute)
                      .second(0)
                      .format(),
                    'end_time'
                  )
                }}
                hour={formData.end_time && moment(formData.end_time).hour()}
                minute={formData.end_time && moment(formData.end_time).minute()}
                required
              />
            )}
          </FieldBlock>
          <FieldBlock>
            <OrganizationSelectField
              label={t('Organization')}
              onSelect={val => {
                setField(val, 'organization')
                setField(
                  formData.coorganizations?.filter(co => co.id !== val?.id),
                  'coorganizations'
                )
              }}
              organization={formData.organization}
              required
            />
            <OrganizationSelectField
              label={t('Co-organizations')}
              organizations={formData.coorganizations}
              isMulti
              filters={[
                formData.organization?.id && {
                  column: 'id',
                  operator: 'is',
                  invert: 'true',
                  param: formData.organization.id,
                },
              ]}
              onSelect={coOrgs => setField(coOrgs, 'coorganizations')}
            />
          </FieldBlock>
          <FieldBlock>
            <TeamSelectField
              label={t('Teams')}
              teams={formData.teams}
              isMulti
              filters={[
                formData.team?.id && {
                  column: 'id',
                  operator: 'is',
                  invert: 'true',
                  param: formData.team.id,
                },
              ]}
              onSelect={teams => setField(teams, 'teams')}
            />
            <Checkbox
              label={t('Add all members as guests')}
              disabled={!formData.teams?.length}
              checked={!!formData.add_all_team_members}
              onChange={addAllTeamMembers =>
                setField(addAllTeamMembers, 'add_all_team_members')
              }
            />
          </FieldBlock>
          <FieldBlock variant="large">
            <LocationSelectField
              id="location"
              label={t('Location')}
              onSelect={val => {
                setField(val === undefined ? null : val, 'location')
              }}
              location={formData.location}
              locationTypes={[LOCATION_TYPES.venue]}
              clearable
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              label={t('URL')}
              id="eventUrl"
              value={formData.url}
              onChange={url => setField(url, 'url')}
              placeholder="https://example.com"
            />
            <TextField
              label={t('URL label')}
              id="eventUrlLabel"
              value={formData.url_label}
              onChange={url => setField(url, 'url_label')}
            />
          </FieldBlock>
          <FieldBlock variant="large">
            <TextField
              label={t('Description')}
              id="description"
              value={formData.description}
              onChange={description => setField(description, 'description')}
            />
          </FieldBlock>
          <FieldBlock variant="large">
            <TextField
              label={t('Notes')}
              id="eventNotes"
              value={formData.notes}
              onChange={notes => setField(notes, 'notes')}
            />
          </FieldBlock>
          <FieldBlock>
            <CampaignSelectField
              label={t('Campaign')}
              onSelect={val => setField(val, 'campaign')}
              filters={[
                {
                  column: 'start_date',
                  operator: 'before',
                  param: moment().add(1, 'days').format('YYYY-MM-DD'),
                },
                {
                  column: 'end_date',
                  operator: 'after',
                  param: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                },
              ]}
              campaign={formData.campaign}
              clearable
            />
          </FieldBlock>
          <FieldBlock>
            <Checkbox
              id="event-public"
              name="Public"
              label={t('Public event')}
              checked={formData.public}
              onChange={checked => setField(checked, 'public')}
            />
          </FieldBlock>
          {formData.public && (
            <Section label={t('Public events settings')}>
              <Sheet>
                <FieldBlock>
                  <TextField
                    label={t('Public event title')}
                    id="publicTitle"
                    value={formData.publicEventTitle}
                    onChange={publicEventTitle =>
                      setField(publicEventTitle, 'publicEventTitle')
                    }
                  />
                </FieldBlock>
                <FieldBlock
                  className={styles['public-event-checkbox-container']}
                >
                  <Font.Copy variant="hint">
                    {t(
                      'Include the following fields in public event sign-up forms'
                    )}
                  </Font.Copy>
                  <Checkbox
                    id="first-name"
                    name="First name"
                    label={t('First name')}
                    disabled
                    checked
                  />
                  <Checkbox
                    id="last-name"
                    name="Last name"
                    label={t('Last name')}
                    disabled
                    checked
                  />
                  <Checkbox
                    id="phone-number"
                    name="Phone number"
                    label={t('Phone number')}
                    disabled
                    checked
                  />
                  <Checkbox
                    id="zip-code"
                    name="Zip code"
                    label={t('Zip code')}
                    disabled
                    checked
                  />
                  <Checkbox
                    id="email"
                    name="Email"
                    label={t('Email')}
                    checked={formData.public_settings.includes('email')}
                    onChange={checked => {
                      updateSettings(checked, 'email')
                    }}
                  />
                  <Checkbox
                    id="street-address"
                    name="Street address"
                    label={t('Street address')}
                    checked={formData.public_settings.includes('street')}
                    onChange={checked => {
                      updateSettings(checked, 'street')
                    }}
                  />
                  <Checkbox
                    id="city"
                    name="City"
                    label={t('City')}
                    checked={formData.public_settings.includes('city')}
                    onChange={checked => {
                      updateSettings(checked, 'city')
                    }}
                  />
                  <Checkbox
                    id="state"
                    name="State"
                    label={t('State')}
                    checked={formData.public_settings.includes('state')}
                    onChange={checked => {
                      updateSettings(checked, 'state')
                    }}
                  />
                  <Checkbox
                    id="invited-by"
                    name="Invited by"
                    label={t('Invited by')}
                    checked={formData.public_settings.includes('invited_by')}
                    onChange={checked => {
                      updateSettings(checked, 'invited_by')
                    }}
                  />
                  <Checkbox
                    id="organizations"
                    name="Organizations"
                    label={t('Organizations')}
                    checked={formData.public_settings.includes('organizations')}
                    onChange={checked => {
                      updateSettings(checked, 'organizations')
                    }}
                  />
                  <Checkbox
                    id="child-care"
                    name="Child care"
                    label={t('Child care needs (if any)')}
                    checked={formData.public_settings.includes(
                      'child_care_needs'
                    )}
                    onChange={checked => {
                      updateSettings(checked, 'child_care_needs')
                    }}
                  />
                  <Checkbox
                    id="transportation"
                    name="Transportation"
                    label={t('Transportation needs (if any)')}
                    checked={formData.public_settings.includes(
                      'transportation_needs'
                    )}
                    onChange={checked => {
                      updateSettings(checked, 'transportation_needs')
                    }}
                  />
                  <Checkbox
                    id="dietary-restrictions"
                    name="Dietary restrictions"
                    label={t('Dietary restrictions (if any)')}
                    checked={formData.public_settings.includes(
                      'dietary_restrictions'
                    )}
                    onChange={checked => {
                      updateSettings(checked, 'dietary_restrictions')
                    }}
                  />
                  <Checkbox
                    id="preferred-language"
                    name="Preferred language"
                    label={t('Preferred language')}
                    checked={formData.public_settings.includes(
                      'preferred_language'
                    )}
                    onChange={checked => {
                      updateSettings(checked, 'preferred_language')
                    }}
                  />
                  <Checkbox
                    id="receives_sms"
                    name="Allow sms"
                    label={t('SMS Opt-in')}
                    checked={formData.public_settings.includes('receives_sms')}
                    onChange={checked => {
                      updateSettings(checked, 'receives_sms')
                    }}
                  />
                </FieldBlock>
                <FieldBlock
                  className={styles['public-event-checkbox-container']}
                >
                  <Font.Copy variant="hint">Custom open field</Font.Copy>
                  <Switch
                    label={t('Include open field')}
                    value={formData.publicEventSettingsHasOpenField}
                    onChange={val => {
                      setField(val, 'publicEventSettingsHasOpenField')
                    }}
                  />
                  <TextField
                    label={t('Open field label')}
                    disabled={!formData.publicEventSettingsHasOpenField}
                    value={formData.publicEventSettingsOpenFieldLabel}
                    onChange={val => {
                      setField(val, 'publicEventSettingsOpenFieldLabel')
                    }}
                  />
                </FieldBlock>
                <FieldBlock variant="large">
                  <TextArea
                    label={t('Post registration message')}
                    id="post-reg"
                    name="post-reg"
                    value={formData.postRegistrationMessage || ''}
                    onChange={val => {
                      setField(val, 'postRegistrationMessage')
                    }}
                    rows={5}
                  />
                </FieldBlock>
              </Sheet>
            </Section>
          )}
          <FieldBlock>
            {formData.public && (
              <Uploader
                dialog
                label={t('Custom event page image')}
                hintText={t('Ideal size 1500 x 650 px')}
                currentFile={formData.public_page_header_file_locator}
                onUpload={handleUpload}
                autoUpload
                endpoint="/system/files/upload?url=true&metadata_generator=public_events"
                stringifyData={false}
                fileTypes={[
                  'image/avif',
                  'image/gif',
                  'image/jpeg',
                  'image/png',
                  'image/webp',
                ]}
              />
            )}
          </FieldBlock>
          <ButtonBlock>
            <Button.Accent onClick={handleSave} disabled={!isFormValid}>
              {t('Save')}
            </Button.Accent>
            <Link to={`/build/events/${match.params.id}`}>
              <Button.Secondary>{t('Cancel')}</Button.Secondary>
            </Link>
          </ButtonBlock>
          <ProgressBar
            show={fetchEventRequest.isLoading || putEventRequest.isLoading}
          />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default EventEditForm
