import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  TextBlock,
  SectionContainer,
  SectionLabel,
  ContentBlock,
} from 'components'
import classNames from 'classnames/bind'
import styles from './Section.module.scss'

const cx = classNames.bind(styles)

class Section extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tight: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    label: '',
    tight: false,
  }

  renderChildren() {
    const { value, children } = this.props

    if (value) return <TextBlock>{value}</TextBlock>

    return React.Children.map(children, child => {
      if (child?.type?.name === 'DetailItem') {
        return React.cloneElement(child, {
          className: cx('detail-item', child.props.className),
        })
      }
      if (child?.type?.name === 'ButtonBlock') {
        return React.cloneElement(child, {
          className: cx('button-block', child.props.className),
        })
      }

      return child
    })
  }

  render() {
    const { className, label, empty, emptyMessage, tight, secondary } =
      this.props

    const containerClass = cx('container', className)

    if (empty) {
      return (
        <SectionContainer data-test-id={label} className={containerClass}>
          {label ? (
            <ContentBlock>
              <SectionLabel primary tight={tight}>
                {label}
              </SectionLabel>
            </ContentBlock>
          ) : null}
          <TextBlock hasBackground={secondary}>
            {emptyMessage || 'No data'}
          </TextBlock>
        </SectionContainer>
      )
    }

    return (
      <SectionContainer data-test-id={label} className={containerClass}>
        {label ? (
          <ContentBlock>
            <SectionLabel primary tight={tight}>
              {label}
            </SectionLabel>
          </ContentBlock>
        ) : null}
        <div
          className={cx('content', {
            'content--secondary': secondary,
          })}
        >
          {this.renderChildren()}
        </div>
      </SectionContainer>
    )
  }
}

export default Section
