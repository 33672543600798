import shadow from 'react-shadow'
import buildMarkup from './buildMarkup'

const TemplatePreview = ({ template }) => (
  <shadow.div>
    <div dangerouslySetInnerHTML={buildMarkup(template)} />
  </shadow.div>
)

export default TemplatePreview
