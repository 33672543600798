import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField } from 'components'
import styles from './AgeRangeField.module.scss'

const AgeRangeField = ({ id, value, onChange, ...props }) => {
  const [min, setMin] = useState(value?.min)
  const [max, setMax] = useState(value?.max)

  useEffect(() => {
    onChange && onChange({ min, max })
  }, [min, max])

  const minValue = value?.min || min
  const maxValue = value?.max || max

  return (
    <div className={styles.field}>
      {/* eslint-disable-next-line blocks/no-number-textfield */}
      <TextField
        id={`${id}-min`}
        label="Min"
        type="number"
        step={1}
        min={0}
        max={+maxValue || 0}
        pattern="^d+(\.|\,)\d{2}"
        value={minValue || ''}
        onChange={setMin}
        {...props}
      />
      {/* eslint-disable-next-line blocks/no-number-textfield */}
      <TextField
        id={`${id}-max`}
        label="Max"
        type="number"
        step={1}
        min={+minValue || 0}
        max={115}
        pattern="^d+(\.|\,)\d{2}"
        value={maxValue || ''}
        onChange={setMax}
        {...props}
      />
    </div>
  )
}

AgeRangeField.propTypes = {
  id: PropTypes.string,
  value: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
}

AgeRangeField.defaultProps = {
  id: '',
  value: {},
}

export default AgeRangeField
