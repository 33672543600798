import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Modal,
} from '@politechdev/blocks-design-system'

const VdrEditWarningModal = ({ isOpen, setIsOpen, canvasserEditRequest }) => {
  const { t } = useTranslation()

  const onApply = () => {
    canvasserEditRequest()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      id="warn-edit-vdr"
      title={t("Are you sure you want to edit this Canvasser's VDRs?")}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <p>
              You are about to permanently edit this canvasser&apos;s VDRs. This
              action cannot be undone.
            </p>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={onApply}>{t('Apply')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default VdrEditWarningModal
