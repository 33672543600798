import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import { Tab } from '@politechdev/blocks-design-system'
import OrganizationTeamTable from '../OrganizationTeamTable/OrganizationTeamTable'
import OrganizationMemberTable from '../OrganizationMemberTable/OrganizationMemberTable'
import styles from './OrganizationTabs.module.scss'

const OrganizationTabs = () => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()

  const canViewOrganizationTeams = doesCurrentUserHavePermission({
    resource: 'team',
    ability: 'view',
  })

  const canViewOrganizationMembership = doesCurrentUserHavePermission({
    resource: 'organization_membership',
    ability: 'view',
  })

  if (!canViewOrganizationTeams && !canViewOrganizationMembership) return null

  return (
    <Tab.Container tabId="organization-tabs" className={styles.tabs}>
      {canViewOrganizationTeams && (
        <Tab label={t('Teams')} id="teams">
          <OrganizationTeamTable />
        </Tab>
      )}
      {canViewOrganizationMembership && (
        <Tab label={t('Members')} id="members">
          <OrganizationMemberTable />
        </Tab>
      )}
    </Tab.Container>
  )
}

export default OrganizationTabs
