import React from 'react'
import moment from 'moment'
import classNames from 'classnames/bind'
import Items from '../Items/Items'
import styles from './DayView.module.scss'

const cx = classNames.bind(styles)

const DayView = ({ dateRange }) => (
  <div className={styles.calendar}>
    {dateRange.map(({ date }) => (
      <div
        key={date.format('X')}
        className={cx('day', {
          'day--today': date.isSame(moment(), 'day'),
        })}
      >
        <Items startDate={date} endDate={date} className={styles.items} />
      </div>
    ))}
  </div>
)

export default DayView
