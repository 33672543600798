import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { advanceFlagStatus } from 'requests/flags'
import { useRequest } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'
import { getBulkReviewButtonText } from './utils'
import { useFlagsFilteredListContext } from './contexts/FlagsFilteredListContext'

const TableProgramBulkReview = ({
  checkboxContext,
  tabType,
  refreshListData,
}) => {
  const { t } = useTranslation()

  const { getCheckedCheckboxValues, checkAll, areAllChecked, uncheckAll } =
    useContext(checkboxContext)

  const { fetchUnreadStatusRequest } = useFlagsFilteredListContext()

  const advanceFlagsStatusRequest = useRequest(
    flags => {
      const requests = flags.map(flag =>
        advanceFlagStatus(flag.id, tabType === 'table' ? 'start' : 'complete')
      )
      return Promise.all(requests)
    },
    {
      onSuccess: async () => {
        uncheckAll()
        refreshListData()
        fetchUnreadStatusRequest.makeRequest()
      },
    }
  )

  if (getCheckedCheckboxValues().length === 0) {
    return null
  }

  const hasErrors =
    fetchUnreadStatusRequest.hasErrors || advanceFlagsStatusRequest.hasErrors
  const errors =
    fetchUnreadStatusRequest.errors || fetchUnreadStatusRequest.errors

  const isLoading =
    fetchUnreadStatusRequest.isLoading || advanceFlagsStatusRequest.isLoading

  return (
    <div>
      <CardError hide={!hasErrors} message={formatErrorMessage(errors)} />
      <ProgressBar show={isLoading} />
      <ButtonBlock>
        <Button.Secondary onClick={areAllChecked() ? uncheckAll : checkAll}>
          {areAllChecked() ? t('Clear all') : t('Select all')}
        </Button.Secondary>
        <Button.Accent
          onClick={() => {
            advanceFlagsStatusRequest.makeRequest(getCheckedCheckboxValues())
          }}
        >
          {getBulkReviewButtonText(tabType === 'table' ? 'start' : undefined)}
        </Button.Accent>
      </ButtonBlock>
    </div>
  )
}

export default TableProgramBulkReview
