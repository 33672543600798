import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { LoadBar, CardError } from 'components'
import { useRequest } from 'hooks'
import { fetchFolders, postFolder } from 'requests/folders'
import DocumentContext from '../DocumentContext/DocumentContext'

const FolderRedirect = () => {
  const { t } = useTranslation()

  const { getFolders, foldersError, foldersLoading } =
    useContext(DocumentContext)

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: rootRequest,
    isLoading: rootLoading,
    errorMsg: rootError,
    isRequestComplete: rootComplete,
    response: rootResponse,
  } = useRequest(fetchFolders)

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: createRequest,
    isLoading: createLoading,
    errorMsg: createError,
    response: createdFolder,
  } = useRequest(postFolder, { onSuccess: getFolders })

  const rootLoaded = !rootLoading && rootComplete && !rootError
  const rootFolder = rootResponse?.folders?.[0]

  useEffect(() => {
    rootRequest({ fields: ['id'] })
  }, [])

  useEffect(() => {
    if (rootLoaded && !rootFolder) {
      createRequest({ name: 'Documents' })
    }
  }, [rootLoaded, rootFolder])

  if (rootFolder || createdFolder) {
    return (
      <Redirect to={`/share/documents/${(rootFolder || createdFolder).id}`} />
    )
  }

  return (
    <>
      <LoadBar show={rootLoading || foldersLoading || createLoading} />
      <CardError
        hide={!rootError && !foldersError}
        message={t("We're unable to load your folders")}
      />
      <CardError
        hide={!createError}
        message={t("We're unable to create your starting folder")}
      />
    </>
  )
}

export default FolderRedirect
