/* eslint-disable arrow-body-style */
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  Text,
  Rectangle,
} from 'recharts'
import classNames from 'classnames/bind'
import { CanvasserContext } from 'canvassers/CanvasserContext/CanvasserContext'
import { useHistory } from 'react-router-dom'
import styles from './CanvasserVisualReviewsChart.module.scss'
import { buildVisualReviewChartData, getShiftTableUrl } from './utils'

const cx = classNames.bind(styles)

const CustomLegend = ({ payload, onLegendClick, getIsFilterActive }) => {
  return (
    <ul className={styles.legend}>
      {payload.map(entry => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          className={cx('legend__item', {
            'legend__item--inactive': getIsFilterActive(entry.value),
          })}
          onClick={() => onLegendClick(entry.value, entry.filter)}
        >
          <svg
            width="36"
            height="18"
            viewBox="0 0 32 18"
            className={styles.legend__icon}
          >
            <Rectangle
              fill={getIsFilterActive(entry.value) ? '#fff' : entry.color}
              stroke={entry.color}
              strokeWidth={3}
              radius={entry.radius}
              width={28}
              height={18}
            />
          </svg>
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  )
}

const AxisLink = props => {
  const { x, y, payload } = props

  const { reportRange, currentCanvasser } = useContext(CanvasserContext)
  const history = useHistory()

  const handleNavigate = () =>
    history.push(
      getShiftTableUrl(
        payload.value,
        reportRange.startDate.slice(0, 10),
        reportRange.endDate.slice(0, 10),
        currentCanvasser.full_name
      )
    )

  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        textAnchor="end"
        verticalAnchor="middle"
        x={-40}
        width={220}
        className={styles['tick-label']}
      >
        {payload.value}
      </Text>
      <svg
        className={styles['response-link']}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x="-25"
        y="-10"
        onClick={handleNavigate}
      >
        <path d="M0 0H16V16H0V0Z" fill="white" />
        <path d="M10 1.71429C9.525 1.71429 9.14286 1.33036 9.14286 0.857143C9.14286 0.383929 9.525 0 10 0H15.1429C15.6179 0 16 0.383929 16 0.857143V6C16 6.475 15.6179 6.85714 15.1429 6.85714C14.6679 6.85714 14.2857 6.475 14.2857 6V2.925L7.14643 10.0036C6.84286 10.3679 6.3 10.3679 5.96429 10.0036C5.63214 9.7 5.63214 9.15714 5.96429 8.82143L13.075 1.71429H10ZM0 3.14286C0 2.03821 0.895357 1.14286 2 1.14286H6C6.475 1.14286 6.85714 1.52679 6.85714 2C6.85714 2.475 6.475 2.85714 6 2.85714H2C1.84214 2.85714 1.71429 2.98571 1.71429 3.14286V14C1.71429 14.1571 1.84214 14.2857 2 14.2857H12.8571C13.0143 14.2857 13.1429 14.1571 13.1429 14V10C13.1429 9.525 13.525 9.14286 14 9.14286C14.475 9.14286 14.8571 9.525 14.8571 10V14C14.8571 15.1036 13.9607 16 12.8571 16H2C0.895357 16 0 15.1036 0 14V3.14286Z" />
      </svg>
    </g>
  )
}

const COLORS = {
  INCOMPLETE: '#348DDF',
  NONFORM: '#03CB9A',
  BASE: '#FF832B',
}

const getIsOtherResponse = entry =>
  !entry.implies_incomplete_form && !entry.implies_not_form

const getDoesImplyNotForm = entry => !!entry.implies_not_form

const legendPayload = [
  {
    value: 'Implies incomplete form',
    iconComponent: Rectangle,
    radius: 4,
    color: COLORS.INCOMPLETE,
    filter: entry => entry.implies_incomplete_form,
  },
  {
    value: 'Implies not form',
    iconComponent: Rectangle,
    radius: 4,
    color: COLORS.NONFORM,
    filter: getDoesImplyNotForm,
  },
  {
    value: 'Other',
    iconComponent: Rectangle,
    radius: 4,
    color: COLORS.BASE,
    filter: getIsOtherResponse,
  },
]

const CanvasserVisualReviewsChart = ({ data }) => {
  const { t } = useTranslation()

  const [filters, setFilters] = useState({
    Other: getIsOtherResponse,
    'Implies not form': getDoesImplyNotForm,
  })

  const handleLegendClick = (legendValue, callback) => {
    const currentFilterValue = filters[legendValue]
    if (typeof currentFilterValue === 'function') {
      setFilters(current => ({
        ...current,
        [legendValue]: undefined,
      }))
    } else {
      setFilters(current => ({
        ...current,
        [legendValue]: callback,
      }))
    }
  }

  const getFillColor = entry => {
    if (entry.implies_incomplete_form) {
      return COLORS.INCOMPLETE
    }
    if (entry.implies_not_form) {
      return COLORS.NONFORM
    }
    return COLORS.BASE
  }

  const getIsFilterActive = value => !!filters[value]

  const preparedChartDate = buildVisualReviewChartData(data, filters)

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={preparedChartDate}
        margin={{
          top: 5,
          right: 5,
          left: 125,
          bottom: 55,
        }}
        layout="vertical"
      >
        <XAxis type="number" allowDecimals={false} dataKey="total_reviews" />
        <YAxis
          type="category"
          dataKey="response_description"
          tick={<AxisLink />}
          tickSize={20}
          width={120}
        />
        <Tooltip
          formatter={value => {
            return [value, t('responses')]
          }}
          cursor={{ fill: 'none' }}
        />
        <Legend
          payload={legendPayload}
          verticalAlign="top"
          content={CustomLegend}
          onLegendClick={handleLegendClick}
          getIsFilterActive={getIsFilterActive}
        />
        <Bar dataKey="total_reviews" activeBar={false}>
          {preparedChartDate.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getFillColor(entry)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default CanvasserVisualReviewsChart
