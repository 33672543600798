import {
  FieldBlock,
  DateField,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { get } from 'lodash'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { validateDate } from '../validation'

const VDRBlock = ({ getField, fieldSetter, attributes, fieldErrors }) => {
  const { t } = useTranslation()
  const [fieldMap, setFieldMap] = useState({})

  useEffect(() => {
    setFieldMap(
      attributes.reduce((fieldMap, [id, groupAttribute]) => {
        fieldMap[groupAttribute] = id
        return fieldMap
      }, {})
    )
  }, [attributes])

  return (
    <FieldBlock>
      {fieldMap.has_vdr_signature ? (
        <Checkbox
          label={t('Has a canvasser (VDR) signature')}
          checked={getField(fieldMap.has_vdr_signature)}
          onChange={fieldSetter(fieldMap.has_vdr_signature)}
          error={!!get(fieldErrors, fieldMap.has_vdr_signature)}
          errorMessage={get(fieldErrors, fieldMap.has_vdr_signature)}
        />
      ) : null}
      {fieldMap.vdr_signature_date ? (
        <DateField
          label={t('Signature date')}
          value={getField(fieldMap.vdr_signature_date)}
          onChange={fieldSetter(fieldMap.vdr_signature_date, validateDate)}
          error={!!get(fieldErrors, fieldMap.vdr_signature_date)}
          errorMessage={get(fieldErrors, fieldMap.vdr_signature_date)}
        />
      ) : null}
      {fieldMap.has_vdr_number ? (
        <Checkbox
          label={t('Has VDR number')}
          checked={getField(fieldMap.has_vdr_number)}
          onChange={fieldSetter(fieldMap.has_vdr_number)}
          error={!!get(fieldErrors, fieldMap.has_vdr_number)}
          errorMessage={get(fieldErrors, fieldMap.has_vdr_number)}
        />
      ) : null}
    </FieldBlock>
  )
}

export default VDRBlock
