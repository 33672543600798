import { useRequest } from 'hooks/useRequest'
import { merge } from 'lodash'
import { createContext, useState } from 'react'
import {
  fetchPhoneBank,
  postScriptQuestion,
  postScriptText,
} from 'requests/phoneBanks'

export const PhoneBankContext = createContext()

const PhoneBankContextProvider = ({ children }) => {
  const [phoneBank, setPhoneBank] = useState({ user: {} })
  const {
    makeRequest: getPhoneBank,
    isLoading: phoneBankReqLoading,
    hasErrors: phoneBankReqHasErrors,
    isRequestComplete: phoneBankReqLoaded,
  } = useRequest(fetchPhoneBank, {
    onSuccess: ({ phone_bank }) => {
      if (phone_bank.script?.script_objects) {
        setPhoneBank(
          merge(phone_bank, {
            script: {
              script_objects: phone_bank.script.script_objects.sort(
                (a, b) => a.position_in_script - b.position_in_script
              ),
            },
          })
        )
      } else {
        setPhoneBank(phone_bank)
      }
    },
  })

  const {
    makeRequest: makeScriptText,
    isLoading: scriptTextReqIsLoading,
    hasErrors: scriptTextReqHasErrors,
  } = useRequest(postScriptText, {
    onSuccess: ({ 'phone_banking/script_text': scriptText }) => {
      setPhoneBank(oldPhoneBank => ({
        ...oldPhoneBank,
        newItem: scriptText,
      }))
    },
  })

  const {
    makeRequest: makeScriptQuestion,
    isLoading: scriptQuestionReqIsLoading,
    hasErrors: scriptQuestionReqHasErrors,
  } = useRequest(postScriptQuestion, {
    onSuccess: ({ 'phone_banking/question': question }) => {
      setPhoneBank(oldPhoneBank => ({ ...oldPhoneBank, newItem: question }))
    },
  })

  return (
    <PhoneBankContext.Provider
      value={{
        phoneBank,
        setPhoneBank,
        getPhoneBank,
        phoneBankReqLoading,
        phoneBankReqHasErrors,
        phoneBankReqLoaded,
        makeScriptText,
        scriptTextReqIsLoading,
        scriptTextReqHasErrors,
        makeScriptQuestion,
        scriptQuestionReqIsLoading,
        scriptQuestionReqHasErrors,
      }}
    >
      {children}
    </PhoneBankContext.Provider>
  )
}

export default PhoneBankContextProvider
