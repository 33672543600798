import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import classNames from 'classnames/bind'
import { LoadBar } from 'components'
import styles from './Modal.module.scss'

const cx = classNames.bind(styles)

const Modal = ({
  title,
  children,
  className,
  contentClassName,
  actionsClassName,
  headerClassName,
  isOpen,
  Actions,
  onHide,
  showLoading,
  allowOverflow,
}) => {
  const target = useRef(document.createElement('div'))

  useEffect(() => {
    document.body.insertBefore(target.current, document.body.firstChild)
    return () => {
      target.current.remove()
    }
  }, [])

  const [visible, setVisibility] = useState(isOpen)

  useEffect(() => {
    setVisibility(isOpen)
  }, [isOpen])

  const handleHide = () => {
    onHide()
  }

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            className={cx('overlay', {
              'overlay--visible': visible,
            })}
            onClick={handleHide}
            onKeyDown={handleHide}
            role="button"
            tabIndex="0"
          />
          <div className={styles.container} data-test-id="modal">
            <div
              className={cx(
                'modal',
                {
                  'modal--visible': visible,
                  'modal--overflow': allowOverflow,
                },
                className
              )}
            >
              <LoadBar show={showLoading} />
              <div className={cx('header', headerClassName)}>{title}</div>
              <div className={cx('content', contentClassName)}>{children}</div>
              <div className={cx('actions', actionsClassName)}>
                <Actions />
              </div>
            </div>
          </div>
        </>,
        target.current
      )}
    </>
  )
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  contentClassName: PropTypes.string,
  actionsClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  Actions: PropTypes.func,
  allowOverflow: PropTypes.bool,
  showLoading: PropTypes.bool,
}

Modal.defaultProps = {
  className: '',
  onHide: () => {},
  contentClassName: '',
  actionsClassName: '',
  Actions: () => null,
  allowOverflow: false,
  children: null,
  showLoading: false,
}

export default Modal
