import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  READY,
  CONNECTING,
  ACTIVE,
  REVIEW,
  COMPLETE,
} from 'constants/phoneBankSessions'
import { CardError } from 'components'
import { useTwilioCall, useTimer, useRouteQueryParams } from 'hooks'
import { useCurrent } from 'contexts/index'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { useHistory } from 'react-router-dom'
import CallPanelHeader from './CallPanelHeader/CallPanelHeader'
import CallPanelControls from './CallPanelControls/CallPanelControls'
import CallPanelInfo from './CallPanelInfo/CallPanelInfo'

const CallPanel = ({ fetchNextCall }) => {
  const { t } = useTranslation()
  const [{ mode }] = useRouteQueryParams()
  const history = useHistory()
  const [dialerError, setDialerError] = useState(false)
  const { startTimer, stopTimer, resetTimer, secondsElapsed } = useTimer()

  const {
    tenantOptions: {
      voip_conferences_enabled: voipConferencesEnabled,
      voip_enabled: hasVoip,
    },
    currentTenant: { subdomain },
  } = useCurrent()

  const {
    callStep,
    setCallStep,
    currentSession: {
      id: sessionId,
      phone_bank: { id: phoneBankId },
    },
    selectedPhoneNumber: phoneNumber,
    updateCallStatus,
    getConferenceName,
  } = useCallSessionContext()
  const conferenceName = getConferenceName(subdomain)
  const showTimer = [ACTIVE, REVIEW, COMPLETE].includes(callStep)

  const onConnect = () => {
    setCallStep(ACTIVE)
    updateCallStatus('connect', {}, { voipConferencesEnabled, subdomain })
  }

  const onDisconnect = () => {
    setCallStep(REVIEW)
    stopTimer()
  }

  const onError = error => {
    setDialerError(error)
  }

  const nextCall = () => {
    fetchNextCall(phoneBankId, mode, hasVoip).then(() => {
      setCallStep(READY)
      resetTimer()
    })
  }

  const { startCall, endCall } = useTwilioCall({
    onConnect,
    onDisconnect,
    onError,
  })

  const handleStartCall = () => {
    setDialerError(false)
    setCallStep(CONNECTING)
    startCall(
      voipConferencesEnabled
        ? { conference_name: conferenceName }
        : { to: phoneNumber }
    )
    startTimer()
  }

  const endSession = () =>
    history.push(
      `/organize/phone_banks/${phoneBankId}/call_sessions/${sessionId}/end`
    )

  return (
    <div className="call-panel">
      <CallPanelHeader
        callStep={callStep}
        showTimer={showTimer}
        secondsElapsed={secondsElapsed}
      />
      <CardError
        hide={!dialerError}
        message={t('Sorry, something went wrong with this call')}
      />
      <CallPanelInfo
        callStep={callStep}
        phoneNumber={phoneNumber}
        showPhone={false}
      />
      <CallPanelControls
        callStep={callStep}
        startCall={handleStartCall}
        endCall={endCall}
        nextCall={nextCall}
        endSession={endSession}
        disabled={!phoneNumber}
      />
    </div>
  )
}

export default CallPanel
