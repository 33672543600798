import { Button } from 'react-md'
import { useTranslation } from 'react-i18next'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'

const ReassignActions = () => {
  const { t } = useTranslation()
  const { setIsReassignmentModalOpen } = usePacketAssignment()

  return (
    <Button
      className="table-filters__modal__button"
      onClick={() => setIsReassignmentModalOpen(false)}
      primary
      flat
    >
      {t('Cancel')}
    </Button>
  )
}

export default ReassignActions
