import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({ value, message, label, color }) => (
  <div className="progress-bar__wrapper">
    {label && <div className="progress-bar__label">{label}</div>}
    <div className="progress-bar__container">
      <div
        className="progress-bar__fill"
        style={{ width: `${value}%`, background: color }}
      />
    </div>
    {message && <p className="progress-bar__msg">{message}</p>}
  </div>
)

ProgressBar.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  message: PropTypes.string,
}

ProgressBar.defaultProps = {
  label: null,
  value: 0,
  message: null,
}

export default ProgressBar
