import { useTranslation } from 'react-i18next'
/* eslint-disable no-alert, import/no-named-as-default */
import DaysLeftFilter from 'qualityControl/inbox/DaysLeftFilter'
import UnitedStatesFilter from 'qualityControl/inbox/UnitedStatesFilter'
import TimeZoneFilter from 'qualityControl/inbox/TimeZoneFilter'
import DaysSinceCollectionFilter from 'qualityControl/inbox/DaysSinceCollectionFilter'
/* eslint-disable no-alert, import/no-named-as-default */
import { FieldBlock } from 'components'
import SortFilter from './SortFilter/SortFilter'
import styles from './Filters.module.scss'

export const Filters = ({ show }) => {
  const { t } = useTranslation()
  if (!show) return null

  return (
    <>
      <FieldBlock>
        <SortFilter />
      </FieldBlock>
      <div className={styles.filters__container}>
        <p className={styles.filters__label}>{t('Filters')}</p>
        <FieldBlock>
          <DaysLeftFilter />
          <TimeZoneFilter />
          <DaysSinceCollectionFilter />
          <UnitedStatesFilter />
        </FieldBlock>
      </div>
    </>
  )
}

export default Filters
