import { generateCheckboxGroupProvider } from 'providers/CheckboxGroupProvider'
import { usePacketAssignment } from '../PacketAssignmentContext'

export const [
  SummaryPacketsCheckboxGroupContext,
  SummaryPacketsCheckboxProvider,
] = generateCheckboxGroupProvider(() => {
  const { tableReadyPackets } = usePacketAssignment()
  return tableReadyPackets
})
