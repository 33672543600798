export function getParamValues(filter) {
  if (filter.id === 'turfs') {
    return Object.values(filter.meta).map(({ name }) => ({
      value: name,
    }))
  }
  if (filter.id === 'states') {
    return filter.params.rules.map(({ param }) => ({ value: param }))
  }
  return Object.values(filter.params)
}
