import { List } from 'components'
import { getStatusForTableReadyPackets } from 'utils/qualityControl'
import {
  getDaysLeft,
  getPacketLanguage,
  getPacketTimeZoneLabel,
} from 'qualityControl/utils'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { PacketListItem } from './PacketListItem'

const PacketList = () => {
  const { packetsBeingAssigned: packets } = usePacketAssignment()
  return (
    <List className="margin--bottom">
      {packets.map(packet => (
        <PacketListItem
          id={`modal-packet-list${packet.id}`}
          key={`modal-packet-list${packet.id}`}
          packetName={packet.original_filename}
          daysLeft={getDaysLeft(packet)}
          packetLanguage={getPacketLanguage(packet)}
          packetQcStatus={getStatusForTableReadyPackets(packet)}
          packetTimeZone={getPacketTimeZoneLabel(packet)}
        />
      ))}
    </List>
  )
}

export default PacketList
