import { useCurrent } from 'contexts/index'
import { createContext, useContext } from 'react'

const PermissionsContext = createContext()

export const PermissionsProvider = ({ children }) => {
  const { doesCurrentUserHavePermission } = useCurrent()
  const canView = doesCurrentUserHavePermission({
    resource: 'document',
    ability: 'view',
  })
  const canModify = doesCurrentUserHavePermission({
    resource: 'document',
    ability: 'modify',
  })
  const canDelete = doesCurrentUserHavePermission({
    resource: 'document',
    ability: 'remove',
  })
  return (
    <PermissionsContext.Provider
      value={{
        canView,
        canModify,
        canDelete,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  )
}

export const usePermissions = () => {
  const context = useContext(PermissionsContext)
  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionsProvider')
  }
  return context
}
