import { createContext, useState } from 'react'
import moment from 'moment'
import { useDebounce } from 'use-debounce'

export const CanvasserContext = createContext()

const CanvasserContextProvider = ({ children }) => {
  const [currentCanvasser, setCurrentCanvasser] = useState()

  const [canvasserStats, setCanvasserStats] = useState({})
  const [otherCanvasserStats, setOtherCanvasserStats] = useState([])
  const [avgStats, setAvgStats] = useState({})
  const [canvasserStatsByDate, setCanvasserStatsByDate] = useState([])
  const [avgStatsByDate, setAvgStatsByDate] = useState([])

  const [canvasserStatsLoading, setCanvasserStatsLoading] = useState(true)
  const [canvasserStatsByDateLoading, setCanvasserStatsByDateLoading] =
    useState(true)

  const [canvasserVisualReviewsLoading, setCanvasserVisualReviewsLoading] =
    useState(true)

  const [canvasserVisualReviews, setCanvasserVisualReviews] = useState([])

  const [reportRange, setReportRange] = useState({
    startDate: moment().subtract(1, 'month').toISOString(),
    endDate: moment().toISOString(),
  })

  const [debouncedReportRange] = useDebounce(reportRange, 500)
  const [metric, setMetric] = useState('totalFormsCover')

  return (
    <CanvasserContext.Provider
      value={{
        currentCanvasser,
        setCurrentCanvasser,
        canvasserStats,
        setCanvasserStats,
        otherCanvasserStats,
        setOtherCanvasserStats,
        avgStats,
        setAvgStats,
        canvasserStatsByDate,
        setCanvasserStatsByDate,
        avgStatsByDate,
        setAvgStatsByDate,
        canvasserVisualReviews,
        setCanvasserVisualReviews,
        setCanvasserStatsLoading,
        setCanvasserStatsByDateLoading,
        setCanvasserVisualReviewsLoading,
        reportRange,
        debouncedReportRange,
        setReportRange,
        metric,
        setMetric,
        canvasserReportsLoading:
          canvasserStatsLoading ||
          canvasserStatsByDateLoading ||
          canvasserVisualReviewsLoading,
      }}
    >
      {children}
    </CanvasserContext.Provider>
  )
}

export default CanvasserContextProvider
