import { useState } from 'react'
import {
  Modal,
  Button,
  Icon,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import IssueModal from './IssueModal/IssueModal'

const RedoQcButton = ({ redoResponses, isAutoReviewed, issuesRaised }) => {
  const { t } = useTranslation()
  const [visible, setVisibility] = useState(false)
  const [issueModalVisible, setIssueModalVisibility] = useState(false)
  const { doesCurrentUserHavePermission } = useCurrent()
  const canPerformVisualQC = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'visual_qc',
  })

  return (
    <>
      <Button.Danger
        disabled={!canPerformVisualQC}
        onClick={() =>
          isAutoReviewed ? setIssueModalVisibility(true) : setVisibility(true)
        }
      >
        <span>{isAutoReviewed ? t('Edit Issues') : t('Redo QC')}</span>
        <Icon.Redo />
      </Button.Danger>
      {isAutoReviewed ? (
        <IssueModal
          isOpen={issueModalVisible}
          closeModal={() => setIssueModalVisibility(false)}
          presetIssueIds={issuesRaised}
        />
      ) : (
        <Modal
          id="redo-qc-modal"
          title={t('Redo scan review?')}
          isOpen={visible}
          onHide={() => setVisibility(false)}
        >
          <Modal.Body>
            <ContentBlock>
              <p>
                {t(
                  'Redoing a scan review will delete the review information for this scan and set it back as unreviewed'
                )}
              </p>
            </ContentBlock>
          </Modal.Body>
          <Modal.Actions>
            <ButtonBlock justify="right">
              <Button.Secondary onClick={() => setVisibility(false)}>
                {t('Cancel')}
              </Button.Secondary>
              <Button.Danger className="button--warn" onClick={redoResponses}>
                {t('Redo QC')}
              </Button.Danger>
            </ButtonBlock>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default RedoQcButton
