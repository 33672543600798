import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TurfSelectField } from 'components'
import { useCurrent, useQueryParams, useTurfs } from 'contexts'
import {
  ProgressBar,
  FieldBlock,
  Sheet,
  DateField,
} from '@politechdev/blocks-design-system'
import { DashboardFilterContext } from '../DashboardFilterContext/DashboardFilterContext'

const DashboardFilters = () => {
  const { t } = useTranslation()
  const { queryParams, setQueryParams } = useQueryParams()

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
    },
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const { startDate, setStartDate, endDate, setEndDate, setTurfId } =
    useContext(DashboardFilterContext)

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  const changeTurf = turfId => {
    setTurfId(turfId)
    setQueryParams({ turfId })
  }

  const changeDateRange = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setQueryParams({
      startDate,
      endDate,
    })
  }

  return (
    <Sheet>
      <ProgressBar show={turfRefreshIsLoading} />
      <FieldBlock variant="medium">
        <DateField
          label={t('Date range')}
          type="range"
          onChange={changeDateRange}
          startDate={startDate}
          endDate={endDate}
        />
      </FieldBlock>
      <FieldBlock>
        <TurfSelectField
          label={t('Turf')}
          isExpanded
          showArchived
          value={+queryParams.turfId || topLevelTurfId}
          onSelect={changeTurf}
        />
      </FieldBlock>
    </Sheet>
  )
}

export default DashboardFilters
