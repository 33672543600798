import {
  Button,
  MenuButton,
  Checkbox,
  DialogBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

export const MultiChoiceMenuButton = ({
  isOpen,
  openMenu,
  setParam,
  param,
  handleDoneClick,
  handleCancelClick,
  column,
}) => {
  const { t } = useTranslation()

  return (
    <MenuButton
      isOpen={isOpen}
      onToggle={openMenu}
      label={t(`${column.title}`)}
    >
      <MenuButton.Dialog>
        <div className="table-filters__column-list__header">
          <p className="table-filters__helper-text">
            {t('Select your options')}
          </p>
        </div>
        <DialogBlock>
          {column.options.map(menuItem => {
            const { label, value } = menuItem
            return (
              <Checkbox
                key={label}
                onChange={() => {
                  param.includes(value)
                    ? setParam(selectedVals =>
                        selectedVals.filter(
                          incomingVal => incomingVal !== value
                        )
                      )
                    : setParam(selectedVals => [...selectedVals, value])
                }}
                checked={param.includes(value)}
                label={label}
                labelClassName="table-filters__column-list__text"
                className="table-filters__column-list__item"
              />
            )
          })}
        </DialogBlock>
        <div className="table-filters__column-list__footer">
          <Button.Secondary onClick={handleCancelClick}>
            {t('Cancel')}
          </Button.Secondary>
          <Button
            onClick={() => {
              handleDoneClick(param)
            }}
          >
            {t('Done')}
          </Button>
        </div>
      </MenuButton.Dialog>
    </MenuButton>
  )
}
