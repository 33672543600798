import { fetchJSON, stringifyParams } from 'utils/req'

export const defaultMeetingOptions = {
  fields: [
    'id',
    'start_time',
    'end_time',
    'cancelled',
    'type',
    'notes',
    {
      campaign: ['id', 'name'],
    },
    {
      location: [
        'city',
        'contact_email',
        'contact_name',
        'contact_phone',
        'county',
        'geoid',
        'has_av',
        'has_hosted_event',
        'has_it_support',
        'has_projector',
        'has_public_transportation',
        'has_wifi',
        'id',
        'largest_turnout',
        'latitude',
        'location_type',
        'longitude',
        'maximum_occupancy',
        'name',
        'notes',
        'parking_spots_available',
        'rooms_available',
        'score',
        'state',
        'street_address',
        'timezone',
        'zipcode',
      ],
    },
    {
      person: [
        'id',
        'name',
        'first_name',
        'last_name',
        'phone',
        'email',
        {
          residential_address: [
            'id',
            'line_one',
            'line_two',
            'city',
            'state',
            'zipcode',
            'county',
          ],
        },
      ],
    },
    {
      additional_person: [
        'id',
        'name',
        'phone',
        'email',
        {
          residential_address: [
            'id',
            'line_one',
            'line_two',
            'city',
            'state',
            'zipcode',
            'county',
          ],
        },
      ],
    },
    {
      user: [
        'id',
        'first_name',
        'last_name',
        'name',
        'datatable_settings',
        'disabled_at',
        'email',
        'extras',
        'last_sign_in_at',
        'locale',
        'role_id',
        'spoken_language_locales',
        'time_zone',
        'trained_in_states',
        'training_status',
        'turf_id',
        'two_factor_enabled',
        'two_factor_phone_last_four',
      ],
    },
    {
      turf: [{ activism_options_config: ['languages'] }],
    },
  ],
}

export const defaultEventShiftOptions = {
  fields: ['id', 'start_time', 'end_time'],
}

export const defaultEventShiftVolunteerOptions = {
  fields: [
    'id',
    'responsibility',
    {
      person: ['name', 'id'],
    },
  ],
}

export const fetchEvents = (params = {}) =>
  fetchJSON(`/api/v1/events?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchEvent = (id, params) =>
  fetchJSON(`/api/v1/events/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchEventAttendees = (id, params) =>
  fetchJSON(
    `/api/v1/events/${id}/attendees?${stringifyParams(params)}`,
    'GET',
    null,
    { useJwt: true }
  )

export const postEvent = (event, params) =>
  fetchJSON(
    `/api/v1/events?${stringifyParams(params)}`,
    'POST',
    { event },
    { useJwt: true }
  )

export const postEventShift = (eventId, shift) =>
  fetchJSON(
    `/api/v1/events/${eventId}/shifts?${stringifyParams(
      defaultEventShiftOptions
    )}`,
    'POST',
    { shift },
    { useJwt: true }
  )

export const postEventShiftVolunteer = (eventId, shiftId, volunteer) =>
  fetchJSON(
    `/api/v1/events/${eventId}/shifts/${shiftId}/volunteers/?${stringifyParams(
      defaultEventShiftVolunteerOptions
    )}`,
    'POST',
    { volunteer },
    { useJwt: true }
  ).then(({ volunteership }) => ({ volunteer: volunteership, shiftId }))

export const postEventAttendee = (eventId, attendance) =>
  fetchJSON(
    `/api/v1/events/${eventId}/attendees`,
    'POST',
    {
      attendance,
    },
    { useJwt: true }
  )

export const postEventAttachment = (eventId, file_locator) =>
  fetchJSON(
    `/api/v1/events/${eventId}/documents?${stringifyParams({
      fields: ['name'],
    })}`,
    'POST',
    {
      document: { file_locator },
    },
    { useJwt: true }
  )

export const putEvent = (id, event) =>
  fetchJSON(`/api/v1/events/${id}`, 'PUT', { event }, { useJwt: true })

export const putEventShiftVolunteer = (
  eventId,
  shiftId,
  volunteerId,
  { responsibility }
) =>
  fetchJSON(
    `/api/v1/events/${eventId}/shifts/${shiftId}/volunteers/${volunteerId}?${stringifyParams(
      defaultEventShiftVolunteerOptions
    )}`,
    'PUT',
    {
      volunteer: {
        responsibility,
      },
    },
    { useJwt: true }
  ).then(({ volunteership }) => ({ volunteer: volunteership, shiftId }))

export const putEventAttendee = (eventId, id, attendance) =>
  fetchJSON(
    `/api/v1/events/${eventId}/attendees/${id}`,
    'PUT',
    {
      attendance,
    },
    { useJwt: true }
  )

export const deleteEvent = id =>
  fetchJSON(`/api/v1/events/${id}`, 'DELETE', null, { useJwt: true })

export const deleteEventShift = (eventId, shiftId) =>
  fetchJSON(`/api/v1/events/${eventId}/shifts/${shiftId}`, 'DELETE', null, {
    useJwt: true,
  }).then(() => shiftId)

export const deleteEventShiftVolunteer = (eventId, shiftId, volunteerId) =>
  fetchJSON(
    `/api/v1/events/${eventId}/shifts/${shiftId}/volunteers/${volunteerId}`,
    'DELETE',
    null,
    { useJwt: true }
  ).then(() => ({ shiftId, volunteerId }))

export const deleteEventAttendee = (eventId, id) =>
  fetchJSON(`/api/v1/events/${eventId}/attendees/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const patchEventAttended = eventId =>
  fetchJSON(`/api/v1/events/${eventId}/mark_fully_attended`, 'PATCH', null, {
    useJwt: true,
  })

export const publicEventRegistrationRequest = params =>
  fetchJSON(`/api/v1/public_events/${params.id}/register`, 'POST', params)

export const fetchMeetings = (params = {}) => {
  const queryString = stringifyParams({
    ...defaultMeetingOptions,
    ...params,
    sort_attr: params?.sort_attr || 'id',
    sort_dir: params?.sort_dir || 'asc',
  })

  return fetchJSON(`/api/v1/meetings?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchMeeting = id => {
  const params = stringifyParams(defaultMeetingOptions)
  return fetchJSON(`/api/v1/meetings/${id}?${params}`, 'GET', null, {
    useJwt: true,
  })
}

export const postMeeting = meeting => {
  const queryString = stringifyParams(defaultMeetingOptions)

  return fetchJSON(
    `/api/v1/meetings?${queryString}`,
    'POST',
    { meeting },
    { useJwt: true }
  )
}

export const putMeeting = (id, meeting) => {
  const queryString = stringifyParams(defaultMeetingOptions)

  return fetchJSON(
    `/api/v1/meetings/${id}?${queryString}`,
    'PUT',
    { meeting },
    { useJwt: true }
  )
}

export const deleteMeeting = id =>
  fetchJSON(`/api/v1/meetings/${id}`, 'DELETE', null, { useJwt: true })

export const fetchPublicEvent = (id, params = {}) =>
  fetchJSON(`/api/v1/public_events/${id}?${stringifyParams(params)}`, 'GET')

export const fetchAttendees = (id, params) =>
  fetchJSON(
    `/api/v1/events/${id}/attendees?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const postPublicLink = (id, params) =>
  fetchJSON(
    `/api/v1/events/${id}/public_link?${stringifyParams(params)}`,
    'POST',
    null,
    {
      useJwt: true,
    }
  )
