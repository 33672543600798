import { useTranslation } from 'react-i18next'
import { Sheet } from 'components'
import { ButtonBlock, Font } from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import { DEFAULT_LIST_FOLDER_NAME } from 'constants/lists'
import FolderEditModal from '../FolderEditModal/FolderEditModal'
import FolderDeleteModal from '../FolderDeleteModal/FolderDeleteModal'
import styles from './FolderCard.module.scss'

const FolderCard = ({ folder, getListFolders }) => {
  const { t } = useTranslation()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyFolders = doesCurrentUserHavePermission({
    resource: 'list_folder',
    ability: 'modify',
  })
  const canDeleteFolders = doesCurrentUserHavePermission({
    resource: 'list_folder',
    ability: 'remove',
  })

  return (
    <Sheet className={styles.card}>
      <h1>{folder.name}</h1>
      <Font.Copy Element="span">
        {t('Contains')} {folder.lists.length} {t('lists')}
      </Font.Copy>
      <Font.Copy Element="span">
        {t('Shared with')} {folder.sharees.length} {t('people')}
      </Font.Copy>
      {folder.name !== DEFAULT_LIST_FOLDER_NAME && (
        <ButtonBlock justify="right">
          {canModifyFolders && (
            <FolderEditModal folder={folder} getListFolders={getListFolders} />
          )}
          {canDeleteFolders && (
            <FolderDeleteModal
              folder={folder}
              getListFolders={getListFolders}
            />
          )}
        </ButtonBlock>
      )}
    </Sheet>
  )
}

export default FolderCard
