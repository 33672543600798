import { sortBy } from 'lodash'
import GalleryScan from '../GalleryScan/GalleryScan'
import styles from './GalleryScans.module.scss'

const GalleryScans = ({ cover, scans }) => (
  <div className={styles.scans}>
    <GalleryScan
      url="?scans[]=cover"
      cover={cover}
      removedAt={cover?.file_locator?.metadata?.redacted_at}
      width={450}
    />
    {sortBy(scans, ['scan_number']).map(s => (
      <GalleryScan
        url={`?scans[]=${s.scan_number}`}
        scan={s}
        removedAt={s?.file_locator?.metadata?.redacted_at}
        width={450}
      />
    ))}
  </div>
)

export default GalleryScans
