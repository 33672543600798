import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

export default ({ className }) => (
  <FontAwesomeIcon
    icon={faHome}
    style={{ fontSize: '16px' }}
    className={className}
  />
)
