import { FontIcon } from 'react-md'
import { useTranslation } from 'react-i18next'
import { List, TextField } from 'components'
import PacketListItem from './PacketListItem'
import { getDaysLeft, getPacketLanguage } from '../../../utils'

const PacketList = ({ packets, packetSearch, setPacketSearch }) => {
  const { t } = useTranslation()

  return (
    <>
      {packets.length > 1 && (
        <TextField
          className="table-filters__search margin--bottom"
          id="reprioritization-packet-name-filter"
          label={t('Packet name')}
          value={packetSearch}
          onChange={setPacketSearch}
          leftIcon={
            packetSearch ? (
              <div
                className="table-filters__search-clear"
                role="button"
                tabIndex={0}
                onClick={setPacketSearch('')}
                onKeyDown={e => e.key === 'Enter' && setPacketSearch('')}
              >
                <FontIcon>clear</FontIcon>
              </div>
            ) : (
              <FontIcon>search</FontIcon>
            )
          }
        />
      )}
      <div className="dialog--modal__content__list">
        <List>
          {packets.map(packet => (
            <PacketListItem
              id={`modal-packet-list${packet.id}`}
              key={`modal-packet-list${packet.id}`}
              packetId={packet.id}
              packetName={packet.original_filename}
              daysLeft={getDaysLeft(packet)}
              reprioritization={packet.reprioritization}
              packetLanguage={getPacketLanguage(packet)}
            />
          ))}
        </List>
      </div>
    </>
  )
}
export default PacketList
