import { CardError, List, ListItem } from 'components'
import {
  Section,
  ProgressBar,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { useRequest } from 'hooks'
import {
  getFlagItemReadStatus,
  getFlagItemPrimaryText,
  getFlagItemPrimaryIcon,
  getFlagItemSecondaryText,
  doesFlagHaveUnreadComments,
} from './utils'
import { ListPager } from './ListPager'
import ViewFlagButton from './buttons/ViewFlagButton'
import { useFlagsFilteredListContext } from './contexts/FlagsFilteredListContext'

const FlagsReviewList = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrent()

  const { filters, listPages, fetchGroupFlags } = useFlagsFilteredListContext()
  const currentPage = listPages.group

  const fetchGroupFlagsRequest = useRequest(fetchGroupFlags)

  const flags = fetchGroupFlagsRequest.response?.['quality_control/flags'] || []

  useEffect(() => {
    fetchGroupFlagsRequest.makeRequest(filters, currentPage)
  }, [currentPage, filters])

  return (
    <>
      <ProgressBar show={fetchGroupFlagsRequest.isLoading} />
      <CardError
        hide={!fetchGroupFlagsRequest.hasErrors}
        message={formatErrorMessage(fetchGroupFlagsRequest.errors)}
      />
      {fetchGroupFlagsRequest.isRequestComplete && !!flags.length && (
        <>
          <List>
            {flags.map(flag => (
              <ListItem
                key={`flag-group-list-item-${flag.id}`}
                primaryText={getFlagItemPrimaryText(flag)}
                primaryIcon={getFlagItemPrimaryIcon(flag)}
                secondaryText={getFlagItemSecondaryText(flag, currentUser)}
                read={getFlagItemReadStatus(flag, currentUser.email)}
                showAlertIcon={doesFlagHaveUnreadComments(flag, currentUser)}
                actions={() => <ViewFlagButton flagId={flag.id} />}
              />
            ))}
          </List>
          <ListPager
            currentPage={currentPage}
            setPage={() => {}}
            totalPages={0}
          />
        </>
      )}
      {fetchGroupFlagsRequest.isRequestComplete && !flags.length && (
        <Section secondary collapsed>
          <TextBlock>{t('You have no flags to review')}</TextBlock>
        </Section>
      )}
    </>
  )
}

export default FlagsReviewList
