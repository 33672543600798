import { createContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTurfs } from 'contexts/index'
import useCheckins from '../hooks/useCheckins'

export const ManagementContext = createContext()

const ManagementContextProvider = ({ children }) => {
  const { preparedTurfs: turfs } = useTurfs()
  const history = useHistory()

  const {
    message,
    adminIsLoading,
    adminTurfId,
    setAdminTurfId,
    currentAdminCheckin,
    setQuestionToSave,
    setQuestionToArchive,
    setCheckinToSave,
    setCheckinToUpdate,
    setCheckinToDelete,
    setQuestionsToOrderSwitch,
  } = useCheckins(() => history.push('/check-ins'))

  const [turfOptions, setTurfOptions] = useState([])
  const [turf, setTurf] = useState()
  const [newCheckinPreview, setNewCheckinPreview] = useState({ questions: [] })
  const [currentTurfHasCheckin, setCurrentTurfHasCheckin] = useState(false)
  const [currentTurfAncestorCheckin, setCurrentTurfAncestorCheckin] = useState()

  const [addingQuestion, setAddingQuestion] = useState(false)
  const [newQuestion, setNewQuestion] = useState('')

  const previewNewCheckIn = newFields =>
    setNewCheckinPreview(prev => ({ ...prev, ...newFields }))

  const highestQuestionPosition = () => {
    const questionPositionArray = currentAdminCheckin.questions
      .map(q => q.position)
      .filter(q => q)
    if (!questionPositionArray.length) return 0
    return Math.max(
      ...currentAdminCheckin.questions.map(q => q.position).filter(q => q)
    )
  }

  const saveNewCheckIn = async (initQuestion = null) => {
    setCheckinToSave({
      initQuestion: initQuestion || newQuestion,
      turf_id: adminTurfId,
      ...newCheckinPreview,
    })
    setAddingQuestion(false)
    setNewQuestion('')
  }

  const addQuestion = () => {
    if (currentAdminCheckin?.turf?.id !== adminTurfId) {
      setAddingQuestion(false)
      saveNewCheckIn(newQuestion)
      setNewQuestion('')
    } else {
      setQuestionToSave({
        checkinId: currentAdminCheckin.id,
        text: newQuestion,
        position: highestQuestionPosition() + 1,
      })
    }
    setAddingQuestion(false)
    setNewQuestion('')
  }

  const updateNewCheckin = async () => {
    if (addingQuestion) {
      setQuestionToSave({
        checkinId: currentAdminCheckin.id,
        text: newQuestion,
        position: highestQuestionPosition() + 1,
      })
      setAddingQuestion(false)
      setNewQuestion('')
    }
    setCheckinToUpdate({
      ...newCheckinPreview,
      archived: false,
    })
  }

  useEffect(() => {
    if (!turfs.length) return
    setTurfOptions(turfs)
    setTurf(turfs[0].id)
  }, [turfs])

  useEffect(() => {
    if (currentAdminCheckin?.turf?.id === adminTurfId) {
      setCurrentTurfHasCheckin(true)
    } else {
      setCurrentTurfAncestorCheckin(currentAdminCheckin?.turf?.name)
      setCurrentTurfHasCheckin(false)
    }
    setNewCheckinPreview({
      end_date: null,
      questions: [],
      days_of_the_week: [1, 2, 3, 4, 5],
      ...(currentAdminCheckin?.turf?.id === adminTurfId && {
        ...currentAdminCheckin,
      }),
    })
  }, [currentAdminCheckin, adminTurfId])

  useEffect(() => {
    setAdminTurfId(turf)
  }, [turf])

  return (
    <ManagementContext.Provider
      value={{
        turf,
        adminTurfId,
        turfOptions,
        setTurf,
        setAdminTurfId,
        currentAdminCheckin,
        previewNewCheckIn,
        newCheckinPreview,
        saveNewCheckIn,
        updateNewCheckin,
        setCheckinToDelete,
        setQuestionToSave,
        setQuestionToArchive,
        adminIsLoading,
        currentTurfHasCheckin,
        setCurrentTurfHasCheckin,
        message,
        setQuestionsToOrderSwitch,
        addingQuestion,
        setAddingQuestion,
        newQuestion,
        setNewQuestion,
        addQuestion,
        currentTurfAncestorCheckin,
      }}
    >
      {children}
    </ManagementContext.Provider>
  )
}

export default ManagementContextProvider
