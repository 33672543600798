import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError, SearchSelect, MultiSearchSelect } from 'components'
import { useRequest } from 'hooks'
import { fetchPhoneBank, fetchPhoneBanks } from 'requests/phoneBanks'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { buildParams, buildOption, buildPhoneBank } from './utils'

const PhoneBankSelectFieldDEPRECATED = ({
  label,
  filters,
  onChange,
  phoneBank = {},
  phoneBanks = [],
  isMulti,
  provideDefault,
  allowAny,
  ...props
}) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const [fetchedPhoneBank, setFetchedPhoneBank] = useState()
  const [fetchedPhoneBanks, setFetchedPhoneBanks] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState(phoneBank?.id)
  const [multiValue, setMultiValue] = useState(
    options.filter(option => phoneBanks.some(val => val.id === option.value))
  )
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: fetchSinglePhoneBank } = useRequest(fetchPhoneBank, {
    onSuccess: ({ phone_bank: incomingPhoneBank }) => {
      setFetchedPhoneBank(incomingPhoneBank)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: fetchMultiplePhoneBanks } = useRequest(fetchPhoneBanks, {
    onSuccess: ({ phone_banks: incomingPhoneBanks }) => {
      setFetchedPhoneBanks(incomingPhoneBanks)
    },
  })

  const { makeRequest, isLoading } = useRequest(fetchPhoneBanks, {
    onSuccess: ({ phone_banks: incomingPhoneBanks }) => {
      setOptions(
        [
          ...(allowAny ? [{ id: 'any', name: 'ANY PHONE BANK' }] : []),
          ...incomingPhoneBanks,
        ].map(buildOption)
      )
    },
    onError: () => {
      setErrorMsg('failed to fetch phone banks')
    },
  })

  useEffect(() => {
    if (options.length && provideDefault && value === undefined) {
      setValue(options[0].value)
    }
  }, [provideDefault, options])

  const getPhoneBanks = () => {
    const params = buildParams({
      query: debounced,
      filters,
    })

    makeRequest(params)
  }

  useEffect(() => {
    getPhoneBanks()
  }, [debounced, filters])

  useEffect(() => {
    if (phoneBank?.id && phoneBank.id !== 'any') {
      fetchSinglePhoneBank(phoneBank?.id)
    } else {
      setFetchedPhoneBank()
    }
    setValue(phoneBank?.id)
  }, [phoneBank])

  useEffect(() => {
    fetchMultiplePhoneBanks({
      filters: {
        rules: [
          {
            column: 'id',
            operator: 'in',
            param: phoneBanks.map(({ id }) => id),
          },
        ],
      },
    })
    setMultiValue(phoneBanks.map(buildOption))
  }, [JSON.stringify(phoneBanks)])

  const handleChange = selection => {
    const selectedOption = options.find(({ value }) => value === selection)
    onChange &&
      onChange(selectedOption ? buildPhoneBank(selectedOption) : undefined)
  }

  const handleMultiChange = selections => {
    onChange &&
      onChange(
        options
          .filter(({ value }) => selections.includes(value))
          .map(buildPhoneBank)
      )
  }

  const singleOptions = buildSingleOptions(
    options,
    fetchedPhoneBank,
    buildOption
  )

  const multiOptions = buildMultiOptions(
    options,
    fetchedPhoneBanks,
    buildOption
  )

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSearchSelect
          {...props}
          label={label || t('Phone bank')}
          options={multiOptions}
          onChange={handleMultiChange}
          onInputChange={setQuery}
          value={multiValue}
          isLoading={isLoading}
        />
      ) : (
        <SearchSelect
          {...props}
          label={label || t('Phone bank')}
          options={singleOptions}
          onChange={handleChange}
          onInputChange={setQuery}
          value={value || ''}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

PhoneBankSelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  phoneBank: PropTypes.object,
  label: PropTypes.string,
  filters: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  provideDefault: PropTypes.bool,
}

PhoneBankSelectFieldDEPRECATED.defaultProps = {
  id: 'phoneBank',
  filters: [],
  phoneBank: null,
  provideDefault: false,
}

export default PhoneBankSelectFieldDEPRECATED
