import { useEffect, useRef } from 'react'
import { Call, Device } from '@twilio/voice-sdk'
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors'
import { noop } from 'lodash'
import { fetchJSON } from 'utils/req'

type TwilioCallCallback = (call: Call) => void
type TwilioErrorCallback = (error: TwilioError | Error) => void

const fetchTwilioToken = (): Promise<string> =>
  fetchJSON<{ token: string }>(`/api/v1/twilio/token`, 'POST', null, {
    useJwt: true,
  }).then(response => response.token)

const useTwilioCall = ({
  setupOptions,
  onConnect = noop,
  onDisconnect = noop,
  onError = noop,
}: {
  setupOptions: Device.Options
  onConnect: TwilioCallCallback
  onDisconnect: TwilioCallCallback
  onError: TwilioErrorCallback
}) => {
  const deviceRef = useRef<Device | null>(null)

  const fetchToken = async (): Promise<string> => fetchTwilioToken()

  const destroyDialer = (): void => {
    deviceRef.current?.destroy()
  }

  useEffect(() => destroyDialer, [])

  const setupDialer = async (): Promise<boolean> => {
    const token = await fetchToken()

    if (deviceRef.current) destroyDialer()

    try {
      deviceRef.current = new Device(token, setupOptions)
    } catch (e) {
      if (e instanceof Error) {
        onError(e)
      }
      return false
    }

    const device = deviceRef.current

    await device.register()

    return device.state === Device.State.Registered
  }

  const startCall = async (params: {
    to?: string
    conference_name?: string
  }): Promise<void> => {
    const isReady = await setupDialer()
    if (!isReady || !deviceRef.current) return

    const call = await deviceRef.current.connect({ params })
    onConnect(call)
    call.on('error', onError)
    call.on('disconnect', onDisconnect)
    call.on('cancel', onDisconnect)
  }

  const endCall = (): void => {
    deviceRef.current?.disconnectAll()
    destroyDialer()
  }

  return { startCall, endCall }
}

export default useTwilioCall
