import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, TextField } from 'components'
import {
  Button,
  ButtonBlock,
  Modal,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useEvent, useRequest } from 'hooks'
import { putFolder } from 'requests/folders'
import { useModal } from '../ModalProvider/ModalProvider'
import { MODAL_TYPES } from '../constants'
import styles from './FolderRenameModal.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const FolderRenameModal = () => {
  const { t } = useTranslation()
  const [{ isOpen, closeModal }, { folder, onClose }] = useModal(
    MODAL_TYPES.RENAME_FOLDER
  )
  const [name, setName] = useState('')

  const resetState = () => {
    setName('')
  }

  useEffect(() => {
    if (isOpen) {
      setName(folder?.name || '')
    }
  }, [isOpen])

  const { getFolders, getCurrentFolder, currentFolder } =
    useContext(DocumentContext)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: foldersRequest, isLoading: foldersLoading } =
    useRequest(getFolders)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: folderRequest, isLoading: folderLoading } =
    useRequest(getCurrentFolder)

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: updateRequest,
    isLoading: updateLoading,
    errorMsg: updateError,
  } = useRequest(putFolder, {
    onSuccess: async () => {
      await Promise.all(
        [
          foldersRequest(),
          folder.id === currentFolder.id && folderRequest(folder.id),
        ].filter(Boolean)
      )
      onClose && onClose()
      closeModal()
      resetState()
    },
  })

  const isLoading = foldersLoading || folderLoading || updateLoading

  const rename = useEvent(() => updateRequest(folder.id, { name }))

  return (
    <Modal
      id={MODAL_TYPES.RENAME_FOLDER}
      title={t(`Rename folder`)}
      isOpen={isOpen}
      showLoading={isLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!updateError}
            message={updateError}
            hideSupportLink
          />
          <div className={styles.field}>
            <TextField
              id="name"
              label={t('Folder name')}
              value={name}
              onChange={setName}
              required
            />
          </div>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={rename} disabled={name === '' || isLoading}>
            {t('Rename')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default FolderRenameModal
