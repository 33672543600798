import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import { ProgressBar } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks/useRequest'
import { putScriptQuestion } from 'requests/phoneBanks'
import ContentQuestion from './ContentQuestion'
import Actions from '../Actions'
import ReorderActions from '../ReorderActions'
import styles from '../ScriptItem.module.scss'
import { iconIndex } from '../utils'

const FIRST_TAG = 0

const ScriptQuestion = ({
  item: incomingItem,
  content: incomingContent,
  choices,
  loading,
  updateContent,
  isOnTop,
  isOnBottom,
  moveUp,
  moveDown,
  setResponse,
  disabled,
  removeSelf,
  isEditable = true,
}) => {
  const { t } = useTranslation()

  const [isEditingItem, setIsEditingItem] = useState(false)

  const [newQuestionText, setQuestionText] = useState()
  const [newQuestionType, setQuestionType] = useState()
  const [newQuestionChoices, setQuestionChoices] = useState()
  const [newQuestionTag, setQuestionTag] = useState(
    incomingItem.question?.tags?.length > 0 &&
      incomingItem.question.tags[FIRST_TAG]
      ? incomingItem.question.tags[FIRST_TAG].name
      : undefined
  )

  const [item, setItem] = useState(incomingItem || {})
  const [content, setContent] = useState(incomingContent || '')

  const itemId = item.question?.id
  const type = item.question?.type
  const tag =
    item.question?.tags?.length > 0 && item.question.tags[0]
      ? item.question.tags[0].name
      : undefined

  useEffect(() => {
    setContent(incomingContent || '')
  }, [incomingContent])

  useEffect(() => {
    setItem(incomingItem || {})
  }, [incomingItem])

  const {
    makeRequest: scriptQuestionRequest,
    isLoading: scriptQuestionLoading,
    hasErrors: scriptQuestionHasErrors,
  } = useRequest(putScriptQuestion, {
    onSuccess: ({ 'phone_banking/question': question }) => {
      setIsEditingItem(false)
      setItem({ question })
    },
  })

  const makeUpdateScriptQuestionRequest = () => {
    const isOpenEnded =
      newQuestionType === 'open-ended' || type === 'open-ended'
    const newChoices = newQuestionChoices
      ? newQuestionChoices.map(c => ({
          id: c.value,
          response: c.label,
        }))
      : choices
    const cleanTag = newQuestionTag?.trim()

    const newQuestion = {
      type: newQuestionType || type,
      question_to_ask: newQuestionText || incomingContent,
      answer_options: !isOpenEnded ? newChoices : undefined,
      tags_list: cleanTag ? [cleanTag.toLowerCase()] : [],
    }

    updateContent(newQuestion)
    scriptQuestionRequest(itemId, newQuestion)
  }

  const handleSaveClick = () => makeUpdateScriptQuestionRequest()

  const wrapperClass = styles['script-item__wrapper']

  const Icon = iconIndex[type]

  const scriptHeader = (
    <div
      className={`${styles['script-item__icon']} ${styles['script-item__icon--accent']}`}
    >
      <Icon />
    </div>
  )

  return (
    <>
      <ProgressBar show={scriptQuestionLoading} />
      <CardError
        hide={!scriptQuestionHasErrors}
        message={t('There was an error editing this item')}
      />
      <div className={wrapperClass}>
        <div className={styles['script-item']}>
          {scriptHeader}
          <div className={styles['script-item__content']}>
            {isEditingItem ? (
              <ContentQuestion.Edit
                type={type}
                content={incomingContent}
                choices={choices}
                tag={tag}
                setQuestionText={setQuestionText}
                setQuestionType={setQuestionType}
                setQuestionChoices={setQuestionChoices}
                setQuestionTag={setQuestionTag}
                contentInState={content}
                setContentInState={setContent}
              />
            ) : (
              <ContentQuestion.Active
                type={type}
                content={incomingContent}
                choices={choices}
                tag={tag}
                itemId={itemId}
                setResponse={setResponse}
                disabled={disabled}
              />
            )}
          </div>
          {isEditable && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isEditingItem ? (
                <Actions.Edit
                  loading={loading}
                  closeEdit={() => setIsEditingItem(false)}
                  handleSaveClick={handleSaveClick}
                />
              ) : (
                <Actions.Read
                  item={incomingItem}
                  loading={loading}
                  setIsEditingItem={setIsEditingItem}
                  removeSelf={removeSelf}
                />
              )}
            </>
          )}
        </div>
        <div className={styles['script-item__reorder-arrows']}>
          <ReorderActions
            loading={loading}
            isOnTop={isOnTop}
            isOnBottom={isOnBottom}
            moveUp={moveUp}
            moveDown={moveDown}
          />
        </div>
      </div>
    </>
  )
}

export default ScriptQuestion
