import { useContext, useEffect, useMemo, useState } from 'react'
import { useBackgroundRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { List, ListItem, Avatar } from 'components'
import { useCurrent } from 'contexts'
import { DashboardFilterContext } from 'dashboard/DashboardFilterContext/DashboardFilterContext'
import WidgetPanel from 'dashboard/WidgetPanel/WidgetPanel'
import { requestChart, requestChartId } from '../../../requests/charts'
import styles from './EventsAttendance.module.scss'

const EventsAttendance = () => {
  const { t } = useTranslation()

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const {
    startDate,
    endDate,
    turfId: turfIdFilter,
  } = useContext(DashboardFilterContext)

  const filters = useMemo(
    () => ({ startDate, endDate, turfId: turfIdFilter }),
    [startDate, endDate, turfIdFilter]
  )

  const [data, setData] = useState([])

  const { makeRequest, isLoading, errorMsg, response } = useBackgroundRequest({
    key: 'events_attendance_by_organizer',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters,
      }),
    requestData: requestChart,
  })

  useEffect(() => {
    makeRequest()
  }, [filters])

  useEffect(() => {
    if (response) {
      setData(response.rows)
    }
  }, [response])

  return (
    <WidgetPanel
      title={t('Highest group event attendance by organizer')}
      isEmpty={!data.length}
      isLoading={isLoading}
      errorMsg={errorMsg}
    >
      <List className={styles.list}>
        {data.map((listItem, index) => (
          <ListItem
            key={index}
            customIcon={
              <Avatar
                name={`${listItem.first_name} ${listItem.last_name}`}
                subtitle={`${listItem.events_count} event${
                  listItem.events_count > 1 ? 's' : ''
                }`}
              />
            }
            primaryTextClassName="table-filters__column-list__text"
            tileClassName="table-filters__column-list__item"
            listItemNumber={index + 1}
            AdditionalContent={<div>{listItem.attendees_count}</div>}
          />
        ))}
      </List>
    </WidgetPanel>
  )
}

export default EventsAttendance
