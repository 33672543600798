import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchPeople = params =>
  fetchJSON(`/api/v1/people?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchPerson = (id, params) =>
  fetchJSON(`/api/v1/people/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const getAutocomplete = params =>
  fetchJSON(
    `/api/v1/people/autocomplete?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchPeopleTags = params =>
  fetchJSON(`/api/v1/people/tags?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const putPerson = (id, attrs, params) =>
  fetchJSON(
    `/api/v1/people/${id}?${stringifyParams(params)}`,
    'PUT',
    { person: attrs },
    {
      useJwt: true,
    }
  )

export const postPerson = (attrs, params) =>
  fetchJSON(
    `/api/v1/people?${stringifyParams(params)}`,
    'POST',
    {
      person: {
        ...attrs,
        ethnicities: (attrs.ethnicities || []).map(
          ethnicityOption => ethnicityOption.value
        ),
      },
    },
    { useJwt: true }
  )

export const destroyPerson = id =>
  fetchJSON(`/api/v1/people/${id}`, 'DELETE', null, { useJwt: true })

export const postOrganizationMembership = (personId, membership, params) =>
  fetchJSON(
    `/api/v1/people/${personId}/organization_memberships?${stringifyParams(
      params
    )}`,
    'POST',
    {
      organization_membership: membership,
    },
    { useJwt: true }
  )

export const destroyOrganizationMembership = async (personId, membershipId) => {
  await fetchJSON(
    `/api/v1/people/${personId}/organization_memberships/${membershipId}`,
    'DELETE',
    null,
    { useJwt: true }
  )
  return membershipId
}

export const putTeamMembership = (personId, membershipId, data, params) =>
  fetchJSON(
    `/api/v1/people/${personId}/team_memberships/${membershipId}?${stringifyParams(
      params
    )}`,
    'PUT',
    { team_membership: data },
    { useJwt: true }
  )

export const postTeamMembership = (personId, data, params) =>
  fetchJSON(
    `/api/v1/people/${personId}/team_memberships?${stringifyParams(params)}`,
    'POST',
    { team_membership: data },
    {
      useJwt: true,
    }
  )

export const destroyTeamMembership = (personId, membershipId) =>
  fetchJSON(
    `/api/v1/people/${personId}/team_memberships/${membershipId}`,
    'DELETE',
    null,
    { useJwt: true }
  )

export const fetchPeopleDuplicates = params =>
  fetchJSON(
    `/api/v1/people/duplicates?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchPositionOptions = () =>
  fetchJSON(`/api/v1/people/positions`, 'GET', null, { useJwt: true })

export const postRelationship = (relationship, params) =>
  fetchJSON(
    `/api/v1/relationships?${stringifyParams(params)}`,
    'POST',
    {
      relationship,
    },
    { useJwt: true }
  )

export const putRelationship = (relationshipId, relationship, params) =>
  fetchJSON(
    `/api/v1/relationships/${relationshipId}?${stringifyParams(params)}`,
    'PUT',
    {
      relationship,
    },
    { useJwt: true }
  )

export const deleteRelationship = async relationshipId => {
  await fetchJSON(`/api/v1/relationships/${relationshipId}`, 'DELETE', null, {
    useJwt: true,
  })
  return relationshipId
}

export const fetchContactLog = (personId, params) =>
  fetchJSON(
    `/api/v1/people/${personId}/contacts?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const postContactLog = (personId, data) =>
  fetchJSON(
    `/api/v1/people/${personId}/contacts`,
    'POST',
    { contact: data },
    {
      useJwt: true,
    }
  )

export const postNote = (personId, note) =>
  fetchJSON(
    `/api/v1/people/${personId}/notes`,
    'POST',
    { note },
    { useJwt: true }
  )

export const putNote = (personId, note) =>
  fetchJSON(
    `/api/v1/people/${personId}/notes/${note.id}`,
    'PUT',
    { note },
    { useJwt: true }
  )

export const destroyNote = (personId, noteId) =>
  fetchJSON(`/api/v1/people/${personId}/notes/${noteId}`, 'DELETE', null, {
    useJwt: true,
  })

export const fetchPeopleDistricts = params =>
  fetchJSON(
    `/api/v1/people/districts?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const updateEventAttendee = (eventId, attendeeId, attendeeAttrs) =>
  fetchJSON(
    `/api/v1/events/${eventId}/attendees/${attendeeId}`,
    'PUT',
    {
      attendance: attendeeAttrs,
    },
    {
      useJwt: true,
    }
  )

export const removeEventAttendee = (eventId, attendeeId) =>
  fetchJSON(
    `/api/v1/events/${eventId}/attendees/${attendeeId}`,
    'DELETE',
    null,
    {
      useJwt: true,
    }
  )

export const updateEventShiftVolunteer = (
  eventId,
  shiftId,
  volunteerId,
  role
) => {
  const params = stringifyParams({
    fields: [
      'id',
      'responsibility',
      {
        person: ['name', 'id'],
      },
    ],
  })
  fetchJSON(
    `/api/v1/events/${eventId}/shifts/${shiftId}/volunteers/${volunteerId}?${params}`,
    'PUT',
    {
      volunteer: {
        responsibility: role,
      },
    },
    { useJwt: true }
  )
}

export const removeEventShiftVolunteer = (eventId, shiftId, volunteerId) =>
  fetchJSON(
    `/api/v1/events/${eventId}/shifts/${shiftId}/volunteers/${volunteerId}`,
    'DELETE',
    null,
    { useJwt: true }
  )

export const cancelMeeting = meetingId =>
  fetchJSON(
    `/api/v1/meetings/${meetingId}`,
    'PUT',
    { meeting: { cancelled: true } },
    { useJwt: true }
  )
