import { useTranslation } from 'react-i18next'
import { SectionLabel, Sheet, ContentBlock } from 'components'
import MainSectionHeader from './MainSectionHeader'
import TableManagerTabs from './TableManagerTabs/TableManagerTabs'

const TableManager = () => {
  const { t } = useTranslation()

  return (
    <ContentBlock>
      <MainSectionHeader />
      <SectionLabel tight>{t('Pending Flags')}</SectionLabel>
      <Sheet>
        <TableManagerTabs />
      </Sheet>
    </ContentBlock>
  )
}

export default TableManager
