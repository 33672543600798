import { useEffect, useState } from 'react'
import { useRequest } from 'hooks'
import { fetchCurrentTenant } from 'requests/current'
import TenantError from 'auth/TenantError/TenantError'
import GenericErrorPage from 'auth/GenericErrorPage/GenericErrorPage'
import { ProgressBar } from '@politechdev/blocks-design-system'

const LOADING = 'LOADING'
const NOT_FOUND = 'NOT_FOUND'
const ERROR = 'ERROR'
const SUCCESS = 'SUCCESS'

const Loader = ({ children }) => {
  const [tenantStatus, setTenantStatus] = useState(LOADING)
  const { makeRequest } = useRequest(fetchCurrentTenant, {
    onSuccess: () => {
      setTenantStatus(SUCCESS)
    },
    onError: response => {
      if (response.status === 404) {
        setTenantStatus(NOT_FOUND)
      } else if (response.status >= 500) {
        setTenantStatus(ERROR)
      } else {
        setTenantStatus(SUCCESS)
      }
    },
  })

  useEffect(() => {
    makeRequest(true)
  }, [])

  switch (tenantStatus) {
    case LOADING: {
      return <ProgressBar show />
    }
    case NOT_FOUND: {
      return <TenantError />
    }
    case SUCCESS: {
      return children
    }
    case ERROR:
    default: {
      return <GenericErrorPage />
    }
  }
}

export default Loader
