import moment from 'moment'

export const userRoleNameCsvCell = data => data.role?.name

export const userTurfNameCsvCell = data => data.turf?.name

export const userLastSeenCsvCell = data =>
  data.last_sign_in_at
    ? moment(data.last_sign_in_at).format('MMM Do YYYY')
    : 'Never'
