import qcTimelineCalculations from '../calculations/qcTimelineCalculations'

const baseQcSummaryColumns = [
  {
    dataKey: 'total_hours_day',
    title: 'Total Hours (Day)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('day').total_hours.derive,
    summaryFunction: qcTimelineCalculations('day').total_hours.summary,
  },
  {
    dataKey: 'total_hours_week',
    title: 'Total Hours (Week)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('week').total_hours.derive,
    summaryFunction: qcTimelineCalculations('week').total_hours.summary,
  },
  {
    dataKey: 'total_hours_all_time',
    title: 'Total Hours (All time)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('all_time').total_hours.derive,
    summaryFunction: qcTimelineCalculations('all_time').total_hours.summary,
  },
  {
    dataKey: 'total_scans_viz_day',
    title: 'Forms Viz QC (Day)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('day').total_scans_viz.derive,
    summaryFunction: qcTimelineCalculations('day').total_scans_viz.summary,
  },
  {
    dataKey: 'total_scans_viz_week',
    title: 'Forms Viz QC (Week)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('week').total_scans_viz.derive,
    summaryFunction: qcTimelineCalculations('week').total_scans_viz.summary,
  },
  {
    dataKey: 'total_scans_viz_all_time',
    title: 'Forms Viz QC (All time)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('all_time').total_scans_viz.derive,
    summaryFunction: qcTimelineCalculations('all_time').total_scans_viz.summary,
  },
  {
    dataKey: 'total_scans_phone_day',
    title: 'Forms called (Day)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('day').total_scans_phone.derive,
    summaryFunction: qcTimelineCalculations('day').total_scans_phone.summary,
  },
  {
    dataKey: 'total_scans_phone_week',
    title: 'Forms called (Week)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('week').total_scans_phone.derive,
    summaryFunction: qcTimelineCalculations('week').total_scans_phone.summary,
  },
  {
    dataKey: 'total_scans_phone_all_time',
    title: 'Forms called (All time)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('all_time').total_scans_phone.derive,
    summaryFunction:
      qcTimelineCalculations('all_time').total_scans_phone.summary.summary,
  },
  {
    dataKey: 'role_name',
    title: 'Role name',
    type: 'string',
    resizable: true,
  },
  {
    dataKey: 'qc_office',
    title: 'QC Office',
    type: 'string',
    resizable: true,
  },
  {
    dataKey: 'total_shifts_day',
    title: 'Total shifts (Day)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('day').total_shifts.derive,
    summaryFunction: qcTimelineCalculations('day').total_shifts.summary,
  },
  {
    dataKey: 'total_shifts_week',
    title: 'Total shifts (Week)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('week').total_shifts.derive,
    summaryFunction: qcTimelineCalculations('week').total_shifts.summary,
  },
  {
    dataKey: 'total_shifts_all_time',
    title: 'Total shifts (All time)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('all_time').total_shifts.derive,
    summaryFunction: qcTimelineCalculations('all_time').total_shifts.summary,
  },
  {
    dataKey: 'avg_shift_length_day',
    title: 'Avg Shift Length (in hours) (Day)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('day').avg_shift_length.derive,
    summaryFunction: qcTimelineCalculations('day').avg_shift_length.summary,
  },
  {
    dataKey: 'avg_shift_length_week',
    title: 'Avg Shift Length (in hours) (Week)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('week').avg_shift_length.derive,
    summaryFunction: qcTimelineCalculations('week').avg_shift_length.summary,
  },
  {
    dataKey: 'avg_shift_length_all_time',
    title: 'Avg Shift Length (in hours) (All time)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('all_time').avg_shift_length.derive,
    summaryFunction:
      qcTimelineCalculations('all_time').avg_shift_length.summary,
  },
  {
    dataKey: 'viz_qc_rate_day',
    title: 'Viz QC rate (per hour) (Day)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('day').viz_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('day').viz_qc_rate.summary,
  },
  {
    dataKey: 'viz_qc_rate_week',
    title: 'Viz QC rate (per hour) (Week)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('week').viz_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('week').viz_qc_rate.summary,
  },
  {
    dataKey: 'viz_qc_rate_all_time',
    title: 'Viz QC rate (per hour) (All time)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('all_time').viz_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('all_time').viz_qc_rate.summary,
  },
  {
    dataKey: 'phone_qc_rate_day',
    title: 'Phone QC rate (per hour) (Day)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('day').phone_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('day').phone_qc_rate.summary,
  },
  {
    dataKey: 'phone_qc_rate_week',
    title: 'Phone QC rate (per hour) (Week)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('week').phone_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('week').phone_qc_rate.summary,
  },
  {
    dataKey: 'phone_qc_rate_all_time',
    title: 'Phone QC rate (per hour) (All time)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('all_time').phone_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('all_time').phone_qc_rate.summary,
  },
  {
    dataKey: 'total_qc_rate_day',
    title: 'Total QC rate (per hour) (Day)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('day').total_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('day').total_qc_rate.summary,
  },
  {
    dataKey: 'total_qc_rate_week',
    title: 'Total QC rate (per hour) (Week)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('week').total_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('week').total_qc_rate.summary,
  },
  {
    dataKey: 'total_qc_rate_all_time',
    title: 'Total QC rate (per hour) (All time)',
    type: 'number',
    resizable: true,
    useRawData: true,
    deriveFunction: qcTimelineCalculations('all_time').total_qc_rate.derive,
    summaryFunction: qcTimelineCalculations('all_time').total_qc_rate.summary,
  },
]

export default baseQcSummaryColumns
