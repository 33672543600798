import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Font,
  Section,
  Modal,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { VDR_ISSUES } from '../constants'

const VDRIssues = ({ vdrIssues, county, canvasser }) => {
  const { full_name } = canvasser

  if (vdrIssues === VDR_ISSUES.NO_MATCHING_VDR) {
    return (
      <Trans>
        You have selected <b>{{ county }}</b> as the county for residential
        address, but there is no associated VDR number for canvasser{' '}
        <b>{{ full_name }}</b> assigned to this shift. Please edit the
        residential address, add the corresponding VDR number to{' '}
        <Link
          to={`/collect/voter_registration/canvassers/${canvasser.id}/edit`}
          target="_blank"
        >
          canvasser’s profile
        </Link>{' '}
        or continue without a VDR number.
      </Trans>
    )
  }

  if (vdrIssues === VDR_ISSUES.COUNTY) {
    return (
      <Trans>
        You have not selected a county for residential address. Please edit the
        residential address or continue without a VDR number.
      </Trans>
    )
  }
}

const PotentialIssuesModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  canvasser,
  county,
  potentialIssues,
}) => {
  const { t } = useTranslation()
  const { vdrIssues, missingFieldsForDisplay, hasMissingFields, hasVDRIssues } =
    potentialIssues || {}

  const translateLabelUnlessCustom = (field, key) => {
    const lableable = key || field
    return field.custom ? lableable.label : t(lableable.label)
  }

  const onApply = () => {
    onConfirm(hasVDRIssues ? { no_vdr_match: true } : undefined)
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      id="warn-cancel-data-entry"
      title={t('Continue with missing information?')}
      isOpen={isOpen}
    >
      <Modal.Body>
        {hasVDRIssues && (
          <Section label={t('Missing VDR')}>
            <TextBlock>
              <Font.Label variant="section">{}</Font.Label>
              <Font.Copy Element="p">
                <VDRIssues
                  vdrIssues={vdrIssues}
                  county={county}
                  canvasser={canvasser}
                />
              </Font.Copy>
            </TextBlock>
          </Section>
        )}
        {hasMissingFields && (
          <Section label={t('Missing fields')}>
            <TextBlock>
              {missingFieldsForDisplay.map(section => (
                <Fragment key={section.label}>
                  <span>{t(section.label)}</span>
                  <ul>
                    {section.fields.map(fieldLike => {
                      let fieldLabel = ''
                      if (!fieldLike.subfieldLabel) {
                        fieldLabel = translateLabelUnlessCustom(fieldLike)
                      } else {
                        fieldLabel = fieldLike.label
                          ? `${translateLabelUnlessCustom(fieldLike)} - `
                          : ''
                        fieldLabel += fieldLike.subfieldLabel
                      }
                      return <li key={fieldLabel}>{fieldLabel}</li>
                    })}
                  </ul>
                </Fragment>
              ))}
            </TextBlock>
          </Section>
        )}
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Go back')}</Button.Secondary>
          <Button.Accent onClick={onApply}>
            {t('Submit with missing information')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default PotentialIssuesModal
