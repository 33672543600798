import { DetailItem } from '@politechdev/blocks-design-system'
import humanizeString from 'humanize-string'
import { CustomFieldConfig, CustomFieldValue } from './CustomFields.types'
import CustomFields from './CustomFields'

const CustomFieldsDisplay = ({
  fields,
  onChange,
  currentData,
  disabled,
  isEditing = false,
}: {
  fields: CustomFieldConfig[]
  onChange: (attribute: string, value: CustomFieldValue) => unknown
  currentData: Record<string, CustomFieldValue>
  disabled?: boolean
  isEditing?: boolean
}) => (
  <>
    {fields.map(field => {
      const label = field.label || humanizeString(field.name)
      const value = currentData[field.name]

      if (!isEditing && !value && value !== 0) {
        return null
      }
      return (
        <DetailItem label={label}>
          {isEditing ? (
            <CustomFields
              fields={[field]}
              currentData={currentData}
              onChange={onChange}
              disabled={disabled}
            />
          ) : (
            value
          )}
        </DetailItem>
      )
    })}
  </>
)

export default CustomFieldsDisplay
