import React from 'react'
import { useTranslation } from 'react-i18next'
import { Section, LinkToClipboard } from 'components'
import { ContentBlock, TextBlock } from '@politechdev/blocks-design-system'

const SessionLinks = ({ sessionType, hasQuestions, isOpen }) => {
  const { t } = useTranslation()

  const urlBase = `${window.location.href}/call_sessions`

  const callSessionUrls = {
    webOrManualAgent: `${urlBase}/new`,
    powerDialerAgent: `${urlBase}/agent-onboarding`,
    powerDialerOperator: `${urlBase}/operator-onboarding`,
  }

  if (hasQuestions && isOpen) {
    switch (sessionType) {
      case 'power_dialer':
        return (
          <>
            <Section label="Operator link">
              <ContentBlock>
                <LinkToClipboard url={callSessionUrls.powerDialerOperator} />
              </ContentBlock>
              <TextBlock>
                <p>{t('Access the power dialer operator')}</p>
              </TextBlock>
            </Section>
            <Section label="Agent call session link">
              <ContentBlock>
                <LinkToClipboard url={callSessionUrls.powerDialerAgent} />
              </ContentBlock>
              <TextBlock>
                <p>
                  {t(
                    'You can send this link to agents participating in your power dialer session'
                  )}
                </p>
              </TextBlock>
            </Section>
          </>
        )
      default:
        return (
          <Section label="Session link">
            <ContentBlock>
              <LinkToClipboard url={callSessionUrls.webOrManualAgent} />
            </ContentBlock>
          </Section>
        )
    }
  }

  return (
    <Section label={t('Call session link')}>
      <TextBlock>
        {!isOpen && <p>{t('This phone bank is closed.')}</p>}
        {!hasQuestions && (
          <p>{t('Please add at least one question to the call script.')}</p>
        )}
      </TextBlock>
    </Section>
  )
}

export default SessionLinks
