import React, { Component } from 'react'
import { Button } from '@politechdev/blocks-design-system'
import AudioAnalyser from './AudioAnalyser'
import styles from './TestMicrophone.module.scss'

class TestMicrophone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      audio: null,
    }
    this.toggleMicrophone = this.toggleMicrophone.bind(this)
  }

  async getMicrophone() {
    const audio = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    })
    this.setState({ audio })
  }

  stopMicrophone() {
    this.state.audio.getTracks().forEach(track => track.stop())
    this.setState({ audio: null })
  }

  toggleMicrophone() {
    if (this.state.audio) {
      this.stopMicrophone()
      if (typeof this.props.onClick === 'function') {
        this.props.onClick()
      }
    } else {
      this.getMicrophone()
    }
  }

  render() {
    return (
      <div className={styles.testMicrophone}>
        <div>
          <Button.Secondary onClick={this.toggleMicrophone}>
            {this.state.audio ? 'Stop microphone' : 'Test Microphone'}
          </Button.Secondary>
        </div>
        {this.state.audio ? <AudioAnalyser audio={this.state.audio} /> : ''}
      </div>
    )
  }
}

export default TestMicrophone
