import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  TextBlock,
  ButtonBlock,
  Modal,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchListFolders, moveLists } from 'requests/lists'
import { DEFAULT_LIST_FOLDER_NAME } from 'constants/lists'
import { useEffect } from 'react'
import { useListCheckboxes } from '../ListCheckboxProvider/ListCheckboxProvider'
import styles from './ListRemoveModal.module.scss'

const ListRemoveModal = ({ isOpen, closeModal }) => {
  const { t } = useTranslation()

  const { getCheckedCheckboxValues, uncheckAll } = useListCheckboxes()
  const selectedCount = getCheckedCheckboxValues().length

  const { makeRequest, isLoading, hasErrors } = useRequest(moveLists, {
    onSuccess: () => {
      closeModal()
      uncheckAll()
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: getDefaultFolder,
    isRequestComplete: defaultFolderLoaded,
    isLoading: defaultFolderIsLoading,
    response: defaultFolderResponse,
  } = useRequest(() =>
    fetchListFolders({
      fields: ['id'],
      filters: {
        rules: [
          { column: 'name', operator: 'is', param: DEFAULT_LIST_FOLDER_NAME },
        ],
      },
    })
  )

  useEffect(() => {
    getDefaultFolder()
  }, [])

  const defaultFolderId =
    defaultFolderLoaded && defaultFolderResponse.list_folders.length > 0
      ? defaultFolderResponse.list_folders[0].id
      : undefined

  const removeSelectedLists = () => {
    makeRequest(getCheckedCheckboxValues(), defaultFolderId)
  }

  return (
    <Modal
      id="remove-lists"
      title={t(`Remove ${selectedCount === 1 ? 'list' : 'lists'}`)}
      isOpen={isOpen}
      showLoading={isLoading || defaultFolderIsLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!hasErrors}
            message={t(
              `There was an error removing your selected ${
                selectedCount === 1 ? 'list' : 'lists'
              }`
            )}
            hideSupportLink
          />
          <TextBlock className={styles.text}>
            {t('Remove')} <strong>{selectedCount}</strong>{' '}
            {t(`${selectedCount === 1 ? 'list' : 'lists'} from this folder`)}
          </TextBlock>
          <TextBlock className={styles.text} muted>
            {t('Removed lists get sent back to your default folder.')}
          </TextBlock>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Danger
            onClick={removeSelectedLists}
            disabled={defaultFolderIsLoading}
          >
            {t('Remove')}
          </Button.Danger>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ListRemoveModal
