import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Font } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import styles from './SectionLabel.module.scss'

const cx = classNames.bind(styles)

class SectionLabel extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    primary: PropTypes.bool,
    accent: PropTypes.bool,
    tight: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const { className, children, primary, accent, tight } = this.props

    return (
      <div
        className={cx('label', className, {
          'label--primary': primary,
          'label--accent': accent,
          'label--tight': tight,
        })}
      >
        <Font.Label variant="section">{children}</Font.Label>
      </div>
    )
  }
}

export default SectionLabel
