import moment from 'moment'
import { cloneDeep, isEmpty, merge } from 'lodash'
import { DATE_FORMAT_YEAR_MONTH_DAY } from 'utils/constants'
import { USER_TYPES } from 'constants/qualityControl'

export const getSelectedDay = dayIndex => {
  const currentDay = new Date().getDay()
  const isLastWeek = currentDay <= dayIndex
  const weekAdjustment = isLastWeek ? -7 : 0
  const selectedDate = moment().day(dayIndex + weekAdjustment)
  return selectedDate.format(DATE_FORMAT_YEAR_MONTH_DAY)
}

export const isUserScheduled = (existingSchedules, schedule) => {
  if (schedule.user === undefined || isEmpty(schedule.user)) return false
  return existingSchedules.some(({ user }) => schedule.user.id === user.id)
}

export const isNewSchedule = schedule => schedule.id === undefined

export const filterManagers = ({ user_type: userType }) =>
  userType === USER_TYPES.MANAGER

export const filterStaff = ({ user_type: userType }) =>
  userType === USER_TYPES.STAFF

export const getDisplayableSchedules = ({
  existingSchedules,
  newSchedules,
  updatedSchedules,
}) => {
  let schedules = cloneDeep(existingSchedules)
  schedules = schedules
    .map(schedule => {
      const updates = updatedSchedules.find(
        update => update.user.id === schedule.user.id
      )
      return updates ? merge(schedule, updates) : schedule
    })
    .filter(({ remove }) => !remove)

  return [...schedules, ...newSchedules]
}

export const addSchedule = setNewSchedules => schedule => {
  setNewSchedules(current => [...current, schedule])
}

export const updateSchedule =
  ({ setNewSchedules, setUpdatedSchedules }) =>
  schedule => {
    const updateAction = isNewSchedule(schedule)
      ? setNewSchedules
      : setUpdatedSchedules

    updateAction(current => {
      const currentIndex = current.findIndex(
        ({ localId }) => localId === schedule.localId
      )

      if (currentIndex === -1) {
        return [...current, schedule]
      }

      const updated = cloneDeep(current)
      updated.splice(currentIndex, 1, schedule)
      return updated
    })
  }

export const removeSchedule =
  ({ setNewSchedules, setUpdatedSchedules }) =>
  schedule => {
    if (isNewSchedule(schedule)) {
      setNewSchedules(current =>
        current.filter(({ localId }) => localId !== schedule.localId)
      )
    } else {
      setUpdatedSchedules(current => {
        const removedSchedule = { ...schedule, remove: true }
        const currentIndex = current.findIndex(
          ({ localId }) => localId === removedSchedule.localId
        )

        if (currentIndex === -1) {
          return [...current, removedSchedule]
        }

        const updated = cloneDeep(current)
        updated.splice(currentIndex, 1, removedSchedule)
        return updated
      })
    }
  }
