import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
  SelectField,
  Section,
  SectionLabel,
  TextBlock,
  FieldBlock,
  ButtonBlock,
  Button,
  Icon,
  DateField,
  TimePicker,
} from '@politechdev/blocks-design-system'

const RecurringEvents = ({
  eventDate,
  recurringInterval,
  setRecurringInterval,
  recurringUntil,
  setRecurringUntil,
  recurringShifts,
  setRecurringShifts,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <FieldBlock>
        <SelectField
          label={t('Recurring interval')}
          value={recurringInterval}
          onSelect={val => setRecurringInterval(val)}
          options={[
            { value: 'day', label: 'Every day' },
            { value: 'week', label: 'Every week' },
            { value: 'month', label: 'Every month' },
          ]}
          required
        />
        <DateField
          label={t('Recurring until')}
          value={recurringUntil}
          onChange={setRecurringUntil}
          required
          maxDate={new Date().fp_incr(365).toISOString()}
          minDate={eventDate}
          hint={t('1 year max')}
        />
      </FieldBlock>
      <Section>
        <TextBlock>
          <SectionLabel>Recurring shifts</SectionLabel>
        </TextBlock>
        {recurringShifts.map((s, i) => (
          <FieldBlock key={s.id}>
            <TimePicker
              label="Shift start"
              id="shift-start-time"
              onSelect={timeObj => {
                const newRecurringShifts = recurringShifts
                newRecurringShifts.splice(i, 1, {
                  id: s.id,
                  start: timeObj,
                  end: s.end,
                })
                setRecurringShifts(() => [...newRecurringShifts])
              }}
              hour={s.start.hour}
              minute={s.start.minute}
              error={moment(s.start).isAfter(moment(s.end))}
              errorMessage={t('Start time must be before end time.')}
            />
            <TimePicker
              label="Shift end"
              id="shift-end-time"
              onSelect={timeObj => {
                const newRecurringShifts = recurringShifts
                newRecurringShifts.splice(i, 1, {
                  id: s.id,
                  start: s.start,
                  end: timeObj,
                })
                setRecurringShifts(() => [...newRecurringShifts])
              }}
              hour={s.end.hour}
              minute={s.end.minute}
            />
            <Button.Secondary
              onClick={() => {
                const newRecurringShifts = recurringShifts
                newRecurringShifts.splice(i, 1)
                setRecurringShifts(() => [...newRecurringShifts])
              }}
            >
              <Icon.Times alt={t('Cancel')} />
            </Button.Secondary>
          </FieldBlock>
        ))}
        <ButtonBlock>
          <Button.Secondary
            onClick={() => {
              const nextId = recurringShifts.length
                ? Math.max(...recurringShifts.map(x => x.id)) + 1
                : 0
              setRecurringShifts(prev => [
                ...prev,
                { id: nextId, start: { hour: 9 }, end: { hour: 17 } },
              ])
            }}
          >
            <Icon.Plus alt={t('Add Recurring Shift')} />
          </Button.Secondary>
        </ButtonBlock>
      </Section>
    </>
  )
}

export default RecurringEvents
