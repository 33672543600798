import { useRef } from 'react'
import { Switch, Route } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { ContextMenuProvider } from 'contexts'
import { useReactRouter } from 'hooks'
import { ModalProvider } from '../ModalProvider/ModalProvider'
import { ClipboardProvider } from '../ClipboardProvider/ClipboardProvider'
import {
  PermissionsProvider,
  usePermissions,
} from '../PermissionsProvider/PermissionsProvider'
import FolderRedirect from '../FolderRedirect/FolderRedirect'
import Sidebar from '../Sidebar/Sidebar'
import FolderSingle from '../FolderSingle/FolderSingle'
import PanelMessage from '../PanelMessage/PanelMessage'
import Search from '../Search/Search'
import Modals from '../Modals/Modals'
import styles from './DocumentBrowser.module.scss'
import { DocumentContextProvider } from '../DocumentContext/DocumentContext'

const DocumentBrowser = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const browserRef = useRef()
  const { canView } = usePermissions()

  if (!canView) {
    return (
      <PanelMessage
        title={t('You do not have permission to view this page')}
        message={t(
          'If you believe this is an error please contact your administrator'
        )}
      />
    )
  }

  return (
    <>
      <div className={styles.browser} ref={browserRef}>
        <Sidebar browserRef={browserRef} />
        <div className={styles.panel}>
          <Switch>
            <Route exact path={match.path} component={FolderRedirect} />
            <Route exact path={`${match.path}/:id`} component={FolderSingle} />
            <Route exact path={`${match.path}/:id/search`} component={Search} />
          </Switch>
        </div>
      </div>
      <Modals />
    </>
  )
}
export default () => (
  <DndProvider backend={HTML5Backend}>
    <PermissionsProvider>
      <ContextMenuProvider>
        <DocumentContextProvider>
          <ClipboardProvider>
            <ModalProvider>
              <DocumentBrowser />
            </ModalProvider>
          </ClipboardProvider>
        </DocumentContextProvider>
      </ContextMenuProvider>
    </PermissionsProvider>
  </DndProvider>
)
