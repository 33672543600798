import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ViewContainer, CardError, View } from 'components'
import {
  Sheet,
  Section,
  DetailItem,
  Grid,
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { formatPhoneNumber } from 'utils/formatting'
import { useCurrent } from 'contexts/index'
import { fetchTeam } from 'requests/teams'
import { defaultTo } from 'lodash'
import TeamMembers from '../TeamMembers/TeamMembers'

const TeamSingle = () => {
  const { t } = useTranslation()
  const { location, match } = useReactRouter()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewTeamMemberships = doesCurrentUserHavePermission({
    resource: 'team_membership',
    ability: 'view',
  })
  const canModifyTeamMemberships = doesCurrentUserHavePermission({
    resource: 'team_membership',
    ability: 'modify',
  })

  const {
    makeRequest: teamRequest,
    isLoading,
    hasErrors,
    isRequestComplete,
    response,
  } = useRequest(fetchTeam)

  const { 'people/team': currentTeam } = defaultTo(response, {
    'people/team': {},
  })

  useEffect(() => {
    teamRequest(match.params.teamId, {
      fields: [
        'id',
        'name',
        'slug',
        'type',
        'active',
        { organizer: ['name'] },
        { campaigns: ['name'] },
        { organizations: ['id', 'name'] },
        { leader: ['name', 'primary_phone_number', 'full_address'] },
      ],
      associations: ['organizer', 'campaigns', 'leader', 'organizations'],
    })
  }, [match.params.teamId])

  const teamLoaded =
    isRequestComplete &&
    !hasErrors &&
    (currentTeam.id === +match.params.teamId ||
      currentTeam.slug === match.params.teamId)

  const campaignNames = defaultTo(currentTeam.campaigns, [])
    .map(campaign => campaign.name)
    .join(', ')
  const organizationNames = defaultTo(currentTeam.organizations, [])
    .map(organization => organization.name)
    .join(', ')
  const teamOrganizer = defaultTo(currentTeam.organizer, {})
  const teamLeader = defaultTo(currentTeam.leader, {})

  if (!canViewTeamMemberships) {
    return (
      <CardError
        hide={canModifyTeamMemberships}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <PageHeader title={currentTeam.name}>
            <ButtonBlock justify="right">
              <Link
                to={{
                  pathname: `${location.pathname}/edit`,
                  state: { returnPath: location.pathname },
                }}
              >
                <Button aria-label={t('Edit ')}>
                  <Icon.Pencil />
                </Button>
              </Link>
            </ButtonBlock>
          </PageHeader>
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this team")}
          />
          {teamLoaded && (
            <Grid>
              <Section label={t('Details')}>
                <DetailItem label={t('Organizer')}>
                  {teamOrganizer.name}
                </DetailItem>
                <DetailItem label={t('Organizations')}>
                  {organizationNames}
                </DetailItem>
                <DetailItem label={t('Type')}>{currentTeam.type}</DetailItem>
                <DetailItem label={t('Campaigns')}>{campaignNames}</DetailItem>
                <DetailItem label={t('Status')}>
                  {currentTeam.active ? t('Active') : t('Inactive')}
                </DetailItem>
              </Section>
              <Section label={t('Leadership')}>
                <DetailItem label={t('Leader name')}>
                  {teamLeader.name}
                </DetailItem>
                <DetailItem label={t('Phone number')}>
                  {formatPhoneNumber(teamLeader.primary_phone_number)}
                </DetailItem>
                <DetailItem label={t('Address')}>
                  {teamLeader.full_address}
                </DetailItem>
              </Section>
            </Grid>
          )}
        </Sheet>
        <TeamMembers />
      </ViewContainer>
    </View>
  )
}

export default TeamSingle
