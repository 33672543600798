import {
  basePartyLeanColumns,
  partyLeanDataDictionaryUrl,
} from '../columns/basePartyLeanColumns'

export default {
  id: 'party_lean_by_turf',
  name: 'Turf',
  description:
    'This report provides information on the party lean values of registrants. It groups data to the children of the turf selected.',
  dataDictionaryUrl: partyLeanDataDictionaryUrl,
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    ...basePartyLeanColumns,
  ],
}
