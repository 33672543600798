import { orderBy } from 'lodash'
import moment from 'moment'
import {
  ENGLISH,
  notContactedChoices,
  excludeFromQueue,
  NotContactedChoice,
} from '../../constants/qcCallScriptsConfig/phoneVerificationQuestions'
import {
  PacketScan,
  PhoneVerificationResponse,
  PhoneVerificationCall,
  Scan,
} from './types'

export const castToBool = (value: string | boolean): boolean => {
  if (typeof value !== 'string') return value

  return value.toLowerCase() === 'true'
}

const attemptResultsMap = notContactedChoices[ENGLISH].reduce(
  (acc, choice) => {
    acc[choice.value] = choice.label
    return acc
  },
  { t: 'Verified', f: 'Not verified' } as Record<
    NotContactedChoice | 't' | 'f',
    string
  >
)

export const getIsScanExcludedByVisualReview = (scan: PacketScan) =>
  scan.visual_reviews.some(
    review =>
      review.response.implies_not_form ||
      review.response.implies_skips_phone_verification
  )

export const getIsScanExcludedByPreviousNotContactedResponse = (
  scan: PacketScan
) =>
  scan.phone_verification_responses.some(phoneVerificationResponse =>
    excludeFromQueue.includes(phoneVerificationResponse.response)
  )

export const countScanAttempts = (
  scanResponses: PacketScan['phone_verification_responses']
) =>
  scanResponses.reduce((highestRound, response) => {
    if (!response) return highestRound
    return Math.max(highestRound, response.round_number)
  }, 0)

export const findLastCompletedPacketRound = (scans: PacketScan[]) => {
  const attempts = scans.map(scan =>
    countScanAttempts(scan.phone_verification_responses)
  )

  return attempts.length ? Math.min(...attempts) : 0
}

const isContacted = (r: PhoneVerificationResponse) => r?.['contacted?']

type FormattedResponse = PhoneVerificationResponse & {
  callTime: PhoneVerificationResponse['created_at']
  contacted: boolean | undefined
  result: string
  notes: string
  number: PhoneVerificationResponse['call']['number']
}
const formatCallData = (r: PhoneVerificationResponse): FormattedResponse => {
  const { notes, ...rest } = r
  return {
    callTime: r.created_at,
    contacted: isContacted(r),
    result: attemptResultsMap[r.response],
    notes: notes || '',
    number: r.call.number,
    ...rest,
  }
}

const isUsefulResponse =
  (verifyingId?: number) => (r: PhoneVerificationResponse) =>
    !isContacted(r) || r.phone_verification_question_id === verifyingId

export const getFailedCallsForResponse = (
  responses: PhoneVerificationResponse[],
  responseCall: FormattedResponse['call'],
  scanPhoneCalls: PhoneVerificationCall[]
) => {
  const responseCallsOldToNew = responses
    .map(response => response.call)
    .sort(
      (callA, callB) =>
        Date.parse(callA.created_at) - Date.parse(callB.created_at)
    )

  const failedCalls = scanPhoneCalls.filter(scanPhoneCall => {
    const responseCallIndex = responseCallsOldToNew.findIndex(
      rCall => rCall.id === responseCall.id
    )
    if (responseCallIndex === 0) {
      return moment(scanPhoneCall.created_at).isBefore(responseCall.created_at)
    }
    return (
      moment(scanPhoneCall.created_at).isBefore(responseCall.created_at) &&
      moment(scanPhoneCall.created_at).isAfter(
        responseCallsOldToNew[responseCallIndex - 1].created_at
      ) &&
      !responseCallsOldToNew.some(
        sortedResponseCall => sortedResponseCall.id === scanPhoneCall.id
      )
    )
  })

  return failedCalls.sort(
    (callA, callB) =>
      Date.parse(callB.created_at) - Date.parse(callA.created_at)
  )
}

export const formatAllAttempts = (
  responses: PhoneVerificationResponse[],
  verifyingId?: number
): FormattedResponse[] => {
  const formatted = responses
    .filter(isUsefulResponse(verifyingId))
    .map(formatCallData)
    .toSorted((a, b) => a.callTime.localeCompare(b.callTime))

  return orderBy(formatted, ['callTime'], ['desc'])
}

export const calculatePhoneVerifiedScanCount = (
  scans: PacketScan[],
  verifyingId: number | undefined
) =>
  scans.filter(scan =>
    scan.phone_verification_responses.some(
      response =>
        response.phone_verification_question_id === verifyingId &&
        response.response === 't'
    )
  ).length

export const isPhoneVerificationComplete = (
  scans: PacketScan[],
  verifiedPercent: number,
  minPhoneVerificationRounds: number,
  minPhoneVerificationPercent: number
) =>
  verifiedPercent >= minPhoneVerificationPercent ||
  findLastCompletedPacketRound(scans) >= minPhoneVerificationRounds

export const isVisualReviewComplete = (scans: Scan[]) =>
  scans.every(
    scan =>
      scan.visual_reviews &&
      scan.visual_reviews.filter(vr => vr.user !== null).length > 0
  )

const setScanPage = (
  index: number,
  setQueryParams: ({ scanId }: { scanId: number }) => void,
  sortedScanIds: number[]
) => {
  setQueryParams({ scanId: sortedScanIds[index] })
}

export const changeScanPage = (
  delta: number,
  {
    currentScanIndex,
    shouldSkip,
    sortedScanIds,
    filteredScanIds,
    setQueryParams,
  }: {
    currentScanIndex: number
    shouldSkip: boolean
    sortedScanIds: number[]
    filteredScanIds: number[]
    setQueryParams: () => void
  }
) => {
  let nextIndex = currentScanIndex + delta

  const setNextSkippedIndex = () => {
    for (let i = nextIndex; i <= sortedScanIds.length + 1; i += delta) {
      if (filteredScanIds.includes(sortedScanIds[i])) {
        nextIndex = i
        break
      }
      if (
        filteredScanIds[0] === sortedScanIds[currentScanIndex] &&
        delta === -1
      ) {
        const lastFilteredScanId = filteredScanIds[filteredScanIds.length - 1]
        i = sortedScanIds.indexOf(lastFilteredScanId) + 1
      }
      if (
        i === sortedScanIds.length &&
        filteredScanIds.length > 1 &&
        delta === +1
      ) {
        i = -1
      }
    }
  }

  if (shouldSkip) setNextSkippedIndex()

  setScanPage(nextIndex, setQueryParams, sortedScanIds)
}

export const getCallNotes = (
  responses: PhoneVerificationResponse[],
  call: PhoneVerificationCall
) => {
  const callId = call.id
  const callResponses = responses.filter(
    ({ call: responseCall }) => responseCall.id === callId
  )
  return callResponses.reduce(
    (note, responseCall) => note || responseCall.notes,
    ''
  )
}
