import {
  List,
  ListItem,
  Section,
  Checkbox,
  ContentBlock,
  TextBlock,
  ButtonBlock,
  Button,
  Font,
  Modal,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const PotentialDuplicateModal = ({
  isOpen,
  setIsOpen,
  issueResponses,
  handleCheckboxChange,
  getIsCheckboxChecked,
  handleSubmitIssues,
  approve,
  potentialDuplicates = [],
}) => {
  const { t } = useTranslation()
  const [areIssuesOpen, setAreIssuesOpen] = useState(false)

  const onRaiseIssue = () => {
    setAreIssuesOpen(true)
  }

  const onApprove = () => {
    approve()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  const onCompleteReview = () => {
    handleSubmitIssues()
    setAreIssuesOpen(false)
    setIsOpen(false)
  }

  return (
    <Modal
      id="warn-pot-dupe"
      title={t('Potential duplicate found')}
      isOpen={isOpen}
    >
      <Modal.Body>
        <TextBlock>
          <Font.Copy>
            {t('The following signatures have the same name and address.')}
          </Font.Copy>
        </TextBlock>
        <List
          itemData={potentialDuplicates}
          render={signature => {
            const {
              first_name,
              middle_name,
              last_name,
              address_one,
              address_two,
              city,
              state,
              zipcode,
              packet,
              page,
            } = signature
            const fullName = [first_name, middle_name, last_name]
              .filter(namePart => !!namePart)
              .join(' ')
            const fullAddress = `${[address_one, address_two, city, state]
              .filter(addressPart => !!addressPart)
              .join(', ')}${zipcode ? ` ${zipcode}` : ''}`
            return (
              <ListItem>
                <ContentBlock>
                  <Font.Copy>{fullName}</Font.Copy>
                  <Font.Copy variant="hint">{fullAddress}</Font.Copy>
                </ContentBlock>
                <ContentBlock>
                  <Font.Copy>{packet.filename}</Font.Copy>
                  <Font.Copy variant="hint">{page.number}</Font.Copy>
                </ContentBlock>
              </ListItem>
            )
          }}
        />
        {areIssuesOpen && (
          <Section label={t('Issues found')}>
            <ContentBlock>
              {issueResponses.map(visualResponse => (
                <Checkbox
                  name={visualResponse.response}
                  label={visualResponse.description}
                  onChange={isChecked =>
                    handleCheckboxChange(visualResponse.id, isChecked)
                  }
                  checked={getIsCheckboxChecked(visualResponse)}
                />
              ))}
            </ContentBlock>
          </Section>
        )}
      </Modal.Body>
      <Modal.Actions>
        {areIssuesOpen ? (
          <ButtonBlock>
            <Button.Accent onClick={onCompleteReview}>
              {t('Complete review')}
            </Button.Accent>
            <Button.Secondary onClick={() => setAreIssuesOpen(false)}>
              {t('Cancel')}
            </Button.Secondary>
          </ButtonBlock>
        ) : (
          <ButtonBlock justify="right">
            <Button.Secondary onClick={onApprove}>
              {t('Approve anyway')}
            </Button.Secondary>
            <Button.Secondary onClick={onCancel}>
              {t('Go back and edit')}
            </Button.Secondary>
            <Button.Danger onClick={onRaiseIssue}>
              {t('Raise issue')}
            </Button.Danger>
          </ButtonBlock>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default PotentialDuplicateModal
