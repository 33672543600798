import { useTranslation } from 'react-i18next'
import { Button, Font, Icon } from '@politechdev/blocks-design-system'
import { usePledgeCardGalleryState } from 'qualityControl/pledgeCardGallery/PledgeCardGalleryContext/PledgeCardGalleryContext'
import ScanContainer from 'qualityControl/pledgeCardGallery/PledgeCardCompare/ScanContainer/ScanContainer'
import ScanCarousel from 'qualityControl/pledgeCardGallery/PledgeCardCompare/ScanCarousel/ScanCarousel'
import styles from 'qualityControl/pledgeCardGallery/PledgeCardGallery.module.scss'
import useComparePledge from '../useComparePledge'

const PledgeCardCompareHeader = () => {
  const { t } = useTranslation()
  const { firstScan, secondScan } = usePledgeCardGalleryState()
  const { incrementScan, decrementScan } = useComparePledge()

  return (
    <div>
      <div className={styles.compare__container}>
        <ScanContainer
          scanUrl={firstScan.pledgeUrl}
          fileLocator={firstScan.fileLocator}
        >
          <Button.Secondary onClick={decrementScan}>
            <Icon.ArrowLeft />
          </Button.Secondary>
          <Font.Copy variant="highlight">
            {t('Registration')} {' #'} {firstScan.id}
          </Font.Copy>
          <Button.Secondary onClick={incrementScan}>
            <Icon.ArrowRight />
          </Button.Secondary>
        </ScanContainer>
        <ScanContainer
          scanUrl={secondScan.pledgeUrl}
          fileLocator={secondScan.fileLocator}
        >
          <Font.Copy variant="highlight">
            {t('Registration')} {' #'} {secondScan.id}
          </Font.Copy>
        </ScanContainer>
      </div>
      <ScanCarousel />
    </div>
  )
}

export default PledgeCardCompareHeader
