import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

const COLORS = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#FF8042',
  '#B23C56',
  '#251A2A',
  '#71828F',
  '#9E715A',
  '#336888',
  '#38424F',
]

const GenericBarChart = ({ data, bars }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart
      width={400}
      height={400}
      data={data}
      margin={{ top: 5, right: 5, left: -20, bottom: 5 }}
    >
      <XAxis dataKey="date" />
      <YAxis />
      <CartesianGrid strokeDasharray="0 0" stroke="#f3f2f2" />
      <CartesianAxis YAxis />
      <Tooltip />
      <Legend />
      {bars.map((bar, index) => (
        <Bar
          key={`cell-${index}`}
          dataKey={bar.dataKey}
          name={bar.name}
          fill={COLORS[index]}
        />
      ))}
    </BarChart>
  </ResponsiveContainer>
)

export default GenericBarChart
