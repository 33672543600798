import { useEffect, useState } from 'react'
import { useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { CardError, MultiSearchSelect } from 'components'
import { uniqBy } from 'lodash'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import {
  COLUMN_NAME,
  buildTagAnswerOptions,
  fetchPhoneBankQuestionTags,
  buildTagsReqParams,
  buildSelectedTags,
  updateRule,
} from './utils'

const PhoneBankTagField = ({ filter }) => {
  const { t } = useTranslation()
  const { setFilterValue } = useListFilters()
  const phoneBankFilterRule = filter.value?.rules
    ? filter.value.rules.find(rule => rule.column === COLUMN_NAME)
    : {}

  const [tagAnswerOptions, setTagAnswerOptions] = useState([])
  const [tagAnswerQuery, setTagAnswerQuery] = useState('')
  const [debounced] = useDebounce(tagAnswerQuery)

  const selectedTags = buildSelectedTags(phoneBankFilterRule, filter.meta)

  const {
    makeRequest: makeTagsReq,
    isLoading,
    hasErrors,
  } = useRequest(fetchPhoneBankQuestionTags, {
    onSuccess: ({ tags }) => {
      setTagAnswerOptions(buildTagAnswerOptions(tags))
    },
  })

  useEffect(() => {
    makeTagsReq(buildTagsReqParams(debounced))
  }, [debounced])

  const handleTagSelect = tags => {
    updateRule({
      params: {
        tagged_responses: tags,
      },
      filter,
      setFilterValue,
    })
  }

  const eligableAnswerOptions = uniqBy(tagAnswerOptions, 'label').filter(
    option => !selectedTags.some(({ label }) => label === option.label)
  )

  return (
    <div>
      <CardError
        hide={!hasErrors}
        message={t('An error ocurred while trying to fetch phone bank tags')}
      />
      <MultiSearchSelect
        label={t('Tags')}
        value={selectedTags}
        options={eligableAnswerOptions}
        onChange={handleTagSelect}
        onInputChange={setTagAnswerQuery}
        loading={isLoading}
      />
    </div>
  )
}

export default PhoneBankTagField
