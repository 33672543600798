import { useContext, useEffect, useMemo, useState } from 'react'
import { useBackgroundRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { PieChart } from 'components'
import { useCurrent } from 'contexts/index'
import { DashboardFilterContext } from 'dashboard/DashboardFilterContext/DashboardFilterContext'
import WidgetPanel from 'dashboard/WidgetPanel/WidgetPanel'
import { requestChartId, requestChart } from '../../../requests/charts'
import styles from './EventsByType.module.scss'

const formatData = (data = []) =>
  data.map(({ type, total_events: value }) => ({
    id: type,
    label: type,
    value,
  }))

const EventsByType = () => {
  const { t } = useTranslation()

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const {
    startDate,
    endDate,
    turfId: turfIdFilter,
  } = useContext(DashboardFilterContext)

  const filters = useMemo(
    () => ({ startDate, endDate, turfId: turfIdFilter }),
    [startDate, endDate, turfIdFilter]
  )

  const [data, setData] = useState([])

  const { makeRequest, isLoading, errorMsg, response } = useBackgroundRequest({
    key: 'events_by_type',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters,
      }),
    requestData: requestChart,
  })

  useEffect(() => {
    makeRequest()
  }, [filters])

  useEffect(() => {
    if (response) {
      setData(response.rows)
    }
  }, [response])

  const formattedData = formatData(data)

  return (
    <WidgetPanel
      title={t('Events by type')}
      isEmpty={!data?.length}
      isLoading={isLoading}
      errorMsg={errorMsg}
    >
      <div className={styles.content}>
        <PieChart data={formattedData} />
      </div>
    </WidgetPanel>
  )
}

export default EventsByType
