/* eslint-disable no-use-before-define */
import { useEffect, useRef, useState, createContext, useContext } from 'react'
import { useHistory, useParams } from 'react-router'
import useEvent from 'hooks/useEvent'
import usePowerDialerSocket from '../hooks/usePowerDialerSocket'
import { buildComponentData, abandonRateExceeded } from '../util'

export const OperatorActionsContext = createContext()
export const CallStatsContext = createContext()

const useOptimisticCount = () => {
  const [count, setCount] = useState(0)
  const incrementCount = () => setCount(current => current + 1)
  const resetCount = () => setCount(0)
  return { count, incrementCount, resetCount }
}

export const OperatorContextProvider = ({ children }) => {
  const history = useHistory()
  const { phoneBankId } = useParams()
  const [componentValues, setComponentValues] = useState({
    averageWaitTime: 0,
    abandonRate: 0,
    agentsIdle: 0,
    agentsAvailable: 0,
    agentsOnCall: 0,
    pickupPercent: 0,
    ongoingCalls: 0,
    remainingContacts: 0,
    callsRinging: 0,
    completedCalls: 0,
    callsConnected: 0,
    callsAbandoned: 0,
    participantsReached: 0,
  })
  const {
    count: optimisticRingingAdjust,
    incrementCount: incrementRingingAdjust,
    resetCount: resetRingingAdjust,
  } = useOptimisticCount()

  const [startEndSession, setStartEndSession] = useState(false)
  const [showAbandonRateModal, setShowAbandonRateModal] = useState(false)
  const [socketDisconnected, setSocketDisconnected] = useState(false)

  const { channel, presence, pushToChannel, channelError, socketError } =
    usePowerDialerSocket({ role: 'operator' })

  const startTime = useRef(new Date())

  useEffect(() => {
    if (channel) {
      attachStatsListeners(channel)
      refreshStats()
    }
  }, [channel])

  useEffect(() => {
    if (socketError) {
      setSocketDisconnected(true)
    }
  }, [socketError])

  const attachStatsListeners = channel => {
    channel.on('stats:updated', doRefreshStats)

    presence.onSync(() => {
      refreshAgentStats()
    })
  }

  const refreshAgentStats = () => {
    const users = presence.list()

    doRefreshStats({
      agents_on_call:
        calculateAgentStatus(users, 'oncall') +
        calculateAgentStatus(users, 'review'),
      agents_available: calculateAgentStatus(users, 'ready'),
      agents_idle: calculateAgentStatus(users, 'away'),
    })
  }

  const calculateAgentStatus = (users, status) =>
    users.filter(
      user => user.metas[0].status === status && user.metas[0].role === 'agent'
    ).length

  const doRefreshStats = useEvent(stats => {
    const prevAbandonRate = componentValues.abandonRate
    const currentAbandonRate = stats.abandon_rate
    const consumableStats = { ...componentValues, ...buildComponentData(stats) }

    if (
      !abandonRateExceeded(prevAbandonRate) &&
      abandonRateExceeded(currentAbandonRate)
    ) {
      setShowAbandonRateModal(true)
    }

    setComponentValues(consumableStats)
    resetRingingAdjust()

    if (startEndSession && consumableStats.agentsOnCall === 0) {
      leaveChannel()
      setStartEndSession(false)
    }
  })

  const refreshStats = () => {
    pushToChannel('fetch:stats').receive('ok', doRefreshStats)
  }

  const dial = () => {
    pushToChannel('add:call', {})
    incrementRingingAdjust()
  }

  const endSession = () => {
    if (startEndSession === false && componentValues.agentsOnCall > 0) {
      setStartEndSession(true)
    } else {
      leaveChannel()
    }
  }

  const leaveChannel = () => {
    channel
      ?.leave()
      .receive('ok', () => history.push(`/organize/phone_banks/${phoneBankId}`))
  }

  return (
    <OperatorActionsContext.Provider value={{ dial, endSession }}>
      <CallStatsContext.Provider
        value={{
          ...(componentValues ?? {}),
          callsRinging: componentValues.callsRinging + optimisticRingingAdjust,
          startTime: startTime.current,
          startEndSession,
          showAbandonRateModal,
          setShowAbandonRateModal,
          channelError,
          socketDisconnected,
        }}
      >
        {children}
      </CallStatsContext.Provider>
    </OperatorActionsContext.Provider>
  )
}

export const useOperatorActions = () => useContext(OperatorActionsContext)
export const useCallStats = () => useContext(CallStatsContext)
