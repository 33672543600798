import { useRef, memo, useState, useContext, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDrop, useDrag } from 'react-dnd'
import isEqual from 'react-fast-compare'
import classNames from 'classnames/bind'
import {
  Button,
  ButtonBlock,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'
import { useCurrent, useScroll } from 'contexts'
import { useTranslation } from 'react-i18next'
import TurfTree from '../TurfTree/TurfTree'
import styles from './TurfTreeItem.module.scss'
import { TurfContext } from '../TurfContext/TurfContext'

const cx = classNames.bind(styles)

const TurfTreeItem = memo(({ turfId, parentId, observerRef }) => {
  const containerRef = useRef(null)
  const itemRef = useRef(null)

  const [areChildrenExpanded, setAreChildrenExpanded] = useState(true)

  const { getTurf, getDirectChildTurfs, moveTurf, placeTurf } =
    useContext(TurfContext)

  const turf = getTurf(turfId)

  const childTurfs = getDirectChildTurfs(turfId)

  const childTurfIds = childTurfs.map(turf => turf.id)

  const { endScroll } = useScroll()

  const { t } = useTranslation()

  const { tenantBlocks, doesCurrentUserHavePermission } = useCurrent()

  const [, dropRef] = useDrop({
    accept: ['turf'],
    hover: (item, monitor) => {
      if (!itemRef.current || !containerRef.current) return
      if (!monitor.isOver({ shallow: true })) return

      const itemBounds = itemRef.current?.getBoundingClientRect()
      const containerBounds = containerRef.current?.getBoundingClientRect()

      const padding = Math.ceil((itemBounds.top - containerBounds.top) / 2)

      const { y: yPos } = monitor.getClientOffset()
      const hoverY = yPos - containerBounds.top

      if (hoverY < padding) {
        parentId &&
          moveTurf({
            turfId: item.id,
            parentId,
            nextId: turfId,
          })
      } else if (hoverY > padding + itemBounds.height && !childTurfIds.length) {
        parentId &&
          moveTurf({
            turfId: item.id,
            parentId,
            prevId: turfId,
          })
      } else {
        moveTurf({
          turfId: item.id,
          parentId: turfId,
          nextId: childTurfIds[0],
        })
      }
    },
    drop: (item, monitor) => {
      if (!parentId) return
      if (monitor.didDrop()) return
      placeTurf(item.id, parentId)
    },
    canDrop: item => ![parentId, turfId].includes(item.id) && parentId,
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    item: {
      id: turfId,
      type: 'turf',
    },
    isDragging: monitor => turfId === monitor.getItem().id,
    end: endScroll,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const qcEnabled = tenantBlocks.some(block => block.name === 'quality_control')

  const canModifyTurfs = doesCurrentUserHavePermission({
    resource: 'turf',
    ability: 'modify',
  })

  const hasChildTurfs = childTurfIds.length > 0

  useLayoutEffect(() => {
    if (observerRef?.current) {
      observerRef?.current?.observe(itemRef.current)
    }
  }, [observerRef?.current])

  return (
    <div
      ref={dropRef(containerRef)}
      className={cx('container', {
        'container--dragging': isDragging,
        'container--root': !parentId,
      })}
    >
      <div ref={previewRef} className={styles.preview} />
      <div
        className={cx('item')}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        ref={itemRef}
        data-turf-id={turfId}
        data-turf-lft={turf.lft}
      >
        <div className={styles.handle} ref={dragRef}>
          <Icon.GripVertical aria-label={t('drag')} />
        </div>
        <div
          className={cx('item__content', {
            item__content__archived: turf.archived,
          })}
        >
          <div className={cx('item__content__label')}>
            <Font.Copy Element="span">{turf.name}</Font.Copy>
            <Font.Copy variant="hint" Element="span">
              {t('ID:')} {turf.id}
            </Font.Copy>
          </div>
          {turf.archived && (
            <div className={styles.item__content__archived__container}>
              <Icon.Archive />
              <div
                className={styles.item__content__archived__container__tooltip}
              >
                <Font.Copy
                  className={
                    styles.item__content__archived__container__tooltip__text
                  }
                >
                  {t('Archived')}
                </Font.Copy>
              </div>
            </div>
          )}
        </div>
        <div className={styles.actions}>
          {turf.turf_level?.name && (
            <span className={styles.level}>{turf.turf_level.name}</span>
          )}
          {qcEnabled && (
            <span className={styles.level}>
              {turf?.qc_config?.external ? t('External') : t('Internal')}
            </span>
          )}
          <ButtonBlock>
            <Link
              onClick={e => !canModifyTurfs && e.preventDefault()}
              to={`/admin/turfs/${turfId}/edit`}
            >
              <Button.Secondary
                aria-label={t('Edit ')}
                disabled={!canModifyTurfs}
              >
                <Icon.Pencil />
              </Button.Secondary>
            </Link>
            {hasChildTurfs && (
              <Button
                aria-label={areChildrenExpanded ? t('Collapse') : t('Expand')}
                onClick={() => setAreChildrenExpanded(current => !current)}
              >
                {areChildrenExpanded ? (
                  <Icon.ChevronDown />
                ) : (
                  <Icon.ChevronUp />
                )}
              </Button>
            )}
          </ButtonBlock>
        </div>
      </div>
      {areChildrenExpanded && hasChildTurfs && (
        <TurfTree
          parentId={turfId}
          turfIds={childTurfIds}
          observerRef={observerRef}
        />
      )}
    </div>
  )
}, isEqual)

export default TurfTreeItem
