import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import humanizeString from 'humanize-string'
import moment from 'moment'
import { Trans } from 'react-i18next'
import { dateFormat } from 'utils/constants'

export const DeliveryTurfCell = ({ rowData }) => (
  <TableGridCell>{rowData.office.name}</TableGridCell>
)

export const DeliveryIdCell = ({ rowData }) => (
  <TableGridCell>{rowData.id}</TableGridCell>
)

export const DeliveryStatusCell = ({ rowData }) => (
  <TableGridCell>
    <Trans>{humanizeString(rowData.status)}</Trans>
  </TableGridCell>
)

export const DeliveryLocationCell = ({ rowData }) => (
  <TableGridCell>{rowData.turn_in_location.name}</TableGridCell>
)

export const DeliveryRunnerCell = ({ rowData }) => (
  <TableGridCell>{rowData.canvasser.full_name}</TableGridCell>
)

export const DeliveryDateCell = ({ rowData }) => {
  const momentDate = moment(rowData.turn_in_date)
  if (momentDate.isValid()) {
    return <TableGridCell>{momentDate.format(dateFormat)}</TableGridCell>
  }
  return <TableGridCell />
}

export const DeliveryFormsAttachedCell = ({ rowData }) => (
  <TableGridCell>{rowData.attached_forms_count || 0}</TableGridCell>
)

export const DeliveryTypeCell = ({ rowData }) => (
  <TableGridCell>{rowData.delivery_method}</TableGridCell>
)
