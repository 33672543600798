import React, { useContext } from 'react'
import {
  Font,
  SectionLabel,
  NumberField,
} from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import { calcProjectedTotal } from 'utils/fieldManagement'
import { ProjectionContext } from '../../ProjectionContext/ProjectionContext'
import styles from './BreakdownPage.module.scss'

const BreakdownPage = ({ setDirty }) => {
  const {
    weeklyProjectedData,
    setWeeklyProjectedData,
    selectedWeek,
    weeklyActualData,
  } = useContext(ProjectionContext)
  const cx = classNames.bind(styles)
  const isPositiveDelta = (actualData, projectedData) =>
    (actualData || 0) - (projectedData || 0) >= 0 &&
    weeklyActualData.length > selectedWeek
  const isNegativeDelta = (actualData, projectedData) =>
    (actualData || 0) - (projectedData || 0) < 0 &&
    weeklyActualData.length > selectedWeek

  const updateWeeklyData = (val, field) => {
    setDirty(true)
    setWeeklyProjectedData(() =>
      weeklyProjectedData.map((weeklyData, i) => {
        if (i === selectedWeek) {
          if (field === 'daysCanvassingPerWeek' && val > 7) {
            return {
              ...weeklyProjectedData[selectedWeek],
            }
          }
          return {
            ...weeklyProjectedData[selectedWeek],
            [field]: val,
          }
        }

        return weeklyData
      })
    )
  }

  return (
    <div className={styles.page__container}>
      <div className={styles.page__column}>
        <div className={styles.page__item}>
          <SectionLabel>Projected forms collected</SectionLabel>
          <Font.Display variant="small">
            {weeklyProjectedData[selectedWeek]
              ? Math.floor(
                  calcProjectedTotal(weeklyProjectedData[selectedWeek])
                )
              : 0}
          </Font.Display>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Canvassers per day</Font.Label>
          <NumberField
            className={styles.page__item__field}
            label="Canvassers"
            value={weeklyProjectedData[selectedWeek]?.dailyCanvassers}
            disabled={weeklyActualData[selectedWeek]?.dailyCanvassers}
            onChange={val => updateWeeklyData(val, 'dailyCanvassers')}
          />
        </div>
        <div className={styles.page__item}>
          <Font.Label>Days canvassing per week</Font.Label>
          <NumberField
            className={styles.page__item__field}
            label="Days"
            value={weeklyProjectedData[selectedWeek]?.daysCanvassingPerWeek}
            disabled={weeklyActualData[selectedWeek]?.daysCanvassingPerWeek}
            onChange={val => updateWeeklyData(val, 'daysCanvassingPerWeek')}
          />
        </div>
        <div className={styles.page__item}>
          <Font.Label>Average field hours per canvasser</Font.Label>
          <NumberField
            className={styles.page__item__field}
            label="Hours"
            value={weeklyProjectedData[selectedWeek]?.averageFieldHours}
            disabled={weeklyActualData[selectedWeek]?.averageFieldHours}
            onChange={val => updateWeeklyData(val, 'averageFieldHours')}
            step="any"
          />
        </div>
        <div className={styles.page__item}>
          <Font.Label>Forms per hour</Font.Label>
          <NumberField
            className={styles.page__item__field}
            label="Forms collected"
            value={weeklyProjectedData[selectedWeek]?.formsPerHour}
            disabled={weeklyActualData[selectedWeek]?.formsPerHour}
            onChange={val => updateWeeklyData(val, 'formsPerHour')}
            step="any"
          />
        </div>
      </div>
      <div className={styles.page__column}>
        <div className={styles.page__item}>
          <SectionLabel>Actual forms collected</SectionLabel>
          <Font.Display variant="small">
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : weeklyActualData[selectedWeek]?.formsCollected || 0}
          </Font.Display>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Canvassers per day</Font.Label>
          <Font.Copy variant="highlight">
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : weeklyActualData[selectedWeek]?.dailyCanvassers || 0}
          </Font.Copy>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Days canvassing per week</Font.Label>
          <Font.Copy variant="highlight">
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : weeklyActualData[selectedWeek]?.daysCanvassingPerWeek || 0}
          </Font.Copy>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Average field hours per canvasser</Font.Label>
          <Font.Copy variant="highlight">
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : weeklyActualData[selectedWeek]?.averageFieldHours || 0}
          </Font.Copy>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Forms per hour</Font.Label>
          <Font.Copy variant="highlight">
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : weeklyActualData[selectedWeek]?.formsPerHour || 0}
          </Font.Copy>
        </div>
      </div>
      <div className={styles.page__column}>
        <div className={styles.page__item}>
          <SectionLabel>Difference</SectionLabel>
          <Font.Display
            variant="small"
            className={cx({
              positive: isPositiveDelta(
                weeklyActualData[selectedWeek]?.formsCollected,
                weeklyProjectedData[selectedWeek]
                  ? Math.floor(
                      calcProjectedTotal(weeklyProjectedData[selectedWeek])
                    )
                  : 0
              ),
              negative: isNegativeDelta(
                weeklyActualData[selectedWeek]?.formsCollected,
                weeklyProjectedData[selectedWeek]
                  ? Math.floor(
                      calcProjectedTotal(weeklyProjectedData[selectedWeek])
                    )
                  : 0
              ),
            })}
          >
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : (weeklyActualData[selectedWeek]?.formsCollected || 0) -
                (weeklyProjectedData[selectedWeek]
                  ? Math.floor(
                      calcProjectedTotal(weeklyProjectedData[selectedWeek])
                    )
                  : 0)}
          </Font.Display>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Canvassers per day</Font.Label>
          <Font.Copy
            variant="highlight"
            className={cx({
              positive: isPositiveDelta(
                weeklyActualData[selectedWeek]?.dailyCanvassers,
                weeklyProjectedData[selectedWeek]?.dailyCanvassers
              ),
              negative: isNegativeDelta(
                weeklyActualData[selectedWeek]?.dailyCanvassers,
                weeklyProjectedData[selectedWeek]?.dailyCanvassers
              ),
            })}
          >
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : (weeklyActualData[selectedWeek]?.dailyCanvassers || 0) -
                (weeklyProjectedData[selectedWeek]?.dailyCanvassers || 0)}
          </Font.Copy>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Days canvassing per week</Font.Label>
          <Font.Copy
            variant="highlight"
            className={cx({
              positive: isPositiveDelta(
                weeklyActualData[selectedWeek]?.daysCanvassingPerWeek,
                weeklyProjectedData[selectedWeek]?.daysCanvassingPerWeek
              ),
              negative: isNegativeDelta(
                weeklyActualData[selectedWeek]?.daysCanvassingPerWeek,
                weeklyProjectedData[selectedWeek]?.daysCanvassingPerWeek
              ),
            })}
          >
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : (weeklyActualData[selectedWeek]?.daysCanvassingPerWeek || 0) -
                (weeklyProjectedData[selectedWeek]?.daysCanvassingPerWeek || 0)}
          </Font.Copy>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Average field hours per canvasser</Font.Label>
          <Font.Copy
            variant="highlight"
            className={cx({
              positive: isPositiveDelta(
                weeklyActualData[selectedWeek]?.averageFieldHours,
                weeklyProjectedData[selectedWeek]?.averageFieldHours
              ),
              negative: isNegativeDelta(
                weeklyActualData[selectedWeek]?.averageFieldHours,
                weeklyProjectedData[selectedWeek]?.averageFieldHours
              ),
            })}
          >
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : (weeklyActualData[selectedWeek]?.averageFieldHours || 0) -
                (weeklyProjectedData[selectedWeek]?.averageFieldHours || 0)}
          </Font.Copy>
        </div>
        <div className={styles.page__item}>
          <Font.Label>Forms per hour</Font.Label>
          <Font.Copy
            variant="highlight"
            className={cx({
              positive: isPositiveDelta(
                weeklyActualData[selectedWeek]?.formsPerHour,
                weeklyProjectedData[selectedWeek]?.formsPerHour
              ),
              negative: isNegativeDelta(
                weeklyActualData[selectedWeek]?.formsPerHour,
                weeklyProjectedData[selectedWeek]?.formsPerHour
              ),
            })}
          >
            {weeklyActualData.length <= selectedWeek
              ? '--'
              : (weeklyActualData[selectedWeek]?.formsPerHour || 0) -
                (weeklyProjectedData[selectedWeek]?.formsPerHour || 0)}
          </Font.Copy>
        </div>
      </div>
    </div>
  )
}

export default BreakdownPage
