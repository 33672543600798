import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button, ScrollToTop } from 'components'

class EditSheet extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    formTitle: PropTypes.string,
  }

  static defaultProps = {
    formTitle: 'Edit',
  }

  state = {
    visible: false,
  }

  show = () => {
    this.setState({
      visible: true,
    })
  }

  hide = () => {
    this.setState({ visible: false })
  }

  renderChildren() {
    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        hide: () => {
          this.hide()
        },
      })
    )
  }

  render() {
    const { visible } = this.state
    const { t, formTitle } = this.props

    if (visible) {
      return (
        <div className="sheet__edit-dialog">
          <ScrollToTop />
          <div className="sheet__edit-dialog__toolbar">
            <Button icon onClick={this.hide}>
              close
            </Button>
            <h1 className="sheet__edit-dialog__title">{t(formTitle)}</h1>
          </div>
          <div className="sheet__edit-dialog__content">
            {this.renderChildren()}
          </div>
        </div>
      )
    }

    return (
      <div className="sheet__edit-button">
        <Button floating accent onClick={this.show} aria-controls="Edit dialog">
          edit
        </Button>
      </div>
    )
  }
}

export default withTranslation()(EditSheet)
