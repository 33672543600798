import { useTranslation } from 'react-i18next'
import {
  Sheet,
  FieldBlock,
  SelectField,
} from '@politechdev/blocks-design-system'

const PhoneBankReportsPicker = ({
  reportType,
  changeReportType,
  reportTypes,
}) => {
  const { t } = useTranslation()

  return (
    <Sheet>
      <FieldBlock>
        <SelectField
          id="report-category"
          label={t('Report')}
          options={Object.values(reportTypes).map(category => ({
            value: category.id,
            label: t(category.name),
          }))}
          deleteKeys={['columns', 'reportTypes', 'defaultType']}
          value={reportType}
          onSelect={changeReportType}
        />
      </FieldBlock>
    </Sheet>
  )
}

export default PhoneBankReportsPicker
