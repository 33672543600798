export const formatParams = params => ({
  fields: [
    'id',
    'responsibility',
    {
      person: [
        'name',
        'first_name',
        'last_name',
        'id',
        'primary_phone_number',
        'primary_email_address',
        {
          residential_address: [
            'line_one',
            'line_two',
            'city',
            'state',
            'zipcode',
          ],
        },
      ],
    },
  ],
  current_page: params?.page,
  per: params?.pageSize,
  sort_attr: params?.sortColumn,
  sort_dir: params?.sortOrder,
  filters: {
    rules: params?.filterRules,
    conjunction: params?.filterConjunction,
  },
})
