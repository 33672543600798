import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from 'components'
import {
  PageHeader,
  Sheet,
  Button,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import RecordsTable from '../RecordsTable/RecordsTable'
import RecordsSaveAllModal from '../RecordsSaveAllModal/RecordsSaveAllModal'
import RecordsDiscardAllModal from '../RecordsDiscardAllModal/RecordsDiscardAllModal'
import RecordsContextProvider, {
  RecordsContext,
} from '../RecordsContext/RecordsContext'
import styles from './Records.module.scss'

const Records = () => {
  const { t } = useTranslation()
  const [isDiscardAllOpen, setDiscardAllOpen] = useState(false)
  const [isSaveAllOpen, setSaveAllOpen] = useState(false)
  const { count } = useContext(RecordsContext)
  return (
    <div>
      <RecordsSaveAllModal
        onClose={() => setSaveAllOpen(false)}
        isOpen={isSaveAllOpen}
      />{' '}
      <RecordsDiscardAllModal
        onClose={() => setDiscardAllOpen(false)}
        isOpen={isDiscardAllOpen}
      />
      <PageHeader
        title={`${count} ${t('import validation errors to resolve')}`}
      />
      <ViewContainer>
        <Sheet>
          <div className={styles.actions}>
            <h2>{t('Bulk actions')}</h2>
            <ButtonBlock justify="right">
              <Button.Danger onClick={() => setDiscardAllOpen(true)}>
                {t(' Discard all')}
              </Button.Danger>
              <Button.Accent onClick={() => setSaveAllOpen(true)}>
                {t('Save all as new')}
              </Button.Accent>
            </ButtonBlock>
          </div>
          <RecordsTable />
        </Sheet>
      </ViewContainer>
    </div>
  )
}
export default () => (
  <RecordsContextProvider>
    <Records />
  </RecordsContextProvider>
)
