import {
  calculateRate,
  calculatePercent,
  summaryFunctions,
  formatActiveColumn,
} from 'utils/reporting'
import { YES_NO_OPTIONS } from '../../constants'

export default {
  id: 'canvasser_quality_by_group_tablet',
  name: 'Group',
  description:
    'This report compares total forms from the cover sheet to total forms from the scans and counts from the visual QC process.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=2049147248',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'active_turf',
      title: 'Active Turf',
      type: 'enum',
      options: YES_NO_OPTIONS,
      resizable: true,
      deriveFunction: ({ rowData }) =>
        formatActiveColumn(rowData.turf_archived),
    },
    {
      dataKey: 'total_scans_cover',
      title: 'Total Forms',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_field_hours_cover',
      title: 'Total Field Hours',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'scans_per_field_hour_cover',
      title: 'Forms per Field Hour',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_scans_cover,
          rowData.total_field_hours_cover
        ),
    },
    {
      dataKey: 'total_shift_hours',
      title: 'Total Shift Hours',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'scans_with_phones_qc',
      title: 'Forms with Phones',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'percent_with_phones_qc',
      title: 'Percent with Phones',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.scans_with_phones_qc, rowData.total_scans_qc),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customPercent(data, acc, [
          'scans_with_phones_qc',
          'total_scans_qc',
        ]),
    },
    {
      dataKey: 'percent_scans_sms',
      title: '% of Registrations with Cell Opt-in',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.total_scans_sms_qc, rowData.total_scans_qc),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customPercent(data, acc, [
          'total_scans_sms_qc',
          'total_scans_qc',
        ]),
    },
    {
      dataKey: 'percent_scans_email',
      title: '% of Registrations with Email Opt-in',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.total_scans_email_qc, rowData.total_scans_qc),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customPercent(data, acc, [
          'total_scans_email_qc',
          'total_scans_qc',
        ]),
    },
    {
      dataKey: 'percent_scans_ssn',
      title: '% of Registrations with SSN',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.total_scans_ssn_qc, rowData.total_scans_qc),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customPercent(data, acc, [
          'total_scans_ssn_qc',
          'total_scans_qc',
        ]),
    },
    {
      dataKey: 'percent_scans_state_id',
      title: '% Registrations with State ID',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.total_scans_state_id_qc,
          rowData.total_scans_qc
        ),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customPercent(data, acc, [
          'total_scans_state_id_qc',
          'total_scans_qc',
        ]),
    },
  ],
}
