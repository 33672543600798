import { useContext, useEffect } from 'react'
import { useCurrent, useAutoTable, useFilters } from 'contexts'
import { useBackgroundRequest } from 'hooks'
import { areAllFiltersValid } from 'components/DataTable/TableFilters/utils'
import { formatReportData } from 'registrationReports/utils'
import { QualityControlReportsContext } from './QualityControlReportsContext'

const TimelineReportTable = ({ reportType, dateRange, turfId }) => {
  const {
    filterRules,
    isLoading: filterLoading,
    errorMsg: filterErrorMsg,
  } = useFilters()
  const { renderTable } = useAutoTable()
  const { currentTurfPerformsExternalQC } = useCurrent()
  const {
    setCurrentReportType,
    reportData,
    requestReportIdByDay,
    requestReportIdByWeek,
    requestReportIdByAllTime,
    requestReport,
    aggregateTimelineReport,
  } = useContext(QualityControlReportsContext)

  const {
    makeRequest: dayReq,
    isLoading: isDayReqLoading,
    errorMsg: dayReqErrorMsg,
    isSuccessful: isDayReqSuccessful,
    response: dayReportData,
  } = useBackgroundRequest({
    key: reportType.id,
    requestId: type =>
      requestReportIdByDay({
        type,
        turfId,
        date: dateRange.end,
        currentTurfPerformsExternalQC,
      }),
    requestData: requestReport,
  })

  const {
    makeRequest: weekReq,
    isLoading: isWeekReqLoading,
    errorMsg: weekReqErrorMsg,
    isSuccessful: isWeekReqSuccessful,
    response: weekReportData,
  } = useBackgroundRequest({
    key: reportType.id,
    requestId: type =>
      requestReportIdByWeek({
        type,
        turfId,
        date: dateRange.end,
        currentTurfPerformsExternalQC,
      }),
    requestData: requestReport,
  })

  const {
    makeRequest: allTimeReq,
    isLoading: isAllTimeReqLoading,
    errorMsg: allTimeReqErrorMsg,
    isSuccessful: isAllTimeReqSuccessful,
    response: allTimeReportData,
  } = useBackgroundRequest({
    key: reportType.id,
    requestId: type =>
      requestReportIdByAllTime({
        type,
        turfId,
        date: dateRange.end,
        currentTurfPerformsExternalQC,
      }),
    requestData: requestReport,
  })

  useEffect(() => {
    if (isWeekReqSuccessful && isDayReqSuccessful && isAllTimeReqSuccessful) {
      const formattedDayReportData = formatReportData(dayReportData)
      const formattedWeekReportData = formatReportData(weekReportData)
      const formattedAllTimeReportData = formatReportData(allTimeReportData)

      aggregateTimelineReport({
        reportConfig: reportType,
        day: formattedDayReportData.data
          ? formattedDayReportData.data
          : formattedDayReportData,
        week: formattedWeekReportData.data
          ? formattedWeekReportData.data
          : formattedWeekReportData,
        allTime: formattedAllTimeReportData.data
          ? formattedAllTimeReportData.data
          : formattedAllTimeReportData,
      })
    }
  }, [isWeekReqSuccessful, isDayReqSuccessful, isAllTimeReqSuccessful])

  useEffect(() => {
    setCurrentReportType(reportType.id)
  }, [reportType.id])

  useEffect(() => {
    dayReq(reportType.id)
    weekReq(reportType.id)
    allTimeReq(reportType.id)
  }, [dateRange, turfId, reportType.id])

  if (!areAllFiltersValid(filterRules, reportType.columns)) {
    return null
  }

  return renderTable({
    data: reportData.data ? reportData.data : reportData,
    sqlQuery: isDayReqSuccessful ? dayReportData.sql_query : '',
    loading:
      isDayReqLoading ||
      isWeekReqLoading ||
      isAllTimeReqLoading ||
      filterLoading,
    error:
      dayReqErrorMsg || weekReqErrorMsg || allTimeReqErrorMsg || filterErrorMsg,
    columns: reportType.columns,
    rowKey: reportType.rowKey || 'id',
    showTotals: reportType.showTotals ?? true,
    fetchAction: () => {
      dayReq()
      weekReq()
      allTimeReq()
    },
  })
}

export default TimelineReportTable
