import {
  TextField,
  NumberField,
  SelectField,
} from '@politechdev/blocks-design-system'
import humanizeString from 'humanize-string'
import { FilterType } from 'lists/types'
import { CustomFieldValue } from './CustomFields.types'

const CustomFilterField = ({
  filter,
  onChange,
  value,
}: {
  filter: FilterType
  onChange: (value: CustomFieldValue) => unknown
  value: CustomFieldValue
}) => {
  if (filter.customType === 'select') {
    return (
      <SelectField
        label={humanizeString(filter.name)}
        value={value}
        onSelect={onChange}
        options={filter.fieldOptions ?? []}
      />
    )
  }
  if (filter.customType === 'number') {
    return (
      <NumberField
        label={humanizeString(filter.name)}
        value={value as number}
        onChange={onChange}
      />
    )
  }

  return (
    <TextField
      label={humanizeString(filter.name)}
      value={value}
      onChange={onChange}
      icon={undefined}
    />
  )
}

export default CustomFilterField
