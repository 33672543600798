import {
  TextBlock,
  Font,
  Icon,
  ContentBlock,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import styles from './ReportDescription.module.scss'

const ReportDescription = ({ currentReport, categoryName }) => {
  const { t } = useTranslation()

  return (
    <Sheet>
      <ContentBlock className={styles.header}>
        <Font.Display variant="small">
          <span>{t(categoryName)}</span>
        </Font.Display>
        {currentReport?.name && (
          <>
            <Font.Copy className={styles.header__icon}>
              <Icon.ChevronRight />
            </Font.Copy>
            <Font.Display variant="small">{`${t(
              currentReport.name
            )}`}</Font.Display>
          </>
        )}
      </ContentBlock>
      <TextBlock>
        <Font.Copy variant="reading">
          <p>{t(currentReport.description)}</p>
        </Font.Copy>
      </TextBlock>
    </Sheet>
  )
}

export default ReportDescription
