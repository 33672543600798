/* eslint-disable react/jsx-no-constructed-context-values */
import { useState, useEffect, useContext, createContext } from 'react'
import { useQueryParams, usePagination, useCurrent } from 'contexts'
import { useMounted, useRequest } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'
import { fetchFilters, postFilter } from 'requests/tableFilters'
import { normalizeFiltersByLabel } from 'utils/tableFilters'
import { defaultTo } from 'lodash'

const FilterContext = createContext()

export const FilterProvider = ({
  tableId,
  persistSelectedFilter,
  enableQueryParams,
  enablePagination,
  children,
  defaultFilters = [],
}) => {
  const {
    currentUser: { datatable_settings: dataTableSettings },
    updateCurrentUser,
  } = useCurrent()

  const [savedFilters, setSavedFilters] = useState([])
  const [activeFilterId, setActiveFilterId] = useState()

  const tableFilters = savedFilters[tableId] || []

  const { queryParams, setQueryParams } = useQueryParams(enableQueryParams)
  const [filterSettings, setFilterSettings] = useState({
    hiddenColumns: defaultTo(queryParams.hiddenColumns, []),
    filterRules: defaultTo(queryParams.filterRules, []),
    filterConjunction: queryParams.filterConjunction,
  })

  const updateFilterSettings = settings => {
    setQueryParams(settings)
    setFilterSettings({ ...filterSettings, ...settings })
  }

  const { setPage } = usePagination(enablePagination)

  const setActiveFilter = filterId => {
    const activeFilter = [...defaultFilters, ...tableFilters].find(
      f => f.id === filterId
    )

    setPage(1)
    setActiveFilterId(filterId)

    if (activeFilter) {
      updateFilterSettings({
        filterConjunction: activeFilter.conjunction,
        filterRules: defaultTo(activeFilter.rules, []),
        hiddenColumns: defaultTo(activeFilter.metadata.hiddenColumns, []),
      })
    } else {
      updateFilterSettings({
        filterRules: [],
        hiddenColumns: [],
      })
    }
  }

  const {
    makeRequest: fetchRequest,
    isLoading: fetchLoading,
    errors: fetchErrors,
    isRequestComplete: isFetchFiltersComplete,
  } = useRequest(fetchFilters, {
    onSuccess: ({ filter_views }) => {
      const savedFilters = normalizeFiltersByLabel(filter_views)
      setSavedFilters(savedFilters)

      const hasPresetFilterParams =
        !!filterSettings.filterRules?.length ||
        !!filterSettings.hiddenColumns?.length

      if (hasPresetFilterParams) return

      const userFilters = savedFilters[tableId] || []

      const availableFilters = [...defaultFilters, ...userFilters]
      const lastUsedFilterId = dataTableSettings[tableId]

      const lastUsedFilterIsAvailable =
        !!lastUsedFilterId &&
        !!availableFilters.find(f => f.id === lastUsedFilterId)

      if (persistSelectedFilter && lastUsedFilterIsAvailable) {
        setActiveFilter(lastUsedFilterId)
      } else {
        const hasDefaultFilters = !!defaultFilters.length
        hasDefaultFilters && setActiveFilter(defaultFilters[0].id)
      }
    },
  })

  const fetchErrorMsg = formatErrorMessage(fetchErrors)

  const {
    makeRequest: createRequest,
    isLoading: createLoading,
    errors: createErrors,
  } = useRequest(
    ({ name, conjunction, rules, label, hiddenColumns }) =>
      postFilter({
        name,
        conjunction,
        rules,
        metadata: {
          label,
          hiddenColumns,
        },
      }),
    {
      onSuccess: ({ filter_view }) => {
        setSavedFilters(oldFilters => {
          const newFilters = oldFilters[filter_view.metadata.label] || []
          const index = newFilters.findIndex(
            filter => filter.id === filter_view.id
          )

          if (index > -1) {
            newFilters[index] = filter_view
          } else {
            newFilters.push(filter_view)
          }

          return {
            ...oldFilters,
            [filter_view.metadata.label]: newFilters,
          }
        })
        setActiveFilterId(filter_view.id)
      },
    }
  )
  const createErrorMsg = formatErrorMessage(createErrors)

  useEffect(() => {
    fetchRequest()
  }, [])

  const isMounted = useMounted()

  const changePage = page => {
    setPage(page)
  }

  const resetFilters = () => {
    changePage(1)
    setActiveFilterId()
    updateFilterSettings({
      hiddenColumns: [],
      filterRules: [],
      filterConjunction: undefined,
    })
  }

  const setDataTableSetting = filterId => {
    if (persistSelectedFilter) {
      updateCurrentUser({
        datatable_settings: { ...dataTableSettings, [tableId]: filterId },
      })
    }
  }

  const clearActiveFilter = () => {
    setDataTableSetting()
    resetFilters()
  }

  const saveFilter = name => {
    createRequest({
      name,
      conjunction: filterSettings.filterConjunction,
      rules:
        filterSettings.filterRules.map(rule => ({
          ...rule,
          param: rule.param.toString(),
        })) || [],
      label: tableId,
      hiddenColumns: filterSettings.hiddenColumns,
    })
  }

  useEffect(() => {
    if (activeFilterId) {
      setDataTableSetting(activeFilterId)
    }
  }, [activeFilterId])

  const onToggleColumn = colName => {
    setActiveFilterId()
    if (!filterSettings.hiddenColumns) {
      return updateFilterSettings({ hiddenColumns: [colName] })
    }

    updateFilterSettings({
      hiddenColumns: filterSettings.hiddenColumns.includes(colName)
        ? filterSettings.hiddenColumns.filter(col => col !== colName)
        : [...filterSettings.hiddenColumns, colName],
    })
  }

  const setFilterConjunction = filterConjunction => {
    clearActiveFilter()
    updateFilterSettings({ filterConjunction })
  }

  const applyFilterRule = filterRule => {
    clearActiveFilter()
    const filterRules = filterSettings.filterRules
      ? [...filterSettings.filterRules, filterRule]
      : [filterRule]
    updateFilterSettings({ filterRules })
  }

  const updateFilterRule = filterRule => {
    const existingRules = filterSettings.filterRules || []
    const existingRule = existingRules.find(f => f.id === filterRule.id)

    const filterRules = existingRule
      ? existingRules.map(f => (f.id === filterRule.id ? filterRule : f))
      : [...existingRules, filterRule]

    updateFilterSettings({ filterRules })
  }

  const removeFilterRule = ruleId => {
    clearActiveFilter()
    const filterRules = filterSettings.filterRules.filter(r => r.id !== ruleId)
    const filterConjunction =
      filterRules.length < 2 ? undefined : filterSettings.filterConjunction
    updateFilterSettings({
      filterRules,
      filterConjunction,
    })
  }

  const clearFiltersOnTableIdChange = () => {
    isMounted && resetFilters()
  }

  const {
    filterRules = [],
    hiddenColumns = [],
    filterConjunction,
  } = filterSettings

  const filterContextValues = {
    filterRules,
    hiddenColumns,
    filterConjunction,
    isFiltered: filterRules.length > 0 || hiddenColumns.length > 0,
    isLoading: fetchLoading || createLoading,
    errorMsg: fetchErrorMsg || createErrorMsg,
    availableFilters: [...defaultFilters, ...tableFilters],
    defaultFilters,
    onToggleColumn,
    applyFilterRule,
    updateFilterRule,
    removeFilterRule,
    setFilterConjunction,
    saveFilter,
    activeFilter: [...defaultFilters, ...tableFilters].find(
      f => f.id === activeFilterId
    ),
    activeFilterId,
    setActiveFilter,
    resetFilters,
    initialFilterReady: isFetchFiltersComplete,
    clearActiveFilter,
    clearFiltersOnTableIdChange,
    setSavedFilters,
    setActiveFilterId,
    fetchRequest,
  }

  return (
    <FilterContext.Provider value={filterContextValues}>
      {children}
    </FilterContext.Provider>
  )
}

export const useFilters = (enabled = true) => {
  if (!enabled) {
    return {
      filterRules: [],
      hiddenColumns: [],
      initialFilterReady: true,
      clearActiveFilter: () => {},
    }
  }

  const context = useContext(FilterContext)
  if (context === undefined) {
    throw new Error('useFilters must be used within a FilterProvider')
  }
  return context
}
