import { useContext, Context } from 'react'

export function useContextOrThrow<T>(context: Context<T | undefined>): T {
  const contextValue = useContext(context)
  if (contextValue === undefined) {
    throw new Error(
      `Cannot use ${context.displayName} context without a containing context provider`
    )
  }
  return contextValue
}
