import React from 'react'
import PropTypes from 'prop-types'
import EditSheet from './EditSheet'

const Sheet = props => (
  <div className={`sheet ${props.className}`}>
    {props.customHeader || !props.title ? null : (
      <div
        className={`sheet__header ${
          props.primary ? 'sheet__header--primary' : ''
        }`}
      >
        <h1>{props.title}</h1>
        {props.subtitle !== '' && <h4>{props.subtitle}</h4>}
      </div>
    )}
    {props.children}
    {typeof props.editForm === 'function' && !props.readOnly ? (
      <EditSheet formTitle={props.editFormTitle} onSave={props.onEditFormSave}>
        {props.editForm()}
      </EditSheet>
    ) : (
      ''
    )}
  </div>
)

Sheet.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  editForm: PropTypes.func,
  editFormTitle: PropTypes.string,
  onEditFormSave: PropTypes.func,
}

Sheet.defaultProps = {
  title: '',
  subtitle: '',
  editForm: null,
  editFormTitle: 'Edit',
  onEditFormSave: null,
}

export default Sheet
