import { TextField } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const PhoneNumberInput = ({
  phoneNumber,
  onChange,
  hasError,
  onEnterKeyPress,
  disabled,
}) => {
  const { t } = useTranslation()
  const errorText = phoneNumber
    ? t('Number must contain 10 digits')
    : t(
        'No phone number detected. Enter number as displayed written in the scan'
      )

  const handleKeyPress = event => {
    if (event.key === 'Enter' && !hasError) {
      onEnterKeyPress()
    }
  }

  return (
    <TextField
      type="tel"
      id={phoneNumber}
      value={phoneNumber}
      error={hasError}
      errorMessage={errorText}
      onChange={onChange}
      onKeyPress={handleKeyPress}
      disabled={disabled}
    />
  )
}

export default PhoneNumberInput
