import { useTranslation } from 'react-i18next'
import { Sheet, SectionLabel } from 'components'
import { ButtonBlock } from '@politechdev/blocks-design-system'
import { AutoTableProvider, useCurrent } from 'contexts'
import tableConfig from 'teams/TeamMemberTable/tableConfig'
import TeamMemberTable from '../TeamMemberTable/TeamMemberTable'
import AddTeamMemberModal from '../AddTeamMemberModal/AddTeamMemberModal'

const TeamMembers = () => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyTeamMemberships = doesCurrentUserHavePermission({
    resource: 'team_membership',
    ability: 'modify',
  })

  return (
    <Sheet>
      <ButtonBlock justify="spread">
        <SectionLabel raised>{t('Team members')}</SectionLabel>
        {canModifyTeamMemberships && <AddTeamMemberModal />}
      </ButtonBlock>
      <TeamMemberTable />
    </Sheet>
  )
}

export default () => (
  <AutoTableProvider
    persistSelectedFilter
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
    defaultFilters={tableConfig.defaultFilters}
  >
    <TeamMembers />
  </AutoTableProvider>
)
