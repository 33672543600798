import { fetchJSON, stringifyParams } from 'utils/req'

export const formatSignatureForVoterMatch = ({
  city,
  first_name,
  last_name,
  middle_name,
  state,
  address_one,
  address_two,
}) => ({
  city: city || '',
  firstName: first_name || '',
  lastName: last_name || '',
  middleName: middle_name || '',
  state: state || '',
  streetAddress: [address_one, address_two].filter(field => !!field).join(' '),
})

export const buildPageUpdateRequest = formData => ({
  notes: formData.notes,
  visual_reviews: formData.visual_review_responses.map(response => ({
    visual_review_response_id: response.id,
  })),
})

const pageReqParams = {
  fields: [
    'id',
    'notes',
    {
      visual_review_responses: ['id', 'response'],
    },
  ],
}

export const updatePage = (currentPage, pageForm) =>
  fetchJSON(
    `/api/v1/petition_pages/${currentPage.id}?${stringifyParams(
      pageReqParams
    )}`,
    'PUT',
    { page: buildPageUpdateRequest(pageForm) },
    {
      useJwt: true,
    }
  )

export const matchConfig = {
  name: [
    {
      signatureKey: 'first_name',
      matchKey: 'firstName',
      label: 'First name',
    },
    {
      signatureKey: 'middle_name',
      matchKey: 'middleName',
      label: 'Middle name',
    },
    { signatureKey: 'last_name', matchKey: 'lastName', label: 'Last name' },
  ],
  address: [
    {
      signatureKey: formData =>
        [formData.address_one, formData.address_two]
          .filter(field => !!field)
          .join(' '),
      matchKey: 'address',
      label: 'Address',
    },
    { signatureKey: 'city', matchKey: 'city', label: 'City' },
    { signatureKey: 'state', matchKey: 'state', label: 'State' },
    { signatureKey: 'zipcode', matchKey: 'zip', label: 'Zipcode' },
  ],
}
