import { defaultTo } from 'lodash'
import { createContext, useState } from 'react'
import { defaultScriptStructures } from 'constants/qcCallScriptsConfig/defaultScripts'
import {
  DEFAULT_PENDING_SCRIPT,
  qcCallScriptParams,
} from 'constants/qcCallScripts'
import {
  createPhoneVerificationScript,
  fetchPhoneVerificationScript,
  updatePhoneVerificationScript,
} from 'requests/qcCallScripts'
import { immutablyRemoveItemByIndex, immutablySwapItems } from 'utils/array'
import { fetchTurf } from 'requests/turfs'

export const CallScriptsContext = createContext()

const CallScriptsContextProvider = ({ children }) => {
  const [scriptsByTurf, setScriptsByTurf] = useState({})
  const [pendingScript, setPendingScript] = useState(DEFAULT_PENDING_SCRIPT)
  const [copyToTurfOptions, setCopyToTurfOptions] = useState([])

  const fetchScriptsByTurf = async turfId => {
    const data = await fetchTurf(turfId, {
      fields: [{ phone_verification_scripts: ['language', 'id'] }],
    })
    setScriptsByTurf(oldScriptsByTurf => ({
      ...oldScriptsByTurf,
      [turfId]: defaultTo(data.turf.phone_verification_scripts, []),
    }))
  }

  const fetchScript = async id => {
    const { 'quality_control/phone_verification_script': script } =
      await fetchPhoneVerificationScript(id, {
        fields: ['id', 'turf_id', 'language', 'structure'],
      })
    setPendingScript({
      id: script.id,
      turfId: script.turf_id,
      language: script.language,
      structure: script.structure,
    })
  }

  const setPendingScriptStructureToDefault = lang => {
    setPendingScript(oldPendingScript => ({
      ...oldPendingScript,
      structure: defaultScriptStructures[lang],
    }))
  }

  const clearPendingScript = () => {
    setPendingScript(DEFAULT_PENDING_SCRIPT)
  }

  const toggleCopyToTurfOption = id => {
    if (copyToTurfOptions.includes(id)) {
      setCopyToTurfOptions(oldCopyToTurfOptions =>
        oldCopyToTurfOptions.filter(option => option !== id)
      )
    } else {
      setCopyToTurfOptions(oldCopyToTurfOptions =>
        oldCopyToTurfOptions.concat([id])
      )
    }
  }

  const moveItem = increment => index => {
    setPendingScript(oldPendingScript => ({
      ...oldPendingScript,
      structure: immutablySwapItems(
        increment,
        index,
        oldPendingScript.structure
      ),
    }))
  }

  const moveItemUp = moveItem(-1)
  const moveItemDown = moveItem(+1)

  const removeItem = index => {
    setPendingScript(oldPendingScript => ({
      ...oldPendingScript,
      structure: immutablyRemoveItemByIndex(index, oldPendingScript.structure),
    }))
  }

  const addItem = newItem => {
    setPendingScript(oldPendingScript => ({
      ...oldPendingScript,
      structure: [...oldPendingScript.structure, newItem],
    }))
  }

  const copyToTurfs = async () => {
    const copyToTurfObjects = await Promise.all(
      copyToTurfOptions.map(id =>
        fetchTurf(id, {
          fields: ['id', { phone_verification_scripts: ['language', 'id'] }],
        })
      )
    )

    const scriptsToUpdate = copyToTurfObjects.map(({ turf }) => {
      const scriptToUpdate = turf.phone_verification_scripts.find(
        script => script.language === pendingScript.language
      )

      const scriptId = scriptToUpdate?.id
      const turfScript = {
        ...pendingScript,
        id: scriptId,
        turf_id: turf.id,
      }

      return scriptId
        ? updatePhoneVerificationScript(scriptId, turfScript)
        : createPhoneVerificationScript(turfScript)
    })

    await Promise.all(scriptsToUpdate)
    setCopyToTurfOptions([])
  }

  const createScript = async applyToOtherTurfs => {
    if (applyToOtherTurfs) {
      await copyToTurfs()
    }

    return createPhoneVerificationScript(
      { ...pendingScript, turf_id: pendingScript.turfId },
      qcCallScriptParams
    )
  }

  const updateScript = async applyToOtherTurfs => {
    if (applyToOtherTurfs) {
      await copyToTurfs()
    }

    return updatePhoneVerificationScript(
      pendingScript.id,
      pendingScript,
      qcCallScriptParams
    )
  }

  return (
    <CallScriptsContext.Provider
      value={{
        scriptsByTurf,
        setScriptsByTurf,
        pendingScript,
        setPendingScript,
        copyToTurfOptions,
        setCopyToTurfOptions,
        fetchScriptsByTurf,
        fetchScript,
        setPendingScriptStructureToDefault,
        clearPendingScript,
        toggleCopyToTurfOption,
        moveItemUp,
        moveItemDown,
        removeItem,
        addItem,
        createScript,
        updateScript,
      }}
    >
      {children}
    </CallScriptsContext.Provider>
  )
}

export default CallScriptsContextProvider
