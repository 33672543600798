import { Redirect } from 'react-router-dom'
import { useCurrent } from 'contexts/index'

const HasPowerDialerPermission = ({ children }) => {
  const {
    tenantOptions: { power_dialer_enabled: powerDialerEnabled },
  } = useCurrent()

  if (!powerDialerEnabled) {
    return <Redirect to="/organize/phone_banks" />
  }

  return children
}

export default HasPowerDialerPermission
