import React, { useContext, useEffect, useState } from 'react'
import { Uploader } from 'components'
import {
  TextField,
  ContentBlock,
  Button,
  Modal,
  ButtonBlock,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useEvent } from 'hooks'
import { TemplateContext } from '../TemplateContext/TemplateContext'
import styles from './TemplateEditModal.module.scss'
import TemplateRichTextEditor from '../TemplateRichTextEditor/TemplateRichTextEditor'
import { TEMPLATE_ITEM_OPTIONS } from '../TemplateContext/constants'

const TemplateEditModal = () => {
  const { t } = useTranslation()
  const { activeItemIndex, setActiveItemIndex, content, updateItemValue } =
    useContext(TemplateContext)

  const [value, setValue] = useState(content[activeItemIndex]?.value)
  const activeItem = content[activeItemIndex]

  useEffect(() => {
    setValue(content[activeItemIndex]?.value)
  }, [activeItemIndex])

  const doUpdateItemValue = useEvent(() =>
    updateItemValue(activeItemIndex, value)
  )

  return activeItemIndex !== null ? (
    <Modal
      id="template-edit-modal"
      headerClassName={styles['modal-header']}
      title={`${activeItem.type}`}
      onHide={() => setActiveItemIndex(null)}
      isOpen={activeItemIndex !== null}
      className={styles.modal}
    >
      <Modal.Body>
        {(activeItem.type === TEMPLATE_ITEM_OPTIONS.TITLE ||
          activeItem.type === TEMPLATE_ITEM_OPTIONS.HEADING) && (
          <FieldBlock>
            <TextField
              id={`${activeItem}-type`}
              value={value}
              onChange={val => setValue(val)}
            />
          </FieldBlock>
        )}
        {activeItem.type === TEMPLATE_ITEM_OPTIONS.BUTTON_LINK && (
          <FieldBlock>
            <TextField
              id={`${activeItem}-type-name`}
              label="Text"
              value={value?.name}
              onChange={val => setValue(prevVal => ({ ...prevVal, name: val }))}
            />
            <TextField
              id={`${activeItem}-type-url`}
              label="URL"
              value={value?.url}
              onChange={val => {
                const isValid = val.includes('http') || val.includes('https')
                setValue(prevVal => ({
                  ...prevVal,
                  url: isValid ? val : `http://${val}`,
                }))
              }}
            />
          </FieldBlock>
        )}
        {activeItem.type === TEMPLATE_ITEM_OPTIONS.BODY_COPY &&
          value !== undefined && (
            <TemplateRichTextEditor value={value} setValue={setValue} />
          )}
        {activeItem.type === TEMPLATE_ITEM_OPTIONS.IMAGE && (
          <div>
            {value ? (
              <ContentBlock>
                <img alt={value} src={value} className={styles.image} />
              </ContentBlock>
            ) : (
              <ContentBlock>
                <Uploader
                  label={t('New attachment')}
                  autoUpload
                  endpoint="/system/files/upload?public=true&url=true"
                  onUpload={async (_data, url) => {
                    setValue(url)
                  }}
                  public
                />
              </ContentBlock>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary
            onClick={() => {
              setActiveItemIndex(null)
            }}
          >
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent
            onClick={() => {
              doUpdateItemValue()
              setActiveItemIndex(null)
            }}
          >
            {t('Done')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  ) : null
}

export default TemplateEditModal
