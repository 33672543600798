import { createContext, useContext, useState } from 'react'
import RoleModal from '../RoleModal/RoleModal'

export const RoleModalStateContext = createContext()
export const RoleModalStateActionsContext = createContext()

export const useRoleModalState = () => useContext(RoleModalStateContext)
export const useRoleModalActions = () =>
  useContext(RoleModalStateActionsContext)

const initialState = {
  modalTitle: '',
  modalType: null,
  actionLabel: '',
}

const RoleModalStateProvider = ({ children }) => {
  const [modalState, setModalState] = useState(initialState)

  const openCreateModal = () => {
    setModalState({
      modalTitle: 'New Role',
      actionLabel: 'Save',
      modalType: RoleModal.TYPE.CREATE,
    })
  }

  const openEditModal = () => {
    setModalState({
      modalTitle: 'Edit Role',
      actionLabel: 'Update',
      modalType: RoleModal.TYPE.UPDATE,
    })
  }

  const openDeleteModal = () => {
    setModalState({
      modalTitle: 'Delete Role',
      actionLabel: 'Delete',
      modalType: RoleModal.TYPE.DELETE,
    })
  }

  const closeModal = () => {
    setModalState(initialState)
  }

  return (
    <RoleModalStateActionsContext.Provider
      value={{ openCreateModal, openEditModal, openDeleteModal, closeModal }}
    >
      <RoleModalStateContext.Provider value={modalState}>
        {children}
      </RoleModalStateContext.Provider>
    </RoleModalStateActionsContext.Provider>
  )
}

export default RoleModalStateProvider
