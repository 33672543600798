import { StaffLanguages } from 'qualityControl/packetAssignment/staff/StaffLanguages'

export const PacketListItem = ({
  packetName,
  daysLeft,
  packetLanguage,
  packetQcStatus,
  packetTimeZone,
}) => (
  <div className="list__item">
    <div className="list__item__content">
      <div className="text-content">
        <div key="primaryText" className="primary">
          {packetName}
        </div>
        <div className="secondary">
          {daysLeft} - {packetTimeZone} - {packetQcStatus}
        </div>
      </div>
    </div>
    <div className="list__item__details">
      <div className="button__group" key="actions">
        <StaffLanguages languages={[packetLanguage]} />
      </div>
    </div>
  </div>
)
