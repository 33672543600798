import { useState } from 'react'
import { MenuButton, ListItem } from 'react-md'
import { FieldBlock, TextField } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import ModalStaffList from 'qualityControl/packetAssignment/staff/ModalStaffList'
import StaffItemAssignButton from 'qualityControl/packetAssignment/staff/StaffItemAssignButton'
import PacketList from './PacketList'
import styles from './ModalReassignment.module.scss'

const ModalReassignment = ({ refreshAssignedPackets }) => {
  const { t } = useTranslation()

  const {
    setIsReassignmentScheduleOpen,
    scheduledForReassignmentByTerm: scheduledUsers,
    getScheduledUsersIsLoading,
  } = usePacketAssignment()

  const [userSearch, setUserSearch] = useState('')

  const filteredUsers = scheduledUsers.filter(user =>
    user.name.toLowerCase().includes(userSearch.toLowerCase())
  )

  return (
    <>
      <PacketList />
      <FieldBlock variant="large">
        <div className={styles['search-container']}>
          <TextField
            id="reassign-packets-staff-member-search"
            label={t('Staff member')}
            value={userSearch}
            onChange={setUserSearch}
          />
          <MenuButton
            icon
            listInline
            menuItems={[
              <ListItem
                key={1}
                primaryText={t('Manage Schedule')}
                onClick={() => setIsReassignmentScheduleOpen(true)}
              />,
            ]}
          >
            more_vert
          </MenuButton>
        </div>
      </FieldBlock>
      <ModalStaffList
        users={filteredUsers}
        isUsersLoading={getScheduledUsersIsLoading}
        AssignmentButton={props => (
          <StaffItemAssignButton
            {...props}
            refreshAssignedPackets={refreshAssignedPackets}
          />
        )}
      />
    </>
  )
}

export default ModalReassignment
