import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import { TextBlock, Font } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import { GoalSummaryContext } from '../GoalSummaryContext/GoalSummaryContext'
import styles from './GoalSummary.module.scss'

const GoalNew = () => {
  const cx = classNames.bind(styles)
  const { t } = useTranslation()
  const { totalTarget, weeklySum, isSplit } = useContext(GoalSummaryContext)
  const targetDelta = weeklySum - totalTarget
  const deltaLabel = targetDelta < 0 ? 'Under target by' : 'Exceeds target by'

  return (
    <Section label="Goal for full period" secondary>
      <div className={styles.summary}>
        <TextBlock>
          <Font.Copy variant="highlight">
            {t('Overall collection target')}
          </Font.Copy>
          <Font.Display variant="small">{totalTarget}</Font.Display>
        </TextBlock>
        {isSplit && (
          <>
            <TextBlock>
              <Font.Copy variant="highlight">{t('Weekly sum')}</Font.Copy>
              <Font.Display
                variant="small"
                className={cx({
                  positive: targetDelta >= 0,
                  negative: targetDelta < 0,
                })}
              >
                {weeklySum}
              </Font.Display>
            </TextBlock>
            <TextBlock>
              <Font.Copy variant="highlight">{t(deltaLabel)}</Font.Copy>
              <Font.Display
                variant="small"
                className={cx({
                  positive: targetDelta >= 0,
                  negative: targetDelta < 0,
                })}
              >
                {targetDelta}
              </Font.Display>
            </TextBlock>
          </>
        )}
      </div>
    </Section>
  )
}

export default GoalNew
