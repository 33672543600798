import { useTranslation } from 'react-i18next'
import { Sheet, Section, ButtonBlock, List, ContentBlock } from 'components'
import { ProgressBar } from '@politechdev/blocks-design-system'
import StaffListItem from './StaffListItem'
import ReassignmentScheduleButton from './ReassignmentScheduleButton'
import styles from './StaffListNotice.module.scss'

const ModalStaffList = ({ AssignmentButton, users, isUsersLoading }) => {
  const { t } = useTranslation()

  const isReassignable = users.some(user => user.unassignablePackets === 0)

  return (
    <>
      {!isUsersLoading && !isReassignable && (
        <Section className={styles.notice}>
          <ContentBlock>
            <p>{t(`No assignable users found`)}</p>
          </ContentBlock>
          <ButtonBlock justify="center">
            <ReassignmentScheduleButton />
          </ButtonBlock>
        </Section>
      )}
      <Sheet>
        <ProgressBar show={isUsersLoading} />
        <List>
          {users.map(scheduledUser => (
            <StaffListItem
              key={scheduledUser.staffId}
              user={scheduledUser}
              isAssigning
              Buttons={
                <AssignmentButton
                  disabled={
                    !!scheduledUser.unassignablePackets ||
                    !scheduledUser.isViewable
                  }
                  staffId={scheduledUser.staffId}
                />
              }
            />
          ))}
        </List>
      </Sheet>
    </>
  )
}

export default ModalStaffList
