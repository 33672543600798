import { Sheet, TextBlock, Font } from '@politechdev/blocks-design-system'
import { Avatar, DonutChart } from 'components'
import { useTranslation } from 'react-i18next'
import { formatDisplayName } from 'utils/formatting'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import styles from './InboxHeader.module.scss'
import { useInbox } from '../InboxContext'

const InboxHeader = () => {
  const { t } = useTranslation()

  const { activePackets: assignedPackets } = useQualityControl()

  const {
    completedPackets: completedPhonePackets,
    completedVisualPackets,
    inboxUser: user,
  } = useInbox()

  return (
    <Sheet>
      <div className={styles.header}>
        <div className={styles['avatar-container']}>
          <Avatar
            name={formatDisplayName(user)}
            imageUrl={user.avatar}
            imageLarge
            subtitle={user.turf.name}
          />
          <TextBlock>
            <Font.Copy>
              {`${assignedPackets.length} ${t(
                `${
                  assignedPackets.length === 1 ? 'packet' : 'packets'
                } assigned`
              )}`}
            </Font.Copy>
          </TextBlock>
        </div>

        <div className={styles['charts-container']}>
          <TextBlock>
            <Font.Display variant="small">
              {t('Completed packets in this worklist')}
            </Font.Display>
          </TextBlock>
          <div className={styles['charts-wrapper']}>
            <DonutChart
              value={completedVisualPackets.length}
              denominator={assignedPackets.length}
              label={t('Visual QC')}
              secondary
            />
            <DonutChart
              value={completedPhonePackets.length}
              denominator={assignedPackets.length}
              label={t('Phone verification')}
              secondary
            />
          </div>
        </div>
      </div>
    </Sheet>
  )
}

export default InboxHeader
