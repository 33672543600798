import { TeamNameCell, TeamLeaderNameCell } from './OrganizationTeamTableCells'

export default {
  label: 'organization-teams',
  columns: [
    {
      dataKey: 'name',
      title: 'Team Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: TeamNameCell,
    },
    {
      dataKey: 'members_count',
      title: 'Members',
      type: 'number',
      autoResize: true,
      resizable: true,
      sortable: true,
    },
    {
      dataKey: 'leader_name',
      title: 'Leader name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: TeamLeaderNameCell,
    },
  ],
}
