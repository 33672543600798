import translatedCellRenderer from 'tableCells/TranslatedCell'
import { DELIVERY_STATUSES } from 'constants/deliveries'
import humanizeString from 'humanize-string'
import {
  DeliveryDateCell,
  DeliveryFormsAttachedCell,
  DeliveryIdCell,
  DeliveryLocationCell,
  DeliveryRunnerCell,
  DeliveryStatusCell,
  DeliveryTurfCell,
  DeliveryTypeCell,
} from './DeliveryTableCells'
import {
  DeliveryFormsAttachedCsvCell,
  deliveryLocationCsvCell,
  deliveryRunnerCsvCell,
  deliveryTurfCsvCell,
} from './deliveryCsvCells'

export default {
  label: 'deliveries',
  defaultSortColumn: 'turn_in_date',
  defaultSortOrder: 'desc',
  columns: [
    {
      dataKey: 'id',
      title: 'Delivery ID',
      type: 'number',
      bodyCell: DeliveryIdCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'office_name',
      title: 'Turf',
      type: 'string',
      bodyCell: DeliveryTurfCell,
      headerCell: translatedCellRenderer,
      csvCell: deliveryTurfCsvCell,
    },
    {
      dataKey: 'turn_in_date',
      title: 'Date',
      type: 'date',
      bodyCell: DeliveryDateCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'status',
      title: 'Status',
      type: 'multiple_choice',
      options: DELIVERY_STATUSES.map(status => ({
        label: humanizeString(status),
        value: status,
      })),
      bodyCell: DeliveryStatusCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'turn_in_location_name',
      title: 'Location',
      type: 'string',
      bodyCell: DeliveryLocationCell,
      headerCell: translatedCellRenderer,
      csvCell: deliveryLocationCsvCell,
    },
    {
      dataKey: 'canvasser_full_name',
      title: 'Runner',
      type: 'string',
      bodyCell: DeliveryRunnerCell,
      headerCell: translatedCellRenderer,
      csvCell: deliveryRunnerCsvCell,
    },
    {
      dataKey: 'attached_forms_count',
      title: 'Forms Attached',
      type: 'number',
      sortable: false,
      filterable: false,
      bodyCell: DeliveryFormsAttachedCell,
      headerCell: translatedCellRenderer,
      csvCell: DeliveryFormsAttachedCsvCell,
    },
    {
      dataKey: 'delivery_method',
      title: 'Type',
      type: 'string',
      bodyCell: DeliveryTypeCell,
      headerCell: translatedCellRenderer,
    },
  ],
}
