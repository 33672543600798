import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useContextMenuEvent } from 'contexts'
import { useMounted, usePrevious } from 'hooks'
import Portal from './Portal/Portal'
import {
  KeyboardNavProvider,
  useKeyboardNav,
} from './KeyboardNavProvider/KeyboardNavProvider'
import Item from './Item/Item'
import Divider from './Divider/Divider'
import { getMousePosition } from './utils'
import styles from './ContextMenu.module.scss'

const cx = classNames.bind(styles)

const Menu = ({ onOpen, onClose, className, children, ...props }) => {
  const id = JSON.stringify(props.id)
  const isMounted = useMounted()
  const [isVisible, setVisible] = useState(false)
  const [triggerCoordinates, setTriggerCoordinates] = useState({ x: 0, y: 0 })
  const [menuCoordinates, setMenuCoodinates] = useState({ x: 0, y: 0 })
  const wasVisible = usePrevious(isVisible)

  const menuRef = useRef(null)
  const { register, deregister, clear } = useContextMenuEvent()
  const { clearItems, moveDown, moveUp } = useKeyboardNav()

  const openMenu = event => {
    event.stopPropagation()
    const position = getMousePosition(event)

    setVisible(true)
    setTriggerCoordinates({ x: position.x, y: position.y })
  }

  const closeMenu = () => {
    setVisible(false)
    clearItems()
  }

  useEffect(() => {
    register(id, { open: openMenu, close: closeMenu })

    return () => {
      deregister(id)
    }
  }, [id])

  useEffect(() => {
    if (isMounted && isVisible !== wasVisible) {
      if (isVisible && onOpen) {
        onOpen()
      }
      if (!isVisible && onClose) {
        onClose()
      }
    }
  }, [isVisible, onClose, onOpen])

  useEffect(() => {
    if (isVisible) {
      const { innerWidth: windowWidth, innerHeight: windowHeight } = window
      const { offsetWidth: menuWidth, offsetHeight: menuHeight } =
        menuRef.current
      let { x, y } = triggerCoordinates

      if (x + menuWidth > windowWidth) {
        x -= x + menuWidth - windowWidth
      }

      if (y + menuHeight > windowHeight) {
        y -= y + menuHeight - windowHeight
      }

      setMenuCoodinates({ x, y })
    }
  }, [isVisible, triggerCoordinates])

  useEffect(() => {
    const handleKeydown = event => {
      event.preventDefault()
      switch (event.key) {
        case 'Enter':
        case 'Escape':
          clear()
          break
        case 'ArrowUp':
          moveUp()
          break
        case 'ArrowDown':
          moveDown()
          break
        default:
      }
    }

    const handleClose = event => {
      if (
        event &&
        (event.button === 2 || event.ctrlKey === true) &&
        event.type !== 'contextmenu'
      ) {
        return
      }
      if (event && event.target === menuRef.current && event.type === 'click') {
        return
      }

      clear()
    }

    if (isVisible) {
      document.addEventListener('resize', handleClose)
      document.addEventListener('contextmenu', handleClose)
      document.addEventListener('click', handleClose)
      document.addEventListener('scroll', handleClose, true)
      document.addEventListener('blur', handleClose)
      document.addEventListener('keydown', handleKeydown)
    }

    return () => {
      document.removeEventListener('resize', handleClose)
      document.removeEventListener('contextmenu', handleClose)
      document.removeEventListener('click', handleClose)
      document.removeEventListener('scroll', handleClose, true)
      document.removeEventListener('blur', handleClose)
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isVisible])

  return (
    <Portal>
      {isVisible && (
        <div
          role="menu"
          ref={menuRef}
          className={cx('menu', className)}
          style={{
            left: menuCoordinates.x,
            top: menuCoordinates.y,
          }}
          {...props}
        >
          {children}
        </div>
      )}
    </Portal>
  )
}

const ContextMenu = props => (
  <KeyboardNavProvider>
    <Menu {...props} />
  </KeyboardNavProvider>
)

ContextMenu.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

ContextMenu.Item = Item
ContextMenu.Divider = Divider

export default ContextMenu
