import { EditableContent } from 'components'
import { useTranslation } from 'react-i18next'

const NewComment = ({ createComment }) => {
  const { t } = useTranslation()

  return (
    <EditableContent
      isNew
      newButtonText={t('Comment')}
      label={t('Comment')}
      content=""
      onSave={createComment}
    />
  )
}

export default NewComment
