import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { Button } from '@politechdev/blocks-design-system'
import { formatDisplayName } from 'utils/formatting'

export const PersonNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/build/meetings/${rowData.id}`}>
      {rowData.person ? rowData.person.name : '[Person removed]'}
    </Link>
  </TableGridCell>
)

export const PersonFirstNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.person.first_name}</TableGridCell>
)

export const PersonLastNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.person.last_name}</TableGridCell>
)

export const HostNameCell = ({ rowData }) => (
  <TableGridCell>{formatDisplayName(rowData.user)}</TableGridCell>
)

export const MeetingLocationCell = ({ rowData }) => (
  <TableGridCell>{rowData.location?.name}</TableGridCell>
)

export const MeetingDateCell = ({ rowData }) => (
  <TableGridCell>
    {`${moment(rowData.start_time).format(dateFormat)}, ${moment(
      rowData.start_time
    ).format('h A')} - ${moment(rowData.end_time).format('h A')}`}
  </TableGridCell>
)

export const MeetingStatusCell = ({ rowData }) => {
  let status

  if (rowData.cancelled) {
    status = 'Cancelled'
  } else if (moment().isBefore(moment(rowData.end_time))) {
    status = 'Open'
  } else {
    status = 'Completed'
  }
  return <TableGridCell>{status}</TableGridCell>
}

export const MeetingNotesCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.notes ? (
      rowData.notes
    ) : (
      <Link to={`/build/meetings/${rowData.id}/edit`}>
        <Button primary>Add Notes</Button>
      </Link>
    )}
  </TableGridCell>
)

export const MeetingTypeCell = ({ rowData }) => (
  <TableGridCell>{rowData.type}</TableGridCell>
)
