import {
  Grid,
  DetailItem,
  Section,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchSentEmail } from 'requests/email'
import { formatDisplayName } from 'utils/formatting'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import shadow from 'react-shadow'
import TemplatePreviewLabel from 'email/template/TemplatePreviewLabel/TemplatePreviewLabel'
import { buildContactList } from './utils'
import styles from './EmailSingle.module.scss'

const EmailSingle = () => {
  const { match } = useReactRouter()
  const { t } = useTranslation()
  const { id } = match.params
  const [email, setEmail] = useState({})
  const { makeRequest, isRequestComplete, hasErrors } = useRequest(
    fetchSentEmail,
    {
      onSuccess: ({ sent_email }) => {
        setEmail(sent_email)
      },
    }
  )

  useEffect(() => {
    makeRequest(id, {
      fields: [
        'subject',
        { template: ['name'] },
        { list: ['name'] },
        { team: ['name'] },
        { event: ['name'] },
        { sender: ['email', 'full_name'] },
        'recipients_count',
        'body',
        'created_at',
      ],
    })
  }, [])

  const requestLoadedSuccessfully = isRequestComplete && !hasErrors

  const displayData = data =>
    (requestLoadedSuccessfully && data) ||
    (!isRequestComplete && `${t('Loading')}...`) ||
    t('Unable to load')

  return (
    <div>
      <PageHeader
        title={displayData(email.template?.name)}
        subtitle={displayData(
          `${t('Sent on')} ${moment(email.created_at).format(dateFormat)}`
        )}
      />
      <Grid>
        <div>
          <DetailItem label={t('Subject')}>
            {displayData(email.subject)}
          </DetailItem>
          <DetailItem label={t('Draft used')}>
            {displayData(email.template?.name)}
          </DetailItem>
        </div>
        <div>
          <DetailItem label={t('Contact list')}>
            {displayData(buildContactList(email, t))}
          </DetailItem>
          <DetailItem label={t('Emails sent')}>
            {displayData(email.recipients_count)}
          </DetailItem>
          <DetailItem label={t('Sent by')}>
            {displayData(formatDisplayName(email.sender))}
          </DetailItem>
        </div>
      </Grid>
      {requestLoadedSuccessfully && (
        <Section className={styles.preview}>
          <TemplatePreviewLabel />
          <shadow.div>
            <div dangerouslySetInnerHTML={{ __html: email.body }} />
          </shadow.div>
        </Section>
      )}
    </div>
  )
}

export default EmailSingle
