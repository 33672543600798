import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import { useTranslation } from 'react-i18next'

export const StaffItemButtons = ({ staffId, disabled }) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()

  return (
    <ButtonBlock justify="right">
      <Button
        disabled={disabled}
        onClick={() => {
          history.push(`${match.url}/${staffId}/inbox`)
        }}
      >
        {t('View QC worklist')}
      </Button>
      <Button.Accent
        disabled={disabled}
        onClick={() => {
          history.push(`${match.url}/${staffId}`)
        }}
      >
        {t('Manage Packets')}
      </Button.Accent>
    </ButtonBlock>
  )
}
