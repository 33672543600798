import { useState } from 'react'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  SelectField,
  TextField,
  Section,
  DetailTable,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import FormattedData from 'components/FormattedData'
import { localizeTime } from 'utils/dateTime'
import { getPositionHistory } from './utils'

const PositionField = ({
  options,
  isLoading,
  newPosition,
  setNewPosition,
  isAddingNew,
}) => {
  const { t } = useTranslation()

  return isAddingNew ? (
    <TextField
      id="new-position"
      label={t('New position')}
      value={newPosition}
      onChange={setNewPosition}
    />
  ) : (
    <SelectField
      id="position"
      label={t('Position')}
      value={newPosition}
      options={options}
      onSelect={setNewPosition}
      loading={isLoading}
    />
  )
}

const PersonPosition = ({
  person,
  positionOptions,
  updatePersonReq,
  isLoading,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [newPosition, setNewPosition] = useState(null)
  const [newPositionOptions, setNewPositionOptions] = useState([])
  const [isAddingNew, setIsAddingNew] = useState(false)
  const positionHistory = getPositionHistory(person)
  const { t } = useTranslation()

  const handleAddNewPosition = async () => {
    if (newPosition?.length) {
      updatePersonReq(person.id, {
        position: newPosition,
      })
      if (
        ![...positionOptions, ...newPositionOptions].find(
          position => position.value === newPosition
        )
      ) {
        setNewPositionOptions(current => [
          ...current,
          { label: newPosition, value: newPosition },
        ])
      }
      setNewPosition(null)
      setIsEditing(false)
      setIsAddingNew(false)
    }
  }

  return (
    <div>
      <Section
        label={t('Position history')}
        empty={!positionHistory.length && !isLoading}
        emptyMessage={t('No positions logged')}
        collapsed
      >
        <DetailTable columnHeaders={['Position name', 'Date added'].map(t)}>
          {positionHistory.map((history, index) => (
            <DetailTable.Row key={index}>
              <DetailTable.Cell>{history.position}</DetailTable.Cell>
              <DetailTable.Cell>
                <FormattedData
                  type="date"
                  value={localizeTime(history.created_at)}
                />
              </DetailTable.Cell>
            </DetailTable.Row>
          ))}
        </DetailTable>
        <ProgressBar show={isLoading} />
      </Section>
      {isEditing ? (
        <>
          <FieldBlock>
            <PositionField
              options={[
                ...positionOptions,
                ...newPositionOptions,
                {
                  label: 'ADD NEW POSITION',
                  value: () => {
                    setNewPosition('')
                    setIsAddingNew(true)
                    return true
                  },
                },
              ]}
              isLoading={isLoading}
              newPosition={newPosition}
              setNewPosition={setNewPosition}
              isAddingNew={isAddingNew}
            />
          </FieldBlock>
          <ButtonBlock>
            <Button onClick={handleAddNewPosition}>Save</Button>
            <Button.Secondary
              onClick={() => {
                setNewPosition(null)
                setIsEditing(false)
                setIsAddingNew(false)
              }}
            >
              Cancel
            </Button.Secondary>
          </ButtonBlock>
        </>
      ) : (
        <ButtonBlock>
          <Button onClick={() => setIsEditing(current => !current)}>
            {t('Add')}
          </Button>
        </ButtonBlock>
      )}
    </div>
  )
}

export default PersonPosition
