import { useMemo, useState } from 'react'
import { setWith, clone, get, merge } from 'lodash'

const useLocalForm = (initialFormState = {}) => {
  const [formData, internalSetFormData] = useState(initialFormState)
  const [fieldErrors, internalSetFieldErrors] = useState({})

  const setField = (val, key) => {
    internalSetFormData(prev => setWith(clone(prev), key, val))
  }

  const getField = (key, fallback = '') => get(formData, key, fallback)

  const areFieldsValid = useMemo(
    () => Object.values(fieldErrors).every(error => error === null),
    [fieldErrors]
  )

  const setFieldError = (field, message) =>
    internalSetFieldErrors(prevFieldErrors => ({
      ...prevFieldErrors,
      [field]: message,
    }))

  const setFormData = val => {
    internalSetFormData(val)
    internalSetFieldErrors({})
  }

  const mergeWithFieldErrors = errors => {
    internalSetFieldErrors(currentErrors => merge(currentErrors, errors))
  }

  return {
    formData,
    setFormData,
    setField,
    getField,
    setFieldError,
    fieldErrors,
    areFieldsValid,
    mergeWithFieldErrors,
  }
}

export default useLocalForm
