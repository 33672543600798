import { Button } from '@politechdev/blocks-design-system'
import { useReportModalContext } from 'contexts'
import { useTranslation } from 'react-i18next'
import styles from './FlagCommentsModalCell.module.scss'

const FlagCommentsModalCell = ({ rowData }) => {
  const { setIsOpen, setContent, setModalType, MODAL_TYPES } =
    useReportModalContext()
  const { t } = useTranslation()
  if (rowData.comment_count) {
    return (
      <div className="ellipsis-modal-cell">
        <div className="ellipsis-modal-cell__cell-data">
          <Button.Secondary
            className={styles.widthAdjusted}
            onClick={() => {
              setModalType(MODAL_TYPES.FLAG_COMMENTS)
              setContent({
                flagId: rowData.flag_id,
              })
              setIsOpen(true)
            }}
          >
            {rowData.comment_count > 1
              ? `${rowData.comment_count} ${t('comments')}`
              : `1 ${t('comment')}`}
          </Button.Secondary>
        </div>
      </div>
    )
  }
  return (
    <div className="ellipsis-modal-cell">
      <div className="ellipsis-modal-cell__cell-data">{t('No comments')}</div>
    </div>
  )
}

export default FlagCommentsModalCell

export const flagCommentsModalCellRenderer = props => (
  <FlagCommentsModalCell {...props} />
)
