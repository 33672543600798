import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { maskZipcode } from 'utils/inputMasks'

const MAX_ZIP_LENGTH = 10

const ZipCodeField = ({ value, error, errorMessage, onChange, ...props }) => {
  const { t } = useTranslation()

  return (
    <TextField
      label={t('Zip code')}
      regex="(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)"
      value={value}
      error={error}
      errorMessage={errorMessage || t('Invalid zip code')}
      onChange={val => {
        onChange(maskZipcode(val).substring(0, MAX_ZIP_LENGTH))
      }}
      {...props}
    />
  )
}

ZipCodeField.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

ZipCodeField.defaultProps = {
  error: false,
  errorText: '',
}

export default ZipCodeField
