import { createContext, useState } from 'react'
import { createReportId, fetchReportData } from 'requests/registrationReports'
import { createChartId, fetchChartData } from 'requests/weeklyReports'
import { endOfCurrentWeekYMD, startOfCurrentWeekYMD } from 'utils/dateTime'

export const WeeklyReportContext = createContext()

export const WeeklyReportProvider = ({ children }) => {
  const [chartMap, setChartMap] = useState({})
  const [reportMap, setReportMap] = useState({})
  const [filters, setFilters] = useState({
    turf_id: null,
    start_date: startOfCurrentWeekYMD,
    end_date: endOfCurrentWeekYMD,
  })

  const updateChart = (newChart, type) => {
    setChartMap(oldChartMap => ({
      ...oldChartMap,
      [type]: {
        ...oldChartMap[type],
        ...newChart,
      },
    }))
  }

  const updateReport = (newReport, type) => {
    setReportMap(oldReportMap => ({
      ...oldReportMap,
      [type]: {
        ...oldReportMap[type],
        ...newReport,
      },
    }))
  }

  const requestChartId = async (
    chartType,
    userTurfId,
    currentTurfPerformsExternalQC
  ) => {
    const res = await createChartId(chartType, {
      ...filters,
      turf_id: filters.turf_id || userTurfId,
      qc_external: currentTurfPerformsExternalQC,
    })

    updateChart({ id: res.id }, chartType)
    return res.id
  }

  const requestChart = async (chartId, chartType) => {
    try {
      const { statusCode, response } = await fetchChartData(chartId)
      if (statusCode === 204) return

      if (chartMap[chartType]?.id === chartId) {
        updateChart({ data: response }, chartType)
        return { id: chartId, response }
      }
    } catch (error) {
      if (chartMap[chartType]?.id === chartId) {
        throw error
      }
    }
  }

  const requestReportId = async (
    reportType,
    userTurfId,
    currentTurfPerformsExternalQC
  ) => {
    const res = await createReportId(reportType, {
      ...filters,
      turf_id: filters.turf_id || userTurfId,
      qc_external: currentTurfPerformsExternalQC,
    })

    updateReport({ id: res.id }, reportType)
    return res.id
  }

  const requestReport = async (reportId, reportType) => {
    try {
      const { statusCode, response } = await fetchReportData(reportId)
      if (statusCode === 204) return

      if (reportMap[reportType]?.id === reportId) {
        updateReport({ data: response }, reportType)
        return { id: reportId, response }
      }
    } catch (error) {
      if (reportMap[reportType]?.id === reportId) {
        throw error
      }
    }
  }

  return (
    <WeeklyReportContext.Provider
      value={{
        chartMap,
        reportMap,
        filters,
        setFilters,
        requestChartId,
        requestChart,
        requestReportId,
        requestReport,
      }}
    >
      {children}
    </WeeklyReportContext.Provider>
  )
}
