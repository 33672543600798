import React, { useState, useEffect } from 'react'
import { Checkbox } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './InterestCheckboxGroup.module.scss'

const cx = classNames.bind(styles)

const InterestCheckboxGroup = ({
  id,
  values,
  options,
  onChange,
  disabled,
  required,
  error,
  errorText,
  className,
}) => {
  const { t } = useTranslation()
  const [selectedOptions, setSelectedOptions] = useState(values)
  const [isPristine, setPristine] = useState(true)

  const addSelectedOption = option => {
    setSelectedOptions([...values, option.value])
  }

  const removeSelectedOption = ({ value }) => {
    setSelectedOptions(values.filter(option => option !== value))
  }

  const handleChange = (checked, option) => {
    isPristine && setPristine(false)
    if (checked) {
      addSelectedOption(option)
    } else {
      removeSelectedOption(option)
    }
  }

  useEffect(() => {
    !isPristine && onChange && onChange(selectedOptions)
  }, [selectedOptions])

  const checkboxValues = values || selectedOptions
  const hasValue = checkboxValues?.length

  const fieldError = (!isPristine && required && !hasValue) || error
  const fieldErrorText =
    fieldError && required && !hasValue ? t('Required') : errorText

  return (
    <div className={cx('container', className)}>
      {options.map(option => (
        <Checkbox
          key={option.value}
          checked={checkboxValues.some(value => value === option.value)}
          onChange={checked => handleChange(checked, option)}
          id={`${option.value}-${id}`}
          name={`${option.value}-${id}`}
          label={t(option.label)}
          value={option.value}
          disabled={disabled}
        />
      ))}
      {fieldError && <div className={styles.error}>{fieldErrorText}</div>}
    </div>
  )
}

InterestCheckboxGroup.propTypes = {
  id: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
}

InterestCheckboxGroup.defaultProps = {
  id: '',
  values: [],
  disabled: false,
  required: false,
  error: false,
  errorText: '',
}

export default InterestCheckboxGroup
