import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import classNames from 'classnames/bind'
import styles from './EventCalendarItem.module.scss'

const cx = classNames.bind(styles)

const EventCalendarItem = ({
  start,
  startInRange,
  end,
  endInRange,
  id,
  type,
  name,
  person,
  location,
}) => {
  const { t } = useTranslation()

  let dateFormat = 'h:mma'
  if (!startInRange || !endInRange) {
    dateFormat = 'M/D'
  }

  const dateRange = [start.format(dateFormat), end.format(dateFormat)]
    .join(' – ')
    .replace(/:00/gi, '')

  return (
    <Link
      to={type === 'event' ? `/build/events/${id}` : `/build/meetings/${id}`}
      className={cx('item', type, {
        past: start.isBefore(moment()),
      })}
    >
      {dateRange} {person?.name || name}{' '}
      {location && `${t('at')} ${location?.name}`}
    </Link>
  )
}

export default EventCalendarItem
