import React from 'react'
import PropTypes from 'prop-types'
import { FontIcon, TextField as ReactMdTextField } from 'react-md'
import classNames from 'classnames/bind'
import { Icon } from '@politechdev/blocks-design-system'

import styles from './TextField.module.scss'

const cx = classNames.bind(styles)

const TextField = ({
  hintText,
  clearable,
  loading,
  ignoreWhitespace = false,
  ...props
}) => (
  <div className={styles.wrapper}>
    <ReactMdTextField
      {...props}
      onBlur={
        ignoreWhitespace
          ? val => {
              props.onBlur?.(val)
            }
          : val => {
              props.onChange?.(props.value?.trim())
              props.onBlur?.(val)
            }
      }
      helpText={hintText}
      className={cx('field', props.className)}
    />
    {clearable && !!props.value?.length && (
      <div className={styles.clear} onClick={() => props.onChange?.('')}>
        <FontIcon>clear</FontIcon>
      </div>
    )}
    {loading && (
      <div className={styles.load}>
        <Icon.Spinner />
      </div>
    )}
  </div>
)

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  hintText: PropTypes.string,
  clearable: PropTypes.bool,
}

TextField.defaultProps = {
  id: '',
  label: '',
  className: '',
  disabled: false,
  error: false,
  errorText: '',
  hintText: '',
}

export default TextField
