import React, { useState, useContext, useEffect } from 'react'
import {
  Button,
  ButtonBlock,
  Section,
  SectionLabel,
  NumberField,
} from '@politechdev/blocks-design-system'
import moment, { duration } from 'moment'
import { dateFormat } from 'utils/constants'
import { GoalSummaryContext } from '../GoalSummaryContext/GoalSummaryContext'
import styles from './GoalBreakdown.module.scss'
import BreakdownPaginator from './BreakdownPaginator/BreakdownPaginator'
import BulkChangesModal from './BulkChangesModal/BulkChangesModal'

const GoalBreakdown = ({ readonly = false }) => {
  const {
    totalStartDate,
    totalEndDate,
    totalTarget,
    setWeeklySum,
    targets,
    setTargets,
    isSplit,
    setIsSplit,
  } = useContext(GoalSummaryContext)
  const [pageMap, setPageMap] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [weekCount, setWeekCount] = useState(0)
  const pageSize = 8
  const [isBulkChangesModalVisible, setIsBulkChangesModalVisible] =
    useState(false)

  const sumAllTargetValues = () => {
    const sumValues = (prevCount, currentValue) => prevCount + +currentValue
    const targetsSummed = Object.values(targets)
      .map(target => target.registrationsToCollect)
      .reduce(sumValues, 0)
    setWeeklySum(targetsSummed)
  }

  const splitByWeek = () => {
    const newWeekCount = Math.ceil(
      duration(moment(totalEndDate).diff(moment(totalStartDate))).asWeeks()
    )
    const startingWeeklyTarget = Math.ceil(totalTarget / newWeekCount)

    const createTargetObject = weekIndex => {
      const weekStart = moment(totalStartDate).add(weekIndex, 'weeks')
      const weekEnd = moment(weekStart)
        .add(6, 'days')
        .isBefore(moment(totalEndDate))
        ? moment(weekStart).add(6, 'days')
        : moment(totalEndDate)
      setTargets(prevState => ({
        ...prevState,
        [weekIndex]: {
          index: weekIndex,
          startDate: weekStart.format(),
          endDate: weekEnd.format(),
          label: `${weekStart.format(dateFormat)} - ${weekEnd.format(
            dateFormat
          )}`,
          registrationsToCollect: startingWeeklyTarget,
        },
      }))
    }

    const indexArray = Array.from(Array(newWeekCount).keys())

    indexArray.forEach(createTargetObject)
    setWeekCount(newWeekCount)
    setIsSplit(true)
  }

  const updateTarget = (val, targetIndex) => {
    setTargets(prevState => ({
      ...prevState,
      [targetIndex]: {
        ...prevState[targetIndex],
        registrationsToCollect: val,
      },
    }))
  }

  const revertSplit = () => {
    setIsSplit(false)
    setTargets({
      0: {
        index: 1,
        startDate: moment(totalStartDate).format(),
        endDate: moment(totalEndDate).format(),
        label: `${moment(totalStartDate).format(dateFormat)} - ${moment(
          totalEndDate
        ).format(dateFormat)}`,
        registrationsToCollect: totalTarget,
      },
    })
  }

  const createPageMap = () => {
    const newPageMap = {}
    let pageNum = 0

    const newWeekCount = Math.ceil(
      duration(moment(totalEndDate).diff(moment(totalStartDate))).asWeeks()
    )

    for (let weekNum = 1; weekNum <= newWeekCount; weekNum += 1) {
      if (weekNum % pageSize === 1) {
        pageNum += 1
        const currentTarget = targets[weekNum - 1]
        const pageStartLabel = moment(currentTarget.startDate).format(
          dateFormat
        )

        const pageEndLabel = moment(totalEndDate).isBefore(
          moment(currentTarget.startDate).add(55, 'days')
        )
          ? moment(totalEndDate).format(dateFormat)
          : moment(currentTarget.startDate).add(55, 'days').format(dateFormat)

        const pageLabel = `${pageStartLabel} - ${pageEndLabel}`
        newPageMap[pageNum] = {
          pageNumber: pageNum,
          label: pageLabel,
        }
      }
    }
    setWeekCount(newWeekCount)
    setPageMap(newPageMap)
  }

  useEffect(() => {
    sumAllTargetValues()
    if (targets.length > 2) {
      createPageMap()
    }
  }, [targets])

  useEffect(() => {
    if (targets.length > 1) setIsSplit(true)
  }, [targets])

  useEffect(() => {
    if (!isSplit) return

    createPageMap()
  }, [isSplit])

  return (
    <>
      {isSplit ? (
        <div>
          <BreakdownPaginator
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageMap={pageMap}
            weekCount={weekCount}
          />
          <Section secondary>
            <div className={styles.breakdown__container}>
              {Object.values(targets)
                .slice((currentPage - 1) * pageSize, pageSize * currentPage)
                .map(target => (
                  <div className={styles.breakdown__item} key={target.index}>
                    <SectionLabel>Week {target.index + 1}</SectionLabel>
                    <NumberField
                      className={styles.breakdown__item__field}
                      label={target.label}
                      value={target.registrationsToCollect}
                      onChange={val => updateTarget(val, target.index)}
                      disabled={readonly}
                    />
                  </div>
                ))}
            </div>
            <ButtonBlock>
              <Button.Secondary
                onClick={() => setIsBulkChangesModalVisible(true)}
                disabled={readonly}
              >
                Make changes to multiple weeks
              </Button.Secondary>
            </ButtonBlock>
          </Section>
          <ButtonBlock>
            <Button.Secondary onClick={revertSplit} disabled={readonly}>
              Revert Split
            </Button.Secondary>
          </ButtonBlock>
        </div>
      ) : (
        <ButtonBlock>
          <Button.Secondary
            onClick={() => {
              splitByWeek()
            }}
            disabled={readonly}
          >
            Split by week
          </Button.Secondary>
        </ButtonBlock>
      )}
      <BulkChangesModal
        isVisible={isBulkChangesModalVisible}
        setIsVisible={setIsBulkChangesModalVisible}
      />
    </>
  )
}

export default GoalBreakdown
