import { buildDefaultFilters } from 'utils/reporting'
import canvasserQualitybyGroupConfig from './canvasserQualityByGroup'
import { partisanColumnAppends } from '../columns/partisanColumnAppends'

export default {
  id: 'canvasser_quality_by_group_party',
  name: 'Group',
  description:
    'This report compares total forms from the cover sheet to total forms from the scans and counts from the visual QC process. The data is broken out by both raw totals and percentages.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1xABMR2DqVMaDx-u18b9gH-yh0-wh7P6Av2BVBU2zodE/edit#gid=2049147248',
  columns: [...canvasserQualitybyGroupConfig.columns, ...partisanColumnAppends],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Canvass Information',
        visibleColumns: [
          'turf_name',
          'active_turf',
          'total_scans_cover',
          'scans_per_field_hour_cover',
          'total_scans_qc',
          'complete_scan_percent_qc',
          'percent_with_phones_qc',
          'target_partisan_two_way_percent',
          'total_district_target_forms',
          'target_district_form_percent',
        ],
      },
    ])
  },
}
