import { useContext, useState } from 'react'
import {
  Button,
  ContentBlock,
  Font,
  Icon,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { ButtonBlock } from 'components/'
import { defaultTo } from 'lodash'
import RolePermissions from '../RolePermissions/RolePermissions'
import styles from './RoleSection.module.scss'
import { RoleContext } from '../RoleContext/RoleContext'

const RoleSection = ({ permissions, title }) => {
  const [isOpen, setIsOpen] = useState(true)
  const { currentRoleParent: parentRole } = useContext(RoleContext)
  const { t } = useTranslation()

  return (
    <>
      <ButtonBlock className={styles['button-block']}>
        <Font.Copy variant="secondary">{title}</Font.Copy>
        {permissions.length > 1 && (
          <Button.Secondary onClick={() => setIsOpen(prev => !prev)}>
            {isOpen ? (
              <>
                <span>{t('Hide')}</span> <Icon.AngleUp />
              </>
            ) : (
              <>
                <span>{t('Show')}</span> <Icon.AngleDown />
              </>
            )}
          </Button.Secondary>
        )}
      </ButtonBlock>
      <ContentBlock>
        {isOpen &&
          permissions.map(permission => (
            <RolePermissions
              key={permission.key || permission.id}
              permission={permission}
              parentAdmin={parentRole.admin}
              parentPermissions={
                defaultTo(
                  parentRole.permissions.find(
                    p => p.resource === permission.id
                  ),
                  { abilities: [] }
                ).abilities
              }
            />
          ))}
      </ContentBlock>
    </>
  )
}

export default RoleSection
