import { Font } from '@politechdev/blocks-design-system'
import { INTERPOLATED_VALUES } from './utils'
import { useCallSessionContext } from '../CallSessionContext/CallSessionContext'

const InterpolatedContent = ({ text }) => {
  const { currentParticipant: participant } = useCallSessionContext()
  const interpolatedContent = Object.keys(INTERPOLATED_VALUES).reduce(
    (results, key) =>
      results.flatMap(piece => {
        if (typeof piece !== 'string') return piece
        const value = INTERPOLATED_VALUES[key](participant)
        return piece
          .split(key)
          .flatMap((stringPiece, index) =>
            index ? [<strong>{value}</strong>, stringPiece] : stringPiece
          )
      }),
    [text]
  )

  return (
    <Font.Copy>
      {interpolatedContent.map((content, index) => (
        <span key={`interpolated-${index}`}>{content}</span>
      ))}
    </Font.Copy>
  )
}

export default InterpolatedContent
