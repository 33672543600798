import { useTranslation } from 'react-i18next'
import { Tab } from '@politechdev/blocks-design-system'
import ShiftsTab from '../ShiftsTab/ShiftsTab'
import GuestsTable from '../GuestsTable/GuestsTable'

const EventTabs = () => {
  const { t } = useTranslation()

  return (
    <Tab.Container>
      <Tab label={t('Guests')}>
        <GuestsTable />
      </Tab>
      <Tab label={t('Shifts')}>
        <ShiftsTab />
      </Tab>
    </Tab.Container>
  )
}

export default EventTabs
