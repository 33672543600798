import { useTranslation } from 'react-i18next'
import { Font, Radio } from '@politechdev/blocks-design-system'
import styles from 'qualityControl/pledgeCardGallery/PledgeCardGallery.module.scss'
import {
  usePledgeCardGalleryState,
  ViewerType,
} from 'qualityControl/pledgeCardGallery/PledgeCardGalleryContext/PledgeCardGalleryContext'
import useGalleryViewOptions from './useGalleryViewOptions'

const GalleryViewOptions = () => {
  const { t } = useTranslation()
  const { viewerType } = usePledgeCardGalleryState()
  const options = [
    {
      value: ViewerType.Form,
      label: 'Corresponding registration',
    },
    {
      value: ViewerType.Pledge,
      label: 'Other pledge cards',
    },
  ]

  const { changeViewType } = useGalleryViewOptions()

  return (
    <div>
      <Font.Copy variant="hint">{t('Compare to')}</Font.Copy>
      <Radio.Group
        id="view-choice"
        name="view-choice"
        key="view-choice"
        type="radio"
        aria-label="Compare view toggle"
        value={viewerType}
        options={options}
        className={styles.subheader__radios}
        onChange={changeViewType}
      />
    </div>
  )
}

export default GalleryViewOptions
