import { useContext, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  Section,
  Modal,
  SelectField,
  NumberField,
} from '@politechdev/blocks-design-system'
import slice from 'lodash/slice'
import useEvent from 'hooks/useEvent'
import { GoalSummaryContext } from '../../GoalSummaryContext/GoalSummaryContext'

const BulkChangesModal = ({ isVisible, setIsVisible }) => {
  const { t } = useTranslation()
  const { targets, setTargets } = useContext(GoalSummaryContext)

  const [collection, setCollection] = useState(0)
  const [startWeekIndex, setStartWeekIndex] = useState(-1)
  const [endWeekIndex, setEndWeekIndex] = useState(-1)

  useEffect(() => {
    setStartWeekIndex(-1)
    setEndWeekIndex(-1)
    setCollection(0)
  }, [isVisible])

  const isValid = startWeekIndex !== -1 && endWeekIndex !== -1

  const startWeeks = useMemo(
    () =>
      Object.values(targets).map((t, i) => ({
        value: i,
        label: `${i + 1} Week | ${t.label}`,
      })),
    [targets]
  )

  const endWeeks = useMemo(() => {
    const weeks = slice(startWeeks, startWeekIndex + 1)
    return weeks
  }, [startWeekIndex, startWeeks])

  const updateTarget = (val, indexes) => {
    setTargets(prevState => ({
      ...prevState,
      ...indexes.reduce(
        (a, c) => ({
          ...a,
          [c]: {
            ...prevState[c],
            registrationsToCollect: +val,
          },
        }),
        {}
      ),
    }))
  }

  const onApply = useEvent(() => {
    if (startWeekIndex < 0 || endWeekIndex <= startWeekIndex) return

    updateTarget(
      collection,
      Array(endWeekIndex - startWeekIndex + 1)
        .fill()
        .map((_, i) => i + startWeekIndex)
    )
    setIsVisible(false)
  })

  return (
    <Modal
      id="bulk-goal-changes"
      title={t('Make changes to multiple weeks')}
      isOpen={isVisible}
    >
      <Modal.Body>
        <Section>
          <FieldBlock>
            <NumberField
              label="Weekly collection target"
              value={collection}
              onChange={val => setCollection(val)}
            />
          </FieldBlock>
        </Section>
        <Section label={t('Apply to weeks')}>
          <FieldBlock variant="medium">
            <SelectField
              label="Starting week"
              value={startWeekIndex}
              onSelect={val => setStartWeekIndex(val)}
              options={startWeeks}
            />
          </FieldBlock>
          <FieldBlock variant="medium">
            <SelectField
              label="Ending week"
              value={endWeekIndex}
              onSelect={val => setEndWeekIndex(val)}
              options={endWeeks}
            />
          </FieldBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary
            onClick={() => {
              setIsVisible(false)
            }}
          >
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={onApply} disabled={!isValid}>
            {t('Apply')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default BulkChangesModal
