import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Font, Icon } from '@politechdev/blocks-design-system'
import { ContentBlock } from 'components'
import PledgeCardPdfViewer from 'qualityControl/pledgeCardGallery/PledgeCardPdfViewer/PledgeCardPdfViewer'
import { usePledgeCardGalleryActions } from 'qualityControl/pledgeCardGallery/PledgeCardGalleryContext/PledgeCardGalleryContext'
import styles from './ScanCarousel.module.scss'
import useComparePledge from '../useComparePledge'

const PledgeCardCompareHeader = () => {
  const { t } = useTranslation()
  const { setSecondScan, setQueryParams } = usePledgeCardGalleryActions()
  const [pledgePage, setPledgePage] = useState(0)
  const { pagedScans } = useComparePledge()

  if (!pagedScans.length) return null

  return (
    <div>
      <ContentBlock>
        <Font.Label variant="section">{t('Compare to other scans')}</Font.Label>
      </ContentBlock>
      <ContentBlock className={styles.carousel}>
        <Button.Secondary
          onClick={() => setPledgePage(pledgePage - 1)}
          disabled={pledgePage === 0}
        >
          <Icon.ArrowLeft />
        </Button.Secondary>
        {pagedScans[pledgePage].map(scan => {
          const { id, pledgeUrl, fileLocator } = scan
          return (
            <div
              className={styles.carousel__item}
              onClick={() => {
                setSecondScan(scan)
                setQueryParams({
                  secondScanId: scan.id,
                })
              }}
            >
              <Font.Copy variant="highlight">
                {t('Registration')} {' #'} {id}
              </Font.Copy>
              <PledgeCardPdfViewer
                scanUrl={pledgeUrl}
                removedAt={fileLocator?.redacted_at}
              />
            </div>
          )
        })}
        <Button.Secondary
          onClick={() => setPledgePage(pledgePage + 1)}
          disabled={pledgePage === pagedScans.length - 1}
        >
          <Icon.ArrowRight />
        </Button.Secondary>
      </ContentBlock>
    </div>
  )
}

export default PledgeCardCompareHeader
