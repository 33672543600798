import { combineName, formatActiveColumn } from 'utils/reporting'
import { YES_NO_OPTIONS } from 'registrationReports/constants'
import { baseGeneralColumns } from './baseGeneralColumns'

export default {
  petitions_by_canvasser: {
    id: 'petitions_by_canvasser',
    name: 'Canvasser',
    dataDictionaryUrl:
      'https://docs.google.com/spreadsheets/d/1Br0BHxyRR2ZZ3Uzwqr-wi3fCKC6169NtDJR3Dbjo_D4/edit#gid=2049147248',
    columns: [
      {
        dataKey: 'canvasser_name',
        title: 'Canvasser Name',
        type: 'string',
        resizable: true,
        deriveFunction: ({ rowData }) =>
          combineName(
            rowData.canvasser_first_name,
            rowData.canvasser_last_name
          ),
      },
      {
        dataKey: 'active_canvasser',
        title: 'Active Canvasser',
        type: 'enum',
        options: YES_NO_OPTIONS,
        resizable: true,
        deriveFunction: ({ rowData }) =>
          formatActiveColumn(rowData.canvasser_archived),
      },
      ...baseGeneralColumns,
    ],
  },
}
