import i18n from 'i18next'
import LocizeBackend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { locizePlugin } from 'locize'

i18n.use(LocizeBackend).use(LanguageDetector).use(initReactI18next)

const backendConfig = {
  projectId: process.env.LOCIZE_PROJECT_ID,
  apiKey: process.env.LOCIZE_API_KEY,
  referenceLng: 'en',
}

if (process.env.NODE_ENV === 'development') {
  i18n.use(locizePlugin)
  backendConfig.allowedAddOrUpdateHosts = () => true
}

export const i18nConfig = {
  compatibilityJSON: 'v3',
  bindStore: 'added removed',
  lng: 'en',
  fallbackLng: 'en',
  appendNamespaceToCIMode: true,
  ns: 'translations',
  fallbackNS: 'translations',
  saveMissing: process.env.NODE_ENV === 'development',
  debug: false,
  keySeparator: '### not used ###', // we use content as keys
  nsSeparator: '## not used ##',
  backend: backendConfig,
  interpolation: {
    escapeValue: false, // not needed for react
    formatSeparator: ',',
    format(value, format) {
      if (format === 'uppercase') return value.toUpperCase()
      return value
    },
  },
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged editorSaved',
  },
}

i18n.init(i18nConfig)

export default i18n
