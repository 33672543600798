import { useEffect, useState } from 'react'
import { FontIcon } from 'react-md'
import { useTranslation } from 'react-i18next'
import { defaultTo } from 'lodash'
import { ContentBlock, FieldBlock, TextField } from 'components'
import { useTurfs } from 'contexts/index'
import TurfListItem from './TurfListItem'
import styles from './TurfList.module.scss'

const TurfList = ({
  turfSearch,
  setTurfSearch,
  updatedTurfPriorities,
  setTurfDaysLeftToQc,
}) => {
  const { t } = useTranslation()

  const { refreshCurrentTurfs, currentTurfs } = useTurfs()

  const [selectedId, setSelectedId] = useState(null)

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  const filteredTurfs = currentTurfs.filter(turf =>
    turf.name.toLowerCase().includes(turfSearch.toLowerCase())
  )

  return (
    <div className={styles.container}>
      <FieldBlock>
        <TextField
          className="margin--bottom"
          id="reprioritization-turf-name-filter"
          label={t('Turf name')}
          value={turfSearch}
          onChange={setTurfSearch}
          leftIcon={
            turfSearch ? (
              <div
                role="button"
                tabIndex={0}
                onClick={() => setTurfSearch('')}
                onKeyDown={e => e.key === 'Enter' && setTurfSearch('')}
              >
                <FontIcon>clear</FontIcon>
              </div>
            ) : (
              <FontIcon>search</FontIcon>
            )
          }
        />
      </FieldBlock>
      <ContentBlock>
        {(filteredTurfs || []).map(turf => (
          <TurfListItem
            id={`modal-turf-list${turf.id}`}
            key={`modal-turf-list${turf.id}`}
            turfId={turf.id}
            turfName={turf.name}
            reprioritization={defaultTo(updatedTurfPriorities[turf.id], '')}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            setTurfDaysLeftToQc={setTurfDaysLeftToQc}
          />
        ))}
      </ContentBlock>
    </div>
  )
}
export default TurfList
