import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonBlock, Icon } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import styles from './ExpandPanel.module.scss'

const cx = classNames.bind(styles)

const ExpandPanel = ({ label, children, className }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={className}>
      <ButtonBlock>
        <Button.Secondary
          onClick={() => {
            setExpanded(!expanded)
          }}
          className={cx(styles.button)}
        >
          <span>{label}</span>{' '}
          {expanded ? <Icon.ChevronDown alt="" /> : <Icon.ChevronUp alt="" />}
        </Button.Secondary>
      </ButtonBlock>
      <div className={cx('body', { 'body--closed': !expanded })}>
        {children}
      </div>
    </div>
  )
}

ExpandPanel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

export default ExpandPanel
