import classNames from 'classnames/bind'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  CoverPdfViewer,
  ScanPdfViewer,
} from 'qualityControl/scanReview/PdfViewer'
import styles from './GalleryScan.module.scss'

const cx = classNames.bind(styles)

const GalleryScan = ({
  removedAt,
  scan,
  cover,
  width,
  url,
  isTitle = false,
  labelPosition = 'bottom',
}) => {
  const { t } = useTranslation()
  const Tag = url ? Link : 'div'

  if (!cover && !scan) {
    // eslint-disable-next-line no-console
    console.error('Unable to display scan')
    return null
  }

  const label = cover ? t('Cover Sheet') : `${t('Scan')} #${scan.scan_number}`

  const renderLabel = label =>
    isTitle ? (
      <h2 className={styles.title}>{label}</h2>
    ) : (
      <span className={styles.label}>{label}</span>
    )

  return (
    <Tag className={styles.scan} to={url}>
      {labelPosition === 'top' && renderLabel(label)}
      <div
        className={cx('pdf', {
          'pdf--top': labelPosition === 'top',
          'pdf-bottom': labelPosition === 'bottom',
        })}
      >
        {cover ? (
          <CoverPdfViewer
            className={styles.viewer}
            url={cover.file_url}
            removedAt={removedAt}
            width={width}
          />
        ) : (
          <ScanPdfViewer
            className={styles.viewer}
            scanUrl={scan.file_url}
            removedAt={removedAt}
            width={width}
          />
        )}
      </div>
      {labelPosition === 'bottom' && renderLabel(label)}
    </Tag>
  )
}

export default GalleryScan
