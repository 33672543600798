import { fetchJSON, stringifyParams } from 'utils/req'

export function postGoal(goal) {
  fetchJSON(
    `/api/v1/field_management/goals`,
    'POST',
    {
      goal,
    },
    { useJwt: true }
  )
}

export function putGoal(goal, id) {
  fetchJSON(
    `/api/v1/field_management/goals/${id}`,
    'PUT',
    {
      goal,
    },
    { useJwt: true }
  )
}

export function deleteGoal(id) {
  return fetchJSON(`/api/v1/field_management/goals/${id}`, 'DELETE', null, {
    useJwt: true,
  })
}

export function fetchGoals(params) {
  const queryString = stringifyParams(params)

  return fetchJSON(
    `/api/v1/field_management/goals?${queryString}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
}

export function fetchGoal(params) {
  const queryString = stringifyParams(params)

  return fetchJSON(
    `/api/v1/field_management/goals/${params.id}?${queryString}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
}

export function createGoalsPerDay(params) {
  return fetchJSON(
    `/api/v1/reports/voter_registration`,
    'POST',
    {
      report: {
        type: 'goal_collection_per_day',
        ...params,
      },
    },
    {
      useJwt: true,
    }
  )
}

export function fetchGoalsPerDay(id) {
  return fetchJSON(
    `/api/v1/reports/voter_registration/${id}`,
    'GET',
    null,
    {
      useJwt: true,
    },
    {
      showStatusCode: true,
    }
  )
}

export function createProjection(projection) {
  return fetchJSON(
    `/api/v1/field_management/projections`,
    'POST',
    {
      projection,
    },
    { useJwt: true }
  )
}

export function updateProjection(projection, id) {
  return fetchJSON(
    `/api/v1/field_management/projections/${id}`,
    'PUT',
    {
      projection,
    },
    { useJwt: true }
  )
}

export function deleteProjection(id) {
  return fetchJSON(
    `/api/v1/field_management/projections/${id}`,
    'DELETE',
    null,
    {
      useJwt: true,
    }
  )
}

export function fetchProjections(params) {
  const queryString = stringifyParams(params)

  return fetchJSON(
    `/api/v1/field_management/projections?${queryString}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
}

export function fetchProjection(id, params) {
  const queryString = stringifyParams(params)

  return fetchJSON(
    `/api/v1/field_management/projections/${id}?${queryString}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
}
