import React, { createContext, useContext, useState } from 'react'

const SidebarContext = createContext()

export const SidebarProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState({})

  const toggleCollapsed = folderId => {
    setCollapsed({
      ...collapsed,
      [folderId]: !collapsed[folderId],
    })
  }

  const isCollapsed = folderId => !!collapsed[folderId]

  return (
    <SidebarContext.Provider
      value={{
        toggleCollapsed,
        isCollapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }
  return context
}
