import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  PageHeader,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRoutePathParams } from 'hooks'
import { useCurrent } from 'contexts/index'
import ShiftTable from '../ShiftTable/ShiftTable'

const ShiftSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewShifts = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'view',
  })

  const canModifyShifts = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'modify',
  })

  const [{ shiftType }] = useRoutePathParams()
  const isPetitions = shiftType === 'petitions'

  if (!canViewShifts) {
    return (
      <CardError
        hide={canViewShifts}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader
        title={isPetitions ? t('Petition Shifts') : t('Registration Shifts')}
      >
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyShifts && e.preventDefault()}
            to={`${location.pathname}/new`}
          >
            <Button.Accent disabled={!canModifyShifts}>
              {t('Add shift')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer>
        <Sheet>
          <ShiftTable shiftType={shiftType} />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default ShiftSelect
