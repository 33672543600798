import React from 'react'
import logo from 'assets/images/logo.svg'
import lighthouse from 'assets/images/lighthouse.svg'
import edgePieceLeft from 'assets/images/decorative/edge-piece--left.svg'
import edgePieceRight from 'assets/images/decorative/edge-piece--right.svg'
import {
  ContentBlock,
  Font,
  TextBlock,
} from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import styles from './TenantError.module.scss'

const TenantError = () => {
  const cx = classNames.bind(styles)
  const splitHost = window.location.host.split('.')
  const subdomain = splitHost.shift()
  const restOfHost = splitHost.join('.')

  return (
    <div className={styles.container}>
      <div className={styles['top-bar']}>
        <img className={styles.logo} src={logo} alt="logo" />
      </div>
      <ContentBlock>
        <Font.Display variant="large" className={styles.title}>
          This Blocks subdomain does not exist
        </Font.Display>
      </ContentBlock>
      <TextBlock>
        <Font.Copy className={styles.message}>
          “<strong>{subdomain}</strong>.{restOfHost}” doesn’t exist yet. Want to{' '}
          <a href="https://blocks.app" alt="Blocks marketing homepage">
            sign up
          </a>
          ?
        </Font.Copy>
      </TextBlock>
      <img className={styles.lighthouse} src={lighthouse} alt="lighthouse" />
      <img
        className={cx('edge-piece', 'edge-piece--left')}
        src={edgePieceLeft}
        alt=""
      />
      <img
        className={cx('edge-piece', 'edge-piece--right')}
        src={edgePieceRight}
        alt=""
      />
    </div>
  )
}

export default TenantError
