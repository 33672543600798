import { useTranslation } from 'react-i18next'
import { View, Sheet, SubheaderBlock } from 'components'
import TurfForm from '../TurfForm/TurfForm'

const TurfCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <Sheet>
        <SubheaderBlock title={t('New turf')} />
        <TurfForm />
      </Sheet>
    </View>
  )
}

export default TurfCreate
