import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const SupportLink = props => {
  const { t } = useTranslation()

  if (props.hide) {
    return null
  }

  return <span>{t('Please try again or contact support.')}</span>
}

SupportLink.propTypes = {
  hide: PropTypes.bool.isRequired,
}

export default SupportLink
