import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Modal,
} from '@politechdev/blocks-design-system'

const ProjectionDateWarningModal = ({
  isOpen,
  setIsOpen,
  setIsLoading,
  goalToDelete,
  goalDeletionRequest,
}) => {
  const { t } = useTranslation()

  const onApply = () => {
    goalDeletionRequest(goalToDelete)
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
    setIsLoading(false)
  }

  return (
    <Modal
      id="warn-delete-goal"
      title={t('Are you sure you want to delete this goal?')}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <p>
              You are about to permanently delete this goal. This action cannot
              be undone.
            </p>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={onApply}>{t('Apply')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ProjectionDateWarningModal
