import { useEffect, useState, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router'
import { FormattedData, View, CardError } from 'components'
import {
  Section,
  DetailItem,
  Grid,
  Font,
  ContentBlock,
  TextBlock,
  ProgressBar,
  useToast,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useRoutePathParams } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import { useCurrent, QueryParamProvider } from 'contexts/index'
import ShiftPdfViewer from 'shifts/ShiftPdfViewer/ShiftPdfViewer'
import useEvent from 'hooks/useEvent'
import { fetchShift } from 'requests/shifts'
import { advanceShiftStatus } from 'requests/qualityControl'
import { COMPLETED, PETITIONS_COMPLETED } from 'constants/qualityControl'
import SignatureList from './SignatureList'
import SignatureForm from './SignatureForm'
import PageIssues from './PageIssues'
import {
  initialState,
  loadSignatures,
  signaturesReducer,
  updateSignature,
} from './SignatureReducer'
import { shiftReqParams } from './constants'
import styles from './PetitionPacketSingle.module.scss'

const PetitionPacketSingle = () => {
  const [{ id: shiftId, shiftType }] = useRoutePathParams()
  const { t } = useTranslation()
  const { path, isExact } = useRouteMatch()
  const history = useHistory()
  const { setToast } = useToast()
  const { currentUser } = useCurrent()
  const [shift, setShift] = useState(null)
  const [visualReviewResponses, setVisualReviewResponses] = useState([])
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [
    {
      pages,
      signatures,
      totalSignatures,
      reviewedSignatures,
      maxPage,
      reviewsAdded,
    },
    dispatch,
  ] = useReducer(signaturesReducer, initialState)

  const {
    makeRequest: fetchShiftRequest,
    isRequestComplete: isShiftReqComplete,
    hasErrors: shiftHasErrors,
  } = useRequest(fetchShift, {
    onSuccess: ({ shift }) => {
      setShift(shift)
      setVisualReviewResponses(shift.turf.visual_review_responses)
      dispatch(loadSignatures(shift.petition_packet.pages))
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const completeShift = useRequest(
    shiftId => advanceShiftStatus(shiftId, PETITIONS_COMPLETED),
    { onSuccess: () => history.push(`/collect/${shiftType}/shifts`) }
  )
  const completeCurrentShift = useEvent(() =>
    completeShift.makeRequest(shiftId)
  )

  const addReviewCompletedToast = useEvent(() => {
    setToast({
      message: `${reviewedSignatures} of ${totalSignatures} reviews completed`,
      variant: 'success',
    })
  })

  useEffect(() => {
    fetchShiftRequest(shiftId, shiftReqParams)
  }, [shiftId])

  useEffect(() => {
    if (reviewsAdded) {
      addReviewCompletedToast()
    }
  }, [reviewsAdded])

  const handlePageChange = useEvent(change => () => {
    setCurrentPageNumber(current => current + change)
  })

  const handleSignatureChange = useEvent(signature => {
    dispatch(updateSignature(signature))
  })

  const isEditable =
    shift?.petition_packet?.assignee &&
    shift.petition_packet.assignee.id === currentUser.id

  return (
    <View>
      <PageHeader title={shift?.petition_packet?.filename} />
      <ProgressBar show={!isShiftReqComplete} />
      <CardError
        hide={!shiftHasErrors}
        errorMessage={t("We're unable to retrieve this packet")}
      />
      {!!pages && pages.length > 0 && (
        <div>
          <Grid>
            <div>
              <Section label={t('Shift info')}>
                <DetailItem label={t('Office of origin')}>
                  {shift.turf.name}
                </DetailItem>
                <DetailItem label={t('Canvasser')}>
                  {shift.canvasser.full_name}
                </DetailItem>
                <DetailItem label={t('County')}>
                  {shift.petition_packet.county}
                </DetailItem>
                <DetailItem label={t('Shift date')}>
                  <FormattedData type="date" value={shift.shift_start} />
                </DetailItem>
              </Section>
              <Section
                label={t('Petition requirements')}
                secondary
                empty={!shift.turf.petition_requirements.length}
                emptyMessage={t('No petition requirements set')}
                tight
              >
                <TextBlock>
                  <ul className={styles.requirements}>
                    {shift.turf.petition_requirements.map(req => (
                      <li key={req}>
                        <Font.Copy>{req}</Font.Copy>
                      </li>
                    ))}
                  </ul>
                </TextBlock>
              </Section>
            </div>
            <ContentBlock>
              <ShiftPdfViewer
                url={shift.petition_packet.file_url}
                filename={
                  shift.petition_packet.file_locator?.metadata?.filename
                }
                pageNumber={currentPageNumber}
                onNext={handlePageChange(1)}
                onPrevious={handlePageChange(-1)}
                disabled={!isExact}
              />
            </ContentBlock>
          </Grid>
          <PageIssues
            pages={pages}
            currentPageNumber={currentPageNumber}
            visualReviewResponses={visualReviewResponses}
            getUpdatedShift={() => fetchShiftRequest(shiftId, shiftReqParams)}
            isQcCompleted={shift.status === COMPLETED}
          />
          <Switch>
            <Route exact path={`${path}`}>
              <SignatureList
                hasPageLevelIssue={
                  pages.find(page => page.number === currentPageNumber)
                    .visual_review_responses.length > 0
                }
                isEditable={isEditable}
                maxPage={maxPage}
                signatures={signatures[currentPageNumber]}
                currentPageNumber={currentPageNumber}
                handlePageChange={handlePageChange}
                canCompleteQC={totalSignatures === reviewedSignatures}
                isQcCompleted={shift.status === COMPLETED}
                handleCompleteQC={completeCurrentShift}
              />
            </Route>
            <Route path={`${path}/signature/:signatureId`}>
              <SignatureForm
                isEditable={isEditable}
                signatures={signatures[currentPageNumber]}
                visualReviewResponses={visualReviewResponses}
                handleSignatureChange={handleSignatureChange}
              />
            </Route>
          </Switch>
        </div>
      )}
    </View>
  )
}

export default () => (
  <QueryParamProvider>
    <PetitionPacketSingle />
  </QueryParamProvider>
)
