export const GENDERS = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
}

export const ETHNICITIES = {
  'African-American': 'Black/African American',
  Asian: 'Asian',
  Caucasian: 'White/Caucasian',
  Hispanic: 'Hispanic/Latino',
  'Native American': 'American Indian/Alaska Native',
  Uncoded: 'Other',
  MENA: 'MENA',
}

export const CONTACT_METHODS = {
  Email: 'Email',
  Text: 'Text',
  Call: 'Call',
}

export const PHONE_TYPES = {
  primary_phone_number: 'Primary',
  cell_phone_number: 'Cell',
  home_phone_number: 'Home',
  work_phone_number: 'Work',
  other_phone_number: 'Other',
}

export const PRIMARY_PHONE_CONTACT_TYPE = 'primary_phone_number'
export const OTHER_PHONE_CONTACT_TYPE = 'other_phone_number'

export const EMAIL_TYPES = {
  primary_email_address: 'Primary',
  home_email_address: 'Home',
  work_email_address: 'Work',
}

export const SOCIAL_LINK_TYPES = {
  linkedIn: 'LinkedIn',
  facebook: 'Facebook',
  twitter: 'Twitter',
}

export const INTEREST_LEVEL_TYPES = {
  lowest: '1',
  low: '2',
  medium: '3',
  high: '4',
  highest: '5',
}

const formatAsOptionsArray = types =>
  Object.keys(types).map(key => ({
    value: key,
    label: types[key],
  }))

export const genderOptions = formatAsOptionsArray(GENDERS)

export const ethnicityOptions = formatAsOptionsArray(ETHNICITIES)

export const contactMethodOptions = formatAsOptionsArray(CONTACT_METHODS)

export const phoneTypeOptions = formatAsOptionsArray(PHONE_TYPES)
export const nonPrimaryPhoneOptions = phoneTypeOptions.filter(
  ({ value }) => value !== PRIMARY_PHONE_CONTACT_TYPE
)

export const emailTypeOptions = formatAsOptionsArray(EMAIL_TYPES)

export const interestLevelOptions = formatAsOptionsArray(INTEREST_LEVEL_TYPES)

export const isNonPrimaryPhone = contactMethod => {
  const isPhone = contactMethod.contact_type?.includes('phone')
  const isPrimary = contactMethod.contact_type?.includes('primary')
  return isPhone && !isPrimary
}
