/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-use-before-define */
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import useTwilioDevice from 'hooks/useTwilioDevice'
import useTimer from 'hooks/useTimer'
import { fetchCall } from 'requests/phoneBankSessions'
import { useHistory, useParams } from 'react-router'
import useEvent from 'hooks/useEvent'
import { useCurrent } from 'contexts/index'
import usePowerDialerSocket from '../hooks/usePowerDialerSocket'
import {
  callReducer,
  defaultCallState,
  RESET_REDUCER,
  useCallReducerActions,
} from './callReducer'

export const AGENT_STATUS = {
  ONCALL: 'oncall',
  READY: 'ready',
  AWAY: 'away',
  REVIEW: 'review',
}

const REASON_OPERATOR_ENDED_SESSION = 'operator_ended_session'

export const AgentActionsContext = createContext()
export const AgentStateContext = createContext()

const TEN_MINUTES_IN_SECONDS = 600

export const AgentContextProvider = ({ children }) => {
  const history = useHistory()

  const { phoneBankId, id: sessionId } = useParams()
  const [call, callDispatch] = useReducer(callReducer, defaultCallState)
  const {
    updateParticipation,
    updateNonParticipationReason,
    updateCheckboxResponse,
    updateOpenEndedResponse,
    updateRadioResponse,
    clearResponse,
    resetReducerState,
  } = useCallReducerActions(callDispatch)

  const [callId, setCallId] = useState(null)
  const [participant, setParticipant] = useState()

  const [internalStatus, setInternalStatus] = useState(AGENT_STATUS.AWAY)
  const [dialerError, setDialerError] = useState(false)
  const [averageWaitTime, setAverageWait] = useState(null)
  const [isSessionEnding, setIsSessionEnding] = useState(false)
  const [isOperatorReady, setIsOperatorReady] = useState(false)
  const [isOperatorDisconnected, setIsOperatorDisconnected] = useState(false)
  const [timeToDisconnect, setTimeToDisconnect] = useState(
    TEN_MINUTES_IN_SECONDS
  )

  const {
    currentUser: { id: currentUserId },
  } = useCurrent()

  const {
    setupDevice,
    endCall: twilioEndCall,
    customParams,
  } = useTwilioDevice({
    setupOptions: {},
    onConnect: () => onCallConnect(),
    onError: error => {
      // eslint-disable-next-line no-console
      console.error('dialer error:')
      // eslint-disable-next-line no-console
      console.error(error)
      setDialerError(error)
    },
  })

  const { channel, pushToChannel, channelError, presence } =
    usePowerDialerSocket({
      role: 'agent',
    })

  const { startTimer, resetTimer, secondsElapsed } = useTimer()

  useEffect(() => {
    async function fetchCallData() {
      if (customParams?.get && customParams?.get('callId') !== callId) {
        callDispatch({ action: RESET_REDUCER })
        setCallId(customParams.get('callId'))

        const call = await fetchCall(phoneBankId, customParams.get('callId'))
        setParticipant(call.person)
      }
    }

    fetchCallData()
  }, [customParams])

  useEffect(() => {
    if (presence) {
      presence.onSync(() => {
        const users = presence.list()
        const operatorReady = users.some(user =>
          user.metas.some(
            presence =>
              presence.role === 'operator' && presence.status === 'ready'
          )
        )
        setIsOperatorReady(operatorReady)
        if (operatorReady && isOperatorDisconnected) {
          setIsOperatorDisconnected(false)
        }
      })
    }
  }, [presence, isOperatorDisconnected])

  useEffect(() => {
    if (channel) {
      channel.on('stats:updated', data => {
        if (data && data.average_wait_time) {
          setAverageWait(data.average_wait_time)
        }
      })
      channel.on('operator_disconnected', () => {
        setIsOperatorDisconnected(true)
      })
      channel.on('operator_closed_session', () => {
        setIsSessionEnding(true)
      })
    }
  }, [channel])

  useEffect(() => {
    if (isOperatorDisconnected) {
      const countdown = setInterval(() => {
        setTimeToDisconnect(oldTtd => oldTtd - 1)
      }, 1000)

      return () => clearInterval(countdown)
    }
    if (!isOperatorDisconnected) {
      setTimeToDisconnect(TEN_MINUTES_IN_SECONDS)
    }
  }, [isOperatorDisconnected])

  useEffect(() => {
    if (timeToDisconnect <= 0) {
      endSession()
    }
  }, [timeToDisconnect])

  useEffect(() => {
    if (
      internalStatus === AGENT_STATUS.AWAY ||
      internalStatus === AGENT_STATUS.READY
    ) {
      if (isSessionEnding) {
        endSession(REASON_OPERATOR_ENDED_SESSION)
      }
    }
  }, [isSessionEnding, internalStatus])

  const setStatus = (status, params = {}) => {
    if (!channel) throw new Error('Not connected to power dialer channel')

    pushToChannel('update:status', { ...params, id: currentUserId, status })
    setInternalStatus(status)
  }

  const setStatusReady = async () => {
    await setupDevice()

    setStatus(AGENT_STATUS.READY)
    resetTimer()
    startTimer()
  }

  const setStatusAway = () => {
    setStatus(AGENT_STATUS.AWAY)
  }

  const setStatusReview = () => {
    setStatus(AGENT_STATUS.REVIEW)
  }

  const endCall = () => {
    twilioEndCall()
    setStatusReview()
  }

  const submitResponses = params => {
    pushToChannel('call:completed', {
      call_id: parseInt(callId),
      params,
    })
  }

  const endSession = reason => {
    history.push(
      `/organize/phone_banks/${phoneBankId}/call_sessions/${sessionId}/end${reason ? `?reason=${reason}` : ''}`
    )
  }

  const onCallConnect = useEvent(() => {
    setStatus(AGENT_STATUS.ONCALL, { wait_time: secondsElapsed })
    resetTimer()
    startTimer()
  })

  return (
    <AgentActionsContext.Provider
      value={{
        setStatus,
        setStatusReady,
        setStatusAway,
        endCall,
        endSession,
        updateParticipation,
        updateNonParticipationReason,
        updateCheckboxResponse,
        updateOpenEndedResponse,
        updateRadioResponse,
        clearResponse,
        resetReducerState,
        submitResponses,
      }}
    >
      <AgentStateContext.Provider
        value={{
          secondsElapsed,
          averageWaitTime,
          status: internalStatus,
          dialerError,
          participant,
          call,
          callId,
          channelError,
          isOperatorReady,
          isOperatorDisconnected,
          timeToDisconnect,
        }}
      >
        {children}
      </AgentStateContext.Provider>
    </AgentActionsContext.Provider>
  )
}

export const useAgentActions = () => useContext(AgentActionsContext)
export const useAgentState = () => useContext(AgentStateContext)
