import { useTranslation } from 'react-i18next'
import {
  TextField,
  TextBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import { useCallSessionContext } from '../../CallSessionContext/CallSessionContext'

const ContentActive = ({ content }) => {
  const { t } = useTranslation()

  const {
    currentUser: { name: volunteerName },
  } = useCurrent()

  const { currentParticipant: participant } = useCallSessionContext()
  const participantName = participant?.name

  return (
    <div>
      <TextBlock>
        {t('Hello, may I speak to')}{' '}
        <strong>{participantName || `[${t('participant name')}]`}</strong>?
      </TextBlock>
      <TextBlock>
        {t('Hi, my name is')}{' '}
        <strong>{volunteerName || `[${t('volunteer name')}]`}</strong>{' '}
        {t("and I'm calling about")}{' '}
        <strong>{content || `[${t('add cause statement')}]`}</strong>
      </TextBlock>
    </div>
  )
}

const ContentEdit = ({ contentInState, setContentInState }) => {
  const { t } = useTranslation()

  return (
    <div>
      <TextBlock>
        {t('Hello, may I speak to')}{' '}
        <strong>{`[${t('participant name')}]`}</strong>?
      </TextBlock>
      <TextBlock>
        {t('Hi, my name is')} <strong>{`[${t('volunteer name')}]`}</strong>{' '}
        {t("and I'm calling about")}
      </TextBlock>
      <ContentBlock>
        <TextField
          label={t('Cause statement')}
          id="cause"
          value={contentInState}
          onChange={setContentInState}
        />
      </ContentBlock>
    </div>
  )
}

const ContentIntro = {
  Active: ContentActive,
  Edit: ContentEdit,
}
export default ContentIntro
