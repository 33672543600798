import {
  Font,
  Button,
  Icon,
  TooltipContainer,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { formatPhoneNumber } from 'utils/formatting'
import styles from './PersonDetails.module.scss'

export const PhoneNumberDetail = ({ phone }) => {
  const { t } = useTranslation()

  if (phone.invalid) {
    return (
      <div className={styles['phone-number__container']}>
        <Button.Secondary disabled>
          <Icon.Phone />
          <span className={styles['phone-number__text--invalid']}>
            {formatPhoneNumber(phone.content)}
          </span>
        </Button.Secondary>
        <TooltipContainer
          label={t('marked as invalid number')}
          labelPosition="top"
        >
          <Icon.ExclamationCircle
            className={styles['phone-number__icon--invalid']}
          />
        </TooltipContainer>
      </div>
    )
  }

  return (
    <div className={styles['phone-number__container']}>
      <a href={`tel:${phone.content}`}>
        <Button.Secondary>
          <Icon.Phone />
          <span>{formatPhoneNumber(phone.content)}</span>
        </Button.Secondary>
      </a>
      {phone.extension ? (
        <Font.Copy
          variant="hint"
          Element="span"
          className={styles['phone-number__extension']}
        >
          {`(Ext. ${phone.extension})`}
        </Font.Copy>
      ) : null}
    </div>
  )
}
