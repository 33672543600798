import { NavLink } from 'react-router-dom'
import { UserMenu } from 'components'
import { Button, Icon } from '@politechdev/blocks-design-system'
import logo from 'assets/images/logo.svg'
import LanguageToggle from '../LanguageToggle/LanguageToggle'
import styles from './TopBar.module.scss'
import { useNavigation } from '../NavigationProvider/NavigationProvider'

const TopBar = () => {
  const { toggleNavigation } = useNavigation()

  return (
    <header className={styles.header}>
      <div className={styles.menu}>
        <Button.Secondary
          onClick={toggleNavigation}
          className={styles.menu__button}
        >
          <div className={styles.menu__button__icon__container}>
            <Icon.TableCells className={styles.menu__button__icon} />
          </div>
          <span>Menu</span>
        </Button.Secondary>
      </div>
      <NavLink alt="Dashboard" to="/" exact className={styles.logo}>
        <img src={logo} alt="Blocks" />
      </NavLink>
      <div className={styles['action-items']}>
        <LanguageToggle />
        <UserMenu />
      </div>
    </header>
  )
}

export default TopBar
