export const IMPORTABLE_ATTRS = [
  { label: 'First name', value: 'first_name' },
  { label: 'Last name', value: 'last_name' },
  { label: 'Middle name', value: 'middle_name' },
  { label: 'Prefix', value: 'prefix' },
  { label: 'Suffix', value: 'suffix_name' },
  { label: 'Address: Line one', value: 'residential_address_line_one' },
  { label: 'Address: Line two', value: 'residential_address_line_two' },
  { label: 'City', value: 'residential_address_city' },
  { label: 'County', value: 'residential_address_county' },
  { label: 'State', value: 'residential_address_state' },
  { label: 'Zipcode', value: 'residential_address_zipcode' },
  { label: 'Birthdate', value: 'birth_date' },
  { label: 'Gender', value: 'gender' },
  { label: 'Primary phone number', value: 'primary_phone_number' },
  { label: 'Primary email address', value: 'primary_email_address' },
  { label: 'External ID', value: 'external_id' },
  { label: 'Notes', value: 'notes' },
  { label: 'Receives SMS', value: 'receives_sms' },
  {
    label: 'Alternate phone number',
    value: 'alternate_phone_number',
  },
  {
    label: 'Team names',
    value: 'team_memberships',
  },
  {
    label: 'Event names',
    value: 'event_attendances',
  },
  {
    label: 'Organization names',
    value: 'organization_memberships',
  },
  {
    label: 'Campaign names',
    value: 'campaigns',
  },
  { label: 'Issues', value: 'issues' },
  { label: 'Ignore', value: 'ignore' },
]
