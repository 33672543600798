export const buildRequest = formData => ({
  first_name: formData.first_name,
  last_name: formData.last_name,
  primary_phone_number: formData.primary_phone_number,
  residential_address: {
    line_one: formData.residential_address.line_one,
    city: formData.residential_address.city,
    state: formData.residential_address.state,
    zipcode: formData.residential_address.zipcode,
  },
  organization_memberships: (formData.organizations || []).map(
    organization => ({
      organization_id: organization.id,
    })
  ),
  needs: {
    child_care: formData.child_care,
    transportation: formData.transportation,
    diet: formData.diet,
    language: formData.language,
    other: formData.other,
  },
  primary_email_address: formData.email,
  id: formData.id,
  inviter_id: formData.inviter?.id,
  receives_sms: formData.receives_sms,
})
