import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#FF8042']

const DonutChart = ({ data }) => (
  <ResponsiveContainer labelFormatter>
    <PieChart>
      <Tooltip />
      <Pie
        data={data}
        nameKey="registration_type"
        dataKey="count"
        label
        labelLine={false}
        fill="#8884d8"
        innerRadius={50}
        outerRadius={100}
        onMouseEnter={data.onPieEnter}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index]}
            name={entry.registration_type}
          />
        ))}
      </Pie>
      <Legend />
    </PieChart>
  </ResponsiveContainer>
)

export default DonutChart
