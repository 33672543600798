import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  TextBlock,
  ContentBlock,
  Font,
  TextField,
} from '@politechdev/blocks-design-system'
import { useAuth } from 'contexts'
import { useRequest } from 'hooks'
import { postLogin } from 'requests/auth'
import { isValidEmail } from 'utils/inputValidations'
import styles from './LoginForm.module.scss'

const LoginForm = () => {
  const { t } = useTranslation()

  const { login } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const { makeRequest, isLoading, hasErrors } = useRequest(postLogin, {
    onSuccess: response => {
      login(response.token)
    },
    onError: error => {
      if (error.status === 403) {
        return setErrorMsg(
          t(
            'The email or password was incorrect. Please try again with a valid email and password or reset your password.'
          )
        )
      }
      return setErrorMsg(
        t(
          "We're sorry. An internal error occurred while trying to log you in. Please try again later."
        )
      )
    },
  })

  const isFormValid = () => isValidEmail(email) && !isEmpty(password)

  const submitForm = e => {
    e.preventDefault()
    if (!isFormValid()) return

    makeRequest({ email, password })
  }

  return (
    <div>
      <TextBlock>
        <Font.Display variant="small">
          {t('Sign into your blocks account')}
        </Font.Display>
      </TextBlock>
      <form onSubmit={submitForm}>
        <FieldBlock>
          <TextField
            id="Email"
            label={t('Email')}
            type="email"
            value={email}
            onChange={setEmail}
            disabled={isLoading}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="Password"
            label={t('Password')}
            type="password"
            value={password}
            onChange={setPassword}
            disabled={isLoading}
          />
        </FieldBlock>
        <ButtonBlock justify="left">
          <Button.Accent type="submit">
            {isLoading ? t('Signing in') : t('Sign in')}
          </Button.Accent>
        </ButtonBlock>
        <ContentBlock className={styles.links}>
          <Font.Copy variant="hint" className={styles.link}>
            <Link to="/auth/forgot_password">{t('Forgot password?')}</Link>
          </Font.Copy>
          <Font.Copy variant="hint" className={styles.link}>
            <a
              href={`mailto:${process.env.INTERCOM_APP_ID}@incoming.intercom.io`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Help? Contact support')}
            </a>
          </Font.Copy>
        </ContentBlock>
      </form>
      <CardError hide={!hasErrors} message={errorMsg} hideSupportLink />
    </div>
  )
}

export default LoginForm
