import { createContext, useContext, useState } from 'react'
import { useRequest } from 'hooks'
import { postPerson, fetchPeopleDuplicates } from 'requests/people'
import CreatePartialPersonForm from './CreatePartialPersonForm'
import PotentialDuplicatesWarning from './PotentialDuplicatesWarning'

const PotentialDuplicatesContext = createContext({ potentialDuplicates: [] })

const CreatePartialPerson = ({
  onClose = () => {},
  onCreated = () => {},
  languages,
  children,
}) => {
  const [newPerson, setNewPerson] = useState()

  const {
    makeRequest: postPersonReq,
    isLoading: isPersonReqLoading,
    hasErrors: postPersonError,
  } = useRequest(postPerson, {
    onSuccess: res => {
      onCreated(res.person)
      onClose()
    },
  })

  const createPerson = formData => {
    postPersonReq(formData, {
      fields: ['id', 'name', 'primary_email_address', 'primary_phone_number'],
    })
  }

  const {
    makeRequest: dupeCheckReq,
    isLoading: dupeCheckIsLoading,
    hasErrors: dupeCheckError,
    response: { people: potentialDuplicates } = {},
  } = useRequest(fetchPeopleDuplicates)

  const dupeCheckThenCreatePerson = async formData => {
    setNewPerson(formData)
    const response = await dupeCheckReq({
      fields: ['id', 'name', 'primary_email_address', 'primary_phone_number'],
      attrs: {
        first_name: formData.first_name,
        last_name: formData.last_name,
        primary_phone_number: formData.primary_phone_number,
        primary_email_address: formData.primary_email_address,
      },
    })

    if (response?.people?.length === 0) {
      createPerson(formData)
    }
  }

  const childrenOrDefault = children ?? (
    <>
      <CreatePartialPerson.Body />
      <CreatePartialPerson.Actions />
    </>
  )

  return (
    <PotentialDuplicatesContext.Provider
      value={{
        dupeCheckReq,
        potentialDuplicates,
        ignoreWarningAndCreatePerson: () => createPerson(newPerson),
        onClose,
      }}
    >
      {potentialDuplicates?.length > 0 ? (
        childrenOrDefault
      ) : (
        <CreatePartialPersonForm
          languages={languages}
          isLoading={isPersonReqLoading || dupeCheckIsLoading}
          hasErrors={postPersonError || dupeCheckError}
          onSubmit={dupeCheckThenCreatePerson}
          onCancel={onClose}
        >
          {childrenOrDefault}
        </CreatePartialPersonForm>
      )}
    </PotentialDuplicatesContext.Provider>
  )
}

CreatePartialPerson.Body = () => {
  const { potentialDuplicates } = useContext(PotentialDuplicatesContext)

  return potentialDuplicates?.length > 0 ? (
    <PotentialDuplicatesWarning potentialDuplicates={potentialDuplicates} />
  ) : (
    <CreatePartialPersonForm.Fields />
  )
}

CreatePartialPerson.Actions = () => {
  const { potentialDuplicates, ignoreWarningAndCreatePerson, onClose } =
    useContext(PotentialDuplicatesContext)

  return potentialDuplicates?.length > 0 ? (
    <PotentialDuplicatesWarning.Actions
      onConfirm={ignoreWarningAndCreatePerson}
      onCancel={onClose}
    />
  ) : (
    <CreatePartialPersonForm.Actions />
  )
}

export default CreatePartialPerson
