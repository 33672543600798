import React from 'react'
import { Switch, Route } from 'react-router-dom'
import TurfSelect from '../TurfSelect/TurfSelect'
import TurfCreate from '../TurfCreate/TurfCreate'
import TurfEdit from '../TurfEdit/TurfEdit'

const TurfRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={TurfSelect} />
    <Route exact path={`${match.path}/new`} component={TurfCreate} />
    <Route exact path={`${match.path}/:id/edit`} component={TurfEdit} />
  </Switch>
)

export default TurfRoutes
