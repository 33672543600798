import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import {
  MultiSelectField,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchUsers } from 'requests/users'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts/index'
import { buildParams, buildOption, buildUser } from './utils'

const UserSelectField = ({
  label,
  onSelect,
  user = {},
  users = [],
  isMulti = false,
  filters,
  ...props
}) => {
  const { t } = useTranslation()

  const [options, setOptions] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState(user?.id)
  const [multiValue, setMultiValue] = useState(users.map(user => user.id))
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)
  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'user',
    ability: 'view',
  })

  const { makeRequest, isLoading } = useRequest(fetchUsers, {
    onSuccess: ({ users: incomingUsers }) => {
      setOptions(incomingUsers.map(buildOption))
    },
    onError: () => {
      setErrorMsg('failed to fetch users')
    },
  })

  const getUsers = () => {
    const params = buildParams({ query: debounced, filters })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getUsers()
  }, [debounced])

  useEffect(() => {
    setValue(user?.id)
  }, [user])

  useEffect(() => {
    setMultiValue(users.map(user => user.id))
  }, [JSON.stringify(users)])

  const singleOptions = buildSingleOptions(options, user, buildOption)

  const multiOptions = buildMultiOptions(options, users, buildOption)

  const handleChange = selection => {
    setValue(selection)
    const selectedOption = singleOptions.find(
      ({ value }) => value === selection
    )
    onSelect && onSelect(selectedOption ? buildUser(selectedOption) : undefined)
  }

  const handleMultiChange = selections => {
    onSelect &&
      onSelect(
        multiOptions
          .filter(({ value }) => selections.includes(value))
          .map(buildUser)
      )
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSelectField
          label={label || t('User')}
          options={multiOptions}
          onSelect={handleMultiChange}
          onInputChange={setQuery}
          values={multiValue}
          loading={isLoading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      ) : (
        <SelectField
          label={label || t('User')}
          options={singleOptions}
          onSelect={handleChange}
          onInputChange={setQuery}
          value={value || ''}
          loading={isLoading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      )}
    </>
  )
}

UserSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  user: PropTypes.object,
}

UserSelectField.defaultProps = {
  id: 'user',
  filters: [],
  user: null,
}

export default UserSelectField
