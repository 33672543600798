export const tableToApiParams = params => ({
  current_page: params.page,
  per: params.pageSize,
  sort_attr: params.sortColumn,
  sort_dir: params.sortOrder,
  filters: {
    conjunction: params.filterConjunction,
    rules: params.filterRules,
  },
})
