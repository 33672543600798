import petitionsByTurf from './petitionsByTurf'
import petitionsByCanvasser from './petitionsByCanvasser'
import petitionsByPacket from './petitionsByPacket'

export const reportCategories = [
  {
    id: 'general',
    name: 'General',
    reportTypes: [
      'petitions_by_turf',
      'petitions_by_canvasser',
      'petitions_by_packet',
    ],
    permissionId: 'petition_general',
  },
]
export const permissionIds = ['petition_general']

export const reportTypes = {
  ...petitionsByTurf,
  ...petitionsByCanvasser,
  ...petitionsByPacket,
}

export const defaultReportType = 'petitions_by_turf'
