import { produce } from 'immer'
import { useMemo } from 'react'

export const RECEIVE_PARTICIPATION = 'power_dialer/RECEIVE_PARTICIPATION'
export const RECEIVE_NON_PARTICIPATION_REASON =
  'power_dialer/RECEIVE_NON_PARTICIPATION_REASON'
export const RECEIVE_RADIO_RESPONSE = 'power_dialer/RECEIVE_RADIO_RESPONSE'
export const RECEIVE_CHECKBOX_RESPONSE =
  'power_dialer/RECEIVE_CHECKBOX_RESPONSE'
export const RECEIVE_OPEN_ENDED_RESPONSE =
  'power_dialer/RECEIVE_OPEN_ENDED_RESPONSE'
export const CLEAR_RESPONSE = 'power_dialer/CLEAR_RESPONSE'
export const RESET_REDUCER = 'power_dialer/RESET_REDUCER'

export const defaultCallState = {
  participated: null,
  non_participation_reason: null,
  responses: [],
}

export const callReducer = (appState, action) => {
  const state = { ...defaultCallState, ...appState }

  return produce(state, draft => {
    const { type } = action
    switch (type) {
      case RECEIVE_PARTICIPATION: {
        draft.participated = action.participated
        draft.non_participation_reason =
          action.participated === 't' ? null : state.non_participation_reason
        return draft
      }
      case RECEIVE_NON_PARTICIPATION_REASON: {
        draft.non_participation_reason = action.reason
        return draft
      }
      case RECEIVE_RADIO_RESPONSE: {
        const questionFound = state.responses.find(
          r => r.question_id === action.questionId
        )
        if (questionFound) {
          draft.responses = state.responses.map(r =>
            r.question_id === action.questionId
              ? {
                  question_id: action.questionId,
                  answer_option_id: action.response,
                }
              : r
          )
        } else {
          draft.responses.push({
            question_id: action.questionId,
            answer_option_id: action.response,
          })
        }
        return draft
      }
      case RECEIVE_CHECKBOX_RESPONSE: {
        const oldResp = state.responses.filter(
          resp => resp.question_id !== action.questionId
        )
        const newResp = action.response
          .filter(checkbox => checkbox.checked)
          .map(checkbox => ({
            question_id: action.questionId,
            answer_option_id: checkbox.value,
          }))
        draft.responses = oldResp.concat(newResp)
        return draft
      }
      case RECEIVE_OPEN_ENDED_RESPONSE: {
        draft.responses = [
          ...state.responses.filter(
            resp => resp.question_id !== action.questionId
          ),
          {
            question_id: action.questionId,
            open_ended_answer_text: action.response,
          },
        ]
        return draft
      }
      case CLEAR_RESPONSE: {
        draft.responses = state.responses.filter(
          question => question.question_id === action.questionId
        )
        return draft
      }
      case RESET_REDUCER: {
        return defaultCallState
      }
      default: {
        return state
      }
    }
  })
}

export const useCallReducerActions = dispatch =>
  useMemo(
    () => ({
      updateParticipation: participated =>
        dispatch({ type: RECEIVE_PARTICIPATION, participated }),
      updateNonParticipationReason: reason =>
        dispatch({
          type: RECEIVE_NON_PARTICIPATION_REASON,
          reason,
        }),
      updateCheckboxResponse: params =>
        dispatch({ type: RECEIVE_CHECKBOX_RESPONSE, ...params }),
      updateOpenEndedResponse: params =>
        dispatch({ type: RECEIVE_OPEN_ENDED_RESPONSE, ...params }),
      updateRadioResponse: params =>
        dispatch({ type: RECEIVE_RADIO_RESPONSE, ...params }),
      clearResponse: questionId =>
        dispatch({ type: CLEAR_RESPONSE, questionId }),
      resetReducerState: () => dispatch({ type: RESET_REDUCER }),
    }),
    [dispatch]
  )
