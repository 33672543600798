import {
  usePledgeCardGalleryState,
  usePledgeCardGalleryActions,
} from '../../PledgeCardGalleryContext/PledgeCardGalleryContext'

const useCompareForm = () => {
  const { packetScans, firstScan } = usePledgeCardGalleryState()
  const { setFirstScan, setQueryParams } = usePledgeCardGalleryActions()
  const currScanIndex = packetScans.findIndex(scan => scan.id === firstScan.id)
  const totalScans = packetScans.length
  const incrementIndex = (totalScans + currScanIndex + 1) % totalScans
  const decrementIndex = (totalScans + currScanIndex - 1) % totalScans
  const incrementScan = () => {
    const newFirstScan = packetScans[incrementIndex]
    setFirstScan(newFirstScan)
    setQueryParams({
      firstScanId: newFirstScan.id,
    })
  }
  const decrementScan = () => {
    const newFirstScan = packetScans[decrementIndex]
    setFirstScan(newFirstScan)
    setQueryParams({
      firstScanId: newFirstScan.id,
    })
  }

  return { incrementScan, decrementScan }
}

export default useCompareForm
