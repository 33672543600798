import { useRef, useState } from 'react'
import classNames from 'classnames/bind'
import { Dropdown } from 'components'
import { Globe as GlobeIcon, Check as CheckIcon } from 'components/uiKit/icons'
import { useCurrent, useLocale } from 'contexts'
import styles from './LanguageToggle.module.scss'

const cx = classNames.bind(styles)

const LanguageToggle = () => {
  const dropdownRef = useRef()
  const triggerRef = useRef()
  const [isOpen, setOpen] = useState(false)
  const [locale, setLocale] = useLocale()

  const {
    tenantOptions: { bilingual: isBilingual },
  } = useCurrent()

  if (!isBilingual) return null

  return (
    <div className={styles.container}>
      <button
        type="button"
        aria-haspopup="true"
        aria-expanded={isOpen}
        ref={triggerRef}
        className={styles.button}
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        <GlobeIcon />
        <span>{locale}</span>
      </button>
      <Dropdown
        ref={dropdownRef}
        isOpen={isOpen}
        onChange={setOpen}
        triggerRef={triggerRef}
        className={styles.dropdown}
      >
        <div className={styles.options}>
          <button
            type="button"
            className={cx('option', {
              'option--active': locale === 'en',
            })}
            onClick={() => {
              setLocale('en')
            }}
          >
            <span>Switch to English</span>
            <CheckIcon />
          </button>
          <button
            type="button"
            className={cx('option', {
              'option--active': locale === 'es',
            })}
            onClick={() => {
              setLocale('es')
            }}
          >
            <span>Cambiar a Español</span>
            <CheckIcon />
          </button>
        </div>
      </Dropdown>
    </div>
  )
}

export default LanguageToggle
