import { createContext, useState } from 'react'

export const SendContext = createContext()

export const SendProvider = ({ children }) => {
  const [contacts, setContacts] = useState([])
  const [contactMeta, setContactMeta] = useState({})
  const [template, setTemplate] = useState(null)
  const [event, setEvent] = useState(null)
  const [team, setTeam] = useState(null)
  const [list, setList] = useState(null)
  const [subject, setSubject] = useState('')
  const [from, setFrom] = useState('')

  return (
    <SendContext.Provider
      value={{
        contacts,
        setContacts,
        contactMeta,
        setContactMeta,
        template,
        setTemplate,
        subject,
        setSubject,
        from,
        setFrom,
        event,
        setEvent,
        list,
        setList,
        team,
        setTeam,
      }}
    >
      {children}
    </SendContext.Provider>
  )
}
