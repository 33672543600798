import { fetchJSON, stringifyParams } from 'utils/req'

export const postCheckinAnswer = (questionId, answer) =>
  fetchJSON(
    '/api/v1/current/check_in/answers',
    'POST',
    {
      check_in_answer: {
        question_id: questionId,
        text: answer,
      },
    },
    {
      useJwt: true,
    }
  )

export const putCheckinAnswer = (questionId, answer, answerId) =>
  fetchJSON(
    `/api/v1/current/check_in/answers/${answerId}`,
    'PUT',
    {
      check_in_answer: {
        question_id: questionId,
        text: answer,
      },
    },
    {
      useJwt: true,
    }
  )

export const deleteCheckinAnswers = questionId =>
  fetchJSON(`/api/v1/check_in_questions/${questionId}`, 'DELETE', null, {
    useJwt: true,
  })

export const getCheckin = (id, params) =>
  fetchJSON(
    `/api/v1/turfs/${id}/check_in?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const getCurrentUserCheckin = params =>
  fetchJSON(
    `/api/v1/current/check_in?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const getCurrentUserCheckinAnswers = params =>
  fetchJSON(
    `/api/v1/current/check_in/answers/today?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const getCheckinAnswers = params =>
  fetchJSON(
    `/api/v1/check_in_answers?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const postCheckin = (checkin, params) =>
  fetchJSON(
    `/api/v1/check_ins?${stringifyParams(params)}`,
    'POST',
    { check_in: checkin },
    {
      useJwt: true,
    }
  )

export const putCheckin = (id, checkin) =>
  fetchJSON(
    `/api/v1/check_ins/${id}`,
    'PUT',
    { check_in: checkin },
    {
      useJwt: true,
    }
  )

export const deleteCheckin = id =>
  fetchJSON(`/api/v1/check_ins/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const postCheckinQuestion = (checkinId, text, position) =>
  fetchJSON(
    `/api/v1/check_in_questions`,
    'POST',
    {
      check_in_question: {
        check_in_id: checkinId,
        text,
        position,
      },
    },
    {
      useJwt: true,
    }
  )

export const putCheckinQuestion = (questionId, check_in_question) =>
  fetchJSON(
    `/api/v1/check_in_questions/${questionId}`,
    'PUT',
    {
      check_in_question,
    },
    {
      useJwt: true,
    }
  )
