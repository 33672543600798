import { CardError } from 'components'
import {
  Button,
  ContentBlock,
  ButtonBlock,
  ProgressBar,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useReactRouter } from 'hooks'
import RecordContextProvider, {
  RecordContext,
} from '../RecordContext/RecordContext'
import RecordError from '../RecordError/RecordError'
import RecordPager from '../RecordPager/RecordPager'
import styles from './Record.module.scss'
import RecordDuplicates from '../RecordDuplicates/RecordDuplicates'

const Record = () => {
  const { t } = useTranslation()
  const {
    importRes,
    errorRecord,
    duplicateRes,
    duplicateReqLoading,
    importReqLoading,
    initialImportRecordReqLoading,
    importReqError,
    initialImportRecordReqError,
    readOnly,
  } = useContext(RecordContext)
  const count = importRes?.meta?.total_count
  const { match } = useReactRouter()
  return (
    <>
      <PageHeader
        title={
          count !== undefined
            ? `${count} ${t(
                `import validation error${count === 1 ? '' : 's'} to resolve`
              )}`
            : '...'
        }
        className={styles.header}
      >
        <ButtonBlock justify="right">
          <RecordPager />
          <Link
            to={`/data_sources/imports/${match.params.importId}/duplicate_prevention/`}
          >
            <Button.Secondary>{t('Review in bulk')}</Button.Secondary>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ProgressBar show={importReqLoading || initialImportRecordReqLoading} />
      {(importReqError || initialImportRecordReqError) && (
        <ContentBlock>
          <CardError />
        </ContentBlock>
      )}
      {errorRecord && (
        <div className={styles.container}>
          <div className={styles.container__main}>
            <RecordError />
          </div>
          {duplicateRes && !duplicateReqLoading && !readOnly && (
            <div className={styles.container__sidepanel}>
              <RecordDuplicates />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default () => (
  <RecordContextProvider>
    <Record />
  </RecordContextProvider>
)
