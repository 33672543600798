import { omitBy, isNil } from 'lodash'

export const formatPercentage = percentage => `${Math.round(percentage * 100)}%`

export const formatRelativeTime = num => {
  const numInSec = num / 1000
  const hours = Math.floor(numInSec / 3600)
  const minutes = Math.floor((numInSec - 3600 * hours) / 60)
  const seconds = Math.floor(numInSec - (3600 * hours + 60 * minutes))
  const paddedSeconds = `${String(seconds).padStart(2, '0')}`
  const paddedMinutes = `${String(minutes).padStart(2, '0')}`
  const paddedHours = `${String(hours).padStart(2, '0')}`
  if (hours === 0) {
    return `${paddedMinutes}:${paddedSeconds}`
  }
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`
}

export const formatWaitTime = numInSec => {
  if (numInSec == null) return 'Calculating'

  const hours = Math.floor(numInSec / 3600)
  const minutes = Math.floor((numInSec - 3600 * hours) / 60)
  const seconds = Math.floor(numInSec - (3600 * hours + 60 * minutes))

  let combinedMinutes = seconds >= 30 ? minutes + 1 : minutes

  combinedMinutes += hours * 60

  if (hours === 0 && minutes < 1) {
    return 'Less than 1 minute'
  }

  return `${combinedMinutes} Minutes`
}

export const formatLocalTime = date =>
  date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })

export const buildComponentData = apiValues => {
  const result = {
    ongoingCalls: apiValues.active_calls,
    callsRinging: apiValues.pending_calls,
    completedCalls: apiValues.completed_calls,
    agentsOnCall: apiValues.agents_on_call,
    agentsAvailable: apiValues.agents_available,
    agentsIdle: apiValues.agents_idle,
    averageWaitTime: apiValues.average_wait_time,
    remainingContacts: apiValues.remaining_contacts,
    participantsReached: apiValues.participants_reached,
    pickupPercent: apiValues.pickup_percent,
    abandonRate: apiValues.abandon_rate,
  }

  return omitBy(result, isNil)
}

export const abandonRateExceeded = abandonRate => abandonRate > 0.2
