import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { useLocationMap } from '../LocationMapProvider/LocationMapProvider'
import { VIEW_TYPES } from '../constants'
import styles from './LocationMapSearchButton.module.scss'

const cx = classNames.bind(styles)

const LocationMapSearchButton = () => {
  const { t } = useTranslation()
  const { updateBounds, hasMoved, setViewType } = useLocationMap()

  return (
    <div className={styles.container}>
      <Button.Secondary
        className={cx('button', {
          'button--active': hasMoved,
        })}
        onClick={() => {
          if (!hasMoved) return
          setViewType(VIEW_TYPES.BOUNDS)
          updateBounds()
        }}
      >
        <Icon.Search />
        <span>{t('Search this area')}</span>
      </Button.Secondary>
    </div>
  )
}

export default LocationMapSearchButton
