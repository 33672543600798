import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useEvent, useReactRouter, useRequest } from 'hooks'
import {
  destroyCanvasser,
  fetchCanvassers,
  putCanvasser,
} from 'requests/canvassers'
import { tableConfig } from './tableConfig'

const CanvasserTable = ({ archived = false, isActive }) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()

  const { renderTable, refreshPage } = useAutoTable()

  const { doesCurrentUserHavePermission } = useCurrent()

  const [canvassers, setCanvassers] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const canModifyCanvasser = doesCurrentUserHavePermission({
    resource: 'canvasser',
    ability: 'modify',
  })

  const canDeleteCanvasser = doesCurrentUserHavePermission({
    resource: 'canvasser',
    ability: 'remove',
  })

  const canArchiveBeToggled = rowData =>
    canModifyCanvasser && !rowData.turf.archived

  const { makeRequest, isLoading, hasErrors } = useRequest(fetchCanvassers, {
    onSuccess: ({ canvassers: incomingCanvassers, meta: { total_count } }) => {
      setCanvassers(incomingCanvassers)
      setTotalRecords(total_count)
    },
  })

  const fetchAction = async tableProps =>
    makeRequest({
      current_page: tableProps.page,
      per: tableProps.pageSize,
      sort_attr: tableProps.sortColumn || 'id',
      sort_dir: tableProps.sortOrder || 'asc',
      filters: {
        rules: [
          ...(tableProps.filterRules ?? []),
          {
            column: 'archived',
            operator: 'is',
            param: archived,
          },
        ],
        conjunction: tableProps.filterConjunction,
      },
      fields: [
        'id',
        'first_name',
        'last_name',
        'full_name',
        'phone_number',
        'notes',
        'email',
        { turf: ['id', 'name', 'archived'] },
      ],
    })

  useEffect(() => {
    isActive && refreshPage()
  }, [isActive])

  const {
    makeRequest: deleteRequest,
    isLoading: deleteIsLoading,
    hasErrors: deleteHasErrors,
  } = useRequest(destroyCanvasser, {
    onSuccess: refreshPage,
  })

  const {
    makeRequest: updateRequest,
    isLoading: updateIsLoading,
    hasErrors: updateHasErrors,
  } = useRequest(putCanvasser, {
    onSuccess: refreshPage,
  })

  const handleEdit = useEvent(rowData => {
    history.push(`${match.url}/${rowData.id}/edit`)
  })

  const handleDelete = async rowData => {
    deleteRequest(rowData.id)
  }

  const handleUnarchive = async rowData => {
    updateRequest(rowData.id, { archived: false })
  }
  const handleArchive = async rowData => {
    updateRequest(rowData.id, { archived: true })
  }

  const columns = [
    {
      actions: [
        createRowAction(t('Edit'), handleEdit, canModifyCanvasser),
        createRowAction(t('Delete'), handleDelete, canDeleteCanvasser),
        archived &&
          createRowAction(t('Unarchive'), handleUnarchive, canArchiveBeToggled),
        !archived &&
          createRowAction(t('Archive'), handleArchive, canArchiveBeToggled),
      ].filter(Boolean),
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return renderTable({
    data: canvassers,
    loading: isLoading || deleteIsLoading || updateIsLoading,
    error: hasErrors || deleteHasErrors || updateHasErrors,
    totalRecords,
    columns,
    fetchAction,
  })
}

export default ({ archived, isActive }) => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
    defaultFilters={tableConfig.defaultFilters}
  >
    <CanvasserTable archived={archived} isActive={isActive} />
  </AutoTableProvider>
)
