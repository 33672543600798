import { useContext, useEffect, useMemo, useState } from 'react'
import { useBackgroundRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import WidgetPanel from 'dashboard/WidgetPanel/WidgetPanel'
import { DashboardFilterContext } from 'dashboard/DashboardFilterContext/DashboardFilterContext'
import { requestChartId, requestChart } from '../../../requests/charts'
import DonutChart from '../DonutChart'
import styles from './VoterRegistrationsByType.module.scss'

const VoterRegistrationsByType = () => {
  const { t } = useTranslation()

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const {
    startDate,
    endDate,
    turfId: turfIdFilter,
  } = useContext(DashboardFilterContext)

  const filters = useMemo(
    () => ({ startDate, endDate, turfId: turfIdFilter }),
    [startDate, endDate, turfIdFilter]
  )

  const [data, setData] = useState([])

  const { makeRequest, isLoading, errorMsg, response } = useBackgroundRequest({
    key: 'voter_registrations_by_type',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters,
      }),
    requestData: requestChart,
  })

  useEffect(() => {
    makeRequest()
  }, [filters])

  useEffect(() => {
    if (response) {
      setData(response.rows)
    }
  }, [response])

  const long_data =
    data && data.length === 1
      ? [
          { registration_type: 'Completed', count: data[0].completed_count },
          { registration_type: 'Incomplete', count: data[0].incomplete_count },
        ]
      : []

  const isEmpty =
    data && data.length === 1
      ? data[0].completed_count === 0 && data[0].incomplete_count === 0
      : true

  return (
    <WidgetPanel
      title={t('Voter registrations by type')}
      isLoading={isLoading}
      errorMsg={errorMsg}
      isEmpty={isEmpty}
    >
      <div className={styles.content}>
        <DonutChart data={long_data} />
      </div>
    </WidgetPanel>
  )
}

export default VoterRegistrationsByType
