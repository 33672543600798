import { ALL_ORGANIZATION_TYPES } from 'constants/organizations'
import {
  OrganizationNameCell,
  OrganizationAddressCell,
  OrganizationPhoneNumberCell,
  OrganizationLeaderNameCell,
} from './OrganizationTableCells'
import {
  organizationAddressCsvCell,
  organizationPhoneNumberCsvCell,
  organizationLeaderNameCsvCell,
} from './organizationCsvCells'

export default {
  label: 'organizations',
  columns: [
    {
      dataKey: 'name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: OrganizationNameCell,
    },
    {
      dataKey: 'address_full_address',
      title: 'Address',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: OrganizationAddressCell,
      csvCell: organizationAddressCsvCell,
    },
    {
      dataKey: 'phone_number',
      title: 'Phone number',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: OrganizationPhoneNumberCell,
      csvCell: organizationPhoneNumberCsvCell,
    },
    {
      dataKey: 'leader_full_name',
      title: 'Leader',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: false,
      bodyCell: OrganizationLeaderNameCell,
      csvCell: organizationLeaderNameCsvCell,
    },
    {
      dataKey: 'members_count',
      title: 'Member count',
      type: 'number',
      autoResize: true,
      resizable: true,
    },
    {
      dataKey: 'organization_type',
      title: 'Organization type',
      type: 'enum',
      options: ALL_ORGANIZATION_TYPES,
      autoResize: true,
      resizable: true,
    },
    {
      dataKey: 'address_congressional_district',
      title: 'Congressional District',
      type: 'string',
      autoResize: true,
      resizable: true,
      alphaNumericSort: true,
      deriveFunction: ({ rowData }) =>
        rowData.address?.district_config?.congressional,
    },
    {
      dataKey: 'address_state_legislative_upper_district',
      title: 'State legislative upper district',
      type: 'string',
      autoResize: true,
      resizable: true,
      alphaNumericSort: true,
      deriveFunction: ({ rowData }) =>
        rowData.address?.district_config?.state_legislative_upper,
    },
    {
      dataKey: 'address_state_legislative_lower_district',
      title: 'State legislative lower district',
      type: 'string',
      autoResize: true,
      resizable: true,
      alphaNumericSort: true,
      deriveFunction: ({ rowData }) =>
        rowData.address?.district_config?.state_legislative_lower,
    },
  ],
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: {
          hiddenColumns: [
            'address_congressional_district',
            'address_state_legislative_upper_district',
            'address_state_legislative_lower_district',
          ],
        },
      },
    ]
  },
}
