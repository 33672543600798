import { createContext, useState } from 'react'

export const OtherGoalsListContext = createContext()

const OtherGoalsListContextProvider = ({ children }) => {
  const [otherGoals, setOtherGoals] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [activePage, setActivePage] = useState(1)

  return (
    <OtherGoalsListContext.Provider
      value={{
        otherGoals,
        setOtherGoals,
        totalAmount,
        setTotalAmount,
        activePage,
        setActivePage,
      }}
    >
      {children}
    </OtherGoalsListContext.Provider>
  )
}

export default OtherGoalsListContextProvider
