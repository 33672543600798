import {
  SelectField,
  FieldBlock,
  Icon,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import styles from './ReportPicker.module.scss'

const ReportPicker = ({
  categoryId,
  reportType,
  reportTypes,
  reportCategories,
  changeReportType,
  changeReportCategory,
}) => {
  const { t } = useTranslation()
  const { doesCurrentUserHavePermission } = useCurrent()

  return (
    <Sheet>
      <FieldBlock>
        <SelectField
          id="report-category"
          label={t('Report')}
          className={styles.select}
          options={reportCategories
            .filter(category =>
              doesCurrentUserHavePermission({
                resource: category.permissionId,
                ability: 'view',
              })
            )
            .map(category => ({
              value: category.id,
              label: t(category.name),
            }))}
          deleteKeys={['columns', 'reportTypes', 'defaultType']}
          value={categoryId}
          onSelect={changeReportCategory}
        />
        {reportType ? (
          <>
            <Icon.ChevronRight key="chevron" className={styles.chevron} />
            <SelectField
              className={styles.select}
              key="report-type"
              id="report-type"
              label={t('Narrow by')}
              options={reportCategories
                .find(reportCategory => reportCategory.id === categoryId)
                .reportTypes.map(id => {
                  const reportType = reportTypes.find(
                    reportType => reportType.id === id
                  )
                  return {
                    value: reportType.id,
                    label: t(reportType.name),
                    disabled: reportType.isSelectable === false,
                  }
                })}
              value={reportType}
              onSelect={changeReportType}
            />
          </>
        ) : null}
      </FieldBlock>
    </Sheet>
  )
}

export default ReportPicker
