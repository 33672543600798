import { fetchJSON, stringifyParams } from 'utils/req'

export const buildOption = organization => ({
  value: organization.id,
  label: organization.name,
})

export const buildOrganization = option => ({
  id: option.value,
  name: option.label,
})

export const fetchPublicEventOrgs = (eventId, params) =>
  fetchJSON(
    `/api/v1/public_events/${eventId}/organizations?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: false,
    }
  )
