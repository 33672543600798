import countsByType from './countsByType'
import eventByAttendance from './eventByAttendance'
import userActivity from './userActivity'
import countsByOrganizer from './countsByOrganizer'

export const reportCategories = {
  counts_by_turf: {
    id: 'counts_by_turf',
    name: 'Counts by turf',
    reportTypes: ['counts_by_turf'],
    defaultType: 'counts_by_turf',
  },
  event_attendance: {
    id: 'event_attendance',
    name: 'Event attendance',
    reportTypes: ['event_attendance'],
    defaultType: 'event_attendance',
  },
  user_activity: {
    id: 'user_activity',
    name: 'User Activity',
    reportTypes: ['user_activity'],
    defaultType: 'user_activity',
  },
  counts_by_organizer: {
    id: 'counts_by_organizer',
    name: 'Counts by organizer',
    reportTypes: ['counts_by_organizer'],
    defaultType: 'counts_by_organizer',
  },
}

export const reportTypes = {
  ...countsByType,
  ...eventByAttendance,
  ...userActivity,
  ...countsByOrganizer,
}

export const defaultReportType = 'counts_by_turf'
