import { createContext, useState } from 'react'

export const RecordsContext = createContext()

const RecordsContextProvider = ({ children }) => {
  const [count, setCount] = useState(0)
  return (
    <RecordsContext.Provider
      value={{
        count,
        setCount,
      }}
    >
      {children}
    </RecordsContext.Provider>
  )
}

export default RecordsContextProvider
