import { useState, useEffect, useMemo, useContext } from 'react'
import { TurfSelectField } from 'components'
import { SelectField, FieldBlock } from '@politechdev/blocks-design-system'
import moment from 'moment'
import { useCurrent, useQueryParams, useTurfs } from 'contexts'
import { WeeklyReportContext } from './WeeklyReportContext'

const WEEK_OPTIONS = new Array(8).fill().map((_, i) => ({
  label: `Week of ${moment()
    .startOf('isoWeek')
    .subtract(i, 'weeks')
    .format('MMM D, YYYY')}`,
  value: moment().startOf('isoWeek').subtract(i, 'weeks').format('YYYYMMDD'),
}))

const FilterControls = () => {
  const { queryParams, setQueryParams } = useQueryParams()
  const { setFilters } = useContext(WeeklyReportContext)
  const isQueryWeekValid = useMemo(() => {
    if (
      !queryParams.week ||
      !moment(queryParams.week, 'YYYYMMDD', true).isValid()
    ) {
      return false
    }
    return WEEK_OPTIONS.find(option => option.value === queryParams.week)
  }, [queryParams.week])

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
    },
  } = useCurrent()

  const { refreshCurrentTurfs } = useTurfs()

  const [turfId, setTurfId] = useState(+queryParams.turfId || topLevelTurfId)
  const [week, setWeek] = useState(() =>
    isQueryWeekValid ? queryParams.week : WEEK_OPTIONS[0].value
  )

  useEffect(() => {
    const defaultParams = {}
    if (!isQueryWeekValid) {
      defaultParams.week = WEEK_OPTIONS[0].value
    }
    if (!+queryParams.turfId) {
      defaultParams.turfId = topLevelTurfId
    }
    setQueryParams(defaultParams, 'replace')

    refreshCurrentTurfs()
  }, [])

  const changeTurf = turfId => {
    setTurfId(turfId)
    setQueryParams({ turfId })
  }

  const changeWeek = week => {
    setWeek(week)
    setQueryParams({ week })
  }

  useEffect(() => {
    if (turfId && week) {
      setFilters({
        turf_id: turfId,
        start_date: week,
        end_date: moment(week, 'YYYYMMDD').add(6, 'days').format('YYYYMMDD'),
      })
    }
  }, [turfId, week])

  return (
    <div>
      <FieldBlock>
        <TurfSelectField
          value={turfId}
          onSelect={changeTurf}
          isExpanded
          showArchived
        />
      </FieldBlock>
      <FieldBlock>
        <SelectField
          label="Week"
          value={week}
          onSelect={changeWeek}
          options={WEEK_OPTIONS}
        />
      </FieldBlock>
    </div>
  )
}

export default FilterControls
