import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { dateFormat } from 'utils/constants'

export const CampaignNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/admin/campaigns/${rowData.id}/edit`}>{rowData.name}</Link>
  </TableGridCell>
)

export const CampaignDateCell = ({ cellData }) => (
  <TableGridCell>{moment(cellData).format(dateFormat)}</TableGridCell>
)
