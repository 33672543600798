import { useRef, memo } from 'react'
import { useDrop } from 'react-dnd'
import isEqual from 'react-fast-compare'
import classNames from 'classnames/bind'
import { useScroll } from 'contexts'
import SidebarTreeFolder from '../SidebarTreeFolder/SidebarTreeFolder'
import { INTERACTABLE_TYPES } from '../constants'
import styles from './SidebarTree.module.scss'

const cx = classNames.bind(styles)

const SidebarTree = memo(({ folder = null, childIds = [] }) => {
  const treeRef = useRef(null)
  const { startScroll, endScroll } = useScroll()

  const folderId = folder?.id || null

  const [, dropRef] = useDrop({
    accept: [
      INTERACTABLE_TYPES.SIDEBAR_FOLDER,
      INTERACTABLE_TYPES.PANEL_FOLDER,
      INTERACTABLE_TYPES.PANEL_DOCUMENT,
      INTERACTABLE_TYPES.SEARCH_FOLDER,
      INTERACTABLE_TYPES.SEARCH_DOCUMENT,
    ],
    hover: (item, monitor) => {
      if (folderId) return

      const { y: yPos } = monitor.getClientOffset()

      if (yPos < 120) {
        startScroll('up')
      } else if (window.innerHeight - yPos < 180) {
        startScroll('down')
      } else {
        endScroll()
      }
    },
    canDrop: () => false,
  })

  return (
    <div
      ref={dropRef(treeRef)}
      className={cx('tree', {
        'tree--root': !folderId,
      })}
    >
      <div className={styles.line} />
      {childIds.map(childId => (
        <SidebarTreeFolder
          key={childId}
          folderId={childId}
          parentId={folderId}
        />
      ))}
    </div>
  )
}, isEqual)

export default SidebarTree
