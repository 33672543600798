import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, LoadBar } from 'components'
import { useRequest } from 'hooks/useRequest'
import { putScript } from 'requests/phoneBanks'
import styles from '../ScriptItem.module.scss'
import ContentIntro from './ContentIntro'
import ActionsIntro from './ActionsIntro'
import { iconIndex } from '../utils'

const ScriptIntro = ({ content, updateContent, loading, scriptId }) => {
  const { t } = useTranslation()

  const [isEditingItem, setIsEditingItem] = useState(false)
  const [contentInState, setContentInState] = useState(content || '')

  useEffect(() => {
    setContentInState(content || '')
  }, [content])

  const {
    makeRequest: causeStatementRequest,
    isLoading: causeStatementLoading,
    hasErrors: causeStatementHasErrors,
  } = useRequest(putScript, {
    onSuccess: () => setIsEditingItem(false),
  })

  const makeUpdateCauseStatementRequest = () => {
    updateContent({ cause_statement: contentInState })
    causeStatementRequest(scriptId, { cause_statement: contentInState })
  }

  const handleSaveClick = () => {
    makeUpdateCauseStatementRequest()
  }

  const removeSelf = () => {
    updateContent({ cause_statement: null })
    causeStatementRequest(scriptId, { cause_statement: null })
  }

  const wrapperClass = styles['script-item__wrapper']

  const Icon = iconIndex.intro
  const scriptHeader = (
    <div
      className={`${styles['script-item__icon']} ${styles['script-item__icon--accent']}`}
    >
      <Icon />
    </div>
  )

  return (
    <>
      <LoadBar show={causeStatementLoading} />
      <CardError
        hide={!causeStatementHasErrors}
        message={t('There was an error editing this item')}
      />
      <div className={wrapperClass}>
        <div className={styles['script-item']}>
          {scriptHeader}
          <div className={styles['script-item__content']}>
            {isEditingItem ? (
              <ContentIntro.Edit
                contentInState={contentInState}
                setContentInState={setContentInState}
              />
            ) : (
              <ContentIntro.Active content={content} />
            )}
          </div>
          {isEditingItem ? (
            <ActionsIntro.Edit
              loading={loading}
              setIsEditingItem={setIsEditingItem}
              handleSaveClick={handleSaveClick}
            />
          ) : (
            <ActionsIntro.Read
              loading={loading}
              setIsEditingItem={setIsEditingItem}
              removeSelf={removeSelf}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ScriptIntro
