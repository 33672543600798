import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchExports = (params = {}) =>
  fetchJSON(
    `/api/v1/scheduled_exports?${stringifyParams(params)}`,
    'GET',
    null,
    { useJwt: true }
  )

export const fetchSingleExport = (id, params = {}) =>
  fetchJSON(
    `/api/v1/scheduled_exports/${id}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const postScheduledExport = scheduledExport =>
  fetchJSON(
    `/api/v1/scheduled_exports`,
    'POST',
    {
      scheduled_export: scheduledExport,
      fields: ['id'],
    },
    { useJwt: true }
  )

export const putScheduledExport = (id, scheduledExport) =>
  fetchJSON(
    `/api/v1/scheduled_exports/${id}`,
    'PUT',
    {
      scheduled_export: scheduledExport,
    },
    { useJwt: true }
  )

export const deleteScheduledExport = id =>
  fetchJSON(`/api/v1/scheduled_exports/${id}`, 'DELETE', null, {
    useJwt: true,
  })

export const postScheduledExportSendEmail = id =>
  fetchJSON(`/api/v1/scheduled_exports/${id}/send_email`, 'POST', null, {
    useJwt: true,
  })
