import React from 'react'
import { useTranslation } from 'react-i18next'
import { Step, Section, Sheet } from 'components'
import { Font, TextBlock } from '@politechdev/blocks-design-system'
import { useForm } from 'contexts'
import { FILTER_GROUPS } from '../constants'
import ListFilterGroup from '../ListFilterGroup/ListFilterGroup'
import styles from './ListCreateFilters.module.scss'

const ListCreateFilters = props => {
  const { t } = useTranslation()
  const { formData, areFieldsValid } = useForm()

  return (
    <Step label={t('Select filters')} disableNext={!areFieldsValid} {...props}>
      <div className="create-list__select-filters">
        <Section label={`${t('Select filters for')} ${formData.name}`}>
          <TextBlock>
            <Font.Copy>
              {FILTER_GROUPS.map(group => (
                <span key={group.id} className={styles.subtitle}>
                  <strong>{t(group.title)}</strong> - {t(group.subtitle)}
                </span>
              ))}
            </Font.Copy>
          </TextBlock>
        </Section>
        {FILTER_GROUPS.map(group => (
          <Sheet key={group.id}>
            <ListFilterGroup
              group={group}
              initialFilters={formData[group.id]}
            />
          </Sheet>
        ))}
      </div>
    </Step>
  )
}

export default ListCreateFilters
