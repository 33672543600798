import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from 'components'
import { Button, Icon } from '@politechdev/blocks-design-system'
import PhoneVerificationScriptItem from '../PhoneVerificationScriptItem'
import AddItemModal from '../AddItemModal'
import questionItemstyles from '../QuestionItem.module.scss'
import { CallScriptsContext } from '../CallScriptsContext'

const FIRST_ITEM_POSITION = 0

const EditableScript = () => {
  const [visible, setModalVisible] = useState(false)
  const openAddModal = () => setModalVisible(true)
  const closeAddModal = () => setModalVisible(false)
  const { t } = useTranslation()
  const {
    pendingScript: { structure },
    moveItemUp,
    moveItemDown,
    removeItem,
    addItem,
  } = useContext(CallScriptsContext)

  return (
    <ViewContainer>
      <section className={questionItemstyles['script-item__container--edit']}>
        {structure.map((item, index) => (
          <PhoneVerificationScriptItem
            key={item.content || item.questionKey}
            editable
            isOnTop={index === FIRST_ITEM_POSITION}
            isOnBottom={index === structure.length - 1}
            moveUp={() => moveItemUp(index)}
            moveDown={() => moveItemDown(index)}
            remove={() => removeItem(index)}
            {...item}
          />
        ))}
      </section>
      <section>
        <Button.Primary onClick={openAddModal}>
          <span>{t('Add dialogue')}</span> <Icon.CommentAltLines alt="" />
        </Button.Primary>
      </section>
      <AddItemModal
        visible={visible}
        close={closeAddModal}
        addNewItem={addItem}
      />
    </ViewContainer>
  )
}

export default EditableScript
