import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { useAutoTable } from 'contexts'
import { useRequest, useRoutePathParams } from 'hooks'
import { fetchTeamMemberships, destroyTeamMembership } from 'requests/teams'
import { defaultTo } from 'lodash'
import tableConfig from './tableConfig'
import TextTeamDialog from '../components/TextTeamDialog'
import InviteTeamDialog from '../components/InviteTeamDialog'
import { formatParams } from './utils'

const TeamMemberTable = () => {
  const { t } = useTranslation()
  const [{ teamId }] = useRoutePathParams()

  const { renderTable, refreshPage } = useAutoTable()

  const {
    makeRequest: deleteRequest,
    isLoading: deleteIsLoading,
    hasErrors: deleteHasErrors,
  } = useRequest(destroyTeamMembership, { onSuccess: refreshPage })

  const {
    makeRequest: fetchRequest,
    isLoading: fetchIsLoading,
    hasErrors: fetchHasErrors,
    response,
  } = useRequest(fetchTeamMemberships)

  const memberships = defaultTo(response?.team_memberships, [])
  const totalRecords = defaultTo(response?.meta.total_count, 0)
  const isLoading = deleteIsLoading || fetchIsLoading
  const hasErrors = deleteHasErrors || fetchHasErrors

  const fetchAction = params => fetchRequest(teamId, formatParams(params))

  const handleDelete = rowData => {
    deleteRequest(teamId, rowData.id)
  }

  const columns = [
    {
      actions: [createRowAction(t('Delete'), handleDelete)],
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return renderTable({
    tableId: tableConfig.label,
    data: memberships,
    totalRecords,
    fetchAction,
    columns,
    loading: isLoading,
    error: hasErrors
      ? t(
          `There was a problem ${fetchHasErrors ? "fetching this team's members" : 'deleting the selected membership'}`
        )
      : null,
    headerActions: (
      <>
        <TextTeamDialog teamId={teamId} disabled={totalRecords > 100} />
        <InviteTeamDialog teamId={teamId} />
      </>
    ),
  })
}

export default TeamMemberTable
