import { createContext, useState } from 'react'
import { createReportId, fetchReportData } from 'requests/registrationReports'
import { convertToUTCYMD, subtractOneWeek } from 'utils/dateTime'
import { formatReportData } from 'registrationReports/utils'
import { REGISTRATION_REPORTS_ALL_TIME_START_DATE } from './constants'
import { aggregateReport, fillAggregatedReportRow } from './utils'

export const QualityControlReportsContext = createContext()

const QualityControlReportsProvider = ({ children }) => {
  const [currentReportType, setCurrentReportType] = useState()
  const [reportData, setReportData] = useState({ data: [] })
  const requestReportId = async ({
    reportType,
    startDate,
    endDate,
    turfId,
    currentTurfPerformsExternalQC,
  }) => {
    const res = await createReportId(reportType, {
      start_date: convertToUTCYMD(startDate),
      end_date: convertToUTCYMD(endDate),
      turf_id: turfId,
      qc_external: currentTurfPerformsExternalQC,
    })

    return res.id
  }

  const requestReportIdByDay = ({
    type,
    turfId,
    date,
    currentTurfPerformsExternalQC,
  }) =>
    requestReportId({
      startDate: date,
      endDate: date,
      reportType: type,
      turfId,
      currentTurfPerformsExternalQC,
    })

  const requestReportIdByWeek = ({
    type,
    turfId,
    date,
    currentTurfPerformsExternalQC,
  }) =>
    requestReportId({
      startDate: subtractOneWeek(date),
      endDate: date,
      reportType: type,
      turfId,
      currentTurfPerformsExternalQC,
    })

  const requestReportIdByAllTime = ({
    type,
    turfId,
    date,
    currentTurfPerformsExternalQC,
  }) =>
    requestReportId({
      startDate: REGISTRATION_REPORTS_ALL_TIME_START_DATE,
      endDate: date,
      reportType: type,
      turfId,
      currentTurfPerformsExternalQC,
    })

  const requestReport = async (reportId, reportType) => {
    try {
      const { statusCode, response } = await fetchReportData(reportId)
      if (statusCode === 204) return
      if (currentReportType === reportType) {
        const formattedReport = formatReportData(response)
        setReportData(formattedReport)
        return { id: reportId, response }
      }
    } catch (error) {
      if (currentReportType === reportType) {
        throw error
      }
    }
  }

  const aggregateTimelineReport = ({ reportConfig, day, week, allTime }) => {
    const {
      nonTimelineColumns,
      aggregateKeys,
      columns: columnConfigs,
      totalOnlyKeys,
    } = reportConfig
    const aggregatedReport = []

    aggregateReport({
      report: day,
      label: 'day',
      nonTimelineColumns,
      aggregateKeys,
      aggregatedReport,
    })
    aggregateReport({
      report: week,
      label: 'week',
      nonTimelineColumns,
      aggregateKeys,
      aggregatedReport,
    })
    aggregateReport({
      report: allTime,
      label: 'all_time',
      nonTimelineColumns,
      aggregateKeys,
      aggregatedReport,
    })

    if (reportConfig.id !== 'qc_by_response') {
      setReportData(aggregatedReport)
    } else {
      const aggregatedReportFilled = aggregatedReport.map(aggregatedRow =>
        fillAggregatedReportRow({ aggregatedRow, columnConfigs, totalOnlyKeys })
      )
      setReportData(aggregatedReportFilled)
    }
  }

  return (
    <QualityControlReportsContext.Provider
      value={{
        setCurrentReportType,
        reportData,
        requestReportId,
        requestReportIdByDay,
        requestReportIdByWeek,
        requestReportIdByAllTime,
        requestReport,
        aggregateTimelineReport,
      }}
    >
      {children}
    </QualityControlReportsContext.Provider>
  )
}

export default QualityControlReportsProvider
