import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { fetchListFolders } from 'requests/lists'
import FolderCard from '../FolderCard/FolderCard'
import FolderCreate from '../FolderCreate/FolderCreate'

const FolderSelect = () => {
  const { t } = useTranslation()

  const [showCreate, setShowCreate] = useState(false)

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewFolders = doesCurrentUserHavePermission({
    resource: 'list_folder',
    ability: 'view',
  })
  const canModifyFolders = doesCurrentUserHavePermission({
    resource: 'list_folder',
    ability: 'modify',
  })

  if (!canViewFolders && !canModifyFolders) {
    return (
      <CardError
        hide={canViewFolders}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  const {
    makeRequest,
    isLoading,
    hasErrors,
    errors,
    response,
    isRequestComplete,
  } = useRequest(fetchListFolders)

  const errorMsg = formatErrorMessage(errors)

  const getListFolders = () =>
    makeRequest({
      fields: ['id', 'name', { lists: ['id'] }, { sharees: ['id'] }],
    })

  useEffect(() => {
    getListFolders()
  }, [])

  const folders = isRequestComplete ? response.list_folders : []

  return (
    <View>
      <ProgressBar show={isLoading} />
      <PageHeader title={t('My folders')}>
        {canModifyFolders && (
          <ButtonBlock justify="right">
            {showCreate ? (
              <Button.Secondary onClick={() => setShowCreate(false)}>
                <Icon.Times alt={t('close')} />
              </Button.Secondary>
            ) : (
              <Button.Accent onClick={() => setShowCreate(true)}>
                {t('New folder')}
              </Button.Accent>
            )}
          </ButtonBlock>
        )}
      </PageHeader>
      <CardError hide={!hasErrors} message={errorMsg} />
      <FolderCreate
        hide={!showCreate}
        closeForm={() => {
          setShowCreate(false)
        }}
        getListFolders={getListFolders}
      />
      <ViewContainer>
        {folders.map(folder => (
          <FolderCard
            key={folder.id}
            folder={folder}
            getListFolders={getListFolders}
          />
        ))}
      </ViewContainer>
    </View>
  )
}

export default FolderSelect
