import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchCampaigns = (params = {}) =>
  fetchJSON(`/api/v1/campaigns?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchCampaign = (id, params) =>
  fetchJSON(`/api/v1/campaigns/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postCampaign = campaign =>
  fetchJSON('/api/v1/campaigns', 'POST', { campaign }, { useJwt: true })

export const putCampaign = (id, campaign) =>
  fetchJSON(
    `/api/v1/campaigns/${id}`,
    'PUT',
    { campaign },
    {
      useJwt: true,
    }
  )

export const destroyCampaign = id =>
  fetchJSON(`/api/v1/campaigns/${id}`, 'DELETE', null, {
    useJwt: true,
  })
