export const getMousePosition = event => {
  const pos = {
    x: 0,
    y: 0,
  }

  if (
    event.type === 'touchend' &&
    event.changedTouches &&
    event.changedTouches.length > 0
  ) {
    pos.x = event.changedTouches[0].clientX
    pos.y = event.changedTouches[0].clientY
  } else {
    pos.x = event.clientX
    pos.y = event.clientY
  }

  if (!pos.x || pos.x < 0) pos.x = 0
  if (!pos.y || pos.y < 0) pos.y = 0

  return pos
}
