import { useTranslation, Trans } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  Modal,
  TextBlock,
} from '@politechdev/blocks-design-system'

const DeliveryWarnVdrModal = ({
  isOpen,
  setIsOpen,
  deliveryStepRequest,
  issueCounties,
}) => {
  const { t } = useTranslation()

  const onApply = () => {
    deliveryStepRequest()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  const issueCountiesString = issueCounties.join(',')

  return (
    <Modal
      id="warn-unapproved-vdr"
      title={t('Are you sure you want to continue?')}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <Trans i18nKey="countyVdrNotFound">
              Warning: You have selected a county ({{ issueCountiesString }})
              for which the runner is not an approved VDR. If the runner is an
              approved VDR, please return to the canvasser page and add that VDR
              number for the appropriate canvasser. Are you sure you wish to
              continue?
            </Trans>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('No')}</Button.Secondary>
          <Button.Accent onClick={onApply}>{t('Yes')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default DeliveryWarnVdrModal
