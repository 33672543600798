import {
  Icon,
  FieldBlock,
  Sheet,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import styles from './ReportPicker.module.scss'

const ReportsPicker = ({
  categoryId,
  reportType,
  reportTypes,
  reportCategories,
  changeReportType,
  changeReportCategory,
}) => {
  const { doesCurrentUserHavePermission } = useCurrent()
  return (
    <Sheet>
      <FieldBlock>
        <SelectField
          id="report-category"
          className={styles.select}
          label="Report"
          options={reportCategories
            .filter(category =>
              doesCurrentUserHavePermission({
                resource: category.permissionId,
                ability: 'view',
              })
            )
            .map(category => ({
              value: category.id,
              label: category.name,
            }))}
          deleteKeys={['columns', 'reportTypes', 'defaultType']}
          value={categoryId}
          onSelect={changeReportCategory}
        />
        {reportType ? (
          <>
            <span>
              <Icon.ChevronRight key="chevron" />
            </span>
            <SelectField
              className={styles.select}
              key="report-type"
              id="report-type"
              label="Narrow by"
              options={reportCategories
                .find(reportCategory => reportCategory.id === categoryId)
                .reportTypes.map(id => {
                  const reportType = reportTypes.find(
                    reportType => reportType.id === id
                  )
                  return {
                    value: reportType.id,
                    label: reportType.name,
                    disabled: reportType.isSelectable === false,
                  }
                })}
              value={reportType}
              onSelect={changeReportType}
            />
          </>
        ) : null}
      </FieldBlock>
    </Sheet>
  )
}

export default ReportsPicker
