import React from 'react'
import { useTranslation } from 'react-i18next'
import PacketList from './PacketList'
import PacketDetail from './PacketDetail'

const FormViewer = ({ hasCounty }) => {
  const { t } = useTranslation()

  return (
    <div className="form-viewer">
      {hasCounty ? (
        <>
          <div className="form-viewer__title">{t('Available packets')}</div>
          <div className="form-viewer__container">
            <PacketList />
            <PacketDetail />
          </div>
        </>
      ) : (
        <div className="form-viewer__blank-slate">
          <p className="reading-text">{t('Select a county to get started')}</p>
        </div>
      )}
    </div>
  )
}

export default FormViewer
