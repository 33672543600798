import React from 'react'
import classNames from 'classnames/bind'
import styles from './Grid.module.scss'

const cx = classNames.bind(styles)

const Grid = ({ className, children, fourBy }) => (
  <div className={cx('grid', { 'grid--four': fourBy }, className)}>
    {children}
  </div>
)

export default Grid
