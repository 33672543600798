import { SelectField } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

export const Filter = ({
  id,
  label,
  value,
  onChange,
  menuItems,
  isDisabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <SelectField
      id={id}
      label={t(label)}
      value={value}
      options={menuItems}
      onSelect={onChange}
      disabled={isDisabled}
    />
  )
}
