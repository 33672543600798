import React, { useState } from 'react'
import { CardError, Button as IconButton, EventSelectField } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  TextBlock,
  FieldBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks'
import { inviteTeam } from 'requests/teams'

const InviteTeamDialog = ({ teamId }) => {
  const { t } = useTranslation()

  const [dialogVisible, setDialogVisble] = useState(false)
  const [event, setEvent] = useState('')

  const hideDialog = () => {
    setDialogVisble(false)
  }

  const inviteTeamRequest = useRequest(inviteTeam, { onSuccess: hideDialog })

  const showDialog = () => {
    setDialogVisble(true)
  }

  const sendInvite = async () => {
    await inviteTeamRequest.makeRequest(teamId, event.id)
  }

  return (
    <span>
      <IconButton
        icon
        tooltipLabel={t('Add team to event')}
        onClick={showDialog}
        aria-label={t('Add team to event')}
      >
        event
      </IconButton>
      <Modal
        id="invite-dialog"
        isOpen={dialogVisible}
        title={t('Add team to event')}
        onHide={hideDialog}
        showLoading={inviteTeamRequest.isLoading}
      >
        <Modal.Body>
          <ContentBlock>
            <TextBlock>
              {t('Add the people in this team to the following event')}
            </TextBlock>
            <FieldBlock>
              <EventSelectField
                id="select-event"
                event={event}
                onSelect={setEvent}
                requestParams={{
                  sort_attr: 'name',
                  sort_dir: 'asc',
                }}
              />
            </FieldBlock>
            <CardError
              hide={!inviteTeamRequest.hasErrors}
              message={t(
                'An internal error occurred while selecting your event'
              )}
            />
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={hideDialog}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent
              disabled={!event || inviteTeamRequest.hasErrors}
              onClick={() => sendInvite(teamId)}
            >
              {t('Send')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </span>
  )
}

export default InviteTeamDialog
