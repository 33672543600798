import { useContext, useEffect, useMemo, useState } from 'react'
import { useBackgroundRequest } from 'hooks'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ContentBlock } from 'components'
import { useCurrent } from 'contexts/index'
import { DashboardFilterContext } from 'dashboard/DashboardFilterContext/DashboardFilterContext'
import WidgetPanel from 'dashboard/WidgetPanel/WidgetPanel'
import { requestChart, requestChartId } from '../../../requests/charts'
import WeeklyRates from './WeeklyRates/WeeklyRates'
import { DEFAULT_RATES } from './constants'
import styles from './EventsByDate.module.scss'

const formatData = (data = []) => {
  data.forEach(item => {
    const date = Object.keys(item)[0]
    const dateObj = moment(date)
    const month = dateObj.month()
    let nthOfMonth = Math.ceil(dateObj.date() / 7)
    if (nthOfMonth > 3) nthOfMonth = 3
    DEFAULT_RATES[month].rates[nthOfMonth] += item[date]
  })
  return DEFAULT_RATES
}

const EventsByDate = () => {
  const { t } = useTranslation()

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()
  const {
    startDate,
    endDate,
    turfId: turfIdFilter,
  } = useContext(DashboardFilterContext)

  const filters = useMemo(
    () => ({ startDate, endDate, turfId: turfIdFilter }),
    [startDate, endDate, turfIdFilter]
  )

  const [data, setData] = useState([])

  const { makeRequest, isLoading, errorMsg, response } = useBackgroundRequest({
    key: 'events_by_date',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters,
      }),
    requestData: requestChart,
  })

  useEffect(() => {
    makeRequest()
  }, [filters])

  useEffect(() => {
    if (response) {
      setData(response.rows)
    }
  })

  const formattedData = formatData(data)

  return (
    <WidgetPanel
      title={t('Events by date')}
      isEmpty={!data}
      isLoading={isLoading}
      errorMsg={errorMsg}
    >
      <>
        <ContentBlock>
          <div className={styles.rates}>
            {formattedData
              ? Object.values(formattedData).map(rate => (
                  <WeeklyRates
                    month={rate.month}
                    rates={rate.rates}
                    key={rate.month}
                  />
                ))
              : t('no rates')}
          </div>
        </ContentBlock>
        <ContentBlock className={styles.legend}>
          <div>
            <span>0</span>
            <WeeklyRates rates={[0]} />
          </div>
          <div>
            <span>1-5</span>
            <WeeklyRates rates={[1]} />
          </div>
          <div>
            <span>6-9</span>
            <WeeklyRates rates={[6]} />
          </div>
          <div>
            <span>10+</span>
            <WeeklyRates rates={[10]} />
          </div>
        </ContentBlock>
      </>
    </WidgetPanel>
  )
}

export default EventsByDate
