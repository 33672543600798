import { fetchJSON, stringifyParams } from 'utils/req'

export const organizationFields = [
  'id',
  'name',
  'slug',
  'turf_id',
  'organization_type',
  'membership_type',
  'phone_number',
  'website',
  'mailing_street_address',
  'mailing_city',
  'mailing_state',
  'mailing_zipcode',
  'active',
  'soft_member_count',
  'members_count',
  'influence_level',
  'issues',
  'notes',
  'denominations',
  { leader: ['id', 'name'] },
  {
    locations: ['id', 'name', 'street_address', { turf: ['id', 'name'] }],
  },
  { teams: ['id', 'name'] },
  {
    address: [
      'line_one',
      'city',
      'zipcode',
      'state',
      'county',
      'district_config',
    ],
  },
]

export const fetchOrganizations = params =>
  fetchJSON(`/api/v1/organizations?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchOrganization = (id, params = {}) =>
  fetchJSON(
    `/api/v1/organizations/${id}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const postOrganization = organization =>
  fetchJSON(
    `/api/v1/organizations?${stringifyParams({ fields: ['id'] })}`,
    'POST',
    { organization },
    { useJwt: true }
  )

export const putOrganization = (id, organization) =>
  fetchJSON(
    `/api/v1/organizations/${id}?${stringifyParams({ fields: ['id'] })}`,
    'PUT',
    { organization },
    {
      useJwt: true,
    }
  )

export const destroyOrganization = id =>
  fetchJSON(`/api/v1/organizations/${id}`, 'DELETE', null, {
    useJwt: true,
  })
