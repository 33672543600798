import {
  SectionLabel,
  List,
  ListItem,
  ContentBlock,
  Font,
  Icon,
} from '@politechdev/blocks-design-system'
import moment from 'moment'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CanvasserContext } from 'canvassers/CanvasserContext/CanvasserContext'
import { lowerCase, upperFirst } from 'lodash'
import { Paginator } from 'components/index'
import styles from './CanvasserFeedback.module.scss'
import { getFormattedFeedback } from './utils'

const CanvasserFeedback = () => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(0)
  const { currentCanvasser } = useContext(CanvasserContext)
  const feedback = getFormattedFeedback(currentCanvasser)

  const paginatedFeedback = feedback.slice(
    currentPage * 5,
    Math.min(currentPage * 5 + 5, feedback.length)
  )

  const FeedbackIndicator = ({ item }) => (
    <Font.Copy className={styles['centered-item']}>
      {t(upperFirst(lowerCase(item.feedback)))}
      {item.feedback === 'positive_feedback' && (
        <Icon.ThumbsUp className={styles['positive-feedback']} />
      )}{' '}
      {item.feedback === 'negative_feedback' && (
        <Icon.ThumbsDown className={styles['negative-feedback']} />
      )}
    </Font.Copy>
  )

  return (
    <section>
      <SectionLabel>{t('Feedback')}</SectionLabel>
      <List
        itemData={paginatedFeedback}
        render={item => (
          <ListItem>
            <ContentBlock className={styles['center-container']}>
              <Font.Copy variant="hint" className={styles['centered-item']}>
                {item.content}
              </Font.Copy>
            </ContentBlock>
            <ContentBlock>
              <Font.Copy>{moment(item.created_at).format('ll')}</Font.Copy>
              <Font.Copy variant="hint">
                {t('Added by')} {item.author}
              </Font.Copy>
            </ContentBlock>
            <ContentBlock className={styles['center-container']}>
              <FeedbackIndicator item={item} />
            </ContentBlock>
          </ListItem>
        )}
      />
      <Paginator
        currentPage={currentPage + 1}
        totalPages={Math.ceil(feedback.length / 5) || 1}
        onNext={() => setCurrentPage(old => old + 1)}
        onPrevious={() => setCurrentPage(old => old - 1)}
        justify="left"
      />
    </section>
  )
}

export default CanvasserFeedback
