import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  ButtonBlock,
  Button,
  Icon,
  PageHeader,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useRoutePathParams, useReactRouter, useRequest } from 'hooks'
import { fetchOrganization, organizationFields } from 'requests/organizations'
import { useCurrent } from 'contexts/index'
import OrganizationDeleteModal from '../OrganizationDeleteModal/OrganizationDeleteModal'
import OrganizationDetails from '../OrganizationDetails/OrganizationDetails'
import OrganizationTabs from '../OrganizationTabs/OrganizationTabs'

const OrganizationSingle = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [{ id: organizationId }] = useRoutePathParams()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyOrganization = doesCurrentUserHavePermission({
    resource: 'organization',
    ability: 'modify',
  })

  const [currentOrganization, setCurrentOrganization] = useState({})

  const { makeRequest, isLoading, hasErrors, isRequestComplete } = useRequest(
    fetchOrganization,
    {
      onSuccess: ({ 'people/organization': organization }) => {
        setCurrentOrganization(organization)
      },
    }
  )

  useEffect(() => {
    makeRequest(organizationId, { fields: organizationFields })
  }, [organizationId])

  const organizationLoaded =
    isRequestComplete &&
    !hasErrors &&
    (currentOrganization?.id === +organizationId ||
      currentOrganization?.slug === organizationId)

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <CardError
          hide={!hasErrors}
          message={t("We're unable to retrieve this organization")}
        />
        {organizationLoaded && (
          <>
            <Sheet>
              <PageHeader title={currentOrganization.name}>
                <ButtonBlock justify="right">
                  <Link
                    onClick={e => !canModifyOrganization && e.preventDefault()}
                    to={`${match.url}/edit`}
                  >
                    <Button disabled={!canModifyOrganization}>
                      <Icon.Pencil alt={t('Edit')} />
                    </Button>
                  </Link>
                  <OrganizationDeleteModal name={currentOrganization?.name} />
                </ButtonBlock>
              </PageHeader>
              <OrganizationDetails currentOrganization={currentOrganization} />
            </Sheet>
            <Sheet>
              <OrganizationTabs />
            </Sheet>
          </>
        )}
      </ViewContainer>
    </View>
  )
}

export default OrganizationSingle
