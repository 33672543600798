import moment from 'moment'
import { dateTimeFormat } from 'utils/constants'

export const projectionNameCsvCell = data => data.name

export const projectionTurfCsvCell = data => data.turf.name

export const projectionCreatorCsvCell = data => data.creator?.name

export const projectionStatusCsvCell = data => data.status

export const projectionStartDateCsvCell = data =>
  moment(data.startDate).format(dateTimeFormat)

export const projectionEndDateCsvCell = data =>
  moment(data.endDate).format(dateTimeFormat)

export const projectionTotalCsvCell = data => data.total
