import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ViewContainer,
  Sheet,
  Section,
  LoadBar,
  CardError,
  TextField,
  UserSelectFieldDEPRECATED,
} from 'components'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { postListFolder } from 'requests/lists'
import styles from './FolderCreate.module.scss'

const FolderCreate = ({ hide, closeForm, getListFolders }) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrent()

  const [name, setName] = useState('')
  const [users, setUsers] = useState([])

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(
    postListFolder,
    {
      onSuccess: () => {
        getListFolders()
        closeForm()
      },
    }
  )
  const errorMsg = formatErrorMessage(errors)

  const submitForm = e => {
    e.preventDefault()
    makeRequest({
      name,
      sharee_ids: users.map(s => s.id),
    })
  }

  if (hide) {
    return null
  }

  return (
    <ViewContainer>
      <Sheet className={styles.sheet}>
        <LoadBar show={isLoading} />
        <CardError hide={!hasErrors} message={errorMsg} hideSupportLink />
        <form onSubmit={submitForm}>
          <Section label={t('Create folder')}>
            <FieldBlock className={styles.field}>
              <TextField
                id="name"
                label={t('Name')}
                value={name}
                onChange={setName}
                required
              />
            </FieldBlock>
            <FieldBlock className={styles.field}>
              <UserSelectFieldDEPRECATED
                label={t('Share with')}
                isMulti
                onSelect={setUsers}
                users={users}
                filters={[
                  {
                    column: 'id',
                    operator: 'is',
                    invert: 'true',
                    param: currentUser.id,
                  },
                ]}
                clearable
                usePortal
              />
            </FieldBlock>
            <TextBlock>
              <span className={styles.hint}>
                {t('Shared users can only view your lists, not edit them')}
              </span>
            </TextBlock>
          </Section>
          <ButtonBlock justify="left">
            <Button.Accent type="submit" disabled={name === '' || isLoading}>
              {t('Create Folder')}
            </Button.Accent>
            <Button.Secondary onClick={closeForm}>
              {t('Cancel')}
            </Button.Secondary>
          </ButtonBlock>
        </form>
      </Sheet>
    </ViewContainer>
  )
}

export default FolderCreate
