import { ViewContainer } from 'components'
import { useEffect } from 'react'
import PacketSummary from 'qualityControl/packetAssignment/packetSummary/PacketSummary'
import StaffList from './staff/StaffList'
import { usePacketAssignment } from './PacketAssignmentContext'

export const GroupPacketAssignment = () => {
  const { getScheduledUsers } = usePacketAssignment()

  useEffect(() => {
    getScheduledUsers()
  }, [])

  return (
    <ViewContainer>
      <PacketSummary />
      <StaffList />
    </ViewContainer>
  )
}

export default GroupPacketAssignment
