import React from 'react'
import PropTypes from 'prop-types'
import { Marker as MarkerGL } from '@urbica/react-map-gl'
import classNames from 'classnames/bind'
import styles from './ClusterMarker.module.scss'

const cx = classNames.bind(styles)

const LOW_LIMIT = 5
const MEDIUM_LIMIT = 15

const ClusterMarker = ({ onClick, cluster }) => {
  const handleClick = () => {
    onClick && onClick(cluster)
  }

  return (
    <MarkerGL
      onClick={handleClick}
      latitude={cluster.latitude}
      longitude={cluster.longitude}
    >
      <div
        className={cx('cluster', {
          'cluster--low': cluster.pointCount <= LOW_LIMIT,
          'cluster--medium':
            cluster.pointCount > LOW_LIMIT &&
            cluster.pointCount <= MEDIUM_LIMIT,
          'cluster--high': cluster.pointCount > MEDIUM_LIMIT,
        })}
      >
        {cluster.pointCount}
      </div>
    </MarkerGL>
  )
}

ClusterMarker.propTypes = {
  cluster: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    pointCount: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
}

ClusterMarker.defaultProps = {
  onClick: null,
}

export default ClusterMarker
