import { Icon } from '@politechdev/blocks-design-system'

export const iconIndex = {
  intro: Icon.CommentAltLines,
  dialogue: Icon.CommentAltLines,
  single_choice: Icon.QuestionCircle,
  multiple_choice: Icon.QuestionCircle,
  open_ended: Icon.QuestionCircle,
  instruction: Icon.Map,
}
