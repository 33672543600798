import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PublicPage from '../PublicPage/PublicPage'

const PublicEventRoutes = props => {
  const { match } = props

  return (
    <Switch>
      <Route exact path={`${match.url}/:id/public`} component={PublicPage} />
      <Route>
        <Redirect to="/auth" />
      </Route>
    </Switch>
  )
}

export default PublicEventRoutes
