import { formatPhoneNumber } from 'utils/formatting'

export const PARTICIPANT_KEYS = {
  NAME: '[participant name]',
  GENDER: '[participant gender]',
  PRIMARY_PHONE: '[participant primary phone number]',
  STATE: '[participant state]',
  PREV_REG_ADDRESS: '[participant previous registration address]',
  PREV_REG_DATE: '[participant previous registration date]',
  PREV_REG_ORGANIZATION: '[participant previous registration organization]',
  LANGUAGES: `[participant languages]`,
}

export const INTERPOLATED_VALUES = {
  [PARTICIPANT_KEYS.NAME]: participant =>
    participant?.name || PARTICIPANT_KEYS.NAME,
  [PARTICIPANT_KEYS.GENDER]: participant =>
    participant?.gender || PARTICIPANT_KEYS.GENDER,
  [PARTICIPANT_KEYS.PRIMARY_PHONE]: participant => {
    const phoneNumber = participant?.primary_phone_number
    return phoneNumber
      ? formatPhoneNumber(phoneNumber)
      : PARTICIPANT_KEYS.PRIMARY_PHONE
  },
  [PARTICIPANT_KEYS.STATE]: participant =>
    participant?.state || PARTICIPANT_KEYS.STATE,
  [PARTICIPANT_KEYS.LANGUAGES]: participant =>
    participant?.languages?.length
      ? participant.languages.join(', ')
      : PARTICIPANT_KEYS.LANGUAGES,
}
