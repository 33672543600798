import React from 'react'
import { Link } from 'react-router-dom'
import { countBy } from 'lodash'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { formatPhoneNumber } from 'utils/formatting'
import { isNonPrimaryPhone } from 'constants/people'

export const MemberNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/people/${rowData.id}`}>{rowData.name}</Link>
  </TableGridCell>
)

export const MemberFirstNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.first_name}</TableGridCell>
)

export const MemberLastNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.last_name}</TableGridCell>
)

export const MemberPhoneNumberCell = ({ rowData }) => {
  const phoneNumbers = countBy(rowData.contact_methods, isNonPrimaryPhone).true
  return (
    <TableGridCell
      subtitle={
        rowData.primary_phone_number && phoneNumbers > 1
          ? `+${phoneNumbers - 1} more`
          : null
      }
    >
      {formatPhoneNumber(rowData.primary_phone_number)}
    </TableGridCell>
  )
}

export const MemberAddressCell = ({ rowData }) => (
  <TableGridCell>
    {[
      rowData.residential_address?.line_one,
      rowData.residential_address?.line_two,
    ].join(' ')}
  </TableGridCell>
)

export const MemberCityCell = ({ rowData }) => (
  <TableGridCell>{rowData.residential_address?.city}</TableGridCell>
)

export const MemberStateCell = ({ rowData }) => (
  <TableGridCell>{rowData.residential_address?.state}</TableGridCell>
)

export const MemberZipCodeCell = ({ rowData }) => (
  <TableGridCell>{rowData.residential_address?.zipcode}</TableGridCell>
)

export const MemberEmailCell = ({ rowData }) => {
  const emailAddresses = countBy(
    rowData.contact_methods,
    method =>
      method.contact_type === 'home_email_address' ||
      method.contact_type === 'work_email_address'
  ).true
  return (
    <TableGridCell
      subtitle={
        rowData.primary_email_address && emailAddresses > 1
          ? `+${emailAddresses - 1} more`
          : null
      }
    >
      {rowData.primary_email_address}
    </TableGridCell>
  )
}
