import { useTranslation } from 'react-i18next'
import { ViewContainer } from 'components'
import {
  ButtonBlock,
  Button,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { Card } from 'react-md'
import EmailSelectTable from './EmailSelectTable/EmailSelectTable'

const EmailSelect = () => {
  const { t } = useTranslation()
  return (
    <div>
      <PageHeader title={t('Emails sent')}>
        <ButtonBlock justify="right">
          <Link to="/email/manage/drafts">
            <Button.Secondary>{t('Manage drafts')}</Button.Secondary>
          </Link>
          <Link to="/email/send">
            <Button.Accent>{t('Send new')}</Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer>
        <Card>
          <EmailSelectTable />
        </Card>
      </ViewContainer>
    </div>
  )
}

export default EmailSelect
