import React from 'react'
import { useTranslation } from 'react-i18next'
import { Font } from '@politechdev/blocks-design-system'
import styles from './LocationMapHeader.module.scss'

const LocationMapHeader = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.header}>
      <Font.Display variant="small" className={styles.title}>
        {t('Locations map')}
      </Font.Display>
    </div>
  )
}

export default LocationMapHeader
