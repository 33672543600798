import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { CardError, LoadBar } from 'components'
import { formatErrorMessage } from 'utils/formatting'
import { putPacket } from 'requests/tableQcManager'
import { UserAssignablePacketsCheckboxGroupContext } from './CheckboxContextProvider'

const AssignmentButtons = ({ onAssignComplete, user }) => {
  const { t } = useTranslation()

  const { getCheckedCheckboxValues, checkAll, areAllChecked, uncheckAll } =
    useContext(UserAssignablePacketsCheckboxGroupContext)

  const { makeRequest, isLoading, errors, hasErrors } = useRequest(
    () => {
      const packetsToAssign = getCheckedCheckboxValues()
      const requests = packetsToAssign.map(packet =>
        putPacket(packet.id, { assignee_id: user.id })
      )

      return Promise.all(requests)
    },
    {
      onSuccess: () => {
        uncheckAll()
        onAssignComplete()
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  const areNoPacketsSelected = getCheckedCheckboxValues().length === 0

  const handleSelection = areAllChecked() ? uncheckAll : checkAll

  const selectButtonText = areAllChecked() ? t('Clear all') : t('Select all')

  return (
    <div>
      <CardError hide={!hasErrors} message={errorMsg} />
      <LoadBar show={isLoading} />
      <ButtonBlock>
        <Button.Secondary onClick={handleSelection}>
          {selectButtonText}
        </Button.Secondary>
        <Button.Accent
          disabled={areNoPacketsSelected}
          onClick={() => {
            makeRequest(getCheckedCheckboxValues())
          }}
        >
          {`${t('Assign to')} ${user.name}`.toUpperCase()}
        </Button.Accent>
      </ButtonBlock>
    </div>
  )
}

export default AssignmentButtons
