import draftToHtml from 'draftjs-to-html'

const processRichText = value =>
  typeof value === 'string'
    ? value
    : draftToHtml(value, {}, false, (entity, text) => {
        if (entity.type !== 'LINK') return
        return `<a href="${entity.data.url}" target="_blank">${text}</a>`
      })

export const buildStart = styles => `
  <!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Blocks Email Communications</title>
      <style>

        /* -------------------------------------
            CSS Reset
        ------------------------------------- */

        html, body, div, span, applet, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed,
        figure, figcaption, footer, header, hgroup,
        menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
        	margin: 0;
        	padding: 0;
        	border: 0;
        	font-size: 100%;
        	font: inherit;
        	vertical-align: baseline;
        }
        /* HTML5 display-role reset for older browsers */
        article, aside, details, figcaption, figure,
        footer, header, hgroup, menu, nav, section {
        	display: block;
        }
        body {
        	line-height: 1;
        }
        blockquote, q {
        	quotes: none;
        }
        blockquote:before, blockquote:after,
        q:before, q:after {
        	content: '';
        	content: none;
        }

        /* -------------------------------------
            Typography
        ------------------------------------- */
        .email__container p {
          color: ${styles.bodyCopyColor};
        }

        .email__container ul,
        .email__container ol {
          margin-left: 1em;
          margin-top: 1em;
          margin-bottom: 1em;
          color: ${styles.bodyCopyColor};
        }

        .email__container a {
          text-decoration: underline;
        }

        .email__container em,
        .email__container i {
         font-style: italic;
         color: ${styles.bodyCopyColor};
        }

        .email__container strong,
        .email__container b {
          font-weight: 700;
          color: ${styles.bodyCopyColor};
        }

      </style>
    </head>
    <body style="font-family: ${styles.fontFamily}; color: ${styles.bodyCopyColor}; background-color: #ffffff; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; width: 100%;">
      <table class="email__container" width="100%" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box; min-width: 100% !important;">
        <tr>
          <td>&nbsp;</td>
          <td style="max-width: 860px; width: 100%; padding: 10px; display: block; margin: 0 auto !important;">

              <!-- START CENTERED CONTAINER -->
              <table width="100%" border="0" cellpadding="0" cellspacing="0" style="background-color: ${styles.backgroundColor}; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box; min-width: 100% !important; border-radius: 3px;">

                <!-- START MAIN CONTENT AREA -->
                  <tr>
                    <td style="box-sizing: border-box; padding: 40px; width: 100% !important;">
`

export const buildEnd = () => `
                </td>
              </tr>
              <!-- END MAIN CONTENT AREA -->

            </table>
            <!-- END CENTERED WHITE CONTAINER -->

            <!-- START FOOTER -->
            <table width="100%" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box; min-width: 100% !important; border-radius: 3px;">
              <tr>
                <td style="text-align: center; color: #1A1A1A; box-sizing: border-box; padding: 40px; width: 100% !important;">
                  Sent from <a href="http://blocks.im" target="_blank">Blocks</a>
                </td>
              </tr>
            </table>
            <!-- END FOOTER -->
        </td>
        <td>&nbsp;</td>
      </tr>
    </table>
  </body>
</html>
`

export const buildButton = (value, styles) => `
  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box; min-width: 100% !important;" width="100%">
    <tr>
      <td align="center" style="font-family: ${styles.fontFamily}; font-size: 14px; vertical-align: top; padding-top: 30px; padding-bottom: 30px;" valign="top">
        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
          <tr>
            <td style="font-family: ${styles.fontFamily}; font-size: 14px; vertical-align: top; background-color: ${styles.buttonBackgroundColor}; border-radius: 5px; text-align: center;" valign="top" bgcolor="${styles.buttonBackgroundColor}" align="center">
              <a href="${value.url}" target="_blank" style="display: inline-block; color: ${styles.buttonColor}; background-color: ${styles.buttonBackgroundColor}; border: solid 1px ${styles.buttonBackgroundColor}; border-radius: 5px; box-sizing: border-box; cursor: pointer; text-decoration: none; font-size: 14px; font-weight: bold; margin: 0; padding: 12px 25px; text-transform: capitalize; border-color: ${styles.buttonBackgroundColor};">
                ${value.name}
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
`

export const buildTitle = (value, styles) => `
  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box; min-width: 100% !important;" width="100%">
    <tr>
      <td align="center" style="font-family: ${styles.fontFamily}; font-size: 28px; vertical-align: top; padding-bottom: 42px;" valign="top">
        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
          <tr>
            <td style="font-family: ${styles.fontFamily}; font-size: 28px; text-transform: uppercase; vertical-align: top; border-radius: 5px; text-align: center;" valign="top" align="center">
              <h1 color: ${styles.titleColor} style="color: ${styles.titleColor}">${value}</h1>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
`

export const buildHeading = (value, styles) => `
  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box; min-width: 100% !important;" width="100%">
    <tr>
      <td style="font-family: ${styles.fontFamily}; font-size: 22px; vertical-align: top; padding-bottom: 15px;" valign="top">
        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
          <tr>
            <td style="font-family: ${styles.fontFamily}; font-size: 22px; vertical-align: top; border-radius: 5px; text-align: left;" valign="top" align="center">
              <h2 color: ${styles.headingColor} style="color: ${styles.headingColor}">${value}</h2>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
`

export const buildBodyCopy = (value, styles) => `
  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box; min-width: 100% !important;" width="100%">
    <tr>
      <td style="font-family: ${
        styles.fontFamily
      }; font-size: 16px; vertical-align: top; padding-bottom: 15px;" valign="top">
        <table border="0" cellpadding="0" cellspacing="0" style="max-width: 750px; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
          <tr>
            <td color: ${styles.bodyCopyColor} style="font-family: ${
              styles.fontFamily
            }; color: ${
              styles.bodyCopyColor
            }; font-size: 16px; vertical-align: top; border-radius: 5px; text-align: left;" valign="top" align="center">
              <div color: ${styles.bodyCopyColor} style="color: ${
                styles.bodyCopyColor
              }">
                ${processRichText(value)}
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
`

export const buildImage = (value, styles) =>
  value
    ? `
    <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box; min-width: 100% !important;" width="100%">
        <tr>
          <td align="center" style="font-family: ${styles.fontFamily}; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
            <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
              <tr>
                <td style="font-family: ${styles.fontFamily}; font-size: 28px; vertical-align: top; border-radius: 5px; text-align: center;" valign="top" align="center">
                  <img src="${value}" width="100%" height="auto" border="0" style="max-width: 100%; border:0; outline:none; text-decoration:none; display:block;">
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
`
    : ''
