import {
  Button,
  ButtonBlock,
  Checkbox,
  Font,
  ContentBlock,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { StaffLanguages } from 'qualityControl/packetAssignment/staff/StaffLanguages'
import styles from './ListItem.module.scss'
import { useAssignedCheckboxes } from '../../CheckboxContextProvider'

export const PacketAssignmentListItem = ({
  id,
  packetName,
  scanCount,
  daysLeft,
  packetLanguage,
  reassignPacket,
}) => {
  const { t } = useTranslation()

  const { toggleCheckbox, isChecked } = useAssignedCheckboxes()

  return (
    <div className={styles.item}>
      <ContentBlock>
        <Checkbox
          checked={isChecked(id)}
          onChange={() => toggleCheckbox(id)}
          id={id}
          name={`name-${id}`}
          aria-label="select column"
          aria-labelledby={id}
        />
      </ContentBlock>
      <div className={styles.item__main}>
        <TextBlock>
          <Font.Copy>{packetName}</Font.Copy>
          <Font.Copy variant="hint">
            {`${scanCount} ${t('scans')} - ${daysLeft}`}
          </Font.Copy>
        </TextBlock>
      </div>
      <ContentBlock>
        <StaffLanguages languages={[packetLanguage]} />
      </ContentBlock>
      <div>
        <ButtonBlock justify="right">
          <Button onClick={reassignPacket}>{t('Reassign')}</Button>
        </ButtonBlock>
      </div>
    </div>
  )
}
