import { useCallback, useState } from 'react'

const useControlled = ({
  default: defaultValue,
  value: controlled,
  onChange: setControlled,
}) => {
  const [uncontrolled, setUncontrolled] = useState(defaultValue)

  const isControlled = controlled => controlled !== undefined

  const setState = useCallback(
    value => {
      if (typeof setControlled === 'function') {
        setControlled(value)
      }

      setUncontrolled(value)
    },
    [controlled, setControlled]
  )

  const state = isControlled(controlled) ? controlled : uncontrolled

  return [state, setState]
}

export default useControlled
