import React from 'react'
import {
  Icon,
  ButtonBlock,
  Button,
  Font,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { FormattedData } from 'components'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { formatDisplayName } from 'utils/formatting'
import styles from './ResponseContainer.module.scss'

const cx = classNames.bind(styles)

const ResponseContainer = ({
  reviewIsLoading,
  responses,
  responseNum,
  setResponseNum,
  selectRespondent,
  selectTurf,
  answersPage,
  totalAnswers,
  setAnswersPage,
  answersPerPage,
  showArchived,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles['response-container__wrapper']}>
      {responses?.length ? (
        <>
          <div className={styles['response-container__topbar']}>
            <div className={styles['response-container__topbar__column']}>
              <Font.Label>{t('Responses')}</Font.Label>
              <div
                className={styles['response-container__topbar__filter-text']}
                onClick={selectRespondent}
              >
                {formatDisplayName(responses[responseNum][0].user)}
              </div>
            </div>
            <div className={styles['response-container__topbar__column']}>
              <div>
                <Button.Secondary
                  disabled={responseNum <= 0}
                  onClick={() => setResponseNum(prev => prev - 1)}
                >
                  <Icon.ChevronLeft />
                </Button.Secondary>
                <Font.Copy
                  variant="hint"
                  className={styles['response-container__response-num']}
                >
                  {t('Answer')} {responseNum + 1} {t('of')} {responses.length}
                </Font.Copy>
                <Button.Secondary
                  disabled={responseNum >= responses.length - 1}
                  onClick={() => setResponseNum(prev => prev + 1)}
                >
                  <Icon.ChevronRight />
                </Button.Secondary>
              </div>
              <div>
                <Font.Copy>
                  {t('Responded on')}{' '}
                  <FormattedData
                    type="date"
                    value={responses[responseNum][0].created_at}
                  />
                </Font.Copy>
              </div>
            </div>
          </div>
          {[
            ...responses[responseNum]
              .filter(r => !r.question.archived)
              .sort((a, b) => a.question.position - b.question.position),
            ...responses[responseNum].filter(r => r.question.archived),
          ].map(
            (response, i) =>
              ((showArchived && response.question.archived) ||
                !response.question.archived) && (
                <div
                  className={cx('response-container__card', {
                    'response-container__card--last':
                      i === responses[responseNum].length - 1,
                  })}
                >
                  <div className={styles['response-container__card__question']}>
                    <Font.Copy>
                      {response.question.archived ? (
                        <>
                          <em
                            className={
                              styles[
                                'response-container__card__question--archived'
                              ]
                            }
                          >
                            {response.question.text}
                          </em>{' '}
                          (archived question)
                        </>
                      ) : (
                        response.question.text
                      )}
                    </Font.Copy>
                    <div
                      className={
                        styles[
                          'response-container__card__question__turf-filter-text'
                        ]
                      }
                      onClick={() => selectTurf(response.turf.id)}
                    >
                      {response.turf.name}
                    </div>
                  </div>
                  <div className={styles['response-container__card__answer']}>
                    <Font.Copy variant="reading-block">
                      {response.text}
                    </Font.Copy>
                  </div>
                </div>
              )
          )}
          {answersPage * answersPerPage < totalAnswers && (
            <ButtonBlock>
              <Button onClick={() => setAnswersPage(oldPage => oldPage + 1)}>
                {t('Load more')}
              </Button>
            </ButtonBlock>
          )}
        </>
      ) : (
        <TextBlock>
          <Font.Copy>
            {!reviewIsLoading
              ? t(
                  'No responses were found with your current filters or search criteria.'
                )
              : `${t('Loading responses')}...`}
          </Font.Copy>
        </TextBlock>
      )}
    </div>
  )
}

export default ResponseContainer
