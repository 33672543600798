import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select/creatable'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './ZipCodeMultiSelectField.module.scss'

const cx = classNames.bind(styles)

const ZIP_LENGTH = 5

const buildOption = label => ({
  label,
  value: label,
})

const ZipCodeMultiSelectFieldDEPRECATED = ({
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  required,
  clearable,
  error,
  errorText,
  className,
}) => {
  const { t } = useTranslation()

  const [entries, setEntries] = useState(value || [])
  const [input, setInput] = useState('')
  const [isFocused, setFocused] = useState(false)
  const [isPristine, setPristine] = useState(true)
  const selectRef = useRef()

  const addEntry = val => {
    if (!entries.find(entry => entry === val)) {
      onChange && onChange([...entries.map(({ value }) => value), val])
    }
  }

  const handleInputChange = val => {
    setInput(val.replace(/\D/g, '').slice(0, 5))
  }

  const focusSelect = () => {
    selectRef.current.focus()
  }

  const handleFocus = () => {
    setFocused(true)
    onFocus && onFocus()
  }

  const handleBlur = () => {
    setFocused(false)
    setPristine(false)

    if (input.length === ZIP_LENGTH) {
      addEntry(input)
    }

    setInput('')
    onBlur && onBlur()
  }

  const handleKeyDown = event => {
    if (!input || input === '') return

    if (input.length === ZIP_LENGTH) {
      addEntry(input)
      setInput('')
    }

    if (/\d/.test(event.key) && input.length === ZIP_LENGTH - 1) {
      addEntry(`${input}${event.key}`)
      setInput('')
      event.preventDefault()
    }
  }

  const hasValue = value?.length || entries?.length

  const fieldError = (!isPristine && required && !hasValue) || error
  const fieldErrorText =
    fieldError && required && !hasValue ? t('Required') : errorText

  useEffect(() => {
    setEntries(value.map(buildOption))
  }, [value])

  const handleMultiChange = selections => {
    onChange && onChange((selections || []).map(({ value }) => value))
  }

  return (
    <div className={styles.container}>
      <div
        className={cx('field', className, {
          'field--focused': isFocused,
          'field--error': fieldError,
          'field--has-value': hasValue,
        })}
      >
        <div className={styles.label} tabIndex="-1" onFocus={focusSelect}>
          {[label, required && '*'].filter(Boolean).join(' ')}
        </div>
        <Select
          ref={selectRef}
          isClearable={!!clearable}
          isDisabled={disabled}
          onKeyDown={handleKeyDown}
          onChange={handleMultiChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInputChange={handleInputChange}
          inputValue={input}
          value={entries}
          classNamePrefix="ReactCreatableSelect"
          menuIsOpen={false}
          components={{
            DropdownIndicator: null,
          }}
          isMulti
        />
      </div>
      {fieldError && <div className={styles.error}>{fieldErrorText}</div>}
    </div>
  )
}

ZipCodeMultiSelectFieldDEPRECATED.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
}

ZipCodeMultiSelectFieldDEPRECATED.defaultProps = {
  disabled: false,
  required: false,
  clearable: false,
  error: false,
  errorText: '',
}

export default ZipCodeMultiSelectFieldDEPRECATED
