import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from 'assets/images/logo.svg'
import { ContentBlock, Font } from '@politechdev/blocks-design-system'
import { useAuth } from 'contexts'
import LockedNotice from '../LockedNotice/LockedNotice'
import LoginForm from '../LoginForm/LoginForm'
import styles from './Login.module.scss'

const Login = () => {
  const { t } = useTranslation()
  const { locked } = useAuth()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.backdrop} />
        <Font.Display className={styles.title}>
          {t('Blocks is your tool for the next generation of campaigning')}
        </Font.Display>
        <Font.Copy variant="highlight" className={styles.message}>
          {t(
            'It combines the data entry, reporting, analytic, and program management tools you need into one easy to use platform'
          )}
        </Font.Copy>
      </div>
      <div className={styles.panel}>
        <ContentBlock>
          <img src={logo} alt="logo" className={styles.logo} />
        </ContentBlock>
        <div className={styles.form}>
          {locked ? <LockedNotice /> : <LoginForm />}
        </div>
      </div>
    </div>
  )
}
export default Login
