import React from 'react'
import PropTypes from 'prop-types'
import { Button as ReactMDButton } from 'react-md'
import classNames from 'classnames/bind'
import styles from './Button.module.scss'

const cx = classNames.bind(styles)

const Button = ({ accent, warn, className, ...props }) => (
  <ReactMDButton
    className={cx(
      'button',
      {
        'button--icon': props.icon,
        'button--floating': props.floating,
        'button--warn': warn,
        'button--default':
          !props.floating &&
          !props.raised &&
          !props.primary &&
          !props.icon &&
          !warn &&
          !accent,
      },
      className
    )}
    {...props}
    flat={props.flat && !props.floating && !props.raised && !props.icon}
    secondary={accent === true}
  />
)

Button.propTypes = {
  flat: PropTypes.bool,
  raised: PropTypes.bool,
  primary: PropTypes.bool,
  accent: PropTypes.bool,
  disabled: PropTypes.bool,
  floating: PropTypes.bool,
  icon: PropTypes.bool,
  svg: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
}

Button.defaultProps = {
  flat: true,
  raised: false,
  primary: false,
  accent: false,
  disabled: false,
  floating: false,
  icon: false,
  svg: false,
  className: '',
  onClick: null,
  href: '',
}

export default Button
