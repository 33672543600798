import jwtDecode from 'jwt-decode'

const localStorageKey = 'blocks_token_storage'

const getToken = () => window.localStorage.getItem(localStorageKey)

const setToken = token => window.localStorage.setItem(localStorageKey, token)

const removeToken = () => window.localStorage.removeItem(localStorageKey)

const decodeToken = token => {
  try {
    return {
      ...jwtDecode(token),
      token,
    }
  } catch {
    return null
  }
}

export { getToken, setToken, removeToken, decodeToken }
