import React, { useContext } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  Section,
  DateField,
  ContentBlock,
  FieldBlock,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { ManagementContext } from 'checkins/CheckinContext/ManagementContext'
import SchedulePicker from '../SchedulePicker/SchedulePicker'
import styles from './DateControls.module.scss'

const DateControls = () => {
  const { t } = useTranslation()

  const { newCheckinPreview, previewNewCheckIn } = useContext(ManagementContext)

  const noEndDateToggle = () => {
    !newCheckinPreview.end_date
      ? previewNewCheckIn({
          end_date: moment().add(10, 'days').format(),
        })
      : previewNewCheckIn({ end_date: null })
  }

  return (
    <Section className={styles.container}>
      <ContentBlock className={styles.container__element}>
        <SchedulePicker />
      </ContentBlock>
      <FieldBlock className={styles.container__element}>
        <DateField
          label={t('End date')}
          value={newCheckinPreview.end_date}
          onChange={end_date => previewNewCheckIn({ end_date })}
          disabled={!newCheckinPreview.end_date}
        />
      </FieldBlock>
      <FieldBlock className={styles.container__element}>
        <Checkbox
          id="no-end-date"
          checked={!newCheckinPreview.end_date}
          label={t('No end date')}
          onChange={noEndDateToggle}
        />
      </FieldBlock>
    </Section>
  )
}

export default DateControls
