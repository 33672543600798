import { useCurrent } from 'contexts'
import { useTranslation } from 'react-i18next'
import { Temporal } from 'temporal-polyfill'

function parseInstant(dateTime, defaultZone = 'etc/UTC') {
  try {
    return Temporal.Instant.from(dateTime)
  } catch {
    return Temporal.PlainDateTime.from(dateTime)
      .toZonedDateTime(defaultZone)
      .toInstant()
  }
}

export const Timestamp = ({
  dateTime,
  timezone,
  formatOptions = { dateStyle: 'medium', timeStyle: 'short' },
  ...timeProps
}) => {
  const { i18n } = useTranslation()
  const {
    currentUser: { time_zone: userTimezone },
  } = useCurrent()

  const sourceDatetime = parseInstant(dateTime)

  return (
    <time
      dateTime={sourceDatetime.toString({ smallestUnit: 'seconds' })}
      {...timeProps}
    >
      {sourceDatetime
        .toZonedDateTimeISO(timezone ?? userTimezone)
        .toLocaleString(i18n.language || 'en', {
          ...formatOptions,
        })}
    </time>
  )
}
