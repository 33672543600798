import {
  SelectField,
  FieldBlock,
  Button,
  Icon,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import {
  USER_TYPES,
  STAFF_HOUR_OPTIONS,
  STAFF_MINUTE_OPTIONS,
} from 'constants/qualityControl'
import { useEffect, useState } from 'react'
import styles from './ScheduleItem.module.scss'
import ScheduleUserSelect from './ScheduledUserSelect'

const ScheduleItem = ({
  schedule,
  scheduledUserIds,
  onUpdate,
  removeSchedule,
  nameDisabled,
  userType,
  tooltipPosition = 'right',
}) => {
  const { t } = useTranslation()

  const hoursValue = Math.floor(schedule.minutes / 60)
  const minutesValue = schedule.minutes % 60
  const [hours, setHours] = useState(hoursValue)
  const [minutes, setMinutes] = useState(minutesValue)

  const updateSchedule = value => {
    onUpdate({
      ...schedule,
      user_type: userType,
      ...value,
    })
  }

  useEffect(() => {
    if (hours !== hoursValue || minutes !== minutesValue) {
      updateSchedule({
        minutes: hours * 60 + minutes,
      })
    }
  }, [hours, minutes])

  const userLabel = () => {
    switch (userType) {
      case USER_TYPES.MANAGER: {
        return t('Manager')
      }
      case USER_TYPES.STAFF:
      default: {
        return t('Staff member')
      }
    }
  }

  return (
    <FieldBlock data-test-id="schedule-item">
      <ScheduleUserSelect
        label={userLabel()}
        id={`user_${schedule.localId || 'new'}`}
        user={schedule.user}
        scheduledUserIds={scheduledUserIds}
        disabled={nameDisabled}
        onSelect={user => {
          updateSchedule({
            user,
          })
        }}
      />
      <div className={styles.duration}>
        <SelectField
          className={styles.hours}
          label={t('Hours')}
          id={`hours_${schedule.localId}`}
          value={hoursValue}
          options={STAFF_HOUR_OPTIONS}
          disabled={!schedule.user}
          onSelect={setHours}
          error={schedule.error}
        />
        <SelectField
          label={t('Minutes')}
          id={`minutes_${schedule.localId}`}
          value={minutesValue}
          options={STAFF_MINUTE_OPTIONS}
          disabled={!schedule.user}
          onSelect={setMinutes}
          error={schedule.error}
        />
      </div>
      <Button.Secondary
        tooltipLabel={t('Remove from schedule')}
        tooltipPosition={tooltipPosition}
        onClick={() => removeSchedule(schedule)}
      >
        <Icon.Times />
      </Button.Secondary>
    </FieldBlock>
  )
}

export default ScheduleItem
