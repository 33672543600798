import { useContext, useState } from 'react'
import { Button, Icon } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import { convert } from 'html-to-text'
import draftToHtml from 'draftjs-to-html'
import { useTranslation } from 'react-i18next'
import styles from './TemplateContent.module.scss'
import { TemplateContext } from '../TemplateContext/TemplateContext'
import TemplateAddContent from '../TemplateAddContent/TemplateAddContent'
import TemplateContentCard from '../TemplateContentCard/TemplateContentCard'
import { TEMPLATE_ITEM_OPTIONS } from '../TemplateContext/constants'

const cx = classNames.bind(styles)

const TemplateContent = () => {
  const { content, moveItemUp, moveItemDown, removeItem, setActiveItemIndex } =
    useContext(TemplateContext)

  const { t } = useTranslation()
  const [animatedIndex, setAnimatedIndex] = useState(-1)

  const getContentValue = content => {
    switch (content.type) {
      case TEMPLATE_ITEM_OPTIONS.BODY_COPY: {
        return typeof content.value === 'string'
          ? content.value
          : convert(draftToHtml(content.value))
      }
      case TEMPLATE_ITEM_OPTIONS.BUTTON_LINK: {
        return content.value.name
      }
      case TEMPLATE_ITEM_OPTIONS.IMAGE: {
        return content.value || t('Upload an image')
      }
      default: {
        return content.value
      }
    }
  }

  return (
    <div>
      <TemplateAddContent />
      {content.map((c, i) => (
        <div className={styles.content} key={`${c.type}-${i}`}>
          <TemplateContentCard
            type={c.type}
            value={getContentValue(c)}
            animated={i === animatedIndex}
            clearAnimation={() => setAnimatedIndex(-1)}
            onDelete={() => removeItem(i)}
            onEdit={() => setActiveItemIndex(i)}
          />
          <div
            className={cx('content__buttons', {
              'content__buttons--animated': i === animatedIndex,
            })}
          >
            <Button.Secondary
              className={styles.button}
              disabled={i === 0}
              onClick={() => {
                setAnimatedIndex(i - 1)
                moveItemUp(i)
              }}
            >
              <Icon.ChevronUp aria-label={t('Move up button')} />
            </Button.Secondary>
            <Button.Secondary
              className={styles.button}
              disabled={i === content.length - 1}
              onClick={() => {
                setAnimatedIndex(i + 1)
                moveItemDown(i)
              }}
            >
              <Icon.ChevronDown aria-label={t('Move down button')} />
            </Button.Secondary>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TemplateContent
