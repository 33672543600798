import { ProgressBar } from 'components'

export const StaffBarChart = ({ value }) => {
  const valueFillColors = {
    low: '#48b0f7',
    high: '#f44336',
  }

  return (
    <ProgressBar
      value={value}
      color={value > 75 ? valueFillColors.high : valueFillColors.low}
    />
  )
}
