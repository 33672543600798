import React from 'react'
import PropTypes from 'prop-types'
import { LoadBar } from 'components'
import styles from './View.module.scss'

const View = ({ loading, children }) => (
  <div className={styles.view}>
    <LoadBar show={loading} className={styles.loadbar} />
    {children}
  </div>
)

View.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

View.defaultProps = {
  loading: false,
}

export default View
