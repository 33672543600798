import { Avatar } from 'components'
import {
  createStaffCardData,
  getScansAssignedOutOfShiftHours,
} from 'utils/tableQcManager'
import { TrainedStates } from './TrainedStates'
import { StaffBarChart } from './StaffBarChart'
import { StaffLanguagesVerbose } from './StaffLanguages'
import styles from './StaffAssignmentCard.module.scss'

const StaffAssignmentCard = ({ user }) => {
  const member = createStaffCardData(user)
  const scanCount = member.assignedScansCount

  return (
    <div className={styles.card}>
      <Avatar
        name={member.name}
        imageUrl={member.avatar}
        imageLarge
        subtitle={member.position}
      />
      <TrainedStates trainedStates={member.trainedStates} />
      <p className={styles.timezone} data-testid="staff-assignment-timezone">
        {member.timeZone}
      </p>
      <StaffLanguagesVerbose languages={member.languages} />
      <div className={styles.chart} data-testid="staff-assignment-chart">
        <StaffBarChart value={scanCount / member.shiftHours} />
        <div className={styles.label}>
          <p className="muted">
            {getScansAssignedOutOfShiftHours(scanCount, member.shiftHours)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default StaffAssignmentCard
