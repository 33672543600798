import PropTypes from 'prop-types'
import {
  Button,
  Icon,
  Font,
  TextBlock,
} from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import styles from './Paginator.module.scss'

const cx = classNames.bind(styles)

const Paginator = ({
  currentPage,
  totalPages,
  onNext,
  onPrevious,
  className,
  justify,
  disabled,
  CustomDisplay,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={cx('paginator', className, {
        'paginator--justify-left': justify === 'left',
        'paginator--justify-center': justify === 'center',
      })}
    >
      <Button.Secondary
        onClick={onPrevious}
        disabled={disabled || currentPage < 2}
      >
        <Icon.ArrowLeft />
      </Button.Secondary>
      {CustomDisplay ? (
        <CustomDisplay />
      ) : (
        <TextBlock>
          <Font.Copy element="span">
            {t('Page {{currentPage}} of {{totalPages}}', {
              currentPage,
              totalPages,
            })}
          </Font.Copy>
        </TextBlock>
      )}
      <Button.Secondary
        onClick={onNext}
        disabled={disabled || currentPage >= totalPages}
      >
        <Icon.ArrowRight />
      </Button.Secondary>
    </div>
  )
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default Paginator
