import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { formatErrorMessage, formatAutoTableParams } from 'utils/formatting'
import { destroyCampaign, fetchCampaigns } from 'requests/campaigns'
import { useState } from 'react'
import tableConfig from './tableConfig'

const CampaignTable = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const { renderTable, refreshPage } = useAutoTable()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyCampaign = doesCurrentUserHavePermission({
    resource: 'campaign',
    ability: 'modify',
  })

  const canDeleteCampaign = doesCurrentUserHavePermission({
    resource: 'campaign',
    ability: 'remove',
  })

  const [campaigns, setCampaigns] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const {
    makeRequest: deleteRequest,
    isLoading: isDeleteLoading,
    errors: deleteErrors,
  } = useRequest(destroyCampaign, { onSuccess: refreshPage })

  const {
    makeRequest: fetchAction,
    isLoading: isFetchLoading,
    errors: fetchErrors,
  } = useRequest(
    params =>
      fetchCampaigns({
        ...formatAutoTableParams(params),
        fields: ['id', 'name', 'start_date', 'end_date'],
      }),
    {
      onSuccess: ({ campaigns, meta }) => {
        setCampaigns(campaigns)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const errors = { ...deleteErrors, ...fetchErrors }
  const isLoading = isDeleteLoading || isFetchLoading

  const handleEdit = rowData => {
    history.push(`${match.path}/${rowData.id}/edit`)
  }

  const handleDelete = rowData => {
    deleteRequest(rowData.id)
  }

  const columns = [
    {
      actions: [
        createRowAction(t('Edit'), handleEdit, canModifyCampaign),
        createRowAction(t('Delete'), handleDelete, canDeleteCampaign),
      ].filter(Boolean),
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  const errorMsg = formatErrorMessage(errors)

  return renderTable({
    data: campaigns,
    loading: isLoading,
    error: errorMsg,
    totalRecords,
    columns,
    fetchAction,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
  >
    <CampaignTable />
  </AutoTableProvider>
)
