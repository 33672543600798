import { useContext, useEffect } from 'react'
import { SortArrowButton } from 'components'
import { Filter } from 'qualityControl/inbox/Filter'
import menuItems from 'qualityControl/inbox/menuItems'
import { useTranslation } from 'react-i18next'
import { SORT_OPTIONS } from 'constants/packetFilters'
import { SORT_ORDER } from 'constants'
import styles from './SortFilter.module.scss'
import { QCPacketFilterContext } from '../../QCPacketFilterContext/QCPacketFilterContext'
import { useInbox } from '../../InboxContext'

const SortFilters = () => {
  const { t } = useTranslation()
  const { isPendingPacketsEmpty: isDisabled } = useInbox()

  const {
    sortOrder,
    updateSortDirectionSortOrder,
    selectedSort,
    setSelectedSort,
  } = useContext(QCPacketFilterContext)

  const getSortDirection = selectedSort => {
    switch (selectedSort) {
      case SORT_OPTIONS.DAYS_LEFT:
        return sortOrder.daysLeft
      case SORT_OPTIONS.TIME_ZONE:
        return sortOrder.timeZone
      case SORT_OPTIONS.DAYS_SINCE_COLLECTION:
        return sortOrder.daysSinceCollection
      case SORT_OPTIONS.UNITED_STATES:
        return sortOrder.unitedStates
      default:
        break
    }
  }

  useEffect(() => {
    sortOrder.daysLeft === SORT_ORDER.ASC &&
      setSelectedSort(SORT_OPTIONS.DAYS_LEFT)
    sortOrder.daysSinceCollection === SORT_ORDER.ASC &&
      setSelectedSort(SORT_OPTIONS.DAYS_SINCE_COLLECTION)
    sortOrder.timeZone === SORT_ORDER.ASC &&
      setSelectedSort(SORT_OPTIONS.TIME_ZONE)
    sortOrder.unitedStates === SORT_ORDER.ASC &&
      setSelectedSort(SORT_OPTIONS.UNITED_STATES)
  }, [])

  return (
    <div
      className={
        selectedSort !== '' && selectedSort !== 'None' ? styles.container : ''
      }
    >
      <Filter
        id="sort_filter"
        label={t('Sort by')}
        value={selectedSort}
        onChange={setSelectedSort}
        menuItems={menuItems.sortByList}
        isDisabled={isDisabled}
      />
      {selectedSort !== '' && selectedSort !== 'None' && (
        <SortArrowButton
          className={styles.button}
          direction={getSortDirection(selectedSort)}
          toggleDirection={() => updateSortDirectionSortOrder(selectedSort)}
        />
      )}
    </div>
  )
}

export default SortFilters
