import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from 'components'
import { useTranslation } from 'react-i18next'
import { maskPhone, unmaskPhone } from 'utils/inputMasks'

const VALID_PHONE_LENGTH = 10

const PhoneField = ({
  id,
  value,
  error,
  errorText,
  onChange,
  label,
  onKeyPress,
  onEnterKeyPress,
  ...props
}) => {
  const { t } = useTranslation()

  const fieldError =
    (value?.length && value.length !== VALID_PHONE_LENGTH) || error
  const fieldErrorText =
    value?.length && fieldError ? t('Number must contain 10 digits') : errorText

  const handleKeyPress = event => {
    onKeyPress && onKeyPress(event)
    if (onEnterKeyPress && event.key === 'Enter' && !fieldError) {
      onEnterKeyPress()
    }
  }

  return (
    <TextField
      type="tel"
      id={id || 'phone_number'}
      label={label !== undefined ? label : t('Phone number')}
      value={maskPhone(value)}
      error={fieldError}
      errorText={fieldErrorText}
      onChange={val => {
        onChange(unmaskPhone(val).substring(0, VALID_PHONE_LENGTH))
      }}
      onKeyPress={handleKeyPress}
      {...props}
    />
  )
}

PhoneField.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
}

PhoneField.defaultProps = {
  error: false,
  errorText: '',
  onKeyPress: null,
  onEnterKeyPress: null,
}

export default PhoneField
