import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ChipBlock,
  ScrollToTop,
  OrganizationSelectField,
  TeamSelectField,
  ResponsibilitySelectField,
  DenominationSelectField,
  CustomFields,
} from 'components'
import {
  Button,
  Icon,
  ButtonBlock,
  FieldBlock,
  SelectField,
  TextField,
  Section,
} from '@politechdev/blocks-design-system'
import { interestLevelOptions } from 'constants/people'
import { buildDenomination } from 'components/DenominationSelectField/utils'
import classNames from 'classnames/bind'
import { useCurrent } from 'contexts/index'
import { useRequest } from 'hooks/useRequest'
import { fetchPositionOptions } from 'requests/people'
import styles from './NewPersonFormAdditional.module.scss'

const cx = classNames.bind(styles)

const NewPersonFormAdditional = ({
  currentUserId,
  updatePersonState,
  newPerson,
  errors,
}) => {
  const [newPosition, setNewPosition] = useState('')
  const [positionOptions, setPositionOptions] = useState([])
  const [additonalPositionOptions, setAdditionalPositionOptions] = useState([])
  const { t } = useTranslation()

  const {
    tenantOptions: { uses_interest_level: usesInterestLevel },
    showDenominations,
    currentTurfActivismIssues: issues,
    currentTurfActivismSkills: skills,
    currentTurfActivismLanguages: languages,
    getCustomFieldsForTable,
  } = useCurrent()

  const customFieldsForPeople = getCustomFieldsForTable('people')

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: makePositionOptionReq,
    isLoading: positionOptionsLoading,
  } = useRequest(fetchPositionOptions, {
    onSuccess: ({ positions }) =>
      setPositionOptions(
        positions.map(position => ({ label: position, value: position }))
      ),
  })

  const updateTeamMembership = (teamIndex, value) => {
    updatePersonState({
      newPerson: {
        ...newPerson,
        team_memberships: newPerson.team_memberships.map(
          (teamMembership, index) =>
            index === teamIndex
              ? {
                  ...teamMembership,
                  ...value,
                }
              : teamMembership
        ),
      },
    })
  }

  const removeTeamMembership = index => {
    if (newPerson.team_memberships.length === 1) {
      updatePersonState({
        newPerson: {
          ...newPerson,
          team_memberships: [{}],
        },
      })
    } else {
      updatePersonState({
        newPerson: {
          ...newPerson,
          team_memberships: newPerson.team_memberships.filter(
            teamMembershipsAttribute =>
              teamMembershipsAttribute !== newPerson.team_memberships[index]
          ),
        },
      })
    }
  }

  useEffect(() => {
    makePositionOptionReq()
  }, [])

  return (
    <>
      <ScrollToTop />
      <form>
        <Section label={t('Memberships')}>
          {newPerson.team_memberships.map((membership, index) => (
            <FieldBlock key={index}>
              <TeamSelectField
                id={`team-select-${index}`}
                onSelect={team => {
                  if (team) {
                    updateTeamMembership(index, {
                      team,
                    })
                  }
                }}
                excludedOptions={newPerson.team_memberships
                  .filter(({ team }) => team?.id !== membership.team?.id)
                  .map(({ team }) => team?.id)}
                team={membership.team}
              />
              <ResponsibilitySelectField
                id={`responsibility-select-${index}`}
                onSelect={responsibility =>
                  updateTeamMembership(index, {
                    responsibility,
                  })
                }
                disabled={!membership.team?.id}
                label={t('Team role')}
                responsibility={membership.responsibility}
              />
              <Button.Secondary
                aria-label={t('Delete')}
                onClick={() => removeTeamMembership(index)}
              >
                <Icon.Times />
              </Button.Secondary>
            </FieldBlock>
          ))}
          <ButtonBlock>
            <Button
              aria-label={t('Add team')}
              onClick={() => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    team_memberships: [...newPerson.team_memberships, {}],
                  },
                })
              }}
            >
              <Icon.Plus />
            </Button>
          </ButtonBlock>
          <FieldBlock>
            <OrganizationSelectField
              label={t('Organizations')}
              organizations={newPerson.organization_memberships.map(
                ({ name, organization_id }) => ({ name, id: organization_id })
              )}
              isMulti
              onSelect={organizations =>
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    organization_memberships: organizations.map(
                      ({ name, id }) => ({
                        name,
                        organization_id: id,
                      })
                    ),
                  },
                })
              }
            />
          </FieldBlock>
        </Section>
        <Section label={t('Aptitudes')}>
          <FieldBlock>
            <SelectField
              id="primary_language"
              label={t('Primary language')}
              value={newPerson.primary_language || ''}
              options={languages.map(language => ({
                label: language,
                value: language,
              }))}
              onSelect={primary_language => {
                updatePersonState({
                  newPerson: { ...newPerson, primary_language },
                })
              }}
            />
          </FieldBlock>
          {newPerson.position === 'new' || newPosition ? (
            <div>
              <FieldBlock>
                <TextField
                  id="new-position"
                  label={t('New position')}
                  value={newPosition}
                  onChange={setNewPosition}
                />
              </FieldBlock>
              <ButtonBlock className={cx('position-button-block')}>
                <Button.Primary
                  onClick={() => {
                    updatePersonState({
                      newPerson: {
                        ...newPerson,
                        position: newPosition,
                      },
                    })
                    setAdditionalPositionOptions(currentOptions => [
                      ...currentOptions,
                      {
                        label: newPosition,
                        value: newPosition,
                      },
                    ])
                    setNewPosition('')
                  }}
                >
                  {t('Create')}
                </Button.Primary>
                <Button.Secondary
                  onClick={() => {
                    updatePersonState({
                      newPerson: {
                        ...newPerson,
                        position: '',
                      },
                    })
                    setNewPosition('')
                  }}
                >
                  Cancel
                </Button.Secondary>
              </ButtonBlock>
            </div>
          ) : (
            <FieldBlock>
              <SelectField
                id="position"
                label={t('Position')}
                value={newPerson.position}
                loading={positionOptionsLoading}
                options={[
                  ...positionOptions,
                  ...additonalPositionOptions,
                  { label: 'ADD NEW POSITION', value: 'new' },
                ]}
                onSelect={position =>
                  updatePersonState({
                    newPerson: {
                      ...newPerson,
                      position,
                    },
                  })
                }
              />
            </FieldBlock>
          )}
          {usesInterestLevel ? (
            <FieldBlock>
              <SelectField
                id="interest-level"
                label={t('Interest Level')}
                value={newPerson.interest_level || ''}
                options={interestLevelOptions}
                onSelect={interest_level => {
                  updatePersonState({
                    newPerson: { ...newPerson, interest_level },
                  })
                }}
              />
              {newPerson.interest_level && (
                <Button
                  onClick={() =>
                    updatePersonState({
                      newPerson: { ...newPerson, interest_level: null },
                    })
                  }
                  aria-label={t('Clear')}
                >
                  <Icon.Times />
                </Button>
              )}
            </FieldBlock>
          ) : null}
          {showDenominations ? (
            <FieldBlock>
              <DenominationSelectField
                denominations={newPerson.denominations}
                onChange={denominations =>
                  updatePersonState({
                    newPerson: {
                      ...newPerson,
                      denominations: denominations.map(buildDenomination),
                    },
                  })
                }
              />
            </FieldBlock>
          ) : null}
          <ChipBlock
            label={t('Other languages spoken')}
            chipType="language"
            choices={languages.filter(
              language =>
                !newPerson.languages.includes(language) &&
                language !== newPerson.primary_language
            )}
            selectedChips={newPerson.languages}
            removeChip={(removeLanguage, removeIndex) => {
              updatePersonState({
                newPerson: {
                  ...newPerson,
                  languages: newPerson.languages.filter(
                    (language, index) => index !== removeIndex
                  ),
                },
              })
            }}
            addChip={language =>
              updatePersonState({
                newPerson: {
                  ...newPerson,
                  languages: [...newPerson.languages, language],
                },
              })
            }
          />
          <ChipBlock
            label={t('Activism')}
            chipType="issue"
            choices={issues.filter(issue => !newPerson.issues.includes(issue))}
            selectedChips={newPerson.issues}
            removeChip={(removeIssue, removeIndex) =>
              updatePersonState({
                newPerson: {
                  ...newPerson,
                  issues: newPerson.issues.filter(
                    (issue, index) => index !== removeIndex
                  ),
                },
              })
            }
            addChip={newIssue =>
              updatePersonState({
                newPerson: {
                  ...newPerson,
                  issues: [...newPerson.issues, newIssue],
                },
              })
            }
          />
          <ChipBlock
            label={t('Skills')}
            chipType="skill"
            choices={skills.filter(skill => !newPerson.skills.includes(skill))}
            selectedChips={newPerson.skills}
            removeChip={(removeSkill, removeIndex) =>
              updatePersonState({
                newPerson: {
                  ...newPerson,
                  skill_list: newPerson.skills.filter(
                    (skill, skillIndex) => skillIndex !== removeIndex
                  ),
                },
              })
            }
            addChip={newSkill =>
              updatePersonState({
                newPerson: {
                  ...newPerson,
                  skills: [...newPerson.skills, newSkill],
                },
              })
            }
          />
        </Section>
        {!!customFieldsForPeople.length && (
          <Section label={t('Additional fields')}>
            <FieldBlock>
              <CustomFields
                onChange={(attr, value) => {
                  const customFieldData = newPerson.custom_field_data
                  updatePersonState({
                    newPerson: {
                      ...newPerson,
                      custom_field_data: {
                        ...customFieldData,
                        [attr]: value,
                      },
                    },
                  })
                }}
                fields={customFieldsForPeople}
                currentData={newPerson.custom_field_data}
              />
            </FieldBlock>
          </Section>
        )}
        <Section label={t('Notes')}>
          <FieldBlock variant="large">
            <TextField
              id="new-note"
              label={t('Add note')}
              maxLength={200}
              value={newPerson.notes[0].content}
              onChange={content => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    notes: [{ content, created_by_user_id: currentUserId }],
                  },
                })
              }}
            />
          </FieldBlock>
        </Section>
        <Section label={t('External')}>
          <FieldBlock>
            <TextField
              id="external-id"
              label={t('External ID')}
              value={newPerson.external_id}
              onChange={external_id => {
                updatePersonState({
                  newPerson: {
                    ...newPerson,
                    external_id,
                  },
                })
              }}
              error={!!errors?.external_id}
              errorText={errors?.external_id}
            />
          </FieldBlock>
        </Section>
      </form>
    </>
  )
}

export default NewPersonFormAdditional
