export const buildOption = denomination => ({
  value: denomination,
  label: denomination,
})

export const buildDenomination = option => option.value

export const buildParams = ({ query, filters = [] }) => ({
  filters: {
    rules: [
      query && query !== ''
        ? {
            rules: [
              {
                column: 'description',
                operator: 'containing',
                param: query,
              },
            ],
            conjunction: 'inclusive',
          }
        : undefined,
      ...filters,
    ],
    conjunction: 'exclusive',
  },
  sort_attr: 'description',
  sort_dir: 'asc',
  per: 25,
  indexed: true,
})
