import { useTranslation } from 'react-i18next'
import { CardError, LoadBar, Button, ButtonBlock } from 'components'
import { useAutoTable } from 'contexts'
import { useRequest } from 'hooks/useRequest'
import { formatErrorMessage } from 'utils/formatting'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { putPacket } from 'requests/tableQcManager'
import styles from './StaffItemAssignButton.module.scss'

const StaffItemAssignButton = ({
  staffId,
  disabled = false,
  refreshAssignedPackets = () => {},
}) => {
  const { t } = useTranslation()
  const { refreshPage } = useAutoTable()

  const {
    setIsReassignmentModalOpen,
    packetsBeingAssigned,
    getScheduledUsers,
  } = usePacketAssignment()

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(
    scheduledUserId => {
      const requests = packetsBeingAssigned.map(packet =>
        putPacket(packet.id, {
          assignee_id: scheduledUserId,
        })
      )

      return Promise.all(requests)
    },
    {
      onSuccess: () => {
        refreshPage()
        getScheduledUsers()
        setIsReassignmentModalOpen(false)
        refreshAssignedPackets()
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  return (
    <>
      <ButtonBlock justify="right">
        <Button
          raised
          primary
          disabled={disabled}
          onClick={() => {
            makeRequest(staffId)
          }}
        >
          {t('Assign')}
        </Button>
      </ButtonBlock>
      <LoadBar show={isLoading} />
      <CardError
        className={styles['error-message']}
        hide={!hasErrors}
        message={errorMsg}
      />
    </>
  )
}

export default StaffItemAssignButton
