import {
  SectionLabel,
  CardError,
  LoadBar,
  Section,
  TextBlock,
} from 'components'
import { useReactRouter, useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { defaultFlagParams } from 'requests/flags'
import styles from './FlagSingleAssociatedFlagsList.module.scss'
import { useCurrentFlagContext } from '../contexts/CurrentFlagContext'

const FlagSingleAssociatedFlagsList = () => {
  const { match, history } = useReactRouter()
  const { t } = useTranslation()

  const { currentFlag, fetchAssociatedFlags } = useCurrentFlagContext()

  const associatedFlagsRequest = useRequest(fetchAssociatedFlags)

  useEffect(() => {
    if (currentFlag) associatedFlagsRequest.makeRequest(defaultFlagParams)
  }, [currentFlag?.id])

  const associatedFlags =
    associatedFlagsRequest.response?.['quality_control/flags'] || []

  return (
    <Section>
      <div className={styles.header}>
        <TextBlock>
          <SectionLabel>
            {associatedFlagsRequest.isLoading || associatedFlags.length !== 0
              ? t('Other technical flags on this packet')
              : t('No other technical flags found on this packet')}
          </SectionLabel>
        </TextBlock>
      </div>
      <CardError hide={!associatedFlagsRequest.hasErrors} />
      <LoadBar show={associatedFlagsRequest.isLoading} />
      {associatedFlags.map(associatedFlag => (
        <div
          className={styles.wrapper}
          key={`associated-flag-${associatedFlag.id}`}
        >
          <span className={styles.id}>{`Flag ${associatedFlag.id}`}</span>
          <span
            className={styles.flagName}
            onClick={() =>
              history.push(
                `/quality_control/flags/${match.params.type}/${associatedFlag.id}/${match.params.originalType}`
              )
            }
          >
            {associatedFlag.trigger.name}
          </span>
          <span className={styles.actionPlan}>
            {associatedFlag.action_plan}
          </span>
        </div>
      ))}
    </Section>
  )
}

export default FlagSingleAssociatedFlagsList
