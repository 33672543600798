import { useContext } from 'react'
import {
  Sheet,
  SelectField,
  TextBlock,
  Font,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import styles from './TemplateFontCard.module.scss'
import { TemplateContext } from '../TemplateContext/TemplateContext'

const TemplateFontCard = () => {
  const { t } = useTranslation()
  const { fontFamily, updateFontFamily } = useContext(TemplateContext)
  const FONT_OPTIONS = [
    { label: t('Arial'), value: 'Arial' },
    { label: t('Veranda'), value: 'Veranda' },
  ]
  return (
    <div className={styles.card}>
      <Sheet>
        <div className={styles.card__title}>
          <Font.Label>{t('Font')}</Font.Label>
        </div>
        <TextBlock className={styles.card__content}>
          <div className={styles.card__content__text}>
            <SelectField
              value={fontFamily}
              options={FONT_OPTIONS}
              onSelect={updateFontFamily}
            />
          </div>
        </TextBlock>
      </Sheet>
    </div>
  )
}

export default TemplateFontCard
