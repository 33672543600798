import { useState } from 'react'
import {
  Button,
  Radio,
  ButtonBlock,
  ContentBlock,
  Font,
  FieldBlock,
  TextField,
  Modal,
} from '@politechdev/blocks-design-system'
import {
  nonParticipationOptions,
  NON_PARTICIPATION_VALUES,
} from 'constants/phoneBankSessions'
import { useTranslation } from 'react-i18next'
import useEvent from 'hooks/useEvent'
import { useRequest } from 'hooks/useRequest'
import { putPerson } from 'requests/people'
import styles from './SessionResponses.module.scss'
import {
  AGENT_STATUS,
  useAgentActions,
  useAgentState,
} from '../AgentContext/AgentContext'

const SessionResponses = () => {
  const [isDidNotParticipateModalOpen, setIsDidNotParticipateModalOpen] =
    useState(false)
  const [notes, setNotes] = useState()
  const { t } = useTranslation()
  const { call, status, participant } = useAgentState()
  const { resetReducerState, submitResponses } = useAgentActions()
  const { participated, non_participation_reason, responses } = call
  const { updateParticipation, updateNonParticipationReason, setStatusReady } =
    useAgentActions()
  const [isInvalidNumberModalOpen, setIsInvalidNumberModalOpen] =
    useState(false)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updatePersonReq } = useRequest(putPerson)

  const submit = useEvent(async () => {
    const isInvalidNumberSelected =
      call.non_participation_reason === NON_PARTICIPATION_VALUES.WRONG_NUMBER ||
      call.non_participation_reason === NON_PARTICIPATION_VALUES.DISCONNECTED

    if (isInvalidNumberSelected) {
      const calledNumber = participant.primary_phone_number

      const updatedContactMethods = participant.contact_methods.map(method =>
        method.content.includes(calledNumber)
          ? { ...method, invalid: true }
          : method
      )
      updatePersonReq(participant.id, {
        contact_methods: updatedContactMethods,
      })
    }

    submitResponses({
      ...call,
      notes,
    })
    setNotes('')
    resetReducerState()
    setStatusReady()
  })

  const showParticipationPicker =
    status === AGENT_STATUS.ONCALL || status === AGENT_STATUS.REVIEW
  const showSubmitButton = status === AGENT_STATUS.REVIEW

  const participationComplete = participated === true && responses.length
  const nonParticipationComplete =
    participated === false && non_participation_reason
  const canSubmit = participationComplete || nonParticipationComplete

  if (!showParticipationPicker) return null

  const participatedOptions = [
    {
      label: t('Participated'),
      value: true,
    },
    {
      label: t('Did not participate'),
      value: false,
      onClick: () => setIsDidNotParticipateModalOpen(true),
    },
  ]
  return (
    <form>
      <ContentBlock>
        <label htmlFor="participated-select">{t('Status')}</label>
        <Radio.Group
          id="participated-select"
          name="participated"
          className={styles.offset}
          defaultValue={null}
          options={participatedOptions}
          onChange={updateParticipation}
          value={participated}
        />
      </ContentBlock>
      <Modal
        id="did-not-participate-modal"
        title={t('Did not participate')}
        isOpen={isDidNotParticipateModalOpen}
      >
        <Modal.Body>
          <ContentBlock>
            <label htmlFor="non-participation-reason-select">
              {t('Reason')}
            </label>
            <Radio.Group
              id="non-participation-reason-select"
              name="non-participation-reason"
              className={styles.offset}
              defaultValue={null}
              options={nonParticipationOptions}
              onChange={updateNonParticipationReason}
              value={non_participation_reason}
            />
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary
              onClick={() => {
                updateParticipation(null)
                updateNonParticipationReason(null)
                setIsDidNotParticipateModalOpen(false)
              }}
            >
              {t('Cancel')}
            </Button.Secondary>
            <Button
              onClick={() => {
                setIsDidNotParticipateModalOpen(false)
              }}
            >
              {t('Select reason')}
            </Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
      <Modal
        id="is-number-invalid-modal"
        title={t('Is this number invalid?')}
        isOpen={isInvalidNumberModalOpen}
      >
        <Modal.Body>
          <ContentBlock>
            <Font.Copy>
              {t('Once submitted this number will be marked as invalid.')}
            </Font.Copy>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary
              onClick={() => {
                setIsInvalidNumberModalOpen(false)
                setIsDidNotParticipateModalOpen(true)
              }}
            >
              {t('Back to reasons')}
            </Button.Secondary>
            <Button
              onClick={() => {
                setIsInvalidNumberModalOpen(false)
              }}
            >
              {t('continue')}
            </Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
      <FieldBlock>
        <TextField
          label={t('Notes (optional)')}
          value={notes}
          onChanges={setNotes}
        />
      </FieldBlock>
      {showSubmitButton && (
        <ButtonBlock>
          <Button.Accent disabled={!canSubmit} onClick={submit}>
            {t('Submit')}
          </Button.Accent>
        </ButtonBlock>
      )}
      {!canSubmit && (
        <ContentBlock>
          <Font.Copy>{t('Enter a response before submitting')}</Font.Copy>
        </ContentBlock>
      )}
    </form>
  )
}

export default SessionResponses
