import moment from 'moment'

export const getPageOptions = weeklyProjectedData => {
  const pageOptions = []
  if (weeklyProjectedData.length > 0) {
    weeklyProjectedData.forEach((week, index) => {
      pageOptions.push({
        value: index,
        label: `Week ${index + 1} | ${moment(week.startDate).format(
          'MMM D'
        )} - ${moment(week.endDate).format('MMM D')}`,
      })
    })
  }
  return { pageOptions }
}
