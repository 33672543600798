import { PERMISSION_OPTIONS } from '../constants/roles'

const findDependencies = (requires = [], abilities, existingIds) =>
  requires.flatMap(requirement => {
    const ability = abilities.find(
      ({ permission, value }) =>
        permission.id === requirement.permission && value === requirement.value
    )

    if (existingIds.includes(ability.id)) return []

    return [
      ability,
      ...findDependencies(ability.requires, abilities, [
        ...existingIds,
        ability.id,
      ]),
    ]
  })

export const getAbilityDependencies = ability =>
  findDependencies(
    ability.requires,
    PERMISSION_OPTIONS.flatMap(permission => permission.abilities),
    [ability.id]
  ).reduce(
    (uniqueRequirements, requirement) =>
      uniqueRequirements.find(unique => unique.id === requirement.id)
        ? uniqueRequirements
        : [...uniqueRequirements, requirement],
    []
  )

const findDependants = (abilityId, abilities, existingIds = []) =>
  abilities
    .filter(ability =>
      (ability.requires || []).find(
        ({ permission, value }) => abilityId === `${permission}.${value}`
      )
    )
    .flatMap(ability => {
      if (existingIds.includes(ability.id)) return []

      return [
        ability,
        ...findDependants(ability.id, abilities, [...existingIds, ability.id]),
      ]
    })

export const getAbilityDependants = ability =>
  findDependants(
    ability.id,
    PERMISSION_OPTIONS.flatMap(permission => permission.abilities)
  ).reduce(
    (uniqueRequirements, requirement) =>
      uniqueRequirements.find(unique => unique.id === requirement.id) ||
      requirement.id === ability.id
        ? uniqueRequirements
        : [...uniqueRequirements, requirement],
    []
  )
