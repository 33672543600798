import { CustomFieldConfig } from 'components/CustomFields/CustomFields.types'
import { useCurrent } from 'contexts'
import { BASE_FILTER_TYPES } from './constants'
import { FilterType } from './types'
import { getFilterTypesFromFieldsConfig, toKeyValueMap } from './utils'

export function useFilterTypes() {
  const { getCustomFieldsForTable } = useCurrent() as {
    getCustomFieldsForTable: (tableName: string) => Array<CustomFieldConfig>
  }

  const customFields = getCustomFieldsForTable('people')

  const customFilters = getFilterTypesFromFieldsConfig(customFields)

  const filterTypes = [...BASE_FILTER_TYPES, ...customFilters]

  const filterTypesMap = filterTypes.reduce(toKeyValueMap<FilterType>, {})

  return { filterTypes, filterTypesMap }
}
