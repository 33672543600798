import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  TextBlock,
  ButtonBlock,
  Modal,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { useContext } from 'react'
import { destroyFolder } from 'requests/folders'
import { useModal } from '../ModalProvider/ModalProvider'
import { MODAL_TYPES } from '../constants'
import styles from './FolderDeleteModal.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const FolderDeleteModal = () => {
  const { t } = useTranslation()
  const [{ isOpen, closeModal }, { folder, onClose }] = useModal(
    MODAL_TYPES.DELETE_FOLDER
  )

  const { getFolders, getCurrentFolder, currentFolder } =
    useContext(DocumentContext)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: foldersRequest, isLoading: foldersLoading } =
    useRequest(getFolders)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: folderRequest, isLoading: folderLoading } =
    useRequest(getCurrentFolder)

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: deleteRequest,
    isLoading: deleteLoading,
    errorMsg: deleteError,
  } = useRequest(destroyFolder, {
    onSuccess: async () => {
      await Promise.all(
        [
          foldersRequest(),
          folder.id === currentFolder.id && folderRequest(folder.id),
        ].filter(Boolean)
      )
      onClose && onClose()
      closeModal()
    },
  })

  const isLoading = foldersLoading || folderLoading || deleteLoading

  if (!folder) return null

  return (
    <Modal
      id={MODAL_TYPES.DELETE_FOLDER}
      title={t(`Delete folder`)}
      isOpen={isOpen}
      showLoading={isLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!deleteError}
            message={deleteError}
            hideSupportLink
          />
          <TextBlock className={styles.text}>
            {t('Are you sure you want to delete')}{' '}
            <strong>{folder.name}</strong>
          </TextBlock>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Danger onClick={() => deleteRequest(folder.id)}>
            {t('Delete')}
          </Button.Danger>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default FolderDeleteModal
