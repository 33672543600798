import { createContext, useState } from 'react'
import { SORT_ORDER } from 'constants'
import { applyPacketFilters, sortPackets } from 'utils/packetFilters'
import { SHOW_ALL } from '../menuItems'

export const QCPacketFilterContext = createContext()

const QCPacketFilterProvider = ({ children }) => {
  const [daysLeft, setDaysLeft] = useState(SHOW_ALL)
  const [timeZone, setTimeZone] = useState(SHOW_ALL)
  const [daysSinceCollection, setDaysSinceCollection] = useState(SHOW_ALL)
  const [selectedState, setSelectedState] = useState(SHOW_ALL)
  const [sortOrder, setSortOrder] = useState({
    daysLeft: SORT_ORDER.DESC,
    timeZone: SORT_ORDER.DESC,
    daysSinceCollection: SORT_ORDER.DESC,
    unitedStates: SORT_ORDER.DESC,
  })
  const [selectedSort, setSelectedSort] = useState('')

  const updateSortDirectionSortOrder = sortKey => {
    const selectedOption = sortOrder[sortKey]
    const nextSortOrder = {
      ...sortOrder,
      [sortKey]:
        selectedOption === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC,
    }
    setSortOrder(nextSortOrder)
  }

  const filterPackets = packets => {
    const filteredPackets = applyPacketFilters(
      packets,
      daysLeft,
      timeZone,
      daysSinceCollection,
      selectedState
    )
    return sortPackets(sortOrder, selectedSort, filteredPackets)
  }

  const resetPacketFilters = () => {
    setDaysLeft(SHOW_ALL)
    setTimeZone(SHOW_ALL)
    setDaysSinceCollection(SHOW_ALL)
    setSelectedState(SHOW_ALL)
  }

  return (
    <QCPacketFilterContext.Provider
      value={{
        daysLeft,
        setDaysLeft,
        timeZone,
        setTimeZone,
        daysSinceCollection,
        setDaysSinceCollection,
        selectedState,
        setSelectedState,
        sortOrder,
        updateSortDirectionSortOrder,
        filterPackets,
        resetPacketFilters,
        selectedSort,
        setSelectedSort,
      }}
    >
      {children}
    </QCPacketFilterContext.Provider>
  )
}

export default QCPacketFilterProvider
