import React from 'react'
import { useDrag } from 'react-dnd'
import { ExpansionList, ExpansionPanel } from 'react-md'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useFilterTypes } from 'lists/useFilterTypes'
import { FILTER_SECTIONS } from '../constants'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import { FilterCardHeader } from '../FilterCard/FilterCard'
import styles from './FilterSelectionZone.module.scss'

const cx = classNames.bind(styles)

const FilterSelectionHandle = ({ filterType, groupId }) => {
  const [{ isDragging }, dragRef] = useDrag({
    item: { id: filterType.id, type: `${filterType.id}-${groupId}` },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return (
    <div
      ref={dragRef}
      className={cx('handle', {
        'handle--isDragging': isDragging,
      })}
    >
      <FilterCardHeader filterType={filterType} />
    </div>
  )
}

const FilterSelectionSection = ({ section, ...props }) => {
  const { t } = useTranslation()
  const { group, availableFilters } = useListFilters()

  const sectionFilters = availableFilters.filter(
    filterType => filterType.sectionId === section.id
  )

  return (
    <ExpansionPanel label={t(section.name)} footer={null} {...props}>
      {sectionFilters.length ? (
        sectionFilters.map(filterType => (
          <FilterSelectionHandle
            key={filterType.id}
            groupId={group.id}
            filterType={filterType}
          />
        ))
      ) : (
        <span className={styles.empty}>{t('All filters in use')}</span>
      )}
    </ExpansionPanel>
  )
}

const FilterSelectionZone = () => {
  const { filterTypes } = useFilterTypes()

  return (
    <div className={styles.zone}>
      <span className={styles.instructions}>
        Browse & drag filters to apply it to your list
      </span>
      <ExpansionList>
        {FILTER_SECTIONS.map(section =>
          filterTypes.some(filter => filter.sectionId === section.id) ? (
            <FilterSelectionSection key={section.id} section={section} />
          ) : null
        )}
      </ExpansionList>
    </div>
  )
}

export default FilterSelectionZone
