import React, { useRef, useState, useCallback } from 'react'
import {
  createContext,
  useContext,
  useContextSelector,
} from 'use-context-selector'

const MenuContext = createContext()

export const ContextMenuProvider = ({ children }) => {
  const menus = useRef(new Map()).current
  const [activeMenu, setActiveMenu] = useState(null)

  const register = useCallback((id, actions) => {
    menus.set(id, actions)
  }, [])

  const deregister = useCallback(id => {
    menus.delete(id)
  }, [])

  const open = useCallback((id, event) => {
    if (menus.has(id)) {
      const actions = menus.get(id)
      actions.open && actions.open(event)
      setActiveMenu(id)
    }
  }, [])

  const close = useCallback((id, event) => {
    if (menus.has(id)) {
      const actions = menus.get(id)
      actions.close && actions.close(event)
      setActiveMenu(null)
    }
  }, [])

  const clear = useCallback(() => {
    menus.forEach(actions => {
      actions.close && actions.close()
    })
    setActiveMenu(null)
  }, [])

  return (
    <MenuContext.Provider
      value={{ activeMenu, register, deregister, open, close, clear }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export const useContextMenuEvent = () => useContext(MenuContext)

export const useActiveContextMenu = id =>
  useContextSelector(MenuContext, value => value.activeMenu === id)

export const useContextMenu = params => {
  const id = JSON.stringify(params.id)
  const open = useContextSelector(MenuContext, value => value.open)
  const close = useContextSelector(MenuContext, value => value.close)
  const clear = useContextSelector(MenuContext, value => value.clear)
  const isMenuActive = useActiveContextMenu(id)

  return {
    showMenu: (event = {}) => {
      event.nativeEvent?.stopImmediatePropagation &&
        event.nativeEvent.stopImmediatePropagation()
      event.preventDefault && event.preventDefault()

      clear()
      open(id, event)
    },
    hideMenu: () => {
      close(id)
    },
    hideAllMenus: () => {
      clear()
    },
    isMenuActive,
  }
}
