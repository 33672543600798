import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { useQueryParams } from 'contexts'
import { dateFormat } from 'utils/constants'
import moment from 'moment'

function CsvDownloadButton({
  columnHeaders,
  data,
  filename,
  tooltipLabel,
  tooltipPosition,
  newTab,
  wrapperClassName,
  disabled = false,
}) {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const target = newTab ? '_blank' : '_self'

  return (
    <CSVLink
      className={wrapperClassName}
      filename={`${moment(queryParams.startDate).format(dateFormat)}-${moment(
        queryParams.endDate
      ).format(dateFormat)}-${filename}`}
      headers={columnHeaders}
      data={data}
      target={target}
      onClick={() => !disabled}
    >
      <Button.Secondary
        key="download"
        disabled={disabled}
        tooltipLabel={t(tooltipLabel)}
        tooltipPosition={tooltipPosition}
      >
        <Icon.Download />
      </Button.Secondary>
    </CSVLink>
  )
}

CsvDownloadButton.propTypes = {
  columnHeaders: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, key: PropTypes.string })
  ),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filename: PropTypes.string,
  tooltipLabel: PropTypes.string,
  tooltipPosition: PropTypes.string,
  newTab: PropTypes.bool,
  wrapperClassName: PropTypes.string,
}

CsvDownloadButton.defaultProps = {
  columnHeaders: undefined,
  data: [],
  filename: 'table-data.csv',
  tooltipLabel: 'Download CSV',
  tooltipPosition: 'left',
  newTab: false,
  wrapperClassName: '',
}

export default memo(CsvDownloadButton)
