import { useEffect, useState } from 'react'
import { LoadBar } from 'components'
import { useRouteQueryParams } from 'hooks'
import { useTranslation } from 'react-i18next'
import { changeScanPage } from 'qualityControl/contexts/contextUtils'
import { useCurrent } from 'contexts/index'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import { useCurrentScan } from 'qualityControl/contexts/ScanContext'
import {
  getPhoneUnreviewedScanIds,
  getSortedScanIds,
  getVisualUnapprovedScanIds,
  getVisualUnreviewedScanIds,
} from 'qualityControl/contexts/contextSelectors'
import { useCurrentFlagContext } from 'qualityControl/flags/contexts/CurrentFlagContext'
import PacketDetails from './PacketDetails'
import PacketPaginationControls from './PacketPaginationControls'
import { ScanPdfViewer } from './PdfViewer'
import ScanViewerHeader from './ScanViewHeader/ScanViewerHeader'
import QcReviewPanel from './visualQc/QcReviewPanel/QcReviewPanel'
import PhoneVerificationPanel from './phoneVerification/PhoneVerificationPanel/PhoneVerificationPanel'

const PacketViewer = ({
  title,
  showDetail = true,
  showCountyLookup = true,
  showPaginationStatus,
  getEligibleScanIds,
}) => {
  const [currentPacket] = useCurrentPacket()
  const [currentScan] = useCurrentScan()
  const sortedScanIds = getSortedScanIds(currentPacket)
  const eligibleScanIds = getEligibleScanIds(currentPacket)
  const { doesCurrentUserHavePermission } = useCurrent()
  const canPerformVisualQc = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'visual_qc',
  })
  const canPerformPhoneVerification = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'phone_verification',
  })
  const canReviewFlags = doesCurrentUserHavePermission({
    resource: 'quality_control/flag',
    ability: 'review',
  })
  const [shouldSkip, toggleSkip] = useState(false)

  const [{ scanId: stringScanId }, setQueryParams] = useRouteQueryParams()
  const scanId = +stringScanId

  const { scans_count: scansCount } = currentPacket

  const currentScanIndex = sortedScanIds.indexOf(scanId)

  const isPacketLoaded = currentPacket?.id

  useEffect(() => {
    const loadInitialScan = () => {
      if (!isPacketLoaded) return

      const sortedScanIds = getSortedScanIds(currentPacket)
      const currentScanIndex = sortedScanIds.indexOf(scanId)

      if (scanId && currentScanIndex > -1) return

      const eligibleScanIds = getEligibleScanIds(currentPacket)

      if (eligibleScanIds.length) {
        setQueryParams({ scanId: eligibleScanIds[0] }, 'replace')
      } else if (sortedScanIds.length) {
        setQueryParams({ scanId: sortedScanIds[0] }, 'replace')
      }
    }
    loadInitialScan()
  }, [currentPacket?.id, isPacketLoaded, scanId])

  const args = {
    currentScanIndex,
    shouldSkip,
    sortedScanIds,
    filteredScanIds: eligibleScanIds,
    setQueryParams,
  }

  const { t } = useTranslation()

  const goToNextScan = () => changeScanPage(1, args)
  const previousScan = () => changeScanPage(-1, args)

  if (!sortedScanIds.length) return null

  return (
    <section aria-label="packet viewer">
      {title && (
        <div className="inbox-viewer__sidebar__header">
          <h2>{title}</h2>
        </div>
      )}
      {!isPacketLoaded || !currentScan ? (
        <LoadBar show />
      ) : (
        <>
          <ScanViewerHeader
            scansCount={scansCount}
            currentScanIndex={currentScanIndex}
            scanNumber={currentScan.scan_number}
          />
          <ScanPdfViewer
            scanUrl={currentScan.file_url}
            removedAt={currentScan.file_locator?.metadata?.redacted_at}
            isInteractable
          />
          {showDetail && <PacketDetails />}
          {(canPerformVisualQc || canReviewFlags) && (
            <QcReviewPanel
              showCountyLookup={showCountyLookup}
              currentScan={currentScan}
              initExpanded
              goToNext={goToNextScan}
            />
          )}
          {(canPerformPhoneVerification || canReviewFlags) && (
            <PhoneVerificationPanel />
          )}
          <PacketPaginationControls
            currentScanIndex={currentScanIndex}
            scansCount={scansCount}
            goToNext={goToNextScan}
            goToPrevious={previousScan}
            shouldSkip={shouldSkip}
            toggleSkip={toggleSkip}
            skipLabel={t('Skip reviewed scans')}
            showSkipCheckbox={
              canPerformVisualQc ||
              canPerformPhoneVerification ||
              canReviewFlags
            }
            showStatusIcons={showPaginationStatus}
          />
        </>
      )}
    </section>
  )
}

export const VisualReviewPacketViewer = params => (
  <PacketViewer
    getEligibleScanIds={getVisualUnreviewedScanIds}
    showPaginationStatus
    {...params}
  />
)
export const PhoneReviewPacketViewer = params => (
  <PacketViewer getEligibleScanIds={getPhoneUnreviewedScanIds} {...params} />
)
export const VisualApprovePacketViewer = params => (
  <PacketViewer getEligibleScanIds={getVisualUnapprovedScanIds} {...params} />
)

export const FlagPacketViewer = props => {
  const [currentPacket] = useCurrentPacket()

  const sortedScanIds = getSortedScanIds(currentPacket)

  const { currentFlag } = useCurrentFlagContext()

  const getEligibleScanIds = () =>
    currentFlag.triggered_by_scans
      .filter(scan => sortedScanIds.includes(scan.id))
      .sort((a, b) => a.scan_number - b.scan_number)
      .map(scan => scan.id)

  return <PacketViewer {...props} getEligibleScanIds={getEligibleScanIds} />
}
