import {
  calculatePercent,
  calculateRate,
  summaryFunctions,
} from 'utils/reporting'

export const baseVisualQcColumns = [
  {
    dataKey: 'complete_scans_qc',
    title: 'Complete Forms (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'incomplete_scans_qc',
    title: 'Incomplete Forms (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      rowData.total_scans_qc - rowData.complete_scans_qc,
  },
  {
    dataKey: 'approved_qc',
    title: 'Approved (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'missing_phone_qc',
    title: 'Missing Phone (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'reupload_qc',
    title: 'Reupload (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'handwriting_qc',
    title: 'Handwriting (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'not_form_qc',
    title: 'Not a Card (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'canvasser_assistance_qc',
    title: 'Canvasser Assistance (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'other_qc',
    title: 'Other (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'percent_complete_scans_qc',
    title: 'Complete Form Percent (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.complete_scans_qc, rowData.total_scans_qc),
    summaryFunction: summaryFunctions.percent(
      'complete_scans_qc',
      'total_scans_qc'
    ),
  },
  {
    dataKey: 'percent_approved_qc',
    title: 'Percent Approved (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.approved_qc, rowData.total_scans_qc),
    summaryFunction: summaryFunctions.percent('approved_qc', 'total_scans_qc'),
  },
  {
    dataKey: 'percent_missing_phone_qc',
    title: 'Percent Missing Phone (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.missing_phone_qc, rowData.total_scans_qc),
    summaryFunction: summaryFunctions.percent(
      'missing_phone_qc',
      'total_scans_qc'
    ),
  },
  {
    dataKey: 'percent_reupload_qc',
    title: 'Percent Reupload (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.reupload_qc, rowData.total_scans_qc),
    summaryFunction: summaryFunctions.percent('reupload_qc', 'total_scans_qc'),
  },
  {
    dataKey: 'percent_handwriting_qc',
    title: 'Percent Handwriting (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.handwriting_qc, rowData.total_scans_qc),
    summaryFunction: summaryFunctions.percent(
      'handwriting_qc',
      'total_scans_qc'
    ),
  },
  {
    dataKey: 'percent_canvasser_assistance_qc',
    title: 'Percent Canvasser Assistance (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.canvasser_assistance_qc, rowData.total_scans_qc),
    summaryFunction: summaryFunctions.percent(
      'canvasser_assistance_qc',
      'total_scans_qc'
    ),
  },
  {
    dataKey: 'percent_other_qc',
    title: 'Percent Other (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.other_qc, rowData.total_scans_qc),
    summaryFunction: summaryFunctions.percent('other_qc', 'total_scans_qc'),
  },
]

export const basePhoneQcColumns = [
  {
    dataKey: 'average_rounds_called_qc',
    title: 'Average Rounds Called (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculateRate(rowData.calls, rowData.total_scans_called_qc),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customRatio(data, acc, [
        'calls',
        'rowData.total_scans_called_qc',
      ]),
  },
  {
    dataKey: 'total_scans_called_qc',
    title: 'Total Cards Called (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'contacted_qc',
    title: 'Contacted (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'phone_needs_attention_qc',
    title: 'Phone Needs Attention (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'confirmed_registered_qc',
    title: 'Confirmed Registration on Phone (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'did_not_register_qc',
    title: 'Did Not Register (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'correct_address_qc',
    title: 'Correct Address (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'incorrect_address_qc',
    title: 'Incorrect Address (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'correct_dob_qc',
    title: 'Correct DOB (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'incorrect_dob_qc',
    title: 'Incorrect DOB (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'received_feedback_qc',
    title: 'Received Canvasser Feedback (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'duplicate_scans_qc',
    title: 'Duplicate Forms (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'not_home_qc',
    title: 'Not Home (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'disconnected_qc',
    title: 'Disconnected (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'call_back_qc',
    title: 'Call Back (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'language_barrier_qc',
    title: 'Language Barrier (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'refused_qc',
    title: 'Refused (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'wrong_number_qc',
    title: 'Wrong Number (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'wrong_voicemail_qc',
    title: 'Wrong Voicemail (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'voicemail_matches_applicant_qc',
    title: 'Voicemail Matches Applicant (QC)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'busy_qc',
    title: 'Busy (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'do_not_call_qc',
    title: 'Do Not Call (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'percent_scans_called_qc',
    title: 'Percent Cards Called (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.total_scans_called_qc, rowData.total_scans_qc),
    summaryFunction: summaryFunctions.percent(
      'total_scans_called_qc',
      'total_scans_qc'
    ),
  },
  {
    dataKey: 'percent_contacted_qc',
    title: 'Percent Contacted (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.contacted_qc, rowData.total_scans_called_qc),
  },
  {
    dataKey: 'percent_phone_needs_attention_qc',
    title: 'Percent Phone Needs Attention (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.phone_needs_attention_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_confirmed_registered_qc',
    title: 'Percent Confirmed Registration on Phone (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.confirmed_registered_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_did_not_register_qc',
    title: 'Percent Did Not Register (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.did_not_register_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_correct_address_qc',
    title: 'Percent Correct Address (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.correct_address_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_incorrect_address_qc',
    title: 'Percent Incorrect Address (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.incorrect_address_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_correct_dob_qc',
    title: 'Percent Correct DOB (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.correct_dob_qc, rowData.total_scans_called_qc),
  },
  {
    dataKey: 'percent_incorrect_dob_qc',
    title: 'Percent Incorrect DOB (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.incorrect_dob_qc, rowData.total_scans_called_qc),
  },
  {
    dataKey: 'percent_received_feedback_qc',
    title: 'Percent Received Canvasser Feedback (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.received_feedback_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_duplicate_scan_qc',
    title: 'Percent Duplicate Form (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.duplicate_scans_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_not_home_qc',
    title: 'Percent Not Home (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.not_home_qc, rowData.total_scans_called_qc),
    summaryFunction: summaryFunctions.percent(
      'not_home_qc',
      'total_scans_called_qc'
    ),
  },
  {
    dataKey: 'percent_disconnected_qc',
    title: 'Percent Disconnected (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.disconnected_qc, rowData.total_scans_called_qc),
  },
  {
    dataKey: 'percent_call_back_qc',
    title: 'Percent Call Back (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.call_back_qc, rowData.total_scans_called_qc),
    summaryFunction: summaryFunctions.percent(
      'call_back_qc',
      'total_scans_called_qc'
    ),
  },
  {
    dataKey: 'percent_language_barrier_qc',
    title: 'Percent Language Barrier (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.language_barrier_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_refused_qc',
    title: 'Percent Refused (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.refused_qc, rowData.total_scans_called_qc),
  },
  {
    dataKey: 'percent_wrong_number_qc',
    title: 'Percent Wrong Number (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.wrong_number_qc, rowData.total_scans_called_qc),
  },
  {
    dataKey: 'percent_wrong_voicemail_qc',
    title: 'Percent Wrong Voicemail (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.wrong_voicemail_qc,
        rowData.total_scans_called_qc
      ),
  },
  {
    dataKey: 'percent_voicemail_matches_applicant_qc',
    title: 'Percent Voicemail Matches Applicant (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.voicemail_matches_applicant_qc,
        rowData.total_scans_called_qc
      ),
    summaryFunction: summaryFunctions.percent(
      'voicemail_matches_applicant_qc',
      'total_scans_called_qc'
    ),
  },
  {
    dataKey: 'percent_busy_qc',
    title: 'Percent Busy (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.busy_qc, rowData.total_scans_called_qc),
    summaryFunction: summaryFunctions.percent(
      'busy_qc',
      'total_scans_called_qc'
    ),
  },
  {
    dataKey: 'percent_do_not_call_qc',
    title: 'Percent Do Not Call (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.do_not_call_qc, rowData.total_scans_called_qc),
  },
]
