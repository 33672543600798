import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getToken } from 'utils/auth'
import { getApiUrl } from 'utils/req'
import {
  Button,
  ButtonBlock,
  Icon,
  Modal,
} from '@politechdev/blocks-design-system'
import { useGoogleCalendarUrl } from './hooks'
import { EventContext } from '../EventContext/EventContext'

const AddToCalendarModal = () => {
  const { t } = useTranslation()
  const { currentEvent } = useContext(EventContext)
  const googleUrl = useGoogleCalendarUrl(currentEvent)

  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Button.Secondary
        onClick={openModal}
        aria-label={t('Export to calendar')}
      >
        <Icon.Calendar />
      </Button.Secondary>
      <Modal
        id="add-to-calendar-modal"
        title={t('Add to calendar')}
        isOpen={modalOpen}
      >
        <Modal.Body>
          <ButtonBlock justify="center">
            <Button
              onClick={() => {
                window.open(
                  getApiUrl(
                    `/api/v1/events/${currentEvent.id}.ics?jwt=${getToken()}`
                  ),
                  '_blank'
                )
              }}
            >
              {t('Download as ICS')}
            </Button>
            <Button
              onClick={() => {
                window.open(googleUrl, '_blank')
              }}
            >
              {t('Add to Google Cal')}
            </Button>
          </ButtonBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AddToCalendarModal
