export const DEFAULT_PENDING_SCRIPT = {
  turfId: '',
  id: '',
  language: '',
  structure: [],
}

export const qcCallScriptParams = {
  fields: ['id', 'turf_id', 'language', 'structure'],
}
