import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { SearchSelect, MultiSearchSelect, CardError } from 'components'
import { useRequest } from 'hooks'
import { fetchOrganizations } from 'requests/organizations'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts/index'
import { buildParams, buildOption, buildOrganization } from './utils'

const OrganizationSelectFieldDEPRECATED = ({
  label,
  organizationTypes,
  filters,
  onSelect,
  organization = {},
  organizations = [],
  isMulti,
  canSelectAll,
  ...props
}) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'organization',
    ability: 'view',
  })

  const [options, setOptions] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState(organization?.id)
  const [multiValue, setMultiValue] = useState(organizations.map(buildOption))
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  const { makeRequest, isLoading } = useRequest(fetchOrganizations, {
    onSuccess: ({ organizations: incomingOrganizations }) => {
      setOptions([
        ...incomingOrganizations.map(buildOption),
        ...(canSelectAll
          ? [
              {
                label: t(`All ${label?.toLowerCase() || 'organizations'}`),
                value: 'ALL',
              },
            ]
          : []),
      ])
    },
    onError: () => {
      setErrorMsg('failed to fetch organizations')
    },
  })

  const getOrganizations = () => {
    const params = buildParams({
      query: debounced,
      organizationTypes,
      filters,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getOrganizations()
  }, [debounced])

  useEffect(() => {
    setValue(organization?.id)
  }, [organization])

  useEffect(() => {
    setMultiValue(organizations.map(buildOption))
  }, [JSON.stringify(organizations)])

  const singleOptions = buildSingleOptions(options, organization, buildOption)

  const multiOptions = buildMultiOptions(options, organizations, buildOption)

  const handleChange = selection => {
    const selectedOption = options.find(({ value }) => value === selection)
    onSelect &&
      onSelect(selectedOption ? buildOrganization(selectedOption) : undefined)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(buildOrganization))
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSearchSelect
          label={label || t('Organizations')}
          options={multiOptions}
          onChange={handleMultiChange}
          onInputChange={setQuery}
          value={multiValue}
          isLoading={isLoading}
          disabled={!hasPermission}
          helpText={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      ) : (
        <SearchSelect
          label={label || t('Organization')}
          options={singleOptions}
          onChange={handleChange}
          onInputChange={setQuery}
          value={value || ''}
          isLoading={isLoading}
          disabled={!hasPermission}
          helpText={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      )}
    </>
  )
}

OrganizationSelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  organizationTypes: PropTypes.array,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  organization: PropTypes.object,
}

OrganizationSelectFieldDEPRECATED.defaultProps = {
  id: 'organization',
  organizationTypes: [],
  filters: [],
  organization: null,
}

export default OrganizationSelectFieldDEPRECATED
