import React, { useState } from 'react'
import { QueryParamProvider, useQueryParams } from 'contexts'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import { Icon, Button } from '@politechdev/blocks-design-system'
import {
  rootPathToName,
  exceptionalParents,
  excludedParents,
} from 'layout/routesConfig'

import styles from './BreadCrumbs.module.scss'

const cx = classNames.bind(styles)

const BACKLINKS = {
  deliveries: id => `/collect/voter_registration/deliveries/${id}`,
  shifts: id => `/collect/voter_registration/shifts/${id}/packet`,
  quality_control: id => `quality_control/inbox/${id}/visual_qc`,
  flags: sourceId => {
    const [type, flagId, originalType, packetId] = JSON.parse(sourceId)
    return `/quality_control/flags/${type}/${flagId}/${originalType}/${packetId}`
  },
}

const getParentView = () => {
  const pieces = window.location.pathname.split('/')

  let path = ''
  let navigationMatch = ''
  let exceptionalMatch = ''

  pieces.slice(1, pieces.length).forEach(piece => {
    path += `/${piece}`
    if (rootPathToName[path]) {
      navigationMatch = path
    }
    if (exceptionalParents[path]) {
      exceptionalMatch = path
    }
  })

  const match = exceptionalMatch
    ? exceptionalParents[exceptionalMatch]
    : navigationMatch

  if (excludedParents.has(match)) return null

  return match && !rootPathToName[path]
    ? { name: rootPathToName[match], path: match }
    : null
}

const BreadCrumbs = () => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const parentView = getParentView()
  const [cachedQuery, setCachedQuery] = useState('')

  if (!parentView && cachedQuery !== window.location.search) {
    setCachedQuery(window.location.search)
  }

  if (!parentView) {
    return null
  }

  const linkPath = queryParams.source
    ? `${BACKLINKS[queryParams.source](queryParams.sourceId)}`
    : `${parentView.path}${cachedQuery}`

  const linkLabel = queryParams.source || parentView.name

  return (
    <div className={cx('breadcrumbs__container')}>
      <Link to={linkPath}>
        <Button.Secondary>
          <Icon.ArrowLeft className={cx('breadcrumbs__icon')} />
          <span>{t(linkLabel)}</span>
        </Button.Secondary>
      </Link>
    </div>
  )
}

export default props => (
  <QueryParamProvider>
    <BreadCrumbs {...props} />
  </QueryParamProvider>
)
