export default class RequestException extends Error {
  status: number

  statusText: string

  _response: Response

  json: Record<string, any> | null = null

  text: string | null = null

  constructor(message: string, response: Response) {
    super(`RequestException: ${message} ${response.status}`)
    this._response = response.clone()
    this.status = this._response.status
    this.statusText = this._response.statusText
    this.name = 'RequestException'
  }

  async readJSON() {
    let json
    let text
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      json = await this._response?.clone().json()
    } catch (e) {
      if (e instanceof SyntaxError) {
        text = await this._response?.clone().text()
      } else {
        throw e
      }
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.json = json || null
      this.text = text || null
    }
  }
}
