import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, TextBlock } from 'components'
import {
  Button,
  Icon,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { destroyListFolder } from 'requests/lists'
import styles from './FolderDeleteModal.module.scss'

const FolderDeleteModal = ({ folder, getListFolders }) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(destroyListFolder, {
    onSuccess: async () => {
      await getListFolders()
      closeModal()
    },
  })

  return (
    <>
      <Button.Secondary disabled={!!folder.lists.length} onClick={openModal}>
        <Icon.TrashAlt alt={t('delete')} />
      </Button.Secondary>
      <Modal
        id="folder-delete"
        title={t(`Delete folder`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error deleting this folder')}
              hideSupportLink
            />
            <TextBlock className={styles.text}>
              {t('Are you sure you want to delete')}{' '}
              <strong>{folder.name}</strong>
            </TextBlock>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary flat onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                makeRequest(folder.id)
              }}
            >
              {t('Delete')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default FolderDeleteModal
