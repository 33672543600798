import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { RoleAbilityContext } from '../RoleAbilityContextProvider/RoleAbilityContextProvider'
import styles from './RoleAbilityDependencyModal.module.scss'

const RoleAbilityDependencyModal = () => {
  const { t } = useTranslation()
  const {
    pendingAction: { ability, dependencies },
    clearPendingAction,
    toggleAbilities,
  } = useContext(RoleAbilityContext)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(!!dependencies.length)
  }, [dependencies])

  return (
    <Modal
      id="roles-dependency-modal"
      title={t('Required Dependencies')}
      isOpen={isOpen}
      onHide={clearPendingAction}
    >
      <Modal.Body>
        {ability && (
          <ContentBlock>
            <p>
              The following abilities are required by{' '}
              <span className={styles.strong}>
                {ability.permission.label} - {ability.label}
              </span>{' '}
              and must also be enabled:
            </p>
            <div className={styles.list}>
              <ul>
                {dependencies.map(dependency => (
                  <li key={dependency.id}>
                    <span className={styles.strong}>
                      {dependency.permission.label}
                    </span>{' '}
                    - {dependency.label}
                  </li>
                ))}
              </ul>
            </div>
          </ContentBlock>
        )}
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={clearPendingAction}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={toggleAbilities}>
            {t('Enable All')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default RoleAbilityDependencyModal
