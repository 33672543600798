import { useEffect, useState } from 'react'
import { TextBlock, Font } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { fetchPhoneBankCalls } from 'requests/phoneBanks'
import PhoneBankLogTable from './PhoneBankLogTable'

const getInitialState = () => ({
  readOnly: true,
  contactLog: [],
  newContactLog: {
    contacted_at: new Date(),
  },
})

const PhoneBankLog = ({ personId }) => {
  const [state, _setState] = useState(getInitialState())
  const setState = newState => {
    if (typeof newState === 'function') {
      _setState(prevState => ({ ...prevState, ...newState(prevState) }))
    } else {
      _setState(prevState => ({ ...prevState, ...newState }))
    }
  }

  const { t } = useTranslation()

  const { makeRequest: getCalls } = useRequest(fetchPhoneBankCalls, {
    onSuccess: response => {
      setState({ contactLog: response['phone_banking/calls'] })
    },
    onError: () => {},
  })

  useEffect(() => {
    getCalls({
      filters: {
        rules: [
          {
            column: 'person',
            operator: 'is',
            param: personId,
          },
        ],
      },
      fields: [
        'participated',
        'created_at',
        'non_participation_reason',
        'notes',
        { phone_bank: ['id', 'name'] },
      ],
    })
  }, [])

  return (
    <div>
      <TextBlock>
        <Font.Copy variant="highlight">{t('Phone bank log')}</Font.Copy>
      </TextBlock>
      <PhoneBankLogTable log={state.contactLog} />
    </div>
  )
}

export default PhoneBankLog
