import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './ViewContainer.module.scss'

const cx = classNames.bind(styles)

const ViewContainer = ({ loading, className, children }) => (
  <div
    className={cx(
      'container',
      {
        'container--loading': loading,
      },
      className
    )}
  >
    {children}
  </div>
)

ViewContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
}

ViewContainer.defaultProps = {
  className: '',
  loading: false,
}

export default ViewContainer
