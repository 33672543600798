import { useEffect } from 'react'

const useDocumentEvent = ({ event, active, handler }) => {
  useEffect(() => {
    if (!active) {
      document.removeEventListener(event, handler)
    } else {
      document.addEventListener(event, handler)
      return () => document.removeEventListener(event, handler)
    }
  }, [event, active, handler])
}

export default useDocumentEvent
