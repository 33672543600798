import { useTranslation } from 'react-i18next'
import { TextBlock } from 'components'
import { useContext } from 'react'
import styles from './RolesSummary.module.scss'
import { RoleContext } from '../RoleContext/RoleContext'

const RolesSummary = () => {
  const { t } = useTranslation()

  const { sortedRoles: roles } = useContext(RoleContext)
  return (
    <div className={styles.content}>
      <TextBlock>
        <p className={styles.title}>{t('All roles')}</p>
      </TextBlock>
      <TextBlock>
        <ul className={styles.list}>
          {roles.map(role => (
            <li
              key={role.id}
              style={{
                paddingLeft: `${30 * (role.depth - 1)}px`,
              }}
            >
              {role.name} ({role.users.filter(user => !user.disabled_at).length}
              )
            </li>
          ))}
        </ul>
      </TextBlock>
    </div>
  )
}

export default RolesSummary
