import { createContext, useContext } from 'use-context-selector'
import { useState } from 'react'
import { useRequest } from 'hooks/useRequest'
import { fetchPetitionReport } from 'requests/petitionReports'
import { formatErrorMessage } from 'utils/formatting'

const PetitionReportsContext = createContext()

export const PetitionReportsProvider = ({ children }) => {
  const [reportData, setReportData] = useState([])

  const receivePetitionReport = reportData => setReportData(reportData)

  const {
    makeRequest: requestPetitionReport,
    isLoading,
    errors,
  } = useRequest(
    (reportType, startDate, endDate, turfId) =>
      fetchPetitionReport(reportType, {
        startdate: startDate,
        enddate: endDate,
        turf_id: turfId,
      }),
    {
      onSuccess: response => {
        receivePetitionReport(
          response.map((item, index) => ({
            ...item,
            id: index,
          }))
        )
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  return (
    <PetitionReportsContext.Provider
      value={{
        reportData,
        requestPetitionReport,
        isLoading,
        errorMsg,
      }}
    >
      {children}
    </PetitionReportsContext.Provider>
  )
}

export const usePetitionReport = () => useContext(PetitionReportsContext)
