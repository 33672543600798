import { fetchJSON, stringifyParams } from '../utils/req'

export const fetchTurf = (turfId, params = {}) =>
  fetchJSON(`/api/v1/turfs/${turfId}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const createPhoneVerificationScript = (script, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/phone_verification_scripts?${stringifyParams(
      params
    )}`,
    'POST',
    {
      script,
    },
    {
      useJwt: true,
    }
  )

export const fetchPhoneVerificationScript = (id, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/phone_verification_scripts/${id}?${stringifyParams(
      params
    )}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const updatePhoneVerificationScript = (id, newAttrs, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/phone_verification_scripts/${id}?${stringifyParams(
      params
    )}`,
    'PUT',
    {
      script: newAttrs,
    },
    {
      useJwt: true,
    }
  )
