import { Link } from 'react-router-dom'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { Button } from '@politechdev/blocks-design-system'

export const EventNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/build/events/${rowData.id}`}>{rowData.name}</Link>
  </TableGridCell>
)

export const EventCreatorCell = ({ rowData }) => (
  <TableGridCell>{rowData.creator.name}</TableGridCell>
)

export const EventTypeCell = ({ rowData }) => (
  <TableGridCell>{rowData.type}</TableGridCell>
)

export const EventLocationCell = ({ rowData }) => (
  <TableGridCell>{rowData.location?.name}</TableGridCell>
)

export const EventDateCell = ({ rowData }) => (
  <TableGridCell>
    {`${moment(rowData.start_time).format(dateFormat)}, ${moment(
      rowData.start_time
    ).format('h A')} - ${moment(rowData.end_time).format('h A')}`}
  </TableGridCell>
)

export const EventAttendedCountCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.attended_count + rowData.walk_in_count}
  </TableGridCell>
)

export const EventDidNotAttendCountCell = ({ rowData }) => (
  <TableGridCell>{rowData.no_show_count + rowData.invited_count}</TableGridCell>
)

export const EventOrganizationCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/organizations/${rowData.organization_id}`}>
      {rowData.organization.name}
    </Link>
  </TableGridCell>
)

export const EventTeamsCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.teams.map((team, index) => (
      <>
        <Link to={`/organize/teams/${team.id}`}>{team.name}</Link>
        {index !== rowData.teams.length - 1 ? ', ' : ''}
      </>
    ))}
  </TableGridCell>
)

export const EventNotesCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.notes ? (
      rowData.notes
    ) : (
      <Link to={`/build/events/${rowData.id}/edit`}>
        <Button primary>Add Notes</Button>
      </Link>
    )}
  </TableGridCell>
)
