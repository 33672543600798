import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNumber } from 'lodash'
import { CardError, LoadBar, TurfSelectField } from 'components'
import {
  Button,
  FieldBlock,
  ButtonBlock,
  TextField,
} from '@politechdev/blocks-design-system'
import { useCurrent, useForm, useTurfs } from 'contexts'
import { useRequest, useReactRouter } from 'hooks'
import { formatLegacyErrorMsg } from 'utils/formatting'
import {
  fetchCanvassers,
  postCanvasser,
  putCanvasser,
} from 'requests/canvassers'
import { isValidPhone } from 'utils/inputValidations'
import { fetchTurf } from 'requests/turfs'
import { buildForm, areVdrsUnmodified } from './utils'
import CanvasserVdrs from '../CanvasserVdrs/CanvasserVdrs'
import VdrEditWarningModal from '../CanvasserVdrs/VdrEditWarningModal'
import DupeCheckModal from '../CanvasserDupes/DupeCheckModal'

const CanvasserForm = ({ canvasser }) => {
  const isEditForm = !!canvasser
  const formAction = isEditForm ? putCanvasser : postCanvasser
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { history } = useReactRouter()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const { formData, setField, setFormData } = useForm()
  const [vdrEditModalIsOpen, setVdrEditModalIsOpen] = useState(false)
  const [dupeModalOpen, setDupeModalOpen] = useState(false)
  const [dupeCanvassers, setDupeCanvassers] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [shouldUseVdrs, setShouldUseVdrs] = useState(false)
  const { currentTenantStateOptions } = useCurrent()

  const { makeRequest, isLoading: updateIsLoading } = useRequest(formAction, {
    onSuccess: () => {
      history.push(`/collect/${match.params.shiftType}/canvassers`)
    },
    onError: async error => {
      const message = await formatLegacyErrorMsg(error)
      setErrorMsg(message)
    },
  })

  const createOrUpdateCanvasser = () => {
    if (isEditForm) {
      makeRequest(match.params.id, formData)
      return
    }
    makeRequest(formData)
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: dupeCheck, isLoading: dupeCheckIsLoading } = useRequest(
    fetchCanvassers,
    {
      onSuccess: ({ canvassers, meta: { total_count: totalCount } }) => {
        if (totalCount === 0) {
          createOrUpdateCanvasser()
        } else {
          setDupeModalOpen(true)
          setDupeCanvassers(canvassers)
        }
      },
    }
  )

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: getUsesVdrs, isLoading: getUsesVdrsLoading } =
    useRequest(fetchTurf, {
      onSuccess: ({
        turf: {
          voter_registration_config: { uses_canvasser_vdrs: useVdrs },
        },
      }) => {
        setShouldUseVdrs(useVdrs)
      },
    })

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  useEffect(() => {
    setFormData(buildForm(canvasser))
  }, [canvasser])

  useEffect(() => {
    if (isNumber(formData.turf_id)) {
      getUsesVdrs(formData.turf_id, { fields: ['voter_registration_config'] })
    }
  }, [formData.turf_id])

  const validateVdrs = vdrs =>
    vdrs.every(
      ({ county, code }) =>
        county && code && county.length > 0 && code.length > 0
    )

  const isFormValid = () =>
    !isEmpty(formData.first_name) &&
    !isEmpty(formData.last_name) &&
    isValidPhone(formData.phone_number) &&
    formData.turf_id &&
    (!shouldUseVdrs || validateVdrs(formData.vdrs))

  const isLoading =
    updateIsLoading || turfRefreshIsLoading || getUsesVdrsLoading

  const submitForm = e => {
    e.preventDefault()
    if (
      shouldUseVdrs &&
      !areVdrsUnmodified(formData.vdrs, isEditForm ? canvasser.vdrs : [])
    ) {
      setVdrEditModalIsOpen(true)
    }
    dupeCheck({
      fields: [
        'id',
        'first_name',
        'last_name',
        'phone_number',
        'archived',
        { turf: ['id', 'name'] },
      ],
      filters: {
        rules: [
          {
            conjunction: 'exclusive',
            rules: [
              {
                column: 'first_name',
                operator: 'is',
                param: formData.first_name,
              },
              {
                column: 'phone_number',
                operator: 'is_nil',
              },
              {
                column: 'last_name',
                operator: 'is',
                param: formData.last_name,
              },
              {
                column: 'turf_id',
                operator: 'is',
                param: formData.turf_id,
              },
              match.params.id
                ? {
                    column: 'id',
                    operator: 'is',
                    param: match.params.id,
                    invert: true,
                  }
                : undefined,
            ],
          },
          {
            conjunction: 'exclusive',
            rules: [
              {
                column: 'first_name',
                operator: 'is',
                param: formData.first_name,
              },
              {
                column: 'phone_number',
                operator: 'is',
                param: formData.phone_number,
              },
              {
                column: 'last_name',
                operator: 'is',
                param: formData.last_name,
              },
              {
                column: 'turf_id',
                operator: 'is',
                param: formData.turf_id,
              },
              match.params.id
                ? {
                    column: 'id',
                    operator: 'is',
                    param: match.params.id,
                    invert: true,
                  }
                : undefined,
            ],
          },
        ],
      },
      conjunction: 'inclusive',
    })
  }

  return (
    <>
      <LoadBar show={isLoading} />
      <CardError hide={!errorMsg} message={errorMsg} />
      <form onSubmit={submitForm}>
        <FieldBlock>
          <TextField
            id="first_name"
            label={t('First Name')}
            value={formData.first_name}
            onChange={val => setField(val, 'first_name')}
            required
          />
          <TextField
            id="last_name"
            label={t('Last Name')}
            value={formData.last_name}
            onChange={val => setField(val, 'last_name')}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="phone_number"
            type="tel"
            label={t('Phone number')}
            value={formData.phone_number}
            onChange={val => {
              setField(val, 'phone_number')
            }}
            required
          />
          <TurfSelectField
            id="turf_id"
            label={t('Turf')}
            value={formData.turf_id}
            onSelect={val => {
              setField(val, 'turf_id')
            }}
            showArchived={isEditForm}
            disableArchived
            required
          />
        </FieldBlock>
        <FieldBlock variant="large">
          <TextField
            id="notes"
            label={t('Notes')}
            value={formData.notes}
            onChange={val => setField(val, 'notes')}
          />
        </FieldBlock>
        {shouldUseVdrs && (
          <CanvasserVdrs
            vdrData={formData.vdrs || []}
            onChange={val => setField(val, 'vdrs')}
            stateOptions={currentTenantStateOptions}
          />
        )}
        <ButtonBlock>
          <Button.Accent type="submit" disabled={!isFormValid() || isLoading}>
            {t(isEditForm ? 'Save' : 'Create canvasser')}
          </Button.Accent>
        </ButtonBlock>
        <VdrEditWarningModal
          isOpen={vdrEditModalIsOpen}
          setIsOpen={setVdrEditModalIsOpen}
          canvasserEditRequest={() => makeRequest(match.params.id, formData)}
        />
        <DupeCheckModal
          isOpen={dupeModalOpen}
          setIsOpen={setDupeModalOpen}
          showLoading={dupeCheckIsLoading}
          canvasserCreateOrUpdateRequest={createOrUpdateCanvasser}
          potentialDupes={dupeCanvassers}
        />
      </form>
    </>
  )
}

export default CanvasserForm
