import { calculatePercent, formatDecimal } from 'utils/reporting'
import { dateFormat } from 'utils/constants'
import moment from 'moment'

export const buildStats = stats => ({
  latestShift: stats.last_canvass_date
    ? moment(stats.last_canvass_date).format(dateFormat)
    : undefined,
  firstShift: stats.first_canvass_date
    ? moment(stats.first_canvass_date).format(dateFormat)
    : undefined,
  totalShifts: stats.total_shifts,
  totalFormsCover: stats.total_forms_cover,
  totalFieldHoursCover: stats.total_field_hours,
  totalShiftHours: stats.total_shift_hours,
  totalScans: stats.total_scans,
  totalFormsThroughQc: stats.total_scans_qc,
  totalScansCalledQc: stats.total_scans_called_qc,
  totalFormsAddressValidationAttempted:
    stats.total_forms_address_validation_attempted,
  totalFormsPhoneValidationAttempted:
    stats.total_forms_phone_validation_attempted,
  completeFormsQc: calculatePercent(
    stats.complete_scans_qc,
    +stats.total_scans_qc || 1
  ),
  formsPerFieldHourCover: formatDecimal(
    +stats.total_forms_cover / (+stats.total_field_hours || 1)
  ),
  percentMissingPhoneQc: calculatePercent(
    stats.missing_phone_qc,
    +stats.total_scans_qc || 1
  ),
  percentHandwritingQc: calculatePercent(
    stats.handwriting_qc,
    +stats.total_scans_qc || 1
  ),
  percentCanvasserAssistanceQc: calculatePercent(
    stats.canvasser_assistance_qc,
    +stats.total_scans_qc || 1
  ),
  percentConfirmedRegisteredQc: calculatePercent(
    stats.confirmed_registered_qc,
    +stats.total_scans_called_qc || 1
  ),
  percentDisconnectedQc: calculatePercent(
    stats.disconnected_qc,
    +stats.total_scans_called_qc || 1
  ),
  percentWrongNumberQc: calculatePercent(
    stats.wrong_number_qc,
    +stats.total_scans_called_qc || 1
  ),
  percentValidAddresses: calculatePercent(
    stats.valid_address_count,
    +stats.total_forms_address_validation_attempted || 1
  ),
  percentValidAutomatedPhones: calculatePercent(
    stats.valid_phone_count,
    +stats.total_forms_phone_validation_attempted || 1
  ),
  percentSameApplicantName: calculatePercent(
    stats.forms_with_applicant_name,
    +stats.total_forms_address_validation_attempted || 1
  ),
  percentDuplicateForms: calculatePercent(
    stats.duplicate_forms,
    +stats.total_forms_address_validation_attempted || 1
  ),
})

export const buildSummaryData = canvasser => {
  const sortedShifts = canvasser.shifts.sort(
    (a, b) => Date.parse(a.shift_start) - Date.parse(b.shift_start)
  )
  if (sortedShifts.length === 0) {
    return {
      allTimeShifts: 0,
      allTimeShiftHours: 0,
      allTimeFieldHours: 0,
    }
  }
  const firstShift = moment(sortedShifts[0].shift_start).format(dateFormat)
  const allTimeShifts = sortedShifts.length
  const latestShift = moment(
    sortedShifts[allTimeShifts - 1].shift_start
  ).format(dateFormat)
  const allTimeShiftHours =
    sortedShifts.reduce(
      (acc, shift) =>
        acc +
        moment(shift.shift_end).diff(moment(shift.shift_start), 'hours', true),
      0
    ) || 0
  const allTimeFieldHours =
    sortedShifts.reduce(
      (acc, shift) =>
        acc +
        moment(shift.field_end).diff(moment(shift.field_start), 'hours', true),
      0
    ) || 0
  return {
    latestShift,
    firstShift,
    allTimeShifts,
    allTimeShiftHours,
    allTimeFieldHours,
  }
}

export const buildStatsByDate = stats =>
  stats.map(statRow => ({
    date: statRow.date,
    totalFormsCover: Math.round(statRow.total_forms_cover),
    totalFieldHoursCover: Math.round(statRow.total_field_hours || 0),
    totalShiftHours: Math.round(statRow.total_shift_hours),
    totalScans: Math.round(statRow.total_scans),
    totalFormsThroughQc: Math.round(statRow.total_scans_qc),
  }))

export const buildTableData = (canvasserStats, avgStats) => [
  {
    metric: 'Total forms (cover)',
    canvasser: canvasserStats.totalFormsCover,
    average: avgStats.totalFormsCover,
    type: 'number',
  },
  {
    metric: 'Total field hours (cover)',
    canvasser: canvasserStats.totalFieldHoursCover,
    average: avgStats.totalFieldHoursCover,
    type: 'number',
  },
  {
    metric: 'Total shift hours',
    canvasser: canvasserStats.totalShiftHours,
    average: avgStats.totalShiftHours,
    type: 'number',
  },
  {
    metric: 'Total scans',
    canvasser: canvasserStats.totalScans,
    average: avgStats.totalScans,
    type: 'number',
  },
  {
    metric: 'Total forms through QC',
    canvasser: canvasserStats.totalFormsThroughQc,
    average: avgStats.totalFormsThroughQc,
    type: 'number',
  },
  {
    metric: 'Forms per field hour (cover)',
    canvasser: canvasserStats.formsPerFieldHourCover,
    average: avgStats.formsPerFieldHourCover,
    type: 'number',
  },
  {
    metric: 'Complete form percent (QC)',
    canvasser: canvasserStats.completeFormsQc,
    average: avgStats.completeFormsQc,
    type: 'percent',
  },
  {
    metric: 'Percent missing phone (QC)',
    canvasser: canvasserStats.percentMissingPhoneQc,
    average: avgStats.percentMissingPhoneQc,
    type: 'percent',
  },
  {
    metric: 'Percent handwriting (QC)',
    canvasser: canvasserStats.percentHandwritingQc,
    average: avgStats.percentHandwritingQc,
    type: 'percent',
  },
  {
    metric: 'Percent canvasser assistance (QC)',
    canvasser: canvasserStats.percentCanvasserAssistanceQc,
    average: avgStats.percentCanvasserAssistanceQc,
    type: 'percent',
  },
  {
    metric: 'Percent confirmed registration on phone (QC)',
    canvasser: canvasserStats.percentConfirmedRegisteredQc,
    average: avgStats.percentConfirmedRegisteredQc,
    type: 'percent',
  },
  {
    metric: 'Percent disconnected (QC)',
    canvasser: canvasserStats.percentDisconnectedQc,
    average: avgStats.percentDisconnectedQc,
    type: 'percent',
  },
  {
    metric: 'Percent wrong number (QC)',
    canvasser: canvasserStats.percentWrongNumberQc,
    average: avgStats.percentWrongNumberQc,
    type: 'percent',
  },
  {
    metric: 'Valid addresses %',
    canvasser: canvasserStats.percentValidAddresses,
    average: avgStats.percentValidAddresses,
    type: 'percent',
  },
  {
    metric: 'Valid automated phones %',
    canvasser: canvasserStats.percentValidAutomatedPhones,
    average: avgStats.percentValidAutomatedPhones,
    type: 'percent',
  },
  {
    metric: 'Forms with same applicant name %',
    canvasser: canvasserStats.percentSameApplicantName,
    average: avgStats.percentSameApplicantName,
    type: 'percent',
  },
  {
    metric: 'Duplicate forms (DOB or zip) %',
    canvasser: canvasserStats.percentDuplicateForms,
    average: avgStats.percentDuplicateForms,
    type: 'percent',
  },
]
