import { uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import countiesByState from 'utils/counties.json'

const useVdrs = (vdrData, onChange, stateOptions) => {
  useEffect(() => {
    onChange(vdrData.sort((a, b) => a.county.localeCompare(b.county)))
  }, [])

  const [countyOptions, setCountyOptions] = useState([])

  useEffect(() => {
    const possibleCounties = stateOptions
      ? stateOptions.flatMap(state =>
          countiesByState[state.value]
            .map(county => ({
              value: county,
              label: county,
            }))
            .filter(
              countyOption =>
                !vdrData
                  .map(({ county }) => county)
                  .includes(countyOption.value)
            )
        )
      : []

    setCountyOptions(uniqBy(possibleCounties, 'value'))
  }, [stateOptions])

  const updateCountyOptions = data => {
    const possibleCounties = stateOptions
      ? stateOptions.flatMap(state =>
          countiesByState[state.value]
            .map(county => ({
              value: county,
              label: county,
            }))
            .filter(
              countyOption =>
                !data.map(({ county }) => county).includes(countyOption.value)
            )
        )
      : []

    setCountyOptions(uniqBy(possibleCounties, 'value'))
  }

  const updateCountyVdr = data => {
    onChange(data)
    updateCountyOptions(data)
  }

  const updateCounty = (val, i) => {
    const newData = vdrData.map((data, j) => {
      if (j === i) {
        return {
          ...data,
          county: val,
        }
      }
      return data
    })
    updateCountyVdr(newData)
  }

  const updateCode = (val, i) => {
    onChange(
      vdrData.map((data, j) => {
        if (j === i) {
          return {
            ...data,
            code: val,
          }
        }
        return data
      })
    )
  }

  const addVdr = () => {
    const newData = [...vdrData, { county: null, code: '' }]
    updateCountyVdr(newData)
  }

  const removeVdr = index => {
    const newData = vdrData.filter((_, oldIndex) => index !== oldIndex)
    updateCountyVdr(newData)
  }

  return {
    vdrData,
    countyOptions,
    updateCounty,
    updateCode,
    addVdr,
    removeVdr,
  }
}

export default useVdrs
