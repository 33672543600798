import { useTranslation } from 'react-i18next'
import { ButtonBlock, Button, Icon } from '@politechdev/blocks-design-system'
import styles from './ScriptItem.module.scss'

const ReadActions = ({ item, loading, setIsEditingItem, removeSelf }) => {
  const { t } = useTranslation()
  const blockEditing =
    item.question?.scripts?.length > 1 || item.question?.responses?.length
  return (
    <>
      <Button.Secondary
        className={styles.button}
        disabled={loading || blockEditing}
        onClick={() => {
          setIsEditingItem(true)
        }}
        aria-label={t('Edit')}
      >
        <Icon.Pencil alt={t('Edit')} />
      </Button.Secondary>
      <Button.Secondary
        className={styles.button}
        onClick={removeSelf}
        disabled={loading || blockEditing}
        aria-label={t('Delete')}
      >
        <Icon.Times alt={t('Delete')} />
      </Button.Secondary>
    </>
  )
}

const EditActions = ({ loading, closeEdit, handleSaveClick }) => {
  const { t } = useTranslation()
  return (
    <ButtonBlock justify="right">
      <Button.Secondary disabled={loading} onClick={closeEdit}>
        {t('Cancel')}
      </Button.Secondary>
      <Button.Accent disabled={loading} onClick={handleSaveClick}>
        {t('Save')}
      </Button.Accent>
    </ButtonBlock>
  )
}

const Actions = {
  Read: ReadActions,
  Edit: EditActions,
}

export default Actions
