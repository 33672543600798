import React from 'react'
import PropTypes from 'prop-types'
import styles from './PlaceholderText.module.scss'

const PlaceholderText = ({ children }) => (
  <span className={styles.placeholderText}>{children}</span>
)

export default PlaceholderText

PlaceholderText.propTypes = {
  children: PropTypes.string.isRequired,
}
