import {
  calculatePercent,
  calculateRate,
  summaryFunctions,
} from 'utils/reporting'
import { customPercent, customRatio } from '../columnTotals'
import HighlightCell from '../../bodyCells/HighlightCell/HighlightCell'
import { HIGHLIGHT_THRESHOLDS } from './constants'

const baseValidatedFormColumns = [
  {
    dataKey: 'total_forms_address_validation_attempted',
    title: 'Total Forms with Verifiable Addresses',
    type: 'number',
    resizable: true,
    summaryFunction: params =>
      params.data.length ? summaryFunctions.sum(params) : 0,
    secondarySummaryFunction: ({ data }) =>
      data[0]?.meta?.total_forms_address_validation_attempted ?? 0,
  },
  {
    dataKey: 'percent_valid_addresses',
    title: 'Valid Addresses %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.valid_address_count,
        rowData.total_forms_address_validation_attempted
      ),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, [
        'valid_address_count',
        'total_forms_address_validation_attempted',
      ]),
    bodyCell: HighlightCell(
      'percent_valid_addresses',
      rowData =>
        rowData.percent_valid_addresses <
        HIGHLIGHT_THRESHOLDS.percent_valid_addresses
    ),
    secondarySummaryFunction: ({ data }) => {
      if (data[0]?.meta === undefined) {
        return 0
      }
      return calculatePercent(
        data[0]?.meta.valid_address_count,
        data[0]?.meta.total_forms_address_validation_attempted
      )
    },
  },
  {
    dataKey: 'percent_duplicate_dob',
    title: 'Duplicate DOB %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.dupe_dob_count,
        rowData.total_forms_address_validation_attempted
      ),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, [
        'dupe_dob_count',
        'total_forms_address_validation_attempted',
      ]),
    bodyCell: HighlightCell(
      'percent_duplicate_dob',
      rowData =>
        rowData.percent_duplicate_dob >
        HIGHLIGHT_THRESHOLDS.percent_duplicate_dob
    ),
    secondarySummaryFunction: ({ data }) => {
      if (data[0]?.meta === undefined) {
        return 0
      }
      return calculatePercent(
        data[0]?.meta.dupe_dob_count,
        data[0]?.meta.total_forms_address_validation_attempted
      )
    },
  },
  {
    dataKey: 'percent_duplicate_zip',
    title: 'Duplicate Zip %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.dupe_zip_count,
        rowData.total_forms_address_validation_attempted
      ),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, [
        'dupe_zip_count',
        'total_forms_address_validation_attempted',
      ]),
    bodyCell: HighlightCell(
      'percent_duplicate_zip',
      rowData =>
        rowData.percent_duplicate_zip >
        HIGHLIGHT_THRESHOLDS.percent_duplicate_zip
    ),
    secondarySummaryFunction: ({ data }) => {
      if (data[0]?.meta === undefined) {
        return 0
      }
      return calculatePercent(
        data[0]?.meta.dupe_zip_count,
        data[0]?.meta.total_forms_address_validation_attempted
      )
    },
  },
  {
    dataKey: 'total_forms_phone_validation_attempted',
    title: 'Total Forms with Verifiable Phone Numbers',
    type: 'number',
    resizable: true,
    summaryFunction: params =>
      params.data.length ? summaryFunctions.sum(params) : 0,
    secondarySummaryFunction: ({ data }) =>
      data[0]?.meta?.total_forms_phone_validation_attempted ?? 0,
  },
  {
    dataKey: 'percent_valid_automated_phones',
    title: 'Valid Automated Phones %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.valid_phone_count,
        rowData.total_forms_phone_validation_attempted
      ),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, [
        'valid_phone_count',
        'total_forms_phone_validation_attempted',
      ]),
    bodyCell: HighlightCell(
      'percent_valid_automated_phones',
      rowData =>
        rowData.percent_valid_automated_phones <
        HIGHLIGHT_THRESHOLDS.percent_valid_automated_phones
    ),
    secondarySummaryFunction: ({ data }) => {
      if (data[0]?.meta === undefined) {
        return 0
      }
      return calculatePercent(
        data[0]?.meta.valid_phone_count,
        data[0]?.meta.total_forms_phone_validation_attempted
      )
    },
  },
  {
    dataKey: 'total_forms_with_verifiable_calls',
    title: 'Total Forms with Verifiable Calls',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
    secondarySummaryFunction: ({ data }) =>
      data[0]?.meta?.total_forms_with_verifiable_calls ?? 0,
  },
  {
    dataKey: 'percent_manual_wrong_number',
    title: 'Manual Wrong Number %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.wrong_number_count,
        rowData.total_forms_with_verifiable_calls
      ),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, [
        'wrong_number_count',
        'total_forms_with_verifiable_calls',
      ]),
    bodyCell: HighlightCell(
      'percent_manual_wrong_number',
      rowData =>
        rowData.percent_manual_wrong_number >
        HIGHLIGHT_THRESHOLDS.percent_manual_wrong_number
    ),
    secondarySummaryFunction: ({ data }) => {
      if (data[0]?.meta === undefined) {
        return 0
      }
      return calculatePercent(
        data[0]?.meta.wrong_number_count,
        data[0]?.meta.total_forms_with_verifiable_calls
      )
    },
  },
  {
    dataKey: 'percent_manual_failed_verification',
    title: 'Manual Phone Verification Failed %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.failed_verification_count,
        rowData.total_forms_with_verifiable_calls
      ),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, [
        'failed_verification_count',
        'total_forms_with_verifiable_calls',
      ]),
    bodyCell: HighlightCell(
      'percent_manual_failed_verification',
      rowData =>
        rowData.percent_manual_failed_verification >
        HIGHLIGHT_THRESHOLDS.percent_manual_failed_verification
    ),
    secondarySummaryFunction: ({ data }) => {
      if (data[0]?.meta === undefined) {
        return 0
      }
      return calculatePercent(
        data[0]?.meta.failed_verification_count,
        data[0]?.meta.total_forms_with_verifiable_calls
      )
    },
  },
  {
    dataKey: 'registered_count',
    title: 'Total Forms Manually Verified',
    type: 'number',
    resizable: true,
    summaryFunction: params =>
      params.data.length ? summaryFunctions.sum(params) : 0,
    secondarySummaryFunction: ({ data }) =>
      data[0]?.meta?.registered_count ?? 0,
  },
  {
    dataKey: 'manual_wrong_numbers_per_verified_number',
    title: 'Manual Wrong Numbers per Verified Number',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculateRate(rowData.wrong_number_count, rowData.registered_count),
    summaryFunction: ({ data, acc }) =>
      customRatio(data, acc, ['wrong_number_count', 'registered_count']),
    bodyCell: HighlightCell(
      'manual_wrong_numbers_per_verified_number',
      rowData =>
        rowData.manual_wrong_numbers_per_verified_number >
        HIGHLIGHT_THRESHOLDS.manual_wrong_numbers_per_verified_number
    ),
    secondarySummaryFunction: ({ data }) => {
      if (data[0]?.meta === undefined) {
        return 0
      }
      return calculateRate(
        data[0]?.meta.wrong_number_count,
        data[0]?.meta.registered_count
      )
    },
  },
  {
    dataKey: 'total_scans_qc',
    title: 'Total Forms Through QC',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
    secondarySummaryFunction: ({ data }) => data[0]?.meta?.total_scans_qc ?? 0,
  },
]

baseValidatedFormColumns.visibleColumns = [
  'total_forms_address_validation_attempted',
  'percent_valid_addresses',
  'percent_duplicate_dob',
  'percent_duplicate_zip',
  'total_forms_phone_validation_attempted',
  'percent_valid_automated_phones',
  'total_forms_with_verifiable_calls',
  'percent_manual_failed_verification',
  'manual_wrong_numbers_per_verified_number',
  'total_scans_qc',
]

export default baseValidatedFormColumns
