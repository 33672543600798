import moment from 'moment'

const sanitizeString = s => s.trim().toLowerCase()

const sanitizeNum = n => {
  const num = parseFloat(n)
  return Number.isNaN(num) ? 0 : num
}

export const stringIs = (value, target) => {
  const val = typeof value === 'string' ? sanitizeString(value) : value
  return val === sanitizeString(target) || (!val && !target)
}

export const stringContains = (value, target, invert) => {
  switch (typeof value) {
    case 'string':
      return invert !== sanitizeString(value).includes(sanitizeString(target))
    case 'number':
      return (
        invert !==
        sanitizeString(value.toString()).includes(sanitizeString(target))
      )
    default:
      return false
  }
}

export const dateIs = (value, target) => moment(value).isSame(target, 'day')

export const dateIsBefore = (value, target) =>
  moment(value).isBefore(target, 'day')

export const dateIsAfter = (value, target) =>
  moment(value).isAfter(target, 'day')

export const dateIsMoreThanXAgo = (value, target) =>
  moment(value).isBefore(moment().subtract(target, 'days'))

export const dateIsLessThanXAgo = (value, target) =>
  moment(value).isAfter(moment().subtract(target, 'days'))

export const numberIs = (value, target) =>
  sanitizeNum(value) === parseFloat(target)

export const numberContains = (value, target) =>
  sanitizeNum(value) === parseFloat(target)

export const numberIsMoreThan = (value, target) =>
  sanitizeNum(value) > parseFloat(target)

export const numberIsLessThan = (value, target) =>
  sanitizeNum(value) < parseFloat(target)

export const enumMatch = (value, target) => {
  if (typeof target === 'number') {
    return numberIs(value, target)
  }
  return stringIs(value, target)
}

export default {
  string: {
    is: stringIs,
    containing: stringContains,
  },
  number: {
    is: numberIs,
    containing: stringContains,
    more_than: numberIsMoreThan,
    less_than: numberIsLessThan,
  },
  date: {
    is: dateIs,
    containing: stringContains,
    before: dateIsBefore,
    after: dateIsAfter,
  },
  enum: {
    is: enumMatch,
  },
}

export const areAllFiltersValid = (rules, columns) =>
  rules.every(rule => columns.some(column => column.dataKey === rule.column))
