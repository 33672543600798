import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  Icon,
  Section,
} from '@politechdev/blocks-design-system'

const ViewMoreButton = ({ children, count, isOpenByDefault, name }) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)
  const { t } = useTranslation()

  return !isOpen ? (
    <ButtonBlock>
      <Button.Secondary onClick={() => setIsOpen(true)}>
        <span>{`${t('Show additional')} ${t(name)}${
          count > 1 ? 's' : ''
        }`}</span>
        <Icon.ChevronDown />
      </Button.Secondary>
    </ButtonBlock>
  ) : (
    <Section>
      {children}
      <ButtonBlock>
        <Button.Secondary onClick={() => setIsOpen(false)}>
          <span>{`${t('Hide additional')} ${t(name)}${
            count > 1 ? 's' : ''
          }`}</span>
          <Icon.ChevronUp />
        </Button.Secondary>
      </ButtonBlock>
    </Section>
  )
}

export default ViewMoreButton
