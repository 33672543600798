import { MultiSearchSelect, SearchSelect } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import { buildOption, buildEventType } from './utils'

const EventTypeSelectFieldDEPRECATED = ({
  onSelect,
  eventType,
  eventTypes = [],
  isMulti,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    tenantOptions: { event_types: tenantEventTypes },
  } = useCurrent()
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(eventType)
  const [multiValue, setMultiValue] = useState(eventTypes.map(buildOption))

  useEffect(() => {
    setValue(eventType)
  }, [eventType])

  useEffect(() => {
    setMultiValue(eventTypes.map(buildOption))
  }, [JSON.stringify(eventTypes)])

  useEffect(() => {
    setOptions(tenantEventTypes.map(buildOption))
  }, [])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(buildEventType))
  }

  if (isMulti) {
    return (
      <MultiSearchSelect
        id="event-type-select"
        name="event-type-select"
        label={t('Event type')}
        value={multiValue}
        options={options}
        onChange={handleMultiChange}
        {...props}
      />
    )
  }

  return (
    <SearchSelect
      id="event-type-select"
      name="event-type-select"
      label={t('Event type')}
      value={value}
      options={options}
      onChange={handleChange}
      {...props}
    />
  )
}

export default EventTypeSelectFieldDEPRECATED
