import { useState, useEffect } from 'react'
import { getToken } from 'utils/auth'
import { Socket, Presence } from 'phoenix'
import { useRoutePathParams } from 'hooks/router'
import { useCurrent } from 'contexts/index'

const usePowerDialerSocket = ({ role = 'agent' } = {}) => {
  const { currentTenant: tenant } = useCurrent()
  const [pathParams] = useRoutePathParams()
  const { phoneBankId } = pathParams

  const [socket] = useState(
    new Socket(`wss://${process.env.API_HOSTNAME}/socket`, {
      params: { jwt: getToken(), tenant: tenant.subdomain },
    })
  )

  const [socketError, setSocketError] = useState(null)
  const [channel, setChannel] = useState(null)
  const [channelError, setChannelError] = useState(null)
  const [presence, setPresence] = useState(null)

  const pushToChannel = (...args) => {
    setChannelError(null)
    return channel?.push(...args).receive('error', error => {
      // eslint-disable-next-line no-console
      console.error({ ...error })
      setChannelError(error)
    })
  }

  useEffect(() => {
    socket.connect()
    // eslint-disable-next-line no-console
    socket.onClose(closeEvent => console.log(closeEvent))
    socket.onError(error => {
      // eslint-disable-next-line no-console
      console.error({ ...error })
      setSocketError(error)
      if (role === 'agent') {
        window.location.reload()
      } else {
        socket.disconnect()
      }
    })
    const channel = socket.channel(`power_dialer_session:${phoneBankId}`, {
      role,
    })

    channel.join().receive('error', error => setChannelError(error))

    setChannel(channel)
    setChannelError(null)
    setPresence(new Presence(channel))

    return () => {
      channel?.leave()
      socket?.disconnect()
    }
  }, [])

  return {
    socket,
    channel,
    presence,
    pushToChannel,
    channelError,
    socketError,
  }
}

export default usePowerDialerSocket
