import { calculatePercent } from 'utils/reporting'

export default [
  {
    dataKey: 'canvasser_name',
    title: 'Name',
    type: 'string',
    frozen: 'left',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      `${rowData.first_name} ${rowData.last_name}`,
  },
  {
    dataKey: 'turf_name',
    title: 'Turf Name',
    type: 'string',
    resizable: true,
  },
  {
    dataKey: 'all_time_forms_collected',
    title: 'All Time Forms Collected',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'all_time_forms_called',
    title: 'All Time Forms Called',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'all_time_forms_phones_percent',
    title: 'All Time Forms With Phones %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.all_time_forms_phones,
        rowData.all_time_forms_collected
      ),
  },
  {
    dataKey: 'all_time_disconnected_percent',
    title: 'All Time Disconnect %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.all_time_disconnected,
        rowData.all_time_forms_called
      ),
  },
  {
    dataKey: 'all_time_wrong_number_percent',
    title: 'All Time Wrong Number %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.all_time_wrong_number,
        rowData.all_time_forms_called
      ),
  },
  {
    dataKey: 'flags_this_week',
    title: 'Flags This Week',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'total_open_flags',
    title: 'Open Canvasser Flags',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'last_flagged_on',
    title: 'Threshold Crossed Last At',
    type: 'date',
    resizable: true,
  },
]
