import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ListSelect from '../ListSelect/ListSelect'
import FolderSelect from '../FolderSelect/FolderSelect'
import ListCreate from '../ListCreate/ListCreate'
import ListSingle from '../ListSingle/ListSingle'

const ListRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={ListSelect} />
    <Route exact path={`${match.path}/new`} component={ListCreate} />
    <Route path={`${match.path}/folders`} component={FolderSelect} />
    <Route path={`${match.path}/:listId`} component={ListSingle} />
  </Switch>
)

export default ListRoutes
