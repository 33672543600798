import {
  calculatePercent,
  formatDecimalCell,
  summaryFunctions,
} from 'utils/reporting'

export const partyDictionaryUrl =
  'https://docs.google.com/spreadsheets/d/1xABMR2DqVMaDx-u18b9gH-yh0-wh7P6Av2BVBU2zodE/edit#gid=1997694548'

export const basePartyColumns = [
  {
    dataKey: 'party',
    title: 'Party Preference',
    type: 'string',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      rowData.party ? rowData.party : 'OTHER PARTY / NO PARTY',
  },
  {
    dataKey: 'scans_qc',
    title: 'Forms (QC)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'percent_of_total_scans_qc',
    title: 'Percent of Total Forms (QC)',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.scans_qc, rowData.total_scans_qc),
    summaryFunction: ({ data }) =>
      summaryFunctions.percentOfGroupedTotal(
        data,
        'scans_qc',
        'total_scans_qc',
        'table_id'
      ),
  },
]
