import { useState, useEffect, useContext } from 'react'
import { ViewContainer, CardError } from 'components'
import {
  Sheet,
  ProgressBar,
  Tab,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useReactRouter, useRoutePathParams } from 'hooks'
import InboxViewer from 'qualityControl/inbox/InboxViewer'
import {
  COMPLETED_TAB,
  PHONE_VERIFICATION_TAB,
  VISUAL_QC_TAB,
} from 'constants/qualityControl'
import { isPacketCompleted } from 'utils/qualityControl'
import {
  PacketProvider,
  useCurrentPacket,
} from 'qualityControl/contexts/PacketContext'
import {
  CompletedPacketList,
  PhoneVerificationPacketList,
  VisualReviewPacketList,
} from './PacketList'
import InboxHeader from './InboxHeader/InboxHeader'
import { QCPacketFilterContext } from './QCPacketFilterContext/QCPacketFilterContext'
import { useInbox } from './InboxContext'

const InboxContentWrapper = params => {
  const { history } = useReactRouter()

  const [{ packetId }, replaceParams] = useRoutePathParams()
  const setActivePacket = id => history.push(replaceParams({ packetId: id }))

  return (
    <PacketProvider packetId={packetId} setPacketId={setActivePacket}>
      <InboxContent {...params} />
    </PacketProvider>
  )
}

const InboxContent = ({ isLoading, errorMsg, pageTitle }) => {
  const { t } = useTranslation()
  const [currentPacket, setCurrentPacket] = useCurrentPacket()
  const clearActivePacket = () => setCurrentPacket(undefined)

  const { lastActiveInboxTab: lastActiveTab, setLastActiveInboxTab } =
    useInbox()

  const { resetPacketFilters } = useContext(QCPacketFilterContext)

  const [activeTabId, setActiveTabId] = useState(0)

  useEffect(() => {
    lastActiveTab === VISUAL_QC_TAB && setActiveTabId(VISUAL_QC_TAB)
    lastActiveTab === PHONE_VERIFICATION_TAB &&
      setActiveTabId(PHONE_VERIFICATION_TAB)
    lastActiveTab === COMPLETED_TAB && setActiveTabId(COMPLETED_TAB)
  }, [])

  useEffect(() => {
    const url = window.location.href
    if (url.includes('phone_verification')) {
      isPacketCompleted(currentPacket)
        ? setActiveTabId(COMPLETED_TAB)
        : setActiveTabId(PHONE_VERIFICATION_TAB)
    } else {
      setActiveTabId(VISUAL_QC_TAB)
    }
  }, [currentPacket])

  const onTabChange = ({ tabId }) => {
    setLastActiveInboxTab(tabId)
    setActiveTabId(tabId)
    clearActivePacket()
    resetPacketFilters()
  }

  return (
    <>
      {errorMsg && (
        <CardError
          message={errorMsg}
          hide={!errorMsg}
          hideSupportLink={false}
        />
      )}
      <PageHeader
        title={pageTitle}
        subtitle={`${t('For')} ${moment().format('LL')}`}
      />
      <ViewContainer>
        {isLoading ? <ProgressBar show /> : <InboxHeader />}
        {!isLoading && (
          <Sheet>
            <Tab.Container onChange={onTabChange} selectedTabId={activeTabId}>
              <Tab label={t('Visual QC')} id={VISUAL_QC_TAB}>
                {activeTabId === VISUAL_QC_TAB ? (
                  <InboxViewer PacketList={VisualReviewPacketList} />
                ) : null}
              </Tab>
              <Tab label={t('Phone Verification')} id={PHONE_VERIFICATION_TAB}>
                {activeTabId === PHONE_VERIFICATION_TAB ? (
                  <InboxViewer PacketList={PhoneVerificationPacketList} />
                ) : null}
              </Tab>
              <Tab label={t('Completed')} id={COMPLETED_TAB}>
                {activeTabId === COMPLETED_TAB ? (
                  <InboxViewer PacketList={CompletedPacketList} />
                ) : null}
              </Tab>
            </Tab.Container>
          </Sheet>
        )}
      </ViewContainer>
    </>
  )
}

export default InboxContentWrapper
