import { Filter } from 'qualityControl/inbox/Filter'
import menuItems from 'qualityControl/inbox/menuItems'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { QCPacketFilterContext } from './QCPacketFilterContext/QCPacketFilterContext'
import { useInbox } from './InboxContext'

const DaysSinceCollectionFilter = () => {
  const { t } = useTranslation()
  const { isPendingPacketsEmpty: isDisabled } = useInbox()
  const { daysSinceCollection, setDaysSinceCollection } = useContext(
    QCPacketFilterContext
  )

  return (
    <Filter
      id="days_since_collection_filter"
      label={t('Days since collection')}
      value={daysSinceCollection}
      onChange={setDaysSinceCollection}
      menuItems={menuItems.daysSinceCollection}
      isDisabled={isDisabled}
    />
  )
}

export default DaysSinceCollectionFilter
