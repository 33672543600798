import { createContext, useContext, useEffect, useState } from 'react'
import { useQueryParams } from 'contexts'
import { useReactRouter, useRequest, useRoutePathParams } from 'hooks'
import { fetchDocuments } from 'requests/documents'
import { fetchFolders } from 'requests/folders'
import DocumentContext from '../DocumentContext/DocumentContext'

const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
  const { queryParams, setQueryParams } = useQueryParams()
  const [{ id: folderId }] = useRoutePathParams()
  const { location } = useReactRouter()
  const isSearch = location.pathname.includes('/search')

  const [query, setQuery] = useState(() => queryParams.query || '')
  const [isGlobal, setGlobal] = useState(() => queryParams.global === 'true')
  const [isPristine, setPristine] = useState(true)
  const { currentFolder: folder, getCurrentFolder } =
    useContext(DocumentContext)

  const {
    makeRequest: documentsRequest,
    isLoading: documentsLoading,
    hasErrors: documentsError,
    response: documentsResponse,
    clearRequest: clearDocuments,
  } = useRequest(fetchDocuments)

  const documents = documentsResponse ? documentsResponse.documents : []

  const {
    makeRequest: foldersRequest,
    isLoading: foldersLoading,
    hasErrors: foldersError,
    response: foldersResponse,
    clearRequest: clearFolders,
  } = useRequest(fetchFolders)

  const folders = foldersResponse ? foldersResponse.folders : []

  const resetState = () => {
    setQueryParams({
      query: undefined,
      global: undefined,
    })
    setQuery('')
    setGlobal(false)
    setPristine(true)
    clearDocuments()
    clearFolders()
  }

  useEffect(() => {
    !isSearch && resetState()
  }, [isSearch])

  const {
    makeRequest: folderRequest,
    isLoading: folderLoading,
    hasErrors: folderError,
  } = useRequest(getCurrentFolder)

  const isReady = folder.id === +folderId

  useEffect(() => {
    if (!isReady && folderId && isSearch) {
      folderRequest(folderId)
    }
  }, [folderId, isSearch])

  const makeSearch = () => {
    if (!isReady) return

    setPristine(false)
    setQueryParams({
      query,
    })
    documentsRequest({
      fields: ['id', 'name', 'folder_id', 'file_url', 'mime_type'],
      filters: {
        rules: [
          {
            column: 'name',
            operator: 'containing',
            param: query,
          },
          !isGlobal && {
            column: 'folder_lft',
            operator: 'before',
            param: folder.lft,
            invert: true,
          },
          !isGlobal && {
            column: 'folder_rgt',
            operator: 'after',
            param: folder.rgt,
            invert: true,
          },
        ].filter(Boolean),
      },
    })
    foldersRequest({
      fields: ['id', 'name', 'lft', 'rgt'],
      filters: {
        rules: [
          {
            column: 'name',
            operator: 'containing',
            param: query,
          },
          !isGlobal && {
            column: 'lft',
            operator: 'after',
            param: folder.lft,
          },
          !isGlobal && {
            column: 'rgt',
            operator: 'before',
            param: folder.rgt,
          },
          !isGlobal && {
            column: 'id',
            operator: 'is',
            param: folder.id,
            invert: 'true',
          },
        ].filter(Boolean),
      },
    })
  }

  useEffect(() => {
    !isPristine && makeSearch()
    setQueryParams({
      global: isGlobal ? true : undefined,
    })
  }, [isGlobal])

  useEffect(() => {
    if (query && isReady) {
      makeSearch()
    }
  }, [isReady])

  const isLoading = documentsLoading || foldersLoading || folderLoading
  const hasErrors = documentsError || foldersError || folderError
  const isSuccessful = !isLoading && !hasErrors
  const hasResults =
    (documentsResponse?.documents || []).length > 0 ||
    (foldersResponse?.folders || []).length > 0

  return (
    <SearchContext.Provider
      value={{
        query,
        setQuery,
        isPristine,
        isGlobal,
        setGlobal,
        isReady,
        makeSearch,
        isLoading,
        hasErrors,
        isSuccessful,
        hasResults,
        documents,
        folders,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = () => {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
}
