import { buildDefaultFilters, combineName } from 'utils/reporting'
import {
  baseDistrictColumns,
  baseDistrictFilters,
  districtDataDictionaryUrl,
} from '../columns/baseDistrictColumns'

export default {
  id: 'district_by_canvasser',
  name: 'Canvasser',
  description:
    'This report provides information on the legislative districts of registrants. Users can use the filter dropdown to toggle between district types. Districts are determined from the addresses collected on registration forms. This report summarizes the data by the canvasser who collected the form.',
  dataDictionaryUrl: districtDataDictionaryUrl,
  columns: [
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    ...baseDistrictColumns,
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, baseDistrictFilters)
  },
}
