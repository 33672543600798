import { useEffect } from 'react'
import { Sheet, SectionLabel, TextBlock } from 'components'
import { useTranslation } from 'react-i18next'
import { defaultTo } from 'lodash'
import StaffAssignmentCard from 'qualityControl/packetAssignment/staff/StaffAssignmentCard'
import StaffMemberPacketList from './list'
import ReassignmentButtons from './ReassignmentButtons'
import ReprioritizeButton from './ReprioritizeButton'
import { useAssignedCheckboxes } from '../CheckboxContextProvider'

const MainPanel = ({ user, refreshAssignedPackets }) => {
  const { t } = useTranslation()

  const { setCheckboxData } = useAssignedCheckboxes()

  useEffect(() => {
    setCheckboxData(defaultTo(user.assigned_packets, []), user)
  }, [user])

  return (
    <Sheet className="quality-control__packet-management__main-panel">
      <StaffAssignmentCard stacked user={user} />
      <div className="quality-control__packet-management__currently-assigned">
        <div className="quality-control__packet-management__currently-assigned__header">
          <TextBlock>
            <SectionLabel primary>{t('Currently Assigned')}</SectionLabel>
          </TextBlock>
          <div className="quality-control__packet-management__currently-assigned__actions">
            <ReassignmentButtons
              user={user}
              refreshAssignedPackets={refreshAssignedPackets}
            />
            <ReprioritizeButton
              user={user}
              refreshAssignedPackets={refreshAssignedPackets}
            />
          </div>
        </div>
        <StaffMemberPacketList user={user} />
      </div>
    </Sheet>
  )
}

export default MainPanel
