import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  ListItem,
  ContentBlock,
  Font,
  Icon,
} from '@politechdev/blocks-design-system'
import { StaffLanguages } from 'qualityControl/packetAssignment/staff/StaffLanguages'
import { formatDisplayName } from 'utils/formatting'
import { Timestamp } from 'components/Time/Timestamp'
import RecordingAudio from './RecordingAudio'

const RecordingItem = ({ call, isActive, setIsActive }) => {
  const { t } = useTranslation()
  const audioRef = useRef(null)

  const getParticipantName = call =>
    [
      call.phone_verification_call.form?.first_name,
      call.phone_verification_call.form?.last_name,
    ]
      .filter(Boolean)
      .join(' ')

  const shiftId = call.phone_verification_call.shift?.id
  const scanId = call.phone_verification_call.voter_registration_scan.id

  const handleOnPlay = () => {
    setIsActive()
    if (!isActive) {
      audioRef.current?.reset()
    }
  }

  useEffect(() => {
    if (!isActive) {
      audioRef.current.pause()
    }
  }, [isActive])

  return (
    <ListItem>
      <ContentBlock>
        <Font.Copy>{formatDisplayName(call.user)}</Font.Copy>
        <Font.Copy variant="hint">
          {t('with {{participantName}}', {
            participantName: getParticipantName(call),
          })}
        </Font.Copy>
      </ContentBlock>
      <ContentBlock>
        <StaffLanguages languages={call.user.spoken_language_locales} />
      </ContentBlock>
      <RecordingAudio
        ref={audioRef}
        recordingSid={call.twilio_recording_sid}
        isActive={isActive}
        onPlay={handleOnPlay}
        InactiveDisplayComponent={() => (
          <Font.Copy variant="hint">
            <span>{t('Call made on')}</span>{' '}
            <Timestamp dateTime={call.created_at} />
          </Font.Copy>
        )}
        LabelComponent={() => (
          <Font.Copy>
            <span>{`${t('Call ID')}: ${call.phone_verification_call.id}`}</span>
            {shiftId ? (
              <Link
                to={`/collect/voter_registration/shifts/${shiftId}/packet?scansId=${scanId}`}
              >
                <Icon.FileCircleInfo />
              </Link>
            ) : null}
          </Font.Copy>
        )}
      />
    </ListItem>
  )
}

export default RecordingItem
