import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { deleteEvent, fetchEvents } from 'requests/events'
import { formatErrorMessage } from 'utils/formatting'
import { useState } from 'react'
import tableConfig from './tableConfig'

const EventTable = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const { renderTable, refreshPage } = useAutoTable()

  const { doesCurrentUserHavePermission } = useCurrent()

  const [events, setEvents] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const canModifyEvent = doesCurrentUserHavePermission({
    resource: 'event',
    ability: 'modify',
  })

  const canDeleteEvent = doesCurrentUserHavePermission({
    resource: 'event',
    ability: 'remove',
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: eventsRequest } = useRequest(
    params =>
      fetchEvents({
        current_page: params?.page,
        per: params?.pageSize,
        sort_attr: params?.sortColumn,
        sort_dir: params?.sortOrder,
        filters: {
          rules: params?.filterRules,
          conjunction: params?.filterConjunction,
        },
        fields: [
          'id',
          'name',
          'start_time',
          'end_time',
          'organization_id',
          'attended_count',
          'invited_count',
          'walk_in_count',
          'no_show_count',
          'notes',
          'type',
          { organization: ['name'] },
          { teams: ['id', 'name'] },
          { location: ['name'] },
          { creator: ['name'] },
        ],
      }),
    {
      onSuccess: ({ events: incomingEvents, meta }) => {
        setEvents(incomingEvents)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const {
    makeRequest: deleteRequest,
    isLoading,
    errors,
  } = useRequest(deleteEvent, {
    onSuccess: refreshPage,
  })

  const handleEdit = rowData => {
    history.push(`${match.path}/${rowData.id}/edit`)
  }

  const handleDelete = rowData => {
    deleteRequest(rowData.id)
  }

  const columns = [
    {
      actions: [
        createRowAction(t('Edit'), handleEdit, canModifyEvent),
        createRowAction(t('Delete'), handleDelete, canDeleteEvent),
      ].filter(Boolean),
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  const errorMsg = formatErrorMessage(errors)

  return renderTable({
    data: events,
    loading: isLoading,
    error: errorMsg,
    totalRecords,
    columns,
    fetchAction: eventsRequest,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showFullTextQuickSearch
    showTableLink
  >
    <EventTable />
  </AutoTableProvider>
)
