import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './FreeformMultiSelectField.module.scss'

const cx = classNames.bind(styles)

const buildOption = label => ({
  label,
  value: label,
})

const FreeformMultiSelectFieldDEPRECATED = ({
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  required,
  clearable,
  error,
  errorText,
  className,
}) => {
  const { t } = useTranslation()

  const [entries, setEntries] = useState(value || [])
  const [input, setInput] = useState('')
  const [isFocused, setFocused] = useState(false)
  const [isPristine, setPristine] = useState(true)
  const selectRef = useRef()

  useEffect(() => {
    setEntries(value || [])
  }, [value])

  const addEntry = value => {
    const trimmedValue = value.trim()

    if (trimmedValue !== '' && !entries.find(entry => entry === trimmedValue)) {
      onChange && onChange([...entries, trimmedValue])
      setInput('')
    }
  }

  const focusSelect = () => {
    selectRef.current.focus()
  }

  const handleFocus = () => {
    setFocused(true)
    onFocus && onFocus()
  }

  const handleChange = val => {
    onChange && onChange((val || []).map(({ value }) => value))
  }

  const handleBlur = () => {
    setFocused(false)
    setPristine(false)
    addEntry(input)
    onBlur && onBlur()
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      addEntry(event.target.value)
    }
  }

  const hasValue = value?.length || entries?.length

  const fieldError = (!isPristine && required && !hasValue) || error
  const fieldErrorText =
    fieldError && required && !hasValue ? t('Required') : errorText

  return (
    <div className={styles.container}>
      <div
        className={cx('field', className, {
          'field--focused': isFocused,
          'field--error': fieldError,
          'field--has-value': hasValue,
        })}
      >
        <div className={styles.label} tabIndex="-1" onFocus={focusSelect}>
          {[label, required && '*'].filter(Boolean).join(' ')}
        </div>
        <CreatableSelect
          ref={selectRef}
          isClearable={!!clearable}
          isDisabled={disabled}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInputChange={setInput}
          inputValue={input}
          value={(value || entries).map(buildOption)}
          classNamePrefix="ReactCreatableSelect"
          menuIsOpen={false}
          components={{
            DropdownIndicator: null,
          }}
          isMulti
        />
      </div>
      {fieldError && <div className={styles.error}>{fieldErrorText}</div>}
    </div>
  )
}

FreeformMultiSelectFieldDEPRECATED.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
}

FreeformMultiSelectFieldDEPRECATED.defaultProps = {
  disabled: false,
  required: false,
  clearable: false,
  error: false,
  errorText: '',
}

export default FreeformMultiSelectFieldDEPRECATED
