import { getPacketDaysLeft } from 'utils/qualityControl'

export const PacketAssigneeNameCsvCell = data => data.assignee

export const PacketLanguageCsvCell = data =>
  data.turf.phone_verification_language

export const PacketLocationStateCsvCell = data => data.location.state

export const PacketDaysLeftCsvCell = getPacketDaysLeft

export const PacketTurfNameCsvCell = data => data.turf.name

export const PacketDateUploadedCsvCell = data => data.date_uploaded_at

export const PacketDateCollectedCsvCell = data => data.date_collected_at
