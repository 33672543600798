import {
  TextField,
  Button,
  MenuButton,
  DialogBlock,
  Radio,
  NumberField,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

export const NumStringMenuButton = ({
  isOpen,
  openMenu,
  operatorOptions,
  selectedOperator,
  setParam,
  handleDoneClick,
  column,
  handleCancelClick,
  param,
  setOperator,
  isInverted,
  setIsInverted,
}) => {
  const { t } = useTranslation()

  return (
    <MenuButton
      isOpen={isOpen}
      onToggle={openMenu}
      label={t(`${column.title}`)}
    >
      <MenuButton.Dialog>
        <div className="table-filters__column-list__header">
          <p className="table-filters__helper-text">{t('Select operator')}</p>
        </div>
        <DialogBlock>
          {operatorOptions.map(
            ({ prefix, value, invert, inputProps, suffix }) => {
              const { type, placeholder, ...restInputProps } = inputProps

              const isActive =
                selectedOperator === value && isInverted === invert

              return (
                <div
                  key={prefix}
                  className={`table-filters__operator-option ${
                    isActive ? 'active' : ''
                  }`}
                >
                  <Radio
                    label={t(prefix)}
                    value={value}
                    id={prefix}
                    name={prefix}
                    checked={isActive}
                    onChange={val => {
                      setOperator(val)
                      setIsInverted(invert)
                    }}
                  />
                  {isActive && (
                    <>
                      {type === 'number' ? (
                        <NumberField
                          className={`table-filters__param-input ${type}`}
                          id={`${value}-text-field`}
                          value={param}
                          onChange={val => setParam(val)}
                          {...restInputProps}
                          placeholder={t(placeholder)}
                        />
                      ) : (
                        <TextField
                          className={`table-filters__param-input ${type}`}
                          id={`${value}-text-field`}
                          value={param}
                          type={type}
                          onChange={val => setParam(val)}
                          {...restInputProps}
                          placeholder={t(placeholder)}
                        />
                      )}
                      {t(suffix)}
                    </>
                  )}
                </div>
              )
            }
          )}
        </DialogBlock>
        <div className="table-filters__column-list__footer">
          <Button.Secondary onClick={handleCancelClick}>
            {t('Cancel')}
          </Button.Secondary>
          <Button
            disabled={
              !selectedOperator ||
              param === '' ||
              param === undefined ||
              param === null
            }
            onClick={() => handleDoneClick(param)}
          >
            {t('Done')}
          </Button>
        </div>
      </MenuButton.Dialog>
    </MenuButton>
  )
}
