import { useEffect, useState, useRef, useContext } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { CardError, LoadBar, ContextMenu } from 'components'
import { Icon } from '@politechdev/blocks-design-system'
import { useContextMenu, useScroll, ScrollProvider } from 'contexts'
import { useRoutePathParams, useRequest } from 'hooks'
import { moveDocument } from 'requests/documents'
import FolderHeader from '../FolderHeader/FolderHeader'
import FolderContent from '../FolderContent/FolderContent'
import FolderUpload from '../FolderUpload/FolderUpload'
import { useModalTrigger } from '../ModalProvider/ModalProvider'
import {
  useFolderClipboard,
  useClipboardStatus,
} from '../ClipboardProvider/ClipboardProvider'
import { usePermissions } from '../PermissionsProvider/PermissionsProvider'
import { INTERACTABLE_TYPES, MODAL_TYPES } from '../constants'
import { isItemDocument, isItemFolder, isItemUpload } from '../utils'
import styles from './FolderSingle.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const FolderSingle = ({ scrollRef }) => {
  const { t } = useTranslation()
  const [{ id: folderId }] = useRoutePathParams()

  const uploadRef = useRef()
  const { canModify } = usePermissions()
  const { startScroll, endScroll } = useScroll()

  const { setDocuments, currentFolder, getCurrentFolder, moveFolder } =
    useContext(DocumentContext)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: moveDocumentRequest } = useRequest(
    folderProps => moveDocument(folderProps, currentFolder.id),
    {
      onSuccess: ({ documents }) => {
        setDocuments(documents)
      },
    }
  )

  const {
    makeRequest,
    isLoading: folderLoading,
    hasErrors: folderHasErrors,
    isRequestComplete,
  } = useRequest(getCurrentFolder)

  useEffect(() => {
    makeRequest(folderId)
  }, [folderId])

  const [uploads, setUploads] = useState([])

  const folderLoaded = isRequestComplete && !folderHasErrors

  const [{ isOver, isParent, isDescendant, isUpload }, dropRef] = useDrop({
    accept: [
      INTERACTABLE_TYPES.SIDEBAR_FOLDER,
      INTERACTABLE_TYPES.PANEL_FOLDER,
      INTERACTABLE_TYPES.PANEL_DOCUMENT,
      INTERACTABLE_TYPES.UPLOAD,
    ],
    hover: (item, monitor) => {
      if (isItemUpload(item)) return

      const { y: yPos } = monitor.getClientOffset()

      if (yPos < 180) {
        startScroll('up')
      } else if (window.innerHeight - yPos < 180) {
        startScroll('down')
      } else {
        endScroll()
      }
    },
    drop: (item, monitor) => {
      if (monitor.didDrop()) return
      endScroll()

      if (isItemDocument(item)) {
        moveDocumentRequest({
          documentId: item.id,
          folderId: currentFolder.id,
        })
      }

      if (isItemFolder(item)) {
        moveFolder(item.id, currentFolder.id)
      }

      if (isItemUpload(item)) {
        setUploads(item.files)
      }
    },
    collect: monitor => {
      const item = monitor.getItem()
      const isOver = monitor.isOver({ shallow: true })
      if (!item) {
        return {
          isOver,
        }
      }

      if (isItemUpload(item)) {
        return {
          isOver,
          isUpload: true,
        }
      }

      const isParent = currentFolder.id === item.parent_id
      if (isItemDocument(item)) {
        return {
          isOver,
          isParent,
        }
      }

      return {
        isOver,
        isParent,
        isDescendant:
          item.lft <= currentFolder.lft && item.rgt >= currentFolder.rgt,
      }
    },
    canDrop: item => {
      if (!canModify) return false
      if (!item) return false
      if (isItemUpload(item)) return true

      const isParent = currentFolder.id === item.parent_id
      if (isParent) return false

      if (isItemDocument(item)) return true

      return !(item.lft <= currentFolder.lft && item.rgt >= currentFolder.rgt)
    },
  })

  const openModal = useModalTrigger()
  const { showMenu } = useContextMenu({
    id: INTERACTABLE_TYPES.PANEL,
  })

  const { canPaste, pasteItem } = useFolderClipboard({
    folder: currentFolder,
  })

  const { isLoading: clipboardLoading, errorMsg: clipboardError } =
    useClipboardStatus()

  const isLoading = folderLoading || clipboardLoading

  return (
    <>
      <div className={styles.wrapper}>
        <div ref={scrollRef} className={styles.panel}>
          <div
            ref={dropRef}
            className={styles.container}
            onContextMenu={showMenu}
          >
            <LoadBar show={isLoading} className={styles.loadbar} />
            <CardError
              hide={!folderHasErrors}
              message={t("We're unable to retrieve this folder")}
            />
            <CardError
              hide={!clipboardError}
              message={t("We're unable to complete this action")}
            />
            {folderLoaded && (
              <>
                <FolderHeader uploadRef={uploadRef} />
                <FolderContent />
              </>
            )}
          </div>
        </div>
        {isOver && !isDescendant && !isParent && !isUpload && (
          <div className={styles.drop} />
        )}
        <FolderUpload
          isOver={isOver && isUpload}
          uploads={uploads}
          onComplete={() => setUploads([])}
        />
        <input
          id="upload"
          type="file"
          ref={uploadRef}
          className={styles.input}
          onChange={event => {
            setUploads([...event.target.files])
          }}
          multiple
        />
      </div>
      <ContextMenu id={INTERACTABLE_TYPES.PANEL}>
        <ContextMenu.Item
          label={t('Paste')}
          icon={<Icon.Paste alt="" />}
          onClick={pasteItem}
          isDisabled={!canPaste || !canModify}
        />
        <ContextMenu.Divider />
        <ContextMenu.Item
          label={t('Upload')}
          icon={<Icon.Upload alt="" />}
          isDisabled={!canModify}
          onClick={() => {
            uploadRef.current && uploadRef.current.click()
          }}
        />
        <ContextMenu.Item
          label={t('Create folder')}
          icon={<Icon.FolderPlus alt="" />}
          isDisabled={!canModify}
          onClick={() => {
            openModal(MODAL_TYPES.CREATE_FOLDER, {
              parentId: currentFolder.id,
            })
          }}
        />
      </ContextMenu>
    </>
  )
}

export default () => {
  const scrollRef = useRef()

  return (
    <ScrollProvider speed={2} targetRef={scrollRef}>
      <FolderSingle scrollRef={scrollRef} />
    </ScrollProvider>
  )
}
