import React from 'react'
import moment from 'moment'
import { List, ListItem } from 'components'
import { Font, TextBlock, ProgressBar } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const PhoneBankList = ({ phoneBanks, onClickPhoneBank, isLoading }) => {
  const { t } = useTranslation()
  const today = moment()

  const getSecondaryText = (startDate, endDate) => {
    const start = moment(startDate)
    const end = moment(endDate)
    if (today.isAfter(end, 'day')) return `Ended ${end.format('MMM D, YYYY')}`
    if (today.isBefore(start, 'day')) {
      return `Starts ${start.format('MMM D, YYYY')}`
    }
    return `Started ${start.format('MMM D, YYYY')}`
  }

  if (isLoading) return <ProgressBar show />

  return phoneBanks.length ? (
    <List>
      {phoneBanks.map(phoneBank => (
        <ListItem
          key={phoneBank.id}
          primaryText={phoneBank.name}
          secondaryText={getSecondaryText(
            phoneBank.start_date,
            phoneBank.end_date
          )}
          details={() => <div>{phoneBank.list.name}</div>}
          onClick={() => onClickPhoneBank(phoneBank.id)}
        />
      ))}
    </List>
  ) : (
    <TextBlock>
      <Font.Copy>{t('No phone banks')}</Font.Copy>
    </TextBlock>
  )
}

export default PhoneBankList
