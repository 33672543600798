import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import { SelectField } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchRoles } from 'requests/roles'
import { useCurrent } from 'contexts'
import { sortOptionsByLabel } from 'utils/select'
import { buildParams } from './utils'

const RoleSelect = ({ label, filters, fields, onSelect, role, ...props }) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'role',
    ability: 'view',
  })

  const [options, setOptions] = useState([])

  const buildOption = role => ({
    value: role.id,
    label: role.name,
    role,
  })

  const buildRole = option => option.role

  const { makeRequest, isLoading, hasErrors } = useRequest(fetchRoles, {
    onSuccess: ({ roles }) => {
      setOptions(
        sortOptionsByLabel(
          roles
            .reduce(
              (options, option) =>
                options.find(({ id }) => id === option.id)
                  ? options
                  : [...options, option],
              []
            )
            .map(buildOption)
        )
      )
    },
  })

  const [value, setValue] = useState(role?.id)
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  useEffect(() => {
    if (value !== role?.id) {
      const selection = buildRole(
        options.find(({ value: incomingValue }) => incomingValue === value)
      )
      onSelect && onSelect(selection)
    }
  }, [value])

  useEffect(() => {
    if (value !== role?.id) {
      setValue(role?.id)
    }
  }, [role])

  const getRoles = () => {
    const params = buildParams({
      query: debounced,
      filters,
      fields,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getRoles()
  }, [debounced])

  return (
    <>
      <CardError
        hide={!hasErrors}
        message={t("We're unable to retrieve these roles")}
      />
      <SelectField
        label={label || t('Role')}
        options={options}
        onSelect={setValue}
        onInputChange={setQuery}
        value={value || ''}
        isLoading={isLoading}
        disabled={!hasPermission}
        hintText={
          !hasPermission && t('You do not have permission to edit this field')
        }
        {...props}
      />
    </>
  )
}

RoleSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.array,
  fields: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  role: PropTypes.object,
}

RoleSelect.defaultProps = {
  id: 'role',
  filters: [],
  fields: undefined,
  role: null,
}

export default RoleSelect
