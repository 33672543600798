import { FieldBlock } from '@politechdev/blocks-design-system'
import {
  CanvasserFlagSwitchControl,
  CanvasserSelectControl,
  EndDatePickerControl,
  FlagIdInputControl,
  FlagTypeSelectControl,
  TurfSelectControl,
  NewCommentFilterControl,
  SortByFlagControl,
  StartDatePickerControl,
  TechnicalFlagSwitchControl,
} from './FilterControls'

const TableProgramFilters = ({ className, activeTab }) => (
  <div className={className}>
    <FieldBlock>
      <TechnicalFlagSwitchControl />
      <CanvasserFlagSwitchControl />
    </FieldBlock>
    <FieldBlock>
      <TurfSelectControl />
    </FieldBlock>
    <FieldBlock>
      <StartDatePickerControl activeTab={activeTab} />
      <EndDatePickerControl activeTab={activeTab} />
    </FieldBlock>
    <FieldBlock>
      <FlagTypeSelectControl activeTab={activeTab} />
      <FlagIdInputControl />
      <CanvasserSelectControl />
      <SortByFlagControl />
    </FieldBlock>
    <FieldBlock variant="medium">
      <NewCommentFilterControl />
    </FieldBlock>
  </div>
)

export default TableProgramFilters
