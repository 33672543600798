import { uniqBy } from 'lodash'
import { YES_NO_OPTIONS } from 'registrationReports/constants'
import {
  calculatePercent,
  formatDecimalCell,
  summaryFunctions,
  formatDistrictType,
} from 'utils/reporting'

const isOneDistrictTypeSelected = data => {
  const district_types = [...new Set(data.map(item => item.district_type))]
  return district_types.length === 1
}

export const districtDataDictionaryUrl =
  'https://docs.google.com/spreadsheets/d/1xABMR2DqVMaDx-u18b9gH-yh0-wh7P6Av2BVBU2zodE/edit#gid=1493379193'

export const baseDistrictColumns = [
  {
    dataKey: 'district_type',
    title: 'District Type',
    type: 'string',
    deriveFunction: ({ rowData }) => formatDistrictType(rowData.district_type),
    resizable: true,
  },
  {
    dataKey: 'district_name',
    title: 'District Name',
    type: 'string',
    resizable: true,
    alphaNumericSort: true,
  },
  {
    dataKey: 'target_district',
    title: 'Target District',
    options: YES_NO_OPTIONS,
    type: 'enum',
    resizable: true,
    deriveFunction: ({ rowData }) => (rowData.is_target ? 'Yes' : 'No'),
  },
  {
    dataKey: 'scans_qc',
    title: 'Forms with District (QC)',
    type: 'number',
    resizable: true,
    summaryFunction: ({ data }) =>
      isOneDistrictTypeSelected(data)
        ? summaryFunctions.sum({ data, key: 'scans_qc' })
        : NaN,
  },
  {
    dataKey: 'percent_of_total_scans_qc_with_value',
    title: 'Percent of Total Forms with District (QC)',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.scans_qc, rowData.total_scans_qc_with_value),
    summaryFunction: ({ data }) =>
      isOneDistrictTypeSelected(data)
        ? summaryFunctions.percentOfGroupedTotal(
            data,
            'scans_qc',
            'total_scans_qc_with_value',
            'table_id'
          )
        : NaN,
  },
  {
    dataKey: 'total_scans_qc',
    title: 'Total Forms (QC)',
    type: 'number',
    resizable: true,
    summaryFunction: ({ data }) => {
      const grouped_data = uniqBy(data, 'table_id')

      return summaryFunctions.sum({
        data: grouped_data,
        key: 'total_scans_qc',
      })
    },
  },
  {
    dataKey: 'percent_of_total_scans_qc',
    title: 'Percent of Total Forms (QC)',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.scans_qc, rowData.total_scans_qc),
    summaryFunction: ({ data }) =>
      isOneDistrictTypeSelected(data)
        ? summaryFunctions.percentOfGroupedTotal(
            data,
            'scans_qc',
            'total_scans_qc',
            'table_id'
          )
        : NaN,
  },
]

export const baseDistrictFilters = [
  {
    title: 'State Legislative Lower Districts',
    rules: [
      {
        column: 'district_type',
        operator: 'is',
        param: 'State Legislative Lower District',
        id: 'state_legislative_lower',
      },
    ],
    hiddenColumns: ['total_scans_qc'],
  },
  {
    title: 'State Legislative Upper Districts',
    rules: [
      {
        column: 'district_type',
        operator: 'is',
        param: 'State Legislative Upper District',
        id: 'state_legislative_upper',
      },
    ],
    hiddenColumns: ['total_scans_qc'],
  },
  {
    title: 'Congressional Districts',
    rules: [
      {
        column: 'district_type',
        operator: 'is',
        param: 'Congressional District',
        id: 'congressional',
      },
    ],
    hiddenColumns: ['total_scans_qc'],
  },
  {
    title: 'Target Districts',
    rules: [
      {
        column: 'target_district',
        operator: 'is',
        param: 'Yes',
        id: 'targets',
      },
    ],
    hiddenColumns: ['total_scans_qc'],
  },
]
