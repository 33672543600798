import { createContext, useEffect, useState } from 'react'
import { fetchEmailTemplate } from 'requests/email'
import { useReactRouter, useRequest } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'
import { DEFAULT_TEMPLATE_CONTENT, DEFAULT_TEMPLATE_COLORS } from './constants'

export const TemplateContext = createContext()

export const TemplateProvider = ({ children }) => {
  const [templateName, setTemplateName] = useState('')
  const [content, setContent] = useState(DEFAULT_TEMPLATE_CONTENT)
  const [colors, setColors] = useState(DEFAULT_TEMPLATE_COLORS)
  const [fontFamily, setFontFamily] = useState('Arial')
  const [themeName, setThemeName] = useState('Business')
  const [activeItemIndex, setActiveItemIndex] = useState(null)

  const { match } = useReactRouter()

  const {
    makeRequest: templateReq,
    isLoading,
    errors,
  } = useRequest(fetchEmailTemplate, {
    onSuccess: res => {
      const template = res['people/email_template']
      setTemplateName(template.name)
      if (template.template_content) {
        setContent(template.template_content.content)
        setColors(template.template_content.colors)
        setFontFamily(template.template_content.fontFamily)
        setThemeName(template.template_content.themeName)
      }
    },
  })

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    if (match.params?.id) {
      templateReq(match.params.id, { fields: ['name', 'template_content'] })
    }
  }, [match.params?.id])

  const updateColor = (type, color) => {
    setColors(prevColors => ({
      ...prevColors,
      [type]: color,
    }))
  }

  const removeItem = i =>
    setContent(prevContent => {
      const nextContent = [...prevContent]
      nextContent.splice(i, 1)
      return nextContent
    })

  const moveItemUp = i => {
    setContent(prevContent => {
      const temp = prevContent[i]
      const nextContent = [...prevContent]
      nextContent.splice(i, 1)
      nextContent.splice(i - 1, 0, temp)
      return nextContent
    })
  }

  const moveItemDown = i => {
    setContent(prevContent => {
      const temp = prevContent[i]
      const nextContent = [...prevContent]
      nextContent.splice(i, 1)
      nextContent.splice(i + 1, 0, temp)
      return nextContent
    })
  }

  const addItem = type => {
    setContent(prevContent => [
      ...prevContent,
      DEFAULT_TEMPLATE_CONTENT.find(
        defaultContent => defaultContent.type === type
      ),
    ])
  }

  const updateItemValue = (i, value) => {
    setContent(prevContent => {
      const nextContent = [...prevContent]
      const nextItem = { ...nextContent[i], value }
      nextContent.splice(i, 1, nextItem)
      return nextContent
    })
  }

  const updateFontFamily = fontFamily => {
    setFontFamily(fontFamily)
  }

  const updateTheme = (name, theme) => {
    setThemeName(name)
    Object.keys(theme).forEach(colorType => {
      updateColor(colorType, theme[colorType])
    })
  }

  return (
    <TemplateContext.Provider
      value={{
        content,
        removeItem,
        moveItemUp,
        moveItemDown,
        addItem,
        activeItemIndex,
        setActiveItemIndex,
        updateItemValue,
        updateColor,
        colors,
        fontFamily,
        updateFontFamily,
        themeName,
        updateTheme,
        templateName,
        setTemplateName,
        isLoading,
        errorMsg,
      }}
    >
      {children}
    </TemplateContext.Provider>
  )
}
