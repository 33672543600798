import ScriptSection from './Section/ScriptSection'
import ScriptText from './Text/ScriptText'
import ScriptQuestion from './Question/ScriptQuestion'

const ScriptItem = ({
  item,
  content,
  choices,
  loading,
  updateContent,
  isOnTop,
  isOnBottom,
  moveUp,
  moveDown,
  sectionNumber,
  setResponse,
  disabled,
  removeSelf,
  disableEditing,
}) => {
  if (item.is_section_divider) {
    return (
      <ScriptSection
        loading={loading}
        sectionNumber={sectionNumber}
        isOnTop={isOnTop}
        isOnBottom={isOnBottom}
        moveUp={moveUp}
        moveDown={moveDown}
        removeSelf={removeSelf}
        isEditable={!disableEditing}
      />
    )
  }

  if (item.script_text) {
    return (
      <ScriptText
        item={item}
        content={content}
        loading={loading}
        updateContent={updateContent}
        isOnTop={isOnTop}
        isOnBottom={isOnBottom}
        moveUp={moveUp}
        moveDown={moveDown}
        removeSelf={removeSelf}
        isEditable={!disableEditing}
      />
    )
  }

  if (item.question) {
    return (
      <ScriptQuestion
        item={item}
        content={content}
        choices={choices}
        loading={loading}
        setResponse={setResponse}
        disabled={disabled}
        updateContent={updateContent}
        isOnTop={isOnTop}
        isOnBottom={isOnBottom}
        moveUp={moveUp}
        moveDown={moveDown}
        removeSelf={removeSelf}
        isEditable={!disableEditing}
      />
    )
  }

  return null
}

export default ScriptItem
