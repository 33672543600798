import Appsignal from '@appsignal/javascript'
import { plugin as pathDecorator } from '@appsignal/plugin-path-decorator'
import { plugin as networkBreadCrumbs } from '@appsignal/plugin-breadcrumbs-network'

const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_TOKEN,
  ignoreErrors: [],
  revision: process.env.VERSION,
})

appsignal.use(pathDecorator())
appsignal.use(networkBreadCrumbs())

export { appsignal }
