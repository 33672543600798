import {
  MemberNameCell,
  MemberFirstNameCell,
  MemberLastNameCell,
  MemberPhoneNumberCell,
  MemberAddressCell,
  MemberCityCell,
  MemberStateCell,
  MemberZipCodeCell,
  MemberEmailCell,
} from './OrganizationMemberTableCells'
import {
  memberNameCsvCell,
  memberFirstNameCsvCell,
  memberLastNameCsvCell,
  memberStreetAddressCsvCell,
  memberCityCsvCell,
  memberStateCsvCell,
  memberZipcodeCsvCell,
  memberPhoneCsvCell,
  memberEmailCsvCell,
} from './csvCells'

export default {
  label: 'organization-members',
  columns: [
    {
      dataKey: 'full_name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: MemberNameCell,
      csvCell: memberNameCsvCell,
      filterOperators: [
        {
          prefix: 'contains',
          value: 'containing',
          inputProps: {
            type: 'text',
            placeholder: 'Text to search',
          },
        },
      ],
      filterConstructor: rule => ({
        rules: [
          {
            column: 'full_text_search',
            param: rule.param,
          },
          {
            column: 'full_name',
            operator: 'containing',
            param: rule.param,
          },
        ],
        conjunction: 'inclusive',
      }),
    },
    {
      dataKey: 'person_first_name',
      title: 'First name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberFirstNameCell,
      csvCell: memberFirstNameCsvCell,
    },
    {
      dataKey: 'person_last_name',
      title: 'Last name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberLastNameCell,
      csvCell: memberLastNameCsvCell,
    },
    {
      dataKey: 'phone_number',
      title: 'Phone Number',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberPhoneNumberCell,
      csvCell: memberPhoneCsvCell,
      filterConstructor: rule => ({
        rules: [
          {
            column: 'contact_methods_contact_type',
            operator: 'is',
            param: 'primary_phone_number',
          },
          {
            column: 'contact_methods_content',
            operator: rule?.operator || 'containing',
            param: rule?.param || '',
          },
        ],
        conjunction: 'exclusive',
      }),
    },
    {
      dataKey: 'residential_address_full_street',
      title: 'Address',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberAddressCell,
      csvCell: memberStreetAddressCsvCell,
    },
    {
      dataKey: 'residential_address_city',
      title: 'City',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberCityCell,
      csvCell: memberCityCsvCell,
    },
    {
      dataKey: 'residential_address_state',
      title: 'State',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberStateCell,
      csvCell: memberStateCsvCell,
    },
    {
      dataKey: 'residential_address_zipcode',
      title: 'Zip code',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberZipCodeCell,
      csvCell: memberZipcodeCsvCell,
    },
    {
      dataKey: 'email',
      title: 'Email',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberEmailCell,
      csvCell: memberEmailCsvCell,
      filterConstructor: rule => ({
        rules: [
          {
            column: 'contact_methods_contact_type',
            operator: 'is',
            param: 'primary_email_address',
          },
          {
            column: 'contact_methods_content',
            operator: rule?.operator || 'containing',
            param: rule?.param || '',
          },
        ],
        conjunction: 'exclusive',
      }),
    },
  ],
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: { hiddenColumns: ['person_first_name', 'person_last_name'] },
      },
    ]
  },
}
