import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { LoadBar } from 'components'
import { Button } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import {
  fetchDocumentsThroughFolder,
  uploadDocuments,
} from 'requests/documents'
import styles from './FolderUpload.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const cx = classNames.bind(styles)

const FolderUpload = ({ uploads = [], isOver = false, onComplete }) => {
  const { t } = useTranslation()
  const { setDocuments, currentFolder } = useContext(DocumentContext)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makeDocumentsRequest } = useRequest(
    fetchDocumentsThroughFolder,
    {
      onSuccess: ({ documents }) => {
        setDocuments(documents)
        onComplete()
      },
    }
  )
  const { makeRequest, isLoading, hasErrors } = useRequest(uploadDocuments, {
    onSuccess: () => {
      makeDocumentsRequest(currentFolder.id)
    },
  })
  useEffect(() => {
    uploads.length && makeRequest(currentFolder.id, uploads)
  }, [uploads])

  if (!isOver && !isLoading) return null

  return (
    <div
      className={cx('upload', {
        'upload--loading': isLoading,
        'upload--error': !!hasErrors,
      })}
      onContextMenu={event => {
        event.stopPropagation()
        event.preventDefault()
      }}
    >
      <span className={styles.message}>
        {hasErrors && t("We're unable to upload your files")}
        {!hasErrors && isLoading && t('Uploading your files')}
        {!hasErrors && !isLoading && t('Drop your files to upload')}
      </span>
      <LoadBar show={isLoading} className={styles.loadbar} />
      {hasErrors && (
        <div className={styles.button}>
          <Button.Danger onClick={onComplete}>{t('Close')}</Button.Danger>
        </div>
      )}
    </div>
  )
}

export default FolderUpload
