import {
  Grid,
  Section,
  DetailItem,
  Button,
  Icon,
} from '@politechdev/blocks-design-system'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { formatPhoneNumber } from 'utils/formatting'
import { formatDecimal } from 'utils/reporting'
import { CanvasserContext } from '../../CanvasserContext/CanvasserContext'
import { buildSummaryData } from '../statUtils'

const CanvasserSummary = () => {
  const { t } = useTranslation()
  const { currentCanvasser } = useContext(CanvasserContext)
  const {
    latestShift,
    firstShift,
    allTimeShifts,
    allTimeShiftHours,
    allTimeFieldHours,
  } = buildSummaryData(currentCanvasser)
  return (
    <Grid>
      <Section label={t('Canvassing summary')}>
        <DetailItem label={t('Latest shift')}>
          {latestShift || t('No shifts')}
        </DetailItem>
        <DetailItem label={t('First shift')}>
          {firstShift || t('No shifts')}
        </DetailItem>
        <DetailItem label={t('All time shifts')}>{allTimeShifts}</DetailItem>
        <DetailItem label={t('All time shift hours')}>
          {formatDecimal(allTimeShiftHours)}
        </DetailItem>
        <DetailItem label={t('All time field hours')}>
          {formatDecimal(allTimeFieldHours)}
        </DetailItem>
      </Section>
      <Section
        label={t('Contact information')}
        empty={!currentCanvasser.phone_number}
        emptyMessage={t('No contact information available')}
      >
        <DetailItem label={t('Primary phone')}>
          <a href={`tel:${currentCanvasser.phone_number}`}>
            <Button.Secondary>
              <Icon.Phone />
              <span>{formatPhoneNumber(currentCanvasser.phone_number)}</span>
            </Button.Secondary>
          </a>
        </DetailItem>
      </Section>
    </Grid>
  )
}

export default CanvasserSummary
