import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  TextBlock,
  Modal,
  Icon,
} from '@politechdev/blocks-design-system'
import { useRequest, useReactRouter } from 'hooks'
import { defaultErrorMessage } from 'utils/constants'
import { destroyPerson } from 'requests/people'
import styles from './PersonDeleteModal.module.scss'

const PersonDeleteModal = ({ person }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const { makeRequest, isLoading, errors, hasErrors } = useRequest(
    destroyPerson,
    {
      onSuccess: () => {
        closeModal()
        history.push('/organize/people')
      },
    }
  )

  const errorMessage =
    Object.entries(errors)
      .map(([field, message]) => `${field}: ${message}`)
      .join(', ') || defaultErrorMessage

  return (
    <>
      <Button onClick={openModal}>
        <Icon.TrashAlt />
      </Button>
      <Modal
        id="person-delete"
        title={t(`Delete person`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={errorMessage}
              hideSupportLink
            />
            <TextBlock className={styles.text}>
              {t('Are you sure you want to delete')}{' '}
              <strong>{person.name}</strong>?
            </TextBlock>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                makeRequest(person.id)
              }}
            >
              {t('Delete')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default PersonDeleteModal
