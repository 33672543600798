import { useState } from 'react'
import { TextField } from 'components'
import {
  Button,
  ButtonBlock,
  Modal,
  TextBlock,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import useEvent from 'hooks/useEvent'

const SendTextDialog = ({ onClose, isOpen, onSend }) => {
  const [message, setMessage] = useState('')
  const [sent, setSent] = useState(false)
  const { t } = useTranslation()

  const close = () => {
    setTimeout(onClose, 300)
  }

  const {
    makeRequest,
    isLoading: sending,
    hasErrors: error,
  } = useRequest(onSend, {
    onSuccess: () => {
      setSent(true)
      setTimeout(close, 2000)
    },
  })

  const submitMessage = useEvent(() => {
    makeRequest(message)
  })

  const tooLong = message.length > 150

  return (
    <Modal id="text-list" title={t('Send SMS')} isOpen={isOpen}>
      <Modal.Body>
        <TextBlock>
          {sent ? (
            <p>{t('Your message has been sent!')}</p>
          ) : (
            <div>
              <TextBlock>
                <p>{t('Compose your SMS message below')}</p>
              </TextBlock>
              <FieldBlock>
                <TextField
                  id="text-message-body"
                  label={t('Message')}
                  value={message}
                  onChange={setMessage}
                  rows={2}
                  error={tooLong}
                />
              </FieldBlock>
              <TextBlock>
                <p style={tooLong ? { color: 'red' } : {}}>
                  {message.length} / {t('150 characters remaining')}
                </p>
              </TextBlock>
              {error ? (
                <p style={{ color: 'red' }}>
                  {t(
                    'Something went wrong sending your message. Please try again later.'
                  )}
                </p>
              ) : null}
            </div>
          )}
        </TextBlock>
      </Modal.Body>
      <Modal.Actions>
        {!sent && (
          <ButtonBlock justify="right">
            <Button.Secondary onClick={onClose}>{t('Cancel')}</Button.Secondary>
            <Button.Accent
              onClick={submitMessage}
              primary
              disabled={tooLong || sending}
            >
              {sending ? t('Sending...') : t('Send')}
            </Button.Accent>
          </ButtonBlock>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default SendTextDialog
