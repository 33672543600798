import { Blocks, Route, Config } from '../routesConfigTypes'

export const getRoutes = (routes: Config): Route[] => {
  const isRoute = (obj: Route | Blocks): obj is Route => 'path' in obj

  return Object.values(routes)
    .flatMap(rv => Object.values(rv))
    .filter(isRoute)
}

export const findPermissions = (
  routes: Route[],
  pathname: string
): Route['permissions'] | undefined =>
  routes.find(fr => pathname.includes(fr.path))?.permissions
