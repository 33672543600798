import { useTranslation } from 'react-i18next'
import { SelectField } from '@politechdev/blocks-design-system'
import { supportedTimezones } from 'utils/dateTime'
import { sortOptionsByLabel } from 'utils/select'

const timezoneOptions = [...supportedTimezones].map(([value, label]) => ({
  value,
  label,
}))

export const TimeZoneSelectField = ({
  id = 'timezone',
  label = 'Timezone',
  value,
  onSelect,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <SelectField
      id={id}
      label={t(label)}
      options={sortOptionsByLabel(timezoneOptions)}
      value={value}
      onSelect={onSelect}
      {...rest}
    />
  )
}
