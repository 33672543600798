import {
  Button,
  ButtonBlock,
  ContentBlock,
  TextBlock,
  Font,
  List,
  ListItem,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { maskPhone } from 'utils/inputMasks'
import { Link } from 'react-router-dom'

const PotentialDuplicatesWarning = ({ potentialDuplicates }) => {
  const { t } = useTranslation()

  return (
    <>
      <TextBlock>
        <Font.Copy Element="p">
          {t(
            'Potential duplicate records were found. Are you sure this person is not already stored in Blocks?'
          )}
        </Font.Copy>
      </TextBlock>
      <List
        itemData={potentialDuplicates}
        render={person => (
          <ListItem key={person.id}>
            <ContentBlock>
              <Font.Copy>
                <Link to={`/organize/people/${person.id}`}>{person.name}</Link>
              </Font.Copy>
              <Font.Copy variant="hint">
                {t('Email')}: {person.primary_email_address || t('None')},{' '}
                {t('Phone')}:{' '}
                {person.primary_phone_number
                  ? maskPhone(person.primary_phone_number)
                  : t('None')}
              </Font.Copy>
            </ContentBlock>
          </ListItem>
        )}
      />
    </>
  )
}

PotentialDuplicatesWarning.Actions = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation()

  return (
    <ButtonBlock justify="right">
      <Button.Secondary onClick={onConfirm}>
        {t('Ignore warning and create')}
      </Button.Secondary>
      <Button onClick={onCancel}>{t('Cancel')}</Button>
    </ButtonBlock>
  )
}

export default PotentialDuplicatesWarning
