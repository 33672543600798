import { useState, useEffect, memo } from 'react'
import { LoadBar, CardError } from 'components'
import { FontIcon } from 'react-md'

export const DefaultErrorCard = ({ errorMessage, close }) => (
  <div className="default-error-card">
    <FontIcon onClick={close} className="default-error-card__close">
      close
    </FontIcon>
    <CardError
      hide={false}
      hideSupportLink
      message={errorMessage || 'An error occured while fetching this data.'}
    />
  </div>
)

export const DefaultSortIcon = ({ sortOrder }) => (
  <FontIcon className="BaseTable__sort-indicator">
    {sortOrder === 'asc' ? 'arrow_upward' : 'arrow_downward'}
  </FontIcon>
)

export const DefaultEmptyState = ({ message }) => (
  <div className="BaseTable__empty">
    <p>{message}</p>
  </div>
)

export const DefaultLoadingState = memo(({ loading, headerHeight, error }) => {
  const [show, setShow] = useState(!!loading || !!error)
  const handleClose = () => setShow(false)

  useEffect(() => setShow(!!loading || !!error), [loading, error])

  if (show) {
    return (
      <div className="BaseTable__overlay">
        <div
          className="BaseTable__overlay-header"
          style={{ height: `${headerHeight}px` }}
        />
        <div
          className={`BaseTable__overlay-body ${error ? 'error-overlay' : ''}`}
        >
          {loading && <LoadBar show />}
        </div>
        {error && <DefaultErrorCard close={handleClose} errorMessage={error} />}
      </div>
    )
  }

  return null
})
