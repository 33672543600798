export const TEMPLATE_ITEM_OPTIONS = {
  TITLE: 'title',
  HEADING: 'heading',
  IMAGE: 'image',
  BODY_COPY: 'body-copy',
  BUTTON_LINK: 'button-link',
}

export const DEFAULT_TEMPLATE_COLORS = {
  background: '#F2FBFE',
  [TEMPLATE_ITEM_OPTIONS.TITLE]: '#5B6163',
  [TEMPLATE_ITEM_OPTIONS.HEADING]: '#1A1A1A',
  [TEMPLATE_ITEM_OPTIONS.BODY_COPY]: '#1A1A1A',
  [TEMPLATE_ITEM_OPTIONS.BUTTON_LINK]: '#348DDF',
}

export const DEFAULT_TEMPLATE_CONTENT = [
  {
    type: TEMPLATE_ITEM_OPTIONS.TITLE,
    value: 'Your email title here',
  },
  {
    type: TEMPLATE_ITEM_OPTIONS.HEADING,
    value: 'This is a heading',
  },
  {
    type: TEMPLATE_ITEM_OPTIONS.BODY_COPY,
    value: 'This is a text item, you can add any text here',
  },
  {
    type: TEMPLATE_ITEM_OPTIONS.BUTTON_LINK,
    value: {
      name: 'Click here',
      url: '',
    },
  },
  {
    type: TEMPLATE_ITEM_OPTIONS.IMAGE,
    value: null,
  },
]

export const TEMPLATE_COLOR_OPTIONS = Object.keys(DEFAULT_TEMPLATE_COLORS)
