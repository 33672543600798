import { Link } from 'react-router-dom'
import { Sticky } from 'components'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { useModalTrigger } from '../ModalProvider/ModalProvider'
import { usePermissions } from '../PermissionsProvider/PermissionsProvider'
import FolderBreadcrumbs from '../FolderBreadcrumbs/FolderBreadcrumbs'
import { MODAL_TYPES } from '../constants'
import styles from './FolderHeader.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const FolderHeader = ({ uploadRef }) => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { canModify } = usePermissions()
  const openModal = useModalTrigger()
  const { currentFolder: folder } = useContext(DocumentContext)

  return (
    <Sticky className={styles.header} stuckClassName={styles['header--stuck']}>
      <FolderBreadcrumbs />
      <div className={styles.content}>
        <h2 className={styles.title}>{folder.name}</h2>
        <div className={styles.actions}>
          <Link to={`${match.url}/search`}>
            <Button.Secondary className={styles.action}>
              <Icon.Search alt={t('Search')} />
            </Button.Secondary>
          </Link>
          <Button.Secondary
            className={styles.action}
            disabled={!canModify}
            onClick={() => {
              openModal(MODAL_TYPES.CREATE_FOLDER, {
                parentId: folder.id,
              })
            }}
          >
            <Icon.FolderPlus alt={t('Add Folder')} />
          </Button.Secondary>
          <Button.Secondary
            className={styles.action}
            disabled={!canModify}
            onClick={() => {
              uploadRef.current && uploadRef.current.click()
            }}
          >
            <Icon.Upload alt={t('Upload file')} />
          </Button.Secondary>
        </div>
      </div>
    </Sticky>
  )
}

export default FolderHeader
