import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import {
  AutoTableProvider,
  useCurrent,
  useAutoTable,
  useFilters,
  usePagination,
  useSort,
} from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { fetchTeams, destroyTeam } from 'requests/teams'
import tableConfig, { teamTypeColumn } from './tableConfig'

const buildError = (
  fetchHasErrors,
  fetchErrors,
  deleteHasErrors,
  deleteErrors
) => {
  if (fetchHasErrors) {
    return Object.values(fetchErrors).join(', ')
  }
  if (deleteHasErrors) {
    return Object.values(deleteErrors).join(', ')
  }
  return false
}

const TeamTable = ({ inactive = false }) => {
  const [teams, setTeams] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const {
    tenantOptions: { team_types: types },
  } = useCurrent()

  const { filterConjunction, filterRules } = useFilters()
  const { pageSize, page } = usePagination()
  const { sortColumn, sortOrder } = useSort()
  const { renderTable, refreshPage, searchValue } = useAutoTable()

  const {
    makeRequest: fetchRequest,
    isLoading: fetchLoading,
    hasErrors: fetchHasErrors,
    errors: fetchErrors,
  } = useRequest(fetchTeams, {
    onSuccess: ({ teams, meta }) => {
      setTeams(teams)
      setTotalRecords(meta.total_count)
    },
  })

  const {
    makeRequest: deleteRequest,
    isLoading: deleteLoading,
    hasErrors: deleteHasErrors,
    errors: deleteErrors,
  } = useRequest(destroyTeam, {
    onSuccess: () => {
      refreshPage()
      fetchRequest({
        current_page: page,
        per: pageSize,
        sort_attr: sortColumn || 'id',
        sort_dir: sortOrder || 'asc',
        filters: {
          rules: [
            ...filterRules,
            searchValue && {
              column: 'name',
              operator: 'containing',
              param: searchValue,
            },
            {
              column: 'active',
              operator: 'is',
              param: !inactive,
            },
          ],
          conjunction: filterConjunction,
        },
        fields: [
          'id',
          'name',
          'members_count',
          'type',
          { organizations: ['id', 'name', 'city'] },
          { organizer: ['name'] },
          { leader: ['name', 'primary_phone_number'] },
        ],
        associations: ['organizations', 'organizer', 'leader'],
      })
    },
  })

  const isLoading = fetchLoading || deleteLoading
  const errorMsg = buildError(
    fetchHasErrors,
    fetchErrors,
    deleteHasErrors,
    deleteErrors
  )

  const handleEdit = rowData => {
    history.push(`${match.url}/${rowData.id}/edit`)
  }

  const handleDelete = rowData => {
    deleteRequest(rowData)
  }

  const columns = [
    {
      actions: [
        createRowAction(t('Edit'), handleEdit),
        createRowAction(t('Delete'), handleDelete),
      ],
      listInline: true,
    },
    ...tableConfig.columns,
    ...(types.length === 0 ? [] : [teamTypeColumn]),
  ]

  return renderTable({
    data: teams,
    loading: isLoading,
    error: errorMsg,
    totalRecords,
    columns,
    fetchAction: async tableProps => {
      await fetchRequest({
        current_page: tableProps.page,
        per: tableProps.pageSize,
        sort_attr: tableProps.sortColumn || 'id',
        sort_dir: tableProps.sortOrder || 'asc',
        filters: {
          rules: [
            ...tableProps.filterRules,
            {
              column: 'active',
              operator: 'is',
              param: !inactive,
            },
          ],
          conjunction: tableProps.filterConjunction,
        },
        fields: [
          'id',
          'name',
          'members_count',
          'type',
          { organizations: ['id', 'name', 'city'] },
          { organizer: ['name'] },
          { leader: ['name', 'primary_phone_number'] },
        ],
        associations: ['organizations', 'organizer', 'leader'],
      })
    },
  })
}

export default ({ inactive }) => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
  >
    <TeamTable inactive={inactive} />
  </AutoTableProvider>
)
