import React from 'react'
import { Switch, Route } from 'react-router-dom'
import OrganizationSelect from '../OrganizationSelect/OrganizationSelect'
import OrganizationNew from '../OrganizationNew/OrganizationNew'
import OrganizationSingle from '../OrganizationSingle/OrganizationSingle'
import OrganizationEdit from '../OrganizationEdit/OrganizationEdit'

const OrganizationRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={OrganizationSelect} />
    <Route exact path={`${match.path}/new`} component={OrganizationNew} />
    <Route exact path={`${match.path}/:id`} component={OrganizationSingle} />
    <Route exact path={`${match.path}/:id/edit`} component={OrganizationEdit} />
  </Switch>
)

export default OrganizationRoutes
