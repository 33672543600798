import { mean } from 'lodash/math'
import moment from 'moment-timezone'
import {
  buildDefaultFilters,
  calculateRate,
  combineName,
} from 'utils/reporting'
import { customRatio } from '../columnTotals'

export default {
  id: 'qc_phone_calls_by_user',
  name: 'Phone Calls by QC Staff',
  description: 'This report provides information on QC calls grouped by user.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1396168495',
  isTimeline: true,
  nonTimelineColumns: ['email', 'first_name', 'last_name', 'qc_office'],
  aggregateKeys: ['email'],
  columns: [
    {
      dataKey: 'caller_name',
      title: 'Staff name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.first_name, rowData.last_name),
    },
    {
      dataKey: 'qc_office',
      title: 'QC Office',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'total_calls_day',
      title: 'Total calls (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_calls_week',
      title: 'Total calls (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_calls_all_time',
      title: 'Total calls (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_contacts_day',
      title: 'Total contacts (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_contacts_week',
      title: 'Total contacts (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_contacts_all_time',
      title: 'Total contacts (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'contact_rate_day',
      title: 'Contact rate (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_contacts_day, rowData.total_calls_day),
      summaryFunction: ({ data, acc }) =>
        customRatio(data, acc, ['total_contacts_day', 'total_calls_day']),
    },
    {
      dataKey: 'contact_rate_week',
      title: 'Contact rate (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_contacts_week, rowData.total_calls_week),
      summaryFunction: ({ data, acc }) =>
        customRatio(data, acc, ['total_contacts_week', 'total_calls_week']),
    },
    {
      dataKey: 'contact_rate_all_time',
      title: 'Contact rate (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_contacts_all_time,
          rowData.total_calls_all_time
        ),
      summaryFunction: ({ data, acc }) =>
        customRatio(data, acc, [
          'total_contacts_all_time',
          'total_calls_all_time',
        ]),
    },
    {
      dataKey: 'total_verified_day',
      title: 'Total verified (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_verified_week',
      title: 'Total verified (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_verified_all_time',
      title: 'Total verified (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'verified_rate_day',
      title: 'Verified rate (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_verified_day, rowData.total_calls_day),
      summaryFunction: ({ data, acc }) =>
        customRatio(data, acc, ['total_verified_day', 'total_calls_day']),
    },
    {
      dataKey: 'verified_rate_week',
      title: 'Verified rate (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_verified_week, rowData.total_calls_week),
      summaryFunction: ({ data, acc }) =>
        customRatio(data, acc, ['total_verified_week', 'total_calls_week']),
    },
    {
      dataKey: 'verified_rate_all_time',
      title: 'Verified rate (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_verified_all_time,
          rowData.total_calls_all_time
        ),
      summaryFunction: ({ data, acc }) =>
        customRatio(data, acc, [
          'total_verified_all_time',
          'total_calls_all_time',
        ]),
    },
    {
      dataKey: 'avg_length_of_call_day',
      title: 'Avg length of call (in mins) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => rowData.avg_length_of_call_day * 1000,
      bodyCell: ({ cellData }) =>
        Number.isNaN(cellData)
          ? '-'
          : moment.utc(cellData).format('HH:mm:ss.S'),
      csvCell: ({ avg_length_of_call_day }) =>
        Number.isNaN(avg_length_of_call_day)
          ? avg_length_of_call_day
          : moment.utc(avg_length_of_call_day).format('HH:mm:ss.S'),
      summaryFunction: ({ data }) => {
        if (!data[0]) return '-'
        const allValues = Object.values(data).map(value =>
          parseFloat(value.avg_length_of_call_day)
        )
        const realNumberValues = allValues.filter(value => !Number.isNaN(value))
        const meanValue = mean(realNumberValues)
        if (Number.isNaN(meanValue)) {
          return '-'
        }
        return moment
          .utc(Math.round(meanValue / 100) * 100)
          .format('HH:mm:ss.S')
      },
    },
    {
      dataKey: 'avg_length_of_call_week',
      title: 'Avg length of call (in mins) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => rowData.avg_length_of_call_week * 1000,
      bodyCell: ({ cellData }) =>
        Number.isNaN(cellData)
          ? '-'
          : moment.utc(cellData).format('HH:mm:ss.S'),
      csvCell: ({ avg_length_of_call_week }) =>
        Number.isNaN(avg_length_of_call_week)
          ? avg_length_of_call_week
          : moment.utc(avg_length_of_call_week).format('HH:mm:ss.S'),
      summaryFunction: ({ data }) => {
        if (!data[0]) return '-'
        const allValues = Object.values(data).map(value =>
          parseFloat(value.avg_length_of_call_week)
        )
        const realNumberValues = allValues.filter(value => !Number.isNaN(value))
        const meanValue = mean(realNumberValues)
        if (Number.isNaN(meanValue)) {
          return '-'
        }
        return moment
          .utc(Math.round(meanValue / 100) * 100)
          .format('HH:mm:ss.S')
      },
    },
    {
      dataKey: 'avg_length_of_call_all_time',
      title: 'Avg length of call (in mins) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.avg_length_of_call_all_time * 1000,
      bodyCell: ({ cellData }) =>
        Number.isNaN(cellData)
          ? '-'
          : moment.utc(cellData).format('HH:mm:ss.S'),
      csvCell: ({ avg_length_of_call_all_time }) =>
        Number.isNaN(avg_length_of_call_all_time)
          ? avg_length_of_call_all_time
          : moment.utc(avg_length_of_call_all_time).format('HH:mm:ss.S'),
      summaryFunction: ({ data }) => {
        if (!data[0]) return '-'
        const allValues = Object.values(data).map(value =>
          parseFloat(value.avg_length_of_call_all_time)
        )
        const realNumberValues = allValues.filter(value => !Number.isNaN(value))
        const meanValue = mean(realNumberValues)
        if (Number.isNaN(meanValue)) {
          return '-'
        }
        return moment
          .utc(Math.round(meanValue / 100) * 100)
          .format('HH:mm:ss.S')
      },
    },
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Day',
        visibleColumns: [
          'caller_name',
          'qc_office',
          'total_calls_day',
          'total_contacts_day',
          'contact_rate_day',
          'total_verified_day',
          'verified_rate_day',
          'avg_length_of_call_day',
        ],
      },
      {
        title: 'Week',
        visibleColumns: [
          'caller_name',
          'qc_office',
          'total_calls_week',
          'total_contacts_week',
          'contact_rate_week',
          'total_verified_week',
          'verified_rate_week',
          'avg_length_of_call_week',
        ],
      },
      {
        title: 'All time',
        visibleColumns: [
          'caller_name',
          'qc_office',
          'total_calls_all_time',
          'total_contacts_all_time',
          'contact_rate_all_time',
          'total_verified_all_time',
          'verified_rate_all_time',
          'avg_length_of_call_all_time',
        ],
      },
    ])
  },
}
