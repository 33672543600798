import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  Font,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { CardError, TimerDisplay } from 'components'
import { useHistory, useParams } from 'react-router'
import { formatTimeGivenSeconds } from 'utils/dateTime'
import CallPanelInfo from './CallPanelInfo/CallPanelInfo'
import styles from './CallPanel.module.scss'

import {
  AGENT_STATUS,
  useAgentActions,
  useAgentState,
} from '../AgentContext/AgentContext'

const TimeToDisconnectDisplay = () => {
  const { t } = useTranslation()

  const { isOperatorDisconnected, timeToDisconnect } = useAgentState()

  if (isOperatorDisconnected) {
    return (
      <TextBlock>
        <Font.Copy variant="hint">
          {`${t('Session will end automatically in')} ${formatTimeGivenSeconds(
            timeToDisconnect
          )}`}
        </Font.Copy>
      </TextBlock>
    )
  }
  return null
}

const CallPanel = () => {
  const { t } = useTranslation()
  const { phoneBankId, id: sessionId } = useParams()
  const history = useHistory()

  const {
    status,
    dialerError,
    channelError,
    secondsElapsed,
    isOperatorReady,
    isOperatorDisconnected,
  } = useAgentState()
  const { endCall, setStatusReady, setStatusAway } = useAgentActions()

  const endSession = () =>
    history.push(
      `/organize/phone_banks/${phoneBankId}/call_sessions/${sessionId}/end`
    )

  const headerText = {
    [AGENT_STATUS.AWAY]: t('Preparation'),
    [AGENT_STATUS.READY]: `${t('Finding you a call')}...`,
    [AGENT_STATUS.ONCALL]: `${t('On a call')}...`,
    [AGENT_STATUS.REVIEW]: t('Reviewing answers'),
  }

  const dialButtonProps = {
    [AGENT_STATUS.AWAY]: {
      text: t('Accept calls'),
      action: setStatusReady,
      disabled: !isOperatorReady,
    },
    [AGENT_STATUS.REVIEW]: {
      text: t('Accept calls'),
      action: setStatusReady,
      disabled: true,
    },
    [AGENT_STATUS.READY]: {
      text: t('Pause'),
      action: setStatusAway,
    },
    [AGENT_STATUS.ONCALL]: {
      text: t('End call'),
      action: endCall,
    },
  }

  return (
    <div className={styles.panel}>
      <div className={styles.header}>
        <Font.Display variant="small" className={styles.label}>
          {headerText[status]}
        </Font.Display>
        {status === AGENT_STATUS.ONCALL ? (
          <TimerDisplay secondsElapsed={secondsElapsed} />
        ) : null}
      </div>
      <CardError
        hide={!dialerError}
        message={t('Sorry, something went wrong with this call')}
      />
      <CardError
        hide={!channelError}
        message={t('Sorry, something went wrong connecting to the dialer')}
      />
      {!isOperatorReady && !isOperatorDisconnected ? (
        <TextBlock>
          <Font.Copy variant="highlight">
            {t('Waiting for operator to start session')}
          </Font.Copy>
        </TextBlock>
      ) : null}
      {isOperatorDisconnected ? (
        <TextBlock>
          <Font.Copy
            variant="highlight"
            className={styles.operatorDisconnectErrorMessage}
          >
            {t('Error: Operator Disconnected')}
          </Font.Copy>
          <Font.Copy>
            {t(
              'We lost connection with your phone bank operator. Please wait while we try to reconnect you.'
            )}
          </Font.Copy>
        </TextBlock>
      ) : null}
      <CallPanelInfo />
      <ButtonBlock>
        <Button.Accent
          onClick={dialButtonProps[status]?.action}
          disabled={dialButtonProps[status]?.disabled}
        >
          {dialButtonProps[status]?.text}
        </Button.Accent>
        <Button.Secondary
          onClick={endSession}
          disabled={status === AGENT_STATUS.ONCALL}
          className={styles.endSessionBtn}
        >
          {t('End session')}
        </Button.Secondary>
      </ButtonBlock>
      <TimeToDisconnectDisplay />
    </div>
  )
}

export default CallPanel
