import { useTranslation } from 'react-i18next'
import { View, ViewContainer } from 'components'
import { PageHeader, Sheet } from '@politechdev/blocks-design-system'
import TeamForm from '../TeamForm/TeamForm'

const TeamNew = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <PageHeader title={t('New Team')} />
          <TeamForm />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default TeamNew
