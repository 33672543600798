import React from 'react'
import propTypes from 'prop-types'

const Row = ({ children }) => <tr>{children}</tr>

Row.propTypes = {
  children: propTypes.node.isRequired,
}

export default Row
