import React from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Cell.module.scss'

const cx = classNames.bind(styles)

const Cell = ({ children, center, right }) => (
  <td className={cx('cell', { 'cell--center': center, 'cell--right': right })}>
    {children}
  </td>
)

Cell.propTypes = {
  center: propTypes.string,
  right: propTypes.string,
  children: propTypes.node.isRequired,
}

export default Cell
