import React from 'react'
import { Switch, Route } from 'react-router-dom'
import i18n, { i18nConfig } from 'translate/i18n'
import PublicEventRoutes from './events/PublicEventRoutes/PublicEventRoutes'
import AuthRoutes from './auth/AuthRoutes/AuthRoutes'

const UnauthenticatedApp = () => {
  i18n.init(i18nConfig)

  return (
    <Switch>
      <Route path="/build/events" component={PublicEventRoutes} />
      <Route path="/" component={AuthRoutes} />
    </Switch>
  )
}

export default UnauthenticatedApp
