import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  PageHeader,
  Sheet,
  Button,
  ButtonBlock,
  Tab,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import { useCurrent } from 'contexts/index'
import TeamTable from '../TeamTable/TeamTable'

const TeamSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewTeams = doesCurrentUserHavePermission({
    resource: 'team',
    ability: 'view',
  })
  const canModifyTeams = doesCurrentUserHavePermission({
    resource: 'team',
    ability: 'modify',
  })

  if (!canViewTeams) {
    return (
      <CardError
        hide={canViewTeams}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('Teams')}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyTeams && e.preventDefault()}
            to={`${location.pathname}/new`}
          >
            <Button.Accent disabled={!canModifyTeams}>
              {t('Add team')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer>
        <Sheet>
          <Tab.Container>
            <Tab label={t('Active Teams')}>
              <TeamTable />
            </Tab>
            <Tab label={t('Inactive Teams')}>
              <TeamTable inactive />
            </Tab>
          </Tab.Container>
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default TeamSelect
