/* eslint-disable arrow-body-style */
import { useRequest, UseRequestState } from 'hooks/useRequest'
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { useCurrent } from 'contexts'
import { useToast } from '@politechdev/blocks-design-system'
import {
  defaultFlagParams,
  fetchFlags,
  fetchUnreadStatusForFlagGroups,
} from 'requests/flags'
import { useEvent } from 'hooks'
import { useTranslation } from 'react-i18next'
import { getFilteredFlagsRequestParams } from './utils'

export type FlagListItem = {
  id: number
  status: 'pending' | 'ready' | 'reviewed' | 'completed'
  action_plan: string
  created_at: string
  viewed_by_users: Array<{
    email: string
  }>
  comments: Array<{
    id: number
    body: string
    created_at: string
    viewed_by_users: Array<{ email: string }>
    author: { id: string; name: string; email: string }
  }>
  packet: {
    id: number
    shift_id: number
    original_filename: string
    scans_count: number
  }
  trigger: {
    id: number
    name: string
    implies_canvasser_issue: boolean
    needs_reupload: boolean
    resource_type: 'canvasser' | 'packet'
  }
  canvasser: { id: number; full_name: string }
  turf: { id: number; name: string }
  triggered_by_scans: Array<{
    id: number
    scan_number: number
    qc_history: Array<{
      created_at: string
    }>
    packet: {
      id: number
      original_filename: string
      scans_count: number
    }
  }>
  triggered_by_shift: {
    status:
      | 'ready_for_scans'
      | 'ready_for_cover_sheet_info'
      | 'ready_for_separation'
      | 'ready_for_qc'
      | 'in_qc'
      | 'ready_for_phone_verification'
      | 'in_phone_verification'
      | 'ready_for_delivery'
      | 'complete'
  }
}

type RequestMeta = {
  total_count: number
  per: number
  total_pages: number
}

type FetchFlagsResponse = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'quality_control/flags': Array<FlagListItem>
  meta: RequestMeta
}

export type TabTypes = 'table' | 'group' | 'resolution' | 'resolved'

type UnreadStatuses = Record<TabTypes, boolean>

type FlagsListPages = Record<TabTypes, number>

export type FlagsFilters = {
  flagName: string | undefined
  id: string | undefined
  startDate: string | undefined
  endDate: string | undefined
  turf: string | undefined
  canvasser: string | undefined
  canvasserFlag: boolean | undefined
  technicalFlag: boolean | undefined
  sortBy: string | undefined
  newComments: boolean | undefined
}

export const defaultFlagFilters: FlagsFilters = {
  flagName: '',
  id: '',
  startDate: undefined,
  endDate: undefined,
  turf: '',
  canvasser: '',
  canvasserFlag: true,
  technicalFlag: true,
  sortBy: '',
  newComments: false,
} as const

type FilterValue = string | boolean | undefined

export const FLAG_TABS = {
  TABLE: 'table',
  GROUP: 'group',
  RESOLUTION: 'resolution',
  RESOLVED: 'resolved',
} as const

type FlagsListGetAction = (
  flagFilters: FlagsFilters,
  page: number
) => Promise<FetchFlagsResponse>

const FlagsFilteredListContext = createContext<{
  unreadStatus: UnreadStatuses
  setUnreadStatus: Dispatch<SetStateAction<UnreadStatuses>>
  fetchUnreadStatusRequest: UseRequestState<UnreadStatuses>
  filters: FlagsFilters
  setFlagFilter: (attribute: string, value: FilterValue) => void
  listPages: FlagsListPages
  setListPages: Dispatch<SetStateAction<FlagsListPages>>
  setListPage: (tab: TabTypes, page: number) => void
  fetchTableFlags: FlagsListGetAction
  fetchGroupFlags: FlagsListGetAction
  fetchResolutionFlags: FlagsListGetAction
  fetchResolvedFlags: FlagsListGetAction
} | null>(null)

export const FlagsFilteredListContextProvider = ({
  children,
}: {
  children?: ReactNode | undefined
}) => {
  const { t } = useTranslation()
  const { setToast } = useToast()

  const {
    currentTurfPerformsExternalQC,
    currentUser: { id: userId, email: userEmail },
  } = useCurrent() as {
    currentTurfPerformsExternalQC: boolean
    currentUser: {
      id: number
      email: string
    }
  }

  const [listPages, setListPages] = useState<FlagsListPages>({
    [FLAG_TABS.TABLE]: 1,
    [FLAG_TABS.GROUP]: 1,
    [FLAG_TABS.RESOLUTION]: 1,
    [FLAG_TABS.RESOLVED]: 1,
  })

  const setListPage = (tab: TabTypes, page: number) =>
    setListPages(current => ({
      ...current,
      [tab]: page,
    }))

  const [unreadStatus, setUnreadStatus] = useState<UnreadStatuses>({
    [FLAG_TABS.TABLE]: false,
    [FLAG_TABS.GROUP]: false,
    [FLAG_TABS.RESOLUTION]: false,
    [FLAG_TABS.RESOLVED]: false,
  })

  const fetchUnreadStatusRequest = useRequest(
    () =>
      fetchUnreadStatusForFlagGroups(userEmail, currentTurfPerformsExternalQC),
    {
      onSuccess: setUnreadStatus,
      onError: () => {
        setToast({
          message: t('Failed to get unread status for flags groups'),
          variant: 'error',
        })
      },
    }
  )

  const [filters, setFilters] = useState<FlagsFilters>({
    ...defaultFlagFilters,
  })

  const setFlagFilter = (attribute: string, value: FilterValue) => {
    setFilters(current => ({
      ...current,
      [attribute]: value,
    }))
  }

  const fetchTableFlags = useEvent(
    (flagFilters: FlagsFilters, page: number) => {
      const params = getFilteredFlagsRequestParams(
        'table',
        flagFilters,
        page,
        [],
        currentTurfPerformsExternalQC,
        userId
      )

      return fetchFlags({
        ...defaultFlagParams,
        ...params,
      }) as Promise<FetchFlagsResponse>
    }
  )

  const fetchGroupFlags = useEvent(
    (flagFilters: FlagsFilters, page: number) => {
      const params = getFilteredFlagsRequestParams(
        'group',
        flagFilters,
        page,
        [],
        currentTurfPerformsExternalQC,
        userId
      )

      return fetchFlags({
        ...defaultFlagParams,
        ...params,
      }) as Promise<FetchFlagsResponse>
    }
  )

  const fetchResolutionFlags = useEvent(
    (flagFilters: FlagsFilters, page: number) => {
      const params = getFilteredFlagsRequestParams(
        'resolution',
        flagFilters,
        page,
        [],
        currentTurfPerformsExternalQC,
        userId
      )

      return fetchFlags({
        ...defaultFlagParams,
        ...params,
      }) as Promise<FetchFlagsResponse>
    }
  )

  const fetchResolvedFlags = useEvent(
    (flagFilters: FlagsFilters, page: number) => {
      const params = getFilteredFlagsRequestParams(
        'resolved',
        flagFilters,
        page,
        [],
        currentTurfPerformsExternalQC,
        userId
      )

      return fetchFlags({
        ...defaultFlagParams,
        ...params,
      }) as Promise<FetchFlagsResponse>
    }
  )

  return (
    <FlagsFilteredListContext.Provider
      value={{
        unreadStatus,
        setUnreadStatus,
        fetchUnreadStatusRequest,
        filters,
        setFlagFilter,
        listPages,
        setListPages,
        setListPage,
        fetchTableFlags,
        fetchGroupFlags,
        fetchResolutionFlags,
        fetchResolvedFlags,
      }}
    >
      {children}
    </FlagsFilteredListContext.Provider>
  )
}

export const useFlagsFilteredListContext = () => {
  const context = useContext(FlagsFilteredListContext)
  if (context === null) {
    throw new Error(
      'useFlagsFilteredListContext must be used within a FlagsFilteredListContextProvider'
    )
  }
  return context
}
