import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default ({ className }) => (
  <FontAwesomeIcon
    className={className}
    icon={faChevronRight}
    style={{ fontSize: '16px' }}
  />
)
