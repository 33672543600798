import { useState, useEffect, useRef } from 'react'
import { Device, Call } from '@twilio/voice-sdk'
import { noop } from 'lodash'
import { fetchJSON } from 'utils/req'
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors'

type TwilioCallCallback = (call: Call) => void
type TwilioErrorCallback = (error: TwilioError) => void

const fetchTwilioToken = (): Promise<string> =>
  fetchJSON<{ token: string }>(`/api/v1/twilio/token`, 'POST', null, {
    useJwt: true,
  }).then(response => response.token)

const defaultErrorCallback = (error: TwilioError) =>
  // eslint-disable-next-line no-console
  console.error('Twilio call error:', { error })

const useTwilioDevice = ({
  setupOptions,
  onConnect = noop,
  onDisconnect = noop,
  onError = defaultErrorCallback,
}: {
  setupOptions: Device.Options
  onConnect: TwilioCallCallback
  onDisconnect: TwilioCallCallback
  onError: TwilioErrorCallback
}) => {
  const deviceRef = useRef<Device | null>(null)
  const [customParams, setCustomParams] = useState({})

  const fetchToken = async (): Promise<string> => fetchTwilioToken()

  const destroyDialer = (): void => {
    deviceRef.current?.destroy()
  }

  useEffect(() => {
    fetchToken()
      .then(token => {
        deviceRef.current = new Device(token, setupOptions)
      })
      .catch(onError)
    return destroyDialer
  }, [])

  const setupDevice = async (): Promise<boolean> => {
    const device = deviceRef.current
    if (!device) return false

    device.on('error', onError)
    device.on('incoming', (call: Call) => {
      setCustomParams(call.customParameters)
      call.on('disconnect', (disconnectedCall: Call) => {
        onDisconnect(disconnectedCall)
        setCustomParams({})
      })
      onConnect(call)
      call.accept()
    })

    await device.register()

    return device.state === Device.State.Registered
  }

  const endCall = (): void => {
    deviceRef.current?.disconnectAll()
  }

  return { setupDevice, endCall, device: deviceRef, customParams }
}

export default useTwilioDevice
