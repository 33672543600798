import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactRouter, useRequest } from 'hooks'
import { View, Sheet, CardError, LoadBar, SubheaderBlock } from 'components'
import { fetchUser } from 'requests/users'
import UserForm from '../UserForm/UserForm'

const UserEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const { makeRequest, isRequestComplete, hasErrors, response } =
    useRequest(fetchUser)

  const selectedUser = response ? response.user : {}

  useEffect(() => {
    makeRequest(match.params.id, {
      fields: [
        'id',
        'first_name',
        'last_name',
        'email',
        'locale',
        'time_zone',
        'extras',
        'role_id',
        'turf_id',
        'training_status',
        'trained_in_states',
        'spoken_language_locales',
        'qc_office',
        { role: ['id', 'name', 'permissions', 'needs_training'] },
      ],
    })
  }, [match.params.id])

  const userLoaded = isRequestComplete && !hasErrors

  return (
    <View>
      <Sheet>
        <LoadBar show={!userLoaded} />
        <SubheaderBlock title={t('Edit user')} />
        <CardError
          hide={!hasErrors}
          message={t("We're unable to retrieve this user")}
        />
        {userLoaded && <UserForm user={selectedUser} />}
      </Sheet>
    </View>
  )
}

export default UserEdit
