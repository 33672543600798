import React from 'react'
import moment from 'moment'
import { Font, Icon } from '@politechdev/blocks-design-system'
import PropTypes from 'prop-types'
import styles from './TimerDisplay.module.scss'

const TimerDisplay = ({ secondsElapsed }) => {
  const formattedTimeElapsed = moment
    .utc(+secondsElapsed * 1000)
    .format('HH:mm:ss')

  return (
    <div className={styles.timer}>
      <Icon.Timer alt="timer" />
      <Font.Display
        variant="small"
        Element="span"
        className={styles.timer__text}
      >
        {formattedTimeElapsed}
      </Font.Display>
    </div>
  )
}

export default TimerDisplay

TimerDisplay.propTypes = {
  secondsElapsed: PropTypes.number,
}
