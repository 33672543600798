import React, { useEffect, useRef } from 'react'
import { useReactRouter, useRequest } from 'hooks'
import { Link, useParams } from 'react-router-dom'
import { ViewContainer, CardError, View } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
  ProgressBar,
  Sheet,
  Section,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import FlagSingleCanvasserDetail from 'qualityControl/flags/FlagSingleCanvasserDetail'
import { QueryParamProvider } from 'contexts/index'
import { defaultFlagParams } from 'requests/flags'
import { formatErrorMessage } from 'utils/formatting'
import { QueryParamContext } from 'contexts/QueryParamProvider/QueryParamProvider'
import { PacketProvider } from 'qualityControl/contexts/PacketContext'
import FlagSingleReadyButton from './FlagSingleReadyButton'
import FlagSingleActionPlan from './FlagSingleActionPlan'
import FlagSingleComments from './FlagSingleComments/FlagSingleComments'
import FlagSingleTriggeredScansList from './FlagSingleTriggeredScansList'
import FlagSinglePacketViewer from './FlagSinglePacketViewer'
import { getFlagItemPrimaryText, isFlagTechnical } from './utils'
import FlagSingleReuploadButton from './FlagSingleReuploadButton'
import FlagSingleAssociatedFlagsList from './FlagSingleAssociatedFlagsList/FlagSingleAssociatedFlagsList'
import FlagSingleDuplicateFlagsList from './FlagSingleDuplicateFlagsList/FlagSingleDuplicateFlagsList'
import {
  CurrentFlagProvider,
  useCurrentFlagContext,
} from './contexts/CurrentFlagContext'

const FlagSingleMain = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { originalType, reportOriginParams } = useParams()
  const { currentFlag, fetchFlagAction } = useCurrentFlagContext()

  const fetchFlagRequest = useRequest(fetchFlagAction)

  const previousLink = reportOriginParams
    ? `/reporting/reports/quality_control?${reportOriginParams}`
    : `/quality_control/flags/${originalType}`

  const packetViewerRef = useRef(null)

  useEffect(() => {
    fetchFlagRequest.makeRequest(match.params.flagId, defaultFlagParams)
  }, [match.params.flagId])

  const packetName = currentFlag?.packet.scan_name

  const flagCanvasser = currentFlag?.canvasser
  const isComplete = currentFlag?.status === 'complete'

  const needsReupload = currentFlag?.trigger.needs_reupload
  const isTechnical = !!currentFlag && isFlagTechnical(currentFlag)

  return (
    <View>
      <PageHeader
        title={getFlagItemPrimaryText(currentFlag)}
        subtitle={isTechnical && packetName}
      >
        <ButtonBlock justify="right">
          <Link to={previousLink}>
            <Button.Secondary>
              <Icon.Times />
            </Button.Secondary>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer>
        <Sheet>
          <CardError
            hide={!fetchFlagRequest.hasErrors}
            message={formatErrorMessage(fetchFlagRequest.errors)}
          />
          <ProgressBar show={fetchFlagRequest.isLoading} />
          {!fetchFlagRequest.isLoading &&
            !fetchFlagRequest.hasErrors &&
            !!currentFlag && (
              <>
                {needsReupload && isComplete ? (
                  <div />
                ) : (
                  <FlagSingleReuploadButton />
                )}
                <FlagSingleReadyButton />
                <FlagSingleDuplicateFlagsList />
                <Section label={t('Action Plan')}>
                  <FlagSingleActionPlan />
                </Section>
                <Section label={t('Resolution and Responses')}>
                  <FlagSingleComments />
                </Section>
                {flagCanvasser && <FlagSingleCanvasserDetail />}
                <FlagSingleAssociatedFlagsList />
                <FlagSingleTriggeredScansList scrollToRef={packetViewerRef} />
                <FlagSinglePacketViewer refProp={packetViewerRef} />
              </>
            )}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default () => (
  <CurrentFlagProvider>
    <QueryParamProvider>
      <QueryParamContext.Consumer>
        {({ queryParams, setQueryParams }) => (
          <PacketProvider
            packetId={queryParams.packetId}
            setPacketId={packetId => setQueryParams({ packetId })}
          >
            <FlagSingleMain />
          </PacketProvider>
        )}
      </QueryParamContext.Consumer>
    </QueryParamProvider>
  </CurrentFlagProvider>
)
