import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonBlock } from 'components'
import { useAuth } from 'contexts'
import AuthFrame from '../AuthFrame/AuthFrame'
import { useTwoFactorSetup } from '../SetupTwoFactor/SetupTwoFactor'

const SetupTwoFactorIntro = () => {
  const { t } = useTranslation()

  const { logout } = useAuth()
  const { nextPage } = useTwoFactorSetup()

  return (
    <div>
      <AuthFrame.Content>
        {t(
          'Your organization has requested that all of its users enable two-factor authentication on Blocks. Two-factor authentication increases the security of your account by verifying your identity via a unique one-time code that is sent to your cell phone.'
        )}
      </AuthFrame.Content>
      <AuthFrame.Actions>
        <ButtonBlock justify="spread">
          <Button raised primary onClick={nextPage}>
            {t('Set up now')}
          </Button>
          <Button primary onClick={logout}>
            {t('Sign out')}
          </Button>
        </ButtonBlock>
      </AuthFrame.Actions>
    </div>
  )
}

export default SetupTwoFactorIntro
