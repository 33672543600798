import { sumBy } from 'lodash'
import { useTranslation } from 'react-i18next'

const Schedule = ({ staffSchedules }) => {
  const { t } = useTranslation()

  return (
    <div className="quality-control__scheduler__summary">
      <div className="summary-display">
        <h2 className="summary-display__figure">{staffSchedules.length}</h2>
        <p className="summary-display__label">{t('Staff members')}</p>
      </div>
      <div className="summary-display">
        <h2 className="summary-display__figure">
          {sumBy(
            staffSchedules.filter(schedule => schedule.minutes),
            'minutes'
          ) / 60}
        </h2>
        <p className="summary-display__label">{t('Shift hours scheduled')}</p>
      </div>
    </div>
  )
}

export default Schedule
