import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedData } from 'components'
import { ContentBlock } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks/useRequest'
import {
  fetchPhoneBankCalls,
  fetchPhoneBankSessions,
} from 'requests/phoneBanks'

const VolunteerProfile = ({ volunteer }) => {
  const { t } = useTranslation()

  const {
    isLoading: phoneBankCallsLoading,
    hasErrors: phoneBankCallsErrorMsg,
    response: phoneBankCallsMetadata,
    makeRequest: phoneBankCallsRequest,
  } = useRequest(fetchPhoneBankCalls)

  const {
    isLoading: phoneBankSessionsLoading,
    hasErrors: phoneBankSessionsErrorMsg,
    response: phoneBankSessionsMetadata,
    makeRequest: phoneBankSessionsRequest,
  } = useRequest(fetchPhoneBankSessions)

  useEffect(() => {
    if (volunteer) {
      phoneBankCallsRequest({
        filters: {
          rules: [
            {
              column: 'caller',
              operator: 'is',
              param: volunteer.id,
            },
          ],
        },
        meta_only: true,
      })
      phoneBankSessionsRequest({
        filters: {
          rules: [
            {
              column: 'user',
              operator: 'is',
              param: volunteer.id,
            },
          ],
        },
        meta_only: true,
      })
    }
  }, [volunteer])

  const phoneBankCallsCount = useMemo(() => {
    if (phoneBankCallsLoading) {
      return t('Loading')
    }
    if (phoneBankCallsErrorMsg) {
      return t('Failed to get scan count')
    }

    return phoneBankCallsMetadata?.meta?.total_count || 0
  }, [
    phoneBankCallsLoading,
    phoneBankCallsErrorMsg,
    phoneBankCallsMetadata?.meta?.total_count,
  ])

  const phoneBankSessionsCount = useMemo(() => {
    if (phoneBankSessionsLoading) {
      return t('Loading')
    }
    if (phoneBankSessionsErrorMsg) {
      return t('Failed to get session count')
    }

    return phoneBankSessionsMetadata?.meta?.total_count || 0
  }, [
    phoneBankSessionsLoading,
    phoneBankSessionsErrorMsg,
    phoneBankSessionsMetadata?.meta?.total_count,
  ])

  return (
    <ContentBlock className="pb-session__profile">
      <span className="pb-label">{t('My profile')}</span>
      <h2>{volunteer.name}</h2>
      <ul>
        <li>
          {t('Calls made:')} {phoneBankCallsCount}
        </li>
        <li>
          {t('Sessions:')} {phoneBankSessionsCount}
        </li>
        <li>
          {t('Volunteer since:')}{' '}
          <FormattedData value={volunteer.created_at} type="date" />
        </li>
      </ul>
    </ContentBlock>
  )
}

export default VolunteerProfile
