import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getCurrentUserCheckin,
  getCurrentUserCheckinAnswers,
  getCheckinAnswers,
  postCheckinAnswer,
  putCheckinAnswer,
  deleteCheckinAnswers,
  getCheckin,
  postCheckinQuestion,
  putCheckinQuestion,
  postCheckin,
  putCheckin,
  deleteCheckin,
} from '../requests'

const useCheckins = callback => {
  const { t } = useTranslation()
  const defaultGetCheckinParams = {
    fields: [
      { questions: ['id', 'text', 'archived', 'position'] },
      { turf: ['id', 'name'] },
      'days_of_the_week',
      'end_date',
      'created_at',
      'id',
    ],
    associations: ['questions', 'turf'],
  }

  const defaultCurrentAnswersParams = {
    sort_dir: 'desc',
    fields: [
      'text',
      'id',
      { question: ['id', 'text', 'archived'] },
      'created_at',
      'updated_at',
    ],
    associations: ['question'],
  }

  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [currentUserCheckin, setCurrentUserCheckin] = useState()
  const [currentUserCheckinAnswers, setCurrentUserCheckinAnswers] = useState([])
  const [answerKey, setAnswerKey] = useState()

  useEffect(() => {
    const fetchCurrentUserCheckin = async () => {
      const response = await getCurrentUserCheckin(defaultGetCheckinParams)
      setCurrentUserCheckin(response['check_ins/check_in'] || null)
      setIsLoading(false)
    }
    fetchCurrentUserCheckin().catch(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    setIsLoading(true)
    const fetchCurrentUserCheckinAnswers = async () => {
      const response = await getCurrentUserCheckinAnswers(
        defaultCurrentAnswersParams
      )
      setCurrentUserCheckinAnswers(response['check_ins/check_in_answers'] || [])
      setIsLoading(false)
    }
    fetchCurrentUserCheckinAnswers()
  }, [])

  useEffect(() => {
    if (!answerKey) return
    setIsLoading(true)
    const saveAnswers = async () => {
      const answerRequestArray = answerKey.map(answer => {
        if (answer.created_at) {
          return putCheckinAnswer(answer.question.id, answer.text, answer.id)
        }
        return postCheckinAnswer(answer.question.id, answer.text)
      })
      await Promise.all(answerRequestArray)
      const response = await getCurrentUserCheckin(defaultGetCheckinParams)
      const answerResponse = await getCurrentUserCheckinAnswers(
        defaultCurrentAnswersParams
      )
      setCurrentUserCheckinAnswers(answerResponse['check_ins/check_in_answers'])
      setCurrentUserCheckin(response['check_ins/check_in'])
      setIsLoading(false)
      if (typeof callback === 'function') callback()
    }
    saveAnswers().catch(() => setIsLoading(false))
  }, [answerKey])

  const [adminIsLoading, setAdminIsLoading] = useState(true)
  const [adminTurfId, setAdminTurfId] = useState()
  const [currentAdminCheckin, setCurrentAdminCheckin] = useState()
  const [checkinToSave, setCheckinToSave] = useState()
  const [checkinToUpdate, setCheckinToUpdate] = useState()
  const [checkinToDelete, setCheckinToDelete] = useState()
  const [questionsToOrderSwitch, setQuestionsToOrderSwitch] = useState()
  const [questionToSave, setQuestionToSave] = useState()
  const [questionToArchive, setQuestionToArchive] = useState()
  const [questionToDelete, setQuestionToDelete] = useState()

  useEffect(() => {
    if (!adminTurfId) return
    setAdminIsLoading(true)
    const fetchAdminCheckin = async () => {
      const response = await getCheckin(adminTurfId, defaultGetCheckinParams)
      setCurrentAdminCheckin(response['check_ins/check_in'])
      setAdminIsLoading(false)
    }
    fetchAdminCheckin().catch(() => setAdminIsLoading(false))
  }, [adminTurfId])

  useEffect(() => {
    if (!checkinToSave) return
    setAdminIsLoading(true)
    setMessage(`${t('Saving check-in')}...`)
    const postCheckinFunction = async () => {
      const response = await postCheckin(checkinToSave, {
        fields: ['id'],
      })
      if (checkinToSave.initQuestion) {
        await postCheckinQuestion(
          response['check_ins/check_in'].id,
          checkinToSave.initQuestion,
          1
        )
      }
      const newCheckin = await getCheckin(adminTurfId, defaultGetCheckinParams)
      setCurrentAdminCheckin(newCheckin['check_ins/check_in'])
      setAdminIsLoading(false)
      setMessage('')
      if (typeof callback === 'function' && !checkinToSave.initQuestion) {
        callback()
      }
    }
    postCheckinFunction().catch(() => setAdminIsLoading(false))
  }, [checkinToSave])

  useEffect(() => {
    if (!checkinToUpdate) return
    setAdminIsLoading(true)
    setMessage(`${t('Saving check-in')}...`)
    const putCheckinFunction = async () => {
      await putCheckin(checkinToUpdate.id, checkinToUpdate)
      const response = await getCheckin(adminTurfId, defaultGetCheckinParams)
      setCurrentAdminCheckin(response['check_ins/check_in'])
      setAdminIsLoading(false)
      setMessage('')
      if (typeof callback === 'function') callback()
    }
    putCheckinFunction()
  }, [checkinToUpdate])

  useEffect(() => {
    if (!checkinToDelete) return
    const deleteCheckinCall = async () => {
      const archiveAllQuestionsArray = checkinToDelete.questions
        .filter(q => !q.archived)
        .map(q =>
          putCheckinQuestion(q.id, {
            archived: true,
            position: null,
          })
        )
      await Promise.all(archiveAllQuestionsArray)
      await deleteCheckin(checkinToDelete.id)
      const response = await getCheckin(adminTurfId, defaultGetCheckinParams)
      setCurrentAdminCheckin(response['check_ins/check_in'])
    }
    deleteCheckinCall()
  }, [checkinToDelete])

  useEffect(() => {
    if (!questionToSave) return
    setMessage(`${t('Saving question')}...`)
    const saveQuestion = async () => {
      await postCheckinQuestion(
        questionToSave.checkinId,
        questionToSave.text,
        questionToSave.position
      )
      const response = await getCheckin(adminTurfId, defaultGetCheckinParams)
      setCurrentAdminCheckin(response['check_ins/check_in'])
      setMessage('')
    }
    saveQuestion()
  }, [questionToSave])

  useEffect(() => {
    if (!questionToArchive) return
    const archiveQuestion = async () => {
      const questionsToAdjust = questionToArchive.questionsToAdjust.map(q =>
        putCheckinQuestion(q.id, {
          position: q.position - 1,
        })
      )
      await Promise.all([
        ...questionsToAdjust,
        putCheckinQuestion(questionToArchive.questionId, {
          archived: true,
          position: null,
        }),
      ])
      const response = await getCheckin(adminTurfId, defaultGetCheckinParams)
      setCurrentAdminCheckin(response['check_ins/check_in'])
    }
    archiveQuestion()
  }, [questionToArchive])

  useEffect(() => {
    if (!questionsToOrderSwitch) return
    const { questionToMoveUp, questionToMoveDown } = questionsToOrderSwitch
    const orderSwitchCall = async () => {
      const orderSwitchPromiseArray = [
        putCheckinQuestion(questionToMoveUp.id, {
          position: questionToMoveUp.position - 1,
        }),
        putCheckinQuestion(questionToMoveDown.id, {
          position: questionToMoveDown.position + 1,
        }),
      ]
      await Promise.all(orderSwitchPromiseArray)
      const response = await getCheckin(adminTurfId, defaultGetCheckinParams)
      setCurrentAdminCheckin(response['check_ins/check_in'])
    }
    orderSwitchCall()
  }, [questionsToOrderSwitch])

  const [reviewAnswers, setReviewAnswers] = useState()
  const [reviewIsLoading, setReviewIsLoading] = useState(false)
  const [reviewTurfId, setReviewTurfId] = useState()
  const [reviewUserId, setReviewUserId] = useState()
  const [reviewStartRange, setReviewStartRange] = useState()
  const [reviewEndRange, setReviewEndRange] = useState()
  const [reviewSortDirection, setReviewSortDirection] = useState('desc')
  const [answersPage, setAnswersPage] = useState(1)
  const [totalAnswers, setTotalAnswers] = useState(0)

  const answersPerPage = 25

  const defaultAnswersParams = {
    filters: {
      rules: [
        {
          column: 'in_turf_tree',
          param: reviewTurfId,
        },
        ...(reviewUserId && reviewUserId !== 0
          ? [
              {
                column: 'user_id',
                operator: 'is',
                param: reviewUserId,
              },
            ]
          : []),
        ...(reviewStartRange
          ? [
              {
                column: 'created_at',
                operator: 'after',
                param: reviewStartRange,
              },
            ]
          : []),
        ...(reviewEndRange
          ? [
              {
                column: 'created_at',
                operator: 'before',
                param: reviewEndRange,
              },
            ]
          : []),
      ],
    },
    sort_dir: reviewSortDirection,
    current_page: answersPage,
    per: answersPerPage,
    fields: [
      'text',
      'id',
      { question: ['id', 'text', 'archived', 'check_in_id', 'position'] },
      { user: ['name', 'id'] },
      { turf: ['name', 'id'] },
      'created_at',
    ],
    associations: ['question', 'user', 'turf'],
  }

  useEffect(() => {
    if (!reviewTurfId) {
      return setReviewAnswers([])
    }
    setReviewIsLoading(true)
    const fetchReviewAnswers = async () => {
      const response = await getCheckinAnswers(defaultAnswersParams)
      setReviewAnswers(response['check_ins/check_in_answers'])
      if (response.meta.total_count !== 0) {
        setTotalAnswers(response.meta.total_count)
      }
      setReviewIsLoading(false)
    }
    fetchReviewAnswers()
  }, [
    reviewTurfId,
    reviewUserId,
    reviewStartRange,
    reviewEndRange,
    reviewSortDirection,
  ])

  useEffect(() => {
    if (answersPage === 1) {
      return
    }
    if (!reviewTurfId) {
      return setReviewAnswers([])
    }
    setReviewIsLoading(true)
    const fetchReviewAnswers = async () => {
      const response = await getCheckinAnswers(defaultAnswersParams)
      setReviewAnswers(oldReviewAnswers =>
        oldReviewAnswers.concat(response['check_ins/check_in_answers'])
      )
      setReviewIsLoading(false)
    }
    fetchReviewAnswers()
  }, [reviewTurfId, answersPage])

  useEffect(() => {
    if (!questionToDelete) return
    const deleteQuestionData = async () => {
      const { questionId } = questionToDelete
      await deleteCheckinAnswers(questionId)
      const response = await getCheckinAnswers(defaultAnswersParams)
      setReviewAnswers(response['check_ins/check_in_answers'])
      if (typeof callback === 'function') callback()
    }
    deleteQuestionData()
  }, [questionToDelete])

  return {
    message,
    isLoading,
    currentUserCheckin,
    currentUserCheckinAnswers,
    setAnswerKey,
    adminIsLoading,
    adminTurfId,
    setAdminTurfId,
    currentAdminCheckin,
    setQuestionToSave,
    setQuestionToArchive,
    setCheckinToSave,
    setCheckinToUpdate,
    setCheckinToDelete,
    setQuestionsToOrderSwitch,
    setQuestionToDelete,
    totalAnswers,
    answersPage,
    reviewAnswers,
    reviewIsLoading,
    reviewTurfId,
    reviewUserId,
    reviewSortDirection,
    setAnswersPage,
    setReviewTurfId,
    setReviewUserId,
    setReviewStartRange,
    setReviewEndRange,
    setReviewSortDirection,
    answersPerPage,
  }
}

export default useCheckins
