import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  MultiSelectField,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'

const ResponsibilitySelectField = ({
  hasPermission,
  label,
  filters,
  onSelect,
  responsibility,
  responsibilities = [],
  isMulti = false,
  ...props
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(responsibility)
  const [multiValue, setMultiValue] = useState(responsibilities)

  const { responsibilityOptions } = useCurrent()

  useEffect(() => {
    setValue(responsibility)
  }, [responsibility])

  useEffect(() => {
    setMultiValue(responsibilities)
  }, [JSON.stringify(responsibilities)])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections)
  }

  return isMulti ? (
    <MultiSelectField
      label={label || t('Responsibility')}
      options={responsibilityOptions}
      onSelect={handleMultiChange}
      values={multiValue}
      {...props}
    />
  ) : (
    <SelectField
      label={label || t('Responsibility')}
      options={responsibilityOptions}
      onSelect={handleChange}
      value={value}
      {...props}
    />
  )
}

ResponsibilitySelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  responsibility: PropTypes.string,
}

ResponsibilitySelectField.defaultProps = {
  id: 'responsibility',
  responsibility: null,
}

export default ResponsibilitySelectField
