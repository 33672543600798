import {
  calculateRate,
  summaryFunctions,
  formatActiveColumn,
} from 'utils/reporting'
import { findOtherColTotals } from '../columnTotals'
import { YES_NO_OPTIONS } from '../../constants'

export default {
  id: 'qc_flags_by_turf',
  name: 'Turf',
  description: 'This report provides information on flags grouped by turf.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=150162290',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'active_turf',
      title: 'Active Turf',
      type: 'enum',
      options: YES_NO_OPTIONS,
      resizable: true,
      deriveFunction: ({ rowData }) =>
        formatActiveColumn(rowData.turf_archived),
    },
    {
      dataKey: 'total_packets_uploaded',
      title: 'Packets uploaded',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_scans_uploaded',
      title: 'Scans uploaded',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_flags',
      title: 'Total flags',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'canvasser_flags_per_hundred',
      title: 'Canvasser flags per 100 scans',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_canvasser_flags,
          rowData.total_scans_uploaded / 100
        ),
      summaryFunction: ({ data, acc }) => {
        const [val1, val2] = findOtherColTotals(data, acc, [
          'total_canvasser_flags',
          'total_scans_uploaded',
        ])
        return calculateRate(val1, val2 / 100)
      },
    },
    {
      dataKey: 'technical_flags_per_hundred',
      title: 'Technical flags per 100 scans',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_technical_flags,
          rowData.total_scans_uploaded / 100
        ),
      summaryFunction: ({ data, acc }) => {
        const [val1, val2] = findOtherColTotals(data, acc, [
          'total_technical_flags',
          'total_scans_uploaded',
        ])
        return calculateRate(val1, val2 / 100)
      },
    },
  ],
}
