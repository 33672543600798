import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import styles from './EventCalendarLegend.module.scss'

const cx = classNames.bind(styles)

const EventCalendarLegend = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.legend}>
      <div className={cx('item', 'event')}>
        <div className={styles.label}>
          <span>{t('Event')}</span>
        </div>
      </div>
      <div className={cx('item', 'meeting')}>
        <div className={styles.label}>
          <span>{t('Meeting')}</span>
        </div>
      </div>
    </div>
  )
}

export default EventCalendarLegend
