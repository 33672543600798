import moment from 'moment'

import {
  buildDefaultFilters,
  calculatePercent,
  calculateRate,
  combineName,
  summaryFunctions,
} from 'utils/reporting'

export default {
  id: 'qc_by_response',
  name: 'Response',
  description:
    'This report provides information on QC staff regarding the responses they have submitted for each scan reviewed.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1532106555',
  isTimeline: true,
  nonTimelineColumns: ['first_name', 'last_name', 'email'],
  aggregateKeys: ['email'],
  rowKey: 'email',
  columns: [
    {
      dataKey: 'qc_staffer',
      title: 'QC Staffer',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.first_name, rowData.last_name),
    },
    {
      dataKey: 'total_visual_qc_days_day',
      title: 'Total Visual QC Days (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_visual_qc_days_week',
      title: 'Total Visual QC Days (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_visual_qc_days_all_time',
      title: 'Total Visual QC Days (All Time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_day',
      title: 'Total Visual QC Scans (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_week',
      title: 'Total Visual QC Scans (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_all_time',
      title: 'Total Visual QC Scans (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_through_qc_day',
      title: 'Total Forms Through Visual QC (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_scans_through_qc_week',
      title: 'Total Forms Through Visual QC (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_scans_through_qc_all_time',
      title: 'Total Forms Through Visual QC (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.complete_scans_qc_all_time +
        rowData.incomplete_scans_qc_all_time,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'complete_scans_qc_day',
      title: 'Complete Forms (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'complete_scans_qc_week',
      title: 'Complete Forms (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'complete_scans_qc_all_time',
      title: 'Complete Forms (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'incomplete_scans_qc_day',
      title: 'Incomplete Forms (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'incomplete_scans_qc_week',
      title: 'Incomplete Forms (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'incomplete_scans_qc_all_time',
      title: 'Incomplete Forms (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'scans_with_phones_qc_day',
      title: 'Forms with Phones (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.complete_scans_qc_day +
        rowData.incomplete_scans_qc_day -
        rowData.missing_phone_qc_day,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'scans_with_phones_qc_week',
      title: 'Forms with Phones (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.complete_scans_qc_week +
        rowData.incomplete_scans_qc_week -
        rowData.missing_phone_qc_week,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'scans_with_phones_qc_all_time',
      title: 'Forms with Phones (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.complete_scans_qc_all_time +
        rowData.incomplete_scans_qc_all_time -
        rowData.missing_phone_qc_all_time,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'approved_qc_day',
      title: 'Approved (Visual QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'approved_qc_week',
      title: 'Approved (Visual QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'approved_qc_all_time',
      title: 'Approved (Visual QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'missing_phone_qc_day',
      title: 'Missing Phone (Visual QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'missing_phone_qc_week',
      title: 'Missing Phone (Visual QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'missing_phone_qc_all_time',
      title: 'Missing Phone (Visual QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'reupload_qc_day',
      title: 'Reupload (Visual QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'reupload_qc_week',
      title: 'Reupload (Visual QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'reupload_qc_all_time',
      title: 'Reupload (Visual QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'handwriting_qc_day',
      title: 'Handwriting (Visual QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'handwriting_qc_week',
      title: 'Handwriting (Visual QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'handwriting_qc_all_time',
      title: 'Handwriting (Visual QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_form_qc_day',
      title: 'Not a Card (Visual QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_form_qc_week',
      title: 'Not a Card (Visual QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_form_qc_all_time',
      title: 'Not a Card (Visual QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'canvasser_assistance_qc_day',
      title: 'Canvasser Assistance (Visual QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'canvasser_assistance_qc_week',
      title: 'Canvasser Assistance (Visual QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'canvasser_assistance_qc_all_time',
      title: 'Canvasser Assistance (Visual QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'other_qc_day',
      title: 'Other (Visual QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'other_qc_week',
      title: 'Other (Visual QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'other_qc_all_time',
      title: 'Other (Visual QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'percent_complete_scans_qc_day',
      title: 'Percent Complete Forms (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.complete_scans_qc_day,
          rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day
        ),
      summaryFunction: ({ data }) => {
        const complete_scans_qc_day = data.reduce(
          (acc, row) => row.complete_scans_qc_day + acc,
          0
        )
        const total_scans_through_qc_day = data.reduce(
          (acc, row) => row.total_scans_through_qc_day + acc,
          0
        )
        return calculatePercent(
          complete_scans_qc_day,
          total_scans_through_qc_day
        )
      },
    },
    {
      dataKey: 'percent_complete_scans_qc_week',
      title: 'Percent Complete Forms (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.complete_scans_qc_week,
          rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week
        ),
      summaryFunction: ({ data }) => {
        const complete_scans_qc_week = data.reduce(
          (acc, row) => row.complete_scans_qc_week + acc,
          0
        )
        const total_scans_through_qc_week = data.reduce(
          (acc, row) => row.total_scans_through_qc_week + acc,
          0
        )
        return calculatePercent(
          complete_scans_qc_week,
          total_scans_through_qc_week
        )
      },
    },
    {
      dataKey: 'percent_complete_scans_qc_all_time',
      title: 'Percent Complete Forms (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.complete_scans_qc_all_time,
          rowData.complete_scans_qc_all_time +
            rowData.incomplete_scans_qc_all_time
        ),
      summaryFunction: ({ data }) => {
        const complete_scans_qc_all_time = data.reduce(
          (acc, row) => row.complete_scans_qc_all_time + acc,
          0
        )
        const total_scans_through_qc_all_time = data.reduce(
          (acc, row) => row.total_scans_through_qc_all_time + acc,
          0
        )
        return calculatePercent(
          complete_scans_qc_all_time,
          total_scans_through_qc_all_time
        )
      },
    },
    {
      dataKey: 'percent_incomplete_scans_qc_day',
      title: 'Percent Incomplete Forms (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incomplete_scans_qc_day,
          rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day
        ),
      summaryFunction: ({ data }) => {
        const incomplete_scans_qc_day = data.reduce(
          (acc, row) => row.incomplete_scans_qc_day + acc,
          0
        )
        const total_scans_through_qc_day = data.reduce(
          (acc, row) => row.total_scans_through_qc_day + acc,
          0
        )
        return calculatePercent(
          incomplete_scans_qc_day,
          total_scans_through_qc_day
        )
      },
    },
    {
      dataKey: 'percent_incomplete_scans_qc_week',
      title: 'Percent Incomplete Forms (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incomplete_scans_qc_week,
          rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week
        ),
      summaryFunction: ({ data }) => {
        const incomplete_scans_qc_week = data.reduce(
          (acc, row) => row.incomplete_scans_qc_week + acc,
          0
        )
        const total_scans_through_qc_week = data.reduce(
          (acc, row) => row.total_scans_through_qc_week + acc,
          0
        )
        return calculatePercent(
          incomplete_scans_qc_week,
          total_scans_through_qc_week
        )
      },
    },
    {
      dataKey: 'percent_incomplete_scans_qc_all_time',
      title: 'Percent Incomplete Forms (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incomplete_scans_qc_all_time,
          rowData.complete_scans_qc_all_time +
            rowData.incomplete_scans_qc_all_time
        ),
      summaryFunction: ({ data }) => {
        const incomplete_scans_qc_all_time = data.reduce(
          (acc, row) => row.incomplete_scans_qc_all_time + acc,
          0
        )
        const total_scans_through_qc_all_time = data.reduce(
          (acc, row) => row.total_scans_through_qc_all_time + acc,
          0
        )
        return calculatePercent(
          incomplete_scans_qc_all_time,
          total_scans_through_qc_all_time
        )
      },
    },
    {
      dataKey: 'percent_approved_qc_day',
      title: 'Percent Approved (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.approved_qc_day,
          rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day
        ),
      summaryFunction: ({ data }) => {
        const approved_qc_day = data.reduce(
          (acc, row) => row.approved_qc_day + acc,
          0
        )
        const total_scans_through_qc_day = data.reduce(
          (acc, row) => row.total_scans_through_qc_day + acc,
          0
        )
        return calculatePercent(approved_qc_day, total_scans_through_qc_day)
      },
    },
    {
      dataKey: 'percent_approved_qc_week',
      title: 'Percent Approved (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.approved_qc_week,
          rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week
        ),
      summaryFunction: ({ data }) => {
        const approved_qc_week = data.reduce(
          (acc, row) => row.approved_qc_week + acc,
          0
        )
        const total_scans_through_qc_week = data.reduce(
          (acc, row) => row.total_scans_through_qc_week + acc,
          0
        )
        return calculatePercent(approved_qc_week, total_scans_through_qc_week)
      },
    },
    {
      dataKey: 'percent_approved_qc_all_time',
      title: 'Percent Approved (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.approved_qc_all_time,
          rowData.complete_scans_qc_all_time +
            rowData.incomplete_scans_qc_all_time
        ),
      summaryFunction: ({ data }) => {
        const approved_qc_all_time = data.reduce(
          (acc, row) => row.approved_qc_all_time + acc,
          0
        )
        const total_scans_through_qc_all_time = data.reduce(
          (acc, row) => row.total_scans_through_qc_all_time + acc,
          0
        )
        return calculatePercent(
          approved_qc_all_time,
          total_scans_through_qc_all_time
        )
      },
    },
    {
      dataKey: 'percent_missing_phone_qc_day',
      title: 'Percent Missing Phone (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.missing_phone_qc_day,
          rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day
        ),
      summaryFunction: ({ data }) => {
        const missing_phone_qc_day = data.reduce(
          (acc, row) => row.missing_phone_qc_day + acc,
          0
        )
        const total_scans_through_qc_day = data.reduce(
          (acc, row) => row.total_scans_through_qc_day + acc,
          0
        )
        return calculatePercent(
          missing_phone_qc_day,
          total_scans_through_qc_day
        )
      },
    },
    {
      dataKey: 'percent_missing_phone_qc_week',
      title: 'Percent Missing Phone (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.missing_phone_qc_week,
          rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week
        ),
      summaryFunction: ({ data }) => {
        const missing_phone_qc_week = data.reduce(
          (acc, row) => row.missing_phone_qc_week + acc,
          0
        )
        const total_scans_through_qc_week = data.reduce(
          (acc, row) => row.total_scans_through_qc_week + acc,
          0
        )
        return calculatePercent(
          missing_phone_qc_week,
          total_scans_through_qc_week
        )
      },
    },
    {
      dataKey: 'percent_missing_phone_qc_all_time',
      title: 'Percent Missing Phone (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.missing_phone_qc_all_time,
          rowData.complete_scans_qc_all_time +
            rowData.incomplete_scans_qc_all_time
        ),
      summaryFunction: ({ data }) => {
        const missing_phone_qc_all_time = data.reduce(
          (acc, row) => row.missing_phone_qc_all_time + acc,
          0
        )
        const total_scans_through_qc_all_time = data.reduce(
          (acc, row) => row.total_scans_through_qc_all_time + acc,
          0
        )
        return calculatePercent(
          missing_phone_qc_all_time,
          total_scans_through_qc_all_time
        )
      },
    },
    {
      dataKey: 'percent_reupload_qc_day',
      title: 'Percent Reupload (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.reupload_qc_day,
          rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day
        ),
      summaryFunction: ({ data }) => {
        const reupload_qc_day = data.reduce(
          (acc, row) => row.reupload_qc_day + acc,
          0
        )
        const total_scans_through_qc_day = data.reduce(
          (acc, row) => row.total_scans_through_qc_day + acc,
          0
        )
        return calculatePercent(reupload_qc_day, total_scans_through_qc_day)
      },
    },
    {
      dataKey: 'percent_reupload_qc_week',
      title: 'Percent Reupload (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.reupload_qc_week,
          rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week
        ),
      summaryFunction: ({ data }) => {
        const reupload_qc_week = data.reduce(
          (acc, row) => row.reupload_qc_week + acc,
          0
        )
        const total_scans_through_qc_week = data.reduce(
          (acc, row) => row.total_scans_through_qc_week + acc,
          0
        )
        return calculatePercent(reupload_qc_week, total_scans_through_qc_week)
      },
    },
    {
      dataKey: 'percent_reupload_qc_all_time',
      title: 'Percent Reupload (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.reupload_qc_all_time,
          rowData.complete_scans_qc_all_time +
            rowData.incomplete_scans_qc_all_time
        ),
      summaryFunction: ({ data }) => {
        const reupload_qc_all_time = data.reduce(
          (acc, row) => row.reupload_qc_all_time + acc,
          0
        )
        const total_scans_through_qc_all_time = data.reduce(
          (acc, row) => row.total_scans_through_qc_all_time + acc,
          0
        )
        return calculatePercent(
          reupload_qc_all_time,
          total_scans_through_qc_all_time
        )
      },
    },
    {
      dataKey: 'percent_handwriting_qc_day',
      title: 'Percent Handwriting (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.handwriting_qc_day,
          rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day
        ),
      summaryFunction: ({ data }) => {
        const handwriting_qc_day = data.reduce(
          (acc, row) => row.handwriting_qc_day + acc,
          0
        )
        const total_scans_through_qc_day = data.reduce(
          (acc, row) => row.total_scans_through_qc_day + acc,
          0
        )
        return calculatePercent(handwriting_qc_day, total_scans_through_qc_day)
      },
    },
    {
      dataKey: 'percent_handwriting_qc_week',
      title: 'Percent Handwriting (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.handwriting_qc_week,
          rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week
        ),
      summaryFunction: ({ data }) => {
        const handwriting_qc_week = data.reduce(
          (acc, row) => row.handwriting_qc_week + acc,
          0
        )
        const total_scans_through_qc_week = data.reduce(
          (acc, row) => row.total_scans_through_qc_week + acc,
          0
        )
        return calculatePercent(
          handwriting_qc_week,
          total_scans_through_qc_week
        )
      },
    },
    {
      dataKey: 'percent_handwriting_qc_all_time',
      title: 'Percent Handwriting (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.handwriting_qc_all_time,
          rowData.complete_scans_qc_all_time +
            rowData.incomplete_scans_qc_all_time
        ),
      summaryFunction: ({ data }) => {
        const handwriting_qc_all_time = data.reduce(
          (acc, row) => row.handwriting_qc_all_time + acc,
          0
        )
        const total_scans_through_qc_all_time = data.reduce(
          (acc, row) => row.total_scans_through_qc_all_time + acc,
          0
        )
        return calculatePercent(
          handwriting_qc_all_time,
          total_scans_through_qc_all_time
        )
      },
    },
    {
      dataKey: 'percent_canvasser_assistance_qc_day',
      title: 'Percent Canvasser Assistance (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.canvasser_assistance_qc_day,
          rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day
        ),
      summaryFunction: ({ data }) => {
        const canvasser_assistance_qc_day = data.reduce(
          (acc, row) => row.canvasser_assistance_qc_day + acc,
          0
        )
        const total_scans_through_qc_day = data.reduce(
          (acc, row) => row.total_scans_through_qc_day + acc,
          0
        )
        return calculatePercent(
          canvasser_assistance_qc_day,
          total_scans_through_qc_day
        )
      },
    },
    {
      dataKey: 'percent_canvasser_assistance_qc_week',
      title: 'Percent Canvasser Assistance (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.canvasser_assistance_qc_week,
          rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week
        ),
      summaryFunction: ({ data }) => {
        const canvasser_assistance_qc_week = data.reduce(
          (acc, row) => row.canvasser_assistance_qc_week + acc,
          0
        )
        const total_scans_through_qc_week = data.reduce(
          (acc, row) => row.total_scans_through_qc_week + acc,
          0
        )
        return calculatePercent(
          canvasser_assistance_qc_week,
          total_scans_through_qc_week
        )
      },
    },
    {
      dataKey: 'percent_canvasser_assistance_qc_all_time',
      title: 'Percent Canvasser Assistance (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.canvasser_assistance_qc_all_time,
          rowData.complete_scans_qc_all_time +
            rowData.incomplete_scans_qc_all_time
        ),
      summaryFunction: ({ data }) => {
        const canvasser_assistance_qc_all_time = data.reduce(
          (acc, row) => row.canvasser_assistance_qc_all_time + acc,
          0
        )
        const total_scans_through_qc_all_time = data.reduce(
          (acc, row) => row.total_scans_through_qc_all_time + acc,
          0
        )
        return calculatePercent(
          canvasser_assistance_qc_all_time,
          total_scans_through_qc_all_time
        )
      },
    },
    {
      dataKey: 'percent_other_qc_day',
      title: 'Percent Other (Visual QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.other_qc_day,
          rowData.complete_scans_qc_day + rowData.incomplete_scans_qc_day
        ),
      summaryFunction: ({ data }) => {
        const other_qc_day = data.reduce(
          (acc, row) => row.other_qc_day + acc,
          0
        )
        const total_scans_through_qc_day = data.reduce(
          (acc, row) => row.total_scans_through_qc_day + acc,
          0
        )
        return calculatePercent(other_qc_day, total_scans_through_qc_day)
      },
    },
    {
      dataKey: 'percent_other_qc_week',
      title: 'Percent Other (Visual QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.other_qc_week,
          rowData.complete_scans_qc_week + rowData.incomplete_scans_qc_week
        ),
      summaryFunction: ({ data }) => {
        const other_qc_week = data.reduce(
          (acc, row) => row.other_qc_week + acc,
          0
        )
        const total_scans_through_qc_week = data.reduce(
          (acc, row) => row.total_scans_through_qc_week + acc,
          0
        )
        return calculatePercent(other_qc_week, total_scans_through_qc_week)
      },
    },
    {
      dataKey: 'percent_other_qc_all_time',
      title: 'Percent Other (Visual QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.other_qc_all_time,
          rowData.complete_scans_qc_all_time +
            rowData.incomplete_scans_qc_all_time
        ),
      summaryFunction: ({ data }) => {
        const other_qc_all_time = data.reduce(
          (acc, row) => row.other_qc_all_time + acc,
          0
        )
        const total_scans_through_qc_all_time = data.reduce(
          (acc, row) => row.total_scans_through_qc_all_time + acc,
          0
        )
        return calculatePercent(
          other_qc_all_time,
          total_scans_through_qc_all_time
        )
      },
    },
    {
      dataKey: 'total_phone_qc_days_day',
      title: 'Total Phone QC Days (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_phone_qc_days_week',
      title: 'Total Phone QC Days (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_phone_qc_days_all_time',
      title: 'Total Phone QC Days (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_calls_day',
      title: 'Total Calls (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_calls_week',
      title: 'Total Calls (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_calls_all_time',
      title: 'Total Calls (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'calls_per_day_day',
      title: 'Calls per Day (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_calls_day, rowData.total_phone_qc_days_day),
    },
    {
      dataKey: 'calls_per_day_week',
      title: 'Calls per Day (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_calls_week,
          rowData.total_phone_qc_days_week
        ),
    },
    {
      dataKey: 'calls_per_day_all_time',
      title: 'Calls per Day (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_calls_all_time,
          rowData.total_phone_qc_days_all_time
        ),
    },
    {
      dataKey: 'total_scans_called_qc_day',
      title: 'Total Forms Called (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_called_qc_week',
      title: 'Total Forms Called (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_called_qc_all_time',
      title: 'Total Forms Called (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'contacted_qc_day',
      title: 'Contacted (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'contacted_qc_week',
      title: 'Contacted (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'contacted_qc_all_time',
      title: 'Contacted (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'phone_needs_attention_qc_day',
      title: 'Phone Needs Attention (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'phone_needs_attention_qc_week',
      title: 'Phone Needs Attention (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'phone_needs_attention_qc_all_time',
      title: 'Phone Needs Attention (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'confirmed_registered_qc_day',
      title: 'Confirmed Registration on Phone (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'confirmed_registered_qc_week',
      title: 'Confirmed Registration on Phone (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'confirmed_registered_qc_all_time',
      title: 'Confirmed Registration on Phone (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'did_not_register_qc_day',
      title: 'Did Not Register (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'did_not_register_qc_week',
      title: 'Did Not Register (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'did_not_register_qc_all_time',
      title: 'Did Not Register (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_address_qc_day',
      title: 'Correct Address (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_address_qc_week',
      title: 'Correct Address (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_address_qc_all_time',
      title: 'Correct Address (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_address_qc_day',
      title: 'Incorrect Address (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_address_qc_week',
      title: 'Incorrect Address (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_address_qc_all_time',
      title: 'Incorrect Address (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_dob_qc_day',
      title: 'Correct DOB (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_dob_qc_week',
      title: 'Correct DOB (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_dob_qc_all_time',
      title: 'Correct DOB (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_dob_qc_day',
      title: 'Incorrect DOB (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_dob_qc_week',
      title: 'Incorrect DOB (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_dob_qc_all_time',
      title: 'Incorrect DOB (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'received_feedback_qc_day',
      title: 'Received Canvasser Feedback (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'received_feedback_qc_week',
      title: 'Received Canvasser Feedback (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'received_feedback_qc_all_time',
      title: 'Received Canvasser Feedback (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_home_qc_day',
      title: 'Not Home (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_home_qc_week',
      title: 'Not Home (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_home_qc_all_time',
      title: 'Not Home (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'disconnected_qc_day',
      title: 'Disconnected (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'disconnected_qc_week',
      title: 'Disconnected (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'disconnected_qc_all_time',
      title: 'Disconnected (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'call_back_qc_day',
      title: 'Call Back (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'call_back_qc_week',
      title: 'Call Back (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'call_back_qc_all_time',
      title: 'Call Back (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'language_barrier_qc_day',
      title: 'Language Barrier (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'language_barrier_qc_week',
      title: 'Language Barrier (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'language_barrier_qc_all_time',
      title: 'Language Barrier (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'refused_qc_day',
      title: 'Refused (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'refused_qc_week',
      title: 'Refused (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'refused_qc_all_time',
      title: 'Refused (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_number_qc_day',
      title: 'Wrong Number (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_number_qc_week',
      title: 'Wrong Number (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_number_qc_all_time',
      title: 'Wrong Number (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_voicemail_qc_day',
      title: 'Wrong Voicemail (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_voicemail_qc_week',
      title: 'Wrong Voicemail (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_voicemail_qc_all_time',
      title: 'Wrong Voicemail (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'voicemail_matches_applicant_qc_day',
      title: 'Voicemail Matches Applicant (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'voicemail_matches_applicant_qc_week',
      title: 'Voicemail Matches Applicant (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'voicemail_matches_applicant_qc_all_time',
      title: 'Voicemail Matches Applicant (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'busy_qc_day',
      title: 'Busy (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'busy_qc_week',
      title: 'Busy (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'busy_qc_all_time',
      title: 'Busy (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'do_not_call_qc_day',
      title: 'Do Not Call (Phone QC) (Day)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'do_not_call_qc_week',
      title: 'Do Not Call (Phone QC) (Week)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'do_not_call_qc_all_time',
      title: 'Do Not Call (Phone QC) (All time)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'percent_contacted_qc_day',
      title: 'Percent Contacted (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.contacted_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_contacted_qc_week',
      title: 'Percent Contacted (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.contacted_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_contacted_qc_all_time',
      title: 'Percent Contacted (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.contacted_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_phone_needs_attention_qc_day',
      title: 'Percent Phone Needs Attention (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.phone_needs_attention_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_phone_needs_attention_qc_week',
      title: 'Percent Phone Needs Attention (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.phone_needs_attention_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_phone_needs_attention_qc_all_time',
      title: 'Percent Phone Needs Attention (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.phone_needs_attention_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_confirmed_registered_qc_day',
      title: 'Percent Confirmed Registration on Phone (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.confirmed_registered_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_confirmed_registered_qc_week',
      title: 'Percent Confirmed Registration on Phone (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.confirmed_registered_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_confirmed_registered_qc_all_time',
      title: 'Percent Confirmed Registration on Phone (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.confirmed_registered_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_did_not_register_qc_day',
      title: 'Percent Did Not Register (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.did_not_register_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_did_not_register_qc_week',
      title: 'Percent Did Not Register (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.did_not_register_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_did_not_register_qc_all_time',
      title: 'Percent Did Not Register (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.did_not_register_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_correct_address_qc_day',
      title: 'Percent Correct Address (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.correct_address_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_correct_address_qc_week',
      title: 'Percent Correct Address (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.correct_address_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_correct_address_qc_all_time',
      title: 'Percent Correct Address (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.correct_address_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_incorrect_address_qc_day',
      title: 'Percent Incorrect Address (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_address_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_incorrect_address_qc_week',
      title: 'Percent Incorrect Address (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_address_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_incorrect_address_qc_all_time',
      title: 'Percent Incorrect Address (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_address_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_correct_dob_qc_day',
      title: 'Percent Correct DOB (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.correct_dob_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_correct_dob_qc_week',
      title: 'Percent Correct DOB (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.correct_dob_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_correct_dob_qc_all_time',
      title: 'Percent Correct DOB (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.correct_dob_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_incorrect_dob_qc_day',
      title: 'Percent Incorrect DOB (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_dob_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_incorrect_dob_qc_week',
      title: 'Percent Incorrect DOB (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_dob_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_incorrect_dob_qc_all_time',
      title: 'Percent Incorrect DOB (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_dob_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_received_feedback_qc_day',
      title: 'Percent Received Canvasser Feedback (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.received_feedback_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_received_feedback_qc_week',
      title: 'Percent Received Canvasser Feedback (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.received_feedback_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_received_feedback_qc_all_time',
      title: 'Percent Received Canvasser Feedback (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.received_feedback_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_not_home_qc_day',
      title: 'Percent Not Home (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.not_home_qc_day,
          rowData.total_scans_called_qc_day
        ),
      summaryFunction: summaryFunctions.percent(
        'not_home_qc_day',
        'total_scans_called_qc_day'
      ),
    },
    {
      dataKey: 'percent_not_home_qc_week',
      title: 'Percent Not Home (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.not_home_qc_week,
          rowData.total_scans_called_qc_week
        ),
      summaryFunction: summaryFunctions.percent(
        'not_home_qc_week',
        'total_scans_called_qc_week'
      ),
    },
    {
      dataKey: 'percent_not_home_qc_all_time',
      title: 'Percent Not Home (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.not_home_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
      summaryFunction: summaryFunctions.percent(
        'not_home_qc_all_time',
        'total_scans_called_qc_all_time'
      ),
    },
    {
      dataKey: 'percent_disconnected_qc_day',
      title: 'Percent Disconnected (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.disconnected_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_disconnected_qc_week',
      title: 'Percent Disconnected (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.disconnected_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_disconnected_qc_all_time',
      title: 'Percent Disconnected (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.disconnected_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_call_back_qc_day',
      title: 'Percent Call Back (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.call_back_qc_day,
          rowData.total_scans_called_qc_day
        ),
      summaryFunction: summaryFunctions.percent(
        'call_back_qc_day',
        'total_scans_called_qc_day'
      ),
    },
    {
      dataKey: 'percent_call_back_qc_week',
      title: 'Percent Call Back (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.call_back_qc_week,
          rowData.total_scans_called_qc_week
        ),
      summaryFunction: summaryFunctions.percent(
        'call_back_qc_week',
        'total_scans_called_qc_week'
      ),
    },
    {
      dataKey: 'percent_call_back_qc_all_time',
      title: 'Percent Call Back (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.call_back_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
      summaryFunction: summaryFunctions.percent(
        'call_back_qc_all_time',
        'total_scans_called_qc_all_time'
      ),
    },
    {
      dataKey: 'percent_language_barrier_qc_day',
      title: 'Percent Language Barrier (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.language_barrier_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_language_barrier_qc_week',
      title: 'Percent Language Barrier (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.language_barrier_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_language_barrier_qc_all_time',
      title: 'Percent Language Barrier (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.language_barrier_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_refused_qc_day',
      title: 'Percent Refused (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.refused_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_refused_qc_week',
      title: 'Percent Refused (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.refused_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_refused_qc_all_time',
      title: 'Percent Refused (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.refused_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_wrong_number_qc_day',
      title: 'Percent Wrong Number (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_number_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_wrong_number_qc_week',
      title: 'Percent Wrong Number (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_number_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_wrong_number_qc_all_time',
      title: 'Percent Wrong Number (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_number_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_wrong_voicemail_qc_day',
      title: 'Percent Wrong Voicemail (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_voicemail_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_wrong_voicemail_qc_week',
      title: 'Percent Wrong Voicemail (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_voicemail_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_wrong_voicemail_qc_all_time',
      title: 'Percent Wrong Voicemail (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_voicemail_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'percent_voicemail_matches_applicant_qc_day',
      title: 'Percent Voicemail Matches Applicant (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.voicemail_matches_applicant_qc_day,
          rowData.total_scans_called_qc_day
        ),
      summaryFunction: summaryFunctions.percent(
        'voicemail_matches_applicant_qc_day',
        'total_scans_called_qc_day'
      ),
    },
    {
      dataKey: 'percent_voicemail_matches_applicant_qc_week',
      title: 'Percent Voicemail Matches Applicant (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.voicemail_matches_applicant_qc_week,
          rowData.total_scans_called_qc_week
        ),
      summaryFunction: summaryFunctions.percent(
        'voicemail_matches_applicant_qc_week',
        'total_scans_called_qc_week'
      ),
    },
    {
      dataKey: 'percent_voicemail_matches_applicant_qc_all_time',
      title: 'Percent Voicemail Matches Applicant (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.voicemail_matches_applicant_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
      summaryFunction: summaryFunctions.percent(
        'voicemail_matches_applicant_qc_all_time',
        'total_scans_called_qc_all_time'
      ),
    },
    {
      dataKey: 'percent_busy_qc_day',
      title: 'Percent Busy (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.busy_qc_day,
          rowData.total_scans_called_qc_day
        ),
      summaryFunction: summaryFunctions.percent(
        'busy_qc_day',
        'total_scans_called_qc_day'
      ),
    },
    {
      dataKey: 'percent_busy_qc_week',
      title: 'Percent Busy (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.busy_qc_week,
          rowData.total_scans_called_qc_week
        ),
      summaryFunction: summaryFunctions.percent(
        'busy_qc_week',
        'total_scans_called_qc_week'
      ),
    },
    {
      dataKey: 'percent_busy_qc_all_time',
      title: 'Percent Busy (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.busy_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
      summaryFunction: summaryFunctions.percent(
        'busy_qc_all_time',
        'total_scans_called_qc_all_time'
      ),
    },
    {
      dataKey: 'percent_do_not_call_qc_day',
      title: 'Percent Do Not Call (Phone QC) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.do_not_call_qc_day,
          rowData.total_scans_called_qc_day
        ),
    },
    {
      dataKey: 'percent_do_not_call_qc_week',
      title: 'Percent Do Not Call (Phone QC) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.do_not_call_qc_week,
          rowData.total_scans_called_qc_week
        ),
    },
    {
      dataKey: 'percent_do_not_call_qc_all_time',
      title: 'Percent Do Not Call (Phone QC) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.do_not_call_qc_all_time,
          rowData.total_scans_called_qc_all_time
        ),
    },
    {
      dataKey: 'avg_length_of_call_day',
      title: 'Avg length of call (in mins) (Day)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => {
        if (!rowData.total_calls_day) return 0
        return rowData.lengths_of_calls_seconds_day / rowData.total_calls_day
      },
      bodyCell: ({ rowData }) => {
        if (!rowData.avg_length_of_call_day) return '00:00:00'
        return moment
          .utc(rowData.avg_length_of_call_day * 1000)
          .format('HH:mm:ss.S')
      },
      csvCell: ({ avg_length_of_call_day }) => {
        if (!avg_length_of_call_day) return '00:00:00'
        return moment.utc(avg_length_of_call_day * 1000).format('HH:mm:ss.S')
      },
    },
    {
      dataKey: 'avg_length_of_call_week',
      title: 'Avg length of call (in mins) (Week)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => {
        if (!rowData.total_calls_week) return 0
        return rowData.lengths_of_calls_seconds_week / rowData.total_calls_week
      },
      bodyCell: ({ rowData }) => {
        if (!rowData.avg_length_of_call_week) return '00:00:00'
        return moment
          .utc(rowData.avg_length_of_call_week * 1000)
          .format('HH:mm:ss.S')
      },
      csvCell: ({ avg_length_of_call_week }) => {
        if (!avg_length_of_call_week) return '00:00:00'
        return moment.utc(avg_length_of_call_week * 1000).format('HH:mm:ss.S')
      },
    },
    {
      dataKey: 'avg_length_of_call_all_time',
      title: 'Avg length of call (in mins) (All time)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => {
        if (!rowData.total_calls_all_time) return 0
        return (
          rowData.lengths_of_calls_seconds_all_time /
          rowData.total_calls_all_time
        )
      },
      bodyCell: ({ rowData }) => {
        if (!rowData.avg_length_of_call_all_time) return '00:00:00'
        return moment
          .utc(rowData.avg_length_of_call_all_time * 1000)
          .format('HH:mm:ss.S')
      },
      csvCell: ({ avg_length_of_call_all_time }) => {
        if (!avg_length_of_call_all_time) return '00:00:00'
        return moment
          .utc(avg_length_of_call_all_time * 1000)
          .format('HH:mm:ss.S')
      },
    },
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Day',
        visibleColumns: [
          'qc_staffer',
          'total_visual_qc_days_day',
          'total_scans_day',
          'total_scans_through_qc_day',
          'complete_scans_qc_day',
          'incomplete_scans_qc_day',
          'scans_with_phones_qc_day',
          'approved_qc_day',
          'missing_phone_qc_day',
          'reupload_qc_day',
          'missing_required_qc_day',
          'handwriting_qc_day',
          'not_form_qc_day',
          'canvasser_assistance_qc_day',
          'other_qc_day',
          'percent_approved_qc_day',
          'percent_missing_phone_qc_day',
          'percent_reupload_qc_day',
          'percent_missing_required_qc_day',
          'percent_handwriting_qc_day',
          'percent_canvasser_assistance_qc_day',
          'percent_other_qc_day',
          'total_phone_qc_days_day',
          'total_calls_day',
          'calls_per_day_day',
          'total_scans_called_qc_day',
          'contacted_qc_day',
          'phone_needs_attention_qc_day',
          'confirmed_registered_qc_day',
          'did_not_register_qc_day',
          'correct_address_qc_day',
          'incorrect_address_qc_day',
          'correct_dob_qc_day',
          'incorrect_dob_qc_day',
          'received_feedback_qc_day',
          'not_home_qc_day',
          'disconnected_qc_day',
          'call_back_qc_day',
          'language_barrier_qc_day',
          'refused_qc_day',
          'wrong_number_qc_day',
          'wrong_voicemail_qc_day',
          'voicemail_matches_applicant_qc_day',
          'busy_qc_day',
          'do_not_call_qc_day',
          'percent_contacted_qc_day',
          'percent_phone_needs_attention_qc_day',
          'percent_confirmed_registered_qc_day',
          'percent_did_not_register_qc_day',
          'percent_correct_address_qc_day',
          'percent_incorrect_address_qc_day',
          'percent_correct_dob_qc_day',
          'percent_incorrect_dob_qc_day',
          'percent_received_feedback_qc_day',
          'percent_not_home_qc_day',
          'percent_disconnected_qc_day',
          'percent_call_back_qc_day',
          'percent_language_barrier_qc_day',
          'percent_refused_qc_day',
          'percent_wrong_number_qc_day',
          'percent_wrong_voicemail_qc_day',
          'percent_voicemail_matches_applicant_qc_day',
          'percent_busy_qc_day',
          'percent_do_not_call_qc_day',
          'avg_length_of_call_day',
        ],
      },
      {
        title: 'Week',
        visibleColumns: [
          'qc_staffer',
          'total_visual_qc_days_week',
          'total_scans_week',
          'total_scans_through_qc_week',
          'complete_scans_qc_week',
          'incomplete_scans_qc_week',
          'scans_with_phones_qc_week',
          'approved_qc_week',
          'missing_phone_qc_week',
          'reupload_qc_week',
          'missing_required_qc_week',
          'handwriting_qc_week',
          'not_form_qc_week',
          'canvasser_assistance_qc_week',
          'other_qc_week',
          'percent_approved_qc_week',
          'percent_missing_phone_qc_week',
          'percent_reupload_qc_week',
          'percent_missing_required_qc_week',
          'percent_handwriting_qc_week',
          'percent_canvasser_assistance_qc_week',
          'percent_other_qc_week',
          'total_phone_qc_days_week',
          'total_calls_week',
          'calls_per_day_week',
          'total_scans_called_qc_week',
          'contacted_qc_week',
          'phone_needs_attention_qc_week',
          'confirmed_registered_qc_week',
          'did_not_register_qc_week',
          'correct_address_qc_week',
          'incorrect_address_qc_week',
          'correct_dob_qc_week',
          'incorrect_dob_qc_week',
          'received_feedback_qc_week',
          'not_home_qc_week',
          'disconnected_qc_week',
          'call_back_qc_week',
          'language_barrier_qc_week',
          'refused_qc_week',
          'wrong_number_qc_week',
          'wrong_voicemail_qc_week',
          'voicemail_matches_applicant_qc_week',
          'busy_qc_week',
          'do_not_call_qc_week',
          'percent_contacted_qc_week',
          'percent_phone_needs_attention_qc_week',
          'percent_confirmed_registered_qc_week',
          'percent_did_not_register_qc_week',
          'percent_correct_address_qc_week',
          'percent_incorrect_address_qc_week',
          'percent_correct_dob_qc_week',
          'percent_incorrect_dob_qc_week',
          'percent_received_feedback_qc_week',
          'percent_not_home_qc_week',
          'percent_disconnected_qc_week',
          'percent_call_back_qc_week',
          'percent_language_barrier_qc_week',
          'percent_refused_qc_week',
          'percent_wrong_number_qc_week',
          'percent_wrong_voicemail_qc_week',
          'percent_voicemail_matches_applicant_qc_week',
          'percent_busy_qc_week',
          'percent_do_not_call_qc_week',
          'avg_length_of_call_week',
        ],
      },
      {
        title: 'All time',
        visibleColumns: [
          'qc_staffer',
          'total_visual_qc_days_all_time',
          'total_scans_all_time',
          'total_scans_through_qc_all_time',
          'complete_scans_qc_all_time',
          'incomplete_scans_qc_all_time',
          'scans_with_phones_qc_all_time',
          'approved_qc_all_time',
          'missing_phone_qc_all_time',
          'reupload_qc_all_time',
          'missing_required_qc_all_time',
          'handwriting_qc_all_time',
          'not_form_qc_all_time',
          'canvasser_assistance_qc_all_time',
          'other_qc_all_time',
          'percent_approved_qc_all_time',
          'percent_missing_phone_qc_all_time',
          'percent_reupload_qc_all_time',
          'percent_missing_required_qc_all_time',
          'percent_handwriting_qc_all_time',
          'percent_canvasser_assistance_qc_all_time',
          'percent_other_qc_all_time',
          'total_phone_qc_days_all_time',
          'total_calls_all_time',
          'calls_per_day_all_time',
          'total_scans_called_qc_all_time',
          'contacted_qc_all_time',
          'phone_needs_attention_qc_all_time',
          'confirmed_registered_qc_all_time',
          'did_not_register_qc_all_time',
          'correct_address_qc_all_time',
          'incorrect_address_qc_all_time',
          'correct_dob_qc_all_time',
          'incorrect_dob_qc_all_time',
          'received_feedback_qc_all_time',
          'not_home_qc_all_time',
          'disconnected_qc_all_time',
          'call_back_qc_all_time',
          'language_barrier_qc_all_time',
          'refused_qc_all_time',
          'wrong_number_qc_all_time',
          'wrong_voicemail_qc_all_time',
          'voicemail_matches_applicant_qc_all_time',
          'busy_qc_all_time',
          'do_not_call_qc_all_time',
          'percent_contacted_qc_all_time',
          'percent_phone_needs_attention_qc_all_time',
          'percent_confirmed_registered_qc_all_time',
          'percent_did_not_register_qc_all_time',
          'percent_correct_address_qc_all_time',
          'percent_incorrect_address_qc_all_time',
          'percent_correct_dob_qc_all_time',
          'percent_incorrect_dob_qc_all_time',
          'percent_received_feedback_qc_all_time',
          'percent_not_home_qc_all_time',
          'percent_disconnected_qc_all_time',
          'percent_call_back_qc_all_time',
          'percent_language_barrier_qc_all_time',
          'percent_refused_qc_all_time',
          'percent_wrong_number_qc_all_time',
          'percent_wrong_voicemail_qc_all_time',
          'percent_voicemail_matches_applicant_qc_all_time',
          'percent_busy_qc_all_time',
          'percent_do_not_call_qc_all_time',
          'avg_length_of_call_all_time',
        ],
      },
    ])
  },
}
