import { ContentBlock } from 'components'
import { TEMPLATE_COLOR_OPTIONS } from '../TemplateContext/constants'
import { THEMES } from './constants'
import TemplateThemeItem from '../TemplateThemeItem/TemplateThemeItem'
import TemplateColorCard from '../TemplateColorCard/TemplateColorCard'
import TemplateFontCard from '../TemplateFontCard/TemplateFontCard'

const TemplateStyle = () => (
  <div>
    <ContentBlock>
      {Object.keys(THEMES).map((label, i) => (
        <TemplateThemeItem
          key={`${label}-${i}`}
          label={label}
          theme={THEMES[label]}
        />
      ))}
    </ContentBlock>
    <TemplateFontCard />
    {TEMPLATE_COLOR_OPTIONS.map((option, i) => (
      <TemplateColorCard type={option} key={`${option}-${i}`} />
    ))}
  </div>
)

export default TemplateStyle
