import { useTranslation } from 'react-i18next'
import { ButtonBlock, Button } from '@politechdev/blocks-design-system'
import {
  READY,
  CONNECTING,
  ACTIVE,
  REVIEW,
  COMPLETE,
} from 'constants/phoneBankSessions'
import PropTypes from 'prop-types'

const CallPanelControls = ({
  callStep,
  startCall,
  endCall,
  nextCall,
  disabled,
  endSession,
}) => {
  const { t } = useTranslation()

  const dialButtonProps = {
    [READY]: {
      text: t('Start call'),
      action: startCall,
      disabled,
    },
    [CONNECTING]: {
      text: t('End call'),
      action: endCall,
      disabled: false,
    },
    [ACTIVE]: {
      text: t('End call'),
      action: endCall,
      disabled: false,
    },
    [REVIEW]: {
      text: t('Next call'),
      action: nextCall,
      disabled: true,
    },
    [COMPLETE]: {
      text: t('Next call'),
      action: nextCall,
      disabled: false,
    },
  }

  return (
    <ButtonBlock>
      <Button.Accent
        onClick={dialButtonProps[callStep].action}
        disabled={dialButtonProps[callStep].disabled}
      >
        {dialButtonProps[callStep].text}
      </Button.Accent>
      <Button.Secondary
        onClick={endSession}
        disabled={callStep !== COMPLETE && callStep !== READY}
      >
        {t('End session')}
      </Button.Secondary>
    </ButtonBlock>
  )
}

export default CallPanelControls

CallPanelControls.propTypes = {
  callStep: PropTypes.string,
  startCall: PropTypes.func,
  endCall: PropTypes.func,
  nextCall: PropTypes.func,
  disabled: PropTypes.bool,
  endSession: PropTypes.func,
}
