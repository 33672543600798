import { Route, Switch } from 'react-router-dom'
import Template from '../template/Template/Template'
import Send from '../send/Send/Send'
import Manage from '../manage/Manage/Manage'
import EmailSelect from '../manage/EmailSelect/EmailSelect'
import EmailSingle from '../manage/EmailSingle/EmailSingle'

const EmailRoutes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/manage`} component={EmailSelect} />
    <Route exact path={`${match.path}/manage/drafts`} component={Manage} />
    <Route exact path={`${match.path}/manage/:id`} component={EmailSingle} />
    <Route exact path={`${match.path}/template/:id`} component={Template} />
    <Route exact path={`${match.path}/template`} component={Template} />
    <Route exact path={`${match.path}/send`} component={Send} />
    <Route exact path={`${match.path}/send/:id`} component={Send} />
  </Switch>
)

export default EmailRoutes
