import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import { SelectField } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchLists } from 'requests/lists'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { buildParams } from './utils'

const ListSelectField = ({ label, filters, onSelect, list, ...props }) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()

  const hasPermission = doesCurrentUserHavePermission({
    resource: 'list',
    ability: 'view',
  })

  const { makeRequest, isLoading, hasErrors, errors, response } =
    useRequest(fetchLists)

  const errorMsg = formatErrorMessage(errors)

  const [value, setValue] = useState(list?.id)
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  useEffect(() => {
    if (value !== list?.id) {
      const selection =
        value === null ? null : response?.lists.find(({ id }) => id === value)
      onSelect && onSelect(selection)
    }
  }, [value])

  useEffect(() => {
    if (value !== list?.id) {
      setValue(list?.id)
    }
  }, [list])

  const getLists = () => {
    const params = buildParams({
      query: debounced,
      filters,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getLists()
  }, [debounced, filters])

  const buildListOptions = list => ({
    value: list.id,
    label: list.name,
  })

  const options = [
    ...(list && query === '' ? [list] : []),
    ...(response?.lists || []),
  ]
    .reduce(
      (options, list) =>
        options.find(({ id }) => id === list.id) ? options : [...options, list],
      []
    )
    .map(buildListOptions)

  return (
    <>
      <CardError hide={!hasErrors} message={errorMsg} />
      <SelectField
        label={label || t('Lists')}
        options={options}
        onSelect={setValue}
        onInputChange={setQuery}
        value={value}
        loading={isLoading}
        disabled={!hasPermission}
        hint={
          !hasPermission && t('You do not have permission to edit this field')
        }
        {...props}
      />
    </>
  )
}

ListSelectField.propTypes = {
  id: PropTypes.string,
  list: PropTypes.object,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
}

ListSelectField.defaultProps = {
  id: 'lists',
  filters: [],
  list: null,
}

export default ListSelectField
