export const buildOption = event => ({
  value: event.id,
  label: event.name,
})

export const buildEvent = option => ({
  id: option.value,
  name: option.label,
})

export const buildParams = (query, params) => {
  const filters = query
    ? {
        filters: {
          rules: [
            {
              column: 'name',
              operator: 'containing',
              param: query,
            },
          ],
        },
      }
    : {}

  return {
    per: 25,
    indexed: true,
    fields: ['id', 'name', 'start_time'],
    ...params,
    ...filters,
  }
}
