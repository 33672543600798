import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import { TextBlock, Font } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import styles from './ProjectionSummary.module.scss'
import { ProjectionContext } from '../ProjectionContext/ProjectionContext'
import { formatProjectionSummaryData } from './summaryUtils'

const ProjectionSummary = () => {
  const cx = classNames.bind(styles)
  const { t } = useTranslation()
  const { weeklyActualData, weeklyProjectedData } =
    useContext(ProjectionContext)

  const {
    projectedWithActualFormsCollected,
    projectedFormsCollected,
    delta,
    avgCanvassersPerDay,
    avgDaysSpentCanvassing,
    avgHoursSpentCanvassingDaily,
    avgFormsPerHour,
  } = formatProjectionSummaryData({
    weeklyActualData,
    weeklyProjectedData,
  })

  return (
    <>
      <Section secondary label={t('Projections for entire period')}>
        <div className={styles.summary__row}>
          <TextBlock>
            <Font.Copy variant="highlight">{t('Projected')}</Font.Copy>
            <Font.Display>{projectedFormsCollected}</Font.Display>
          </TextBlock>
          <TextBlock>
            <Font.Copy variant="highlight">
              {t('Projected with actuals')}
            </Font.Copy>
            <Font.Display
              className={cx({
                positive: delta >= 0 && projectedFormsCollected !== 0,
                negative: delta < 0 && projectedFormsCollected !== 0,
              })}
            >
              {projectedWithActualFormsCollected}
            </Font.Display>
          </TextBlock>
          <TextBlock>
            <Font.Copy variant="highlight">{t('Projection status')}</Font.Copy>
            <Font.Display
              className={cx({
                positive: delta >= 0 && projectedFormsCollected !== 0,
                negative: delta < 0 && projectedFormsCollected !== 0,
              })}
            >
              {Math.abs(delta)} {delta >= 0 ? t('ahead') : t('behind')}
            </Font.Display>
          </TextBlock>
        </div>
      </Section>
      <Section
        secondary
        label={t('Average projection variables (with actuals)')}
      >
        <div className={styles.summary__row}>
          <TextBlock>
            <Font.Copy>{t('Canvassers per day')}</Font.Copy>
            <Font.Display>{avgCanvassersPerDay}</Font.Display>
          </TextBlock>
          <TextBlock>
            <Font.Copy>{t('Days canvassing per week')}</Font.Copy>
            <Font.Display>{avgDaysSpentCanvassing}</Font.Display>
          </TextBlock>
          <TextBlock>
            <Font.Copy>{t('Field hours')}</Font.Copy>
            <Font.Display>{avgHoursSpentCanvassingDaily}</Font.Display>
          </TextBlock>
          <TextBlock>
            <Font.Copy>{t('Forms per hour')}</Font.Copy>
            <Font.Display>{avgFormsPerHour}</Font.Display>
          </TextBlock>
        </div>
      </Section>
    </>
  )
}

export default ProjectionSummary
