import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Stepper } from 'components'
import { PageHeader, Sheet } from '@politechdev/blocks-design-system'
import { FormProvider } from 'contexts'
import ListCreateForm from '../ListCreateForm/ListCreateForm'
import ListCreateFilters from '../ListCreateFilters/ListCreateFilters'
import ListCreateReview from '../ListCreateReview/ListCreateReview'

const ListCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <PageHeader title={t('New list')} />
          <Stepper>
            <ListCreateForm />
            <ListCreateFilters />
            <ListCreateReview />
          </Stepper>
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default () => (
  <FormProvider>
    <ListCreate />
  </FormProvider>
)
