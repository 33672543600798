import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useAutoTable, useAuth, useCurrent } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import {
  destroyUser,
  disableUserRequest,
  fetchUsers,
  impersonateUser,
} from 'requests/users'
import { formatAutoTableParams } from 'utils/formatting'
import tableConfig from './tableConfig'

const UsersTable = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()

  const { login } = useAuth()
  const { renderTable, refreshPage } = useAutoTable()

  const { doesCurrentUserHavePermission } = useCurrent()

  const canModifyUser = doesCurrentUserHavePermission({
    resource: 'user',
    ability: 'modify',
  })
  const canImpersonateUser = doesCurrentUserHavePermission({
    resource: 'user',
    ability: 'impersonate',
  })
  const canDisableUser = doesCurrentUserHavePermission({
    resource: 'user',
    ability: 'disable',
  })
  const canDeleteUser = doesCurrentUserHavePermission({
    resource: 'user',
    ability: 'remove',
  })

  const {
    makeRequest: fetchAction,
    isLoading: fetchLoading,
    hasErrors: fetchError,
    response: usersResponse,
  } = useRequest(params => fetchUsers(formatAutoTableParams(params)))

  const users = usersResponse ? usersResponse.users : []
  const totalRecords = usersResponse ? usersResponse.meta.total_count : 0

  const {
    makeRequest: disableRequest,
    isLoading: disableLoading,
    hasErrors: disableError,
  } = useRequest(disableUserRequest, { onSuccess: refreshPage })

  const {
    makeRequest: deleteRequest,
    isLoading: deleteLoading,
    hasErrors: deleteError,
  } = useRequest(destroyUser, { onSuccess: refreshPage })

  const {
    makeRequest: impersonateRequest,
    isLoading: impersonateLoading,
    hasErrors: impersonateError,
  } = useRequest(impersonateUser, {
    onSuccess: response => {
      login(response.token)
      history.push('/')
    },
  })

  const handleEdit = rowData => {
    history.push(`${match.path}/${rowData.id}/edit`)
  }

  const handleImpersonate = rowData => {
    impersonateRequest(rowData.id)
  }

  const handleDisable = rowData => {
    disableRequest(rowData.id)
  }

  const handleDelete = rowData => {
    deleteRequest(rowData.id)
  }

  const showActions =
    canModifyUser || canImpersonateUser || canDisableUser || canDeleteUser

  const columns = [
    showActions && {
      actions: [
        createRowAction(t('Edit'), handleEdit, canModifyUser),
        createRowAction(
          t('Impersonate'),
          handleImpersonate,
          canImpersonateUser
        ),
        createRowAction(t('Disable'), handleDisable, canDisableUser),
        createRowAction(t('Delete'), handleDelete, canDeleteUser),
      ].filter(Boolean),
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  const isLoading =
    disableLoading || deleteLoading || impersonateLoading || fetchLoading
  const hasError = disableError || deleteError || impersonateError || fetchError

  return renderTable({
    data: users,
    loading: isLoading,
    error: hasError ? t('Something went wrong') : false,
    totalRecords,
    columns,
    fetchAction,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
  >
    <UsersTable />
  </AutoTableProvider>
)
