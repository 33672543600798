import PropTypes from 'prop-types'

export default function Column() {}

Column.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  headerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: PropTypes.object,
  title: PropTypes.string,
  dataKey: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
  width: PropTypes.number,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  frozen: PropTypes.oneOf(['left', 'right', true, false]),
  hidden: PropTypes.bool,
  resizable: PropTypes.bool,
  sortable: PropTypes.bool,
  bodyCell: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  headerCell: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  alphaNumericSort: PropTypes.bool,
}
