import {
  READY,
  CONNECTING,
  ACTIVE,
  REVIEW,
  COMPLETE,
} from 'constants/phoneBankSessions'
import { TimerDisplay } from 'components'
import PropTypes from 'prop-types'
import styles from './CallPanelHeader.module.scss'

const CallPanelHeader = ({ callStep, showTimer, secondsElapsed }) => {
  const headerText = {
    [READY]: 'Number to call',
    [CONNECTING]: 'Connecting...',
    [ACTIVE]: 'On a call...',
    [REVIEW]: 'Call ended',
    [COMPLETE]: 'Call ended',
  }

  return (
    <div className={styles.header}>
      <h2>{headerText[callStep]}</h2>
      {showTimer && <TimerDisplay secondsElapsed={secondsElapsed} />}
    </div>
  )
}

export default CallPanelHeader

CallPanelHeader.propTypes = {
  callStep: PropTypes.string,
  showTimer: PropTypes.bool,
  secondsElapsed: PropTypes.number,
}
