import { Radio, TextBlock } from '@politechdev/blocks-design-system'
import styles from './QuestionItem.module.scss'

const QuestionItem = ({
  questionKey,
  content,
  choices,
  selectedChoice,
  onSelect,
  disabled,
}) => (
  <div key={`question-single-choice-${questionKey}`}>
    <TextBlock>
      <p>{content}</p>
    </TextBlock>
    <div className={styles['script-item__choices']}>
      <Radio.Group
        id={`single-choice-${questionKey}`}
        name={`single-choice-${questionKey}`}
        key={`single-choice-${questionKey}`}
        type="radio"
        aria-label="Choices"
        value={selectedChoice}
        options={choices}
        onChange={onSelect}
        disabled={disabled}
      />
    </div>
  </div>
)

export default QuestionItem
