import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ContentBlock, Font } from '@politechdev/blocks-design-system'
import { useAuth } from 'contexts'

const LockedNotice = () => {
  const { t } = useTranslation()
  const { logout } = useAuth()

  return (
    <div>
      <ContentBlock>
        <Font.Copy variant="highlight">
          {t(
            'Your user account has been locked, please contact support to unlock your account.'
          )}
        </Font.Copy>
      </ContentBlock>
      <ContentBlock>
        <Font.Copy Element="p">
          <a
            href={`mailto:${process.env.INTERCOM_APP_ID}@incoming.intercom.io`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Contact support')}
          </a>
        </Font.Copy>
      </ContentBlock>
      <ContentBlock>
        <Button primary onClick={logout}>
          {t('Sign out')}
        </Button>
      </ContentBlock>
    </div>
  )
}

export default LockedNotice
