import { summaryFunctions } from 'utils/reporting'

export default {
  id: 'qc_flags_by_date',
  name: 'New and Total Open Flags by Date',
  description:
    'This report provides an overview of the daily change in flags in a date range.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1509333848',
  columns: [
    {
      dataKey: 'date',
      title: 'Date',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'total_flags_date',
      title: 'Total Flags',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'new_canvasser_flags',
      title: 'New Canvasser Flags',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_open_canvasser_flags',
      title: 'Total Open Canvasser Flags',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_canvasser_flags',
      title: 'Total Canvasser Flags',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'new_technical_flags',
      title: 'New Technical Flags',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_open_technical_flags',
      title: 'Total Open Technical Flags',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_technical_flags',
      title: 'Total Technical Flags',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
  ],
}
