import { Paginator } from 'components'

export const ListPager = ({ setPage, currentPage, totalPages }) => (
  <Paginator
    onNext={() => {
      setPage(currentPage + 1)
    }}
    currentPage={currentPage}
    totalPages={totalPages}
    onPrevious={() => setPage(currentPage - 1)}
  />
)
