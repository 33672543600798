import { useEffect, useState } from 'react'
import { useRequest, useRoutePathParams } from 'hooks'
import { useTranslation } from 'react-i18next'
import { CardError, ViewContainer, Sheet } from 'components'
import { fetchPublicEvent } from 'requests/events'
import EventImage from './EventImage'
import EventInfo from './EventInfo'
import RegistrationForm from './RegistrationForm'
import styles from './PublicPage.module.scss'

const PublicPage = () => {
  const { t } = useTranslation()
  const [event, setEvent] = useState()

  const {
    makeRequest: requestEvent,
    isRequestComplete: isEventFetched,
    hasErrors,
  } = useRequest(fetchPublicEvent, {
    onSuccess: response => setEvent(response.event),
  })

  const [{ id }] = useRoutePathParams()

  useEffect(() => {
    requestEvent(id)
  }, [id])

  if (!isEventFetched) return null

  const locationLoaded = isEventFetched && !hasErrors

  return (
    <>
      <CardError
        hide={!hasErrors}
        message={t("We're unable to retrieve this event")}
        hideSupportLink
      />
      {locationLoaded && (
        <>
          <EventImage url={event.public_page_header_url} />
          <ViewContainer>
            <Sheet className={styles.card}>
              <EventInfo event={event} />
              <RegistrationForm event={event} />
            </Sheet>
          </ViewContainer>
        </>
      )}
    </>
  )
}

export default PublicPage
