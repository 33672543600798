import moment from 'moment-timezone'

const UTC = 'UTC'

const YMD = 'YYYYMMDD'

const YMD_DASH = 'YYYY-MM-DD'

const ONE_HOUR_IN_SECONDS = 3600
const ONE_MINUTE_IN_SECONDS = 60

const convertUTCToUserTimezoneMoment = (date, user) =>
  moment.tz(date, UTC).tz(user.time_zone)

export const formatUTCAsTimeFromNow = (date, user) =>
  convertUTCToUserTimezoneMoment(date, user).fromNow()

export const localizeTime = time => moment.parseZone(time).local()

export const startOfCurrentWeek = moment().startOf('week')

export const endOfCurrentWeek = moment().endOf('week')

export const startOfCurrentWeekYMD = startOfCurrentWeek.format(YMD)

export const endOfCurrentWeekYMD = endOfCurrentWeek.format(YMD)

const startOfCurrentYear = moment().startOf('year')

export const startOfCurrentYearYMD = startOfCurrentYear.format(YMD)

export const startOfCurrentYearYMDDash = startOfCurrentYear.format(YMD_DASH)

const endOfCurrentYear = moment().endOf('year')

export const endOfCurrentYearYMDDash = endOfCurrentYear.format(YMD_DASH)

export const currentDateYMD = moment().format(YMD)

export const currentDateYMDDash = moment().format(YMD_DASH)

export const currentDateEasternYMDDash = moment
  .tz('US/Eastern')
  .format(YMD_DASH)

export const formatISODate = date => moment(date).toISOString()

export const formatYMDDashDate = date => moment(date).format(YMD_DASH)

export const roundISOStringToNearest15Minutes = ISOString =>
  Math.floor(moment(ISOString).minute() / 15) * 15
export const convertToUTCYMD = time => moment.tz(time, 'UTC').format(YMD)

export const subtractOneWeek = time => moment(time).subtract(1, 'weeks')

export const isToday = date => moment(date).isSame(moment(), 'day')

const padTime = time => time.toString().padStart(2, '0')

export const formatTimeGivenSeconds = secondsGiven => {
  const hours = Math.floor(secondsGiven / ONE_HOUR_IN_SECONDS)
  const minutes = Math.floor(
    (secondsGiven % ONE_HOUR_IN_SECONDS) / ONE_MINUTE_IN_SECONDS
  )
  const seconds = secondsGiven % ONE_MINUTE_IN_SECONDS

  const timeParts = [minutes, seconds]

  if (hours) {
    timeParts.unshift(hours)
  }

  return timeParts
    .map((time, index) => (index === 0 ? time : padTime(time)))
    .join(':')
}

export const getDateTimeXDaysFromNow = days =>
  moment().add(days, 'days').format()

export const supportedTimezones = new Map([
  ['US/Eastern', 'Eastern time'],
  ['US/Central', 'Central time'],
  ['US/Mountain', 'Mountain time'],
  ['US/Arizona', 'Arizona time'],
  ['US/Pacific', 'Pacific time'],
  ['US/Alaska', 'Alaska time'],
  ['US/Hawaii', 'Hawaii time'],
])

export const timezoneName = timezoneIdentifier =>
  supportedTimezones.get(timezoneIdentifier)

export const isValidDate = date => moment(date).isValid()

export const isSameDayOrAfter = (date, compareToDate) =>
  date ? moment(compareToDate).isSameOrAfter(moment(date), 'day') : true

export const isSameDayOrBefore = (date, compareToDate) =>
  date ? moment(compareToDate).isSameOrBefore(moment(date), 'day') : true
