import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const EmptyMessage = ({ message }) => {
  const { t } = useTranslation()

  return <p>{t(message)}</p>
}

EmptyMessage.propTypes = {
  message: PropTypes.string,
}

EmptyMessage.defaultProps = {
  message: 'No data found',
}

export default EmptyMessage
