import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Modal,
  TextField,
  Button,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useCurrent, useForm } from 'contexts'
import { useEvent, useRequest } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'

const generateError = async ({ json }) => {
  const defaultError = 'Something went wrong changing your password'

  try {
    const { error } = await json

    if (error.current_password?.includes('is invalid')) {
      return 'Incorrect current password'
    }

    return defaultError
  } catch {
    return defaultError
  }
}

const UserPasswordChangeModal = () => {
  const { t } = useTranslation()
  const { formData, setField, setFormData } = useForm()
  const { updateCurrentUser } = useCurrent()
  const [isOpen, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
    setFormData({})
  }

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(
    updateCurrentUser,
    {
      onSuccess: closeModal,
      onError: generateError,
    }
  )

  const errorMsg = formatErrorMessage(errors)

  const isFormValid =
    formData.current_password &&
    formData.password?.length > 5 &&
    formData.password === formData.password_confirmation

  const submitForm = useEvent(() => {
    makeRequest(formData)
  })

  return (
    <>
      <Button.Secondary onClick={openModal}>{t('Change')}</Button.Secondary>
      <Modal
        id="user-password-change"
        title={t(`Change password`)}
        isOpen={isOpen}
        showLoading={isLoading}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t(errorMsg)}
              hideSupportLink
            />
          </ContentBlock>
          <ContentBlock>
            <TextField
              required
              id="current_password"
              label={t('Current password')}
              type="password"
              value={formData.current_password || ''}
              ignoreWhitespace
              onChange={val => setField(val, 'current_password')}
              error={formData.current_password?.length < 6}
              errorText={t('Must be at least 6 characters long')}
            />
          </ContentBlock>
          <ContentBlock>
            <TextField
              required
              id="password"
              label={t('New password')}
              type="password"
              value={formData.password || ''}
              ignoreWhitespace
              onChange={val => setField(val, 'password')}
              error={formData.password?.length < 6}
              errorText={t('Must be at least 6 characters long')}
            />
          </ContentBlock>
          <ContentBlock>
            <TextField
              required
              id="password_confirmation"
              label={t('New password confirmation')}
              type="password"
              value={formData.password_confirmation || ''}
              ignoreWhitespace
              onChange={val => setField(val, 'password_confirmation')}
              error={formData.password_confirmation !== formData.password}
              errorText={t('Password does not match')}
            />
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent onClick={submitForm} disabled={!isFormValid}>
              {t('Change password')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default UserPasswordChangeModal
