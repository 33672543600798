import {
  RegistrationCanvasserNameCell,
  CanvasserTurfCell,
} from './CanvasserTableCells'
import { canvasserTurfCsvCell } from './canvasserCsvCells'

export const tableConfig = {
  label: 'canvassers',
  columns: [
    {
      dataKey: 'full_name',
      title: 'Canvasser',
      type: 'string',
      bodyCell: RegistrationCanvasserNameCell,
    },
    {
      dataKey: 'id',
      title: 'Canvasser ID',
      type: 'number',
    },
    {
      dataKey: 'turf_name',
      title: 'Turf',
      type: 'string',
      bodyCell: CanvasserTurfCell,
      csvCell: canvasserTurfCsvCell,
    },
    {
      dataKey: 'phone_number',
      title: 'Phone',
      type: 'string',
    },
  ],
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: { hiddenColumns: ['id'] },
      },
    ]
  },
}
