import {
  Button,
  DialogBlock,
  Font,
  MenuButton,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

import styles from './EnumMenuButton.module.scss'

export const EnumMenuButton = ({
  isOpen,
  openMenu,
  setParam,
  handleDoneClick,
  handleCancelClick,
  column,
}) => {
  const { t } = useTranslation()

  return (
    <MenuButton
      isOpen={isOpen}
      onToggle={openMenu}
      label={t(`${column.title}`)}
    >
      <MenuButton.Dialog>
        <div className="table-filters__column-list__header">
          <p className="table-filters__helper-text">{t('Select a filter')}</p>
        </div>
        {column.options.map(menuItem => {
          const { label, value } = menuItem
          return (
            <Font.Action
              key={label}
              role="button"
              onClick={() => {
                setParam(value)
                handleDoneClick(value)
              }}
              className={styles.option}
            >
              <span>{t(label)}</span>
            </Font.Action>
          )
        })}
        <DialogBlock row>
          <Button.Secondary onClick={handleCancelClick}>
            {t('Cancel')}
          </Button.Secondary>
        </DialogBlock>
      </MenuButton.Dialog>
    </MenuButton>
  )
}
