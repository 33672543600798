export const formatNewItem = (newItem, scriptItems) => {
  if (newItem.type === 'section') {
    return {
      id: null,
      is_section_divider: true,
      position_in_script: scriptItems.length,
    }
  }
  if (newItem.type === 'instruction' || newItem.type === 'dialogue') {
    return {
      id: null,
      is_section_divider: false,
      position_in_script: scriptItems.length,
      question: null,
      script_text: newItem,
    }
  }
  return {
    id: null,
    is_section_divider: false,
    position_in_script: scriptItems.length,
    question: newItem,
    script_text: null,
  }
}
