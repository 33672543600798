import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  PageHeader,
  Tab,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import { useCurrent } from 'contexts/index'
import CanvasserTable from '../CanvasserTable/CanvasserTable'

const CanvasserSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()
  const [selectedTabId, setSelectedTabId] = useState('active-canvassers')

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewCanvassers = doesCurrentUserHavePermission({
    resource: 'canvasser',
    ability: 'view',
  })
  const canModifyCanvassers = doesCurrentUserHavePermission({
    resource: 'canvasser',
    ability: 'modify',
  })

  if (!canViewCanvassers) {
    return (
      <CardError
        hide={canViewCanvassers}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('Canvassers')}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyCanvassers && e.preventDefault()}
            to={`${location.pathname}/new`}
          >
            <Button.Accent disabled={!canModifyCanvassers}>
              {t('Add canvasser')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer>
        <Sheet>
          <Tab.Container
            selectedTabId={selectedTabId}
            onChange={({ tabId }) => {
              setSelectedTabId(tabId)
            }}
          >
            <Tab label={t('Active canvassers')} id="active-canvassers">
              <CanvasserTable
                isActive={selectedTabId === 'active-canvassers'}
              />
            </Tab>
            <Tab label={t('Archived canvassers')} id="archived-canvassers">
              <CanvasserTable
                archived
                isActive={selectedTabId === 'archived-canvassers'}
              />
            </Tab>
          </Tab.Container>
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default CanvasserSelect
