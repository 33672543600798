import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as CheckboxField } from '@politechdev/blocks-design-system'

const Checkbox = ({
  className,
  label,
  value,
  checked,
  onChange,
  id,
  name,
  disabled,
}) => (
  <CheckboxField
    className={className}
    checked={checked}
    onChange={onChange}
    id={id}
    name={name}
    label={label}
    value={value}
    disabled={disabled}
  />
)

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  checked: PropTypes.bool,
}

Checkbox.defaultProps = {
  id: '',
  label: '',
  className: '',
  disabled: false,
}

export default Checkbox
