import { createContext, useContext, useState } from 'react'
import { defaultTo, merge } from 'lodash'
import { useCurrent } from 'contexts/index'
import { fetchPackets, fetchSchedulesOnDate } from 'requests/qualityControl'
import { useRequest } from 'hooks'
import { currentDateEasternYMDDash } from 'utils/dateTime'
import { USER_TYPES } from 'constants/qualityControl'
import { createStaffCardData } from 'utils/tableQcManager'
import { putPacket } from 'requests/tableQcManager'
import {
  getFormattedPacketCreationDate,
  getFormattedPacketShiftStartDate,
  getStatusForTableReadyPackets,
} from 'utils/qualityControl'
import { useQualityControl } from '../QualityControlContext'
import {
  filterAssignedPackets,
  fetchSchedulesOnDateParams,
} from './staff/utils'

export const PacketAssignmentContext = createContext()

export const PacketAssignmentProvider = ({ children }) => {
  const { currentRoles, currentTurfPerformsExternalQC } = useCurrent()

  const { activePackets, receivePackets } = useQualityControl()
  const [totalPacketCount, setTotalPacketCount] = useState(0)

  const [isReassignmentModalOpen, setIsReassignmentModalOpen] = useState(false)
  const [isReassignmentScheduleOpen, setIsReassignmentScheduleOpen] =
    useState(false)
  const [packetsBeingAssigned, setPacketsBeingAssigned] = useState([])

  const [isReprioritizationModalOpen, setIsReprioritizationModalOpen] =
    useState(false)
  const [packetsBeingReprioritized, setPacketsBeingReprioritized] = useState([])

  const [isTurfReprioritizationModalOpen, setIsTurfReprioritizationModalOpen] =
    useState(false)

  const [scheduledUsers, setScheduledUsers] = useState([])
  const [totalCurrentStaffSchedulesCount, setTotalCurrentStaffSchedulesCount] =
    useState(0)

  const [userOptions, setUserOptions] = useState([])

  const scheduledUsersCardData = scheduledUsers.map(scheduledUser => ({
    ...createStaffCardData(scheduledUser),
    isViewable:
      !currentTurfPerformsExternalQC ||
      currentRoles.find(role => role.id === scheduledUser.role.id),
  }))

  const scheduledForReassignmentByTerm = scheduledUsersCardData
    .map(staff => ({
      ...staff,
      assignablePackets: packetsBeingAssigned.filter(packet =>
        staff.assignablePacketIds.includes(packet.id)
      ).length,
      unassignablePackets: packetsBeingAssigned.filter(
        packet => !staff.assignablePacketIds.includes(packet.id)
      ).length,
    }))
    .sort((a, b) => b.assignablePackets - a.assignablePackets)

  const tableReadyPackets = activePackets.map(packet => ({
    id: packet.id,
    scan_name: packet.original_filename,
    original_filename: packet.original_filename,
    scans_count: packet.scans_count,
    assignee: packet.assignee
      ? packet.assignee.name || packet.assignee.email
      : '',
    assigned_user: packet.assignee,
    shift: packet.shift,
    creator: packet.creator,
    location: packet.location,
    shift_status: getStatusForTableReadyPackets(packet),
    turf: packet.turf,
    qc_office: packet.assignee ? packet.assignee.qc_office || '' : '',
    qc_deadline: packet.qc_deadline,
    language: packet.turf?.phone_verification_language,
    state: packet.location?.state,
    date_uploaded_at: getFormattedPacketCreationDate(packet),
    date_collected_at: getFormattedPacketShiftStartDate(packet),
  }))

  const filterSchedulesByTurf = ({ user }) =>
    user.turf.qc_config.performs_external_qc === currentTurfPerformsExternalQC

  const getQcPackets = async (params = {}) => {
    const rules = [
      {
        column: 'shift_status',
        operator: 'in',
        param: [
          'ready_for_qc',
          'in_qc',
          'ready_for_phone_verification',
          'in_phone_verification',
        ],
      },
      ...defaultTo(params.filterRules, []),
      {
        column: 'shift_qc_external',
        operator: 'is',
        param: currentTurfPerformsExternalQC,
      },
      {
        column: 'unresolved_flag_triggers_stops_qc',
        operator: 'is',
        invert: 'true',
        param: true,
      },
    ]

    const {
      packets,
      meta: { total_count: totalPacketCount },
    } = await fetchPackets(
      {
        current_page: params.page,
        per: params.pageSize,
        sort_attr: defaultTo(params.sortColumn, 'id'),
        sort_dir: defaultTo(params.sortOrder, 'asc'),
        filters: {
          rules,
        },
        fields: merge([{ shift: ['status'] }], defaultTo(params.fields, [])),
      },
      { strictNullHandling: true }
    )

    receivePackets(packets)
    setTotalPacketCount(totalPacketCount)
  }

  const {
    makeRequest: getScheduledUsers,
    isLoading: getScheduledUsersIsLoading,
    hasErrors: getScheduledUsersHasErrors,
  } = useRequest(
    () =>
      fetchSchedulesOnDate(
        currentDateEasternYMDDash,
        fetchSchedulesOnDateParams
      ),
    {
      onSuccess: schedulesOnDateResponse => {
        const filteredSchedules = schedulesOnDateResponse.schedules.filter(
          filterSchedulesByTurf
        )

        setTotalCurrentStaffSchedulesCount(
          filteredSchedules.filter(
            ({ user_type }) => user_type === USER_TYPES.STAFF
          ).length
        )

        setScheduledUsers(
          filteredSchedules.map(schedule => {
            const { id, date, minutes, user } = schedule
            const quality_control_schedules = [{ id, date, minutes }]
            const assigned_packets = filterAssignedPackets(
              user.assigned_packets
            )
            return {
              ...user,
              quality_control_schedules,
              assigned_packets,
              assignable_packet_ids:
                schedulesOnDateResponse.meta.assignable_packet_ids[user.id] ||
                [],
            }
          })
        )

        setUserOptions(
          filteredSchedules.map(({ user }) => ({
            value: user.name,
            label: user.name,
          }))
        )
      },
    }
  )

  const unassignPackets = packetsToUnassign => {
    const requests = packetsToUnassign.map(packetToUnassign =>
      putPacket(packetToUnassign.id, { assignee_id: null })
    )

    return Promise.all(requests)
  }

  const unassignPacket = async packetToUnassign =>
    unassignPackets([packetToUnassign])

  const packetAssignmentContextProps = {
    isReassignmentModalOpen,
    setIsReassignmentModalOpen,
    isReassignmentScheduleOpen,
    setIsReassignmentScheduleOpen,
    isReprioritizationModalOpen,
    setIsReprioritizationModalOpen,
    isTurfReprioritizationModalOpen,
    setIsTurfReprioritizationModalOpen,
    getQcPackets,
    tableReadyPackets,
    packetsBeingAssigned,
    setPacketsBeingAssigned,
    packetsBeingReprioritized,
    setPacketsBeingReprioritized,
    totalPacketCount,
    scheduledUsers,
    userOptions,
    totalCurrentStaffSchedulesCount,
    scheduledUsersCardData,
    scheduledForReassignmentByTerm,
    getScheduledUsers,
    getScheduledUsersIsLoading,
    getScheduledUsersHasErrors,
    unassignPackets,
    unassignPacket,
  }

  return (
    <PacketAssignmentContext.Provider value={packetAssignmentContextProps}>
      {children}
    </PacketAssignmentContext.Provider>
  )
}

export const usePacketAssignment = () => useContext(PacketAssignmentContext)
