import { useTranslation } from 'react-i18next'
import { StaffLanguages } from 'qualityControl/packetAssignment/staff/StaffLanguages'
import { NumberField } from '@politechdev/blocks-design-system'
import { defaultTo, merge } from 'lodash'
import { usePacketAssignment } from '../../PacketAssignmentContext'

const PacketListItem = ({
  packetId,
  packetName,
  daysLeft,
  packetLanguage,
  reprioritization,
}) => {
  const { t } = useTranslation()
  const { setPacketsBeingReprioritized } = usePacketAssignment()

  const setReprioritizedPacketDaysLeft = (packetId, daysInput) =>
    setPacketsBeingReprioritized(oldPackets =>
      oldPackets.map(packet => {
        if (packet.id !== packetId) return packet

        return merge(packet, { reprioritization: daysInput })
      })
    )

  return (
    <div className="list__item">
      <div className="list__item__content">
        <div className="text-content">
          <div key="primaryText" className="primary">
            {packetName}
          </div>
          <div className="secondary">{daysLeft}</div>
        </div>
        <div className="button__group margin--left" key="actions">
          <StaffLanguages languages={[packetLanguage]} />
        </div>
      </div>
      <div className="list__item__details">
        <NumberField
          id={`${packetId}-reprioritization-days-left`}
          label={t('Days left')}
          step={1}
          value={defaultTo(reprioritization, undefined)}
          onChange={val => {
            setReprioritizedPacketDaysLeft(packetId, val)
          }}
        />
      </div>
    </div>
  )
}

export default PacketListItem
