import { stringifyParams, fetchJSON } from 'utils/req'
import nanoid from 'nanoid'
import { currentDateEasternYMDDash } from 'utils/dateTime'
import {
  COMPLETED,
  IN_PHONE_VERIFICATION,
  IN_QC,
  READY_FOR_PHONE_VERIFICATION,
  READY_FOR_QC,
} from '../constants/qualityControl'

export const fetchPledgeCardGalleryPacket = (packetId, params) =>
  fetchJSON(
    `/api/v1/packets/${packetId}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchPledgeCardGalleryScans = (scanId, params) =>
  fetchJSON(`/api/v1/scans/${scanId}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchSchedules = (params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/schedules?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchSchedulesOnDate = async (date, params = {}) => {
  const { quality_control_schedules: schedules, meta } = await fetchJSON(
    `/api/v1/quality_control/schedules/date/${date}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
  const schedulesWithLocalId = schedules.map(schedule => ({
    ...schedule,
    localId: nanoid(),
  }))
  return { schedules: schedulesWithLocalId, meta }
}

export const fetchScheduleUserOptions = (query, currentContext) => {
  const {
    currentUser: { qc_office: userQcOffice, turf: currentTurf },
  } = currentContext
  const isQc = currentTurf.qc_config.performs_external_qc

  const requestParams = {
    fields: ['id', 'name', 'email', 'extras'],
    filters: {
      rules: [
        {
          column: 'disabled_at',
          operator: 'is_nil',
        },
        {
          rules: isQc
            ? [
                {
                  column: 'with_ability',
                  param: ['quality_control/schedule', 'modify'],
                },
                {
                  column: 'with_performs_external_qc',
                  param: true,
                },
                userQcOffice
                  ? {
                      column: 'qc_office',
                      operator: 'is',
                      param: userQcOffice,
                    }
                  : undefined,
              ]
            : [
                {
                  column: 'with_ability',
                  param: ['quality_control/schedule', 'modify'],
                },
                {
                  column: 'with_performs_external_qc',
                  param: false,
                },
              ],
        },
        query
          ? {
              column: 'full_name',
              operator: 'containing',
              param: query,
            }
          : undefined,
      ],
    },
    per: 20,
    indexed: true,
  }

  return fetchJSON(
    `/api/v1/users?${stringifyParams(requestParams)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
}

export const fetchUserOptions = params =>
  fetchJSON(`/api/v1/users?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchPackets = params =>
  fetchJSON(`/api/v1/packets?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchAssignablePackets = params =>
  fetchJSON(
    `/api/v1/packets/assignable_for_qc?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const advanceShiftStatus = (shiftId, newStatus, payload = {}) => {
  const eventUrls = {
    [READY_FOR_QC]: 'set_packet_info',
    [IN_QC]: 'start_qc',
    [READY_FOR_PHONE_VERIFICATION]: 'approve_scans',
    [IN_PHONE_VERIFICATION]: 'start_phone_verification',
    [COMPLETED]: 'verify_scans',
  }

  const path = `/api/v1/shifts/${shiftId}/${
    eventUrls[newStatus]
  }?${stringifyParams({
    fields: ['status'],
  })}`

  return fetchJSON(path, 'PUT', payload, {
    useJwt: true,
  })
}

export const setPacketInfo = async (id, packetInfo) => {
  const response = await advanceShiftStatus(id, READY_FOR_QC, {
    event_args: [packetInfo],
  })
  return response.shift
}

export const fetchAutoAssignSystemJobStatus = () =>
  fetchJSON(
    `/api/v1/jobs?${stringifyParams({ worker: 'BlocksCore.VoterRegistration.AutoAssigner', status: 'in-progress' })}`,
    'GET',
    null,
    { useJwt: true }
  )

export const saveSchedules = async (assignAll, schedulesToUpdate) => {
  const requests = schedulesToUpdate.map(
    ({ id, minutes = 0, user, user_type, remove }) => {
      if (remove) {
        return fetchJSON(
          `/api/v1/quality_control/schedules/${id}`,
          'DELETE',
          null,
          {
            useJwt: true,
          }
        )
      }
      if (id) {
        return fetchJSON(
          `/api/v1/quality_control/schedules/${id}`,
          'PUT',
          { schedule: { minutes, user_type } },
          { useJwt: true }
        )
      }
      return fetchJSON(
        `/api/v1/quality_control/schedules`,
        'POST',
        {
          schedule: {
            user_id: user.id,
            minutes,
            user_type,
            date: currentDateEasternYMDDash,
          },
        },
        { useJwt: true }
      )
    }
  )

  await Promise.all(requests)

  if (assignAll) {
    const { job_id } = await fetchJSON(
      `/api/v1/packets/auto_assign`,
      'POST',
      undefined,
      {
        useJwt: true,
      }
    )

    fetchAutoAssignSystemJobStatus(job_id)
    return job_id
  }
}
