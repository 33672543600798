import { useTranslation } from 'react-i18next'
import {
  Section,
  DetailItem,
  SectionLabel,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import UserPasswordChangeModal from '../UserPasswordChangeModal/UserPasswordChangeModal'
import UserTwoFactorNumberChangeModal from '../UserTwoFactorNumberChangeModal/UserTwoFactorNumberChangeModal'
import styles from './UserSecurityDetails.module.scss'

const UserSecurityDetails = () => {
  const { t } = useTranslation()

  const {
    currentUser: { two_factor_phone_last_four: lastFour },
    tenantOptions: { two_factor_required: isRequired },
  } = useCurrent()

  return (
    <div className={styles.container}>
      <ContentBlock>
        <SectionLabel>{t('Security')}</SectionLabel>
      </ContentBlock>
      <Section>
        {isRequired && (
          <DetailItem label={t('Two factor phone number')}>
            <div className={styles.item}>
              {lastFour ? (
                <span>
                  {t('ends in')} <strong>{lastFour}</strong>
                </span>
              ) : (
                <span>{t('not set')}</span>
              )}
              <UserTwoFactorNumberChangeModal />
            </div>
          </DetailItem>
        )}
        <DetailItem label={t('Password')}>
          <div className={styles.item}>
            <span>•••••••</span>
            <UserPasswordChangeModal />
          </div>
        </DetailItem>
      </Section>
    </div>
  )
}

export default UserSecurityDetails
