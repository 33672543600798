import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Font } from '@politechdev/blocks-design-system'
import styles from './OperatorGauge.module.scss'
import { useCallStats } from '../OperatorContext/OperatorContext'

const OperatorGauge = () => {
  const { t } = useTranslation()
  const { agentsAvailable, callsRinging, pickupPercent } = useCallStats()

  const width = useMemo(() => {
    const estimatedReached = callsRinging * pickupPercent

    if (agentsAvailable > 0) {
      return (estimatedReached / agentsAvailable) * 100
    }

    return estimatedReached > 0 ? estimatedReached * 100 : 0
  }, [agentsAvailable, callsRinging, pickupPercent])

  const backgroundColor = useMemo(() => {
    if (width >= 90) return 'var(--color-danger)'

    if (width >= 80) {
      return 'var(--color-warning)'
    }
    if (width >= 0) {
      return 'var(--color-safe)'
    }
  }, [width])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.guide}>
          <div className={styles.green} />
          <div className={styles.yellow} />
          <div className={styles.red} />
        </div>
        <div className={styles.main}>
          <div
            className={styles['main--active']}
            style={{ width: `${width}%`, backgroundColor }}
          />
          <div
            className={styles['main--inactive']}
            style={{ width: `calc(100% - ${width}%)` }}
          />
        </div>
      </div>
      <Font.Copy variant="hint" className={styles.hint}>
        {t('Estimating {{ count }} contacts will answer', {
          count: Math.round(callsRinging * pickupPercent),
        })}
      </Font.Copy>
    </div>
  )
}

export default OperatorGauge
