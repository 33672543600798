export const PHONE_VERIFICATION_LANGUAGE_OPTIONS = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' },
]

export const TURF_TYPE_OPTIONS = [
  { label: 'Canvassing', value: true },
  { label: 'Managing', value: false },
]

export const QC_EXTERNAL_OPTIONS = [
  {
    label: 'Internal',
    value: false,
  },
  { label: 'External', value: true },
]

export const COPYABLE_FIELDS = [
  {
    values: ['qc_config.external'],
    label: 'QC organization',
  },
  {
    values: ['qc_turnaround_days'],
    label: 'Days of turnaround to complete QC',
  },
  {
    values: [
      'qc_config.visual_review_enabled',
      'qc_config.phone_verification_enabled',
    ],
    label: 'Required QC stages',
  },
  {
    values: ['phone_verification_language'],
    label: 'Phone verification language',
    shouldShow: turf => turf.qc_config?.phone_verification_enabled,
  },
  {
    values: ['min_phone_verification_percent'],
    label: 'Phone verification threshold',
    shouldShow: turf => turf.qc_config?.phone_verification_enabled,
  },
  {
    values: ['min_phone_verification_rounds'],
    label: 'Phone verification rounds',
    shouldShow: turf => turf.qc_config?.phone_verification_enabled,
  },
  {
    values: ['qc_config.required_for_delivery'],
    label: 'Delivery requirements',
  },
]
