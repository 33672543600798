import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './PanelMessage.module.scss'

const cx = classNames.bind(styles)

const PanelMessage = ({ title, message, className }) => (
  <div className={cx('container', className)}>
    {title && <h2>{title}</h2>}
    {message && <p>{message}</p>}
  </div>
)

PanelMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
}

export default PanelMessage
