import { createContext, useState } from 'react'

export const GoalSummaryContext = createContext()

const GoalSummaryContextProvider = ({ children }) => {
  const [totalStartDate, setTotalStartDate] = useState()
  const [totalEndDate, setTotalEndDate] = useState()
  const [totalTarget, setTotalTarget] = useState(0)
  const [weeklySum, setWeeklySum] = useState(0)
  const [targets, setTargets] = useState([])
  const [isSplit, setIsSplit] = useState(false)

  return (
    <GoalSummaryContext.Provider
      value={{
        totalStartDate,
        setTotalStartDate,
        totalEndDate,
        setTotalEndDate,
        totalTarget,
        setTotalTarget,
        weeklySum,
        setWeeklySum,
        targets,
        setTargets,
        isSplit,
        setIsSplit,
      }}
    >
      {children}
    </GoalSummaryContext.Provider>
  )
}

export default GoalSummaryContextProvider
