/* eslint-disable jsx-a11y/media-has-caption */
import { useTranslation } from 'react-i18next'
import {
  ContentBlock,
  Font,
  ButtonBlock,
  Button,
  Icon,
} from '@politechdev/blocks-design-system'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { formatTimeGivenSeconds } from 'utils/dateTime'
import twilio from 'services/twilio'
import AudioProgress from './AudioProgress'

import styles from './RecordingAudio.module.scss'

const displayNone = () => null
const noop = () => {}

const RecordingAudio = forwardRef(
  (
    {
      recordingSid,
      isActive,
      onPlay = noop,
      onPause = noop,
      InactiveDisplayComponent = displayNone,
      LabelComponent = displayNone,
    },
    refFromParent
  ) => {
    const audioRef = useRef(new Audio())

    const [isMediaLoading, setIsMediaLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [trackProgress, setTrackProgress] = useState(0)
    const [playbackError, setPlaybackError] = useState(null)

    const { t } = useTranslation()

    const loadMedia = async recordingSid => {
      setIsMediaLoading(true)
      try {
        const response = await twilio.fetchRecording(recordingSid)

        if (!response.ok) {
          throw new Error()
        }

        const arrayBuffer = await response.arrayBuffer()
        const blob = new Blob([arrayBuffer], { type: 'audio/wav' })
        const url = window.URL.createObjectURL(blob)
        audioRef.current.src = url
      } catch (error) {
        setPlaybackError(t('There was an issue loading this recording'))
        return false
      } finally {
        setIsMediaLoading(false)
      }
      return true
    }

    const getIsAudioPaused = () => audioRef.current?.paused

    const handleAudioToggle = async () => {
      if (getIsAudioPaused()) {
        if (!audioRef.current.src) {
          const loaded = await loadMedia(recordingSid)
          if (!loaded) {
            return
          }
        }
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }

    useImperativeHandle(
      refFromParent,
      () => ({
        reset: () => {
          setTrackProgress(0)
          audioRef.current.currentTime = 0
        },
        play: () => {
          getIsAudioPaused() && handleAudioToggle()
        },
        pause: () => {
          !getIsAudioPaused() && handleAudioToggle()
        },
      }),
      []
    )

    const handleOnPlay = () => {
      setIsPlaying(true)
      setPlaybackError(null)
      onPlay()
    }

    const handleOnPause = () => {
      setIsPlaying(false)
      onPause()
    }

    const handleTimeUpdate = () => {
      setTrackProgress(audioRef.current.currentTime)
    }

    const handlePlaybackError = () => {
      setIsPlaying(false)
      setPlaybackError(t('There was an issue playing this recording'))
    }

    const handleScrub = value => {
      audioRef.current.currentTime = value
    }

    const audioDuration = audioRef.current?.duration || 0

    const timeLeft = formatTimeGivenSeconds(
      Math.round(audioDuration - trackProgress)
    )

    return (
      <>
        <ContentBlock className={styles['slider-block']}>
          <audio
            preload="metadata"
            ref={audioRef}
            onError={handlePlaybackError}
            onPause={handleOnPause}
            onPlay={handleOnPlay}
            onTimeUpdate={handleTimeUpdate}
          />
          <LabelComponent />
          {!isActive && !isMediaLoading && !playbackError && (
            <InactiveDisplayComponent />
          )}
          {isMediaLoading && (
            <span className={styles['slider-container']}>
              <Font.Copy Element="span" variant="hint">
                {t('Loading audio')}&hellip;
              </Font.Copy>
            </span>
          )}
          {isActive && !playbackError && (
            <span className={styles['slider-container']}>
              <AudioProgress
                value={trackProgress}
                max={audioDuration}
                onChange={handleScrub}
              />
              <Font.Copy Element="span" variant="hint">
                -{timeLeft}
              </Font.Copy>
            </span>
          )}
          {isActive && !isMediaLoading && playbackError && (
            <Font.Copy variant="error">{playbackError}</Font.Copy>
          )}
        </ContentBlock>
        <ButtonBlock justify="right" className={styles['button-block']}>
          <Button.Secondary
            onClick={handleAudioToggle}
            disabled={isMediaLoading}
          >
            {isPlaying ? (
              <Icon.Pause aria-label="pause" data-testid="recording-pause" />
            ) : (
              <Icon.Play aria-label="play" data-testid="recording-play" />
            )}
          </Button.Secondary>
        </ButtonBlock>
      </>
    )
  }
)

export default RecordingAudio
