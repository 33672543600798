import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, TextField } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { useEvent, useRequest } from 'hooks'
import { putList } from 'requests/lists'
import styles from './ListEditModal.module.scss'

const ListEditModal = ({
  currentList,
  makeListRequest,
  setEditModalOpen,
  isEditModalOpen,
}) => {
  const { t } = useTranslation()
  const [name, setName] = useState(currentList.name)

  const { makeRequest, isLoading, hasErrors } = useRequest(putList, {
    onSuccess: () => {
      makeListRequest()
      setEditModalOpen(false)
    },
  })

  const save = useEvent(() => {
    makeRequest(
      currentList.id,
      {
        name,
      },
      currentList
    )
  })

  return (
    <Modal
      id="list-edit"
      title={t(`Edit list`)}
      isOpen={isEditModalOpen}
      showLoading={isLoading}
      className={styles.modal}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!hasErrors}
            message={t('There was an error editing your list')}
            hideSupportLink
          />
          <div className={styles.field}>
            <TextField
              id="name"
              label={t('Name')}
              value={name}
              onChange={setName}
              required
            />
          </div>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={() => setEditModalOpen(false)}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={save} disabled={name === '' || isLoading}>
            {t('Save')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ListEditModal
