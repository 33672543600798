import translatedCellRenderer from 'tableCells/TranslatedCell'
import {
  ContactListCell,
  DraftNameCell,
  SentByCell,
  SentOnCell,
} from './EmailSelectTableCells'

export default {
  label: 'Emails sent',
  defaultSortOrder: 'desc',
  defaultSortColumn: 'created_at',
  columns: [
    {
      dataKey: 'created_at',
      title: 'Sent on',
      type: 'date',
      headerCell: translatedCellRenderer,
      bodyCell: SentOnCell,
    },
    {
      dataKey: 'template_name',
      title: 'Draft name',
      type: 'string',
      headerCell: translatedCellRenderer,
      bodyCell: DraftNameCell,
    },
    {
      dataKey: 'subject',
      title: 'Subject',
      type: 'string',
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'sender_full_name',
      title: 'Sent by',
      type: 'string',
      headerCell: translatedCellRenderer,
      bodyCell: SentByCell,
    },
    {
      title: 'Contact list',
      type: 'string',
      sortable: false,
      filterable: false,
      headerCell: translatedCellRenderer,
      bodyCell: ContactListCell,
    },
    {
      dataKey: 'recipients_count',
      title: 'Emails sent',
      type: 'number',
      headerCell: translatedCellRenderer,
    },
  ],
}
