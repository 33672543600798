import {
  Icon,
  SelectField,
  Sheet,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import styles from 'registrationReports/ReportPicker.module.scss'

const PetitionReportsPicker = ({
  categoryId,
  changeReportCategory,
  reportCategories,
  changeReportType,
  reportType,
  reportTypes,
}) => {
  const { t } = useTranslation()

  return (
    <Sheet>
      <FieldBlock>
        <SelectField
          id="report-category"
          label={t('Report')}
          className={styles.select}
          options={reportCategories.map(category => ({
            value: category.id,
            label: t(category.name),
          }))}
          value={categoryId}
          onSelect={changeReportCategory}
        />
        {reportType ? (
          <>
            <span>
              <Icon.ChevronRight key="chevron" />
            </span>
            <SelectField
              className={styles.select}
              key="report-type"
              id="report-type"
              label="Narrow by"
              options={reportCategories
                .find(reportCategory => reportCategory.id === categoryId)
                .reportTypes.map(id => {
                  const reportType = reportTypes[id]
                  return {
                    value: reportType.id,
                    label: reportType.name,
                    disabled: false,
                  }
                })}
              value={reportType}
              onSelect={changeReportType}
            />
          </>
        ) : null}
      </FieldBlock>
    </Sheet>
  )
}

export default PetitionReportsPicker
