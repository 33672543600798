import React, { createContext, useContext, useEffect } from 'react'
import { useCheckboxGroup } from 'hooks/useCheckboxGroup'

const ListCheckboxContext = createContext()

export const ListCheckboxProvider = ({ lists = [], children }) => {
  const checkboxManagement = useCheckboxGroup(lists)

  useEffect(() => {
    checkboxManagement.setCheckboxData(lists)
  }, [lists])

  return (
    <ListCheckboxContext.Provider value={checkboxManagement}>
      {children}
    </ListCheckboxContext.Provider>
  )
}

export const useListCheckboxes = () => {
  const context = useContext(ListCheckboxContext)
  if (context === undefined) {
    throw new Error(
      'useListCheckboxes must be used within a ListCheckboxProvider'
    )
  }
  return context
}
