import { isValidDate } from 'utils/dateTime'

const rEXTENDED_DATE_IDENTIFIER = /^[+-]/

export const validateDate = maybeDate => {
  const INVALID_DATE = 'Date is invalid'

  if (!isValidDate(maybeDate)) return INVALID_DATE

  if (maybeDate.match(rEXTENDED_DATE_IDENTIFIER)) return INVALID_DATE

  return null
}
