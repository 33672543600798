import React from 'react'
import classNames from 'classnames/bind'
import { Icon } from '@politechdev/blocks-design-system'
import { DOCUMENT_TYPES } from '../constants'
import { getDocumentType } from '../utils'
import styles from './DocumentIcon.module.scss'

const cx = classNames.bind(styles)

const DocumentIcon = ({ mime_type }) => {
  const getIcon = () => {
    switch (getDocumentType(mime_type)) {
      case DOCUMENT_TYPES.ARCHIVE:
        return <Icon.FileArchive />
      case DOCUMENT_TYPES.AUDIO:
        return <Icon.FileAudio />
      case DOCUMENT_TYPES.CODE:
        return <Icon.FileCode />
      case DOCUMENT_TYPES.CSV:
        return <Icon.FileCsv />
      case DOCUMENT_TYPES.EXCEL:
        return <Icon.FileExcel />
      case DOCUMENT_TYPES.IMAGE:
        return <Icon.FileImage />
      case DOCUMENT_TYPES.PDF:
        return <Icon.FilePdf />
      case DOCUMENT_TYPES.POWERPOINT:
        return <Icon.FilePowerpoint />
      case DOCUMENT_TYPES.TEXT:
        return <Icon.FileAlt />
      case DOCUMENT_TYPES.VIDEO:
        return <Icon.FileVideo />
      case DOCUMENT_TYPES.WORD:
        return <Icon.FileWord />
      case DOCUMENT_TYPES.OTHER:
      default:
        return <Icon.File />
    }
  }

  return (
    <div className={cx('icon', mime_type.replace('DOCUMENT_TYPES/', ''))}>
      {getIcon()}
    </div>
  )
}

export default DocumentIcon
