import React from 'react'

const DocumentLink = ({ url, onContextMenu, children }) => {
  const handleClick = event => {
    const comboKey =
      event.metaKey || event.altKey || event.ctrlKey || event.shiftKey

    if (!comboKey) {
      event.preventDefault()
    }
  }

  const handleDoubleClick = () => {
    window.open(url, '_blank')
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      window.open(url, '_blank')
    }
  }

  if (!url) return children

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onKeyDown={handleKeyDown}
      onContextMenu={onContextMenu}
    >
      {children}
    </a>
  )
}

export default DocumentLink
