import {
  MemberNameCell,
  MemberResponsibilityCellRenderer,
  MemberAddressCell,
  MemberPhoneCell,
  MemberEmailCell,
  MemberFirstNameCell,
  MemberLastNameCell,
} from './TeamMemberTableCells'
import {
  memberNameCsvCell,
  memberResponsibilityCsvCell,
  memberAddressCsvCell,
  memberPhoneCsvCell,
  memberEmailCsvCell,
  memberFirstNameCsvCell,
  memberLastNameCsvCell,
} from './teamMemberCsvCells'

export default {
  label: 'team_memberships',
  columns: [
    {
      dataKey: 'person_full_name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: MemberNameCell,
      csvCell: memberNameCsvCell,
    },
    {
      dataKey: 'person_first_name',
      title: 'First name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberFirstNameCell,
      csvCell: memberFirstNameCsvCell,
    },
    {
      dataKey: 'person_last_name',
      title: 'Last name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: MemberLastNameCell,
      csvCell: memberLastNameCsvCell,
    },
    {
      dataKey: 'responsibility',
      title: 'Responsibility',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: MemberResponsibilityCellRenderer,
      csvCell: memberResponsibilityCsvCell,
    },
    {
      dataKey: 'address',
      title: 'Address',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: false,
      bodyCell: MemberAddressCell,
      csvCell: memberAddressCsvCell,
    },
    {
      dataKey: 'phone_number',
      title: 'Phone number',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      filterable: false,
      bodyCell: MemberPhoneCell,
      csvCell: memberPhoneCsvCell,
    },
    {
      dataKey: 'email',
      title: 'Email',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      filterable: false,
      bodyCell: MemberEmailCell,
      csvCell: memberEmailCsvCell,
    },
  ],
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: { hiddenColumns: ['person_first_name', 'person_last_name'] },
      },
    ]
  },
}
