import { createContext, useContext, useEffect, useState } from 'react'
import { useQueryParams } from 'contexts'

const PaginationContext = createContext()

export const PaginationProvider = ({
  enableQueryParams = false,
  enablePageSize = true,
  defaultPageSize = 10,
  children,
}) => {
  const { queryParams, setQueryParams } = useQueryParams(enableQueryParams)

  const [page, setPage] = useState(+queryParams.page || 1)
  const [pageSize, setPageSize] = useState(
    +queryParams.pageSize || defaultPageSize
  )

  useEffect(() => {
    const defaultParams = {}
    if (!+queryParams.page) {
      defaultParams.page = page
    }
    if (!+queryParams.pageSize && enablePageSize) {
      defaultParams.pageSize = pageSize
    }
    setQueryParams(defaultParams, 'replace')
  }, [])

  const updatePage = page => {
    setQueryParams({
      page,
    })
    setPage(page)
  }

  const updatePageSize = pageSize => {
    if (!enablePageSize) return

    setQueryParams({
      pageSize,
      page: 1,
    })
    setPageSize(pageSize)
    setPage(1)
  }

  return (
    <PaginationContext.Provider
      value={{
        page,
        pageSize,
        setPage: updatePage,
        setPageSize: updatePageSize,
      }}
    >
      {children}
    </PaginationContext.Provider>
  )
}

export const usePagination = (enabled = true) => {
  if (!enabled) {
    return {
      page: null,
      pageSize: null,
      setPage: () => {},
      setPageSize: () => {},
    }
  }

  const context = useContext(PaginationContext)
  if (context === undefined) {
    throw new Error('usePagination must be used within a PaginationProvider')
  }
  return context
}
