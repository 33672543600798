import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
  TextField,
} from '@politechdev/blocks-design-system'
import { useFilters } from 'contexts'
import { useRequest, useEvent } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'
import { destroyFilter, putFilter } from 'requests/tableFilters'

const EditFilterModal = ({ close, isOpen }) => {
  const { t } = useTranslation()
  const {
    activeFilter,
    clearActiveFilter,
    setSavedFilters,
    setActiveFilterId,
    fetchRequest,
  } = useFilters()
  const [newFilterName, setNewFilterName] = useState('')
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    activeFilter && setNewFilterName(activeFilter.name)
  }, [activeFilter, isOpen])

  const handleClose = () => {
    close()
    setHasError(false)
    setNewFilterName('')
  }

  const {
    makeRequest: update,
    isLoading: isUpdateLoading,
    errors: updateErrors,
    hasErrors: hasUpdateErrors,
  } = useRequest(putFilter, {
    onSuccess: ({ filter_view }) => {
      setSavedFilters(oldFilters => {
        const newFilters = oldFilters[filter_view.metadata.label] || []
        const index = newFilters.findIndex(
          filter => filter.id === filter_view.id
        )

        if (index > -1) {
          newFilters[index] = filter_view
        } else {
          newFilters.push(filter_view)
        }

        return {
          ...oldFilters,
          [filter_view.metadata.label]: newFilters,
        }
      })
      handleClose()
    },
  })

  const {
    makeRequest: destroy,
    isLoading: isDeleteLoading,
    errors: deleteErrors,
    hasErrors: hasDeleteErrors,
  } = useRequest(destroyFilter, {
    onSuccess: () => {
      fetchRequest()
      setActiveFilterId()
      handleClose()
      clearActiveFilter()
    },
  })

  const updateErrorMsg = formatErrorMessage(updateErrors)
  const deleteErrorMsg = formatErrorMessage(deleteErrors)

  useEffect(() => {
    if (hasUpdateErrors || hasDeleteErrors) {
      setHasError(true)
    }
  }, [hasUpdateErrors, hasDeleteErrors])

  const doUpdateFilter = useEvent(() =>
    update({ ...activeFilter, name: newFilterName })
  )
  const doDeleteFilter = useEvent(() => destroy(activeFilter?.id))

  return (
    <Modal
      id="edit-filter-modal"
      title={t('Edit filter')}
      className="table-filters__modal"
      onHide={handleClose}
      isOpen={isOpen}
      showLoading={isUpdateLoading || isDeleteLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <TextField
            id="filter-name-input"
            label={t('Filter name')}
            value={newFilterName}
            onChange={setNewFilterName}
            required
            errorMessage={t('Saved filters must be named')}
          />
          <CardError
            hide={!hasError}
            message={updateErrorMsg || deleteErrorMsg}
            hideSupportLink
          />
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Primary onClick={handleClose}>{t('Cancel')}</Button.Primary>
          <Button.Accent
            disabled={
              newFilterName.length === 0 || newFilterName === activeFilter?.name
            }
            onClick={doUpdateFilter}
          >
            {t('Update filter')}
          </Button.Accent>
          <Button.Danger onClick={doDeleteFilter}>
            {t('Delete filter')}
          </Button.Danger>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default EditFilterModal
