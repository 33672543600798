import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  TextField,
  Sheet,
  ProgressBar,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import InterpolationSelect from './InterpolationSelect/InterpolationSelect'
import styles from './ScriptItemNewForm.module.scss'
import { PhoneBankContext } from '../PhoneBankContext/PhoneBankContext'

const ScriptItemNewForm = ({ type }) => {
  const [content, setContent] = useState('')
  const { t } = useTranslation()
  const { makeScriptText, scriptTextReqIsLoading, scriptTextReqHasErrors } =
    useContext(PhoneBankContext)

  return (
    <Sheet>
      <ProgressBar show={scriptTextReqIsLoading} />
      <CardError
        hide={!scriptTextReqHasErrors}
        message={`${t('There was an error creating this')} ${type}.`}
      />
      <FieldBlock variant="large">
        <TextField
          id="item-text"
          label={t('Text')}
          value={content}
          rows={1}
          onChange={setContent}
          ignoreWhitespace
        />
      </FieldBlock>
      <ContentBlock>
        <InterpolationSelect
          selectionStartId="item-text"
          updateText={setContent}
          currentText={content}
        />
      </ContentBlock>
      <ButtonBlock className={styles['save-button']}>
        <Button.Accent
          disabled={scriptTextReqIsLoading}
          onClick={() => makeScriptText({ type, text: content })}
        >
          {t('Save')}
        </Button.Accent>
      </ButtonBlock>
    </Sheet>
  )
}

export default ScriptItemNewForm
