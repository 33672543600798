import pixelWidth from 'string-pixel-width'
import { isNil } from 'lodash/lang'

// The "string-pixel-width" library has built-in support for these fonts:
// https://github.com/adambisek/string-pixel-width#supported-fonts
//
// BUT! if we move to an unsupported font for our tables, that repo contains
// instructions on how to create a "character width map" for any font.
//
// Following that, we could:
// a) PR that widthmap to the original lib, or
// b) fork the lib and use the new widthmap in our version, or
// c) recreate the lib's central function here in the codebase.

const DISPLAY_FONT = {
  font: 'avenir next',
  size: 13,
}
const CELL_PADDING = 32
const WIDTH_BUFFER = 8
const minWidth = CELL_PADDING + WIDTH_BUFFER

const getCellWidth = columnText => {
  if (isNil(columnText)) return minWidth

  try {
    const safeText = columnText.toString().trim()

    const width = safeText.length
      ? Math.round(pixelWidth(columnText, DISPLAY_FONT) + minWidth)
      : minWidth

    return width
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error calculating pixel width of column value.`, {
      columnValue: columnText,
      error,
    })

    return minWidth
  }
}

export const getMinColumnWidth = (data, colKey, colTitle) => {
  if (!data.length) return minWidth
  const lengths = data
    .map(row => getCellWidth(row[colKey]))
    .concat(getCellWidth(colTitle))
  return Math.max(...lengths)
}
