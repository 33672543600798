import React, { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, Button } from '@politechdev/blocks-design-system'
import { useSearch } from '../SearchProvider/SearchProvider'
import styles from './SearchBar.module.scss'

const SearchBar = () => {
  const { t } = useTranslation()
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [])

  const { query, setQuery, isReady, makeSearch } = useSearch()

  const handleSubmit = event => {
    event.preventDefault()
    makeSearch()
  }

  const handleChange = event => {
    setQuery(event.target.value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.bar}>
        <input
          id="query"
          type="text"
          className={styles.input}
          value={query}
          onChange={handleChange}
          ref={inputRef}
        />
        <div className={styles.icon}>
          <Icon.Search alt="" />
        </div>
        <div className={styles.wrapper} />
        <div className={styles.button}>
          <Button.Secondary disabled={!query || !isReady} onClick={makeSearch}>
            {t('Search')}
          </Button.Secondary>
        </div>
      </div>
    </form>
  )
}

export default SearchBar
