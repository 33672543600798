import { get, omit, set } from 'lodash'

const DATA_ENTRY_UPLOAD_STORAGE = 'pledge_card_uploads'
const DATA_ENTRY_FORM_STORAGE = 'data_entry_form_data'

const TWO_WEEKS_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 14
const STORAGE_TIME_LIMIT = TWO_WEEKS_IN_MILLISECONDS

const defaultRecord = {
  data: [],
  createdAt: Date.now(),
}

export const COVER_SHEET_INDEX = 0

/**
 * Storage shape
 * {
 *  [recordId]: {
 *     data: Array<fileData>
 *     createdAt: timestamp
 *   }
 * }
 */
export class StorageUtils {
  storageKey = DATA_ENTRY_UPLOAD_STORAGE

  constructor(key) {
    this.storageKey = key
    this._removeStaleRecords()
  }

  _reformatLegacyStructure = data => ({
    data,
    createdAt: Date.now(),
  })

  _removeStaleRecords() {
    const storage = this.getStorage()
    const inStorage = Object.entries(storage)

    inStorage.forEach(([id, record]) => {
      if (Array.isArray(record)) {
        this.addToStorage(id, this._reformatLegacyStructure(record))
      }
      const { createdAt } = record
      if (Date.now() - createdAt > STORAGE_TIME_LIMIT) {
        this.removeFromStorage(id)
      }
    })
  }

  setStorageKey = key => {
    this.storageKey = key
  }

  getStorage = () => {
    const storage = window.localStorage.getItem(this.storageKey)
    let parsed = null
    try {
      parsed = JSON.parse(storage)
    } finally {
      parsed = parsed || {}
    }
    return parsed
  }

  setStorage = async data => {
    await null
    window.localStorage.setItem(this.storageKey, JSON.stringify(data))
  }

  getFromStorage = (key, fallback = null) => {
    const storage = this.getStorage()
    return get(storage, key, fallback)
  }

  removeFromStorage = async key => {
    const storage = this.getStorage()
    const newStorage = omit(storage, [key])
    return this.setStorage(newStorage)
  }

  addToStorage = async (key, value) => {
    const storage = this.getStorage()
    const newStorage = set(storage, key, value)
    return this.setStorage(newStorage)
  }

  addRecordToStorage = async (shiftId, page, fileData) => {
    this.addToStorage(`${shiftId}.data.${page}`, fileData)
  }

  getRecordFromStorage = (shiftId, page) =>
    this.getFromStorage(`${shiftId}.data.${page}`, {})

  addPacketInfoToStorage = async (shiftId, formData) => {
    const currentScan = this.getRecordFromStorage(shiftId, COVER_SHEET_INDEX)
    currentScan.formData = formData
    await this.addRecordToStorage(shiftId, COVER_SHEET_INDEX, currentScan)
  }

  getCountRecordsInStorage = shiftId => {
    const COVER_SHEET = 1
    const record = this.getFromStorage(shiftId, defaultRecord)
    return record.data.length - COVER_SHEET
  }

  getFirstUnenteredFromStorage = shiftId => {
    const record = this.getFromStorage(shiftId, defaultRecord)
    const lastPage = record.data.findIndex(
      (page, index) => index !== COVER_SHEET_INDEX && !page?.formData
    )
    return lastPage > COVER_SHEET_INDEX ? lastPage : record.data.length
  }

  isShiftInStorage = shiftId => !!this.getFromStorage(shiftId)
}

export const DataEntryUploadStorage = new StorageUtils(
  DATA_ENTRY_UPLOAD_STORAGE
)
export const DataEntryFormStorage = new StorageUtils(DATA_ENTRY_FORM_STORAGE)
