export const FIELD_KEYS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  MIDDLE_NAME: 'middle_name',
  PREFIX: 'prefix',
  SUFFIX_NAME: 'suffix_name',
  GENDER: 'gender',
  BIRTH_DATE: 'birth_date',
  TEAMS: 'teams',
  EVENTS: 'event_attendances',
  CAMPAIGNS: 'campaigns',
  ISSUES: 'issues',
  NOTES: 'notes',
  ORGANIZATIONS: 'organizations_memberships',
  PRIMARY_PHONE_NUMBER: 'primary_phone_number',
  PRIMARY_EMAIL_ADDRESS: 'primary_email_address',
  EXTERNAL_ID: 'external_id',
  GOTV_PHONE_NUMBER: 'gotv_phone_number',
  ALTERNATE_PHONE_NUMBER: 'alternate_phone_number',
  RESIDENTIAL_ADDRESS: 'residential_address',
  STATE: 'state',
  CITY: 'city',
  LINE_ONE: 'line_one',
  LINE_TWO: 'line_two',
  ZIPCODE: 'zipcode',
  COUNTY: 'county',
}

export const getAddress = residentialAddress => {
  if (!residentialAddress) return ''
  const { line_one, line_two, city, state, zipcode } = residentialAddress
  const stateZip = `${state || ''}${zipcode ? ` ${zipcode}` : ''}`
  return [line_one, line_two, city, stateZip].filter(Boolean).join(', ')
}

export const getExtraFields = (personRecord = {}, errorRecord = {}) => {
  const extraFields = [
    {
      label: 'Prefix',
      value: errorRecord[FIELD_KEYS.PREFIX] || personRecord[FIELD_KEYS.PREFIX],
    },
    {
      label: 'Suffix',
      value:
        errorRecord[FIELD_KEYS.SUFFIX_NAME] ||
        personRecord[FIELD_KEYS.SUFFIX_NAME],
    },
    {
      label: 'County',
      value:
        errorRecord[FIELD_KEYS.RESIDENTIAL_ADDRESS]?.county ||
        personRecord[FIELD_KEYS.RESIDENTIAL_ADDRESS]?.county,
    },
    {
      label: 'Gender',
      value: errorRecord[FIELD_KEYS.GENDER] || personRecord[FIELD_KEYS.GENDER],
    },
    {
      label: 'Alternate phone number',
      value:
        errorRecord[FIELD_KEYS.ALTERNATE_PHONE_NUMBER] ||
        personRecord[FIELD_KEYS.ALTERNATE_PHONE_NUMBER],
    },
    {
      label: 'GoTV phone number',
      value:
        errorRecord[FIELD_KEYS.GOTV_PHONE_NUMBER] ||
        personRecord[FIELD_KEYS.GOTV_PHONE_NUMBER],
    },
    {
      label: 'Teams',
      value:
        errorRecord[FIELD_KEYS.TEAMS]?.join(', ') ||
        personRecord[FIELD_KEYS.TEAMS]?.join(', '),
    },
    {
      label: 'Organizations',
      value:
        errorRecord[FIELD_KEYS.ORGANIZATIONS]?.join(', ') ||
        personRecord[FIELD_KEYS.ORGANIZATIONS]?.join(', '),
    },
    {
      label: 'Events attended',
      value:
        errorRecord[FIELD_KEYS.EVENTS]?.join(', ') ||
        personRecord[FIELD_KEYS.EVENTS]?.join(', '),
    },
    {
      label: 'Campaigns',
      value:
        errorRecord[FIELD_KEYS.CAMPAIGNS]?.join(', ') ||
        personRecord[FIELD_KEYS.CAMPAIGNS]?.join(', '),
    },
    {
      label: 'Issues',
      value:
        errorRecord[FIELD_KEYS.ISSUES]?.join(', ') ||
        personRecord[FIELD_KEYS.ISSUES]?.join(', '),
    },
    {
      label: 'Notes',
      value:
        (!!errorRecord[FIELD_KEYS.NOTES]?.length ||
          !!personRecord[FIELD_KEYS.NOTES]?.length) &&
        `Note 1: ${[
          ...(errorRecord[FIELD_KEYS.NOTES]
            ? errorRecord[FIELD_KEYS.NOTES]
            : []),
          ...(personRecord[FIELD_KEYS.NOTES]
            ? personRecord[FIELD_KEYS.NOTES]
            : []),
        ]
          .map(({ content }) => content)
          ?.join(' | Note 2: ')}`,
    },
  ]

  return extraFields
}
