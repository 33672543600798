/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import { TextField, SearchSelect, DateRangeSelector } from 'components/'
import { useForm } from 'contexts/'
import useEvent from 'hooks/useEvent'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import {
  ABSOLUTE_DATE,
  ANY_DATE,
  DATE_TYPE_OPTIONS,
  DATE_TYPE_WITH_ANY_OPTIONS,
  getRelativeDateRangeFromMeta,
  updateRuleColumns,
  defaultGetStart,
  defaultGetEnd,
  toFormattedDateStrings,
} from './utils'

import styles from './RelativeDateRangeField.module.scss'

const RelativeDateRangeField = ({
  filter,
  filterType,
  columns = [],
  buildColumnValue = toFormattedDateStrings,
  getStart = defaultGetStart,
  getEnd = defaultGetEnd,
  includeAny,
  anyColumns = [],
  buildAnyRule,
}) => {
  const { t } = useTranslation()
  const { setFilterValue } = useListFilters()
  const {
    formData: { hasRefreshRequest },
    setFieldError,
  } = useForm()

  useEffect(() => {
    if (hasRefreshRequest) {
      handleRefresh()
    }
  }, [hasRefreshRequest])

  const handleRefresh = useEvent(() => {
    const rules = [...(filter.value?.rules || [])]

    const meta = { ...(filter.meta || {}) }

    if (![ANY_DATE, ABSOLUTE_DATE].includes(meta.dateType)) {
      const dateRange = getRelativeDateRangeFromMeta(meta)
      const param = buildColumnValue(dateRange)
      updateRuleColumns(rules, param, columns)
    }

    setFilterValue(filter, { ...filter.value, rules }, meta)
  })

  const onChangeType = useEvent(value => {
    let rules = [...(filter.value?.rules || [])]

    const meta = { ...(filter.meta || {}) }
    meta.dateType = value
    meta.relativeValue = 1

    if (meta.dateType === ANY_DATE) {
      const newRule = buildAnyRule()
      rules = rules.filter(({ column }) => !columns.includes(column))
      rules.push(newRule)
    } else {
      rules = rules.filter(({ column }) => !anyColumns.includes(column))
      const dateRange = getRelativeDateRangeFromMeta(meta)
      const param = buildColumnValue(dateRange)
      updateRuleColumns(rules, param, columns)
    }

    const conjunction = rules.length > 1 ? 'inclusive' : 'exclusive'
    setFilterValue(filter, { ...filter.value, rules, conjunction }, meta)
  })

  const onChangeRelativeDate = useEvent(value => {
    const rules = [...(filter.value?.rules || [])]

    const meta = { ...(filter.meta || {}) }
    meta.relativeValue = value
    const dateRange = getRelativeDateRangeFromMeta(meta)
    const param = buildColumnValue(dateRange)

    updateRuleColumns(rules, param, columns)
    setFilterValue(filter, { ...filter.value, rules }, meta)
  })

  const onChangeAbsoluteDate = useEvent((start, end) => {
    const rules = [...(filter.value?.rules || [])]

    const dateRange = [start?.startOf('day'), end?.endOf('day')]
    const param = buildColumnValue(dateRange)

    updateRuleColumns(rules, param, columns)
    setFilterValue(filter, { ...filter.value, rules })

    if (!start) {
      setFieldError(filterType.id, t('Start date must be selected'))
    } else if (!end) {
      setFieldError(filterType.id, t('End date must be selected'))
    } else {
      setFieldError(filterType.id, null)
    }
  })

  const dateType = filter?.meta?.dateType

  return (
    <div className={styles.container}>
      <SearchSelect
        id="date-type-select"
        label={t('Date type')}
        options={includeAny ? DATE_TYPE_WITH_ANY_OPTIONS : DATE_TYPE_OPTIONS}
        value={dateType}
        onChange={onChangeType}
      />
      {!dateType || dateType === ANY_DATE ? null : dateType ===
        ABSOLUTE_DATE ? (
        <DateRangeSelector
          label={t('Select date range')}
          onDatesChange={onChangeAbsoluteDate}
          defaultPresets
          initialStartDate={getStart(filter, columns)}
          initialEndDate={getEnd(filter, columns)}
        />
      ) : (
        // eslint-disable-next-line blocks/no-number-textfield
        <TextField
          id="relative-value"
          label={t(filter?.meta?.dateType)}
          type="number"
          step={1}
          min={1}
          value={filter?.meta?.relativeValue || ''}
          onChange={onChangeRelativeDate}
        />
      )}
    </div>
  )
}

export default RelativeDateRangeField
