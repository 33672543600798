import translatedCellRenderer from 'tableCells/TranslatedCell'
import {
  ListMemberNameCell,
  ListMemberOrganizationCell,
  ListMemberTeamCell,
  ListMemberCountyCell,
  ListMemberZipcodeCell,
} from './ListMemberTableCells'
import {
  listMemberCountyCsvCell,
  listMemberNameCsvCell,
  listMemberOrganizationsCsvCell,
  listMemberTeamCsvCell,
  listMemberZipcodeCsvCell,
} from './listMemberTableCsvCells'

export default {
  label: 'list-members',
  columns: [
    {
      dataKey: 'full_name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      headerCell: translatedCellRenderer,
      bodyCell: ListMemberNameCell,
      csvCell: listMemberNameCsvCell,
    },
    {
      dataKey: 'gender',
      title: 'Gender',
      type: 'string',
      autoResize: true,
      resizable: true,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'county',
      title: 'County',
      type: 'string',
      autoResize: true,
      resizable: true,
      headerCell: translatedCellRenderer,
      bodyCell: ListMemberCountyCell,
      csvCell: listMemberCountyCsvCell,
    },
    {
      dataKey: 'zipcode',
      title: 'Zip code',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      headerCell: translatedCellRenderer,
      bodyCell: ListMemberZipcodeCell,
      csvCell: listMemberZipcodeCsvCell,
    },
    {
      dataKey: 'birth_date',
      title: 'DOB',
      type: 'string',
      autoResize: true,
      resizable: true,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'organizations',
      title: 'Organizations',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      headerCell: translatedCellRenderer,
      bodyCell: ListMemberOrganizationCell,
      csvCell: listMemberOrganizationsCsvCell,
    },
    {
      dataKey: 'teams',
      title: 'Teams',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      headerCell: translatedCellRenderer,
      bodyCell: ListMemberTeamCell,
      csvCell: listMemberTeamCsvCell,
    },
  ],
}
