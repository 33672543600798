import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { defaultTo } from 'lodash'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { useAssignedCheckboxes } from '../CheckboxContextProvider'

const ReprioritizeButton = ({ user }) => {
  const { t } = useTranslation()

  const { setIsReprioritizationModalOpen, setPacketsBeingReprioritized } =
    usePacketAssignment()

  const { getCheckedCheckboxValues } = useAssignedCheckboxes()

  const { assigned_packets } = defaultTo(user, {
    assigned_packets: [],
  })

  const areNoPacketsSelected = getCheckedCheckboxValues().length === 0
  const userHasNoPackets = assigned_packets.length === 0

  return (
    <ButtonBlock justify="right">
      <Button.Secondary
        disabled={areNoPacketsSelected || userHasNoPackets}
        onClick={() => {
          setPacketsBeingReprioritized(getCheckedCheckboxValues())
          setIsReprioritizationModalOpen(true)
        }}
      >
        {t('Reprioritize')}
      </Button.Secondary>
    </ButtonBlock>
  )
}

export default ReprioritizeButton
