import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import RoleAbilitySwitch from '../RoleAbilitySwitch/RoleAbilitySwitch'
import styles from './RolePermissions.module.scss'
import { RoleContext } from '../RoleContext/RoleContext'

const RolePermissions = ({
  permission,
  parentAdmin = false,
  parentPermissions = [],
}) => {
  const { t } = useTranslation()

  const { currentRole: role } = useContext(RoleContext)

  return (
    <div className={styles.block}>
      <p>{t(permission.label)}</p>
      <div className={styles.options}>
        {permission.abilities.map(ability => (
          <RoleAbilitySwitch
            key={ability.id}
            ability={ability}
            disabled={
              role.depth !== 1 &&
              !parentAdmin &&
              !parentPermissions.includes(ability.value)
            }
          />
        ))}
      </div>
    </div>
  )
}

export default RolePermissions
