import {
  TeamNameCell,
  TeamCityCell,
  TeamLeaderNameCell,
  TeamPhoneNumberCell,
  TeamOrganizerNameCell,
  TeamOrganizationCell,
} from './TeamTableCells'

import {
  teamCityCsvCell,
  teamLeaderNameCsvCell,
  teamPhoneNumberCsvCell,
  teamOrganizerNameCsvCell,
  teamOrganizationCsvCell,
} from './teamTableCsvCells'

export default {
  label: 'team',
  columns: [
    {
      dataKey: 'name',
      title: 'Team Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: TeamNameCell,
    },
    {
      dataKey: 'city',
      title: 'City',
      type: 'string',
      sortable: false,
      autoResize: true,
      resizable: true,
      bodyCell: TeamCityCell,
      csvCell: teamCityCsvCell,
      filterConstructor: rule => ({
        ...rule,
        column: 'organizations_city',
      }),
    },
    {
      dataKey: 'leader_full_name',
      title: 'Leader name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      bodyCell: TeamLeaderNameCell,
      csvCell: teamLeaderNameCsvCell,
    },
    {
      dataKey: 'team_leaders_contact_methods_content',
      title: 'Phone',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      filterable: false,
      bodyCell: TeamPhoneNumberCell,
      csvCell: teamPhoneNumberCsvCell,
    },
    {
      dataKey: 'organizer_full_name',
      title: 'Organizer name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: true,
      bodyCell: TeamOrganizerNameCell,
      csvCell: teamOrganizerNameCsvCell,
    },
    {
      dataKey: 'members_count',
      title: 'Members',
      type: 'number',
      autoResize: true,
      resizable: true,
      sortable: true,
    },
    {
      dataKey: 'institutions',
      title: 'Institutions',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: false,
      filterable: false,
      bodyCell: TeamOrganizationCell,
      csvCell: teamOrganizationCsvCell,
    },
  ],
}

export const teamTypeColumn = {
  dataKey: 'type',
  title: 'Team Type',
  type: 'string',
  autoResize: true,
  resizable: true,
  sortable: true,
  filterable: true,
}
