import { duplicatePacketsModalCellRenderer } from 'registrationReports/bodyCells/DuplicatePacketsModalCell/DuplicatePacketsModalCell'
import {
  buildDefaultFilters,
  combineName,
  summaryFunctions,
} from 'utils/reporting'
import { TRUE_FALSE_OPTIONS } from '../../constants'

export default {
  id: 'duplicate_applicants_by_canvasser',
  name: 'Duplicate Applicants by Canvasser',
  description: 'This report provides counts of visual qc reviews by response.',
  columns: [
    {
      dataKey: 'applicant_name',
      title: 'Applicant Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.applicant_first_name, rowData.applicant_last_name),
    },
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'shift_year',
      title: 'Collection Year',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'forms_with_applicant_name',
      title: 'Forms with Same Applicant Name',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'duplicate_forms',
      title: 'Duplicate Forms (DOB or ZIP)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'applicant_matches_canvasser',
      title: 'Applicant Name Matches A Canvasser',
      options: TRUE_FALSE_OPTIONS,
      type: 'enum',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.applicant_matches_canvasser?.toString(),
    },
    {
      dataKey: 'packets',
      title: 'Packets',
      type: 'string',
      resizable: true,
      bodyCell: duplicatePacketsModalCellRenderer,
    },
    {
      dataKey: 'most_recent_upload_date',
      title: 'Most Recent Upload Date',
      type: 'date',
      resizable: true,
    },
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Default Filter',
        visibleColumns: [
          'applicant_name',
          'canvasser_name',
          'forms_with_applicant_name',
          'duplicate_forms',
          'applicant_matches_canvasser',
          'packets',
        ],
      },
    ])
  },
}
