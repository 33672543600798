import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FormattedData, NotesBlock } from 'components'
import {
  Section,
  ProgressBar,
  DetailTable,
  ButtonBlock,
  Button,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { orderBy } from 'lodash'
import { postNote, putNote, destroyNote, fetchPerson } from 'requests/people'
import { useRequest } from 'hooks/useRequest'
import { formatDisplayName } from 'utils/formatting'
import useEvent from 'hooks/useEvent'
import { localizeTime } from 'utils/dateTime'
import PersonContactLog from '../PersonContactLog/PersonContactLog'
import PersonEventForm from '../PersonEventForm/PersonEventForm'
import PersonMeetingForm from '../PersonMeetingForm/PersonMeetingForm'
import PersonShiftForm from '../PersonShiftForm/PersonShiftForm'
import {
  personFields,
  handlePersonFetchRequest,
  limitShownGathering,
} from './utils'

const DEFAULT_EVENT_LIMIT = 4

const PersonHistory = ({ personId }) => {
  const { t } = useTranslation()
  const [person, setPerson] = useState(null)
  const [pastEvents, setPastEvents] = useState([])
  const [pastMeetings, setPastMeetings] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [upcomingMeetings, setUpcomingMeetings] = useState([])
  const [upcomingEventShifts, setUpcomingEventShifts] = useState([])

  const [pastEventsLimit, setPastEventsLimit] = useState(DEFAULT_EVENT_LIMIT)
  const [pastMeetingsLimit, setPastMeetingsLimit] =
    useState(DEFAULT_EVENT_LIMIT)
  const [upcomingEventsLimit, setUpcomingEventsLimit] =
    useState(DEFAULT_EVENT_LIMIT)
  const [upcomingMeetingsLimit, setUpcomingMeetingsLimit] =
    useState(DEFAULT_EVENT_LIMIT)
  const [upcomingEventShiftLimit, setUpcomingEventShiftLimit] =
    useState(DEFAULT_EVENT_LIMIT)

  const [pastEventFormVisible, setPastEventFormVisible] = useState(false)
  const [pastMeetingFormVisible, setPastMeetingFormVisible] = useState(false)
  const [upcomingEventFormVisible, setUpcomingEventFormVisible] =
    useState(false)
  const [upcomingMeetingFormVisible, setUpcomingMeetingFormVisible] =
    useState(false)
  const [upcomingEventShiftFormVisible, setUpcomingEventShiftFormVisible] =
    useState(false)
  const handlePersonFetchSuccess = useEvent(({ person }) =>
    handlePersonFetchRequest({
      person,
      setPerson,
      setUpcomingEventShifts,
      setPastEvents,
      setPastMeetings,
      setUpcomingEvents,
      setUpcomingMeetings,
    })
  )

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: personReq } = useRequest(fetchPerson, {
    onSuccess: handlePersonFetchSuccess,
  })

  useEffect(() => {
    personReq(personId, personFields)
  }, [personId])

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: addNoteReq } = useRequest(postNote, {
    onSuccess: () => personReq(personId, personFields),
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updateNoteReq } = useRequest(putNote, {
    onSuccess: () => personReq(personId, personFields),
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: deleteNoteReq } = useRequest(destroyNote, {
    onSuccess: () => personReq(personId, personFields),
  })

  if (!person) return <ProgressBar show data-testid="loadingBar" />

  return (
    <div data-testid="historySection">
      <NotesBlock
        addNote={addNoteReq}
        updateNote={updateNoteReq}
        deleteNote={deleteNoteReq}
        recordId={person.id}
        notes={orderBy(person.notes, 'created_at', 'desc')}
      />
      <Section label={t('Past meetings')} className="person__history__events">
        {pastMeetingFormVisible && (
          <PersonMeetingForm
            existingMeetings={pastMeetings}
            hideForm={() => {
              setPastMeetingFormVisible(false)
              personReq(personId, personFields)
            }}
            personId={personId}
          />
        )}
        {!pastMeetingFormVisible && pastMeetings.length > 0 && (
          <DetailTable
            columnHeaders={['Meeting name', 'Date', 'Status', 'Notes'].map(t)}
          >
            {limitShownGathering(pastMeetings, pastMeetingsLimit).map(
              meeting => (
                <DetailTable.Row key={meeting.id}>
                  <DetailTable.Cell>
                    <Link to={`/build/meetings/${meeting.id}`}>
                      {t('Meeting with')} {formatDisplayName(meeting.user)}
                    </Link>
                  </DetailTable.Cell>
                  <DetailTable.Cell>
                    <FormattedData
                      type="date"
                      value={localizeTime(meeting.start_time)}
                    />
                  </DetailTable.Cell>
                  {meeting.cancelled === true ? (
                    <DetailTable.Cell>{t('Cancelled')}</DetailTable.Cell>
                  ) : (
                    <DetailTable.Cell>{t('Attended')}</DetailTable.Cell>
                  )}
                  <DetailTable.Cell>{meeting.notes}</DetailTable.Cell>
                </DetailTable.Row>
              )
            )}
          </DetailTable>
        )}
        {!pastMeetingFormVisible && pastMeetings.length === 0 && (
          <TextBlock>{t('No past meetings logged')}</TextBlock>
        )}
        {!pastMeetingFormVisible && pastMeetings.length > pastMeetingsLimit && (
          <ButtonBlock>
            <Button.Secondary
              onClick={() => {
                setPastMeetingsLimit(pastMeetings.length)
              }}
            >
              {t('View All')}
            </Button.Secondary>
          </ButtonBlock>
        )}
        {!pastMeetingFormVisible && pastMeetingsLimit > DEFAULT_EVENT_LIMIT && (
          <ButtonBlock>
            <Button.Secondary
              onClick={() => {
                setPastMeetingsLimit(DEFAULT_EVENT_LIMIT)
              }}
            >
              {t('View Less')}
            </Button.Secondary>
          </ButtonBlock>
        )}
        {!pastMeetingFormVisible && (
          <ButtonBlock>
            <Button onClick={() => setPastMeetingFormVisible(true)}>
              {t('Edit past meetings')}
            </Button>
          </ButtonBlock>
        )}
      </Section>
      <Section
        label={t('Past Group Events')}
        className="person__history__events"
      >
        {pastEventFormVisible && (
          <PersonEventForm
            personId={person.id}
            events={pastEvents}
            pastEvents
            setFormVisible={() => {
              setPastEventFormVisible(false)
              personReq(personId, personFields)
            }}
          />
        )}
        {!pastEventFormVisible && pastEvents.length > 0 && (
          <DetailTable
            columnHeaders={['Event name', 'Date', 'Status', 'Invited by'].map(
              t
            )}
          >
            {limitShownGathering(pastEvents, pastEventsLimit).map(event => (
              <DetailTable.Row key={event.id}>
                <DetailTable.Cell>
                  <Link to={`/build/events/${event.id}`}>{event.name}</Link>
                </DetailTable.Cell>
                <DetailTable.Cell>
                  <FormattedData
                    type="date"
                    value={localizeTime(event.start_time)}
                  />
                </DetailTable.Cell>
                {event.status === 'invited' ? (
                  <DetailTable.Cell>{t('Did not attend')}</DetailTable.Cell>
                ) : (
                  <DetailTable.Cell>
                    {t(event.status || 'Attended')}
                  </DetailTable.Cell>
                )}
                <DetailTable.Cell>{event.inviter?.name || ''}</DetailTable.Cell>
              </DetailTable.Row>
            ))}
          </DetailTable>
        )}
        {!pastEventFormVisible && pastEvents.length === 0 && (
          <TextBlock>{t('No past group events logged')}</TextBlock>
        )}
        {!pastEventFormVisible && pastEvents.length > pastEventsLimit && (
          <ButtonBlock>
            <Button.Secondary
              onClick={() => {
                setPastEventsLimit(pastEvents.length)
              }}
            >
              {t('View All')}
            </Button.Secondary>
          </ButtonBlock>
        )}
        {!pastEventFormVisible && pastEventsLimit > DEFAULT_EVENT_LIMIT && (
          <ButtonBlock>
            <Button.Secondary
              onClick={() => {
                setPastEventsLimit(DEFAULT_EVENT_LIMIT)
              }}
            >
              {t('View Less')}
            </Button.Secondary>
          </ButtonBlock>
        )}
        {!pastEventFormVisible && (
          <ButtonBlock>
            <Button onClick={() => setPastEventFormVisible(true)}>
              {t('Edit past group events')}
            </Button>
          </ButtonBlock>
        )}
      </Section>
      <Section
        label={t('Upcoming meetings')}
        className="person__history__events"
      >
        {upcomingMeetingFormVisible && (
          <PersonMeetingForm
            existingMeetings={upcomingMeetings}
            hideForm={() => {
              setUpcomingMeetingFormVisible(false)
              personReq(personId, personFields)
            }}
            personId={personId}
          />
        )}
        {!upcomingMeetingFormVisible && upcomingMeetings.length > 0 && (
          <DetailTable
            columnHeaders={['Meeting name', 'Date', 'Status', 'Notes'].map(t)}
          >
            {limitShownGathering(upcomingMeetings, upcomingMeetingsLimit).map(
              meeting => (
                <DetailTable.Row key={meeting.id}>
                  <DetailTable.Cell>
                    <Link to={`/build/meetings/${meeting.id}`}>
                      {t('Meeting with')} {formatDisplayName(meeting.user)}
                    </Link>
                  </DetailTable.Cell>
                  <DetailTable.Cell>
                    <FormattedData
                      type="date"
                      value={localizeTime(meeting.start_time)}
                    />
                  </DetailTable.Cell>
                  {meeting.cancelled === true ? (
                    <DetailTable.Cell>{t('Cancelled')}</DetailTable.Cell>
                  ) : (
                    <DetailTable.Cell>{t('Attended')}</DetailTable.Cell>
                  )}
                  <DetailTable.Cell>{meeting.notes}</DetailTable.Cell>
                </DetailTable.Row>
              )
            )}
          </DetailTable>
        )}
        {!upcomingMeetingFormVisible && upcomingMeetings.length === 0 && (
          <TextBlock>{t('No upcoming meetings logged')}</TextBlock>
        )}
        {!upcomingMeetingFormVisible &&
          upcomingMeetings.length > upcomingMeetingsLimit && (
            <ButtonBlock>
              <Button.Secondary
                onClick={() => {
                  setUpcomingMeetingsLimit(upcomingMeetings.length)
                }}
              >
                {t('View All')}
              </Button.Secondary>
            </ButtonBlock>
          )}
        {!upcomingMeetingFormVisible &&
          upcomingMeetingsLimit > DEFAULT_EVENT_LIMIT && (
            <ButtonBlock>
              <Button.Secondary
                onClick={() => {
                  setUpcomingMeetingsLimit(DEFAULT_EVENT_LIMIT)
                }}
              >
                {t('View Less')}
              </Button.Secondary>
            </ButtonBlock>
          )}
        {!upcomingMeetingFormVisible && (
          <ButtonBlock>
            <Button onClick={() => setUpcomingMeetingFormVisible(true)}>
              {t('Edit upcoming meetings')}
            </Button>
          </ButtonBlock>
        )}
      </Section>
      <Section
        label={t('Upcoming Group Events')}
        className="person__history__events"
      >
        {upcomingEventFormVisible && (
          <PersonEventForm
            personId={person.id}
            events={upcomingEvents}
            upcomingEvents
            setFormVisible={() => {
              setUpcomingEventFormVisible(false)
              personReq(personId, personFields)
            }}
          />
        )}
        {!upcomingEventFormVisible && upcomingEvents.length > 0 && (
          <DetailTable
            columnHeaders={['Event name', 'Date', 'Status', 'Invited by'].map(
              t
            )}
          >
            {upcomingEvents.map(event => (
              <DetailTable.Row key={event.id}>
                <DetailTable.Cell>
                  <Link to={`/build/events/${event.id}`}>{event.name}</Link>
                </DetailTable.Cell>
                <DetailTable.Cell>
                  <FormattedData
                    type="date"
                    value={localizeTime(event.start_time)}
                  />
                </DetailTable.Cell>
                {event.status === 'attended' ? (
                  <DetailTable.Cell>
                    {t(event.status || 'Attended')}
                  </DetailTable.Cell>
                ) : (
                  <DetailTable.Cell>
                    {t(event.status || 'Invited')}
                  </DetailTable.Cell>
                )}
                <DetailTable.Cell>{event.inviter?.name || ''}</DetailTable.Cell>
              </DetailTable.Row>
            ))}
          </DetailTable>
        )}
        {!upcomingEventFormVisible && upcomingEvents.length === 0 && (
          <TextBlock>{t('No upcoming group events logged')}</TextBlock>
        )}
        {!upcomingEventFormVisible &&
          upcomingEvents.length > upcomingEventsLimit && (
            <ButtonBlock>
              <Button.Secondary
                onClick={() => {
                  setUpcomingEventsLimit(upcomingEvents.length)
                }}
              >
                {t('View All')}
              </Button.Secondary>
            </ButtonBlock>
          )}
        {!upcomingEventFormVisible &&
          upcomingEventsLimit > DEFAULT_EVENT_LIMIT && (
            <ButtonBlock>
              <Button.Secondary
                onClick={() => {
                  setUpcomingEventsLimit(DEFAULT_EVENT_LIMIT)
                }}
              >
                {t('View Less')}
              </Button.Secondary>
            </ButtonBlock>
          )}
        {!upcomingEventFormVisible && (
          <ButtonBlock>
            <Button onClick={() => setUpcomingEventFormVisible(true)}>
              {t('Edit upcoming group events')}
            </Button>
          </ButtonBlock>
        )}
      </Section>
      <Section
        label={t('Upcoming Event Shifts')}
        className="person__history__events"
      >
        {upcomingEventShiftFormVisible && (
          <PersonShiftForm
            shifts={upcomingEventShifts}
            setFormVisible={() => {
              setUpcomingEventShiftFormVisible(false)
              personReq(personId, personFields)
            }}
          />
        )}
        {!upcomingEventShiftFormVisible && upcomingEventShifts.length > 0 && (
          <DetailTable
            columnHeaders={['Event', 'Role', 'Shift start', 'Shift end'].map(t)}
          >
            {upcomingEventShifts.map(shift => (
              <DetailTable.Row>
                <DetailTable.Cell>
                  <Link to={`/build/events/${shift.event.id}`}>
                    {shift.event.name}
                  </Link>
                </DetailTable.Cell>
                <DetailTable.Cell>{shift.responsibility}</DetailTable.Cell>
                <DetailTable.Cell>
                  <FormattedData
                    type="dateTime"
                    value={localizeTime(shift.start_time)}
                  />
                </DetailTable.Cell>
                <DetailTable.Cell>
                  <FormattedData
                    type="dateTime"
                    value={localizeTime(shift.end_time)}
                  />
                </DetailTable.Cell>
              </DetailTable.Row>
            ))}
          </DetailTable>
        )}
        {!upcomingEventShiftFormVisible && upcomingEventShifts.length === 0 && (
          <TextBlock>{t('No upcoming event shifts logged')}</TextBlock>
        )}
        {!upcomingEventShiftFormVisible &&
          upcomingEventShifts.length > upcomingEventShiftLimit && (
            <ButtonBlock>
              <Button.Secondary
                onClick={() => {
                  setUpcomingEventShiftLimit(upcomingEventShifts.length)
                }}
              >
                {t('View All')}
              </Button.Secondary>
            </ButtonBlock>
          )}
        {!upcomingEventShiftFormVisible &&
          upcomingEventShiftLimit > DEFAULT_EVENT_LIMIT && (
            <ButtonBlock>
              <Button.Secondary
                onClick={() => {
                  setUpcomingEventShiftLimit(DEFAULT_EVENT_LIMIT)
                }}
              >
                {t('View Less')}
              </Button.Secondary>
            </ButtonBlock>
          )}
        {!upcomingEventShiftFormVisible && upcomingEventShifts.length > 0 && (
          <ButtonBlock>
            <Button onClick={() => setUpcomingEventShiftFormVisible(true)}>
              {t('Edit upcoming shifts')}
            </Button>
          </ButtonBlock>
        )}
      </Section>
      <PersonContactLog personId={person.id} />
      <Section label={t('Metadata')}>
        <DetailTable
          columnHeaders={['Created On', 'Updated On', 'Record Source'].map(t)}
        >
          <DetailTable.Row>
            <DetailTable.Cell>
              <FormattedData type="date" value={person.created_at} />
            </DetailTable.Cell>
            <DetailTable.Cell>
              <FormattedData
                type="date"
                value={person.updated_at}
                fallback={t('No updates made')}
              />
            </DetailTable.Cell>
            <DetailTable.Cell>{person.source}</DetailTable.Cell>
          </DetailTable.Row>
        </DetailTable>
      </Section>
    </div>
  )
}

export default PersonHistory
