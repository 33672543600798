import { useContext, useEffect } from 'react'
import {
  Sheet,
  ButtonBlock,
  ProgressBar,
  DetailTable,
} from '@politechdev/blocks-design-system'
import { CsvDownloadButton, CardError, TxtDownloadButton } from 'components'
import { useBackgroundRequest } from 'hooks'
import { formatDecimal } from 'utils/reporting'
import { useCurrent } from 'contexts/index'
import { buildReportFilename } from './utils'
import { WeeklyReportContext } from '../WeeklyReportContext'

const CHART_TYPE = 'upload_timing'

const UploadTiming = () => {
  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
    doesCurrentUserHavePermission,
  } = useCurrent()

  const { chartMap, filters, requestChartId, requestChart } =
    useContext(WeeklyReportContext)

  const { makeRequest, isLoading, errorMsg, isSuccessful } =
    useBackgroundRequest({
      key: CHART_TYPE,
      requestId: type =>
        requestChartId(type, turfId, currentTurfPerformsExternalQC),
      requestData: requestChart,
    })

  useEffect(() => {
    if (filters.turf_id) {
      makeRequest()
    }
  }, [filters])

  const rowNames = {
    packets_total: 'Packets uploaded:',
    reuploads: 'Uploads that were re-ups:',
    late_packets: 'Late packets (excluding re-ups):',
    avg_upload_time: 'Avg up time in days (excluding re-ups):',
    avg_turnaround_time: 'Avg turnaround time in days:',
    avg_time_to_resolve: 'Avg time to resolve flags in days:',
  }

  const tableColumns = [
    {
      label: '',
      key: 'key',
    },
    {
      label: '',
      key: 'value',
    },
  ]

  const chartData = chartMap[CHART_TYPE]?.data

  const rowData = chartData ? chartData.rows : undefined
  const sqlQuery = chartData ? chartData.sql_query : ''

  const tableRows = rowData
    ? Object.keys(rowNames)
        .map(key => ({
          key: rowNames[key],
          value: formatDecimal(rowData[0][key]),
        }))
        .filter(Boolean)
    : []

  const averageTableRows =
    rowData && rowData[0].avg_upload_time_by_turf
      ? rowData[0].avg_upload_time_by_turf
          .filter(row => row.average)
          .map(row => ({
            key: row.name,
            value: formatDecimal(row.average),
          }))
      : []

  return (
    <Sheet>
      <div className="panel__header">Upload timing</div>
      <ButtonBlock justify="right">
        {doesCurrentUserHavePermission({
          resource: 'weekly',
          ability: 'download',
        }) && (
          <CsvDownloadButton
            columnHeaders={tableColumns}
            data={tableRows}
            filename={buildReportFilename(CHART_TYPE, filters)}
            disabled={isLoading}
          />
        )}
        {doesCurrentUserHavePermission({
          resource: 'weekly',
          ability: 'sql',
        }) && (
          <TxtDownloadButton
            txtString={sqlQuery}
            filename={buildReportFilename(CHART_TYPE, filters, 'txt')}
          />
        )}
      </ButtonBlock>
      <ProgressBar show={isLoading} />
      <CardError hide={!errorMsg} message={errorMsg} hideSupportLink />
      {isSuccessful && (
        <>
          <DetailTable columnHeaders={[]}>
            {tableRows.map((row, index) => (
              <DetailTable.Row key={index}>
                {tableColumns.map((column, index) => (
                  <DetailTable.Cell key={column.key}>
                    <span className={index === 0 ? 'bold muted' : ''}>
                      {row[column.key]}
                    </span>
                  </DetailTable.Cell>
                ))}
              </DetailTable.Row>
            ))}
          </DetailTable>
          <div className="panel__header">
            Avg upload time in Days by Office (excluding re-ups)
          </div>
          <ButtonBlock justify="right">
            {doesCurrentUserHavePermission({
              resource: 'weekly',
              ability: 'download',
            }) && (
              <CsvDownloadButton
                columnHeaders={tableColumns}
                data={averageTableRows}
                filename={buildReportFilename('average_upload_timing', filters)}
                disabled={isLoading}
              />
            )}
          </ButtonBlock>
          <DetailTable columnHeaders={[]}>
            {averageTableRows.map((row, index) => (
              <DetailTable.Row key={index}>
                {tableColumns.map((column, index) => (
                  <DetailTable.Cell key={column.key}>
                    <span className={index === 0 ? 'bold muted' : ''}>
                      {row[column.key]}
                    </span>
                  </DetailTable.Cell>
                ))}
              </DetailTable.Row>
            ))}
          </DetailTable>
        </>
      )}
    </Sheet>
  )
}

export default UploadTiming
