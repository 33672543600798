import { calculatePercent } from 'utils/reporting'
import {
  sum,
  customPercent,
} from 'registrationReports/reportsConfig/columnTotals'

export default [
  {
    dataKey: 'turf_name',
    title: 'Turf Name',
    type: 'string',
    frozen: 'left',
    resizable: true,
  },
  {
    dataKey: 'total_scans_qc',
    title: 'Total Forms Through QC',
    type: 'number',
    resizable: true,
    summaryFunction: sum,
  },
  {
    dataKey: 'weekly_complete_forms_percent',
    title: 'Complete %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.complete_forms_count, rowData.total_scans_qc),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, ['complete_forms_count', 'total_scans_qc']),
  },
  {
    dataKey: 'weekly_forms_with_phone_percent',
    title: 'Phone %',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.forms_with_phone_count, rowData.total_scans_qc),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, ['forms_with_phone_count', 'total_scans_qc']),
  },
  {
    dataKey: 'canvasser_flags_rate',
    title: 'Canvasser Flags per 100 Forms',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.canvasser_flags_count, rowData.total_scans_qc),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, ['canvasser_flags_count', 'total_scans_qc']),
  },
  {
    dataKey: 'technical_flags_rate',
    title: 'Technical Flags per 100 Forms',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.technical_flags_count, rowData.total_scans_qc),
    summaryFunction: ({ data, acc }) =>
      customPercent(data, acc, ['technical_flags_count', 'total_scans_qc']),
  },
  {
    dataKey: 'canvasser_flags_count',
    title: 'Canvasser Flags',
    type: 'number',
    resizable: true,
    summaryFunction: sum,
  },
  {
    dataKey: 'technical_flags_count',
    title: 'Technical Flags',
    type: 'number',
    resizable: true,
    summaryFunction: sum,
  },
]
