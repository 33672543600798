import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Font } from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import styles from './MaintenanceBanner.module.scss'

const MaintenanceBanner = () => {
  const { tenantOptions } = useCurrent()
  const message = tenantOptions.maintenance_message
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(true)

  if (!isVisible || !message) return null

  return (
    <div className={styles.container}>
      <Font.Copy className={styles.message}>{t(message)}</Font.Copy>
      <Button.Secondary
        className={styles['clear-button']}
        onClick={() => setIsVisible(false)}
      >
        <Icon.Times />
      </Button.Secondary>
    </div>
  )
}

export default MaintenanceBanner
