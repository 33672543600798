import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import PropTypes from 'prop-types'

const PieChart = ({
  data,
  showIndexInLegend,
  donut,
  enableLabels,
  margin,
  hasToolbar,
}) => {
  if (!data) return null
  return (
    <ResponsivePie
      data={data}
      sortByValue
      enableRadialLabels={enableLabels}
      radialLabelsLinkOffset={-20}
      radialLabelsLinkDiagonalLength={18}
      radialLabelsTextXOffset={14}
      radialLabelsLinkStrokeWidth={2}
      enableSlicesLabels={false}
      radialLabel="value"
      margin={
        hasToolbar ? { top: 20, right: 140, bottom: 80, left: 30 } : margin
      }
      colors={{ scheme: 'set1' }}
      innerRadius={donut ? 0.5 : 0}
      legends={[
        {
          dataFrom: showIndexInLegend ? 'indexes' : 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: -50,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          itemsSpacing: 8,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate
      motionStiffness={90}
      motionDamping={15}
    />
  )
}

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  showIndexInLegend: PropTypes.bool,
  donut: PropTypes.bool,
  enableLabels: PropTypes.bool,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  hasToolbar: PropTypes.bool,
}

PieChart.defaultProps = {
  showIndexInLegend: false,
  donut: false,
  enableLabels: false,
  hasToolbar: false,
  margin: { top: 30, right: 140, bottom: 20, left: 30 },
}

export default PieChart
