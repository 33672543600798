import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchPetitionReport = (reportType, params) => {
  const queryParams = {
    ...params,
    type: reportType,
  }

  return fetchJSON(
    `/api/v1/reports/petitions?${stringifyParams(queryParams)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
}
