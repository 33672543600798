import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './ContentBlock.module.scss'

const cx = classNames.bind(styles)

const ContentBlock = ({ children, className }) => (
  <div className={cx('content-block', className)}>{children}</div>
)

ContentBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

ContentBlock.defaultProps = {
  className: '',
}

export default ContentBlock
