import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.svg'
import { Sheet, ContentBlock } from 'components'
import styles from './AuthFrame.module.scss'

const AuthFrame = ({ children }) => (
  <div className={styles.container}>
    <ContentBlock>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>
    </ContentBlock>
    <Sheet className={styles.panel}>{children}</Sheet>
  </div>
)

AuthFrame.Title = ({ children }) => (
  <ContentBlock>
    <span className={styles.title}>{children}</span>
  </ContentBlock>
)

AuthFrame.Content = ({ children }) => (
  <ContentBlock>
    <span className={styles.message}>{children}</span>
  </ContentBlock>
)

AuthFrame.Success = ({ children }) => (
  <ContentBlock>
    <span className={styles.success}>{children}</span>
  </ContentBlock>
)

AuthFrame.Error = ({ children }) => (
  <ContentBlock>
    <span className={styles.error}>{children}</span>
  </ContentBlock>
)

AuthFrame.Actions = ({ children }) => (
  <div className={styles.actions}>{children}</div>
)

export default AuthFrame
