import { useState, useEffect } from 'react'
import { useRequest } from 'hooks/useRequest'
import { fetchForm } from 'requests/registrationForms'

const useProofOfResidence = formId => {
  const [porUrl, setPorUrl] = useState()
  const [porRemovedAt, setPorRemovedAt] = useState(false)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: formRequest } = useRequest(fetchForm, {
    onSuccess: response => {
      const form = response.registration_form
      setPorRemovedAt(form.por_data?.metadata?.redacted_at)
      setPorUrl(form.por_url)
    },
  })

  useEffect(() => {
    formRequest(formId, { fields: ['por_data', 'por_url'] })
  }, [formId])

  return {
    proofOfResidenceScanUrl: porUrl,
    porRemovedAt,
  }
}

export default useProofOfResidence
