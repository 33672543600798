import {
  calculatePercent,
  formatDecimalCell,
  summaryFunctions,
} from 'utils/reporting'

export const partyLeanDataDictionaryUrl =
  'https://docs.google.com/spreadsheets/d/1xABMR2DqVMaDx-u18b9gH-yh0-wh7P6Av2BVBU2zodE/edit#gid=393845982'

export const basePartyLeanColumns = [
  {
    dataKey: 'party_lean',
    title: 'Party Lean',
    type: 'string',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      rowData.party_lean ? rowData.party_lean : 'NO DATA',
  },
  {
    dataKey: 'scans_qc',
    title: 'Forms with Pledge Cards (QC)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'percent_of_total_scans_qc_with_value',
    title: 'Percent of Total Forms with Pledge Cards (QC)',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.scans_qc, rowData.total_scans_qc_with_value),
    summaryFunction: ({ data }) =>
      summaryFunctions.percentOfGroupedTotal(
        data,
        'scans_qc',
        'total_scans_qc_with_value',
        'table_id'
      ),
  },
  {
    dataKey: 'percent_of_total_scans_qc',
    title: 'Percent of Total Forms (QC)',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.scans_qc, rowData.total_scans_qc),
    summaryFunction: ({ data }) =>
      summaryFunctions.percentOfGroupedTotal(
        data,
        'scans_qc',
        'total_scans_qc',
        'table_id'
      ),
  },
]
