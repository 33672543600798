import { useEffect, useState } from 'react'
import {
  CardError,
  LoadBar,
  SearchSelect,
  MultiSearchSelect,
  DateRangeSelector,
  PhoneBankSelectFieldDEPRECATED,
} from 'components'
import { Radio, Checkbox } from '@politechdev/blocks-design-system'
import { nonParticipationOptions } from 'constants/phoneBankSessions'
import { useTranslation } from 'react-i18next'
import { fetchPhoneBank } from 'requests/phoneBanks'
import { useRequest } from 'hooks'
import { useForm } from 'contexts'
import styles from './PhoneBankResponseField.module.scss'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import SingleChoice from './SingleChoice/SingleChoice'
import MultipleChoice from './MultipleChoice/MultipleChoice'

const PRE_SELECTION_INDEX = 0
const SINGLE_CHOICE_INDEX = 0
const KEY_INDEX = 0
const VALUE_INDEX = 1
const COLUMN_NAME = 'with_phone_bank_activity'

const PhoneBankResponseField = ({ filter }) => {
  const { t } = useTranslation()
  const { setFilterValue } = useListFilters()
  const { setFieldError } = useForm()
  const [error, setError] = useState(false)
  const [currentScriptObject, setCurrentScriptObject] = useState(null)
  const [scriptObjectOptions, setScriptObjectOptions] = useState([])

  const phoneBankFilterRule = filter.value?.rules
    ? filter.value.rules.find(rule => rule.column === COLUMN_NAME)
    : {}

  const hasParams = !!phoneBankFilterRule?.param

  const ruleParams = hasParams ? phoneBankFilterRule?.param : {}

  const updateRule = params => {
    const newParams = {
      ...(hasParams ? phoneBankFilterRule.param : {}),
      ...params,
    }

    const sanitizedParams = Object.entries(newParams).reduce(
      (currentObj, currentKeyValuePair) => {
        if (typeof currentKeyValuePair[VALUE_INDEX] !== 'undefined') {
          currentObj[currentKeyValuePair[KEY_INDEX]] =
            currentKeyValuePair[VALUE_INDEX]
        }
        return currentObj
      },
      {}
    )

    const newRule = {
      column: COLUMN_NAME,
      param: sanitizedParams,
    }

    setFilterValue(filter, {
      rules: [newRule],
    })
  }

  const selectPhoneBank = phoneBank => {
    setError(false)

    if (!phoneBank || phoneBank.id === 'any') {
      updateRule({
        phone_bank_id: undefined,
        question_id: undefined,
        answer_ids: undefined,
        participated: undefined,
        non_participation_reasons: undefined,
      })
      setScriptObjectOptions([])
      setCurrentScriptObject(null)
      return
    }

    const newScriptObjectOptions = phoneBank?.script?.script_objects
      ? phoneBank.script?.script_objects.filter(
          script =>
            script.question &&
            (script.question.type === 'single_choice' ||
              script.question.type === 'multiple_choice')
        )
      : []

    updateRule({
      phone_bank_id: phoneBank.id,
      participated: hasParams
        ? phoneBankFilterRule.param.participated
        : undefined,
    })
    setScriptObjectOptions(newScriptObjectOptions)
  }

  const { makeRequest, isLoading } = useRequest(fetchPhoneBank, {
    onSuccess: response => {
      const data = response.phone_bank
      if (data.script?.script_objects) {
        data.script.script_objects = data.script?.script_objects.sort(
          (a, b) => a.position_in_script - b.position_in_script
        )
      }

      selectPhoneBank(data)
    },
    onError: () => {
      setError(true)
    },
  })

  const selectedScriptObjectId =
    hasParams && phoneBankFilterRule.param.question_id

  const updateParticipation = value => {
    if (value === false) {
      updateRule({
        participated: false,
        question_id: undefined,
        answer_ids: undefined,
      })
      setCurrentScriptObject(null)
      return
    }
    updateRule({
      participated: true,
      non_participation_reasons: undefined,
    })
  }

  const updateNonParticipationReason = reasons => {
    updateRule({
      non_participation_reasons:
        reasons.length > 0 ? reasons.map(({ value }) => value) : undefined,
    })
  }

  const getNonParticipationReasons = () => {
    if (hasParams) {
      const selectedReasons = nonParticipationOptions.filter(option =>
        phoneBankFilterRule.param.non_participation_reasons?.includes(
          option.value
        )
      )

      if (selectedReasons.length === 0) return undefined
      return selectedReasons
    }
  }

  const updateQuestion = value => {
    const selectedScriptObject = scriptObjectOptions.find(
      scriptObject => scriptObject.id === value
    )
    const preselectedAnswer =
      selectedScriptObject?.question.answer_options?.[PRE_SELECTION_INDEX]

    updateRule({
      question_id: value,
      answer_ids: preselectedAnswer ? [preselectedAnswer.id] : [],
    })
    setCurrentScriptObject(selectedScriptObject)
  }

  const updateSingleAnswer = choiceId => {
    updateRule({
      answer_ids: [choiceId],
    })
  }

  const updateMultipleChoiceAnswers = choiceId => {
    const selectedAnswerIds = ruleParams.answer_ids
    const newAnswerIds = selectedAnswerIds.includes(choiceId)
      ? selectedAnswerIds.filter(id => id !== choiceId)
      : [...selectedAnswerIds, choiceId]

    updateRule({
      answer_ids: newAnswerIds,
    })
  }

  const updateDateRange = (start, end) => {
    if (!start || !end) {
      setFieldError(filter.id, t('Both start and end dates must be selected'))
      return
    }

    setFieldError(filter.id, null)

    updateRule({
      start_date: start.format('YYYY-MM-DD'),
      end_date: end.format('YYYY-MM-DD'),
      is_any_date: false,
    })
  }

  const updateIsAnyDate = () => {
    updateRule({
      start_date: undefined,
      end_date: undefined,
      is_any_date: !ruleParams.is_any_date,
    })
  }

  useEffect(() => {
    if (!ruleParams.phone_bank_id || ruleParams.phone_bank_id === 'any') {
      selectPhoneBank({ id: 'any' })
      return
    }
    if (ruleParams.phone_bank_id) {
      makeRequest(ruleParams.phone_bank_id)
    }
  }, [ruleParams.phone_bank_id])

  useEffect(() => {
    if (scriptObjectOptions?.length && ruleParams.participated) {
      const preselectedScriptObject =
        scriptObjectOptions && scriptObjectOptions[PRE_SELECTION_INDEX]
      const newCurrentScriptObject = selectedScriptObjectId
        ? scriptObjectOptions?.find(
            scriptObject => scriptObject.id === selectedScriptObjectId
          )
        : preselectedScriptObject

      const preselectedAnswerIds = hasParams
        ? phoneBankFilterRule.param.answer_ids
        : [
            preselectedScriptObject?.question.answer_options?.[
              PRE_SELECTION_INDEX
            ].id,
          ]

      setCurrentScriptObject(newCurrentScriptObject)

      updateRule({
        question_id: preselectedScriptObject?.id,
        answer_ids: hasParams
          ? phoneBankFilterRule.param.answer_ids
          : preselectedAnswerIds,
        non_participation_reasons: hasParams
          ? phoneBankFilterRule.param.non_participation_reasons
          : undefined,
      })
    } else {
      setCurrentScriptObject(null)
      updateRule({
        question_id: hasParams
          ? phoneBankFilterRule.param.question_id
          : undefined,
        answer_ids: hasParams
          ? phoneBankFilterRule.param.answer_ids
          : undefined,
        non_participation_reasons: hasParams
          ? phoneBankFilterRule.param.non_participation_reasons
          : undefined,
      })
    }
  }, [scriptObjectOptions, ruleParams.participated])

  return (
    <div>
      <LoadBar show={isLoading} />
      <CardError
        hide={!error}
        message={t(
          'An error ocurred while trying to fetch the selected phone bank'
        )}
      />
      <PhoneBankSelectFieldDEPRECATED
        className={styles.field}
        phoneBank={{ id: ruleParams.phone_bank_id || 'any' }}
        provideDefault
        allowAny
        onChange={phoneBank => {
          updateRule({
            phone_bank_id: phoneBank.id,
          })
        }}
      />
      <div>
        <div className={styles.field}>
          <Radio
            label="Participated"
            value="Unchecked"
            checked={ruleParams.participated}
            onChange={() => {
              updateParticipation(true)
            }}
          />
          <Radio
            label="Did not participate"
            value="Unchecked"
            checked={ruleParams.participated === false}
            onChange={() => {
              updateParticipation(false)
            }}
          />
        </div>
        {ruleParams.participated === false ? (
          <MultiSearchSelect
            className={styles.field}
            label={t('Nonparticipation reason')}
            value={getNonParticipationReasons()}
            options={nonParticipationOptions}
            onChange={updateNonParticipationReason}
          />
        ) : null}
      </div>
      {!!scriptObjectOptions?.length && ruleParams.participated && (
        <SearchSelect
          label={t('Question')}
          className={styles.field}
          value={selectedScriptObjectId}
          options={scriptObjectOptions.map(scriptObject => ({
            label: scriptObject.question.question_to_ask,
            value: scriptObject.id,
          }))}
          onChange={updateQuestion}
        />
      )}
      {currentScriptObject && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {currentScriptObject.question.type === 'single_choice' ? (
            <SingleChoice
              options={currentScriptObject.question.answer_options}
              value={ruleParams.answer_ids?.[SINGLE_CHOICE_INDEX]}
              onSelect={updateSingleAnswer}
            />
          ) : (
            <MultipleChoice
              options={currentScriptObject.question.answer_options}
              selectedIds={ruleParams.answer_ids}
              onSelect={updateMultipleChoiceAnswers}
            />
          )}
        </>
      )}
      <Checkbox
        className={styles.field}
        id="any-date-checkbox"
        name="any-date-checkbox"
        checked={ruleParams.is_any_date}
        onChange={updateIsAnyDate}
        label={t('Any date')}
      />
      {!ruleParams.is_any_date && (
        <DateRangeSelector
          className={styles.field}
          label={t('Date range contacted')}
          disabled={ruleParams.is_any_date}
          onDatesChange={updateDateRange}
          defaultPresets
          initialStartDate={ruleParams.start_date}
          initialEndDate={ruleParams.end_date}
        />
      )}
    </div>
  )
}

export default PhoneBankResponseField
