import { useTranslation } from 'react-i18next'
import {
  TextField,
  TextBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import InterpolationSelect from '../../InterpolationSelect/InterpolationSelect'
import InterpolatedContent from '../../InterpolatedContent/InterpolatedContent'

const ContentRead = ({ type, content }) => {
  switch (type) {
    case 'dialogue':
      return (
        <TextBlock>
          <InterpolatedContent text={content} />
        </TextBlock>
      )

    case 'instruction':
      return (
        <TextBlock>
          <em>
            <InterpolatedContent text={content} />
          </em>
        </TextBlock>
      )

    default: {
      return null
    }
  }
}

const ContentEdit = ({ type, contentInState, setContentInState }) => {
  const { t } = useTranslation()

  switch (type) {
    case 'dialogue':
      return (
        <ContentBlock>
          <TextField
            id="dialogue-form"
            label={t('Dialogue')}
            value={contentInState}
            onChange={setContentInState}
          />
          <InterpolationSelect
            selectionStartId="dialogue-form"
            updateText={setContentInState}
            currentText={contentInState}
          />
        </ContentBlock>
      )

    case 'instruction':
      return (
        <ContentBlock>
          <TextField
            id="instruction-form"
            label={t('Instructions')}
            value={contentInState}
            rows={1}
            onChange={setContentInState}
            ignoreWhitespace
          />
          <InterpolationSelect
            selectionStartId="instruction-form"
            updateText={setContentInState}
            currentText={contentInState}
          />
        </ContentBlock>
      )

    default: {
      return null
    }
  }
}

const ContentText = {
  Read: ContentRead,
  Edit: ContentEdit,
}
export default ContentText
