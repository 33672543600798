import { useEffect } from 'react'
import { ProgressBar, Section, Sheet } from '@politechdev/blocks-design-system'
import { CardError } from 'components'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import StaffListItem from './StaffListItem'
import { StaffItemButtons } from './StaffItemButtons'
import StaffListEmpty from './StaffListEmpty'
import { usePacketAssignment } from '../PacketAssignmentContext'

const StaffList = () => {
  const { t } = useTranslation()
  const { refreshCurrentRoles } = useCurrent()

  const {
    scheduledUsersCardData: scheduledUsers,
    getScheduledUsersIsLoading,
    getScheduledUsersHasErrors,
  } = usePacketAssignment()

  useEffect(() => {
    refreshCurrentRoles()
  }, [])

  const renderSectionBody = () => {
    if (getScheduledUsersIsLoading) {
      return <ProgressBar show />
    }

    if (getScheduledUsersHasErrors) {
      return (
        <CardError
          hide={false}
          message={t(
            'An error occurred while trying to get your scheduled users.'
          )}
        />
      )
    }

    return scheduledUsers.length ? (
      <Sheet>
        {scheduledUsers.map(scheduledUser => (
          <StaffListItem
            key={scheduledUser.staffId}
            user={scheduledUser}
            Buttons={
              <StaffItemButtons
                disabled={!scheduledUser.isViewable}
                staffId={scheduledUser.staffId}
              />
            }
          />
        ))}
      </Sheet>
    ) : (
      <StaffListEmpty />
    )
  }

  return <Section label={t('QC Staff')}>{renderSectionBody()}</Section>
}

export default StaffList
