import { Link } from 'react-router-dom'
import {
  ContentBlock,
  FieldBlock,
  Button,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { isTrainable, isVisualQcTrainable, isPhoneVerTrainable } from './utils'
import styles from './UserRoleDetails.module.scss'

const UserRoleDetails = ({ role }) => {
  const { t } = useTranslation()

  if (!role) return null

  return (
    <ContentBlock>
      <FieldBlock>{t('Role details:')}</FieldBlock>
      <div className={styles.container}>
        <div className={styles.details}>
          <FieldBlock>
            {t('QC Training:')}{' '}
            {isTrainable(role) ? t('Available') : t('Not available')}
          </FieldBlock>
          <FieldBlock>
            {t('Visual QC:')}{' '}
            {isVisualQcTrainable(role) ? t('Available') : t('Not available')}
          </FieldBlock>
          <FieldBlock>
            {t('Phone Verification:')}{' '}
            {isPhoneVerTrainable(role) ? t('Available') : t('Not available')}
          </FieldBlock>
          <ButtonBlock justify="right">
            <Link to="/admin/roles">
              <Button>{t('Edit role')}</Button>
            </Link>
          </ButtonBlock>
        </div>
      </div>
    </ContentBlock>
  )
}

export default UserRoleDetails
