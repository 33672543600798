import { useRequest } from 'hooks'
import { fetchEventAttendees } from 'requests/events'
import { useEffect, useContext } from 'react'
import { formatAutoTableParams } from 'utils/formatting'
import { EventContext } from 'events/EventContext/EventContext'
import { type Attendee } from 'types/events'

const attendeeFields = [
  'id',
  'status',
  'role',
  'needs',
  { inviter: ['id', 'name'] },
  {
    person: [
      'id',
      'name',
      'first_name',
      'last_name',
      'email',
      'primary_phone_number',
      {
        residential_address: ['line_one', 'city', 'state', 'zipcode', 'county'],
      },
    ],
  },
]

function coalesceAddress(attendee: Attendee) {
  return attendee.person.residential_address
    ? attendee
    : {
        ...attendee,
        person: {
          ...attendee.person,
          residential_address: {
            line_one: undefined,
            city: undefined,
            state: undefined,
            zipcode: undefined,
            county: undefined,
          },
        },
      }
}

export function useAttendees(
  currentEventId: number,
  debouncedSearchValue: string
) {
  const { attendees, setAttendees } = useContext(EventContext) as {
    attendees: Attendee[]
    setAttendees: (attendees: Attendee[]) => void
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest } = useRequest<{
    event_attendances: Attendee[]
  }>(fetchEventAttendees, {
    onSuccess: ({ event_attendances }) => {
      const fetchedAttendees = event_attendances.map(coalesceAddress)
      setAttendees(fetchedAttendees)
    },
  })

  const fetchAttendees = (params?: { filterRules: unknown[] }) =>
    makeRequest(currentEventId, {
      ...formatAutoTableParams(params),
      fields: attendeeFields,
      filters:
        debouncedSearchValue || params?.filterRules
          ? {
              rules: [
                ...(debouncedSearchValue
                  ? [
                      {
                        column: 'person_full_name',
                        operator: 'containing',
                        param: debouncedSearchValue,
                      },
                    ]
                  : []),
                ...(params?.filterRules ?? []),
              ],
            }
          : undefined,
      per: Number.MAX_SAFE_INTEGER,
    })

  useEffect(() => {
    void fetchAttendees()
  }, [currentEventId, debouncedSearchValue])

  return { attendees, fetchAttendees }
}
