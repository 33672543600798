import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './FieldBlock.module.scss'

const cx = classNames.bind(styles)

const FieldBlock = ({ className, children, ...props }) => (
  <div className={cx('container', className)} {...props}>
    {children}
  </div>
)

FieldBlock.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FieldBlock
