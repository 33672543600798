import { createContext, useState } from 'react'

export const EventContext = createContext()

const EventContextProvider = ({ children }) => {
  const [currentEvent, setCurrentEvent] = useState({})
  const [attendees, setAttendees] = useState([])
  return (
    <EventContext.Provider
      value={{ currentEvent, setCurrentEvent, attendees, setAttendees }}
    >
      {children}
    </EventContext.Provider>
  )
}

export default EventContextProvider
