import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { useQueryParams } from 'contexts'
import { dateFormat } from 'utils/constants'
import moment from 'moment'

function TxtDownloadButton({
  txtString,
  filename,
  tooltipLabel,
  tooltipPosition,
  disabled = false,
}) {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const fullFilename = `${moment(queryParams.startDate).format(
    dateFormat
  )}-${moment(queryParams.endDate).format(dateFormat)}-${filename}`

  const downloadTxtFile = (txtString, filename) => {
    const element = document.createElement('a')
    const file = new Blob([txtString], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = filename
    document.body.appendChild(element)
    element.click()
  }

  return (
    <Button.Secondary
      key="download"
      disabled={disabled}
      tooltipLabel={t(tooltipLabel)}
      tooltipPosition={tooltipPosition}
      onClick={() => downloadTxtFile(txtString, fullFilename)}
    >
      <Icon.FileAlt />
    </Button.Secondary>
  )
}

TxtDownloadButton.defaultProps = {
  filename: 'sql_query.txt',
  tooltipLabel: 'Download SQL Query',
  tooltipPosition: 'left',
  newTab: false,
  wrapperClassName: '',
}

export default memo(TxtDownloadButton)
