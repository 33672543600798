import Plugin from '@uppy/core/lib/BasePlugin'
import { PDFDocument } from 'pdf-lib'

export default class PDFSplitter extends Plugin {
  constructor(uppy, opts = {}) {
    super(uppy, opts)
    this.id = opts.id || 'pdfsplitter'
    this.type = 'preprocessor'
  }

  processPDFs = async fileIDs => {
    const newIds = await Promise.all(fileIDs.map(this.splitPDF))
    return newIds.filter(Boolean)
  }

  splitPDF = async fileId => {
    const { files } = this.uppy.getState()
    const file = files[fileId]

    if (file.source === this.id) return fileId

    this.uppy.removeFile(fileId)

    const pdfBytesBuffer = await file.data.arrayBuffer()
    const pdfDoc = await PDFDocument.load(new Uint8Array(pdfBytesBuffer))

    const separatedPages = pdfDoc.getPages().map(async (_, index, pages) => {
      const subDocument = await PDFDocument.create()
      const [page] = await subDocument.copyPages(pdfDoc, [index])
      subDocument.addPage(page)

      const pageBytes = await subDocument.save()
      const pageNumber = index.toString().padStart(3, '0')
      const totalPages = pages.length.toString().padStart(3, '0')
      return new File(
        [pageBytes.buffer],
        `${file.name.split('.pdf')[0]}_page_${pageNumber}_of_${totalPages}.pdf`,
        {
          type: file.type,
        }
      )
    })

    const uploadReadyPages = await Promise.all(separatedPages)
    uploadReadyPages.forEach(file => {
      this.uppy.addFile({
        source: this.id,
        name: file.name,
        type: file.type,
        data: file,
      })
    })
  }

  install() {
    this.uppy.addPreProcessor(this.processPDFs)
  }

  uninstall() {
    this.uppy.removePreProcessor(this.processPDFs)
  }
}
