import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Sheet, View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { AutoTableProvider, useAutoTable, useCurrent } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { fetchPeople } from 'requests/people'
import tableConfig from './tableConfig'

const PeopleTable = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()
  const { renderTable } = useAutoTable()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewPeople = doesCurrentUserHavePermission({
    resource: 'person',
    ability: 'view',
  })
  const canModifyPeople = doesCurrentUserHavePermission({
    resource: 'person',
    ability: 'modify',
  })

  const [rows, setRows] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const peopleRequestMapper = params => {
    const mappedParams = {
      current_page: params?.page,
      per: params?.pageSize,
      sort_attr: params?.sortColumn || 'full_name',
      sort_dir: params?.sortOrder || 'asc',
      fields: params?.fields,
      filters: {
        rules: params?.filterRules,
        conjunction: params?.filterConjunction,
      },
    }

    return fetchPeople({
      ...mappedParams,
      fields: [
        'id',
        'name',
        'first_name',
        'last_name',
        'primary_phone_number',
        'primary_email_address',
        { organizations: ['name'] },
        { contact_methods: ['id', 'content', 'contact_type', 'invalid'] },
        {
          residential_address: [
            'id',
            'city',
            'county',
            'line_one',
            'line_two',
            'state',
            'zipcode',
          ],
        },
      ],
    }).then(({ meta, people }) => ({
      tableRows: people,
      totalRecords: meta.total_count,
    }))
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: peopleRequest } = useRequest(peopleRequestMapper, {
    onSuccess: ({ tableRows, totalRecords }) => {
      setTotalRecords(totalRecords)
      setRows(tableRows)
    },
  })

  if (!canViewPeople) {
    return (
      <CardError
        hide={canViewPeople}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('People')}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyPeople && e.preventDefault()}
            to={`${location.pathname}/new`}
          >
            <Button.Accent disabled={!canModifyPeople}>
              {t('Add person')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer>
        <Sheet>
          {renderTable({
            data: rows,
            totalRecords,
            loading: false,
            error: null,
            fetchAction: peopleRequest,
            columns: tableConfig.columns,
          })}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    persistSelectedFilter
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
    defaultFilters={tableConfig.defaultFilters}
  >
    <PeopleTable />
  </AutoTableProvider>
)
