import { formatDateStringToISO } from 'utils/formatting'
import { TABLE_FILTER_DATE_OUTPUT_FORMAT } from 'utils/constants'
import {
  ShiftPacketCell,
  ShiftPacketCountyCell,
  ShiftTurfNameCell,
  ShiftLocationNameCell,
  ShiftCanvasserNameCell,
  AssigneeNameCell,
  ShiftStartCell,
  shiftPacketUploadDateCellRenderer,
  ShiftDueDateCell,
  QCStatusCell,
  DeliveryStatusCell,
  ShiftVRRCell,
} from './ShiftTableCells'
import {
  shiftPacketCsvCell,
  shiftPacketCountyCsvCell,
  shiftTurfNameCsvCell,
  shiftPacketUploadDateCsvCell,
  shiftDueDateCsvCell,
  shiftLocationNameCsvCell,
  shiftCanvasserNameCsvCell,
  assigneeNameCsvCell,
  shiftVRRCsvCell,
  DeliveryStatusCSVCell,
} from './shiftCsvCells'
import { PETITION_STATUS_TYPES, QC_STATUS_TYPES } from '../constants'

export const nonQcDefaultFilter = [
  {
    id: 'default',
    name: 'Default Filter',
    default: true,
    rules: [],
    metadata: {
      hiddenColumns: [
        'packet_created_at',
        'packet_qc_deadline',
        'turf_name',
        'id',
        'visual_review_responses_description',
      ],
    },
  },
]

export default {
  voter_registration: {
    label: 'shifts',
    defaultSortColumn: 'shift_start',
    defaultSortOrder: 'desc',
    tableFilters: [
      {
        column: 'type',
        operator: 'is',
        param: 'voter_registration',
      },
    ],
    columns: [
      {
        dataKey: 'packet_original_filename',
        title: 'Packet',
        type: 'string',
        width: 400,
        autoResize: true,
        resizable: true,
        bodyCell: ShiftPacketCell,
        csvCell: shiftPacketCsvCell,
      },
      {
        dataKey: 'id',
        title: 'Shift ID',
        type: 'number',
        resizable: true,
      },
      {
        dataKey: 'status',
        title: 'QC Status',
        type: 'enum',
        options: QC_STATUS_TYPES,
        resizable: true,
        bodyCell: QCStatusCell,
      },
      {
        dataKey: 'delivered',
        title: 'Delivery Status',
        type: 'enum',
        options: [
          { label: 'Delivered', value: 'true' },
          { label: 'Not Delivered', value: 'false' },
        ],
        resizable: true,
        bodyCell: DeliveryStatusCell,
        csvCell: DeliveryStatusCSVCell,
      },
      {
        dataKey: 'turf_name',
        title: 'Turf',
        type: 'string',
        resizable: true,
        bodyCell: ShiftTurfNameCell,
        csvCell: shiftTurfNameCsvCell,
      },
      {
        dataKey: 'shift_start',
        title: 'Start Time',
        type: 'date',
        resizable: true,
        bodyCell: ShiftStartCell,
        filterConstructor: rule => ({
          ...rule,
          param: formatDateStringToISO(
            rule.param,
            TABLE_FILTER_DATE_OUTPUT_FORMAT
          ),
          column: 'shift_start',
        }),
      },
      {
        dataKey: 'packet_created_at',
        title: 'Upload Time',
        type: 'date',
        resizable: true,
        bodyCell: shiftPacketUploadDateCellRenderer,
        csvCell: shiftPacketUploadDateCsvCell,
        filterConstructor: rule => ({
          ...rule,
          param: formatDateStringToISO(
            rule.param,
            TABLE_FILTER_DATE_OUTPUT_FORMAT
          ),
          column: 'shift_start',
        }),
      },
      {
        dataKey: 'packet_qc_deadline',
        title: 'Due Time',
        type: 'date',
        resizable: true,
        bodyCell: ShiftDueDateCell,
        csvCell: shiftDueDateCsvCell,
        filterConstructor: rule => ({
          ...rule,
          param: formatDateStringToISO(
            rule.param,
            TABLE_FILTER_DATE_OUTPUT_FORMAT
          ),
          column: 'packet_qc_deadline',
        }),
      },
      {
        dataKey: 'location_name',
        title: 'Location',
        type: 'string',
        resizable: true,
        bodyCell: ShiftLocationNameCell,
        csvCell: shiftLocationNameCsvCell,
      },
      {
        dataKey: 'canvasser_full_name',
        title: 'Canvasser',
        type: 'string',
        resizable: true,
        bodyCell: ShiftCanvasserNameCell,
        csvCell: shiftCanvasserNameCsvCell,
      },
      {
        dataKey: 'soft_count_cards_complete_collected',
        title: 'Complete Forms',
        type: 'number',
        resizable: true,
      },
      {
        dataKey: 'soft_count_cards_incomplete_collected',
        title: 'Incomplete Forms',
        type: 'number',
        resizable: true,
      },
      {
        dataKey: 'visual_review_responses_description',
        title: 'Visual Review Responses',
        type: 'string',
        resizable: true,
        bodyCell: ShiftVRRCell,
        csvCell: shiftVRRCsvCell,
      },
    ],
    get defaultFilters() {
      return nonQcDefaultFilter
    },
  },
  petitions: {
    label: 'shifts',
    defaultSortColumn: 'shift_start',
    defaultSortOrder: 'desc',
    tableFilters: [
      {
        column: 'type',
        operator: 'is',
        param: 'petitions',
      },
    ],
    columns: [
      {
        dataKey: 'petition_packet_filename',
        title: 'Packet',
        type: 'string',
        width: 400,
        autoResize: true,
        resizable: true,
        bodyCell: ShiftPacketCell,
        csvCell: shiftPacketCsvCell,
      },
      {
        dataKey: 'petition_packet_county',
        title: 'County',
        type: 'string',
        autoResize: true,
        resizable: true,
        bodyCell: ShiftPacketCountyCell,
        csvCell: shiftPacketCountyCsvCell,
      },
      {
        dataKey: 'id',
        title: 'Shift ID',
        type: 'number',
        resizable: true,
      },
      {
        dataKey: 'status',
        title: 'Status',
        type: 'enum',
        options: PETITION_STATUS_TYPES,
        resizable: true,
        bodyCell: QCStatusCell,
      },
      {
        dataKey: 'turf_name',
        title: 'Turf',
        type: 'string',
        resizable: true,
        bodyCell: ShiftTurfNameCell,
        csvCell: shiftTurfNameCsvCell,
      },
      {
        dataKey: 'shift_start',
        title: 'Start Time',
        type: 'date',
        resizable: true,
        bodyCell: ShiftStartCell,
        filterConstructor: rule => ({
          ...rule,
          param: formatDateStringToISO(
            rule.param,
            TABLE_FILTER_DATE_OUTPUT_FORMAT
          ),
          column: 'shift_start',
        }),
      },
      {
        dataKey: 'petition_packet_created_at',
        title: 'Upload Time',
        type: 'date',
        resizable: true,
        bodyCell: shiftPacketUploadDateCellRenderer,
        csvCell: shiftPacketUploadDateCsvCell,
        filterConstructor: rule => ({
          ...rule,
          param: formatDateStringToISO(
            rule.param,
            TABLE_FILTER_DATE_OUTPUT_FORMAT
          ),
          column: 'shift_start',
        }),
      },
      {
        dataKey: 'location_name',
        title: 'Location',
        type: 'string',
        resizable: true,
        bodyCell: ShiftLocationNameCell,
        csvCell: shiftLocationNameCsvCell,
      },
      {
        dataKey: 'canvasser_full_name',
        title: 'Canvasser',
        type: 'string',
        resizable: true,
        bodyCell: ShiftCanvasserNameCell,
        csvCell: shiftCanvasserNameCsvCell,
      },
      {
        dataKey: 'petition_packet_assignee_full_name',
        title: 'Assigned to',
        type: 'string',
        resizable: true,
        bodyCell: AssigneeNameCell,
        csvCell: assigneeNameCsvCell,
      },
    ],
    get defaultFilters() {
      return [
        ...nonQcDefaultFilter,
        {
          id: 'petitions_needs_qc',
          name: 'Needs QC',
          conjunction: 'exclusive',
          rules: [
            {
              column: 'petition_packet_assignee_id',
              operator: 'is_nil',
            },
            {
              column: 'status',
              operator: 'in',
              param: ['ready_for_qc', 'in_qc'],
            },
            {
              column: 'petition_signatures_id',
              operator: 'is_nil',
              invert: true,
            },
          ],
          metadata: {
            hiddenColumns: [],
          },
        },
      ]
    },
  },
}
