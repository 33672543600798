import React, { useState, useContext } from 'react'
import { Section, Button, ButtonBlock } from '@politechdev/blocks-design-system'
import styles from './ProjectionBreakdown.module.scss'
import BreakdownPaginator from './BreakdownPaginator/BreakdownPaginator'
import BreakdownPage from './BreakdownPage/BreakdownPage'
import BulkChangesModal from './BulkChangesModal/BulkChangesModal'
import { ProjectionContext } from '../ProjectionContext/ProjectionContext'

const ProjectionBreakdown = ({ setDirty }) => {
  const [pageMap] = useState({})
  const [isBulkChangesModalVisible, setIsBulkChangesModalVisible] =
    useState(false)
  const { selectedWeek, setSelectedWeek, weeklyProjectedData } =
    useContext(ProjectionContext)

  return (
    <>
      <div>
        <BreakdownPaginator
          currentPage={selectedWeek}
          setCurrentPage={setSelectedWeek}
          weeklyProjectedData={weeklyProjectedData}
          pageMap={pageMap}
        />
        <Section secondary>
          <div className={styles.breakdown__container}>
            <BreakdownPage setDirty={setDirty} />
          </div>
          <ButtonBlock>
            <Button.Secondary
              onClick={() => setIsBulkChangesModalVisible(true)}
            >
              Make changes to multiple weeks
            </Button.Secondary>
          </ButtonBlock>
        </Section>
      </div>
      <BulkChangesModal
        isVisible={isBulkChangesModalVisible}
        setIsVisible={setIsBulkChangesModalVisible}
        setDirty={setDirty}
      />
    </>
  )
}

export default ProjectionBreakdown
