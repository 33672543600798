import { Section, PdfViewer } from 'components/'
import { useTranslation } from 'react-i18next'
import useProofOfResidence from './useProofOfResidence'

const ProofOfResidence = ({ formId }) => {
  const { proofOfResidenceScanUrl, porRemovedAt } = useProofOfResidence(formId)

  const { t } = useTranslation()

  return (
    <Section
      label={t('Proof of residence')}
      empty={!proofOfResidenceScanUrl}
      emptyMessage="No proof of residence was found."
    >
      {porRemovedAt ? (
        <PdfViewer
          file={proofOfResidenceScanUrl}
          documentLoadingMessage={t('Scan loading...')}
          removedAt={porRemovedAt}
        />
      ) : (
        <PdfViewer
          file={proofOfResidenceScanUrl}
          documentLoadingMessage={t('Scan loading...')}
        />
      )}
    </Section>
  )
}

export default ProofOfResidence
