import React from 'react'
import { Switch, Route } from 'react-router-dom'
import FieldManagement from '..'
import GoalSelect from '../GoalSelect/GoalSelect'
import GoalNew from '../GoalNew/GoalNew'
import GoalSingle from '../GoalSingle/GoalSingle'
import ProjectionSelect from '../ProjectionSelect/ProjectionSelect'
import ProjectionNew from '../ProjectionNew/ProjectionNew'
import ProjectionSingle from '../ProjectionSingle/ProjectionSingle'

const FieldManagementRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={FieldManagement} />
    <Route exact path={`${match.path}/goals`} component={GoalSelect} />
    <Route exact path={`${match.path}/goals/new`} component={GoalNew} />
    <Route path={`${match.path}/goals/new/:turfId`} component={GoalNew} />
    <Route path={`${match.path}/goals/:id`} component={GoalSingle} />
    <Route
      exact
      path={`${match.path}/projections`}
      component={ProjectionSelect}
    />
    <Route
      exact
      path={`${match.path}/projections/new`}
      component={ProjectionNew}
    />
    <Route
      path={`${match.path}/projections/:id`}
      component={ProjectionSingle}
    />
  </Switch>
)

export default FieldManagementRoutes
