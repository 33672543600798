import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import {
  MultiSelectField,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchLocations } from 'requests/locations'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts/index'
import { buildParams, buildLocation, buildOption } from './utils'

const LocationSelectField = ({
  label,
  locationTypes,
  filters,
  onSelect,
  location,
  locations = [],
  isMulti = false,
  ...props
}) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState(location?.id)
  const [multiValue, setMultiValue] = useState(
    locations.map(location => location.id)
  )
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  const { qcEnabled, doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'view',
  })

  const { makeRequest, isLoading } = useRequest(fetchLocations, {
    onSuccess: ({ locations: incomingLocations }) => {
      setOptions(
        incomingLocations.map(incomingLocation =>
          buildOption(qcEnabled, incomingLocation)
        )
      )
    },
    onError: () => {
      setErrorMsg('failed to fetch locations')
    },
  })

  const getLocations = () => {
    const params = buildParams({
      query: debounced,
      locationTypes,
      filters,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getLocations()
  }, [debounced])

  useEffect(() => {
    setValue(location?.id)
  }, [location])

  useEffect(() => {
    setMultiValue(locations.map(location => location.id))
  }, [JSON.stringify(locations)])

  const singleOptions = buildSingleOptions(options, location, location =>
    buildOption(qcEnabled, location)
  )

  const multiOptions = buildMultiOptions(options, locations, location =>
    buildOption(qcEnabled, location)
  )

  const handleChange = selection => {
    const selectedOption = singleOptions.find(
      ({ value }) => value === selection
    )
    onSelect &&
      onSelect(selectedOption ? buildLocation(selectedOption) : undefined)
  }

  const handleMultiChange = selections => {
    onSelect &&
      onSelect(
        multiOptions
          .filter(({ value }) => selections.includes(value))
          .map(buildLocation)
      )
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSelectField
          label={label || t('Location')}
          options={multiOptions}
          onSelect={handleMultiChange}
          onInputChange={setQuery}
          onClear={() => setQuery('')}
          values={multiValue}
          loading={isLoading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      ) : (
        <SelectField
          label={label || t('Location')}
          options={singleOptions}
          onSelect={handleChange}
          onInputChange={setQuery}
          onClear={() => {
            setQuery('')
            setValue(undefined)
            onSelect(undefined)
          }}
          value={value}
          loading={isLoading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      )}
    </>
  )
}

LocationSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  locationTypes: PropTypes.array,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  location: PropTypes.object,
  required: PropTypes.bool,
}

LocationSelectField.defaultProps = {
  id: 'location',
  locationTypes: [],
  filters: [],
  location: null,
}

export default LocationSelectField
