import { List } from 'components'
import { useTranslation } from 'react-i18next'
import { defaultTo } from 'lodash'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { PacketAssignmentListItem } from './ListItem'
import { getDaysLeft, getPacketLanguage } from '../../../../utils'

const StaffMemberPacketList = ({ user }) => {
  const { t } = useTranslation()

  const { setIsReassignmentModalOpen, setPacketsBeingAssigned } =
    usePacketAssignment()

  const { assigned_packets } = defaultTo(user, {
    assigned_packets: [],
  })

  const filteredPackets = assigned_packets.filter(
    packet => packet.shift.status !== 'completed'
  )

  if (filteredPackets.length === 0) {
    return (
      <div className="quality-control__packet-management__main-panel--empty">
        <p>{t('No assigned packets')}</p>
      </div>
    )
  }

  return (
    <List>
      {filteredPackets.map(packet => (
        <PacketAssignmentListItem
          id={packet.id}
          key={packet.id}
          packetName={packet.original_filename}
          scanCount={packet.scans_count}
          daysLeft={getDaysLeft(packet)}
          packetLanguage={getPacketLanguage(packet)}
          reassignPacket={() => {
            setPacketsBeingAssigned([packet])
            setIsReassignmentModalOpen(true)
          }}
        />
      ))}
    </List>
  )
}

export default StaffMemberPacketList
