import { useTranslation } from 'react-i18next'
import { EmailTemplateSelectField, CardError } from 'components'
import {
  FieldBlock,
  Sheet,
  Section,
  TextField,
  ProgressBar,
  PageHeader,
} from '@politechdev/blocks-design-system'
import TemplatePreviewLabel from 'email/template/TemplatePreviewLabel/TemplatePreviewLabel'
import { useContext, useEffect } from 'react'
import { useReactRouter, useRequest } from 'hooks'
import { fetchEmailTemplate } from 'requests/email'
import styles from './Send.module.scss'
import { SendContext, SendProvider } from '../SendContext/SendContext'
import SendRecipients from '../SendRecipients/SendRecipients'
import SendSource from '../SendSource/SendSource'
import TemplatePreview from '../../template/TemplatePreview/TemplatePreview'
import SendControls from '../SendControls/SendControls'

const Send = () => {
  const { t } = useTranslation()
  const { setTemplate, template, subject, setSubject, from, setFrom } =
    useContext(SendContext)
  const { match, history } = useReactRouter()
  const {
    makeRequest: templateReq,
    isLoading: isTemplateReqLoading,
    hasErrors: templateReqError,
  } = useRequest(fetchEmailTemplate, {
    onSuccess: res => setTemplate(res['people/email_template']),
  })

  const { id } = match.params

  useEffect(() => {
    id && templateReq(id, { fields: ['id', 'name', 'template_content'] })
  }, [])

  const selectTemplate = id => {
    templateReq(id, { fields: ['id', 'name', 'template_content'] })
    history.push(`/email/send/${id}`)
  }

  useEffect(() => {
    template && history.push(`/email/send/${template.id}`)
  }, [template])

  return (
    <div>
      <PageHeader title={t('Send Email')} collapsed>
        <SendControls />
      </PageHeader>
      <ProgressBar show={isTemplateReqLoading} />
      <CardError
        hide={!templateReqError}
        message={t('Something went wrong retrieving the email draft.')}
      />
      <Sheet>
        <div className={styles.toolbar}>
          <FieldBlock>
            <TextField
              id="from"
              label={t('From')}
              type="email"
              required
              onChange={setFrom}
              value={from}
              placeholder={t('me@email.com')}
            />
            <TextField
              id="subject"
              label={t('Subject')}
              required
              onChange={setSubject}
              value={subject}
            />
            <EmailTemplateSelectField
              template={template}
              onSelect={({ id }) => selectTemplate(id)}
              required
            />
          </FieldBlock>
        </div>
        <div className={styles.panels}>
          <Section
            collapsed
            label={t('Recipients')}
            className={styles.panels__controls}
          >
            <SendSource />
            <SendRecipients />
          </Section>
          <Section>
            {template?.template_content && (
              <>
                <TemplatePreviewLabel />
                <TemplatePreview template={template.template_content} />
              </>
            )}
          </Section>
        </div>
      </Sheet>
    </div>
  )
}
export default () => (
  <SendProvider>
    <Send />
  </SendProvider>
)
