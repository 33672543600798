import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { init as fullstoryInit } from '@fullstory/browser'
import { AuthProvider, useAuth } from 'contexts'
import { loadPolyfills } from 'utils/polyfills'
import { Toast, ToastProvider } from '@politechdev/blocks-design-system'
import { NetworkCheck } from 'components'
import AuthenticatedApp from './AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'
import { ErrorBoundary } from './ErrorBoundary'
import Loader from './Loader'
import './assets/styles/app.scss'

window.React = React

fullstoryInit({ orgId: 'o-1VT21E-na1' })

void loadPolyfills()

const Application = () => {
  const { isLoggedIn } = useAuth() as {
    isLoggedIn: boolean
  }

  return isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

ReactDOM.render(
  <ErrorBoundary>
    <ToastProvider>
      <Toast />
      <NetworkCheck />
      <BrowserRouter>
        <Loader>
          <AuthProvider>
            <Application />
          </AuthProvider>
        </Loader>
      </BrowserRouter>
    </ToastProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)
