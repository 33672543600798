import { useTranslation } from 'react-i18next'
import { Button } from 'components'
import { getToken } from 'utils/auth'
import { getApiUrl } from 'utils/req'
import { useReactRouter } from 'hooks'
import ListEventInviteModal from '../ListEventInviteModal/ListEventInviteModal'
import ListEmailModal from '../ListEmailModal/ListEmailModal'
import ListSmsModal from '../ListSmsModal/ListSmsModal'

const MAX_PEOPLE_COUNT = 100

const ListMemberTableActions = ({ currentList, totalRecords }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  return (
    <>
      <Button
        icon
        tooltipLabel={t('Download as CSV')}
        onClick={() =>
          window.open(
            getApiUrl(
              `/api/v1/lists/${currentList.id}/people.csv?jwt=${getToken()}`
            ),
            '_blank'
          )
        }
      >
        file_download
      </Button>
      <Button
        icon
        tooltipLabel={t('Start phone bank with this list')}
        onClick={() =>
          history.push(`/organize/phone_banks/new?list_id=${currentList.id}`)
        }
      >
        add_ic_call
      </Button>
      <ListEventInviteModal currentList={currentList} />
      <ListEmailModal currentList={currentList} />
      {totalRecords <= MAX_PEOPLE_COUNT && (
        <ListSmsModal currentList={currentList} />
      )}
    </>
  )
}

export default ListMemberTableActions
