import { useReactRouter } from 'hooks'
import { useTranslation } from 'react-i18next'
import ScanReviewSection from 'qualityControl/scanReview/ScanReviewSection/ScanReviewSection'
import { usePhoneVerificationQuestions } from 'qualityControl/contexts/PhoneVerificationQuestionsContext'
import { getFullCallHistory } from 'qualityControl/contexts/contextSelectors'
import { useCurrentScan } from 'qualityControl/contexts/ScanContext'
import CallHistoryList from '../callSession/CallHistoryList/CallHistoryList'
import CallMenu from '../CallMenu/CallMenu'
import styles from './PhoneVerificationPanel.module.scss'

const PhoneVerificationPanel = () => {
  const { t } = useTranslation()

  const {
    location: { pathname },
  } = useReactRouter()

  const isInVisualQc = () => pathname.split('/').pop() === 'visual_qc'
  const isInPhoneVerification = () =>
    pathname.split('/').pop() === 'phone_verification'

  const [scan] = useCurrentScan()
  const [questions] = usePhoneVerificationQuestions()
  const callAttempts = getFullCallHistory(scan, questions)

  if (isInVisualQc()) return null

  return (
    <>
      <div className={styles.title}>
        <h3>{t('Phone Verification')}</h3>
      </div>
      {callAttempts.length && questions ? (
        <ScanReviewSection
          approved={callAttempts[callAttempts.length - 1].result === 'Verified'}
          initExpanded={isInPhoneVerification()}
        >
          <CallHistoryList attempts={callAttempts} />
          <CallMenu />
        </ScanReviewSection>
      ) : (
        <CallMenu />
      )}
    </>
  )
}

export default PhoneVerificationPanel
