import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  Modal,
  TextBlock,
} from '@politechdev/blocks-design-system'

const ShiftDeleteWarningModal = ({
  isOpen,
  setIsOpen,
  shiftDeleteRequest,
  type,
}) => {
  const { t } = useTranslation()

  const onApply = () => {
    shiftDeleteRequest()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      id={`warn-delete-${type}}`}
      title={t(`Delete ${type}?`)}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <p>
              {t(`Are you sure you want to delete this ${type} and all of its
            volunteers?`)}
            </p>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Danger onClick={onApply}>{t('Delete')}</Button.Danger>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ShiftDeleteWarningModal
