import { createContext, useState } from 'react'

export const GoalListContext = createContext()

const GoalListContextProvider = ({ children }) => {
  const [selectedTurf, setSelectedTurf] = useState()
  const [turfMap, setTurfMap] = useState({})
  const [goalsByTurfMap, setGoalsByTurfMap] = useState({})

  return (
    <GoalListContext.Provider
      value={{
        selectedTurf,
        setSelectedTurf,
        turfMap,
        setTurfMap,
        goalsByTurfMap,
        setGoalsByTurfMap,
      }}
    >
      {children}
    </GoalListContext.Provider>
  )
}

export default GoalListContextProvider
