import moment from 'moment-timezone'
import {
  MORE_THAN_FIVE,
  OVERDUE,
  ZERO,
  ONE,
  SHOW_ALL,
  THREE_TO_FIVE,
  TWO,
  THREE,
  FOUR,
  FIVE,
} from 'qualityControl/inbox/menuItems'
import {
  getPacketDaysLeft,
  getPacketDaysSinceCollection,
  getPacketTimeZoneOffset,
  getPacketUSState,
} from 'utils/qualityControl'
import { SORT_ORDER } from 'constants'
import { SORT_OPTIONS } from 'constants/packetFilters'

export const filterPacketsByTimeZone = timeZoneFilter => packets => {
  if (timeZoneFilter === SHOW_ALL) return packets
  return packets.filter(
    packet =>
      getPacketTimeZoneOffset(packet) ===
      moment.tz(timeZoneFilter).utcOffset() * 60
  )
}

export const doesPassDaysLeft = (daysLeft, packetDaysLeft) => {
  switch (daysLeft) {
    case OVERDUE: {
      return packetDaysLeft < 0
    }
    case ZERO: {
      return packetDaysLeft === 0
    }
    case ONE: {
      return packetDaysLeft === 1
    }
    case TWO: {
      return packetDaysLeft === 2
    }
    case THREE: {
      return packetDaysLeft === 3
    }
    case FOUR: {
      return packetDaysLeft === 4
    }
    case FIVE: {
      return packetDaysLeft === 5
    }
    case THREE_TO_FIVE: {
      return packetDaysLeft >= 3 && packetDaysLeft <= 5
    }
    case MORE_THAN_FIVE: {
      return packetDaysLeft > 5
    }
    default: {
      return true
    }
  }
}

export const doesUSStateMatch = (selectedUSState, packetUSState) =>
  selectedUSState === packetUSState

const doesPassDaysSinceCollectionFilter = (
  daysSinceCollectionFilter,
  packetDaysSinceCollection
) => {
  switch (daysSinceCollectionFilter) {
    case ZERO: {
      return packetDaysSinceCollection === 0
    }
    case ONE: {
      return packetDaysSinceCollection === 1
    }
    case TWO: {
      return packetDaysSinceCollection === 2
    }
    case THREE: {
      return packetDaysSinceCollection === 3
    }
    case FOUR: {
      return packetDaysSinceCollection === 4
    }
    case FIVE: {
      return packetDaysSinceCollection === 5
    }
    case MORE_THAN_FIVE: {
      return packetDaysSinceCollection > 5
    }
    default: {
      return true
    }
  }
}

export const filterPacketsByDaysLeft = daysLeftFilter => packets => {
  if (daysLeftFilter === SHOW_ALL) return packets
  return packets.reduce((filteredPackets, packet) => {
    const packetDaysLeft = getPacketDaysLeft(packet)
    doesPassDaysLeft(daysLeftFilter, packetDaysLeft) &&
      filteredPackets.push(packet)
    return filteredPackets
  }, [])
}

export const filterPacketsBySelectedUSState =
  selectedStateFilter => packets => {
    if (selectedStateFilter === SHOW_ALL) return packets
    return packets.reduce((filteredPackets, packet) => {
      const packetSelectedState = getPacketUSState(packet)
      doesUSStateMatch(selectedStateFilter, packetSelectedState) &&
        filteredPackets.push(packet)
      return filteredPackets
    }, [])
  }

const filterPacketsByDaysSinceCollection =
  daysSinceCollectionFilter => packets => {
    if (daysSinceCollectionFilter === SHOW_ALL) return packets
    return packets.reduce((filteredPackets, packet) => {
      const daysSinceCollection = getPacketDaysSinceCollection(packet)
      if (daysSinceCollection === null) {
        return filteredPackets
      }
      if (
        doesPassDaysSinceCollectionFilter(
          daysSinceCollectionFilter,
          daysSinceCollection
        )
      ) {
        filteredPackets.push(packet)
      }
      return filteredPackets
    }, [])
  }

export const applyPacketFilters = (
  packets,
  daysLeftFilter,
  timeZoneFilter,
  daysSinceCollectionFilter,
  selectedStateFilter
) => {
  const filters = [
    filterPacketsByDaysLeft(daysLeftFilter),
    filterPacketsByTimeZone(timeZoneFilter),
    filterPacketsByDaysSinceCollection(daysSinceCollectionFilter),
    filterPacketsBySelectedUSState(selectedStateFilter),
  ]
  return filters.reduce(
    (filteredPackets, filter) => filter(filteredPackets),
    packets
  )
}

const sortPacketsByDaysLeft = (packets, sortDirection) =>
  [...packets].sort((packetA, packetB) =>
    sortDirection === SORT_ORDER.ASC
      ? getPacketDaysLeft(packetA) - getPacketDaysLeft(packetB)
      : getPacketDaysLeft(packetB) - getPacketDaysLeft(packetA)
  )

const sortPacketsByTimeZone = (packets, sortDirection) =>
  [...packets].sort((packetA, packetB) =>
    sortDirection === SORT_ORDER.ASC
      ? getPacketTimeZoneOffset(packetA) - getPacketTimeZoneOffset(packetB)
      : getPacketTimeZoneOffset(packetB) - getPacketTimeZoneOffset(packetA)
  )

const sortPacketsByDaysSinceCollection = (packets, sortDirection) =>
  [...packets].sort((packetA, packetB) =>
    sortDirection === SORT_ORDER.ASC
      ? getPacketDaysSinceCollection(packetA) -
        getPacketDaysSinceCollection(packetB)
      : getPacketDaysSinceCollection(packetB) -
        getPacketDaysSinceCollection(packetA)
  )
const sortPacketsByUSState = (packets, sortDirection) =>
  [...packets].sort((packetA, packetB) =>
    sortDirection === SORT_ORDER.ASC
      ? getPacketUSState(packetA) - getPacketUSState(packetB)
      : getPacketUSState(packetB) - getPacketUSState(packetA)
  )

export const sortPackets = (sortOrder, selectedSort, packets) => {
  switch (selectedSort) {
    case SORT_OPTIONS.DAYS_LEFT: {
      return sortPacketsByDaysLeft(packets, sortOrder[selectedSort])
    }
    case SORT_OPTIONS.TIME_ZONE: {
      return sortPacketsByTimeZone(packets, sortOrder[selectedSort])
    }
    case SORT_OPTIONS.DAYS_SINCE_COLLECTION: {
      return sortPacketsByDaysSinceCollection(packets, sortOrder[selectedSort])
    }
    case SORT_OPTIONS.UNITED_STATES: {
      return sortPacketsByUSState(packets, sortOrder[selectedSort])
    }
    default: {
      return packets
    }
  }
}

export const getSortDirection = (key, sortOrder) =>
  sortOrder.find(sortOption => sortOption.key === key).direction

export const getToggledSortOption = sortOption => {
  const { direction } = sortOption
  const nextOption = { ...sortOption }
  nextOption.direction =
    direction === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC
  return nextOption
}

export const prioritizeSortOrder = (updatedSortOption, sortOrder) => {
  const prioritizedSortOrder = sortOrder.map(sortOption => {
    const nextOption = { ...sortOption }
    nextOption.direction === SORT_ORDER.DESC
      ? (nextOption.direction = SORT_ORDER.ASC)
      : (nextOption.direction = SORT_ORDER.DESC)
    return nextOption
  })
  prioritizedSortOrder.push(updatedSortOption)
  return prioritizedSortOrder
}
