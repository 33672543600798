import { useState } from 'react'
import { Card, CardError } from 'components'
import {
  Font,
  Grid,
  Section,
  DetailItem,
  TextBlock,
  FieldBlock,
  ButtonBlock,
  Button,
  NumberField,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks'
import { advancePhoneBankRound } from 'requests/phoneBanks'

const PhoneBankProgress = ({
  currentRound,
  callsCompleted,
  callsMade,
  callsPerRound,
  callDelay,
  setCallDelay,
  canModifyPhoneBank,
  phoneBankId,
  fetchRequest,
}) => {
  const { t } = useTranslation()
  const [errorMsg, setErrorMsg] = useState('')
  const rounds = [...Array(currentRound)].map(
    (_r, i) => callsPerRound[i + 1] || 0
  )
  const { makeRequest } = useRequest(advancePhoneBankRound, {
    onSuccess: () => {
      setErrorMsg('')
      fetchRequest(phoneBankId)
    },
    onError: () => {
      setErrorMsg('An error occurred while trying to advance phone bank round')
    },
  })
  const makeAdvanceRoundRequest = () => makeRequest(phoneBankId)
  return (
    <>
      <ContentBlock>
        <CardError hide={!errorMsg} message={errorMsg} />
      </ContentBlock>
      <Grid>
        <Card title={`${t('Calls made')}`}>
          <Font.Display className="align--center margin--bottom">
            {callsMade}
          </Font.Display>
        </Card>
        <Card title={`${t('Participants reached')}`}>
          <Font.Display className="align--center margin--bottom">
            {callsCompleted}
          </Font.Display>
        </Card>
        <Section>
          {rounds.map((r, i) => {
            const roundNumber = i + 1
            return (
              <DetailItem
                key={`round-${roundNumber}`}
                label={`${t('Round')} ${roundNumber}${
                  roundNumber === currentRound ? t(' (Active)') : ''
                }`}
              >
                {`${callsPerRound[roundNumber] || 0} ${t('calls')}`}
              </DetailItem>
            )
          })}
        </Section>
        <Section>
          <TextBlock>
            <p>
              {t(
                'Each contact in your call list is dialed once per round. Contacts who were not succesfully contacted or explicitly dismissed are attempted again in the next round.'
              )}
            </p>
          </TextBlock>
          <FieldBlock>
            <NumberField
              id="delay"
              label={t('Wait to redial contacts (in hours)')}
              value={callDelay}
              min={1}
              onChange={val => {
                if (val > 0) return setCallDelay(val)
                return setCallDelay(1)
              }}
              disabled={!canModifyPhoneBank}
            />
            {rounds[rounds.length - 1] > 0 && (
              <ButtonBlock>
                <Button onClick={makeAdvanceRoundRequest}>
                  Advance To Next Round
                </Button>
              </ButtonBlock>
            )}
          </FieldBlock>
        </Section>
      </Grid>
    </>
  )
}

export default PhoneBankProgress
