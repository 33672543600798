import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { Uploader } from 'components'
import { useDeliveryState, useDeliveryActions } from './DeliveryContext'

const ReceiptUploader = () => {
  const { t } = useTranslation()
  const { currentDelivery } = useDeliveryState()
  const { deliverDelivery } = useDeliveryActions()
  const [receiptUrl, setReceiptUrl] = useState(null)

  const handleUpload = fileData => deliverDelivery(currentDelivery.id, fileData)

  useEffect(() => {
    setReceiptUrl(currentDelivery.runner_receipt_url)
  }, [currentDelivery.runner_receipt_url])

  if (currentDelivery.status === 'completed') {
    if (receiptUrl) {
      return (
        <a href={receiptUrl} target="_blank" rel="noreferrer">
          <Button>
            <span>{t('Form Delivery Receipt')}</span>{' '}
            <Icon.CheckCircle alt="" />
          </Button>
        </a>
      )
    }
  }

  return (
    <Uploader
      label={t('Form Delivery Receipt')}
      dialog
      endpoint="/system/files/upload?url=true"
      onUpload={handleUpload}
      fileTypes={['.pdf']}
      stringifyData={false}
    />
  )
}

export default ReceiptUploader
