import { FIELD_KEYS } from '../utils'

export const getMissingFields = errorRecord => {
  const missing = []
  const {
    first_name,
    last_name,
    primary_phone_number,
    primary_email_address,
    external_id,
  } = errorRecord
  if (!first_name) {
    missing.push({
      label: 'First name',
      key: FIELD_KEYS.FIRST_NAME,
      type: 'text',
    })
  }
  if (!last_name) {
    missing.push({
      label: 'Last name',
      key: FIELD_KEYS.LAST_NAME,
      type: 'text',
    })
  }
  if (!primary_phone_number) {
    missing.push({
      label: 'Primary phone',
      key: FIELD_KEYS.PRIMARY_PHONE_NUMBER,
      type: 'tel',
    })
  }
  if (!primary_email_address) {
    missing.push({
      label: 'Primary email',
      key: FIELD_KEYS.PRIMARY_EMAIL_ADDRESS,
      type: 'email',
    })
  }
  if (!external_id) {
    missing.push({
      label: 'External ID',
      key: FIELD_KEYS.EXTERNAL_ID,
      type: 'text',
    })
  }
  return missing
}

export const getExtraFields = errorRecord => {
  const extraFields = [
    {
      label: 'Prefix',
      value: errorRecord.prefix,
    },
    {
      label: 'Suffix',
      value: errorRecord.suffix_name,
    },
    {
      label: 'County',
      value: errorRecord.residential_address?.county,
    },
    {
      label: 'Gender',
      value: errorRecord.gender,
    },
    {
      label: 'GoTV phone number',
      value: errorRecord.gotv_phone_number,
    },
    {
      label: 'Teams',
      value: errorRecord.teams?.join(', '),
    },
    {
      label: 'Organizations',
      value: errorRecord.organization_memberships?.join(', '),
    },
    {
      label: 'Events attended',
      value: errorRecord.event_attendances?.join(', '),
    },
    {
      label: 'Campaigns',
      value: errorRecord.campaigns?.join(', '),
    },
    {
      label: 'Issues',
      value: errorRecord.issues?.join(', '),
    },
    {
      label: 'Notes',
      value: `Note 1: ${errorRecord.notes
        ?.map(({ content }) => content)
        ?.join(' | Note 2: ')}`,
    },
  ]

  return extraFields
}
