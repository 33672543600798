import { LOCATION_TYPES } from 'constants/locations'

const buildFormFromExistingLocation = location => ({
  ...location,
  location_type: LOCATION_TYPES[location.location_type]?.value,
  turf_id: location.turf?.id,
})

const buildDefaultLocation = () => ({
  location_type: LOCATION_TYPES.canvassing.value,
})

export const buildForm = location =>
  location ? buildFormFromExistingLocation(location) : buildDefaultLocation()
