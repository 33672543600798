import { useReducer, useState, useEffect } from 'react'
import { CardError, CustomFieldsDisplay } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  Section,
  SectionLabel,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { putPerson } from 'requests/people'
import { useRequest } from 'hooks'
import { useCurrent } from 'contexts'
import {
  CustomFieldConfig,
  CustomFieldValue,
} from 'components/CustomFields/CustomFields.types'

const PersonAdditionalInformation = ({
  person,
  refreshPerson,
}: {
  person: {
    id: string
    custom_field_data: Record<string, CustomFieldValue> | null
  }
  refreshPerson: () => unknown
}) => {
  const { getCustomFieldsForTable } = useCurrent() as {
    getCustomFieldsForTable: (tableName: string) => Array<CustomFieldConfig>
  }

  const customFieldsWithoutGroup = getCustomFieldsForTable('people').filter(
    ({ group }) => !group
  )

  const [isEditing, toggleIsEditing] = useReducer(current => !current, false)

  const [additionalData, setAdditionalData] = useState<
    Record<string, CustomFieldValue>
  >({})

  const updateField = (attribute: string, value: CustomFieldValue): unknown =>
    setAdditionalData(current => ({
      ...current,
      [attribute]: value,
    }))

  const { t } = useTranslation()

  const { makeRequest: updatePersonReq, hasErrors } = useRequest<void>(
    putPerson,
    {
      onSuccess: () => {
        refreshPerson()
        toggleIsEditing()
      },
    }
  )

  useEffect(() => {
    if (person?.custom_field_data) {
      setAdditionalData(person.custom_field_data)
    }
  }, [person])

  const updateAdditionalInformation = async () => {
    await updatePersonReq(person.id, { custom_field_data: additionalData })
  }

  const handleCancelUpdate = () => {
    setAdditionalData(person.custom_field_data || {})
    toggleIsEditing()
  }

  const hasAdditionalFields = customFieldsWithoutGroup.length !== 0

  if (!hasAdditionalFields) return null

  const hasAdditionalData = !!Object.values(additionalData).length

  return (
    <Section>
      <ButtonBlock>
        <SectionLabel>{t('Additional information')}</SectionLabel>
        {isEditing ? (
          <ButtonBlock justify="right">
            <Button.Secondary onClick={handleCancelUpdate}>
              {t('Cancel')}
            </Button.Secondary>
            <Button onClick={updateAdditionalInformation}>{t('Save')}</Button>
          </ButtonBlock>
        ) : (
          <Button.Secondary
            aria-label={t('Edit')}
            onClick={toggleIsEditing}
            data-testid="edit-additional-information-button"
          >
            <Icon.Pencil />
          </Button.Secondary>
        )}
      </ButtonBlock>
      <CardError
        hide={!hasErrors}
        message={t('An internal error occurred while trying to update')}
      />
      <Section
        empty={!isEditing && !hasAdditionalData}
        emptyMessage={t('No additional data set')}
      >
        <CustomFieldsDisplay
          fields={customFieldsWithoutGroup}
          currentData={additionalData}
          onChange={updateField}
          isEditing={isEditing}
        />
      </Section>
    </Section>
  )
}

export default PersonAdditionalInformation
