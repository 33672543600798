import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { GoalSummaryContext } from '../GoalSummaryContext/GoalSummaryContext'

const GoalBreakdown = ({ isVisible, setIsVisible, handleCreateGoal }) => {
  const { t } = useTranslation()
  const { weeklySum } = useContext(GoalSummaryContext)
  return (
    <Modal
      id="handle-delta"
      title={t('Update goal target before saving')}
      isOpen={isVisible}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <p>
              {t(
                'The sum of your weekly targets does not match the overall collection target you entered for this goal.'
              )}
            </p>
            <p>
              {t('Would you like to update your overall collection goal to')}{' '}
              <strong>{weeklySum}</strong>?
            </p>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary
            onClick={() => {
              setIsVisible(false)
            }}
          >
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={handleCreateGoal}>
            {t('Update goal')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default GoalBreakdown
