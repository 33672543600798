import moment from 'moment'
import { dateTimeFormat } from 'utils/constants'

export const PersonNameCsvCell = data =>
  data.person ? data.person.name : '[Person removed]'

export const PersonFirstNameCsvCell = data => data.person?.first_name
export const PersonLastNameCsvCell = data => data.person?.last_name

export const hostNameCsvCell = data => data.user?.name

export const meetingLocationCsvCell = data => data.location?.name

export const meetingDateCsvCell = data =>
  moment(data?.start_time).format(dateTimeFormat)

export const meetingStatusCsvCell = data => {
  let status

  if (data.cancelled) {
    status = 'Cancelled'
  } else if (moment().isBefore(moment(data.end_time))) {
    status = 'Open'
  } else {
    status = 'Completed'
  }
  return status
}
