import React from 'react'
import { PieChart, Pie, Cell, Label } from 'recharts'
import { Tooltipped } from 'react-md'

const DonutChart = ({
  label,
  value,
  denominator,
  tooltipLabel,
  secondary,
  size,
  colors,
}) => {
  const nominatorValue = typeof value === 'number' ? value : 0
  const denominatorValue = typeof denominator === 'number' ? denominator : 100
  const data = [
    { value: nominatorValue },
    { value: Math.max(denominatorValue - nominatorValue, 0) },
  ]

  if (!nominatorValue && !denominatorValue) {
    data[1].value = 1
  }

  const iColors = colors || ['#61bf93', '#ededed']
  const pieces = data.map((_entry, index) => [
    <Cell key={index} fill={iColors[index % iColors.length]} />,
  ])
  const centerText = (
    <Label
      value={nominatorValue}
      position="center"
      style={{ fontSize: '20px', fontWeight: 'bold' }}
    />
  )

  return (
    <div className="chart--donut">
      <Tooltipped label={tooltipLabel || ''}>
        <div>
          <PieChart width={size || 80} height={size || 80}>
            <Pie
              data={data}
              dataKey="value"
              startAngle={90}
              endAngle={90 + 360}
              labelLine={false}
              innerRadius="50%"
              outerRadius="80%"
              fill="#ededed"
              isAnimationActive={false}
            >
              {pieces}
              {centerText}
            </Pie>
          </PieChart>
        </div>
      </Tooltipped>
      <p
        className={
          secondary
            ? 'chart--donut__label chart--donut__label--small'
            : 'chart--donut__label'
        }
      >
        {label}
      </p>
    </div>
  )
}

export default DonutChart
