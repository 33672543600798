import { Font } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import styles from './OperatorGauge.module.scss'

const cx = classNames.bind(styles)

const OperatorGaugeLegend = () => {
  const { t } = useTranslation()
  return (
    <div className={cx('legend__container')}>
      <Font.Copy variant="hint" className={cx('legend__title')}>
        {t('Agent capacity')}
      </Font.Copy>
      <div className={cx('legend__item', 'legend__item--safe')}>
        {t('High')}
      </div>
      <div className={cx('legend__item', 'legend__item--warning')}>
        {t('Medium')}
      </div>
      <div className={cx('legend__item', 'legend__item--danger')}>
        {t('Low')}
      </div>
    </div>
  )
}

export default OperatorGaugeLegend
