import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError, SearchSelect } from 'components'
import { fetchPeople } from 'requests/people'
import { useCachedRequest } from 'hooks/useRequest'
import { buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts/index'
import { buildParams, buildOptions } from './utils'

const PersonSelectFieldDEPRECATED = ({
  label,
  filters,
  onSelect,
  person,
  keepEmpty,
  fields,
  excludedPersonIds = [],
  disabledPersonIds = [],
  disabledPersonReason = '',
  ...props
}) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'person',
    ability: 'view',
  })

  const [value, setValue] = useState(person?.id)
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState([])
  const [debounced] = useDebounce(query, 300)

  const { makeRequest, isLoading, errors, hasErrors, response } =
    useCachedRequest(params => fetchPeople(params), {
      onSuccess: ({ people: incomingPeople }) =>
        setOptions(
          incomingPeople
            .filter(person => !excludedPersonIds.includes(person.id))
            .map(person =>
              buildOptions(person, disabledPersonIds, disabledPersonReason)
            )
        ),
    })

  const errorMsg = Object.entries(errors)
    .map(([field, error]) => `${field}: ${error}`)
    .join('; ')

  useEffect(() => {
    if (value !== person?.id) {
      const selection = response?.people.find(({ id }) => id === value)
      onSelect && onSelect(selection)
      keepEmpty && setValue(undefined)
    }
  }, [value])

  useEffect(() => {
    if (value !== person?.id) {
      setValue(person?.id)
    }
  }, [person])

  const getPeople = () => {
    const params = buildParams({
      query: debounced,
      filters,
      fields,
    })
    hasPermission && makeRequest(`getPersonOptions ${debounced}`, params)
  }

  useEffect(() => {
    getPeople()
  }, [debounced])

  const singleOptions = buildSingleOptions(options, person, person =>
    buildOptions(person, disabledPersonIds, disabledPersonReason)
  )

  return (
    <>
      <CardError hide={!hasErrors} message={errorMsg} />
      <SearchSelect
        label={label || t('Person')}
        options={singleOptions}
        onChange={setValue}
        onInputChange={setQuery}
        value={value || ''}
        isLoading={isLoading}
        disabled={!hasPermission}
        helpText={
          !hasPermission && t('You do not have permission to edit this field')
        }
        {...props}
      />
    </>
  )
}

PersonSelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  person: PropTypes.object,
  keepEmpty: PropTypes.bool,
}

PersonSelectFieldDEPRECATED.defaultProps = {
  id: 'person',
  filters: [],
  person: null,
  keepEmpty: false,
}

export default PersonSelectFieldDEPRECATED
