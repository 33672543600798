import { useTranslation } from 'react-i18next'
import { TextBlock } from '@politechdev/blocks-design-system'

const IntroText = () => {
  const { t } = useTranslation()

  return (
    <>
      <TextBlock>
        <h2>{t('Thank you for volunteering your time')}</h2>
        <p>
          {t(
            'Please read the following guidelines before starting your session.'
          )}
        </p>
      </TextBlock>
      <TextBlock>
        <h3>{t('Running a phone bank session')}</h3>
        <p>
          {t(
            'Thank you again for helping us reach out to voters! Phone banking is an efficient and effective way to communicate with voters about the upcoming election and what’s at stake. On each page you will see the voter’s name, a phone number to call, and some response options. Please make sure you are choosing the correct response for each conversation that you have so that we get an accurate picture of what is happening in the district.'
          )}
        </p>
      </TextBlock>
      <TextBlock>
        <h3>{t('General etiquette')}</h3>
        <p>
          {t(
            'When talking to a voter make sure you are kind, courteous, and understanding. The conversations that you have with voters will go with them into the polls and can be the deciding factor on whether or not they vote for our candidate. With that in mind, make sure to keep all conversations positive, and to never get into fights with someone on the other line.'
          )}
        </p>
      </TextBlock>
      <TextBlock>
        <h3>{t('Privacy policy')}</h3>
        <p>
          {t(
            'Please do not record or share any voter’s contact information Additionally, any data captured during the call is property of the DCCC and results of calls should not be shared outside of the calling platform.'
          )}
        </p>
      </TextBlock>
    </>
  )
}

export default IntroText
