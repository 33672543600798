import React from 'react'
import WeekRow from '../WeekRow/WeekRow'
import styles from './MonthView.module.scss'

const MonthView = ({ dateRange }) => {
  const weeks = dateRange.reduce((weeks, date, index) => {
    const weekIndex = Math.floor(index / 7)

    if (weeks[weekIndex]) {
      weeks[weekIndex].push(date)
    } else {
      weeks.push([date])
    }
    return weeks
  }, [])
  if (!weeks.length) return null

  return (
    <div className={styles.calendar}>
      <div className={styles.columns}>
        {weeks[0].map(({ date }, index) => (
          <div className={styles.label} key={index}>
            <span>{date.format('ddd')}</span>
          </div>
        ))}
      </div>
      {weeks.map((week, index) => (
        <WeekRow key={index} week={week} rowLimit={4} showMarker />
      ))}
    </div>
  )
}

export default MonthView
