export const locationAddressCsvCell = data =>
  [data.street_address, data.city, data.state, data.zipcode].join(' ')

export const locationHostedStatusCsvCell = data =>
  data.has_hosted_event ? 'yes' : 'no'

export const locationMaxSizeCsvCell = data => data.maximum_occupancy

export const locationPublicTransportationCsvCell = data =>
  data.has_public_transportation ? 'yes' : 'no'
