import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  Button,
  ButtonBlock,
  MenuButton,
  Font,
  Icon,
} from '@politechdev/blocks-design-system'
import { useFilters } from 'contexts'
import AddRuleButton from './AddRuleButton'
import AddRuleMenu from './AddRuleMenu'
import { operators, conjunctions } from './operatorsConfig'
import { areAllFiltersValid } from './utils'
import styles from './FilterRuleControls.module.scss'

export const generateRuleButtonText = ({
  columnOptions,
  columnType,
  columnTitle,
  prefix,
  param,
  suffix = '',
}) => {
  switch (columnType) {
    case 'string': {
      return `${columnTitle} ${prefix} "${param}" ${suffix}`
    }
    case 'multiple_choice': {
      const label = param.reduce((label, value, index, array) => {
        const optionLabel =
          columnOptions.find(option => [value, +value].includes(option.value))
            ?.label || ''
        return label + optionLabel + (index === array.length - 1 ? '' : ', ')
      }, '')
      return `${columnTitle} ${prefix} ${label} ${suffix}`
    }
    case 'enum': {
      const label = columnOptions.find(option =>
        [param, +param].includes(option.value)
      )?.label
      return `${columnTitle} ${prefix} ${label} ${suffix}`
    }
    default: {
      return `${columnTitle} ${prefix} ${param} ${suffix}`
    }
  }
}

const FilterRuleControls = ({ columns }) => {
  const { t } = useTranslation()
  const {
    filterRules,
    applyFilterRule,
    removeFilterRule,
    filterConjunction,
    setFilterConjunction,
  } = useFilters()
  const [conjunctionIsOpen, setConjunctionOpen] = useState(false)
  const toggleConjunctionMenu = () =>
    conjunctionIsOpen ? setConjunctionOpen(false) : setConjunctionOpen(true)
  const [ruleColumn, setRuleColumn] = useState()
  const [ruleDetails, setRuleDetails] = useState()
  const availableColumns = columns.filter(
    column => typeof column.filterable !== 'boolean' || column.filterable
  )
  const columnsByKey = availableColumns.reduce(
    (acc, col) => ({ ...acc, [col.dataKey]: col }),
    {}
  )

  useEffect(() => {
    if (ruleColumn && ruleDetails) {
      applyFilterRule({
        column: ruleColumn,
        operator: ruleDetails.operator,
        param: ruleDetails.param,
        invert: ruleDetails.invert,
        id: `${`${ruleColumn}${ruleDetails.invert ? 'not' : ''}${
          ruleDetails.operator
        }${ruleDetails.param}`}`,
      })
      setRuleColumn('')
      setRuleDetails('')
    }
  }, [ruleColumn, ruleDetails])

  if (!areAllFiltersValid(filterRules, availableColumns)) {
    return null
  }

  return (
    <>
      <div className={styles.field__label}>
        <Font.Copy variant="hint">{t('Filter rules')}</Font.Copy>
      </div>
      <ButtonBlock>
        {filterRules.length > 1 && (
          <MenuButton
            label={conjunctions[filterConjunction] || t('Match all')}
            isOpen={conjunctionIsOpen}
            onToggle={toggleConjunctionMenu}
          >
            <MenuButton.Select
              options={[
                { label: t('Match all'), value: 'exclusive' },
                { label: t('Match any'), value: 'inclusive' },
              ]}
              onSelect={conjunction => setFilterConjunction(conjunction)}
            />
          </MenuButton>
        )}
        {!!filterRules.length &&
          filterRules.map(({ column: key, operator, invert, param, id }) => {
            const column = columnsByKey[key]
            const { prefix, suffix } = operators[column.type].find(
              c => c.value === operator && !!c.invert === !!invert
            )

            return (
              <Button.Secondary key={id} onClick={() => removeFilterRule(id)}>
                <span>
                  {generateRuleButtonText({
                    columnOptions: column.options,
                    columnType: column.type,
                    columnTitle: t(column.title),
                    prefix: t(prefix),
                    param,
                    suffix: t(suffix),
                  })}
                </span>
                <Icon.Times />
              </Button.Secondary>
            )
          })}
        {ruleColumn ? (
          <AddRuleMenu
            column={columnsByKey[ruleColumn]}
            setRuleDetails={setRuleDetails}
            setRuleColumn={setRuleColumn}
            operatorOptions={
              columnsByKey[ruleColumn].filterOperators ||
              operators[columnsByKey[ruleColumn].type]
            }
          />
        ) : (
          <AddRuleButton columns={availableColumns} setColumn={setRuleColumn} />
        )}
      </ButtonBlock>
    </>
  )
}

FilterRuleControls.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
}

FilterRuleControls.defaultProps = {
  columns: [],
}

export default FilterRuleControls
