import moment from 'moment'

export const buildItems = ({ items, startDate, endDate, rowLimit }) => {
  const rangeStart = startDate.clone().startOf('day')
  const rangeEnd = endDate.clone().endOf('day')

  const filteredItems = items.reduce((rangeItems, item) => {
    const itemStart = moment(item.start)
    const itemEnd = moment(item.end)

    const startIn = itemStart.isBetween(rangeStart, rangeEnd)
    const endIn = itemEnd.isBetween(rangeStart, rangeEnd)
    const inRange = rangeStart.isBetween(itemStart, itemEnd)

    if (!startIn && !endIn && !inRange) return rangeItems

    rangeItems.push({
      ...item,
      start: itemStart,
      startDay: startIn ? itemStart.day() : 0,
      startInRange: startIn,
      end: itemEnd,
      endDay: endIn ? itemEnd.day() : 6,
      endInRange: endIn,
    })
    return rangeItems
  }, [])

  const sortedItems = filteredItems.sort(
    (a, b) =>
      a.startDay - b.startDay ||
      b.endDay - b.startDay - a.endDay - a.startDay ||
      +a.start.toDate() - +b.start.toDate()
  )

  let rowNumber = 1
  const placedItems = sortedItems.reduce(
    (placedItems, item, index, unplacedItems) => {
      const prevItem = index && placedItems[index - 1]
      const prevEndDay = index ? prevItem?.endDay : -1
      if (index && !prevItem) return placedItems

      const nextItemInRow = unplacedItems.find(
        unplaced =>
          unplaced.startDay > prevEndDay &&
          !placedItems.find(placed => placed.id === unplaced.id)
      )

      if (nextItemInRow) {
        nextItemInRow.rowNumber = rowNumber
        placedItems.push(nextItemInRow)
        return placedItems
      }

      rowNumber += 1
      const nextItem = unplacedItems.find(
        unplaced => !placedItems.find(placed => placed.id === unplaced.id)
      )

      if (nextItem) {
        nextItem.rowNumber = rowNumber
        placedItems.push(nextItem)
      }
      return placedItems
    },
    []
  )

  if (!rowLimit) return placedItems

  const rowOverages = new Array(7).fill().reduce((overages, _, day) => {
    const count = placedItems.filter(
      item =>
        item.startDay >= day && item.endDay <= day && item.rowNumber > rowLimit
    )
    if (count.length) {
      overages.push({
        startDay: day,
        endDay: day,
        date: rangeStart.clone().add(day, 'day'),
        showMore: true,
        showMoreCount: count.length,
      })
    }
    return overages
  }, [])

  const limitedItems = placedItems
    .filter(item => item.rowNumber <= rowLimit)
    .concat(rowOverages)

  return limitedItems
}
