import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { SORT_ORDER } from '../../constants'
import styles from './SortArrowButton.module.scss'

const cx = classNames.bind(styles)

const SortArrow = ({
  direction = SORT_ORDER.DESC,
  toggleDirection,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <Button.Secondary
      onClick={toggleDirection}
      tooltipLabel={
        direction === SORT_ORDER.DESC
          ? t('Sort descending')
          : t('Sort ascending')
      }
      className={cx('sort-arrow', className)}
    >
      {direction === SORT_ORDER.DESC ? <Icon.ArrowUp /> : <Icon.ArrowDown />}
    </Button.Secondary>
  )
}

SortArrow.propTypes = {
  direction: PropTypes.string,
  toggleDirection: PropTypes.func.isRequired,
}

SortArrow.defaultProps = {
  direction: SORT_ORDER.DESC,
}

export default SortArrow
