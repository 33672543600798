import { useTranslation } from 'react-i18next'
import { NumberField } from '@politechdev/blocks-design-system'
import { defaultTo } from 'lodash'
import styles from './TurfListItem.module.scss'

const TurfListItem = ({
  turfId,
  turfName,
  reprioritization,
  selectedId,
  setSelectedId,
  setTurfDaysLeftToQc,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div
        className={`${styles.name} ${selectedId === turfId && styles.selected}`}
      >
        {turfName}
      </div>
      <div className={styles.input}>
        <NumberField
          id={turfId}
          label={t('Days left')}
          step={1}
          value={defaultTo(reprioritization, undefined)}
          onChange={val => {
            setTurfDaysLeftToQc(turfId, val)
          }}
          onFocus={() => setSelectedId(turfId)}
          onBlur={() => setSelectedId(null)}
        />
      </div>
    </div>
  )
}

export default TurfListItem
