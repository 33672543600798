import { useEffect, useState } from 'react'
import { useRequest, useInterval } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'

const useBackgroundRequest = ({
  key,
  requestId,
  requestData,
  attemptLimit = 13,
}) => {
  const [attemptCount, setAttemptCount] = useState(0)
  const incrementAtemptCount = () => setAttemptCount(count => count + 1)
  const attemptLimitReached = attemptCount >= attemptLimit

  const getIdRequest = useRequest(requestId)
  const id = getIdRequest.response

  const getDataRequest = useRequest(requestData)

  const loadedId = getDataRequest.response?.id
  const isDataLoaded = id && loadedId === id

  const { startInterval, resetInterval } = useInterval(() => {
    getDataRequest.makeRequest(id, key)
    incrementAtemptCount()
  }, 5000)

  useEffect(() => {
    if (
      (!getDataRequest.isLoading && loadedId && loadedId === id) ||
      attemptLimitReached
    ) {
      resetInterval()
    }
  }, [id, loadedId, getDataRequest.isLoading, attemptLimitReached])

  useEffect(() => {
    setAttemptCount(0)
    resetInterval()
    id && startInterval()
  }, [id])

  const makeRequest = () => getIdRequest.makeRequest(key)

  const isLoading =
    getIdRequest.isLoading ||
    getDataRequest.isLoading ||
    (!isDataLoaded && !attemptLimitReached)

  const getIdErrorMessage =
    getIdRequest.hasErrors && formatErrorMessage(getIdRequest.errors)
  const getDataRequestErrorMessage =
    getDataRequest.hasErrors && formatErrorMessage(getDataRequest.errors)

  const errorMsg =
    getIdErrorMessage ||
    getDataRequestErrorMessage ||
    (attemptLimitReached && 'Request timed out')

  const isSuccessful = !isLoading && !errorMsg

  return {
    makeRequest,
    isLoading,
    errorMsg,
    isSuccessful,
    response: getDataRequest.response?.response,
  }
}

export default useBackgroundRequest
