import { memo } from 'react'
import { MenuButton, ListItem, FontIcon } from 'react-md'

const ActionColumnHeader = props => {
  const disabled = props.column?.disabled || false
  return <FontIcon disabled={disabled}>build</FontIcon>
}

const ActionColumnCell = memo(
  ({ rowData, actions, listInline, disabled = false }) => {
    const menuItems = actions
      .filter(({ show }) => (show ? show(rowData) : true))
      .map(({ onClick, show, isDisabled, ...listItemProps }, i) => (
        <ListItem
          key={i}
          onClick={() => onClick(rowData)}
          disabled={
            typeof isDisabled === 'function' ? isDisabled(rowData) : isDisabled
          }
          {...listItemProps}
        />
      ))

    return disabled || !menuItems.length ? (
      <FontIcon aria-label="row-action-menu-toggle" disabled>
        more_vert
      </FontIcon>
    ) : (
      <MenuButton
        aria-label="row-action-menu-toggle"
        id="menu-button-2"
        icon
        listInline={listInline}
        position="tl"
        menuItems={menuItems}
      >
        more_vert
      </MenuButton>
    )
  }
)

const ActionColumn = () => {}

ActionColumn.defaultProps = {
  isActionColumn: true,
  width: 50,
  frozen: 'left',
  sortable: false,
  align: 'center',
  listInline: true,
  actions: [],
  headerCell: ActionColumnHeader,
  bodyCell: ActionColumnCell,
}

export default ActionColumn
