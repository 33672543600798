import { fetchJSON, stringifyParams } from 'utils/req'
import { callFields } from 'constants/phoneBankSessions'

export const fetchCall = async (phoneBankId, callId) => {
  const params = stringifyParams({
    fields: callFields,
  })

  const json = await fetchJSON(
    `/api/v1/phone_banks/${phoneBankId}/calls/${callId}?${params}`,
    'GET',
    null,
    { useJwt: true }
  )
  return json['phone_banking/call']
}

export const createSession = phoneBankId =>
  new Promise((resolve, reject) => {
    fetchJSON(
      `/api/v1/phone_banks/${phoneBankId}/call_sessions?${stringifyParams({
        fields: 'id',
      })}`,
      'POST',
      null,
      { useJwt: true }
    )
      .then(({ 'phone_banking/session': session }) => resolve(session.id))
      .catch(error => {
        reject(error)
      })
  })

export const putCallStatus = async (
  endpoint,
  phoneBankId,
  callId,
  params = {}
) =>
  fetchJSON(
    `/api/v1/phone_banks/${phoneBankId}/calls/${callId}/${endpoint}`,
    'PUT',
    { event_params: params },
    { useJwt: true }
  )

export const getSession = (phoneBankId, sessionId) =>
  fetchJSON(
    `/api/v1/phone_banks/${phoneBankId}/call_sessions/${sessionId}?${stringifyParams(
      {
        fields: [
          'id',
          {
            phone_bank: [
              'name',
              'id',
              {
                script: [
                  'cause_statement',
                  {
                    script_objects: [
                      'id',
                      'position_in_script',
                      'is_section_divider',
                      { script_text: ['id', 'text', 'type'] },
                      {
                        question: [
                          'id',
                          'question_to_ask',
                          'type',
                          { answer_options: ['id', 'response'] },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      }
    )}`,
    'GET',
    null,
    { useJwt: true }
  )

export const getNextCall = (phoneBankId, mode, hasVoip) =>
  fetchJSON(
    `/api/v1/phone_banks/${phoneBankId}/calls`,
    'POST',
    {
      fields: callFields,
      phone_bank_call: {
        person_id: null,
        external: mode === 'external' || !hasVoip,
      },
    },
    { useJwt: true }
  )

export const submitSessionSurvey = (phoneBankId, sessionId, response) =>
  fetchJSON(
    `/api/v1/phone_banks/${phoneBankId}/call_sessions/${sessionId}`,
    'PUT',
    { session: { caller_survey_response: response } },
    { useJwt: true }
  )
