import { get, has } from 'lodash'

export const isObject = value =>
  typeof value === 'object' && !Array.isArray(value) && value !== null
export const isNumber = value =>
  typeof value === 'number' && !Number.isNaN(value)
export const isIncludedIn = options => value => options.includes(value)
export const isNumberLike = value => !Number.isNaN(+value)
export const isOneOf = validations => value =>
  validations.some(isValid => isValid(value))

export const validate = {
  isObject,
  isNumber,
  isIncludedIn,
  isNumberLike,
  isOneOf,
}

export const getValidations = schema => {
  const validators = []
  const searchStack = [[[], schema]]
  const visited = new WeakSet()

  while (searchStack.length > 0) {
    const [searchPath, searchSchema] = searchStack.pop()
    visited.add(searchSchema)
    Object.entries(searchSchema).forEach(([path, value]) => {
      const currentPath = [...searchPath, path]
      if (isObject(value)) {
        if (visited.has(value)) {
          throw new Error('Cyclical reference in validation object')
        }
        searchStack.push([currentPath, value])
      } else if (Array.isArray(value)) {
        validators.push({
          path: currentPath,
          validations: value,
        })
      } else {
        // eslint-disable-next-line no-console
        console.warn('Invalid validation object')
      }
    })
  }
  return validators
}

export const createValidatorFromSchema = schema => {
  const validators = getValidations(schema)

  return obj =>
    !validators.some(({ path, validations }) => {
      if (!has(obj, path)) return true
      const value = get(obj, path)
      let isInvalid = true
      try {
        isInvalid = validations.some(validate => !validate(value))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      return isInvalid
    })
}
