import { useTranslation } from 'react-i18next'
import { TextBlock, ButtonBlock, Font } from '@politechdev/blocks-design-system'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { useRoutePathParams } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import { useState } from 'react'
import { fetchTeams } from 'requests/teams'
import { formatErrorMessage } from 'utils/formatting'
import AddOrganizationTeamModal from '../AddOrganizationTeamModal/AddOrganizationTeamModal'
import tableConfig from './tableConfig'
import styles from './OrganizationTeamTable.module.scss'

const OrganizationTeamTable = () => {
  const { t } = useTranslation()
  const { renderTable } = useAutoTable()
  const [{ id: currentOrganizationId }] = useRoutePathParams()
  const [teams, setTeams] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const {
    makeRequest: fetchAction,
    isLoading,
    errors,
  } = useRequest(
    params =>
      fetchTeams({
        current_page: params.page,
        per: params.pageSize,
        sort_attr: params.sortColumn || 'id',
        sort_dir: params.sortOrder || 'asc',
        filters: {
          rules: [
            ...(params.filterRules || []),
            {
              column: 'organizations',
              operator: 'is',
              param: currentOrganizationId,
            },
          ],
          conjunction: params.filterConjunction,
        },
        fields: ['id', 'name', 'members_count'],
      }),
    {
      onSuccess: ({ teams, meta }) => {
        setTeams(teams)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  return (
    <>
      <div className={styles.header}>
        <TextBlock>
          <Font.Label variant="section">{t('Teams')}</Font.Label>
        </TextBlock>
        <ButtonBlock justify="right">
          <AddOrganizationTeamModal existingTeams={teams} />
        </ButtonBlock>
      </div>
      {renderTable({
        data: teams,
        isLoading,
        errorMsg,
        totalRecords,
        columns: tableConfig.columns,
        fetchAction,
      })}
    </>
  )
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enablePagination
    enableSort
  >
    <OrganizationTeamTable />
  </AutoTableProvider>
)
