import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Icon,
  Font,
  Button,
  ButtonBlock,
  Modal,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { ManagementContext } from 'checkins/CheckinContext/ManagementContext'
import styles from './ArchiveButton.module.scss'

const ArchiveButton = ({ id, position }) => {
  const { t } = useTranslation()

  const { currentAdminCheckin, setQuestionToArchive } =
    useContext(ManagementContext)

  const [modalOpen, setModalOpen] = useState(false)

  const handleArchive = () => {
    const questionsWithHigherPosition = () =>
      currentAdminCheckin.questions.filter(
        q => !q.archived && q.position > position
      )
    setQuestionToArchive({
      checkinId: currentAdminCheckin.id,
      questionId: id,
      questionsToAdjust: questionsWithHigherPosition(),
    })
    setModalOpen(false)
  }

  return (
    <>
      <Button.Secondary
        onClick={() => setModalOpen(true)}
        tooltipLabel={t('Archive')}
      >
        <Icon.Archive alt={t('Archive')} />
      </Button.Secondary>
      <Modal
        id="archive-question"
        title={t('Archive Check-in Question')}
        isOpen={modalOpen}
        className={styles.modal}
      >
        <Modal.Body>
          <TextBlock>
            <Font.Copy>
              {t(
                'This action will remove the question from the check-in. Response data will be maintained and remain viewable in the review panel.'
              )}
            </Font.Copy>
          </TextBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={() => setModalOpen(false)}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger onClick={() => handleArchive()}>
              {t('Archive')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ArchiveButton
