import React from 'react'

import classNames from 'classnames/bind'
import styles from './WeeklyRates.module.scss'

const cx = classNames.bind(styles)

const WeeklyRates = ({ month, rates }) => {
  const renderRate = (rate, index) => (
    <div
      key={index}
      className={cx('rate', {
        'rate--light': rate >= 1 && rate <= 5,
        'rate--medium': rate >= 6 && rate <= 9,
        'rate--dark': rate >= 10,
      })}
    />
  )

  if (!rates) return null
  const ratesStyle = rates.length > 1 ? styles.rates : null

  return (
    <div>
      <p className={styles.label}>{month}</p>
      <div className={ratesStyle}>{Object.values(rates).map(renderRate)}</div>
    </div>
  )
}

export default WeeklyRates
