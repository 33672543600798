import { uniqBy } from 'lodash'
import moment from 'moment'
import { sumBy, mean } from 'lodash/math'
import { QC_STATUS_MAP } from 'shifts/constants'
import {
  customPercent,
  customRatio,
} from 'registrationReports/reportsConfig/columnTotals'

const getPaddedDurationValue = (duration, key) =>
  duration.get(key).toString().padStart(2, '0')

export const formatDuration = seconds => {
  const duration = moment.duration(seconds, 'seconds')

  return `${getPaddedDurationValue(duration, 'h')}:${getPaddedDurationValue(
    duration,
    'm'
  )}:${getPaddedDurationValue(duration, 's')}`
}

export const formatDurationCell = ({ cellData }) =>
  cellData ? formatDuration(cellData) : '-'

export const formatDecimal = value => {
  if (value === 0) return 0.0

  if (!Number.isInteger(value)) {
    return +parseFloat(value).toFixed(2)
  }

  return value
}

export const secondsToDays = seconds => (seconds ? seconds / 86400 : NaN)

export const secondsToHours = seconds => (seconds ? seconds / 3600 : NaN)

export const combineName = (first_name, last_name) =>
  [first_name, last_name].join(' ')

export const formatDeliveryStatus = delivered => {
  switch (delivered) {
    case true: {
      return 'Delivered'
    }
    case false: {
      return 'Not Delivered'
    }
    default: {
      return ''
    }
  }
}

export const formatActiveColumn = archived => {
  switch (archived) {
    case true: {
      return 'No'
    }
    case false: {
      return 'Yes'
    }
    default: {
      return ''
    }
  }
}

export const formatQcStatus = statusValue =>
  QC_STATUS_MAP[statusValue] || statusValue

export const formatDistrictType = districtType => {
  switch (districtType) {
    case 'congressional': {
      return 'Congressional District'
    }
    case 'state_legislative_upper': {
      return 'State Legislative Upper District'
    }
    case 'state_legislative_lower': {
      return 'State Legislative Lower District'
    }
    default: {
      return districtType
    }
  }
}

const pasteJsonArrayValues = (jsonArray, key1, key2, valueSep) =>
  jsonArray.map(object => `${object[key1]}${valueSep}${object[key2]}`)

export const combineJsonPairs = (jsonArray, key1, key2, valueSep, objectSep) =>
  jsonArray
    ? pasteJsonArrayValues(jsonArray, key1, key2, valueSep).join(objectSep)
    : null

export const combineJsonArrays = (
  jsonArray1,
  jsonArray2,
  key1,
  key2,
  valueSep
) => {
  const formattedArray1 = jsonArray1
    ? pasteJsonArrayValues(jsonArray1, key1, key2, valueSep)
    : null

  const formattedArray2 = jsonArray2
    ? pasteJsonArrayValues(jsonArray2, key1, key2, valueSep)
    : null

  const unique_values = new Set([].concat(formattedArray1, formattedArray2))

  return Array.from(unique_values)
    .filter(v => v)
    .join(', ')
}

export const filterJsonPairs = (jsonArray, key1, values1, key2, values2) =>
  jsonArray.filter(
    object => values1.includes(object[key1]) && values2.includes(object[key2])
  )

export const calculateRate = (nom, denom) => formatDecimal(nom / denom)

export const calculatePercent = (nom, denom) =>
  formatDecimal((nom / denom) * 100)

export const formatDecimalCell = ({ cellData }) =>
  Number.isNaN(formatDecimal(cellData)) ? '-' : formatDecimal(cellData)

export const buildDefaultFilters = (columns, id, filters) => {
  const columnKeys = columns.map(column => column.dataKey)

  return filters.map((filter, index) => ({
    id: `default-${index}`,
    name: filter.title,
    default: true,
    conjunction: 'exclusive',
    rules: filter.rules || [],
    metadata: {
      label: id,
      hiddenColumns: filter.visibleColumns
        ? columnKeys.filter(
            columnKey => !filter.visibleColumns.includes(columnKey)
          )
        : filter.hiddenColumns || [],
    },
  }))
}

const summaryFunctionSum = ({ data, key }) =>
  sumBy(data, row => parseFloat(row[key]) || 0)

const summaryFunctionPercent =
  (numKey, denomKey) =>
  ({ data }) => {
    const num = summaryFunctionSum({ key: numKey, data })
    const denom = summaryFunctionSum({ key: denomKey, data })

    return calculatePercent(num, denom)
  }

const summaryFunctionAverage = ({ data, key }) => {
  const allValues = Object.values(data).map(value => parseFloat(value[key]))

  const realNumberValues = allValues.filter(value => !Number.isNaN(value))

  return mean(realNumberValues)
}

const percentOfGroupedTotal = (data, numKey, denomKey, groupIdKey) => {
  const num = summaryFunctionSum({
    data,
    key: numKey,
  })

  const grouped_data = uniqBy(data, groupIdKey)

  const denom = summaryFunctionSum({
    data: grouped_data,
    key: denomKey,
  })
  return calculatePercent(num, denom)
}

export const summaryFunctions = {
  sum: summaryFunctionSum,
  percent: summaryFunctionPercent,
  avg: summaryFunctionAverage,
  customPercent,
  customRatio,
  percentOfGroupedTotal,
}
