import { get, isEmpty, isNumber } from 'lodash'
import moment from 'moment'

export const buildForm = shift => ({
  location: shift.location,
  canvasser: shift.canvasser,
  shiftDate: moment(shift.shift_start).toISOString(),
  shiftStartTime: {
    hour: moment(shift.shift_start).hour(),
    minute: moment(shift.shift_start).minute(),
  },
  shiftEndTime: {
    hour: moment(shift.shift_end).hour(),
    minute: moment(shift.shift_end).minute(),
  },
  fieldStartTime: {
    hour: moment(shift.field_start).hour(),
    minute: moment(shift.field_start).minute(),
  },
  fieldEndTime: {
    hour: moment(shift.field_end).hour(),
    minute: moment(shift.field_end).minute(),
  },
  totalCollected: shift.soft_count_cards_total_collected,
  completeCollected: shift.soft_count_cards_complete_collected,
  incompleteCollected: shift.soft_count_cards_incomplete_collected,
  phoneCollected: shift.soft_count_cards_with_phone_collected,
  preRegCollected: shift.soft_count_pre_registration_cards_collected,
  notes: shift.notes,
  custom_field_data: shift.custom_field_data,
})

export const initializeForm = {
  shiftStartTime: {
    hour: moment().hour(),
    minute: 0,
  },
  shiftEndTime: {
    hour: moment().hour() + 2,
    minute: 0,
  },
  fieldStartTime: {
    hour: moment().hour(),
    minute: 0,
  },
  fieldEndTime: {
    hour: moment().hour() + 2,
    minute: 0,
  },
}

export const buildRequest = (formData, shiftType) => ({
  location_id: formData.location.id,
  canvasser_id: formData.canvasser.id,
  shift_start: moment(formData.shiftDate)
    .hour(formData.shiftStartTime.hour)
    .minute(formData.shiftStartTime.minute)
    .second(0)
    .toISOString(),
  shift_end: moment(formData.shiftDate)
    .hour(formData.shiftEndTime.hour)
    .minute(formData.shiftEndTime.minute)
    .second(0)
    .toISOString(),
  field_start: formData.fieldStartTime
    ? moment(formData.shiftDate)
        .hour(formData.fieldStartTime.hour)
        .minute(formData.fieldStartTime.minute)
        .second(0)
        .toISOString()
    : undefined,
  field_end: formData.fieldEndTime
    ? moment(formData.shiftDate)
        .hour(formData.fieldEndTime.hour)
        .minute(formData.fieldEndTime.minute)
        .second(0)
        .toISOString()
    : undefined,
  soft_count_cards_total_collected: formData.totalCollected,
  soft_count_cards_complete_collected: formData.completeCollected,
  soft_count_cards_incomplete_collected: formData.incompleteCollected,
  soft_count_cards_with_phone_collected: formData.phoneCollected,
  soft_count_pre_registration_cards_collected: formData.preRegCollected,
  notes: formData.notes,
  type: shiftType,
  custom_field_data: formData.custom_field_data,
})

export const areFormsCollectedValid = formData =>
  +formData.totalCollected ===
  +formData.completeCollected + +formData.incompleteCollected

export const areFormsWithPhoneValid = formData =>
  +formData.phoneCollected <= +formData.totalCollected

export const validateForm = (
  formData,
  useSoftCounts,
  hasPacket,
  customFieldsConfig = {}
) => {
  const hasMinimumSystemRequiredFields =
    formData.location && formData.canvasser && formData.shiftDate

  if (!hasMinimumSystemRequiredFields) return false

  if (!hasPacket) return true

  const isCustomFormValid =
    !customFieldsConfig ||
    !(customFieldsConfig?.fields || []).some(field => {
      const value = get(formData.custom_field_data, field.name)
      const isInvalid = !value && value !== 0
      return field.required && isInvalid
    })

  if (!isCustomFormValid) return false

  if (useSoftCounts) {
    const formsCollectedAreValid = areFormsCollectedValid(formData)
    const formsWithPhoneAreValid = areFormsWithPhoneValid(formData)

    const softCountsAreValid =
      isNumber(formData.totalCollected) &&
      isNumber(formData.completeCollected) &&
      isNumber(formData.incompleteCollected) &&
      isNumber(formData.phoneCollected) &&
      isNumber(formData.preRegCollected) &&
      formsCollectedAreValid &&
      formsWithPhoneAreValid

    return softCountsAreValid
  }

  return true
}

export const getDateErrors = ({ formData, formIsValid, t }) => {
  const errors = {
    shiftStart: [],
    shiftEnd: [],
    fieldStart: [],
  }

  if (isEmpty(formData) || !formIsValid) {
    return { dateErrors: errors, hasDateErrors: false }
  }

  const shiftStart = moment(formData.shiftDate)
    .hour(formData.shiftStartTime.hour)
    .minute(formData.shiftStartTime.minute)
    .second(0)

  const shiftEnd = moment(formData.shiftDate)
    .hour(formData.shiftEndTime.hour)
    .minute(formData.shiftEndTime.minute)
    .second(0)

  if (shiftStart >= shiftEnd) {
    errors.shiftStart.push(
      t('Shift start time must be earlier than shift end time.')
    )
  }

  const fieldStart = moment(formData.shiftDate)
    .hour(formData.fieldStartTime.hour)
    .minute(formData.fieldStartTime.minute)
    .second(0)

  const fieldEnd = moment(formData.shiftDate)
    .hour(formData.fieldEndTime.hour)
    .minute(formData.fieldEndTime.minute)
    .second(0)

  if (shiftStart.isAfter(fieldStart)) {
    errors.shiftStart.push(
      t('Shift start time must be earlier or equal to field start time.')
    )
  }
  if (fieldStart.isSameOrAfter(fieldEnd)) {
    errors.fieldStart.push(
      t('Field start time must be earlier than field end time.')
    )
  }
  if (shiftEnd.isBefore(fieldEnd)) {
    errors.shiftEnd.push(
      t('Shift end must be later or equal to the field end time.')
    )
  }

  return {
    dateErrors: errors,
    hasDateErrors: Object.values(errors).some(category => category.length),
  }
}
