import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  FieldBlock,
  ContentBlock,
  TextField,
  Section,
} from '@politechdev/blocks-design-system'
import NoteCarousel from './NoteCarousel'
import styles from './NotesBlock.module.scss'

const cx = classNames.bind(styles)

class NotesBlock extends Component {
  state = {
    noteIndex: 0,
    editMode: false,
    deleteMode: false,
    currentNote: '',
    error: false,
  }

  previousNote = () => {
    this.setState(prevState => ({ noteIndex: prevState.noteIndex - 1 }))
  }

  nextNote = () => {
    this.setState(prevState => ({ noteIndex: prevState.noteIndex + 1 }))
  }

  toggleEditMode = (args = {}) => {
    const { notes } = this.props

    this.setState(prevState => ({
      editMode: !prevState.editMode,
      currentNote:
        args.new || !notes[prevState.noteIndex]
          ? ''
          : notes[prevState.noteIndex].content,
      newNote: !!args.new,
    }))
  }

  toggleDeleteMode = () => {
    this.setState(prevState => ({
      deleteMode: !prevState.deleteMode,
      error: false,
    }))
  }

  addNote = () => {
    const { addNote, notes, recordId } = this.props

    this.toggleEditMode()
    addNote(recordId, {
      content: this.state.currentNote,
    }).then(
      () => {
        this.setState({ noteIndex: notes.length })
      },
      () => {
        this.setState({ error: true })
      }
    )
  }

  updateNote = () => {
    const { notes, updateNote, recordId } = this.props

    updateNote(recordId, {
      ...notes[this.state.noteIndex],
      content: this.state.currentNote,
    }).then(this.toggleEditMode)
  }

  deleteNote = () => {
    const { notes, deleteNote, recordId } = this.props

    deleteNote(recordId, notes[this.state.noteIndex].id).then(
      () => {
        this.setState(prevState => ({
          noteIndex: 0,
          deleteMode: !prevState.deleteMode,
          error: false,
        }))
      },
      () => {
        this.setState({ error: true })
      }
    )
  }

  renderBlock() {
    const { t, notes } = this.props

    if (this.state.editMode) {
      return (
        <div>
          <FieldBlock>
            <TextField
              id="new-note"
              label={t('New note')}
              value={this.state.currentNote}
              onChange={val => {
                this.setState({ currentNote: val })
              }}
            />
          </FieldBlock>
          <ButtonBlock>
            <Button
              onClick={this.state.newNote ? this.addNote : this.updateNote}
              disabled={!this.state.currentNote}
            >
              {t('Save')}
            </Button>
            <Button.Secondary onClick={this.toggleEditMode}>
              {t('Cancel')}
            </Button.Secondary>
          </ButtonBlock>
        </div>
      )
    }

    if (this.state.deleteMode) {
      return (
        <div>
          <TextBlock>
            <p>Delete this note?</p>
          </TextBlock>
          <ContentBlock className={styles.content}>
            <TextBlock>
              <p>
                {notes[this.state.noteIndex] &&
                  notes[this.state.noteIndex].content}
              </p>
            </TextBlock>
          </ContentBlock>
          <ButtonBlock>
            <Button.Danger onClick={this.deleteNote}>
              {t('Delete')}
            </Button.Danger>
            <Button.Secondary
              onClick={() => {
                this.setState({ deleteMode: false })
              }}
            >
              Cancel
            </Button.Secondary>
          </ButtonBlock>
        </div>
      )
    }
    return (
      <div>
        <NoteCarousel
          notes={notes}
          noteIndex={this.state.noteIndex}
          previousNote={this.previousNote}
          nextNote={this.nextNote}
          toggleEditMode={this.toggleEditMode}
          toggleDeleteMode={this.toggleDeleteMode}
        />
        <ButtonBlock>
          <Button
            onClick={() => {
              this.toggleEditMode({ new: true })
            }}
          >
            {t('New Note')}
          </Button>
        </ButtonBlock>
      </div>
    )
  }

  render() {
    const { t, className } = this.props

    return (
      <Section className={cx('notes', className)} label={t('Notes')}>
        {this.renderBlock()}
        <CardError
          hide={!this.state.error}
          message="An internal error occurred while trying update this record's notes."
        />
      </Section>
    )
  }
}

NotesBlock.defaultProps = {
  className: '',
}

NotesBlock.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  updateNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default withTranslation()(NotesBlock)
