import { Switch, Route } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  SettingsNavigationRoutes,
  adminSettingsPaths,
} from 'layout/routesConfig'

import classNames from 'classnames/bind'
import styles from './SideNavigation.module.scss'
import { useNavigationPermission } from '../NavigationPermissionProvider/NavigationPermissionProvider'

const cx = classNames.bind(styles)

const SideNavigationRoutes = () => (
  <Switch>
    <Route path={adminSettingsPaths}>
      <SideNavigation routes={SettingsNavigationRoutes} />
    </Route>
  </Switch>
)

const SideNavigation = ({ routes = [] }) => (
  <nav className={cx('side-navigation')}>
    {Array.isArray(routes) &&
      routes.map(({ label, path, permissions }) => (
        <SideNavigationItem
          label={label}
          path={path}
          permissions={permissions}
        />
      ))}
  </nav>
)

const SideNavigationItem = ({ label, path, permissions }) => {
  const { hasPermission } = useNavigationPermission({ permissions })
  const { t } = useTranslation()

  if (!hasPermission) return null
  return (
    <NavLink
      key={label}
      className={cx('side-navigation__item')}
      activeClassName={cx('side-navigation__item--active')}
      to={path}
    >
      {t(label)}
    </NavLink>
  )
}

export default SideNavigationRoutes
