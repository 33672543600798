import { createContext } from 'react'
import { useCheckboxGroup } from 'hooks/useCheckboxGroup'

const CheckboxGroupProvider =
  Context =>
  ({ children }) => {
    const checkboxManagement = useCheckboxGroup()

    return (
      <Context.Provider value={checkboxManagement}>{children}</Context.Provider>
    )
  }

export const generateCheckboxGroupProvider = () => {
  const CheckboxGroupContext = createContext()

  const GeneratedCheckboxGroupProvider =
    CheckboxGroupProvider(CheckboxGroupContext)

  return [CheckboxGroupContext, GeneratedCheckboxGroupProvider]
}
