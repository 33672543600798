import React from 'react'
import PropTypes from 'prop-types'
import { Clear as ClearIcon } from 'components/uiKit/icons'
import { Popup as PopupGL } from '@urbica/react-map-gl'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import styles from './Popup.module.scss'

const cx = classNames.bind(styles)

const Popup = ({
  title,
  latitude,
  longitude,
  isOpen,
  markerOffset,
  onClose,
  children,
}) => {
  const { t } = useTranslation()

  if (!isOpen) return null

  const offset = markerOffset
    ? {
        'top-left': [0, 12],
        top: [0, 12],
        'top-right': [0, 12],
        left: [12, 0],
        right: [-12, 0],
        'bottom-left': [0, -12],
        bottom: [0, -12],
        'bottom-right': [0, -12],
      }
    : undefined

  return (
    <PopupGL
      latitude={latitude}
      longitude={longitude}
      onClose={onClose}
      closeButton={false}
      closeOnClick={false}
      offset={offset}
      className={cx('popup', {
        'popup--marker': markerOffset,
        'popup--empty': !children,
      })}
    >
      <div className={styles.header}>
        {title && <span className={styles.title}>{title}</span>}
        <button type="button" onClick={onClose} className={styles.button}>
          <ClearIcon alt={t('clear')} />
        </button>
      </div>
      {children && <div className={styles.content}>{children}</div>}
    </PopupGL>
  )
}

Popup.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  isOpen: PropTypes.bool,
  markerOffset: PropTypes.bool,
  onClose: PropTypes.func,
}

Popup.defaultProps = {
  isOpen: true,
  markerOffset: true,
  onClose: null,
}

export default Popup
