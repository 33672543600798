import { SectionLabel, Sheet, ViewContainer } from 'components'
import { useTranslation } from 'react-i18next'
import { FlagsReviewFilters } from './FlagsReviewFilters'
import FlagsReviewList from './FlagsReviewList'
import MainSectionHeader from './MainSectionHeader'

export const FlagsReviewMain = () => {
  const { t } = useTranslation()

  return (
    <ViewContainer>
      <MainSectionHeader />
      <div className="margin--top">
        <SectionLabel primary tight>
          {t('Updates')}
        </SectionLabel>
        <Sheet>
          <FlagsReviewFilters />
          <FlagsReviewList />
        </Sheet>
      </div>
    </ViewContainer>
  )
}
