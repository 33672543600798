import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Icon,
  Button,
  TextBlock,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { destroyList } from 'requests/lists'
import styles from './ListDeleteModal.module.scss'

const ListDeleteModal = ({ currentList }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(destroyList, {
    onSuccess: () => {
      closeModal()
      history.push('/organize/lists')
    },
  })

  return (
    <>
      <Button.Primary onClick={openModal} aria-label={t('delete')}>
        <Icon.TrashAlt alt={t('delete')} />
      </Button.Primary>
      <Modal
        id="list-delete"
        title={t(`Delete list`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error deleting your list')}
              hideSupportLink
            />
            <TextBlock className={styles.text}>
              {t('Are you sure you want to delete')}{' '}
              <strong>{currentList.name}</strong>
            </TextBlock>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                makeRequest(currentList.id)
              }}
            >
              {t('Delete')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ListDeleteModal
