import { createContext, useState } from 'react'

export const ProjectionContext = createContext()

const ProjectionContextProvider = ({ children }) => {
  const [totalStartDate, setTotalStartDate] = useState()
  const [totalEndDate, setTotalEndDate] = useState()
  const [goal, setGoal] = useState(0)
  const [weeklyProjectedData, setWeeklyProjectedData] = useState([])
  const [weeklyActualData, setWeeklyActualData] = useState([])
  const [selectedWeek, setSelectedWeek] = useState(0)
  const [formattedChartData, setFormattedChartData] = useState([])
  /**
   * * Note about structure of weeklyData:
   *  {
   *    startDate: date format
   *    endDate: date format
   * !  formsCollected: int, only in actualData not projectedData
   *    dailyCanvassers: int
   *    daysSpentCanvassing: int
   *    hoursSpentCanvassingDaily: float
   *    formsPerHour: int
   *  }
   */

  return (
    <ProjectionContext.Provider
      value={{
        totalStartDate,
        setTotalStartDate,
        totalEndDate,
        setTotalEndDate,
        goal,
        setGoal,
        weeklyProjectedData,
        setWeeklyProjectedData,
        weeklyActualData,
        setWeeklyActualData,
        selectedWeek,
        setSelectedWeek,
        formattedChartData,
        setFormattedChartData,
      }}
    >
      {children}
    </ProjectionContext.Provider>
  )
}

export default ProjectionContextProvider
