import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './ResourceIcon.module.scss'

const cx = classNames.bind(styles)

const ResourceIcon = forwardRef(
  (
    { name, icon, isDisabled, isActive, isFocused, isClipboard, className },
    ref
  ) => (
    <div
      className={cx(
        'item',
        {
          'item--disabled': isDisabled,
          'item--active': isActive,
          'item--focused': isFocused,
          'item--clipboard': isClipboard,
        },
        className
      )}
      ref={ref}
    >
      <div className={styles.icon}>{icon}</div>
      <span className={styles.name}>{name}</span>
    </div>
  )
)

ResourceIcon.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isFocused: PropTypes.bool,
  isClipboard: PropTypes.bool,
  className: PropTypes.string,
}

ResourceIcon.defaultProps = {
  isDisabled: false,
  isActive: false,
  isFocused: false,
  isClipboard: false,
}

export default ResourceIcon
