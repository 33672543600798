import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  SelectField,
} from '@politechdev/blocks-design-system'
import { TemplateContext } from '../TemplateContext/TemplateContext'
import { TEMPLATE_ITEM_OPTIONS } from '../TemplateContext/constants'

const TemplateAddContent = () => {
  const { t } = useTranslation()

  const OPTIONS = [
    { label: t('Title'), value: TEMPLATE_ITEM_OPTIONS.TITLE },
    { label: t('Heading'), value: TEMPLATE_ITEM_OPTIONS.HEADING },
    { label: t('Body copy'), value: TEMPLATE_ITEM_OPTIONS.BODY_COPY },
    { label: t('Button link'), value: TEMPLATE_ITEM_OPTIONS.BUTTON_LINK },
    { label: t('Image'), value: TEMPLATE_ITEM_OPTIONS.IMAGE },
  ]

  const { addItem } = useContext(TemplateContext)
  const [isAdding, setIsAdding] = useState(false)
  return !isAdding ? (
    <ButtonBlock>
      <Button onClick={() => setIsAdding(true)}>{t('Add item')}</Button>
    </ButtonBlock>
  ) : (
    <FieldBlock>
      <SelectField
        options={OPTIONS}
        label={t('Choose a new item')}
        onSelect={value => {
          addItem(value)
          setIsAdding(false)
        }}
      />
    </FieldBlock>
  )
}

export default TemplateAddContent
