import {
  usePledgeCardGalleryActions,
  usePledgeCardGalleryState,
} from '../PledgeCardGalleryContext/PledgeCardGalleryContext'

const useComparePledge = () => {
  const { packetScans, firstScan, secondScan } = usePledgeCardGalleryState()
  const { setFirstScan, setQueryParams } = usePledgeCardGalleryActions()
  const firstScanIndex = packetScans.findIndex(scan => scan.id === firstScan.id)
  const secondScanIndex = packetScans.findIndex(
    scan => scan.id === secondScan.id
  )
  const pagedScans = []
  const filteredScans = packetScans.filter(
    ({ id }) => id !== firstScan.id && id !== secondScan.id
  )
  for (let i = 0; i < filteredScans.length; i += 3) {
    pagedScans.push(filteredScans.slice(i, i + 3))
  }

  const changeIndex = (currentIndex, change) => {
    const newIndex =
      (packetScans.length + currentIndex + change) % packetScans.length
    if (newIndex === secondScanIndex) return changeIndex(newIndex, change)
    return newIndex
  }

  const incrementScan = () => {
    const newScanIndex = changeIndex(firstScanIndex, 1)
    const newScan = packetScans[newScanIndex]
    setFirstScan(newScan)
    setQueryParams({
      firstScanId: newScan.id,
    })
  }

  const decrementScan = () => {
    const newScanIndex = changeIndex(firstScanIndex, -1)
    const newScan = packetScans[newScanIndex]
    setFirstScan(newScan)
    setQueryParams({
      firstScanId: newScan.id,
    })
  }

  return { incrementScan, decrementScan, pagedScans }
}

export default useComparePledge
