import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextBlock, Font } from '@politechdev/blocks-design-system'
import DeliveryFilters from './DeliveryFilters'
import DeliverySummary from './DeliverySummary/DeliverySummary'
import FormViewer from './FormViewer/FormViewer'
import { useDeliveryActions, useDeliveryState } from './DeliveryContext'

const SelectionStep = () => {
  const { t } = useTranslation()
  const { currentDelivery } = useDeliveryState()
  const { makePacketsRequest } = useDeliveryActions()

  const hasCounty =
    !!currentDelivery &&
    (currentDelivery.form_filters_no_county ||
      currentDelivery.form_filter_counties.length > 0)

  useEffect(() => {
    if (currentDelivery?.id) {
      makePacketsRequest(currentDelivery.id)
    }
  }, [currentDelivery?.id])

  return (
    <>
      <TextBlock>
        <Font.Display variant="small" element="h2">
          {t('Select the forms you want to deliver')}
        </Font.Display>
      </TextBlock>
      <DeliveryFilters />
      <TextBlock>
        <DeliverySummary />
      </TextBlock>
      <FormViewer hasCounty={hasCounty} />
    </>
  )
}

export default SelectionStep
