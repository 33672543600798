import React from 'react'
import logo from 'assets/images/logo.svg'
import wrench from 'assets/images/wrench.svg'
import edgePieceLeft from 'assets/images/decorative/edge-piece--left.svg'
import edgePieceRight from 'assets/images/decorative/edge-piece--right.svg'
import {
  ContentBlock,
  Font,
  TextBlock,
} from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import styles from './GenericErrorPage.module.scss'

const GenericErrorPage = () => {
  const cx = classNames.bind(styles)

  return (
    <div className={styles.container}>
      <div className={styles['top-bar']}>
        <img className={styles.logo} src={logo} alt="logo" />
      </div>
      <ContentBlock>
        <Font.Display variant="large" className={styles.title}>
          An error occurred
        </Font.Display>
      </ContentBlock>
      <TextBlock>
        <Font.Copy className={styles.message}>
          The Blocks team has been notified of this error and will attempt to
          resolve any possible issues that may have caused it. Please try again
          later.
        </Font.Copy>
      </TextBlock>
      <img className={styles.wrench} src={wrench} alt="wrench" />
      <img
        className={cx('edge-piece', 'edge-piece--left')}
        src={edgePieceLeft}
        alt=""
      />
      <img
        className={cx('edge-piece', 'edge-piece--right')}
        src={edgePieceRight}
        alt=""
      />
    </div>
  )
}

export default GenericErrorPage
