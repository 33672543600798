export const buildForm = team => ({
  ...team,
  type: team.type,
})

export const buildRequest = formData => ({
  name: formData.name,
  leader_id: formData.leader?.id,
  organizer_id: formData.organizer?.id,
  organization_ids: (formData.organizations || []).map(
    organization => organization.id
  ),
  campaign_ids: (formData.campaigns || []).map(campaign => campaign.id),
  type: formData.type,
  active: !!formData.active,
})
