import {
  calculatePercent,
  combineName,
  summaryFunctions,
} from 'utils/reporting'

export default {
  id: 'phone_by_day',
  name: 'Phone Verification by Day',
  description:
    'This report provides information on staffer performance for each day they worked on phone QC. Staffers may have multiple days depending on the date range criteria. The report includes individual flags from phone QC by either raw totals or percentages.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1301693329',
  freezeLeftColumns: 2,
  columns: [
    {
      dataKey: 'qc_staffer',
      title: 'QC Staffer',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.first_name, rowData.last_name),
    },
    {
      dataKey: 'phone_qc_date',
      title: 'Phone QC Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'total_calls',
      title: 'Total Calls',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_called_qc',
      title: 'Total Forms Called',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'contacted_qc',
      title: 'Contacted (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'phone_needs_attention_qc',
      title: 'Phone Needs Attention (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'confirmed_registered_qc',
      title: 'Confirmed Registration on Phone (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'did_not_register_qc',
      title: 'Did Not Register (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_address_qc',
      title: 'Correct Address (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_address_qc',
      title: 'Incorrect Address (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_dob_qc',
      title: 'Correct DOB (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_dob_qc',
      title: 'Incorrect DOB (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'received_feedback_qc',
      title: 'Received Canvasser Feedback (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_home_qc',
      title: 'Not Home (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'disconnected_qc',
      title: 'Disconnected (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'call_back_qc',
      title: 'Call Back (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'language_barrier_qc',
      title: 'Language Barrier (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'refused_qc',
      title: 'Refused (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_number_qc',
      title: 'Wrong Number (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_voicemail_qc',
      title: 'Wrong Voicemail (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'voicemail_matches_applicant_qc',
      title: 'Voicemail Matches Applicant (QC)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'busy_qc',
      title: 'Busy (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'do_not_call_qc',
      title: 'Do Not Call (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'percent_contacted_qc',
      title: 'Percent Contacted (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.contacted_qc, rowData.total_scans_called_qc),
    },
    {
      dataKey: 'percent_phone_needs_attention_qc',
      title: 'Percent Phone Needs Attention (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.phone_needs_attention_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_confirmed_registered_qc',
      title: 'Percent Confirmed Registration on Phone (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.confirmed_registered_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_did_not_register_qc',
      title: 'Percent Did Not Register (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.did_not_register_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_correct_address_qc',
      title: 'Percent Correct Address (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.correct_address_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_incorrect_address_qc',
      title: 'Percent Incorrect Address (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_address_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_correct_dob_qc',
      title: 'Percent Correct DOB (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.correct_dob_qc, rowData.total_scans_called_qc),
    },
    {
      dataKey: 'percent_incorrect_dob_qc',
      title: 'Percent Incorrect DOB (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_dob_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_received_feedback_qc',
      title: 'Percent Received Canvasser Feedback (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.received_feedback_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_not_home_qc',
      title: 'Percent Not Home (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.not_home_qc, rowData.total_calls),
    },
    {
      dataKey: 'percent_disconnected_qc',
      title: 'Percent Disconnected (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.disconnected_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_call_back_qc',
      title: 'Percent Call Back (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.call_back_qc, rowData.total_calls),
    },
    {
      dataKey: 'percent_language_barrier_qc',
      title: 'Percent Language Barrier (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.language_barrier_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_refused_qc',
      title: 'Percent Refused (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.refused_qc, rowData.total_scans_called_qc),
    },
    {
      dataKey: 'percent_wrong_number_qc',
      title: 'Percent Wrong Number (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_number_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_wrong_voicemail_qc',
      title: 'Percent Wrong Voicemail (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.wrong_voicemail_qc, rowData.total_calls),
    },
    {
      dataKey: 'percent_voicemail_matches_applicant_qc',
      title: 'Percent Voicemail Matches Applicant (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.voicemail_matches_applicant_qc,
          rowData.total_scans_called_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'voicemail_matches_applicant_qc',
        'total_scans_called_qc'
      ),
    },
    {
      dataKey: 'percent_busy_qc',
      title: 'Percent Busy (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.busy_qc, rowData.total_calls),
    },
    {
      dataKey: 'percent_do_not_call_qc',
      title: 'Percent Do Not Call (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.do_not_call_qc, rowData.total_scans_called_qc),
    },
    {
      dataKey: 'average_call_time',
      title: 'Average Call Time',
      type: 'number',
      summaryFunction: summaryFunctions.avg,
      resizable: true,
    },
  ],
}
