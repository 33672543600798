import PledgeCardPdfViewer from 'qualityControl/pledgeCardGallery/PledgeCardPdfViewer/PledgeCardPdfViewer'
import styles from './ScanContainer.module.scss'

const ScanContainer = ({ scanUrl, fileLocator, children }) => (
  <div className={styles.card}>
    <div className={styles.header}>{children}</div>
    <PledgeCardPdfViewer
      scanUrl={scanUrl}
      removedAt={fileLocator?.redacted_at}
    />
  </div>
)

export default ScanContainer
