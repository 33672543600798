import { useTranslation } from 'react-i18next'
import { IntercomAPI } from 'react-intercom'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'

const IntercomChat = ({ className, otherActions }) => {
  const handleShow = e => {
    e.preventDefault()
    IntercomAPI('show')
  }

  const { t } = useTranslation()

  return (
    <ButtonBlock justify="right" className={className}>
      <Button onClick={handleShow}>{t('Chat With Support')}</Button>
      {otherActions}
    </ButtonBlock>
  )
}

export default IntercomChat
