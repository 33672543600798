import { DOCUMENT_TYPES, INTERACTABLE_TYPES } from './constants'

export const getDocumentType = mimeType => {
  if (
    [
      'application/msword',
      'application/vnd.ms-word',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.wordprocessingml',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.template.macroEnabled.12',
    ].includes(mimeType)
  ) {
    return DOCUMENT_TYPES.WORD
  }

  if (
    [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.ms-powerpoint.addin.macroEnabled.12',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.ms-powerpoint.template.macroEnabled.12',
      'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    ].includes(mimeType)
  ) {
    return DOCUMENT_TYPES.POWERPOINT
  }

  if (
    [
      'application/vnd.ms-excel',
      'application/msexcel',
      'application/x-msexcel',
      'application/x-ms-excel',
      'application/x-excel',
      'application/x-dos_ms_excel',
      'application/xls',
      'application/x-xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml',
      'application/vnd.oasis.opendocument.spreadsheet',
    ].includes(mimeType)
  ) {
    return DOCUMENT_TYPES.EXCEL
  }

  if (
    ['text/html', 'application/json', 'application/ecmascript'].includes(
      mimeType
    )
  ) {
    return DOCUMENT_TYPES.CODE
  }

  if (['application/gzip', 'application/zip'].includes(mimeType)) {
    return DOCUMENT_TYPES.ARCHIVE
  }

  if (mimeType === 'application/pdf') return DOCUMENT_TYPES.PDF
  if (mimeType === 'text/plain') return DOCUMENT_TYPES.TEXT
  if (mimeType === 'text/csv') return DOCUMENT_TYPES.CSV

  if ((mimeType || '').includes('video')) return DOCUMENT_TYPES.VIDEO
  if ((mimeType || '').includes('image')) return DOCUMENT_TYPES.IMAGE
  if ((mimeType || '').includes('audio')) return DOCUMENT_TYPES.AUDIO

  return DOCUMENT_TYPES.OTHER
}

export const isItemDocument = (item = {}) =>
  [
    INTERACTABLE_TYPES.PANEL_DOCUMENT,
    INTERACTABLE_TYPES.SEARCH_DOCUMENT,
  ].includes(item?.type)

export const isItemFolder = (item = {}) =>
  [
    INTERACTABLE_TYPES.SIDEBAR_FOLDER,
    INTERACTABLE_TYPES.PANEL_FOLDER,
    INTERACTABLE_TYPES.SEARCH_FOLDER,
  ].includes(item?.type)

export const isItemSearch = (item = {}) =>
  [
    INTERACTABLE_TYPES.SEARCH_DOCUMENT,
    INTERACTABLE_TYPES.SEARCH_FOLDER,
  ].includes(item?.type)

export const isItemUpload = (item = {}) => !!item?.files

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 B'

  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const index = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** index).toFixed(decimals))} ${sizes[index]}`
}
