import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, ButtonBlock, Font } from '@politechdev/blocks-design-system'
import { submitSessionSurvey } from 'requests/phoneBankSessions'
import { useReactRouter } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import { QueryParamProvider, useCurrent, useQueryParams } from 'contexts/index'
import { PhoneBankContext } from 'phone_banks/PhoneBankContext/PhoneBankContext'
import VolunteerProfile from '../VolunteerProfile'
import SessionSidebar from '../SessionSidebar'
import SessionMainPanel from '../SessionMainPanel'
import CallMenu from '../../CallMenu/CallMenu'
import styles from './SessionEnd.module.scss'

const ReasonText = ({ reason }) => {
  const { t } = useTranslation()
  if (reason === 'operator_ended_session') {
    return (
      <Font.Copy variant="highlight">
        {t('The operator has ended the session')}
      </Font.Copy>
    )
  }
  return null
}

const SessionEnd = () => {
  const [surveySubmitted, setSurveySubmitted] = useState(false)
  const { match } = useReactRouter()
  const { id: sessionId, phoneBankId } = match.params
  const { currentUser: user } = useCurrent()
  const { phoneBank: currentPhoneBank, getPhoneBank } =
    useContext(PhoneBankContext)
  const { t } = useTranslation()

  const { queryParams } = useQueryParams()
  const { reason } = queryParams || {}

  useEffect(() => {
    getPhoneBank(phoneBankId)
  }, [])

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest } = useRequest(submitSessionSurvey, {
    onSuccess: () => {
      setSurveySubmitted(true)
    },
  })

  const handleSurveySubmission = response => {
    makeRequest(phoneBankId, sessionId, response)
  }

  return (
    <div className="pb-session">
      <SessionSidebar>
        <VolunteerProfile volunteer={user} />
        <CallMenu callType={currentPhoneBank?.call_type} />
      </SessionSidebar>
      <SessionMainPanel layout="end">
        <div className={styles.panel}>
          <Font.Display>{t('Thank you for volunteering')}</Font.Display>
          <ReasonText reason={reason} />
          <Font.Copy>
            {t(
              'The calls you made are going to help us in our common cause and we are grateful for the time you have dedicated today.'
            )}
          </Font.Copy>
          <Font.Display variant="small" Element="h3">
            {t('How was your experience?')}
          </Font.Display>
          {!surveySubmitted ? (
            <ButtonBlock className={styles.experience}>
              <button type="button" onClick={() => handleSurveySubmission(3)}>
                <Icon.Smile alt={t('good')} />
              </button>
              <button type="button" onClick={() => handleSurveySubmission(2)}>
                <Icon.Meh alt={t('neutral')} />
              </button>
              <button type="button" onClick={() => handleSurveySubmission(1)}>
                <Icon.Frown alt={t('bad')} />
              </button>
            </ButtonBlock>
          ) : (
            <Font.Copy>{t('Thank you for your feedback!')}</Font.Copy>
          )}
        </div>
      </SessionMainPanel>
    </div>
  )
}

export default () => (
  <QueryParamProvider>
    <SessionEnd />
  </QueryParamProvider>
)
