import qs from 'qs'

const PROXY_URL = `//${process.env.API_HOSTNAME}/system/proxy/smarty`

const fetchOpts = {
  credentials: 'same-origin',
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'text/plain',
    authorization: `Basic ${process.env.SYSTEM_AUTH_TOKEN}`,
  },
}

export const fetchAddressOptions = ({ label, fields, match = 'strict' }) =>
  new Promise((resolve, reject) => {
    fetch(PROXY_URL, {
      ...fetchOpts,
      body: `street-address?match=${match}&${qs.stringify(fields)}`,
    })
      .then(
        res =>
          res.ok
            ? res.json()
            : reject({ message: 'Fetch address error', label }) // eslint-disable-line prefer-promise-reject-errors
      )
      .then(json => resolve({ label, candidates: json }))
  })

export const fetchAddressSuggestions = ({ label, fields }) =>
  new Promise((resolve, reject) => {
    fetch(PROXY_URL, {
      ...fetchOpts,
      body: `lookup?${qs.stringify(fields)}`,
    })
      .then(
        res =>
          res.ok
            ? res.json()
            : reject({ message: 'Fetch address error', label }) // eslint-disable-line prefer-promise-reject-errors
      )
      .then(json => resolve(json.suggestions))
  })
