/* eslint-disable react/no-danger */
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TextField, Button, ButtonBlock, FieldBlock } from 'components'
import { useAuth } from 'contexts'
import { useRequest } from 'hooks'
import {
  sendCode as sendCodeRequest,
  verifyCode as verifyCodeRequest,
} from 'requests/auth'
import AuthFrame from '../AuthFrame/AuthFrame'
import styles from './TwoFactor.module.scss'

const TwoFactor = () => {
  const { t } = useTranslation()

  const { login, logout, twoFactorPhone, validToken } = useAuth()
  const [code, setCode] = useState('')
  const [verifyError, setVerifyError] = useState('')

  const {
    makeRequest: resendCode,
    isLoading: resendLoading,
    hasErrors: resendError,
  } = useRequest(sendCodeRequest)

  const { makeRequest: verifyCode, isLoading: verifyLoading } = useRequest(
    verifyCodeRequest,
    {
      onSuccess: response => login(response.token),
      onError: error => {
        if (error.status === 403) {
          return setVerifyError(t('Invalid or expired two-factor code'))
        }
        return setVerifyError(
          t('We had trouble verifying your code. Please try again later.')
        )
      },
    }
  )

  const handleSubmit = e => {
    e.preventDefault()
    verifyCode(code)
  }

  if (!validToken) return <Redirect to="/auth/login" />

  return (
    <AuthFrame>
      <AuthFrame.Title>{t('Two factor authentication')}</AuthFrame.Title>
      <AuthFrame.Content>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              `We've sent a two-factor login code to your phone number ending in {{twoFactorPhone}}. Please submit that code here to complete your login.`,
              {
                twoFactorPhone: `<strong>${twoFactorPhone}</strong>`,
                interpolation: { escapeValue: false },
              }
            ),
          }}
        />
      </AuthFrame.Content>
      {verifyError && <AuthFrame.Error>{verifyError}</AuthFrame.Error>}
      {resendError && (
        <AuthFrame.Error>
          {t(
            'We had trouble resending your two-factor code. Please try again later.'
          )}
        </AuthFrame.Error>
      )}
      <form onSubmit={handleSubmit}>
        <FieldBlock>
          <TextField
            id="code"
            label={t('Two-factor code')}
            value={code}
            onChange={val => setCode(val.replace(/\D/g, ''))}
            disabled={verifyLoading || resendLoading}
          />
          <Button flat primary onClick={resendCode} className={styles.button}>
            {resendLoading ? t('Resending code') : t('Resend code')}
          </Button>
        </FieldBlock>
        <AuthFrame.Actions>
          <ButtonBlock justify="spread">
            <Button raised primary type="submit">
              {verifyLoading ? t('Verifying code') : t('Verify code')}
            </Button>
            <Button flat primary onClick={logout}>
              {t('Sign out')}
            </Button>
          </ButtonBlock>
        </AuthFrame.Actions>
      </form>
    </AuthFrame>
  )
}

export default TwoFactor
