import { Filter } from 'qualityControl/inbox/Filter'
import { useTranslation } from 'react-i18next'
import menuItems from 'qualityControl/inbox/menuItems'
import { useContext } from 'react'
import { QCPacketFilterContext } from './QCPacketFilterContext/QCPacketFilterContext'
import { useInbox } from './InboxContext'

export const UnitedStatesFilter = () => {
  const { t } = useTranslation()
  const { isPendingPacketsEmpty: isDisabled } = useInbox()
  const { selectedState, setSelectedState } = useContext(QCPacketFilterContext)

  return (
    <Filter
      id="united_states_filter"
      label={t('State')}
      value={selectedState}
      onChange={setSelectedState}
      menuItems={menuItems.unitedStates}
      isDisabled={isDisabled}
    />
  )
}

export default UnitedStatesFilter
