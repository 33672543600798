import { useContext, useEffect, useState } from 'react'
import { List, LoadBar, CardError, SelectPanel } from 'components'
import { Card } from 'react-md'
import { defaultLanguages } from 'constants/qcCallScriptsConfig/defaultScripts'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { defaultTo } from 'lodash'
import TurfScriptListItem from './TurfScriptListItem'
import { CallScriptsContext } from './CallScriptsContext'

const TurfScriptList = ({ turfId, turfName }) => {
  const { t } = useTranslation()
  const { scriptsByTurf, fetchScriptsByTurf } = useContext(CallScriptsContext)
  const turfScripts = defaultTo(scriptsByTurf[turfId], [])
  const [isExpanded, setIsExpanded] = useState(false)

  const {
    makeRequest: fetchScripts,
    isLoading,
    hasErrors,
    isRequestComplete,
  } = useRequest(fetchScriptsByTurf)

  useEffect(() => {
    if (isExpanded && !isRequestComplete) {
      fetchScripts(turfId)
    }
  }, [isExpanded, isRequestComplete])

  return (
    <SelectPanel title={turfName} defaultClosed onToggle={setIsExpanded}>
      <Card>
        <List>
          <LoadBar show={isLoading} />
          <CardError
            hide={!hasErrors}
            message={t('Failed to get turf scripts')}
            hideSupportLink
          />
          {Object.values(defaultLanguages).map(language => {
            const customScript = turfScripts.find(
              script => defaultLanguages[script.language] === language
            )
            return (
              <TurfScriptListItem
                key={turfName + language}
                turfId={turfId}
                turfName={turfName}
                language={language}
                scriptId={customScript ? customScript.id : 'new'}
              />
            )
          })}
        </List>
      </Card>
    </SelectPanel>
  )
}

export default TurfScriptList
