import { useTranslation } from 'react-i18next'

export const StaffLanguages = ({ languages }) =>
  languages?.length ? (
    <div className="staff-languages">
      {languages.map((language, i) => (
        <div className="detail-badge" key={i}>
          {language.toUpperCase()}
        </div>
      ))}
    </div>
  ) : null

export const StaffLanguagesVerbose = ({ languages }) => {
  const { t } = useTranslation()
  return languages?.length ? (
    <div className="staff-languages">
      {languages.map((language, i) => (
        <div key={i}>
          <div className="detail-badge">{language.toUpperCase()}</div>
          {language === 'en' ? t('Speaks English') : t('Speaks Spanish')}
        </div>
      ))}
    </div>
  ) : null
}
