import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, LoadBar } from 'components'
import { useRoutePathParams } from 'hooks'
import { useCurrent } from 'contexts/index'
import { PhoneBankContext } from 'phone_banks/PhoneBankContext/PhoneBankContext'
import { isPhoneBankOpen } from 'phone_banks/utils'
import IntroText from './IntroText'
import SessionMainPanel from '../SessionMainPanel'
import VolunteerProfile from '../VolunteerProfile'
import CallMenu from '../../CallMenu/CallMenu'

const SessionNew = () => {
  const { t } = useTranslation()
  const [{ phoneBankId }] = useRoutePathParams()

  const { currentUser } = useCurrent()

  const {
    phoneBank: currentPhoneBank,
    getPhoneBank,
    phoneBankReqLoading,
    phoneBankReqHasErrors,
    phoneBankReqLoaded,
  } = useContext(PhoneBankContext)

  useEffect(() => {
    getPhoneBank(phoneBankId)
  }, [])

  return (
    <div className="pb-session">
      <LoadBar show={phoneBankReqLoading} />
      <CardError
        hide={!phoneBankReqHasErrors}
        message={t('There was an error loading this phone bank.')}
      />
      <div className="pb-session__summary">
        <VolunteerProfile volunteer={currentUser} />
        <CallMenu callType={currentPhoneBank?.call_type} />
        {phoneBankReqLoaded && !isPhoneBankOpen(currentPhoneBank) && (
          <p className="space">{t('This phone bank is closed.')}</p>
        )}
      </div>
      <SessionMainPanel layout="intro">
        <IntroText />
      </SessionMainPanel>
    </div>
  )
}

export default SessionNew
