import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { Button, ButtonBlock, CardError, TextBlock } from 'components'
import { Modal, useToast } from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { putDiscardAllImportErrors } from 'requests/duplicatePrevention'
import { RecordsContext } from '../RecordsContext/RecordsContext'

const RecordsDiscardAllModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const { setToast } = useToast()
  const { count } = useContext(RecordsContext)
  const onSuccess = () => {
    setToast({
      message: t('Success'),
      variant: 'success',
    })
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      history.push(
        `/data_sources/imports/${match.params.importId}/duplicate_prevention/done`
      )
    }, 1500)
  }

  const discardImportErrorsRequest = useRequest(putDiscardAllImportErrors, {
    onSuccess,
  })

  return (
    <Modal
      id="merge-error-record-dialog"
      title={t('Confirm discard')}
      showLoading={discardImportErrorsRequest.isLoading}
      isOpen={isOpen}
    >
      <Modal.Body>
        <TextBlock>
          {`${t('You are about to discard')} ${count} ${t(
            'import records that have errors without seeing them.'
          )}`}
        </TextBlock>
        <CardError
          hide={!discardImportErrorsRequest.hasErrors}
          message={t('An error occurred while merging this record.')}
        />
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button flat onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={discardImportErrorsRequest.isLoading}
            onClick={() => {
              discardImportErrorsRequest.makeRequest(match.params.importId)
            }}
            raised
            warn
          >
            {t('Confirm discard')}
          </Button>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default RecordsDiscardAllModal
