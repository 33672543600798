import { CardError, List, ListItem } from 'components'
import {
  Section,
  ProgressBar,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { useRequest } from 'hooks'
import {
  getFlagItemPrimaryText,
  getFlagItemPrimaryIcon,
  getFlagItemSecondaryText,
  doesFlagHaveUnreadComments,
} from './utils'
import { ListPager } from './ListPager'
import ViewFlagButton from './buttons/ViewFlagButton'
import { useFlagsFilteredListContext } from './contexts/FlagsFilteredListContext'

const ResolvedList = ({ setListPage }) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrent()
  const { filters, listPages, fetchResolvedFlags } =
    useFlagsFilteredListContext()
  const currentPage = listPages.resolved

  const fetchResolvedFlagsRequest = useRequest(fetchResolvedFlags)

  const flagsErrorMsg = formatErrorMessage(fetchResolvedFlagsRequest.errors)

  useEffect(() => {
    fetchResolvedFlagsRequest.makeRequest(filters, currentPage)
  }, [currentPage, filters, currentUser.id])

  const flags =
    fetchResolvedFlagsRequest.response?.['quality_control/flags'] || []

  return (
    <>
      <ProgressBar show={fetchResolvedFlagsRequest.isLoading} />
      <CardError
        hide={!fetchResolvedFlagsRequest.hasErrors}
        message={flagsErrorMsg}
      />
      {fetchResolvedFlagsRequest.isRequestComplete && !!flags.length && (
        <>
          <List>
            {flags.map(flag => (
              <ListItem
                key={`resolved-flag-list-item-${flag.id}`}
                primaryText={getFlagItemPrimaryText(flag)}
                primaryIcon={getFlagItemPrimaryIcon(flag)}
                secondaryText={getFlagItemSecondaryText(flag, currentUser)}
                showAlertIcon={doesFlagHaveUnreadComments(flag, currentUser)}
                actions={() => (
                  <ViewFlagButton typeOverride="reopen" flagId={flag.id} />
                )}
              />
            ))}
          </List>
          <ListPager
            currentPage={currentPage}
            setPage={setListPage}
            totalPages={0}
          />
        </>
      )}
      {fetchResolvedFlagsRequest.isRequestComplete && !flags.length && (
        <Section secondary collapsed>
          <TextBlock>{t('There are no resolved flags')}</TextBlock>
        </Section>
      )}
    </>
  )
}

export default ResolvedList
