import { useState } from 'react'
import { Button } from '@politechdev/blocks-design-system'

const url =
  'https://cdn.pixabay.com/download/audio/2022/01/20/audio_a6ba8658c6.mp3?filename=barking-dogs-15232.mp3'
const audio = new Audio(url)

const TestAudio = ({ onClick }) => {
  const [playing, setPlaying] = useState(false)

  const togglePlay = () => {
    if (playing) {
      audio.pause()
      setPlaying(false)
      if (typeof onClick === 'function') {
        onClick()
      }
    } else {
      audio.play()
      setPlaying(true)
    }
  }

  return (
    <div>
      <Button.Secondary onClick={togglePlay}>
        {playing ? 'Stop Audio' : 'Test Audio'}
      </Button.Secondary>
    </div>
  )
}

export default TestAudio
