import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNumber } from 'lodash'
import { CardError, TurfSelectField } from 'components'
import {
  ProgressBar,
  Section,
  TextField,
  FieldBlock,
  ButtonBlock,
  Button,
  TextBlock,
  SelectField,
  TimePicker,
  DateField,
  Icon,
} from '@politechdev/blocks-design-system'
import { useCurrent, useForm, useTurfs } from 'contexts'
import { useReactRouter, useRequest, useRouteQueryParams } from 'hooks'
import { fetchList, fetchLists } from 'requests/lists'
import { phoneBankTypes } from 'phone_banks/constants'
import { createPhoneBank, putPhoneBank } from 'requests/phoneBanks'
import { useDebounce } from 'use-debounce'
import { TimeZoneSelectField } from 'components/TimeZoneSelectField/TimeZoneSelectField'
import { PhoneBankListMetaData } from '../PhoneBankListMetaData'
import { buildRequest, buildForm } from './utils'
import styles from './PhoneBankForm.module.scss'
import PhoneBankTypeSelect, { options } from './PhoneBankTypeSelect'

const PhoneBankForm = ({ phoneBank }) => {
  const isEditForm = !!phoneBank
  const formAction = isEditForm
    ? data => putPhoneBank(phoneBank.id, data)
    : createPhoneBank
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [queryParams] = useRouteQueryParams()

  const [callListInput, setCallListInput] = useState('')
  const [debouncedCallListInput] = useDebounce(callListInput, 300)

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
      time_zone: defaultTimeZone,
    },
  } = useCurrent()

  const { refreshCurrentTurfs } = useTurfs()

  const { formData, setField, setFormData } = useForm()
  const { makeRequest, isLoading, hasErrors } = useRequest(formAction, {
    onSuccess: response => {
      history.push(`/organize/phone_banks/${response.phone_bank.id}`)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: requestLists,
    isLoading: listsLoading,
    response: listsResponse,
  } = useRequest(fetchLists)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: fetchListReq, response: selectedListResponse } =
    useRequest(fetchList)

  const createListOptions = (listsResponse, selectedListResponse) => {
    const lists = listsResponse?.lists || []
    const selectedList = selectedListResponse?.list

    const appendage =
      selectedList && !lists.includes(list => list.id === selectedList.id)
        ? [selectedList]
        : []

    return [...lists, ...appendage].map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }

  const listOptions = createListOptions(listsResponse, selectedListResponse)

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  useEffect(() => {
    const rules = debouncedCallListInput
      ? [
          { column: 'phones_count', operator: 'more_than', param: 0 },
          {
            column: 'name',
            operator: 'containing',
            param: debouncedCallListInput,
          },
        ]
      : [{ column: 'phones_count', operator: 'more_than', param: 0 }]
    requestLists({
      fields: ['id', 'name'],
      filters: {
        rules,
      },
    })
  }, [debouncedCallListInput])

  useEffect(() => {
    if (!isEditForm && queryParams.list_id) {
      setFormData(
        buildForm({
          list_id: queryParams.list_id,
          defaultTimeZone,
          defaultTurfId: topLevelTurfId,
          callType: phoneBankTypes.MANUAL,
        })
      )
    } else {
      setFormData(
        buildForm({
          phoneBank,
          defaultTimeZone,
          defaultTurfId: topLevelTurfId,
          callType: phoneBankTypes.MANUAL,
        })
      )
    }
  }, [])

  useEffect(() => {
    if (+formData.list_id) {
      fetchListReq(formData.list_id, { fields: ['id', 'name'] })
    }
  }, [formData.list_id])

  useEffect(() => {
    setField(selectedListResponse?.list, 'list')
  }, [selectedListResponse])

  const isFormValid = () =>
    !isEmpty(formData.name) &&
    isNumber(formData.list_id) &&
    !isEmpty(formData.call_type)

  const submitForm = e => {
    e.preventDefault()
    makeRequest(buildRequest(formData))
  }

  const routeToListImport = () => {
    history.push(`/data_sources/imports/new`)
  }

  return (
    <>
      <ProgressBar show={isLoading} />
      <CardError
        hide={!hasErrors}
        message={t(
          `A problem occurred ${
            isEditForm ? 'updating' : 'creating'
          } your phone bank`
        )}
      />
      <form onSubmit={submitForm}>
        <Section>
          <FieldBlock>
            <TextField
              id="name"
              label={t('Phone bank name')}
              value={formData.name}
              onChange={val => setField(val, 'name')}
              required
            />
          </FieldBlock>
          <FieldBlock>
            <DateField
              label={t('Start date')}
              value={formData.start_date}
              onChange={val => setField(val, 'start_date')}
              required
            />
            <DateField
              label={t('End date')}
              value={formData.end_date}
              onChange={val => setField(val, 'end_date')}
              required
            />
          </FieldBlock>
          <FieldBlock>
            <TimePicker
              label={t('Daily open time')}
              onSelect={val => {
                setField(val.hour, 'openHour')
                setField(val.minute, 'openMinute')
              }}
              hour={formData.openHour}
              minute={formData.openMinute}
              required
            />
          </FieldBlock>
          <FieldBlock>
            <TimePicker
              label={t('Daily close time')}
              onSelect={val => {
                setField(val.hour, 'closeHour')
                setField(val.minute, 'closeMinute')
              }}
              hour={formData.closeHour}
              minute={formData.closeMinute}
              required
            />
          </FieldBlock>
          <FieldBlock>
            <TimeZoneSelectField
              value={formData.time_zone}
              onSelect={val => {
                setField(val, 'time_zone')
              }}
              required
            />
          </FieldBlock>
          {!isEditForm && (
            <FieldBlock>
              <TurfSelectField
                label={t('Turf')}
                value={formData.turf_id || formData.defaultTurfId}
                onSelect={val => setField(val, 'turf_id')}
                isExpanded
                showArchived={isEditForm}
                disableArchived
              />
            </FieldBlock>
          )}
          <FieldBlock variant="large">
            <TextField
              id="desc"
              label={t('Description')}
              value={formData.description}
              onChange={val => setField(val, 'description')}
            />
          </FieldBlock>
        </Section>
        <Section label={t('Call list')}>
          <FieldBlock className={styles['call-list']}>
            <SelectField
              id="call-list"
              label={t('Call list')}
              options={listOptions}
              itemLabel="name"
              itemValue="id"
              value={formData.list_id}
              onInputChange={val => {
                if (val) {
                  setCallListInput(val)
                } else if (callListInput !== '') {
                  setCallListInput('')
                }
              }}
              onSelect={val => {
                setField(val, 'list_id')
                setField(undefined, 'list')
              }}
              loading={listsLoading}
              required
            />
            <Button.Secondary onClick={routeToListImport}>
              <span>{t('Import new call list')}</span>
              <Icon.ArrowUpRightFromSquare />
            </Button.Secondary>
          </FieldBlock>
          {formData.list && formData.list.folder && (
            <PhoneBankListMetaData list={formData.list} />
          )}
          {isEditForm ? (
            phoneBank.call_type && (
              <Section label={t('Call session type')}>
                <TextBlock>
                  <p>
                    {options.find(el => el.id === phoneBank.call_type).label}
                  </p>
                </TextBlock>
              </Section>
            )
          ) : (
            <PhoneBankTypeSelect
              value={formData.call_type}
              onChange={val => setField(val, 'call_type')}
            />
          )}
        </Section>
        <Section>
          <ButtonBlock justify="left">
            <Button.Accent type="submit" disabled={!isFormValid() || isLoading}>
              {t(isEditForm ? 'Save phone bank' : 'Create phone bank')}
            </Button.Accent>
          </ButtonBlock>
        </Section>
      </form>
    </>
  )
}

export default PhoneBankForm
