import React, { useState, useCallback, useRef, useEffect } from 'react'
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  ContentState,
  CompositeDecorator,
} from 'draft-js'
import { TextField } from 'components'
import {
  Button,
  TextBlock,
  ButtonBlock,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import {
  Bold,
  UnorderedList,
  OrderedList,
  Italic,
  Link,
} from 'components/uiKit/icons'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import styles from './TemplateRichTextEditor.module.scss'
import { TEXT_TYPES } from './constants'

const cx = classNames.bind(styles)

const RteLink = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()
  return <a href={url}>{children}</a>
}

const TemplateRichTextEditor = ({ value, setValue }) => {
  const { t } = useTranslation()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const [showLinkInput, setShowLinkInput] = useState(false)
  const [url, setUrl] = useState('')

  const editor = useRef(null)

  useEffect(() => {
    const linkDecorator = new CompositeDecorator([
      {
        strategy: (contentBlock, callback, contentState) => {
          contentBlock.findEntityRanges(character => {
            const entityKey = character.getEntity()
            return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === TEXT_TYPES.LINK
            )
          }, callback)
        },
        component: RteLink,
      },
    ])
    typeof value === 'string'
      ? setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(value),
            linkDecorator
          )
        )
      : setEditorState(
          EditorState.createWithContent(convertFromRaw(value), linkDecorator)
        )
  }, [])

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    setValue(rawContentState)
  }, [editorState])

  const initializeUrlFromLink = () => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        setUrl(linkInstance.getData().url)
      } else {
        setUrl('')
      }
    }
  }

  function saveLink() {
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      TEXT_TYPES.LINK,
      'MUTABLE',
      { url }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    })
    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    )
  }

  const removeLink = () => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null))
    }
  }

  const handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
    }
  })

  const toggle = (e, type) => {
    e.preventDefault()
    const applyType = {
      [TEXT_TYPES.BOLD]: () =>
        setEditorState(
          RichUtils.toggleInlineStyle(editorState, TEXT_TYPES.BOLD)
        ),
      [TEXT_TYPES.ITALIC]: () =>
        setEditorState(
          RichUtils.toggleInlineStyle(editorState, TEXT_TYPES.ITALIC)
        ),
      [TEXT_TYPES.UL]: () =>
        setEditorState(RichUtils.toggleBlockType(editorState, TEXT_TYPES.UL)),
      [TEXT_TYPES.OL]: () =>
        setEditorState(RichUtils.toggleBlockType(editorState, TEXT_TYPES.OL)),
    }
    applyType[type]()
  }

  return (
    <div className={styles.background}>
      <TextBlock className={styles.label}>
        <span>{t('Body copy')}</span>
      </TextBlock>
      <div className={styles.editor}>
        <div className={styles.editor__buttons}>
          <div
            className={cx('editor__button', {
              'editor__button--active': editorState
                .getCurrentInlineStyle()
                .has(TEXT_TYPES.BOLD),
            })}
            onMouseDown={e => toggle(e, TEXT_TYPES.BOLD)}
          >
            <Bold />
          </div>
          <div
            className={cx('editor__button', {
              'editor__button--active': editorState
                .getCurrentInlineStyle()
                .has(TEXT_TYPES.ITALIC),
            })}
            onMouseDown={e => toggle(e, TEXT_TYPES.ITALIC)}
          >
            <Italic />
          </div>
          <div
            className={styles.editor__button}
            onMouseDown={e => {
              e.preventDefault()
              setShowLinkInput(true)
              initializeUrlFromLink()
            }}
          >
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <Link />
          </div>
          <div
            className={cx('editor__button', {
              'editor__button--active':
                editorState.getCurrentContent().getLastBlock().getType() ===
                TEXT_TYPES.UL,
            })}
            onMouseDown={e => toggle(e, TEXT_TYPES.UL)}
          >
            <UnorderedList />
          </div>
          <div
            className={cx('editor__button', {
              'editor__button--active':
                editorState.getCurrentContent().getLastBlock().getType() ===
                TEXT_TYPES.OL,
            })}
            onMouseDown={e => toggle(e, TEXT_TYPES.OL)}
          >
            <OrderedList />
          </div>
        </div>
        {showLinkInput && (
          <div className={styles.editor__link}>
            <FieldBlock className={styles['editor__link__field-block']}>
              <TextField
                onChange={val => {
                  const isValid = url.includes('http') || url.includes('https')
                  setUrl(isValid ? val : `http://${url}`)
                }}
                type="text"
                label="url"
                value={url}
                onKeyDown={e => {
                  if (e.which === 13) {
                    saveLink()
                    setShowLinkInput(false)
                    setUrl('')
                  }
                }}
                onMouseDown={e => e.preventDefault()}
              />
            </FieldBlock>
            <ButtonBlock className={styles['editor__link__button-block']}>
              <Button
                onMouseDown={e => {
                  e.preventDefault()
                  saveLink()
                  setShowLinkInput(false)
                  setUrl('')
                }}
              >
                {t('Link')}
              </Button>
              <Button
                onMouseDown={e => {
                  e.preventDefault()
                  removeLink()
                  setShowLinkInput(false)
                }}
              >
                {t('Unlink')}
              </Button>
            </ButtonBlock>
          </div>
        )}
        <div
          onMouseDown={() => setShowLinkInput(false)}
          className={styles['editor__text-field']}
        >
          <Editor
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onChange={setEditorState}
            ref={editor}
          />
        </div>
      </div>
    </div>
  )
}

export default TemplateRichTextEditor
