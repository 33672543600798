import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { createProjectionWeeks } from 'utils/fieldManagement'
import { ProjectionContext } from '../ProjectionContext/ProjectionContext'

const ProjectionDateWarningModal = ({
  isOpen,
  setIsOpen,
  originalStartDate,
  newStartDate,
  originalEndDate,
  newEndDate,
  setDirty,
}) => {
  const { t } = useTranslation()
  const {
    setTotalStartDate,
    setTotalEndDate,
    setWeeklyActualData,
    setWeeklyProjectedData,
  } = useContext(ProjectionContext)

  const onApply = () => {
    setTotalStartDate(newStartDate)
    setTotalEndDate(newEndDate)
    setWeeklyActualData([])
    setWeeklyProjectedData(
      createProjectionWeeks({
        startDate: newStartDate,
        endDate: newEndDate,
      })
    )
    setDirty(true)
    setIsOpen(false)
  }

  const onCancel = () => {
    setTotalStartDate(originalStartDate)
    setTotalEndDate(originalEndDate)
    setIsOpen(false)
  }

  return (
    <Modal
      id="warn-date-change"
      title={t('Are you sure you want to change projection dates?')}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <p>
              Changing projection dates will re-split the projection into weeks.
              You will lose any custom weekly projection data that you have
              entered and newly generated weeks will be set with default values.
            </p>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={onApply}>{t('Apply')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ProjectionDateWarningModal
