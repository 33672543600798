import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldBlock, TextField } from '@politechdev/blocks-design-system'
import { RoleModalFormContext } from './RoleModal'
import { useRoleModalState } from '../RoleModalStateProvider/RoleModalStateProvider'

const RoleModalUpdateContent = () => {
  const { t } = useTranslation()
  const { formData, setField } = useContext(RoleModalFormContext)
  const { actionLabel } = useRoleModalState()

  return (
    <FieldBlock>
      <TextField
        id={`roleName${actionLabel}`}
        label={t('Role name')}
        required
        value={formData.name || ''}
        onChange={val => setField(val, 'name')}
      />
      <TextField
        id={`roleDescription${actionLabel}`}
        label={t('Role description')}
        required
        value={formData.description || ''}
        onChange={val => setField(val, 'description')}
      />
    </FieldBlock>
  )
}

export default RoleModalUpdateContent
