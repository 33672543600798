import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonBlock,
  ContentBlock,
  Button,
  Modal,
  TextField,
} from '@politechdev/blocks-design-system'
import useEvent from 'hooks/useEvent'

const CreateFilterModal = ({ close, save, isOpen }) => {
  const { t } = useTranslation()

  const [filterName, setFilterName] = useState('')

  const handleClose = () => {
    close()
    setFilterName('')
  }

  const handleSave = useEvent(() => {
    save(filterName)
    handleClose()
  })

  return (
    <Modal
      id="create-new-filter"
      title={t('Create new filter')}
      className="table-filters__modal"
      onHide={handleClose}
      isOpen={isOpen}
    >
      <Modal.Body>
        <ContentBlock>
          <TextField
            id="filter-name-input"
            label={t('Filter name')}
            value={filterName}
            onChange={setFilterName}
            required
            errorMessage={t('Saved filters must be named')}
          />
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button
            className="table-filters__modal__button"
            primary
            flat
            onClick={handleClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            className="table-filters__modal__button"
            primary
            raised
            disabled={filterName.length === 0}
            onClick={handleSave}
          >
            {t('Save')}
          </Button>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default CreateFilterModal
