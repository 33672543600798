import { useEffect, useState } from 'react'
import { ButtonBlock, ContentBlock } from 'components'
import { Modal } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import ModalScheduleForm from 'qualityControl/schedule/ModalScheduleForm'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { saveSchedules, fetchSchedulesOnDate } from 'requests/qualityControl'
import { useCurrent } from 'contexts/index'
import {
  addSchedule,
  getDisplayableSchedules,
  removeSchedule,
  updateSchedule,
} from 'qualityControl/schedule/utils'
import { currentDateEasternYMDDash } from 'utils/dateTime'
import ScheduleActions from './ScheduleActions'
import ModalReassignment from './ModalReassignment'
import ReassignActions from './ReassignActions'

const ReassignPacketsModal = ({ refreshAssignedPackets }) => {
  const { t } = useTranslation()
  const { currentTurfPerformsExternalQC } = useCurrent()

  const [updatedSchedules, setUpdatedSchedules] = useState([])
  const [existingSchedules, setExistingSchedules] = useState([])
  const [newSchedules, setNewSchedules] = useState([])

  const {
    makeRequest: fetchExistingSchedulesReq,
    isLoading: isFetchLoading,
    hasErrors: fetchHasErrors,
  } = useRequest(fetchSchedulesOnDate, {
    onSuccess: ({ schedules }) =>
      setExistingSchedules(
        schedules.filter(
          s =>
            !!s.turf.qc_config.performs_external_qc ===
            currentTurfPerformsExternalQC
        )
      ),
  })

  useEffect(() => {
    fetchExistingSchedulesReq(currentDateEasternYMDDash, {
      fields: [
        'id',
        'user_type',
        'minutes',
        'date',
        { user: ['id', 'full_name'] },
        { turf: ['qc_config'] },
      ],
    })
  }, [])

  const {
    isReassignmentModalOpen: isModalOpen,
    isReassignmentScheduleOpen: isScheduleOpen,
    setIsReassignmentModalOpen,
    setIsReassignmentScheduleOpen,
    packetsBeingAssigned,
  } = usePacketAssignment()

  const {
    makeRequest: saveScheduleReq,
    isLoading: isSaveLoading,
    hasErrors: saveHasErrors,
  } = useRequest(saveSchedules, {
    onSuccess: () => {
      setIsReassignmentScheduleOpen(false)
    },
  })

  useEffect(() => {
    setIsReassignmentScheduleOpen(false)
  }, [isModalOpen])

  const displayableSchedules = getDisplayableSchedules({
    existingSchedules,
    newSchedules,
    updatedSchedules,
  })

  const title =
    packetsBeingAssigned.length > 1 ? 'Reassign Packets' : 'Reassign Packet'

  if (!isModalOpen) return null

  return (
    <Modal
      id="reassign-packets-modal"
      className="quality-control__packet-management__modal"
      title={t(isScheduleOpen ? t('Staff Schedule') : title)}
      onHide={() => {
        setIsReassignmentModalOpen(false)
      }}
      isOpen={isModalOpen}
    >
      <Modal.Body>
        <ContentBlock>
          {isScheduleOpen && (
            <ModalScheduleForm
              existingSchedules={existingSchedules}
              displayableSchedules={displayableSchedules}
              updateSchedule={updateSchedule({
                setNewSchedules,
                setUpdatedSchedules,
              })}
              removeSchedule={removeSchedule({
                setNewSchedules,
                setUpdatedSchedules,
              })}
              addSchedule={addSchedule(setNewSchedules)}
              isSaveLoading={isSaveLoading}
              saveHasErrors={saveHasErrors}
              isFetchLoading={isFetchLoading}
              fetchHasErrors={fetchHasErrors}
            />
          )}
          {!isScheduleOpen && (
            <ModalReassignment
              refreshAssignedPackets={refreshAssignedPackets}
            />
          )}
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        {isScheduleOpen ? (
          <ScheduleActions
            saveSchedules={saveScheduleReq}
            updatedSchedules={updatedSchedules}
            newSchedules={newSchedules}
            clearScheduleChanges={() => {
              setNewSchedules([])
              setUpdatedSchedules([])
            }}
          />
        ) : (
          <ButtonBlock justify="right">
            <ReassignActions />
          </ButtonBlock>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default ReassignPacketsModal
