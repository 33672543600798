import { AutoTableProvider, useAutoTable } from 'contexts'
import { useRequest } from 'hooks/useRequest'
import { tableToApiParams } from 'utils/table'
import { fetchForms } from 'requests/registrationForms'
import { defaultTo } from 'lodash'
import { makeTableRows } from 'utils/registrationForms'
import tableConfig from './tableConfig'

const FormsTable = () => {
  const { renderTable } = useAutoTable()

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: getForms, response } = useRequest(fetchForms)

  const data = makeTableRows(defaultTo(response?.registration_forms, []))
  const totalRecords = defaultTo(response?.meta.total_count, 0)

  const reqForms = params =>
    getForms({
      ...tableToApiParams(params),
      fields: [
        'id',
        'display_name',
        'registration_date',
        { location: ['name'] },
        { packet: ['original_filename'] },
        { canvasser: ['full_name'] },
      ],
    })

  return renderTable({
    data,
    totalRecords,
    columns: tableConfig.columns,
    fetchAction: reqForms,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
  >
    <FormsTable />
  </AutoTableProvider>
)
