import { fetchCurrentTenant } from 'requests/current'
import { gql, createClient, dedupExchange, fetchExchange } from 'urql'
import { authExchange } from '@urql/exchange-auth'

const OSTRAKA_URL = 'https://ostraka-ykmw.onrender.com/api/v1'

const client = createClient({
  url: OSTRAKA_URL,
  exchanges: [
    dedupExchange,
    authExchange(async utils => {
      const fetchToken = async () =>
        fetchCurrentTenant(true).then(
          ({ 'accounts/tenant': tenant }) => tenant.options.ostraka_token
        )
      let token
      token = await fetchToken()

      return {
        addAuthToOperation: operation =>
          utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          }),
        didAuthError: error => error.response.status === 403,
        refreshAuth: async () => {
          token = await fetchToken()
        },
      }
    }),
    fetchExchange,
  ],
})

const fetchGraphql = query => params => client.query(query, params).toPromise()

const voterMatchQuery = gql`
  query (
    $city: String!
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $state: String!
    $streetAddress: String!
  ) {
    votersMatchConfidence(
      city: $city
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      state: $state
      streetAddress: $streetAddress
    ) {
      address
      city
      externalId
      firstName
      id
      lastName
      matchScore
      middleName
      state
      suffix
      voterfileSource
      zip
    }
  }
`

export const fetchVoterMatch = fetchGraphql(voterMatchQuery)
