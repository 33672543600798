import { useContext } from 'react'
import styles from './TemplateThemeItem.module.scss'
import { TemplateContext } from '../TemplateContext/TemplateContext'
import { THEME_OPTIONS } from '../TemplateStyle/constants'

const TemplateThemeItem = ({ theme, label }) => {
  const { themeName, updateTheme } = useContext(TemplateContext)
  return (
    <div className={styles.item}>
      <input
        id={label}
        type="radio"
        checked={label === themeName}
        className={styles.item__select}
        onChange={checked => {
          if (checked) {
            updateTheme(label, theme)
          }
        }}
      />
      {label !== THEME_OPTIONS.CUSTOM && (
        <button
          type="button"
          onClick={() => updateTheme(label, theme)}
          className={styles.item__palette}
        >
          {Object.values(theme).map((color, i) => (
            <div key={`${color}-${i}`} style={{ backgroundColor: color }} />
          ))}
        </button>
      )}
      <label htmlFor={label} className={styles.item__label}>
        {label}
      </label>
    </div>
  )
}

export default TemplateThemeItem
