export const defaultPhoneBankOptions = {
  fields: [
    'id',
    'name',
    'list_id',
    'daily_start_time',
    'daily_end_time',
    'start_date',
    'end_date',
    'time_zone',
    'description',
    'current_round',
    'min_call_delay_in_hours',
    'participated_calls_count',
    'calls_count',
    'calls_per_round',
    'call_type',
    'archived',
    { list: ['name'] },
  ],
}

export const detailedPhoneBankOptions = {
  fields: [
    ...defaultPhoneBankOptions.fields,
    { list: ['name', 'meta', { folder: ['name'] }] },
    {
      script: [
        'id',
        'cause_statement',
        'react_template_name',
        {
          script_objects: [
            'id',
            'position_in_script',
            'is_section_divider',
            {
              question: [
                'id',
                'created_at',
                'question_to_ask',
                'type',
                {
                  scripts: ['id'],
                },
                {
                  responses: ['id'],
                },
                {
                  answer_options: ['id', 'response'],
                },
                {
                  tags: ['name'],
                },
              ],
            },
            { script_text: ['id', 'text', 'type'] },
          ],
        },
      ],
    },
  ],
}

export const detailedScriptOptions = {
  fields: [
    'id',
    'cause_statement',
    'react_template_name',
    {
      script_objects: [
        'id',
        'position_in_script',
        'is_section_divider',
        {
          question: [
            'id',
            'created_at',
            'question_to_ask',
            'type',
            'tags',
            {
              scripts: ['id'],
            },
            {
              responses: ['id'],
            },
            {
              answer_options: ['id', 'response'],
            },
            {
              tags: ['name'],
            },
          ],
        },
        { script_text: ['id', 'text', 'type'] },
      ],
    },
  ],
  associations: [
    'script_objects',
    'script_objects.question',
    'script_objects.script_text',
    'script_objects.question',
    'script_objects.question.answer_options',
    'script_objects.question.responses',
    'script_objects.question.scripts',
    'script_objects.question.tags',
  ],
}

export const scriptQuestionParams = {
  fields: [
    'id',
    'created_at',
    'question_to_ask',
    'type',
    {
      scripts: ['id'],
    },
    {
      responses: ['id'],
    },
    {
      answer_options: ['id', 'response'],
    },
    {
      tags: ['name'],
    },
  ],
}
