import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, TooltipContainer } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import { RecordContext } from '../RecordContext/RecordContext'
import styles from './FieldIndicator.module.scss'

const InvalidIndicator = ({ section }) => {
  const { t } = useTranslation()
  const cx = classNames.bind(styles)
  const { invalidFields, errorRecord } = useContext(RecordContext)

  const isInvalidImportField = Object.keys(invalidFields).includes(section)

  const displayAddrField = key => {
    const addr = errorRecord.residential_address
    const invalidAddr = invalidFields.residential_address

    if (!invalidAddr[key]?.value && !addr[key]) return null

    return (
      <span
        className={cx('container__value', {
          'container__value--error': invalidAddr[key],
        })}
      >
        {invalidAddr[key]?.value || addr[key]}
      </span>
    )
  }

  const displayFullAddress = () => {
    const addressLineOne = displayAddrField('line_one')
    const addressLineTwo = displayAddrField('line_two')
    const addressLineThree = [
      displayAddrField('city'),
      displayAddrField('county') && (
        <span>{displayAddrField('county')} County</span>
      ),
      displayAddrField('state'),
    ]
      .filter(Boolean)
      .flatmap(item => [item, ', '])
      .slice(0, -1)

    const addressLineFour = displayAddrField('zipcode')

    return [
      addressLineOne,
      addressLineTwo,
      addressLineThree,
      addressLineFour,
    ].map(line => <div>{line}</div>)
  }

  const displayError = () => {
    if (section === 'residential_address') {
      return displayFullAddress()
    }

    return (
      <span
        className={cx('container__value--error', 'container__value--error')}
      >
        {invalidFields[section].value || t('empty')}
      </span>
    )
  }

  return isInvalidImportField ? (
    <div className={styles.container}>
      <TooltipContainer label={t('Invalid value received')}>
        <Icon.Ban className={styles['container__icon--error']} />
      </TooltipContainer>
      <span className={styles.container__value}>{displayError()}</span>
    </div>
  ) : null
}

export default InvalidIndicator
