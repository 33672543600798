import moment from 'moment'

export const ABSOLUTE_DATE = 'Date range'
export const ANY_DATE = 'Any date'
export const dateTypes = [
  ABSOLUTE_DATE,
  'Days ago',
  'Weeks ago',
  'Months ago',
  'Years ago',
  'Days from now',
  'Weeks from now',
  'Months from now',
  'Years from now',
]
export const dateTypesWithAny = [ANY_DATE, ...dateTypes]
export const DATE_TYPE_OPTIONS = dateTypes.map(type => ({
  label: type,
  value: type,
}))
export const DATE_TYPE_WITH_ANY_OPTIONS = dateTypesWithAny.map(type => ({
  label: type,
  value: type,
}))

export const DATE_TYPE_OPERATIONS = {
  'Days ago': {
    unit: 'day',
    operation: 'subtract',
  },
  'Weeks ago': {
    unit: 'week',
    operation: 'subtract',
  },
  'Months ago': {
    unit: 'month',
    operation: 'subtract',
  },
  'Years ago': {
    unit: 'year',
    operation: 'subtract',
  },
  'Days from now': {
    unit: 'day',
    operation: 'add',
  },
  'Weeks from now': {
    unit: 'week',
    operation: 'add',
  },
  'Months from now': {
    unit: 'month',
    operation: 'add',
  },
  'Years from now': {
    unit: 'year',
    operation: 'add',
  },
}

export const getRelativeDateRangeFromMeta = meta => {
  const start = moment().startOf('day')
  const end = moment().endOf('day')
  const dateType = meta?.dateType

  if (dateType && dateType !== ABSOLUTE_DATE && dateType !== ANY_DATE) {
    const { operation, unit } = DATE_TYPE_OPERATIONS[dateType]
    if (operation === 'subtract') {
      start.subtract(meta.relativeValue, unit)
    }
    if (operation === 'add') {
      end.add(meta.relativeValue, unit)
    }
  }
  return [start, end]
}

export const toFormattedDateStrings = ([start, end]) => [
  start?.format(),
  end?.format(),
]

export const buildRule = (column, param) => ({
  column,
  operator: 'between',
  param,
})
export const updateRuleColumns = (rules, param, columns) => {
  columns.forEach(column => {
    const matchingRule = rules.find(rule => rule.column === column)
    if (matchingRule) {
      matchingRule.param = param
    } else {
      rules.push(buildRule(column, param))
    }
  })
}

export const defaultGetStart = (filter, columns) =>
  filter.value.rules.find(rule => rule.column === columns[0])?.param[0]
export const defaultGetEnd = (filter, columns) =>
  filter.value.rules.find(rule => rule.column === columns[0])?.param[1]
