import { useState } from 'react'
import { defaultTo } from 'lodash'
import { useAutoTable } from 'contexts'
import tableConfig from 'qualityControl/SortablePacketColumnConfig'
import {
  getFormattedPacketCreationDate,
  getFormattedPacketShiftStartDate,
  getStatusForTableReadyPackets,
  removeEmptyFilters,
} from 'utils/qualityControl'
import { fetchAssignablePackets } from 'requests/qualityControl'
import { useRequest } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'
import CheckboxColumn from './CheckboxColumn'
import AssignmentTableFilters from '../../filters/AssignmentTableFilters'
import AssignmentButtons from '../AssignmentButtons'
import { useAssignableCheckboxes } from '../CheckboxContextProvider'

const AssignablePacketsTable = ({ user, refreshAssignedPackets }) => {
  const { renderTable, refreshPage } = useAutoTable()

  const [assignablePackets, setAssignablePackets] = useState()
  const [totalPacketCount, setTotalPacketCount] = useState(0)

  const columns = [
    <CheckboxColumn key="select-column" />,
    ...tableConfig.columns,
  ]

  const { defaultSortOrder, defaultSortColumn } = tableConfig

  const { setCheckboxData } = useAssignableCheckboxes()

  const getAssignablePackets = useRequest(
    (params = {}) => {
      const extraRules = removeEmptyFilters(params.filterRules)
      return fetchAssignablePackets(
        {
          current_page: params.page,
          per: params.pageSize,
          sort_attr: defaultTo(params.sortColumn, 'id'),
          sort_dir: defaultTo(params.sortOrder, 'asc'),
          filters: {
            rules: [
              ...(extraRules.find(r => r.column === 'assignee')
                ? []
                : [
                    {
                      column: 'assignee',
                      operator: 'is',
                      param: user.id,
                      invert: true,
                    },
                  ]),
              {
                column: 'in_turf_tree',
                param: user.turf.id,
              },
              {
                column: 'shift_qc_external',
                operator: 'is',
                param: user.turf.qc_config.performs_external_qc,
              },
              ...extraRules,
            ],
          },
          fields: [
            'id',
            'original_filename',
            'created_at',
            'scans_count',
            'qc_deadline',
            { creator: 'time_zone' },
            { assignee: ['name', 'extras'] },
            { turf: ['name', 'phone_verification_language'] },
            { location: ['state', 'timezone'] },
            { shift: ['status', 'shift_start'] },
          ],
        },
        { strictNullHandling: true }
      )
    },
    {
      onSuccess: ({ packets, meta }) => {
        setAssignablePackets(
          packets.map(packet => ({
            id: packet.id,
            scan_name: packet.original_filename,
            original_filename: packet.original_filename,
            scans_count: packet.scans_count,
            assignee: packet.assignee
              ? packet.assignee.name || packet.assignee.email
              : '',
            assigned_user: packet.assignee,
            shift: packet.shift,
            creator: packet.creator,
            location: packet.location,
            shift_status: getStatusForTableReadyPackets(packet),
            turf: packet.turf,
            qc_office: packet.assignee ? packet.assignee.qc_office || '' : '',
            qc_deadline: packet.qc_deadline,
            language: packet.turf?.phone_verification_language,
            state: packet.location?.state,
            date_uploaded_at: getFormattedPacketCreationDate(packet),
            date_collected_at: getFormattedPacketShiftStartDate(packet),
          }))
        )

        setCheckboxData(packets)

        setTotalPacketCount(meta.total_count)
      },
    }
  )

  return (
    <>
      <AssignmentButtons
        onAssignComplete={() => {
          refreshPage()
          refreshAssignedPackets()
        }}
        user={user}
      />
      <AssignmentTableFilters />
      {renderTable({
        data: assignablePackets,
        defaultSortOrder,
        defaultSortColumn,
        totalRecords: totalPacketCount,
        columns,
        fetchAction: getAssignablePackets.makeRequest,
        loading: getAssignablePackets.isLoading,
        error: formatErrorMessage(getAssignablePackets.errors),
      })}
    </>
  )
}

export default AssignablePacketsTable
