import { useReducer, useState, useEffect } from 'react'
import {
  SectionContainer,
  Section,
  DetailItem,
  FormattedData,
  SectionLabel,
  CardError,
  CustomFieldsDisplay,
} from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  SelectField,
  MultiSelectField,
  DateField,
} from '@politechdev/blocks-design-system'
import { putPerson } from 'requests/people'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { formatAsISODate, formatDateStringToISO } from 'utils/formatting'
import { useCurrent } from 'contexts/index'
import Denominations from './Denominations'

const PersonDemographics = ({ person: initialPerson }) => {
  const [person, setPerson] = useState({})

  const [isEditing, toggleIsEditing] = useReducer(
    isEditing => !isEditing,
    false
  )

  const [demographics, setDemographics] = useState({
    birth_date: null,
    age: null,
    gender: null,
    ethnicities: [],
    denominations: [],
    custom_field_data: {},
  })

  const {
    showDenominations,
    ethnicityOptions,
    genderOptions,
    getCustomFieldsForTable,
  } = useCurrent()

  const customPeopleDemographicsFields = getCustomFieldsForTable(
    'people'
  ).filter(({ group }) => group === 'demographics')

  useEffect(() => {
    setPerson(initialPerson)
  }, [initialPerson])

  useEffect(() => {
    setDemographics({
      birth_date: person.birth_date,
      age: person.age,
      gender: person.gender,
      ethnicities: person.ethnicities || [],
      denominations: person.denominations || [],
      custom_field_data: person.custom_field_data || {},
    })
  }, [person, isEditing])

  const { t } = useTranslation()

  const { makeRequest: updatePersonReq, hasErrors } = useRequest(putPerson, {
    onSuccess: ({ person: incomingPerson }) => setPerson(incomingPerson),
  })

  const updateDemographics = async () => {
    await updatePersonReq(person.id, demographics, {
      fields: [
        'id',
        'birth_date',
        'age',
        'gender',
        'ethnicities',
        'denominations',
        'custom_field_data',
      ],
    })
    toggleIsEditing()
  }

  const hasAdditionalFieldValues = customPeopleDemographicsFields.some(
    field => !!demographics.custom_field_data[field.name]
  )

  return (
    <SectionContainer>
      <ButtonBlock>
        <SectionLabel primary>{t('Demographics')}</SectionLabel>
        {isEditing ? (
          <ButtonBlock justify="right">
            <Button.Secondary onClick={toggleIsEditing}>
              {t('Cancel')}
            </Button.Secondary>
            <Button onClick={updateDemographics}>{t('Save')}</Button>
          </ButtonBlock>
        ) : (
          <Button.Secondary
            aria-label={t('Edit demographics')}
            onClick={toggleIsEditing}
            data-testid="edit-demographics-button"
          >
            <Icon.Pencil />
          </Button.Secondary>
        )}
      </ButtonBlock>
      <CardError
        hide={!hasErrors}
        message={t(
          'An internal error occurred while trying to update your demographics'
        )}
      />
      <Section
        empty={
          !demographics.ethnicities.length &&
          !demographics.age &&
          !demographics.birth_date &&
          !demographics.gender &&
          !demographics.denominations.length &&
          !hasAdditionalFieldValues &&
          !isEditing
        }
        emptyMessage={t('No demographics set')}
      >
        <DetailItem
          label={t('DOB')}
          hide={!demographics.birth_date && !isEditing}
        >
          {isEditing ? (
            <DateField
              value={formatDateStringToISO(
                demographics.birth_date,
                'YYYY-MM-DD'
              )}
              onChange={date =>
                setDemographics(prevDemographics => ({
                  ...prevDemographics,
                  birth_date: formatAsISODate(date),
                }))
              }
            />
          ) : (
            <FormattedData type="date" value={demographics.birth_date || ''} />
          )}
        </DetailItem>
        <DetailItem label={t('Age')} hide={!demographics.age}>
          {demographics.age}
        </DetailItem>
        <DetailItem
          label={t('Gender')}
          hide={!demographics.gender && !isEditing}
        >
          {isEditing ? (
            <SelectField
              id="gender"
              data-testid="demographics-gender-picker"
              placeholder={t('Select gender')}
              value={demographics.gender || ''}
              options={genderOptions}
              onSelect={gender =>
                setDemographics(prevDemographics => ({
                  ...prevDemographics,
                  gender,
                }))
              }
            />
          ) : (
            demographics.gender
          )}
        </DetailItem>
        <DetailItem
          label={t('Ethnicity')}
          hide={!demographics.ethnicities.length && !isEditing}
        >
          {isEditing ? (
            <div style={{ minWidth: 120 }}>
              <MultiSelectField
                id="ethnicity-type"
                aria-label="Select ethnicity"
                data-testid="demographics-ethnicity-picker"
                values={demographics.ethnicities || []}
                options={ethnicityOptions.concat(
                  demographics.ethnicities
                    .map(ethnicity => ({
                      label: ethnicity,
                      value: ethnicity,
                    }))
                    .filter(
                      potentialOption =>
                        !ethnicityOptions.some(
                          ethnicityOption =>
                            ethnicityOption.value === potentialOption.value
                        )
                    )
                )}
                onSelect={ethnicities => {
                  setDemographics(prevDemographics => ({
                    ...prevDemographics,
                    ethnicities,
                  }))
                }}
              />
            </div>
          ) : (
            demographics.ethnicities.join(', ')
          )}
        </DetailItem>
        <Denominations
          hide={!showDenominations}
          denominations={demographics.denominations}
          setDemographics={setDemographics}
          isEditing={isEditing}
        />
        <CustomFieldsDisplay
          fields={customPeopleDemographicsFields}
          currentData={demographics.custom_field_data}
          onChange={(field, value) =>
            setDemographics(current => ({
              ...current,
              custom_field_data: {
                ...current.custom_field_data,
                [field]: value,
              },
            }))
          }
          isEditing={isEditing}
        />
      </Section>
    </SectionContainer>
  )
}

export default PersonDemographics
