import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, TextField } from 'components'
import {
  Button,
  Modal,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useRequest, useEvent } from 'hooks'
import { postFolder } from 'requests/folders'
import { useModal } from '../ModalProvider/ModalProvider'
import { MODAL_TYPES } from '../constants'
import styles from './FolderCreateModal.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const FolderCreateModal = () => {
  const { t } = useTranslation()
  const [{ isOpen, closeModal }, { parentId }] = useModal(
    MODAL_TYPES.CREATE_FOLDER
  )
  const [name, setName] = useState('')

  const resetState = () => {
    setName('')
  }

  const {
    getFolders,
    getCurrentFolder,
    currentFolder,
    foldersLoading,
    currentFolderLoading,
  } = useContext(DocumentContext)

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: createRequest,
    isLoading: createLoading,
    errorMsg: createError,
  } = useRequest(postFolder, {
    onSuccess: async () => {
      await Promise.all(
        [
          getFolders(),
          parentId === currentFolder.id && getCurrentFolder(parentId),
        ].filter(Boolean)
      )
      closeModal()
      resetState()
    },
  })

  const isLoading = foldersLoading || currentFolderLoading || createLoading

  const create = useEvent(() => createRequest({ name, parent_id: parentId }))

  return (
    <Modal
      id={MODAL_TYPES.CREATE_FOLDER}
      title={t(`Create folder`)}
      isOpen={isOpen}
      showLoading={isLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!createError}
            message={createError}
            hideSupportLink
          />
          <div className={styles.field}>
            <TextField
              id="name"
              label={t('Folder name')}
              value={name}
              onChange={setName}
              required
            />
          </div>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={create} disabled={name === '' || isLoading}>
            {t('Create')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default FolderCreateModal
