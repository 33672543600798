import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import styles from './ShiftCalendarLegend.module.scss'

const cx = classNames.bind(styles)

const ShiftCalendarLegend = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.legend}>
      <div className={cx('item', 'voter_registration')}>
        <div className={styles.label}>
          <span>{t('Voter Registration')}</span>
          <span>({t('open')})</span>
        </div>
      </div>
      <div className={cx('item', 'petitions')}>
        <div className={styles.label}>
          <span>{t('Petitions')}</span>
          <span>({t('open')})</span>
        </div>
      </div>
    </div>
  )
}

export default ShiftCalendarLegend
