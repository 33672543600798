import React from 'react'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'

export const RegistrationCanvasserNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={location => `${location.pathname}/${rowData.id}`}>
      {rowData.full_name}
    </Link>
  </TableGridCell>
)

export const CanvasserTurfCell = ({ rowData }) => (
  <TableGridCell>{rowData.turf.name}</TableGridCell>
)
