import React from 'react'
import PropTypes from 'prop-types'
import { MenuButton } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const AddRuleButton = ({ columns, setColumn }) => {
  const { t } = useTranslation()

  return (
    <MenuButton.Primary label={t('Add rule')}>
      <MenuButton.Select
        options={columns.map(col => ({
          label: t(col.title),
          value: col.dataKey,
        }))}
        onSelect={dataKey => setColumn(dataKey)}
      />
    </MenuButton.Primary>
  )
}

AddRuleButton.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  setColumn: PropTypes.func.isRequired,
}

export default AddRuleButton
