import { useContext } from 'react'
import { __RouterContext, generatePath } from 'react-router'
import qs from 'qs'

// High enough to handle all our large reports, as opposed to the default of 20,
// and low enough to avoid the attacks described here: https://github.com/ljharb/qs#parsing-arrays
const URL_ARRAY_LIMIT = 100

export const useReactRouter = () => useContext(__RouterContext)

export function useRoutePathParams() {
  const {
    match: { path, params: pathParams },
  } = useReactRouter()

  const replaceParams = newParams =>
    generatePath(path, { ...pathParams, ...newParams })

  return [pathParams, replaceParams]
}

export function useRouteQueryParams() {
  const {
    location: { pathname: currentPath, search },
    history: { push, replace },
  } = useReactRouter()

  const queryParams =
    qs.parse(search, {
      ignoreQueryPrefix: true,
      arrayLimit: URL_ARRAY_LIMIT,
    }) || {}

  const setQueryParams = (
    newParams,
    method = 'push',
    pathname = currentPath
  ) => {
    const navMethods = { push, replace }

    return navMethods[method]({
      pathname,
      search: qs.stringify(newParams, { encodeValuesOnly: true }),
    })
  }

  return [queryParams, setQueryParams]
}
