import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import { FontIcon } from 'react-md'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './MultiSearchSelect.module.scss'

const cx = classNames.bind(styles)

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <FontIcon>
      {props.selectProps.menuIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
    </FontIcon>
  </components.DropdownIndicator>
)

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <FontIcon>close</FontIcon>
  </components.ClearIndicator>
)

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <FontIcon>close</FontIcon>
  </components.MultiValueRemove>
)

const MultiSearchSelect = ({
  label,
  value,
  options,
  onChange,
  onFocus,
  onBlur,
  disabled,
  required,
  clearable,
  loading,
  error,
  errorText,
  usePortal,
  className,
  noOptionsMessage,
  remoteFiltered,
  helpText,
  ...props
}) => {
  const { t } = useTranslation()

  const [selectedOptions, setSelectedOptions] = useState([])
  const [isFocused, setFocused] = useState(false)
  const [isPristine, setPristine] = useState(true)
  const selectRef = useRef()

  const focusSelect = () => {
    selectRef.current.focus()
  }

  const handleFocus = () => {
    setFocused(true)
    onFocus && onFocus()
  }

  const handleBlur = () => {
    setFocused(false)
    setPristine(false)
    onBlur && onBlur()
  }

  const handleChange = options => {
    setSelectedOptions(options || [])
    onChange && onChange(options || [])
  }

  const hasValue = value?.length || selectedOptions?.length

  const fieldError = (!isPristine && required && !hasValue) || error
  const fieldErrorText =
    fieldError && required && !hasValue ? t('Required') : errorText

  return (
    <div className={styles.container}>
      <div
        className={cx('field', className, {
          'field--focused': isFocused,
          'field--error': fieldError,
          'field--has-value': hasValue,
        })}
      >
        <div className={styles.label} tabIndex="-1" onFocus={focusSelect}>
          {[label, required && '*'].filter(Boolean).join(' ')}
        </div>
        <Select
          ref={selectRef}
          isClearable={!!clearable}
          isDisabled={disabled}
          isLoading={loading}
          value={value || selectedOptions}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          options={options}
          classNamePrefix="ReactMultiSelect"
          menuPortalTarget={usePortal ? document.body : undefined}
          closeMenuOnScroll={event => usePortal && event.target === document}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          components={{
            DropdownIndicator,
            ClearIndicator,
            MultiValueRemove,
          }}
          noOptionsMessage={() => noOptionsMessage}
          filterOption={remoteFiltered ? () => true : undefined}
          isMulti
          {...props}
        />
      </div>
      {helpText && <div className={styles.help}>{helpText}</div>}
      {fieldError && <div className={styles.error}>{fieldErrorText}</div>}
    </div>
  )
}

MultiSearchSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  usePortal: PropTypes.bool,
  className: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  remoteFiltered: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

MultiSearchSelect.defaultProps = {
  disabled: false,
  required: false,
  clearable: false,
  loading: false,
  error: false,
  errorText: '',
  usePortal: false,
  noOptionsMessage: 'No options',
  remoteFiltered: false,
}

export default MultiSearchSelect
