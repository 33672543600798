import { useTranslation } from 'react-i18next'
import { PdfViewer } from 'components'

const ShiftPdfViewer = ({
  url,
  name,
  removedAt,
  pageNumber,
  showPaginator = true,
  showDownload = true,
  onNext,
  onPrevious,
  disabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <PdfViewer
      file={url}
      filename={name}
      removedAt={removedAt}
      documentLoadingMessage={t('Shift PDF loading...')}
      pageNumber={pageNumber}
      showPaginator={showPaginator}
      showDownload={showDownload}
      onNext={onNext}
      onPrevious={onPrevious}
      disabled={disabled}
    />
  )
}

export default ShiftPdfViewer
