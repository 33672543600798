import { useTranslation } from 'react-i18next'
import { LoadBar, CardError } from 'components'
import { USER_TYPES } from 'constants/qualityControl'
import ScheduleItem from './ScheduleItem'
import NewScheduleItem from './NewScheduleItem'
import StaffScheduleMetrics from './StaffScheduleMetrics'
import { filterManagers, filterStaff, isUserScheduled } from './utils'

const ModalScheduleForm = ({
  existingSchedules,
  displayableSchedules,
  updateSchedule,
  addSchedule,
  removeSchedule,
  isSaveLoading,
  saveHasErrors,
  isFetchLoading,
  fetchHasErrors,
}) => {
  const { t } = useTranslation()

  const managerSchedules = displayableSchedules.filter(filterManagers)
  const staffSchedules = displayableSchedules.filter(filterStaff)
  const scheduledUserIds = new Set(
    displayableSchedules.map(({ user }) => user.id)
  )

  return (
    <div className="quality-control__packet-management__modal__schedule">
      <div>
        <LoadBar
          className="quality-control__scheduler__loadbar"
          show={isFetchLoading || isSaveLoading}
        />
        <form>
          <h2 className="quality-control__scheduler__form__label">
            {t('Shift Managers')}
          </h2>
          <div className="quality-control__scheduler__form quality-control__scheduler__form--modal">
            {managerSchedules.map(schedule => (
              <ScheduleItem
                key={`schedule-item-${schedule.localId}`}
                schedule={schedule}
                scheduledUserIds={scheduledUserIds}
                onUpdate={updateSchedule}
                removeSchedule={removeSchedule}
                nameDisabled={isUserScheduled(existingSchedules, schedule)}
                userType={USER_TYPES.MANAGER}
              />
            ))}
            <NewScheduleItem
              onUpdate={addSchedule}
              userType={USER_TYPES.MANAGER}
            />
          </div>
          <h2 className="quality-control__scheduler__form__label">
            {t('Staff Members')}
          </h2>
          <StaffScheduleMetrics staffSchedules={staffSchedules} />
          <div className="quality-control__scheduler__form quality-control__scheduler__form--modal">
            {staffSchedules.map(schedule => (
              <ScheduleItem
                key={`schedule-item-${schedule.localId}`}
                schedule={schedule}
                scheduledUserIds={scheduledUserIds}
                onUpdate={updateSchedule}
                removeSchedule={removeSchedule}
                nameDisabled={isUserScheduled(existingSchedules, schedule)}
                tooltipPosition="left"
                userType={USER_TYPES.STAFF}
              />
            ))}
            <NewScheduleItem
              onUpdate={addSchedule}
              userType={USER_TYPES.STAFF}
            />
          </div>
          <div className="quality-control__scheduler__form">
            <CardError hide={!fetchHasErrors && !saveHasErrors} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ModalScheduleForm
