import { fetchJSON, stringifyParams } from 'utils/req'

const FIELDS_PARAM_STRING = stringifyParams({
  fields: ['id', 'name', 'conjunction', 'metadata', 'rules'],
})

export const fetchFilters = () =>
  fetchJSON(`/api/v1/filter_views?${FIELDS_PARAM_STRING}`, 'GET', null, {
    useJwt: true,
  })

export const postFilter = filter =>
  fetchJSON(
    `/api/v1/filter_views?${FIELDS_PARAM_STRING}`,
    'POST',
    { filter_view: filter },
    {
      useJwt: true,
    }
  )

export const putFilter = filter =>
  fetchJSON(
    `/api/v1/filter_views/${filter.id}?${FIELDS_PARAM_STRING}`,
    'PUT',
    { filter_view: filter },
    {
      useJwt: true,
    }
  )

export const destroyFilter = id =>
  fetchJSON(
    `/api/v1/filter_views/${id}?${FIELDS_PARAM_STRING}`,
    'DELETE',
    null,
    {
      useJwt: true,
    }
  )
