import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'
import classNames from 'classnames/bind'
import {
  Font,
  List,
  ContentBlock,
  ButtonBlock,
  Button,
  Icon,
} from '@politechdev/blocks-design-system'
import { formatDisplayName, formatName } from 'utils/formatting'
import { APPROVED_RESPONSE } from './constants'

import styles from './PetitionPacketSingle.module.scss'

const cx = classNames.bind(styles)

const SignatureListItem = ({ item, isEditable, hasPageLevelIssue }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const match = useRouteMatch()

  const handleSignatureClick = itemId => () => {
    history.push(`${match.url}/signature/${itemId}`)
  }

  const isApproved =
    !!item.visual_review_responses.find(
      visualResponse => visualResponse.response === APPROVED_RESPONSE
    ) && !hasPageLevelIssue
  const hasBeenReviewed = item.visual_review_responses.length

  const BadgeIcon = isApproved ? Icon.Check : Icon.Xmark

  return (
    <List.Item key={item.id}>
      <ContentBlock>
        <Font.Copy variant="highlight">
          {t('Signature #{{ line_number }}', item)}
        </Font.Copy>
        <Font.Copy variant="secondary">{item.county}</Font.Copy>
      </ContentBlock>
      <ContentBlock>
        <Font.Copy variant="highlight">
          {formatName([item.first_name, item.middle_name, item.last_name])}
        </Font.Copy>
      </ContentBlock>
      <ContentBlock>
        {!!item.reviewer && (
          <>
            <Font.Copy variant="highlight">{t('Reviewed by')}</Font.Copy>
            <Font.Copy variant="secondary">
              {formatDisplayName(item.reviewer)}
            </Font.Copy>
          </>
        )}
      </ContentBlock>
      <ButtonBlock justify="right">
        {hasBeenReviewed || hasPageLevelIssue ? (
          <div className={cx('review-badge__container')}>
            <div
              className={cx('review-badge', {
                'review-badge--approved': isApproved,
                'review-badge--rejected': !isApproved,
              })}
            >
              <Font.Action
                className={cx('review-badge', {
                  'review-badge--approved__label': isApproved,
                  'review-badge--rejected__label': !isApproved,
                })}
              >
                {isApproved && t('Approved')}
                {!isApproved && !hasPageLevelIssue && t('Rejected')}
                {!isApproved && hasPageLevelIssue && t('Page issue')}
              </Font.Action>
              <BadgeIcon className={cx('review-badge__icon')} />
            </div>
          </div>
        ) : null}
        <Button
          onClick={handleSignatureClick(item.id)}
          disabled={hasPageLevelIssue}
        >
          {isEditable ? t(hasBeenReviewed ? 'Edit' : 'Review') : t('View')}
        </Button>
      </ButtonBlock>
    </List.Item>
  )
}

export default SignatureListItem
