import { useEffect, useState } from 'react'
import { CardError } from 'components'
import {
  Modal,
  Button,
  ButtonBlock,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { useAutoTable } from 'contexts'
import { patchTurfPacketsDeadline } from 'requests/tableQcManager'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { getDateTimeXDaysFromNow } from 'utils/dateTime'
import TurfList from './TurfList'

const ReprioritizeTurfPacketsModal = () => {
  const { t } = useTranslation()

  const { refreshPage } = useAutoTable()

  const [updatedTurfPriorities, setUpdatedTurfPriorities] = useState({})
  const [turfSearch, setTurfSearch] = useState('')

  const {
    isTurfReprioritizationModalOpen,
    setIsTurfReprioritizationModalOpen,
  } = usePacketAssignment()

  const { isLoading, hasErrors, makeRequest } = useRequest(
    turfDeadlineMap => {
      const requests = Object.entries(turfDeadlineMap).map(
        ([turfId, deadline]) => patchTurfPacketsDeadline(turfId, deadline)
      )
      return Promise.all(requests)
    },
    {
      onSuccess: () => {
        setUpdatedTurfPriorities({})
        setIsTurfReprioritizationModalOpen(false)
        refreshPage()
      },
    }
  )

  useEffect(() => {
    setTurfSearch('')
    setUpdatedTurfPriorities({})
  }, [isTurfReprioritizationModalOpen])

  const setTurfDaysLeftToQc = (id, daysInput) => {
    setUpdatedTurfPriorities(current => ({
      ...current,
      [id]: daysInput,
    }))
  }

  const handleSaveUpdates = () => {
    const updates = Object.fromEntries(
      Object.entries(updatedTurfPriorities).map(([turfId, days]) => [
        turfId,
        getDateTimeXDaysFromNow(days),
      ])
    )
    makeRequest(updates)
  }

  return (
    <Modal
      id="reprioritize-turfs-modal"
      title={t('Reprioritize Packets by Turf')}
      onHide={() => setIsTurfReprioritizationModalOpen(false)}
      isOpen={isTurfReprioritizationModalOpen}
    >
      <Modal.Body>
        <ProgressBar show={isLoading} />
        <CardError hide={!hasErrors} />
        <TurfList
          turfSearch={turfSearch}
          setTurfSearch={setTurfSearch}
          updatedTurfPriorities={updatedTurfPriorities}
          setTurfDaysLeftToQc={setTurfDaysLeftToQc}
        />
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button onClick={() => setIsTurfReprioritizationModalOpen(false)}>
            {t('Cancel')}
          </Button>
          <Button.Accent onClick={handleSaveUpdates}>{t('Save')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ReprioritizeTurfPacketsModal
