import { useEffect } from 'react'
import { SectionLabel, CardError, Section, TextBlock } from 'components'
import { useReactRouter, useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { defaultFlagParams } from 'requests/flags'
import styles from './FlagSingleDuplicateFlagsList.module.scss'
import { useCurrentFlagContext } from '../contexts/CurrentFlagContext'

const FlagSingleDuplicateFlagsList = () => {
  const { match, history } = useReactRouter()
  const { t } = useTranslation()

  const { currentTurfPerformsExternalQC } = useCurrent()

  const { currentFlag, fetchDuplicateFlags } = useCurrentFlagContext()

  const duplicateFlagsRequest = useRequest(fetchDuplicateFlags)

  const duplicateFlags =
    duplicateFlagsRequest.response?.['quality_control/flags'] || []

  const handleDuplicateFlagsRequest = () => {
    if (currentFlag.status === 'completed') {
      duplicateFlagsRequest.clearRequest()
    } else {
      duplicateFlagsRequest.makeRequest(
        currentTurfPerformsExternalQC,
        defaultFlagParams
      )
    }
  }

  const errorMsg = formatErrorMessage(duplicateFlagsRequest.errors)

  useEffect(() => {
    if (currentFlag) handleDuplicateFlagsRequest()
  }, [currentFlag?.id])

  if (!duplicateFlags.length || duplicateFlagsRequest.isLoading) return null

  return (
    <Section>
      <div className={styles.header}>
        <TextBlock>
          <SectionLabel>
            {t('Duplicate flags preventing restoration')}
          </SectionLabel>
        </TextBlock>
      </div>
      <CardError hide={!errorMsg} />
      {duplicateFlags.map(duplicateFlag => (
        <div className={styles.wrapper} key={`${duplicateFlag.id}`}>
          <span className={styles.id}>{`Flag ${duplicateFlag.id}`}</span>
          <span
            className={styles.flagName}
            onClick={() =>
              history.push(
                `/quality_control/flags/${match.params.type}/${duplicateFlag.id}/${match.params.originalType}`
              )
            }
          >
            {duplicateFlag.trigger.name}
          </span>
        </div>
      ))}
    </Section>
  )
}

export default FlagSingleDuplicateFlagsList
