import moment from 'moment'
import {
  EventNameCell,
  EventCreatorCell,
  EventTypeCell,
  EventLocationCell,
  EventDateCell,
  EventAttendedCountCell,
  EventDidNotAttendCountCell,
  EventOrganizationCell,
  EventNotesCell,
  EventTeamsCell,
} from './EventTableCells'
import {
  eventCreatorCsvCell,
  eventTypeCsvCell,
  eventLocationCsvCell,
  eventDateCsvCell,
  eventAttendedCountCsvCell,
  eventDidNotAttendCountCsvCell,
  eventOrganizationCsvCell,
  eventTeamsCsvCell,
} from './eventCsvCells'

export default {
  label: 'events',
  defaultSortOrder: 'desc',
  defaultSortColumn: 'start_time',
  columns: [
    {
      dataKey: 'name',
      title: 'Event name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: EventNameCell,
    },
    {
      dataKey: 'creator_full_name',
      title: 'Creator',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: EventCreatorCell,
      csvCell: eventCreatorCsvCell,
    },
    {
      dataKey: 'type',
      title: 'Type',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: EventTypeCell,
      csvCell: eventTypeCsvCell,
    },
    {
      dataKey: 'organization_name',
      title: 'Organization',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: EventOrganizationCell,
      csvCell: eventOrganizationCsvCell,
    },
    {
      dataKey: 'teams_name',
      title: 'Teams',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      sortable: false,
      bodyCell: EventTeamsCell,
      csvCell: eventTeamsCsvCell,
    },
    {
      dataKey: 'location_name',
      title: 'Location',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: EventLocationCell,
      csvCell: eventLocationCsvCell,
    },
    {
      dataKey: 'start_time',
      title: 'Date',
      type: 'date',
      autoResize: true,
      resizable: true,
      bodyCell: EventDateCell,
      csvCell: eventDateCsvCell,
      filterConstructor: rule => ({
        ...rule,
        param: moment(rule.param).format('YYYY-MM-DDT00:00:00'),
      }),
    },
    {
      dataKey: 'total_attended_count',
      title: 'Attended',
      type: 'number',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: EventAttendedCountCell,
      csvCell: eventAttendedCountCsvCell,
    },
    {
      dataKey: 'total_non_attended_count',
      title: 'Did not attend',
      type: 'number',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: EventDidNotAttendCountCell,
      csvCell: eventDidNotAttendCountCsvCell,
    },
    {
      dataKey: 'notes',
      title: 'Notes',
      type: 'string',
      resizable: true,
      bodyCell: EventNotesCell,
    },
  ],
}
