export const buildOption = organization => ({
  value: organization.id,
  label: organization.name,
})

export const buildOrganization = option => ({
  id: option.value,
  name: option.label,
})

export const buildParams = ({ query, organizationTypes, filters = [] }) => ({
  filters: {
    rules: [
      query && query !== ''
        ? {
            rules: [
              {
                column: 'name',
                operator: 'containing',
                param: query,
              },
            ],
            conjunction: 'inclusive',
          }
        : undefined,
      organizationTypes && organizationTypes.length > 0
        ? {
            rules: organizationTypes.map(organizationType => ({
              column: 'organization_type',
              operator: 'is',
              param: organizationType.value,
            })),
            conjunction: 'inclusive',
          }
        : undefined,
      ...filters,
    ],
    conjunction: 'exclusive',
  },
  sort_attr: 'name',
  sort_dir: 'asc',
  per: 25,
  indexed: true,
  fields: ['id', 'name'],
})
