import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, LoadBar } from 'components'
import { Button, Icon, Font } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks/useRequest'
import { putScriptText } from 'requests/phoneBanks'
import ContentText from './ContentText'
import Actions from '../Actions'
import styles from '../ScriptItem.module.scss'
import { iconIndex } from '../utils'

const ScriptText = ({
  item,
  content,
  loading,
  updateContent,
  isOnTop,
  isOnBottom,
  moveUp,
  moveDown,
  removeSelf,
  isEditable = true,
}) => {
  const { t } = useTranslation()
  const itemId = item.script_text?.id
  const type = item.script_text?.type

  const [isEditingItem, setIsEditingItem] = useState(false)

  const [contentInState, setContentInState] = useState(content || '')

  useEffect(() => {
    setContentInState(content || '')
  }, [content])

  const {
    makeRequest: scriptTextRequest,
    isLoading: scriptTextLoading,
    hasErrors: scriptTextHasErrors,
  } = useRequest(putScriptText, {
    onSuccess: () => setIsEditingItem(false),
  })

  const makeUpdateScriptTextRequest = () => {
    updateContent({ text: contentInState })
    scriptTextRequest(itemId, { text: contentInState })
  }

  const handleSaveClick = () => makeUpdateScriptTextRequest()

  const wrapperClass = styles['script-item__wrapper']

  const HeaderIcon = iconIndex[type] || null

  const scriptHeader =
    type === 'instruction' ? (
      <div className={styles['script-item__icon']}>
        <Icon.Map alt="" />
        <Font.Label Element="span">{`${t('Instructions')}:`}</Font.Label>
      </div>
    ) : (
      <div
        className={`${styles['script-item__icon']} ${styles['script-item__icon--accent']}`}
      >
        <HeaderIcon />
      </div>
    )

  return (
    <>
      <LoadBar show={scriptTextLoading} />
      <CardError
        hide={!scriptTextHasErrors}
        message={t('There was an error editing this item')}
      />
      <div className={wrapperClass}>
        <div className={styles['script-item']}>
          {scriptHeader}
          <div className={styles['script-item__content']}>
            {isEditingItem ? (
              <ContentText.Edit
                type={type}
                contentInState={contentInState}
                setContentInState={setContentInState}
              />
            ) : (
              <ContentText.Read type={type} content={content} />
            )}
          </div>
          {isEditable && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isEditingItem ? (
                <Actions.Edit
                  loading={loading}
                  closeEdit={() => setIsEditingItem(false)}
                  handleSaveClick={handleSaveClick}
                />
              ) : (
                <Actions.Read
                  item={item}
                  loading={loading}
                  setIsEditingItem={setIsEditingItem}
                  removeSelf={removeSelf}
                />
              )}
            </>
          )}
        </div>
        <div className={styles['script-item__reorder-arrows']}>
          <Button.Secondary
            className={styles.button}
            disabled={isOnTop || loading}
            onClick={moveUp}
            aria-label={t('Move up')}
          >
            <Icon.ChevronUp alt={t('Move up')} />
          </Button.Secondary>
          <Button.Secondary
            className={styles.button}
            disabled={isOnBottom || loading}
            onClick={moveDown}
            aria-label={t('Move down')}
          >
            <Icon.ChevronDown alt={t('Move down')} />
          </Button.Secondary>
        </div>
      </div>
    </>
  )
}

ScriptText.propTypes = {
  type: PropTypes.string.isRequired,
}

export default ScriptText
