import IntercomChatButton from 'components/IntercomChatButton/IntercomChatButton'
import SupportLink from './SupportLink'
import styles from './CardError.module.scss'

const removeEndingPeriod = (val: string): string => {
  if (val && val.slice(-1) === '.') {
    return val.slice(0, -1)
  }
  return val
}

const CardError = ({
  className,
  hideSupportText = false,
  hideSupportLink = false,
  hide = true,
  message = 'An internal error occurred.',
}: {
  className?: string
  hideSupportText?: boolean
  hideSupportLink?: boolean
  hide?: boolean
  message?: string
}) => {
  const msg = removeEndingPeriod(message)
  if (hide === true) return null
  return (
    <div className={`${styles.card__error} ${className || ''}`}>
      <p className={styles['card__error__text-container']}>
        <span className={styles.card__error__label}>Error:</span> {msg}.{' '}
        <SupportLink hide={hideSupportLink || hideSupportText} />
      </p>
      {!hideSupportLink && (
        <IntercomChatButton
          className="card__error__button"
          otherActions={undefined}
        />
      )}
    </div>
  )
}

export default CardError
