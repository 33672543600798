import { stringifyParams } from 'utils/req'

interface VisualReviewChartEntry {
  response_description: string
  total_reviews: number
  implies_not_form: boolean
  implies_incomplete_form: boolean
}

type ResponsePredicate = (entry: VisualReviewChartEntry) => boolean

interface ResponsePredicateMap {
  [key: string]: ResponsePredicate
}

export const buildVisualReviewChartData = (
  data: Array<VisualReviewChartEntry>,
  predicateMap: ResponsePredicateMap
) => {
  const observableFilters = Object.values(predicateMap).filter(
    predicate => typeof predicate === 'function'
  )

  const filteredData = data.filter(
    entry => !observableFilters.some(predicate => predicate(entry))
  )

  const sorted = filteredData.toSorted(
    (a, b) => b.total_reviews - a.total_reviews
  )
  return sorted.slice(0, 10)
}

export function getShiftTableUrl(
  response: string,
  startDate: string,
  endDate: string,
  canvasserName: string
) {
  const urlParams = {
    page: 1,
    filterRules: [
      {
        column: 'shift_start',
        operator: 'before',
        param: endDate,
        id: 'redirectFilterShiftStart',
      },
      {
        column: 'shift_start',
        operator: 'after',
        param: startDate,
        id: 'redirectFilterShiftEnd',
      },
      {
        column: 'visual_review_responses_description',
        operator: 'is',
        param: response,
        id: 'redirectFilterShiftResponse',
      },
      {
        column: 'canvasser_full_name',
        operator: 'is',
        param: canvasserName,
        id: 'redirectFilterShiftCanvasser',
      },
    ],
  }
  return `/collect/voter_registration/shifts?${stringifyParams(urlParams)}`
}
