import tableDisplayNames from './tableNamesConfig'

export const EXPORTABLE_TABLES = {
  canvassers: [
    'id',
    'first_name',
    'last_name',
    'phone_number',
    'notes',
    'turf_id',
    'created_at',
    'updated_at',
    'created_by_user_id',
  ],
  deliveries: [
    'id',
    'batch_number',
    'status',
    'office_id',
    'turn_in_location_id',
    'canvasser_id',
    'turn_in_date',
    'notes',
    'created_at',
    'updated_at',
    'created_by_user_id',
  ],
  phone_verification_responses: [
    'id',
    'phone_verification_question_id',
    'voter_registration_scan_id',
    'created_by_user_id',
    'response',
    'created_at',
    'updated_at',
    'round_number',
    'notes',
  ],
  registrant_matches: [
    'id',
    'registration_form_id',
    'dwid',
    'applicant_id',
    'household_id_1',
    'household_id_2',
    'election_cycle',
    'confidence_score',
    'type_code',
    'first_name',
    'middle_name',
    'last_name',
    'name_suffix',
    'full_name',
    'birthdate',
    'age',
    'gender',
    'race',
    'race_confidence',
    'ethnicity',
    'race_source',
    'registration_date',
    'earliest_registration_date',
    'reg_address_one',
    'reg_address_two',
    'reg_address_street_number',
    'reg_address_pre_directional',
    'reg_address_street_name',
    'reg_address_street_type',
    'reg_address_post_directional',
    'reg_address_unit_type',
    'reg_address_unit_number',
    'reg_city',
    'reg_state',
    'reg_zip5',
    'reg_zip4',
    'mailing_address_one',
    'mailing_address_two',
    'mailing_address_street_number',
    'mailing_address_pre_directional',
    'mailing_address_street_name',
    'mailing_address_street_type',
    'mailing_address_post_directional',
    'mailing_address_unit_type',
    'mailing_address_unit_number',
    'mailing_city',
    'mailing_state',
    'mailing_zip5',
    'mailing_zip4',
    'county_name',
    'state',
    'zip9',
    'cass_street_address',
    'cass_city',
    'cass_state',
    'cass_zipcode',
    'cass_state_fips',
    'cass_county_fips',
    'change_of_address_date',
    'voter_file_acqusition_date',
    'voter_file_latest_registration_date',
    'voter_file_version',
    'created_at',
    'updated_at',
    'phone_number',
    'precinct_code',
    'precinct_name',
    'unique_precinct_code',
    'likely_cell',
    'likely_cell_assignment_score',
    'likely_cell_connectivity_score',
    'likely_cell_restricted',
    'likely_landline',
    'likely_landline_assignment_score',
    'likely_landline_connectivity_score',
    'likely_landline_restricted',
  ],
  registration_forms: [
    'id',
    'scan_id',
    'registration_type',
    'shift_id',
    'created_by_user_id',
    'form_number',
    'first_name',
    'middle_name',
    'last_name',
    'name_suffix',
    'voting_street_address_one',
    'voting_street_address_two',
    'voting_city',
    'voting_state',
    'voting_zipcode',
    'mailing_street_address_one',
    'mailing_street_address_two',
    'mailing_city',
    'mailing_zipcode',
    'county',
    'precinct',
    'gender',
    'date_of_birth',
    'registration_date',
    'identification',
    'phone_number',
    'email_address',
    'us_citizen',
    'eligible_voting_age',
    'signature',
    'extras',
    'created_at',
    'updated_at',
    'delivery_id',
    'party',
    'name_prefix',
    'ethnicity',
    'latitude',
    'longitude',
    'distance_from_location',
    'completed',
    'secondary_identification',
    'pledge_card_data',
  ],
  shifts: [
    'id',
    'location_id',
    'staging_location_id',
    'canvasser_id',
    'shift_start',
    'shift_end',
    'created_by_user_id',
    'created_at',
    'updated_at',
    'status',
    'incomplete_vr_forms_count',
    'registration_forms_count',
    'soft_count_cards_total_collected',
    'soft_count_cards_complete_collected',
    'soft_count_cards_incomplete_collected',
    'soft_count_cards_with_incorrect_date_collected',
    'soft_count_cards_with_phone_collected',
    'soft_count_cards_with_email_collected',
    'soft_count_pre_registration_cards_collected',
    'notes',
    'locked_by_user_id',
    'locked_at',
    'field_start',
    'field_end',
  ],
  voter_registration_scans: [
    'id',
    'file_locator',
    'voter_registration_scan_batch_id',
    'scan_number',
    'created_at',
    'updated_at',
    'reviewed_by_user_id',
    'reviewed_at',
    'turn_in_location_id',
    'delivery_id',
    'notes',
  ],
  visual_reviews: [
    'id',
    'voter_registration_scan_id',
    'visual_review_response_id',
    'created_at',
    'updated_at',
  ],
  locations: [
    'id',
    'name',
    'street_address',
    'city',
    'state',
    'zipcode',
    'county',
    'latitude',
    'longitude',
    'created_by_user_id',
    'location_type',
    'notes',
    'created_at',
    'updated_at',
    'score',
    'registration_forms_count',
    'hours',
    'primary_point_of_contact',
    'phone_number',
    'email',
    'turf_id',
  ],
  turfs: ['id', 'parent_id', 'name', 'turf_level', 'created_at', 'updated_at'],
  voter_registration_scan_batches: [
    'id',
    'shift_id',
    'created_at',
    'updated_at',
    'original_filename',
    'created_by_user_id',
    'scans_count',
  ],
  scans_qc_overview: [
    'voter_registration_scan_id',
    'upload_time',
    'voter_registration_scan_updated_at',
    'visual_qc_county',
    'is_registration_form',
    'registration_form_id',
    'registration_type',
    'first_name',
    'middle_name',
    'last_name',
    'name_suffix',
    'voting_street_address_one',
    'voting_street_address_two',
    'voting_city',
    'voting_state',
    'voting_zipcode',
    'mailing_street_address_one',
    'mailing_street_address_two',
    'mailing_city',
    'mailing_zipcode',
    'data_entry_county',
    'gender',
    'date_of_birth',
    'identification',
    'phone_number',
    'email_address',
    'eligible_voting_age',
    'signature',
    'extras',
    'data_entry_time',
    'data_entry_updated_at',
    'party',
    'name_prefix',
    'ethnicity',
    'voting_address_latitude',
    'voting_address_longitude',
    'distance_from_location',
    'address_validated',
    'shift_id',
    'shift_start',
    'shift_end',
    'field_start',
    'field_end',
    'visual_qc_completed_by_user_id',
    'phone_verification_completed_by_user_id',
    'through_visual_qc',
    'has_phone_number_visual_qc',
    'qc_organization',
    'voter_registration_scan_batches_id',
    'packet_filename',
    'qc_deadline',
    'packet_delivered',
    'canvasser_id',
    'canvasser_first_name',
    'canvasser_last_name',
    'turf_id',
    'name',
    'van_committee_id',
    'organization_name',
    'collective_name',
    'program_state',
    'collection_location_id',
    'collection_location_name',
    'collection_location_street_address',
    'collection_location_city',
    'collection_location_state',
    'collection_location_zip',
    'collection_location_county',
    'collection_location_latitude',
    'collection_location_longitude',
    'phone_verified',
    'bad_number',
    'pledge_card_metadata',
  ],
}

export const TABLE_OPTIONS = Object.keys(EXPORTABLE_TABLES).map(table => ({
  tableName: table,
  label: tableDisplayNames[table],
}))
