import {
  ProjectionNameCell,
  ProjectionTurfCell,
  ProjectionCreatorCell,
  ProjectionStatusCell,
  ProjectionStartDateCell,
  ProjectionEndDateCell,
  ProjectionTotalCell,
} from './ProjectionTableCells'
import {
  projectionNameCsvCell,
  projectionCreatorCsvCell,
  projectionTurfCsvCell,
  projectionStatusCsvCell,
  projectionStartDateCsvCell,
  projectionEndDateCsvCell,
  projectionTotalCsvCell,
} from './projectionCsvCells'

export default {
  label: 'projections',
  defaultSortOrder: 'desc',
  defaultSortColumn: 'startDate',
  columns: [
    {
      dataKey: 'name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: ProjectionNameCell,
      csvCell: projectionNameCsvCell,
    },
    {
      dataKey: 'turf',
      title: 'Turf',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: ProjectionTurfCell,
      csvCell: projectionTurfCsvCell,
    },
    {
      dataKey: 'creatorFullName',
      title: 'Creator',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: ProjectionCreatorCell,
      csvCell: projectionCreatorCsvCell,
    },
    {
      dataKey: 'status',
      title: 'Status',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: ProjectionStatusCell,
      csvCell: projectionStatusCsvCell,
      sortable: false,
    },
    {
      dataKey: 'startDate',
      title: 'Start',
      type: 'date',
      autoResize: true,
      resizable: true,
      bodyCell: ProjectionStartDateCell,
      csvCell: projectionStartDateCsvCell,
    },
    {
      dataKey: 'endDate',
      title: 'End',
      type: 'date',
      autoResize: true,
      resizable: true,
      bodyCell: ProjectionEndDateCell,
      csvCell: projectionEndDateCsvCell,
    },
    {
      dataKey: 'total',
      title: 'Total projected',
      type: 'number',
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: ProjectionTotalCell,
      csvCell: projectionTotalCsvCell,
      filterOperators: [
        {
          prefix: 'is more than',
          value: 'more_than',
          inputProps: { type: 'number', min: 0 },
        },
        {
          prefix: 'is less than',
          value: 'less_than',
          inputProps: { type: 'number', min: 0 },
        },
      ],
    },
  ],
}
