import { buildDefaultFilters } from 'utils/reporting'
import baseQcSummaryColumns from '../columns/baseQcSummaryColumns'

export default {
  id: 'qc_summary_by_role',
  name: 'QC Work by Staff Role and Office',
  description:
    'This report provides information on quality control shifts, totals and rates for QC staff roles.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=601943128',
  isTimeline: true,
  nonTimelineColumns: ['role_name', 'qc_office'],
  aggregateKeys: ['role_name', 'qc_office'],
  totalOnlyKeys: [
    'total_no_phone_scans_viz_day',
    'total_no_phone_scans_viz_week',
    'total_no_phone_scans_viz_all_time',
  ],
  columns: baseQcSummaryColumns,
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Day',
        visibleColumns: [
          'role_name',
          'qc_office',
          'total_shifts_day',
          'total_hours_day',
          'total_packet_scans_day',
          'avg_shift_length_day',
          'total_scans_viz_day',
          'total_scans_phone_day',
          'viz_qc_rate_day',
          'phone_qc_rate_day',
          'total_qc_rate_day',
        ],
      },
      {
        title: 'Week',
        visibleColumns: [
          'role_name',
          'qc_office',
          'total_shifts_week',
          'total_hours_week',
          'total_packet_scans_week',
          'avg_shift_length_week',
          'total_scans_viz_week',
          'total_scans_phone_week',
          'viz_qc_rate_week',
          'phone_qc_rate_week',
          'total_qc_rate_week',
        ],
      },
      {
        title: 'All time',
        visibleColumns: [
          'role_name',
          'qc_office',
          'total_shifts_all_time',
          'total_hours_all_time',
          'total_packet_scans_all_time',
          'avg_shift_length_all_time',
          'total_scans_viz_all_time',
          'total_scans_phone_all_time',
          'viz_qc_rate_all_time',
          'phone_qc_rate_all_time',
          'total_qc_rate_all_time',
        ],
      },
    ])
  },
}
