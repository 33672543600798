import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ViewContainer, CardError, View } from 'components'
import {
  Sheet,
  SectionLabel,
  TextBlock,
  TextField,
  ButtonBlock,
  FieldBlock,
  Button,
  PageHeader,
} from '@politechdev/blocks-design-system'
import {
  QueryParamProvider,
  useQueryParams,
  PaginationProvider,
  usePagination,
} from 'contexts'
import { useTranslation } from 'react-i18next'
import { useRequest, useReactRouter } from 'hooks'
import { fetchPhoneBanks, copyPhoneBank } from 'requests/phoneBanks'
import PhoneBankList from '../components/PhoneBankList'

const PhoneBankCopy = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const {
    makeRequest: requestPhoneBanks,
    isLoading: phoneBanksLoading,
    hasErrors: phoneBanksError,
    response: phoneBanksResponse,
  } = useRequest(fetchPhoneBanks)

  const phoneBanks = phoneBanksResponse?.phone_banks
  const meta = phoneBanksResponse?.meta

  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [rules, setRules] = useState({
    current_page: 1,
    per: 50,
  })

  const {
    queryParams: { searchValue },
    setQueryParams,
  } = useQueryParams()

  const setSearchValue = newSearchValue => {
    setQueryParams({ searchValue: newSearchValue })
  }

  const { page, setPage } = usePagination(true)

  const quickSearchFilterRule = input => {
    if (!input) return null

    return {
      column: 'name',
      operator: 'containing',
      param: input,
    }
  }

  useEffect(() => {
    if (meta && meta.current_page !== 1) {
      setData(data.concat(phoneBanks))
    } else {
      setData(phoneBanks || [])
    }
  }, [phoneBanks])

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setSearchValue(search)
      setPage(1)
    }, 1500)

    return () => clearTimeout(delayDebounce)
  }, [search])

  useEffect(() => {
    requestPhoneBanks(rules)
  }, [rules])

  useEffect(() => {
    setRules({
      ...rules,
      filters: {
        rules: [quickSearchFilterRule(searchValue)].filter(Boolean),
        conjunction: 'exclusive',
      },
      current_page: page,
    })
  }, [searchValue, page])

  const {
    makeRequest: copyPhoneBankRequest,
    isLoading: copyLoading,
    hasErrors: copyError,
  } = useRequest(id => copyPhoneBank(id, { fields: ['id'] }), {
    onSuccess: ({ phone_bank }) => {
      history.push(`/organize/phone_banks/${phone_bank.id}`)
    },
  })

  const isLoading = copyLoading || phoneBanksLoading

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <PageHeader title={t('Copy phone bank')} />
          <CardError
            hide={!phoneBanksError}
            message={t("We're unable to retrieve existing phone banks")}
          />
          <CardError
            hide={!copyError}
            message={t('There was an error copying your phone bank')}
          />
          <FieldBlock>
            <TextField
              name="search_text"
              id="search_text"
              type="search"
              label={t('Phone bank or list name')}
              value={search || ''}
              onChange={val => {
                setSearch(val)
              }}
              loading={isLoading}
            />
          </FieldBlock>
          <TextBlock>
            <SectionLabel accent>
              {t('Select a phone bank to copy')}
            </SectionLabel>
          </TextBlock>
          <PhoneBankList
            phoneBanks={data}
            onClickPhoneBank={copyPhoneBankRequest}
          />
          <ButtonBlock justify="right">
            <Link to="/organize/phone_banks">
              <Button.Secondary>{t('Cancel')}</Button.Secondary>
            </Link>
          </ButtonBlock>
        </Sheet>
      </ViewContainer>
      {meta && meta.current_page < meta.total_count / meta.per && (
        <ButtonBlock justify="center">
          <Button
            className="triggered-scans-list__show-more-button"
            onClick={() => setPage(page + 1)}
          >
            {t('View more')}
          </Button>
        </ButtonBlock>
      )}
    </View>
  )
}

export default () => (
  <QueryParamProvider>
    <PaginationProvider>
      <PhoneBankCopy />
    </PaginationProvider>
  </QueryParamProvider>
)
