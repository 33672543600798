import React from 'react'
import classNames from 'classnames/bind'
import { useCalendar } from '../Calendar'
import { buildItems } from './utils'
import styles from './Items.module.scss'

const cx = classNames.bind(styles)

export const DefaultRenderItem = ({ start, startInRange, end, endInRange }) => {
  let dateFormat = 'h:mma'
  if (!startInRange || !endInRange) {
    dateFormat = 'M/D h:mma'
  }

  const dateRange = [start.format(dateFormat), end.format(dateFormat)]
    .join(' – ')
    .replace(/:00/gi, '')

  return <span>{dateRange}</span>
}

const ShowMoreLink = ({ date, count }) => {
  const { navigateTo } = useCalendar()

  return (
    <button
      className={styles.more}
      type="button"
      onClick={() => {
        navigateTo(date, 'week')
      }}
    >
      + {count} more
    </button>
  )
}

const Items = ({ startDate, endDate, rowLimit, className = null }) => {
  const { items, renderItem } = useCalendar()
  const weekItems = buildItems({
    items,
    startDate,
    endDate,
    rowLimit,
  })

  return (
    <div className={cx('items', className)}>
      {weekItems.map((item, index) => (
        <div
          key={item.key || item.id || index}
          style={{
            gridColumnStart: item.startDay + 1,
            gridColumnEnd: `span ${item.endDay - item.startDay + 1}`,
          }}
          className={cx('item', {
            'item--startOverflow': !item.showMore && !item.startInRange,
            'item--endOverflow': !item.showMore && !item.endInRange,
          })}
        >
          {item.showMore ? (
            <ShowMoreLink date={item.date} count={item.showMoreCount} />
          ) : (
            renderItem(item)
          )}
        </div>
      ))}
    </div>
  )
}

export default Items
