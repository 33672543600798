import { useEffect, useState } from 'react'
import { PageHeader, Tab } from '@politechdev/blocks-design-system'
import SessionMainPanel from 'phone_banks/call_session/SessionMainPanel'
import SessionScript from 'phone_banks/call_session/active/SessionScript'
import ReviewBanner from 'phone_banks/call_session/active/ReviewBanner'
import PersonEditForm from 'people/PersonEditForm/PersonEditForm'
import PersonActivism from 'people/PersonActivism/PersonActivism'
import PersonHistory from 'people/PersonHistory/PersonHistory'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { AGENT_STATUS, useAgentState } from '../AgentContext/AgentContext'

const TAB = {
  SCRIPT: 0,
  DETAILS: 1,
  ACTIVISM: 2,
  HISTORY: 3,
}

const ActiveMainPanel = ({ handleResponseChange }) => {
  const { participant, call, status } = useAgentState()
  const { participated } = call
  const {
    currentSession: { phone_bank: phoneBank },
  } = useCallSessionContext()
  const { name: phoneBankName, script } = phoneBank

  const scriptDisabled =
    participated === false ||
    ![AGENT_STATUS.ONCALL, AGENT_STATUS.REVIEW].includes(status)
  const showReviewBanner = status === AGENT_STATUS.REVIEW

  const [activeTab, setActiveTab] = useState(TAB.SCRIPT)

  useEffect(() => {
    if (status === AGENT_STATUS.READY) {
      setActiveTab(activeTab)
    }
  }, [status])

  return (
    <SessionMainPanel layout="main">
      {showReviewBanner && <ReviewBanner />}
      {!showReviewBanner && <PageHeader title={`${phoneBankName} Script`} />}
      <div className="pb-session__script-container">
        <Tab.Container
          selectedTabId={activeTab}
          onChange={({ tabId }) => setActiveTab(tabId)}
        >
          <Tab label="Script">
            <SessionScript
              script={script}
              disabled={scriptDisabled}
              handleResponseChange={handleResponseChange}
            />
          </Tab>
          <Tab label="Participant details" disabled={!participant}>
            {!!participant && (
              <PersonEditForm personId={participant.id} hideCancel />
            )}
          </Tab>
          <Tab label="Participant activism" disabled={!participant}>
            {!!participant && <PersonActivism personId={participant.id} />}
          </Tab>
          <Tab label="Participant history" disabled={!participant}>
            {!!participant && <PersonHistory personId={participant.id} />}
          </Tab>
        </Tab.Container>
      </div>
    </SessionMainPanel>
  )
}

export default ActiveMainPanel
