export const DEFAULT_RATES = [
  {
    month: 'Jan',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Feb',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Mar',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Apr',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'May',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Jun',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'July',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Aug',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Sept',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Oct',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Nov',
    rates: [0, 0, 0, 0],
  },
  {
    month: 'Dec',
    rates: [0, 0, 0, 0],
  },
]
