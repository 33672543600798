export const getName = record =>
  [
    record.prefix,
    record.first_name,
    record.middle_name,
    record.last_name,
    record.suffix_name,
  ]
    .filter(Boolean)
    .join(' ')
