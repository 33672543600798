import React, { useContext } from 'react'
import {
  Icon,
  ContentBlock,
  TooltipContainer,
} from '@politechdev/blocks-design-system'
import { RecordContext } from '../RecordContext/RecordContext'
import styles from './FieldIndicator.module.scss'

const DiffIndicator = ({ section, value, hasDifference }) => {
  const { mainRecordDifferences } = useContext(RecordContext)

  const doesNameDiffer =
    mainRecordDifferences.first_name ||
    mainRecordDifferences.middle_name ||
    mainRecordDifferences.last_name

  const doesResidentialAddressDiffer =
    mainRecordDifferences.residential_address?.line_one ||
    mainRecordDifferences.residential_address?.line_two ||
    mainRecordDifferences.residential_address?.city ||
    mainRecordDifferences.residential_address?.state ||
    mainRecordDifferences.residential_address?.zipcode

  const sectionDifferences = {
    fullName: doesNameDiffer,
    fullResidentialAddress: doesResidentialAddressDiffer,
    ...mainRecordDifferences,
  }

  if (!sectionDifferences[section]) return null

  if (sectionDifferences[section] && hasDifference) {
    return (
      <div className={styles.container}>
        <span className={styles['container__value--error']}>
          {value || 'empty'}
        </span>
        <TooltipContainer label="Changes to record">
          <Icon.ArrowDown className={styles.container__icon} />
        </TooltipContainer>
      </div>
    )
  }

  return (
    <ContentBlock collapsed className={styles.icon}>
      <Icon.PlusSquare className={styles.container__icon} />
    </ContentBlock>
  )
}

export default DiffIndicator
