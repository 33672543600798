import { formatName } from 'utils/formatting'

export const listMemberNameCsvCell = data =>
  formatName([data.first_name, data.middle_name, data.last_name])

export const listMemberCountyCsvCell = data => data.residential_address?.county

export const listMemberZipcodeCsvCell = data =>
  data.residential_address?.zipcode

export const listMemberOrganizationsCsvCell = data =>
  data.organizations.map(organization => organization.name).join(', ')

export const listMemberTeamCsvCell = data =>
  data.teams.map(team => team.name).join(', ')
