import React from 'react'
import PropTypes from 'prop-types'
import MapGL from '@urbica/react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useMapRef } from 'contexts'
import {
  ViewportProvider,
  useViewport,
} from './ViewportProvider/ViewportProvider'
import Markers from './Markers/Markers'
import Popup from './Popup/Popup'
import Interface from './Interface/Interface'
import styles from './Map.module.scss'

const MapCanvas = ({ maxZoom, minZoom, showZoomControls, children }) => {
  const [viewport, setViewport] = useViewport()
  const mapRef = useMapRef()

  return (
    <MapGL
      {...viewport}
      ref={mapRef}
      mapStyle="mapbox://styles/eschalon/cki4sccal1yv119trycwx9kyl"
      accessToken={process.env.MAPBOX_ACCESS_TOKEN}
      onViewportChange={setViewport}
      className={styles.map}
      maxZoom={maxZoom}
      minZoom={minZoom}
      pitchWithRotate={false}
      attributionControl={false}
      dragRotate={false}
      viewportChangeMethod="flyTo"
      viewportChangeOptions={{
        duration: 1450,
        offset: [0, 100],
        easing: t => 1 - (1 - t) ** 5,
        animate: true,
        essential: false,
      }}
    >
      {showZoomControls && <Interface maxZoom={maxZoom} minZoom={minZoom} />}
      {children}
    </MapGL>
  )
}

const Map = ({ viewport, onViewportChange, ...props }) => (
  <ViewportProvider viewport={viewport} onChange={onViewportChange}>
    <MapCanvas {...props} />
  </ViewportProvider>
)

Map.Markers = Markers
Map.Popup = Popup

Map.propTypes = {
  viewport: PropTypes.object,
  onViewportChange: PropTypes.func,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  showZoomControls: PropTypes.bool,
}

Map.defaultProps = {
  maxZoom: 22,
  minZoom: 1,
  showZoomControls: true,
}

export default Map
