import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Checkbox,
  FieldBlock,
  Icon,
  TextField,
  ProgressBar,
  MenuButton,
} from '@politechdev/blocks-design-system'
import { CardError, Sticky } from 'components'
import { debounce } from 'lodash'
import RoleDetailUsers from '../RoleDetailUsers/RoleDetailUsers'
import { RoleAbilityContext } from '../RoleAbilityContextProvider/RoleAbilityContextProvider'
import styles from './RoleDetailHeader.module.scss'
import { useRoleModalActions } from '../RoleModalStateProvider/RoleModalStateProvider'
import { RoleContext } from '../RoleContext/RoleContext'

const RoleDetailHeader = () => {
  const { t } = useTranslation()

  const { currentRole, isRoleModified: isModified } = useContext(RoleContext)

  const {
    isLoading,
    errorMsg,
    saveRoleAbilities,
    setInitialAbilities,
    needsTraining,
    toggleNeedsTraining,
    setSearchString,
  } = useContext(RoleAbilityContext)

  const { openEditModal, openDeleteModal } = useRoleModalActions()
  const [localSearchString, setLocalSearchString] = useState('')

  const setSearchStringDebounced = useMemo(
    () => debounce(value => setSearchString(value), 200),
    []
  )

  useEffect(() => {
    setSearchStringDebounced(localSearchString)
  }, [localSearchString])

  return (
    <>
      <Sticky
        className={styles.header}
        stuckClassName={styles['header--stuck']}
        offset={60}
      >
        <div className={styles.loader}>
          <ProgressBar show={isLoading} />
        </div>
        <div className={styles.wrapper}>
          <CardError hide={!errorMsg} message={errorMsg} hideSupportLink />
          {isModified && (
            <ButtonBlock justify="right">
              <Button.Secondary onClick={setInitialAbilities}>
                {t('Cancel')}
              </Button.Secondary>
              <Button.Accent onClick={saveRoleAbilities}>
                {t('Save')}
              </Button.Accent>
            </ButtonBlock>
          )}
          <div className={styles.content}>
            <div className={styles.title}>
              <span>{currentRole.name}</span>
            </div>
            <div className={styles.search}>
              <FieldBlock>
                <TextField
                  type="search"
                  label={t('Permission')}
                  value={localSearchString}
                  onChange={setLocalSearchString}
                />
              </FieldBlock>
            </div>
            <div className={styles.action}>
              <MenuButton.Secondary
                label={<Icon.EllipsisV alt="role options" />}
              >
                <MenuButton.Select
                  onSelect={value => {
                    if (value === 'edit') {
                      openEditModal()
                    }
                    if (value === 'delete') {
                      openDeleteModal()
                    }
                  }}
                  options={[
                    {
                      label: t('Edit'),
                      value: 'edit',
                    },
                    {
                      label: t('Delete'),
                      value: 'delete',
                    },
                  ]}
                />
              </MenuButton.Secondary>
            </div>
          </div>
        </div>
      </Sticky>
      <div className={styles.detail}>
        <p className={styles.label}>{t('Role description')}</p>
        <p>{currentRole.description}</p>
        <Checkbox
          id={`training-${currentRole.id}`}
          checked={needsTraining}
          label={t('Available for QC training')}
          name={`${currentRole}-training`}
          onChange={toggleNeedsTraining}
        />
      </div>
      <RoleDetailUsers />
      <TextBlock>
        <p className="hint-text">
          {t("Users cannot grant permissions that they themselves don't have")}
        </p>
      </TextBlock>
    </>
  )
}

export default RoleDetailHeader
