import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ViewContainer, CardError, View } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
  Sheet,
  Section,
  DetailItem,
  Grid,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { formatPhoneNumber } from 'utils/formatting'
import { fetchLocation } from 'requests/locations'
import { useCurrent } from 'contexts/index'
import styles from './EventLocationSingle.module.scss'

const EventLocationSingle = () => {
  const { t } = useTranslation()
  const { location, match } = useReactRouter()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyLocations = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'modify',
  })

  const canViewLocations = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'view',
  })

  const [currentLocation, setCurrentLocation] = useState({})

  const { makeRequest, isLoading, hasErrors, isRequestComplete } = useRequest(
    fetchLocation,
    {
      onSuccess: ({ location }) => {
        setCurrentLocation(location)
      },
    }
  )

  useEffect(() => {
    makeRequest(match.params.id, {
      fields: [
        'id',
        'name',
        'street_address',
        'city',
        'state',
        'zipcode',
        'county',
        'has_hosted_event',
        'largest_turnout',
        'maximum_occupancy',
        'has_public_transportation',
        'parking_spots_available',
        'has_it_support',
        'has_wifi',
        'has_av',
        'has_projector',
        'notes',
        'contact_name',
        'contact_email',
        'contact_phone',
        { turf: ['id', 'name'] },
      ],
      associations: ['turf'],
    })
  }, [match.params.id])

  const locationLoaded =
    isRequestComplete &&
    !hasErrors &&
    (currentLocation.id === +match.params.id ||
      currentLocation.slug === match.params.id)

  if (!canViewLocations) {
    return (
      <CardError
        hide={canModifyLocations}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  const optionAnswers = option => (option ? t('Yes') : t('No'))

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <PageHeader title={currentLocation.name}>
            <ButtonBlock justify="right">
              <Link
                to={{
                  pathname: `${location.pathname}/edit`,
                  state: { returnPath: location.pathname },
                }}
              >
                <Button
                  aria-label={t('Edit')}
                  alt={t('Edit')}
                  className={styles.button}
                >
                  <Icon.Pencil />
                </Button>
              </Link>
            </ButtonBlock>
          </PageHeader>
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this location")}
          />
          {locationLoaded && (
            <>
              <Grid>
                <Section label={t('Location address')}>
                  <DetailItem label={t('Street address')}>
                    {currentLocation.street_address} <br />
                    {`${currentLocation.city}, `}
                    {`${currentLocation.state}, `}
                    {currentLocation.zipcode}
                  </DetailItem>
                  <DetailItem label={t('County')}>
                    {currentLocation.county}
                  </DetailItem>
                  {currentLocation.turf && (
                    <DetailItem label={t('Turf')}>
                      {currentLocation.turf.name}
                    </DetailItem>
                  )}
                </Section>
              </Grid>
              <Section label={t('Facility details')}>
                <Grid>
                  <Section>
                    <DetailItem label={t('Hosted an event previously?')}>
                      {optionAnswers(currentLocation.has_hosted_event)}
                    </DetailItem>
                    <DetailItem label={t('Biggest turnout')}>
                      {currentLocation.largest_turnout}
                    </DetailItem>
                    <DetailItem label={t('Maximum size')}>
                      {currentLocation.maximum_occupancy}
                    </DetailItem>
                    <DetailItem label={t('Near public transportation')}>
                      {optionAnswers(currentLocation.has_public_transportation)}
                    </DetailItem>
                    <DetailItem label={t('Number of available parking spots')}>
                      {currentLocation.parking_spots_available}
                    </DetailItem>
                  </Section>
                  <Section label="">
                    <DetailItem label={t('Has IT support')}>
                      {optionAnswers(currentLocation.has_it_support)}
                    </DetailItem>
                    <DetailItem label={t('Has Wifi')}>
                      {optionAnswers(currentLocation.has_wifi)}
                    </DetailItem>
                    <DetailItem label={t('Has A/V')}>
                      {optionAnswers(currentLocation.has_av)}
                    </DetailItem>
                    <DetailItem label={t('Has projector')}>
                      {optionAnswers(currentLocation.has_projector)}
                    </DetailItem>
                    <DetailItem label={t('Notes')}>
                      {currentLocation.notes
                        ? currentLocation.notes
                        : t('No notes recorded')}
                    </DetailItem>
                  </Section>
                </Grid>
              </Section>
              <Grid>
                <Section label={t('Contact information')}>
                  <DetailItem label={t('Contact person')}>
                    {currentLocation.contact_name}
                  </DetailItem>
                  <DetailItem label={t('Contact e-mail')}>
                    {currentLocation.contact_email}
                  </DetailItem>
                  <DetailItem label={t('Phone number')}>
                    {formatPhoneNumber(currentLocation.contact_phone)}
                  </DetailItem>
                </Section>
              </Grid>
            </>
          )}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default EventLocationSingle
