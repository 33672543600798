import React, { memo } from 'react'
import PropTypes from 'prop-types'

const StaticListItem = ({ render }) => <li>{render()}</li>

StaticListItem.propTypes = {
  render: PropTypes.func,
}

StaticListItem.defaultProps = {
  render: () => {},
}

export default memo(StaticListItem)
