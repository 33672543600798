export const cleanAddress = address => {
  const cleanedAddress = Object.entries(address).reduce(
    (addr, [field, value]) => {
      if (value) {
        addr[field] = value
      }
      return addr
    },
    {}
  )

  const keys = Object.keys(cleanedAddress)

  return keys.length === 0 || (keys.length === 1 && keys.includes('id'))
    ? undefined
    : cleanedAddress
}
