import React, { useContext } from 'react'
import { Icon } from '@politechdev/blocks-design-system'
import FolderBreadcrumb from '../FolderBreadcrumb/FolderBreadcrumb'
import styles from './FolderBreadcrumbs.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const FolderBreadcrumbs = () => {
  const { folders, currentFolder } = useContext(DocumentContext)

  const parentFolders = folders
    .filter(
      folder => folder.lft < currentFolder.lft && folder.rgt > currentFolder.rgt
    )
    .sort((a, b) => a.lft - b.lft)

  if (!parentFolders.length) return null

  return (
    <div className={styles.breadcrumbs}>
      {parentFolders.map((folder, index) => (
        <React.Fragment key={folder.id}>
          {index > 0 && (
            <div className={styles.chevron}>
              <Icon.ChevronRight alt="" />
            </div>
          )}
          <FolderBreadcrumb folder={folder} />
        </React.Fragment>
      ))}
    </div>
  )
}

export default FolderBreadcrumbs
