import { useTranslation } from 'react-i18next'
import { Section, List, ListItem } from 'components'
import {
  Button,
  ButtonBlock,
  Modal,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks/router'
import { maskPhone } from 'utils/inputMasks'

const DupeCheckModal = ({
  isOpen,
  setIsOpen,
  showLoading,
  canvasserCreateOrUpdateRequest,
  potentialDupes = [],
}) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const onApply = () => {
    canvasserCreateOrUpdateRequest()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      id="warn-dupe-canvasser"
      title={t('Add potential duplicate?')}
      isOpen={isOpen}
      showLoading={showLoading}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            {t(
              'Potential duplicate canvassers were found. Are you sure this canvasser is not already stored in Blocks?'
            )}
          </TextBlock>
          <List>
            {potentialDupes.map(potentialDupe => (
              <ListItem
                key={potentialDupe.id}
                primaryText={`${potentialDupe.first_name} ${potentialDupe.last_name}`}
                secondaryText={`${
                  potentialDupe.archived ? t('Archived') : t('Active')
                }`}
                AdditionalContent={maskPhone(potentialDupe.phone_number)}
                details={() => (
                  <ButtonBlock>
                    <Button.Secondary
                      onClick={() =>
                        history.push(
                          `/collect/voter_registration/canvassers/${potentialDupe.id}/edit`
                        )
                      }
                    >
                      {t('Edit')}
                    </Button.Secondary>
                  </ButtonBlock>
                )}
              />
            ))}
          </List>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={onApply}>{t('Add anyway')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default DupeCheckModal
