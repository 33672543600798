import { fetchJSON } from 'utils/req'
import {
  endOfCurrentYearYMDDash,
  formatYMDDashDate,
  startOfCurrentYearYMDDash,
} from 'utils/dateTime'

const buildChartParams = (chartType, params) => ({
  report: { ...params, type: chartType },
})

export const createChartId = (chartType, params = {}) =>
  fetchJSON(`/api/v1/charts`, 'POST', buildChartParams(chartType, params), {
    useJwt: true,
  })

export const requestChartId = ({
  type: chartType,
  userTurfId,
  currentTurfPerformsExternalQC,
  filters,
}) => {
  const { startDate, endDate, turfId } = filters

  const yearStartDate =
    chartType === 'events_by_date' ? startOfCurrentYearYMDDash : null
  const yearEndDate =
    chartType === 'events_by_date' ? endOfCurrentYearYMDDash : null

  return createChartId(chartType, {
    start_date: yearStartDate || formatYMDDashDate(startDate),
    end_date: yearEndDate || formatYMDDashDate(endDate),
    turf_id: turfId || userTurfId,
    qc_external: currentTurfPerformsExternalQC,
  }).then(({ id }) => id)
}

export const fetchChartData = id =>
  fetchJSON(
    `/api/v1/charts/${id}`,
    'GET',
    null,
    {
      useJwt: true,
    },
    {
      showStatusCode: true,
    }
  )

export const requestChart = async chartId => {
  const { statusCode, response } = await fetchChartData(chartId)
  if (statusCode === 204) {
    return
  }

  return { id: chartId, response }
}
