import { useTranslation } from 'react-i18next'
import { useDeliveryState } from '../DeliveryContext'
import styles from './DeliverySummary.module.scss'

const DeliverySummary = () => {
  const { t } = useTranslation()

  const { selectedFormIds, eligibleFormIds, filteredPackets } =
    useDeliveryState()

  const includedFormsCount = selectedFormIds.length
  const eligibleFormsCount = eligibleFormIds.length
  const eligiblePacketCount = filteredPackets.length

  const allFormsCount = filteredPackets.reduce(
    (acc, packet) =>
      acc +
      packet.scans.filter(
        scan =>
          !scan.visual_review_responses.find(
            response => response.implies_not_form === true
          )
      ).length,
    0
  )

  const ineligibleFormsCount = Math.max(allFormsCount - eligibleFormsCount, 0)
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.value}>
          {includedFormsCount.toLocaleString()}
        </div>
        <div className={styles.label}>{t('Forms included')}</div>
      </div>
      <div>
        <div className={styles.value}>
          {eligibleFormsCount.toLocaleString()}
        </div>
        <div className={styles.label}>{t('Forms eligible')}</div>
      </div>
      <div>
        <div className={styles.value}>
          {eligiblePacketCount.toLocaleString()}
        </div>
        <div className={styles.label}>{t('Packets eligible')}</div>
      </div>
      <div>
        <div className={styles.value}>
          {ineligibleFormsCount.toLocaleString()}
        </div>
        <div className={styles.label}>{t('Forms not eligible')}</div>
      </div>
    </div>
  )
}

export default DeliverySummary
