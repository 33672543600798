import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Sheet,
  Section,
  FieldBlock,
  DateField,
  SelectField,
  ProgressBar,
  Button,
  ButtonBlock,
  ContentBlock,
  Font,
  TextBlock,
  PageHeader,
} from '@politechdev/blocks-design-system'
import {
  View,
  ViewContainer,
  FilledLineChart,
  DonutChart,
  CardError,
  TurfSelectField,
} from 'components'
import {
  useCurrent,
  QueryParamProvider,
  useQueryParams,
  useTurfs,
} from 'contexts'
import moment from 'moment-timezone'
import { orderBy } from 'lodash'
import { useRequest } from 'hooks/useRequest'
import {
  fetchGoals,
  createGoalsPerDay,
  fetchGoalsPerDay,
} from 'requests/fieldManagement'
import { formatNumberAsString } from 'utils/formatting'
import { currentDateYMDDash, startOfCurrentYearYMDDash } from 'utils/dateTime'
import {
  tickFormatter,
  getTotalTarget,
  buildGoalsParams,
  formatRowsForChart,
} from '../utils/fieldManagement'
import styles from './FieldManagement.module.scss'
import { convertToDateRange } from './utils'

const series = [
  {
    dataKey: 'collected',
    color: '#59b0ee',
  },
]

const FieldManagement = () => {
  const { t } = useTranslation()
  const [chartData, setChartData] = useState([])
  const [isBlank, setIsBlank] = useState(false)
  const [goalLine, setGoalLine] = useState(0)
  const [totalCollected, setTotalCollected] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasDates, setHasDates] = useState(false)
  const {
    currentUser: {
      turf: { id: currentUserTurfId },
    },
    goalTagOptions,
  } = useCurrent()

  const { preparedTurfs } = useTurfs()

  const turfs = orderBy(preparedTurfs, ['lft'])

  const tagOptions = [{ label: 'All', value: 'All' }, ...goalTagOptions]

  const defaultStartDate = startOfCurrentYearYMDDash

  const defaultEndDate = currentDateYMDDash

  const { queryParams, setQueryParams } = useQueryParams()

  const updateQueryParams = newParams => {
    setQueryParams({
      ...queryParams,
      ...newParams,
    })
  }

  const dateRange = convertToDateRange(
    queryParams.startDate ? queryParams.startDate : defaultStartDate,
    queryParams.endDate ? queryParams.endDate : defaultEndDate
  )

  const changeDateRange = (start, end) => {
    const dateRange = convertToDateRange(start, end)

    updateQueryParams({
      startDate: dateRange.start,
      endDate: dateRange.end,
    })
  }

  const tag = queryParams.tag || 'All'
  const changeTag = tag => updateQueryParams({ tag })

  const turfId = queryParams.turfId ? Number(queryParams.turfId) : undefined
  const setTurfId = turfId => updateQueryParams({ turfId })

  const sumGoalsActuals = rows =>
    rows.reduce((sum, row) => sum + row.total_collected, 0)

  const getGoalsActuals = async id => {
    const { statusCode, response } = await fetchGoalsPerDay(id)
    if (statusCode === 204) {
      return setTimeout(() => {
        getGoalsActuals(id)
      }, 3000)
    }
    setIsLoading(false)
    const filteredActuals = response.rows.filter(
      row =>
        moment(row.date).isSameOrAfter(moment(queryParams.startDate)) &&
        moment(row.date).isSameOrBefore(moment(queryParams.endDate))
    )
    setChartData(formatRowsForChart(filteredActuals))
    setTotalCollected(sumGoalsActuals(filteredActuals))
  }

  const requestGoalsActuals = async () => {
    const { id } = await createGoalsPerDay({
      start_date: queryParams.startDate,
      end_date: queryParams.endDate,
      turf_id: turfId || currentUserTurfId,
    })
    getGoalsActuals(id)
  }

  const fetchGoalsHandlers = {
    onSuccess: response => {
      const goals = response['field_management/goals']

      if (!goals.length) {
        setIsBlank(true)
        setIsLoading(false)
        return
      }
      setIsBlank(false)
      setGoalLine(
        getTotalTarget(goals, queryParams.startDate, queryParams.endDate)
      )
      requestGoalsActuals()
    },
    onError: () => {
      setIsLoading(false)
      setErrorMessage('An error occurred while trying to get your goal')
    },
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: fetchGoalsRequest } = useRequest(
    fetchGoals,
    fetchGoalsHandlers
  )

  useEffect(() => {
    if (turfs.length) {
      if (queryParams.startDate && queryParams.endDate) {
        setHasDates(true)
        setIsLoading(true)
        setErrorMessage(null)
        fetchGoalsRequest(
          buildGoalsParams(
            queryParams.startDate,
            queryParams.endDate,
            tag === 'All' ? null : tag,
            turfId
          )
        )
      } else {
        setHasDates(false)
      }
    }
  }, [preparedTurfs, queryParams, tag, turfId])

  return (
    <View>
      <PageHeader title={t('Field management')} />
      <FieldBlock variant="medium">
        <TurfSelectField
          label={t('Turfs')}
          value={turfId || turfs[0]?.value}
          isExpanded
          onSelect={setTurfId}
          showArchived
        />
      </FieldBlock>
      <FieldBlock>
        <DateField
          label={t('Target date range')}
          type="range"
          onChange={(start, end) => changeDateRange(start, end)}
          startDate={dateRange.start}
          endDate={dateRange.end}
          clearable
        />
        <SelectField
          label={t('Tags')}
          value={tag}
          options={tagOptions}
          onSelect={changeTag}
        />
      </FieldBlock>
      <ViewContainer>
        <Sheet>
          <Section label={t('Progress to goal')}>
            <ProgressBar show={isLoading} />
            <CardError hide={!errorMessage} message={errorMessage} />
            {!hasDates && (
              <TextBlock>
                <Font.Copy variant="secondary">Select a date range</Font.Copy>
              </TextBlock>
            )}
            {isBlank && hasDates && (
              <TextBlock>
                <Font.Copy variant="secondary">
                  No goals found with the selected filters
                </Font.Copy>
              </TextBlock>
            )}
            {!!chartData.length && !isBlank && hasDates && (
              <ContentBlock className={styles.chart__container}>
                <div className={styles.chart__line}>
                  <TextBlock>
                    <Font.Copy variant="highlight">
                      Voter registrations collected
                    </Font.Copy>
                  </TextBlock>
                  <FilledLineChart
                    data={chartData}
                    reference={goalLine}
                    referenceLabel={`Goal - ${formatNumberAsString(
                      goalLine
                    )} registrations`}
                    series={series}
                    xAxisKey="date"
                    xTickFormatter={tickFormatter}
                  />
                </div>
                <div className={styles.chart__gauge}>
                  <TextBlock>
                    <Font.Copy variant="highlight">
                      Registrations Collected / Goal
                    </Font.Copy>
                  </TextBlock>
                  <DonutChart
                    value={totalCollected}
                    denominator={goalLine}
                    label={`${((totalCollected / goalLine) * 100).toFixed(0)}%`}
                    size={180}
                    colors={['#59b0ee', '#dae0e2']}
                  />
                </div>
              </ContentBlock>
            )}
          </Section>
          <ButtonBlock>
            <Link to="/field-management/goals">
              <Button>{t('Manage goals')}</Button>
            </Link>
            <Link to="/field-management/projections">
              <Button>{t('Manage projections')}</Button>
            </Link>
          </ButtonBlock>
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default () => (
  <QueryParamProvider>
    <FieldManagement />
  </QueryParamProvider>
)
