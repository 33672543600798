import React, { createContext, useContext, useState } from 'react'

const NavigationContext = createContext()

export const NavigationProvider = ({ children }) => {
  const [isExpanded, setExpanded] = useState(false)

  const toggleNavigation = () => setExpanded(e => !e)

  return (
    <NavigationContext.Provider
      value={{
        isExpanded,
        setExpanded,
        toggleNavigation,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigation = () => {
  const context = useContext(NavigationContext)
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider')
  }
  return context
}
