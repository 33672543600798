import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer } from 'components'
import { PageHeader, Sheet } from '@politechdev/blocks-design-system'
import OrganizationForm from '../OrganizationForm/OrganizationForm'

const OrganizationNew = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <PageHeader title={t('New organization')} />
          <OrganizationForm />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default OrganizationNew
