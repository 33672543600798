export const aggregateReport = ({
  report,
  label,
  nonTimelineColumns,
  aggregateKeys,
  aggregatedReport,
}) => {
  report.forEach(row => {
    const keys = Object.keys(row)
    const aggregatedRow = keys.reduce(
      (builtRow, key) =>
        nonTimelineColumns.includes(key)
          ? { ...builtRow, [key]: row[key] }
          : { ...builtRow, [`${key}_${label}`]: row[key] },
      []
    )
    aggregatedRow[`${label}_row_count`] = report.length

    const updateIndex = aggregatedReport.findIndex(row =>
      aggregateKeys.every(
        aggregateKey => row[aggregateKey] === aggregatedRow[aggregateKey]
      )
    )

    updateIndex !== -1
      ? (aggregatedReport[updateIndex] = {
          ...aggregatedReport[updateIndex],
          ...aggregatedRow,
        })
      : aggregatedReport.push(aggregatedRow)
  })
}

export const fillAggregatedReportRow = ({
  aggregatedRow,
  columnConfigs,
  totalOnlyKeys,
}) => {
  const config = [
    ...columnConfigs.filter(columnConfig => columnConfig.type === 'number'),
    ...(totalOnlyKeys ?? []).map(totalOnlyKey => ({
      dataKey: totalOnlyKey,
    })),
  ]

  const missingValues = config.reduce((missing, columnConfig) => {
    const value = aggregatedRow[columnConfig.dataKey]
    if (value === undefined) {
      return { ...missing, [columnConfig.dataKey]: 0 }
    }
    return missing
  }, [])

  return { ...aggregatedRow, ...missingValues }
}
