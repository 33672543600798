import { useContext, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Font,
  Button,
  ButtonBlock,
  FieldBlock,
  Checkbox,
  Modal,
  Section,
  SelectField,
  NumberField,
  TextBlock,
} from '@politechdev/blocks-design-system'
import slice from 'lodash/slice'
import moment from 'moment'
import { bulkUpdateWeeks } from 'utils/fieldManagement'
import useEvent from 'hooks/useEvent'
import { ProjectionContext } from '../../ProjectionContext/ProjectionContext'

const BulkChangesModal = ({ isVisible, setIsVisible, setDirty }) => {
  const { t } = useTranslation()
  const { weeklyProjectedData, setWeeklyProjectedData, weeklyActualData } =
    useContext(ProjectionContext)

  const [dailyCanvassers, setDailyCanvassers] = useState(6)
  const [applyDailyCanvassers, setApplyDailyCanvassers] = useState(true)
  const [daysCanvassingPerWeek, setDaysCanvassingPerWeek] = useState(6)
  const [applyDaysCanvassingPerWeek, setApplyDaysCanvassingPerWeek] =
    useState(true)
  const [averageFieldHours, setAverageFieldHours] = useState(6.5)
  const [applyAverageFieldHours, setApplyAverageFieldHours] = useState(true)
  const [formsPerHour, setFormsPerHour] = useState(2)
  const [applyFormsPerHour, setApplyFormsPerHour] = useState(true)
  const [startWeekIndex, setStartWeekIndex] = useState(-1)
  const [endWeekIndex, setEndWeekIndex] = useState(-1)

  useEffect(() => {
    setStartWeekIndex(-1)
    setEndWeekIndex(-1)
    setDailyCanvassers(6)
    setDaysCanvassingPerWeek(6)
    setAverageFieldHours(6.5)
    setFormsPerHour(2)
  }, [isVisible])

  const isValid =
    (dailyCanvassers || !applyDailyCanvassers) &&
    (daysCanvassingPerWeek || !applyDaysCanvassingPerWeek) &&
    (averageFieldHours || !applyAverageFieldHours) &&
    (formsPerHour || !applyFormsPerHour) &&
    (applyDailyCanvassers ||
      applyDaysCanvassingPerWeek ||
      applyAverageFieldHours ||
      applyFormsPerHour) &&
    startWeekIndex !== -1 &&
    endWeekIndex !== -1

  const startWeeks = useMemo(
    () =>
      Object.values(weeklyProjectedData)
        .map((week, i) => ({
          value: i,
          label: `Week ${i + 1} | ${moment(week.startDate).format(
            'MMM D'
          )} - ${moment(week.endDate).format('MMM D')}`,
        }))
        .filter(week => week.value >= weeklyActualData.length),
    [weeklyProjectedData]
  )

  const endWeeks = useMemo(() => {
    const weeks = slice(
      startWeeks,
      startWeekIndex + 1 - weeklyActualData.length
    )
    return weeks
  }, [startWeekIndex, startWeeks])

  const onApply = useEvent(() => {
    if (startWeekIndex < 0 || endWeekIndex <= startWeekIndex) return

    bulkUpdateWeeks({
      dailyCanvassers,
      applyDailyCanvassers,
      daysCanvassingPerWeek,
      applyDaysCanvassingPerWeek,
      averageFieldHours,
      applyAverageFieldHours,
      formsPerHour,
      applyFormsPerHour,
      startWeekIndex,
      endWeekIndex,
      weeklyProjectedData,
      setWeeklyProjectedData,
    })
    setDirty(true)
    setIsVisible(false)
  })

  return (
    <Modal
      id="bulk-goal-changes"
      title={t('Make changes to multiple weeks')}
      isOpen={isVisible}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <Font.Label>Canvassers per day</Font.Label>
          </TextBlock>
          <FieldBlock>
            <NumberField
              label="Canvassers"
              value={dailyCanvassers}
              onChange={val => setDailyCanvassers(parseInt(val))}
            />
            <Checkbox
              checked={applyDailyCanvassers}
              onChange={() => {
                setApplyDailyCanvassers(!applyDailyCanvassers)
              }}
              label={t('Apply this field')}
            />
          </FieldBlock>
        </Section>
        <Section>
          <TextBlock>
            <Font.Label>Days canvassing per week</Font.Label>
          </TextBlock>
          <FieldBlock>
            <NumberField
              label="Days"
              value={daysCanvassingPerWeek}
              onChange={val => {
                if (parseInt(val) <= 7) {
                  setDaysCanvassingPerWeek(parseInt(val))
                } else {
                  setDaysCanvassingPerWeek(daysCanvassingPerWeek)
                }
              }}
            />
            <Checkbox
              checked={applyDaysCanvassingPerWeek}
              onChange={() => {
                setApplyDaysCanvassingPerWeek(!applyDaysCanvassingPerWeek)
              }}
              label={t('Apply this field')}
            />
          </FieldBlock>
        </Section>
        <Section>
          <TextBlock>
            <Font.Label>Average field hours per canvasser</Font.Label>
          </TextBlock>
          <FieldBlock>
            <NumberField
              label="Hours"
              step="any"
              value={averageFieldHours}
              onChange={val => setAverageFieldHours(parseFloat(val))}
            />
            <Checkbox
              checked={applyAverageFieldHours}
              onChange={() => {
                setApplyAverageFieldHours(!applyAverageFieldHours)
              }}
              label={t('Apply this field')}
            />
          </FieldBlock>
        </Section>
        <Section>
          <TextBlock>
            <Font.Label>Forms per hour</Font.Label>
          </TextBlock>
          <FieldBlock>
            <NumberField
              label="Forms collected"
              step="any"
              value={formsPerHour}
              onChange={val => setFormsPerHour(parseFloat(val))}
            />
            <Checkbox
              checked={applyFormsPerHour}
              onChange={() => {
                setApplyFormsPerHour(!applyFormsPerHour)
              }}
              label={t('Apply this field')}
            />
          </FieldBlock>
        </Section>
        <Section label={t('Apply to weeks')}>
          <FieldBlock>
            <SelectField
              label="Starting week"
              value={startWeekIndex}
              onSelect={val => setStartWeekIndex(val)}
              options={startWeeks}
            />
            <SelectField
              label="Ending week"
              value={endWeekIndex}
              onSelect={val => setEndWeekIndex(val)}
              options={endWeeks}
            />
          </FieldBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary
            onClick={() => {
              setIsVisible(false)
            }}
          >
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent disabled={!isValid} onClick={onApply}>
            {t('Apply')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default BulkChangesModal
