import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Button, SelectField } from '@politechdev/blocks-design-system'
import styles from './InterpolationSelect.module.scss'
import { PARTICIPANT_KEYS } from '../InterpolatedContent/utils'

const InterpolationSelect = ({ currentText, updateText, selectionStartId }) => {
  const [isAdding, setIsAdding] = useState(false)
  const { t } = useTranslation()

  const interpolationOptions = Object.keys(PARTICIPANT_KEYS).map(key => {
    const value = PARTICIPANT_KEYS[key]
    return {
      label: value.slice(1, value.length - 1),
      value,
    }
  })

  const insertInterpolation = value => {
    const input = document.querySelector(`#${selectionStartId}`)
    const nextText = currentText.split('')
    nextText.splice(input?.selectionStart, 0, `${value}`)
    updateText(nextText.join(''))
    setIsAdding(false)
    input.focus()
  }

  return (
    <div className={styles.container}>
      {isAdding ? (
        <div>
          <SelectField
            label={t('Interpolation options')}
            onSelect={insertInterpolation}
            options={interpolationOptions}
          />
        </div>
      ) : (
        <Button onClick={() => setIsAdding(true)}>
          {t('Add interpolated value')}
        </Button>
      )}
    </div>
  )
}

export default InterpolationSelect
