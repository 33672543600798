import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  MultiSelectField,
  SelectField,
} from '@politechdev/blocks-design-system'
import countiesByState from 'utils/counties.json'
import { useTranslation } from 'react-i18next'
import { uniqueCounties } from 'utils/constants'
import { alphaNumericCompare } from 'utils/array'
import { buildOption } from './utils'

const CountySelectField = ({
  id,
  label,
  onSelect,
  state,
  states = [],
  county,
  counties = [],
  emptyMessage,
  isMulti,
  extraOptions = [],
  ...props
}) => {
  const { t } = useTranslation()

  const countyStates = state ? [state] : states

  const dedupedCounties = countyStates.length
    ? new Set(countyStates.flatMap(state => countiesByState[state] ?? []))
    : uniqueCounties

  const countyOptions = Array.from(dedupedCounties)
    .toSorted(alphaNumericCompare)
    .map(buildOption)
    .concat(extraOptions)

  const [value, setValue] = useState(county)
  const [multiValue, setMultiValue] = useState(counties)

  useEffect(() => {
    setValue(county)
  }, [county])

  useEffect(() => {
    setMultiValue(counties)
  }, [JSON.stringify(counties)])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections)
  }

  return isMulti ? (
    <MultiSelectField
      id={id}
      label={label || t('Counties')}
      options={countyOptions}
      value={multiValue}
      onSelect={handleMultiChange}
      noOptionsMessage={t('Select a state first')}
      {...props}
    />
  ) : (
    <SelectField
      id={id}
      label={label || t('County')}
      options={countyOptions}
      value={value}
      onSelect={handleChange}
      noOptionsMessage={t('Select a state first')}
      {...props}
    />
  )
}

CountySelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  county: PropTypes.string,
  state: PropTypes.string,
  shouldFetch: PropTypes.bool,
}

CountySelectField.defaultProps = {
  id: 'county',
  label: 'County',
  state: '',
  shouldFetch: false,
}

export default CountySelectField
