import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, ButtonBlock, FieldBlock } from 'components'
import { useRequest } from 'hooks'
import { sendResetEmail as sendResetEmailRequest } from 'requests/auth'
import { isValidEmail } from 'utils/inputValidations'
import AuthFrame from '../AuthFrame/AuthFrame'

const ForgotPassword = () => {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')

  const { makeRequest, isLoading, hasErrors, isRequestComplete } = useRequest(
    sendResetEmailRequest
  )
  const isSuccessful = !isLoading && !hasErrors && isRequestComplete

  const handleSubmit = e => {
    e.preventDefault()
    if (!isValidEmail(email)) return

    makeRequest(email)
  }

  return (
    <AuthFrame>
      <AuthFrame.Title>{t('Reset your password')}</AuthFrame.Title>
      <AuthFrame.Content>
        {t(
          'Enter your email address and we will send you a password reset link.'
        )}
      </AuthFrame.Content>
      {isSuccessful && (
        <AuthFrame.Success>
          {t(
            `Your password reset email has been sent. It may take up to 2 minutes for the email to arrive.`
          )}
        </AuthFrame.Success>
      )}
      {hasErrors && (
        <AuthFrame.Error>
          {t(
            'We had trouble sending your password reset email. Please try again later.'
          )}
        </AuthFrame.Error>
      )}
      {!isSuccessful && (
        <form onSubmit={handleSubmit}>
          <FieldBlock>
            <TextField
              id="Email"
              label={t('Email')}
              type="email"
              value={email}
              onChange={setEmail}
              disabled={isLoading}
              error={!!email && !isValidEmail(email)}
              errorText={t('Invalid email address')}
            />
          </FieldBlock>
          <AuthFrame.Actions>
            <ButtonBlock justify="left">
              <Button raised primary type="submit">
                {isLoading ? t('Sending reset email') : t('Send reset email')}
              </Button>
            </ButtonBlock>
          </AuthFrame.Actions>
        </form>
      )}
    </AuthFrame>
  )
}

export default ForgotPassword
