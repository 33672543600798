import phoneBankCaller from './phoneBankCaller'
import phoneBank from './phoneBank'

export const reportTypes = {
  phone_bank_caller: {
    id: 'phone_bank_caller',
    name: 'Callers',
    reportTypes: ['phone_bank_caller'],
    defaultType: 'phone_bank_caller',
    permissionId: 'phone_banks',
  },
  phone_bank: {
    id: 'phone_bank',
    name: 'Phone Banks',
    reportTypes: ['phone_bank'],
    defaultType: 'phone_bank',
    permissionId: 'phone_banks',
  },
}

export const permissionIds = ['phone_banks']

export const tableConfig = {
  ...phoneBankCaller,
  ...phoneBank,
}

export const defaultReportType = 'phone_bank_caller'
