import { useTranslation } from 'react-i18next'
import { ButtonBlock, Button, Icon } from '@politechdev/blocks-design-system'
import styles from '../ScriptItem.module.scss'

const ReadActions = ({ loading, setIsEditingItem, removeSelf }) => {
  const { t } = useTranslation()
  return (
    <div className={styles['script-item__actions']}>
      <Button.Secondary
        className={styles.button}
        disabled={loading}
        onClick={() => setIsEditingItem(true)}
        aria-label={t('Edit')}
      >
        <Icon.Pencil alt={t('Edit')} />
      </Button.Secondary>
      <Button.Secondary
        className={styles.button}
        onClick={removeSelf}
        disabled={loading}
        aria-label={t('Delete')}
      >
        <Icon.Times alt={t('Delete')} />
      </Button.Secondary>
    </div>
  )
}

const EditActions = ({ loading, setIsEditingItem, handleSaveClick }) => {
  const { t } = useTranslation()
  return (
    <div className={styles['script-item__actions']}>
      <ButtonBlock justify="right">
        <Button.Secondary
          disabled={loading}
          onClick={() => setIsEditingItem(false)}
        >
          {t('Cancel')}
        </Button.Secondary>
        <Button.Accent disabled={loading} onClick={handleSaveClick}>
          {t('Save')}
        </Button.Accent>
      </ButtonBlock>
    </div>
  )
}

const ActionsIntro = {
  Read: ReadActions,
  Edit: EditActions,
}

export default ActionsIntro
