import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useDocumentEvent } from 'hooks'
import styles from './Dropdown.module.scss'

const cx = classNames.bind(styles)

const Dropdown = forwardRef(
  (
    {
      isOpen,
      closeOnOutsideClick,
      closeOnBlur,
      onChange,
      origin,
      triggerRef,
      className,
      children,
    },
    dropdownRef
  ) => {
    const handleClick = event => {
      if (!dropdownRef.current) return
      if (!isOpen) return

      const inDropdown = dropdownRef.current.contains(event?.target)
      const inTrigger = triggerRef?.current?.contains(event?.target)

      if (!inDropdown && !inTrigger) {
        onChange && onChange(false)
      }
    }

    const handleBlur = event => {
      if (!dropdownRef.current) return
      if (!closeOnBlur) return
      if (!isOpen) return

      const inDropdown = dropdownRef.current.contains(event.relatedTarget)
      const inTrigger = triggerRef?.current?.contains(event.relatedTarget)

      if (!inDropdown && !inTrigger) {
        onChange && onChange(false)
      }
    }

    useDocumentEvent({
      event: 'click',
      active: closeOnOutsideClick && isOpen,
      handler: handleClick,
    })

    return (
      <div
        className={cx(
          'dropdown',
          {
            'dropdown--open': isOpen,
            'dropdown--left': origin === 'left',
            'dropdown--right': origin === 'right',
            'dropdown--top': origin === 'top',
          },
          className
        )}
        onBlur={handleBlur}
      >
        <div className={styles.content} ref={dropdownRef}>
          {children}
        </div>
      </div>
    )
  }
)

Dropdown.propTypes = {
  isOpen: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  closeOnBlur: PropTypes.bool,
  onChange: PropTypes.func,
  origin: PropTypes.oneOf(['left', 'top', 'right']),
  triggerRef: PropTypes.shape({ current: PropTypes.object }),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

Dropdown.defaultProps = {
  isOpen: false,
  closeOnOutsideClick: true,
  closeOnBlur: true,
  origin: 'right',
}

export default Dropdown
