import { Icon } from '@politechdev/blocks-design-system'
import classnames from 'classnames/bind'
import styles from './StaffPermissions.module.scss'

const cx = classnames.bind(styles)

const StaffPermissions = ({ permissions }) => (
  <div className={styles.permissions}>
    <div
      className={cx('permissions__icon__container', {
        'permissions__container--disabled': !permissions.visual,
      })}
    >
      <div className={styles.permissions__icon}>
        {permissions.visual ? (
          <Icon.Eye data-testid="has_visual" />
        ) : (
          <Icon.EyeSlash data-testid="not_has_visual" />
        )}
      </div>
    </div>
    <div
      className={cx('permissions__icon__container', {
        'permissions__container--disabled': !permissions.phone,
      })}
    >
      <div className={styles.permissions__icon}>
        {permissions.phone ? (
          <Icon.Phone data-testid="has_phone" />
        ) : (
          <Icon.PhoneSlash data-testid="not_has_phone" />
        )}
      </div>
    </div>
  </div>
)

export default StaffPermissions
