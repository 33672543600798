import { fetchJSON } from 'utils/req'

export const fetchAllIncomingPhoneNumbers = area_code =>
  fetchJSON(`/api/v1/voip/phone_numbers?area_code=${area_code}`, 'GET', null, {
    useJwt: true,
  })

export const createPhoneNumberWithAreaCode = areaCode =>
  fetchJSON(`/api/v1/voip/phone_numbers?area_code=${areaCode}`, 'POST', null, {
    useJwt: true,
  })
