import {
  CardError,
  LoadBar,
  Section,
  StateSelectField,
  ZipCodeField,
  CountySelectField,
  OrganizationSelectField,
} from 'components'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  TextField,
  Icon,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useState, createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { maskPhone } from 'utils/inputMasks'
import { cleanAddress } from 'utils/inputProcessing'
import { isValidEmail, VALID_PHONE_LENGTH } from 'utils/inputValidations'
import { useLocalForm } from 'hooks'
import { nonPrimaryPhoneOptions } from 'constants/people'
import styles from './CreatePartialPersonForm.module.scss'

const PartialPersonFormContext = createContext()

const CreatePartialPersonForm = ({
  languages,
  isLoading,
  hasErrors,
  onSubmit,
  onCancel,
  children,
}) => {
  const { formData: basicFormData, setField: setBasicField } = useLocalForm({
    ethnicities: [],
  })
  const { formData: addressFormData, setField: setAddressField } =
    useLocalForm()

  const [additionalPhones, setAdditionalPhones] = useState([])
  const [additionalEmails, setAdditionalEmails] = useState([])

  return (
    <PartialPersonFormContext.Provider
      value={{
        languages,
        isLoading,
        hasErrors,
        basicFormData,
        setBasicField,
        addressFormData,
        setAddressField,
        additionalPhones,
        setAdditionalPhones,
        additionalEmails,
        setAdditionalEmails,
        onSubmit,
        onCancel,
      }}
    >
      {children ?? (
        <>
          <CreatePartialPersonForm.Fields />
          <CreatePartialPersonForm.Actions />
        </>
      )}
    </PartialPersonFormContext.Provider>
  )
}

CreatePartialPersonForm.Fields = () => {
  const { t } = useTranslation()
  const {
    languages,
    isLoading,
    hasErrors,
    basicFormData,
    setBasicField,
    addressFormData,
    setAddressField,
    additionalPhones,
    setAdditionalPhones,
    additionalEmails,
    setAdditionalEmails,
  } = useContext(PartialPersonFormContext)

  const setAdditionalPhone = i => partialPhone =>
    setAdditionalPhones(prev =>
      prev.reduce((res, existingPhone, j) => {
        j === i
          ? res.push({ ...existingPhone, ...partialPhone })
          : res.push(existingPhone)
        return res
      }, [])
    )

  const setAdditionalEmail = i => partialEmail =>
    setAdditionalEmails(prev =>
      prev.reduce((res, existingEmail, j) => {
        j === i
          ? res.push({ ...existingEmail, ...partialEmail })
          : res.push(existingEmail)
        return res
      }, [])
    )

  return (
    <form>
      <LoadBar show={isLoading} />
      <CardError
        hide={!hasErrors}
        message={t('Creating this person failed.')}
      />
      <FieldBlock>
        <TextField
          id="prefix"
          label={t('Prefix')}
          value={basicFormData.prefix}
          onChange={val => setBasicField(val, 'prefix')}
        />
      </FieldBlock>
      <FieldBlock>
        <TextField
          id="first-name"
          label={t('First name')}
          required
          value={basicFormData.first_name}
          onChange={val => setBasicField(val, 'first_name')}
        />
        <TextField
          id="middle-name"
          label={t('Middle name')}
          value={basicFormData.middle_name}
          onChange={val => setBasicField(val, 'middle_name')}
        />
        <TextField
          id="last-name"
          label={t('Last name')}
          required
          value={basicFormData.last_name}
          onChange={val => setBasicField(val, 'last_name')}
        />
      </FieldBlock>
      <FieldBlock>
        <TextField
          id="suffix-name"
          label={t('Suffix')}
          value={basicFormData.suffix_name}
          onChange={val => setBasicField(val, 'suffix_name')}
        />
      </FieldBlock>
      <Section label={t('Address information')}>
        <FieldBlock>
          <TextField
            id="address-line-one"
            label={t('Address line one')}
            value={addressFormData.line_one}
            onChange={val => setAddressField(val, 'line_one')}
          />
          <TextField
            id="address-line-two"
            label={t('Address line two')}
            value={addressFormData.line_two}
            onChange={val => setAddressField(val, 'line_two')}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="city"
            label={t('City')}
            value={addressFormData.city}
            onChange={val => setAddressField(val, 'city')}
          />
          <StateSelectField
            id="state"
            label={t('State')}
            state={addressFormData.state}
            onSelect={val => setAddressField(val, 'state')}
          />
        </FieldBlock>
        <FieldBlock>
          <ZipCodeField
            id="zipcode"
            label={t('Zipcode')}
            value={addressFormData.zipcode ?? ''}
            onChange={val => setAddressField(val, 'zipcode')}
          />
          <CountySelectField
            state={addressFormData.state}
            county={addressFormData.county}
            onSelect={val => setAddressField(val, 'county')}
          />
        </FieldBlock>
      </Section>
      <Section label={t('Contact information')}>
        <FieldBlock>
          <div>
            <TextField
              type="tel"
              id="primary-phone-number"
              label={t('Primary phone number')}
              value={basicFormData.primary_phone_number}
              onChange={val => {
                setBasicField(val, 'primary_phone_number')
              }}
            />
          </div>
        </FieldBlock>
        {additionalPhones.map((additionalPhone, i) => (
          <FieldBlock className={styles.align}>
            <TextField
              type="tel"
              id={`phone-number-input-${i}`}
              label={t('Phone number')}
              value={additionalPhone.content}
              onChange={content => setAdditionalPhone(i)({ content })}
            />
            <SelectField
              id={`phone-type-input-${i}`}
              label={t('Phone type')}
              value={additionalPhone.contact_type}
              options={nonPrimaryPhoneOptions}
              onSelect={contact_type => setAdditionalPhone(i)({ contact_type })}
            />
            <Button.Secondary
              aria-label={t('Remove')}
              onClick={() =>
                setAdditionalPhones(additionalPhones =>
                  additionalPhones.filter((_, j) => j !== i)
                )
              }
            >
              <Icon.Times />
            </Button.Secondary>
          </FieldBlock>
        ))}
        <ButtonBlock>
          <Button.Secondary
            aria-label={t('Add')}
            disabled={
              !additionalPhones.every(
                additionalPhone =>
                  additionalPhone.contact_type && additionalPhone.content
              )
            }
            onClick={() =>
              setAdditionalPhones(prev => [
                ...prev,
                { contact_type: null, content: null },
              ])
            }
          >
            <Icon.Plus />
          </Button.Secondary>
        </ButtonBlock>
        <FieldBlock>
          <TextField
            type="email"
            id="primary-email-address"
            label={t('Primary email address')}
            value={basicFormData.primary_email_address}
            onChange={val => {
              setBasicField(val, 'primary_email_address')
            }}
          />
        </FieldBlock>
        {additionalEmails.map((additionalEmail, i) => (
          <FieldBlock className={styles.align}>
            <TextField
              type="email"
              id={`email-input-${i}`}
              label={t('Email')}
              value={additionalEmail.content}
              onChange={content => setAdditionalEmail(i)({ content })}
            />
            <SelectField
              id={`email-type-input-${i}`}
              label={t('Email type')}
              value={additionalEmail.contact_type}
              options={[
                { label: 'Home', value: 'home_email_address' },
                { label: 'Work', value: 'work_email_address' },
              ]}
              onSelect={contact_type => setAdditionalEmail(i)({ contact_type })}
            />
            <Button.Secondary
              aria-label={t('Remove')}
              onClick={() =>
                setAdditionalEmails(additionalEmails =>
                  additionalEmails.filter((_, j) => j !== i)
                )
              }
            >
              <Icon.Times />
            </Button.Secondary>
          </FieldBlock>
        ))}
        <ButtonBlock>
          <Button.Secondary
            aria-label={t('Add')}
            disabled={
              !additionalEmails.every(
                additionalPhone =>
                  additionalPhone.contact_type && additionalPhone.content
              )
            }
            onClick={() =>
              setAdditionalEmails(prev => [
                ...prev,
                { contact_type: null, content: null },
              ])
            }
          >
            <Icon.Plus />
          </Button.Secondary>
        </ButtonBlock>
      </Section>
      <Section label={t('Membership')}>
        <FieldBlock>
          <OrganizationSelectField
            id="organization"
            label={t('Organization')}
            onSelect={val => setBasicField(val, 'organization')}
            organization={basicFormData.organization}
            usePortal={false}
          />
        </FieldBlock>
      </Section>
      <Section label={t('Additional information')}>
        <FieldBlock>
          <SelectField
            id="primary_language"
            label={t('Primary language')}
            value={basicFormData.primary_language || ''}
            options={languages.map(language => ({
              label: language,
              value: language,
            }))}
            onSelect={val => setBasicField(val, 'primary_language')}
          />
        </FieldBlock>
      </Section>
    </form>
  )
}

CreatePartialPersonForm.Actions = () => {
  const { t } = useTranslation()
  const {
    basicFormData,
    addressFormData,
    additionalPhones,
    additionalEmails,
    onSubmit,
    onCancel,
  } = useContext(PartialPersonFormContext)

  const isFormValid =
    additionalPhones.every(
      additionalPhone =>
        additionalPhone.contact_type &&
        additionalPhone.content &&
        maskPhone(additionalPhone.content).length === VALID_PHONE_LENGTH
    ) &&
    additionalEmails.every(
      additionalEmail =>
        additionalEmail.contact_type &&
        additionalEmail.content &&
        isValidEmail(additionalEmail.content)
    ) &&
    basicFormData.first_name &&
    basicFormData.last_name &&
    (basicFormData.primary_email_address
      ? isValidEmail(basicFormData.primary_email_address)
      : true) &&
    (basicFormData.primary_phone_number
      ? maskPhone(basicFormData.primary_phone_number).length ===
        VALID_PHONE_LENGTH
      : true)

  return (
    <ButtonBlock justify="right">
      <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
      <Button.Accent
        type="submit"
        disabled={!isFormValid}
        onClick={() =>
          onSubmit({
            ...basicFormData,
            residential_address: cleanAddress(addressFormData),
            contact_methods: [...additionalPhones, ...additionalEmails],
            organization_memberships: basicFormData.organization
              ? [{ organization_id: basicFormData.organization.id }]
              : [],
          })
        }
      >
        {t('Save')}
      </Button.Accent>
    </ButtonBlock>
  )
}

export default CreatePartialPersonForm
