import React from 'react'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'

export const TemplateNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/email/template/${rowData.id}`}>{rowData.name}</Link>
  </TableGridCell>
)

export const TemplateCreatorCell = ({ rowData }) => (
  <TableGridCell>{rowData?.user?.name}</TableGridCell>
)

export const TemplateCreateAtCell = ({ rowData }) => (
  <TableGridCell>
    {moment(rowData?.created_at).format(dateFormat)}
  </TableGridCell>
)
