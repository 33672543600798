import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import {
  PhoneField,
  Button,
  CardError,
  ButtonBlock,
  ContentBlock,
  TextField,
} from 'components'
import { Modal } from '@politechdev/blocks-design-system'
import { useCurrent, useForm } from 'contexts'
import { useEvent, useRequest } from 'hooks'
import { isValidPhone } from 'utils/inputValidations'
import { formatErrorMessage } from 'utils/formatting'

const generateError = async ({ json }) => {
  const defaultError = 'Something went wrong changing your password'

  try {
    const { error } = await json

    if (error.current_password?.includes('is invalid')) {
      return 'Incorrect current password'
    }

    return defaultError
  } catch {
    return defaultError
  }
}

const UserTwoFactorNumberChangeModal = () => {
  const { t } = useTranslation()
  const { formData, setField, setFormData } = useForm()
  const { updateCurrentUser } = useCurrent()
  const [isOpen, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
    setFormData({})
  }

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(
    updateCurrentUser,
    {
      onSuccess: closeModal,
      onError: generateError,
    }
  )
  const errorMsg = formatErrorMessage(errors)

  const isFormValid =
    isValidPhone(formData.two_factor_phone) &&
    !isEmpty(formData.current_password)

  const submitForm = useEvent(() => {
    makeRequest(formData)
  })

  return (
    <>
      <Button accent flat onClick={openModal}>
        {t('Change')}
      </Button>
      <Modal
        id="user-two-factor-phone-change"
        title={t(`Change number`)}
        isOpen={isOpen}
        showLoading={isLoading}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t(errorMsg)}
              hideSupportLink
            />
          </ContentBlock>
          <ContentBlock>
            <TextField
              required
              id="current_password"
              label={t('Current password')}
              type="password"
              value={formData.current_password || ''}
              ignoreWhitespace
              onChange={val => setField(val, 'current_password')}
              error={formData.current_password?.length < 6}
              errorText={t('Must be at least 6 characters long')}
            />
          </ContentBlock>
          <ContentBlock>
            <PhoneField
              required
              id="two_factor_phone"
              label={t('Two factor phone number')}
              value={formData.two_factor_phone || ''}
              onChange={val => setField(val, 'two_factor_phone')}
            />
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button flat onClick={closeModal}>
              {t('Cancel')}
            </Button>
            <Button primary raised onClick={submitForm} disabled={!isFormValid}>
              {t('Change number')}
            </Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default UserTwoFactorNumberChangeModal
