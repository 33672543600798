import React from 'react'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { formatName } from 'utils/formatting'

export const ListMemberNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/people/${rowData.id}`}>
      {formatName([rowData.first_name, rowData.middle_name, rowData.last_name])}
    </Link>
  </TableGridCell>
)

export const ListMemberCountyCell = ({ rowData }) => (
  <TableGridCell>{rowData.residential_address?.county}</TableGridCell>
)

export const ListMemberZipcodeCell = ({ rowData }) => (
  <TableGridCell>{rowData.residential_address?.zipcode}</TableGridCell>
)

export const ListMemberOrganizationCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.organizations.map(organization => organization.name).join(', ')}
  </TableGridCell>
)

export const ListMemberTeamCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.teams.map(team => team.name).join(', ')}
  </TableGridCell>
)
