import { Button } from '@politechdev/blocks-design-system'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { useReportModalContext } from 'contexts/index'
import { useTranslation } from 'react-i18next'

const DuplicatePacketsModalCell = ({ rowData }) => {
  const { setIsOpen, setContent, setModalType, MODAL_TYPES } =
    useReportModalContext()
  const { t } = useTranslation()
  const numPackets = rowData?.packets ? rowData.packets.length : 0
  return (
    <TableGridCell>
      <Button.Secondary
        onClick={() => {
          setModalType(MODAL_TYPES.DUPLICATE_PACKETS)
          setContent({
            packets: rowData.packets.map(packet => ({
              shiftId: packet.shift_id,
              filename: packet.packet_filename,
            })),
          })
          setIsOpen(true)
        }}
      >
        {numPackets > 1 ? `${numPackets} ${t('packets')}` : `1 ${t('packet')}`}
      </Button.Secondary>
    </TableGridCell>
  )
}

export default DuplicatePacketsModalCell

export const duplicatePacketsModalCellRenderer = props => (
  <DuplicatePacketsModalCell {...props} />
)
