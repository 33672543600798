import { useRef, memo, useContext, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import isEqual from 'react-fast-compare'
import classNames from 'classnames/bind'
import { useScroll } from 'contexts'
import TurfTreeItem from '../TurfTreeItem/TurfTreeItem'
import styles from './TurfTree.module.scss'
import { TurfContext } from '../TurfContext/TurfContext'

const cx = classNames.bind(styles)

const TurfTreeLine = ({ parentId }) => {
  const { isActiveParent, activeParentId } = useContext(TurfContext)
  const activeParent = useMemo(() => isActiveParent(parentId), [activeParentId])
  return (
    <div
      className={cx('line', {
        'line--active': activeParent,
      })}
    />
  )
}

const TurfTree = memo(({ turfIds = [], parentId = null, observerRef }) => {
  const treeRef = useRef(null)
  const { startScroll, endScroll } = useScroll()

  const { moveTurf, placeTurf } = useContext(TurfContext)

  const [, dropRef] = useDrop({
    accept: ['turf'],
    hover: (item, monitor) => {
      if (!parentId) {
        const { y: yPos } = monitor.getClientOffset()

        if (yPos < 120) {
          startScroll('up')
        } else if (window.innerHeight - yPos < 120) {
          startScroll('down')
        } else {
          endScroll()
        }

        return
      }
      if (!monitor.isOver({ shallow: true })) return

      const treeBounds = treeRef.current?.getBoundingClientRect()

      const { y: yPos } = monitor.getClientOffset()
      const hoverY = yPos - treeBounds.top
      const middleY = treeBounds.height / 2

      if (hoverY < middleY) {
        moveTurf({
          turfId: item.id,
          parentId,
          nextId: turfIds[0],
        })
      } else {
        moveTurf({
          turfId: item.id,
          parentId,
          prevId: turfIds[turfIds.length - 1],
        })
      }
    },
    drop: (item, monitor) => {
      if (monitor.didDrop()) return
      placeTurf(item.id, parentId)
    },
    canDrop: item => item.id !== parentId && parentId,
  })

  return (
    <div
      ref={dropRef(treeRef)}
      className={cx('tree', {
        'tree--root': !parentId,
      })}
    >
      <TurfTreeLine parentId={parentId} />
      {turfIds.map(turfId => (
        <TurfTreeItem
          key={turfId}
          turfId={turfId}
          parentId={parentId}
          observerRef={observerRef}
        />
      ))}
    </div>
  )
}, isEqual)

export default TurfTree
