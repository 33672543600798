import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'

export const UserNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/admin/users/${rowData.id}/edit`}>{rowData.full_name}</Link>
  </TableGridCell>
)

export const UserRoleNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.role?.name}</TableGridCell>
)

export const UserTurfNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.turf?.name}</TableGridCell>
)

export const UserLastSeenCell = ({ rowData }) => (
  <TableGridCell>
    {rowData.last_sign_in_at
      ? moment(rowData.last_sign_in_at).format('MMM Do YYYY')
      : 'Never'}
  </TableGridCell>
)
