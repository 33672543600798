import React from 'react'
import { useTranslation } from 'react-i18next'
import { Font } from '@politechdev/blocks-design-system'

const ReviewBanner = () => {
  const { t } = useTranslation()

  return (
    <div className="pb-session__review-banner">
      <Font.Display>{t('Review')}</Font.Display>
    </div>
  )
}

export default ReviewBanner
