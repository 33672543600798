const usePacketDetail = (
  currentPacket,
  eligibleFormIds,
  excludedFormIds,
  updateExcluded,
  deliveryId
) => {
  const currentFormIds =
    currentPacket.scans && currentPacket.scans.map(scan => scan.id)
  const allFormsExcluded =
    currentFormIds &&
    currentFormIds.every(formId => excludedFormIds.includes(formId))
  const eligibleForms = (currentPacket.scans || []).filter(({ id }) =>
    eligibleFormIds.includes(id)
  )
  const ineligibleForms = currentPacket.scans.filter(
    scan =>
      !eligibleFormIds.includes(scan.id) ||
      scan.visual_reviews.some(
        review => review.response.implies_not_form === true
      )
  )

  const excludeForm = formId => {
    const newExcludedIds = [...excludedFormIds, formId]
    updateExcluded(deliveryId, {
      excluded_form_ids: newExcludedIds,
    })
  }
  const excludeAllForms = () => {
    const newExcludedIds = [...excludedFormIds, ...currentFormIds]
    updateExcluded(deliveryId, {
      excluded_form_ids: newExcludedIds,
    })
  }
  const includeForm = formId => {
    const newExcludedIds = excludedFormIds.filter(id => id !== formId)
    updateExcluded(deliveryId, {
      excluded_form_ids: newExcludedIds,
    })
  }
  const includeAllForms = () => {
    const newExcludedIds = excludedFormIds.filter(
      id => !currentFormIds.includes(id)
    )
    updateExcluded(deliveryId, {
      excluded_form_ids: newExcludedIds,
    })
  }
  return {
    allFormsExcluded,
    eligibleForms,
    ineligibleForms,
    excludeForm,
    excludeAllForms,
    includeForm,
    includeAllForms,
  }
}

export default usePacketDetail
