import { Section } from 'components'
import { useTranslation } from 'react-i18next'
import CallHistoryItem from '../CallHistoryItem/CallHistoryItem'

const CallHistoryList = ({ attempts }) => {
  const { t } = useTranslation()
  const sortedAttemps = attempts.sort(
    (a, b) => new Date(a.call.created_at) - new Date(b.call.created_at)
  )

  return (
    <>
      {sortedAttemps.map((attempt, i) => (
        <Section
          key={`${attempt.call.created_at}-${i}`}
          label={`${t('Phone Verification attempt')} ${i + 1}`}
        >
          <CallHistoryItem {...attempt} />
        </Section>
      ))}
    </>
  )
}

export default CallHistoryList
