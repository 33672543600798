export const buildOption = (qcEnabled, location) => {
  if (qcEnabled && location.turf?.name) {
    return {
      value: location.id,
      label: `${[location.name, location.street_address].join(' - ')} (${
        location.turf.name
      })`,
      location,
    }
  }

  return {
    value: location.id,
    label: [location.name, location.street_address].join(' - '),
    location,
  }
}

export const buildLocation = option => option.location

export const buildParams = ({ query, locationTypes, filters = [] }) => ({
  filters: {
    rules: [
      query && query !== ''
        ? {
            rules: [
              {
                column: 'name',
                operator: 'containing',
                param: query,
              },
              {
                column: 'street_address',
                operator: 'containing',
                param: query,
              },
            ],
            conjunction: 'inclusive',
          }
        : undefined,
      locationTypes && locationTypes.length > 0
        ? {
            rules: locationTypes.map(locationType => ({
              column: 'location_type',
              operator: 'is',
              param: locationType.key,
            })),
            conjunction: 'inclusive',
          }
        : undefined,
      ...filters,
    ],
    conjunction: 'exclusive',
  },
  sort_attr: 'name',
  sort_dir: 'asc',
  per: 25,
  indexed: true,
  fields: ['id', 'name', 'street_address', { turf: 'name' }],
})
