import { useTranslation } from 'react-i18next'
import { DetailItem, ExpandPanel } from 'components'
import {
  Button,
  Icon,
  ContentBlock,
  TooltipContainer,
  Font,
} from '@politechdev/blocks-design-system'
import { formatPhoneNumber, formatName } from 'utils/formatting'
import { PHONE_TYPES, PRIMARY_PHONE_CONTACT_TYPE } from 'constants/people'

import { Link } from 'react-router-dom'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { isEqual } from 'lodash'
import styles from './NaiveParticipantProfile.module.scss'

export const NaiveParticipantProfile = ({ disablePhoneSelect }) => {
  const { t } = useTranslation()

  const { currentParticipant: participant, setSelectedPhoneNumber } =
    useCallSessionContext()

  if (
    !participant ||
    isEqual(participant, {
      contact_methods: [],
    })
  ) {
    return null
  }

  const phoneNumbers = [...participant.contact_methods]
  if (participant.primary_phone_number) {
    phoneNumbers.unshift({
      contact_type: PRIMARY_PHONE_CONTACT_TYPE,
      content: participant.primary_phone_number,
    })
  }

  return (
    <div className="pb-session__profile">
      <ContentBlock>
        <span className="pb-label">{t('Participant')}</span>
        <div className={styles.name}>
          <h3>
            {formatName([
              participant.first_name,
              participant.middle_name,
              participant.last_name,
            ])}
          </h3>
          <Link to={`/organize/people/${participant.id}`} target="_blank">
            <Button.Secondary>
              <Icon.ExternalLink alt={t('External link')} />
            </Button.Secondary>
          </Link>
        </div>
        <div>
          <span>{participant.state ? participant.state : ''}</span>
        </div>
        <div>
          <span>
            {participant.polling_info?.description &&
              participant.polling_info?.address &&
              `${participant.polling_info.description.join(', ')} ${
                participant.polling_info.address
              }`}
          </span>
        </div>
        <span>{participant.gender}</span>
      </ContentBlock>
      <ExpandPanel label={t('Phone numbers')} className={styles['margin-top']}>
        <ContentBlock>
          <Font.Copy variant="hint">
            {t('Click to set as calling number')}
          </Font.Copy>
        </ContentBlock>
        {phoneNumbers.map((contact, i) => (
          <DetailItem label={t(PHONE_TYPES[contact.contact_type])} key={i}>
            {!contact.invalid ? (
              <Button.Secondary
                disabled={disablePhoneSelect}
                onClick={() => {
                  setSelectedPhoneNumber(contact.content)
                }}
              >
                <Icon.Phone />
                <span>{formatPhoneNumber(contact.content)}</span>
              </Button.Secondary>
            ) : (
              <TooltipContainer
                label={t('marked as invalid number')}
                labelPosition="top"
              >
                <Button.Secondary disabled>
                  <Icon.Phone />
                  <span className={styles['phone-number--invalid']}>
                    {formatPhoneNumber(contact.content)}
                  </span>
                </Button.Secondary>
              </TooltipContainer>
            )}
          </DetailItem>
        ))}
      </ExpandPanel>
      <ContentBlock>
        <span>
          {participant.languages.length
            ? `, ${t('speaks')} ${participant.languages.join(', ')}`
            : null}
        </span>
      </ContentBlock>
    </div>
  )
}

export default NaiveParticipantProfile
