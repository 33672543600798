export default {
  label: 'registration forms',
  columns: [
    {
      dataKey: 'display_name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterConstructor: rule => ({
        ...rule,
        column: 'by_display_name',
      }),
    },
    {
      dataKey: 'registration_date',
      title: 'Registration Date',
      type: 'date',
    },
    {
      dataKey: 'location_name',
      title: 'Location',
      type: 'string',
    },
    {
      dataKey: 'packet_original_filename',
      title: 'Packet',
      type: 'string',
      autoResize: true,
      resizable: true,
    },
    {
      dataKey: 'canvasser_full_name',
      title: 'Canvasser',
      type: 'string',
      autoResize: true,
      resizable: true,
    },
  ],
}
