import { useContext, useRef, useState } from 'react'
import { DraggableCore } from 'react-draggable'
import classNames from 'classnames/bind'
import { ScrollProvider } from 'contexts'
import { SidebarProvider } from '../SidebarProvider/SidebarProvider'
import SidebarTree from '../SidebarTree/SidebarTree'
import styles from './Sidebar.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const cx = classNames.bind(styles)

const DEFAULT_WIDTH = 350
const MIN_WIDTH = 100

const Sidebar = ({ browserRef, scrollRef }) => {
  const { folders } = useContext(DocumentContext)
  const rootFolderIds = folders
    .filter(
      folder => !folders.find(t => t.lft < folder.lft && t.rgt > folder.rgt)
    )
    .map(folder => folder.id)

  const [width, setWidth] = useState(DEFAULT_WIDTH)
  const [dragging, setDragging] = useState(false)

  const handleDrag = (event, data) => {
    const browserWidth = browserRef?.current?.clientWidth
    setWidth(w =>
      Math.min(
        Math.max(MIN_WIDTH, w - data.deltaX * -1),
        Math.round(browserWidth * 0.6)
      )
    )
    setDragging(true)
  }

  const handleStop = () => {
    setDragging(false)
  }

  return (
    <div className={styles.container}>
      <SidebarProvider>
        <>
          <div
            ref={scrollRef}
            className={styles.sidebar}
            style={{ width: `${width}px` }}
          >
            <SidebarTree childIds={rootFolderIds} />
          </div>
          <DraggableCore onDrag={handleDrag} onStop={handleStop}>
            <div
              className={cx('bar', {
                'bar--dragging': dragging,
              })}
            />
          </DraggableCore>
        </>
      </SidebarProvider>
    </div>
  )
}

export default props => {
  const scrollRef = useRef()

  return (
    <ScrollProvider speed={2} targetRef={scrollRef}>
      <Sidebar {...props} scrollRef={scrollRef} />
    </ScrollProvider>
  )
}
