import { Card, Toolbar, Button } from 'react-md'
import { DetailItem } from 'components'
import PropTypes from 'prop-types'
import styles from './ImageViewer.module.scss'

const ImageViewer = ({ label, src, alt }) => (
  <Card className={styles.viewer}>
    <Toolbar>
      <DetailItem label={label}>
        <Button
          primary
          icon
          tooltipLabel="View in new tab"
          tooltipPosition="left"
          target="_blank"
          href={src}
        >
          launch
        </Button>
      </DetailItem>
    </Toolbar>

    <div className={styles.canvas}>
      <img alt={alt} src={src} />
    </div>
  </Card>
)

ImageViewer.propTypes = {
  label: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
}

ImageViewer.defaultProps = {
  label: '',
  alt: 'Image content',
}

export default ImageViewer
