import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CanvasserSelect from '../CanvasserSelect/CanvasserSelect'
import CanvasserCreate from '../CanvasserCreate/CanvasserCreate'
import CanvasserEdit from '../CanvasserEdit/CanvasserEdit'
import CanvasserSingle from '../CanvasserSingle/CanvasserSingle'

const CanvasserRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={CanvasserSelect} />
    <Route exact path={`${match.path}/new`} component={CanvasserCreate} />
    <Route path={`${match.path}/:id/edit`} component={CanvasserEdit} />
    <Route path={`${match.path}/:id`} component={CanvasserSingle} />
  </Switch>
)

export default CanvasserRoutes
