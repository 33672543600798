import { Tab } from '@politechdev/blocks-design-system'
import {
  READY,
  CONNECTING,
  REVIEW,
  COMPLETE,
} from 'constants/phoneBankSessions'
import PersonEditForm from 'people/PersonEditForm/PersonEditForm'
import PersonHistory from 'people/PersonHistory/PersonHistory'
import PersonActivism from 'people/PersonActivism/PersonActivism'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import SessionMainPanel from '../SessionMainPanel'
import SessionScript from './SessionScript'
import ReviewBanner from './ReviewBanner'

const ActiveMainPanel = ({ hasVoip, handleResponseChange }) => {
  const {
    callStep,
    callLoading,
    currentCall: { participated, responses },
    callId,
    currentSession: {
      phone_bank: { script },
    },
    currentParticipant: { id: participantId },
    setCurrentParticipant,
  } = useCallSessionContext()
  const scriptDisabled =
    [READY, CONNECTING, COMPLETE].includes(callStep) ||
    participated === false ||
    callLoading
  const showReviewBanner = callStep === REVIEW
  return (
    <SessionMainPanel layout="main">
      {showReviewBanner && hasVoip && <ReviewBanner />}
      <div className="pb-session__script-container">
        <Tab.Container>
          <Tab label="Script">
            <SessionScript
              callId={callId}
              script={script}
              disabled={scriptDisabled}
              handleResponseChange={handleResponseChange}
              currentResponses={participated === false ? [] : responses}
            />
          </Tab>
          <Tab label="Participant details">
            <PersonEditForm
              personId={participantId}
              hideCancel
              onChange={({ primary_phone_number }) =>
                setCurrentParticipant(currentParticipant => ({
                  ...currentParticipant,
                  primary_phone_number,
                }))
              }
            />
          </Tab>
          <Tab label="Participant activism">
            <PersonActivism personId={participantId} />
          </Tab>
          <Tab label="Participant history">
            <PersonHistory personId={participantId} />
          </Tab>
        </Tab.Container>
      </div>
    </SessionMainPanel>
  )
}

export default ActiveMainPanel
