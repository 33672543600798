import {
  ButtonBlock,
  Button,
  Icon,
  ProgressBar,
  useToast,
} from '@politechdev/blocks-design-system'
import { CardError } from 'components'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import { getSortedScanIds } from 'qualityControl/contexts/contextSelectors'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import {
  useCurrentScan,
  useScanActions,
} from 'qualityControl/contexts/ScanContext'
import { useRequest } from 'hooks'

const ReviewSelectionPanel = ({ toggleIssuePanel, canReview }) => {
  const { t } = useTranslation()
  const [packet] = useCurrentPacket()
  const [scan, setCurrentScanId] = useCurrentScan()
  const { approveCurrentScan: approveScan } = useScanActions()
  const sortedScanIds = getSortedScanIds(packet)

  const { doesCurrentUserHavePermission } = useCurrent()

  const { setToast } = useToast()
  const allowedToReview =
    canReview &&
    doesCurrentUserHavePermission({
      resource: 'shift',
      ability: 'visual_qc',
    })
  const scanId = scan?.id
  const currentScanIndex = sortedScanIds.indexOf(scanId)

  const goToNextScan = () => {
    setCurrentScanId(sortedScanIds[currentScanIndex + 1])
  }

  const approveScanRequest = useRequest(approveScan, {
    onSuccess: () => {
      setToast({ message: t('Scan approved'), variant: 'success' })
      goToNextScan()
    },
  })

  return (
    <>
      <ButtonBlock justify="center">
        <Button.Danger disabled={!allowedToReview} onClick={toggleIssuePanel}>
          <span>{t('Needs attention')}</span>
          <Icon.CircleExclamation />
        </Button.Danger>
        <Button.Accent
          disabled={!allowedToReview}
          onClick={() => approveScanRequest.makeRequest()}
        >
          <span>{t('Approve form')}</span>
          <Icon.Check />
        </Button.Accent>
      </ButtonBlock>
      <ProgressBar show={approveScanRequest.isLoading} />
      <CardError hide={!approveScanRequest.hasErrors} />
    </>
  )
}

export default ReviewSelectionPanel
