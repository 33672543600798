import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormattedData } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  ContentBlock,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'
import styles from './NoteCarousel.module.scss'

const NoteCarousel = ({
  notes,
  noteIndex,
  nextNote,
  previousNote,
  toggleEditMode,
  toggleDeleteMode,
}) => {
  const { t } = useTranslation()

  if (notes.length) {
    return (
      <div>
        <ContentBlock className={styles.content}>
          <TextBlock>
            <p>{notes[noteIndex].content}</p>
          </TextBlock>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={toggleEditMode}>
              <Icon.Pencil />
            </Button.Secondary>
            <Button.Secondary onClick={toggleDeleteMode}>
              <Icon.TrashAlt />
            </Button.Secondary>
          </ButtonBlock>
        </ContentBlock>
        <ContentBlock>
          <div className={styles.author}>
            <Font.Copy variant="hint">
              - {notes[noteIndex].author}{' '}
              <FormattedData type="date" value={notes[noteIndex].created_at} />
            </Font.Copy>
          </div>
          <ButtonBlock justify="right">
            <span>
              <Font.Copy variant="hint">
                {noteIndex + 1} {t('of')} {notes.length}
              </Font.Copy>
            </span>
            <Button.Secondary onClick={previousNote} disabled={noteIndex === 0}>
              <Icon.ChevronLeft />
            </Button.Secondary>
            <Button.Secondary
              onClick={nextNote}
              disabled={noteIndex === notes.length - 1}
            >
              <Icon.ChevronRight />
            </Button.Secondary>
          </ButtonBlock>
        </ContentBlock>
      </div>
    )
  }
  return (
    <TextBlock>
      <p>0 {t('notes')}</p>
    </TextBlock>
  )
}

NoteCarousel.propTypes = {
  notes: PropTypes.array.isRequired,
  noteIndex: PropTypes.number.isRequired,
  previousNote: PropTypes.func.isRequired,
  nextNote: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  toggleDeleteMode: PropTypes.func.isRequired,
}

export default NoteCarousel
