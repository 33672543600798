import React from 'react'
import PropTypes from 'prop-types'
import styles from './Card.module.scss'

const Card = ({ title, children }) => (
  <div className={styles.card}>
    <div className={styles.card__title}>{title}</div>
    <div className={styles.card__content}>{children}</div>
  </div>
)

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

Card.defaultProps = {
  title: '',
}

export default Card
