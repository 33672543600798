import { useContext, useState } from 'react'
import { Button, Switch, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { defaultTo } from 'lodash'
import { RoleAbilityContext } from '../RoleAbilityContextProvider/RoleAbilityContextProvider'
import styles from './ReportSection.module.scss'
import { RoleContext } from '../RoleContext/RoleContext'

const ReportSection = ({ reportCategories, title }) => {
  const { toggleAbility, isChecked } = useContext(RoleAbilityContext)
  const { currentRole, currentRoleParent: parentRole } = useContext(RoleContext)
  const [isOpen, setIsOpen] = useState(true)
  const { t } = useTranslation()

  const isViewChecked = category => isChecked(`${category.permissionId}.view`)

  const isDownloadChecked = category =>
    isChecked(`${category.permissionId}.download`)

  const isSqlChecked = category => isChecked(`${category.permissionId}.sql`)

  const toggleView = category =>
    toggleAbility({ id: `${category.permissionId}.view`, value: 'view' })

  const toggleDownload = category =>
    toggleAbility({
      id: `${category.permissionId}.download`,
      value: 'download',
    })

  const toggleSql = category =>
    toggleAbility({
      id: `${category.permissionId}.sql`,
      value: 'sql',
    })

  return (
    <div className={styles.block}>
      <div className={styles.title}>
        <span>{title}</span>
        {reportCategories.length > 1 && (
          <Button.Secondary onClick={() => setIsOpen(prev => !prev)}>
            {isOpen ? (
              <>
                <span>{t('Hide')}</span> <Icon.AngleUp />
              </>
            ) : (
              <>
                <span>{t('Show')}</span> <Icon.AngleDown />
              </>
            )}
          </Button.Secondary>
        )}
      </div>
      {isOpen &&
        reportCategories.map(reportCategory => (
          <div className={styles.block} key={reportCategory.name ?? title}>
            <p>{reportCategory.name}</p>
            <div>
              <div className={styles.switch}>
                <Switch
                  key={`${reportCategory.name ?? title}-view`}
                  id={`${reportCategory.name ?? title}-view`}
                  label={t('Can view')}
                  name={t('Can view')}
                  onChange={() => {
                    toggleView(reportCategory)
                  }}
                  value={isViewChecked(reportCategory)}
                  disabled={
                    currentRole.depth !== 1 &&
                    !parentRole.admin &&
                    !defaultTo(
                      parentRole.permissions.find(
                        permission =>
                          permission.resource === reportCategory.permissionId
                      ),
                      { abilities: [] }
                    ).abilities.includes('view')
                  }
                />
              </div>
              <div className={styles.switch}>
                <Switch
                  key={`${reportCategory.name ?? title}-download`}
                  id={`${reportCategory.name ?? title}-download`}
                  label={t('Can download csv')}
                  name={t('Can download csv')}
                  onChange={() => {
                    toggleDownload(reportCategory)
                  }}
                  value={isDownloadChecked(reportCategory)}
                  disabled={
                    currentRole.depth !== 1 &&
                    !parentRole.admin &&
                    !defaultTo(
                      parentRole.permissions.find(
                        permission =>
                          permission.resource === reportCategory.permissionId
                      ),
                      { abilities: [] }
                    ).abilities.includes('download')
                  }
                />
              </div>
              <div className={styles.switch}>
                <Switch
                  key={`${reportCategory.name ?? title}-sql`}
                  id={`${reportCategory.name ?? title}-sql`}
                  label={t('Can download SQL')}
                  name={t('Can download SQL')}
                  onChange={() => {
                    toggleSql(reportCategory)
                  }}
                  value={isSqlChecked(reportCategory)}
                  disabled={
                    currentRole.depth !== 1 &&
                    !parentRole.admin &&
                    !defaultTo(
                      parentRole.permissions.find(
                        permission =>
                          permission.resource === reportCategory.permissionId
                      ),
                      { abilities: [] }
                    ).abilities.includes('sql')
                  }
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default ReportSection
