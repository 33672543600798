import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { CardError } from 'components'
import {
  Button,
  Icon,
  SelectField,
  SectionLabel,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import ReactToPrint from 'react-to-print'
import { useTranslation } from 'react-i18next'
import MonthView from './MonthView/MonthView'
import WeekView, { getWeekViewTitle } from './WeekView/WeekView'
import DayView from './DayView/DayView'
import { DefaultRenderItem } from './Items/Items'
import { getDateRange } from './utils'
import styles from './Calendar.module.scss'

const cx = classNames.bind(styles)

const CalendarContext = createContext()

export const useCalendar = () => {
  const context = useContext(CalendarContext)
  if (context === undefined) {
    throw new Error('useCalendar must be used within a Calendar')
  }
  return context
}

const Calendar = ({
  items,
  defaultDate,
  date: controlledDate,
  onDateChange,
  defaultView,
  view: controlledView,
  onViewChange,
  onRangeChange,
  renderItem,
  renderFilters,
  isLoading,
  errorMsg,
  dateKey,
  WeekDownload,
}) => {
  const { t } = useTranslation()
  const [uncontrolledView, setView] = useState(defaultView)
  const view = controlledView || uncontrolledView

  const [uncontrolledDate, setDate] = useState(defaultDate)
  const date = controlledDate || uncontrolledDate

  const weeklyDownloadRef = useRef()
  useEffect(() => {
    onViewChange && onViewChange(view)
    onDateChange && onDateChange(date)
    onRangeChange && onRangeChange(getDateRange(date, view))
  }, [])

  const handleViewChange = newView => {
    setView(newView)
    onViewChange && onViewChange(newView)
    onRangeChange && onRangeChange(getDateRange(date, newView))
  }

  const handleDateChange = newDate => {
    setDate(newDate)
    onDateChange && onDateChange(newDate)
    onRangeChange && onRangeChange(getDateRange(newDate, view))
  }

  const navigateTo = (newDate, newView) => {
    setDate(newDate)
    newView && setView(newView)
    onDateChange && onDateChange(newDate)
    newView && onViewChange && onViewChange(newView)
    onRangeChange && onRangeChange(getDateRange(newDate, newView || view))
  }

  const handlePrev = () => {
    const newDate = date.clone().subtract(1, view)
    handleDateChange(newDate)
  }

  const handleNext = () => {
    const newDate = date.clone().add(1, view)
    handleDateChange(newDate)
  }

  const handleToday = () => {
    handleDateChange(moment())
  }

  const { startDate, endDate, dateRange } = useMemo(
    () => getDateRange(date, view),
    [date, view]
  )

  const itemsInDateRange = useMemo(
    () =>
      dateKey
        ? items.filter(item => {
            const itemDate = moment(item[dateKey])
            return dateRange.some(date => date.date.isSame(itemDate, 'day'))
          })
        : items,
    [items, dateRange]
  )

  return (
    <div className={styles.container}>
      <div className={styles.loadbar}>
        <ProgressBar show={isLoading} />
      </div>
      <div
        className={cx('header', {
          'header--withFilters': !!renderFilters,
        })}
      >
        <div className={styles.title}>
          <SectionLabel>
            {view === 'month' && date.format('MMMM YYYY')}
            {view === 'week' && getWeekViewTitle({ startDate, endDate })}
            {view === 'day' && date.format('MMMM D, YYYY')}
          </SectionLabel>
        </div>
        <div className={styles.controls}>
          <SelectField
            value={view}
            onSelect={handleViewChange}
            className={styles.select}
            options={[
              {
                label: 'Month',
                value: 'month',
              },
              {
                label: 'Week',
                value: 'week',
              },
              {
                label: 'Day',
                value: 'day',
              },
            ]}
          />
          <div className={styles.navigation}>
            <Button.Secondary onClick={handlePrev}>
              <Icon.ChevronLeft alt={t('Move left')} />
            </Button.Secondary>
            <Button.Secondary onClick={handleToday}>Today</Button.Secondary>
            <Button.Secondary onClick={handleNext}>
              <Icon.ChevronRight alt={t('Move right')} />
            </Button.Secondary>
            {view === 'week' && true && (
              <ReactToPrint
                trigger={() => (
                  <Button.Secondary
                    disabled={!itemsInDateRange.length}
                    key="print"
                  >
                    <Icon.Print alt={t('Print')} />
                  </Button.Secondary>
                )}
                content={() => weeklyDownloadRef.current}
              />
            )}
          </div>
        </div>
      </div>
      {renderFilters && <div className={styles.filters}>{renderFilters()}</div>}
      <CalendarContext.Provider
        value={{
          items,
          renderItem,
          navigateTo,
        }}
      >
        <div className={styles.calendar}>
          {errorMsg && (
            <div className={styles.overlay}>
              <CardError hide={!errorMsg} message={errorMsg} hideSupportLink />
            </div>
          )}
          {view === 'month' && <MonthView dateRange={dateRange} />}
          {view === 'week' && <WeekView dateRange={dateRange} />}
          {view === 'day' && <DayView dateRange={dateRange} />}
        </div>
        <div className={styles.download}>
          {WeekDownload && !!itemsInDateRange.length && (
            <WeekDownload ref={weeklyDownloadRef} items={itemsInDateRange} />
          )}
        </div>
      </CalendarContext.Provider>
    </div>
  )
}

Calendar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      start: PropTypes.string,
      end: PropTypes.strng,
    })
  ),
  defaultDate: PropTypes.object,
  date: PropTypes.object,
  onDateChange: PropTypes.func,
  defaultView: PropTypes.oneOf(['month', 'week', 'day']),
  view: PropTypes.oneOf(['month', 'week', 'day']),
  onViewChange: PropTypes.func,
  onRangeChange: PropTypes.func,
  renderItem: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  renderFilters: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  isLoading: PropTypes.bool,
  errorMsg: PropTypes.string,
}

Calendar.defaultProps = {
  items: [],
  defaultDate: moment(),
  date: null,
  onDateChange: null,
  defaultView: 'month',
  view: null,
  onViewChange: null,
  onRangeChange: null,
  renderItem: DefaultRenderItem,
  renderFilters: null,
  isLoading: false,
  errorMsg: null,
}

export default Calendar
