import { Route, Switch } from 'react-router-dom'
import Record from '../record/Record/Record'
import Records from '../records/Records/Records'
import DuplicatePreventionDone from '../DuplicatePreventionDone/DuplicatePreventionDone'

const DuplicatePreventionRoutes = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/done`}
      component={DuplicatePreventionDone}
    />
    <Route exact path={`${match.path}`} component={Records} />
    <Route exact path={`${match.path}/:recordId`} component={Record} />
  </Switch>
)

export default DuplicatePreventionRoutes
