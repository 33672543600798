import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedData } from 'components'
import { ButtonBlock, TextBlock, Font } from '@politechdev/blocks-design-system'
import ReceiptUploader from './ReceiptUploader'
import ReviewTable from './ReviewTable'

const DeliveredStep = ({ currentDelivery }) => {
  const { t } = useTranslation()

  return (
    <>
      <TextBlock>
        <Font.Display variant="small" element="h2">
          {t('Delivered on')}{' '}
          <FormattedData type="date" value={currentDelivery.turn_in_date} />
        </Font.Display>
      </TextBlock>
      <ButtonBlock>
        <ReceiptUploader type="runner" currentDelivery={currentDelivery} />
      </ButtonBlock>
      <ReviewTable />
    </>
  )
}

export default memo(DeliveredStep)
