export const phoneBankTypes = {
  POWER: 'power_dialer',
  MANUAL: 'external',
  WEB: 'voip',
}

export const callSessionModes = {
  EXTERNAL: 'external',
  VOIP: 'voip',
  OPERATOR: 'powerdialer_operator',
  AGENT: 'powerdialer_agent',
}
