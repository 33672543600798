import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import { ChevronRight } from 'components/uiKit/icons'
import styles from './Breadcrumbs.module.scss'

const cx = classNames.bind(styles)

const Breadcrumb = ({ crumb, isLast }) => {
  const isExternal = /^https?:\/\//.test(crumb.url)

  if (isLast) {
    return <span>{crumb.text}</span>
  }
  if (isExternal) {
    return <a href={crumb.url}>{crumb.text}</a>
  }

  return <Link to={crumb.url}>{crumb.text}</Link>
}

const Breadcrumbs = ({ crumbs, showHome, className }) => {
  const { t } = useTranslation()

  const breadcrumbs = [
    showHome && { text: t('Home'), url: '/' },
    ...crumbs,
  ].filter(Boolean)

  return (
    <div className={cx('container', className)}>
      <ol className={styles.breadcrumbs}>
        {breadcrumbs.map((crumb, index) => {
          const isLast = index === breadcrumbs.length - 1

          return (
            <React.Fragment key={index}>
              <li className={styles.breadcrumb}>
                <Breadcrumb crumb={crumb} isLast={isLast} />
                {!isLast && (
                  <div className={styles.chevron}>
                    <ChevronRight />
                  </div>
                )}
              </li>
            </React.Fragment>
          )
        })}
      </ol>
    </div>
  )
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  showHome: PropTypes.bool,
  className: PropTypes.string,
}

Breadcrumbs.defaultProps = {
  crumbs: [],
  showHome: true,
}

export default Breadcrumbs
