import { packetFields } from 'requests/packets'
import { fetchJSON, stringifyParams } from 'utils/req'
import { QCStatus } from 'constants/qualityControl'
import { advanceShiftStatus } from 'requests/qualityControl'
import {
  NOT_CONTACTED_RESPONSES,
  QuestionKey,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import { scanFields } from 'requests/scans'
import { Packet, Scan } from './types'

export const fetchPacket = (packetId: number, opts = {}) =>
  fetchJSON(
    `/api/v1/packets/${packetId}?${stringifyParams({
      fields: packetFields,
    })}`,
    'GET',
    undefined,
    { useJwt: true, ...opts }
  ).then(({ voter_registration_scan_batch: packet }) => packet as Packet)

export const advancePacket = ({ shift_id }: Packet, newStatus: QCStatus) =>
  advanceShiftStatus(shift_id, newStatus)

export const fetchScan = async (scanId: number, opts = {}) => {
  const baseUrl = `/api/v1/scans/${scanId}?${stringifyParams(scanFields)}`
  const { voter_registration_scan: fetchedScan } = await fetchJSON<{
    voter_registration_scan: Scan
  }>(baseUrl, 'GET', undefined, { useJwt: true }, opts)

  fetchedScan.visual_reviews = fetchedScan.visual_reviews.map(vrr => ({
    ...vrr,
    user: vrr.user
      ? { ...vrr.user, name: vrr.user.name, full_name: vrr.user.name }
      : vrr.user,
  }))

  fetchedScan.phone_verification_responses =
    fetchedScan.phone_verification_responses.map(({ response, ...rest }) => ({
      ...rest,
      response,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'contacted?': !Object.keys(NOT_CONTACTED_RESPONSES).includes(response),
    }))

  return fetchedScan
}

export const redoVisualQc = (scan: { id: number }) =>
  fetchJSON(`/api/v1/scans/${scan.id}/redo_visual_qc`, 'PATCH', undefined, {
    useJwt: true,
  })

export const fetchPhoneVerificationQuestions = async (
  turfId: number,
  opts = {}
) => {
  const json = await fetchJSON<{
    turf: {
      phone_verification_questions: Array<{ id: number; question: QuestionKey }>
    }
  }>(
    `/api/v1/turfs/${turfId}?${stringifyParams({
      fields: [{ phone_verification_questions: ['id', 'question'] }],
    })}`,
    'GET',
    null,
    { useJwt: true },
    opts
  )

  return json.turf.phone_verification_questions
}
