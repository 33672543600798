import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components'
import { ChevronLeft, ChevronRight } from 'components/uiKit/icons'
import { usePagination } from 'contexts'
import { useLocationMap } from '../LocationMapProvider/LocationMapProvider'
import styles from './LocationMapPagination.module.scss'

const LocationMapPagination = () => {
  const { t } = useTranslation()
  const { page, pageSize, setPage } = usePagination()
  const { totalLocations } = useLocationMap()
  const totalPages = Math.ceil(totalLocations / pageSize)

  return (
    <div className={styles.container}>
      <div className={styles.meta}>
        <span className={styles.label}>
          <strong>{totalLocations.toLocaleString()} </strong>
          {totalLocations > 1 ? t('locations') : t('location')}
        </span>
      </div>
      <div className={styles.controls}>
        <Button
          floating
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
          className={styles.control}
        >
          <ChevronLeft />
        </Button>
        <span>
          {t('Page')} <strong>{page}</strong> {t('of')}{' '}
          <strong>{totalPages}</strong>
        </span>
        <Button
          floating
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
          className={styles.control}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  )
}

export default LocationMapPagination
