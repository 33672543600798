import { useEffect, useRef } from 'react'

const useInterval = (fn, delay = 10000) => {
  const functionRef = useRef()
  const intervalRef = useRef()

  useEffect(() => {
    functionRef.current = fn
  }, [fn])

  const startInterval = fn => {
    if (fn) {
      functionRef.current = fn
    }
    intervalRef.current = setInterval(functionRef.current, delay)
  }

  const resetInterval = () => {
    intervalRef.current && clearInterval(intervalRef.current)
  }

  useEffect(() => () => clearInterval(intervalRef.current), [])

  return {
    startInterval,
    resetInterval,
  }
}

export default useInterval
