import { useTranslation } from 'react-i18next'
import { Button, Font, Icon } from '@politechdev/blocks-design-system'
import { ScrollToTop } from 'components'
import ScanContainer from 'qualityControl/pledgeCardGallery/PledgeCardCompare/ScanContainer/ScanContainer'
import { usePledgeCardGalleryState } from 'qualityControl/pledgeCardGallery/PledgeCardGalleryContext/PledgeCardGalleryContext'
import styles from 'qualityControl/pledgeCardGallery/PledgeCardGallery.module.scss'
import useCompareForm from './useCompareForm'

const PledgeCardCompareHeader = () => {
  const { t } = useTranslation()
  const { firstScan } = usePledgeCardGalleryState()
  const { incrementScan, decrementScan } = useCompareForm()
  return (
    <>
      <ScrollToTop />
      <div className={styles.compare__container}>
        <ScanContainer
          scanUrl={firstScan.pledgeUrl}
          fileLocator={firstScan.fileLocator}
        >
          <>
            <Button.Secondary onClick={decrementScan}>
              <Icon.ArrowLeft />
            </Button.Secondary>
            <Font.Copy variant="highlight">
              {t('Registration')} {' #'} {firstScan.id}
            </Font.Copy>
            <Button.Secondary onClick={incrementScan}>
              <Icon.ArrowRight />
            </Button.Secondary>
          </>
        </ScanContainer>
        <ScanContainer
          scanUrl={firstScan.fileUrl}
          fileLocator={firstScan.fileLocator}
        >
          <Font.Copy variant="highlight">{t('Digital Registration')}</Font.Copy>
        </ScanContainer>
      </div>
    </>
  )
}

export default PledgeCardCompareHeader
