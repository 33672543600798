import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Section, DetailItem } from 'components'
import { EventContext } from '../EventContext/EventContext'

const GuestSummary = () => {
  const { t } = useTranslation()
  const { currentEvent } = useContext(EventContext)
  const { invited_count, attended_count, walk_in_count } = currentEvent

  return (
    <Section label={t('Current guestlist')}>
      <DetailItem label={t('Invited')}>{invited_count}</DetailItem>
      <DetailItem label={t('Attended')}>{attended_count}</DetailItem>
      <DetailItem label={t('Walk-ins')}>{walk_in_count}</DetailItem>
    </Section>
  )
}

export default GuestSummary
