import { useEffect } from 'react'
import { qcPacketFields } from 'constants/qualityControl'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { formatErrorMessage } from 'utils/formatting'
import InboxContent from './InboxContent'
import QCPacketFilterProvider from './QCPacketFilterContext/QCPacketFilterContext'
import { useQualityControl } from '../QualityControlContext'
import { useInbox } from './InboxContext'

const Inbox = () => {
  const { t } = useTranslation()

  const { getPackets } = useQualityControl()
  const { advanceReviewedPackets } = useInbox()

  const { makeRequest, isLoading, errors } = useRequest(getPackets, {
    onSuccess: () => {
      advanceReviewedPackets()
    },
  })

  const errorMsg = formatErrorMessage(errors)
  useEffect(() => {
    makeRequest({
      fields: qcPacketFields,
    })
  }, [])

  return (
    <QCPacketFilterProvider>
      <InboxContent
        isLoading={isLoading}
        errorMsg={errorMsg}
        pageTitle={t('My QC Worklist')}
      />
    </QCPacketFilterProvider>
  )
}

export default Inbox
