import { useTranslation } from 'react-i18next'
import { Font } from '@politechdev/blocks-design-system'
import PledgeCardPdfViewer from 'qualityControl/pledgeCardGallery/PledgeCardPdfViewer/PledgeCardPdfViewer'
import {
  usePledgeCardGalleryActions,
  ViewerType,
} from 'qualityControl/pledgeCardGallery/PledgeCardGalleryContext/PledgeCardGalleryContext'
import styles from './PledgeCardTile.module.scss'

const PledgeCardTile = ({ scan }) => {
  const { t } = useTranslation()
  const { setFirstScan, setViewerType, setQueryParams } =
    usePledgeCardGalleryActions()

  return (
    <div
      className={styles.tile}
      onClick={() => {
        setFirstScan(scan)
        setViewerType(ViewerType.Form)
        setQueryParams({
          type: ViewerType.Form,
          firstScanId: scan.id,
        })
      }}
    >
      <PledgeCardPdfViewer
        scanUrl={scan.pledgeUrl}
        removedAt={scan.fileLocator?.redacted_at}
      />
      <Font.Copy variant="highlight" className={styles.title}>
        {t('Registration')} {' #'} {scan.id}
      </Font.Copy>
    </div>
  )
}

export default PledgeCardTile
