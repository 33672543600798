import { useReducer, useState, useEffect } from 'react'

const generateCheckbox = (id, value) => ({
  id,
  value,
  checked: false,
})

export const useCheckboxGroup = (defaultData = []) => {
  const [checkboxGroup, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'update_checkbox_group': {
          return [...state, action.payload]
        }
        case 'replace_checkbox_group': {
          return action.payload
        }
        default: {
          return []
        }
      }
    },
    defaultData.map(data => generateCheckbox(data.id, data))
  )
  const [checkboxData, setCheckboxData] = useState(defaultData)

  const updateCheckboxGroup = checkbox => {
    dispatch({ type: 'update_checkbox_group', payload: checkbox })
  }

  const replaceCheckboxGroup = group => {
    dispatch({ type: 'replace_checkbox_group', payload: group })
  }

  const registerCheckbox = (id, value) => {
    const checkbox = generateCheckbox(id, value)
    checkboxGroup.every(checkbox => checkbox.id !== id) &&
      updateCheckboxGroup(checkbox)
  }

  useEffect(() => {
    if (checkboxData && checkboxData.length) {
      checkboxData.forEach(data => {
        registerCheckbox(data.id, data)
      })
    }
  }, [checkboxData])

  const toggleCheckboxes = ids => {
    const nextCheckboxGroup = checkboxGroup.map(checkbox => ({
      ...checkbox,
      checked: ids.includes(checkbox.id) ? !checkbox.checked : checkbox.checked,
    }))
    replaceCheckboxGroup(nextCheckboxGroup)
  }

  const toggleCheckbox = id => {
    toggleCheckboxes([id])
  }

  const existsInData = checkbox =>
    checkboxData.some(data => data.id === checkbox.id)

  const checkMultiple = ids => {
    const nextCheckboxGroup = checkboxGroup.map(checkbox => ({
      ...checkbox,
      checked: ids.includes(checkbox.id) ? true : checkbox.checked,
    }))
    replaceCheckboxGroup(nextCheckboxGroup)
  }

  const uncheckMultiple = ids => {
    const nextCheckboxGroup = checkboxGroup.map(checkbox => ({
      ...checkbox,
      checked: ids.includes(checkbox.id) ? false : checkbox.checked,
    }))
    replaceCheckboxGroup(nextCheckboxGroup)
  }

  const checkOnly = ids => {
    const nextCheckboxGroup = checkboxGroup.map(checkbox => ({
      ...checkbox,
      checked: !!ids.includes(checkbox.id),
    }))
    replaceCheckboxGroup(nextCheckboxGroup)
  }

  const checkAll = () => {
    const nextCheckboxGroup = checkboxGroup.map(checkbox => ({
      ...checkbox,
      checked: existsInData(checkbox) ? true : checkbox.checked,
    }))
    replaceCheckboxGroup(nextCheckboxGroup)
  }

  const uncheckAll = () => {
    const nextCheckboxGroup = checkboxGroup.map(checkbox => ({
      ...checkbox,
      checked: existsInData(checkbox) ? false : checkbox.checked,
    }))
    replaceCheckboxGroup(nextCheckboxGroup)
  }

  const areAllChecked = () =>
    checkboxGroup.every(checkbox =>
      existsInData(checkbox) ? checkbox.checked : true
    )

  const getCheckboxValues = () => checkboxGroup

  const getCheckedCheckboxValues = () =>
    checkboxGroup.reduce((values, checkbox) => {
      checkbox.checked && values.push(checkbox.value)
      return values
    }, [])

  const isChecked = id => {
    const checkbox = checkboxGroup.find(checkbox => checkbox.id === id)
    return checkbox?.checked || false
  }

  const clearCheckboxes = () => {
    replaceCheckboxGroup([])
  }

  return {
    areAllChecked,
    registerCheckbox,
    setCheckboxData,
    toggleCheckbox,
    toggleCheckboxes,
    checkMultiple,
    uncheckMultiple,
    checkOnly,
    checkAll,
    uncheckAll,
    getCheckboxValues,
    getCheckedCheckboxValues,
    isChecked,
    clearCheckboxes,
  }
}
