import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FormattedData } from 'components'
import { TextBlock, DetailTable, Font } from '@politechdev/blocks-design-system'
import { nonParticipationOptions } from 'constants/phoneBankSessions'

const PhoneBankLogTable = ({ log = [] }) => {
  const { t } = useTranslation()

  if (log.length === 0) {
    return (
      <TextBlock>
        <Font.Copy variant="secondary">
          {t('No phone bank activity logged')}
        </Font.Copy>
      </TextBlock>
    )
  }

  return (
    <DetailTable
      columnHeaders={[t('Phone bank'), t('Date'), t('Status'), t('Notes')]}
    >
      {log.map((contact, i) => (
        <DetailTable.Row key={i}>
          <DetailTable.Cell>
            <Link to={`/organize/phone_banks/${contact.phone_bank?.id}`}>
              {contact.phone_bank?.name}
            </Link>
          </DetailTable.Cell>
          <DetailTable.Cell>
            <FormattedData type="date" value={contact.created_at} />
          </DetailTable.Cell>
          <DetailTable.Cell>
            {contact.participated
              ? t('Participated')
              : contact.non_participation_reason &&
                t(
                  nonParticipationOptions.find(
                    option => option.value === contact.non_participation_reason
                  )?.label
                )}
          </DetailTable.Cell>
          <DetailTable.Cell>{contact.notes}</DetailTable.Cell>
        </DetailTable.Row>
      ))}
    </DetailTable>
  )
}

export default PhoneBankLogTable
