import { Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useReactRouter, useRoutePathParams } from 'hooks'
import classNames from 'classnames/bind'
import { Filters } from 'qualityControl/inbox/Filters/Filters'
import { COMPLETED_TAB } from 'constants/qualityControl'
import {
  VisualReviewPacketViewer,
  PhoneReviewPacketViewer,
} from '../scanReview/PacketViewer'
import CloseSidebarButton from './CloseSidebarButton'
import styles from './InboxViewer.module.scss'
import { useInbox } from './InboxContext'

const InboxViewer = ({ PacketList }) => {
  const { t } = useTranslation()
  const cx = classNames.bind(styles)
  const { match } = useReactRouter()
  const [{ packetId }] = useRoutePathParams()
  const { lastActiveInboxTab: activeTab } = useInbox()
  const showPacketViewer = !!packetId
  const sidebarStatusClassName = showPacketViewer ? 'inbox-viewer--open' : ''

  return (
    <div className={cx(sidebarStatusClassName, 'inbox-viewer')}>
      <div className={cx('inbox-viewer__main')}>
        {showPacketViewer ? (
          <CloseSidebarButton />
        ) : (
          <Filters show={activeTab !== COMPLETED_TAB} />
        )}
        <PacketList isSidebarOpen={showPacketViewer} />
      </div>
      <div className={cx('inbox-viewer__sidebar')}>
        <Switch>
          <Route
            exact
            path={`${match.path}/visual_qc`}
            render={() => (
              <VisualReviewPacketViewer
                title={t('Visual Quality Control')}
                isOpen={showPacketViewer}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/phone_verification`}
            render={() => (
              <PhoneReviewPacketViewer
                title={t('Phone Verification')}
                isOpen={showPacketViewer}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  )
}

export default InboxViewer
