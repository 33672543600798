import { UserSelectFieldDEPRECATED } from 'components'
import { useTranslation } from 'react-i18next'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'

const ByEventCreatorField = ({ filter }) => {
  const { setFilterValue } = useListFilters()
  const { t } = useTranslation()

  const rule = (filter?.value?.rules || []).find(
    ({ column }) => column === 'by_attended_events_with_status_and_creator'
  )

  return (
    <UserSelectFieldDEPRECATED
      id="organizer-select"
      label={t('Organizer')}
      isMulti
      onSelect={value => {
        setFilterValue(
          filter,
          {
            conjunction: 'inclusive',
            rules: [
              {
                column: 'by_attended_events_with_status_and_creator',
                param: value.map(({ id }) => id),
              },
              {
                column: 'staffed_events_created_by_user_id',
                operator: 'in',
                param: value.map(({ id }) => id),
              },
            ],
          },
          {
            labelMap: value.reduce((labelMap, user) => {
              labelMap[user.id] = user.full_name
              return labelMap
            }, {}),
          }
        )
      }}
      users={(rule?.param || []).map(id => ({
        full_name: filter?.meta?.labelMap?.[id] || '',
        id,
      }))}
      usePortal
    />
  )
}

export default ByEventCreatorField
