export const buildForm = delivery => ({
  ...delivery,
  office_id: delivery.office?.id,
})

export const buildRequest = formData => ({
  ...formData,
  canvasser_id: formData.canvasser?.id,
  turn_in_location_id: formData.turn_in_location?.id,
})
