import { useCallback, useLayoutEffect, useRef } from 'react'

// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
const useEvent = <TArgs extends Array<unknown>, TResult>(
  callbackFn: (...args: TArgs) => TResult
) => {
  const ref = useRef<typeof callbackFn | undefined>(undefined)

  useLayoutEffect(() => {
    ref.current = callbackFn
  })

  return useCallback((...args: TArgs) => {
    const callback = ref.current!
    return callback(...args)
  }, [])
}

export default useEvent
