import { useContext, useEffect } from 'react'
import { Sheet } from '@politechdev/blocks-design-system'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useBackgroundRequest } from 'hooks'
import columnConfig from './columnConfigs/CanvasserReportColumnConfig'
import { WeeklyReportContext } from '../WeeklyReportContext'

const REPORT_TYPE = 'qc_weekly_report_canvasser'

const CanvasserReport = () => {
  const { renderTable } = useAutoTable()

  const { reportMap, filters, requestReportId, requestReport } =
    useContext(WeeklyReportContext)

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const { makeRequest, isLoading, errorMsg } = useBackgroundRequest({
    key: REPORT_TYPE,
    requestId: type =>
      requestReportId(type, turfId, currentTurfPerformsExternalQC),
    requestData: requestReport,
  })

  useEffect(() => {
    if (filters.turf_id) {
      makeRequest()
    }
  }, [filters])

  const reportData = reportMap[REPORT_TYPE]?.data

  return (
    <Sheet>
      <div className="panel__header">Canvasser Report</div>
      <div className="panel__body">
        {renderTable({
          data: reportData ? reportData.rows : undefined,
          sqlQuery: reportData ? reportData.sql_query : '',
          columns: columnConfig,
          fetchAction: makeRequest,
          loading: isLoading,
          error: errorMsg,
          rowKey: 'index',
          emptyMessage: 'No Canvassers Found',
        })}
      </div>
    </Sheet>
  )
}

export default () => (
  <AutoTableProvider
    initialTableId={REPORT_TYPE}
    showDownloadButton
    enableSort
    isFrontend
    showTotals
  >
    <CanvasserReport />
  </AutoTableProvider>
)
