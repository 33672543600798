import { useTranslation } from 'react-i18next'
import { useCurrent, useAuth } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { stopImpersonatingUser } from 'requests/users'
import { formatDisplayName } from 'utils/formatting'
import styles from './ImpersonationBar.module.scss'

const ImpersonationBar = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const { currentUser: user } = useCurrent()

  const { isImpersonating, trueUser, login } = useAuth()

  const stopImpersonatingUserRequest = useRequest(stopImpersonatingUser, {
    onSuccess: response => {
      login(response.token)
      history.push('/')
    },
  })

  if (!isImpersonating) return null

  return (
    <>
      {stopImpersonatingUserRequest.hasErrors &&
        !stopImpersonatingUserRequest.isLoading && (
          <div className={styles.bar}>
            <span>
              {t(
                'Something went wrong. Please refresh the page and try again.'
              )}
            </span>
          </div>
        )}
      <div className={styles.bar}>
        <span>
          {t(
            'You ({{trueUserName}}) are currently signed in as {{userName}}.',
            {
              trueUserName: formatDisplayName(trueUser),
              userName: formatDisplayName(user),
            }
          )}
        </span>
        <button
          type="button"
          onClick={() => {
            stopImpersonatingUserRequest.makeRequest()
          }}
          disabled={stopImpersonatingUserRequest.isLoading}
        >
          {stopImpersonatingUserRequest.isLoading
            ? t('Switching back to you')
            : t('Switch back to you')}
        </button>
      </div>
    </>
  )
}

export default ImpersonationBar
