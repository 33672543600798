import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Button, Icon } from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import styles from './InteractionResponder.module.scss'

const cx = classNames.bind(styles)

const InteractionDragPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.querySelector('#interaction-portal'))

const InteractionResponder = ({
  children,
  waitForTrigger = false,
  minScale = 1,
  maxScale = 5,
  ...props
}) => {
  const [disabled, setDisabled] = useState(waitForTrigger)
  const [dragging, setDragging] = useState(false)

  const onPanningStart = () => {
    setDragging(true)
  }

  const onPanningStop = () => {
    setDragging(false)
  }

  const enableResponder = () => {
    setDisabled(false)
  }

  return (
    <div
      className={cx('container', {
        'container--disabled': disabled,
      })}
    >
      <TransformWrapper
        defaultScale={1}
        options={{
          limitToBounds: true,
          transformEnabled: true,
          limitToWrapper: false,
          disabled,
          minScale,
          maxScale,
        }}
        wheel={{
          disabled: true,
        }}
        zoomIn={{
          step: 15,
        }}
        zoomOut={{
          step: 15,
        }}
        doubleClick={{
          step: 30,
        }}
        onPanningStart={onPanningStart}
        onPanningStop={onPanningStop}
        {...props}
      >
        {({ zoomIn, zoomOut, resetTransform, scale }) => (
          <>
            {!disabled && (
              <div className={styles.interface}>
                <Button.Secondary
                  tabIndex={-1}
                  onClick={zoomIn}
                  disabled={scale >= maxScale}
                  className={styles.button}
                >
                  <Icon.MagnifyingGlassPlus />
                </Button.Secondary>
                <Button.Secondary
                  onClick={zoomOut}
                  disabled={scale <= minScale}
                  className={styles.button}
                >
                  <Icon.MagnifyingGlassMinus />
                </Button.Secondary>
                {scale > minScale && (
                  <Button.Secondary
                    onClick={resetTransform}
                    className={styles.button}
                  >
                    <Icon.Times />
                  </Button.Secondary>
                )}
              </div>
            )}
            <TransformComponent>
              {children({ enableResponder })}
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
      {!disabled && (
        <InteractionDragPortal>
          {dragging && <div className={styles.overlay} />}
        </InteractionDragPortal>
      )}
    </div>
  )
}

export default InteractionResponder
