import React from 'react'
import PropTypes from 'prop-types'
import { SelectField as ReactMdSelectField } from 'react-md'
import classNames from 'classnames/bind'

import styles from './SelectField.module.scss'

const cx = classNames.bind(styles)

const SelectField = ({ hintText, options, ...props }) => (
  <ReactMdSelectField
    {...props}
    menuItems={
      options.length
        ? options
        : [{ label: 'No options', value: null, disabled: true }]
    }
    className={cx('field', props.className)}
    helpText={hintText}
  />
)

SelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  hintText: PropTypes.string,
  itemLabel: PropTypes.string,
  itemValue: PropTypes.string,
}

SelectField.defaultProps = {
  id: '',
  label: '',
  className: '',
  itemLabel: 'label',
  itemValue: 'value',
  disabled: false,
  error: false,
  errorText: '',
  hintText: '',
  onChange: () => null,
}

export default SelectField
