import { useContext, useEffect, useMemo, useState } from 'react'
import { useBackgroundRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import moment from 'moment'
import { useCurrent } from 'contexts/index'
import WidgetPanel from 'dashboard/WidgetPanel/WidgetPanel'
import { DashboardFilterContext } from 'dashboard/DashboardFilterContext/DashboardFilterContext'
import { requestChartId, requestChart } from '../../../requests/charts'
import styles from './VoterRegOverTimeLineChart.module.scss'

const VoterRegOverTimeLineChart = () => {
  const { t } = useTranslation()

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const {
    startDate,
    endDate,
    turfId: turfIdFilter,
  } = useContext(DashboardFilterContext)

  const filters = useMemo(
    () => ({ startDate, endDate, turfId: turfIdFilter }),
    [startDate, endDate, turfIdFilter]
  )

  const [data, setData] = useState([])

  const { makeRequest, isLoading, errorMsg, response } = useBackgroundRequest({
    key: 'voter_registrations_over_time',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters,
      }),
    requestData: requestChart,
  })

  useEffect(() => {
    makeRequest()
  }, [filters])

  useEffect(() => {
    if (response) {
      setData(response.rows)
    }
  }, [response])

  if (data.length) {
    data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date))

    for (let i = 0; i < data.length; i += 1) {
      data[i].date = moment(data[i].date).format('MM/DD/YYYY')
    }
  }

  return (
    <WidgetPanel
      title={t('Total registrations over time')}
      isLoading={isLoading}
      errorMsg={errorMsg}
      isEmpty={!data.length}
    >
      <div className={styles.content}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{ left: -25, right: 5, top: 5, bottom: 5 }}
          >
            <XAxis dataKey="date" />
            <YAxis dataKey="count" domain={['dataMin', 'dataMax']} />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line
              type="monotone"
              dataKey="count"
              name="Count"
              stroke="#82ca9d"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </WidgetPanel>
  )
}

export default VoterRegOverTimeLineChart
