import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Font,
  Button,
  ButtonBlock,
  Modal,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { ManagementContext } from 'checkins/CheckinContext/ManagementContext'
import styles from './DeleteCheckinButton.module.scss'

const DeleteCheckinButton = ({ checkin, setCreatingNew }) => {
  const { t } = useTranslation()

  const { setCheckinToDelete, setCurrentTurfHasCheckin } =
    useContext(ManagementContext)

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Button.Danger onClick={() => setModalOpen(true)}>
        {t('Remove check-in')}
      </Button.Danger>
      <Modal
        id="delete-checkin"
        title={`${t('Remove check-in')}?`}
        isOpen={modalOpen}
        className={styles.modal}
      >
        <Modal.Body>
          <TextBlock>
            <Font.Copy>
              {t(
                'This will archive all the questions for this check-in and revert back to using the check-in belonging to an upper level turf if one exists.'
              )}
            </Font.Copy>
          </TextBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={() => setModalOpen(false)}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                setCurrentTurfHasCheckin(false)
                setModalOpen(false)
                setCreatingNew(false)
                setCheckinToDelete(checkin)
              }}
            >
              {t('Remove')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default DeleteCheckinButton
