import {
  calculateRate,
  calculatePercent,
  summaryFunctions,
} from 'utils/reporting'

export const baseFieldColumns = [
  {
    dataKey: 'total_scans_cover',
    title: 'Total Forms (Cover)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'total_field_hours_cover',
    title: 'Total Field Hours (Cover)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'scans_per_field_hour_cover',
    title: 'Forms per Field Hour (Cover)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculateRate(rowData.total_scans_cover, rowData.total_field_hours_cover),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customRatio(data, acc, [
        'total_scans_cover',
        'total_field_hours_cover',
      ]),
  },
  {
    dataKey: 'total_shift_hours',
    title: 'Total Shift Hours',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'total_scans',
    title: 'Total Scans',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'total_scans_qc',
    title: 'Total Forms Through QC',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'complete_scans_cover',
    title: 'Complete Forms (Cover)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'complete_scans_qc',
    title: 'Complete Forms (QC)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'incomplete_scans_cover',
    title: 'Incomplete Forms (Cover)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'incomplete_scans_qc',
    title: 'Incomplete Forms (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      rowData.total_scans_qc - rowData.complete_scans_qc,
  },
  {
    dataKey: 'scans_with_phones_cover',
    title: 'Forms with Phones (Cover)',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'scans_with_phones_qc',
    title: 'Forms with Phones (QC)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'complete_scan_percent_cover',
    title: 'Complete Form Percent (Cover)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.complete_scans_cover, rowData.total_scans_cover),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'complete_scans_cover',
        'total_scans_cover',
      ]),
  },
  {
    dataKey: 'complete_scan_percent_qc',
    title: 'Complete Form Percent (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.complete_scans_qc, rowData.total_scans_qc),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'complete_scans_qc',
        'total_scans_qc',
      ]),
  },
  {
    dataKey: 'incomplete_scan_percent_cover',
    title: 'Incomplete Form Percent (Cover)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.incomplete_scans_cover,
        rowData.total_scans_cover
      ),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'incomplete_scans_cover',
        'total_scans_cover',
      ]),
  },
  {
    dataKey: 'incomplete_scan_percent_qc',
    title: 'Incomplete Form Percent (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.total_scans_qc - rowData.complete_scans_qc,
        rowData.total_scans_qc
      ),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'incomplete_scans_qc',
        'total_scans_qc',
      ]),
  },
  {
    dataKey: 'percent_with_phones_cover',
    title: 'Percent with Phones (Cover)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.scans_with_phones_cover,
        rowData.total_scans_cover
      ),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'scans_with_phones_cover',
        'total_scans_cover',
      ]),
  },
  {
    dataKey: 'percent_with_phones_qc',
    title: 'Percent with Phones (QC)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.scans_with_phones_qc, rowData.total_scans_qc),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'scans_with_phones_qc',
        'total_scans_qc',
      ]),
  },
  {
    dataKey: 'total_pledge_cards',
    title: 'Total Pledge Cards',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
]
