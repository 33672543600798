import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './LoadBar.module.scss'

const cx = classNames.bind(styles)

const LoadBar = ({ show, progress, className }) => {
  if (!show) return null

  return (
    <div className={cx('container', className)} data-testid="loadbar">
      <div
        className={cx('progress', {
          'progress--mock': !progress,
        })}
        style={progress ? { width: `${progress}%` } : {}}
      />
    </div>
  )
}

LoadBar.propTypes = {
  show: PropTypes.bool,
  progress: PropTypes.number,
  className: PropTypes.string,
}

LoadBar.defaultProps = {
  show: false,
  progress: null,
  className: '',
}

export default LoadBar
