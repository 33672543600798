export const REGISTRATION_REPORTS_ALL_TIME_START_DATE = 'Aug 1, 2017'

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

export const TRUE_FALSE_OPTIONS = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
]
