import { useState, useEffect, useContext } from 'react'
import { CardError, Sheet, LoadBar } from 'components'
import { useTranslation } from 'react-i18next'
import {
  Icon,
  Font,
  Button,
  ButtonBlock,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { fetchGoals } from 'requests/fieldManagement'
import { useRequest } from 'hooks/useRequest'
import moment from 'moment'
import { orderBy } from 'lodash'
import { useTurfs } from 'contexts/index'
import styles from './GoalList.module.scss'
import GoalListItem from './GoalListItem'
import { buildGoalsParams } from '../../utils/fieldManagement'
import GoalListContextProvider, { GoalListContext } from './GoalListContext'

const GoalList = ({ startDate, endDate, tag }) => {
  const { t } = useTranslation()
  const { preparedTurfs, refreshCurrentTurfs, turfRefreshComplete } = useTurfs()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const { turfMap, setTurfMap } = useContext(GoalListContext)
  const { setGoalsByTurfMap, goalsByTurfMap } = useContext(GoalListContext)
  const { selectedTurf, setSelectedTurf } = useContext(GoalListContext)

  const sumValues = (prevCount, currentValue) =>
    prevCount + (typeof currentValue === 'number' ? currentValue : 0)

  const calcFormsCollected = targets =>
    targets.map(target => target.forms_collected || 0).reduce(sumValues, 0)

  const calcFormsToCollect = targets =>
    targets.map(target => target.forms_to_collect || 0).reduce(sumValues, 0)

  const calcProgress = targets => {
    const formsToCollect = targets
      .map(target => target.forms_to_collect || 0)
      .reduce(sumValues, 0)
    const formsCollected = targets
      .map(target => target.forms_collected || 0)
      .reduce(sumValues, 0)
    const progressPercentage = (formsCollected / formsToCollect) * 100

    return progressPercentage
  }

  const newTurfMap = {}

  const arrangeGoalsByTurf = goalsResp => {
    const goalMap = {}
    const newGoalsByTurfMap = {}

    const setGoalMap = goal => {
      goalMap[goal.id] = {
        id: goal.id,
        startDate: goal.start_date,
        endDate: goal.end_date,
        tags: goal.labels,
        formsCollected: calcFormsCollected(goal.targets),
        formsToCollect: calcFormsToCollect(goal.targets),
        progressPercentage: calcProgress(goal.targets),
        turfId: goal.turf_id,
        parentId: goal.parent_id,
        isAggregate: false,
      }
    }

    const createGoalByTurfMap = goal => {
      newGoalsByTurfMap[goal.turf_id] = newGoalsByTurfMap[goal.turf_id] || []
      newGoalsByTurfMap[goal.turf_id].push({
        id: goal.id,
        startDate: goal.start_date,
        endDate: goal.end_date,
        tags: goal.labels,
        formsCollected: calcFormsCollected(goal.targets),
        formsToCollect: calcFormsToCollect(goal.targets),
        progressPercentage: calcProgress(goal.targets),
        turfId: goal.turf_id,
        parentId: goal.parent_id,
        isAggregate: false,
      })
    }

    goalsResp.forEach(goal => {
      setGoalMap(goal)
      createGoalByTurfMap(goal)
    })

    const allGoalIds = Object.keys(goalMap)

    const getAggregateGoalIds = turfId => {
      const goalsToAggregate = []

      allGoalIds.forEach(goalId => {
        const goalTurfId = goalMap[goalId].turfId
        let reachedTop = false
        let currentTurfId = newTurfMap[goalTurfId].id

        while (reachedTop === false) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          if (+newTurfMap[currentTurfId]?.parentId === +turfId) {
            goalsToAggregate.push(+goalId)
          }

          if (!newTurfMap[currentTurfId]?.parentId) {
            reachedTop = true
          }

          currentTurfId = newTurfMap[currentTurfId]?.parentId
        }
      })

      return goalsToAggregate
    }

    const addAggregateGoal = (goalIds, turfId) => {
      const startDate = goalIds.reduce(
        (prevStartDate, currentStartDate, index) => {
          const currentGoalId = goalIds[index]
          const currentGoal = goalMap[currentGoalId]

          return moment(currentGoal.startDate).isBefore(prevStartDate)
            ? currentGoal.startDate
            : prevStartDate
        },
        goalMap[goalIds[0]].startDate
      )

      const endDate = goalIds.reduce((prevEndDate, currentendDate, index) => {
        const currentGoalId = goalIds[index]
        const currentGoal = goalMap[currentGoalId]

        return moment(currentGoal.endDate).isAfter(prevEndDate)
          ? currentGoal.endDate
          : prevEndDate
      }, goalMap[goalIds[0]].endDate)

      const formsCollected = goalIds
        .map(goalId => goalMap[goalId].formsCollected)
        .reduce(sumValues, 0)

      const formsToCollect = goalIds
        .map(goalId => goalMap[goalId].formsToCollect)
        .reduce(sumValues, 0)

      const tags = [
        ...new Set(
          goalIds
            .map(goalId => goalMap[goalId].tags)
            .reduce((prevSet, currentSet) => prevSet.concat(currentSet), [])
        ),
      ]

      const progressPercentage = (formsCollected / formsToCollect) * 100

      setIsLoading(false)

      return [
        {
          id: `turf-${turfId}-aggregate`,
          startDate,
          endDate,
          tags,
          formsCollected,
          formsToCollect,
          progressPercentage,
          turfId,
          parentId: newTurfMap[turfId].parentId,
          isAggregate: true,
        },
      ]
    }

    const allTurfIds = Object.keys(newTurfMap)

    allTurfIds.forEach(turfId => {
      const turfHasGoal = !!newGoalsByTurfMap[turfId]?.length
      if (turfHasGoal) return

      const aggregateGoalIds = getAggregateGoalIds(turfId)

      if (aggregateGoalIds.length) {
        newGoalsByTurfMap[turfId] = addAggregateGoal(aggregateGoalIds, turfId)
      }
    })

    setGoalsByTurfMap(newGoalsByTurfMap)
  }

  const { makeRequest: makeGetGoalsReq } = useRequest(
    (startDate, endDate, tag) =>
      fetchGoals(buildGoalsParams(startDate, endDate, tag)),
    {
      onSuccess: response => {
        setIsLoading(false)
        arrangeGoalsByTurf(response['field_management/goals'])
      },
      onError: () => {
        setIsLoading(false)
        setErrorMessage(
          t('An internal error occurred while trying to fetch your goal data')
        )
      },
    }
  )

  useEffect(() => {
    setIsLoading(true)
    setErrorMessage(false)
    refreshCurrentTurfs()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    setErrorMessage(false)
    refreshCurrentTurfs()
  }, [startDate, endDate, tag])

  useEffect(() => {
    if (turfRefreshComplete) {
      const preparedTurfsByLft = orderBy(preparedTurfs, ['lft'])
      const addTurfToMap = turf => {
        newTurfMap[turf.id] = {
          id: turf.id,
          name: turf.name,
          parentId: turf.parent_id,
          collectsRegistrations: false,
          vrEnabled: turf.voter_registration_enabled,
        }
      }
      preparedTurfsByLft.forEach(addTurfToMap)
      setTurfMap(newTurfMap)
      setSelectedTurf(newTurfMap[preparedTurfsByLft[0].id])
      makeGetGoalsReq(startDate, endDate, tag)
    }
  }, [preparedTurfs, turfRefreshComplete])

  if (!selectedTurf || !turfMap) {
    return <CardError hide={!errorMessage} message={errorMessage} />
  }

  return (
    <div>
      <CardError hide={!errorMessage} message={errorMessage} />
      <LoadBar show={isLoading} />
      {turfMap[selectedTurf.parentId] && (
        <ButtonBlock>
          <Button
            onClick={() => {
              setSelectedTurf(turfMap[selectedTurf.parentId])
            }}
          >
            <Icon.ChevronLeft />{' '}
            <span>{turfMap[selectedTurf.parentId]?.name}</span>
          </Button>
        </ButtonBlock>
      )}
      {!Object.values(goalsByTurfMap).length && !isLoading ? (
        <Sheet>
          <TextBlock>
            <Font.Copy variant="secondary">No goals found</Font.Copy>
          </TextBlock>
        </Sheet>
      ) : (
        <Sheet>
          <GoalListItem turf={selectedTurf} />
          <div className={styles.list__secondary}>
            <div className={styles.list__secondary__pane}>
              <Font.Display className={styles.list__secondary__icon}>
                <Icon.SubdirectoryArrowRight />
              </Font.Display>
            </div>
            <div className={styles.list__secondary__container}>
              {Object.values(turfMap)
                .filter(turf => turf.parentId === selectedTurf.id)
                .map(turf => (
                  <GoalListItem key={turf.id} secondary turf={turf} />
                ))}
            </div>
          </div>
        </Sheet>
      )}
    </div>
  )
}

export default props => (
  <GoalListContextProvider>
    <GoalList {...props} />
  </GoalListContextProvider>
)
