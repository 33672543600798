import PropTypes from 'prop-types'
import { Button } from 'components'
/* eslint-disable */
import { FontIcon } from 'react-md'
import { isValidElement } from 'react'

const ListItem = ({
  listItemNumber,
  primaryText,
  secondaryText,
  customIcon,
  primaryIcon,
  primaryIconClassName,
  details,
  className,
  onClick,
  removable,
  onRemove,
  editable,
  onEdit,
  actions,
  isActive,
  Checkbox,
  read,
  showAlertIcon,
  AdditionalContent,
}) => {
  const itemClass = `list__item ${onClick ? 'list__item--selectable ' : ''}${
    isActive ? 'list__item--selected ' : ''
  }${className || ''}`

  const statusIcon =
    read === true ? <i className="read-icon" /> : <i className="unread-icon" />
  const detailContent = []
  const iconContent = []

  if (primaryIcon) {
    iconContent.push(
      <FontIcon
        iconClassName="material-icons-outlined"
        key={`${primaryIcon}-${iconContent.length}`}
      >
        {primaryIcon}
      </FontIcon>
    )
  }

  if (primaryIconClassName) {
    iconContent.push(
      <FontIcon
        className={primaryIconClassName}
        key={`${primaryIconClassName}-${iconContent.length}`}
      />
    )
  }

  if (typeof details === 'function') {
    detailContent.push(
      <div key={`details-${detailContent.length}`}>{details()}</div>
    )
  } else if (isValidElement(details)) {
    detailContent.push(details)
  } else if (removable || editable) {
    if (removable) {
      detailContent.push(
        <Button
          key={`removeButton-${detailContent.length}`}
          icon
          tooltipLabel="remove"
          onClick={onRemove}
        >
          remove_circle_outline
        </Button>
      )
    }
    if (editable) {
      detailContent.push(
        <Button
          key={`editButton-${detailContent.length}`}
          icon
          tooltipLabel="edit"
          onClick={onEdit}
        >
          edit
        </Button>
      )
    }
  } else if (typeof actions === 'function') {
    detailContent.push(actions())
  } else if (isValidElement(actions)) {
    detailContent.push(actions)
  }

  return (
    <div
      className={itemClass}
      role={onClick && 'button'}
      onClick={onClick}
      onKeyPress={e => e.charCode === 13 && onClick}
    >
      {Checkbox}
      <div className="list__item__content">
        {listItemNumber !== undefined && listItemNumber}
        {read !== undefined && statusIcon}
        {customIcon !== undefined && customIcon}
        {iconContent.length > 0 && (
          <span className="primary-icon">{iconContent}</span>
        )}
        <div className="text-content">
          <div>
            <div key={`primaryText-0`} className="primary">
              {primaryText}
              <div className="list__item__alert-icon">
                {showAlertIcon && <i className="alert-icon" />}
              </div>
            </div>
          </div>
          {secondaryText !== undefined && (
            <div
              className="secondary"
              key={`${secondaryText}-${secondaryText?.length}`}
            >
              {secondaryText}
            </div>
          )}
        </div>
      </div>
      <div className="list__item__details">
        <div className="list__item__details__additional-content">
          {AdditionalContent}
        </div>
        <div className="list__item__details__additional-actions">
          {detailContent}
        </div>
      </div>
    </div>
  )
}

ListItem.propTypes = {
  listItemNumber: PropTypes.number,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  primaryIcon: PropTypes.string,
  primaryIconClassName: PropTypes.string,
  details: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func,
  removable: PropTypes.bool,
  onRemove: PropTypes.func,
  actions: PropTypes.func,
  Checkbox: PropTypes.func,
  isActive: PropTypes.bool,
  read: PropTypes.bool,
  showAlertIcon: PropTypes.bool,
  AdditionalContent: PropTypes.object,
}

ListItem.defaultProps = {
  listItemNumber: '',
  primaryText: '',
  secondaryText: '',
  primaryIcon: '',
  primaryIconClassName: '',
  details: null,
  className: '',
  onClick: null,
  removable: false,
  onRemove: null,
  actions: null,
  Checkbox: null,
  isActive: false,
  read: undefined,
  showAlertIcon: false,
  AdditionalContent: null,
}

export default ListItem
