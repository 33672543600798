import { useState, useEffect } from 'react'
import { CardError } from 'components'
import { TextBlock, Section, Tab } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { generateCheckboxGroupProvider } from 'providers/NaiveCheckboxGroupProvider'
import { formatErrorMessage } from 'utils/formatting'
import { PendingTableProgramReviewList } from '../TableProgramReviewList'
import TableProgramFilters from '../TableProgramFilters'
import GroupQcActionList from '../GroupQcActionList'
import ResolvedList from '../ResolvedList'
import { useFlagsFilteredListContext } from '../contexts/FlagsFilteredListContext'

const AwaitingTableReviewBlankSlate = () => {
  const { t } = useTranslation()
  return (
    <Section secondary collapsed>
      <TextBlock>{t('There are no flags awaiting table review')}</TextBlock>
    </Section>
  )
}

const AwaitingResolutionBlankSlate = () => {
  const { t } = useTranslation()
  return (
    <Section secondary collapsed>
      <TextBlock>{t('There are no flags awaiting resolution')}</TextBlock>
    </Section>
  )
}

const [TableReviewCheckboxGroupContext, TableReviewCheckboxGroupProvider] =
  generateCheckboxGroupProvider()

const [
  AwaitingResolutionCheckboxGroupContext,
  AwaitingResolutionReviewCheckboxGroupProvider,
] = generateCheckboxGroupProvider()

const TABLE_REVIEW = '0'
const AWAITING_GROUP_QC_ACTION = '1'
const AWAITING_RESOLUTION = '2'
const RESOLVED = '3'

export const TAB_NAME_MAPPING = {
  [TABLE_REVIEW]: 'table',
  [AWAITING_GROUP_QC_ACTION]: 'group',
  [AWAITING_RESOLUTION]: 'resolution',
  [RESOLVED]: 'resolved',
}

const TableManagerTabs = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(TABLE_REVIEW)

  const { setListPages, setListPage, fetchUnreadStatusRequest, unreadStatus } =
    useFlagsFilteredListContext()

  const { makeRequest, hasErrors, errors } = fetchUnreadStatusRequest

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    makeRequest()
  }, [])

  return (
    <>
      <CardError hide={!hasErrors} message={errorMsg} />
      <Tab.Container
        selectedTabId={tab}
        onChange={({ tabId }) => {
          setListPages({
            group: 1,
            table: 1,
            resolution: 1,
            resolved: 1,
          })
          setTab(tabId)
          makeRequest()
        }}
      >
        <Tab
          id={TABLE_REVIEW}
          alert={unreadStatus.table}
          label={t('Table Review')}
        >
          {tab === TABLE_REVIEW && (
            <TableReviewCheckboxGroupProvider>
              <TableProgramFilters activeTab={tab} />
              <PendingTableProgramReviewList
                checkboxContext={TableReviewCheckboxGroupContext}
                tabType="table"
                BlankSlate={AwaitingTableReviewBlankSlate}
                setListPage={page => setListPage('table', page)}
              />
            </TableReviewCheckboxGroupProvider>
          )}
        </Tab>
        <Tab
          label={t('Awaiting Group QC Action')}
          id={AWAITING_GROUP_QC_ACTION}
          alert={unreadStatus.group}
        >
          {tab === AWAITING_GROUP_QC_ACTION && (
            <>
              <TableProgramFilters activeTab={tab} />
              <GroupQcActionList
                setListPage={page => setListPage('group', page)}
              />
            </>
          )}
        </Tab>
        <Tab
          label={t('Awaiting Resolution')}
          id={AWAITING_RESOLUTION}
          alert={unreadStatus.resolution}
        >
          {tab === AWAITING_RESOLUTION && (
            <AwaitingResolutionReviewCheckboxGroupProvider>
              <TableProgramFilters activeTab={tab} />
              <PendingTableProgramReviewList
                checkboxContext={AwaitingResolutionCheckboxGroupContext}
                tabType="resolution"
                BlankSlate={AwaitingResolutionBlankSlate}
                setListPage={page => setListPage('resolution', page)}
              />
            </AwaitingResolutionReviewCheckboxGroupProvider>
          )}
        </Tab>
        <Tab label={t('Resolved')} id={RESOLVED}>
          {tab === RESOLVED && (
            <>
              <TableProgramFilters activeTab={tab} />
              <ResolvedList
                setListPage={page => setListPage('resolved', page)}
              />
            </>
          )}
        </Tab>
      </Tab.Container>
    </>
  )
}

export default TableManagerTabs
