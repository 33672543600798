export const buildParams = ({ startDate, endDate, turfId, shiftType }) => ({
  filters: {
    rules: [
      {
        rules: [
          {
            column: 'shift_start',
            operator: 'after',
            param: startDate.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            column: 'shift_end',
            operator: 'before',
            param: endDate.add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          },
        ],
        conjunction: 'exclusive',
      },
      {
        column: 'in_turf_tree',
        param: turfId,
      },
      shiftType !== 'all'
        ? {
            column: 'type',
            operator: 'is',
            param: shiftType,
          }
        : {},
    ],
    conjunction: 'exclusive',
  },
  fields: [
    'id',
    'shift_start',
    'shift_end',
    'type',
    'status',
    { location: ['name'] },
    { canvasser: ['full_name'] },
  ],
  per: Number.MAX_SAFE_INTEGER,
  indexed: true,
})

export const buildShifts = shift => ({
  ...shift,
  start: shift.shift_start,
  end: shift.shift_end,
})
