import moment from 'moment'
import { getQcFlagFilter } from 'utils/flags'
import { CurrentFlag } from './CurrentFlagContext'
import { FLAG_TABS, FlagsFilters, TabTypes } from './FlagsFilteredListContext'

type FilterRule = {
  column: string
  param: any
  operator?: string
  invert?: boolean
}

export const createRequestFilters = (
  {
    canvasser,
    canvasserFlag,
    endDate,
    id,
    turf,
    flagName,
    startDate,
    technicalFlag,
    newComments,
  }: FlagsFilters,
  userId?: number
) => {
  const filters = []
  if (!technicalFlag) {
    filters.push({
      column: 'trigger_implies_canvasser_issue',
      operator: 'is',
      param: true,
    })
  }

  if (!canvasserFlag) {
    filters.push({
      column: 'trigger_implies_canvasser_issue',
      operator: 'is',
      param: false,
    })
  }

  if (turf && turf !== 'all') {
    filters.push({
      column: 'in_turf_tree',
      param: turf,
    })
  }

  if (startDate) {
    filters.push({
      column: 'created_at',
      operator: 'after',
      param: startDate,
    })
  }

  if (endDate) {
    filters.push({
      column: 'created_at',
      operator: 'before',
      param: endDate,
    })
  }

  if (canvasser) {
    filters.push({
      column: 'triggered_by_canvasser_id',
      operator: 'is',
      param: canvasser,
    })
  }

  if (flagName && flagName !== 'all') {
    filters.push({
      column: 'trigger_name',
      operator: 'is',
      param: flagName,
    })
  }

  if (id && id !== '') {
    filters.push({
      column: 'id',
      operator: 'containing',
      param: id,
    })
  }

  if (newComments && userId) {
    filters.push({
      column: 'with_unread_comments',
      param: userId,
    })
  }

  return filters
}

export const getDuplicateFlagFilters = (
  currentFlag: CurrentFlag,
  isExternalQc: boolean
): Array<FilterRule> => {
  const filterRules: Array<FilterRule> = [
    {
      column: 'id',
      operator: 'is',
      param: currentFlag.id,
      invert: true,
    },
    {
      column: 'status',
      operator: 'is',
      param: 'completed',
      invert: true,
    },
    {
      column: 'trigger_name',
      operator: 'is',
      param: currentFlag.trigger.name,
    },
  ]

  const qcFilter = getQcFlagFilter({
    only360: isExternalQc,
  })
  if (qcFilter !== undefined) {
    filterRules.push(qcFilter)
  }

  if (currentFlag.trigger.resource_type === 'packet') {
    filterRules.push({
      column: 'packet',
      operator: 'is',
      param: currentFlag.packet?.id,
    })
  } else {
    filterRules.push({
      column: 'canvasser',
      operator: 'is',
      param: currentFlag.canvasser?.id,
    })
  }

  return filterRules
}

export const getBaseFlagsParams = (
  filters: FlagsFilters,
  page: number,
  additionalFilterRules: FilterRule[],
  filterRulesToDisable?: Pick<FilterRule, 'column'>[],
  userId?: number
): {
  current_page: number
  filters: {
    rules: FilterRule[]
  }
  sort_attr: string | undefined
  sort_dir: 'asc' | 'desc'
} => {
  const filterRules = createRequestFilters(filters, userId) as FilterRule[]
  const { sortBy } = filters
  const sort_attr = sortBy && sortBy !== 'none' ? sortBy : undefined
  const sort_dir = sortBy === 'id' ? 'asc' : 'desc'
  const requestFilterRules = [...filterRules, ...additionalFilterRules].filter(
    requestFilterRule =>
      requestFilterRule &&
      !filterRulesToDisable?.find(
        filterRuleToDisable =>
          filterRuleToDisable.column === requestFilterRule.column
      )
  )
  return {
    current_page: page,
    filters: {
      rules: requestFilterRules,
    },
    sort_attr,
    sort_dir,
  }
}

export const getFilteredFlagsRequestParams = (
  type: TabTypes,
  filters: FlagsFilters,
  page: number,
  filterRulesToDisable: Pick<FilterRule, 'column'>[],
  currentTurfPerformsExternalQC: boolean,
  userId?: number | undefined
) => {
  const additionalFilters: Array<FilterRule> = []

  if (currentTurfPerformsExternalQC) {
    additionalFilters.push({
      column: 'triggered_by_shift_qc_external',
      operator: 'is',
      param: currentTurfPerformsExternalQC,
    })
  }

  if (type === FLAG_TABS.TABLE) {
    additionalFilters.push({
      column: 'status',
      operator: 'is',
      param: 'pending',
    })
  }
  if (type === FLAG_TABS.GROUP) {
    additionalFilters.push({
      column: 'status',
      operator: 'is',
      param: 'ready',
    })
  }
  if (type === FLAG_TABS.RESOLUTION) {
    additionalFilters.push({
      column: 'status',
      operator: 'is',
      param: 'reviewed',
    })
  }
  if (type === FLAG_TABS.RESOLVED) {
    additionalFilters.push({
      column: 'status',
      operator: 'is',
      param: 'completed',
    })
    if (!filters.startDate) {
      additionalFilters.push({
        column: 'created_at',
        operator: 'after',
        param: moment().startOf('week').toDate(),
      })
    }
    if (!filters.endDate) {
      additionalFilters.push({
        column: 'created_at',
        operator: 'before',
        param: moment().endOf('week').toDate(),
      })
    }
  }

  return getBaseFlagsParams(
    filters,
    page,
    additionalFilters,
    filterRulesToDisable,
    userId
  )
}
