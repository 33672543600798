import { formatDisplayName } from 'utils/formatting'

export const buildOption = user => ({
  value: user.id,
  label: formatDisplayName(user),
  userInfo: user,
})

export const buildUser = option => ({
  ...option.userInfo,
})

export const buildParams = ({ query, filters = [] }) => ({
  filters:
    query || filters.length
      ? {
          rules: [
            query && query !== ''
              ? {
                  rules: [
                    {
                      column: 'full_name',
                      operator: 'containing',
                      param: query,
                    },
                  ],
                  conjunction: 'inclusive',
                }
              : undefined,
            ...filters,
          ],
          conjunction: 'exclusive',
        }
      : undefined,
  sort_attr: 'full_name',
  sort_dir: 'asc',
  per: 10,
  indexed: true,
})
